import { Spinner, SpinnerSize, TeachingBubble } from "@fluentui/react";
import React, { useRef } from "react";
import PortfolioModelWalletService from "../../services/PortfolioModelWalletService";
import CustomIcon from "../icons/CustomIcon";
import * as Style from "./ActiveSavingPlansStyle";
import { ModalChangePlanName } from "./ModalChangePlanName";
import { ChevronDownIcon, ChevronUpIcon } from "@fluentui/react-icons-mdl2";
import { GenericMessageModal } from "../Modals/GenericMessageModal";
import { addLine, cartState, updateModelPortfolioName, clearCart, updateModelPercentageTotal, setPunctualContributionPortfolioData } from "../../store/cart/actions";
import portfolioModelWallets, { ModelWallet } from "../../services/PortfolioModelWalletService";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { CartModes } from "../../screen/Cart/CartScreen";
import Auth from "../../Auth/Auth";
import { Contracts, ContractsUnderSameSubscription } from "../Payments/RePaymentSubscription";

const educationalType = 5;
export interface ITableSPlansActive
{
    id: number;
    planName: string;
    startDate: string;
    validDate: string;
    outdated?: boolean;
    outdatedContract?: boolean;
    cancelled?: boolean;
    status: any;
    hasElementsSubscribed: boolean;
    type: number;
    selected: boolean;
    subscriptionId?: number;
    userSubscriptionId?: number;
    portfolio: any;
    portfolioAmount: number;
    finametrixContract: string;
    isExpanded: boolean;
    canBeDeleted?: boolean;
    managedPortfolioFinametrixId?: string;
    portfolioModels?: PortfolioModelType[];
    configurationMapStep: string;
    subscriptionName?: string;
    subscriptionPeriod?: string,
    subscriptionPrice?: number;
    subscriptionLimit?: number;
    subscriptionType?: string;
    outdateRecharge?: number;
    elementsWater?: number;
    elementsIA?: number;
    elementAmountId?: number;
    isSubscriptionRenewalBeforeExpiration?: boolean;
    hasElements?: boolean;
}
type PortfolioModelType =
{
    id: number;
    name: string;
    savingPlanId: number;
    userSubscriptionId: number;
    contract: string;
    deletable: boolean;
};
export interface ITableProps
{
    columnTitles: string[],
    rowData: ITableSPlansActive[] | undefined,
    theme: any,
    userId: number,
    onlyShowing: number,
    onClick?: (event: any, item: any, hTR: boolean) => any,
    onSubmit?: (item: string, id: number) => any
}
export interface SubscriptionIdsAndInfo
{
    userSubscriptionId: number,
    contractInfo: Contracts[]
}

function getType(type: number)
{
    switch(type)
    {
        case 0:
            return "Plan Gestionado";
        case 1:
            return "Me Gusta Decidir";
        case 2:
            return "Gurú";
        case 3:
        case 4:
            return "Plan Libertad";
        case 5:
            return "Educación Financiera";
        default:
            return "-";
    }
}

export const TableActiveSavingPlans = (props: ITableProps) =>
{
    const firstTime = useRef<boolean>(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<ITableSPlansActive[]>();
    const [tooltipVisibilities, setTooltipVisibilities] = React.useState<boolean[][][]>([]);
    const [subscriptionInfo, setSubscriptionInfo] = React.useState<SubscriptionIdsAndInfo[]>([]);
    // const [tooltipsResuscribe, setTooltipsResuscribe] = React.useState<boolean[]>([]);
    // const [tooltipsOutdated, setTooltipsOutdated] = React.useState<boolean[]>([]);
    // const resuscribeTooltip = "Pulsa aquí para renovar este plan";

    React.useEffect(() =>
    {
        if(props.rowData !== undefined)
        {
            if(props.onlyShowing === 4)
            {
                setData(props.rowData.filter((e) => e.status === 4));
            }
            else if(props.onlyShowing === 5)
            {
                setData(props.rowData.filter((e) => e.status === 5));
            }
            else
            {
                setData(props.rowData.filter((e) => e.status === 6));
            }

            var auxData: SubscriptionIdsAndInfo[] = [];

            for(var i=0; i<props.rowData.length; i++)
            {
                if(props.rowData[i].userSubscriptionId !== undefined && props.rowData[i].finametrixContract !== null)
                {
                    // eslint-disable-next-line no-loop-func
                    var index = auxData.findIndex((item) => item.userSubscriptionId === (props.rowData as ITableSPlansActive[])[i].userSubscriptionId);

                    if(index >=  0)
                    {
                        auxData[index].contractInfo.push(
                        {
                            contract: props.rowData[i].finametrixContract,
                            elementAmountId: props.rowData[i].elementAmountId,
                            elementsIA: props.rowData[i].elementsIA,
                            elementsWater: props.rowData[i].elementsWater,
                            savingPlanName: props.rowData[i].planName,
                            hasElements: props.rowData[i].hasElements,
                            isSubscriptionRenewalBeforeExpiration: props.rowData[i].isSubscriptionRenewalBeforeExpiration,
                            savingPlanId: props.rowData[i].id
                        });
                    }
                    else
                    {
                        auxData.push({userSubscriptionId: props.rowData[i].userSubscriptionId as number, contractInfo: []});
                        
                        // eslint-disable-next-line no-loop-func
                        var newIdx = auxData.findIndex((it) => it.userSubscriptionId === (props.rowData as ITableSPlansActive[])[i].userSubscriptionId);

                        auxData[newIdx].contractInfo.push(
                        {
                            contract: props.rowData[i].finametrixContract,
                            elementAmountId: props.rowData[i].elementAmountId,
                            elementsIA: props.rowData[i].elementsIA,
                            elementsWater: props.rowData[i].elementsWater,
                            savingPlanName: props.rowData[i].planName,
                            hasElements: props.rowData[i].hasElements,
                            isSubscriptionRenewalBeforeExpiration: props.rowData[i].isSubscriptionRenewalBeforeExpiration,
                            savingPlanId: props.rowData[i].id
                        });
                    }
                }
            }

            setSubscriptionInfo(auxData);

            //setTooltipsOutdated(new Array(props.rowData.length).fill(true));
            //setTooltipsResuscribe(new Array(props.rowData.length).fill(true));
        }
    }, [props.rowData, props.onlyShowing]);

    const handleMouseEnter = (depthIndex: number, rowIndex: number, elementIndex: number) =>
    {
        const newTooltipVisibilities = [...tooltipVisibilities];
        if(!newTooltipVisibilities[depthIndex])
        {
            newTooltipVisibilities[depthIndex] = [];
        }
        if(!newTooltipVisibilities[depthIndex][rowIndex])
        {
            newTooltipVisibilities[depthIndex][rowIndex] = [];
        }
        newTooltipVisibilities[depthIndex][rowIndex][elementIndex] = true;
        setTooltipVisibilities(newTooltipVisibilities);
    };

    const handleMouseLeave = (depthIndex: number, rowIndex: number, elementIndex: number) =>
    {
        const newTooltipVisibilities = [...tooltipVisibilities];
        if(newTooltipVisibilities[depthIndex] && newTooltipVisibilities[depthIndex][rowIndex])
        {
            newTooltipVisibilities[depthIndex][rowIndex][elementIndex] = false;
            setTooltipVisibilities(newTooltipVisibilities);
        }
    };

    const handleClick: ITableProps["onClick"] = (event: any, item: any) =>
    {
        if(props.onClick !== undefined)
        {
            props.onClick(event, item, false);
        }
    };

    const handleClickWithRedirect: ITableProps["onClick"] = (event: any, item: any) =>
    {
        if(props.onClick !== undefined)
        {
            props.onClick(event, item, true);
        }
    };

    const updatePlanName = (item: string, id: number) =>
    {
        if(props.onSubmit !== undefined)
        {
            props.onSubmit(item, id);
        }
    };

    const getPortfolioModel = async (savingPlanId: number, portfolioModelId: number) =>
    {
        portfolioModelWallets.getModelWallet(savingPlanId, portfolioModelId).then((response: ModelWallet) =>
        {
            if(response !== undefined && response !== null)
            {
                firstTime.current = false;
                dispatch(clearCart());
                let modelPercentageTotal = 0;
                response.jsonFundsData.map((item) =>
                {
                    modelPercentageTotal += item.modelPercentage;
                    return dispatch(addLine(
                        {
                            fund: item.fund,
                            amount: 0,
                            modelPercentage: item.modelPercentage,
                            readOnly: item.readOnly,
                            savingsPlanId: item.savingPlanId,
                        })
                    );
                });
                dispatch(updateModelPercentageTotal(modelPercentageTotal));
                dispatch(cartState(CartModes.CREATE_MODEL_PORTFOLIO));
                dispatch(updateModelPortfolioName(response.name));
                history.push(
                {
                    pathname: `/cart`,
                    state:
                    {
                        savingPlanType: response.savingPlanType,
                        portfolioModelId: portfolioModelId,
                    },
                });
            }
        })
        .finally(() =>
        {
            setLoading(false);
        });
    };

    const renamePortfolioModelWallet = (savingPlanId: number, name: string, portfolioModelId: number) =>
    {
        PortfolioModelWalletService.renameModelWallet(savingPlanId, name, portfolioModelId);
    };

    const deletePortfolioModelWallet = (savingPlanId: number, portfolioModelId: number) =>
    {
        PortfolioModelWalletService.deleteModelWallet(savingPlanId, portfolioModelId);
    };

    const cartModelPortfolio = (event: any, savingPlanId: number, portfolioModelId: number) =>
    {
        let savingPlan = Auth.getActivePlan();

        if(savingPlan.id !== savingPlanId)
        {
            //Seleccionamos como activo el plan
            if(props.onClick)
            {
                props.onClick(event, savingPlanId, false);
            }
        }

        PortfolioModelWalletService.getModelWallet(savingPlanId, portfolioModelId).then((response: ModelWallet) =>
        {
            let modelPercentageTotal = 0;
            // eslint-disable-next-line array-callback-return
            response.jsonFundsData.map(item =>
            {
                modelPercentageTotal += item.modelPercentage;
                if(item.fund.available)
                {
                    return dispatch(addLine(
                    {
                        fund: item.fund,
                        amount: 0,
                        modelPercentage: item.modelPercentage,
                        readOnly: item.readOnly,
                        savingsPlanId: item.savingPlanId
                    }));
                }
            });

            dispatch(updateModelPercentageTotal(modelPercentageTotal));
            dispatch(setPunctualContributionPortfolioData({name: response.name, id: portfolioModelId}));
            dispatch(cartState(CartModes.PUNCTUAL_CONTRIBUTION));
            history.push("/cart");
        });
    }

    // function handleDismissTooltip(ev: any, rowIndex: number)
    // {
    //     if(ev._reactName === "onClick")
    //     {
    //         setTooltipsResuscribe((prevState: any) =>
    //         {
    //             const newTooltipsResuscribe = [...prevState];
    //             newTooltipsResuscribe[rowIndex] = false;
    //             return newTooltipsResuscribe;
    //         });
    //     }
    // }

    // function handleDismissOutdatedTooltip(ev: any, rowIndex: number)
    // {
    //     if(ev._reactName === "onClick")
    //     {
    //         setTooltipsOutdated((prevState: any) =>
    //         {
    //             const newTooltipsOutdated = [...prevState];
    //             newTooltipsOutdated[rowIndex] = false;
    //             return newTooltipsOutdated;
    //         });
    //     }
    // }

    function displayArrow(expanded: boolean, list: any[] | undefined): JSX.Element
    {
        if(list)
        {
            if(list?.length <= 0)
            {
                return <ChevronDownIcon className="savingPlans-TableArrowHidden" />;
            }
            if(expanded && list?.length > 0)
            {
                return <ChevronUpIcon className="savingPlans-TableArrow" />;
            }
            if(!expanded && list?.length > 0)
            {
                return <ChevronDownIcon className="savingPlans-TableArrow" />;
            }
        }
        return <ChevronDownIcon className="savingPlans-TableArrowHidden" />;
    }

    return(
        <React.Fragment>
            {loading === true && <Spinner size={SpinnerSize.large} />}
            {loading === false &&
            (
                <Style.Table theme={props.theme}>
                    <thead>
                        <tr>
                            {props.columnTitles.length > 0 &&
                                props.columnTitles.map((column, columnIndex) =>
                                {
                                    return(
                                        <th style={{textAlign: "center", cursor: "default"}} key={"SPlansActiveColumnIndex" + columnIndex}>
                                            {column}
                                        </th>
                                    );
                                })}
                        </tr>
                    </thead>
                    <tbody>
                        {data !== undefined && data.length > 0 && data.map((row, index) =>
                        {
                            let elementIndex = 0;

                            const subscriptionData = subscriptionInfo.find((item) => item.userSubscriptionId === row.userSubscriptionId);
                            var contractData: ContractsUnderSameSubscription =
                            {
                                subscriptionInfo: subscriptionData?.contractInfo as Contracts[],
                                dateOfOutdate: row.validDate,
                                outdateRecharge: row.outdateRecharge ?? 0,
                                subscriptionId: row.subscriptionId ?? 0,
                                subscriptionType: row.subscriptionType ?? "",
                                subscriptionLimit: row.subscriptionLimit ?? 0,
                                subscriptionName: row.subscriptionName ?? "",
                                subscriptionPeriod: row.subscriptionPeriod ?? "",
                                subscriptionPrice: row.subscriptionPrice ?? 0,
                                userSubscriptionId: row.userSubscriptionId ?? 0
                            }

                            var repaymentLink =
                            {
                                pathname: (row.subscriptionType !== undefined && row.subscriptionType === "promotion") ? "/Repayment/morePlans" : "/Repayment",
                                state: {contracts: contractData}
                            };

                            return(
                                <React.Fragment key={"SPlansActiveRowIndex" + index}>
                                    {row.status === 4 &&
                                    (
                                        <React.Fragment>
                                            {row.selected === true &&
                                            (
                                                <Style.SelectedTR theme={props.theme}>
                                                    <td onClick={() =>
                                                        {
                                                            row.isExpanded = !row.isExpanded;
                                                            setData([...data]);
                                                        }}
                                                    >
                                                        <CustomIcon iconName="BullseyeTargetEdit" size="15"
                                                            color="#08FF08"
                                                            paddingBottom="1px"
                                                            verticalAlign="middle"
                                                            cursor="default"
                                                        />
                                                        {displayArrow(row.isExpanded, row.portfolioModels)}
                                                        {row.planName}
                                                    </td>
                                                    <td>{row.startDate}</td>
                                                    <td>{row.validDate}</td>
                                                    <td>{getType(row.type)}</td>
                                                    <td style={{textAlign: "center"}}>
                                                        <Style.SelectedBtn theme={props.theme} onClick={(event) => event.preventDefault()}>
                                                            Plan Seleccionado
                                                        </Style.SelectedBtn>
                                                    </td>
                                                    <td>
                                                        <div className="ms-Grid-row">
                                                            <div id={"renove"+index+"-"+elementIndex} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                onMouseEnter={() => handleMouseEnter(index, elementIndex, 0)}
                                                                onMouseLeave={() => handleMouseLeave(index, elementIndex, 0)}
                                                            >
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex] && tooltipVisibilities[index][elementIndex][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#renove"+index+"-"+elementIndex} hasCloseButton={true}
                                                                            onDismiss={() => handleMouseLeave(index, elementIndex, 0)}
                                                                            headline="Renovación"
                                                                            closeButtonAriaLabel="Cerrar"
                                                                        >
                                                                            Pulsa para renovar este plan{" "}
                                                                            {row.planName}
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <Style.LinkBtn to={repaymentLink}>
                                                                    <CustomIcon iconName="Sync" size="17" color="#CC214F" paddingTop="8%" paddingLeft="25%" />
                                                                </Style.LinkBtn>
                                                            </div>
                                                            <div id={"rename"+index+"-"+elementIndex+1} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                onMouseEnter={() => handleMouseEnter(index, elementIndex+1, 0)}
                                                                onMouseLeave={() => handleMouseLeave(index, elementIndex+1, 0)}
                                                            >
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+1] && tooltipVisibilities[index][elementIndex+1][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#rename"+index+"-"+elementIndex+1}
                                                                            hasCloseButton={true}
                                                                            onDismiss={() => handleMouseLeave(index, elementIndex+1, 0)}
                                                                            headline="Renombrar"
                                                                            closeButtonAriaLabel="Cerrar"
                                                                        >
                                                                            Pulsa para renombrar este plan{" "}
                                                                            {row.planName}
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <ModalChangePlanName name={row.planName} onSubmit={(item) => updatePlanName(item, row.id)} />
                                                            </div>
                                                            <div id={"cartera"+index+"-"+elementIndex+2} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                onMouseEnter={() => handleMouseEnter(index, elementIndex+2, 0)}
                                                                onMouseLeave={() => handleMouseLeave(index, elementIndex+2, 0)}
                                                            >
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+2] && tooltipVisibilities[index][elementIndex+2][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#cartera"+index+"-"+elementIndex+2}
                                                                            hasCloseButton={true}
                                                                            onDismiss={() => handleMouseLeave(index, elementIndex+2, 0)}
                                                                            headline="Resumen de Cartera"
                                                                            closeButtonAriaLabel="Cerrar"
                                                                        >
                                                                            Pulsa para ir a la Cartera
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <Style.LinkBtn to={{pathname: "/portfolio"}}>
                                                                    <CustomIcon iconName="ExploreData" size="17" color="#CC214F" paddingTop="14%" />
                                                                </Style.LinkBtn>
                                                            </div>
                                                            <div id={"map"+index+"-"+elementIndex+3} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                onMouseEnter={() => handleMouseEnter(index, elementIndex+3, 0)}
                                                                onMouseLeave={() => handleMouseLeave(index, elementIndex+3, 0)}
                                                            >
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+3][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#map"+index+"-"+elementIndex+3}
                                                                            hasCloseButton={true}
                                                                            onDismiss={() => handleMouseLeave(index, elementIndex+3, 0)}
                                                                            headline="Mapa de Configuración"
                                                                            closeButtonAriaLabel="Cerrar"
                                                                        >
                                                                            Pulsa para acceder al Mapa de Configuración de este plan
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <CustomIcon iconName="Edit" size="17" color="#CC214F" paddingTop="10%"
                                                                    onClick={() => history.push(
                                                                    {
                                                                        pathname: `/configurationMap`,
                                                                        state:
                                                                        {
                                                                            savingPlanId: row.id,
                                                                            savingPlanName: row.planName,
                                                                        },
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </Style.SelectedTR>
                                            )}
                                            {row.selected === false &&
                                            (
                                                <tr>
                                                    <td style={{cursor: "default"}} onClick={() =>
                                                        {
                                                            row.isExpanded = !row.isExpanded;
                                                            setData([...data]);
                                                        }}
                                                    >
                                                        {displayArrow(row.isExpanded, row.portfolioModels)}
                                                        {row.planName}
                                                    </td>
                                                    <td style={{cursor: "default"}}>{row.startDate}</td>
                                                    <td style={{cursor: "default"}}>{row.validDate}</td>
                                                    <td style={{cursor: "default"}}>{getType(row.type)}</td>
                                                    <td style={{textAlign: "center"}}>
                                                        {row.type !== educationalType &&
                                                        (
                                                            <Style.SelectActivePlanBtn theme={props.theme} onClick={(event) => handleClick(event, row.id, false)}>
                                                                Seleccionar como Activo
                                                            </Style.SelectActivePlanBtn>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div className="ms-Grid-row">
                                                            <div id={"renove"+index+"-"+elementIndex} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                onMouseEnter={() => handleMouseEnter(index, elementIndex, 0)}
                                                                onMouseLeave={() => handleMouseLeave(index, elementIndex, 0)}
                                                            >
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex] && tooltipVisibilities[index][elementIndex][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#renove"+index+"-"+elementIndex}
                                                                            hasCloseButton={true}
                                                                            onDismiss={() => handleMouseLeave(index, elementIndex, 0)}
                                                                            headline="Renovación"
                                                                            closeButtonAriaLabel="Cerrar"
                                                                        >
                                                                            Pulsa para renovar este plan{" "}
                                                                            {row.planName}
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <Style.LinkBtn to={repaymentLink}>
                                                                    <CustomIcon iconName="Sync" size="17" color="#CC214F" paddingTop="8%" paddingLeft="25%" />
                                                                </Style.LinkBtn>
                                                            </div>
                                                            {row.type !== educationalType &&
                                                            (
                                                                <React.Fragment>
                                                                    <div id={"rename"+index+"-"+elementIndex+1} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                        onMouseEnter={() => handleMouseEnter(index, elementIndex+1, 0)}
                                                                        onMouseLeave={() => handleMouseLeave(index, elementIndex+1, 0)}
                                                                    >
                                                                        {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+1] && tooltipVisibilities[index][elementIndex+1][0] &&
                                                                        (
                                                                            <React.Fragment>
                                                                                <TeachingBubble target={"#rename"+index+"-"+elementIndex+1}
                                                                                    hasCloseButton={true}
                                                                                    onDismiss={() => handleMouseLeave(index, elementIndex+1, 0)}
                                                                                    headline="Renombrar"
                                                                                    closeButtonAriaLabel="Cerrar"
                                                                                >
                                                                                    Pulsa para renombrar este plan{" "}
                                                                                    {row.planName}
                                                                                </TeachingBubble>
                                                                            </React.Fragment>
                                                                        )}
                                                                        <ModalChangePlanName name={row.planName} onSubmit={(item) => updatePlanName(item, row.id)} />
                                                                    </div>
                                                                    <div id={"cartera"+index+"-"+(elementIndex+2)} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                        onMouseEnter={() => handleMouseEnter(index, elementIndex+2, 0)}
                                                                        onMouseLeave={() => handleMouseLeave(index, elementIndex+2, 0)}
                                                                    >
                                                                        {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+2] && tooltipVisibilities[index][elementIndex+2][0] &&
                                                                        (
                                                                            <React.Fragment>
                                                                                <TeachingBubble target={"#cartera"+index+"-"+(elementIndex+2)}
                                                                                    hasCloseButton={true}
                                                                                    onDismiss={() => handleMouseLeave(index, elementIndex+2, 0)}
                                                                                    headline="Resumen de Cartera"
                                                                                    closeButtonAriaLabel="Cerrar"
                                                                                >
                                                                                    Pulsa para ir a la Cartera
                                                                                </TeachingBubble>
                                                                            </React.Fragment>
                                                                        )}
                                                                        <Style.LinkBtn to={{pathname: "/portfolio"}}>
                                                                            <CustomIcon iconName="ExploreData" size="17" color="#CC214F" paddingTop="14%"
                                                                                onClick={(event) => handleClickWithRedirect(event, row.id, true)}
                                                                            />
                                                                        </Style.LinkBtn>
                                                                    </div>
                                                                    <div id={"map"+index+"-"+elementIndex+3} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                        onMouseEnter={() => handleMouseEnter(index, elementIndex+3, 0)}
                                                                        onMouseLeave={() => handleMouseLeave(index, elementIndex+3, 0)}
                                                                    >
                                                                        {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+3][0] &&
                                                                        (
                                                                            <React.Fragment>
                                                                                <TeachingBubble target={"#map"+index+"-"+elementIndex+3}
                                                                                    hasCloseButton={true}
                                                                                    onDismiss={() => handleMouseLeave(index, elementIndex+3, 0)}
                                                                                    headline="Mapa de Configuración"
                                                                                    closeButtonAriaLabel="Cerrar"
                                                                                >
                                                                                    Pulsa para acceder al Mapa de Configuración de este plan
                                                                                </TeachingBubble>
                                                                            </React.Fragment>
                                                                        )}
                                                                        <CustomIcon iconName="Edit" size="17" color="#CC214F" paddingTop="10%"
                                                                            onClick={() => history.push(
                                                                                {
                                                                                    pathname: `/configurationMap`,
                                                                                    state:
                                                                                    {
                                                                                        savingPlanId: row.id,
                                                                                        savingPlanName: row.planName,
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            {row && row.portfolioModels && row.portfolioModels.map((modelWallet: PortfolioModelType, subIndex) =>
                                            {
                                                return(
                                                    <React.Fragment key={row.id+"_modelWalletRow_"+subIndex+"_"+modelWallet.id}>
                                                        {row.isExpanded &&
                                                        (
                                                            <tr>
                                                                <td colSpan={5} style={{paddingLeft: "50px", height: "50px", cursor: "default"}}>
                                                                    {modelWallet.name}
                                                                </td>
                                                                <Style.TdModelPortfolio className="portfolioTd">
                                                                    {row.type !== educationalType &&
                                                                    (
                                                                        <React.Fragment>
                                                                            {row.type === 4 &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    <div id={"euro"+index+"-"+subIndex+1+"-"+elementIndex}
                                                                                        onMouseEnter={() => handleMouseEnter(index, elementIndex, subIndex+1)}
                                                                                        onMouseLeave={() => handleMouseLeave(index, elementIndex, subIndex+1)}
                                                                                    >
                                                                                        {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex] && tooltipVisibilities[index][elementIndex][subIndex+1] &&
                                                                                        (
                                                                                            <React.Fragment>
                                                                                                <TeachingBubble target={"#euro"+index+"-"+subIndex+1+"-"+elementIndex} hasCloseButton={true}
                                                                                                    onDismiss={() => handleMouseLeave(index, elementIndex, subIndex+1)}
                                                                                                    headline="Aportación Puntual"
                                                                                                    closeButtonAriaLabel="Cerrar"
                                                                                                >
                                                                                                    Aportación Puntual a la Cartera Modelo {modelWallet.name}
                                                                                                </TeachingBubble>
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                        <CustomIcon iconName="AllCurrency" size="17" color="#CC214F" paddingTop="15%" onClick={(event: any) => cartModelPortfolio(event, modelWallet.savingPlanId, modelWallet.id)} />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}
                                                                            <div id={"period"+index+"-"+subIndex+1+"-"+elementIndex+1} style={{textAlign: "end"}}
                                                                                onMouseEnter={() => handleMouseEnter(index, elementIndex+1, subIndex+1)}
                                                                                onMouseLeave={() => handleMouseLeave(index, elementIndex+1, subIndex+1)}
                                                                            >
                                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+1] && tooltipVisibilities[index][elementIndex+1][subIndex+1] &&
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <TeachingBubble target={"#period"+index+"-"+subIndex+1+"-"+elementIndex+1} hasCloseButton={true}
                                                                                            onDismiss={() => handleMouseLeave(index, elementIndex+1, subIndex+1)}
                                                                                            headline="Aportaciones Periódicas"
                                                                                            closeButtonAriaLabel="Cerrar"
                                                                                        >
                                                                                            Pulsa para acceder a las Aportaciones Periódicas que te permitirán invertir cantidades de forma periódica
                                                                                        </TeachingBubble>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                <Style.LinkBtn to={
                                                                                    {
                                                                                        pathname: `/ModelPortfolio/PeriodicContributions/${modelWallet.id}`,
                                                                                        state:
                                                                                        {
                                                                                            portfolioModelId: modelWallet.id,
                                                                                            savingPlanId: modelWallet.savingPlanId,
                                                                                            portfolioModelName: modelWallet.name,
                                                                                            contract: modelWallet.contract,
                                                                                        },
                                                                                    }}
                                                                                >
                                                                                    <CustomIcon iconName="CalendarSettingsMirrored" size="17" color="#CC214F" paddingTop="15%" />
                                                                                </Style.LinkBtn>
                                                                            </div>
                                                                            <div id={"modelWallet"+index+"-"+subIndex+1+"-"+(elementIndex+2)}
                                                                                onMouseEnter={() => handleMouseEnter(index, elementIndex+2, subIndex+1)}
                                                                                onMouseLeave={() => handleMouseLeave(index, elementIndex+2, subIndex+1)}
                                                                            >
                                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+2] && tooltipVisibilities[index][elementIndex+2][subIndex+1] &&
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <TeachingBubble target={"#modelWallet"+index+"-"+subIndex+1+"-"+(elementIndex+2)}
                                                                                            hasCloseButton={true}
                                                                                            onDismiss={() => handleMouseLeave(index, elementIndex+2, subIndex+1)}
                                                                                            headline="Editar Cartera Modelo"
                                                                                            closeButtonAriaLabel="Cerrar"
                                                                                        >
                                                                                            Pulsa para editar la Cartera Modelo{" "}{modelWallet.name}
                                                                                        </TeachingBubble>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                <CustomIcon iconName="Edit" size="17" color="#CC214F" paddingTop="10%"
                                                                                    onClick={() =>
                                                                                    {
                                                                                        setLoading(true);
                                                                                        getPortfolioModel(modelWallet.savingPlanId, modelWallet.id);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {row.type !== educationalType &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    <div id={"walletName"+index+"-"+subIndex+1+"-"+(elementIndex+3)} 
                                                                                        onMouseEnter={() => handleMouseEnter(index, elementIndex+3, subIndex+1)}
                                                                                        onMouseLeave={() => handleMouseLeave(index, elementIndex+3, subIndex+1)}
                                                                                    >
                                                                                        {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+3][subIndex+1] &&
                                                                                        (
                                                                                            <React.Fragment>
                                                                                                <TeachingBubble target={"#walletName"+index+"-"+subIndex+1+"-"+(elementIndex+3)} hasCloseButton={true}
                                                                                                    onDismiss={() => handleMouseLeave(index, elementIndex+3, subIndex+1)}
                                                                                                    headline="Renombrar Cartera Modelo"
                                                                                                    closeButtonAriaLabel="Cerrar"
                                                                                                >
                                                                                                    Pulsa para renombrar la Cartera Modelo{" "}{modelWallet.name}
                                                                                                </TeachingBubble>
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                        <ModalChangePlanName name={modelWallet.name} paddingTopIcon={"9%"} isModelPortfolio={true}
                                                                                            onSubmit={(item) =>
                                                                                            {
                                                                                                renamePortfolioModelWallet(modelWallet.savingPlanId, item, modelWallet.id);
                                                                                                modelWallet.name = item;
                                                                                                setData([...data]);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {modelWallet.deletable === true &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    <div id={"deletable"+index+"-"+subIndex+"-"+elementIndex+4} 
                                                                                        onMouseEnter={() => handleMouseEnter(index, elementIndex+4, subIndex+1)}
                                                                                        onMouseLeave={() => handleMouseLeave(index, elementIndex+4, subIndex+1)}
                                                                                    >
                                                                                        {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+4] && tooltipVisibilities[index][elementIndex+4][subIndex+1] &&
                                                                                        (
                                                                                            <React.Fragment>
                                                                                                <TeachingBubble target={"#deletable"+index+"-"+subIndex+1+"-"+elementIndex+4} hasCloseButton={true}
                                                                                                    onDismiss={() => handleMouseLeave(index, elementIndex+4, subIndex+1)}
                                                                                                    headline="Borrar Cartera Modelo"
                                                                                                    closeButtonAriaLabel="Cerrar"
                                                                                                >
                                                                                                    Pulsa para borrar la Cartera Modelo
                                                                                                </TeachingBubble>
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                        <GenericMessageModal headerLabel={modelWallet.name} bodyLabel="¿Desea eliminar esta cartera modelo?"
                                                                                            icon=
                                                                                            {
                                                                                                <CustomIcon iconName="Delete" size="17" color="#CC214F" paddingTop="19%" />
                                                                                            }
                                                                                            onSubmit={(item) =>
                                                                                            {
                                                                                                if(item === true)
                                                                                                {
                                                                                                    deletePortfolioModelWallet(modelWallet.savingPlanId, modelWallet.id);
                                                                                                    row.portfolioModels = row.portfolioModels?.filter((x) => x.id !== modelWallet.id);
                                                                                                    setData([...data]);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {modelWallet.deletable === false &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    <div id={"notDeletable"+index+"-"+subIndex+1+"-"+elementIndex+4}
                                                                                        onMouseEnter={() => handleMouseEnter(index, elementIndex+4, subIndex+1)}
                                                                                        onMouseLeave={() => handleMouseLeave(index, elementIndex+4, subIndex+1)}
                                                                                    >
                                                                                        {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+4] && tooltipVisibilities[index][elementIndex+4][subIndex+1] &&
                                                                                        (
                                                                                                <React.Fragment>
                                                                                                    <TeachingBubble target={"#notDeletable"+index+"-"+subIndex+1+"-"+elementIndex+4} hasCloseButton={true}
                                                                                                        onDismiss={() => handleMouseLeave(index, elementIndex+4, subIndex+1)}
                                                                                                        headline="Borrar Cartera Modelo"
                                                                                                        closeButtonAriaLabel="Cerrar"
                                                                                                    >
                                                                                                        Existen Aportaciones Periódicas asociadas pendientes... Para poder borrar una
                                                                                                        Cartera Modelo no deben existir Aportaciones Periódicas.
                                                                                                    </TeachingBubble>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        <CustomIcon iconName="Delete" size="17" color="black" paddingTop="19%" />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </React.Fragment>
                                                                    )}
                                                                </Style.TdModelPortfolio>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                    {row.status === 5 &&
                                    (
                                        <React.Fragment>
                                            {row.selected === true &&
                                            (
                                                <Style.SelectedTR theme={props.theme}>
                                                    <td onClick={() =>
                                                        {
                                                            row.isExpanded = !row.isExpanded;
                                                            setData([...data]);
                                                        }}
                                                    >
                                                        <CustomIcon iconName="BullseyeTargetEdit" size="15" color="#FF0000" fontWeight="bold"
                                                            paddingBottom="1px" verticalAlign="middle" cursor="default"
                                                        />
                                                        {displayArrow(row.isExpanded, row.portfolioModels)}
                                                        {row.planName}
                                                    </td>
                                                    <td>{row.startDate}</td>
                                                    <td>{row.validDate} *</td>
                                                    <td>{getType(row.type)}</td>
                                                    <td style={{textAlign: "center"}}>
                                                        <Style.LinkBtn to={repaymentLink}>
                                                            <Style.SelectOutdatedPlanBtn theme={props.theme}>
                                                                Vuelve a Suscribirte
                                                            </Style.SelectOutdatedPlanBtn>
                                                        </Style.LinkBtn>
                                                    </td>
                                                    {/* <td>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3">
                                                            </div>
                                                            <div id={"rename"+index+"-"+elementIndex+1} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" onMouseEnter={() => handleMouseEnter(index, elementIndex+1, 0)} onMouseLeave={() => handleMouseLeave(index, elementIndex+1, 0)}>
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+1] && tooltipVisibilities[index][elementIndex+1][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#rename"+index+"-"+elementIndex+1} hasCloseButton={true} onDismiss={() => handleMouseLeave(index, elementIndex+1, 0)} headline="Renombrar" closeButtonAriaLabel="Cerrar">
                                                                            Pulsa para renombrar este plan {row.planName}
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <ModalChangePlanName name={row.planName} onSubmit={item => updatePlanName(item, row.id)} />
                                                            </div>
                                                            <div id={"cartera"+index+"-"+(elementIndex+2)} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" onMouseEnter={() => handleMouseEnter(index, elementIndex+2, 0)} onMouseLeave={() => handleMouseLeave(index, elementIndex+2, 0)}>
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+2] && tooltipVisibilities[index][elementIndex+2][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#cartera"+index+"-"+(elementIndex+2)} hasCloseButton={true} onDismiss={() => handleMouseLeave(index, elementIndex+2, 0)} headline="Resumen de Cartera" closeButtonAriaLabel="Cerrar">
                                                                            Pulsa para ir a la Cartera
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <Style.LinkBtn to={{pathname: "/portfolio"}} onClick={event => handleClickWithRedirect(event, row.id, false)}>
                                                                    <CustomIcon iconName="ExploreData" size="17" color="#CC214F" paddingTop="12%" />
                                                                </Style.LinkBtn>
                                                            </div>
                                                            <div id={"map"+index+"-"+elementIndex+3} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" onMouseEnter={() => handleMouseEnter(index, elementIndex+3, 0)} onMouseLeave={() => handleMouseLeave(index, elementIndex+3, 0)}>
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+3][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#map"+index+"-"+elementIndex+3} hasCloseButton={true} onDismiss={() => handleMouseLeave(index, elementIndex+3, 0)} headline="Mapa de Configuración" closeButtonAriaLabel="Cerrar">
                                                                            Pulsa para acceder al Mapa de Configuración de este plan
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <CustomIcon iconName="Edit" size="17" color="#CC214F" paddingTop="10%" onClick={() => history.push(
                                                                    {
                                                                        pathname: `/configurationMap`,
                                                                        state:
                                                                        {
                                                                            savingPlanId: row.id,
                                                                            savingPlanName: row.planName,
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td> */}
                                                </Style.SelectedTR>
                                            )}
                                            {row.selected === false &&
                                            (
                                                <Style.NoActiveTR theme={props.theme}>
                                                    <td onClick={() =>
                                                        {
                                                            row.isExpanded = !row.isExpanded;
                                                            setData([...data]);
                                                        }}
                                                    >
                                                        {displayArrow(row.isExpanded, row.portfolioModels)}
                                                        {row.planName}
                                                    </td>
                                                    <td>{row.startDate}</td>
                                                    <td>{row.validDate} *</td>
                                                    <td>{getType(row.type)}</td>
                                                    <td id={"outdated-"+index} style={{textAlign: "center"}}>
                                                        <Style.LinkBtn to={repaymentLink}>
                                                            <Style.UnSelectedBtn theme={props.theme}>
                                                                Vuelve a Suscribirte
                                                            </Style.UnSelectedBtn>
                                                        </Style.LinkBtn>
                                                    </td>
                                                    {/*<div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3">
                                                        </div>
                                                        <div id={"rename"+index+"-"+elementIndex+1} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" onMouseEnter={() => handleMouseEnter(index, elementIndex+1, 0)} onMouseLeave={() => handleMouseLeave(index, elementIndex+1, 0)}>
                                                            {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+1] && tooltipVisibilities[index][elementIndex+1][0] &&
                                                            (
                                                                <React.Fragment>
                                                                    <TeachingBubble target={"#rename"+index+"-"+elementIndex+1} hasCloseButton={true} onDismiss={() => handleMouseLeave(index, elementIndex+1, 0)} headline="Renombrar" closeButtonAriaLabel="Cerrar">
                                                                        Pulsa para renombrar este plan {row.planName}
                                                                    </TeachingBubble>
                                                                </React.Fragment>
                                                            )}
                                                            <ModalChangePlanName name={row.planName} onSubmit={item => updatePlanName(item, row.id)} />
                                                        </div>
                                                        <div id={"cartera"+index+"-"+(elementIndex+2)} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" onMouseEnter={() => handleMouseEnter(index, elementIndex+2, 0)} onMouseLeave={() => handleMouseLeave(index, elementIndex+2, 0)}>
                                                            {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+2] && tooltipVisibilities[index][elementIndex+2][0] &&
                                                            (
                                                                <React.Fragment>
                                                                    <TeachingBubble target={"#cartera"+index+"-"+(elementIndex+2)} hasCloseButton={true} onDismiss={() => handleMouseLeave(index, elementIndex+2, 0)} headline="Resumen de Cartera" closeButtonAriaLabel="Cerrar">
                                                                        Pulsa para ir a la Cartera
                                                                    </TeachingBubble>
                                                                </React.Fragment>
                                                            )}
                                                            <Style.LinkBtn to={{pathname: "/portfolio"}} onClick={event => event.preventDefault()} style={{cursor: "not-allowed"}}>
                                                                <CustomIcon iconName="ExploreData" size="17" color="#CC214F" paddingTop="12%" />
                                                            </Style.LinkBtn>
                                                        </div>
                                                        <div id={"map"+index+"-"+elementIndex+3} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" onMouseEnter={() => handleMouseEnter(index, elementIndex+3, 0)} onMouseLeave={() => handleMouseLeave(index, elementIndex+3, 0)}>
                                                            {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+3][0] &&
                                                            (
                                                                <React.Fragment>
                                                                    <TeachingBubble target={"#map"+index+"-"+elementIndex+3} hasCloseButton={true} onDismiss={() => handleMouseLeave(index, elementIndex+3, 0)} headline="Mapa de Configuración" closeButtonAriaLabel="Cerrar">
                                                                        Pulsa para acceder al Mapa de Configuración de este plan
                                                                    </TeachingBubble>
                                                                </React.Fragment>
                                                            )}
                                                            <CustomIcon iconName="Edit" size="17" color="#CC214F" paddingTop="10%" onClick={() => history.push(
                                                                {
                                                                    pathname: `/configurationMap`,
                                                                    state:
                                                                    {
                                                                        savingPlanId: row.id,
                                                                        savingPlanName: row.planName,
                                                                    }
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                </td> */}
                                                </Style.NoActiveTR>
                                            )}
                                            {row && row.portfolioModels && row.portfolioModels.map((modelWallet: PortfolioModelType, subIndex) =>
                                            {
                                                return(
                                                    <React.Fragment key={row.id+"_modelWalletRow_"+subIndex+"_"+modelWallet.id}>
                                                        {row.isExpanded &&
                                                        (
                                                            <tr>
                                                                <td colSpan={1} style={{paddingLeft: "50px", height: "50px", cursor: "default"}}>
                                                                    {modelWallet.name}
                                                                </td>
                                                                {/* <td>
                                                                    <div className="ms-Grid-row">
                                                                        {row.type !== educationalType &&
                                                                        (
                                                                            <React.Fragment>
                                                                                <div id={"period"+index+"-"+subIndex+1+"-"+elementIndex} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" style={{textAlign: "end"}}
                                                                                    onMouseEnter={() => handleMouseEnter(index, elementIndex, subIndex+1)}
                                                                                    onMouseLeave={() => handleMouseLeave(index, elementIndex, subIndex+1)}
                                                                                >
                                                                                    {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex] && tooltipVisibilities[index][elementIndex][subIndex+1] &&
                                                                                    (
                                                                                        <React.Fragment>
                                                                                            <TeachingBubble target={"#period"+index+"-"+subIndex+1+"-"+elementIndex} hasCloseButton={true}
                                                                                                onDismiss={() => handleMouseLeave(index, elementIndex, subIndex+1)}
                                                                                                headline="Aportaciones Periódicas"
                                                                                                closeButtonAriaLabel="Cerrar"
                                                                                            >
                                                                                                Pulsa para acceder a las Aportaciones Periódicas que te permitirán invertir cantidades de forma periódica
                                                                                            </TeachingBubble>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                    <Style.LinkBtn to={
                                                                                        {
                                                                                            pathname: `/ModelPortfolio/PeriodicContributions/${modelWallet.id}`,
                                                                                            state:
                                                                                            {
                                                                                                portfolioModelId: modelWallet.id,
                                                                                                savingPlanId: modelWallet.savingPlanId,
                                                                                                portfolioModelName: modelWallet.name,
                                                                                                contract: modelWallet.contract
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <CustomIcon iconName="CalendarSettingsMirrored" size="17" color="#CC214F" paddingTop="15%" />
                                                                                    </Style.LinkBtn>
                                                                                </div>
                                                                                <div id={"modelWallet"+index+"-"+subIndex+1+"-"+elementIndex+1} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                                    onMouseEnter={() => handleMouseEnter(index, elementIndex+1, subIndex+1)}
                                                                                    onMouseLeave={() => handleMouseLeave(index, elementIndex+1, subIndex+1)}
                                                                                >
                                                                                    {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+1] && tooltipVisibilities[index][elementIndex+1][subIndex+1] &&
                                                                                    (
                                                                                        <React.Fragment>
                                                                                            <TeachingBubble target={"#modelWallet"+index+"-"+subIndex+1+"-"+elementIndex+1} hasCloseButton={true}
                                                                                                onDismiss={() => handleMouseLeave(index, elementIndex+1, subIndex+1)}
                                                                                                headline="Editar Cartera Modelo" closeButtonAriaLabel="Cerrar"
                                                                                            >
                                                                                                Pulsa para editar la Cartera Modelo{" "}{modelWallet.name}
                                                                                            </TeachingBubble>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                    <CustomIcon iconName="Edit" size="17" color="#CC214F" paddingTop="10%"
                                                                                        onClick={() =>
                                                                                        {
                                                                                            setLoading(true);
                                                                                            getPortfolioModel(modelWallet.savingPlanId, modelWallet.id);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                {row.type !== educationalType &&
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <div id={"walletName"+index+"-"+subIndex+1+"-"+(elementIndex+2)} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                                            onMouseEnter={() => handleMouseEnter(index, elementIndex+2, subIndex+1)}
                                                                                            onMouseLeave={() => handleMouseLeave(index, elementIndex+2, subIndex+1)}
                                                                                        >
                                                                                            {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+2] && tooltipVisibilities[index][elementIndex+2][subIndex+1] &&
                                                                                            (
                                                                                                <React.Fragment>
                                                                                                    <TeachingBubble target={"#walletName"+index+"-"+subIndex+1+"-"+(elementIndex+2)} hasCloseButton={true}
                                                                                                        onDismiss={() => handleMouseLeave(index, elementIndex+2, subIndex+1)}
                                                                                                        headline="Renombrar Cartera Modelo"
                                                                                                        closeButtonAriaLabel="Cerrar"
                                                                                                    >
                                                                                                        Pulsa para renombrar la Cartera Modelo{" "} {modelWallet.name}
                                                                                                    </TeachingBubble>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                            <ModalChangePlanName name={modelWallet.name} paddingTopIcon={"9%"} isModelPortfolio={true}
                                                                                                onSubmit={(item) =>
                                                                                                {
                                                                                                    renamePortfolioModelWallet(modelWallet.savingPlanId, item, modelWallet.id);
                                                                                                    modelWallet.name = item;
                                                                                                    setData([...data]);
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {modelWallet.deletable === true &&
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <div id={"deletable"+index+"-"+subIndex+"-"+elementIndex+3} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                                            onMouseEnter={() => handleMouseEnter(index, elementIndex+3, subIndex+1)}
                                                                                            onMouseLeave={() => handleMouseLeave(index, elementIndex+3, subIndex+1)}
                                                                                        >
                                                                                            {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+3][subIndex+1] &&
                                                                                            (
                                                                                                <React.Fragment>
                                                                                                    <TeachingBubble target={"#deletable"+index+"-"+subIndex+1+"-"+elementIndex+3} hasCloseButton={true}
                                                                                                        onDismiss={() => handleMouseLeave(index, elementIndex+3, subIndex+1)}
                                                                                                        headline="Borrar Cartera Modelo" closeButtonAriaLabel="Cerrar"
                                                                                                    >
                                                                                                        Pulsa para borrar la Cartera Modelo
                                                                                                    </TeachingBubble>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                            <GenericMessageModal headerLabel={modelWallet.name} bodyLabel="¿Desea eliminar esta cartera modelo?"
                                                                                                    icon={<CustomIcon iconName="Delete" size="17" color="#CC214F" paddingTop="19%" />}
                                                                                                    onSubmit={(item) =>
                                                                                                    {
                                                                                                        if(item === true)
                                                                                                        {
                                                                                                            deletePortfolioModelWallet(modelWallet.savingPlanId, modelWallet.id);
                                                                                                            row.portfolioModels = row.portfolioModels?.filter((x) => x.id !== modelWallet.id);
                                                                                                            setData([...data]);
                                                                                                        }
                                                                                                    }}
                                                                                            />
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {modelWallet.deletable === false &&
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <div id={"notDeletable"+index+"-"+subIndex+1+"-"+elementIndex+3} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                                            onMouseEnter={() => handleMouseEnter(index, elementIndex+3, subIndex+1)}
                                                                                            onMouseLeave={() => handleMouseLeave(index, elementIndex+3, subIndex+1)}
                                                                                        >
                                                                                            {tooltipVisibilities[index] &&tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+3][subIndex+1] &&
                                                                                            (
                                                                                                <React.Fragment>
                                                                                                    <TeachingBubble target={"#notDeletable"+index+"-"+subIndex+1+"-"+elementIndex+3} hasCloseButton={true}
                                                                                                        onDismiss={() => handleMouseLeave(index, elementIndex+3, subIndex+1)}
                                                                                                        headline="Borrar Cartera Modelo" closeButtonAriaLabel="Cerrar"
                                                                                                    >
                                                                                                        Existen Aportaciones Periódicas asociadas pendientes... Para poder borrar 
                                                                                                        una Cartera Modelo no deben existir Aportaciones Periódicas.
                                                                                                    </TeachingBubble>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                            <CustomIcon iconName="Delete" size="17" color="black" paddingTop="19%" />
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>
                                                                </td> */}
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                    {row.status === 6 &&
                                    (
                                        <React.Fragment>
                                            {row.selected === true &&
                                            (
                                                <Style.SelectedTR theme={props.theme}>
                                                    <td onClick={() =>
                                                        {
                                                            row.isExpanded = !row.isExpanded;
                                                            setData([...data]);
                                                        }}
                                                    >
                                                        <CustomIcon iconName="BullseyeTargetEdit" size="15" color="#FF0000" fontWeight="bold"
                                                            paddingBottom="1px" verticalAlign="middle" cursor="default"
                                                        />
                                                        {displayArrow(row.isExpanded, row.portfolioModels)}
                                                        {row.planName}
                                                    </td>
                                                    <td>{row.startDate}</td>
                                                    <td>{getType(row.type)}</td>
                                                    <td id={"resuscribe-"+index} style={{textAlign: "center"}}>
                                                    {/* {tooltipsResuscribe[index] &&
                                                        (
                                                            <TeachingBubble target={"#resuscribe-"+index} hasCloseButton={true} headline="Vuelve a Suscribirte" onDismiss={(ev: any) => handleDismissTooltip(ev, index)}>
                                                                <Style.LinkBtn to={repaymentLink}>
                                                                    {resuscribeTooltip}
                                                                </Style.LinkBtn>
                                                            </TeachingBubble>
                                                        )} */}
                                                        <Style.LinkBtn to={repaymentLink}>
                                                            <Style.UnSelectedBtn theme={props.theme}>
                                                                Vuelve a Suscribirte
                                                            </Style.UnSelectedBtn>
                                                        </Style.LinkBtn>
                                                    </td>
                                                    {/*<td>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3">
                                                            </div>
                                                            <div id={"rename"+index+"-"+elementIndex+1} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" onMouseEnter={() => handleMouseEnter(index, elementIndex+1, 0)} onMouseLeave={() => handleMouseLeave(index, elementIndex+1, 0)}>
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+1] && tooltipVisibilities[index][elementIndex+1][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#rename"+index+"-"+elementIndex+1} hasCloseButton={true} onDismiss={() => handleMouseLeave(index, elementIndex+1, 0)} headline="Renombrar" closeButtonAriaLabel="Cerrar">
                                                                            Pulsa para renombrar este plan {row.planName}
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <ModalChangePlanName name={row.planName} onSubmit={item => updatePlanName(item, row.id)} />
                                                            </div>
                                                            <div id={"cartera"+index+"-"+(elementIndex+2)} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" onMouseEnter={() => handleMouseEnter(index, elementIndex+2, 0)} onMouseLeave={() => handleMouseLeave(index, elementIndex+2, 0)}>
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+2] && tooltipVisibilities[index][elementIndex+2][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#cartera"+index+"-"+(elementIndex+2)} hasCloseButton={true} onDismiss={() => handleMouseLeave(index, elementIndex+2, 0)} headline="Resumen de Cartera" closeButtonAriaLabel="Cerrar">
                                                                            Pulsa para ir a la Cartera
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <Style.LinkBtn to={{pathname: "/portfolio"}} onClick={event => handleClick(event, row.id, false)}>
                                                                    <CustomIcon iconName="ExploreData" size="17" paddingTop="12%" color="#CC214F" />
                                                                </Style.LinkBtn>
                                                            </div>
                                                            <div id={"map"+index+"-"+elementIndex+3} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" onMouseEnter={() => handleMouseEnter(index, elementIndex+3, 0)} onMouseLeave={() => handleMouseLeave(index, elementIndex+3, 0)}>
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+3][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#map"+index+"-"+elementIndex+3} hasCloseButton={true} onDismiss={() => handleMouseLeave(index, elementIndex+3, 0)} headline="Mapa de Configuración" closeButtonAriaLabel="Cerrar">
                                                                            Pulsa para acceder al Mapa de Configuración de este plan
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <CustomIcon iconName="Edit" size="17" color="#CC214F" paddingTop="10%" onClick={() => history.push(
                                                                    {
                                                                        pathname: `/configurationMap`,
                                                                        state:
                                                                        {
                                                                            savingPlanId: row.id,
                                                                            savingPlanName: row.planName,
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td> */}
                                                </Style.SelectedTR>
                                            )}
                                            {row.selected === false &&
                                            (
                                                <Style.NoActiveTR theme={props.theme}>
                                                    <td onClick={() =>
                                                        {
                                                            row.isExpanded = !row.isExpanded;
                                                            setData([...data]);
                                                        }}
                                                    >
                                                        {displayArrow(row.isExpanded, row.portfolioModels)}
                                                        {row.planName}
                                                    </td>
                                                    <td>{row.startDate}</td>
                                                    <td>{row.validDate}</td>
                                                    <td>{getType(row.type)}</td>
                                                    <td id={"resuscribe-"+index} style={{textAlign: "center"}}>
                                                        {/* {tooltipsResuscribe[index] &&
                                                        (
                                                            <TeachingBubble target={"#resuscribe-"+index} hasCloseButton={true} headline="Vuelve a Suscribirte"  onDismiss={(ev: any) => handleDismissTooltip(ev, index)}>
                                                                <Style.LinkBtn to={repaymentLink}>
                                                                    {resuscribeTooltip}
                                                                </Style.LinkBtn>
                                                            </TeachingBubble>
                                                        )} */}
                                                        <Style.LinkBtn to={repaymentLink}>
                                                            <Style.UnSelectedBtn theme={props.theme}>
                                                                Vuelve a Suscribirte
                                                            </Style.UnSelectedBtn>
                                                        </Style.LinkBtn>
                                                    </td>
                                                    {/*
                                                    <td>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3">
                                                            </div>
                                                            <div id={"rename"+index+"-"+elementIndex+1} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" onMouseEnter={() => handleMouseEnter(index, elementIndex+1, 0)} onMouseLeave={() => handleMouseLeave(index, elementIndex+1, 0)}>
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+1] && tooltipVisibilities[index][elementIndex+1][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#rename"+index+"-"+elementIndex+1} hasCloseButton={true} onDismiss={() => handleMouseLeave(index, elementIndex+1, 0)} headline="Renombrar" closeButtonAriaLabel="Cerrar">
                                                                            Pulsa para renombrar este plan {row.planName}
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <ModalChangePlanName name={row.planName} onSubmit={item => updatePlanName(item, row.id)} />
                                                            </div>
                                                            <div id={"cartera"+index+"-"+(elementIndex+2)} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" onMouseEnter={() => handleMouseEnter(index, elementIndex+2, 0)} onMouseLeave={() => handleMouseLeave(index, elementIndex+2, 0)}>
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+2] && tooltipVisibilities[index][elementIndex+2][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#cartera"+index+"-"+(elementIndex+2)} hasCloseButton={true} onDismiss={() => handleMouseLeave(index, elementIndex+2, 0)} headline="Resumen de Cartera" closeButtonAriaLabel="Cerrar">
                                                                            Pulsa para ir a la Cartera
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <Style.LinkBtn to={{pathname: "/portfolio"}} onClick={event => event.preventDefault()} style={{cursor: "not-allowed"}}>
                                                                    <CustomIcon iconName="ExploreData" size="17" paddingTop="12%" color="#CC214F" />
                                                                </Style.LinkBtn>
                                                            </div>
                                                            <div id={"map"+index+"-"+elementIndex+3} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" onMouseEnter={() => handleMouseEnter(index, elementIndex+3, 0)} onMouseLeave={() => handleMouseLeave(index, elementIndex+3, 0)}>
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+3][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#map"+index+"-"+elementIndex+3} hasCloseButton={true} onDismiss={() => handleMouseLeave(index, elementIndex+3, 0)} headline="Mapa de Configuración" closeButtonAriaLabel="Cerrar">
                                                                            Pulsa para acceder al Mapa de Configuración de este plan
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <CustomIcon iconName="Edit" size="17" color="#CC214F" paddingTop="10%" onClick={() => history.push(
                                                                    {
                                                                        pathname: `/configurationMap`,
                                                                        state:
                                                                        {
                                                                            savingPlanId: row.id,
                                                                            savingPlanName: row.planName,
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>*/}
                                                </Style.NoActiveTR>
                                            )}
                                            {row && row.portfolioModels && row.portfolioModels.map((modelWallet: PortfolioModelType, subIndex) =>
                                            {
                                                return(
                                                    <React.Fragment key={row.id+"_modelWalletRow_"+subIndex+"_"+modelWallet.id}>
                                                        {row.isExpanded &&
                                                        (
                                                            <tr>
                                                                <td colSpan={1} style={{paddingLeft: "50px", height: "50px", cursor: "default"}}>
                                                                    {modelWallet.name}
                                                                </td>
                                                                {/* <td>
                                                                    <div className="ms-Grid-row">
                                                                        {row.type !== educationalType &&
                                                                        (
                                                                            <React.Fragment>
                                                                                <div id={"period"+index+"-"+subIndex+1+"-"+elementIndex} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" style={{textAlign: "end"}}
                                                                                    onMouseEnter={() => handleMouseEnter(index, elementIndex, subIndex+1)}
                                                                                    onMouseLeave={() => handleMouseLeave(index, elementIndex, subIndex+1)}
                                                                                >
                                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex] && tooltipVisibilities[index][elementIndex][subIndex+1] &&
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <TeachingBubble target={"#period"+index+"-"+subIndex+1+"-"+elementIndex} hasCloseButton={true}
                                                                                            onDismiss={() => handleMouseLeave(index, elementIndex, subIndex+1)}
                                                                                            headline="Aportaciones Periódicas"
                                                                                            closeButtonAriaLabel="Cerrar"
                                                                                        >
                                                                                            Pulsa para acceder a las Aportaciones Periódicas que te permitirán invertir cantidades de forma periódica
                                                                                        </TeachingBubble>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                <Style.LinkBtn to={
                                                                                {
                                                                                    pathname: `/ModelPortfolio/PeriodicContributions/${modelWallet.id}`,
                                                                                    state:
                                                                                    {
                                                                                        portfolioModelId: modelWallet.id,
                                                                                        savingPlanId: modelWallet.savingPlanId,
                                                                                        portfolioModelName: modelWallet.name,
                                                                                        contract: modelWallet.contract,
                                                                                    }
                                                                                }}>
                                                                                    <CustomIcon iconName="CalendarSettingsMirrored" size="17" color="#CC214F" paddingTop="15%" />
                                                                                </Style.LinkBtn>
                                                                            </div>
                                                                            <div id={"modelWallet"+index+"-"+subIndex+1+"-"+elementIndex+1} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                                onMouseEnter={() => handleMouseEnter(index, elementIndex+1, subIndex+1)}
                                                                                onMouseLeave={() => handleMouseLeave(index, elementIndex+1, subIndex+1)}
                                                                            >
                                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+1] && tooltipVisibilities[index][elementIndex+1][subIndex+1] &&
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <TeachingBubble target={"#modelWallet"+index+"-"+subIndex+1+"-"+elementIndex+1} hasCloseButton={true}
                                                                                            onDismiss={() => handleMouseLeave(index, elementIndex+1, subIndex+1)}
                                                                                            headline="Editar Cartera Modelo" closeButtonAriaLabel="Cerrar"
                                                                                        >
                                                                                            Pulsa para editar la Cartera Modelo{" "}{modelWallet.name}
                                                                                        </TeachingBubble>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                <CustomIcon iconName="Edit" size="17" color="#CC214F" paddingTop="10%" onClick={() =>
                                                                                    {
                                                                                        setLoading(true);
                                                                                        getPortfolioModel(modelWallet.savingPlanId, modelWallet.id);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {row.type !== educationalType &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    <div id={"walletName"+index+"-"+subIndex+1+"-"+(elementIndex+2)} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                                        onMouseEnter={() => handleMouseEnter(index, elementIndex+2, subIndex+1)}
                                                                                        onMouseLeave={() => handleMouseLeave(index, elementIndex+2, subIndex+1)}
                                                                                    >
                                                                                        {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+2] && tooltipVisibilities[index][elementIndex+2][subIndex+1] &&
                                                                                        (
                                                                                            <React.Fragment>
                                                                                                <TeachingBubble target={"#walletName"+index+"-"+subIndex+1+"-"+(elementIndex+2)} hasCloseButton={true}
                                                                                                    onDismiss={() => handleMouseLeave(index, elementIndex+2, subIndex+1)}
                                                                                                    headline="RenombrarCarteraModelo" closeButtonAriaLabel="Cerrar"
                                                                                                >
                                                                                                    Pulsa para renombrar la Cartera Modelo {" "} {modelWallet.name}
                                                                                                </TeachingBubble>
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                        <ModalChangePlanName name={modelWallet.name} paddingTopIcon={"9%"} isModelPortfolio={true} onSubmit={(item) =>
                                                                                            {
                                                                                                renamePortfolioModelWallet(modelWallet.savingPlanId, item, modelWallet.id);
                                                                                                modelWallet.name = item;
                                                                                                setData([...data]);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {modelWallet.deletable === true &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    <div id={"deletable"+index+"-"+subIndex+"-"+elementIndex+3} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                                        onMouseEnter={() => handleMouseEnter(index, elementIndex+3, subIndex+1)}
                                                                                        onMouseLeave={() => handleMouseLeave(index, elementIndex+3, subIndex+1)}
                                                                                    >
                                                                                    {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+3][subIndex+1] &&
                                                                                    (
                                                                                        <React.Fragment>
                                                                                            <TeachingBubble target={"#deletable"+index+"-"+subIndex+1+"-"+elementIndex+3} hasCloseButton={true}
                                                                                                onDismiss={() => handleMouseLeave(index, elementIndex+3, subIndex+1)}
                                                                                                headline="Borrar Cartera Modelo"closeButtonAriaLabel="Cerrar"
                                                                                            >
                                                                                                Pulsa para borrar la Cartera Modelo
                                                                                            </TeachingBubble>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                    <GenericMessageModal headerLabel={modelWallet.name} bodyLabel="¿Desea eliminar esta cartera modelo?"
                                                                                        icon={<CustomIcon iconName="Delete" size="17" color="#CC214F" paddingTop="19%" />}
                                                                                        onSubmit={(item) =>
                                                                                        {
                                                                                            if(item === true)
                                                                                            {
                                                                                                deletePortfolioModelWallet(modelWallet.savingPlanId, modelWallet.id);
                                                                                                row.portfolioModels = row.portfolioModels?.filter((x) => x.id !== modelWallet.id);
                                                                                                setData([...data]);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {modelWallet.deletable === false &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    <div id={"notDeletable"+index+"-"+subIndex+1+"-"+elementIndex+3} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                                        onMouseEnter={() => handleMouseEnter(index, elementIndex+3, subIndex+1)}
                                                                                        onMouseLeave={() => handleMouseLeave(index, elementIndex+3, subIndex+1)}
                                                                                    >
                                                                                        {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+3][subIndex+1] &&
                                                                                        (
                                                                                            <React.Fragment>
                                                                                                <TeachingBubble target={"#notDeletable"+index+"-"+subIndex+1+"-"+elementIndex+3} hasCloseButton={true}
                                                                                                    onDismiss={() => handleMouseLeave(index, elementIndex+3, subIndex+1)}
                                                                                                    headline="Borrar Cartera Modelo"
                                                                                                    closeButtonAriaLabel="Cerrar"
                                                                                                >
                                                                                                    Existen Aportaciones Periódicas asociadas pendientes... Para poder borrar una Cartera Modelo no deben existir Aportaciones Periódicas.
                                                                                                </TeachingBubble>
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                        <CustomIcon iconName="Delete" size="17" color="black" paddingTop="19%" />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </React.Fragment>)}
                                                                    </div>
                                                                </td>*/}
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                    {row.status === 8 &&
                                    (
                                        <React.Fragment>
                                            {row.selected === true &&
                                            (
                                                <Style.SelectedTR theme={props.theme}>
                                                    <td onClick={() =>
                                                        {
                                                            row.isExpanded = !row.isExpanded;
                                                            setData([...data]);
                                                        }}
                                                    >
                                                        <CustomIcon iconName="BullseyeTargetEdit" size="15" color="#FF0000" fontWeight="bold" paddingBottom="1px" verticalAlign="middle" cursor="default" />
                                                        {displayArrow(row.isExpanded, row.portfolioModels)}
                                                        {row.planName}
                                                    </td>
                                                    <td>{row.startDate}</td>
                                                    <td>Documentación caducada</td>
                                                    <td>{row.validDate}</td>
                                                    <td>{getType(row.type)}</td>
                                                    <td style={{textAlign: "center"}}>
                                                        <Style.SelectedBtn theme={props.theme}>
                                                            Actualiza tu Documentacion
                                                        </Style.SelectedBtn>
                                                    </td>
                                                    <td>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"></div>
                                                            <div id={"rename"+index+"-"+elementIndex+1} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                onMouseEnter={() => handleMouseEnter(index, elementIndex+1, 0)}
                                                                onMouseLeave={() => handleMouseLeave(index, elementIndex+1, 0)}
                                                            >
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+1] && tooltipVisibilities[index][elementIndex+1][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#rename"+index+"-"+elementIndex+1} hasCloseButton={true}
                                                                            onDismiss={() => handleMouseLeave(index, elementIndex+1, 0)}
                                                                            headline="Renombrar" closeButtonAriaLabel="Cerrar"
                                                                        >
                                                                            Pulsa para renombrar este plan {row.planName}
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <ModalChangePlanName name={row.planName} onSubmit={(item) => updatePlanName(item, row.id)} />
                                                            </div>
                                                            <div id={"cartera"+index+"-"+(elementIndex+2)} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                onMouseEnter={() => handleMouseEnter(index, elementIndex+2, 0)}
                                                                onMouseLeave={() => handleMouseLeave(index, elementIndex+2, 0)}
                                                            >
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+2] && tooltipVisibilities[index][elementIndex+2][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#cartera"+index+"-"+(elementIndex+2)} hasCloseButton={true}
                                                                            onDismiss={() => handleMouseLeave(index, elementIndex+2, 0)}
                                                                            headline="Resumen de Cartera"
                                                                            closeButtonAriaLabel="Cerrar"
                                                                        >
                                                                            Pulsa para ir a la Cartera
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <Style.LinkBtn to={{pathname: "/portfolio"}} onClick={(event) => event.preventDefault()} style={{cursor: "not-allowed"}}>
                                                                    <CustomIcon iconName="ExploreData" size="17" color="#CC214F" />
                                                                </Style.LinkBtn>
                                                            </div>
                                                            <div id={"map"+index+"-"+elementIndex+3} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                onMouseEnter={() => handleMouseEnter(index, elementIndex+3, 0)}
                                                                onMouseLeave={() => handleMouseLeave(index, elementIndex+3, 0)}
                                                            >
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+2][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#map"+index+"-"+elementIndex+3} hasCloseButton={true}
                                                                            onDismiss={() => handleMouseLeave(index, elementIndex+3, 0)}
                                                                            headline="Mapa de Configuración" closeButtonAriaLabel="Cerrar"
                                                                        >
                                                                            Pulsa para acceder al Mapa de Configuración de este plan
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <CustomIcon iconName="Edit" size="17" color="#CC214F" paddingTop="10%"
                                                                    onClick={() =>
                                                                    {
                                                                        history.push(
                                                                        {
                                                                            pathname: `/configurationMap`,
                                                                            state:
                                                                            {
                                                                                savingPlanId: row.id,
                                                                                savingPlanName: row.planName,
                                                                            },
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </Style.SelectedTR>
                                            )}
                                            {row.selected === false &&
                                            (
                                                <Style.OutdatedTR theme={props.theme}>
                                                    <td onClick={() =>
                                                        {
                                                            row.isExpanded = !row.isExpanded;
                                                            setData([...data]);
                                                        }}
                                                    >
                                                        {displayArrow(row.isExpanded, row.portfolioModels)}
                                                        {row.planName}
                                                    </td>
                                                    <td>{row.startDate}</td>
                                                    <td>Documentacion caducada</td>
                                                    <td>{getType(row.type)}</td>
                                                    <td style={{textAlign: "center"}}>
                                                        <Style.OutdatedBtn theme={props.theme}>
                                                            Actualiza tu Documentacion
                                                        </Style.OutdatedBtn>
                                                    </td>
                                                    <td>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"></div>
                                                            <div id={"rename"+index+"-"+elementIndex+1} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                onMouseEnter={() => handleMouseEnter(index, elementIndex+1, 0)}
                                                                onMouseLeave={() => handleMouseLeave(index, elementIndex+1, 0)}
                                                            >
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+1] && tooltipVisibilities[index][elementIndex+1][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#rename"+index+"-"+elementIndex+1}
                                                                            hasCloseButton={true}
                                                                            onDismiss={() => handleMouseLeave(index, elementIndex+1, 0)}
                                                                            headline="Renombrar"
                                                                            closeButtonAriaLabel="Cerrar"
                                                                        >
                                                                            Pulsa para renombrar este plan {row.planName}
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <ModalChangePlanName name={row.planName} onSubmit={(item) => updatePlanName(item, row.id)} />
                                                            </div>
                                                            <div id={"cartera" + index + "-" + (elementIndex+2)} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                onMouseEnter={() => handleMouseEnter(index, elementIndex+2, 0)}
                                                                onMouseLeave={() => handleMouseLeave(index, elementIndex+2, 0)}
                                                            >
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+2] && tooltipVisibilities[index][elementIndex+2][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#cartera"+index+"-"+(elementIndex+2)} hasCloseButton={true}
                                                                            onDismiss={() => handleMouseLeave(index, elementIndex+2, 0)}
                                                                            headline="Resumen de Cartera" closeButtonAriaLabel="Cerrar"
                                                                        >
                                                                            Pulsa para ir a la Cartera
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <Style.LinkBtn to={{pathname: "/portfolio"}} onClick={(event) => event.preventDefault()} style={{cursor: "not-allowed"}}>
                                                                    <CustomIcon iconName="ExploreData" size="17" color="#CC214F" />
                                                                </Style.LinkBtn>
                                                            </div>
                                                            <div id={"map"+index+"-"+elementIndex+3} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                onMouseEnter={() => handleMouseEnter(index, elementIndex+3, 0)}
                                                                onMouseLeave={() => handleMouseLeave(index, elementIndex+3, 0)}
                                                            >
                                                                {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+3][0] &&
                                                                (
                                                                    <React.Fragment>
                                                                        <TeachingBubble target={"#map"+index+"-"+elementIndex+3} hasCloseButton={true}
                                                                            onDismiss={() => handleMouseLeave(index, elementIndex+3, 0)}
                                                                            headline="Mapa de Configuración" closeButtonAriaLabel="Cerrar"
                                                                        >
                                                                            Pulsa para acceder al Mapa de Configuración de este plan
                                                                        </TeachingBubble>
                                                                    </React.Fragment>
                                                                )}
                                                                <CustomIcon iconName="Edit" size="17" color="#CC214F" paddingTop="10%" onClick={() =>
                                                                    {
                                                                        history.push(
                                                                        {
                                                                            pathname: `/configurationMap`,
                                                                            state:
                                                                            {
                                                                                savingPlanId: row.id,
                                                                                savingPlanName: row.planName,
                                                                            },
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </Style.OutdatedTR>
                                            )}
                                            {row && row.portfolioModels && row.portfolioModels.map((modelWallet: PortfolioModelType, subIndex) =>
                                            {
                                                return(
                                                    <React.Fragment key={row.id+"_modelWalletRow_"+subIndex+"_"+modelWallet.id}>
                                                        {row.isExpanded &&
                                                        (
                                                            <tr>
                                                                <td colSpan={5} style={{paddingLeft: "50px", height: "50px", cursor: "default"}}>
                                                                    {modelWallet.name}
                                                                </td>
                                                                <td>
                                                                    <div className="ms-Grid-row">
                                                                        {row.type !== educationalType &&
                                                                        (
                                                                            <React.Fragment>
                                                                                <div id={"period"+index+"-"+subIndex+1+"-"+elementIndex} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                                    style={{textAlign: "end"}}
                                                                                    onMouseEnter={() => handleMouseEnter(index, elementIndex, subIndex+1)}
                                                                                    onMouseLeave={() => handleMouseLeave(index, elementIndex, subIndex+1)}
                                                                                >
                                                                                    {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex] && tooltipVisibilities[index][elementIndex][subIndex+1] &&
                                                                                    (
                                                                                        <React.Fragment>
                                                                                            <TeachingBubble target={"#period"+index+"-"+subIndex+1+"-"+elementIndex} hasCloseButton={true}
                                                                                                onDismiss={() => handleMouseLeave(index, elementIndex, subIndex+1)}
                                                                                                headline="Aportaciones Periódicas"
                                                                                                closeButtonAriaLabel="Cerrar"
                                                                                            >
                                                                                                Pulsa para acceder a las Aportaciones Periódicas que te permitirán invertir cantidades de forma periódica
                                                                                            </TeachingBubble>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                    <Style.LinkBtn to={
                                                                                        {
                                                                                            pathname: `/ModelPortfolio/PeriodicContributions/${modelWallet.id}`,
                                                                                            state:
                                                                                            {
                                                                                                portfolioModelId: modelWallet.id,
                                                                                                savingPlanId: modelWallet.savingPlanId,
                                                                                                portfolioModelName: modelWallet.name,
                                                                                                contract: modelWallet.contract,
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <CustomIcon iconName="CalendarSettingsMirrored" size="17" color="#CC214F" paddingTop="15%" />
                                                                                    </Style.LinkBtn>
                                                                                </div>
                                                                                <div id={"modelWallet"+index+"-"+subIndex+1+"-"+elementIndex+1} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                                    onMouseEnter={() => handleMouseEnter(index, elementIndex+1, subIndex+1)}
                                                                                    onMouseLeave={() => handleMouseLeave(index, elementIndex+1, subIndex+1)}
                                                                                >
                                                                                    {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+1] && tooltipVisibilities[index][elementIndex+1][subIndex+1] &&
                                                                                    (
                                                                                        <React.Fragment>
                                                                                            <TeachingBubble target={"#modelWallet"+index+"-"+subIndex+1+"-"+(elementIndex+1)} hasCloseButton={true}
                                                                                                onDismiss={() => handleMouseLeave(index, elementIndex+1, subIndex+1)}
                                                                                                headline="Editar Cartera Modelo" closeButtonAriaLabel="Cerrar"
                                                                                            >
                                                                                                Pulsa para editar la Cartera Modelo {modelWallet.name}
                                                                                            </TeachingBubble>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                    <CustomIcon iconName="Edit" size="17" color="#CC214F" paddingTop="10%"
                                                                                        onClick={() =>
                                                                                        {
                                                                                            setLoading(true);
                                                                                            getPortfolioModel(modelWallet.savingPlanId, modelWallet.id);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                {row.type !== educationalType &&
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <div id={"walletName"+index+"-"+subIndex+1+"-"+(elementIndex+2)} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                                            onMouseEnter={() => handleMouseEnter(index, elementIndex+2, subIndex+1)}
                                                                                            onMouseLeave={() => handleMouseLeave(index, elementIndex+2, subIndex+1)}
                                                                                        >
                                                                                            {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+2] && tooltipVisibilities[index][elementIndex+2][subIndex+1] &&
                                                                                            (
                                                                                                <React.Fragment>
                                                                                                    <TeachingBubble target={"#walletName"+index+"-"+subIndex+1+"-"+(elementIndex+2)} hasCloseButton={true}
                                                                                                        onDismiss={() => handleMouseLeave(index, elementIndex+2, subIndex+1)}
                                                                                                        headline="Renombrar Cartera Modelo" closeButtonAriaLabel="Cerrar"
                                                                                                    >
                                                                                                        Pulsa para renombrar la Cartera Modelo {modelWallet.name}
                                                                                                    </TeachingBubble>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                            <ModalChangePlanName name={modelWallet.name} paddingTopIcon={"9%"} isModelPortfolio={true}
                                                                                                onSubmit={(item) =>
                                                                                                {
                                                                                                    renamePortfolioModelWallet(modelWallet.savingPlanId, item, modelWallet.id);
                                                                                                    modelWallet.name = item;
                                                                                                    setData([...data]);
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {modelWallet.deletable === true &&
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <div id={"deletable"+index+"-"+subIndex+"-"+elementIndex+3} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                                            onMouseEnter={() => handleMouseEnter(index, elementIndex+3, subIndex+1)}
                                                                                            onMouseLeave={() => handleMouseLeave(index, elementIndex+3, subIndex+1)}
                                                                                        >
                                                                                            {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+3][subIndex+1] &&
                                                                                            (
                                                                                                <React.Fragment>
                                                                                                    <TeachingBubble target={"#deletable"+index+"-"+subIndex+1+"-"+elementIndex+3} hasCloseButton={true}
                                                                                                        onDismiss={() => handleMouseLeave(index, elementIndex+3, subIndex+1)}
                                                                                                        headline="Borrar Cartera Modelo" closeButtonAriaLabel="Cerrar"
                                                                                                    >
                                                                                                        Pulsa para borrar la Cartera Modelo
                                                                                                    </TeachingBubble>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                            <GenericMessageModal headerLabel={modelWallet.name} bodyLabel="¿Desea eliminar esta cartera modelo?"
                                                                                                icon={<CustomIcon iconName="Delete" size="17" color="#CC214F" paddingTop="19%" />}
                                                                                                onSubmit={(item) =>
                                                                                                {
                                                                                                    if(item === true)
                                                                                                    {
                                                                                                        deletePortfolioModelWallet(modelWallet.savingPlanId, modelWallet.id);
                                                                                                        row.portfolioModels = row.portfolioModels?.filter((x) => x.id !== modelWallet.id);
                                                                                                        setData([...data]);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {modelWallet.deletable === false &&
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <div id={"notDeletable"+index+"-"+subIndex+1+"-"+elementIndex+3} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3"
                                                                                            onMouseEnter={() => handleMouseEnter(index, elementIndex+3, subIndex+1)}
                                                                                            onMouseLeave={() => handleMouseLeave(index, elementIndex+3, subIndex+1)}
                                                                                        >
                                                                                            {tooltipVisibilities[index] && tooltipVisibilities[index][elementIndex+3] && tooltipVisibilities[index][elementIndex+3][subIndex+1] &&
                                                                                            (
                                                                                                <React.Fragment>
                                                                                                    <TeachingBubble target={"#notDeletable"+index+"-"+subIndex+1+"-"+elementIndex+3} hasCloseButton={true}
                                                                                                        onDismiss={() => handleMouseLeave(index, elementIndex+3, subIndex+1)}
                                                                                                        headline="Borrar Cartera Modelo" closeButtonAriaLabel="Cerrar"
                                                                                                    >
                                                                                                        Existen Aportaciones Periódicas asociadas pendientes... Para poder borrar una Cartera Modelo no deben existir Aportaciones Periódicas.
                                                                                                    </TeachingBubble>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                            <CustomIcon iconName="Delete" size="17" color="black" paddingTop="19%" />  
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </Style.Table>
            )}
        </React.Fragment>
    );
};

export default TableActiveSavingPlans;
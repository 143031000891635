import { CommandBar, DefaultButton, IButtonProps, ICommandBarItemProps, IDropdownOption, TeachingBubble } from "@fluentui/react";
import React, { ReactElement } from "react";
import { useHistory } from "react-router";
import styles from './index.module.sass'
import { useBoolean } from "@fluentui/react-hooks";

const _overflowItems: ICommandBarItemProps[] = [];
const overflowProps: IButtonProps = {ariaLabel: 'Más acciones'};

export default function PortfolioActions({
    isLoadingChange,
    isLoadedSeries,
    isLoadedFunds,
    options,
    isElementsSubscribed,
    showElementsButton,
    updateDataOnClickHandler,
    addPortfolioDataToCart,
    editPortfolio,
    checkPortfolioModelLogic,
    noFundsInPortfolio,
    hasFunds,
    type}:
{
    isLoadingChange: boolean,
    isLoadedSeries: boolean,
    isLoadedFunds: boolean,
    options: IDropdownOption[] | undefined,
    isElementsSubscribed: boolean | undefined,
    showElementsButton: boolean,
    updateDataOnClickHandler: () => void,
    addPortfolioDataToCart: () => void,
    editPortfolio: () => void,
    checkPortfolioModelLogic: () => void,
    noFundsInPortfolio: () => void,
    hasFunds: boolean,
    type: any
}): ReactElement
{
    const history = useHistory();
    const [tooltipToggle, { setTrue: showTooltip, setFalse: hideTooltip }] = useBoolean(false);
    const items =
    [
        {
            key: 'Actualizar',
            text: options !== undefined && options.length > 1
                ? (isLoadingChange === false && isLoadedSeries && isLoadedFunds
                    ? 'Actualizar'
                    : 'Actualizando...')
                : '',
            onClick: () => updateDataOnClickHandler(),
            className: options !== undefined && options.length > 1
                ? (isLoadingChange === false && isLoadedSeries && isLoadedFunds
                    ? 'button mini-primary-button'
                    : 'loading')
                : 'hidden'
        },
        {
            key: 'Elements',
            text: isLoadingChange ? 'Actualizando...' : 'Elements',
            onClick: () => history.push('/Elements'),
            className: showElementsButton && isElementsSubscribed !== undefined && isElementsSubscribed === true && !isLoadingChange
                ? 'button mini-primary-button'
                : 'hidden'
        },
        {
            key: 'item2',
            text: isLoadingChange ? 'Actualizando...' : 'Suscríbete a Elements',
            onClick: () => history.push('/Elements/Signature'),
            className: showElementsButton && (isElementsSubscribed === undefined || isElementsSubscribed === false) && !isLoadingChange
                ? 'button mini-primary-button'
                : 'hidden'
        },
        {
            key: 'Cartera Modelo',
            text: type !== undefined && type === 0 ? 'Aportación Periódica' : 'Cartera Modelo',
            className: 'button mini-primary-button',
            onClick: () =>
            {
                if(isLoadedFunds && isLoadedSeries)
                {
                    if(type !== undefined && type === 0)
                    {
                        if(hasFunds)
                        {
                            checkPortfolioModelLogic();
                        }
                        else
                        {
                            noFundsInPortfolio();
                        }
                    }
                    else
                    {
                        addPortfolioDataToCart();
                    }
                }
            }
        },
        {
            key: 'Editar cartera',
            iconProps: { iconName: 'Edit' },
            text: '',
            id: 'tooltipToggle',
            className: `${styles.onlyButton} button mini-primary-button`,
            onClick: () => editPortfolio(),
            onMouseEnter: () => showTooltip(),
            onMouseLeave: () => hideTooltip()
        }
    ];

    const CustomButton: React.FunctionComponent<IButtonProps> = props =>
    {
        return <DefaultButton {...props} />;
    };

    return(
        <React.Fragment>
            <CommandBar
                className={styles.actions}
                items={items}
                buttonAs={CustomButton}
                overflowItems={_overflowItems}
                overflowButtonProps={overflowProps}
                ariaLabel="Usa las flechas arriba y abajo para navegar entre las entradas del menú"
            />
            {tooltipToggle === true &&
            (
                <TeachingBubble target="#tooltipToggle" onDismiss={hideTooltip} hasCloseButton={false}>
                    Editar Plan de Cartera
                </TeachingBubble>
            )}
        </React.Fragment>
    )
}
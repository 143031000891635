import React from "react";
import  { OrderTrendByFund } from "../../../services/OrderService";
import { ThemeContext } from '@fluentui/react-theme-provider';
import styled from "styled-components";
import { ChevronDownIcon, ChevronRightIcon, SkypeMinusIcon } from "@fluentui/react-icons-mdl2";
import { ChildTable } from "../ChildTable";
import { Link } from "react-router-dom";
import { ReactComponent as IncrementIcon } from '../../../images/increment.svg';
import { ReactComponent as DecrementIcon } from '../../../images/decrement.svg';
import { tendencyOperation } from "../Table";

type Props =
{
    fund: OrderTrendByFund,
    index: number
};

const TableBody = (props: Props) =>
{
	const { fund, index } = props;
	const theme = React.useContext(ThemeContext);
    const [isExpanded, setIsExpanded] = React.useState(false);
	
	const TendIcon = (compra: number | undefined, transpasoOrigen: number | undefined, traspasoDestino: number | undefined, reembolso: number | undefined) => {
		if(!compra)
        {
			compra = 0
		}
		if(!transpasoOrigen)
        {
			transpasoOrigen = 0
		}
		if(!traspasoDestino)
        {
			traspasoDestino = 0
		}
		if(!reembolso)
        {
			reembolso = 0
		}
		
		let tendency = tendencyOperation(compra, transpasoOrigen, traspasoDestino, reembolso);
			
		if(tendency < 0)
        {
			return <><DecrementIcon /></>
		}
        else if(tendency > 0 )
        {
			return <><IncrementIcon /></>
		}
        else
        {
			return <><SkypeMinusIcon style={{  color: "coral" }}  /></>
		}
	}

	return(
		<React.Fragment key={index}>
			<tr>
				<td>
					<div style={{width: "40%", textAlign: "left"}}>
						<FundName className="max-content">
							<button style={{color: theme!.palette.black}} className="expanded" onClick={() => setIsExpanded(!isExpanded)}>
								{fund && (isExpanded ? <ChevronDownIcon className="brothers-btn-icon" /> : <ChevronRightIcon className="brothers-btn-icon" />)}
							</button>
							<Link to={{pathname: `/${fund.isin}`}}>
								{fund.fondo}
							</Link>
						</FundName>
					</div>
				</td>
				<td style={{ width: "12%", textAlign: "right" }}>{TendIcon(fund.semanaCompras, fund.semanaTraspasosOrigen, fund.semanaTraspasosDestino, fund.semanaReembolsos)}</td>
				<td style={{ width: "12%", textAlign: "right" }}>{TendIcon(fund.mesCompras, fund.mesTraspasosOrigen, fund.mesTraspasosDestino, fund.mesReembolsos)}</td>
				<td style={{ width: "12%", textAlign: "right" }}>{TendIcon(fund.trimestreCompras, fund.trimestreTraspasosOrigen, fund.trimestreTraspasosDestino, fund.trimestreReembolsos)}</td>
				<td style={{ width: "12%", textAlign: "right" }}>{TendIcon(fund.añoCompras, fund.añoTraspasosOrigen, fund.añoTraspasosDestino, fund.añoReembolsos)}</td>
				<td style={{ width: "12%", textAlign: "right" }}>{TendIcon(fund.compras, fund.traspasosOrigen, fund.traspasosDestino, fund.reembolsos)}</td>
			</tr>
			{isExpanded && <ChildTable fund={fund} index={"sub-table-" + index} />}
		</React.Fragment>
	);
};

export default TableBody;

const FundName = styled.div`
    .brothers-btn-icon
    {
        margin-right: 5px;
        vertical-align: baseline;
    }
`;
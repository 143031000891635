import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import OrderService, { ExternalSwitchValuesType, OrderOrderType, OrderType, OrderValuesType } from '../../../../services/OrderService';
import fundService, { AvailableFundType, FundType, SearchFundsMinType } from '../../../../services/FundService';
import FinametrixService from '../../../../services/FinametrixService';
import DocumentTitle from 'react-document-title';
import { InvestmentFundFinder } from '../../../PurchaseTransferScreen/components/InvestmentFundFinder';
import { useBoolean } from '@fluentui/react-hooks';
import { useForm, Controller } from "react-hook-form";
import { DefaultButton, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { RadioBtnOffIcon, RadioBtnOnIcon } from '@fluentui/react-icons-mdl2';
import { IDataCart, SignatureComponent } from '../../../../components/Signature/SignatureComponent';
import service, { ISignature } from '../../../../services/SignaturesService';
import Auth from '../../../../Auth/Auth';
import moment from 'moment';
import FundService from '../../../../services/FundService';
import FnmContractComplexProductService, { FnmContractComplexProductUserIdentityAgreementType } from '../../../../services/FnmContractComplexProductService';
import UserIdentitiesService, { UserIdentityType } from '../../../../services/UserIdentitiesService';
import { ModalRefund } from '../../../Refunds/components/ModalRefund';
import './OrderIncidence.sass';
import { currencyFormatter } from '../../../../utils/numberFormatter';

const OrderIncidence = () => {
    const { finametrixId } = useParams<any>();
    const history = useHistory();
    const { handleSubmit, formState: { errors }, control, setValue, setError, clearErrors, watch } = useForm();
    const processHours: any = Auth.getProcessHours();
    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState<OrderType>();
    const [sourceFundName, setSourceFundName] = useState<string>();
    const [sourceFundIsin, setSourceFundIsin] = useState<string>();
    const [fundSelector, { setTrue: setFundSelector, setFalse: setRefund }] = useBoolean(true);
    const [sending, setSending] = useState<boolean>(false);
    const [signature, setSignature] = useState<ISignature>();
    const [newOrder, setNewOrder] = useState<OrderType>();
    const [refundCodeIsDefined, setRefundCodeIsDefined] = useState<boolean>();
    const [dataFund, setDataFund] = useState<IDataCart>();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userConfirmation, setUserConfirmation] = useState({ user: false, disclaimer: false });
    const [refundCode, setRefundCode] = useState<any>("");
    const [complexProductAgreements, setComplexProductAgreements] = useState<FnmContractComplexProductUserIdentityAgreementType[]>([]);
    const [serviceError, setServiceError] = useState({ status: false, message: "" });
    const [isValidatingTargetFund, setIsValidatingTargetFund] = useState(false);
    const patternIBAN = "(([E+S]|[e+s]){2})\t*(\\d{2})\\s*\t*(\\d{4})\\s*\t*(\\d{4})\\s*\t*(\\d{2})\\s*\t*(\\d{10})";
    const watchTargetFund = watch("targetFund");
    const [initialTargetFund, setInitialTargetFund] = useState<any>();
    const [userIdentityInstrumentsIds, setUserIdentityInstrumentsIds] = useState<string[]>([]);
    const [isUserIdentityInstrumentIdsLoaded, setIsUserIdentityInstrumentIdsLoaded] = useState(false);
    let user = Auth.getUserProfile();

    useEffect(() => {
        let initialIsin = '';
        if (typeof window !== "undefined") {
            const query = new URLSearchParams(window.location.search);
            initialIsin = query.get('isin') ?? '';
            if (initialIsin !== '') {
                completeInitialTargetFund(initialIsin);
            }
        }
    }, []);

    const completeInitialTargetFund = useCallback(async (initialIsin) => {
        var defaultTargetFund = await fundService.getAvailableFunds(initialIsin).then((data: AvailableFundType[]) => data).catch(err => []);
        setInitialTargetFund(defaultTargetFund[0]);
        setValue('targetFund', defaultTargetFund[0]);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        OrderService.getOrderByFinametrixId(finametrixId)
            .then((data: OrderType) => {
                if (!data.incidence) {
                    setServiceError({
                        status: true,
                        message: "La orden no tiene ninguna incidencia"
                    });
                }
                setOrder(data);
            },
            (error) => {
                setServiceError(error);
            })
    }, []);

    useEffect(() => {
        if (order  !== undefined) {
            switch (order.orderType) {
                case OrderOrderType.Subscription:
                    if (order.instrumentId !== undefined) {
                        FinametrixService.getInstrument(order.instrumentId)
                            .then(
                                (data: FundType) => {
                                    setSourceFundName(data.name);
                                    setSourceFundIsin(data.isin);
                                },
                                (error) => setServiceError(error)
                            )
                            .finally(() => setIsLoading(false));
                    }
                    break;

                case OrderOrderType.InternalSwitch:
                    if (order.instrumentId !== undefined) {
                        FinametrixService.getInstrument(order.instrumentId)
                            .then(
                                (data: FundType) => {
                                    setSourceFundName(data.name);
                                    setSourceFundIsin(data.isin);
                                },
                                (error) => setServiceError(error)
                            )
                            .finally(() => setIsLoading(false));
                    }
                    break;

                case OrderOrderType.ExternalSwitch:
                    if (order.values !== undefined) {
                        const values: ExternalSwitchValuesType = order.values as ExternalSwitchValuesType;
                        if (values.inceptionFund.isin !== undefined) {
                            FundService.getFundsNameByIsin([values.inceptionFund.isin])
                                .then(
                                    (data: SearchFundsMinType[]) => {
                                        setSourceFundName(values.inceptionFund.name);
                                        setSourceFundIsin(values.inceptionFund.isin);
                                    },
                                    (error) => setServiceError(error)
                                )
                                .finally(() => setIsLoading(false));
                        }
                    }
                    break;
            }
        }
    }, [order]);

    const onSubmit = async (data: any) => {
        if (order !== undefined) {
            if (fundSelector) {
                if (data.targetFund?.finametrixId === undefined) {
                    setError("targetFund",
                        {
                            type: "invalidTargetFund",
                            message: "El fondo de destino no es válido"
                        });
                    return false;
                }
            }
            else {
                if (data.iban === undefined) {
                    setError("iban",
                        {
                            type: "invalidIban",
                            message: "El IBAN no es válido"
                        });
                    return false;
                }
                else {
                    let newReg = new RegExp(patternIBAN);
                    if (!data.iban?.match(newReg)) {
                        setError("iban",
                            {
                                type: "invalidIbanFormat",
                                message: "El IBAN no tiene un formato válido"
                            });
                        return false;
                    }
                }

                if (data.ibanConfirm === undefined) {
                    setError("ibanConfirm",
                        {
                            type: "invalidIban",
                            message: "El IBAN no es válido"
                        });
                    return false;
                }

                if (data.iban !== undefined && data.ibanConfirm !== undefined && data.iban !== data.ibanConfirm) {
                    setError("ibanConfirm",
                        {
                            type: "invalidIbanConfirm",
                            message: "El IBAN de confirmación no coincide con el IBAN"
                        });
                    return false;
                }

                if (data.refundKey === undefined) {
                    setError("refundKey",
                        {
                            type: "refundKey",
                            message: "El código de reembolso no es válido"
                        });
                    return false;
                }
            }

            let newOrder: any = {
                userIdentityId: order.userIdentityId,
                fnmPortfolioId: order.fnmPortfolioId,
                status: 0,
                orderType: 5,
                values: {
                    fixedOrder: order.id,
                    fixedOrderType: order.orderType,
                    fixedOrderFinametrixId: order.finametrixId
                }
            };

            if (fundSelector) {
                newOrder.instrumentId = data.targetFund.finametrixId;
            } else {
                newOrder.values.refundCode = data.refundKey;
                newOrder.values.iban = data.iban;
            }

            setSending(true);
            OrderService.createOrder(newOrder)
                .then((response: OrderType) => {
                    if (response.id) {
                        setNewOrder(response);
                        setSignature(response.signature);
                    }
                })
                .catch((error) => setServiceError(error))
                .finally(() => setSending(false));
        }
    };

    const processExpirationDate = (signature: ISignature) => {
        if (signature && typeof signature.expiresAt === "string") {
            signature.expiresAt = moment(signature.expiresAt).toDate();
        }
    };

    if (processHours === undefined) {
        service.getProcessHours().then((response: any) => {
            if (response.status === 200 && response.data.length > 0) {
                Auth.setProcessHours(response.data);
            }
        });
    }

    useEffect(() => {
        if (order !== undefined) {
            var operationPhone = order.userIdentity?.operationPhone;
            if (operationPhone !== undefined) {
                setPhoneNumber("X".repeat(operationPhone.length - 3) + operationPhone.slice(operationPhone.length - 3, operationPhone.length));
            }
        }
    }, [order]);

    useEffect(() => {
        if (order?.userIdentityId !== undefined && fundSelector !== undefined && refundCodeIsDefined === undefined) {
            UserIdentitiesService.isRefundCodeDefined(order.userIdentityId)
                .then((response: boolean) => {
                    setRefundCodeIsDefined(response);
                })
                .catch((error) => setServiceError(error));
        }
    }, [fundSelector]);

    useEffect(() => {
        if (refundCode !== undefined && refundCode !== "") {
            setRefundCodeIsDefined(true);
        }
    }, [refundCode]);

    const submitSignature = (response: any) => {
        if (newOrder !== undefined && signature !== undefined) {
            var data = {
                id: newOrder.id,
                signatureId: signature.id,
                hasDisclaimerTime: response.hasDisclaimerTime
            };

            OrderService.patchFixIncidenceOrder(data)
                .then((response: OrderType) => {
                    if (response.signatureId) {
                        setNewOrder(response);
                    }
                })
                .catch((error) => setServiceError(error));
        }
    };

    useEffect(() => {
        if (watchTargetFund !== undefined) {
            fundService.getFundEstimationDate(watchTargetFund.isin)
                .then((dataResponse: any) => {
                    setDataFund({
                        isin: watchTargetFund.isin,
                        name: watchTargetFund.name,
                        complexity: watchTargetFund.complexity,
                        date: dataResponse !== undefined && dataResponse !== null ? dataResponse : new Date().toLocaleDateString()
                    });
                });
        }
    }, [watchTargetFund]);

    useEffect(() => {
        if (watchTargetFund !== undefined) {
            validateTarget();
        }
    }, [watchTargetFund]);

    const createIsins = () => {
        if (fundSelector && watchTargetFund?.isin !== undefined) {
            return [watchTargetFund.isin];
        }
        return [];
    }

    const checkMin = useCallback(async (targetFund) => {
        if (targetFund.noMin) {
            setIsValidatingTargetFund(true);
            let userIdentityInstrumentsIdsAux: string[] = [];
            if (isUserIdentityInstrumentIdsLoaded) {
                userIdentityInstrumentsIdsAux = userIdentityInstrumentsIds;
            }
            else {
                userIdentityInstrumentsIdsAux = await UserIdentitiesService.getInstrumentsIds(user.mainUserIdentity.id);
                setUserIdentityInstrumentsIds(userIdentityInstrumentsIdsAux);
                setIsUserIdentityInstrumentIdsLoaded(true);
            }

            const min = userIdentityInstrumentsIdsAux.includes(targetFund.isin)
                ? targetFund.minimumAdditionalInvestment
                : targetFund.minimumInitialInvestment;

            const values = order?.values as OrderValuesType;
            if (values.amountType === 'amount' && values.amount < min) {
                setIsValidatingTargetFund(false);
                return { validAmount: false, userIdentityInstrumentsIds, min };
            }
        }

        setIsValidatingTargetFund(false);
        return { validAmount: true, userIdentityInstrumentsIds: [], min: 0 };
    }, [isUserIdentityInstrumentIdsLoaded, userIdentityInstrumentsIds, user.mainUserIdentity.id, setIsUserIdentityInstrumentIdsLoaded, order?.values]);

    const validateTarget = useCallback(async () => {
        setIsValidatingTargetFund(true);

        if (watchTargetFund?.complexity !== undefined && watchTargetFund.complexity) {
            setIsValidatingTargetFund(true);

            var activePlan = Auth.getActivePlan();

            if (!activePlan.allUserIdentitiesHaveSuitabilityTest) {
                localStorage.setItem("complexProduct", JSON.stringify({
                    returnPath: history.location.pathname
                }));
                history.push(`/savingsPlans/${activePlan.id}/complexProducts/${watchTargetFund.isin}/suitabilityTestList`);
                return;
            }

            FnmContractComplexProductService
                .getUserIdentitiesThatShouldSignComplexProductAgreement(activePlan.fnmContractId, watchTargetFund.isin)
                .then((userIdentities: UserIdentityType[]) => {
                    if (userIdentities.length > 0) {
                        localStorage.setItem("complexProduct", JSON.stringify({ returnPath: history.location.pathname }));
                        history.push(`/savingsPlans/${activePlan.id}/complexProducts/${watchTargetFund.isin}/agreement`);
                        return;
                    }
                    else {
                        localStorage.removeItem("complexProduct");
                        setIsValidatingTargetFund(false);
                    }
                });
        }
        else {
            setIsValidatingTargetFund(false);
        }
    }, [setIsValidatingTargetFund, Auth, history, localStorage, FnmContractComplexProductService, watchTargetFund]);

    if (serviceError.status) {
        return (<div>Error: {serviceError.message}</div>)
    }

    return (
        <DocumentTitle title="IronIA - Orden con incidencia">
            <div className="incidence-order">
                <div className="ms-Grid-row">
                    <h2>Corrección de órdenes</h2>
                </div>
                {isLoading && <Spinner size={SpinnerSize.large} />}
                {!isLoading && order !== undefined && (
                    <>
                        <div className="ms-Grid-row description">
                            {order?.orderType === OrderOrderType.Subscription && (
                                <p>Su orden de compra del fondo {sourceFundName} {sourceFundIsin} no puede ejecutarse, seleccione una opción para corregir la orden.</p>
                            )}
                            {order?.orderType === OrderOrderType.InternalSwitch && (
                                <p>Su orden de traspaso interno del fondo {sourceFundName} {sourceFundIsin} no puede ejecutarse, seleccione una opción para corregir la orden.</p>
                            )}
                            {order?.orderType === OrderOrderType.ExternalSwitch && (
                                <p>Su orden de traspaso externo del fondo {sourceFundName} {sourceFundIsin} no puede ejecutarse, seleccione una opción para corregir la orden.</p>
                            )}
                        </div>
                        {order?.orderType === OrderOrderType.Subscription && (
                            <div className="ms-Grid-row">
                                <div className="ms-Tabs-Container">
                                    <button className={"ms-Tab" + (fundSelector ? ' active' : '')} onClick={() => setFundSelector()}>
                                        {fundSelector ? <RadioBtnOnIcon /> : <RadioBtnOffIcon />} Seleccione otro fondo de inversión para realizar la compra
                                    </button>
                                    <button className={"ms-Tab" + (fundSelector ? '' : ' active')} onClick={() => setRefund()}>
                                        {fundSelector ? <RadioBtnOffIcon /> : <RadioBtnOnIcon />} Devolución del dinero a la cuenta bancaria especificada a continuación
                                    </button>
                                </div>
                            </div>
                        )}
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-xl6">
                                <form
                                    className="form rounded shadow"
                                    id="form"
                                    onSubmit={handleSubmit(onSubmit)}
                                    noValidate
                                >
                                    {fundSelector && (
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12">
                                                <Controller
                                                    name="targetFund"
                                                    control={control}
                                                    rules={{ required: fundSelector }}
                                                    render={({ field, field: { onChange } }) =>
                                                        <InvestmentFundFinder
                                                            {...field}
                                                            required={fundSelector}
                                                            default={initialTargetFund ?? null}
                                                            labelISIN="ISIN de destino"
                                                            labelName="Nombre del fondo de destino"
                                                            placeholderISIN="Escribe aquí el ISIN de destino..."
                                                            placeholderName="Escribe aquí el nombre del fondo de destino..."
                                                            loadData={(filter: any) => {
                                                                return fundService.getAvailableFunds(filter)
                                                                    .then((data: AvailableFundType[]) => data)
                                                                    .catch(err => []);
                                                            }}
                                                            onChange={async (ev: any, name: any, value: any) => {
                                                                if (value !== undefined) {
                                                                    const result = await checkMin(value);
                                                                    if (!result.validAmount) {
                                                                        setError("targetFund",
                                                                            {
                                                                                type: "invalidTargetFundMin",
                                                                                message: `El fondo seleccionado requiere una inversión ${result.userIdentityInstrumentsIds.includes(value.isin) ? 'adicional' : 'inicial'} de ${currencyFormatter.format(result.min)}`
                                                                            });
                                                                        return false;
                                                                    }
                                                                    else {
                                                                        clearErrors('targetFund');
                                                                        setValue(name, value);
                                                                        onChange({ target: { value, name } });
                                                                    }
                                                                }
                                                            }}
                                                            errorMessageName={errors.targetFund?.message ?? ''}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {!fundSelector && (
                                        <>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <Controller
                                                        name="iban"
                                                        control={control}
                                                        rules={{ required: !fundSelector }}
                                                        render={({ field, field: { onChange } }) =>
                                                            <TextField
                                                                {...field}
                                                                label="Introduzca el IBAN de su Cuenta Corriente"
                                                                placeholder="IBAN"
                                                                name="iban"
                                                                disabled={sending}
                                                                pattern="[A-Z]{2}[0-9]{22}"
                                                                onChange={(ev: any, value: any) => {
                                                                    setValue('iban', value);
                                                                    onChange({ target: { value } });
                                                                }}
                                                                errorMessage={errors.iban?.message ?? ''}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <Controller
                                                        name="ibanConfirm"
                                                        control={control}
                                                        rules={{ required: !fundSelector }}
                                                        render={({ field, field: { onChange } }) =>
                                                            <TextField
                                                                {...field}
                                                                label="Repetir el IBAN"
                                                                placeholder="IBAN"
                                                                name="iban"
                                                                disabled={sending}
                                                                pattern="[A-Z]{2}[0-9]{22}"
                                                                errorMessage={errors.ibanConfirm?.message ?? ''}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                {refundCodeIsDefined === undefined && (
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <br/>
                                                        <Spinner size={SpinnerSize.medium} className="button-spinner" />
                                                    </div>
                                                )}
                                                {refundCodeIsDefined !== undefined && (
                                                    <>
                                                        <div className={`ms-Grid-col ms-sm${refundCodeIsDefined ? '12' : '8'}`}>
                                                            <Controller
                                                                name="refundKey"
                                                                control={control}
                                                                rules={{ required: !fundSelector }}
                                                                render={({ field, field: { onChange } }) =>
                                                                    <TextField
                                                                        disabled={!refundCodeIsDefined || sending}
                                                                        autoComplete="off"
                                                                        canRevealPassword={true}
                                                                        type="password"
                                                                        label="Clave de Reembolso"
                                                                        placeholder="Escriba aquí la Clave de Reembolso"
                                                                        name="refundKey"
                                                                        id="refundKey"
                                                                        onChange={(ev: any, value: any) => {
                                                                            setRefundCode(value);
                                                                            setValue('refundKey', value);
                                                                            onChange({ target: { value } });
                                                                        }}
                                                                        errorMessage={errors.refundKey?.message ?? ''}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                        {!refundCodeIsDefined && (
                                                            <div className="ms-Grid-col ms-sm4 ms-xl2">
                                                                <ModalRefund onChange={(event: any, item: any) => setRefundCode(item) } />
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {signature === undefined && (
                                        <div className="ms-Grid-row pt20">
                                            <div className="ms-Grid-col ms-sm12 text-right">
                                                <DefaultButton
                                                    className="button tiny-primary-button"
                                                    type="submit"
                                                    disabled={sending || isValidatingTargetFund || (fundSelector && errors.targetFund) || (!fundSelector && (refundCodeIsDefined === undefined || errors.ibanConfirm || errors.refundKey))}
                                                >
                                                    {(sending || isValidatingTargetFund ) && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                                    Continuar
                                                </DefaultButton>
                                            </div>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                        {signature !== undefined && order.userIdentity !== undefined && (
                            <SignatureComponent
                                userIdentities={[order.userIdentity]}
                                signatures={[signature]}
                                alreadySignedText="¡La corrección de la incidencia está en proceso!"
                                title=""
                                submitButtonText="Firmar"
                                cancelButtonText="Cancelar"
                                headerTextArea={`Confirma la orden con la clave enviada a tu teléfono ${phoneNumber}.`}
                                backButtonText="Volver"
                                onBackButtonClicked={() => history.push("/portfolio/orders")}
                                onCancelButtonClicked={() => history.push("/portfolio/orders")}
                                requestNewCodeButtonText="Recibir nuevo código"
                                confirmElement={
                                    {
                                        text: "Confirmo haber recibido, leído y comprendido la documentación obligatoria de IIC's y realizar esta operación por iniciativa propia.",
                                        value: userConfirmation.user,
                                        setValue: (value: boolean) => setUserConfirmation({ user: !!value, disclaimer: userConfirmation.disclaimer })
                                    }}
                                disclaimerEnabled={
                                    {
                                        text: "He sido informado de que alguna de las operaciones realizadas en este momento pueden no tomar el valor liquidativo del día en curso.",
                                        processHours: processHours,
                                        isins: createIsins(),
                                        value: userConfirmation.disclaimer,
                                        isTransfer: false,
                                        isMultiIsin: false,
                                        setValue: (value: boolean) => setUserConfirmation({ user: userConfirmation.user, disclaimer: !!value })
                                    }}
                                dataCart={dataFund !== undefined ? [dataFund] : []}
                                complexProducts={(fundSelector && watchTargetFund.complexity) ? [watchTargetFund] : []}
                                complexProductAgreements={complexProductAgreements}
                                setComplexProductAgreements={setComplexProductAgreements}
                                onNewCodeSended={(signatures: any) => {
                                    signatures.forEach((signature: any) => {
                                        processExpirationDate(signature);
                                    })
                                    setSignature(signatures[0]);
                                }}
                                onSignatureChanged={(signature: any) => {
                                    processExpirationDate(signature);
                                    setSignature(signature);
                                }}
                                onSigned={(signature: any) => {
                                    processExpirationDate(signature);
                                    setSignature(signature);
                                    localStorage.removeItem("cart");
                                    submitSignature(signature);
                                }}
                            />
                        )}
                        <div className="ms-Grid-row p10">
                            <div className="ms-Grid-col ms-sm12">
                                <div className="section-row">
                                    {fundSelector && watchTargetFund !== undefined && (
                                        <div className="warning-container">
                                            <div>
                                                <img src='/icons/information.svg' alt="Información" />
                                            </div>
                                            {watchTargetFund.complexity && (
                                                <>
                                                    Respecto a la suscripción del fondo: {watchTargetFund.name + ' ' + watchTargetFund.isin}
                                                </>
                                            )}
                                            <h3>Operación a iniciativa del cliente que no precisa evaluación por la Entidad</h3>
                                            <p>
                                                La entidad no tiene obligación de recabar información sobre los conocimientos
                                                y experiencia del cliente para un análisis de la conveniencia de la presente
                                                operación, dado que el instrumento sobre el que va operar tiene la categoría
                                                normativa de no complejo y que la operación se realiza por iniciativa suya y
                                                no de la entidad.  Al no realizar dicha valuación la entidad no puede formarse
                                                una opinión respecto a si esta operación es o no conveniente para usted y por
                                                tanto, en caso de que, la operación no resultase conveniente para usted, no
                                                podría advertírselo.
                                            </p>
                                        </div>
                                    )}
                                    <br />
                                    <div className="warning-container">
                                        <div>
                                            <img src='/icons/information.svg' alt="Información" />
                                        </div>
                                        <h3>Entrega documentación obligatoria IIC’s</h3>
                                        <p>
                                            De conformidad con lo establecido en la normativa vigente aplicable a Instituciones de Inversión Colectiva
                                            y en las Circulares 4/2008 y 2/2011 de la Comisión Nacional del Mercado de Valores, Diaphanum Valores, S.V., S.A.U.
                                            entrega la documentación legal requerida al partícipe que se identifica con anterioridad a la suscripción de
                                            participaciones/acciones del fondo de inversión. El firmante declara haber recibido, leído, comprendido y
                                            tener a su disposición la documentación indicada relativa al/los fondo/s que desea suscribir y acusa recibo de la documentación entregada.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </DocumentTitle>
    );
};

export default OrderIncidence;
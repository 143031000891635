import axios from "axios";
import { proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
import { FundType } from "./FundService";
setupInterceptorsTo(axios);

export interface IOperations
{
    amount: number,
    cashIn: boolean,
    description: string,
    exchangeRate: number,
    id: string,
    nav: number,
    operationAt: string,
    operationInstrument:{currency: string, isin: string, name: string},
    settlementDate: string,
    titles: number
}
export interface ICountry
{
    key: string,
    text: string,
    value: string
}
export interface IAddressType
{
    key: string,
    text: string,
    value: string
}

export interface IPositionType
{
    instrumentType: number,
    productName: string,
    isin: string,
    cost: number,
    titles: number,
    value: number,
    dateLastPrice: string,
    plusMinus: number,
    weight: number,
    price: number,
    date: string,
    exchangeRate: {
        from: string,
        value: number
    }
}

class FinametrixService
{
    public getPortfolio = async (portfolio_id: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/portfolios/${portfolio_id}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getReporting = async (portfolio_id: string, date_from_text: string, date_to_text: string, benchmark?: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/reporting/${encodeURIComponent(portfolio_id)}/${date_from_text}/${date_to_text}/assetclasses`;
        if(benchmark)
        {
            url += '/' + benchmark;
        }
        const req = await axios.get(url);
        return req.data;
    }
    public getPortfoliosByUserId = async (finametrix_id: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/portfolios/getByUserId/${finametrix_id}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getOperations = async (portfolio_id: string, dates?: { from: string, to: string }): Promise<IOperations[]> =>
    {
        const datesParams = dates !== undefined ? `/${dates.from}/${dates.to}` : '';
        let url: string = `${proxyUrl}/finametrix/operations/${portfolio_id}${datesParams}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getOperationsNew = async (portfolio_id: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/serviciofinametrix/operations/${portfolio_id}`;
        const req = await axios.get(url);
        return req.data;
    }

    public getOrders = async (portfolio_ccc: string, limit?: number): Promise<any> =>
    {
        const limitParam = limit !== undefined ? `?limit=${limit}` : '';
        const url: string = `${proxyUrl}/finametrix/orders/${portfolio_ccc}${limitParam}`;
        const req = await axios.get(url);
        return req.data;
    }

    public setOrder = async (toSend: any, portfolioId: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/orders/${portfolioId}`;
        const req = await axios.post<any>(url, toSend, { headers: { "content-type": "application/json" } });
        return req.data;
    }
    public getClosedPositions = async (finametrix_id: string, date_from_text: string, date_to_text: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/getClosedPositions/${finametrix_id}/${date_from_text}/${date_to_text}`;
        const req = await axios.get(url);
        return req.data;
    }

    public getInstrument = async (instrument_id: string): Promise<FundType> => {
        let url: string = `${proxyUrl}/finametrix/instruments/${instrument_id}`;
        const req = await axios.get(url);
        return req.data;
    }

    public getInstrumentPrices = async (finametrix_id: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/instruments/${finametrix_id}/prices`;
        const req = await axios.get(url);
        return req.data;
    }
    public getInstrumentPerformance = async (finametrix_id: string, date_from_text: string, date_to_text: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/instruments/${finametrix_id}/performance/${date_from_text}/${date_to_text}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getInstrumentAssetclass = async (finametrix_id: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/instruments/${finametrix_id}/assetclass`;
        const req = await axios.get(url);
        return req.data;
    }
    public getFiscalItems = async (portfolio_id: string, date_from_text: string, date_to_text: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/fiscalItems/${portfolio_id}/${date_from_text}/${date_to_text}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getModelPortfolio = async (portfolio_id: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/modelPortfolios/${portfolio_id}`;
        const req = await axios.get(url);
        return req.data;
    }
    public postBenchmarksQuant = async (benchmark: any, from: string, to: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/benchmarks/quant`;
        const req = await axios.post(url, { benchmark: { compositions: benchmark }, from: from, to: to });
        return req.data;
    }
    public getInstruments = async (instruments_ids: string[]): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/instruments`;
        const req = await axios.get(url, { params: { ids: instruments_ids } });
        return req.data;
    }
    public getCountries = async (): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/countries`;
        const req = await axios.get(url);
        return req.data;
    }
    public getAddressTypes = async (): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/addressTypes`;
        const req = await axios.get(url);
        return req.data;
    }
    public getRegions = async (): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/regions`;
        const req = await axios.get(url);
        return req.data;
    }
    public getCitiesByRegion = async (regionId: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/citiesByRegionId/${regionId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getPostalCodesByCity = async (cityId: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/postalCodesByCityId/${cityId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getLastSuitabilityTestVersion = async (): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/lastTestMifid`;
        const req = await axios.get(url);
        return req.data;
    }
    public postFinametrixSuitabilityTest = async (clientId: string, suitabilityTest: any): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/client/${clientId}/test-profiles`;
        const req = await axios.post(url, suitabilityTest);
        return req.data;
    }
    public getRisks = async (): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/risks`;
        const req = await axios.get(url);
        return req.data;
    }
    public getClientDocuments = async (userId: number): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/clientDocuments/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getClientDocumentBase64Pdf = async (documentId: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/clientDocuments/${documentId}/pdf`;
        const req = await axios.get(url, { responseType: 'blob' });
        const reader = new FileReader();
        return new Promise((resolve) =>
        {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(req.data);
        });
    }
    public getMonthlyReports = async (userId: number): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/monthlyReports/${userId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getMonthlyReportBase64Pdf = async (reportId: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/monthlyReports/${reportId}/pdf`;
        const req = await axios.get<any>(url, { responseType: 'blob' });
        const reader = new FileReader();
        return new Promise((resolve) =>
        {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(req.data);
        });
    }
    public getOperationBase64Pdf = async (orderId: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/finametrix/operationsVoucher/${orderId}`;
        const req = await axios.get(url, { responseType: 'blob' });
        const reader = new FileReader();
        return new Promise((resolve) =>
        {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(req.data);
        });
    }
    public getAddressText = (address: any, addressTypes: IAddressType[], countries: ICountry[]): string =>
    {
        var addressText = addressTypes.find(aT => aT.value === address.roadType)?.text
            + (address.streetName !== "" ? ` ${address.streetName}`: "")
            + (address.streetNumber !== "" ? ` ${address.streetNumber}, ` : "")
            + (address.streetStair !== "" ? ` Escalera ${address.streetStair}` : "")
            + (address.streetFloor !== "" ? ` ${address.streetFloor}` : "")
            + (address.streetDoor !== "" ? ` ${address.streetDoor}` : "")
            + (address.streetOther !== "" ? `(${address.streetOther})` : "")
            + " - "
            + (address.city !== "" ? ` ${address.city}` : "")
            + (address.zipCode !== "" ? ` ${address.zipCode}` : "")
            + (address.state !== "" ? ` ${address.state}` : "")
            + " - "
            + countries?.find(c => c.value === address.country)?.text;
        return addressText;
    }
}

const finametrix = new FinametrixService();

export default finametrix;

interface IRisk
{
    _id?: string,
    name: string,
    order: number,
    minWeight: number,
    maxWeight: number,
    entity: string,
    rangeValues: any[],
    notProfiled: boolean,
    date: Date,
    translations: string[],
    _vv: number,
    description: string
}
export type RiskType = IRisk;

interface IClientDocuments
{
    id: string,
    name: string,
    date: string,
    link: string,
    linkDoc: JSX.Element
}
export type IClientDocs = IClientDocuments;
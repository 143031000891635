import { DefaultButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import FnmContractComplexProductService, { FnmContractComplexProductType, FnmContractComplexProductUserIdentityType } from "../../services/FnmContractComplexProductService";
import FundService from "../../services/FundService";
import { ISignature } from "../../services/SignaturesService";
import { UserIdentityType } from "../../services/UserIdentitiesService";

const ComplexProductAgreement = () => {
	const history = useHistory();
	const { savingsPlanId, isin } = useParams<any>();
	const [fund, setFund] = useState<any>();
	const [fnmContractComplexProduct, setFnmContractComplexProduct] = useState<FnmContractComplexProductType>();
	const [userIdentities, setUserIdentities] = useState<UserIdentityType[]>([]);
	const [signatures, setSignatures] = useState<ISignature[]>([]);
	const [phoneNumbers, setPhoneNumbers] = useState("");
	const [isLoaded, setIsLoaded] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [agreementText, setAgreementText] = useState('');
	const [error, setError] = useState({ status: false, message: "" });
	const defaultAgreementText = `No he sido asesorado. Este producto es complejo y se considera no conveniente para mi`;

	const onChangeAgreementText = useCallback(
		(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
			setError({ status: false, message: "" });
			setAgreementText(newValue || '');
		},
		[],
	);

	useEffect(() => {
		FnmContractComplexProductService.getOrCreateFnmContractComplexProductBySavingsPlanIdAndIsin(savingsPlanId, isin).then(
			(fnmContractComplexProduct: FnmContractComplexProductType) => {
				setFnmContractComplexProduct(fnmContractComplexProduct);
				setIsLoaded(true);
			},
			(error) => {
				setError(error);
				setIsLoaded(true);
			}
		);
	}, []);

	useEffect(() => {
		FundService.getFund(isin).then((fund: any) => {
			setFund(fund);
			setIsLoaded(true);
		},
			(error) => {
				setIsLoaded(true);
				setError(error);
			});
	}, [isin]);

	useEffect(() => {
		if (fnmContractComplexProduct?.fnmContractComplexProductsUserIdentities !== undefined) {
			var userIdentities = fnmContractComplexProduct.fnmContractComplexProductsUserIdentities
				.filter(fCCPUI => fCCPUI.userIdentity !== undefined)
				.map(fCCPUI => fCCPUI.userIdentity);
			// @ts-ignore
			setUserIdentities(userIdentities);
		}
	}, [fnmContractComplexProduct]);

	const validateAndSend = () => {
		if (defaultAgreementText === agreementText) {
			setIsSending(true);
			if (fnmContractComplexProduct?.id !== undefined && userIdentities.length > 0) {
				FnmContractComplexProductService.addSignatureToFnmContractComplexProductUserIdentities(fnmContractComplexProduct.id)
					.then((signatures: ISignature[]) => {
						setSignatures(signatures);
						setIsSending(false);
					});
			}
		}
		else {
			setError({ status: true, message: "El texto no coincide exactamente con el que debe introducir" });
        }
	};

	useEffect(() => {
		setPhoneNumbers(userIdentities.map((userIdentity: any) =>
			"X".repeat(userIdentity.phone.length - 3)
			+ userIdentity.phone.slice(userIdentity.phone.length - 3, userIdentity.phone.length)).join(", "));
	}, [userIdentities]);

	useEffect(() => {
		if (userIdentities.length > 0 && userIdentities.length === signatures.length) {
			history.push(`/savingsPlans/${savingsPlanId}/complexProducts/${isin}/confirm`, { signatures: signatures });
		}
	}, [userIdentities, signatures]);

	return (
		<div className="">
			<div>
				<h1>Firma del Contrato</h1>
				<p className="description">
					Le informamos de que, dadas las características de esta operación, la Entidad está obligada a evaluar la conveniencia de la misma para Usted.
					En nuestra opinión esta operación no es conveniente para Usted. Una operación no resulta conveniente cuando el cliente
					carece de los conocimientos y experiencia necesarios para comprender la naturaleza y riesgos del instrumento financiero sobre
					el que va a operar.
					En la presente operación la Entidad no le ha prestado ningún tipo de asesoramiento.
				</p>
				<p className="description">Manuscribe el siguiente texto dentro del cuadro de texto y dale al botón
					de "Solicitar firma". Te enviaremos un código SMS por cada interviniente
					{userIdentities.length > 1
						? ` a los números ${phoneNumbers} `
						: userIdentities.length === 1 ? ` al número ${phoneNumbers} ` : " "
					}
					para realizar la firma del contrato.
				</p>
			</div>
			<p>Por favor, para dar cumplimiento a
				la <a href="https://www.boe.es/buscar/doc.php?id=BOE-A-2013-6658" target="_blank" rel="noopener noreferrer">Circular 3/2013 de CNMV</a>,
				escriba:</p>
			<p>{defaultAgreementText}</p>
			<TextField
				multiline
				autoAdjustHeight
				value={agreementText}
				onChange={onChangeAgreementText}
			/>
			{error.status && (
				<div className="error">{error.message}</div>
			)}
			<div className="ms-Grid-row">
				<div className="ms-Grid-col ms-sm12 text-right mt20">
					<DefaultButton
						className="button tiny-primary-button"
						onClick={() => validateAndSend()}
						disabled={isSending || error.status}
					>
						{isSending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
						Solicitar firma
					</DefaultButton>
				</div>
			</div>
		</div>
	);
}

export default ComplexProductAgreement;
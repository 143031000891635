import axios from "axios";
import { proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);

interface IMarketItem {
    id: any,
    fileUrl: string,
    imageUrl: string,
}
export type MarketResponseResponse =
    {
        items: IMarketItem[]
    }
export type DataverseType = IMarketItem;

class MenuService {

    public getMarketItemEnable = async (): Promise<boolean> => {
        let url: string = `${proxyUrl}/market-reports/enable`;
        const req = await axios.get(url)
        return req.data
    }
}

const news = new MenuService();
export default news;
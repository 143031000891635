export const {
    NODE_ENV: nodeEnv = "development",
    REACT_APP_ANALYSIS_SERVICE: analysisService = "#{ANALYSIS_SERVICE}#",
    REACT_APP_CDN: cdnUrl = "#{CDN_URL}#",
    REACT_APP_FID_FUNCTIONS_BALANCE_CODE: fidFunctionsBalanceCode = "#{FID_FUNCTIONS_BALANCE_CODE}#",
    REACT_APP_FID_FUNCTIONS_URL: fidFunctionsUrl = "#{FID_FUNCTIONS_URL}#",
    REACT_APP_FUNDS_SERVICE: fundsService = "#{FUNDS_SERVICE}#",
    REACT_APP_INSIGHTS_INSTRUMENTATION_KEY: insightsInstrumentationKey = "#{INSIGHTS_INSTRUMENTATION_KEY}#",
    REACT_APP_MAINTENANCE_MODE: maintenanceMode = "#{MAINTENANCE_MODE}#",
    REACT_APP_MAINTENANCE_URL: maintenanceUrl = "#{MAINTENANCE_URL}#",
    REACT_APP_OPTIMIZER_LITTERMAN_CODE: optimizerLittermanCode = "#{OPTIMIZER_LITTERMAN_CODE}#",
    REACT_APP_OPTIMIZER_MARKOWITZ_CODE: optimizerMarkowitzCode = "#{OPTIMIZER_MARKOWITZ_CODE}#",
    REACT_APP_OPTIMIZER_URL: optimizerUrl = "#{OPTIMIZER_URL}#",
    REACT_APP_POWERBI_API_REPORTTOKENS_CODE: powerbiApiReportTokensCode = "#{POWERBI_API_REPORTTOKENS_CODE}#",
    REACT_APP_POWERBI_SERVICE_URL: powerbiServiceUrl = "#{POWERBI_SERVICE_URL}#",
    REACT_APP_PROXY_URL: proxyUrl = "#{PROXY_URL}#",
    REACT_APP_PUBLIC_URL: publicUrl = "#{PUBLIC_URL}#",
    REACT_APP_SERVER_HOST: serverHost = "#{SERVER_HOST}#",
    REACT_CLARITY_KEY: clarityKey = "#{CLARITY_KEY}#",
    TYPE_OF_CANCEL_DEVOLUTION: typeOfCancelDevolution = "#{TYPE_OF_CANCEL_DEVOLUTION}#"
} = process.env;

if (maintenanceMode !== undefined && maintenanceMode === "true" && maintenanceUrl !== undefined)
{
    window.location.href = maintenanceUrl;
}
import { useParams } from "react-router";
import SuitabilityTestConfirm from "../../components/SuitabilityTests/SuitabilityTestConfirm";

const SuitabilityTestConfirmScreen = () =>
{
    const { savingsPlanId, userIdentityId } = useParams<any>();

    return (
        <SuitabilityTestConfirm
            userIdentityId={userIdentityId}
            returnRoute={`/suitabilityTestList/${savingsPlanId}`}
            cancelRoute={`/suitabilityTest/${savingsPlanId}`}
        />
    );
};

export default SuitabilityTestConfirmScreen;
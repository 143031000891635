import { useCallback, useState, useEffect, useContext } from 'react';
import { useConst } from "@fluentui/react-hooks";
import * as L from './layout.styles';
import FundService from '../../services/FundService.tsx';
import Auth from '../../Auth/Auth';
import { ThemeContext } from '@fluentui/react-theme-provider';
import groupFundsByFamily from '../../utils/groupFundsByFamily';
import styled from 'styled-components';

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 80px;
    justify-content: center;
    align-items: flex-start;
`;

const CompanyFinder = ({ defaultFilters, idCategory }) =>
{
    const theme = useContext(ThemeContext);
    const filters = useConst({
        subcategory: [],
        manager: [],
        currency: [],
        available: undefined,
        income: undefined,
        switchable: undefined,
        indexed: undefined,
        ...defaultFilters,
    });

    const [page, setPage] = useState(0);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [funds, setFunds] = useState([]);
    const [tableSearchTerm, setTableSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [fundGroups, setFundGroups] = useState([]);
    const [productsPerPage, setProductsPerPage] = useState(Auth.getQtyPage());

    const callApiFunds = useCallback(async () =>
    {
        let { subcategory, manager, currency, available, income, switchable, indexed } = filters;
        let subcategories = subcategory.map((sc) =>
        {
            return sc.morningStarCategoryId;
        });

        await FundService.getFunds(page, productsPerPage, tableSearchTerm, subcategories, available, income, switchable, indexed, manager, currency).then(
            (response) =>
            {
                setFunds(response.results);
                setRecordsTotal(response.total);
                setFundGroups(groupFundsByFamily(response.results));
                setLoading(false);
            }
        );
    }, [filters, page, productsPerPage, tableSearchTerm]);

    useEffect(() =>
    {
        setRecordsTotal(0);
        setLoading(true);
        setFunds([])
        setFundGroups([])
        callApiFunds()
    }, [page, tableSearchTerm, filters, productsPerPage, callApiFunds]);

    /*const handleChange = (value, filterId) => {
      setFilters({ ...filters, [filterId]: value });
    };*/

    /*const handleClick = (managerName) => {
      const alreadySelected = filters.manager.some((name) => name === managerName);
      const filteredManagers = filters.manager.filter((name) => name !== managerName);
  
      if(alreadySelected) {
        setFilters({
          ...filters,
          manager: [...filteredManagers],
        });
      } else {
        setFilters({
          ...filters,
          manager: [...filteredManagers, [managerName]],
        });
      }
    };*/

    return (
        <>
            <L.Wrapper theme={theme}>
                <TitleContainer theme={theme}>
                    <h1 style={{ margin: 0 }}>Buscador de Empresas</h1>
                </TitleContainer>
                <h3>Próximamente disponible</h3>
            </L.Wrapper>
        </>
    );

    // return (
    //     <L.Wrapper>
    //         <L.TitleContainer>
    //             <L.Title>Buscador de empresas</L.Title>
    //         </L.TitleContainer>
    //         <L.Warning>
    //             <Icon iconName='Error' styles={WarningIcon} />
    //             <h4>Como funciona nuestro buscador de empresas:</h4>
    //             <p>
    //                 Nuestra buscador de emprsas permite buscar información en la documentación de
    //                 los fondos, en esta documentación encontramos las principales empresas en las
    //                 que invierten los fondos pero no podemos buscar muchas otras cosas. Escribe
    //                 "Tesla", incluso si quieres saber qué fondos invierten en Tesla o escribe "oro"
    //                 para saber cuáles están relacionados con el oro, nuestro buscador te mostrará
    //                 todos fondos.
    //             </p>
    //         </L.Warning>

    //         <L.FieldCont>
    //             <L.StyledSearchBox
    //                 placeholder='Buscar...'
    //                 styles={InputStyles}
    //                 iconProps={InputIcon}
    //                 onChange={debounce((event) =>
    //                 {
    //                     let text = event.target.value.toUpperCase();
    //                     setTableSearchTerm(text);
    //                 }, 1000)}
    //             />
    //         </L.FieldCont>

    //         <hr />

    //         {/* FM = Featured Managers */}
    //         {/* <L.FMContainer>
    //     <L.FMTitleContainer>
    //       <L.FMTitle>GESTORAS DESTACADAS</L.FMTitle>
    //     </L.FMTitleContainer>
    //     <L.FMCardsContainer>
    //       <FeaturedManagers selectedManagers={filters.manager} onClick={handleClick} />
    //     </L.FMCardsContainer>
    //   </L.FMContainer> */}
    //         {/* Featured Managers */}
    //         {/* <L.FiltersWrapper>
    //     <Filters onChange={handleChange} selectedFilters={filters} />
    //   </L.FiltersWrapper> */}
    //         <L.TableWrapper>
    //             <L.TableWidthManager>
    //                 <L.TableFilterContainer>
    //                     {/* TR = Table Results */}
    //                     <L.TRCountContainer>
    //                         <L.TRTitle>
    //                             Todos los fondos
    //                             <L.TRCount>{`(${recordsTotal})`}</L.TRCount>
    //                         </L.TRTitle>
    //                     </L.TRCountContainer>
    //                     {/* <L.InputSearchContainer>
    //           <SearchBox
    //             placeholder='Buscar...'
    //             onChange={debounce((event) => {
    //               let text = event.target.value.toUpperCase();
    //               setTableSearchTerm(text);
    //             }, 1000)}
    //           />
    //         </L.InputSearchContainer> */}
    //                     <L.EditColumnContainer>
    //                         <DefaultButton text='Editar columnas' />
    //                     </L.EditColumnContainer>
    //                 </L.TableFilterContainer>
    //                 {loading ? (
    //                     <Spinner size={SpinnerSize.large} />
    //                 ) : (
    //                     <>
    //                         <L.TableContainer theme={theme}>
    //                             <Table
    //                                 groups={fundGroups}
    //                                 data={funds}
    //                                 columns={['Nombre', 'Gestora', 'Categoría', 'Puntos IRONIA']}
    //                             />
    //                         </L.TableContainer>
    //                         <L.PaginationContainer>
    //                             <L.ProductsPerPage>
    //                                 <span>Productos por página</span>
    //                                 <select
    //                                     onChange={(event) =>
    //                                     {
    //                                         Auth.setQtyPage(event.target.value);
    //                                         setProductsPerPage(event.target.value);
    //                                     }}
    //                                     defaultValue={Auth.getQtyPage()}
    //                                 >
    //                                     <option value={10} selected={productsPerPage === 10}>
    //                                         10
    //                                     </option>
    //                                     <option value={20} selected={productsPerPage === 20}>
    //                                         20
    //                                     </option>
    //                                     <option value={30} selected={productsPerPage === 30}>
    //                                         30
    //                                     </option>
    //                                     <option value={40} selected={productsPerPage === 40}>
    //                                         40
    //                                     </option>
    //                                     <option value={50} selected={productsPerPage === 50}>
    //                                         50
    //                                     </option>
    //                                 </select>
    //                             </L.ProductsPerPage>
    //                             <L.PageCount>
    //                                 <span>{`${page}-${productsPerPage} de ${Math.ceil(
    //                                     recordsTotal / productsPerPage
    //                                 )}`}</span>
    //                             </L.PageCount>
    //                             <L.PageButtons>
    //                                 <button
    //                                     onClick={() =>
    //                                     {
    //                                         if(page !== 0)
    //                                         {
    //                                             setPage(page - 1);
    //                                         }
    //                                     }}
    //                                 >
    //                                     <CaretLeftSolid8Icon style={{ fontSize: '28px', color: '#CC214F' }} />
    //                                 </button>
    //                                 <button
    //                                     onClick={() =>
    //                                     {
    //                                         if(page !== Math.ceil(recordsTotal / productsPerPage))
    //                                         {
    //                                             setPage(page + 1);
    //                                         }
    //                                     }}
    //                                 >
    //                                     <CaretRightSolid8Icon
    //                                         style={{ fontSize: '28px', color: '#CC214F' }}
    //                                     />
    //                                 </button>
    //                             </L.PageButtons>
    //                         </L.PaginationContainer>
    //                     </>
    //                 )}
    //             </L.TableWidthManager>
    //         </L.TableWrapper>
    //     </L.Wrapper>
    // );
};

//Inputs Styles
// const InputStyles =
// {
//     root:
//     {
//         boxSizing: 'border-Box',
//         marginTop: '30px',
//         marginBottom: '20px',
//         width: '100%',
//         borderRadius: '30px',
//         boxShadow: '-3px 5px 31px 1px #b4bcbf',
//         padding: '0px 20px',
//         height: '40px',
//         border: 'none',
//     },

//     field:
//     {
//         fontSize: '16px',
//         selectors:
//         {
//             '::placeholder':
//             {
//                 backgroundColor: '#ffffff',
//                 paddingTop: 0,
//                 display: 'block',
//                 fontWeight: 600,
//                 color: '#b7bfc0',
//                 fontSize: '14px',
//             },
//         },
//     },
// };
// const InputIcon =
// {
//     iconName: 'Search',
//     styles:
//     {
//         root:
//         {
//             fontSize: '14px',
//             color: '#cc214f',
//             fontWeight: 700,
//             transform: "rotateY(180deg)",
//         },
//     },
// };
// const WarningIcon =
// {
//     root:
//     {
//         fontSize: '18px',
//         fontWeight: 500,
//         color: '#f7a53f',
//     },
// };

export default CompanyFinder;
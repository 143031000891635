import React from "react";
import OfficialDocumentationService, { ClavePinRequestDto, OfficialDocumentationSignedRequestDto } from "../../../../services/OfficialDocumentationService";
import { DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import { ThemeContext } from "@fluentui/react-theme-provider";
import Styles from "./index.module.css";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { WarningContainer } from "../../../Elements/ElementStyles";

export const AlertContainer = styled.div`
    display: flex;
    flex-direction: column;
    cursor: default;
    background: #0078d4;
    color: white;
    padding: 1em;
    text-align: justify;
    gap: 10px;
    font-weight: 500;
    h3
    {
        margin: 0;
    }
    p
    {
        line-height: 20px;
        margin: 0;
    }
`;

const BelenderIframe = () => {
	const { state } = useLocation<any>();
	const [accessToken] = React.useState<string>(state?.accessToken ?? "");
	const [userId] = React.useState<string>(state?.userId ?? "");
	const [requestId, setRequestId] = React.useState<string>();
	const [widgetUrl, setWidgetUrl] = React.useState<string>();
	const [hasErrors, setHasErrors] = React.useState<boolean>(false);
	const [errorsMessages, setErrorsMessages] = React.useState<string[]>([]);
	const [loading, setLoading] = React.useState<boolean>(true);
	const theme = React.useContext(ThemeContext);
	let history = useHistory();

	React.useEffect(() => {
		if (!accessToken || !userId) {
			history.push("/Error");
		}
	}, [accessToken, userId, history]);

	React.useEffect(() => {
		if (accessToken && userId) {
			setHasErrors(false);
			setLoading(true);

			let request: ClavePinRequestDto = {
				user_id: userId,
				accessToken: accessToken
			};

			OfficialDocumentationService.clavePin(request)
				.then(response => {
					if (response.hasErrors) {
						setErrorsMessages(response.errorsMessages);
						setHasErrors(true);
					} else {
						setWidgetUrl(response.data?.data.widget_url);
						setRequestId(response.data?.data.request_id);
						CallPostOfficialDocumentationUserStatus(response.data?.data.request_id ?? "");
					}
				})
				.catch(error => {
					if (error?.response?.data?.errorsMessages && Array.isArray(error?.response?.data?.errorsMessages)) {
						setErrorsMessages(currentState => [...currentState, ...error.response.data.errorsMessages]);
					}
					setHasErrors(true);
				})
				.finally(() => {
					setLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken, userId]);

	const CallPostOfficialDocumentationUserStatus = (requestId: string) => {
		let request: OfficialDocumentationSignedRequestDto = {
			newStatusId: 2, //InProcess
			user_id: userId ?? "",
			request_id: requestId
		};
		OfficialDocumentationService.postOfficialDocumentationUserStatus(request)
			.then(response => {
				if (response.hasErrors) {
					setErrorsMessages(currentState => [...currentState, ...response.errorsMessages]);
					setHasErrors(true);
				} else {
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			{loading && (
				<div style={{ marginBottom: "10px" }}>
					<Spinner size={SpinnerSize.large} />
				</div>
			)}
			{!loading && (
				<>
					<AlertContainer theme={theme} style={{ marginBottom: "10px", display: "block", whiteSpace: "nowrap" }}>
						<div style={{ display: "inline-block", verticalAlign: "middle" }}>
							<DefaultButton onClick={() => history.goBack()} className="button tiny-secondary-button">
								Terminar
							</DefaultButton>
						</div>
						<p style={{ display: "inline-block", verticalAlign: "middle" }}>
							Ahora procederemos a verificar su identidad mediante el sistema de Clave-Pin para obtener su documentación.
							<br />
							Por favor, no salga de esta ventana hasta que el proceso esté completado, se le indicará cuando ha finalizado.
						</p>
					</AlertContainer>

					{hasErrors && errorsMessages && errorsMessages.length > 0 && (
						<div>
							<WarningContainer theme={theme}>
								{errorsMessages.map(message => (
									<p>{message}</p>
								))}
							</WarningContainer>
						</div>
					)}
					{widgetUrl && (
						<div className={Styles.ResponsiveIframe}>
							<iframe title="BelenderWidgetUrl" src={widgetUrl} />
						</div>
					)}
				</>
			)}
		</>
	);
};

export default BelenderIframe;

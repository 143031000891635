import { Modal } from "@fluentui/react";
import { Image } from 'office-ui-fabric-react/lib/Image';
import loadingImage from '../../images/loading.svg';
import { useRef } from "react";

interface ModalProps
{
    isModalOpen: boolean,
    hideModal: () => void,
    blocking?: boolean,
    title?: string,
    description?: string
}

const ProcessingModalComponent = (props: ModalProps) =>
{
    const title = useRef<string>(props.title !== undefined ? props.title : "Procesando la operación");
    const description = useRef<string>(props.description !== undefined ? props.description : 'No cierres el navegador hasta que la operación haya terminado.');

    return(
        <Modal isOpen={props.isModalOpen} onDismiss={props.hideModal} isBlocking={true}>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm4">
                    <Image src={loadingImage} alt="Loading" />
                </div>
                <div className="ms-Grid-col ms-sm8">
                    <h3>{title.current}</h3>
                </div>
            </div>
            <p className="text-center">{description.current}</p>
        </Modal>
    );
};

export default ProcessingModalComponent;
import { Checkbox, Separator } from "@fluentui/react";
import { currencyFormatter, percentFormatter } from "../../utils/numberFormatter";
import { Image } from 'office-ui-fabric-react/lib/Image';
import managed_portfolio_item from '../../images/managed_portfolio_item.png';
import { CompleteManagedPortfolioType } from "../../services/ManagedPortfolioService";
import { ThemeContext } from '@fluentui/react-theme-provider';
import * as L from './layout.styles';
import { useContext } from "react";
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { ai } from '../../services/TelemetryService';
import { translate } from "../../utils/i18n";
import { dateFormatter } from "../../utils/DateFormatter";

const appInsights = ai.reactPlugin;

interface IModelPortfolioItemProps
{
    completeManagedPortfolio: CompleteManagedPortfolioType,
    checked: boolean,
    _onChange: any,
    disabled: boolean,
    intl: any
}

const ModelPortfolioItem = (props: IModelPortfolioItemProps) =>
{
    const theme = useContext(ThemeContext);

    const trackClicks = useTrackEvent(appInsights, "Managed Portfolio Link", "");

    const performTracking = () =>
    {
        trackClicks(props.completeManagedPortfolio.managedPortfolio.name);
    };

    return (
        <L.Wrapper className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl2" theme={theme}>
            {props.completeManagedPortfolio.modelPortfolio !== undefined && (
                <div className="item">
                    <div className="header">
                        {props.completeManagedPortfolio.managedPortfolio.image !== null && (
                            <Image src={`/images/${props.completeManagedPortfolio.managedPortfolio.image}`} alt={props.completeManagedPortfolio.modelPortfolio.name} />
                        )}
                        {props.completeManagedPortfolio.managedPortfolio.image === null && (
                            <Image src={managed_portfolio_item} alt={props.completeManagedPortfolio.modelPortfolio.name} />
                        )}
                        <div className="data">
                            {props.completeManagedPortfolio.managedPortfolio.url !== null && (
                                <a className="name"
                                    onClick={performTracking}
                                    href={props.completeManagedPortfolio.managedPortfolio.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {props.completeManagedPortfolio.managedPortfolio.name}
                                </a>
                            )}
                            {props.completeManagedPortfolio.managedPortfolio.url === null && (
                                <span className="name">
                                    {props.completeManagedPortfolio.managedPortfolio.name}
                                </span>
                            )}
                            <span className="manager">
                                {translate(props.completeManagedPortfolio.managedPortfolio.manager.name ?? '')}
                            </span>
                        </div>
                        {props.completeManagedPortfolio.managedPortfolio.managedPortfolioRelationship?.name != null && (
                            <div className="relationship">
                                {props.completeManagedPortfolio.managedPortfolio.managedPortfolioRelationship.name}
                                {' '}
                                {translate(props.completeManagedPortfolio.managedPortfolio.manager.name ?? '')}
                            </div>
                        )}
                    </div>
                    <div className="content">
                        <span className="description">
                            {props.completeManagedPortfolio.managedPortfolio.description}
                        </span>
                        <span className="price">
                            {props.completeManagedPortfolio.managedPortfolio.subscription.price !== undefined
                                ? currencyFormatter.format(props.completeManagedPortfolio.managedPortfolio.subscription.price)
                                : ''
                            }/{props.intl.messages[props.completeManagedPortfolio.managedPortfolio.subscription?.period || '']}
                        </span>
                        <span className="risk">
                            Nivel de riesgo: {props.completeManagedPortfolio.modelPortfolio.risk.name}
                        </span>
                        <span className="performance">
                            Rentabilidad bruta: {props.completeManagedPortfolio.performance !== undefined
                                ? percentFormatter.format(props.completeManagedPortfolio.performance / 100)
                                : ''
                            }
                        </span>
                        <span className="risk">
                            Calculado desde: {dateFormatter(props.completeManagedPortfolio.performanceStartDate ?? '')}
                        </span>
                        <Separator />
                        <Checkbox className="cicled"
                            label="Comparar"
                            onChange={(event: any, checked: boolean | undefined) => props._onChange(event, checked, props.completeManagedPortfolio.managedPortfolio.id)}
                            checked={props.checked}
                            checkmarkIconProps={{ iconName: "CircleFill" }}
                            disabled={props.disabled}
                        />
                    </div>
                </div>
            )}
        </L.Wrapper>
    );
};

export default ModelPortfolioItem;
import { useState, useEffect, useContext } from 'react';
import { useBoolean, useConst } from "@fluentui/react-hooks";
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { DefaultButton } from 'office-ui-fabric-react';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import Auth from "../../Auth/Auth";
import { DetailsList, DirectionalHint, IColumn, ITooltipProps, Spinner, SpinnerSize, TeachingBubble, TooltipDelay, TooltipHost } from '@fluentui/react';
import styled from "styled-components";
import { SavingsPlanType, SavingsPlanTypes } from '../../services/SavingsPlanService';
import ManagedPortfolioService, { CompleteManagedPortfolioType, ManagedPortfolioType, PortfolioCompositionItemType } from '../../services/ManagedPortfolioService';
import Highstock, { GraphicType } from "../Highstock/Highstock";
import PieHighstock from "../Highstock/PieHighstock";
import SubscriptionService, { SubscriptionType } from '../../services/SubscriptionService';
import FinametrixService, { RiskType } from '../../services/FinametrixService';
import FirstSteps from "./FirstSteps";
import { useId } from '@fluentui/react-hooks';
import NumberFormat from 'react-number-format';
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { ai } from '../../services/TelemetryService';
import ModelPortfolioItem from '../ModelPortfolio/ModelPortfolioItem';
import ProcessingModalComponent from '../Modals/ProcessingModalComponent';
import ModelPortfolioModalSearch from "../ModelPortfolio/ModelPortfolioModalSearch";
import * as L from '../ModelPortfolio/layout.styles';
import { ThemeContext } from '@fluentui/react-theme-provider';
import { createIntl, createIntlCache } from '@formatjs/intl';
import Spanish from '../../translations/es.json';
import axios from 'axios';
import WarningModalComponent from '../Modals/WarningModalComponent';
import React from 'react';
import { percentFormatter } from '../../utils/numberFormatter';
import { getCdnUrl } from '../../utils/Cdn';

const SaverSavingsPlanContainer = styled.div`
    .options
    {
        .ms-Grid-col {}
    }

    .model-portfolios
    {
        margin-top: 30px !important;
    }

    h2 {
        margin-bottom: 20px !important;
    }

    .description {
        font-size: 20px;
    }

    .number-control {
        margin: 10px 0 15px;
    }
`;

const tooltipProps: ITooltipProps =
{
    onRenderContent: () =>
    (
        <div>Cálculos realizados con la información de los últimos 5 años</div>
    ),
};

const cache = createIntlCache()
const intl = createIntl(
    {
        locale: 'es',
        messages: Spanish,
    },
    cache
)

interface INewSaverSavingsPlanProps
{
    publicVersion: boolean;
    connectionReference: string;
}

function mapStateToProps(state: any, props: any)
{
    return{
        connectionReference: state.ui.connectionReference
    };
}

const NewSaverSavingsPlan = (props: INewSaverSavingsPlanProps) =>
{
    const appInsights = ai.reactPlugin;
    const theme = useContext(ThemeContext);
    const [error, setError] = useState({ message: false });
    const user = Auth.getUserProfile();
    const history = useHistory();
    const [managedPortfolios, setManagedPortfolios] = useState<ManagedPortfolioType[]>([]);
    const [completeManagedPortfolios, setCompleteManagedPortfolios] = useState<CompleteManagedPortfolioType[]>([]);
    const [isLoadedCompleteModelPortfolios, setIsLoadedCompleteModelPortfolios] = useState(false);
    const [selectedModelPortfolios, setSelectedModelPortfolios] = useState<number[]>([]);
    const [selectedCompleteModelPortfolio, setSelectedCompleteModelPortfolio] = useState<CompleteManagedPortfolioType>();
    const [onCoverManagedPortfolios, setOnCoverManagedPortfolios] = useState<number[]>([]);
    const trackClicks = useTrackEvent(appInsights, "Managed Portfolio Link", "");
    const performTracking = () =>
    {
        if(selectedCompleteModelPortfolio !== undefined)
        {
            trackClicks(selectedCompleteModelPortfolio.managedPortfolio.name);
        }
    };
    const [isLoadedManagedPortfolios, setIsLoadedManagedPortfolios] = useState(false);
    const [isLoadedModelPortfolioSerie, setIsLoadedModelPortfolioSerie] = useState(false);
    const [isLoadedModelPortfolioComposition, setIsLoadedModelPortfolioComposition] = useState(false);
    const [isLoadedModelPortfolioDistribution, setIsLoadedModelPortfolioDistribution] = useState(false);
    const [isLoadedRisks, setIsLoadedRisks] = useState(false);
    const [cancelTokens, setCancelTokens] = useState<object>({});
    const [showModelPortfolios, setShowModelPortfolios] = useState(true);
    const [future, setFuture] = useState(0);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [isModalSearchOpen, { setTrue: showModalSearch, setFalse: hideModalSearch }] = useBoolean(false);
    const [isModalWarningOpen, { setTrue: showModalWarning, setFalse: hideModalWarning }] = useBoolean(false);
    const [timeOutId, setTimeOutId] = useState<NodeJS.Timeout>();
    const [subscription, setSubscription] = useState<SubscriptionType>();
    const [risks, setRisks] = useState<RiskType[]>([]);
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>(
    {
        userId: user.id ? user.id : null,
        name: "Plan Gestionado",
        initialContribution: 10000,
        periodicContribution: 0,
        periodicContributionPeriod: 1,
        type: SavingsPlanTypes.Saver,
        status: 0
    });

    const tooltipId = useId('tooltip');

    const highstockOptions = useConst(
    {
        rangeSelector:
        {
            selected: 6,
            buttons: [
            {
                type: 'year',
                count: 1,
                text: '1y'
            },
            {
                type: 'year',
                count: 2,
                text: '2y'
            },
            {
                type: 'year',
                count: 3,
                text: '3y'
            },
            {
                type: 'year',
                count: 4,
                text: '4y'
            },
            {
                type: 'year',
                count: 5,
                text: '5y'
            },
            {
                type: 'ytd',
                text: 'YTD'
            },
            {
                type: 'all',
                text: 'Todo'
            }]
        }
    });

    const columns: IColumn[] =
    [
        {
            key: 'name',
            name: 'Fondo',
            fieldName: 'name',
            minWidth: 300
        },
        {
            key: 'asset_class',
            name: 'Categoría',
            fieldName: 'category',
            minWidth: 200,
            onRender: (item: PortfolioCompositionItemType) =>
            {
                return <span>{item.category}</span>
            },
        },
        {
            key: 'asset_subclass',
            name: 'Subcategoría',
            fieldName: 'subcategory',
            minWidth: 200,
            onRender: (item: PortfolioCompositionItemType) =>
            {
                return <span>{item.subcategory}</span>
            },
        },
        {
            key: 'weight',
            name: 'Peso',
            fieldName: 'weight',
            minWidth: 100,
            onRender: (item: PortfolioCompositionItemType) =>
            {
                return <span>{percentFormatter.format(item.weight/100)}</span>
            },
        }
    ];

    const setModelPortfoliosData = (modelPortfoliosTxt: string) =>
    {
        var auxModelPortfolios = JSON.parse(modelPortfoliosTxt);
        setCompleteManagedPortfolios(auxModelPortfolios);
        var defaultModelPortfolio = auxModelPortfolios.find((mP: CompleteManagedPortfolioType) => mP.managedPortfolio.default);
        setSelectedModelPortfolios([defaultModelPortfolio.managedPortfolio.id]);
        var selectedPortfolio = defaultModelPortfolio;
        setSelectedCompleteModelPortfolio(selectedPortfolio);

        if(auxModelPortfolios.length > 5)
        {
            auxModelPortfolios = auxModelPortfolios.filter((mP: CompleteManagedPortfolioType) => mP.managedPortfolio.showOnCover);
        }
        
        setOnCoverManagedPortfolios(auxModelPortfolios.map((mP: CompleteManagedPortfolioType) => mP.managedPortfolio.id));
        setIsLoadedCompleteModelPortfolios(true);
        setIsLoadedModelPortfolioDistribution(true);
        setIsLoadedModelPortfolioSerie(true);
        setIsLoadedModelPortfolioComposition(true);
    }

    useEffect(() =>
    {
        if(!isLoadedCompleteModelPortfolios)
        {
			var modelPortfoliosTxt = localStorage.getItem("modelPortfolios");
			if(modelPortfoliosTxt !== null)
            {
				setModelPortfoliosData(modelPortfoliosTxt);
			}
            else
            {
				showModal();
				var timeOutId = setTimeout(() => checkStorage(), 30000);
				setTimeOutId(timeOutId);
			}
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(!isLoadedManagedPortfolios)
        {
            ManagedPortfolioService.getManagedPortfolios().then((managedPortolios: ManagedPortfolioType[]) =>
            {
                if(managedPortolios.length > 0)
                {
                    setManagedPortfolios(managedPortolios);
                    setIsLoadedManagedPortfolios(true);
                }
            },
            (error) =>
            {
                setError(error);
                setIsLoadedManagedPortfolios(true);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() =>
    {
        if(!isLoadedRisks)
        {
            FinametrixService.getRisks().then((risks: RiskType[]) =>
            {
                if(risks.length > 0)
                {
                    setRisks(risks);
                    setIsLoadedRisks(true);
                }
            },
            (error) =>
            {
                setError(error);
                setIsLoadedRisks(true);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() =>
    {
        if(subscription === undefined)
        {
            SubscriptionService.getSubscriptionsByType("saver").then((subscriptions: any) =>
            {
                if(subscriptions.length > 0)
                {
                    setSubscription(subscriptions[0]);
                }
            },
            (error) =>
            {
                setError(error);
            });
        }
    }, [subscription]);

    const checkStorage = () =>
    {
        if(!isLoadedCompleteModelPortfolios)
        {
            var modelPortfoliosTxt = localStorage.getItem('modelPortfolios');
            if(modelPortfoliosTxt !== null)
            {
                hideModal();
                setModelPortfoliosData(modelPortfoliosTxt);
                setIsLoadedCompleteModelPortfolios(true);
            }
            else
            {
                ManagedPortfolioService.getManagedPortfoliosForTheCover(props.connectionReference);
            }
        }
    }

    useEffect(() =>
    {
        document.addEventListener('storageChange', () =>
        {
            if(!isLoadedCompleteModelPortfolios)
            {
                var modelPortfoliosTxt = localStorage.getItem('modelPortfolios');
                if(modelPortfoliosTxt !== null)
                {
                    if(timeOutId)
                    {
                        clearTimeout(timeOutId);
                    }
                    setModelPortfoliosData(modelPortfoliosTxt);
                    setIsLoadedCompleteModelPortfolios(true);
                    hideModal();
                    history.push(history.location);
                }
                else
                {
                    console.log("modelPortfoliosTxt is still null");
                }
            }
        }, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() =>
    {
        if(selectedCompleteModelPortfolio !== undefined)
        {
            setSubscription(selectedCompleteModelPortfolio.managedPortfolio.subscription);
        }
    }, [selectedCompleteModelPortfolio]);

    useEffect(() =>
    {
        if(savingsPlan.initialContribution !== undefined && selectedCompleteModelPortfolio !== undefined && selectedCompleteModelPortfolio.performance !== undefined)
        {
            setFuture(savingsPlan.initialContribution * (1 + selectedCompleteModelPortfolio.performance / 100) - (5 * (selectedCompleteModelPortfolio.managedPortfolio.subscription.price ?? 0)));
        }
    }, [selectedCompleteModelPortfolio, savingsPlan.initialContribution]);

    useEffect(() =>
    {
        if(selectedCompleteModelPortfolio !== undefined)
        {
            setSavingsPlan((prevState: SavingsPlanType) =>
            (
                {
                    ...prevState,
                    managedPortfolioId: selectedModelPortfolios[0],
                    managedPortfolio: selectedCompleteModelPortfolio.managedPortfolio
                }
            ))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompleteModelPortfolio]);

    useEffect(() =>
    {
        if(selectedModelPortfolios.length === 1)
        {
            var selectedPortfolio = completeManagedPortfolios.find(cMP => cMP.managedPortfolio.id === selectedModelPortfolios[0]);
            setSelectedCompleteModelPortfolio(selectedPortfolio);
        }
        else
        {
            setSelectedCompleteModelPortfolio(undefined);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedModelPortfolios]);

    const onChangeQuantity = (event: any) =>
    {
        if(event.target?.value !== undefined && !Number.isNaN(parseFloat(event.target.value || '')))
        {
            setSavingsPlan(prevState =>
            ( // @ts-ignore
                { ...prevState, initialContribution: parseFloat(event.target.value.replace(/[.€]/g, "")) }
            ))
        }
    };

    const onChangePeriodicQuantity = (event: any) =>
    {
        setSavingsPlan(prevState =>
        ( // @ts-ignore
            { ...prevState, periodicContribution: parseFloat(event.target.value.replace(/[.€]/g, "")) }
        ))
    };

    const onChangeContributionPeriod = (event: any) =>
    {
        setSavingsPlan(prevState =>
        ( // @ts-ignore
            { ...prevState, periodicContributionPeriod: parseFloat(event.target.value) }
        ))
    };

    const [tooltipManagedPortfolio, setTooltipManagedPortfolio] = useState(false);
    const managedPortfolioTooltip = "Pulsa en el enlace para conocer más acerca de esta cartera";

    const _onChange = (event: any, checked: boolean | undefined, modelPortfolioId: number) =>
    {
        if(checked)
        {
            if(modelPortfolioId !== undefined && !selectedModelPortfolios.includes(modelPortfolioId))
            {
                setSelectedModelPortfolios(prevState =>
                [
                    ...prevState,
                    modelPortfolioId
                ]);
            }
        }
        else
        {
            if(selectedModelPortfolios.length > 1)
            {
                setSelectedModelPortfolios(prevState => prevState.filter(sMP => sMP !== modelPortfolioId));
            }
        }
    };

    const loadCompleteManagedPortfolio = (managedPortfolioId: number) =>
    {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setCancelTokens(prevState =>
        {
            return {
                ...prevState,
                ["portfolio" + managedPortfolioId]: source
            }
        });
        ManagedPortfolioService.getManagedPortfolioForTheCover(managedPortfolioId, source.token).then(async (completeManagedPortfolio: CompleteManagedPortfolioType) =>
        {
            var modelPortfoliosTxt = localStorage.getItem('modelPortfolios');
            if(modelPortfoliosTxt !== null)
            {
                var auxModelPortfolios = JSON.parse(modelPortfoliosTxt);
                auxModelPortfolios.push(completeManagedPortfolio);
                setCompleteManagedPortfolios(auxModelPortfolios);
                localStorage.setItem('modelPortfolios', JSON.stringify(auxModelPortfolios));

                setCancelTokens((prevState: object) =>
                {
                    var index = "portfolio" + managedPortfolioId;
                    // @ts-ignore
                    const { [index]: lost, ...cancelTokensAux } = prevState;
                    return cancelTokensAux;
                });
            }
        },
        (error: any) =>
        {
            setError(error);
        });
    }

    const _onOnCoverManagedPortfoliosChange = (event: any, checked: boolean | undefined, managedPortfolioId: number) =>
    {
        if(checked)
        {
            if(onCoverManagedPortfolios.length < 5)
            {
                if(!onCoverManagedPortfolios.includes(managedPortfolioId))
                {
                    setOnCoverManagedPortfolios(prevState =>
                    [
                        ...prevState,
                        managedPortfolioId
                    ]);

                    var existent = completeManagedPortfolios?.find((completeManagedPortfolio: CompleteManagedPortfolioType) => completeManagedPortfolio.managedPortfolio.id === managedPortfolioId);
                    if(existent === undefined)
                    {
                        loadCompleteManagedPortfolio(managedPortfolioId);
                    }
                }
            }
            else
            {
                showModalWarning();
                setOnCoverManagedPortfolios(prevState => prevState.filter(oCMP => oCMP !== managedPortfolioId));
            }
        }
        else
        {
            if(Object.keys(cancelTokens).includes('portfolio' + managedPortfolioId))
            {
                // @ts-ignore
                var source = cancelTokens['portfolio' + managedPortfolioId];
                source.cancel();
                setCancelTokens((prevState: object) =>
                {
                    var index = "portfolio" + managedPortfolioId;
                    // @ts-ignore
                    const { [index]: lost, ...cancelTokensAux } = prevState;
                    return cancelTokensAux;
                });
            }

            if(onCoverManagedPortfolios.length > 1)
            {
                setOnCoverManagedPortfolios(prevState =>
                [
                    ...prevState.filter(coverManagedPortfolio => coverManagedPortfolio !== managedPortfolioId)
                ]);

                if(selectedModelPortfolios.includes(managedPortfolioId))
                {
                    if(selectedModelPortfolios.length === 1)
                    {
                        var newSelected = onCoverManagedPortfolios.find(oCMP => oCMP !== managedPortfolioId);
                        if(newSelected)
                        {
                            setSelectedModelPortfolios([newSelected]);
                        }
                    }
                    else
                    {
                        setSelectedModelPortfolios(prevState => prevState.filter((sMP) => sMP !== managedPortfolioId));
                    }
                }
            }
        }
    };

    if(error.message)
    {
        return (<div>Error: {error.message}</div>)
    }

    return(
        <DocumentTitle title='IronIA - Nuevo plan de cartera'>
            <SaverSavingsPlanContainer>
                {!props.publicVersion && <Link to="/savingsPlans/new" className="back-link">Volver atrás</Link>}
                <div className="ms-Grid-row savings-plans section-header">
                    <div className="ms-Grid-col ms-md3">
                        <img
                            src={getCdnUrl('iamasaver.webp')}
                            alt='Profesional asesorando a un cliente'
                        />
                    </div>
                    <div className="ms-Grid-col ms-md9">
                        <h2 className="highlighted">Plan Gestionado</h2>
                        <p className="description">
                            Solo tienes que escribir el dinero que te gustaría invertir para hacerte una estimación
                            de la rentabilidad que hubieras obtenido en base a los resultados históricos de los
                            fondos que componen las diferentes carteras.
                        </p>
                        <p className="description">
                            Ten en cuenta que las rentabilidades pasadas no garantizan rentabilidadades futuras.
                        </p>
                        <div className="number-control big-font">
                            <label className="ms-Label">¿Cuál sería tu inversión inicial?</label>
                            <NumberFormat thousandSeparator="."
                                decimalSeparator=","
                                // @ts-ignore
                                label="¿Cuál es tu dinero base?"
                                placeholder="Escribe una cantidad..."
                                value={savingsPlan.initialContribution}
                                suffix="€"
                                className="ms-TextField-field"
                                decimalScale={2}
                                onChange={onChangeQuantity}
                            />
                            {selectedModelPortfolios.length === 1 &&
                            (
                                <React.Fragment>
                                    <span>Con esta inversión hubieses tenido
                                        {!isLoadedModelPortfolioSerie &&
                                        (
                                            <Spinner size={SpinnerSize.small} className="button-spinner" />
                                        )}
                                    </span>
                                    {isLoadedModelPortfolioSerie &&
                                    (
                                        <NumberFormat thousandSeparator="."
                                            decimalSeparator=","
                                            suffix="€"
                                            value={future}
                                            decimalScale={2}
                                            displayType="text"
                                            className="ms-TextField-text"
                                        />
                                    )}
                                    <TooltipHost tooltipProps={tooltipProps}
                                        delay={TooltipDelay.zero}
                                        id={tooltipId}
                                        directionalHint={DirectionalHint.topCenter}
                                        className="ironia-tooltip"
                                    >
                                        <DefaultButton aria-describedby={tooltipId}>
                                            <i className="ironia-icon information" />
                                        </DefaultButton>
                                    </TooltipHost>
                                </React.Fragment>
                            )}
                        </div>
                        <p className="description">
                            El importe que figura estimado refleja la rentabilidad neta que hubieras obtenido al incluir
                            ya descontados los costes de servicio y de los productos (e.g. comisión de gestión) que
                            componen las diferentes carteras.
                        </p>
                    </div>
                </div>
                {isLoadedCompleteModelPortfolios &&
                (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-md12 mt40">
                            <DefaultButton className={"button collapse-link" + (showModelPortfolios ? ' expanded' : '')} onClick={() => setShowModelPortfolios(!showModelPortfolios)}>
                                Comparador perfiles Plan Gestionado
                            </DefaultButton>
                        </div>
                    </div>
                )}
                {showModelPortfolios &&
                (
                    <React.Fragment>
                        {isLoadedCompleteModelPortfolios &&
                        (
                            <div className="ms-Grid-row model-portfolios">
                                {completeManagedPortfolios.filter(cMP => onCoverManagedPortfolios.includes(cMP.managedPortfolio.id)).map((completeManagedPortfolio, completeManagedPortfolioIndex) => (
                                    <ModelPortfolioItem _onChange={_onChange}
                                        key={'completeManagedPortfolio' + completeManagedPortfolioIndex}
                                        completeManagedPortfolio={completeManagedPortfolio}
                                        checked={selectedModelPortfolios.includes(completeManagedPortfolio.managedPortfolio.id)}
                                        intl={intl}
                                        disabled={selectedModelPortfolios.length === 1 && selectedCompleteModelPortfolio?.managedPortfolio.id === completeManagedPortfolio.managedPortfolio.id}
                                    />
                                ))}
                                {Object.keys(cancelTokens).length > 0 &&
                                (
                                    <L.Wrapper className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl2" theme={theme}>
                                        <div className="item loading">
                                            <div className="header">
                                                <div className="data">
                                                </div>
                                            </div>
                                            <div className="content">
                                                <span className="description">
                                                    <Spinner size={SpinnerSize.large} />
                                                </span>
                                            </div>
                                        </div>
                                    </L.Wrapper>
                                )}
                                <div id="add-model-portfolio" className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl2">
                                    <div className="item">
                                        <div className="header">
                                            <h3>Añadir nueva</h3>
                                        </div>
                                        <div className="data">
                                            Busca y añade nuevas propuestas para comparar su rentabilidad
                                            futura con la de las demás carteras seleccionadas.
                                        </div>
                                        <a href={history.location.pathname} className="link"
                                            onClick={(event) =>
                                            {
                                                event.preventDefault();
                                                showModalSearch();
                                            }}>+</a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
                <div className="ms-Grid-row savings-plans options">
                    <Separator className="separator" />
                    <div className="ms-Grid-col ms-sm12">
                        {tooltipManagedPortfolio === true &&
                            <TeachingBubble target="#link"
                                hasCloseButton={false}
                                onDismiss={() => setTooltipManagedPortfolio(tooltipManagedPortfolio)}
                            >
                                {managedPortfolioTooltip}
                            </TeachingBubble>
                        }
                        {isLoadedCompleteModelPortfolios && selectedCompleteModelPortfolio &&
                        (
                            <h3>Cartera de inversiones recomendada:
                                {selectedCompleteModelPortfolio?.managedPortfolio.url !== null &&
                                (
                                    <a id="link" onMouseEnter={() => setTooltipManagedPortfolio(true)} onMouseLeave={() => setTooltipManagedPortfolio(false)}
                                        onClick={performTracking}
                                        href={selectedCompleteModelPortfolio?.managedPortfolio.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {selectedCompleteModelPortfolio?.managedPortfolio.name}
                                    </a>
                                )}
                                {selectedCompleteModelPortfolio?.managedPortfolio.url === null &&
                                (
                                    <React.Fragment>
                                        {selectedCompleteModelPortfolio?.managedPortfolio.name}
                                    </React.Fragment>
                                )}
                            </h3>
                        )}
                        {(!isLoadedCompleteModelPortfolios || selectedCompleteModelPortfolio === undefined) &&
                        (
                            <h3>Carteras de inversiones seleccionadas:</h3>
                        )}
                        {!isLoadedModelPortfolioSerie &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        {isLoadedModelPortfolioSerie &&
                        (
                            <Highstock graphicType={GraphicType.linearGraphic}
                                series=
                                {
                                    completeManagedPortfolios.filter(cMP => selectedModelPortfolios.includes(cMP.managedPortfolio.id))
                                        .map(cMP => cMP.serie)
                                        // @ts-ignore
                                        .reduce((pre, cur) => { return pre.concat(cur); })
                                }
                                customOptions={highstockOptions}
                            />
                        )}
                    </div>
                </div>
                {selectedCompleteModelPortfolio &&
                (
                    <React.Fragment>
                        <Separator className="separator" />
                        <div className="ms-Grid-row savings-plans options">
                            <div className="ms-Grid-col ms-sm8">
                                {!isLoadedModelPortfolioComposition &&
                                (
                                    <Spinner size={SpinnerSize.large} />
                                )}
                                {isLoadedModelPortfolioComposition &&
                                (
                                    <DetailsList
                                        // @ts-ignore
                                        items={selectedCompleteModelPortfolio.composition}
                                        columns={columns}
                                        selectionMode={0}
                                    />
                                )}
                            </div>
                            <div className="ms-Grid-col ms-md4">
                                <h3 className="text-center">Tipología de inversiones</h3>
                                {!isLoadedModelPortfolioDistribution &&
                                (
                                    <Spinner size={SpinnerSize.large} />
                                )}
                                {isLoadedModelPortfolioDistribution &&
                                (
                                    <PieHighstock chartSerie={selectedCompleteModelPortfolio.distribution} options={{showSubcategories: true}} />
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )}
                <Separator className="separator" />
                <div className="ms-Grid-row savings-plans options">
                    <FirstSteps savingsPlan={savingsPlan}
                        subscription={subscription}
                        isSavingsPlanReady={selectedModelPortfolios.length === 1}
                        onChangeQuantity={onChangeQuantity}
                        onChangePeriodicQuantity={onChangePeriodicQuantity}
                        onChangeContributionPeriod={onChangeContributionPeriod}
                        publicVersion={props.publicVersion}
                        user={user}
                        savingsPlanIsCompleted={true}
                        isDisabled={selectedModelPortfolios.length !== 1}
                    />
                </div>
                <ProcessingModalComponent isModalOpen={isModalOpen}
                    hideModal={hideModal}
                    blocking={true}
                    title='Recuperando información'
                    description='Estamos recuperando los datos de las carteras modelo'
                />
                <ModelPortfolioModalSearch isModalOpen={isModalSearchOpen}
                    hideModal={hideModalSearch}
                    managedPortfolios={managedPortfolios}
                    onCoverManagedPortfolios={onCoverManagedPortfolios}
                    risks={risks}
                    _onChange={_onOnCoverManagedPortfoliosChange}
                    theme={theme}
                    intl={intl}
                />
                <WarningModalComponent isModalOpen={isModalWarningOpen}
                    hideModal={hideModalWarning}
                    blocking={false}
                    title='Ya hay 5 carteras seleccionadas'
                    description='Debe deseleccionar alguna de ellas para poder añadir otras'
                    theme={theme}
                />
            </SaverSavingsPlanContainer>
        </DocumentTitle>
    );
}

export default connect(mapStateToProps)(NewSaverSavingsPlan);
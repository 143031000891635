import { Link } from "react-router-dom";
import DocumentTitle from 'react-document-title';
import styled from "styled-components";
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { ReactComponent as ExperienceImage } from '../../images/i_have_experience_cropped.svg';
import { ResourcesHelpPlans } from "../../resources/ResourcesHelpPlans";
import { useState } from "react";
import { TeachingBubble } from "@fluentui/react";
import { getCdnUrl } from "../../utils/Cdn";

const NewSavingsPlanContainer = styled.div`
    .ms-Grid-row
    {
        .ms-Grid-col
        {
            padding-right: 32px;

            .shadow
            {
                position: relative;
                padding: 0 0 100px 0;
                border-radius: 24px;
                height: 100%;

                > svg
                {
                    width: 100%;
                    height: auto;
                }

                h3, p
                {
                    margin: 0 32px;
                }

                h3
                {
                    font-size: 24px;
                    font-weight: 800;
                    line-height: 32px;
                    margin: 16px 32px 8px;
                }

                p
                {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 24px;
                }

                > a
                {
                    width: 100%;
                    margin-top: 32px;
                    display: block;
                    box-sizing: border-box;
                    border-radius: 0 0 24px 24px;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 500 !important;
                    position: absolute;
                    bottom: 0;
                    max-width: unset;
                    margin: 0;
                }
            }
        }
    }
`;

const NewSavingsPlan = () =>
{
    const [tooltipSavingPlan, setTooltipSavingPlan] = useState(false);
    const savingPlanTooltip = ResourcesHelpPlans.PLANES_AHORRO;
    const [isAllowedTooltip, setIsAllowedTooltip] = useState(true);

    const handleDismissTooltip = () =>
    {
        var cookies = document.cookie;

        if(!cookies.includes("tooltip"))
        {
            document.cookie = "tooltipShowing=0; expires=Thu, 25 May 2032 12:00:00 UTC; path =/";
            cookies = document.cookie;
        }

        var allCookies = cookies.split(";");
        var elementAt: number = allCookies.findIndex((element) => element.includes("tooltipShowing"));

        //@ts-ignore
        var beforeTrim = allCookies[elementAt].trim();
        var numberOfTimes = beforeTrim[15];

        if((numberOfTimes === "1" && beforeTrim[16] !== undefined) || (numberOfTimes === "2" && beforeTrim[16] !== undefined))
        {
            numberOfTimes = numberOfTimes + beforeTrim[16];
        }

        if(numberOfTimes !== undefined && parseInt(numberOfTimes) >= 25)
        {
            setIsAllowedTooltip(false);
        }

        if(numberOfTimes !== undefined && parseInt(numberOfTimes) < 25)
        {
            document.cookie = "tooltipShowing=" + ((parseInt(numberOfTimes)) + 1) + "; expires=Thu, 25 May 2032 12:00:00 UTC; path =/";
        }

        setTooltipSavingPlan(false);
    }

    return (
        <DocumentTitle title='IronIA - Nuevo plan de cartera'>
            <NewSavingsPlanContainer className="ms-Grid-row savings-plans options">
                {tooltipSavingPlan === true &&
                    <TeachingBubble target="#savingPlanTitle h2"
                        hasCloseButton={true}
                        headline="Planes de Cartera"
                        onDismiss={() => handleDismissTooltip()}
                    >
                        {savingPlanTooltip}
                    </TeachingBubble>
                }
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12" id="savingPlanTitle">
                        <h2 style={{margin: 0}} onMouseEnter={() => setTooltipSavingPlan(isAllowedTooltip)}>Selecciona un Plan de cartera</h2>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12">
                        <p className="description">Los planes de cartera te permiten decidir como quieres obtener tu cartera de inversión.</p>
                    </div>
                </div>
                <Separator />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 ms-xxl6 ms-xxxl3 item">
                        <div className="shadow">
                            <div className="image-container">
                                <img
                                    src={getCdnUrl('iamasaver.webp')}
                                    alt='Profesional asesorando a un cliente'
                                />
                            </div>
                            <h3>Plan Gestionado</h3>
                            <p>Los profesionales de Diaphanum crearán y mantendrán tu cartera por tí sin que tengas que preocuparte de nada más.</p>
                            <Link to='/savingsPlans/iAmASaver' className="button primary-button">Seleccionar</Link>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 ms-xxl6 ms-xxxl3 item">
                        <div className="shadow">
                            <div className="image-container">
                                <img
                                    src={getCdnUrl('ihaveexperience.webp')}
                                    alt='Cliente con experiencia'
                                />
                            </div>
                            <h3>Plan Libertad</h3>
                            <p>Tú creas tu propia cartera seleccionando las inversiones que deseas con total libertad.</p>
                            <Link to='/savingsPlans/iHaveExperience' className="button primary-button">Seleccionar</Link>
                        </div>
                    </div>
                </div>
            </NewSavingsPlanContainer>
        </DocumentTitle>
    );
}

export default NewSavingsPlan;
import {
    ERROR,
    LOADING,
    PROFILE,
    AUTHENTICATED,
    ROLES,
    TOKEN,
    COMPLETE_USER_DATA,
    CHECK_SUBSCRIPTIONS,
    CHECK_PORTFOLIOS,
    USER_ACTIVE_PLAN,
    SAVINGS_PLAN_ELEMENTS_STATUS
} from './Constants';
import Auth from "../../Auth/Auth";

export const INITIAL_STATE = {
  user: {
    email: localStorage.getItem("email"),
    oid: localStorage.getItem("oid")
  },
  loading: false,
  token: "",
  expiresAt: "",
  error: "",
  isAuthenticated: Auth.isAuthenticated(),
  roles:[],
  loginFaile: false,
  subscriptions: [],
  portfolios: [],
  outdatedContract: false,
  savingsPlanElementsStatus: {
    isSubscribed: false,
    isOutdatedContract: false,
    sync: false
  }
};

export default function authReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case PROFILE:
      return {...state, user: action.payload};
    case LOADING:
      return {...state, loading: action.payload};
    case TOKEN:
      return {...state, token: action.payload};
    case ERROR:
      return {...state, error:action.payload};
    case AUTHENTICATED:
      return {...state, isAuthenticated:action.payload};
    case ROLES:
      return {...state, roles:action.payload};
    case COMPLETE_USER_DATA:
      return { ...state, user: { ...state.user, ...action.payload } };
    case CHECK_SUBSCRIPTIONS:
      return { ...state, subscriptions: action.payload };
    case CHECK_PORTFOLIOS:
      return { ...state, portfolios: action.payload };
    case USER_ACTIVE_PLAN:
      return { ...state, ...action.payload };
    case SAVINGS_PLAN_ELEMENTS_STATUS:
      return { ...state, savingsPlanElementsStatus: action.payload };
    default:
      return state;
  }
};

import { ReactElement, useEffect, useState } from 'react';
import styles from './index.module.sass';
import FundService, { FundWithExtraDataType } from '../../../services/FundService';
import OutsideClickHandler from 'react-outside-click-handler';
import { Checkbox, DefaultButton, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import debounce from 'lodash.debounce';
import { ChevronUpMedIcon } from '@fluentui/react-icons-mdl2';
import React from 'react';
interface OptionType
{
    label: string,
    value: string
}
const icons =
{
    closed: <ChevronUpMedIcon />,
    opened: <i className="ironia-icon reports" />
}
interface MultiFundsProps
{
    fund?: FundWithExtraDataType,
    siblings?: OptionType[],
    scatterGraphic?: boolean,
    // eslint-disable-next-line no-empty-pattern
    onSave: ([]) => void
}

export default function MultiFundsAndBenchmarksDropDown(props: MultiFundsProps): ReactElement
{
    const [searchTerm, setSearchTerm] = useState<string>();
    const [options, setOptions] = useState<OptionType[]>();
    const [error, setError] = useState({status: false, message: ''});
    const [benchmarks, setBenchmarks] = useState<OptionType[]>([]);
    const [isLoadedBenchmarks, setIsLoadedBenchmarks] = useState(false);
    const [defaultFunds, setDefaultFunds] = useState<OptionType[]>([]);
    const [isLoadedOptions, setIsLoadedOptions] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState<OptionType[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [optionsWithSelectedFirst, setOptionsWithSelectedFirst] = useState<OptionType[]>([]);

    useEffect(() =>
    {
        if(props.scatterGraphic === undefined || props.scatterGraphic === false)
        {
            FundService.getBenchmarks().then((benchmarks) =>
            {
                setBenchmarks(benchmarks.map((benchmark: any) =>
                {
                    return{
                        label: benchmark.name,
                        value: benchmark.finametrixId
                    }
                }));
            },
            (error) => setError(error)).finally(() => setIsLoadedBenchmarks(true));
        }
        else
        {
            setIsLoadedBenchmarks(true);
        }
    }, [props.scatterGraphic]);

    const mixOptions = (optionsA: OptionType[], optionsB: OptionType[]) =>
    {
        var options = optionsA.concat(optionsB).sort((a: OptionType, b: OptionType) =>
        {
            return a.label.localeCompare(b.label, 'es', {sensitivity: 'base'});
        });
        return options;
    }

    useEffect(() =>
    {
        if(isLoadedBenchmarks && (props.scatterGraphic === undefined || props.scatterGraphic === false))
        {
            var funds = mixOptions(benchmarks, props.siblings as OptionType[]);
            setDefaultFunds(funds);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadedBenchmarks]);

    useEffect(() =>
    {
        if(props.scatterGraphic === true)
        {
            if(searchTerm !== undefined && searchTerm !== '')
            {
                setIsLoadedOptions(false);
                FundService.getSearchFunds(searchTerm.toUpperCase(), 25).then((filteredFunds) =>
                {
                    const filteredFundsOptions = filteredFunds.map((fund: any) =>
                    {
                        return{
                            label: fund.name,
                            value: fund.finametrixId
                        }
                    });
    
                    setOptions(filteredFundsOptions);
                })
                .catch((error) => setError(error))
                .finally(() => setIsLoadedOptions(true));
            }
        }
        else
        {
            if(defaultFunds.length > 0)
            {
                if(searchTerm === undefined || searchTerm === '')
                {
                    // eslint-disable-next-line array-callback-return
                    const filteredDefaultFunds = defaultFunds.filter((item: OptionType) =>
                    {
                        if(item !== undefined)
                        {
                            return !selectedKeys.includes(item.value)
                        }
                    });
                    const funds = selectedItems.concat(filteredDefaultFunds);
                    setOptions(funds);
                }
                else
                {
                    setIsLoadedOptions(false);
                    FundService.getSearchFunds(searchTerm.toUpperCase(), 25).then((filteredFunds) =>
                    {
                        const filteredFundsOptions = filteredFunds.map((fund: any) =>
                        {
                            return{
                                label: fund.name,
                                value: fund.finametrixId
                            }
                        });
    
                        const filteredBenchmarks = benchmarks.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase())); 
                        var funds = mixOptions(filteredBenchmarks, filteredFundsOptions);
                        setOptions(funds);
                    })
                    .catch((error) => setError(error))
                    .finally(() => setIsLoadedOptions(true));
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultFunds, searchTerm]);

    useEffect(() =>
    {
        if(props.scatterGraphic === true)
        {
            if(options !== undefined)
            {
                const filteredOptions = options.filter((item: OptionType) => !selectedKeys.includes(item.value))
                const optionsWithSelectedFirst = selectedItems.concat(filteredOptions);
                setOptionsWithSelectedFirst(optionsWithSelectedFirst);
                setIsLoadedOptions(true);
            }
            else
            {
                setIsLoadedOptions(true);
            }
        }
        else
        {
            if(options !== undefined)
            {
                const filteredOptions = options.filter((item: OptionType) => !selectedKeys.includes(item.value))
                const optionsWithSelectedFirst = selectedItems.concat(filteredOptions);
                setOptionsWithSelectedFirst(optionsWithSelectedFirst);
                setIsLoadedOptions(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, selectedKeys, props.scatterGraphic]);

    useEffect(() =>
    {
        if(selectedItems !== undefined)
        {
            const keys = selectedItems.map((item) => item.value);
            setSelectedKeys(keys);
        }
    }, [selectedItems]);

    const handleChange = ({label, value}: {label: string, value: string}) =>
    {
        if(props.scatterGraphic === true)
        {
            if(selectedItems.length === 0)
            {
                setSelectedItems([{label, value}].sort((a: OptionType, b: OptionType) =>
                {
                    return a.label.localeCompare(b.label, 'es', {sensitivity: 'base'});
                }));
            }
            else
            {
                setSelectedItems([{label, value}].sort((a: OptionType, b: OptionType) =>
                {
                    return a.label.localeCompare(b.label, 'es', {sensitivity: 'base'});
                }));
            }
        }
        else
        {
            if(selectedItems.some((item) => item.value === value))
            {
                let selectedItemsWithoutUnselected = selectedItems.filter((item) => item.value !== value);
                setSelectedItems([...selectedItemsWithoutUnselected]);
            }
            else
            {
                setSelectedItems([...selectedItems, { label, value }].sort((a: OptionType, b: OptionType) =>
                {
                    return a.label.localeCompare(b.label, 'es', {sensitivity: 'base'});
                }));
            }
        }
    }
    return(
        <div className={styles.customSelector}>
            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                <div className={styles.head}>
                    {props.scatterGraphic === true &&
                    (
                        <TextField className={styles.inputText} borderless={true} onFocus={() => setIsOpen(true)}
                            placeholder='Escribe aquí el nombre del fondo o su ISIN...'
                            defaultValue={searchTerm}
                            autoComplete='off'
                            onChange={debounce((event) =>
                            {
                                setSearchTerm(event.target.value);
                            }, 1000)}
                        />
                    )}
                    {(props.scatterGraphic === undefined || props.scatterGraphic === false) &&
                    (
                        <TextField className={styles.inputText} borderless={true} onFocus={() => setIsOpen(true)}
                            placeholder='Escribe aquí el nombre del fondo, índice o ISIN para buscar en la lista ...'
                            defaultValue={searchTerm}
                            onChange={debounce((event) =>
                            {
                                setSearchTerm(event.target.value);
                            }, 1000)}
                        />
                    )}
                    {isOpen ? icons.closed : icons.opened}
                </div>
                {isOpen &&
                (
                    <React.Fragment>
                        {props.scatterGraphic === true &&
                        (
                            <div className={styles.menu}>
                                <div className={styles.menuBody}>
                                    {!isLoadedOptions && <Spinner size={SpinnerSize.medium} />}
                                    {isLoadedOptions &&
                                    (
                                        <React.Fragment>
                                            {optionsWithSelectedFirst.length === 0 &&
                                            (
                                                <p style={{cursor: "default"}}>Esperando búsqueda...</p>
                                            )}
                                            {optionsWithSelectedFirst.length > 0 &&
                                            (
                                                optionsWithSelectedFirst.map(({label, value}, i) =>
                                                (
                                                    <Checkbox key={i} className={styles.checkBox} label={label} value={value}
                                                        onChange={() => handleChange({label, value})}
                                                        checked={selectedItems.some((item) => item.value === value)}
                                                    />
                                                ))
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className={styles.menuFooter}>
                                    <DefaultButton className="button tiny-primary-button" onClick={() =>
                                        {
                                            setSelectedItems([]);
                                            setOptionsWithSelectedFirst([]);
                                            props.onSave([]);
                                        }}
                                    >
                                        Borrar
                                    </DefaultButton>
                                    <button className="button tiny-primary-button" onClick={() =>
                                        {
                                            props.onSave(selectedItems);
                                            setIsOpen(false);
                                        }}
                                    >
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        )}
                        {props.scatterGraphic === false &&
                        (
                            <div className={styles.menu}>
                                <div className={styles.menuBody}>
                                    {!isLoadedOptions && <Spinner size={SpinnerSize.medium} />}
                                    {isLoadedOptions &&
                                    (
                                        <React.Fragment>
                                            {optionsWithSelectedFirst.length > 0 ?
                                            (
                                                optionsWithSelectedFirst.map(({label, value}, i) =>
                                                (
                                                    <Checkbox key={i} className={styles.checkBox} label={label} value={value}
                                                        onChange={() => handleChange({label, value})}
                                                        checked={selectedItems.some((item) => item.value === value)}
                                                    />
                                                ))
                                            ) :
                                            (
                                                <p style={{cursor: "default"}}  >No se han encontrado resultados</p>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className={styles.menuFooter}>
                                    <DefaultButton className="button tiny-primary-button" onClick={() =>
                                        {
                                            setSelectedItems([]);
                                            props.onSave([]);
                                        }}
                                    >
                                        Borrar
                                    </DefaultButton>
                                    <button className="button tiny-primary-button" onClick={() =>
                                        {
                                            props.onSave(selectedItems);
                                            setIsOpen(false);
                                        }}
                                    >
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </OutsideClickHandler>
        </div>
    );
}
import styled from 'styled-components';

export const TableWrapper = styled.div`
    background: ${(props) => props.theme.palette.white};
    border-radius: 16px;
    box-shadow: 0px 0px 16px rgba(8, 84, 117, 0.08);
    padding: 25px 20px;
    margin-bottom: 20px;

    @media screen and (min-width: 1200px)
    {
        margin: 0 auto 20px auto;
    }
`;
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
    h1
    {
        margin: 0;
    }
`;
export const DateContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    div
    {
        border-color: ${(props) => props.theme.palette.black};
        display: flex;
        flex-direction: column;
        margin-top: 1px;
        input
        {
            height: 100%;
            background: none !important;
            padding: 0 0 0 5px;
        }
    }
`;
export const WarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: ${(props) => props.theme.palette.warnSecondary};
    color: ${(props) => props.theme.palette.warn};
    padding: 30px;
    gap: 10px;
    font-weight: 500;
    h3
    {
        margin: 0;
    }
    p
    {
        line-height: 20px;
        margin: 0;
    }
`;
import { useState, useEffect } from 'react';
import Moment from 'moment';
import { addYears, DatePicker, DayOfWeek, Stack, TextField } from '@fluentui/react';
import Select from 'react-dropdown-select';
import CountryPrefixes from '../../utils/CountryPrefixes';
import { UserIdentityType } from '../../services/UserIdentitiesService';
import { useConst } from '@fluentui/react-hooks';

//TODO: Reeditar componentes
const DayPickerStrings = {
    months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Augosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ],
    shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    shortDays: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    goToToday: 'Ir a hoy',
    prevMonthAriaLabel: 'Ir al mes anterior',
    nextMonthAriaLabel: 'Ir al siguiente mes',
    prevYearAriaLabel: 'Ir al año anterior',
    nextYearAriaLabel: 'Ir al año siguiente',
    closeButtonAriaLabel: 'Cerrar selector de fecha',
    monthPickerHeaderAriaLabel: '{0}, selecciona para cambiar el año',
    yearPickerHeaderAriaLabel: '{0}, selciona para cambiar el mes',
    isRequiredErrorMessage: 'La fecha de nacimiento es obligatoria.',
    invalidInputErrorMessage: 'Formato de fecha no válido.',
    isOutOfBoundsErrorMessage: 'El titular principal debe ser mayor de edad'
};
const stackTokens = { childrenGap: 50 };
const columnProps = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 432 } },
};

const regExp = RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);
const onFormatDate = (date?: Date) => {
    return !date ? '' : Moment(date).format('DD/MM/YYYY');
};

const UserForm = (props: any) => {
    var country = CountryPrefixes.find((country) => country.iso3 === (props.user.mainUserIdentity?.countryCode !== undefined ? props.user.mainUserIdentity?.countryCode : 'ESP'));
    const today = useConst(new Date(Date.now()));
    const maxDate = useConst(addYears(today, -18));

    const [userIdentity, setUserIdentity] = useState<UserIdentityType>({
        firstName: props.user.mainUserIdentity?.firstName !== undefined ? props.user.mainUserIdentity.firstName : '',
        lastName: props.user.mainUserIdentity?.lastName || '',
        secondLastName: props.user.mainUserIdentity?.secondLastName || '',
        birthday: props.user.mainUserIdentity?.birthday ? new Date(props.user.mainUserIdentity.birthday) : undefined,
        email: props.user.email,
        countryPrefix: country !== undefined ? country.phone_code : '+34',
        phone: props.user.mainUserIdentity?.phone || '',
        status: props.user.mainUserIdentity?.status
    });

    const [errors, setErrors] = useState<any>({});

    const onParseDateFromString = (value: any) => {
        var parts: string[] = value.split('/');
        var myDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
        return new Date(myDate)
    };

    const formDateChange = (value: Date | null | undefined) => {
        if(value === null) {
            setErrors((prevState: any) => ({
                ...prevState,
                birthday: "La fecha de nacimiento no puede estar vacía"
            }));
            return;
        }
        else {
            var currentDate = Moment();
            var birthdayDate = Moment(value);
            if(currentDate.diff(birthdayDate, 'years') < 18) {
                setErrors((prevState: any) => ({
                    ...prevState,
                    birthday: "El titular principal debe ser mayor de edad"
                }));
            }
            else {
                // @ts-ignore
                delete errors.birthday;
                setErrors(() => ({
                    ...errors
                }));

                setUserIdentity(prevState => ({
                    ...prevState,
                    birthday: value || new Date()
                }));
            }
        }
    };

    const formValChange = (e: any) => {
        const name:string = e.target.name;
        const value:string = e.target.value;

        switch(name) {
            case "firstName":
            case "lastName":
            case "secondLastName":
                if(value.length < 3) {
                    setErrors((prevState: any) => ({
                        ...prevState,
                        [name]: "Debe contener al menos 2 caracteres"
                    }));
                }
                else {
                    // @ts-ignore
                    delete errors[name];
                    setErrors(() => ({
                        ...errors
                    }));
                }
                break;

            case "email":
                if(regExp.test(value)) {
                    setErrors((prevState: any) => ({
                        ...prevState,
                        email: "Formato no válido"
                    }));
                }
                else {
                    // @ts-ignore
                    delete errors.email;
                    setErrors(() => ({
                        ...errors
                    }));
                }
                break;

            case "countryPrefix":
                if(value.length === 0) {
                    setErrors((prevState: any) => ({
                        ...prevState,
                        countryCode: "Debes seleccionar un prefijo"
                    }));
                }
                else {
                    // @ts-ignore
                    delete errors.countryCode;
                    setErrors(() => ({
                        ...errors
                    }));
                }
                break;

            case "phone":
                if(value.length < 9) {
                    setErrors((prevState: any) => ({
                        ...prevState,
                        phone: "Debe contener al menos 9 dígitos"
                    }));
                }
                else {
                    // @ts-ignore
                    delete errors.phone;
                    setErrors(() => ({
                        ...errors
                    }));
                }
                break;

            default:
                break;
        }

        setUserIdentity(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        var someEmptyField = Object.values(userIdentity).some((value) => { return value === "" });
        var someFieldError = Object.keys(errors).length > 0;
        props.setIsButtonDisabled(someEmptyField || someFieldError);

        if(!someEmptyField || !someFieldError) {
            props.setUserIdentity(userIdentity);
        }
    }, [errors]);

    return (
        <form onSubmit={() => formValChange} noValidate>
            <Stack tokens={stackTokens}>
                <Stack horizontal {...columnProps}>
                    <Stack.Item grow>
                        <TextField
                            label="Nombre"
                            required
                            placeholder="Ingresa el nombre"
                            name="firstName"
                            value={userIdentity.firstName}
                            onChange={formValChange}
                            errorMessage={'firstName' in errors ? errors['firstName'] : undefined}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            label="Primer Apellido"
                            required
                            placeholder="Ingresa el primer apellido"
                            name="lastName"
                            value={userIdentity.lastName}
                            onChange={formValChange}
                            errorMessage={'lastName' in errors ? errors.lastName : undefined}
                        />
                    </Stack.Item>
                </Stack>
                <Stack horizontal {...columnProps}>
                    <Stack.Item grow>
                        <TextField
                            label="Segundo Apellido"
                            required
                            placeholder="Ingresa el segundo apellido"
                            name="secondLastName"
                            value={userIdentity.secondLastName}
                            onChange={formValChange}
                            errorMessage={'secondLastName' in errors ? errors.secondLastName : undefined}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <DatePicker
                            placeholder="DD/MM/AAAA"
                            label="Fecha de nacimiento"
                            ariaLabel="La fecha de nacimiento es obligatoria"
                            isRequired={true}
                            allowTextInput={true}
                            // @ts-ignore
                            name="birthday"
                            value={userIdentity.birthday}
                            firstDayOfWeek={DayOfWeek.Monday}
                            onSelectDate={(date) => formDateChange(Moment(date).subtract(date?.getTimezoneOffset(), "minutes").toDate())}
                            formatDate={onFormatDate}
                            parseDateFromString={onParseDateFromString}
                            strings={DayPickerStrings}
                            maxDate={maxDate}
                        />
                    </Stack.Item>
                </Stack>
                <Stack {...columnProps}>
                    <TextField
                        label="Correo electrónico"
                        required
                        placeholder="Ingresa el correo electrónico"
                        name="email"
                        value={userIdentity.email}
                        onChange={formValChange}
                        errorMessage={'email' in errors ? errors.email : undefined}
                        readOnly={true}
                    />
                </Stack>
                <Stack horizontal {...columnProps}>
                    <Stack.Item>
                        <label className="react-dropdown-select-label">Prefijo (país) *</label>
                        <Select
                            labelField='name'
                            name="countryCode"
                            options={CountryPrefixes}
                            onChange={(value: any) => formValChange({ target: { name: 'countryPrefix', value: value[0].phone_code } }) }
                            dropdownHeight='2000'
                            noDataLabel={'No encontrado'}
                            backspaceDelete={false}
                            values={CountryPrefixes.filter((countryPrefix) => countryPrefix.phone_code === userIdentity.countryPrefix)}
                            placeholder=''
                            itemRenderer={({ item, methods }) => (
                                <span
                                    role="option"
                                    aria-selected="false"
                                    aria-label={item.name + ' ' + item.phone_code}
                                    // @ts-ignore
                                    tabIndex="-1"
                                    className="react-dropdown-select-item"
                                    onClick={() => methods.addItem(item)}
                                    key={'operation-country-prefix-' + item.iso3}
                                >
                                    {item.name + ' ' + item.phone_code}
                                </span>
                            )}
                            searchable={true}
                            searchBy="text"
                        />
                    </Stack.Item>
                    <Stack.Item grow={3}>
                        <TextField
                            label="Número teléfono móvil"
                            required
                            placeholder="Introduzca el teléfono móvil"
                            name="phone"
                            value={userIdentity.phone}
                            onChange={formValChange}
                            errorMessage={'phone' in errors ? errors.phone : undefined}
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
        </form>
    );
};

export default UserForm;
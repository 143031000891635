import { Callout, DefaultButton, DelayedRender, Text } from "@fluentui/react";
import { CopyIcon } from '@fluentui/react-icons-mdl2';
import styles from './index.module.sass';
import { useBoolean, useId } from "@fluentui/react-hooks";
import { useEffect } from "react";
import { translate } from '../../../utils/i18n';

export interface IAdditionalInformation
{
    lastPrice: any,
    isin: any,
    currency: any,
    manager: any,
    ucits: any,
    switchable: any,
    currencyHedge: any
}

export const AdditionalInformation = (props: IAdditionalInformation) =>
{
    const [isCalloutVisible, { setTrue: showCallout, setFalse: hideCallout }] = useBoolean(false);
    const buttonId = useId('callout-button');

    useEffect(() => {
        const timer = setTimeout(() => {
            hideCallout();
        }, 1000);
        return () => clearTimeout(timer);
    }, [isCalloutVisible]);

    return(
        <table style={{marginTop: "1em"}}>
            <tbody>
                <tr>
                    <td>
                        <strong>ISIN</strong>
                    </td>
                    <td>
                        <strong>{props.isin}</strong>
                        <DefaultButton
                            id={buttonId}
                            className={styles.copyButton}
                            onClick={() => {
                                showCallout();
                                navigator.clipboard.writeText(props.isin);
                            }}
                        >
                            <CopyIcon />
                        </DefaultButton>
                        {isCalloutVisible && (
                            <Callout
                                className={styles.callout}
                                target={`#${buttonId}`}
                                onDismiss={hideCallout}
                                isBeakVisible={false}
                                role="alert"
                            >
                                <DelayedRender>
                                    <Text variant="small">
                                        ISIN copiado
                                    </Text>
                                </DelayedRender>
                            </Callout>
                        )}
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Fecha del Último Precio</strong>
                    </td>
                    <td>
                        <strong>
                            {Intl.DateTimeFormat("es-ES",
                                {
                                    year: "numeric",
                                    day: "2-digit",
                                    month: "2-digit",
                                }).format(new Date(props.lastPrice.date))
                            }
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Último Precio</strong>
                    </td>
                    <td>{parseFloat(props.lastPrice.value).toFixed(2)+" "+props.currency}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Divisa</strong>
                    </td>
                    <td>{props.currency}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Divisa Cubierta</strong>
                    </td>
                    <td>{props.currencyHedge === true ? "Sí": "No"}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Gestora</strong>
                    </td>
                    <td>{translate(props.manager)}</td>
                </tr>
                <tr>
                    <td>
                        <strong>UCITS</strong>
                    </td>
                    <td>{props.ucits ? "Si" : "No"}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Traspasable</strong>
                    </td>
                    <td>{props.switchable ? "Si" : "No"}</td>
                </tr>
            </tbody>
        </table>
    );
};
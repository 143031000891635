import { useContext, useEffect, useRef, useState } from 'react';
import * as L from './layout.styles';
import { IChoiceGroupOption, TextField, ChoiceGroup, Stack, SpinnerSize, Spinner, Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import { Redirect, RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { InvestmentFundFinder } from "./components/InvestmentFundFinder";
import fundService, { TransferEstimationDates, TransferSimulationManagerInfo } from "../../services/FundService";
import { ThemeContext } from '@fluentui/react-theme-provider';
import Auth from "../../Auth/Auth";
import { useForm, Controller } from "react-hook-form";
import OrderService, { OrderType } from '../../services/OrderService';
import FinametrixService from '../../services/FinametrixService';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { TransferTimeLine } from './components/TransferTimeLine';
import { currencyFormatter, decimalFormatter } from '../../utils/numberFormatter';
import styled from 'styled-components';
import { ReactComponent as IncrementIcon } from '../../images/increment.svg';
import { ReactComponent as DecrementIcon } from '../../images/decrement.svg';
import moment from 'moment';

interface RouteParams {}
interface IInternalSitchFormScreenProps extends RouteComponentProps<RouteParams> {}

const amountTypeOptions: IChoiceGroupOption[] = [
    { key: "0", text: 'Por importe', value: "amount" },
    { key: "1", text: 'Por participaciones', value: "titles" },
    { key: "2", text: 'Total', value: "total" },
];

const InternalSwitchCardContainer = styled.div`
  background: ${props =>  props?.theme?.palette?.white };
  width: 150px;
  border-radius: 16px;
  box-shadow: 0.5px 1px 2px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin-right: 10px;
  margin-top: 15px
`;

const InternalSwitchCardTitle = styled.h4`
  padding-bottom: 10px;
  color: #cc2250;
`;

const InternalSwitchCardContent = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  font-size: 20px;
`;

const InternalSwitchCardFooter = styled.div`
  color: #cc2250;
`;

const dropdownStyles: Partial<IDropdownStyles> = {
	caretDown: {
		fontSize: "14px !important",
		fontWeight: 400,
		borderColor: "trasparent"
	},
	dropdownItems: {
		borderColor: "transparent",
		fontWeight: 400,
		fontSize: "14px !important",
		margin: "0px",
		color: "#556769 !important",
		"& :hover": {
			//backgroundColor: "rgb(233, 239, 241)",
			"& :hover span span": {
				color: "rgb(204, 33, 79)"
			}
		}
	},
	title: {
		color: "#556769 !important",
		borderColor: "#95A0A1 !important",
		fontWeight: 400,
		fontSize: "14px !important",
		marginTop: "5px"
	},
	dropdownItemSelected: {
		color: "white",
		margin: "0px",
		backgroundColor: "rgb(204, 33, 79) !important",
		"& span span": {
			color: "white !important"
		}
	},
	dropdownOptionText: { color: "#556769" }
};

export const InternalSwitchFormScreen: React.FunctionComponent<IInternalSitchFormScreenProps> = () =>
{
    const theme = useContext(ThemeContext);
    const { state } = useLocation<any>();
    let defaultOrigin: any = state?.origin;
    let defaultTarget: any = state?.target;
    const { handleSubmit, watch, formState: { errors }, control, setValue, getValues, setError} = useForm();
    const [portfolioFundsLoaded, setPortfolioFundsLoaded] = useState(false);
    const [portfolioFunds, setPortfolioFunds] = useState<any[]>([]);
    const [loadListeners, setLoadListeners] = useState<any[]>([]);
    const [sending, setSending] = useState<boolean>(false);
    const [hasChangedIsin, setHasChangedIsin] = useState<boolean>(false);
    const history = useHistory();
    const mounted = useRef(false);
    const watchAmountType = watch("amountType", "");
    const user = Auth.getUserProfile();
    let activePlan = Auth.getActivePlan();
    const outdated = Auth.isLastPlanOutdated();
    const cancelled = Auth.isActivePlanCancelled();
    const values = getValues();
    const [simulation, setSimulation] = useState<boolean>(false);
    const [simulationLoading, setSimulationLoading] = useState<boolean>(false);
    const [simulationAvailable, setSimulationAvailable] = useState<boolean>(false);
    const [transferDates, setTransferDates] = useState<TransferEstimationDates>({ compraLiquidacion: "", compraValor: "", finalProceso: "", ventaLiquidacion: "", ventaValor: "" });
    const [originTransferSimulationManagerInfo, setOriginTransferSimulationManagerInfo] = useState<TransferSimulationManagerInfo[]>([]);
    const [targetTransferSimulationManagerInfo, setTargetTransferSimulationManagerInfo] =  useState<TransferSimulationManagerInfo[]>([]);
    const [selectedOriginPortfolioFund, setSelectedOriginPortfolioFund] = useState<any>();
	const [selectedOriginOptionKey, setSelectedOriginOptionKey] = useState<string>("");
    const [origenOptions, setOrigenOptions] = useState<IDropdownOption[]>();

    if(values.sourceFund)
    {
        defaultOrigin = values.sourceFund;
    }
    if(values.targetFund)
    {
        defaultTarget = values.targetFund;
    }
    const callAllPortfolioLoadListeners = () =>
    {
        loadListeners.forEach(cb =>
        {
            if(typeof cb === 'function')
            {
                cb.call(this, portfolioFunds);
            }
        });
    };
    const loadPortfolioFunds = async () => {
		const finametrixPortfolio = await FinametrixService.getPortfolio(activePlan.portfolio.finametrixId).catch();

		if (typeof finametrixPortfolio?.creationDate !== "string") {
			return;
		}

		const fromDate = finametrixPortfolio?.creationDate?.substring(0, 10).replace(/-/g, "");
		const date_to = function (this: any) {
			this.setDate(this.getDate() - 1);
			return this;
		}.call(new Date());

		const toDate = date_to.getFullYear().toString() + ("0" + (date_to.getMonth() + 1)).slice(-2) + ("0" + date_to.getDate()).slice(-2);

		const reporting = await FinametrixService.getReporting(activePlan.portfolio.finametrixId, fromDate, toDate).catch();
		if (!Array.isArray(reporting?.positions?.positionsTo?.positions) || reporting.positions.positionsTo.positions.length <= 0) {
			return;
		}

		const currentDate = new Date();
		const yearStartDate = new Date(currentDate.getFullYear(), 0, 1);
		const fiscalItems = await FinametrixService.getFiscalItems(activePlan.portfolio.finametrixId, moment(yearStartDate).format("YYYYMMDD"),  moment(currentDate).format("YYYYMMDD"));

		var isins: string[] = [];
		for (let i = 0; i < reporting.positions.positionsTo.positions.length; i++) {
			let position = reporting.positions.positionsTo.positions[i];
			if (position.productType === 21) {
				isins.push(position.isin);
			}
		}
		var funds = await fundService.getFundsNameByIsin(isins);
		var aux: IDropdownOption[] = [];

		for (let i = 0; i < reporting.positions.positionsTo.positions.length; i++) {
			let position = reporting.positions.positionsTo.positions[i];
			if (position.productType === 21 && funds?.find(x => x.isin === position.isin)?.switchable) {
				
				let plusMinus = 0;
				if(fiscalItems){
					let isinFI = fiscalItems.fiscalItems.find((x: any) => x.isin === position.isin);
					if(isinFI && isinFI.fiscalItems)
					{
						plusMinus = isinFI.fiscalItems.reduce((accumulator: any, current: { profit: any; }) => {
							return accumulator + current.profit;
						  }, 0);
					}
				}

				let data = {
					isin: position.isin,
					name: position.productName,
					instrumentId: position.instrumentId,
					plusMinus: plusMinus,
					titles: position.titles,
					value: position.value,
					plusMinusPercentage: position.plusMinusPercentage,
					switchable: true
				};
				setPortfolioFunds(prev => [...prev, data]);

				let auxOption: IDropdownOption = {
					key: position.isin,
					text: position.isin + " | " + position.productName,
					selected: defaultOrigin?.isin === data?.isin ? true : false,
					id: position.isin.toString()
				};
				aux.push(auxOption);

				if (defaultOrigin?.isin === data?.isin) {
					setSelectedOriginPortfolioFund(data);
					setSelectedOriginOptionKey(""+auxOption.key);
				}
			}
		}
		setOrigenOptions(aux);
	};
    useEffect(() =>
    {
        if(portfolioFundsLoaded)
        {
            callAllPortfolioLoadListeners();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioFundsLoaded]);
    useEffect(() =>
    {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() =>
    {
        const values = getValues();
        if(defaultOrigin && defaultOrigin !== values.sourceFund)
        {
            setValue("sourceFund", defaultOrigin);
        }
        if(defaultTarget && defaultTarget !== values.targetFund)
        {
            setValue("targetFund", defaultTarget);
        }

        loadPortfolioFunds().finally(() =>
        {
            setTimeout(() => setPortfolioFundsLoaded(true));
        });

        mounted.current = true;
        return () =>
        {
            mounted.current = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(hasChangedIsin && watch("sourceFund") !== undefined && watch("targetFund") !== undefined)
        {
            setSimulationAvailable(true);
        }
        else
        {
            setSimulationAvailable(false);
        }
    },[simulationAvailable, watch, hasChangedIsin]);

    const handleSimulation = () =>
    {
        setSimulationLoading(true);
        setSimulation(true);
        const fundOrigen = watch("sourceFund");
        const fundDestino = watch("targetFund");

        if(fundOrigen !== undefined && fundDestino !== undefined)
        {
            fundService.GetTransferSimulationManagerInfo([fundOrigen.isin], [])
            .then((response: TransferSimulationManagerInfo[]) => setOriginTransferSimulationManagerInfo(response));

            fundService.GetTransferSimulationManagerInfo([fundDestino.isin], [])
            .then((response: TransferSimulationManagerInfo[]) => setTargetTransferSimulationManagerInfo(response));

            fundService.getTransferEstimationDates(fundOrigen.isin, fundDestino.isin, true).then((response: any) =>
            {
                if(response !== null && response !== undefined)
                {
                    setTransferDates(
                    {
                        ventaValor: response.ventaValor,
                        ventaLiquidacion: response.ventaLiquidacion,
                        compraValor: response.compraValor,
                        compraLiquidacion: response.compraLiquidacion,
                        finalProceso: response.finalProceso
                    });
                } else{
                    setSimulation(false);
                }
            }).finally(() => {setSimulationLoading(false);});
        }
        else
        {
            setSimulation(false);
        }
    }
    const onSubmit = (data: any) =>
    {
        if(data.sourceFund?.instrumentId === undefined)
        {
            setError("sourceFund",
            {
                type: "invalidOriginFund",
                message: "El fondo de origen no es válido"
            });
            return false;
        }

        if(data.amountType === "total" && typeof data.amount !== "undefined")
        {
            delete data.amount;
        }

        let toSend: any =
        {
            InstrumentId: data.sourceFund?.instrumentId,
            UserIdentityId: user.mainUserIdentity.id,
            FnmPortfolioId: activePlan?.portfolio?.id,
            OrderType: 2,
            Values:
            {
                FundName: data.sourceFund?.name,
                FundIsin: data.sourceFund?.isin.trim(),
                Fund: data.sourceFund?.instrumentId,
                AmountType: data.amountType,
                Amount: data.amount !== undefined ? parseFloat(data.amount) : 0,
                NewInstrument: data.targetFund?.finametrixId,
                NewInstrumentName: data.targetFund?.name,
                Type: data.amountType
            }
        };

        setSending(true);
        OrderService.createOrder(toSend).then((response: OrderType) =>
        {
            if(response.id)
            {
                history.push("/internal-switch/signature",
                {
                    signature: response.signature,
                    transfer: response,
                    isinOrigin: data.sourceFund.isin.trim(),
                    isinDestination: data.targetFund.isin.trim()
                });
            }
        })
        .catch()
        .finally(() =>
        {
            if(mounted.current)
            {
                setSending(false);
            }
        });
    };
    
    if(outdated === true || cancelled === true)
    {
        return (<Redirect to="/savingsPlans" />);
    }

    return (
		<React.Fragment>
			<DocumentTitle title={"IronIA - Traspaso Interno"}>
				<form className="internal-transfer-form" id="internal-transfer-form" onSubmit={handleSubmit(onSubmit)} noValidate>
					<L.Main theme={theme}>
						{activePlan !== undefined && (
							<div className="ms-Grid-row" style={{ minHeight: "20px" }}>
								<div className="ms-Grid-col">
									<L.TitleContainer>
										<h2>Realiza un traspaso interno</h2>
									</L.TitleContainer>
								</div>
								<div className="ms-Grid-col" style={{ paddingTop: "12px", color: "#CC214F" }}>
									<L.SubTitleContainer> {activePlan.name}</L.SubTitleContainer>
								</div>
							</div>
						)}
						{activePlan === undefined && (
							<L.TitleContainer>
								<h2>Realiza un traspaso interno</h2>
							</L.TitleContainer>
						)}
						<L.SeparatorTop />
						<div className="ms-Grid restarted-ms-Grid">
							<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-sm12 ms-xl6">
									<L.CardContainer theme={theme}>
										<L.CardHeader>
											<L.CardHeadingContainer>
												<h3>Datos origen</h3>
											</L.CardHeadingContainer>
										</L.CardHeader>
										<L.CardBody>
											<Stack>
												<Stack.Item>
													<Controller
														control={control}
														name="sourceFund"
														rules={{ required: true }}
														render={({ field, field: { onChange } }) => (
															<div style={{ width: "100%", display: "inline-block" }}>
																<label style={{ width: "100%", display: "inline-block" }}>Fondo de Origen *</label>
																<Dropdown
																	{...field}
																	placeholder={"Selecciona un fondo de origen"}
																	options={origenOptions as IDropdownOption[]}
																	selectedKey={selectedOriginOptionKey}
																	onChange={(ev: any, option: any) => {
																		if (option !== undefined) {
																			setHasChangedIsin(true);
																			setSimulation(false);
																			setSelectedOriginPortfolioFund(portfolioFunds.find(x => x.isin === option.key));
																			setSelectedOriginOptionKey(option.key);
																			let thisPortfolioFund = portfolioFunds.find(x => x.isin === option.key);
																			if (thisPortfolioFund) {
																				setValue(thisPortfolioFund.name, thisPortfolioFund);
																				onChange({
																					target: { value: thisPortfolioFund, name: thisPortfolioFund.name }
																				});
																			}
																		} else {
																			setHasChangedIsin(false);
																		}
																	}}
																	styles={dropdownStyles}
																	disabled={!portfolioFundsLoaded}
																	errorMessage={
																		errors.sourceFund &&
																		((typeof errors.sourceFund.type === "string" &&
																			errors.sourceFund.type === "required" &&
																			"Debe seleccionar un fondo de origen.") ||
																			"El fondo de origen no es válido.")
																	}
																/>
															</div>
														)}
													/>
												</Stack.Item>
											</Stack>
										</L.CardBody>
										{!selectedOriginPortfolioFund && !portfolioFundsLoaded && (
											<>
												<div style={{ paddingTop: "20px" }}>
													Cargando información de los fondos de tu cartera
													<Spinner size={SpinnerSize.medium} className="button-spinner" />
												</div>
											</>
										)}
										{selectedOriginPortfolioFund && portfolioFundsLoaded && (
											<div style={{ display: "flex", flexWrap: "wrap" }}>
												<div style={{ display: "flex", flexWrap: "wrap" }}>
													<InternalSwitchCardContainer theme={theme}>
														<InternalSwitchCardTitle>Títulos</InternalSwitchCardTitle>
														<InternalSwitchCardContent>
															{decimalFormatter.format(selectedOriginPortfolioFund?.titles ?? 0)}
														</InternalSwitchCardContent>
														<InternalSwitchCardFooter>* Participaciones</InternalSwitchCardFooter>
													</InternalSwitchCardContainer>
													<InternalSwitchCardContainer theme={theme}>
														<InternalSwitchCardTitle>
															Importe
															<div style={{ float: "right" }}>
																{selectedOriginPortfolioFund?.plusMinusPercentage > 0 && <IncrementIcon />}
																{selectedOriginPortfolioFund?.plusMinusPercentage < 0 && <DecrementIcon />}
															</div>
														</InternalSwitchCardTitle>
														<InternalSwitchCardContent>
															{currencyFormatter.format(selectedOriginPortfolioFund?.value ?? 0)}
														</InternalSwitchCardContent>
													</InternalSwitchCardContainer>
													<InternalSwitchCardContainer theme={theme}>
														<InternalSwitchCardTitle>Plusvalía / Minusvalía</InternalSwitchCardTitle>
														<InternalSwitchCardContent>
															{currencyFormatter.format(selectedOriginPortfolioFund?.plusMinus ?? 0)}
														</InternalSwitchCardContent>
														<InternalSwitchCardFooter>* Latente</InternalSwitchCardFooter>
													</InternalSwitchCardContainer>
												</div>
											</div>
										)}
									</L.CardContainer>
								</div>
								<div className="ms-Grid-col ms-sm12 ms-xl6">
									<L.CardContainer theme={theme}>
										<L.CardHeader>
											<L.CardHeadingContainer>
												<h3>Datos destino</h3>
											</L.CardHeadingContainer>
										</L.CardHeader>
										<L.CardBody>
											<Stack>
												<Stack.Item>
													<Controller
														name="targetFund"
														control={control}
														rules={{ required: true }}
														render={({ field, field: { onChange } }) => (
															<InvestmentFundFinder
																{...field}
																required
																labelISIN="ISIN de destino"
																labelName="Nombre del fondo de destino"
																placeholderISIN="Escribe aquí el ISIN de destino..."
																placeholderName="Escribe aquí el nombre del fondo de destino..."
																default={defaultTarget}
																loadData={(filter: any) => {
																	return fundService
																		.getAvailableFunds(filter)
																		.then(data => data)
																		.catch(err => []);
																}}
																onChange={(ev: any, name: any, value: any) => {
																	if (value !== undefined) {
																		setHasChangedIsin(true);
																		setSimulation(false);
																	} else {
																		setHasChangedIsin(false);
																	}
																	setValue(name, value);
																	onChange({ target: { value, name } });
																}}
																errorMessage={
																	errors.targetFund &&
																	((typeof errors.targetFund.type === "string" &&
																		errors.targetFund.type === "required" &&
																		"Debe seleccionar un fondo de destino.") ||
																		"El fondo de destino no es válido.")
																}
															/>
														)}
													/>
												</Stack.Item>
											</Stack>
											<L.SeparatorForm />
											<h3>Tipo de importe</h3>
											<Stack>
												<Stack.Item>
													<Controller
														name="amountType"
														control={control}
														rules={{ required: true }}
														render={({ field }) => <ChoiceGroup {...field} required options={amountTypeOptions} />}
													/>
												</Stack.Item>
												{watchAmountType !== "total" && (
													<Stack.Item>
														<Controller
															name="amount"
															control={control}
															rules={{ required: true, min: 0 + Number.MIN_VALUE }}
															render={({ field }) => (
																<TextField
																	{...field}
																	required
																	type="number"
																	label={(watchAmountType === "titles" && "Participaciones") || "Importe"}
																	placeholder={
																		(watchAmountType === "titles" && "Escribe aquí el número de participaciones...") ||
																		"Escribe aquí el importe..."
																	}
																	errorMessage={
																		errors.amount &&
																		((watchAmountType === "titles" &&
																			((typeof errors.amount.type === "string" &&
																				errors.amount.type === "required" &&
																				"Indique las participaciones a traspasar.") ||
																				(typeof errors.amount.type === "string" &&
																					errors.amount.type === "min" &&
																					"Debe indicar un número mayor de participaciones.") ||
																				"Introduzca un número de participaciones válido.")) ||
																			(typeof errors.amount.type === "string" &&
																				errors.amount.type === "required" &&
																				"Debe indicar el importe a traspasar.") ||
																			(typeof errors.amount.type === "string" &&
																				errors.amount.type === "min" &&
																				"El importe debe ser mayor.") ||
																			"Introduzca un importe válido.")
																	}
																/>
															)}
														/>
													</Stack.Item>
												)}
											</Stack>
										</L.CardBody>
									</L.CardContainer>
								</div>
							</div>
						</div>
						<L.OuterFooterArea>
							<L.CardButton
								size="lg"
								disabled={!simulationAvailable}
								type="button"
								style={{ marginRight: "1em" }}
								onClick={() => {
									setSimulationLoading(true);
									handleSimulation();
								}}
							>
								Simular
							</L.CardButton>
							<L.CardButton size="lg" disabled={sending || !selectedOriginPortfolioFund}>
								{sending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
								Continuar
							</L.CardButton>
						</L.OuterFooterArea>
						{simulation === true && simulationAvailable === true && transferDates !== undefined && (
							<TransferTimeLine
								isLoadingProps={simulationLoading}
								transferEstimationDates={transferDates}
								simulationManagerInfoTarget={targetTransferSimulationManagerInfo}
								simulationManagerInfoOrigin={originTransferSimulationManagerInfo}
							/>
						)}
					</L.Main>
				</form>
			</DocumentTitle>
		</React.Fragment>
	);
};
import axios from "axios";
import { proxyUrl } from "../config";
import { FnmContract } from "./FnmContractService";
import { setupInterceptorsTo } from "./Interceptors";
import { ISignature } from "./SignaturesService";
import { UserIdentityType } from "./UserIdentitiesService";
setupInterceptorsTo(axios);

interface IFnmContractComplexProduct
{
    id?: number,
    fnmContractId: number,
    fnmContract?: FnmContract,
    isin: string,
    fnmContractComplexProductsUserIdentities?: FnmContractComplexProductUserIdentityType[]
}
export type FnmContractComplexProductType = IFnmContractComplexProduct;
interface IFnmContractComplexProductUserIdentity
{
    id?: number,
    fnmContractComplexProductId: number,
    fnmContractComplexProduct?: FnmContractComplexProductType,
    userIdentityId: number,
    userIdentity?: UserIdentityType,
    signatureId?: number,
    signature?: ISignature,
    signedAgreement?: string
}
export type FnmContractComplexProductUserIdentityType = IFnmContractComplexProductUserIdentity;

interface IFnmContractComplexProductUserIdentityAgreement
{
    isin: string,
    name: string,
    profile?: string,
    phone?: string,
    date?: string,
    value: boolean
}
export type FnmContractComplexProductUserIdentityAgreementType = IFnmContractComplexProductUserIdentityAgreement;

class FnmContractComplexProductService
{
    public getFnmContractComplexProduct = async (fnmContractComplexProductId: number): Promise<FnmContractComplexProductType> =>
    {
        let url: string = `${proxyUrl}/fnmContractComplexProducts/${fnmContractComplexProductId}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public getOrCreateFnmContractComplexProductBySavingsPlanIdAndIsin = async (savingsPlanId: number, isin: string): Promise<FnmContractComplexProductType> =>
    {
        let url: string = `${proxyUrl}/fnmContractComplexProducts/getOrCreateBySavingsPlanIdAndIsin/${savingsPlanId}/${isin}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public addSignatureToFnmContractComplexProductUserIdentities = async (fnmContractComplexProductId: number): Promise<ISignature[]> =>
    {
        let url: string = `${proxyUrl}/fnmContractComplexProducts/${fnmContractComplexProductId}/addSignaturesToFnmContractComplexProductUserIdentities`;
        const req = await axios.post(url);
        return req.data;
    }
    public getUserIdentitiesThatShouldSignComplexProductAgreement = async (fnmContractId: number, isin: string): Promise<UserIdentityType[]> =>
    {
        let url: string = `${proxyUrl}/fnmContractComplexProducts/userIdentitiesThatShouldSignComplexProductAgreement/${fnmContractId}/${isin}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getFnmContractComplexProductsUserIdentitiesByUserIdentityIdAndIsins = async (userIsentityId: number, isins: string[]): Promise<FnmContractComplexProductUserIdentityType[]> =>
    {
        let url: string = `${proxyUrl}/fnmContractComplexProductsUserIdentities/getByUserIdentityIdAndIsins/${userIsentityId}?isins=${isins.join(",")}`;
        const req = await axios.get(url);
        return req.data;
    }
}

const fnmContractComplexProduct = new FnmContractComplexProductService();
export default fnmContractComplexProduct;
import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import CompetitionsService, { CompetitionType, CompetitionTypes, CompetitionsUsersType } from "../../services/CompetitionsService";
import { Spinner, SpinnerSize } from "@fluentui/react";
import styles from './index.module.sass';
import { currencyNeutreFormatter, percentFormatter } from "../../utils/numberFormatter";
import { useHistory, useParams } from "react-router-dom";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DataAndCartLines } from "../../services/PortfolioModelWalletService";
import BackButton from "../../components/buttons/BackButton";

const options: Highcharts.Options =
{
    title:
    {
        text: '',
    },
    xAxis: { visible: false },
    yAxis: { visible: false },
    legend: { enabled: false },
    exporting: { enabled: false },
    credits: { enabled: false },
    chart:
    {
        type: 'bar',
        margin: 0,
        height: 24,
        backgroundColor: "transparent",
        borderColor: "transparent"
    },
    tooltip:
    {
        headerFormat: "",
        valueSuffix: '%',
        outside: true,
    },
    plotOptions:
    {
        bar:
        {
            stacking: 'percent',
            groupPadding: 0,
            pointPadding: 0,
            borderRadius: 4,
            borderColor: "transparent",
        },
    },
};

const Competition = () => {
    let history = useHistory();
    const { competitionId } = useParams<any>();
    const [competition, setCompetition] = useState<CompetitionType>();
    const [competitionUsers, setCompetitionUsers] = useState<CompetitionsUsersType[]>();
    const [series, setSeries] = useState<any[]>([]);
    const [error, setError] = useState({ status: false, message: '' });
    const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
        CompetitionsService.getCompetition(competitionId)
            .then((response: CompetitionType) => {
                setCompetition(response);
            },
            (error: any) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (competition !== undefined) {
            var competitionsUsers = competition.competitionsUsers.map((competitionUser: CompetitionsUsersType) => {
                let performance = 0;
                const valueVirtualPortfolios = competitionUser.portfolioModel.valueVirtualPortfolios;

                if (valueVirtualPortfolios.length !== 0 && valueVirtualPortfolios[0].ammount !== 0) {
                    const amountDistintInitial = valueVirtualPortfolios.filter(vVP => vVP.ammount !== valueVirtualPortfolios[0].ammount);
                    if (amountDistintInitial.length > 0) {
                        performance = (amountDistintInitial[amountDistintInitial.length - 1].ammount - valueVirtualPortfolios[0].ammount) / valueVirtualPortfolios[0].ammount;
                    }
                }
                competitionUser.performance = performance;
                return competitionUser;
            });
            const sortedCompetitionsUsers = competitionsUsers.sort((a: CompetitionsUsersType, b: CompetitionsUsersType) => ((a.performance ?? 0) > (b.performance ?? 0)  ? -1 : 1));
            setCompetitionUsers(sortedCompetitionsUsers);
        }
    }, [competition]);

    useEffect(() => {
        if (competitionUsers !== undefined) {
            var seriesAux: any[] = [];
            competitionUsers.forEach((competitionUser: CompetitionsUsersType) => {
                var serie: any[] = [];
                competitionUser.portfolioModel.jsonFundsData.forEach((dataAndCartLine: DataAndCartLines) => {
                    serie.push(
                        {
                            name: dataAndCartLine.fund.name,
                            data: [dataAndCartLine.modelPercentage]
                        });
                });
                seriesAux.push(serie.sort((serieItemA, serieItemB) => serieItemA.data[0] - serieItemB.data[0]));
            });
            setSeries(seriesAux);
        }
    }, [competitionUsers]);

    if (error.status) {
        return (
            <DocumentTitle title="IronIA - Competiciones - Versión Preliminar">
                <div>Error: {error.message}</div>
            </DocumentTitle>
        );
    }

    return (
        <DocumentTitle title={`IronIA - Competiciones - ${competition?.name} - Versión Preliminar`}>
            <section className={`${styles.competition}`}>
                <BackButton />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <h2>Competiciones</h2>
                    </div>
                </div>
                <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <p className="descriptioncompetition ">
              ¿Quieres aprender jugando?
            </p>
            <p className="descriptioncompetition ">
              Las competiciones de IronIA te permiten conocer el mercado de los
              fondos de inversión gracias a nuestro simulador. Crea carteras
              modelo, sin ningún tipo de inversión real y compite en
              rentabilidad con otras carteras.
            </p>

            <p className="descriptioncompetition ">
              La cartera más rentable gana... y lo que gana depende del reto.
            </p>
          </div>
        </div>
                {loading && (<Spinner size={SpinnerSize.large} />)}
                {!loading && (
                    <>
                        <section className="ms-Grid-row">
                            <h3>{competition?.name}</h3>
                            <span>
                                Del {competition?.startDate.split("/")[1]}/{competition?.startDate.split("/")[0]}/{competition?.startDate.split("/")[2]} 
                                al 
                                {competition?.endDate.split("/")[1]}/{competition?.endDate.split("/")[0]}/{competition?.endDate.split("/")[2]}
                              </span>
                            <p dangerouslySetInnerHTML={{ __html: competition?.description ?? '' }} />
                            <p className={styles.type}>Tipo: {CompetitionTypes[competition?.type || 0]}</p>
                            <p className={styles.price}>
                                Precio: {competition?.price === 0
                                    ? <>Inscripción gratuita</>
                                    : <>{currencyNeutreFormatter.format(competition?.price ?? 0)} Elements IA</>
                                }
                            </p>
                        </section>
                        <section className="ms-Grid-row">
                            <div className={`${styles.tableContainer} background-tertiary`}>
                                {competitionUsers && (
                                    <div className={styles.tableScroll}>
                                        <table>
                                            <thead className="background-shadow">
                                                <tr>
                                                    <td className={styles.positionHead}>#</td>
                                                    <td className={styles.performanceHead}>Rentabilidad</td>
                                                    <td className={styles.nameHead}>Cartera</td>
                                                    <td className={styles.compositionHead}>Composisión</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {competitionUsers.map((competitionUser, competitionUserIndex) => (
                                                    <tr key={`competitionUserIndex{competitionUserIndex}`}>
                                                        <td className={styles.position}>{competitionUserIndex + 1}</td>
                                                        <td className={styles.performance}>{percentFormatter.format(competitionUser.performance ?? 0)}</td>
                                                        <td className={styles.name}>{competitionUser.portfolioModel.name}</td>
                                                        <td className={styles.composition}>
                                                            <HighchartsReact highcharts={Highcharts}
                                                                options={{
                                                                    ...options,
                                                                    series: series[competitionUserIndex]
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </section>
                    </>
                )}
            </section>
        </DocumentTitle>
    );
};

export default Competition;

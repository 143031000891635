import React from "react"
import OutsideClickHandler from "react-outside-click-handler";
import * as S from '../Styles/BenchmarkScatterDropdownStyles';
import debounce from "lodash.debounce";
import { ChevronUpMedIcon } from "@fluentui/react-icons-mdl2";
import { DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import FundService from "../../../services/FundService";

const icons =
{
    closed: <ChevronUpMedIcon />,
    opened: <i className="ironia-icon reports" />
}
interface OptionType
{
    label: string,
    value: string
}
interface IBenchmarkScatterProps
{
    benchmarkInUse: OptionType;
    onSave: (selectedBenchmark: any) => void
}

const BenchmarkScatterDropdown = (props: IBenchmarkScatterProps) =>
{
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [selectedItem, setSelectedItem] = React.useState<OptionType>(props.benchmarkInUse);
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const [benchmarks, setBenchmarks] = React.useState<OptionType[]>([]);
    const [filteredBenchmarks, setFilteredBenchmarks] = React.useState<OptionType[]>(benchmarks);
    const [isLoadedBenchmarks, setIsLoadedBenchmarks] = React.useState<boolean>(false);

    React.useEffect(() =>
    { 
        FundService.getBenchmarks().then((benchmarks) =>
        {
            setBenchmarks(benchmarks.map((benchmark: any) =>
            {
                return{label: benchmark.name, value: benchmark.finametrixId}
            }));
        })
        .catch()
        .finally(() => setIsLoadedBenchmarks(true));
    }, []);

    React.useEffect(() =>
    {
        setFilteredBenchmarks(filterBenchmarks(benchmarks, searchTerm));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [benchmarks, searchTerm]);

    const resetState = () =>
    {
        setSelectedItem(props.benchmarkInUse);
        props.onSave(props.benchmarkInUse);
        setSearchTerm('');
        setIsOpen(false);
    };

    const saveChanges = () =>
    {
        setFilteredBenchmarks(filterBenchmarks(benchmarks, ''));
        setSearchTerm('');
        props.onSave(selectedItem);
        setIsOpen(false);
    }

    const handleSearchChange = debounce((event) =>
    {
        setSearchTerm(event.target.value);
    }, 500);

    const handleChange = ({label, value}: {label: string, value: string}) =>
    {
        if(selectedItem !== undefined && selectedItem.value === value)
        {
            setSelectedItem(props.benchmarkInUse);
        }
        else
        {
            setSelectedItem({label: label, value: value});
        }
    }

    function filterBenchmarks(benchmarks: OptionType[], searchText: string): OptionType[]
    {
        const normalizedSearchText = searchText.trim().toLowerCase();

        if(!normalizedSearchText)
        {
            const mO = benchmarks.filter(opt => opt.label === selectedItem.label);
            const nMO = benchmarks.filter(option => option.label !== selectedItem.label);

            mO.sort((a, b) => a.label.localeCompare(b.label));
            nMO.sort((a, b) => a.label.localeCompare(b.label));

            return [...mO, ...nMO];
        }
        else
        {
            var result = benchmarks.filter(benchmark => benchmark.label.toLowerCase().includes(normalizedSearchText));
    
            const matchingOptions = result.filter(option => option.label === selectedItem.label);
            const nonMatchingOptions = result.filter(option => option.label !== selectedItem.label);
    
            matchingOptions.sort((a, b) => a.label.localeCompare(b.label));
            nonMatchingOptions.sort((a, b) => a.label.localeCompare(b.label));
    
            return [...matchingOptions, ...nonMatchingOptions];
        }
    }

    return(
        <React.Fragment>
            <S.DropdownContainer>
                <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                    <S.DropdownHeader>
                        <S.DropdownInputText borderless={true} onFocus={() => setIsOpen(true)} style={{display: 'block'}}
                            placeholder={selectedItem.label}    
                            defaultValue={props.benchmarkInUse.label}
                            onChange={handleSearchChange}
                            autoComplete="off"
                        />
                        {isOpen ? icons.closed : icons.opened}
                    </S.DropdownHeader>
                    {isOpen &&
                    (
                        <React.Fragment>
                            <S.Menu>
                                <S.MenuBody>
                                    {!isLoadedBenchmarks && <Spinner size={SpinnerSize.medium} />}
                                    {isLoadedBenchmarks &&
                                    (
                                        <React.Fragment>
                                            {filteredBenchmarks.length > 0 &&
                                            (
                                                filteredBenchmarks.map(({label, value}, i) =>
                                                (
                                                    <S.CheckBox key={i} label={label} value={value}
                                                        onChange={() => handleChange({label, value})}
                                                        checked={selectedItem !== undefined && selectedItem.value === value}
                                                    />
                                                ))
                                            )}
                                        </React.Fragment>
                                    )}
                                </S.MenuBody>
                                <S.MenuFooter>
                                    <DefaultButton className={`button tiny-primary-button`} onClick={resetState}>
                                        Borrar
                                    </DefaultButton>
                                    <DefaultButton className={`button tiny-primary-button`} onClick={saveChanges}>
                                        Guardar
                                    </DefaultButton>
                                </S.MenuFooter>
                            </S.Menu>
                        </React.Fragment>
                    )}
                </OutsideClickHandler>
            </S.DropdownContainer>
        </React.Fragment>
    );
}

export default BenchmarkScatterDropdown;
import React, { useContext } from "react";
import * as L from './Styles/ApiTransferStyles';
import DocumentTitle from "react-document-title";
import { ThemeContext } from '@fluentui/react-theme-provider';
import { CaretHollowMirroredIcon } from '@fluentui/react-icons-mdl2';
import Api, { ApiTransferOrder } from "../services/ApiService";
import { SignatureComponent } from "../components/Signature/SignatureComponent";
import { ISignature } from "../services/SignaturesService";
import moment from "moment";
import ApiProcessingModal from "./ApiProcessingModal";
import { useBoolean } from "@fluentui/react-hooks";

interface MultiTransferData
{
    urlOk: string,
    urlKo: string,
    activePlan: string,
    type: number,
    userId: number,
    distributor: string | undefined,
    transferData: TransferData[]
}
interface TransferData
{
    originIsin: string,
    originFundName: string,
    targetIsin: string,
    targetFundName: string,
    amount?: number,
    amountType: string,
    account?: string | undefined
}

const ApiMultiTransfer = (props: any) =>
{
    const theme = useContext(ThemeContext);
    const guid = window.location.pathname.split('/')[4];
    const firstTimeLoading = React.useRef<boolean>(true);
    const [isModalOpen, {setTrue: showModal, setFalse: hideModal}] = useBoolean(false);
    const [userConfirmation, setUserConfirmation] = React.useState({ user: false, disclaimer: false });
    const [sending, setSending] = React.useState<boolean>(true);
    const transferDates = {compraLiquidacion: "", compraValor: "", finalProceso: "", ventaLiquidacion: "", ventaValor: "" };
    const [data, setData] = React.useState<MultiTransferData>(
    {
        urlOk: "",
        urlKo: "",
        activePlan: "",
        type: -1,
        userId: -1,
        distributor: "",
        transferData:
        [
            {
                originIsin: "",
                originFundName: "",
                targetIsin: "",
                targetFundName: "",
                amountType: "",
                amount: 0,
                account: ""
            },
            {
                originIsin: "",
                originFundName: "",
                targetIsin: "",
                targetFundName: "",
                amountType: "",
                amount: 0,
                account: ""
            }
        ]
    });
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [loadedSignature, setLoadedSignature] = React.useState<boolean>(false);
    const [signatures, setSignatures] = React.useState<any>();

    React.useEffect(() =>
    {
        if(firstTimeLoading.current === true)
        {
            firstTimeLoading.current = false;

            Api.getMultiTransferData(guid).then((response: MultiTransferData) =>
            {
                setData(response);
                setDataLoaded(true);

                if (response.transferData.length > 0 && response.transferData.filter(jv => jv.amountType !== "Total" && jv.amount === undefined).length > 0)
                {
                    setSending(false);
                }
                else
                {
                    setSending(true);
                }
            });
        }
    }, [guid, data]);
    const loadSignature = () =>
    {
        Api.sendSignatureCode(guid).then((response: any) =>
        {
            if(response.length > 0)
            {
                setSignatures(response);
                setLoadedSignature(true);
            }
        });
    }
    const processExpirationDate = (signs: ISignature[]) =>
    {
        if(signs !== undefined && signs.length > 0)
        {
            // eslint-disable-next-line array-callback-return
            signs.map((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = moment(signature.expiresAt).toDate();
                }
            });
        }
    }
    const handleSign = () =>
    {
        showModal();

        var model: ApiTransferOrder =
        {
            transferGuid: guid,
            hasDisclaimerTime: userConfirmation.disclaimer === true ? 2 : 0
        };

        Api.postTransfer(model).then((response: boolean) =>
        {
            if(response === true)
            {
                window.location.href = data.urlOk;
            }
            else
            {
                hideModal();
                window.location.href = data.urlKo;
            }
        });
    }

    return(
        <React.Fragment>
            <DocumentTitle title={"IronIA - Multitraspasos"}>
                <L.Main theme={theme}>
                    <L.BackButtonContainer>
                        <span>
                            <CaretHollowMirroredIcon />
                            <L.BackButton onClick={() => window.location.href = data.urlKo}>
                                Volver atrás
                            </L.BackButton>
                        </span>
                    </L.BackButtonContainer>
                    {data.activePlan !== "" &&
                    (
                        <L.DivRowActivePlan className="ms-Grid-row">
                            <L.DivColActivePlan className="ms-Grid-col">
                                <L.TitleContainer>
                                    <L.TextActivePlan>
                                        Aumenta tu inversión / ahorro
                                    </L.TextActivePlan>
                                </L.TitleContainer>
                            </L.DivColActivePlan>
                            <L.DivColActivePlanText className="ms-Grid-col">
                                <L.SubTitleContainer>
                                    {data.activePlan}
                                </L.SubTitleContainer>
                            </L.DivColActivePlanText>
                        </L.DivRowActivePlan>
                    )}
                    {data.activePlan === "" &&
                    (
                        <L.TitleContainer>
                            <h2>Aumenta tu inversión / ahorro</h2>
                        </L.TitleContainer>
                    )}
                    <L.SeparatorTop />
                    <L.DivGridMain className="ms-Grid restarted-ms-Grid">
                        <L.DivGridRow className="ms-Grid-row">
                            {dataLoaded === true &&
                            (
                                <React.Fragment>
                                    {data.type === 0 &&
                                    (
                                        <React.Fragment>
                                            <L.Table>
                                                <L.TableHead>
                                                    <L.TableTR>
                                                        <L.TableTH>
                                                            ISIN de Origen
                                                        </L.TableTH>
                                                        <L.TableTH>
                                                            Nombre del Fondo
                                                        </L.TableTH>
                                                        <L.TableTH>
                                                            Comercializador
                                                        </L.TableTH>
                                                        <L.TableTH>
                                                            Cuenta de origen
                                                        </L.TableTH>
                                                        <L.TableTH>
                                                            ISIN de Destino
                                                        </L.TableTH>
                                                        <L.TableTH>
                                                            Nombre del Fondo
                                                        </L.TableTH>
                                                        <L.TableTH>
                                                            Tipo de Traspaso
                                                        </L.TableTH>
                                                        <L.TableTH>
                                                            Cantidad
                                                        </L.TableTH>
                                                    </L.TableTR>
                                                </L.TableHead>
                                                <L.TableBody>
                                                    {data.transferData.length > 0 && data.transferData.map((row, index) =>
                                                    {
                                                        return(
                                                            <React.Fragment key={index}>
                                                                <L.TableTR>
                                                                    <L.TableTD>
                                                                        {row.originIsin}
                                                                    </L.TableTD>
                                                                    <L.TableTD>
                                                                        {row.originFundName}
                                                                    </L.TableTD>
                                                                    <L.TableTD>
                                                                        {data.distributor}
                                                                    </L.TableTD>
                                                                    <L.TableTD>
                                                                        {row.account}
                                                                    </L.TableTD>
                                                                    <L.TableTD>
                                                                        {row.targetIsin}
                                                                    </L.TableTD>
                                                                    <L.TableTD>
                                                                        {row.targetFundName}
                                                                    </L.TableTD>
                                                                    <L.TableTD>
                                                                        {row.amountType}
                                                                    </L.TableTD>
                                                                    <L.TableTD>
                                                                        {row.amount !== undefined ? row.amount : "-"}
                                                                    </L.TableTD>
                                                                </L.TableTR>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </L.TableBody>
                                            </L.Table>
                                        </React.Fragment>
                                    )}
                                    {data.type === 1 &&
                                    (
                                        <React.Fragment>
                                            <L.Table>
                                                <L.TableHead>
                                                    <L.TableTR>
                                                        <L.TableTH>
                                                            ISIN de Origen
                                                        </L.TableTH>
                                                        <L.TableTH>
                                                            Nombre del Fondo
                                                        </L.TableTH>
                                                        <L.TableTH>
                                                            ISIN de Destino
                                                        </L.TableTH>
                                                        <L.TableTH>
                                                            Nombre del Fondo
                                                        </L.TableTH>
                                                        <L.TableTH>
                                                            Tipo de Traspaso
                                                        </L.TableTH>
                                                        <L.TableTH>
                                                            Cantidad
                                                        </L.TableTH>
                                                    </L.TableTR>
                                                </L.TableHead>
                                                <L.TableBody>
                                                    {data.transferData.length > 0 && data.transferData.map((row, index) =>
                                                    {
                                                        return(
                                                            <React.Fragment key={index}>
                                                                <L.TableTR>
                                                                    <L.TableTD>
                                                                        {row.originIsin}
                                                                    </L.TableTD>
                                                                    <L.TableTD>
                                                                        {row.originFundName}
                                                                    </L.TableTD>
                                                                    <L.TableTD>
                                                                        {row.targetIsin}
                                                                    </L.TableTD>
                                                                    <L.TableTD>
                                                                        {row.targetFundName}
                                                                    </L.TableTD>
                                                                    <L.TableTD>
                                                                        {row.amountType}
                                                                    </L.TableTD>
                                                                    <L.TableTD>
                                                                        {row.amount}
                                                                    </L.TableTD>
                                                                </L.TableTR>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </L.TableBody>
                                            </L.Table>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </L.DivGridRow>
                    </L.DivGridMain>
                    <L.OuterFooterArea>
                        <L.CardButton size="lg" disabled={!sending} type="button" onClick={loadSignature}>
                            Continuar
                        </L.CardButton>
                    </L.OuterFooterArea>
                    {loadedSignature &&
                    (
                        <React.Fragment>
                            <SignatureComponent
                                signatures={signatures}
                                title={"Firma la Orden de los Traspasos"}
                                submitButtonText={"Firmar"}
                                requestNewCodeButtonText={"Recibir nuevo código"}
                                headerTextArea={"Introduce el código que te hemos enviado al teléfono XXXXXX"+signatures[0].phone?.slice(signatures[0].phone.length - 3)+" por SMS para firmar la orden."}
                                onBackButtonClicked={() => window.location.href = data.urlKo}
                                onCancelButtonClicked={() => window.location.href = data.urlKo}
                                isApiSignature={true}
                                onNewCodeSended={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                onSignatureChanged={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                confirmElement={
                                {
                                    text: "Confirmo haber recibido, leído y comprendido la documentación obligatoria de IIC's y realizar esta operación por iniciativa propia.",
                                    value: userConfirmation.user,
                                    setValue: (value: boolean) => setUserConfirmation({user: !!value, disclaimer: userConfirmation.disclaimer})
                                }}
                                disclaimerEnabled={
                                {
                                    text: "He sido informado de que alguna de las operaciones realizadas en este momento puede no tomar el valor liquidativo del día en curso.",
                                    processHours: transferDates,
                                    isins: data.transferData.map((transfer) => { return { origin: transfer.originIsin, destiny: transfer.targetIsin } }),
                                    value: userConfirmation.disclaimer,
                                    isTransfer: true,
                                    isMultiIsin: true,
                                    setValue: (value: boolean) => setUserConfirmation({user: userConfirmation.user, disclaimer: !!value})
                                }}
                                onSigned={handleSign}
                            />
                        </React.Fragment>
                    )}
                    <ApiProcessingModal isModalOpen={isModalOpen} hideModal={hideModal}
                        title={'Procesando datos...'}
                        description={'Estamos preparando todos los datos para autorizar los traspasos. No cierres la pestaña ni el navegador, por favor.'}
                    />
                </L.Main>
            </DocumentTitle>
        </React.Fragment>
    );
}

export default ApiMultiTransfer;
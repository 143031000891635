import { usePDFSlick } from "@pdfslick/react";
import "@pdfslick/react/dist/pdf_viewer.css";

type PDFViewerAppProps = {
  pdfFilePath: string,
};

const PDFViewer = ({ pdfFilePath }: PDFViewerAppProps) => {
  const { viewerRef, usePDFSlickStore, PDFSlickViewer } = usePDFSlick(
    pdfFilePath,
    {
      singlePageViewer: false,
      scaleValue: "page-width",
    }
  );

  return (
    <PDFSlickViewer {...{ viewerRef, usePDFSlickStore }} />
  );
};

export default PDFViewer;
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 80px;
    justify-content: center;
    align-items: flex-start;
`;
export const Title = styled.h1``;
export const FiltersWrapper = styled.div`
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: flex-start;
`;
export const FilterContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5px 0 0;
    > div span
    {
        border-radius: 100px;
    }
    i
    {
        color: white;
    }
`;
export const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
export const TableWidthManager = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 30px;
    box-shadow: 0px 0px 16px rgba(8, 84, 117, 0.08);
    border-radius: 16px;
`;
export const TableFilterContainer = styled.div`
    display: flex;
    flex: 0 1 50px;
    margin-bottom: 10px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
`;
export const TRCountContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
`;
export const TRTitle = styled.span`
    font-size: 19px;
    font-weight: 500;
`;
export const TRCount = styled.span`
    color: #94a1a1;
    margin-left: 5px;
`;

export const ThBlue = styled.th`
    background-color: #a6a6a6 !important;
    color: white;
    font-weight: bold  !important;
    font-size: 16px;
    text-align: left !important;
`
export const ThCenter = styled.th`
    background-color: #a6a6a6 !important;
    color: white;
    font-weight: bold  !important;
    font-size: 16px;
    text-align: center !important;
`

export const TdTableAccount = styled.td`
    font-size: 14px !important;
`
export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: auto;
    border-bottom: 1px solid lightGrey;
    background: ${(props) => props.theme.palette.white};
    table
    {
        border-radius: 16px;
    }
    thead
    {
        background: ${(props) => props.theme.palette.tertiary};
    }
    > div
    {
        margin: 0;
    }
    .is-selected
    {
        a,
        div
        {
            color: white !important;
        }
    }
`;

export const FileInput = styled.input`
    font-family: inherit;
    width: 100%;
    height: 100%;
    padding: 10px 5px;
    border: 1px solid #95a0a1;
    border-radius: 5px;
    &::file-selector-button
    {
        -webkit-appearance: none;
        position: absolute;
        float: right;
        height: 90%;
        width: 133px;
        top: 50%;
        right: 20%;
        transform: translate(65%, -50%);
        border: none;
        background-color: #cc214f;
        border-radius: 5px;
        color: #ffffff;
    }
`;

export const Label = styled.label`
    margin-top: 5px;
    display: block;
    font-weight: 500;
    color: #556769;
    font-size: 14px;
`;

export const FieldFileCont = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 449px;
    padding-top: 8px;
    label
    {
        margin: 5px 0;
    }
    input
    {
        position: relative;
        max-height: 38.8px;
        &::file-selector-button
        {
            right: 84px;
        }
    }
    span.error
    {
        top: 100%;
    }
`;
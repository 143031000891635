import * as L from './Styles/FundDetailStyles';
import * as Scroll from 'react-scroll';
import { useState, useContext, useRef } from 'react';
import { Spinner, Separator, DefaultButton, Stack, SpinnerSize, TeachingBubble, IButtonProps, DirectionalHint } from '@fluentui/react';
import FundDetails from '../../services/FundDetails';
import { ReactComponent as CircledWarning } from '../../icons/circled-warning.svg';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { AdditionalInformation } from './components/AdditionalInformation';
import { CompliantProfitability } from './components/CompliantProfitability';
import { Documents } from './components/Documents'; 
import { FundEvaluation } from './components/FundEvaluation';
import { InterestData } from './components/InterestData';
import { Profitability } from './components/Profitability';
import { Risk } from './components/Risk';
import { ChevronDownIcon, ChevronUpIcon, CaretHollowMirroredIcon, LightbulbIcon } from '@fluentui/react-icons-mdl2';
import { ThemeContext } from '@fluentui/react-theme-provider';
import { HighlightedPanelButton } from '../../components/buttons/HighlightedPaneButton';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "office-ui-fabric-react";
import { FundTableMin } from './tables/FundTableMin';
import FundService, { FundWithExtraDataType, IRatioBothFunds, IRatioFundChangeBenchmark, IRatioFunds, IRatioTwoFundsChangeBenchmark } from '../../services/FundService';
import Highstock, { GraphicType } from '../../components/Highstock/Highstock';
import MultiFundsAndBenchmarksDropDown from '../../components/dropdown/MultiFundsAndBenchmarksDropDown/MultiFundsAndBenchmarksDropDown';
import groupFundsByFamily from '../../utils/groupFundsByFamily';
import Moment from 'moment';
import 'moment/locale/es';
import Auth from "../../Auth/Auth";
import React from 'react';
import ProductCard from './components/ProductCard';
import { ai } from '../../services/TelemetryService';
import { Helmet } from "react-helmet";
import { ModalFundExplanation } from './ModalFundExplanation';
import IronIAIntegrations, { ChatGPT, ChatGPTResponse, LatestVideoInformation } from '../../services/IroniaIntegrationsService';
import AppendCartLine from '../../utils/AppendCartLine';
import { ModalManagerExplanation } from './ModalManagerExplanation';
import ScatterHighstock from '../../components/Highstock/ScatterHighstock';
import { comparer } from '../../utils/ArrayComparer';
import { useBoolean } from '@fluentui/react-hooks';
import ratios14 from "../../images/RatiosTooltip1-4.png";
import ratios2 from "../../images/RatiosTooltip2.png";
import ratios3 from "../../images/RatiosTooltip3.png";
import ratios5 from "../../images/RatiosTooltip5.png";
import { translate } from "../../utils/i18n";
import BenchmarkScatterDropdown from './components/BenchmarkScatterDropdown';

interface OptionType
{
    label: string,
    value: string
}

const FundDetailPage = (publicVersion : any) =>
{
    const appInsights = ai.reactPlugin;
    const dispatch = useDispatch();
    const location = useLocation();
    const theme = useContext(ThemeContext);
    const history = useHistory();
    const subscribed = Auth.isSubscribed();
    const cancelled = Auth.isActivePlanCancelled();
    const user = Auth.getUserProfile();
    const activePlan = Auth.getActivePlan();
    const savingsPlanElementsStatus = Auth.getSavingsPlanElementsStatus();
    const janusHendersonFunds = useRef<string[]>(["IE00BFRSYK98","IE00B6QFF942","IE00BFRSYK98","IE0002141913","IE00BFRSYJ83","IE00BRJG7840","IE0002122038","IE00B2B36G96","IE0009355771","IE00B2B36J28","IE00BRJG6X20","IE00B3S2VD85"]);
    const isJanusFund = useRef<boolean>(false);
    const isActiveManagementPlan = Auth.isActiveManagementPlan();
    const [fund, setFund] = useState<FundWithExtraDataType>(
    {
        allFundsId: -1,
        benchmark: "",
        benchmarkFinametrixId: "",
        finametrixId: "",
        name: "",
        available: false,
        switchable: false,
        isin: "",
        currency: "",
        currencyHedge: false,
        manager: "",
        ucits: null,
        documents: [],
        managementFee: null,
        minimumInitialInvestment: 0,
        minimumAdditionalInvestment: 0,
        morningStarCategoryId: 0,
        income: 0,
        rebate: 0,
        noMin:  0,
        cleanSharecleanShare: 0,
        liquidationDaysOnSellsliquidationDaysOnSells: 0,
        liquidationDaysOnBuysliquidationDaysOnBuys: 0,
        ongoingCharges: 0,
        cleanShare: "",
        liquidationDaysOnSells: "",
        liquidationDaysOnBuys: "",
        category: "",
        subcategory: "",
        averageScore: 0.0,
        performanceFee: "-",
        complexity: false
    });
    const [fundGroups, setFundGroups] = useState<any>([]);
    const [siblingsOptions, setSiblingsOptions] = useState<OptionType[]>([]);
    const [loadingFund, setLoadingFund] = useState(false);
    const [fundDetails, setFundDetails] = useState(null);
    const [benchmarkSeries, setBenchmarkSeries] = useState<any>([]);
    const [selectedBenchmarks, setSelectedBenchmarks] = useState<OptionType[]>([]);
    const [scatterGraphic, setScatterGraphic] = useState<boolean>(false);
    const [doubleFund, setDoubleFund] = useState<boolean>(false);
    const [changeBenchmark, setChangeBenchmark] = useState<boolean>(false);
    const [secondScatterFund, setSecondScatterFund] = useState<OptionType[]>([]);
    const [secondBenchmark, setSecondBenchmark] = useState<OptionType>();
    const [similarProducts, setSimilarProducts] = useState([]);
    const [loadedDetails, setLoadedDetails] = useState(false);
    const [loadingSimilarProducts, setLoadingSimilarProducts] = useState(false);
    const [currentYear, setCurrentYear] = useState('');
    const [showBrothers, setShowBrothers] = useState(false);
    const [yearDetail, setYearDetail] = useState(null);
    const [sending, setSending] = useState(false);
    const [chatGPTData, setChatGPTData] = useState<ChatGPT>(
    {
        annualReturn: 0,
        currency: "",
        fundName: "",
        fee: 0,
        indexAnnualReturn: 0,
        indexName: "",
        indexVolatility: 0,
        ironiaPoints: 0,
        isin: "",
        volatility: 0,
        managerName: "",
        forceNew: false,
        userId: user.id
    });
    const [chatGPTHelmetValue, setChatGPTHelmetValue] = useState<string>("");
    const [chatGPTResponse, setChatGPTResponse] = useState<ChatGPTResponse>(
    {
        isin: "",
        managerName: "",
        isGeneratingVideo: false,
        elementsIA: 0,
        userId: user.id,
        fund:
        {
            explanation: "",
            urlAudio: "",
            urlVideo: "",
            isVideoAvailable: false,
            isAudioAvailable: false,
            isFundPromoted: false,
            textDate: "",
            videoDate: "",
            price: 0
        },
        lastFund:
        {
            explanation: "",
            urlAudio: "",
            urlVideo: "",
            isVideoAvailable: false,
            isAudioAvailable: false,
            isFundPromoted: false,
            textDate: "",
            videoDate: "",
            price: 0
        }
    });
    const [ratios, setRatios] = useState<any[]>();
    const [allPeriods, setAllPeriods] = React.useState<string[]>([]);
    const [period, setPeriod] = React.useState<string>("3y");
    const [tooltipRatio, {setTrue: showTooltipRatio, setFalse: hideTooltipRatio}] = useBoolean(false);
    const [tooltipRatioPage, setTooltipRatioPage] = useState<number>(0);
    const [windowSize, setWindowSize] = React.useState<any>(window.innerWidth);
    const activeSavingsPlanHasOutdatedcontract = useSelector((state: RootStateOrAny) => state.auth.outdatedContract);
    const stepTooltipFirstButton: IButtonProps = React.useMemo(() =>
    (
        {
            children: 'Siguiente',
            disabled: tooltipRatioPage === 4,
            onClick: () => setTooltipRatioPage(prevState => prevState+1),
            className: 'mini-primary-button'
        }),
        [tooltipRatioPage]
    );
    const stepTooltipSecondButton: IButtonProps = React.useMemo(() =>
    (
        {
            children: 'Anterior',
            disabled: tooltipRatioPage === 0,
            onClick: () => setTooltipRatioPage(prevState => prevState-1),
            className: 'mini-primary-button'
        }),
        [tooltipRatioPage]
    );
    const [yearBenchmarkDetail, setYearBenchmarkDetail] = useState(null);
    const [error, setError] = useState({status: false, message: ''});
    const [chartStartDate, setChartStartDate] = useState('');
    const firstTime = useRef<boolean>(true);
    const [isIFrame, setIsIframe] = React.useState<boolean>(false);
    const [lastPrice, setLastPrice] = useState(
    {
        date: null,
        value: null,
    });
    const [createNewIroniaExplicaData, setCreateNewIroniaExplicaData] = useState<boolean>();
    const [highstockOptions, setHighstockOptions] = useState(
    {
        rangeSelector:
        {
            selected: 0,
            buttons:
            [
                {
                    type: 'year',
                    count: 1,
                    text: '1y'
                },
                {
                    type: 'year',
                    count: 2,
                    text: '2y'
                },
                {
                    type: 'year',
                    count: 3,
                    text: '3y'
                },
                {
                    type: 'year',
                    count: 4,
                    text: '4y'
                },
                {
                    type: 'year',
                    count: 5,
                    text: '5y'
                },
                {
                    type: 'ytd',
                    text: 'YTD'
                },
                {
                    type: 'all',
                    text: 'Todo'
                }
            ]
        },
        xAxis:
        {
            events:
            {
                setExtremes: function (event: any)
                {
                    var selectedButtonIndex = event.target.chart.rangeSelector.selected || 0;
                    if(event.target.chart.rangeSelector.buttons.length > 0 && !isNaN(event.target.chart.rangeSelector.buttons[selectedButtonIndex].textStr[0]))
                    {
                        var endDate = event.max;
                        var startDate = event.min;
                        var duration = Moment.duration(endDate - startDate);
                        setCurrentYear(Math.round(duration.asYears()) + "y");
                    }
                },
                afterSetExtremes: function (event: any)
                {
                    var years = 0;
                    if(event.rangeSelectorButton !== undefined && event.rangeSelectorButton.count !== undefined)
                    {
                        var selectedButtonIndex = event.target.chart.rangeSelector.selected || 0;
                        if(event.target.chart.rangeSelector.buttons.length > 0 && !isNaN(event.target.chart.rangeSelector.buttons[selectedButtonIndex].textStr[0]))
                        {
                            years = event.rangeSelectorButton.count;
                        }
                    }
                    else
                    {
                        if(firstTime.current === true)
                        {
                            firstTime.current = false;
                            years = 1;
                        }
                    }
                    if(years !== 0)
                    {
                        var endDate = event.target.dataMax;
                        var startDate = event.target.min || event.target.dataMin;
                        var duration = Moment.duration(years, "years");
                        startDate = Moment(endDate).subtract(duration).unix() * 1000;
                        event.target.setExtremes(startDate, endDate, true, true);
                    }
                }
            }
        }
    });
    const handleWindowResize = React.useCallback(event =>
    {
        setWindowSize(window.innerWidth);

    }, []);
    React.useEffect(() =>
    {
        window.addEventListener('resize', handleWindowResize);

        return () =>
        {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);
    const toggleScatterChange = (ev: any, checked?: boolean) =>
    {
        if(checked !== undefined)
        {
            setScatterGraphic(checked);
            appInsights.trackEvent({name: "Gráfico Rolling Ratios", properties: {isin: fund.isin, indice: fund.benchmarkFinametrixId}});
        }
    }
    const updateCurrentYear = (value: any) =>
    {
        setHighstockOptions(prevState =>
        {
            return{ ...prevState, rangeSelector: {...prevState.rangeSelector, selected: (parseInt(value[0]) - 1)}}
        });
    };
    const transformValue = (value: any) =>
    {
        if(value === 'NaN' || value === null || value === '' || value === undefined)
        {
            return 'ND';
        }
        else
        {
            return parseFloat((value * 100).toFixed(2));
        }
    };
    const transformNoMultiplyValue = (value: any) =>
    {
        if(value === 'NaN' || value === null || value === '' || value === undefined)
        {
            return 'ND';
        }
        else
        {
            return parseFloat(value.toFixed(2));
        }
    };
    const fetchDetails = async (fund: any) =>
    {
        setChatGPTResponse(
        {
            isin: "",
            managerName: "",
            isGeneratingVideo: false,
            fund:
            {
                explanation: "",
                urlAudio: "",
                urlVideo: "",
                isVideoAvailable: false,
                isAudioAvailable: false,
                isFundPromoted: false,
                textDate: "",
                videoDate: "",
                price: 0
            },
            lastFund:
            {
                explanation: "",
                urlAudio: "",
                urlVideo: "",
                isVideoAvailable: false,
                isAudioAvailable: false,
                isFundPromoted: false,
                textDate: "",
                videoDate: "",
                price: 0
            },
            userId: user.id,
            elementsIA: 0
        });
        setLoadedDetails(false);
        try
        {
            const response = await FundDetails.getDetails(fund.allFundsId, fund.benchmarkFinametrixId);
            setFundDetails(response);
        }
        catch(error: any)
        {
            setError(error);
        }
        setLoadedDetails(true);
    };
    const fetchBrothers = async (fund: any) =>
    {
        let response = await FundService.getFunds(0, 100, fund.isin);
        setFundGroups(response.results);
    };
    React.useEffect(() =>
    {
        const options = fundGroups.filter((brother: any) => brother.finametrixId !== fund.finametrixId).map((brother: any) =>
        {
            return{
                label: brother.name,
                value: brother.finametrixId
            }
        });

        setSiblingsOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fundGroups]);
    const fetchSimilarProducts = async (fund: any) =>
    {
        setLoadingSimilarProducts(true);
        try
        {
            const response = await FundService.getFunds(0, 8, undefined, fund.morningStarCategoryId, true);
            let similarProducts: any = [];

            if(response.results.length)
            {
                const grouped = groupFundsByFamily(response.results);
                grouped.forEach((p) =>
                {
                    if(p.children[0])
                    {
                        similarProducts.push(p.children[0]);
                    }
                });
            }

            setSimilarProducts(similarProducts);
            setLoadingSimilarProducts(false);
        }
        catch(error)
        {
            setLoadingSimilarProducts(false);
        }
    };
    const addToCart = async () =>
    {
        setSending(true);
        await AppendCartLine(dispatch, history,
        {
            fund: fund,
            amount: 0
        });
        setSending(false);
    };
    const getDetailValue = (key: string) =>
    {
        return yearDetail ? yearDetail[`${currentYear}_${key}`] : '';
    };
    const getBenchmarkValue = (key: any) =>
    {
        return yearBenchmarkDetail ? yearBenchmarkDetail[`${currentYear}_${key}`] : '';
    };
    React.useEffect(() =>
    {
        let fundIsin: string;
        let pathIFrame: any = publicVersion.path !== undefined ? publicVersion.path.includes('iframe') : false;
        if(pathIFrame === true)
        {
            fundIsin = location.pathname.split('/')[2];
            setIsIframe(true);
        }
        else
        {
            fundIsin = location.pathname.split('/')[1];
            setIsIframe(false);
        }

        isJanusFund.current = janusHendersonFunds.current.includes(fundIsin);

        const fetchFundInfo = async () =>
        {
            setLoadingFund(true);
            let response = await FundService.getFund(fundIsin);
            setFund(response);
            setLoadingFund(false);
        };
        window.scrollTo(0,0);
        fetchFundInfo();
    }, [location, publicVersion.path]);
    React.useEffect(() =>
    {
        appInsights.trackEvent({name: "Buscador Ficha Fondo", properties: {isin: fund.isin, allfundsId: fund.allFundsId, indice: fund.benchmarkFinametrixId}});
        const fetchData = async () =>
        {
            await fetchDetails(fund);
            fetchSimilarProducts(fund);
            fetchBrothers(fund);
        };

        if(fund.finametrixId)
        {
            fetchData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fund]);
    React.useEffect(() =>
    {
        if(fundDetails && fundDetails['prices'][fund.allFundsId] && fundDetails['ratios'][fund.allFundsId])
        {            
            IronIAIntegrations.getLatestVideoInformation(fund.isin).then((response: LatestVideoInformation) =>
            {
                if(response.dateLastVideo !== "")
                {
                    const lastPrice = Object.keys(fundDetails['prices'][fund.allFundsId]);
                    const lastPriceChangeDate: any = lastPrice[lastPrice.length-1];
                    const lastPriceChangeValue = fundDetails['prices'][fund.allFundsId][lastPriceChangeDate];
                    const prevPriceChangeDate: any = lastPrice[lastPrice.indexOf(response.dateLastVideo)];
                    const prevPriceChangeValue = fundDetails['prices'][fund.allFundsId][prevPriceChangeDate];
    
                    setCreateNewIroniaExplicaData((1-(prevPriceChangeValue/lastPriceChangeValue))*100 > 1);
                    setLastPrice({date: lastPriceChangeDate, value: lastPriceChangeValue});
                }
                else
                {
                    setCreateNewIroniaExplicaData(true);
                    const lastPriceChangeDate: any = Object.keys(fundDetails['prices'][fund.allFundsId])[Object.keys(fundDetails['prices'][fund.allFundsId]).length-1];
                    const lastPriceChangeValue = fundDetails['prices'][fund.allFundsId][lastPriceChangeDate];
                    setLastPrice({date: lastPriceChangeDate, value: lastPriceChangeValue});
                }

                setYearDetail(fundDetails['ratios'][fund.allFundsId]);
                setYearBenchmarkDetail(fundDetails['ratios'][fund.benchmarkFinametrixId]);
                
                let fundSerie =
                {
                    name: fund.name,
                    value: fund.isin,
                    data: Object.entries(fundDetails['prices'][fund.allFundsId.toString()]).map((serie) =>
                    {
                        var offset = Moment().utcOffset();
                        let date = Moment(serie[0]).add(offset, 'minutes').locale('es');
                        return [date.valueOf(), serie[1]];
                    }),
                    events:
                    {
                        legendItemClick: function(event: any)
                        {
                            event.preventDefault();
                        }
                    }
                }

                setChartStartDate(Object.entries(fundDetails['prices'][fund.allFundsId.toString()])[0][0]);

                var auxBenchmarkSeries = [fundSerie];
    
                if(fund.benchmarkFinametrixId !== "" && fundDetails['prices'][fund.benchmarkFinametrixId])
                {
                    var filteredFundPrices = Object.entries(fundDetails['prices'][fund.benchmarkFinametrixId]).filter((serie) =>
                    {
                        return Moment(serie[0]) >= Moment(Object.entries(fundDetails['prices'][fund.allFundsId.toString()])[0][0]);
                    });
    
                    let fundBenchmarkSerie =
                    {
                        name: fund.benchmark, /* || (benchmarks.find((benchmark: any) => benchmark.key === fund.benchmarkFinametrixId).text),*/
                        value: fund.benchmarkFinametrixId,
                        data: filteredFundPrices.map((serie) =>
                        {
                            var offset = Moment().utcOffset();
                            let date = Moment(serie[0]).add(offset, 'minutes').locale('es');
                            return [date.valueOf(), serie[1]];
                        }),
                        events:
                        {
                            legendItemClick: function (event: any)
                            {
                                event.preventDefault();
                            }
                        }
                    };
    
                    auxBenchmarkSeries.push(fundBenchmarkSerie);
                }
                
                var chatGPTData: ChatGPT =
                {
                    annualReturn: fundDetails['ratios'][fund.allFundsId]['1y_linear_return'] * 100,
                    fee: fund.ongoingCharges,
                    currency: fund.currency,
                    fundName: fund.name,
                    isin: fund.isin,
                    volatility: fundDetails['ratios'][fund.allFundsId]['1y_volatility'] * 100,
                    indexName: fund.benchmark,
                    indexAnnualReturn: fundDetails['ratios'][fund.benchmarkFinametrixId]['1y_linear_return'] * 100,
                    indexVolatility: fundDetails['ratios'][fund.benchmarkFinametrixId]['1y_volatility'] * 100,
                    ironiaPoints: Math.ceil(fund.averageScore / 20),
                    managerName: translate(fund.manager),
                    forceNew: createNewIroniaExplicaData as boolean,
                    userId: user.id
                };
    
                setChatGPTData((prevState: ChatGPT) =>
                ({
                    ...prevState,
                    annualReturn: parseFloat(chatGPTData.annualReturn.toFixed(2)),
                    fee: chatGPTData.fee,
                    currency: chatGPTData.currency,
                    fundName: chatGPTData.fundName,
                    isin: chatGPTData.isin,
                    volatility: parseFloat(chatGPTData.volatility.toFixed(2)),
                    indexName: chatGPTData.indexName,
                    indexAnnualReturn: parseFloat(chatGPTData.indexAnnualReturn.toFixed(2)),
                    indexVolatility: parseFloat(chatGPTData.indexVolatility.toFixed(2)),
                    ironiaPoints: chatGPTData.ironiaPoints,
                    managerName: chatGPTData.managerName,
                    forceNew: chatGPTData.forceNew
                }));
    
                setBenchmarkSeries(auxBenchmarkSeries);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fundDetails]);
    React.useEffect(() =>
    {
        //Primero vamos a organizar el caso de que la gráfica se pinte con un fondo y un índice sin variación
        if(doubleFund === false && changeBenchmark === false)
        {
            if(fund.allFundsId !== undefined && fund.allFundsId > 0)
            {
                FundService.getRatioFundSeries(fund.allFundsId).then((response: IRatioFunds[]) =>
                {
                    if(response.length > 0)
                    {
                        var ratioObject: any =
                        [
                            {fundName: fund.name, data: [], type: 'scatter', color: '#4284E6'},
                            {fundName: fund.benchmark, data: [], type: 'line', color: '#CC214F'},
                            {fundName: "Últimas Rentabilidades", data: [], type: 'scatter', color: '#F5EB15'}
                        ];
    
                        for(let i=0; i<response.length; i++)
                        {
                            ratioObject[0].data.push(
                            {
                                x: response[i].valueBenchmark*100,
                                y: response[i].valueProduct*100,
                                period: response[i].period
                            });
    
                            ratioObject[1].data.push(
                            {
                                x: response[i].valueBenchmark*100,
                                y: response[i].valueBenchmark*100,
                                period: response[i].period
                            });
                        }
    
                        var periodSets: string[] = ratioObject[0].data.reduce((periodSet: Set<number>, obj: any) =>
                        {
                            periodSet.add(obj.period);
                            return periodSet;
                        }, new Set<number>()); 
    
                        var periodsNoOrdered: string[] = Array.from(periodSets);
                        var periods: string[] = periodsNoOrdered.sort(comparer);
                        setRatios(ratioObject);
    
                        if(periods.indexOf("3y") !== -1)
                        {
                            setPeriod("3y");
                        }
                        else
                        {
                            setPeriod(periods[periods.length-1]);
                        }
                        setAllPeriods(periods);
                    }
                });
            }
        }
        //Segundo vamos a contemplar el caso de que la gráfica se pinte con doble fondo y un índice sin variación
        if(doubleFund === true && changeBenchmark === false)
        {
            if(secondScatterFund !== undefined && secondScatterFund.length > 0)
            {
                FundService.getFundByFinametrixId(secondScatterFund[0].value).then((response: any) =>
                {
                    if(fund.allFundsId !== undefined && fund.allFundsId > 0 && response !== undefined && response.allFundsId !== -1)
                    {
                        FundService.getRatioForBothFunds(fund.allFundsId, response.allFundsId).then((result: IRatioBothFunds[]) =>
                        {
                            if(result.length > 0)
                            {
                                var ratioObject: any =
                                [
                                    {fundName: fund.name, data: [], type: 'scatter', color: '#4284E6'},
                                    {fundName: fund.benchmark, data: [], type: 'line', color: '#CC214F'},
                                    {fundName: response.name, data: [], type: 'scatter', color: '#3BE345'},
                                    {fundName: "Últimas Rentabilidades del primer Fondo", data: [], type: 'scatter', color: '#F5EB15'},
                                    {fundName: "Últimas Rentabilidades del segundo Fondo", data: [], type: 'scatter', color: '#F270FF'}
                                ];
            
                                for(let i=0; i<result.length; i++)
                                {
                                    ratioObject[0].data.push(
                                    {
                                        x: result[i].valueBenchmark*100,
                                        y: result[i].firstFundValue*100,
                                        period: result[i].period
                                    });
            
                                    ratioObject[1].data.push(
                                    {
                                        x: result[i].valueBenchmark*100,
                                        y: result[i].valueBenchmark*100,
                                        period: result[i].period
                                    });
        
                                    let secondFund: any = result[i].secondFundValue;
        
                                    if(secondFund !== undefined)
                                    {
                                        secondFund = secondFund*100;
                                    }
                                    else
                                    {
                                        secondFund = null;
                                    }
        
                                    ratioObject[2].data.push(
                                    {
                                        x: result[i].valueBenchmark*100,
                                        y: secondFund,
                                        period: result[i].period
                                    })
                                }
            
                                var firstPeriodSets: string[] = ratioObject[0].data.reduce((firstPeriodSet: Set<number>, obj: any) =>
                                {
                                    firstPeriodSet.add(obj.period);
                                    return firstPeriodSet;
                                }, new Set<number>());
            
                                var periodsNoOrdered: string[] = Array.from(firstPeriodSets);
                                var periods: string[] = periodsNoOrdered.sort(comparer);
                                setRatios(ratioObject);
            
                                if(periods.indexOf("3y") !== -1)
                                {
                                    setPeriod("3y");
                                }
                                else
                                {
                                    setPeriod(periods[periods.length-1]);
                                }
                                setAllPeriods(periods);
                            }
                        });
                    }
                });
            }
        }
        //Tercero vamos a contemplar el caso de que la gráfica se pinte con un fondo y con cambio de índice
        if(doubleFund === false && changeBenchmark === true)
        {
            if(fund.allFundsId !== undefined && fund.allFundsId > 0 && secondBenchmark !== undefined)
            {
                FundService.getRatioChangeBenchmark(fund.allFundsId, secondBenchmark.value).then((response: IRatioFundChangeBenchmark[]) =>
                {
                    if(response.length > 0)
                    {
                        var ratioObject: any =
                        [
                            {fundName: fund.name, data: [], type: 'scatter', color: '#4284E6'},
                            {fundName: secondBenchmark.label, data: [], type: 'line', color: '#F0821A'},
                            {fundName: "Últimas Rentabilidades", data: [], type: 'scatter', color: '#F5EB15'}
                        ];
    
                        for(let i=0; i<response.length; i++)
                        {
                            ratioObject[0].data.push(
                            {
                                x: response[i].benchmark*100,
                                y: response[i].fundValue*100,
                                period: response[i].period
                            });
    
                            ratioObject[1].data.push(
                            {
                                x: response[i].benchmark*100,
                                y: response[i].benchmark*100,
                                period: response[i].period
                            });
                        }
    
                        var periodSets: string[] = ratioObject[0].data.reduce((periodSet: Set<number>, obj: any) =>
                        {
                            periodSet.add(obj.period);
                            return periodSet;
                        }, new Set<number>()); 
    
                        var periodsNoOrdered: string[] = Array.from(periodSets);
                        var periods: string[] = periodsNoOrdered.sort(comparer);
                        setRatios(ratioObject);
    
                        if(periods.indexOf("3y") !== -1)
                        {
                            setPeriod("3y");
                        }
                        else
                        {
                            setPeriod(periods[periods.length-1]);
                        }
                        setAllPeriods(periods);
                    }
                });
            }
        }
        //Cuarto vamos a contemplar el caso de que la gráfica se pinte con doble fondo y cambio de índice
        if(doubleFund === true && changeBenchmark === true)
        {
            if(secondScatterFund !== undefined && secondScatterFund.length > 0)
            {
                FundService.getFundByFinametrixId(secondScatterFund[0].value).then((response: any) =>
                {
                    if(fund.allFundsId !== undefined && fund.allFundsId > 0 && response !== undefined && response.allFundsId !== -1 && secondBenchmark !== undefined)
                    {
                        FundService.getRatioBothFundsChangeBenchmark(fund.allFundsId, response.allFundsId, secondBenchmark.value).then((result: IRatioTwoFundsChangeBenchmark[]) =>
                        {
                            if(result.length > 0)
                            {
                                var ratioObject: any =
                                [
                                    {fundName: fund.name, data: [], type: 'scatter', color: '#4284E6'},
                                    {fundName: secondBenchmark.label, data: [], type: 'line', color: '#F0821A'},
                                    {fundName: response.name, data: [], type: 'scatter', color: '#3BE345'},
                                    {fundName: "Últimas Rentabilidades del primer Fondo", data: [], type: 'scatter', color: '#F5EB15'},
                                    {fundName: "Últimas Rentabilidades del segundo Fondo", data: [], type: 'scatter', color: '#F270FF'}
                                ];
            
                                for(let i=0; i<result.length; i++)
                                {
                                    ratioObject[0].data.push(
                                    {
                                        x: result[i].benchmark*100,
                                        y: result[i].firstFundValue*100,
                                        period: result[i].period
                                    });
            
                                    ratioObject[1].data.push(
                                    {
                                        x: result[i].benchmark*100,
                                        y: result[i].benchmark*100,
                                        period: result[i].period
                                    });
        
                                    let secondFund: any = result[i].secondFundValue;
        
                                    if(secondFund !== undefined)
                                    {
                                        secondFund = secondFund*100;
                                    }
                                    else
                                    {
                                        secondFund = null;
                                    }
        
                                    ratioObject[2].data.push(
                                    {
                                        x: result[i].benchmark*100,
                                        y: secondFund,
                                        period: result[i].period
                                    })
                                }
            
                                var firstPeriodSets: string[] = ratioObject[0].data.reduce((firstPeriodSet: Set<number>, obj: any) =>
                                {
                                    firstPeriodSet.add(obj.period);
                                    return firstPeriodSet;
                                }, new Set<number>());
            
                                var periodsNoOrdered: string[] = Array.from(firstPeriodSets);
                                var periods: string[] = periodsNoOrdered.sort(comparer);
                                setRatios(ratioObject);
            
                                if(periods.indexOf("3y") !== -1)
                                {
                                    setPeriod("3y");
                                }
                                else
                                {
                                    setPeriod(periods[periods.length-1]);
                                }
                                setAllPeriods(periods);
                            }
                        });
                    }
                });
            }
        }
    }, [fund, secondScatterFund, doubleFund, changeBenchmark, secondBenchmark]);
    React.useEffect(() =>
    {
        if(selectedBenchmarks.length > 0)
        {
            FundDetails.getSeries(selectedBenchmarks.map(b => b.value)).then((prices) =>
            {
                let aux = selectedBenchmarks.map((benchmark: any) =>
                {
                    let filteredBenchmarksPrices = Object.entries(prices[benchmark.value]).filter((serie) =>
                    {
                        return Moment(serie[0]) >= Moment(chartStartDate);
                    });

                    return{
                        name: benchmark.label,
                        value: benchmark,
                        data: filteredBenchmarksPrices.map((serie) =>
                        {
                            var offset = Moment().utcOffset();
                            let date = Moment(serie[0]).add(offset, 'minutes').locale('es');
                            return [date.valueOf(), serie[1]];
                        }),
                        events:
                        {
                            legendItemClick: function (event: any)
                            {
                                event.preventDefault();
                            }
                        }
                    }
                });
                setBenchmarkSeries([benchmarkSeries[0], benchmarkSeries[1], ...aux]);
            },
            (error) =>
            {
                setError(error);
            });
        }
        else
        {
            if(benchmarkSeries.length > 1)
            {
                setBenchmarkSeries([benchmarkSeries[0], benchmarkSeries[1]]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBenchmarks]);
    React.useEffect(() =>
    {
        if(isIFrame === false)
        {
            if(chatGPTData !== undefined && chatGPTData.fundName !== "")
            {
                IronIAIntegrations.getChatGPTInfo(chatGPTData).then((response: ChatGPTResponse) =>
                {
                    setChatGPTResponse(response);

                    if(response.fund !== null)
                    {
                        setChatGPTHelmetValue(response.fund.explanation);
                    }
                    else
                    {
                        if(response.lastFund !== null)
                        {
                            setChatGPTHelmetValue(response.lastFund.explanation);
                        }
                    }
                });
            }
        }
    }, [chatGPTData, isIFrame]);
    if(error.status)
    {
        return(<div>Error: {error.message}</div>);
    }

    return(
        <L.Main>
            <Helmet>
                <title>IronIA - {fund.isin} - {fund.name}</title>
                <meta name="description" content={`${fund.isin} - Fondo de Inversión de ${fund.name}, Categoría: ${fund.category} y Subcategoría: ${fund.subcategory}.`} />
                <meta name="IronIAExplica" content={chatGPTHelmetValue} />
            </Helmet>
            <L.BackButtonContainer>
                <Stack horizontal verticalAlign="center" grow={1}>
                    <span style={{paddingTop: "0.45rem"}}>
                        <CaretHollowMirroredIcon />
                        <button onClick={() => history.goBack()}>Volver atrás</button>
                    </span>
                    <Stack.Item grow={1}>
                        <h2>{fund ? fund.name : ''}</h2>
                    </Stack.Item>
                    {fundGroups?.length > 0 &&
                    (
                        <Stack.Item>
                            <HighlightedPanelButton theme={theme} onClick={() => setShowBrothers(!showBrothers)}>
                                {showBrothers ?
                                    <React.Fragment>Ver menos <ChevronUpIcon className="brothers-btn-icon" /></React.Fragment> :
                                    <React.Fragment>Más clases ({fundGroups.length}) <ChevronDownIcon className="brothers-btn-icon" /></React.Fragment>
                                }
                            </HighlightedPanelButton>
                        </Stack.Item>
                    )}
                    {similarProducts.length > 0 && !loadingSimilarProducts &&
                    (
                        <Stack.Item style={{marginLeft: "10px"}}>
                            <HighlightedPanelButton theme={theme}>
                                <Scroll.Link to="similar-products" duration={500} smooth={true}>
                                    <div onClick={() => appInsights.trackEvent({name: "Similares"})}>
                                        Ver Productos Similares
                                    </div>
                                </Scroll.Link>
                            </HighlightedPanelButton>
                        </Stack.Item>
                    )}
                </Stack>
            </L.BackButtonContainer>
            {fundGroups?.length > 0 && showBrothers &&
            (
                <L.FundBrothersContainer theme={theme}>
                    <FundTableMin children={fundGroups} onRowClicked={() => {}}/>
                </L.FundBrothersContainer>
            )}
            <Separator style={{paddingTop: 0, paddingBottom: '0.5em'}} />
            <L.ContentWrapper>
                <L.LeftWrapper>
                    <L.GraphContainer>
                        {scatterGraphic === false &&
                        (
                            <React.Fragment>
                                <L.RowToggleTitle className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xl7 ms-xxl8 ms-xxxl8">
                                        <L.MainTitle>Trayectoria del fondo</L.MainTitle>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg5 ms-xl2 ms-xxl2 ms-xxxl2">
                                        <L.ToggleLabel>Rentabilidad</L.ToggleLabel>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 ms-xl1 ms-xxl1 ms-xxxl1">
                                        <L.ScatterToggle theme={theme} defaultChecked={scatterGraphic} onChange={toggleScatterChange} />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg5 ms-xl2 ms-xxl1 ms-xxxl1">
                                        <L.ToggleLabel>Rolling</L.ToggleLabel>
                                    </div>
                                </L.RowToggleTitle>
                                <Highstock series={benchmarkSeries} customOptions={highstockOptions} graphicType={GraphicType.linearGraphic} />
                            </React.Fragment>
                        )}
                        {scatterGraphic === true &&
                        (
                            <React.Fragment>
                                <L.RowToggleTitle className="ms-Grid-row">
                                    <L.ColTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xl7 ms-xxl8 ms-xxxl8">
                                        <L.MainTitle id={windowSize < 1024 ? '' : 'tooltipRatio'} onMouseEnter={showTooltipRatio} onClick={showTooltipRatio}>
                                            Rentabilidad Rolling <img style={{verticalAlign: 'bottom'}} src="/icons/informationBlack.svg" alt="Información" />
                                        </L.MainTitle>
                                    </L.ColTitle>
                                    <L.ColFirstToggleElement className="ms-Grid-col ms-sm12 ms-md12 ms-lg5 ms-xl2 ms-xxl2 ms-xxxl2">
                                        <L.ToggleLabel>Rentabilidad</L.ToggleLabel>
                                    </L.ColFirstToggleElement>
                                    <L.ColToggleElement className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 ms-xl1 ms-xxl1 ms-xxxl1">
                                        <L.ScatterToggle theme={theme} defaultChecked={scatterGraphic} onChange={toggleScatterChange} />
                                    </L.ColToggleElement>
                                    <L.ColSecondToggleElement className="ms-Grid-col ms-sm12 ms-md12 ms-lg5 ms-xl2 ms-xxl1 ms-xxxl1">
                                        <L.ToggleLabel>Rolling</L.ToggleLabel>
                                    </L.ColSecondToggleElement>
                                </L.RowToggleTitle>
                                <ScatterHighstock series={ratios} defaultPeriod={period} allPeriods={allPeriods} doubleFund={doubleFund} />
                                {tooltipRatio === true &&
                                (
                                    <TeachingBubble target="#tooltipRatio"
                                        primaryButtonProps={stepTooltipFirstButton}
                                        secondaryButtonProps={stepTooltipSecondButton}
                                        footerContent={`${tooltipRatioPage+1} de 5`}
                                        onDismiss={hideTooltipRatio}
                                        calloutProps={
                                        {
                                            backgroundColor: 'white !important',
                                            style: {color: 'red'},
                                            directionalHint: DirectionalHint.rightTopEdge
                                        }}
                                        hasCloseButton={true}
                                        closeButtonAriaLabel="Cerrar"
                                        styles={
                                        {
                                            bodyContent: {'.ms-Icon': {color: '#555 !important'}},
                                            body:
                                            {
                                                background: 'white',
                                                marginRight: '0',
                                                marginBottom: '0 !important',
                                                '.TipStep': {transition: 'all 0.5s ease-in-out 0s', opacity: '1'},
                                                '.hidden': {opacity: '0', display: 'inline-block !important', height: '0', width: '0', overflow: 'hidden'}
                                            },
                                            subText: {color: '#555 !important', 'p': {margin: '5px 0'}},
                                            footer:
                                            {
                                                color: '#555 !important',
                                                '.ms-Button:not(:first-child)': {marginLeft: '0 !important'}
                                            }
                                        }}
                                    >
                                        <div className='header'>
                                            <LightbulbIcon style={{color: 'gold', width: '20px', height: '20px'}} />
                                            <h3 style={{display: 'inline', verticalAlign: 'middle', marginLeft: '5px', cursor: 'default'}}>Tips</h3>
                                        </div>
                                        <div className={`TipStep ${tooltipRatioPage === 0 ? '' : 'hidden'}`}>
                                            <img src={ratios14} alt='Ratios1' style={{margin: '5px 0', width: '100%'}} />
                                            <h4>
                                                ¿Por qué este gráfico es importante?
                                            </h4>
                                            <L.TipParagraph>
                                                Este gráfico muestra si un fondo es capaz de superar a su índice de forma consistente en un plazo determinado.
                                                Todos los puntos que se sitúen por encima de la recta son periodos en los que el fondo supera al índice y, 
                                                por el contrario, si están por debajo, quiere decir que el fondo lo ha hecho peor que el índice.
                                            </L.TipParagraph>
                                        </div>
                                        <div className={`TipStep ${tooltipRatioPage === 1 ? '' : 'hidden'}`}>
                                            <img src={ratios2} alt='Ratios2' style={{margin: '5px 0', width: '100%'}} />
                                            <h4>
                                                ¿Qué nos indica este gráfico?
                                            </h4>
                                            <L.TipParagraph>
                                                En el eje horizontal encontramos la rentabilidad del índice y en el eje vertical la rentabilidad del fondo. 
                                                Cada punto representa, por tanto, la rentabilidad que ha obtenido el fondo para cada rentabilidad del índice. 
                                                La línea representa la rentabilidad obtenida por el índice.
                                            </L.TipParagraph>
                                        </div>
                                        <div className={`TipStep ${tooltipRatioPage === 2 ? '' : 'hidden'}`}>
                                            <img src={ratios3} alt='Ratios3' style={{margin: '5px 0', width: '100%'}} />
                                            <h4>
                                                ¿Qué son las rentabilidades rolling?
                                            </h4>
                                            <L.TipParagraph>
                                                Las rentabilidades rolling, de 1 año (1y), por ejemplo, son todas las rentabilidades que ha conseguido 
                                                un fondo en plazos de 52 semanas, de 1 enero de 2020 a 1 de enero de 2021, de 8 de enero a 8 de enero, de 15 a 15 
                                                y así sucesivamente. Si son 3 años (3y) serán de 2020 a 2023, y así con todos los periodos.
                                            </L.TipParagraph>
                                        </div>
                                        <div className={`TipStep ${tooltipRatioPage === 3 ? '' : 'hidden'}`}>
                                            <img src={ratios14} alt='Ratios4' style={{margin: '5px 0', width: '100%'}} />
                                            <h4>
                                                ¿Cuándo un fondo es bueno?
                                            </h4>
                                            <L.TipParagraph>
                                                Un buen fondo será aquel que consiga obtener mejores resultados que su índice de referencia en un plazo razonable 
                                                de forma consistente. En plazos muy cortos (1 mes, 3 meses, 6 meses...), lo normal es que no haya consistencia en 
                                                los resultados. En plazos mayores habrá muchos puntos por encima del índice si el fondo sea bueno.
                                            </L.TipParagraph>
                                        </div>
                                        <div className={`TipStep ${tooltipRatioPage === 4 ? '' : 'hidden'}`}>
                                            <img src={ratios5} alt='Ratios5' style={{margin: '5px 0', width: '100%'}} />
                                            <h4>
                                                ¿Cuándo un fondo no es bueno?
                                            </h4>
                                            <L.TipParagraph style={{marginBottom: "12px"}}>
                                                Un mal fondo será aquel que no consiga obtener mejores resultados que su índice de referencia en un plazo razonable 
                                                de forma consistente. En plazos muy cortos (1 mes, 3 meses, 6 meses...), lo normal es que no haya consistencia en 
                                                los resultados. En plazos mayores habrá muchos puntos por debajo del índice cuando el fondo sea malo.
                                            </L.TipParagraph>
                                        </div>
                                    </TeachingBubble>
                                )}
                            </React.Fragment>
                        )}
                        <Separator />
                        {scatterGraphic === true &&
                        (
                            <React.Fragment>
                                <L.ScatterFilters>
                                    <L.BenchmarkFilter>
                                        <L.ScatterTitle>
                                            Comparar Índices
                                        </L.ScatterTitle>
                                        <L.ScatterDescription className="description">
                                            Selecciona un índice para sustituir al actual y comparar.
                                        </L.ScatterDescription>
                                        <L.FilterContainer>
                                            <BenchmarkScatterDropdown benchmarkInUse={{label: fund.benchmark, value: fund.benchmarkFinametrixId}} onSave={(selectedBenchmark: any) =>
                                                {
                                                    if(selectedBenchmark.value === secondBenchmark?.value)
                                                    {
                                                        setChangeBenchmark(false);
                                                    }
                                                    else
                                                    {
                                                        setChangeBenchmark(true);
                                                    }
                                                    setSecondBenchmark(selectedBenchmark);
                                                }}
                                            />
                                        </L.FilterContainer>
                                    </L.BenchmarkFilter>
                                    <L.FundFilter>
                                        <L.ScatterTitle>
                                            Comparar dos fondos
                                        </L.ScatterTitle>
                                        <L.ScatterDescription className="description">
                                            Selecciona un fondo para comparar su rentabilidad rolling.
                                        </L.ScatterDescription>
                                        <L.FilterContainer>
                                            <MultiFundsAndBenchmarksDropDown fund={fund} siblings={siblingsOptions} scatterGraphic={scatterGraphic} onSave={(selectedBenchmarks: any) =>
                                                {
                                                    setSecondScatterFund(selectedBenchmarks);
                                                    if(selectedBenchmarks.length > 0)
                                                    {
                                                        setDoubleFund(true);
                                                    }
                                                    else
                                                    {
                                                        setDoubleFund(false);
                                                    }
                                                }}
                                            />
                                        </L.FilterContainer>
                                    </L.FundFilter>
                                </L.ScatterFilters>
                            </React.Fragment>
                        )}
                        {scatterGraphic === false &&
                        (
                            <React.Fragment>
                                <h3>Comparar con índices y fondos</h3>
                                <p className="description">Selecciona tantos índices y fondos como quieras y compara su rentabilidad en el tiempo.</p>
                                <L.FilterContainer>
                                    <MultiFundsAndBenchmarksDropDown fund={fund} siblings={siblingsOptions} scatterGraphic={scatterGraphic} onSave={(selectedBenchmarks: any) =>
                                        {
                                            setSelectedBenchmarks(selectedBenchmarks);
                                            setDoubleFund(false);
                                        }}
                                    />
                                </L.FilterContainer>
                            </React.Fragment>
                        )}
                    </L.GraphContainer>
                    <L.YearsContainer>
                        <button className={currentYear === '1y' ? 'active' : ''} onClick={(event) => { event.stopPropagation(); updateCurrentYear('1y') }}>1y</button>
                        <button className={currentYear === '2y' ? 'active' : ''} onClick={(event) => { event.stopPropagation(); updateCurrentYear('2y') }}>2y</button>
                        <button className={currentYear === '3y' ? 'active' : ''} onClick={(event) => { event.stopPropagation(); updateCurrentYear('3y') }}>3y</button>
                        <button className={currentYear === '4y' ? 'active' : ''} onClick={(event) => { event.stopPropagation(); updateCurrentYear('4y') }}>4y</button>
                        <button className={currentYear === '5y' ? 'active' : ''} onClick={(event) => { event.stopPropagation(); updateCurrentYear('5y') }}>5y</button>
                    </L.YearsContainer>
                    <L.CardContainer>
                        <L.CardHeader>
                            <h3>Rentabilidad</h3>
                        </L.CardHeader>
                        <L.CardBody>
                            {!loadedDetails && yearDetail == null ? (<Spinner size={3} style={{padding: "1em"}} />) :
                            (
                                <Profitability fundName={fund.name}
                                    linear_return={transformValue(getDetailValue('linear_return'))}
                                    b_linear_return={transformValue(getBenchmarkValue('linear_return'))}
                                    alpha={transformValue(getDetailValue('alpha'))}
                                    b_alpha={transformValue(getBenchmarkValue('alpha'))}
                                    benchmark={fund.benchmark}
                                />
                            )}
                        </L.CardBody>
                    </L.CardContainer>
                    <L.CardContainer>
                        <L.CardHeader>
                            <h3>Riesgo</h3>
                        </L.CardHeader>
                        <L.CardBody>
                            {!loadedDetails ? (<Spinner size={3} style={{padding: "1em"}} />) :
                            (
                                <Risk fundName={fund.name}
                                    volatility={transformValue(getDetailValue('volatility'))}
                                    b_volatility={transformValue(getBenchmarkValue('volatility'))}
                                    max_drawdown={transformValue(getDetailValue('max_drawdown'))}
                                    b_max_drawdown={transformValue(getBenchmarkValue('max_drawdown'))}
                                    time_to_recovery={getDetailValue('time_to_recovery')}
                                    b_time_to_recovery={getBenchmarkValue('time_to_recovery')}
                                    correlation={transformNoMultiplyValue(getDetailValue('correlation'))}
                                    b_correlation={transformNoMultiplyValue(getBenchmarkValue('correlation'))}
                                    downside_deviation={transformValue(getDetailValue('downside_deviation'))}
                                    b_downside_deviation={transformValue(getBenchmarkValue('downside_deviation'))}
                                    beta={transformNoMultiplyValue(getDetailValue('beta'))}
                                    b_beta={transformNoMultiplyValue(getBenchmarkValue('beta'))}
                                    bull_beta={transformNoMultiplyValue(getDetailValue('bull_beta'))}
                                    b_bull_beta={transformNoMultiplyValue(getBenchmarkValue('bull_beta'))}
                                    bear_beta={transformNoMultiplyValue(getDetailValue('bear_beta'))}
                                    b_bear_beta={transformNoMultiplyValue(getBenchmarkValue('bear_beta'))}
                                    benchmark={fund.benchmark}
                                />
                            )}
                        </L.CardBody>
                    </L.CardContainer>
                    <L.CardContainer>
                        <L.CardHeader>
                            <h3>Rentabilidad ajustada por riesgo</h3>
                        </L.CardHeader>
                        <L.CardBody>
                            {!loadedDetails ? (<Spinner size={3} style={{padding: "1em"}} />) :
                            (
                                <CompliantProfitability fundName={fund.name}
                                    omega={transformNoMultiplyValue(getDetailValue('omega'))}
                                    b_omega={transformNoMultiplyValue(getBenchmarkValue('omega'))}
                                    sharpe={transformNoMultiplyValue(getDetailValue('sharpe'))}
                                    b_sharpe={transformNoMultiplyValue(getBenchmarkValue('sharpe'))}
                                    calmar={transformNoMultiplyValue(getDetailValue('calmar'))}
                                    b_calmar={transformNoMultiplyValue(getBenchmarkValue('calmar'))}
                                    sortino={transformNoMultiplyValue(getDetailValue('sortino'))}
                                    b_sortino={transformNoMultiplyValue(getBenchmarkValue('sortino'))}
                                    tracking_error={transformValue(getDetailValue('tracking_error'))}
                                    b_tracking_error={transformValue(getBenchmarkValue('tracking_error'))}
                                    treynor={transformNoMultiplyValue(getDetailValue('treynor'))}
                                    b_treynor={transformNoMultiplyValue(getBenchmarkValue('treynor'))}
                                    information_ratio={transformNoMultiplyValue(getDetailValue('information_ratio'))}
                                    b_information_ratio={transformNoMultiplyValue(getBenchmarkValue('information_ratio'))}
                                    benchmark={fund.benchmark}
                                />
                            )}
                        </L.CardBody>
                    </L.CardContainer>
                </L.LeftWrapper>
                <L.RightWrapper>
                    <L.CardContainer>
                        <L.CardHeader>
                            <L.CardHeadingContainer>
                                <h3>¿Te interesa esta oportunidad?</h3>
                            </L.CardHeadingContainer>
                            {publicVersion &&
                                <React.Fragment>
                                    {subscribed === true && cancelled === true &&
                                    (
                                        <React.Fragment>
                                            <L.RedBox>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl2 ms-xxl2 ms-xxxl2">
                                                        <CircledWarning />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9 ms-xl10 ms-xxl10 ms-xxxl10" style={{marginTop: "0.2em", fontSize: "24px"}}>
                                                        <strong>Su plan está cancelado.</strong>
                                                    </div>
                                                </div>
                                                <p>Para realizar compras o traspasos sobre cualquier tipo de inversión, tienes que volver a suscribirte en nuestra plataforma.</p>
                                                {(publicVersion &&
                                                    <Link to={"/savingsPlans/advanced"} target="_blank">
                                                        <DefaultButton styles={{root: {background: '#CC214F'}}}>
                                                            Suscríbete
                                                        </DefaultButton>
                                                    </Link>
                                                ) || 
                                                (
                                                    <Link to={"/savingsPlans/advanced"} target="_blank">
                                                        <DefaultButton styles={{root: {background: '#CC214F'}}}>
                                                            Comienza a invertir
                                                        </DefaultButton>
                                                    </Link>
                                                )}
                                            </L.RedBox>
                                        </React.Fragment>
                                    )}
                                    {subscribed === true && isActiveManagementPlan === false && loadedDetails && fund?.available && !activeSavingsPlanHasOutdatedcontract &&
                                    (
                                        <L.CHButtonsContainer>
                                            <PrimaryButton className="rounded" disabled={!loadedDetails || !fund?.isin}
                                                onClick={() =>
                                                {
                                                    history.push("/internal-switch",
                                                    {
                                                        target: fund,
                                                    });
                                                }}
                                            >
                                                Traspasar a este producto
                                            </PrimaryButton>
                                            <PrimaryButton
                                                className="rounded"
                                                disabled={!loadedDetails || !fund.available || sending}
                                                onClick={addToCart}
                                            >
                                                {sending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                                Añadir a tu cesta
                                            </PrimaryButton>
                                        </L.CHButtonsContainer>
                                    )}
                                    {subscribed === true && isActiveManagementPlan === true && loadedDetails && fund?.available &&
                                    (
                                        <L.Advise>
                                            <Separator />
                                            <CircledWarning />
                                            <h3>COMPRA ESTE FONDO AQUÍ</h3>
                                            <p>
                                                Tu plan activo seleccionado es un plan gestionado. No se permite la compra de fondos en un plan gestionado. Si deseas comprar fondos y gestionar tú
                                                mismo tu propia cartera, adquiere uno de los siguientes planes:
                                            </p>
                                            <L.RedBox>
                                                <div className="row">
                                                    <div className="ms-Grid-col ms-sm6 ms-md-6 ms-lg-6 ms-xl-6" style={{textAlign: "center"}}>
                                                        <Link to={"/savingsPlans/advanced"} target="_blank">
                                                            <DefaultButton styles={{root: {background: '#CC214F'}}}>
                                                                Plan Libertad
                                                            </DefaultButton>
                                                        </Link>
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm6 ms-md-6 ms-lg-6 ms-xl-6" style={{padding: 0, margin: 0, textAlign: "center"}}>
                                                        <DefaultButton styles={{root: {background: '#CC214F'}}}>
                                                            Gurú Financiero
                                                        </DefaultButton>
                                                    </div>
                                                </div>
                                            </L.RedBox>
                                        </L.Advise>
                                    )}
                                    {loadedDetails && !fund?.available &&
                                    (
                                        <L.Advise>
                                            <Separator />
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl2 ms-xxl2 ms-xxxl2">
                                                    <CircledWarning />
                                                </div>
                                                <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9 ms-xl10 ms-xxl10 ms-xxxl10" style={{marginTop: "1.1em"}}>
                                                    <h3 style={{marginLeft: "0 !important"}}>NO DISPONIBLE</h3>
                                                </div>
                                            </div>
                                            <p>Si desea activarlo póngase en contacto con nosotros en el mail <a href="mailto:info@ironia.tech" target="_blank" rel="noreferrer">info@ironia.tech</a></p>
                                        </L.Advise>
                                    )}
                                    {!loadedDetails &&
                                    (
                                        <L.CardBody>
                                            <Spinner size={3} style={{padding: "1em"}} />
                                        </L.CardBody>
                                    )}
                                </React.Fragment>
                            }
                            {subscribed === false &&
                                <L.RedBox>
                                    <CircledWarning />
                                    <strong>¿Quieres realizar alguna operación?</strong>
                                    <p>Para realizar compras o traspasos sobre cualquier tipo de inversión, tienes que registrarte en nuestra plataforma.</p>
                                    {(publicVersion &&
                                        <Link to={"/savingsPlans/advanced"} target="_blank">
                                            <DefaultButton styles={{root: {background: '#CC214F'}}}>
                                                Regístrate
                                            </DefaultButton>
                                        </Link>
                                    ) ||
                                    (
                                        <Link to={"/savingsPlans/advanced"} target="_blank">
                                            <DefaultButton styles={{root: {background: '#CC214F'}}}>
                                                Comienza a invertir
                                            </DefaultButton>
                                        </Link>
                                    )}
                                </L.RedBox>
                            }
                            {isIFrame === true &&
                            (
                                <ModalFundExplanation chatGPTResponse={chatGPTResponse} isIframe={true} 
                                    isUserSubscribedToElements={savingsPlanElementsStatus.isSubscribed}
                                    userId={user.id}
                                    savingsPlanId={activePlan.id}
                                />
                            )}
                            {isIFrame === false &&
                            (
                                <React.Fragment>
                                    {isJanusFund.current === false &&
                                    (
                                        <ModalFundExplanation
                                            chatGPTResponse={chatGPTResponse}
                                            isUserSubscribedToElements={savingsPlanElementsStatus.isSubscribed}
                                            userId={user.id}
                                            savingsPlanId={activePlan.id}
                                        />
                                    )}
                                    {isJanusFund.current === true &&
                                    (
                                        <React.Fragment>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6 ms-xxxl6">
                                                    <ModalFundExplanation
                                                        chatGPTResponse={chatGPTResponse}
                                                        isUserSubscribedToElements={savingsPlanElementsStatus.isSubscribed}
                                                        userId={user.id}
                                                        managerEnabled={true}
                                                        savingsPlanId={activePlan.id}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm6 ms-xxxl6">
                                                    <ModalManagerExplanation isin={fund.isin} />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </L.CardHeader>
                        <L.CardBody></L.CardBody>
                    </L.CardContainer>
                    <L.CardContainer>
                        <L.CardHeadingContainer>
                            <h3>Información adicional</h3>
                        </L.CardHeadingContainer>
                        <L.CardBody>
                            {loadingFund ? (<Spinner size={3} style={{padding: "1em"}} />) :
                            (
                                <AdditionalInformation lastPrice={lastPrice}
                                    isin={fund.isin}
                                    currency={fund.currency}
                                    manager={fund.manager}
                                    ucits={fund.ucits}
                                    switchable={fund.switchable}
                                    currencyHedge={fund.currencyHedge}
                                />
                            )}
                        </L.CardBody>
                    </L.CardContainer>
                    <L.CardContainer>
                        <L.CardHeadingContainer>
                            <h3>Evaluación del fondo</h3>
                        </L.CardHeadingContainer>
                        <L.CardBodyFundEvaluation>
                            {loadingFund ? <Spinner size={3} style={{padding: "1em"}} /> : <FundEvaluation subcategory={fund.subcategory} averageScore={fund.averageScore} morningStarCategoryId={fund.morningStarCategoryId} />}
                        </L.CardBodyFundEvaluation>
                    </L.CardContainer>
                    <L.CardContainer>
                        <L.CardHeadingContainer>
                            <h3>Otros datos de interés</h3>
                        </L.CardHeadingContainer>
                        <L.CardBody>
                            {loadingFund ? (<Spinner size={3} style={{padding: "1em"}} />) :
                            (
                                <InterestData managementFee={fund.managementFee}
                                    minimumInitialInvestment={fund.minimumInitialInvestment}
                                    minimumAdditionalInvestment={fund.minimumAdditionalInvestment}
                                    income={fund.income}
                                    rebate={fund.rebate}
                                    noMin={fund.noMin}
                                    cleanShare={fund.cleanShare}
                                    liquidationDaysOnSells={fund.liquidationDaysOnSells}
                                    liquidationDaysOnBuys={fund.liquidationDaysOnBuys}
                                    ogc={fund.ongoingCharges}
                                    performanceFee={fund.performanceFee}
                                    complexity={fund.complexity}
                                />
                            )}
                        </L.CardBody>
                    </L.CardContainer>
                    {fund.documents !== undefined &&
                    (
                        <React.Fragment>
                            <L.CardContainer>
                                <L.CardHeadingContainer>
                                    <h3>Documentos</h3>
                                </L.CardHeadingContainer>
                                <L.CardBody>
                                    {loadingFund
                                        ? (<Spinner size={3} style={{ padding: "1em" }} />)
                                        : fund.documents.length > 0 && (
                                            <Documents
                                                documents={fund.documents}
                                                isin={fund.isin}
                                                userId={user.id}
                                            />
                                        )
                                    }
                                </L.CardBody>
                            </L.CardContainer>
                        </React.Fragment>
                    )}
                </L.RightWrapper>
            </L.ContentWrapper>
            <Separator />
            {loadingSimilarProducts && <Spinner size={3} style={{padding: "1em"}} />}
            {similarProducts.length > 0 && !loadingSimilarProducts &&
            (
                <L.SimilarProductsContainer id="similar-products">
                    <L.SPTitle>
                        <h2>Productos similares que podrían interesarte</h2>
                    </L.SPTitle>
                    <L.SPCardsContainer>
                        {similarProducts.map((similarProduct: any, index: number) =>
                        {
                            return(
                                <React.Fragment key={index}>
                                    <L.ProductCardContainer key={similarProduct.finametrixId}>
                                        <ProductCard product={similarProduct} publicVersion={publicVersion} />
                                    </L.ProductCardContainer>
                                </React.Fragment>
                            );
                        })}
                    </L.SPCardsContainer>
                </L.SimilarProductsContainer>
            )}
        </L.Main>
    );
};

export default FundDetailPage;
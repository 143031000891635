import styled from 'styled-components';

export const Main = styled.main`
    ${props => props?.theme?.itemLimit === 1 && `
        .ms-BasePicker-itemsWrapper,
        .ms-BasePicker-itemsWrapper .ms-TagItem,
        .ms-BasePicker-itemsWrapper .ms-TagItem .ms-TagItem-text
        {
            max-width: none;
            width: 100%;
        }
    `}
`;
export const SearchAutofillWrapper = styled.div`
    .ms-Callout
    {
        z-index: 1;
        width: 100%;
        .ms-Button
        {
            border-radius: 0;
        }
    }
    .ms-Callout .ms-Callout-main
    {
        padding: 0;
        border-radius: 2px;
        background: ${props => props?.theme?.palette?.white} !important;
        .suggestion-rendered-item
        {
            padding: 0.3rem 0.6rem;
            width: 100%;
            text-align: left;
        }
        .ms-Suggestions-item .is-suggested
        {
            background: ${props => props?.theme?.palette?.tertiary || "#F3F7F9"} !important;
            & *, &:hover *
            {
                outline: none;
            } 
        }
    }
`;
export const SectionContainer = styled.div`
    padding: 0;
    margin-top: 2em;
`;
export const WarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: #FFF1BE;
    color: #F69A2D;
    padding: 30px;
    gap: 10px;
    font-weight: 500;
    p
    {
        line-height: 20px;
        margin: 0;
    }
`;
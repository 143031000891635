import { DefaultButton } from "@fluentui/react";
import styled from "styled-components";

export const RowMarket = styled.div`
.shadow{
    width: 98%;
};
& .keyboard-arrow-left{
    position: relative;
    left: -24px;
    z-index: 3;
    color: black !important;
};
& .keyboard-arrow-right{
    position: relative;
    left: -24px;
    z-index: 3;
    color: black !important;
}
`;
export const RowHeaderMarket = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
`;
export const ColHeaderMarket = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

`;
export const ColMarket = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;
export const ColMarketDescription = styled.div`
`;
export const MarketTitle = styled.p`
    margin-bottom: 8px;
    cursor: default;
    font-family: 'Barlow', sans-serif;
    color: #556769;
    font-weight: 600;
    font-size: 18px;
`;
export const MarketSubTitle = styled.h3`
    margin-bottom: 8px;
    cursor: default;
    font-weight: 600;
    width: 35%;
    font-size: 18px !important;
`;
export const MarketDescription = styled.p`
	color: #95A0A1;
    font-family: 'Barlow', sans-serif;
    cursor: default;
    max-width: 73%;
`;
export const MarketExplicationTitle = styled.h3`
    margin-bottom: 0;
    cursor: default;
    font-weight: 600;
    font-style: uppercase;
    text-align: center;
`;
export const MarketExplicationSubTitle = styled.h3`
    margin-bottom: 0;
    font-weight: 600;
    font-style: uppercase;
    text-align: left;
`;
export const ColMarketAnalysis = styled.div`
    width: 100%;
    display: flex;
    margin: 24px 0px;
`;
export const MarketColLastAnalyisis = styled.div`
    display: flex;
    justify-content: flex-start;
    min-width: 50%;
`;
export const MarketColLastAnalyisisContainer = styled.div`
    width: 100%;
`;
export const MarketColSearch = styled.div`
    display: flex;
    justify-content: flex-end;
    min-width: 50%;
`;
export const MarketColSearchContainer = styled.div`
    width: 100%;
    justify-content: flex-end;
`;
export const MarketSearchCardsContainer = styled.div`
    display: block;
`;
export const MarketAnalyisisCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
`;
export const MarketCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    .analysis-management{
        justify-content: flex-end;
    }
`;
export const MarketManagementContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;
export const MarketCard = styled.div`
`;
export const ButtonAnalysis = styled(DefaultButton)`
    padding: 10px 20px;
    background: #CC214F;
    color: white;
    border: 1px solid #CC214F;
    border-radius: 20px;
`;
export const SearchButtonAnalysis = styled(DefaultButton)`
    padding: 10px 20px;
    background: #CC214F;
    color: white;
    border: 1px solid #CC214F;
    border-radius: 20px;
    position: relative;
    top: 6px;
    min-width: 120px;
`;
export const MarketDatePickerCont = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    width: 100%;
    min-height: 96px;
    max-height: 96px;
    height: 96px;
`;

export const MarkerDatePickerInner = styled.div`
    width: 100%;
    margin-right: 8px;
`;
export const ErrorMessage = styled.span`
    position: absolute;
    display: block;
    width: fit-content;
    top: 83%;
    left: 0;
    margin: 0;
    color: hsl(0, 100%, 63%);
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
`;
export const MarketColSearchContainerInner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-height: 280px;
`;
export const MarketColLastAnalyisisContainerInner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-height: 280px;
`;
export const AnalisysPortfolioContainer = styled.div`
    width: 74%;
    display: flex;
    flex-direction: row;
`;
export const AnalisysPortfolioTableContainer = styled.div`
    width: 100%;
`;
export const MarketSteps = styled.div`
    h3
    {
        font-size: 22px;
        color: #cc214f;
    }
    h3 span
    {
        color: #beced5;
        margin-left: 2px;
    }
`;

export const MarketArrow = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const MarketSpacer = styled.div`
    height: 96px;
`;

export const MarketSeparator = styled.div`
    width: 100%;
    height: 1px;
    margin: 8px 0;
`;

export const MarketLineSeparator = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    height: 1px;
    background-color: #95A0A1;
    opacity: 0.2;  
`;
export const MarketPdfViewer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .pdfSlick{
        width: 50%;
        height: 400px;
        .toolbar{display: none;}
    }
        
`;
import React from "react";
import DocumentTitle from "react-document-title";
import { ManagedPortfolioList } from "../../services/ManagedPortfolioService";
import * as S from './AlchemyTransferStyle';
import { Link, useHistory, useLocation } from "react-router-dom";
import { CaretHollowMirroredIcon } from "@fluentui/react-icons-mdl2";
import Auth from "../../Auth/Auth";
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { Checkbox, MessageBar, MessageBarType } from "@fluentui/react";
import { ai } from "../../services/TelemetryService";
import { SignatureComponent } from "../../components/Signature/SignatureComponent";
import service, { ISignature } from "../../services/SignaturesService";
import moment from "moment";
import ProcessingModalComponent from "../../components/Modals/ProcessingModalComponent";
import { useBoolean } from "@fluentui/react-hooks";
import OrderService, { OrderType } from "../../services/OrderService";

export const AlchemyTransferOrdersScreen = (props: any) =>
{
    const { state } = useLocation<any>();
    let user = Auth.getUserProfile();
    let isinOrigin: any = state?.props.isinOrigin;
    let isinDestination: ManagedPortfolioList[] = state?.props.targetFunds;
    let defaultSignature: ISignature = state?.props.signature;
    let phone: any = state?.props.signature.phone;
    let orders: any = state?.props.orders;
    let userIdentityId: any = state?.userIdentityId;
    const history = useHistory();
    const labelCheckbox = "Confirmo que he entendido que las fechas de valor liquidativo y liquidación son meramente estimaciones informativas y no siempre pueden reflejar fehacientemente el tiempo exacto de las órdenes en su trámite mientras dure la operación.";
    const [isChecked, setIsChecked] = React.useState<boolean>(false);
    const [hasError, setHasError] = React.useState<boolean>(false);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [showAlert, { toggle: toggleShowAlert }] = useBoolean(false);
    const [userConfirmation, setUserConfirmation] = React.useState({user: false, disclaimer: false});
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [signature, setSignature] = React.useState(defaultSignature);
    const validatePatchSignature = React.useRef<boolean>(false);
    const objToStringify =
    {
        title: "",
        username: user.fullname,
        userId: user.id,
        clickTime: "",
    };
    const appInsights = ai.reactPlugin;
    const trackClicks = useTrackEvent(appInsights, "Checkbox Traspaso Alchemy Portfolio", "");
    const performTracking = (jsonObject: string) =>
    {
        trackClicks(jsonObject);
    };
    const processHours: any = Auth.getProcessHours();
    const processExpirationDate = (signature: ISignature) =>
    {
        if(signature && typeof signature.expiresAt === "string")
        {
            signature.expiresAt = moment(signature.expiresAt).toDate();
        }
    }
    processExpirationDate(defaultSignature);
    
    if(processHours === undefined)
    {
        service.getProcessHours().then((response: any) =>
        {
            if(response.status === 200 && response.data.length > 0)
            {
                Auth.setProcessHours(response.data);
            }
        });
    }

    React.useEffect(() =>
    {
        setPhoneNumber("X".repeat(phone.length - 3) + phone.slice(phone.length - 3, phone.length));
    }, [phone]);

    const submit = async (signature: any) =>
    {
        showModal();

        for(let i=0; i<orders.length; i++)
        {
            var data =
            {
                id: orders[i],
                signatureId: state.props.signature.id
            };

            await OrderService.patchOrder(data).then((response: OrderType) =>
            {
                if(response.signatureId)
                {
                    validatePatchSignature.current = true;
                }
            })
            .catch((error) =>
            {
                hideModal();
                toggleShowAlert();
                setHasError(true);
                validatePatchSignature.current = false;
            });
        }

        if(validatePatchSignature.current === true)
        {
            history.push("/portfolio/orders");
        }
    };

    return(
        <React.Fragment>
            <DocumentTitle title={"IronIA - Visualización Previa de Órdenes"}>
                <React.Fragment>
                    {showAlert &&
                    (
                        <MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">
                            Se ha producido un error al procesar la operación.
                        </MessageBar>
                    )}
                    <S.BackButtonContainer>
                        <span>
                            <Link to='/AlchemyTransfer/TransferScreen'>
                                <CaretHollowMirroredIcon />
                                Volver atrás
                            </Link>
                        </span>
                    </S.BackButtonContainer>
                    <S.RowTitleOrders className="ms-Grid-row">
                        <S.ColTitleOrders1 className="ms-Grid-col">
                            <S.TitleContainer>
                                <h2>Previsualización de Órdenes</h2>
                            </S.TitleContainer>
                        </S.ColTitleOrders1>
                        <S.ColTitleOrders2 className="ms-Grid-col">
                            <S.SubTitleContainer>Traspaso de Fondo Origen - {isinOrigin}</S.SubTitleContainer>
                        </S.ColTitleOrders2>
                    </S.RowTitleOrders>
                    <S.SeparatorTop />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-xl8 ms-xxxl8">
                            <S.TableOrdersPreview>
                                <S.TableOrdersThead>
                                    <S.TableOrdersTr>
                                        <S.TableOrdersTh>
                                            Nombre del Fondo
                                        </S.TableOrdersTh>
                                        <S.TableOrdersTh>
                                            Isin del Fondo
                                        </S.TableOrdersTh>
                                        <S.TableOrdersTh>
                                            Peso del Fondo
                                        </S.TableOrdersTh>
                                    </S.TableOrdersTr>
                                </S.TableOrdersThead>
                                <S.TableOrdersTBody>
                                    {isinDestination !== undefined && isinDestination.length > 0 && isinDestination.map((element) =>
                                    {
                                        return(
                                            <React.Fragment key={element.instrumentId}>
                                                <S.TableOrdersTr>
                                                    <S.TableOrdersTd>
                                                        {element.name}
                                                    </S.TableOrdersTd>
                                                    <S.TableOrdersTd>
                                                        {element.isin}
                                                    </S.TableOrdersTd>
                                                    <S.TableOrdersTd>
                                                        {element.weight}%
                                                    </S.TableOrdersTd>
                                                </S.TableOrdersTr>
                                            </React.Fragment>
                                        )
                                    })}
                                </S.TableOrdersTBody>
                            </S.TableOrdersPreview>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-xxxl6">
                            <div>
                                {/* <L.CardButton size="lg" disabled={!simulationAvailable} type="button" style={{ marginRight: "1em" }} onClick={handleSimulation}>
                                    Simular
                                </L.CardButton>
                                {simulation === true && simulationAvailable === true && transferDates !== undefined && <TransferTimeLine transferEstimationDates={transferDates} />} */}
                            </div>
                        </div>
                    </div>
                    <S.SectionContainer>
                        <S.WarningContainer>
                            <p>
                                Las fechas de valor liquidativo y liquidación, así como los días fuera de mercado
                                son una estimación informativa que NO tiene en cuenta los días no laborables del país de origen
                                del fondo y no constituyen una garantía para ningún tipo de reclamación por dicha información
                                expuesta.
                                <br />
                                <br />
                                Asimismo, se recuerda al inversor que para poder calcular la estimación más exacta,
                                deberá analizar la memoria de comercialización, el folleto y el resto de documentaciones
                                pre-contractuales de los vehículos donde figuren las horas de corte y el periodo del cálculo
                                liquidativo establecidas por las entidades comercializadoras y las gestoras de los mismos.
                            </p>
                        </S.WarningContainer>
                    </S.SectionContainer>
                    <S.RowCheckbox className="ms-Grid-row">
                        <Checkbox label={labelCheckbox} checked={isChecked} styles={{root: {paddingLeft: "0.5em"}}}
                            disabled={isChecked === true}
                            onChange={(ev?: any, checked?: boolean) =>
                            {
                                setIsChecked(!!checked as boolean);
                                objToStringify.clickTime = new Date().toLocaleDateString() + new Date().toLocaleTimeString();
                                objToStringify.title = "Check de confirmación del usuario en traspaso de fondo origen en Plan Gestionado";
                                performTracking(JSON.stringify(objToStringify));
                            }}
                        />
                    </S.RowCheckbox>
                    {isChecked === true && !hasError &&
                    (
                        <S.RowSignature className="ms-Grid-row">
                            <SignatureComponent userIdentities={[userIdentityId]}
                                signatures={[signature]}
                                alreadySignedText="¡Tus órdenes de traspaso están en proceso!"
                                title="Firma del traspaso"
                                submitButtonText="Firmar traspaso"
                                cancelButtonText="Cancelar"
                                headerTextArea={`Recibirás un SMS en el teléfono ${phoneNumber} con la clave para confirmar esta orden.`}
                                backButtonText="Volver"
                                onBackButtonClicked={() => history.push("/ManagementTransfer/TransferScreen")}
                                onCancelButtonClicked={() => history.push("/ManagementTransfer/TransferScreen")}
                                requestNewCodeButtonText="Recibir nuevo código"
                                isMultiDestiny={true}
                                confirmElement={
                                {
                                    text: "Confirmo haber recibido, leído y comprendido la documentación obligatoria de IIC's y realizar esta operación por iniciativa propia",
                                    value: userConfirmation.user,
                                    setValue: (value: boolean) => setUserConfirmation({ user: !!value, disclaimer: userConfirmation.disclaimer })
                                }}
                                onNewCodeSended={(signatures: any) =>
                                {
                                    signatures.forEach((signature: any) =>
                                    {
                                        processExpirationDate(signature);
                                    })
                                    setSignature(signatures[0]);
                                }}
                                onSignatureChanged={(signature: any) =>
                                {
                                    processExpirationDate(signature);
                                    setSignature(signature);
                                }}
                                onSigned={(signature: any) =>
                                {
                                    processExpirationDate(signature);
                                    setSignature(signature);
                                    submit(signature);
                                }}
                            />
                            <ProcessingModalComponent isModalOpen={isModalOpen} hideModal={hideModal} />
                        </S.RowSignature>
                    )}
                    {isChecked === true && hasError &&
                    (
                        <React.Fragment>
                            <S.ErrorH3>
                                Se ha producido un error en el proceso de las órdenes. Póngase en contacto con info@ironia.tech para más obtener más detalles del error
                                aportando la siguiente información:
                            </S.ErrorH3>
                            <br />
                            <S.ErrorH3>
                                - Isin de origen: {isinOrigin}, Id de la orden fallida: {defaultSignature.id}
                            </S.ErrorH3>
                        </React.Fragment>
                    )}
                </React.Fragment>
            </DocumentTitle>
        </React.Fragment>
    );
}
import { DefaultButton, SearchBox, Spinner, SpinnerSize } from '@fluentui/react';
import { useEffect, useState } from 'react';
import * as L from '../../components/Instruments/layout.styles';
import { useLocation } from "react-router-dom";
import TrainingCoursesService, { CourseData } from '../../services/TrainingCoursesService';
import BaindexCard from '../../components/TrainingCourses/Baindex/Card';
import MiaxCard from '../../components/TrainingCourses/Miax/Card';

interface CustomizedState {
	searchTerm: string
  }

const TrainingCourses = () => {
	const location = useLocation();
	const state = location?.state as CustomizedState;

	const [error, setError] = useState({ message: false });
	const [isLoaded, setIsLoaded] = useState(false);
	const [isfirstLoad, setIsfirstLoad] = useState(true);
	const [searchText, setSearchText] = useState(state?.searchTerm ?? "");
	const [page, setPage] = useState(1);
	const [recordsTotal, setRecordsTotal] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [braindexJwt, setBraindexJwt] = useState<string>("");
	const [braindexCourses, setBraindexCourses] = useState<CourseData[]>([]);
	const [filteredBraindexCourses, setFilteredBraindexCourses] = useState<CourseData[]>([]);
	const [paginatedBraindexCourses, setPaginatedBraindexCourses] = useState<CourseData[]>([]);

	const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			filterCourses();
		}
	};

	useEffect(() => {
		if (isfirstLoad) {
			setIsfirstLoad(false);
			TrainingCoursesService.loginBraindex().then(
				response => {
					setBraindexJwt(response.jwt);
					TrainingCoursesService.getBraindexCourses(response.jwt).then(
						coursesResponse => {
							setBraindexCourses(coursesResponse.courses);						
							setFilteredBraindexCourses(coursesResponse.courses);
							setPaginatedBraindexCourses(coursesResponse.courses.slice((page-1) * itemsPerPage, page * itemsPerPage));
							setRecordsTotal(coursesResponse.courses.length);

							if (coursesResponse.isRefreshed) {
								setBraindexJwt(coursesResponse.newTokenValue);
							}
							setIsLoaded(true);
						},
						error => {
							setError(error);
						}
					);
				},
				error => {
					setError(error);
				}
			);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isfirstLoad]);

	const filterCourses = () => {
		var filterTerm = searchText.trim().toLowerCase();
		var filteredResult = braindexCourses.filter(
			x =>
				x.name.toLowerCase().includes(filterTerm) ||
				x.short_description.toLowerCase().includes(filterTerm) ||
				x.categories[0].name.toLowerCase().includes(filterTerm)
		);
		setFilteredBraindexCourses(filteredResult);
		setRecordsTotal(filteredResult.length);
	};

	useEffect(() => {
		if (!isfirstLoad) {
			setPaginatedBraindexCourses(filteredBraindexCourses.slice((page - 1) * itemsPerPage, page * itemsPerPage));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, itemsPerPage, filteredBraindexCourses]);

	return (
		<L.TableWrapper>
			<div className="ms-Grid-row">
				<div className="ms-Grid-col ms-sm12">
					<L.Title>Formación</L.Title>
				</div>
			</div>
			<L.TableWidthManager>
				<L.TableFilterContainer>
					<L.TRCountContainer>
						<L.TRTitle>
							Resultados de la búsqueda
							<L.TRCount>{`(${recordsTotal})`}</L.TRCount>
						</L.TRTitle>
					</L.TRCountContainer>
					<L.InputSearchContainer className="jcl">
						<SearchBox
							onKeyPress={(e: any) => handleKey(e)}
							placeholder="Buscar..."
							value={searchText}
							onChange={(event: any) => {
								if (event?.target.value) {
									let text = event.target.value;
									setSearchText(text);
								} else {
									setSearchText("");
								}
							}}
						/>
					</L.InputSearchContainer>
					<L.EditColumnContainer style={{ justifyContent: "normal" }}>
						<DefaultButton text="Buscar" onClick={() => filterCourses()} />
					</L.EditColumnContainer>
				</L.TableFilterContainer>
				<div className="ms-Grid-row">
					{error.message && <div>Error: {error.message}</div>}
					{!error.message && (
						<>
							{!isLoaded && <Spinner size={SpinnerSize.large} />}
							{isLoaded && (
								<>
									<MiaxCard />
									{paginatedBraindexCourses.map((item: CourseData, index: number) => (
										<BaindexCard
											key={"course" + index}
											name={item.name}
											slug={item.slug}
											permalink={item.permalink}
											featured={item.featured}
											short_description={item.short_description}
											price={item.price}
											regular_price={item.regular_price}
											sale_price={item.sale_price}
											price_html={item.price_html}
											on_sale={item.on_sale}
											average_rating={item.average_rating}
											categories={item.categories}
											images={item.images}
										/>
									))}
								</>
							)}
						</>
					)}
				</div>
				<L.PaginationContainer>
					<L.ProductsPerPage>
						<span>Resultados por página</span>
						<select
							onChange={event => {
								setItemsPerPage(Number(event.target.value));
								setPage(1);
							}}
							defaultValue={itemsPerPage}
						>
							<option value={10}>10</option>
							<option value={16}>16</option>
							<option value={22}>22</option>
							<option value={28}>28</option>
						</select>
					</L.ProductsPerPage>
					<L.PageCount>
						<span>{`${page} de ${Math.ceil(recordsTotal / itemsPerPage)}`}</span>
					</L.PageCount>
					<L.PageButtons>
						<button
							onClick={() => {
								if (page > 1) {
									setPage(page - 1);
								}
							}}
							className={page === 1 ? "disabled" : ""}
						>
							<i className={"ironia-icon keyboard-arrow-left"} />
						</button>
						<button
							onClick={() => {
								if (page < Math.ceil(recordsTotal / itemsPerPage!)) {
									setPage(page + 1);
								}
							}}
							className={Math.ceil(recordsTotal / itemsPerPage!) === page ? "disabled" : ""}
						>
							<i className={"ironia-icon keyboard-arrow-right"} />
						</button>
					</L.PageButtons>
				</L.PaginationContainer>
			</L.TableWidthManager>
		</L.TableWrapper>
	);
};

export default TrainingCourses;
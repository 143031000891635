import { Checkbox, IconButton, IIconProps, Modal, Separator, Spinner, SpinnerSize } from "@fluentui/react";
import { ManagedPortfolioType } from "../../services/ManagedPortfolioService";
import { currencyFormatter } from "../../utils/numberFormatter";
import { Image } from 'office-ui-fabric-react/lib/Image';
import { RiskType } from "../../services/FinametrixService";
import * as L from './layout.styles';
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { ai } from '../../services/TelemetryService';
import { translate } from "../../utils/i18n";

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const appInsights = ai.reactPlugin;

const ModelPortfolioModalSearch = ({isModalOpen, hideModal, managedPortfolios, onCoverManagedPortfolios, risks, _onChange, theme, intl}:
{
    isModalOpen: boolean,
    hideModal: () => void,
    managedPortfolios: ManagedPortfolioType[],
    onCoverManagedPortfolios: number[],
    risks: RiskType[],
    _onChange: any,
    theme: any,
    intl: any
}) =>
{
    const trackClicks = useTrackEvent(appInsights, "Managed Portfolio Link", "");

    return (
        <Modal isOpen={isModalOpen} onDismiss={hideModal} className="full-width model-portfolio-list">
            <L.StyledModal theme={theme}>
                <IconButton iconProps={cancelIcon}
                    ariaLabel="Cerrar"
                    onClick={hideModal}
                    className="close-button"
                />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <h3>Planes Plan Gestionado</h3>
                    </div>
                </div>
                {managedPortfolios === undefined && (
                    <Spinner size={SpinnerSize.large} />
                )}
                {managedPortfolios !== undefined && (
                    <div className="ms-Grid-row">
                        {managedPortfolios.map((managedPortfolio: ManagedPortfolioType, managedPortfolioIndex: number) => (
                            <div className="ms-Grid-col ms-sm12 ms-md4" key={"managedPortfolioIndex" + managedPortfolioIndex}>
                                <div className="shadow">
                                    <div className="header">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm4">
                                                {managedPortfolio.manager.logo && (
                                                    <Image src={`/images/logos/${managedPortfolio.manager.logo}`}
                                                        alt={translate(managedPortfolio.manager.name || '')}
                                                        style={{background: `#${managedPortfolio.manager.color}`}}
                                                    />
                                                )}
                                            </div>
                                            <div className="ms-Grid-col ms-sm8">
                                                {managedPortfolio.url !== null && (
                                                    <a className="name"
                                                        onClick={() => trackClicks(managedPortfolio.name)}
                                                        href={managedPortfolio.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {managedPortfolio.name}
                                                    </a>
                                                )}
                                                {managedPortfolio.url === null && (
                                                    <span className="name">
                                                        {managedPortfolio.name}
                                                    </span>
                                                )}
                                                <span className="manager-name">{translate(managedPortfolio.manager.name ?? '')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <Separator className="separator" />
                                    <div className="content">
                                        <div className="ms-Grid-row">
                                            <span className="risk">
                                                Nivel de riesgo: {risks.find(r => r._id === managedPortfolio.riskProfile)?.name}
                                            </span>
                                        </div>
                                        <div className="ms-Grid-row">
                                            Precio contratación de servicios:
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6">
                                                <span className="price">
                                                    {managedPortfolio.subscription.price !== undefined
                                                        ? currencyFormatter.format(managedPortfolio.subscription.price)
                                                        : ''
                                                    }/{intl.messages[managedPortfolio.subscription?.period || '']}
                                                </span>
                                            </div>
                                            <div className="ms-Grid-col ms-sm6">
                                                <Checkbox className="cicled"
                                                    label="Seleccionar"
                                                    onChange={(event: any, checked: boolean | undefined) => _onChange(event, checked, managedPortfolio.id)}
                                                    checked={onCoverManagedPortfolios.includes(managedPortfolio.id)}
                                                    checkmarkIconProps={{ iconName: "CircleFill" }}
                                                    disabled={onCoverManagedPortfolios.length === 1 && onCoverManagedPortfolios.includes(managedPortfolio.id)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </L.StyledModal>
        </Modal>
    );
};

export default ModelPortfolioModalSearch;
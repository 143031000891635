import { useState, useContext, useEffect } from "react";
import { Wrapper, TableContainer, Table, Header, CounterContainer, ActionsContainer } from "./styles";
import { ThemeContext } from "@fluentui/react-theme-provider";

const GenericTableFiscalReportDead = (
{
    counterText = "",
    data = [{}],
    columns = [
        {
            id: "",
            title: "",
            source: "",
            customCell: ({}) => (<></>)
        },
    ],
    resumeData = [],
}) =>
{
    const theme = useContext(ThemeContext);
    const [rows, setRows] = useState([]);

    useEffect(() =>
    {
        if(data)
        {
            setRows(data);
        }
    }, [data]);

    return(
        <Wrapper>
            <Header>
                <CounterContainer theme={theme}>
                    <h3>{counterText || "Resultados de la búsqueda"} </h3>
                    <span> ({data.length})</span>
                </CounterContainer>
                <ActionsContainer theme={theme}>
                </ActionsContainer>
            </Header>
            <TableContainer>
                <Table hasResume={resumeData ? 1 : 0} theme={theme}>
                    <thead>
                        <tr>
                            {columns.map((column, index) =>
                            {
                                return <th key={'th' + index}>{column.title}</th>;
                            })}
                        </tr>
                        <tr>
                            {resumeData &&
                                resumeData.map((cell, index) =>
                                {
                                    return <th key={'cell' + index}>{cell}</th>;
                                })}
                        </tr>
                    </thead>
                    {rows.map((row, rowindex) =>
                    {
                        return(
                            <tbody key={'row'+rowindex}>
                                <tr
                                    onClick={() =>
                                    {
                                        row.subData.expanded = !row.subData.expanded;
                                        setRows([...rows]);
                                    }}
                                >
                                    {columns.map((col, colindex) =>
                                    {
                                        const cellValue = row[col.source]
                                        if(col.customCell)
                                        {
                                            return(
                                                <td key={'celldata' + colindex}>
                                                    {col.customCell({value: cellValue, row, col})}
                                                </td>
                                            );
                                        }
                                        else
                                        {
                                            return <td key={'celldata' + colindex}>{cellValue}</td>;
                                        }
                                    })}
                                </tr>
                                {row.subData && row.subData.expanded &&
                                (
                                    row.subData.data.map((childRow, childRowIndex) =>
                                    {
                                        return(
                                            <tr style={{ background: theme.palette.tertiary }} key={'childRow' + childRowIndex}>
                                                {row.subData.columns.map((childCol, childColIndex) =>
                                                {
                                                    const cellValue = childRow[childCol.source];

                                                    if(childCol.customCell)
                                                    {
                                                        return(
                                                            <td key={'childColIndex' + childColIndex}>
                                                                {childCol.customCell(
                                                                {
                                                                    value: cellValue,
                                                                    childRow,
                                                                    childCol,
                                                                })}
                                                            </td>
                                                        );
                                                    }
                                                    else
                                                    {
                                                        return <td key={'childColIndex' + childColIndex}>{cellValue}</td>;
                                                    }
                                                })}
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        );
                    })}
                </Table>
            </TableContainer>
        </Wrapper>
    );
};

export default GenericTableFiscalReportDead;
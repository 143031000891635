import React from "react";
import { SignatureComponent } from "../../Signature/SignatureComponent";
import { ISignature } from "../../../services/SignaturesService";
import moment from "moment";
import OrderService from "../../../services/OrderService";
import Auth from "../../../Auth/Auth";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { ai } from "../../../services/TelemetryService";

type Props =
{
    defaultSignatures: ISignature[];
    userIdentities: any[];
    onCancelSignature?: (cancel: boolean) => any;
    isins: any[];
    onSignedSuccess?: () => any;
};

const BalancerSignature = (props: Props) =>
{
    const user = Auth.getUserProfile();
    const appInsights = ai.reactPlugin;
    const { defaultSignatures, userIdentities, onCancelSignature, isins, onSignedSuccess } = props;
    const [signatures, setSignatures] = React.useState(defaultSignatures);
    const [phoneNumbers, setPhoneNumbers] = React.useState("");
    const [userConfirmation, setUserConfirmation] = React.useState({ user: false, disclaimer: false });
    const processHours: any = Auth.getProcessHours();
    const [loadingFnm, setLoadingFnm] = React.useState<boolean>(false);
    const [alreadySignedText, setAlreadySignedText] = React.useState("Sincronizando, por favor, espere un momento...");
    const processExpirationDate = (signatures: ISignature[]) =>
    {
        if(signatures !== undefined && signatures.length > 0)
        {
            signatures.forEach((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = moment(signature.expiresAt).toDate();
                }
            });
        }
    };

    processExpirationDate(defaultSignatures);

    React.useEffect(() =>
    {
        setPhoneNumbers(
            userIdentities
                .filter(uI =>
                {
                    var currentDate = moment();
                    var birthdayDate = moment(uI.birthday);
                    return currentDate.diff(birthdayDate, "years") > 17;
                })
                .map(
                    (userIdentity: any) =>
                        "X".repeat(userIdentity.phone.length - 3) + userIdentity.phone.slice(userIdentity.phone.length - 3, userIdentity.phone.length)
                )
                .join(", ")
        );
    }, [userIdentities]);

    const handleSubmit = async () =>
    {
        OrderService.patchInternalTransfersOrders(signatures[0]?.id!).finally(() =>
        {
            appInsights.trackEvent({
                name: "Rebalanceo Traspasos internos generados",
                properties: { userId: user.id, signatureId: signatures[0]?.id! }
            });
            setAlreadySignedText("Las órdenes se han generado con éxito.");
            setLoadingFnm(false);
            if(onSignedSuccess){
                onSignedSuccess();
            }
        });
    };

    return(
        <React.Fragment>
            {loadingFnm && <Spinner size={SpinnerSize.large} style={{ width: "100%", padding: "15px" }} />}
            {signatures !== undefined && userIdentities !== undefined && signatures.length > 0 && userIdentities.length > 0 && (
                <SignatureComponent
                    signatures={signatures}
                    userIdentities={userIdentities}
                    title="Confirmación de las operaciones"
                    submitButtonText="Confirmar"
                    cancelButtonText="Cancelar"
                    alreadySignedText={alreadySignedText}
                    headerTextArea={`Te enviaremos un código SMS al números al número ${phoneNumbers} para confirmar los traspasos internos.`}
                    requestNewCodeButtonText="Recibir nuevo código"
                    confirmElement={
                    {
                        text: "Confirmo haber recibido, leído y comprendido la documentación obligatoria de IIC's y realizar esta operación por iniciativa propia",
                        value: userConfirmation.user,
                        setValue: (value: boolean) => setUserConfirmation({user: !!value, disclaimer: userConfirmation.disclaimer})
                    }}
                    disclaimerEnabled={
                    {
                        text: "He sido informado de que la operación realizada en este momento puede no tomar el valor liquidativo del día en curso.",
                        processHours: processHours,
                        isins: isins,
                        value: userConfirmation.disclaimer,
                        isTransfer: true,
                        isMultiIsin: true,
                        setValue: (value: boolean) => setUserConfirmation({user: userConfirmation.user, disclaimer: !!value})
                    }}
                    onNewCodeSended={(signatures: any) =>
                    {
                        processExpirationDate(signatures);
                        setSignatures(signatures);
                    }}
                    onSignatureChanged={(signatures: any) =>
                    {
                        processExpirationDate(signatures);
                        setSignatures(signatures);
                    }}
                    onSigned={() =>
                    {
                        setLoadingFnm(true);
                        handleSubmit();
                    }}
                    onCancelButtonClicked={() =>
                    {
                        if (onCancelSignature)
                        {
                            onCancelSignature(true);
                        }
                    }}
                    backButtonText="Cancelar"
                    onBackButtonClicked={() =>
                    {
                        if (onCancelSignature)
                        {
                            onCancelSignature(true);
                        }
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default BalancerSignature;
import React from "react";
import { PromotionedVideos } from "../../services/IroniaIntegrationsService";
import CardElementVideo from "./CardElementVideo";
import { ColCard, LoadingText } from "./IroniaExplicaStyle";
import { translate } from "../../utils/i18n";

interface IIroniaGrid
{
    page: number,
    elements: number,
    data: PromotionedVideos[]
}

const IroniaExplicaGrid = (props: IIroniaGrid) =>
{
    return(
        <React.Fragment>
            {props.data.length === 0 &&
            (
                <React.Fragment>
                    <LoadingText>
                        No se han encontrado resultados
                    </LoadingText>
                </React.Fragment>
            )}
            {props.data.length > 0 && props.data.map((element) => 
            {
                return(
                    <React.Fragment key={element.id}>
                        <ColCard className="ms-Grid-col ms-sm6 ms-md6 ms-lg4 ms-xl3 ms-xxl2 ms-xxxl2">
                            <CardElementVideo
                                key={element.id}
                                date={element.videoDate}
                                isin={element.isin}
                                url={element.url}
                                name={element.name}
                                managerName={translate(element.managerName)}
                                familyName={element.familyName}
                                score={element.score}
                                managerId={element.managerId}
                                category={element.category}
                                subcategory={element.subcategory}
                                cleanShare={element.cleanShare}
                            />
                        </ColCard>
                    </React.Fragment>
                )
            })}
        </React.Fragment>
    );
}

export default IroniaExplicaGrid;
import axios from "axios";
import { proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);

interface IQLTBonification
{
    result: boolean,
    savingPlans?: string[]
}

export type QLTBonification = IQLTBonification;

class PromotionsService
{
    public asignPromotions = async (model: any): Promise<boolean> =>
    {
        let url: string = `${proxyUrl}/asignPromotions`;
        const req = await axios.post(url, model);
        return req.data;
    }
    public getBonification = async (contract: string): Promise<QLTBonification> =>
    {
        let url: string = `${proxyUrl}/Bonification/${contract}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
}

const PromotionService = new PromotionsService();
export default PromotionService;
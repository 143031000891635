import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Toggle } from '@fluentui/react';

export const Main = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    h3
    {
        font-size: 24px !important;
        margin: 0;
        font-weight: 600;
    }
`;
export const ContentWrapper = styled.div`
    display: flex;
    margin-top: 1em;
`;
export const LeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 60%;
    padding: 0 10px;
    padding-right: 3em;
    max-width: 60%;
    box-sizing: border-box;
`;
export const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 40%;
    box-sizing: border-box;
`;
export const BackButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: black;
    span
    {
        margin-right: 10px;
        cursor: pointer;
        button
        {
            border: 0;
            color: #93aeb9;
            cursor: pointer;
        }
    }
`;
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    h2
    {
        margin: 0;
    }
`;
export const FundBrothersContainer = styled.div`
    margin-top: 22px;
    table
    {
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
    }
    thead
    {
        background: ${(props) => props.theme.palette.highlightedAreaMain || 'rgba(66, 132, 230, 0.2)'};
    }
    th, td
    {
        padding: 17px 13px;
        height: 14px;
        text-align: left;
    }
    td
    {
        border-top: 1px solid rgba(147, 174, 185, 0.2);
    }
    tr:first-child td
    {
        border-top: none;
    }
    th:first-child
    {
        border-radius: 4px 0 0 4px;
    }
    th:last-child
    {
        border-radius: 0 4px 4px 0;
    }
    h3
    {
        margin-bottom: 0 !important;
        padding-left: 8px;
    }
    h3, th
    {
        color: ${(props) => props?.theme?.palette?.highlightedAreaMainText || '#4369A1'};
    }
`;
export const FundBrother = styled(Link)`
    padding: 10px;
    cursor: pointer;
`;
export const GraphContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 690px;
    box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px;
    border-radius: 16px;
    margin-bottom: 20px;
    padding: 32px 32px 40px;
    h3:first-child
    {
        margin-bottom: 20px;
    }
    p.description
    {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin: 10px 0;
    }
    .menu-footer
    {
        button
        {
        min-width: unset;
        width: 50%;
        }
    }
`;
export const RowToggleTitle = styled.div`
    cursor: default;
    margin-bottom: 2.5em !important;
    display: flex;
    flex-wrap: wrap;
`;
export const ColTitle = styled.div``;
export const ColFirstToggleElement = styled.div`
    @media (min-width: 320px) and (max-width: 1023.9999px)
    {
        padding-top: 6%;
    }
`;
export const ColToggleElement = styled.div`
    @media (min-width: 320px) and (max-width: 1023.9999px)
    {
        padding-top: 6%;
    }
`;
export const ColSecondToggleElement = styled.div`
    flex: 1 1 0;
    box-sizing: border-box;

    @media (min-width: 320px) and (max-width: 1023.9999px)
    {
        padding-top: 6%;
    }
`;
export const ToggleLabel = styled.p`
    margin: 0;
    font-size: 15px;
    font-weight: 500;
`;
export const MainTitle = styled.h3`
    margin: 0 !important;

    @media (min-width: 1465px) and (max-width: 1509.99px)
    {
        width: 52%;
    }
    @media (min-width: 1510px)
    {
        width: 50%;
    }
`;
export const ScatterToggle = styled(Toggle)`
    display: inline-block;
    margin-bottom: 0 !important;
    cursor: pointer;
`;

export const TipParagraph = styled.p`
    cursor: default;
`;
export const YearsContainer = styled.div`
    display: flex;
    flex: 0 1 30px;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: 10px;
    font-size: 18px;
    gap: 10px;
    cursor: pointer;
    button
    {
        border: 0;
        background: rgb(243, 247, 249);
        font-size 12px;
        font-weight: 400;
        font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
        color: rgb(42, 65, 67);
        width: 39px;
        height: 22px;
        text-align: center;
        display: inline-block;
        margin: 0 -4px 0 0;
        cursor: pointer;

        &.active
        {
            color: white;
            background: rgb(204, 33, 79);
            font-weight: bold;
        }
    }
`;
export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px;
    border-radius: 16px;
    margin-bottom: 20px;
    padding: 10px 30px;
    a
    {
        font-weight: 600;
    }
`;
export const CardHeader = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 80px;
    justify-content: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
`;
export const CardHeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    a
    {
        display: flex;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
        line-height: 28px;
    }
`;
export const Advise = styled.div`
    h3
    {
        margin-top: -35px;
        margin-left: 50px;
    }
`;
// CH = Card Header
export const CHButtonsContainer = styled.div`
    display: flex;
    flex: 1 1 100%;
    margin: 20px 0;
    > button
    {
        width: 100%;
        margin: 0 5px;
    }
`;
export const RedBox = styled.div`
    display: flex;
    flex-direction: column;
    background: #f1bac9;
    border-radius: 16px;
    margin-top: 10px;
    margin-bottom: 15px;
    color: #cc214f;
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding: 15px;
    strong
    {
        margin-top: 15px;
        font-weight: 600;
    }
    button
    {
        width: 200px;
        color: white;
        height: 48px;
        border-radius: 100px;
    }
`;
export const CardBody = styled.div`
    display: flex;
    flex: 1;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    table
    {
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;
        th
        {
            font-weight: 800;
            border-right: 1px solid rgb(147, 185, 177, 0.2);
            border-bottom: 1px solid rgb(147, 185, 177, 0.2);
            padding: 20px;
            text-align: left;
            :last-child
            {
                border-right: none;
            }
            :first-child
            {
                padding: 0;
            }
            :nth-child(1)
            {
                width: 20%;
            }
            :nth-child(2)
            {
                width: 35%;
            }
            :nth-child(3)
            {
                width: 35%;
            }
            :nth-child(4)
            {
                width: 10%;
            }
        }
        td
        {
            color: #95a0a1;
            font-weight: 500;
            border-right: 1px solid rgb(147, 185, 177, 0.2);
            padding: 20px 15px;
            :last-child
            {
                border-right: none;
            }
            :first-child
            {
                padding: 0;
            }
            strong
            {
                color: #556769;
                font-weight: 500;
            }
            > svg
            {
                display: inline-block;
            }
        }
        tr
        {
            border-bottom: 1px solid rgb(147, 185, 177, 0.2);
            :last-child
            {
                border-bottom: none;
            }
        }
    }
`;
export const CardBodyFundEvaluation = styled.div`
    display: flex;
    flex: 1;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    table
    {
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;
        th
        {
            font-weight: 800;
            border-right: 1px solid rgb(147, 185, 177, 0.2);
            border-bottom: 1px solid rgb(147, 185, 177, 0.2);
            padding: 20px;
            text-align: left;
            :last-child
            {
                border-right: none;
            }
            :first-child
            {
                padding: 0;
            }
            :nth-child(1)
            {
                width: 50%;
            }
            :nth-child(2)
            {
                width: 20%;
            }
            :nth-child(3)
            {
                width: 30%;
            }
        }
        td
        {
            color: #95a0a1;
            font-weight: 500;
            border-right: 1px solid rgb(147, 185, 177, 0.2);
            padding: 20px 15px;
            :last-child
            {
                border-right: none;
            }
            :first-child
            {
                padding: 0;
            }
            strong
            {
                color: #556769;
                font-weight: 500;
            }
            > svg
            {
                display: inline-block;
            }
        }
        tr
        {
            border-bottom: 1px solid rgb(147, 185, 177, 0.2);
            :last-child
            {
                border-bottom: none;
            }
        }
    }
`;
export const SimilarProductsContainer = styled.div``;
// Similar Products Title
export const SPTitle = styled.div`
    display: flex;
    flex: 1;
    margin-bottom: 20px;
    h2
    {
        font-weight: 600;
    }
`;
export const SPCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    > *
    {
        max-width: calc(25% - 1em);
    }
`;
export const ProductCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 24%;
    min-width: 320px;
    box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px;
    border-radius: 16px;
`;
export const FilterContainer = styled.div`
    width 100%;
    > div
    {
        button
        {
            min-width: 80%;
            > div
            {
                color: #95A0A1 !important;
                position: relative;
                span
                {
                    position: absolute;
                    right: -3px;
                }
                .ironia-icon
                {
                    position: absolute;
                    right: -25px;
                    top: -9px;
                    background: #CC214F;
                    width: 32px;
                    height: 32px;
                    border-radius: 100%;
                    &:after
                    {
                        font-size: 17px;
                        line-height: 32px;
                    }
                }
            }
        }
        span
        {
            border-radius: 100px;
        }
    }
    i
    {
        color: white;
    }
`;
export const ScatterFilters = styled.div`
    width: 100%;
    display: flex;
`;
export const BenchmarkFilter = styled.div`
    flex: 0 0 50%;
`;
export const FundFilter = styled.div`
    flex: 0 0 50%;
`;
export const ScatterTitle = styled.h3`
    cursor: default;
    padding-top: 0.5em;
    margin-bottom: 0 !important;
`;
export const ScatterDescription = styled.p`
    cursor: default;
`;
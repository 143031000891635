import React, { useEffect, useState } from "react";
import * as S from '../../components/SuitabilityTests/SuitabilityTest.style';
import { DefaultButton, Spinner, SpinnerSize, TeachingBubble } from "@fluentui/react";
import DocumentTitle from "react-document-title";
import Moment from 'moment';
import { useHistory, useParams } from "react-router";
import UserIdentitiesService, { UserIdentityType } from '../../services/UserIdentitiesService';
import SavingsPlanService, { SavingsPlanType } from '../../services/SavingsPlanService';
import Auth from "../../Auth/Auth";
import FinametrixService, { RiskType } from "../../services/FinametrixService";
import SuitabilityTestsService, { ISuitabilityTestVersion } from "../../services/SuitabilityTestsService";
import { InfoIcon } from "@fluentui/react-icons-mdl2";

const SuitabilityTestList = () => {
    const { savingsPlanId } = useParams<any>();
    const [userIdentities, setUserIdentities] = useState<UserIdentityType[]>([]);
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>();
    const [savingsPlanRiskProfile, setSavingsPlanRiskProfile] = useState<RiskType>();
    const [loadedUserIdentities, serLoadedUserIdentities] = useState(false);
    const [suitabilityTestVersion, setSuitabilityTestVersion] = useState<ISuitabilityTestVersion>();
    const [risks, setRisks] = useState<RiskType[]>();
    const [error, setError] = useState({ message: false });
    const [isSending, setIsSending] = useState(false);
    const user = Auth.getUserProfile();
    const history = useHistory();
    const [tooltipInfoSuitabilityTest, setTooltipInfoSuitabilityTest] = useState(false);

    useEffect(() => {
        UserIdentitiesService.getIdentitiesByUserId(user.id).then(
            (userIdentities: UserIdentityType[]) => {
                setUserIdentities(userIdentities);
                serLoadedUserIdentities(true);
            },
            (error) => {
                setError(error);
            }
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        SavingsPlanService.getSavingsPlan(savingsPlanId).then((savingsPlan: SavingsPlanType) => {
            setSavingsPlan(savingsPlan);
        },
            (error) => {
                setError(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        FinametrixService.getRisks()
            .then((risksAux: RiskType[]) => {
                setRisks(risksAux);
            },
                (error) => {
                    setError(error);
                });
    }, []);

    useEffect(() => {
        SuitabilityTestsService.getLastSuitabilityTestVersion().then((suitabilityTestVersion: ISuitabilityTestVersion) => {
            setSuitabilityTestVersion(suitabilityTestVersion);
        },
            (error) => {
                setError(error);
            });
    }, []);

    useEffect(() => {
        if (savingsPlan !== undefined && risks !== undefined) {
            switch (savingsPlan.type) {
                case 1:
                case 0:
                    setSavingsPlanRiskProfile(risks.find((risk: RiskType) => risk._id === savingsPlan?.managedPortfolio?.riskProfile));
                    break;
            }
        }
    }, [savingsPlan, risks]);

    function editUserIdentity(userIdentityId: number) {
        history.push('/suitabilityTest/' + savingsPlanId + '/' + userIdentityId);
    }

    function redirectToMap() {
        history.push('/configurationMap',
            {
                savingPlanId: savingsPlan?.id,
                savingPlanName: savingsPlan?.name
            })
    }

    function onSubmit() {
        SavingsPlanService.patchSavingsPlan(savingsPlanId, { configurationMapStep: '2.3' }).then(() => {
            redirectToMap();
        },
            (error: any) => {
                setError(error)
            });
    }

    return (
        <DocumentTitle title='IronIA - 2.2 Test de idoneidad'>
            <div className="ms-Grid-row savings-plans savings-plan-select-identities">
                <div className="mt20">
                    <h1>Test de idoneidad de la inversión</h1>
                </div>
                {userIdentities.length === 0 && (
                    <Spinner size={SpinnerSize.large} />
                )}
                {userIdentities.length > 0 && savingsPlan?.type !== undefined &&
                    (
                        <S.TableCont>
                            <table className="full-width">
                                <thead>
                                    <tr>
                                        <th>Nombre y apellidos</th>
                                        <th>Correo electrónico</th>
                                        <th>Menor de edad</th>
                                        <th>Conocer a nuestro cliente</th>
                                        {[0, 1].includes(savingsPlan?.type) &&
                                            (
                                                <th>Test de idoneidad</th>
                                            )}
                                        <th className="actions"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userIdentities.map((userIdentity, userIdentityIndex) => {
                                        var currentDate = Moment();
                                        var birthdayDate = Moment(userIdentity.birthday);
                                        var isMinor = currentDate.diff(birthdayDate, 'years') < 18;
                                        return (
                                            <tr key={'userIdentityIndex' + userIdentityIndex}>
                                                <td>{userIdentity.firstName} {userIdentity.lastName} {userIdentity.secondLastName}</td>
                                                <td>{userIdentity.email}</td>
                                                <td className="text-center">{isMinor ? 'Sí' : 'No'}</td>
                                                <td className="text-center">
                                                    {isMinor && (<>No es necesario</>)}
                                                    {!isMinor && userIdentity.status !== undefined
                                                        && userIdentity.status === 'NEW' &&
                                                        (
                                                            <span>No</span>
                                                        )}
                                                    {!isMinor && userIdentity.status !== undefined
                                                        && ['PENDING', 'ACTIVE', 'OUTDATED', 'CANCELLED'].includes(userIdentity.status) &&
                                                        (
                                                            <span>Sí</span>
                                                        )}
                                                </td>
                                                {[0, 1].includes(savingsPlan?.type) &&
                                                    (
                                                        <td className="text-center">
                                                            {isMinor && (<>No es necesario</>)}
                                                            {!isMinor && userIdentity.maxRiskAllowed === null &&
                                                                (
                                                                    <React.Fragment>
                                                                        <i className='ironia-icon warning-alert' />
                                                                        No
                                                                    </React.Fragment>
                                                                )}
                                                            {!isMinor && userIdentity.maxRiskAllowed !== null &&
                                                                (
                                                                    <React.Fragment>
                                                                        {risks !== undefined &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    {(risks.find(r => userIdentity.suitabilityTests !== undefined
                                                                                        && r._id === userIdentity.suitabilityTests[userIdentity.suitabilityTests.length - 1].results?.results.profile)?.order || 0)}/4
                                                                                    {' '}
                                                                                    {risks.find(r => userIdentity.suitabilityTests !== undefined
                                                                                        && r._id === userIdentity.suitabilityTests[userIdentity.suitabilityTests.length - 1].results?.results.profile)?.name}
                                                                                </React.Fragment>
                                                                            )}
                                                                    </React.Fragment>
                                                                )}
                                                        </td>
                                                    )}
                                                <td className="text-center">
                                                    {userIdentity?.id !== undefined
                                                        && userIdentity.status !== undefined
                                                        && (userIdentity.maxRiskAllowed === null
                                                            || (suitabilityTestVersion !== undefined && !userIdentity.suitabilityTests?.some(sT => sT.suitabilityTestVersionId === suitabilityTestVersion.id)))
                                                        && !isMinor
                                                        && (
                                                            <DefaultButton className="button mini-primary-button info-suitability-test-button"
                                                                disabled={!userIdentity.canActivateSuitabilityTestButton || isSending || userIdentity.status === 'CANCELLED'}
                                                                onClick={() => {
                                                                    // @ts-ignore
                                                                    editUserIdentity(userIdentity.id);
                                                                }}
                                                            >
                                                                {isSending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                                                {/* Mostrar solo el icono de información si `canActivateSuitabilityTestButton` es `false` */}
                                                                {!userIdentity.canActivateSuitabilityTestButton && (
                                                                    <>
                                                                        <InfoIcon
                                                                            id="tooltipActivateTest"
                                                                            onMouseEnter={() => setTooltipInfoSuitabilityTest(true)}
                                                                            onMouseLeave={() => setTooltipInfoSuitabilityTest(false)}
                                                                            style={{ fontSize: "20px", marginTop: "0px" }}
                                                                        />
                                                                        {/* TeachingBubble mostrado cuando `tooltipInfoSuitabilityTest` es `true` */}
                                                                        {tooltipInfoSuitabilityTest && (
                                                                            <TeachingBubble
                                                                                target="#tooltipActivateTest"
                                                                                hasCloseButton={false}
                                                                                headline="Información sobre la actualización del Test de Idoneidad"
                                                                                onDismiss={() => setTooltipInfoSuitabilityTest(false)}
                                                                            >
                                                                                <div>
                                                                                    El Test de Idoneidad puede actualizarse si cumple al menos una de las siguientes condiciones:
                                                                                    <br />
                                                                                    <ul>
                                                                                        <li>
                                                                                            <strong>Menos de 2 actualizaciones:</strong> No se han realizado más de 2 actualizaciones en los últimos 6 meses.
                                                                                        </li>
                                                                                        <li>
                                                                                            <strong>Última actualización hace más de 6 meses:</strong> Han pasado al menos 6 meses desde la última actualización del test.
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </TeachingBubble>
                                                                        )}
                                                                    </>
                                                                )}

                                                                {/* Mostrar el texto "Realizar test" o "Actualizar test" solo si `canActivateSuitabilityTestButton` es `true` */}
                                                                {userIdentity.canActivateSuitabilityTestButton && (
                                                                    <>
                                                                        {userIdentity.maxRiskAllowed === null ? "Realizar test" : "Actualizar test"}
                                                                    </>
                                                                )}
                                                            </DefaultButton>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </S.TableCont>
                    )}
                <p className="description">
                    El plan de cartera seleccionado tiene un perfil de riesgo {(savingsPlanRiskProfile?.order || 0)}/4
                    {" "}
                    {savingsPlanRiskProfile?.name}
                </p>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 text-right pt10">
                        {savingsPlan?.configurationMapStep === '2.2' && (
                            <DefaultButton
                                className="button tiny-secondary-button"
                                disabled={isSending || !loadedUserIdentities}
                                onClick={() => {
                                    setIsSending(true);
                                    onSubmit();
                                }}
                            >
                                {isSending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                Pasar al siguiente paso
                            </DefaultButton>
                        )}
                        {savingsPlan?.configurationMapStep !== '2.2' &&
                            (
                                <DefaultButton
                                    className="button tiny-secondary-button"
                                    onClick={() => redirectToMap()}
                                >
                                    Volver al mapa
                                </DefaultButton>
                            )}
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default SuitabilityTestList;
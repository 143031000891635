export const DayPickerStrings = {
    months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octobre',
        'Noviembre',
        'Diciembre'
    ],
    shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    days: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
    shortDays: ['D', 'L', 'M', 'MI', 'J', 'V', 'S'],
    goToToday: 'Ir a Hoy',
    prevMonthAriaLabel: 'Ir al mes anterior',
    nextMonthAriaLabel: 'Ir al proximo mes',
    prevYearAriaLabel: 'Ir al año anterior',
    nextYearAriaLabel: 'Ir al proximo Año'
};
import React from "react";
import { Link } from "react-router-dom";
import { ActiveIcon } from "../../icons/IconsTable";
import { ThirdSearchEngineTable } from "./SearchManagersStyle";

interface ISearchEngineThirdTable
{
    children: any[]
}

const SearchEngineThirdTable = (props: ISearchEngineThirdTable) =>
{
    return(
        <React.Fragment>
            <ThirdSearchEngineTable>
                <thead>
                    <tr>
                        <th>
                            <div style={{marginLeft: "5px"}}>Nombre/ISIN</div>
                        </th>
                        <th>
                            <div>Divisa</div>
                        </th>
                        <th>
                            <div>Gastos Corrientes (OGC)</div>
                        </th>
                        <th>
                            <div>Retrocesión</div>
                        </th>
                        <th>
                            <div>Inversión Inicial Mínima</div>
                        </th>
                        <th>
                            <div>Dividendos</div>
                        </th>
                        <th>
                            <div>Aplica mínimo</div>
                        </th>
                        <th>
                            <div>Clase limpia</div>
                        </th>
                        <th>
                            <div>Disponible</div>
                        </th>
                        <th>
                            <div />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.children.length > 0 && props.children.map((item: any, index: number) =>
                    (
                        <React.Fragment key={index}>
                            <tr key={item.key}>
                                <td>
                                    <div className="max-content">
                                        <button className="expanded">
                                            <Link to={{pathname: `/iframe/${item.isin}`, state: {fund: item, brothers: props.children}}}>
                                                <div>
                                                    {item.name}
                                                    <br />
                                                    {item.isin}
                                                </div>
                                            </Link>
                                        </button>
                                    </div>
                                </td>
                                <td>{item.currency}</td>
                                <td>{item.ongoingCharges}%</td>
                                <td>{parseFloat(item.rebate).toFixed(4)}%</td>
                                <td>
                                    {item.minimumInitialInvestment.toLocaleString()}
                                    &euro;
                                </td>
                                <td>{item.income ? "Si" : "No"}</td>
                                <td>{item.noMin ? "Si" : "No"}</td>
                                <td>{item.cleanShare ? "Si" : "No"}</td>
                                <td>{item.available ? "Si" : "No"}</td>
                                <td>
                                    {item.currency === "EUR" && item.cleanShare &&
                                    (
                                        <ActiveIcon color='#1BB2AD' />
                                    )}
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </ThirdSearchEngineTable>
        </React.Fragment>
    );
}

export default SearchEngineThirdTable;
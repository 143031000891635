import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { ChevronDownMedIcon, ChevronUpMedIcon } from '@fluentui/react-icons-mdl2';
import { TextField, Checkbox } from '@fluentui/react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ThemeContext } from '@fluentui/react-theme-provider';

const CustomDropdownContainer = styled.div`
  position: relative;
  display: block;

  &.with-summary {
    min-height: 100px;
  }

  > button {
    background-color: white;
    border-radius: 5rem;
    border: 1px solid #bcc8c9;
    padding: 0.75rem 1rem;
    padding-right: 1.75rem;
    width: max-content;
    cursor: pointer;
    color: ${(props) => props.theme.palette.lightBlack} !important;
    background: ${(props) => props.theme.palette.white} !important;
    height: min-content;

    svg {
      margin-left: 0.75rem;
    }

    span {
      color: ${(props) => props.theme.palette.themePrimary};
      padding-left: 5px;
    }
  }
  .menu {
    display: flex;
    flex-direction: column;
    width: max-content;
    position: absolute;
    z-index: 100;
    background-color: ${(props) => props.theme.palette.popupBackground} !important;
    top: 48px;
    left: 0;
    border-radius: 0 0 16px 16px;
    max-height: 315px;
    box-shadow: 0px 2px 64px rgba(8, 84, 117, 0.12), 0px 1px 20px rgba(8, 84, 117, 0.12);


    .menu-header {
      display: flex;
      flex-direction: column;
      min-height: 40px;

      div,
      input {
        :after {
          border: none;
          outline: none;
        }
        border: none;
        outline: none;
      }

      .ms-TextField-fieldGroup  {
        border-radius: 0;
        background: ${(props) => props.theme.palette.shadowBackground};

        input {
          padding: 0 20px;
          color: ${(props) => props.theme.palette.greyText};
        }
      }
    }

    .menu-body {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: auto;
      max-height: 230px;
      border-bottom: 1px solid lightGrey;

      .ms-Checkbox {
        padding: 0.75rem 0;
        border-bottom: 1px solid rgba(147,174,185,0.2);
        label {
          font-weight: 600;
        }

        &:last-of-type {
          border:  0;
        }
      }
    }

    .menu-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      button {
        border: none;
        padding: 10px 15px;
        margin: 10px;
      }
      button {
        font-family: Barlow;
        font-weight: 600;
        font-size: 14px;

        &:first-of-type {
          background: none;
          cursor: pointer;
          color: ${(props) => props.theme.palette.greyText};
          
        }
        &:last-of-type {
          background: #cc214f;
          border-radius: 50px;
          color: white;
          cursor: pointer;
        }
      }
    }

    p {
      text-align: center;
      font-weight: 600;
    }
  }

  .selected-summary {
    position: absolute;
    top: 45px;
    padding: 0 10px;
    max-width: 100%;
    color: grey;

    ul {
      margin-top: 0;
      li {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 640px)
  {
    > button {
        width: 100%;
    }
  }
`;

const CustomDropdown = ({ options, title, onSave = {}, showSummary = false, icons = { closed: <ChevronUpMedIcon />, opened: <ChevronDownMedIcon /> } }) =>
{
    const theme = useContext(ThemeContext);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [optionsToRender, setOptionsToRender] = useState([]);
    const [searchTerm, setSearchTerm] = useState([]);

    const handleFilter = (event) =>
    {
        setSearchTerm(event.target.value);
        let filtered = options.filter((item) =>
            item.label
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(event.target.value.toLowerCase())
        );
        setFilteredOptions([...filtered]);
    };

    useEffect(() =>
    {
        setOptionsToRender(filteredOptions.length > 0 ? filteredOptions : options);
    }, [filteredOptions, options]);

    return (
        <CustomDropdownContainer className={showSummary ? 'with-summary' : ''} theme={theme}>
            <button onClick={() => setIsOpen(!isOpen)}>
                <div style={{ display: 'flex' }}>
                    {title}
                    {isOpen ? icons.closed : icons.opened}
                </div>
            </button>
            {isOpen && (
                <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                    <div className='menu ms-depth-16'>
                        <div className='menu-header'>
                            <TextField value={searchTerm} onChange={handleFilter} placeholder='Buscar...' />
                        </div>
                        <div className='menu-body'>
                            {optionsToRender.length > 0 ? (
                                optionsToRender.map(({ label, value }, i) => (
                                    <Checkbox
                                        key={i}
                                        label={label}
                                        value={value}
                                        onChange={() =>
                                        {
                                            if(selectedKeys.some((item) => item.label === label))
                                            {
                                                let selected = selectedKeys.filter((key) => key.label !== label);
                                                setSelectedKeys([...selected]);
                                            } else
                                            {
                                                setSelectedKeys([...selectedKeys, { label, value }]);
                                            }
                                        }}
                                        checked={selectedKeys.some((key) => key.label === label)}
                                    />
                                ))
                            ) : (
                                <p>No options</p>
                            )}
                        </div>
                        <div className='menu-footer'>
                            <button
                                onClick={() =>
                                {
                                    setSelectedKeys([]);
                                    onSave([]);
                                    //setIsOpen(false);
                                }}
                            >
                                Borrar
                            </button>
                            <button
                                onClick={() =>
                                {
                                    onSave(selectedKeys);
                                    setIsOpen(false);
                                }}
                            >
                                Guardar
                            </button>
                        </div>
                    </div>
                </OutsideClickHandler>
            )}
            {showSummary && selectedKeys.length > 0 && (
                <div className="selected-summary">
                    <ul>
                        {optionsToRender.map((option) =>
                        {
                            if(selectedKeys.map((key) => key.value).includes(option.value))
                            {
                                return <li key={option.value}>{option.label}</li>
                            }
                            return "";
                        })}
                    </ul>
                </div>
            )}
        </CustomDropdownContainer>
    );
};

export default CustomDropdown;
import React from "react";
import { OrderTrendByFund } from "../../../services/OrderService";
import styled from "styled-components";

type Props =
{
    fund: OrderTrendByFund,
    index: string | number
}

export const ChildTable = (props: Props) =>
{
    const { fund, index } = props
    return (
		<React.Fragment key={index}>
			<tr>
				<td>
					<div style={{ marginLeft: "35px" }}>Compra</div>
				</td>
				<StyledTd>{fund.semanaCompras ?? 0}</StyledTd>
				<StyledTd>{fund.mesCompras ?? 0}</StyledTd>
				<StyledTd>{fund.trimestreCompras ?? 0}</StyledTd>
				<StyledTd>{fund.añoCompras ?? 0}</StyledTd>
				<StyledTd>{fund.compras ?? 0}</StyledTd>
			</tr>
			<tr>
				<td>
					<div style={{ marginLeft: "35px" }}>Transpaso Destino</div>
				</td>
				<StyledTd>{fund.semanaTraspasosDestino ?? 0}</StyledTd>
				<StyledTd>{fund.mesTraspasosDestino ?? 0}</StyledTd>
				<StyledTd>{fund.traspasosDestino ?? 0}</StyledTd>
				<StyledTd>{fund.añoTraspasosDestino ?? 0}</StyledTd>
				<StyledTd>{fund.traspasosDestino ?? 0}</StyledTd>
			</tr>
			<tr>
				<td>
					<div style={{ marginLeft: "35px" }}>Transpaso Origen</div>
				</td>
				<StyledTd>{fund.semanaTraspasosOrigen ?? 0}</StyledTd>
				<StyledTd>{fund.mesTraspasosOrigen ?? 0}</StyledTd>
				<StyledTd>{fund.trimestreTraspasosOrigen ?? 0}</StyledTd>
				<StyledTd>{fund.añoTraspasosOrigen ?? 0}</StyledTd>
				<StyledTd>{fund.traspasosOrigen ?? 0}</StyledTd>
			</tr>
			<tr>
				<td>
					<div style={{ marginLeft: "35px" }}>Reembolso</div>
				</td>
				<StyledTd>{fund.semanaReembolsos ?? 0}</StyledTd>
				<StyledTd>{fund.mesReembolsos ?? 0}</StyledTd>
				<StyledTd>{fund.trimestreReembolsos ?? 0}</StyledTd>
				<StyledTd>{fund.añoReembolsos ?? 0}</StyledTd>
				<StyledTd>{fund.reembolsos ?? 0}</StyledTd>
			</tr>
		</React.Fragment>
	);
};

const StyledTd = styled.td`
    text-align: right;
`;
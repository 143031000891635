import { Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import { TransferEstimationDates, TransferSimulationManagerInfo } from "../../../services/FundService";
import { SectionContainer, WarningContainer } from "./styles";
import "./TransferTimeLine.css";
import TransferManagerRiskCard from "./TransferManagerRiskCard";
import { WarningIcon } from "@fluentui/react-icons-mdl2";

type TransferProps = {
	transferEstimationDates: TransferEstimationDates;
	simulationManagerInfoOrigin?: TransferSimulationManagerInfo[];
	simulationManagerInfoTarget?: TransferSimulationManagerInfo[];
	isLoadingProps?: boolean;
};

function calculateDaysOutOfMarket(date1: string, date2: string) {
	let date1Array = date1.split("/");
	let date2Array = date2.split("/");

	let date1Converted = new Date(date1Array[2] + "-" + date1Array[1] + "-" + date1Array[0]);
	let date2Converted = new Date(date2Array[2] + "-" + date2Array[1] + "-" + date2Array[0]);

	return Math.abs(+date2Converted - +date1Converted) / 86400000;
}

export const TransferTimeLine = (props: TransferProps) => {
	const { simulationManagerInfoOrigin, simulationManagerInfoTarget, isLoadingProps } = props;
	const theme = window.localStorage.getItem("theme") || "light";

	const isValidData: boolean =
		props.transferEstimationDates.compraLiquidacion !== "" &&
		props.transferEstimationDates.compraValor !== "" &&
		props.transferEstimationDates.ventaLiquidacion !== "" &&
		props.transferEstimationDates.ventaValor !== "" &&
		props.transferEstimationDates.finalProceso !== "";

	const days = calculateDaysOutOfMarket(props.transferEstimationDates.compraValor, props.transferEstimationDates.ventaValor);

	return (
		<React.Fragment>
			<React.Fragment>
				<div className={"ms-Grid-row principalRowContainer" + (theme === "dark" ? "Black" : "")}>
					<React.Fragment>
						{!isLoadingProps && isValidData && (
							<>
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6">
									<p className={"textTitle" + (theme === "dark" ? "White" : "Black")}>Simulación de tiempo de Traspaso</p>
								</div>
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6">
									<p className={"outMarketText" + (theme === "dark" ? "White" : "Black")}>Días fuera de Mercado: {days}</p>
								</div>
								<div style={{ padding: "0px 10px 0px 10px" }}>
									<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl2 ms-xxl2 ms-xxxl2" style={{ padding: "0px" }}>
										{simulationManagerInfoOrigin &&
											simulationManagerInfoOrigin.map((manager, index) => (
												<TransferManagerRiskCard
													key={index}
													index={index}
													isOrigin={true}
													theme={theme}
													name={manager.manager}
													koRatio={manager.ratioKOOrigen}
													okRatio={manager.ratioOKOrigen}
													totalTransfers={manager.totalOrigen}
												></TransferManagerRiskCard>
											))}
									</div>
									<div
										className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8 ms-xxl8 ms-xxxl8"
										style={{ marginTop: "20px", marginBottom: "30px", padding: "0px 20px 0px 20px" }}
									>
										<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
											<div className="bodyContainer">
												<div className="container">
													<div className="progress-container">
														<div className="progress"></div>
														<div className="circleVenta realized"></div>
														<div className="circleVenta realized">
															<p className={"circleText" + (theme === "dark" ? "White" : "Black")}>
																Fecha de Valor Liquidativo Salida
															</p>
														</div>
														<div className="circleVenta realized">
															<p className={"circleText" + (theme === "dark" ? "White" : "Black")}>Fecha Liquidación Salida</p>
														</div>
														<div className="circleCompra active">
															<p className={"circleText" + (theme === "dark" ? "White" : "Black")}>
																Fecha Valor Liquidativo Entrada
															</p>
														</div>
														<div className="circleCompra active">
															<p className={"circleText" + (theme === "dark" ? "White" : "Black")}>Fecha Liquidación Entrada</p>
														</div>
														<div className="circleCompra active"></div>
													</div>
												</div>
											</div>
										</div>
										<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
											<div className="dateBody-container">
												<div className="container">
													<div className="date-container">
														<div className="circleEmptyLeft">{new Date().toLocaleDateString()}</div>
														<p className={"circleDate" + (theme === "dark" ? "White" : "Black")}>
															{props.transferEstimationDates.ventaValor}
														</p>
														<p className={"circleDate" + (theme === "dark" ? "White" : "Black")}>
															{props.transferEstimationDates.ventaLiquidacion}
														</p>
														<p className={"circleDate" + (theme === "dark" ? "White" : "Black")}>
															{props.transferEstimationDates.compraValor}
														</p>
														<p className={"circleDate" + (theme === "dark" ? "White" : "Black")}>
															{props.transferEstimationDates.compraLiquidacion}
														</p>
														<div className="circleEmptyRight">{props.transferEstimationDates.finalProceso}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl2 ms-xxl2 ms-xxxl2" style={{ padding: "0px" }}>
										{simulationManagerInfoTarget &&
											simulationManagerInfoTarget.map((manager, index) => (
												<TransferManagerRiskCard
													key={index}
													index={index}
													isOrigin={false}
													theme={theme}
													name={manager.manager}
													koRatio={manager.ratioKODestino}
													okRatio={manager.ratioOKDestino}
													totalTransfers={manager.totalDestino}
												></TransferManagerRiskCard>
											))}
									</div>
								</div>
							</>
						)}
						{(isLoadingProps || !isValidData) && (
							<div
								className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12"
								style={{ padding: "20px 0px 0px 0px", display: "inline-flex", justifyContent: "center" }}
							>
								<Spinner size={SpinnerSize.large} className="button-spinner" />
							</div>
						)}
						<SectionContainer className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
							{!isLoadingProps && simulationManagerInfoTarget?.find(x => x.switchable === false) !== undefined && (
								<div
									style={{
										display: "flex",
										background: "#cc214f",
										color: "#ffffff",
										padding: "10px",
										justifyContent: "end",
										fontWeight: "500",
										fontSize: "16px"
									}}
								>
									<p>
										<WarningIcon style={{ height: "20px", width: "20px", paddingRight: "5px" }} />
										Fondo destino no traspasable
									</p>
								</div>
							)}
							<WarningContainer style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
								<div>
									<img src="/icons/information.svg" alt="Información" />
								</div>
								<p>
									Las fechas de valor liquidativo y liquidación, así como los días fuera de mercado expuestos son una estimación informativa
									que NO tiene en cuenta los días no laborables del país de origen del fondo y no constituyen una garantía para ningún tipo de
									reclamación por dicha información expuesta. Asimismo, se recuerda al inversor que para poder calcular la estimación más
									exacta, deberá analizar la memoria de comercialización, el folleto y el resto de documentaciones pre-contractuales de los
									vehículos donde figuren las horas de corte y el periodo del cálculo liquidativo establecidas por las entidades
									comercializadoras y las gestoras de los mismos.
								</p>
							</WarningContainer>
						</SectionContainer>
					</React.Fragment>
				</div>
			</React.Fragment>
		</React.Fragment>
	);
};

import { useState, useContext, useEffect } from "react";
import { Wrapper, TableContainer, Table, Header, CounterContainer, ActionsContainer } from "./styles";
import { ThemeContext } from "@fluentui/react-theme-provider";
import { toFixedDecimals } from "../../../utils/toFixedDecimals";

const GenericTable = (
{
    counterText,
    data = [],
    columns = [
        {
            id: "",
            title: "",
            source: "",
            customCell: ({}) => (<></>)
        },
    ],
    resumeData = [],
}) =>
{
    const theme = useContext(ThemeContext);
    const [rows, setRows] = useState([]);

    useEffect(() =>
    {
        if(data)
        {
            setRows(data);
        }
    }, [data]);

    return (
        <Wrapper>
            <Header>
                <CounterContainer theme={theme}>
                    <h3>{counterText || "Resultados de la búsqueda"} </h3>
                    <span> ({data.length})</span>
                </CounterContainer>
                <ActionsContainer theme={theme} />
            </Header>
            <TableContainer>
                <Table hasResume={resumeData ? 1 : 0} theme={theme}>
                    <thead>
                        <tr>
                            {columns.map((column, columnIndex) =>
                            {
                                return <th key={'columnIndex' + columnIndex}>{column.title}</th>;
                            })}
                        </tr>
                        <tr>
                            {resumeData &&
                                resumeData.map((cell, cellIndex) =>
                                {
                                    return <th key={'cellIndex' + cellIndex}>{cell}</th>;
                                })}
                        </tr>
                    </thead>
                    {rows.map((row, rowIndex) => (
                        <tbody key={'rowIndex' + rowIndex}>
                            <tr
                                onClick={() =>
                                {
                                    row.subData.expanded = !row.subData.expanded;
                                    setRows([...rows]);
                                }}
                            >
                                {columns.map((col, colIndex) =>
                                {
                                    const cellValue = toFixedDecimals(row[col.source], 2);
                                    if(col.customCell)
                                    {
                                        return (
                                            <td key={'colIndex' + colIndex}>
                                                {col.customCell({ value: cellValue, row, col })}
                                            </td>
                                        );
                                    } else
                                    {
                                        return <td key={'colIndex' + colIndex}>{cellValue}</td>;
                                    }
                                })}
                            </tr>
                            {row.subData && row.subData.expanded && (
                                row.subData.data.map((childRow, childRowIndex) =>
                                {
                                    return (
                                        <tr style={{ background: theme.palette.tertiary }} key={'childRowIndex' + childRowIndex}>
                                            <td></td>
                                            <td></td>
                                            {row.subData.columns.map((childCol, childColIndex) =>
                                            {
                                                const cellValue = childRow[childCol.source];

                                                if(childCol.customCell)
                                                {
                                                    return (
                                                        <td key={'childColIndex' + childColIndex}>
                                                            {childCol.customCell({
                                                                value: cellValue,
                                                                childRow,
                                                                childCol,
                                                            })}
                                                        </td>
                                                    );
                                                } else
                                                {
                                                    return <td key={'childColIndex' + childColIndex}>{cellValue}</td>;
                                                }
                                            })}

                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    ))}
                </Table>
            </TableContainer>
        </Wrapper>
    );
};

export default GenericTable;
import styled from "styled-components";

export const TableStyled = styled.table`
    width: 100%;
    border-spacing: 0;
    overflow: hidden;
    thead
    {
        text-align: left;
        border-top-right-radius: 30px;
        div{}
        th
        {
            border-spacing: 0;
            padding: 0;
            div
            {
                //padding: 17px ;
                padding-top: 17px;
                padding-right: 17px;
                padding-bottom: 17px;

                svg {
                    width: 20px;
                    height: 20px;
                    position: relative;
                    top: -4px;
                    left: 3px;
                }
            }
        }
        th:first-of-type
        {
            div
            {
                padding-left: 10px;
                border-top-left-radius: 30px;
            }
        }
        th:last-of-type
        {
            div
            {
                padding-right: 10px;
                border-top-right-radius: 30px;
            }
        }
    }
    tbody
    {
        button.expanded
        {
            border: none;
            cursor: pointer;
            transition: 150ms;
            border-radius: 0;
            &:hover
            {
                border-bottom: 1px solid #4284e6;
                transition: 150ms;
            }
        }
        strong
        {
            color: #4284e6;
        }
        tr
        {
            td:first-of-type
            {
                //padding-left: 1.75rem;
            }
            td
            {
                border-bottom: 2px solid #ecf1f3;
                div
                {
                    padding: 0px 0;
                }
                p
                {
                    color: #9ca6a6;
                }
            }
            svg
            {
                margin-right: 3px;
            }
            .max-content
            {
                min-width: max-content;
            }
        }
        tr:last-of-type
        {
            td
            {
                border-bottom: none;
            }
        }
        tr.container-table td:first-of-type
        {
            padding: 0;
        }
    }
`;

export const Table = { TableStyled };
import { useEffect, useState } from "react";
import * as S from '../Elements/ElementStyles';
import { Icon } from '@fluentui/react/lib/Icon';
import React from "react";

const ModalElementSigned = ({ setModalShow }: { setModalShow: any}) =>
{
    const [height, setHeight] = useState('');

    useEffect(() =>
    {
        const toHeight = document?.querySelector('#root')?.scrollHeight + 'px';
        setHeight(toHeight);
    }, []);

    return (
        <React.Fragment>
            <S.HelperStyle />
            <S.ModalMainCont>
                <S.ModalToExitCont>
                    <S.ModalToExit>
                        <Icon iconName='ChromeClose'
                            // @ts-ignore
                            styles={closeBtn}
                            onClick={() => setModalShow(false)}
                        />
                    </S.ModalToExit>
                </S.ModalToExitCont>
                <S.ModalContinue>¡Proceso de firma completado!</S.ModalContinue>
                <hr />
                <S.ModalNeedsCont>
                    <p>
                        Enhorabuena, has completado el contrato de suscripción a Elements en IronIA.
                    </p>
                </S.ModalNeedsCont>
                <S.ModalBtnCont>
                    <S.ModalLinkBtn to={'/Elements'}>
                        <S.ModalAcceptBtn>Aceptar</S.ModalAcceptBtn>
                    </S.ModalLinkBtn>
                </S.ModalBtnCont>
            </S.ModalMainCont>
            <S.ModalFocus className='modal' // @ts-ignore
                height={height} />
        </React.Fragment>
    );
};

const closeBtn =
{
    root:
    {
        fontSize: "14px",
        fontWeight: 1000,
        position: "relative",
        color: "#BFC6C7",
    },
}

export default ModalElementSigned;
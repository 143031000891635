import { useContext, useEffect, useRef, useState } from 'react';
import * as L from './layout.styles';
import { TextField, Stack, SpinnerSize, Spinner, DetailsList, DetailsListLayoutMode } from '@fluentui/react';
import { CaretHollowMirroredIcon } from '@fluentui/react-icons-mdl2';
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { InvestmentFundFinder } from "./components/InvestmentFundFinder";
import { ThemeContext } from '@fluentui/react-theme-provider';
import Auth from "../../Auth/Auth";
import { useForm, Controller } from "react-hook-form";
import OrderService, { OrderType } from '../../services/OrderService';
import React from 'react';
import DocumentTitle from 'react-document-title';

interface RouteParams {}
interface IMarketerSwitchFormScreenProps extends RouteComponentProps<RouteParams> { }

export const MarketerSwitchFormScreen: React.FunctionComponent<IMarketerSwitchFormScreenProps> = (props, context) =>
{
    const theme = useContext(ThemeContext);
    let activePlan = Auth.getActivePlan();
    const {register, handleSubmit, watch, setError, setValue, formState: { errors }, control} = useForm();
    const [sending, setSending] = useState(false);
    const [lines, setLines] = useState<any[]>([]);
    const history = useHistory();
    const mounted = useRef(false);
    const watchSourceFund = watch("sourceFund");
    const watchAmount = watch("amount");
    const outdated = Auth.isLastPlanOutdated();
    const cancelled = Auth.isActivePlanCancelled();

    let userData = Auth.getUserProfile();

    const addLine = () =>
    {
        if(!watchSourceFund?.isin)
        {
            setError("sourceFund", {type: "required"});
            return;
        }
        if(!watchAmount)
        {
            setError("amount", {type: "required"});
            return;
        }
        
        let newLines: any[] = [...lines];
        if(lines.length === 0)
        {
            newLines.push(
            {
                originISIN: watchSourceFund?.isin,
                originName: watchSourceFund?.name,
                amount: watchAmount
            });
        }
        else
        {
            if(newLines.some(p => p.originISIN === watchSourceFund.isin))
            {
                let idx: number = newLines.findIndex(p => p.originISIN === watchSourceFund.isin);
                newLines[idx].amount = (parseFloat(newLines[idx].amount) + parseFloat(watchAmount)).toFixed(4);
            }
            else
            {
                newLines.push(
                {
                    originISIN: watchSourceFund?.isin,
                    originName: watchSourceFund?.name,
                    amount: watchAmount
                });
            }
        }
        setLines(newLines);
    };
    const removeLine = (index: any) =>
    {
        setLines((lines) =>
        {
            let newLines = [...lines];
            newLines.splice(index, 1);
            let newState = [...newLines];
            return newState;
        });
    };
    const onSubmit = (data: any) =>
    {
        if(Array.isArray(lines) && lines.length > 0)
        {
            let toSend: any =
            {
                UserIdentityId: userData.mainUserIdentity.id,
                FnmPortfolioId: activePlan?.portfolio?.id,
                OrderType: 4,
                Statement: data.statement[0],
                Values: {Bank: data.bank, Office: data.office, Lines: lines}
            };
    
            setSending(true);
            OrderService.createOrderWithAttachment(toSend).then((response: OrderType) =>
            {
                if(response.id)
                {
                    history.push("/distributor-switch/signature",
                    {
                        signature: response.signature,
                        transfer: response
                    });
                }
            })
            .catch((error) => {})
            .finally(() =>
            {
                if(mounted.current)
                {
                    setSending(false);
                }
            });
        }
    };

    useEffect(() =>
    {
        mounted.current = true;
        return () =>
        {
            mounted.current = false;
        };
    }, []);

    if(outdated === true || cancelled === true)
    {
        return (<Redirect to="/savingsPlans" />);
    }
    return(
        <React.Fragment>
            <DocumentTitle title={"IronIA - Cambio de Comercializador"}>
                <form className="external-transfer-form" id="external-transfer-form" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <L.Main theme={theme}>
                        <L.BackButtonContainer>
                            <span>
                                <CaretHollowMirroredIcon />
                                <Link to='/purchase-transfer'>
                                    Volver atrás
                                </Link>
                            </span>
                        </L.BackButtonContainer>
                        {activePlan !== undefined &&
                            <div className="ms-Grid-row" style={{minHeight: "20px"}}>
                                <div className="ms-Grid-col">
                                    <L.TitleContainer>
                                        <h2>Cambio de comercializador</h2>
                                    </L.TitleContainer>
                                </div>
                                <div className="ms-Grid-col" style={{paddingTop: "12px", color: "#CC214F"}}>
                                <L.SubTitleContainer> {activePlan.name}</L.SubTitleContainer>
                                </div>
                            </div>
                        }
                        {activePlan === undefined &&
                            <L.TitleContainer>
                                <h2>Cambio de comercializador</h2>
                            </L.TitleContainer>
                        }
                        <L.SeparatorTop />
                        <div className="ms-Grid restarted-ms-Grid">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-xl6">
                                    <L.CardContainer theme={theme}>
                                        <L.CardHeader>
                                            <L.CardHeadingContainer>
                                                <h3>Datos de origen</h3>
                                            </L.CardHeadingContainer>
                                        </L.CardHeader>
                                        <L.CardBody>
                                            <Stack>
                                                <Stack.Item>
                                                    <TextField
                                                        {...register("bank",
                                                            {
                                                                required: true,
                                                                minLength: 3
                                                            }
                                                        )}
                                                        required
                                                        label="Banco"
                                                        placeholder="Nombre Banco Origen y Código..."
                                                        errorMessage={errors.account &&
                                                        (
                                                            (typeof (errors.account.type) === "string"
                                                                && errors.account.type === "required"
                                                                && "Debe rellenar este campo.")
                                                            || "Introduzca una cuenta válida."
                                                        )}
                                                    />
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <TextField
                                                        {...register(
                                                            "office",
                                                            {
                                                                required: true,
                                                                minLength: 3
                                                            }
                                                        )}
                                                        required
                                                        label="Sucursal"
                                                        placeholder="Nombre Banco Origen y Código..."
                                                        errorMessage={errors.account &&
                                                        (
                                                            (typeof (errors.account.type) === "string"
                                                                && errors.account.type === "required"
                                                                && "Debe rellenar este campo.")
                                                            || "Introduzca una cuenta válida."
                                                        )}
                                                    />
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <p>Debe adjuntar extracto de posición de la entidad comercializadora origen firmado
                                                        por todos los intervinientes con menos de tres meses de antigüedad.</p>
                                                    <L.Label>Extracto de posición *</L.Label>
                                                    <L.FieldFileCont>
                                                        <L.FileInput
                                                            {...register(
                                                                "statement",
                                                                {
                                                                    required: true
                                                                }
                                                            )}
                                                            required
                                                            type='file'
                                                        />
                                                    </L.FieldFileCont>
                                                    {errors.statement &&
                                                    (
                                                        (typeof (errors.statement.type) === "string"
                                                            && errors.statement.type === "required"
                                                            && (<L.Error>Es obligatorio adjuntar el extracto</L.Error>)
                                                        )
                                                    )}
                                                </Stack.Item>
                                                <Stack.Item>
                                                    {lines.length > 0 &&
                                                    (
                                                        <DetailsList items={lines}
                                                            columns={[
                                                                { key: 'OriginName', name: 'Fondo', fieldName: 'originName', minWidth: 100, isResizable: true },
                                                                { key: 'OriginISIN', name: 'Código ISIN', fieldName: 'originISIN', minWidth: 100, isResizable: true },
                                                                { key: 'amount', name: 'Participaciones', fieldName: 'amount', minWidth: 100, isResizable: true },
                                                                {
                                                                    key: 'actions',
                                                                    name: '',
                                                                    fieldName: '',
                                                                    minWidth: 0,
                                                                    isResizable: true,
                                                                    onRender: (item, index) =>
                                                                    {
                                                                        return(
                                                                            <Link to={{}} onClick={() => {removeLine(index);}}>
                                                                                Borrar
                                                                            </Link>
                                                                        );
                                                                    }
                                                                },
                                                            ]}
                                                            setKey="set"
                                                            disableSelectionZone={true}
                                                            checkboxVisibility={2}
                                                            layoutMode={DetailsListLayoutMode.justified}
                                                        />
                                                    )}
                                                    {lines.length === 0 &&
                                                    (
                                                        <p style={{color: "#CC214F"}}>Debes seleccionar al menos un fondo</p>
                                                    )}
                                                </Stack.Item>
                                            </Stack>
                                        </L.CardBody>
                                    </L.CardContainer>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-xl6">
                                    <L.CardContainer theme={theme}>
                                        <L.CardHeader>
                                            <L.CardHeadingContainer>
                                                <h3>Información de fondo</h3>
                                            </L.CardHeadingContainer>
                                        </L.CardHeader>
                                        <L.CardBody>
                                            <Stack>
                                                <Stack.Item>
                                                    <Controller name="sourceFund"
                                                        control={control}
                                                        rules={{}}
                                                        render={({field, field: {onChange}}) =>
                                                            <InvestmentFundFinder {...field}
                                                                labelISIN="ISIN de origen"
                                                                labelName="Nombre del fondo de origen"
                                                                placeholderISIN="Escribe aquí el ISIN de origen..."
                                                                placeholderName="Escribe aquí el nombre del fondo de origen..."
                                                                onChange={(ev: any, name: any, value: any) =>
                                                                {
                                                                    setValue(name, value);
                                                                    onChange({target: {value, name}});
                                                                }}
                                                                errorMessage={errors.sourceFund &&
                                                                (
                                                                    (typeof (errors.sourceFund.type) === "string"
                                                                        && errors.sourceFund.type === "required"
                                                                        && "Debe seleccionar un fondo de origen.")
                                                                    || "El fondo de origen no es válido."
                                                                )}
                                                            />
                                                        }
                                                    />
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Controller name="amount" control={control}
                                                        rules={{min: 0 + Number.MIN_VALUE}}
                                                        render={({field}) =>
                                                            <TextField {...field} label="Participaciones"
                                                            placeholder="Escribe aquí el número de participaciones..."
                                                            type="number"
                                                            errorMessage={errors.amount &&
                                                            (
                                                                (typeof (errors.amount.type) === "string"
                                                                    && errors.amount.type === "required"
                                                                    && "Indique las participaciones a traspasar.")
                                                                || (typeof (errors.amount.type) === "string"
                                                                    && errors.amount.type === "min"
                                                                    && "Debe indicar un número mayor de participaciones.")
                                                                || "Introduzca un número de participaciones válido."
                                                            )}
                                                        />}
                                                    />
                                                </Stack.Item>
                                            </Stack>
                                            <L.CardButton size="lg" onClick={addLine} type="button">Añadir</L.CardButton>
                                        </L.CardBody>
                                    </L.CardContainer>
                                </div>
                            </div>
                        </div>
                        <L.OuterFooterArea>
                            <L.CardButton size="lg">
                                {sending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                Continuar
                            </L.CardButton>
                        </L.OuterFooterArea>
                    </L.Main>
                </form>
            </DocumentTitle>
        </React.Fragment>
    );
};
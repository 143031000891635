import * as React from "react";
import {useState} from "react";
import {IComboBoxOption} from "@fluentui/react";
import {ISearchAutofillProps, SearchAutofill} from "./SearchAutofill";
import distributorsService from "../../../services/DistributorsService";

interface DistributorFinderProps {
    label?: string;
    name?: string;
    ref?: any;
    required?: boolean;
    errorMessage?: any;
    placeholder?: string;
    onChange?: (event: any, name?: string, value?: any, required?: boolean) => any;
    onValidate?: (inputStatus: any) => any;
}

const loadData = (filter: any) => {
    return distributorsService.getDistributors()
    .then(response => response?.data?.filter?.(
        distributor => {
            return distributor?.name?.toUpperCase?.().includes(filter?.toUpperCase());
        }
    )?.slice?.(0, 20))
    .catch(err => []);
}

export const DistributorFinder = React.forwardRef((props: DistributorFinderProps, ref: any) => {
    const [selectedItem, setSelectedItem] = useState<IComboBoxOption|any>(undefined);

    const onChange: ISearchAutofillProps["onChange"] = (event: any, item: any) => {
        setSelectedItem(item);
        if(props.onChange) {
            props.onChange(event, props.name, item?.data, props.required);
        }
    };

    return (
        <>
            <SearchAutofill
            {...props}
            itemRef={ref}
            selectedItem={selectedItem}
            getKey={(item: any) => item?.id}
            getDisplayValue={(item: any) => { return item?.name }}
            getSuggestionDisplayValue={(item: any) => { return item?.name }}
            loadData={loadData}
            onChange={onChange}
            />
        </>
    );
});

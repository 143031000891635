import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as IncrementIcon } from '../../../images/increment.svg';
import { ReactComponent as DecrementIcon } from '../../../images/decrement.svg';
import 'office-ui-fabric-react/dist/css/fabric.css';
import './CardsSection.sass';
import NumberFormat from 'react-number-format';
import { ThemeContext } from '@fluentui/react-theme-provider';
import styled from 'styled-components';
import { Button } from '../../../components/buttons/MainButton';
import * as Component from '../Summary/CardsSectionStyles';
import SharedPortfolioService from "../../../services/SharedPortfoliosService";
import Auth from '../../../Auth/Auth';
import { useHistory } from 'react-router-dom';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { Callout, DefaultButton, DirectionalHint, INavLink, INavLinkGroup, INavStyles, Nav, Separator, TeachingBubble } from '@fluentui/react';
import { ModalEndSharePortfolio } from '../SharedPortfolios/ModalEndSharePortfolio';
import SharePortolioButton from '../../../components/Elements/SharePortolioButton';

type Props =
{
    reporting: any,
    finametrixPortfolio: {amount: number, cost: number},
    finametrixContracts: string[],
    global: string,
    period: string,
    ytd: string,
    year: string,
    isElementsSubscribed: boolean | undefined,
    theme: any
}
export const CardButton = styled(Button.Primary)`
    margin-top: 3.3em;
    margin-bottom: 0px;
`;
const navStyles: Partial<INavStyles> =
{
    root:
    {
        boxSizing: 'border-box',
        border: '1px solid #eee',
        overflowY: 'auto'
    },
    link:
    {
        whiteSpace: 'normal',
        lineHeight: 'inherit',
    },
};
const CardsSection: React.FC<Props> = (props: Props) =>
{
    const theme = useContext(ThemeContext);
    let activePlan = Auth.getActivePlan();
    const history = useHistory();
    const [sending, setSending] = useState(false);
    const [isNameModalOpen, { setTrue: showNameModal, setFalse: hideNameModal }] = useBoolean(false);
    const [isEndSharePortfolioModalOpen, { setTrue: showEndSharePortfolioModal, setFalse: hideEndSharePortfolioModal }] = useBoolean(false);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    const labelId = useId('callout-label');
    const valorationTooltip = useId('valoration');
    const descriptionId = useId('callout-description');
    const totalAmount = typeof props.reporting.totalAmount != 'undefined' ? props.reporting.totalAmount : '';
    const [teachingBubbleVisible, setTeachingBubbleVisible] = useState(false);

    const handleBubbleChange = () =>
    {
        setTeachingBubbleVisible(false);
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [mQuery, setMQuery] = React.useState<any>(
    {
        matches: window.innerWidth <= 1366 ? true : false,
    });
    useEffect(() =>
    {
        let mediaQuery = window.matchMedia("(min-width: 1366px)");
        mediaQuery.addEventListener("change", setMQuery);

        return () => mediaQuery.removeEventListener("change", setMQuery);
    }, []);
    const endSharePortfolio = () =>
    {
        setSending(true);
        SharedPortfolioService.patchSharedPortfolio(activePlan.portfolio.sharedPortfolio.id, {status: 3}).then(() =>
        {
            Auth.setActivePlan(
            {
                ...activePlan,
                portfolio:
                {
                    ...activePlan.portfolio,
                    sharedPortfolio: null
                }
            });
            activePlan = Auth.getActivePlan();
            setSending(false);
            hideEndSharePortfolioModal();
        },
        (error: any) =>
        {
            //setError(error);
            setSending(false);
            hideEndSharePortfolioModal();
        });
    }
    const copySharedPortfolioLink = () =>
    {
        navigator.clipboard.writeText(`${document.location.protocol}/${document.location.host}/sharedPortfolios/${activePlan.portfolio?.sharedPortfolio.id}`)
    };
    const navLinkGroups: INavLinkGroup[] = [
    {
        links: [
        {
            key: 'stop_share_portfolio',
            name: 'Dejar de compartir Cartera',
            url: '',
            action: showEndSharePortfolioModal
        },
        {
            key: 'copy-link',
            name: 'Copiar enlace',
            url: '',
            action: copySharedPortfolioLink
        },
        {
            key: 'change-name',
            name: 'Cambiar seudónimo',
            url: '',
            action: showNameModal
        }]
    }];
    const contractTitle = !props.finametrixContracts || props.finametrixContracts.length < 1 ? "Contrato" : "Contratos";

    return(
        <Component.DivContainerRow className="ms-Grid-row rowHeight">
            <Component.DivContainerCol className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8 ms-xxl8">
                <Component.DivPrincipalRow className="ms-Grid-row rowHeight">
                    <Component.DivRowContract key="contract" className="ms-Grid-col ms-sm4 ms-md4 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2 colMargin">
                        <Component.ContainerCard theme={theme}>
                            <Component.TitleCard>
                                <Component.TitleText theme={theme}>{contractTitle}</Component.TitleText>
                            </Component.TitleCard>
                            {props.finametrixContracts && props.finametrixContracts.length > 0 &&
                            (
                                <Component.DataCardMultipleContract>
                                    {props.finametrixContracts.map((contract, index) =>
                                    {
                                        if(index < 2)
                                        {
                                            return <Component.DataTextMultipleContract key={index}>{contract}</Component.DataTextMultipleContract>;
                                        }
                                        else if(index === 2)
                                        {
                                            return <Component.DataTextMultipleContract key={index}>...</Component.DataTextMultipleContract>;
                                        }
                                        else
                                        {
                                            return <React.Fragment key={index}></React.Fragment>;
                                        }
                                    })}
                                </Component.DataCardMultipleContract>
                            )}
                        </Component.ContainerCard>
                    </Component.DivRowContract>
                    <Component.DivRowPosition key="position" className="ms-Grid-col ms-sm4 ms-md4 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2 colMargin">
                        <Component.ContainerCard theme={theme}>
                            <Component.TitleCard>
                                <Component.TitleText theme={theme}>Valoración</Component.TitleText>
                            </Component.TitleCard>
                            {props.theme === "light" &&
                            (
                                <Component.InformationIcon className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2">
                                    <img src="/icons/informationBlack.svg" alt="Información" style={{marginTop: "4px"}} />
                                </Component.InformationIcon>
                            )}
                            {props.theme === "dark" &&
                            (
                                <Component.InformationIcon className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2">
                                    <img src="/icons/informationWhite.svg" alt="Información" style={{marginTop: "4px"}} />
                                </Component.InformationIcon>
                            )}
                            <Component.DataCardValoration id={valorationTooltip} onMouseEnter={() => setTeachingBubbleVisible(true)}>
                                <NumberFormat style={{cursor: "default"}} value={totalAmount} displayType="text" thousandSeparator="." decimalSeparator="," decimalScale={2} suffix={"€"}>
                                </NumberFormat>
                            </Component.DataCardValoration>
                        </Component.ContainerCard>
                        {teachingBubbleVisible === true &&
                        (
                            <TeachingBubble target={`#${valorationTooltip}`} hasCloseButton={true} hasSmallHeadline={false} onDismiss={handleBubbleChange} closeButtonAriaLabel="Cerrar" 
                                calloutProps={{directionalHint: DirectionalHint.bottomLeftEdge, calloutWidth: 290}}
                                styles={{content: {maxWidth: "290px"}, bodyContent: {padding: "36px 0 2px 14px", cursor: "default"}, body: {marginBottom: "0 !important", cursor: "default"}}}
                            >
                                <Component.TableBubble>
                                    <tbody>
                                        <Component.TRTableBubble>
                                            <Component.THTableBubble>
                                                Aportaciones: 
                                            </Component.THTableBubble>
                                            <Component.TDTableBubble>
                                                +<NumberFormat value={props.reporting.summary[0].amountFrom + props.reporting.summary[0].totalNetIn} displayType="text" thousandSeparator="." decimalSeparator="," decimalScale={2} suffix={"€"} />
                                            </Component.TDTableBubble>
                                        </Component.TRTableBubble>
                                        <Component.TRTableBubble>
                                            <Component.THTableBubble>
                                                Retiradas: 
                                            </Component.THTableBubble>
                                            <Component.TDTableBubble>
                                                -<NumberFormat value={props.reporting.summary[0].totalNetOut} displayType="text" thousandSeparator="." decimalSeparator="," decimalScale={2} suffix={"€"} />
                                            </Component.TDTableBubble>
                                        </Component.TRTableBubble>
                                        <Component.TRTableBubble>
                                            <Component.THTableBubble>
                                                Retenciones: 
                                            </Component.THTableBubble>
                                            <Component.TDTableBubble>
                                                -<NumberFormat value={props.reporting.summary[0].totalRetention} displayType="text" thousandSeparator="." decimalSeparator="," decimalScale={2} suffix={"€"} />
                                            </Component.TDTableBubble>
                                        </Component.TRTableBubble>
                                        <Component.TRTableBubble>
                                            <Component.THTableBubble>
                                                Valoración: 
                                            </Component.THTableBubble>
                                            <Component.TDTableBubble>
                                                +<NumberFormat value={totalAmount} displayType="text" thousandSeparator="." decimalSeparator="," decimalScale={2} suffix={"€"} />
                                            </Component.TDTableBubble>
                                        </Component.TRTableBubble>
                                        <Component.TRTableBubble>
                                            <Component.THTableBubble>
                                                Resultado: 
                                            </Component.THTableBubble>
                                            <Component.TDTableBubble>
                                                <NumberFormat value={props.reporting.summary[0].plusMin} displayType="text" thousandSeparator="." decimalSeparator="," decimalScale={2} suffix={"€"} />
                                            </Component.TDTableBubble>
                                        </Component.TRTableBubble>
                                    </tbody>
                                </Component.TableBubble>
                            </TeachingBubble>
                        )}
                    </Component.DivRowPosition>
                    <Component.DivRowRate key="rate" className="ms-Grid-col ms-sm4 ms-md4 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2 colMargin">
                        <Component.ContainerCard theme={theme}>
                            <Component.TitleCard>
                                <Component.TitleText theme={theme}>Desde inicio</Component.TitleText>
                            </Component.TitleCard>
                            <Component.DataCard>
                                <Component.NumberFormatDiv>
                                    {props.global < "0,00" && <DecrementIcon />}
                                    {props.global > "0,00" && <IncrementIcon />}
                                </Component.NumberFormatDiv>
                                <Component.DataText>{props.global}</Component.DataText>
                            </Component.DataCard>
                        </Component.ContainerCard>
                    </Component.DivRowRate>
                    <Component.DivRowPeriod key="period" className="ms-Grid-col ms-sm4 ms-md4 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2 colMargin">
                        <Component.ContainerCard theme={theme}>
                            <Component.TitleCard>
                                <Component.TitleText theme={theme}>Periodo</Component.TitleText>
                            </Component.TitleCard>
                            <Component.DataCard>
                                <Component.NumberFormatDiv>
                                    {props.period < "0,00" && <DecrementIcon />}
                                    {props.period > "0,00" && <IncrementIcon />}
                                </Component.NumberFormatDiv>
                                <Component.DataText>{props.period}</Component.DataText>
                            </Component.DataCard>
                        </Component.ContainerCard>
                    </Component.DivRowPeriod>
                    <Component.DivRowYTD key="ytd" className="ms-Grid-col ms-sm4 ms-md4 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2 colMargin">
                        <Component.ContainerCard theme={theme}>
                            <Component.TitleCard>
                                <Component.TitleText theme={theme}>YTD</Component.TitleText>
                            </Component.TitleCard>
                            <Component.DataCard>
                                <Component.NumberFormatDiv>
                                    {props.ytd < "0,00" && <DecrementIcon />}
                                    {props.ytd > "0,00" && <IncrementIcon />}
                                </Component.NumberFormatDiv>
                                <Component.DataText>{props.ytd}</Component.DataText>
                            </Component.DataCard>
                        </Component.ContainerCard>
                    </Component.DivRowYTD>
                    <Component.DivRowYear key="year" className="ms-Grid-col ms-sm4 ms-md4 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2 colMargin">
                        <Component.ContainerCard theme={theme}>
                            <Component.TitleCard>
                                <Component.TitleText theme={theme}>Año</Component.TitleText>
                            </Component.TitleCard>
                            <Component.DataCard>
                                <Component.NumberFormatDiv>
                                    {props.year < "0,00" && <DecrementIcon />}
                                    {props.year > "0,00" && <IncrementIcon />}
                                </Component.NumberFormatDiv>
                                <Component.DataText>{props.year}</Component.DataText>
                            </Component.DataCard>
                        </Component.ContainerCard>
                    </Component.DivRowYear>
                </Component.DivPrincipalRow>
            </Component.DivContainerCol>
            <Component.DivColEmpty className="ms-Grid-col ms-hiddenLgDown ms-xl1 ms-xxl1" />
            <Component.DivColSharedPortfolio className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 ms-xl3 ms-xxl3 colMargin">
                {props.isElementsSubscribed && activePlan.type !== 0 && activePlan.type !== 1 &&
                (
                    <React.Fragment>
                        {activePlan.portfolio?.sharedPortfolio?.status !== undefined && activePlan.portfolio?.sharedPortfolio?.status === 2 && (
                            <React.Fragment>
                                <Component.ContainerCard theme={theme} id="shared-portfolio">
                                    <Component.TitleCard>
                                        <Component.TitleText theme={theme}>CARTERA COMPARTIDA</Component.TitleText>
                                        {activePlan.portfolio.sharedPortfolio.name}
                                    </Component.TitleCard>
                                    <Separator className="separator" />
                                    <div className="content" style={{ display: "inline-block"}}>
                                        <span>Nº Visitas:</span>
                                        <span className="value">{activePlan.portfolio.sharedPortfolio.sharedTimes}</span>
                                        <span>Posición:</span>
                                        <span className="value">
                                            {activePlan.portfolio.sharedPortfolio.sharedPortfolioDailyStats !== null &&
                                                activePlan.portfolio.sharedPortfolio.sharedPortfolioDailyStats.length > 0
                                                ? activePlan.portfolio.sharedPortfolio.sharedPortfolioDailyStats[0].profitabilityPosition
                                                : "-"}
                                            /{activePlan.portfolio.sharedPortfolio.sharedPortfoliosCount}
                                        </span>
                                    </div>
                                    <DefaultButton className="button" id={buttonId} onClick={toggleIsCalloutVisible}>
                                        <i className="ironia-icon more-options-vertical" />
                                    </DefaultButton>
                                    {isCalloutVisible ?
                                    (
                                        <Callout className="shared-portfolio-actions"
                                            ariaLabelledBy={labelId}
                                            ariaDescribedBy={descriptionId}
                                            role="alertdialog"
                                            target={`#${buttonId}`}
                                            onDismiss={toggleIsCalloutVisible}
                                            setInitialFocus
                                            gapSpace={-10}
                                            calloutWidth={312}
                                            directionalHint={DirectionalHint.bottomRightEdge}
                                        >
                                            <Nav ariaLabel="Menú de cartera compartida" styles={navStyles} groups={navLinkGroups}
                                                onLinkClick={(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) =>
                                                {
                                                    if(ev)
                                                    {
                                                        ev.nativeEvent.preventDefault();
                                                    }
                                                    if(item)
                                                    {
                                                        if(item.action !== undefined)
                                                        {
                                                            item.action();
                                                        }
                                                        else
                                                        {
                                                            history.push(item.url);
                                                        }
                                                        toggleIsCalloutVisible();
                                                    }
                                                }}
                                                selectedKey={history.location.pathname}
                                            />
                                        </Callout>
                                    ) : null}
                                </Component.ContainerCard>
                            </React.Fragment>
                        )}
                        <SharePortolioButton sending={sending}
                            setSending={setSending}
                            text="Compartir cartera"
                            isModalOpen={isNameModalOpen}
                            showModal={showNameModal}
                            hideModal={hideNameModal}
                        />
                        {activePlan.portfolio?.sharedPortfolio?.status !== undefined && activePlan.portfolio?.sharedPortfolio?.status === 2 &&
                        (
                            <ModalEndSharePortfolio isModalOpen={isEndSharePortfolioModalOpen}
                                hideModal={hideEndSharePortfolioModal}
                                _onSubmit={endSharePortfolio}
                                theme={theme}
                            />
                        )}
                    </React.Fragment>
                )}
            </Component.DivColSharedPortfolio>
        </Component.DivContainerRow>
    );
}

export default CardsSection;
import React from 'react';

export const themes =
{
    light:
    {
        name: 'light',
        body: '#FAFCFD',
        themePrimary: '#CC214F',
        shadowedPrimary: "#F1BAC9",
        background: '#FFFFFF',
        backgroundSearch: '#FFFFFF',
        darkBackground: '#0F2426',
        black: '#2A4143',
        darkBlack: '#17311B',
        lightBlack: '#556769',
        white: '#FFFFFF',
        themeLighterAlt: '#FDF4F7',
        themeLighter: '#F7D6DF',
        themeLight: '#F0B3C3',
        themeTertiary: '#E06F8D',
        themeSecondary: '#D23760',
        themeDarkAlt: '#B81D47',
        themeDark: '#9B193C',
        themeDarker: '#72122C',
        neutralLighterAlt: '#0B0B0B',
        neutralLighter: '#E9EFF1', // fondo hover botones
        neutralLight: '#CC214F',
        neutralQuaternaryAlt: '#CC214F',
        neutralQuaternary: '#D64D73',
        neutralTertiaryAlt: '#595959',
        neutralTertiary: '#C8C8C8',
        neutralSecondary: '#D0D0D0', // border searchBox
        neutralPrimaryAlt: '#DADADA',
        neutralPrimary: '#2A4143', // color texto
        neutralDark: '#CC214F',
        neutralHeader: 'rgba(42, 65, 67, 0.2)',
        shadow: 'rgba(8, 84, 117, 0.08)',
        borderPrimary: '#CC214F',
        borderSecondary: '#F3F7F9',
        borderTertiary: '#FFFFFF',
        tertiary: '#F3F7F9',
        borderQuaternary: 'rgba(204,33,79,0.3)',
        quaternary: 'rgba(204,33,79,0.2)',
        quinary: 'rgba(255,255,255,0.1)',
        senary: '#FFFFFF',
        septary: 'rgba(255, 255, 255, 0.3)',
        octary: 'rgba(255, 255, 255, 0.3)',
        nonary: '#FFFFFF',
        neutralBorder: '#FFFFFF',
        lightBorder: '#D9E6FA',
        highlightedAreaBg: '#D9E6FA',
        highlightedAreaMain: 'rgba(66, 132, 230, 0.2)',
        highlightedAreaMainText: '#4369A1',
        middleBorder: '#93AEB9',
        darkBorder: '#4284E6',
        lighterBorder: '#FFF1BE',
        lighter: '#FFBD70',
        neutralText: '#95A0A1',
        linearGradient: 'linear-gradient(90deg, #162B2C 1.01%, rgba(22, 43, 44, 0.24) 156.42%);',
        linearGradientSmall: 'linear-gradient(90deg, #162B2C -4.94%, rgba(22, 43, 44, 0.24) 444.67%);',
        dust: '#FFFFFF',
        shadowBackground: '#FFFFFF',
        grey: '#F3F7F9',
        greySelected: '#F37F9',
        red: '#F5160A',
        correctPrimary: "#4C9C6C",
        correctSecondary: "#BEFBD6",
        warn: "#F69A2D",
        warnSecondary: "#FFF1BE",
        alert: "#ED4949",
        alertSecondary: "#FFCBCB",
        inputBackground: "#FAFCFD",
        inputBorder: "#E9EFF1",
        inputColor: "#BFC6C7",
        popupBackground: "#FFFFFF",
        greyText: "#556769",
        greyBackground: "#F3F7F9",
        selected: "#2A4143",
        outdated: "rgb(179,179,184)",
        allWhite: "#FFFFFF",
        allBlack: '#2A4143',
        greyMorePlans: "#F4F8FA",
        greyMorePlansSelected: "#F4F8FA",
        infoElementCards: "#FFF",
        infoElementCardsText: "#000",
        contractElementPanel: "#F3F7F9",
        paragraphSelectUnderline: "blue",
        paragraphSelectUnderlineHover: "#00BCD4",
        bubbleMapConfiguration: "lightblue",
        fundLinks: "#4284E6",
        cardFooter: "white",
        cardFooterColor: "grey",
        blueWhite: "#6faac9"
    },
    dark:
    {
        name: 'dark',
        body: '#0F2426',
        background: 'rgba(255, 255, 255, 0.04)',
        backgroundSearch: '#24282F',
        darkBackground: 'rgba(255, 255, 255, 0.04)',
        themePrimary: '#CC214F',
        shadowedPrimary: "#F1BAC9",
        themeLighterAlt: '#080103',
        themeLighter: '#21050D',
        themeLight: '#3D0A18',
        themeTertiary: '#7A142F',
        themeSecondary: '#B41D45',
        themeDarkAlt: '#D1335D',
        themeDark: '#D84E73',
        themeDarker: '#E27894',
        neutralLighterAlt: '#30484A',
        neutralLighter: '#364F52',
        neutralLight: '#405B5E',
        neutralQuaternaryAlt: '#476265',
        neutralQuaternary: '#4C686B',
        neutralTertiaryAlt: '#648284',
        neutralTertiary: '#FCFDFD',
        neutralSecondary: '#FCFFE',
        neutralPrimaryAlt: '#FDFDFE',
        neutralPrimary: '#FAFCFD',
        neutralDark: '#FEFEFE',
        neutralHeader: 'rgb(42, 65, 67)',
        black: '#FEFFFF',
        darkBlack: '#FEFFFF',
        lightBlack: '#556769',
        white: '#24282F',
        shadow: 'rgba(108, 184, 217, 0.08)',
        borderPrimary: '#B31540',
        borderSecondary: 'rgba(255,255,255,0.1)',
        borderTertiary: '#CC214F',
        tertiary: 'rgba(2,2,2,0.75)',
        borderQuaternary: 'rgba(255, 255, 255, 0.3)',
        quaternary: 'rgba(204,33,79,0.2)',
        quinary: 'rgba(15, 36, 38, 0.15)',
        senary: '#CC214F',
        septary: '#FFFFFF',
        octary: 'rgba(8, 84, 117, 0.1)',
        nonary: '#F3F7F9',
        neutralBorder: 'rgba(255, 255, 255, 0.3)',
        lightBorder: '#D9E6FA',
        highlightedAreaBg: 'rgb(42, 65, 67)',
        highlightedAreaMain: 'rgba(66, 132, 230, 0.2)',
        highlightedAreaMainText: '#D9E6FA',
        middleBorder: '#93AEB9',
        darkBorder: '#4284E6',
        lighterBorder: '#FFF1BE',
        lighter: '#FFBD70',
        neutralText: 'rgba(255,255,255,0.5)',
        linearGradient: 'linear-gradient(90deg, #FFFFFF 33.85%, rgba(255, 255, 255, 0.86) 60.74%, rgba(255, 255, 255, 0.65) 83.79%)',
        linearGradientSmall: 'linear-gradient(90deg, #FFFFFF -4.94%, rgba(255, 255, 244, 0.86) 444.67%);',
        dust: 'rgba(15, 36, 38, 0.95)',
        shadowBackground: 'rgba(255,255,255,0.05)',
        grey: 'rgba(243,247,249,0.1)',
        greySelected: '#2A4143',
        red: 'rgba(245,22,10,0.96)',
        correctPrimary: "#4C9C6C",
        correctSecondary: "#BEFBD6",
        warn: "#F69A2D",
        warnSecondary: "#FFF1BE",
        alert: "#ED4949",
        alertSecondary: "#FFCBCB",
        inputBackground: "rgba(15, 36, 38, 0.95)",
        inputBorder: "#E9EFF1",
        inputColor: "rgba(255, 255, 255, 0.3)",
        popupBackground: "#2A4143",
        greyText: "rgba(255, 255, 255, 0.8)",
        greyBackground: "rgba(243,247,249,0.1)",
        selected: "#F5FCFE",
        outdated: "#B9B58F7D",
        allWhite: "#FFFFFF",
        allBlack: '#2A4143',
        greyMorePlans: "#0F2426",
        greyMorePlansSelected: "#00000085",
        infoElementCards: "#000",
        infoElementCardsText: "#FFF",
        contractElementPanel: "#131313",
        paragraphSelectUnderline: "#00E4FF",
        paragraphSelectUnderlineHover: "#EA2454",
        bubbleMapConfiguration: "#194BAA",
        fundLinks: "#4284E6",
        cardFooter: "#CC214F",
        cardFooterColor: "white"
    }
};

export const ThemeContext = React.createContext(themes.dark);
import { useContext, useEffect, useRef, useState } from 'react';
import { Stack, Spinner, SpinnerSize, IChoiceGroupOption, ChoiceGroup, TextField } from '@fluentui/react';
import Auth from "../../Auth/Auth";
import { Redirect, RouteComponentProps, useHistory, useLocation } from 'react-router';
import { ThemeContext } from '@fluentui/react-theme-provider';
import { useForm, Controller } from 'react-hook-form';
import * as Lay from './styles/RefundsStyles';
import { Link } from 'react-router-dom';
import { CaretHollowMirroredIcon } from '@fluentui/react-icons-mdl2';
import fundService, { RefundEstimationDates } from '../../services/FundService';
import FundService from '../../services/FundService';
import { InvestmentFundFinder } from '../PurchaseTransferScreen/components/InvestmentFundFinder';
import FinametrixService from '../../services/FinametrixService';
import InputRefundsButton from "./components/InputRefundsButton";
import OrderService from '../../services/OrderService';
import UserIdentitiesService from '../../services/UserIdentitiesService';
import DocumentTitle from 'react-document-title';
import React from 'react';
import './styles/Refunds.css';
import { RefundTimeLine } from './components/RefundTimeLine';
import users from '../../services/UserService';
import { AccountsAutorized } from '../../services/SubscriptionService';
import DropdownAccounts from './components/DropdownAccounts';

const refundsOptions: IChoiceGroupOption[] = [
    {key: "0", text: 'Por Importe', value: 'amount'},
    {key: "1", text: 'Por Participaciones', value: 'titles'},
    {key: "2", text: 'Total', value: 'total'},
];

interface RouteParams {};
interface Props extends RouteComponentProps<RouteParams> {};
export interface Accounts
{
    id: number,
    value: string,
    default: boolean
}

export const Refunds: React.FunctionComponent<Props> = () =>
{
    const theme = useContext(ThemeContext);
    const {state} = useLocation<any>();
    const history = useHistory();
    const {handleSubmit, watch, formState: { errors, touchedFields }, control, getValues, setValue} = useForm();
    const watchAmount = watch("amount", "");
    const watchAmountType = watch("amountType", "");
    const watchRefundKey = watch("refundKey", "");
    const [sending, setSending] = useState(false);
    const [portfolioFundsLoaded, setPortfolioFundsLoaded] = useState(false);
    const [portfolioFunds, setPortfolioFunds] = useState<any[]>([]);
    const [loadListeners, setLoadListeners] = useState<any[]>([]);
    const [refundCode, setRefundCode] = useState<any>("");
    const [refundIBAN, setRefundIBAN] = useState<string>("");
    const [accounts, setAccounts] = useState<Accounts[]>([]);
    const [error, setErrorMessage] = useState<string>("");
    const [errorKey, setErrorKey] = useState<string>("");
    const [firstTime, setFirstTime] = useState<boolean>(true);
    const [simulation, setSimulation] = useState<boolean>(false);
    const [simulationAvailable, setSimulationAvailable] = useState<boolean>(false);
    const [hasChangedIsin, setHasChangedIsin] = useState<boolean>(false);
    const [refundDates, setRefundDates] = useState<RefundEstimationDates>({finalProceso: "",ventaLiquidacion: "",ventaValor: ""});
    //const patternIBAN = "(([E+S]|[e+s]){2})\t*(\\d{2})\\s*\t*(\\d{4})\\s*\t*(\\d{4})\\s*\t*(\\d{2})\\s*\t*(\\d{10})";
    const mounted = useRef(false);
    const user = Auth.getUserProfile();
    let activePlan = Auth.getActivePlan();
    const outdated = Auth.isLastPlanOutdated();
    const cancelled = Auth.isActivePlanCancelled();
    const values = getValues();
    let defaultOrigin: any = state?.origin;
    let defaultTarget: any = state?.target;
    let title = parseFloat(state?.origin?.titles.replace(".", "").replace(",", "."));
    let importe = parseFloat(state?.origin?.bought.replace("€", "").replace(".", "").replace(",", ".").trim());

    function handleChange(refund?: string)
    {
        setRefundCode(refund);
    }
    if(values.sourceFund)
    {
        defaultOrigin = values.sourceFund;
    }
    if(values.targetFund)
    {
        defaultTarget = values.targetFund;
    }
    const checkRefundCode = async (usrId: any) =>
    {
        const refund = await UserIdentitiesService.isRefundCodeDefined(user.mainUserIdentity.id).catch((error: any) => {});

        if(!!refund === refund)
        {
            setFirstTime(false);
            if(refund === true)
            {
                setRefundCode("claveReembolso");
            }
            else
            {
                setRefundCode("");
            }
        }
    };
    const checkDefaultIBAN = async (userId: any) =>
    {
        if(userId > 0)
        {
            users.getBankAccountsAuthorizedBySavingPlanId(userId, activePlan.id).then((response: AccountsAutorized[]) =>
            {
                if(response !== null && response.length > 0)
                {
                    let accounts: Accounts[] = [];
                    
                    for(let i=0; i<response.length; i++)
                    {
                        accounts.push(
                        {
                            id: response[i].id as number,
                            value: response[i].accountOrigin,
                            default: response[i].isSelected
                        });

                        if(response[i].isSelected === true)
                        {
                            setRefundIBAN(response[i].accountOrigin);
                        }
                    }
                    setAccounts(accounts);
                }
            });
        }
    }
    const onChangeError = React.useCallback((value?: string) =>
    {
        let newVal = value === undefined ? "" : value;
        setErrorMessage(newVal);
    },[]);
    const onChangeIBANValue = React.useCallback((newValue?: string) =>
    {
        setRefundIBAN(newValue as string);
        // if(newValue !== undefined)
        // {
        //     newValue = newValue.replaceAll(/\s/g, '');
        // }
        // if(newValue !== undefined && newValue.length === 24)
        // {
        //     let newReg = new RegExp(patternIBAN);
        //     if(newValue?.match(newReg))
        //     {
        //         setRefundIBAN(newValue);
        //         onChangeError("");
        //     }
        //     else
        //     {
        //         onChangeError("Introduzca un IBAN válido");
        //     }
        // }
        // else if(newValue !== undefined && newValue.length > 24)
        // {
        //     setRefundIBAN(newValue);
        //     onChangeError("El IBAN debe contener 24 dígitos como máximo, con 2 letras al comienzo y 22 números al final.");
        // }
        // else
        // {
        //     setRefundIBAN(newValue || "");
        //     onChangeError("");
        // }
    }, []);
    useEffect(() =>
    {
        if(firstTime)
        {
            checkRefundCode(user.id);
            checkDefaultIBAN(user.id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onPortfolioLoaded = (cb: any) =>
    {
        if(portfolioFundsLoaded)
        {
            if(typeof cb === 'function')
            {
                cb.call(this, portfolioFunds);
            }
        }
        else
        {
            setLoadListeners(prev => [...prev, cb]);
        }
    };
    const callAllPortfolioLoadListeners = () =>
    {
        loadListeners.forEach(cb =>
        {
            if(typeof cb === 'function')
            {
                cb.call(this, portfolioFunds);
            }
        });
    };
    const loadPortfolioFunds = async () =>
    {
        const finametrixPortfolio = await FinametrixService.getPortfolio(activePlan.portfolio.finametrixId).catch(error => {});

        if(typeof finametrixPortfolio?.creationDate !== 'string')
        {
            return;
        }

        const fromDate = finametrixPortfolio?.creationDate?.substring(0, 10).replace(/-/g, "");
        const date_to = (function (this: any)
        {
            this.setDate(this.getDate() - 1);
            return this
        }).call(new Date());

        const toDate = date_to.getFullYear().toString() + ("0" + (date_to.getMonth() + 1)).slice(-2) + ("0" + date_to.getDate()).slice(-2);

        const reporting = await FinametrixService.getReporting(activePlan.portfolio.finametrixId, fromDate, toDate).catch(error => { });
        if(!Array.isArray(reporting?.positions?.positionsTo?.positions) || reporting.positions.positionsTo.positions.length <= 0)
        {
            return;
        }

        let loadedISINs: any[] = [];
        for(let i = 0; i < reporting.positions.positionsTo.positions.length; i++)
        {
            let position = reporting.positions.positionsTo.positions[i];
            let result = await FundService.getFund(position.isin);

            if(!loadedISINs.includes(result?.isin))
            {
                loadedISINs.push(result.isin);
                setPortfolioFunds(prev => [...prev, result]);
            }
        }
    };
    useEffect(() =>
    {
        if(portfolioFundsLoaded)
        {
            callAllPortfolioLoadListeners();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() =>
    {
        const values = getValues();
        if(defaultOrigin && defaultOrigin !== values.sourceFund)
        {
            setValue("sourceFund", defaultOrigin);
        }

        if(defaultTarget && defaultTarget !== values.targetFund)
        {
            setValue("targetFund", defaultTarget);
        }

        loadPortfolioFunds().finally(() =>
        {
            setTimeout(() => setPortfolioFundsLoaded(true));
        });

        mounted.current = true;

        return () =>
        {
            mounted.current = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() =>
    {
        const values = getValues();

        if(defaultOrigin && defaultOrigin !== values.sourceFund)
        {
            setValue("sourceFund", defaultOrigin);
        }

        mounted.current = true;
        return () =>
        {
            mounted.current = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() =>
    {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() =>
    {
        if(watch("sourceFund") !== undefined)
        {
            setSimulationAvailable(true);
        }
        else
        {
            setSimulationAvailable(false);
        }
    },[simulationAvailable, watch, hasChangedIsin]);
    const handleSimulation = () =>
    {
        const fundOrigen = watch("sourceFund");

        if(fundOrigen !== undefined)
        {
            fundService.getRefundEstimationDates(fundOrigen.isin).then((response: any) =>
            {
                if(response !== null && response !== undefined)
                {
                    setRefundDates(
                    {
                        ventaValor: response.ventaValor,
                        ventaLiquidacion: response.ventaLiquidacion,
                        finalProceso: response.finalProceso
                    });
                }
            });
            setSimulation(true);
        }
        else
        {
            setSimulation(false);
        }
    }
    const onSubmit = (data: any) =>
    {
        setSimulation(false);
        setErrorKey("");

        if(refundIBAN.length !== 24)
        {
            onChangeError("El IBAN debe contener 24 dígitos, con 2 letras al comienzo y 22 números al final.");
            return;
        }

        if(data.amountType === "total" && typeof data.amount !== "undefined")
        {
            delete data.amount;
        }

        if(data.amountType === "titles" && title < parseFloat(data.amount))
        {
            data.amount = title;
        }
        let toSend: any =
        {
            UserIdentityId: user.mainUserIdentity.id,
            FnmPortfolioId: activePlan.portfolio.id,
            OrderType: 1,
            Status: 0,
            Values:
            {
                RefundCode: data.refundKey,
                FundName: data.sourceFund?.name,
                FundIsin: data.sourceFund?.isin.trim(),
                Fund: data.sourceFund?.instrumentId,
                AmountType: data.amountType,
                Amount: data.amount !== undefined ? parseFloat(data.amount) : 0,
                Type: data.amountType,
                Iban: refundIBAN
            }
        };

        setSending(true);
        OrderService.createOrder(toSend).then((response: any) =>
        {
            if(response === 403)
            {
                setErrorKey("Clave de Reembolso inválida. Si necesitas resetear la Clave de Reembolso, envía un email a info@ironia.tech indicando tu dirección de email y usuario de IronIA indicando que deseas resetearla. En un plazo mínimo de 48h nos pondremos en contacto contigo.")
            }
            if(response.id)
            {
                history.push("/portfolio/refunds/signature",
                {
                    signature: response.signature,
                    refund: response
                });
            }
        })
        .catch().finally(() =>
        {
            if(mounted.current)
            {
                setSending(false);
            }
        });
    };

    if(outdated === true || cancelled === true)
    {
        return (<Redirect to="/savingsPlans" />);
    }

    if(firstTime === true && (refundCode === "" || refundCode === undefined))
    {
        return(
            <DocumentTitle title='IronIA - Reembolsos'>
                <form className="external-transfer-form" id="refunds-form" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Lay.Main theme={theme}>
                        <Lay.BackButtonContainer>
                            <span>
                                <CaretHollowMirroredIcon />
                                <Link to='/portfolio'>
                                    Volver atrás
                                </Link>
                            </span>
                        </Lay.BackButtonContainer>
                        {activePlan !== undefined &&
                            <div className="ms-Grid-row" style={{minHeight: "20px"}}>
                                <div className="ms-Grid-col">
                                    <Lay.TitleContainer>
                                        <h2>Reembolsos</h2>
                                    </Lay.TitleContainer>
                                </div>
                                <div className="ms-Grid-col" style={{paddingTop: "12px", color: "#CC214F"}}>
                                <Lay.SubTitleContainer> {activePlan.name}</Lay.SubTitleContainer>
                                </div>
                            </div>
                        }
                        {activePlan === undefined &&
                            <Lay.TitleContainer>
                                <h2>Reembolsos</h2>
                            </Lay.TitleContainer>
                        }
                        <Lay.SeparatorTop />
                        <Spinner size={SpinnerSize.medium} className="button-spinner" />
                    </Lay.Main>
                </form>
            </DocumentTitle>
        );
    }
    else if(refundCode === "" || refundCode === undefined)
    {
        return(
            <DocumentTitle title='IronIA - Reembolsos'>
                <form className="external-transfer-form" id="refunds-form" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Lay.Main theme={theme}>
                        <Lay.BackButtonContainer>
                            <span>
                                <CaretHollowMirroredIcon />
                                <Link to='/portfolio'>
                                    Volver atrás
                                </Link>
                            </span>
                        </Lay.BackButtonContainer>
                        {activePlan !== undefined &&
                        (
                            <div className="ms-Grid-row" style={{minHeight: "20px"}}>
                                <div className="ms-Grid-col">
                                    <Lay.TitleContainer>
                                        <h2>Reembolsos</h2>
                                    </Lay.TitleContainer>
                                </div>
                                <div className="ms-Grid-col" style={{paddingTop: "12px", color: "#CC214F"}}>
                                    <Lay.SubTitleContainer> {activePlan.name}</Lay.SubTitleContainer>
                                </div>
                            </div>
                        )}
                        {activePlan === undefined &&
                        (
                            <Lay.TitleContainer>
                                <h2>Reembolsos</h2>
                            </Lay.TitleContainer>
                        )}
                        <Lay.SeparatorTop />
                        <div className="ms-Grid restarted-ms-Grid">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-xl6">
                                    <Lay.CardContainer theme={theme}>
                                        <Lay.CardHeader>
                                            <Lay.CardHeadingContainer>
                                                <h3>Datos para el Reembolso</h3>
                                            </Lay.CardHeadingContainer>
                                        </Lay.CardHeader>
                                        <Lay.CardBody>
                                            <Stack>
                                                <Stack.Item>
                                                    <Controller name="sourceFund" control={control} rules={{required: true}} render={({field, field: { onChange }}) =>
                                                        <InvestmentFundFinder {...field} required labelISIN="ISIN de origen" labelName="Nombre del fondo de origen"
                                                            placeholderISIN="Escribe aquí el ISIN de origen..." placeholderName="Escribe aquí el nombre del fondo de origen..."
                                                            default={defaultOrigin}
                                                            loadData={(filter: any) =>
                                                            {
                                                                return new Promise<any[]>((resolve) =>
                                                                {
                                                                    function load(portfolioFunds: any[])
                                                                    {
                                                                        setTimeout(() =>
                                                                        {
                                                                            if(Array.isArray(portfolioFunds) && portfolioFunds.length <= 0)
                                                                            {
                                                                                fundService.getFunds(0, 5, filter).then(data => resolve(data.results?.slice(0, 20))).catch(err => resolve([]));
                                                                            }
                                                                            if(typeof filter === 'string')
                                                                            {
                                                                                filter = filter.toLowerCase();
                                                                            }
                                                                            const filteredFunds: any[] = portfolioFunds.filter(fund => (typeof fund?.isin === "string" && fund?.isin.toLowerCase().includes(filter)) || (typeof fund?.name === "string" && fund?.name.toLowerCase().includes(filter)));
                                                                            resolve(filteredFunds);
                                                                        }, 100);
                                                                    }
                                                                    if(portfolioFundsLoaded)
                                                                    {
                                                                        load(portfolioFunds);
                                                                    }
                                                                    else
                                                                    {
                                                                        onPortfolioLoaded(load);
                                                                    }
                                                                });
                                                            }}
                                                            onChange={(ev: any, name: any, value: any) =>
                                                            {
                                                                setValue(name, value);
                                                                onChange({target: {value, name}});
                                                            }}
                                                            errorMessage={errors.sourceFund &&
                                                            (
                                                                (typeof (errors.sourceFund.type) === "string"
                                                                    && errors.sourceFund.type === "required"
                                                                    && "Debe seleccionar un fondo de origen.")
                                                                || "El fondo de origen no es válido.")
                                                            }
                                                        />
                                                    } />
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Controller name="refundKey" control={control} rules={{required: true}} render={({field, field: {onChange}}) =>
                                                        <InputRefundsButton disabled label="Clave de Reembolso" placeholder="Escriba aquí la Clave de Reembolso" buttonTitle="Establecer"
                                                            onChange={(ev: any, name: any, value: any) =>
                                                            {
                                                                setRefundCode(value);
                                                                onChange({target: {value, name}});
                                                            }}
                                                            valueRefund={refundCode} handleRefund={handleChange} />
                                                    } />
                                                </Stack.Item>
                                            </Stack>
                                        </Lay.CardBody>
                                    </Lay.CardContainer>
                                </div>
                            </div>
                        </div>
                    </Lay.Main>
                </form>
            </DocumentTitle>
        );
    }
    else
    {
        return(
            <DocumentTitle title='IronIA - Reembolsos'>
                <form className="external-transfer-form" id="refunds-form" onSubmit={handleSubmit(onSubmit)} noValidate={false}>
                    <Lay.Main theme={theme}>
                        <Lay.BackButtonContainer>
                            <span>
                                <CaretHollowMirroredIcon />
                                <Link to='/portfolio'>
                                    Volver atrás
                                </Link>
                            </span>
                        </Lay.BackButtonContainer>
                        {activePlan !== undefined &&
                        (
                            <div className="ms-Grid-row" style={{minHeight: "20px"}}>
                                <div className="ms-Grid-col">
                                    <Lay.TitleContainer>
                                        <h2>Reembolsos</h2>
                                    </Lay.TitleContainer>
                                </div>
                                <div className="ms-Grid-col" style={{paddingTop: "12px", color: "#CC214F"}}>
                                    <Lay.SubTitleContainer> {activePlan.name}</Lay.SubTitleContainer>
                                </div>
                            </div>
                        )}
                        {activePlan === undefined &&
                        (
                            <Lay.TitleContainer>
                                <h2>Reembolsos</h2>
                            </Lay.TitleContainer>
                        )}
                        <Lay.SeparatorTop />
                        <div className="ms-Grid restarted-ms-Grid">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 ms-xxl6 ms-xxxl6">
                                    <Lay.CardContainer theme={theme}>
                                        <Lay.CardHeader>
                                            <Lay.CardHeadingContainer>
                                                <h3>Datos para el Reembolso</h3>
                                            </Lay.CardHeadingContainer>
                                        </Lay.CardHeader>
                                        <Lay.CardBody>
                                            <Stack>
                                                <Stack.Item>
                                                    <Controller name="sourceFund" control={control} rules={{required: true}} render={({field, field: {onChange}}) =>
                                                        <InvestmentFundFinder {...field} required labelISIN="ISIN de origen" labelName="Nombre del fondo de origen"
                                                            placeholderISIN="Escribe aquí el ISIN de origen..." placeholderName="Escribe aquí el nombre del fondo de origen..."
                                                            default={defaultOrigin}
                                                            loadData={(filter: any) =>
                                                            {
                                                                return new Promise<any[]>((resolve) =>
                                                                {
                                                                    function load(portfolioFunds: any[])
                                                                    {
                                                                        setTimeout(() =>
                                                                        {
                                                                            if(Array.isArray(portfolioFunds) && portfolioFunds.length <= 0)
                                                                            {
                                                                                fundService.getFunds(0, 5, filter).then(data => resolve(data.results?.slice(0, 20))).catch(err => resolve([]));
                                                                            }
                                                                            if(typeof filter === 'string')
                                                                            {
                                                                                filter = filter.toLowerCase();
                                                                            }
                                                                            const filteredFunds: any[] = portfolioFunds.filter(fund => (typeof fund?.isin === "string" && fund?.isin.toLowerCase().includes(filter)) || (typeof fund?.name === "string" && fund?.name.toLowerCase().includes(filter)));
                                                                            resolve(filteredFunds);
                                                                        }, 100);
                                                                    }
                                                                    if(portfolioFundsLoaded)
                                                                    {
                                                                        load(portfolioFunds);
                                                                    }
                                                                    else
                                                                    {
                                                                        onPortfolioLoaded(load);
                                                                    }
                                                                });
                                                            }}
                                                            onChange={(ev: any, name: any, value: any) =>
                                                            {
                                                                if(value !== undefined)
                                                                {
                                                                    setHasChangedIsin(true);
                                                                    setSimulation(false);
                                                                }
                                                                else
                                                                {
                                                                    setSimulationAvailable(false);
                                                                    setHasChangedIsin(false);
                                                                }

                                                                setValue(name, value);
                                                                onChange({target: {value, name}});
                                                            }}
                                                            errorMessage={errors.sourceFund &&
                                                            (
                                                                (typeof (errors.sourceFund.type) === "string"
                                                                    && errors.sourceFund.type === "required"
                                                                    && "Debe seleccionar un fondo de origen.")
                                                                || "El fondo de origen no es válido."
                                                            )}
                                                        />}
                                                    />
                                                </Stack.Item>
                                                <Stack.Item style={{marginTop: "1em"}}>
                                                    <Controller name="refundKey" control={control} rules={{required: true}} render={({field, field: {onChange}}) =>
                                                        <InputRefundsButton type="password" canRevealPassword label="Clave de Reembolso" placeholder="Escriba aquí la Clave de Reembolso" buttonTitle="Establecer"
                                                            onChange={(ev: any, name: any, value: any) =>
                                                            {
                                                                setValue("refundKey", name);
                                                            }}
                                                            valueRefund={refundCode}
                                                            handleRefund={handleChange}
                                                            errorMessage={errorKey}
                                                        />
                                                    } />
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <DropdownAccounts accounts={accounts} dropdownChange={(newValue: string) => {onChangeIBANValue(newValue)}} errorMessage={error} />
                                                </Stack.Item>
                                            </Stack>
                                            <Lay.SeparatorForm />
                                            <h3 style={{marginBottom: "15px !important"}}>Tipo de importe</h3>
                                            <Stack>
                                                <Stack.Item>
                                                    <Controller name="amountType" control={control} rules={{required: true}} render={({field}) =>
                                                        <ChoiceGroup {...field} required options={refundsOptions} />
                                                    } />
                                                </Stack.Item>
                                                {watchAmountType !== "total" && <Stack.Item>
                                                    <Controller name="amount" control={control}
                                                        rules={{required: true, min: 0 + Number.MIN_VALUE,
                                                            validate:
                                                            {
                                                                amountLess: value => (watchAmountType === "amount" && parseFloat(value) <= importe) || watchAmountType === "titles",
                                                                titlesLess: value => (watchAmountType === "titles" && parseFloat(value) <= title) || watchAmountType === "amount"
                                                            }
                                                        }}
                                                        render={({field}) =>
                                                            <TextField {...field} className="amountText" style={{textAlign: "end"}} required type="number"
                                                                label={(watchAmountType === "titles" && "Participaciones") || "Importe"}
                                                                placeholder={(watchAmountType === "titles" && "Escriba aquí el número de participaciones...") || "Escriba aquí el importe..."}
                                                                errorMessage=
                                                                {
                                                                    errors.amount &&
                                                                    (
                                                                        (watchAmountType === "titles" &&
                                                                            (
                                                                                (typeof(errors.amount.type) === "string"
                                                                                    && errors.amount.type === "required"
                                                                                    && "Indique las participaciones a traspasar.") ||
                                                                                (typeof(errors.amount.type) === "string"
                                                                                    && errors.amount.type === "min"
                                                                                    && "Debe indicar un número mayor de participaciones.") ||
                                                                                (typeof(errors.amount.type) === "string"
                                                                                    && errors.amount.type === "titlesLess"
                                                                                    && "El valor de las participaciones no puede ser mayor que el número total de participaciones que posea (" + title + ").")
                                                                                || "Introduzca un número de participaciones válido."
                                                                            )
                                                                        )
                                                                        ||
                                                                        (typeof(errors.amount.type) === "string"
                                                                            && errors.amount.type === "min"
                                                                            && "El importe debe ser mayor de 0.") ||
                                                                        (typeof(errors.amount.type) === "string"
                                                                            && errors.amount.type === "required"
                                                                            && "Debe indicar el importe a traspasar.") ||
                                                                        (typeof(errors.amount.type) === "string"
                                                                            && errors.amount.type === "amountLess"
                                                                            && "El valor del importe no puede ser mayor que el importe máximo que posee del fondo (" + importe + ").")
                                                                        || "Introduzca un importe válido."
                                                                    )
                                                                }
                                                            />
                                                        } />
                                                </Stack.Item>}
                                            </Stack>
                                        </Lay.CardBody>
                                        <Lay.OuterFooterArea>
                                            <Lay.CardButton
                                                size="lg"
                                                disabled={!simulationAvailable}
                                                type="button"
                                                style={{marginRight: "1em"}}
                                                onClick={handleSimulation}
                                            >
                                                Simular
                                            </Lay.CardButton>
                                            <Lay.CardButton
                                                size="lg"
                                                disabled={sending
                                                    || values.sourceFund === undefined || errors.sourceFund !== undefined
                                                    || values.refundKey === undefined || values.refundKey.length === 0 || errors.refundKey !== undefined
                                                    || refundIBAN.length !== 24
                                                    || watchAmountType === undefined || errors.amountType !== undefined
                                                    || (values.amountType !== 'total' && (watchAmount === undefined || errors.amount !== undefined))
                                                }
                                                style={{float: "right"}}
                                                type="submit"
                                                onClick={handleSubmit(onSubmit)}
                                            >
                                                {sending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                                Continuar
                                            </Lay.CardButton>
                                        </Lay.OuterFooterArea>
                                    </Lay.CardContainer>
                                </div>
                                {simulation === true && simulationAvailable === true && refundDates !== undefined &&
                                (
                                    <React.Fragment>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 ms-xxl6 ms-xxxl6">
                                            <Lay.CardContainer theme={theme}>
                                                <Lay.CardHeader>
                                                    <Lay.CardHeadingContainer>
                                                        <h3>Simulación de Tiempo de Reembolso</h3>
                                                    </Lay.CardHeadingContainer>
                                                </Lay.CardHeader>
                                                <RefundTimeLine refundEstimationDates={refundDates} />
                                            </Lay.CardContainer>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </Lay.Main>
                </form>
            </DocumentTitle>
        );
    }
}
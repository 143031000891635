import axios from "axios";
import { proxyUrl } from "../config";
import { ManagedPortfolioType } from "./ManagedPortfolioService";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);

export interface FnmContract
{
    contractName: string,
    contractCode: string,
    date: string,
    savingsPlanName: string,
    fnmContractsUserIdentities: FnmContractsUserIdentity[]
    fnmPortfolio: FnmPortfolio
}
export interface FnmContractsUserIdentity
{
    idDocument: string,
    signedContract: string,
    signedManagementAuthorizationContract: string,
    version: string,
    signDate?: string
}
export interface FnmPortfolio
{
    id?: number,
    finametrixId: string,
    fnmcontractId: number,
    fnmContract: FnmContract,
    managedPortfolioId?: number,
    managedPortfolio?: ManagedPortfolioType
}
export interface SyncResult
{
    result: boolean,
    message: string
}
class FnmContractService
{
    public getFnmContract = async (userId: number): Promise<FnmContract[]> =>
    {
        let url: string = `${proxyUrl}/contracts/getByUserId/${userId}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public syncSavingPlan = async (savingPlanId: number): Promise<SyncResult> =>
    {
        let url: string = `${proxyUrl}/syncFnmSavingsPlan/${savingPlanId}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
}

const contract = new FnmContractService();
export default contract;
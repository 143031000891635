import axios from "axios";
import { proxyUrl } from "../config";
import { SubscriptionType } from "./SubscriptionService";
import { SavingsPlanType } from "./SavingsPlanService";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);

interface IUsersSubscription
{
    id?: number,
    userId?: number,
    subscriptionId?: number,
    subscription?: SubscriptionType,
    savingsPlans?: SavingsPlanType[],
    createdAt?: string,
    startDate?: string
    endDate?: string
}

export type UsersSubscriptionType = IUsersSubscription;

class UsersSubscriptionService
{
    public createUsersSubscription = async(usersSubscription: UsersSubscriptionType): Promise<UsersSubscriptionType> =>
    {
        usersSubscription.createdAt = new Date().toUTCString();
        let url: string = `${proxyUrl}/usersSubscriptions`;
        const req = await axios.post(url, usersSubscription);
        return req.data
    }
    public updateUsersSubscription = async(usersSubscription: UsersSubscriptionType): Promise<UsersSubscriptionType> =>
    {
        let url: string = `${proxyUrl}/usersSubscriptions/${usersSubscription.id}`;
        const req = await axios.put(url, usersSubscription);
        return req.data;
    }
    public patchUsersSubscription = async (usersSubscription: UsersSubscriptionType): Promise<UsersSubscriptionType> =>
    {
        let url: string = `${proxyUrl}/usersSubscriptions/${usersSubscription.id}`;
        const req = await axios.patch(url, usersSubscription);
        return req.data;
    }
    public getPendingPromotioned = async(userId: number, savingsPlanId: number): Promise<UsersSubscriptionType> =>
    {
        let url: string = `${proxyUrl}/usersSubscriptions/${userId}/${savingsPlanId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getLimitAmount = async(userSubscriptionId: number): Promise<number> =>
    {
        let url: string = `${proxyUrl}/getLimitAmount/${userSubscriptionId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getUserSubscriptionsByType = async(userId: number, type: string): Promise<UsersSubscriptionType[]> =>
    {
        let url: string = `${proxyUrl}/userSubscriptionsByType/${userId}/${type}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getOutdatedPriceBySubscriptionId = async (userSubscriptionId: number): Promise<any> =>
    {
        let url: string = `${proxyUrl}/GetOutdatedPriceBySubscriptionId/${userSubscriptionId}`;
        const req = await axios.get(url);
        return req.data;
    }
}

const UsersSubscription = new UsersSubscriptionService();
export default UsersSubscription;
import * as React from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { getTheme, FontWeights, Modal, IIconProps, TextField, Stack, DefaultButton, Label, ILabelStyles, DatePicker, IDatePicker, mergeStyleSets, ITextFieldStyles } from '@fluentui/react';
import { IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import UserIdentitiesService from '../../../services/UserIdentitiesService';
import Auth from '../../../Auth/Auth';
import { useState } from 'react';
import Moment from 'moment';

export interface Props
{
    onChange?: (event: any, value: any) => any;
};
const theme = getTheme();
const contentStyles = mergeStyleSets(
{
    container:
    {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        borderRadius: '2em',
        minWidth: "550px !important",
        width: "35%",
    },
    header:
    [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            color: '#CC214F',
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body:
    {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors:
        {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
export const ButtonRefunds = styled.button`
    cursor: pointer;
    background: #CC214F;
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 100px;
    padding: 0.5em 1.5em 0.5em 1.94em;
    margin-left: 3em !important;
    margin-top: 27px !important;
    border-style: none;
    &:hover
    {
        background-color: rgb(184, 29, 71);
        color: rgb(255, 255, 255);        
    }
`;
const styleLabel: Partial<ILabelStyles> =
{
    root:
    {
        fontSize: '19px !important',
        padding: '0 45px',
        margin: '0px',
        textAlign: "center",
        width: "100%"
    }
};
const styleDefButton1: Partial<IButtonStyles> =
{
    root:
    {
        marginLeft: '0',
        marginTop: '1em',
        cursor: 'pointer',
        background: '#CC214F',
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'center',
        color: '#FFFFFF',
        borderRadius: '100px',
        padding: '0.5em 1.5em 0.5em 1.5em',
        borderStyle: 'none',
    }
};
const styleDefButton2: Partial<IButtonStyles> =
{
    root:
    {
        marginLeft: '2em',
        marginTop: '1em',
        cursor: 'pointer',
        background: '#CC214F',
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'center',
        color: '#FFFFFF',
        borderRadius: '100px',
        padding: '0.5em 1.5em 0.5em 2em',
        borderStyle: 'none',
    }
};
const iconButtonStyles: Partial<IButtonStyles> =
{
    root:
    {
        color: '#CC214F',
        marginLeft: 'auto',
        marginRight: '2px',
    },
    rootHovered:
    {
        color: theme.palette.neutralDark,
    }
};
const stylesPassword: Partial<ITextFieldStyles> =
{
    revealButton:
    {
        backgroundColor: "transparent !important",
        paddingTop: "10px"
    }
};
const styles = mergeStyleSets(
{
    root:
    {
        selectors:
        {
            '> *': { marginBottom: 15 }
        }
    },
    control:
    {
        marginBottom: 15
    },
});

export const ModalRefund: React.FunctionComponent<Props> = (props: Props) =>
{
    const [isModalValidationOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [isModalRefundOpen, { setTrue: showRefundModal, setFalse: hideRefundModal }] = useBoolean(false);
    const [sendingValidation, setSendingValidation] = useState(false);
    const [post, setPost] = useState(false);
    const { control } = useForm();
    const [dni, setDNIValue] = useState<string>("");
    const [birth, setBirthDate] = useState<Date | undefined>();
    const [key, setKey] = useState<string>("");
    const [error, setErrorMessage] = useState("");
    const [errorKey, setErrorKeyMessage] = useState("");
    const [errorRepeatedKey, setErrorRepeatedKeyMessage] = useState("");
    const [repeatedKey, setRepeatedKey] = useState<string>("");
    const titleId = useId('title');
    const modalRefund = useId('refundModal');
    const dniRegex = new RegExp("([0-9]{8}[A-Za-z]{1}|[A-Za-z]{1}[0-9]{7}[A-Za-z0-9]{1})||([0-9]{7}[0-9A-Ja])");
    const user = Auth.getUserProfile();
    const datePickerRef = React.useRef<IDatePicker>(null);
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const changeRefundValue = (item: any) =>
    {
        props.onChange?.call(this, null, item);
    };
    const callBackRefundCode: any = (value: any) =>
    {
        changeRefundValue(value);
    };
    function cleanFirstModal()
    {
        setDNIValue('');
        onChangeError("");
        setBirthDate(undefined);
    };
    function cleanSecondModal()
    {
        setKey('');
        setRepeatedKey('');
        //@ts-ignore
        document.getElementById("uppercase").style.color = "red";
        //@ts-ignore
        document.getElementById("lowercase").style.color = "red";
        //@ts-ignore
        document.getElementById("number").style.color = "red";
        //@ts-ignore
        document.getElementById("special").style.color = "red";
        //@ts-ignore
        document.getElementById("minLength").style.color = "red";
        //@ts-ignore
        document.getElementById("maxLength").style.color = "red";
    };
    const onClick = React.useCallback((): void =>
    {
        cleanFirstModal();
        datePickerRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onChangeError = React.useCallback((value?: string) =>
    {
        let newVal = value === undefined ? "" : value;
        setErrorMessage(newVal);
    }, []);
    const onChangeDNI = React.useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
    {
        if(newValue !== undefined && newValue.length === 9)
        {
            if(newValue?.match(dniRegex))
            {
                setDNIValue(newValue || '');
                onChangeError("");
            }
            else
            {
                onChangeError("Introduzca un DNI/NIE o CIF/NIF válido");
            }
        }
        else if(newValue !== undefined && newValue.length < 9)
        {
            setDNIValue(newValue);
            onChangeError("");
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChangeError]);
    const onFocusDNI = React.useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    {
        onChangeError("");
    }, [onChangeError]);
    const onChangeBirth = React.useCallback((date: Date) =>
    {
        if(date !== undefined && date !== null)
        {
            setBirthDate(Moment(date).subtract(date?.getTimezoneOffset(), "minutes").toDate());
        }
    }, []);
    const onSubmit = async () =>
    {
        if(dni !== "" && birth !== undefined)
        {
            let toSend: any = {};
            toSend.Dni = dni;
            toSend.BirthDate = setInputDate(birth).replaceAll('/', '-');

            setSendingValidation(true);

            let response = await UserIdentitiesService.checkByIdDocumentAndBirthday(user.mainUserIdentity.id, toSend)
                .catch((error: any) => {})
                .then((valid) => { return valid });

            if(response === true)
            {
                hideModal();
                cleanFirstModal();
                setSendingValidation(false);
                showRefundModal();
            }
            else
            {
                onChangeError("El DNI/CIF o la fecha de nacimiento no son correctas.");
                setSendingValidation(false);
            }
        }
        else
        {
            onChangeError("El DNI/CIF es requerido");
            setSendingValidation(false);
        }
    };
    const onChangeKey = React.useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
    {
        setKey(newValue || '');
        validationKey(newValue || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    const onChangeRepeatedKey = React.useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
    {
        setRepeatedKey(newValue || '');
    },[]);
    const onRefundClick = React.useCallback((): void =>
    {
        cleanSecondModal();
    }, []);
    const onErrorKey = React.useCallback((value?: string) =>
    {
        let newVal = value === undefined ? "" : value;
        setErrorKeyMessage(newVal);
    }, []);
    const onErrorRepeatedKey = React.useCallback((value?: string) =>
    {
        let newVal = value === undefined ? "" : value;
        setErrorRepeatedKeyMessage(newVal);
    }, []);
    const onRefundSubmit = async () =>
    {
        if(key !== null && repeatedKey !== null)
        {
            if(key === repeatedKey)
            {
                setPost(true);

                let response = await UserIdentitiesService
                    .patch(user.mainUserIdentity.id, { refundCode: key })
                    .catch((error: any) => { })
                    .then((valid) => { return valid });

                if(response !== false)
                {
                    hideRefundModal();
                    callBackRefundCode(key);
                    setPost(false);
                    cleanSecondModal();
                }
                else
                {
                    onErrorKey("Se ha producido un fallo con su clave seleccionada");
                    onErrorRepeatedKey("Se ha producido un fallo con su clave seleccionada");
                    setPost(false);
                }
            }
            else
            {
                onErrorKey("Las claves deben coincidir");
                onErrorRepeatedKey("Las claves deben coincidir");
                setPost(false);
            }
        }
        else
        {
            if(key == null)
            {
                onErrorKey("La clave es obligatoria");
            }
            if(repeatedKey == null)
            {
                onErrorRepeatedKey("La repetición de clave es obligatoria");
            }
            setPost(false);
        }
    };
    function setInputDate(date: Date | undefined)
    {
        if(date !== undefined)
        {
            let result: string = '';

            if(date.getDate().toString().length < 2)
            {
                result += result + '0' + date.getDate() + '/';
            }
            else
            {
                result += result + date.getDate() + '/';
            }

            if((date.getMonth() + 1).toString().length < 2)
            {
                result += '0' + (date.getMonth() + 1) + '/';
            }
            else
            {
                result += (date.getMonth() + 1) + '/';
            }

            result += date.getFullYear();

            return result;
        }
        else
        {
            return '';
        }
    }
    function validationKey(str: string)
    {
        if(hasUpperCase(str))
        {
            //@ts-ignore
            document.getElementById("uppercase").style.color = "#46d602";
        }
        else
        {
            //@ts-ignore
            document.getElementById("uppercase").style.color = "red";
        }
        if(hasLowerCase(str))
        {
            //@ts-ignore
            document.getElementById("lowercase").style.color = "#46d602";
        }
        else
        {
            //@ts-ignore
            document.getElementById("lowercase").style.color = "red";
        }
        if(isMinLengthRequired(str))
        {
            //@ts-ignore
            document.getElementById("minLength").style.color = "#46d602";
        }
        else
        {
            //@ts-ignore
            document.getElementById("minLength").style.color = "red";
        }
        if(isMaxLengthRequired(str))
        {
            //@ts-ignore
            document.getElementById("maxLength").style.color = "#46d602";
        }
        else
        {
            //@ts-ignore
            document.getElementById("maxLength").style.color = "red";
        }
        if(hasNumericValue(str))
        {
            //@ts-ignore
            document.getElementById("number").style.color = "#46d602";
        }
        else
        {
            //@ts-ignore
            document.getElementById("number").style.color = "red";
        }
        if(hasSpecialValue(str))
        {
            //@ts-ignore
            document.getElementById("special").style.color = "#46d602";
        }
        else
        {
            //@ts-ignore
            document.getElementById("special").style.color = "red";
        }
    }

    function hasUpperCase(str: string)
    {
        return /[A-Z]/.test(str);
    }
    function hasLowerCase(str: string)
    {
        return /[a-z]/.test(str);
    }
    function isMinLengthRequired(str: string)
    {
        return str.length >= 12;
    }
    function isMaxLengthRequired(str: string)
    {
        return str.length <= 30;
    }
    function hasNumericValue(str: string)
    {
        return /\d/.test(str);
    }
    function hasSpecialValue(str: string)
    {
        return str.includes("#") || str.includes("?") || str.includes(".") || str.includes("!") ||
               str.includes("@") || str.includes("$") || str.includes("%") || str.includes("^") ||
               str.includes("&") || str.includes("*") || str.includes("-");
    }

    //const tooltipText = "La contraseña debe contener entre 12 y 30 caracteres, con mayúsculas, minúsculas, dígitos y caracteres especiales.";

    return(
        <React.Fragment>
            <ButtonRefunds onClick={showModal}>Establecer</ButtonRefunds>
            <Modal titleAriaId={titleId} isOpen={isModalValidationOpen} onDismiss={hideModal} isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <Label styles={styleLabel} id={titleId}>Establezca Clave de Reembolso</Label>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Cerrar modal" onClick={hideModal} />
                </div>
                <div className={contentStyles.body}>
                    <Stack>
                        <Stack.Item style={{ marginBottom: "1em" }}>
                            <Controller name="DNI" control={control} rules={{ required: true, pattern: dniRegex }} render={({ field, field: { onChange } }) =>
                                <TextField value={dni} required label="DNI/NIE/CIF" placeholder="DNI/NIE o CIF del titular del Contrato, sin guiones ni espacios" onChange={onChangeDNI} onFocus={onFocusDNI} errorMessage={error}/>
                            } />
                        </Stack.Item>
                        <Stack.Item>
                            <Controller name="birthDate" control={control} rules={{ required: true }} render={({ field, field: { onChange } }) =>
                                <div className={styles.root}>
                                    <DatePicker
                                        componentRef={datePickerRef}
                                        label="Fecha de Nacimiento"
                                        className={styles.control}
                                        isRequired={true}
                                        showGoToToday={false}
                                        allowTextInput={true}
                                        onSelectDate={onChangeBirth as (date: Date | null | undefined) => void}
                                        value={birth}
                                        formatDate={(birth) => setInputDate(birth)}
                                        parseDateFromString={(dateStr) =>
                                        {
                                            var parts: string[] = dateStr.split('/');
                                            var myDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
                                            return new Date(myDate)
                                        }}
                                    />
                                </div>
                            } />
                        </Stack.Item>
                    </Stack>
                    <Stack>
                        <Stack.Item style={{ display: "flex", justifyContent: "center" }}>
                            <DefaultButton styles={styleDefButton1} aria-label="Limpiar Calendario" onClick={onClick} text="Limpiar" />
                            <DefaultButton disabled={sendingValidation} styles={styleDefButton2} onClick={onSubmit}>Comprobar</DefaultButton>
                        </Stack.Item>
                    </Stack>
                </div>
            </Modal>
            <Modal titleAriaId={modalRefund} isOpen={isModalRefundOpen} onDismiss={hideRefundModal} isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <Label styles={styleLabel} id={modalRefund}>Establezca Clave de Reembolso</Label>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Cerrar modal" onClick={hideRefundModal} />
                </div>
                <div className={contentStyles.body}>
                    <Stack>
                        <Stack.Item style={{ marginBottom: "1em" }}>
                            <TextField value={key} required label="Introduzca la Clave de Reembolso"
                                type="password" canRevealPassword
                                styles={stylesPassword}
                                placeholder="Introduzca contraseña"
                                onChange={onChangeKey} errorMessage={errorKey} />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField value={repeatedKey} required label="Repita la Clave de Reembolso"
                                type="password" canRevealPassword
                                styles={stylesPassword}
                                placeholder="Repita la Clave de Reembolso" onChange={onChangeRepeatedKey}
                                errorMessage={errorRepeatedKey} />
                        </Stack.Item>
                        <Stack.Item style={{marginTop: "1em"}}>
                            <p style={{margin:0, fontSize: "10px", color: "red"}} id="lowercase">· Al menos un caracter en minúsculas</p>
                            <p style={{margin:0, fontSize: "10px", color: "red"}} id="uppercase">· Al menos un caracter en mayúsculas</p>
                            <p style={{margin:0, fontSize: "10px", color: "red"}} id="special">· Al menos un caracter especial</p>
                            <p style={{margin:0, fontSize: "10px", color: "red"}} id="number">· Al menos un caracter numérico</p>
                            <p style={{margin:0, fontSize: "10px", color: "red"}} id="minLength">· Al menos 12 caracteres de longitud</p>
                            <p style={{margin:0, fontSize: "10px", color: "red"}} id="maxLength">· Como máximo 30 caracteres de longitud</p>
                        </Stack.Item>
                    </Stack>
                    <Stack>
                        <Stack.Item style={{ display: "flex", justifyContent: "center" }}>
                            <DefaultButton styles={styleDefButton1} aria-label="Limpiar" onClick={onRefundClick} text="Limpiar" />
                            <DefaultButton disabled={post} styles={styleDefButton2} onClick={onRefundSubmit}>Enviar</DefaultButton>
                        </Stack.Item>
                    </Stack>
                </div>
            </Modal>
        </React.Fragment>
    );
};
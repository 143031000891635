import styled from "styled-components"
import { Link } from 'react-router-dom';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { createGlobalStyle } from "styled-components";

export const Cont = styled.div`
    grid-area: main;
    background-color: #fafcfd;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
`;
export const Steps = styled.div`
    & h3
    {
        font-size: 22px;
        color: #cc214f;
    }

    & h3 span
    {
        color: #beced5;
        margin-left: 2px;
    }
`;
export const MainCont = styled.div`
    padding: 40px 55px;

    @media screen and (max-width: 599px)
    {
        padding: 40px 25px;
    }
`;
export const Title = styled.div`
    margin-top: 30px;

    & h1
    {
        font-size: 30px;
        color: #2a4143;
        font-weight: 800;
    }

    & h2
    {
        font-size: 24px;
        font-weight: 400;
        width: 100%;
        max-width: 900px;
        color: #5f7172;
        text-align: justify;
    }
`;
export const BarCont = styled.div`
    position: relative;
    width: 100%;
    height: 20px;
    margin-top: 30px;
`;
export const BgBar = styled.hr`
    position: absolute;
    width: 100%;
    border: solid 6px #fae9ed;
    border-radius: 10px;
    top: 50%;
    transform: translate(0%, -50%);
`;
export const ProsBar = styled.hr`
    position: absolute;
    width: calc(100%);
    border: solid 4px #cc214f;
    border-radius: 10px;
    top: 50%;
    transform: translate(0%, -50%);
`;
export const TabletCont = styled.div`
    width: 100%;
    border-radius: 20px;
    margin-top: 30px;

    & hr
    {
        margin: 40px 0;
        border: solid 1px #e9eff2;
    }
`;
export const TabletTitle = styled.h3`
    color: #2a4143;
    font-size: 20px;
    font-weight: 800;
`;
export const SubTitle = styled.h4`
    color: #2a4143;
    font-size: 17px;
    font-weight: 600;
`;
export const Phrase = styled.p`
    color: #556769;
    font-size: 14px;
    font-weight: 500;
`;
export const ExpCont = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;

    @media screen and (max-width: 599px)
    {
        flex-flow: row wrap;
    }
`;
export const ExpTimeCont = styled.div`
    width: 180px;
    height: 100px;

    & p
    {
        font-size: 70px;
        color: #bfc6c7;
    }
`;
export const CodesCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 415px;
    margin-top: 22px;
`;
export const BtnCont = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 80px;

    @media screen and (max-width: 599px)
    {
        justify-content: center;
        flex-flow: row wrap;
        margin-top: 40px;

        & a
        {
            margin: 0 10px;
        }

        & button
        {
            margin-bottom: 20px;
        }
    }
`;
export const LinkBtn = styled(Link)`
    display: block;
    width: fit-content;
`;
export const ContinueBtn = styled(DefaultButton)`
    background-color: ${(props) => (props.disabled ? '#f0bcca' : '#cc214f')};
`;
export const BackBtn = styled(DefaultButton)``;
export const Question = styled.p`
    font-weight: 500;
    color: #556769;
    font-size: 14px;
    width: 100%;
    max-width: 700px;
    text-align: justify;
`;
export const HelperStyle = createGlobalStyle`
    *,
    ::after,
    ::before
    {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    } 

    ul
    {
        list-style-type: none;
    }
    a
    {
        text-decoration: none;
    } 
`;
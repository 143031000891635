import {RouteComponentProps, useHistory, useLocation} from "react-router-dom";
import {SignatureComponent} from '../../components/Signature/SignatureComponent';
import moment from "moment";
import { useEffect, useState } from "react";
import { ISignature } from "../../services/SignaturesService";
import OrderService, { OrderType } from '../../services/OrderService';
import ProcessingModalComponent from "../../components/Modals/ProcessingModalComponent";
import { useBoolean } from "@fluentui/react-hooks";
import { MessageBar, MessageBarType } from "@fluentui/react";
import React from "react";

interface SignatureProps extends RouteComponentProps {}

export const MarketerSwitchSignatureScreen = (props: SignatureProps) =>
{
    const history = useHistory();
    const {state} = useLocation<any>();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [showAlert, { toggle: toggleShowAlert }] = useBoolean(false);

    const processExpirationDate = (signature: ISignature) =>
    {
        if(signature && typeof signature.expiresAt === "string")
        {
            signature.expiresAt = moment(signature.expiresAt).toDate();
        }
    }

    let transfer: any = state?.transfer;
    let defaultSignature: any = state?.signature;
    processExpirationDate(defaultSignature);

    const [signature, setSignature] = useState(defaultSignature);

    useEffect(() =>
    {
        var operationPhone = transfer?.userIdentity?.operationPhone;
        setPhoneNumber("X".repeat(operationPhone.length - 3) + operationPhone.slice(operationPhone.length - 3, operationPhone.length));
    }, [transfer]);

    if(!state?.signature || !state?.transfer)
    {
        history.push("/transfer");
        return;
    }

    const submit = (signature: ISignature) =>
    {
        showModal();
        var data = { id: transfer.id, signatureId: state.signature.id };
        OrderService.patchOrder(data).then((response: OrderType) =>
        {
            if(response.signatureId)
            {
                history.push("/distributor-switch");
            }
        })
        .catch((error) =>
        {
            hideModal();
            toggleShowAlert();
        });
    };

    return(
        <React.Fragment>
            {showAlert &&
            (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">
                    Se ha producido un error al procesar la operación.
                </MessageBar>
            )}
            <SignatureComponent
                userIdentities={[transfer?.userIdentity]}
                signatures={[signature]}
                title="Firma del cambio de comercializador"
                submitButtonText="Firmar"
                cancelButtonText="Cancelar"
                headerTextArea={`Recibirás un SMS en el teléfono ${phoneNumber} con la clave para confirmar esta orden.`}
                alreadySignedText="¡Tu orden de traspaso está en proceso!"
                backButtonText="Volver"
                onBackButtonClicked={() => history.push("/distributor-switch")}
                onCancelButtonClicked={() => history.push("/")}
                requestNewCodeButtonText="Recibir nuevo código"
                onNewCodeSended={(signatures: any) =>
                {
                    signatures.forEach((signature: any) =>
                    {
                        processExpirationDate(signature);
                    })
                    setSignature(signatures[0]);
                }}
                onSignatureChanged={(signature: any) =>
                {
                    processExpirationDate(signature);
                    setSignature(signature);
                }}
                onSigned={(signature: any) =>
                {
                    processExpirationDate(signature);
                    setSignature(signature);
                    submit(signature);
                }}
            />
            <ProcessingModalComponent isModalOpen={isModalOpen} hideModal={hideModal}/>
        </React.Fragment>
    );
};
import Cookies from 'universal-cookie';
import { useState } from "react";
import { SearchBox } from '@fluentui/react';
import { IIconProps } from "@fluentui/react";
import OutsideClickHandler from "react-outside-click-handler";
import SearchResults from "./SearchResults";
import React from "react";
import DocumentTitle from "react-document-title";
import FundService, { SearchFundsMinType } from "../../../services/FundService";
import AzureSearchServices, { AzureNewsType } from "../../../services/AzureSearchServices";
import { ai } from '../../../services/TelemetryService';
import Auth from "../../../Auth/Auth";
const filterIcon: IIconProps = {iconName: "ironiaSearch", style: {cursor: "pointer"}};

export const validFundIsin = new RegExp("^[A-Za-z]{2}");

type Props =
{
    theme: any;
}

export const MainSearch = (props: Props) =>
{
    const {theme} = props;
    const cookies = new Cookies();
    const appInsights = ai.reactPlugin;
    const userId = Auth.getUserProfile().id;
    const [showResults, setShowResults] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedTerm, setDebouncedTerm] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fundsResult, setFundsResult] = useState<SearchFundsMinType[]>([]);
    const [news, setNews] = useState<AzureNewsType[]>([]);
    const [fundsByCompany, setFundsByCompany] = useState<SearchFundsMinType[]>([]);
    const [top5searchTermHistory, setTop5searchTermHistory] = useState<[]>([]);

    React.useEffect(() =>
    {
        const timer = setTimeout(() =>
        {
            setSearchText(debouncedTerm);
        }, 2000);
        return () => clearTimeout(timer);
    }, [debouncedTerm]);

    React.useEffect(() =>
    {
        setIsLoading(true);
        setNews([]);
        setFundsResult([]);
        setFundsByCompany([]);
        var top5searchTermHistory = cookies.get("top5searchTermHistory") ?? [];
        if(searchText && searchText.length >= 3)
        {
            //saving historic on cookies
            top5searchTermHistory.push(searchText);
            if(top5searchTermHistory.length >= 6)
            {
                top5searchTermHistory.shift();
            }
            cookies.set("top5searchTermHistory", top5searchTermHistory, {path: "/"});
            setTop5searchTermHistory(top5searchTermHistory);

            //add event to track searchs
            appInsights.trackEvent({name: "Buscador IronIA", properties: {userId: userId, search: searchText}});

            //querys
            const p0 = AzureSearchServices.getNewsFromAzureSearchService(0, 5, searchText);
            const p1 = FundService.getSearchFunds(searchText);
            const p2 = AzureSearchServices.getDocFondos(0, 50, searchText);
            let companyIsins: string[] = [];

            Promise.all([p0, p1, p2]).then(values =>
            {
                setNews(values[0].value);
                setFundsResult(values[1]);
                let fundsByCompanyFilteredResult = values[2].value.filter(x => x.metadata_storage_name !== null && x.metadata_storage_name !== undefined && 
                                                                            validFundIsin.test(x.metadata_storage_name) === true)
                                                    .sort((a, b) => b["@search.score"] - a["@search.score"])
                                                    .slice(0, 5);

                fundsByCompanyFilteredResult.forEach(function (part, index, theArray)
                {
                    theArray[index].isin = theArray[index].metadata_storage_name.split(".")[0];
                    companyIsins.push(theArray[index].metadata_storage_name.split(".")[0]);
                });
			})
            .finally(() =>
            {
                if(companyIsins.length > 0)
                {
                    FundService.getFundsNameByIsin(companyIsins).then(response =>
                    {
                        setFundsByCompany(response);
                        setIsLoading(false);
                    });
                }
                else
                {
                    setIsLoading(false);
                }
            });
        }
        else
        {
            setTop5searchTermHistory(top5searchTermHistory);
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    return(
        <DocumentTitle title="IronIA - Buscador de Fondos">
            <React.Fragment>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                    <SearchBox autoComplete="off"
                        placeholder="Buscar..."
                        iconProps={filterIcon}
                        onFocus={() => setShowResults(true)}
                        onSelect={() => setShowResults(true)}
                        onChange={e => setDebouncedTerm(String(e?.target.value))}
                        value={debouncedTerm}
                    />
                    {showResults &&
                    (
                        <OutsideClickHandler onOutsideClick={() => setShowResults(false)}>
                            <SearchResults theme={theme}
                                searchTerm={searchText}
                                isLoading={isLoading}
                                fundsResult={fundsResult}
                                fundsByCompany={fundsByCompany}
                                top5searchTermHistory={top5searchTermHistory}
                                news={news}
                                onClose={() => setShowResults(false)}
                                changedSearchTerm={newTerm =>
                                {
                                    setDebouncedTerm(newTerm);
                                    setSearchText(newTerm);
                                }}
                            />
                        </OutsideClickHandler>
                    )}
                </div>
            </React.Fragment>
        </DocumentTitle>
    );
};
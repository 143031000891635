import { useHistory } from "react-router-dom";

interface PageProps
{
    text: string;
}
const BackButton = (props: PageProps) =>
{
    const history = useHistory();

    return (
        <button className="back-link" onClick={() => history.goBack()}>
            {props.text}
        </button>
    );
};

BackButton.defaultProps = {text: "Volver atrás"};

export default BackButton;
import Slider, { Settings } from "react-slick";
import MarketCardComponent from '../MarkertCard/MarketCardComponent';
import "./MarketCarouselStyle.css";
import { useState } from "react";

type MarketItem = {
    id: string;
    fileUrl: string;
    imageUrl: string;
};

interface MarketCardsCarouselProps {
    marketItems: MarketItem[];
    disabled: boolean;
    onMarketCardClick: (marketItem: MarketItem) => void;
};

const NextArrow = (props: any) => {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
            style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                width: "20.1px",
                height: "21.5px",
                backgroundColor: "transparent",
                borderRadius: "50%",
                position: "absolute",
                right: "-10px",
                zIndex: 2,
                cursor: "pointer",
            }}
        >
            <i className="ironia-icon keyboard-arrow-right" />
        </div>
    );
};

const PrevArrow = (props: any) => {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
            style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                width: "20.1px",
                height: "21.5px",
                backgroundColor: "transparent",
                borderRadius: "50%",
                position: "absolute",
                left: "-21px",
                zIndex: 2,
                cursor: "pointer",
            }}
        >
            <i className="ironia-icon keyboard-arrow-left" />
        </div>
    );
};

const MarketCardsCarousel = (props: MarketCardsCarouselProps) => {
    const settings: Settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        nextArrow: <NextArrow className="next-arrow" />,
        prevArrow: <PrevArrow className="prev-arrow" />,
    };

    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleCardClick = (marketItem: MarketItem, index: number) => {
        setActiveIndex(index);
        props.onMarketCardClick(marketItem);
    };

    return (
        <div style={{ padding: "0px" }}>
            {props.marketItems.length > 3 ? (
                <Slider {...settings}>
                    {props.marketItems.map((marketItem, index) => (
                        <div key={index} style={{ width: 130 }}>
                            <MarketCardComponent
                                className={`analysis-market-slider-image`}
                                active={activeIndex === index ? "active" : ""}
                                disabled={props.disabled}
                                reportDate={marketItem.id}
                                reportImage={marketItem.imageUrl}
                                onMarketCardClicked={() => handleCardClick(marketItem, index)}
                            />
                        </div>
                    ))}
                </Slider>
            ) : (
                <div style={{ display: "flex", gap: "10px", justifyItems: "flex-start" }}>
                    {props.marketItems.map((marketItem, index) => (
                        <MarketCardComponent
                            key={index}
                            className="analysis-market-image"
                            active={activeIndex === index ? "active" : ""}
                            disabled={props.disabled}
                            reportDate={marketItem.id}
                            reportImage={marketItem.imageUrl}
                            onMarketCardClicked={() => props.onMarketCardClick(marketItem)}
                        />
                    ))}
                </div>
            )}
        </div>
    );

};

export default MarketCardsCarousel;

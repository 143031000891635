import styled from 'styled-components';
import { InfoIcon } from "@fluentui/react-icons-mdl2";
import { Link } from 'react-router-dom';

export const DivRowPrincipal = styled.div``;
export const PrincipalHeader = styled.h2`
    margin: 0;
    cursor: default;
`;
export const PrincipalDescription = styled.p`
    margin-bottom: 0;
    color: #556769;
    font-size: 24px;
    line-height: 32px;
    cursor: default;
`;
export const TextLoading = styled.h3`
    text-align: center;
    padding-top: 2em;
    padding-bottom: 2em;
    cursor: default;
`;
export const Card = styled.div`
    background-color: #F9F9F9;
    border-radius: 8px;
    padding: 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
export const CardTitle = styled.h3`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0 !important;
    color: #333;
    cursor: default;
`;
export const TableContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr 2fr 2fr;
    gap: 10px;
    align-items: center;
    cursor: default;

    @media (max-width: 1540px)
    {
        grid-template-columns: 1fr 1fr 2fr 1fr 2fr 2fr;
    }

    @media (max-width: 1024px)
    {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    @media (max-width: 768px)
    {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    @media (max-width: 480px)
    {
        grid-template-columns: 1fr;
    }
`;
export const TableHeader = styled.div`
    font-weight: bold;
    color: #CC214F;
    font-size: 14px;
`;
export const TableCell = styled.div`
    font-size: 14px;
    color: #333;
`;
export const ElementsGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;
`;
export const ElementsInput = styled.input`
    width: 50%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: right;
`;
export const ElementsButton = styled.button`
    border: none;
    padding: 10px 5px 5px 5px;
    cursor: pointer;
`;
export const PaymentBreakdown = styled.div`
    flex: 1;
`;
export const PaymentItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;
export const PaymentLabel = styled.span`
    color: #556769;
`;
export const PaymentValue = styled.span`
    font-weight: bold;
`;
export const PaymentTotal = styled(PaymentItem)`
    border-top: 1px solid #ccc;
    padding-top: 10px;
    font-weight: bold;
`;
export const PaymentInfo = styled.div`
    margin-bottom: 20px;
    p
    {
        margin: 5px 0;
        color: #556769;
    }
`;
export const PayButton = styled.button`
    background-color: #CC214F;
    color: white;
    border: none;
    border-radius: 24px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    float: right;
    &:hover
    {
        background-color: #A51B3F;
    }
`;
export const SpinnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SpinnerText = styled.span`
    margin-left: 10px;
`;
export const PayButtonDisabled = styled.button`
    background-color: #36272B;
    color: white;
    border: none;
    border-radius: 24px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    float: right;
    &:hover
    {
        background-color: #500418;
    }
`;
export const MoreOptionsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;
export const MoreOptions = styled(Link)`
    background-color: #CC214F;
    color: white;
    border: none;
    border-radius: 24px;
    padding: 0.5rem 1.5rem;
    text-align: center;
    font-size: 16px;
    white-space: nowrap;
    cursor: pointer;
    float: right;
    &:hover
    {
        background-color: #A51B3F;
    }
`;
export const PaymentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;

    @media (max-width: 768px)
    {
        flex-direction: column;
    }
`;
export const CardContainer = styled.div`
    display: flex;
    padding-top: 1em;
    padding-right: 1em;
    gap: 20px;
    margin-bottom: 20px;

    @media (max-width: 768px)
    {
        flex-direction: column;
    }
`;
export const PaymentCard = styled.div`
    background-color: #F9F9F9;
    border-radius: 8px;
    padding: 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex: 2;
    cursor: default;
    transition: box-shadow 0.3s ease;
    &:hover
    {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
`;
export const InfoCard = styled.div`
    background-color: #F9F9F9;
    height: auto;
    border-radius: 8px;
    padding: 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex: 1;
    transition: box-shadow 0.3s ease;
    &:hover
    {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
`;
export const PaymentInfoBox = styled.div`
    background-color: #FFF3CD;
    color: #F69A2D;
    flex: 2;
    border-radius: 8px;
    padding: 15px;
    p
    {
        margin: 0 0 10px 0;
        font-size: 17px;
        line-height: 1.4;
        text-align: justify;

        &:last-child
        {
            margin-bottom: 0;
        }
    }
`;
export const RenewalDate = styled.span`
    color: #CC214F;
`;
export const SpanIntraCell = styled.span`
    margin-right: 2.25em;
`;
export const SpanButton = styled.span`
    vertical-align: middle;
    display: inline;
`;
export const FlexContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 2em;
    cursor: default;
`;
export const TextRightAligned = styled.p`
    text-align: right;
    vertical-align: middle;
    display: flex;
`;
export const InfoIconStyled = styled(InfoIcon)`
    color: #CC214F;
    padding: 3px;
    vertical-align: text-bottom !important;
`;
export const RowSubscriptionCard = styled.div`
    padding-bottom: 2em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;
export const ColSubscriptionTitle = styled.div`
    flex: 1;
    display: flex;
    min-width: 50%;
    align-items: center;
`;
export const ColSubscriptionButton = styled.div`
    display: flex;
    flex: 1;
    min-width: 50%;
    justify-content: flex-end;
    align-items: center;

    @media (min-width: 1220px)
    {
        padding-right: 0;
    }
    @media (max-width: 480px)
    {
        margin-top: 1em;
    }
`;
export const CardsContainer = styled.div`
    display: flex;
    align-items: stretch;
    gap: 20px;
    
    @media (min-width: 1200px)
    {
        min-height: 550px;
    }

    > div
     {
        flex: 1;
    }
`;
export const MoreResuscriptionPlansTitle = styled.h2`
    text-align: center;
    padding-bottom: 1.5rem;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;
export const ItemDiv = styled.div`
    background-color: ${props => props.theme.palette.greyMorePlans};
    border-radius: 12px;
    padding: 32px 24px;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @media (min-width: 1200px)
    {
        min-height: 550px;
    }

    &.selected
    {
        border: 3px solid ${props => props.theme.palette.themePrimary};
        background-color: ${props => props.theme.palette.greyMorePlansSelected};
    }
`;
export const MoreResuscriptionPlansH2Item = styled.h2`
    text-align: center;
    color: ${props => props.theme.palette.black};
    font-size: 25px;
    margin: 0;
    padding: 0;
    width: 100%;
    cursor: default;

    &.title
    {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        text-align: center;
        width: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    &.price
    {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
    }

    &.limit
    {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 32px 0 16px 0;
    }
`;
export const DescriptionContainer = styled.div`
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    cursor: default;
`;
export const MoreResuscriptionPlansPItem = styled.p`
    text-align: center;
    margin: 0;
    font-size: 16px;
    color: ${props => props.theme.palette.black};
    line-height: 1.5;
    width: 100%;
    cursor: default;

    &.description
    {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.limit-text
    {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
    }
`;
export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: auto;
    height: 40px;
`;
import { Modal } from "@fluentui/react";
import { Image } from 'office-ui-fabric-react/lib/Image';
import loadingImage from '../../images/loading.svg';

const ProccesingModalComponent = ({ isModalOpen, hideModal }: { isModalOpen: boolean, hideModal: () => void }) => {
    return (
        <Modal
            isOpen={isModalOpen}
            onDismiss={hideModal}
            isBlocking={true}
        >
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm4">
                    <Image src={loadingImage} alt="Loading" />
                </div>
                <div className="ms-Grid-col ms-sm8">
                    <h3>Esperando confirmación del TPV</h3>
                </div>
            </div>
            <p className="text-center">No cierres el navegador hasta que la confirmación se haya recibido.</p>
        </Modal>
    );
};

export default ProccesingModalComponent;

import { useState } from "react";
import styled from "styled-components";
import { ChevronRightIcon, ChevronDownIcon } from '@fluentui/react-icons-mdl2';
import { PaymentTable } from "./PaymentTable";
import { UserSubscriptionsType } from "../../../../services/SubscriptionService";

export const SubscriptionName = styled.div`
    .brothers-btn-icon
    {
        margin-right: 5px;
        vertical-align: middle;
    }
`;
export const PaymentTableStyle = styled.tbody`
    width: 100%;
    min-width: max-content;
    padding: 10px 0 !important;
    margin: 15px;
    margin-left: 10px !important;
    color: lightBlack;
    box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px;
    border-radius: 0;
    th div,
    td div
    {
        text-align: left;
        margin: 0 !important;
    }
    th,
    td
    {
        padding-left: 5px !important;
    }
`;
export const SubTableDiv = styled.div`
    div
    {
        width: 100%;
        min-width: max-content;
        padding: 10px 0 !important;
    }
    margin: 15px;
    margin-left: 10px !important;
    table
    {
        color: lightBlack;
        box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px;
        border-radius: 16px;
        th div,
        td div
        {
            text-align: left;
            margin: 0 !important;
        }
        th,
        td
        {
            padding-left: 5px !important;
        }
    }
`;
export const SubTable = styled.table`
    width: 100%;
    border-spacing: 0;
    overflow: hidden;
    thead
    {
        text-align: left;
        border-top-right-radius: 30px;
        div{}
        th
        {
            border-spacing: 0;
            padding: 5px !important;
            div
            {
                padding-top: 17px;
                padding-right: 17px;
                padding-bottom: 17px;
            }
        }
        th:first-of-type
        {
            div
            {
                padding-left: 10px;
                border-top-left-radius: 30px;
            }
        }
        th:last-of-type
        {
            div
            {
                padding-right: 10px;
                border-top-right-radius: 30px;
            }
        }
    }
    tbody
    {
        button.expanded
        {
            border: none;
            cursor: pointer;
            transition: 150ms;
            border-radius: 0;
            &:hover
            {
                border-bottom: 1px solid #4284e6;
                transition: 150ms;
            }
        }
        strong
        {
            color: #4284e6;
        }
        tr
        {
            td
            {
                div
                {
                    padding: 0px 0;
                }
                p
                {
                    color: #9ca6a6;
                }
            }
            svg
            {
                margin-right: 3px;
            }
            .max-content
            {
                min-width: max-content;
            }
        }
        tr:last-of-type
        {
            td
            {
                border-bottom: none;
            }
        }
        tr.container-table td:first-of-type
        {
            padding: 0;
        }
    }
`;

export const SubscriptionSubTable = (props: UserSubscriptionsType) =>
{
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            <tr>
                <td>
                    <SubscriptionName className="max-content">
                        <span style={{cursor: "pointer"}} onClick={() => setIsExpanded(!isExpanded)}>
                            {props.subscriptionPayments.length > 0 && (isExpanded
                                ? <ChevronDownIcon className="brothers-btn-icon" />
                                : <ChevronRightIcon className="brothers-btn-icon" />
                            )}
                            {props.subscriptionName}
                            {props.subscriptionPayments.length > 0 && ` (${props.subscriptionPayments.length})`}
                        </span>
                    </SubscriptionName>
                </td>
                <td>{<p className="max-content">{props.subscriptionStartDate}</p>}</td>
                <td>{<p className="max-content">{props.subscriptionEndDate}</p>}</td>
                <td>{<p className="max-content">{props.subscriptionStatus}</p>}</td>
            </tr>
            {isExpanded && (
                <tr className="container-table">
                    <td colSpan={12}>
                        <SubTableDiv>
                            <SubTable>
                                <thead>
                                    <tr>
                                        <th>
                                            <div style={{ marginLeft: "5px" }}>Nombre de la Suscripción</div>
                                        </th>
                                        <th>
                                            <div>Fecha de Pago</div>
                                        </th>
                                        <th>
                                            <div>Fecha de Validez</div>
                                        </th>
                                    </tr>
                                </thead>
                                <PaymentTableStyle>
                                    {props.subscriptionPayments !== undefined && props.subscriptionPayments.length > 0 && props.subscriptionPayments.map((row, index) =>
                                        {
                                            return <PaymentTable key={index} subscriptionName={row.subscriptionName} paymentStartDate={row.paymentStartDate} paymentEndDate={row.paymentEndDate} />
                                        })
                                    }
                                </PaymentTableStyle>
                            </SubTable>
                        </SubTableDiv>
                    </td>
                </tr>
            )}
        </>
    );
};

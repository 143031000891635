import { useEffect, useState } from 'react';
import { DefaultButton, DetailsList, IColumn, PrimaryButton, Separator, Spinner, SpinnerSize } from '@fluentui/react';
import { Link, useHistory, useParams } from "react-router-dom";
import sharedPortfoliosService, { SharedPortfolioType, SharedPortfolioStatsType } from "../../../services/SharedPortfoliosService";
import scrollToTop from '../../../utils/scrollToTop';
import FundService from '../../../services/FundService';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { addLine } from '../../../store/cart/actions';
import BackButton from "../../../components/buttons/BackButton";
import DocumentTitle from 'react-document-title';
import { getScoreIcons } from '../../../utils/scoreToIcons';
import NumberFormat from 'react-number-format';
import debounce from 'lodash.debounce';
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { ai } from '../../../services/TelemetryService';
import Auth from '../../../Auth/Auth';
import { ShoppingCartIcon } from '@fluentui/react-icons-mdl2';
import AppendCartLine from '../../../utils/AppendCartLine';
import { ActiveIcon, InactiveIcon } from '../../../components/icons/IconsTable';

const SharedPortfolio = () =>
{
    const { sharedPortfolioId } = useParams<any>();
    const [error, setError] = useState({ status: false, message: "" });
    const [sharedPortfolio, setSharedPortfolio] = useState<SharedPortfolioType>();
    const [sharedPortfolioStats, setSharedPortfolioStats] = useState<SharedPortfolioStatsType>();
    const [isSendingToCart, setIsSendingToCart] = useState(false);
    const [puntualContribution, setPuntualContribution] = useState(1000);
    const dispatch = useDispatch();
    const user = Auth.getUserProfile();
    const history = useHistory();
    const isActiveManagementPlan: boolean = Auth.isActiveManagementPlan();
    const appInsights = ai.reactPlugin;
    const trackClicks = useTrackEvent(appInsights, "Visita a cartera compartida", "");
    const activeSavingsPlanHasOutdatedcontract = useSelector((state: RootStateOrAny) => state.auth.outdatedContract);

    const onChangeQuantity = (finametrixId: number, values: any) =>
    {
        if(sharedPortfolioStats?.distribution !== undefined)
        {
            var { floatValue } = values;
            setSharedPortfolioStats((prevState: any) =>
            {
                var element = prevState.distribution.find((item: any) => item.instrumentId === finametrixId);
                element.amount = floatValue;
                return {
                    ...prevState,
                    distribution: [
                        ...prevState.distribution.filter((item: any) => item.instrumentId !== finametrixId),
                        element
                    ].sort((itemA: any, itemB: any) => itemA.weight - itemB.weight)
                }
            });
        }
    };

    const addToCart = async (item: any) =>
    {
        AppendCartLine(
            dispatch,
            history,
            {
                fund: item.fund,
                amount: 0
            }
        );
    };

    const columns: IColumn[] = [
        {
            key: 'fundIssin',
            name: 'ISIN',
            fieldName: 'fundIssin',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: any) =>
            {
                if(item.fund === undefined)
                {
                    return "";
                }
                return (
                    <Link to={
                    {
                        pathname: `/${item.fund.isin}`,
                        state: {fund: item.fund},
                    }}>
                        {item.fundIssin}
                    </Link>
                );
            }
        },
        {
            key: 'category',
            name: 'Categoría',
            fieldName: 'category',
            minWidth: 100,
            maxWidth: 300
        },
        {
            key: 'fundName',
            name: 'Fondo',
            fieldName: 'fundName',
            minWidth: 650,
            onRender: (item: any) =>
            {
                if(item.fund === undefined)
                {
                    return "";
                }
                return (
                    <Link to={
                    {
                        pathname: `/${item.fund.isin}`,
                        state: {fund: item.fund},
                    }}>
                        {item.fundName}
                    </Link>
                );
            }
        },
        {
            key: 'averageScore',
            name: 'Puntos IronIA',
            fieldName: 'averageScore',
            minWidth: 130,
            onRender: (item: any) =>
            {
                if(item.fund === undefined)
                {
                    return "";
                }
                const scoreIcons = getScoreIcons(item.fund.averageScore);
                return(
                    scoreIcons.map((active: any, activeIndex: number) =>
                    {
                        return active
                            ? <ActiveIcon key={item + 'activeIndex' + activeIndex} />
                            : <InactiveIcon key={item + 'activeIndex' + activeIndex} />
                    })
                );
            }
        },
        {
            key: 'weight',
            name: 'Peso',
            fieldName: 'weight',
            className: 'text-right',
            minWidth: 60,
            onRender: (item: any) =>
            (
                <>{(item.weight * 100).toFixed(2)}%</>
            ),
        }
    ];

    if(!isActiveManagementPlan)
    {
        columns.push(
            {
                key: 'amount',
                name: 'Importe',
                fieldName: 'amount',
                className: 'text-right',
                minWidth: 100,
                onRender: (item: any) =>
                (
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={item.amount}
                        onValueChange={(values) => onChangeQuantity(item.instrumentId, values)}
                        suffix=" €"
                        className="ms-TextField-field"
                    />
                ),
            },
            {
                key: 'addToCart',
                name: '',
                fieldName: 'addToCart',
                className: 'text-right',
                minWidth: 35,
                maxWidth: 35,
                onRender: (item: any) => (
                    <PrimaryButton
                        className="light-circled-icon"
                        id={`cartBtn_${item.fundIssin}`}
                        onClick={() => addToCart(item)}
                    >
                        <ShoppingCartIcon className="ironia-table-icon" shopping-cart />
                    </PrimaryButton >
                )
            }
        );
    }

    useEffect(() =>
    {
        scrollToTop();
    }, []);

    useEffect(() =>
    {
        sharedPortfoliosService.getSharedPortfolio(sharedPortfolioId)
            .then((data: SharedPortfolioType) =>
            {
                setSharedPortfolio(data);
            },
                (error) =>
                {
                    setError(error);
                });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        sharedPortfoliosService.getLastSharedPortfolioDailyStatBySharedPortfolioId(sharedPortfolioId)
            .then(async (data: SharedPortfolioStatsType) =>
            {
                var ids = data.distribution.map((instrument: any) => instrument.instrumentId);
                await FundService.getMultipleFundsByIds(ids).then((items: any) =>
                {
                    data.distribution.forEach(async (compositionItem: any) =>
                    {
                        var selectedItem = items.find((item: any) => item.finametrixId === compositionItem.instrumentId);
                        compositionItem.fund = selectedItem;
                        compositionItem.amount = (puntualContribution || 0) * compositionItem.weight;
                    });
                });
                setSharedPortfolioStats(data);
            },
                (error) =>
                {
                    setError(error);
                });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(sharedPortfolio !== undefined)
        {
            sharedPortfoliosService.patchSharedPortfolio(sharedPortfolioId, { sharedTimes: sharedPortfolio.sharedTimes + 1 });

            trackClicks(`${sharedPortfolio.name} - ${user.id}`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedPortfolio]);

    const recalculate = () =>
    {
        if(sharedPortfolioStats?.distribution !== undefined)
        {
            sharedPortfolioStats.distribution.forEach(async (compositionItem: any) =>
            {
                compositionItem.amount = parseFloat(((puntualContribution || 0) * compositionItem.weight).toFixed(2));
            });

            var sum = sharedPortfolioStats?.distribution.reduce((sum: number, item: any) =>
            {
                return sum + item.amount;
            }, 0);

            sum = parseFloat(sum.toFixed(2));

            if(sum !== puntualContribution)
            {
                var step = puntualContribution > sum ? 0.01 : -0.01;

                var index = 0;
                do
                {
                    if(sharedPortfolioStats.distribution[index] != null)
                    {
                        sharedPortfolioStats.distribution[index].amount += step;
                    }
                    index++;

                    if(index >= sharedPortfolioStats.distribution.length)
                    {
                        index = 0;
                    }

                    sum = sharedPortfolioStats?.distribution.reduce((sum: number, item: any) =>
                    {
                        return sum + item.amount;
                    }, 0);

                    sum = parseFloat(sum.toFixed(2));
                } while (sum !== puntualContribution);
            }

            setSharedPortfolioStats((prevState: any) =>
            {
                return {
                    ...prevState,
                    distribution: sharedPortfolioStats.distribution
                }
            });
        }
    };

    const addFundsToCart = async () =>
    {
        // FIXME: habría que revisar que si el plan activo es isActiveManagementPlan
        if(sharedPortfolioStats?.distribution !== undefined)
        {
            setIsSendingToCart(true);

            sharedPortfolioStats.distribution.forEach(async (compositionItem: any) =>
            {
                await dispatch(addLine(
                {
                    fund: compositionItem.fund,
                    amount: parseFloat(compositionItem.amount.toFixed(2))
                }));
            });
            return history.push("/cart");
        }
    };

    return(
        <DocumentTitle title={`IronIA - Cartera compartida ${sharedPortfolio ? sharedPortfolio.name : ''}`}>
            <div className="shared-portfolio">
                <div className="ms-Grid-row">
                    <BackButton />
                </div>
                <div className="ms-Grid-row">
                    <h2>{isActiveManagementPlan ? 'Ver' : 'Copiar'} cartera de {sharedPortfolio ? sharedPortfolio.name : ''}</h2>
                </div>
                {!isActiveManagementPlan && (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            {sharedPortfolioStats === undefined && (
                                <Spinner size={SpinnerSize.large} />
                            )}
                            {sharedPortfolioStats !== undefined && (
                                <div className="number-control big-font">
                                    <label>Importe total a invertir</label>
                                    <NumberFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        value={puntualContribution}
                                        onValueChange={debounce(
                                            (value: any) =>
                                            {
                                                setPuntualContribution(value.floatValue);
                                            },
                                            500
                                        )}
                                        suffix="€"
                                        className="ms-TextField-field"
                                    />
                                    <DefaultButton
                                        className="button tiny-primary-button"
                                        onClick={recalculate}
                                    >
                                        Recalcular
                                    </DefaultButton>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <Separator className="separator" />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        {sharedPortfolioStats !== undefined && (
                            <DetailsList
                                items={sharedPortfolioStats.distribution}
                                columns={columns}
                                selectionMode={0}
                            />
                        )}
                    </div>
                </div>
                {!isActiveManagementPlan && !activeSavingsPlanHasOutdatedcontract && (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 text-right">
                            <DefaultButton
                                className="button tiny-primary-button"
                                onClick={addFundsToCart}
                                disabled={sharedPortfolioStats === undefined}
                            >
                                {isSendingToCart && <Spinner size={SpinnerSize.small} className="button-spinner" />} Añadir a la cesta
                            </DefaultButton>
                        </div>
                    </div>
                )}
            </div>
        </DocumentTitle>
    );
};

export default SharedPortfolio;
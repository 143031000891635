import styled from "styled-components";

type MainButtonProps =
{
    size?: "sm" | "base" | "lg";
    block?: boolean;
};

const MainButton = styled.button<MainButtonProps>`
    background-color: #cc2250;
    border-radius: 3rem;
    color: white;
    border: none;
    font-size: ${(props) =>
    {
        switch(props.size)
        {
            case "lg":
                return `16px`;
        }
    }};
    padding: ${(props) =>
    {
        switch(props.size)
        {
            case "lg":
                return `1rem 2rem`;
            default:
                return "0.75rem 1.5rem";
        }
    }};
    outline: none;
    cursor: pointer;
    width: ${(props) => (props.block ? "100%" : "auto")};
    &[disabled]
    {
        background-color: ${props => props?.theme?.palette?.neutralLighter || "#E9EFF1"};
        color: ${props => props?.theme?.palette?.middleBorder || "#93AEB9"};
        box-shadow: none;
        cursor: not-allowed;
    }
`;
const SecondaryButton = styled.button<MainButtonProps>`
    background-color: ${props => props?.theme?.palette?.lightBlack || "#556769"};
    border-radius: 3rem;
    color: white;
    border: none;
    font-size: ${(props) =>
    {
        switch(props.size)
        {
            case "lg":
                return `16px`;
        }
    }};
    padding: ${(props) =>
    {
        switch(props.size)
        {
            case "lg":
                return `1rem 2rem`;
            default:
                return "0.75rem 1.5rem";
        }
    }};
    outline: none;
    cursor: pointer;
    width: ${(props) => (props.block ? "100%" : "auto")};
`;
const EmptyButton = styled.button<MainButtonProps>`
    background-color: ${props => props?.theme?.palette?.white || "#FFF"};
    border-radius: 3rem;
    color: ${props => props?.theme?.palette?.borderPrimary || "#CC214F"};
    border: 2px solid ${props => props?.theme?.palette?.borderPrimary || "#CC214F"};
    font-size: ${(props) =>
    {
        switch(props.size)
        {
            case "lg":
                return `16px`;
        }
    }};
    padding: ${(props) =>
    {
        switch(props.size)
        {
            case "lg":
                return `1rem 2rem`;
            default:
                return "0.75rem 1.5rem";
        }
    }};
    outline: none;
    cursor: pointer;
    width: ${(props) => (props.block ? "100%" : "auto")};
`;
export const Button =
{
    Primary: MainButton,
    Secondary: SecondaryButton,
    EmptyButton: EmptyButton,
};
import React from "react";
import DocumentTitle from "react-document-title";
import * as S from './ElementsIARebateStyle';
import Auth from "../../Auth/Auth";
import { ThemeContext } from '@fluentui/react-theme-provider';
import { serverHost } from "../../config";
import { Redirect, useHistory } from "react-router";
import { Separator, Spinner, SpinnerSize } from "@fluentui/react";
import { SignatureComponent } from "../../components/Signature/SignatureComponent";
import scrollToTop from "../../utils/scrollToTop";
import SignaturesService, { ISignature } from "../../services/SignaturesService";
import moment from "moment";
import ModalElementPromotionSigned from "./ModalElementPromotionSigned";
import { UserIdentityType } from "../../services/UserIdentitiesService";
import savingsPlansUserIdentities from "../../services/SavingsPlanUserIdentityService";

const ElementsIARebate = () =>
{
    const user = Auth.getUserProfile();
    const subscribed = Auth.isSubscribed();
    const outdated = Auth.isLastPlanOutdated();
    const cancelled = Auth.isActivePlanCancelled();
    const activePlan = Auth.getActivePlan();
    const theme = React.useContext(ThemeContext);
    const [bottom, setBottom] = React.useState<boolean>(false);
    const [enableSignature, setEnableSignature] = React.useState<boolean>(false);
    const history = useHistory();
    const [modalShow, setModalShow] = React.useState<boolean>(false);
    const [signatures, setSignatures] = React.useState<ISignature[]>([]);
    const [allContractIdentities, setAllContractIdentities] = React.useState<UserIdentityType[]>([]);
    const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
    const isFirstLoading = React.useRef<boolean>(true);

    React.useEffect(() =>
    {
        scrollToTop();

        if(isFirstLoading.current === true)
        {
            isFirstLoading.current = false;
            savingsPlansUserIdentities.getAllUserIdentitiesFromContract(activePlan.finametrixContract).then((response: any) =>
            {
                if(response.length !== 0)
                {
                    setAllContractIdentities(response);
                }
            });
        }
    }, [activePlan]);

    const processExpirationDate = (signs: ISignature[]) =>
    {
        if(signs !== undefined && signs.length > 0)
        {
            // eslint-disable-next-line array-callback-return
            signs.map((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = moment(signature.expiresAt).toDate();
                }
            });
        }
    }

    const handleScroll = (event: any) =>
    {
        const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 1;
        setBottom(bottom);
    };

    const handleClick = () =>
    {
        setEnableSignature(true);
        if(signatures.length === 0)
        {
            SignaturesService.createSignatureByFinametrixContract(activePlan.id).then((response: any) =>
            {
                if(response.data.length !== 0 && response.data[0].id !== null)
                {
                    setSignatures(response.data);
                    setIsLoaded(true);
                    scrollToTop();
                }
                else
                {
                    setEnableSignature(false);
                }
            });
        }
    }

    const handleSubmit = () =>
    {
        if(signatures.length > 0 || !modalShow)
        {
            scrollToTop();
            setModalShow(true)
        }
    };

    if(user === undefined || user === null)
    {
        localStorage.clear();
        window.location.href = serverHost + '/login';
        return (<></>);
    }

    if(subscribed === false)
    {
        localStorage.clear();
        window.location.href = serverHost + '/login';
        return (<></>);
    }

    if(outdated === true)
    {
        return (<Redirect to="/savingsPlans" />);
    }

    if(cancelled === true)
    {
        return (<Redirect to="/savingsPlans" />);
    }

    return(
        <React.Fragment>
            <DocumentTitle title={"IronIA - Contrato Promoción Elements"}>
                <React.Fragment>
                    <S.MainPage>
                        <S.MainTitle>
                            Ahora tus retrocesiones, dividendos y redondeos son Elements IA
                        </S.MainTitle>
                        <Separator />
                        <S.MainSubTitle>
                            Contrato de suscripción a la Promoción "Ahora tus retrocesiones, dividendos
                            y redondeos son Elements IA".
                        </S.MainSubTitle>
                        {isFirstLoading.current === true &&
                        (
                            <React.Fragment>
                                <Spinner size={SpinnerSize.large} />
                            </React.Fragment>
                        )}
                        {isFirstLoading.current === false &&
                        (
                            <React.Fragment>
                                <S.ContractPanel theme={theme} onScroll={handleScroll}>
                                    <S.FirstParagraph>
                                        {allContractIdentities !== undefined && allContractIdentities !== null && allContractIdentities.length > 0 &&
                                        (
                                            <React.Fragment>
                                                {allContractIdentities.length === 1 &&
                                                (
                                                    <React.Fragment>
                                                        <b>{allContractIdentities[0].firstName} {allContractIdentities[0].lastName}</b>, con DNI <b>{allContractIdentities[0].idDocument}</b>
                                                        , en calidad de Cliente, y Simplicitas Capital SL, en calidad de proveedor de la promoción establecen:
                                                    </React.Fragment>
                                                )}
                                                {allContractIdentities.length > 1 &&
                                                (
                                                    allContractIdentities.map((item: UserIdentityType, idx: number) =>
                                                    {
                                                        if(idx !== allContractIdentities.length-1)
                                                        {
                                                            return(
                                                                <React.Fragment key={idx}>
                                                                    <b> {item.firstName} {item.lastName}</b>, con DNI <b>{item.idDocument}</b>, 
                                                                </React.Fragment>
                                                            )
                                                        }
                                                        else
                                                        {
                                                            return(
                                                                <React.Fragment key={idx}>
                                                                    <b> {item.firstName} {item.lastName}</b>, con DNI <b>{item.idDocument}</b>
                                                                    , en calidad de Clientes y Simplicitas Capital SL, en calidad de proveedor de la promoción establecen:
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    })
                                                )}
                                            </React.Fragment>
                                        )}
                                    </S.FirstParagraph>
                                    <S.SecondParagraph>
                                        Que la firma del presente contrato de suscripción se encuentra regulada en sus términos generales por el contrato de Elements previamente firmado por ambas partes, siendo este contrato las condiciones específicas de esta promoción.
                                    </S.SecondParagraph>
                                    <S.SpecificConditions>
                                        Condiciones específicas.
                                    </S.SpecificConditions>
                                    <S.FullParagraph>
                                        <S.SmallTitle>
                                            Obtención de Elements mediante retrocesiones.
                                        </S.SmallTitle>
                                        Por la presente el cliente autoriza la conversión a Elements IA de las retrocesiones obtenidas derivadas de la compra de productos financieros. Para el cálculo del valor de los Elements IA se toma el valor de las retrocesiones en euros y se multiplica por 100, siguiendo la equivalencia 1 euro es igual a 100 Elements IA. Los Elements IA no tienen cambios de valor en el tiempo.
                                        <S.SmallTitle>
                                            Obtención de Elements mediante dividendos.
                                        </S.SmallTitle>
                                        Por la presente el cliente autoriza la conversión a Elements IA de los dividendos obtenidos derivados de la compra de productos financieros. Para el cálculo del valor de los Elements IA se toma el valor de los dividendos en euros y se multiplica por 100, siguiendo la equivalencia 1 euro es igual a 100 Elements IA. Los Elements IA no tienen cambios de valor en el tiempo.
                                        <S.SmallTitle>
                                            Obtención de Elements mediante redondeos.
                                        </S.SmallTitle>
                                        Por la presente el cliente autoriza la conversión a Elements IA de los redondeos obtenidos derivados de la compra de productos financieros. Para el cálculo del valor de los Elements IA se toma el valor de los redondeos en euros y se multiplica por 100, siguiendo la equivalencia 1 euro es igual a 100 Elements IA. Los Elements IA no tienen cambios de valor en el tiempo.
                                        <br/>
                                        <br/>
                                        Se entiende por redondeo la diferencia entre importe de compra de una operación y el importe neto de dicha operación, pudiendo ser un resultado positivo o negativo. En el caso de que el importe neto sea superior al importe de compra se procederá a realizar una sustracción de Elements IA de la cuenta del cliente, pudiendo quedar esta en negativo. Si el cliente causase baja de la plataforma de IronIA, el proveedor de la promoción estaría en su derecho de reclamar la cantidad negativa de Elements IA antes de proceder a dicha baja.
                                        <S.SmallTitle>
                                            Duración.
                                        </S.SmallTitle>
                                        Esta suscripción no tiene duración específica; para causar baja de la misma, el cliente debe enviar un correo a <a href="mailto:elements@ironia.tech">elements@ironia.tech</a>, especificando el código de firma que aparecerá en su contrato y se procederá a la baja en periodo máximo de 48 horas.
                                        Si el cliente causa baja en la plataforma, el proveedor tiene derecho a reclamar los Elements que estén en negativo antes de dicha baja, siendo responsabilidad del cliente dejar su cuenta a 0 antes del causar la baja. Por consiguiente, el cliente autoriza a que cualquier saldo positivo de Elements IA después de la baja se traspasen al proveedor y pueda disponer de estos para su conversión a euros cumpliendo las obligaciones fiscales a las que esté sujeta dicha operación en cada momento.
                                        <S.SmallTitle>
                                            Reclamaciones.
                                        </S.SmallTitle>
                                        Todas las reclamaciones se realizarán según las condiciones generales del contrato Elements.
                                        <S.SmallTitle>
                                            Implementación.
                                        </S.SmallTitle>
                                        La implementación de los procesos que realizan la conversión de las retrocesiones, dividendos y redondeos se implementarán a lo largo del cuarto trimestre del 2023 y el primer trimestre del 2024. Hasta su implementación los procesos para su abono son los estipulados actualmente.
                                        <S.SmallTitle>
                                            Conversión de Elements IA.
                                        </S.SmallTitle>
                                        En la página <a href={"https://store.ironia.tech/ElementsIA"}>https://store.ironia.tech/ElementsIA</a> de la plataforma IronIA Store el cliente podrá trasferir sus Elements IA a euros mediante una trasferencia bancaria SEPA no inmediata. Por razones de eficiencia la trasferencia mínima que se permite realizar es de 1000 Elements IA equivalente a 100 €.
                                        <br/>
                                        <br/>
                                        Asimismo, el cliente podrá utilizar los Elements IA para realizar distintas operaciones en la plataforma según el precio de estas fijado en Elements IA, también puede trasformar los Elements IA en otro tipo de Elements para abonar las suscripciones a la plataforma. 
                                    </S.FullParagraph>
                                </S.ContractPanel>
                                <S.DivRowButton className="ms-Grid-row">
                                    {enableSignature === false &&
                                    (
                                        <S.DivColButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                        {bottom === true &&
                                        (
                                            <React.Fragment>
                                                <S.SubscribeButton onClick={handleClick}>
                                                    Firma
                                                </S.SubscribeButton>
                                            </React.Fragment>
                                        )}
                                        {bottom === false &&
                                        (
                                            <React.Fragment>
                                                <S.DisabledButton disabled={true}>
                                                    Firma
                                                </S.DisabledButton>
                                            </React.Fragment>
                                        )}
                                        </S.DivColButton>
                                    )}
                                </S.DivRowButton>
                                {enableSignature === true && !isLoaded &&
                                (
                                    <React.Fragment>
                                        <Spinner size={SpinnerSize.large} />
                                    </React.Fragment>
                                )}
                                {enableSignature === true && isLoaded &&
                                (
                                    <React.Fragment>
                                        <S.SignatureContainer>
                                            <SignatureComponent
                                                alreadySignedText={"Ya has firmado tu contrato"}
                                                submitButtonText={"Firmar contrato"}
                                                backButtonText={"Volver"}
                                                cancelButtonText={"Cancelar Firma"}
                                                onBackButtonClicked={() => history.push('/ElementsIA')}
                                                onCancelButtonClicked={() => history.push('/ElementsIA')}
                                                signatures={signatures}
                                                title={"Firma tu contrato de suscripción a la promoción"}
                                                requestNewCodeButtonText={"Recibe un nuevo código"}
                                                userIdentities={allContractIdentities}
                                                onNewCodeSended={(signatures: any) =>
                                                {
                                                    processExpirationDate(signatures);
                                                    setSignatures(signatures);
                                                }}
                                                onSignatureChanged={(signatures: any) =>
                                                {
                                                    processExpirationDate(signatures);
                                                    setSignatures(signatures);
                                                }}
                                                onSigned={handleSubmit}
                                            />
                                        </S.SignatureContainer>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </S.MainPage>
                    {modalShow && <ModalElementPromotionSigned setModalShow={setModalShow} />}
                </React.Fragment>
            </DocumentTitle>
        </React.Fragment>
    );
}

export default ElementsIARebate;
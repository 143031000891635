import styled from "styled-components";

const Card = styled.div`
  background-color: white;
  padding: 1.75rem;
  border-radius: 0.75rem;
  box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
`;

export const Cards = {
  Card,
};

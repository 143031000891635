import React from "react";
import { ExcelDocumentIcon, ExcelLogoIcon } from "@fluentui/react-icons";
import * as XLSX from "xlsx";
import { TooltipHost } from "@fluentui/react";
import styles from './index.module.sass'

export interface Props
{
    headers: string[],
    getDataToExport: () => any[],
    name: string
}

export const ExportIndex: React.FunctionComponent<Props> = (props: Props) =>
{
    const date = (new Date(Date.now()).toLocaleDateString()).replaceAll("/", "-");

    const handleCSV = () =>
    {
        const data = props.getDataToExport();

        var csv: any = [];
        var csvFile, downloadLink;

        csv.push(props.headers.join(";"));

        for (var i = 0; i < data.length; i++)
        {
            csv.push(data[i].join(";"));
        }

        csv = csv.join("\n");
        csvFile = new Blob(['\uFEFF' + csv], {type: "text/csv;charset=utf-8"});

        downloadLink = document.createElement("a");
        downloadLink.download = props.name+"-"+date+".csv";
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }

    const handleExcel = () =>
    {
        const data = props.getDataToExport();

        const wb = XLSX.utils.book_new();
        wb.Props =
        {
            Title: props.name + "-" + date + ".xlsx",
            CreatedDate : new Date()
        };
        wb.SheetNames.push(date);

        var allData: any[] = new Array(data.length+1).fill(0).map(() => new Array(props.headers.length).fill(0));

        for (let i = 0; i < data.length; i++)
        {
            if (i === 0)
            {
                for (let j = 0; j < props.headers.length; j++)
                {
                    allData[i][j] = props.headers[j];
                }

                for (let j = 0; j < data[i].length; j++)
                {
                    allData[i + 1][j] = data[i][j];
                }
            }
            else
            {
                for (let j = 0; j < data[i].length; j++)
                {
                    allData[i + 1][j] = data[i][j];
                }
            }
        }

        var ws = XLSX.utils.aoa_to_sheet(allData);
        wb.Sheets[date] = ws;
        XLSX.writeFile(wb, props.name + "-" + date + ".xlsx");
    }

    return(
        <>
            <TooltipHost
                content="Excel"
                id={`excel`}
                className={styles.tooltip}
            >
                <ExcelLogoIcon
                    className={styles.icon}
                    onClick={handleExcel}
                >
                    Excel
                </ExcelLogoIcon>
            </TooltipHost>
            <TooltipHost
                content="CSV"
                id={`csv`}
                className={styles.tooltip}
            >
                <ExcelDocumentIcon
                    className={styles.icon}
                    onClick={handleCSV}
                >
                    CSV
                </ExcelDocumentIcon>
            </TooltipHost>
        </>
    );
}
import React from "react";
import styled from "styled-components";

type IndexNumberProps = {
  i: number;
};

const P = styled.p`
  color: #c1c1c1;
`;

export const IndexNumber: React.FC<IndexNumberProps> = ({ i }) => {
  return <P>{i < 10 ? `0${i}` : i}.</P>;
};

import styled from "styled-components";

export const RatioRollingComparerMessage = styled.p`
    cursor: default;
`;
export const SpanRatioRolling = styled.span`
    font-weight: 700;
`;
export const RowPeriodButtons = styled.div`
    cursor: default;
`;
export const PeriodButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 2.5rem;
    font-size: 18px;
    gap: 5px;
    cursor: pointer;
    justify-content: left;

    button
    {
        border: 0;
        border-radius: 4px;
        background: #F3F7F9;
        font-size 12px;
        font-weight: 400;
        font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
        color: rgb(42, 65, 67);
        width: 40px;
        height: 25px;
        text-align: center;
        display: inline-block;
        margin: 0;
        cursor: pointer;

        @keyframes shrink
        {
            0%
            {
                transform: scale(0);
            }
            100%
            {
                transform: scale(1);
            }
        }

        &.active
        {
            color: white;
            background: #CC214F;
            font-weight: bold;
            display: block;
            animation: shrink 0.5s ease forwards;
        }
    }
`;
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

export const Cont = styled.div`
    grid-area: main;
    background-color: #fafcfd;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
`;
export const MainCont = styled.div`
    padding: 40px 55px;
    hr
    {
        margin: 10px 0;
        border: solid 1px #eff4f6;
    }
    @media screen and (max-width: 599px)
    {
        padding: 40px 25px;
    }
`;
export const Title = styled.div`
    margin-top: 30px;
    h1
    {
        font-size: 30px;
        color: #2a4143;
        font-weight: 800;
    }
    h2
    {
        font-size: 24px;
        font-weight: 400;
        color: #5f7172;
    }
`;
export const Header = styled.h1`
    cursor: default;
`;
export const ContractSubTitle = styled.h2`
    cursor: default;
`;
export const BarCont = styled.div`
    position: relative;
    width: 100%;
    height: 20px;
`;
export const BgBar = styled.hr`
    position: absolute;
    width: 100%;
    border: solid 6px #fae9ed;
    border-radius: 10px;
    top: 50%;
    transform: translate(0%, -50%);
`;
export const ProsBar = styled.hr`
    position: absolute;
    width: calc(100% - 8.33%);
    border: solid 4px #cc214f;
    border-radius: 10px;
    top: 50%;
    transform: translate(0%, -50%);
`;
export const ScrollableTabletCont = styled.div`
    background-color: #f3f7f9;
    width: 100%;
    padding: 35px 25px 30px 25px;
    border-radius: 20px;
    margin-top: 20px;
    max-height: 400px;
    overflow: auto;
    hr
    {
        margin: 40px 0;
        border: solid 1px #e9eff2;
    }
`;
export const TabletTitle = styled.h3`
    color: #2a4143;
    font-size: 20px;
    font-weight: 800;
`;
export const SubTitle = styled.h4`
    color: #2a4143;
    font-size: 18px;
    font-weight: 500;
    span
    {
        color: #4284e6;
    }
`;
export const Phrase = styled.p`
    color: #95a0a1;
    font-size: 14px;
    font-weight: 400;
`;
export const BtnCont = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 40px;
    @media screen and (max-width: 599px)
    {
        justify-content: center;
        flex-flow: row wrap;
        margin-top: 40px;
        a
        {
            margin: 0 10px;
        }
        button
        {
            margin-bottom: 20px;
        }
    }
`;
export const HelperStyle = createGlobalStyle`
    *,
    ::after,
    ::before
    {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    } 
    ul
    {
        list-style-type: none;
    }
    a
    {
        text-decoration: none;
    } 
`;
export const SignatureContainer = styled.div`

`;
import React from "react";
import { getScoreIcons } from "../../../utils/scoreToIcons";
import { FundName, PSecondTable, SubTableSearch } from "./SearchManagersStyle";
import { ChevronRightIcon, ChevronDownIcon } from '@fluentui/react-icons-mdl2';
import { TooltipHost } from "@fluentui/react";
import { ActiveIcon, InactiveIcon } from "../../icons/IconsTable";
import SearchEngineThirdTable from "./SearchEngineThirdTable";
import { translate } from "../../../utils/i18n";

interface SecondTableSearchEngine
{
    group: any,
    fund: any
}
const SearchEngineSecondTable = (props: SecondTableSearchEngine) =>
{
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
    const iconArray = getScoreIcons(props.fund.children[0].averageScore);
    
    return(
        <React.Fragment>
            <tr>
                <td>
                    <FundName className="max-content">
                        <button className="expanded" onClick={() => setIsExpanded(!isExpanded)}>
                            {props.fund?.children?.length > 0 && (isExpanded
                                ? <ChevronDownIcon className="brothers-btn-icon" />
                                : <ChevronRightIcon className="brothers-btn-icon" />
                            )}
                            {props.fund.family}
                            {props.fund?.children?.length > 0 && ` (${props.fund?.children?.length})`}
                        </button>
                    </FundName>
                </td>
                <td>
                    <PSecondTable>
                        {translate(props.fund.children[0].manager)}
                    </PSecondTable>
                </td>
                <td>
                    <PSecondTable>
                        {props.fund.children[0].subcategory}
                    </PSecondTable>
                </td>
                <td>
                    <TooltipHost content={`${parseFloat(props.fund.children[0].averageScore).toFixed(2)}/100`}>
                        <div className="max-content">
                            {iconArray.map((active: any, iconIndex: number) =>
                            {
                                return active ? <ActiveIcon key={'iconIndex'+iconIndex} /> : <InactiveIcon key={'iconIndex'+iconIndex} />;
                            })}
                        </div>
                    </TooltipHost>
                </td>
            </tr>
            {isExpanded &&
            (
                <tr className="container-table">
                    <td colSpan={4}>
                        <SubTableSearch>
                            <SearchEngineThirdTable children={props.group.children} />
                        </SubTableSearch>
                    </td>
                </tr>
            )}
        </React.Fragment>
    );
}
export default SearchEngineSecondTable;
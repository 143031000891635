import styled from "styled-components";
import { PaymentsSubscriptionsType } from "../../../../services/SubscriptionService";

export const PaymentStyle = styled.tr`
    td
    {
        text-align: left;
        div
        {
            padding: 0px 0;
        }
        p
        {
            color: #9ca6a6;
        }
    }
    svg
    {
        margin-right: 3px;
    }
    .max-content
    {
        min-width: max-content;
    }
    tr:last-of-type
    {
        td
        {
            border-bottom: none;
        }
    }
    tr.container-table td:first-of-type
    {
        padding: 0;
    }
`;

export const PaymentTable = (props: PaymentsSubscriptionsType) =>
{
    return(
        <PaymentStyle>
            <td>{props.subscriptionName}</td>
            <td>{props.paymentStartDate}</td>
            <td>{props.paymentEndDate}</td>
        </PaymentStyle>
    );
}
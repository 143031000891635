import axios from 'axios';
import { analysisService } from "../config";

class FundDetailsService
{
    public getDetails = async (allFundsId: any, finametrixId: any) =>
    {
        const response = await axios.get(`${analysisService}/analyze/${allFundsId}/${finametrixId}`);
        return response.data;
    };
    
    public getSeries = async (finametrixIds: any) =>
    {
        const response = await axios.get(`${analysisService}/prices`, {params: {products: finametrixIds}});
        return response.data;
    };
}

const FundDetails = new FundDetailsService();
export default FundDetails;
import * as S from '../../ConvenienceTest/ConvenienceTest.style';
import { TextField, DatePicker } from '@fluentui/react';
import { UserIdentityType } from '../../../services/UserIdentitiesService';
import { DayPickerStrings } from '../../ConvenienceTest/Utils/DatesTranlateFluents';
import { DatePickerStyle, InputStyles } from '../../GlobalStyle';
import moment from 'moment';
import Select from 'react-dropdown-select';

interface UserIdentityPersonalDataProps {
    userIdentityData: UserIdentityType;
    handleInputChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleDateInputChange: (name: string, value: Date | null | undefined) => void;
    handleChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handlePersonalDataSelectChange: (name: string, value: string) => void;
    disabled: boolean;
    idTypes: SelectOption[];
    countries: SelectOption[];
    errors: {
        [key: string]: string;
    };
}
interface SelectOption {
    key: string;
    text: string;
    value: string;
}

export const UserIdentityPersonalData = (props: UserIdentityPersonalDataProps) => {
    const { userIdentityData, handleInputChange, handlePersonalDataSelectChange, handleDateInputChange, disabled, countries, idTypes, errors } = props;

    return (
        <>
            {/* Nombre / Apellidos / Fecha de nacimiento */}
            <S.FielSet>
                <S.FieldCont>
                    <TextField
                        label='Nombre'
                        // @ts-ignore
                        styles={InputStyles}
                        id={'firstName'}
                        name={'firstName'}
                        type='text'
                        value={userIdentityData! ? userIdentityData?.firstName! : ''}
                        onChange={handleInputChange}
                        disabled={disabled}
                        required
                    />
                    {errors.firstName !== "" && errors.firstName}
                </S.FieldCont>
                <S.FieldCont>
                    <TextField
                        label='Primer Apellido'
                        // @ts-ignore
                        styles={InputStyles}
                        id={'lastName'}
                        name={'lastName'}
                        type='text'
                        value={userIdentityData! ? userIdentityData?.lastName! : ''}
                        onChange={handleInputChange}
                        disabled={disabled}
                        required
                    />
                    {errors.lastName !== "" && errors.lastName}
                </S.FieldCont>
                <S.FieldCont>
                    <TextField
                        label='Segundo Apellido'
                        // @ts-ignore
                        styles={InputStyles}
                        id={'secondLastName'}
                        name={'secondLastName'}
                        type='text'
                        value={userIdentityData! ? userIdentityData?.secondLastName! : ''}
                        onChange={handleInputChange}
                        disabled={disabled}
                    />
                </S.FieldCont>
                <S.FieldCont>
                    <DatePicker
                        styles={DatePickerStyle}
                        label='Fecha de nacimiento'
                        id={'birthday'}
                        // @ts-ignore
                        name={'birthday'}
                        formatDate={(date) => {
                            return moment(date).format('DD/MM/YYYY');
                        }}
                        onSelectDate={(date) => {
                            if (date) {
                                const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                                handleDateInputChange('birthday', utcDate);
                            }
                        }}
                        value={userIdentityData?.birthday ? new Date(userIdentityData.birthday) : undefined}
                        isRequired
                        disabled={disabled}
                        strings={DayPickerStrings}
                        allowTextInput={true}
                        parseDateFromString={(dateStr) => {
                            var parts: string[] = dateStr.split('/');
                            var myDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
                            return new Date(myDate)
                        }}
                    />
                    {errors.birthday !== "" && errors.birthday}
                </S.FieldCont>
            </S.FielSet>

            {/* Nacionalidad / País de nacimiento / Tipo de documento / Número de documento */}
            <S.FielSet>
                <S.FieldAddressTypeCont>
                    <S.Label>Nacionalidad *</S.Label>
                    <Select
                        name={'nationalCountry'}
                        labelField='text'
                        valueField='value'
                        options={countries}
                        onChange={(selectedItems) => handlePersonalDataSelectChange("nationalCountry", selectedItems.length === 0 ? "" : selectedItems[0].value)}
                        dropdownHeight='2000'
                        searchable={true}
                        disabled={disabled}
                        searchBy="text"
                        noDataLabel={'No encontrado'}
                        values={countries.filter(country => country.value === (userIdentityData?.nationalCountry! || ''))}
                        placeholder={'Selecciona un país'}
                        itemRenderer={({ item, methods }) => (
                            <span
                                role="option"
                                aria-selected="false"
                                aria-label={item.text}
                                // @ts-ignore
                                tabIndex="-1"
                                className="react-dropdown-select-item"
                                onClick={() => methods.addItem(item)}
                                key={'nationalCountry-' + item.value}
                            >
                                {item.text}
                            </span>
                        )}
                        required
                    />
                    {errors.nationalCountry !== "" && errors.nationalCountry}
                </S.FieldAddressTypeCont>
                <S.FieldAddressTypeCont>
                    <S.Label>País de nacimiento *</S.Label>
                    <Select
                        name={'birthdayCountry'}
                        labelField='text'
                        valueField='value'
                        options={countries}
                        onChange={(selectedItems) => handlePersonalDataSelectChange("birthdayCountry", selectedItems.length === 0 ? "" : selectedItems[0].value)}
                        dropdownHeight='2000'
                        searchable={true}
                        disabled={disabled}
                        searchBy="text"
                        noDataLabel={'No encontrado'}
                        values={countries.filter(country => country.value === (userIdentityData?.birthdayCountry! || 'ES'))}
                        placeholder={'Selecciona un país'}
                        itemRenderer={({ item, methods }) => (
                            <span
                                role="option"
                                aria-selected="false"
                                aria-label={item.text}
                                // @ts-ignore
                                tabIndex="-1"
                                className="react-dropdown-select-item"
                                onClick={() => methods.addItem(item)}
                                key={'nationalCountry-' + item.value}
                            >
                                {item.text}
                            </span>
                        )}
                        required
                    />
                </S.FieldAddressTypeCont>
                <S.FieldAddressTypeCont>
                    <S.Label>Tipo de documento</S.Label>
                    <Select
                        name={'idType'}
                        labelField='text'
                        valueField='value'
                        options={idTypes}
                        onChange={(selectedItems) => handlePersonalDataSelectChange("idType", selectedItems.length === 0 ? "" : selectedItems[0].value)}
                        dropdownHeight='2000'
                        searchable={false}
                        disabled={disabled}
                        searchBy="text"
                        noDataLabel={'No encontrado'}
                        values={idTypes.filter(idType => idType.value === (userIdentityData.idType || 'DNI'))}
                        placeholder={'Tipo de documento'}
                        itemRenderer={({ item, methods }) => (
                            <span
                                role="option"
                                aria-selected="false"
                                aria-label={item.text}
                                // @ts-ignore
                                tabIndex="-1"
                                className="react-dropdown-select-item"
                                onClick={() => methods.addItem(item)}
                                key={'idType-' + item.value}
                            >
                                {item.text}
                            </span>
                        )}
                        required
                    />
                </S.FieldAddressTypeCont>
                <S.FieldCont>
                    <TextField
                        label='Número de documento'
                        // @ts-ignore
                        styles={InputStyles}
                        disabled={disabled}
                        id={'idDocument'}
                        name={'idDocument'}
                        type='text'
                        value={userIdentityData! ? userIdentityData?.idDocument! : ''}
                        onChange={handleInputChange}
                        required
                    />
                    {errors.idDocument !== "" && errors.idDocument}
                </S.FieldCont>
            </S.FielSet>
        </>
    );
};
import React from "react";
import styled from 'styled-components';
import { PersonaSize, TeachingBubble } from '@fluentui/react';
import { Persona } from 'office-ui-fabric-react/lib/Persona';
import { useContext, useState } from 'react';
import { ThemeContext } from '@fluentui/react-theme-provider';

interface IRiskRate
{
    risk: string,
    riskValue: number,
    rate: string,
    rateValue: number,
    initials: string
}

const PersonComponent = styled.div`
    .ms-Persona
        {
            .ms-Persona-coin
            {
                .ms-Persona-imageArea
                {
                    .ms-Persona-initials
                    {
                        background: #D9E6FA;
                        span
                        {
                            font-size: 12px !important;
                            color: ${(props) => // @ts-ignore
                            `${props.theme.palette.darkBorder}`};
                        }
                    }
                }
            }
        }
    }
`;

const RiskRateComponent = (props: IRiskRate) =>
{
    const theme = useContext(ThemeContext);
    const riskTooltip = "Desde un punto de vista financiero, el riesgo significa incertidumbre sobre la evolución de un activo, e indica la posibilidad de que una inversión ofrezca un rendimiento distinto del esperado (tanto a favor como en contra del inversor, aunque lógicamente a éste sólo le preocupa el riesgo de registrar pérdidas).";
    const firstTooltip = "Tiene como principal objetivo la estabilidad patrimonial, pero también busca la protección frente a la inflación, por lo que admite una exposición baja al riesgo. El capital capital esta invertido principalmente en renta fija y suele destinar en torno a un 20% del patrimonio a renta variable y otros activos de renta similar. Para intentar intentar superar a la inflación, admite que la rentabilidad de la cartera pueda ser negativa temporalmente, aunque con una frecuencia, duración y dimensión relativamente bajas. Muy ocasionalmente la rentabilidad negativa puede ser superior a lo normal en dimensión y duración, aunque en general se recupera posteriormente.";
    const secondTooltip = "Tiene como objetivo el equilibrio entre la estabilidad y el crecimiento patrimonial, por lo que admite una exposición intermedia al riesgo. El capital esta invertido principalmente en activos de renta fija y suele destinar en torno a un 35% del patrimonio a renta variable y otros activos de riesgo similar. Para optar al crecimiento patrimonial, admite la posibilidad de que la rentabilidad de la cartera pueda ser negativa con una frecuencia, duración y dimensión media. Ocasionalmente la rentabilidad negativa puede ser superior a lo normal en dimensión y duración, aunque en general se recupera posteriormente. El inversor moderado da menos importancia a las oscilaciones de la rentabilidad en el corto plazo y sitúa su horizonte en una inversión a largo plazo.";
    const thirdTooltip = "Tiene como principal objetivo el crecimiento patrimonial, por lo que admite una exposición significativa al riesgo. Alrededor de un 65% del capital suele estar invertido en renta variable y otros activos de riesgo similar. Para optar al crecimiento patrimonial, admite la posibilidad de obtener rentabilidad negativa, que puede ser frecuente a corto plazo y que en determinados periodos puede ser coyunturalmente significativa y duradera. El inversor equilibrado da menos importancia a las oscilaciones de la rentabilidad en el corto plazo y sitúa su horizonte de inversión a largo plazo";
    const fourthTooltip = "Tiene como objetivo maximizar la rentabilidad para obtener un importante crecimiento patrimonial, por lo que admite una decidida exposición al riesgo. Alrededor del 90% suele estar invertido en renta variable y otros activos de riesgo similar. Para optar a obtener un importante crecimiento patrimonial, admite la posibilidad de tener rentabilidad negativa, que puede ser muy frecuente en el corto plazo y que en determinados periodos puede ser significativa y duradera. El inversor arriesgado esta acostumbrado a las oscilaciones de la bolsa y sitúa su horizonte a una inversión a largo plazo.";
    const rateTooltip = "Time-Weighted Rate of Return es el estándar de medición recomendado por las normas GIPS, ya que permite comparar los resultados de la gestión al eliminar el efecto de las aportaciones y reintegros de efectivo en el periodo de cálculo. La rentabilidad es calculada diariamente y compuesta según:\n (1+r1)x(1+r2)x(1+r3) x … x (1+ rn) = (1+r).\n Donde r son las rentabilidades diarias";

    const [showTooltip1, setShowTooltip1] = useState(false);
    const [showTooltip2, setShowTooltip2] = useState(false);
    const [showTooltip3, setShowTooltip3] = useState(false);
    const [showTooltip4, setShowTooltip4] = useState(false);
    const [showTooltip5, setShowTooltip5] = useState(false);
    const [showTooltip6, setShowTooltip6] = useState(false);

    return(
        <React.Fragment>      
            <div className="ms-Grid-row riskRateRows rowIcon" id="risk" onMouseEnter={() => setShowTooltip1(true)} onMouseLeave={() => setShowTooltip1(false)}>
                <i className="ms-Icon ms-Icon--ContactHeart" aria-hidden={true} style={{fontSize: "25px"}}></i>
                <p className="noMargin" style={{paddingLeft: "1em", cursor: "default"}}>Riesgo: {props.risk}</p>
            </div>
            <div className="ms-Grid-row riskRateRows riskRow" style={{paddingTop: "2em"}}>
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-xl3 ms-xxl3">
                    <p className="noMargin size12 lHeight riskRow">Conservador 1/4</p>
                </div>
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-xl3 ms-xxl3">
                    <p className="noMargin size12 lHeight riskRow">Moderado 2/4</p>
                </div>
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-xl3 ms-xxl3">
                    <p className="noMargin size12 lHeight riskRow">Equilibrado 3/4</p>
                </div>
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-xl3 ms-xxl3">
                    <p className="noMargin size12 lHeight riskRow">Agresivo 4/4</p>
                </div>
            </div>
            <div className="ms-Grid-row riskRateRows riskRow">
                <div className="semaphoreRisk semaphoreLeftCorner firstSemaphore ms-Grid-col ms-sm2 ms-md2 ms-xl2 ms-xxl2" id="green" onMouseEnter={() => setShowTooltip2(true)} onMouseLeave={() => setShowTooltip2(false)}>
                    <span className="badgeRisk">
                        <PersonComponent className="persona-component badgePosition" theme={theme} style={{"display": props.rateValue === 1 ? "block" : "none"}}>
                            <Persona key={props.initials} imageInitials={props.initials} showSecondaryText={false} size={PersonaSize.size32} style={{display: "block !important", fontSize: "12px !important", cursor: "default"}}/>
                        </PersonComponent>
                    </span>
                </div>
                <div className="semaphoreRisk secondSemaphore ms-Grid-col ms-sm2 ms-md2 ms-xl2 ms-xxl2" id="yellow" onMouseEnter={() => setShowTooltip3(true)} onMouseLeave={() => setShowTooltip3(false)}>
                    <span className="badgeRisk">
                        <PersonComponent className="persona-component badgePosition" theme={theme} style={{"display": props.rateValue === 2 ? "block" : "none"}}>
                            <Persona key={props.initials} imageInitials={props.initials} showSecondaryText={false} size={PersonaSize.size32} style={{display: "block !important", fontSize: "12px !important", cursor: "default"}}/>
                        </PersonComponent>
                    </span>
                </div>
                <div className="semaphoreRisk thirdSemaphore ms-Grid-col ms-sm2 ms-md2 ms-xl2 ms-xxl2" id="orange" onMouseEnter={() => setShowTooltip4(true)} onMouseLeave={() => setShowTooltip4(false)}>
                    <span className="badgeRisk">
                        <PersonComponent className="persona-component badgePosition" theme={theme} style={{"display": props.rateValue === 3 ? "block" : "none"}}>
                            <Persona key={props.initials} imageInitials={props.initials} showSecondaryText={false} size={PersonaSize.size32} style={{display: "block !important", fontSize: "12px !important", cursor: "default"}}/>
                        </PersonComponent>
                    </span>
                </div>
                <div className="semaphoreRisk semaphoreRightCorner fourthSemaphore ms-Grid-col ms-sm2 ms-md2 ms-xl2 ms-xxl2" id="red" onMouseEnter={() => setShowTooltip5(true)} onMouseLeave={() => setShowTooltip5(false)}>
                    <span className="badgeRisk">
                        <PersonComponent className="persona-component badgePosition" theme={theme} style={{"display": props.rateValue === 4 ? "block" : "none"}}>
                            <Persona key={props.initials} imageInitials={props.initials} showSecondaryText={false} size={PersonaSize.size32} style={{display: "block !important", fontSize: "12px !important", cursor: "default"}}/>
                        </PersonComponent>
                    </span>
                </div>
            </div>
            <div className="ms-Grid-row riskRateRows riskRow">
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-xl3 ms-xxl3">
                    <p className="noMargin size12 lHeight riskRow">Baja</p>
                </div>
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-xl3 ms-xxl3">
                    <p className="noMargin size12 lHeight riskRow">Media Baja</p>
                </div>
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-xl3 ms-xxl3">
                    <p className="noMargin size12 lHeight riskRow">Media Alta</p>
                </div>
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-xl3 ms-xxl3">
                    <p className="noMargin size12 lHeight riskRow">Alta</p>
                </div>
            </div>
            <div className="ms-Grid-row riskRateRows rowIcon" style={{paddingTop: "3em"}}>
                <div className="ms-Grid-col ms-sm12 text-right" style={{verticalAlign: "middle"}} id="rate" onMouseEnter={() => setShowTooltip6(true)} onMouseLeave={() => setShowTooltip6(false)}>
                    <i className="ms-Icon ms-Icon--Diagnostic" aria-hidden={true} style={{fontSize: "25px", verticalAlign: "middle"}}></i>
                    <span className="noMargin" style={{paddingLeft: "1em", paddingTop: "5px", verticalAlign: "middle"}}> Ā Rentabilidad: {props.rate}</span>
                </div>
            </div>
            {showTooltip1 === true &&
                <TeachingBubble target="#risk" hasCloseButton={false}
                    headline="Riesgo"
                    onDismiss={() => setShowTooltip1(showTooltip1)}
                >
                    {riskTooltip}
                </TeachingBubble>
            }
            {showTooltip2 === true &&
                <TeachingBubble target="#green" hasCloseButton={false}
                    headline="Cartera Conservadora 1/4"
                    onDismiss={() => setShowTooltip2(showTooltip2)}
                >
                    {firstTooltip}
                </TeachingBubble>
            }
            {showTooltip3 === true &&
                <TeachingBubble target="#yellow" hasCloseButton={false}
                    headline="Cartera Moderada 2/4"
                    onDismiss={() => setShowTooltip3(showTooltip3)}
                >
                    {secondTooltip}
                </TeachingBubble>
            }
            {showTooltip4 === true &&
                <TeachingBubble target="#orange" hasCloseButton={false}
                    headline="Cartera Equilibrada 3/4"
                    onDismiss={() => setShowTooltip4(showTooltip4)}
                >
                    {thirdTooltip}
                </TeachingBubble>
            }
            {showTooltip5 === true &&
                <TeachingBubble target="#red" hasCloseButton={false}
                    headline="Cartera Agresiva 4/4"
                    onDismiss={() => setShowTooltip5(showTooltip5)}
                >
                    {fourthTooltip}
                </TeachingBubble>
            }
            {showTooltip6 === true &&
                <TeachingBubble target="#rate" hasCloseButton={false}
                    headline="Rentabilidad TWR"
                    onDismiss={() => setShowTooltip6(showTooltip6)}
                >
                    {rateTooltip}
                </TeachingBubble>
            }
        </React.Fragment>
    );
}

export default RiskRateComponent;
import styled from "styled-components";

export const TitleCard = styled.div`
    padding-left: 16px;
    padding-top: 16px;
`;
export const ContainerCard = styled.div`
    min-height: 6em;
    background-color: ${props => props?.theme?.palette?.tertiary || "#e9eff1"};
    border-radius: 10px;
    width: 100%;
    margin-left: 10px;
    line-height: 16px;
    box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
    &#shared-portfolio
    {
        background: ${props => props?.theme?.palette?.themePrimary};
        color: ${props => props?.theme?.palette?.white};
        display: inline-block;
        > *:first-child
        {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            > h5
            {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
            }
        }
        .separator
        {
            margin: 0 16px;
            opacity: 0.2;
        }
        .content
        {
            padding-left: 1em;
            padding-bottom: 1em;
            span
            {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin-right: 5px;
                &.value
                {
                    margin-right: 16px;
                    font-weight: 600;
                }
            }
        }
        > button
        {
            position: absolute;
            top: 5px;
            right: 5px;
            padding: 0;
            border: 0;
            background: none;
            color: ${props => props?.theme?.palette?.white};
        }
    }
`;
export const DataCard = styled.div`
    padding-top: 1.3em;
    font-size: 20px;
    float: right;
    padding-right: 8px;
    display: flex;
    width: 80%;
`;
export const DataCardContract = styled.div`
    padding-top: 1.3em;
    font-size: 20px;
    float: right;
    padding-right: 5px;
    display: block;
    width: 80 %;
`;
export const DataCardMultipleContract = styled.div`
    padding-top: inherit;
    font-size: 20px;
    float: right;
    padding-right: 5px;
    display: block;
    width: 80%;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    right: 0;
    padding-bottom: 7px;
`;
export const DataCardValoration = styled.div`
    padding-top: 2em;
    font-size: 1em;
    float: right;
    padding-right: 8px;
    display: flex;
    font-weight: bold;
`;
export const TitleText = styled.h5`
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    cursor: default;
    color: ${props => props?.theme?.palette?.primary};
`;
export const DataText = styled.h5`
    margin: 0 !important;
    width: 100%;
    text-align: end;
    font-size: 0.75em;
    cursor: default;
`;
export const DataTextContract = styled.h5`
    padding-left: 1em;
    padding-right: 0.7em;
    margin: 0 !important;
    text-align: end;
    font-size: 0.65em;
`;
export const DataTextMultipleContract = styled.div`
    padding-left: 1em;
    margin: 0 !important;
    text-align: end;
    font-size: 0.65em;
    display: flex;
    justify-content: end;
    align-items: flex-end;
`;
export const NumberFormatDiv = styled.div`
    margin-top: -2px;
`;
export const TableBubble = styled.table`
    width: 100%;
`;
export const TRTableBubble = styled.tr`
    font-size: 18px;
`;
export const THTableBubble = styled.th`
    text-align: left;
`;
export const TDTableBubble = styled.td`
    text-align: right;
`;
export const InformationIcon = styled.div`
    margin-top: 1.5em;
    margin-left: 1em;

    @media(width: 1024px)
    {
        margin-left: 0.5em;
    }
`;
export const DivRowContract = styled.div``;
export const DivRowPosition = styled.div``;
export const DivRowRate = styled.div``;
export const DivRowPeriod = styled.div``;
export const DivRowYTD = styled.div``;
export const DivRowYear = styled.div``;
export const DivPrincipalRow = styled.div``;
export const DivContainerRow = styled.div``;
export const DivContainerCol = styled.div``;
export const DivColEmpty = styled.div``;
export const DivColSharedPortfolio = styled.div``;
import styled from "styled-components";
import { getScoreIcons } from '../../../utils/scoreToIcons';
import { ActiveIcon, InactiveIcon } from "../../../components/icons/IconsTable";

const StyledScore = styled.div`
    svg
    {
        margin-right: 4px
    }
`;

const IroniaPoints = (props: any) =>
{
    const scoreIcons = getScoreIcons(props.score);

    if(props.score === undefined)
    {
        return <></>;
    }
    else
    {
        return (
            <StyledScore>
                {scoreIcons.map((active, index) => (active ? <ActiveIcon key={'active-icon-' + index} /> : <InactiveIcon key={'inactive-icon-' + index} />))}
            </StyledScore>
        );
    }
}

export default IroniaPoints;
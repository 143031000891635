import { Modal } from "@fluentui/react";
import * as L from './layout.styles';

const WarningModalComponent = ({
    isModalOpen,
    hideModal,
    blocking = true,
    title = 'Procesando la operación',
    description = 'No cierres el navegador hasta que la operación haya terminado.',
    theme
}: {
    isModalOpen: boolean,
    hideModal: () => void,
    blocking?: boolean,
    title?: string,
    description?: string,
    theme: any
}) => {

    return (
        <Modal
            isOpen={isModalOpen}
            onDismiss={hideModal}
            isBlocking={blocking}
        >
            <L.WarningAdvise className="ms-Grid-row" theme={theme}>
                <div className="ms-Grid-col ms-sm3 text-center">
                    <i className={'ironia-icon warning-alert'} />
                </div>
                <div className="ms-Grid-col ms-sm9">
                    <h3>{title}</h3>
                </div>
            </L.WarningAdvise>
            <L.WarningAdvise className="ms-Grid-row" theme={theme}>
                <div className="ms-Grid-col ms-sm12">
                    <p className="text-center">{description}</p>
                </div>
            </L.WarningAdvise>
        </Modal>
    );
};

export default WarningModalComponent;

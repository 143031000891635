import React, { useEffect } from "react";
import styled from "styled-components";
import { ThemeContext } from "@fluentui/react-theme-provider";
import subscriptionService, { SubscriptionType } from "../../services/SubscriptionService";
import { DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import { Link } from "react-router-dom";

export const MainTitle = styled.h2`
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 1em;
    font-size: 25px !important;
    color: ${(props) => props.theme.palette.black};
    display: block;
`;
export const Description = styled.p`
    margin: 0;
    overflow-y: hidden;
    max-height: 10em;
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    font-size: 23px;
    text-align: justify;
`;
export const LateralDiv = styled.div`
    background-color: ${(props) => props.theme.palette.greyMorePlans};
    padding-right: 0;
    border-radius: 12px;
    text-overflow: ellipsis;
    min-height: 565.75px;
`;
export const Button = styled(DefaultButton)``;
const buttonStyle =
{
    root:
    {
        textAlign: 'center',
        display: 'block',
        border: 'none',
        borderRadius: '10px !important',
        width: 'auto',
        height: '3.25em',
        marginTop: "25%",
        marginBottom: "2em",
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '1em',
        backgroundColor: "#cc214f",
    },
    rootPressed:
    {
        backgroundColor: '#cc214f',
        color: '#ffffff',
    },
    rootHovered:
    {
        backgroundColor: '#0f2426',
    },
    textContainer:
    {
        color: '#ffffff',
        fontFamily: 'inherit',
        fontSize: '18px',
    },
};

const ExperienceSavingsPlanDifferentSubscriptions = () =>
{
    const theme = React.useContext(ThemeContext);
    const [subscriptions, setSubscriptions] = React.useState<SubscriptionType[]>();
    const isLoading = React.useRef<boolean>(false);
    const areSubscriptionsLoaded = React.useRef<boolean>(false);
    const limitText = "El límite marca el volumen total de la cartera. Si se supera el límite no se pueden realizar más compras de fondos o traspasos.";

    useEffect(() =>
    {
        if(areSubscriptionsLoaded.current === false)
        {
            areSubscriptionsLoaded.current = true;
            subscriptionService.getSubscriptionsByType("experience").then((response: SubscriptionType[]) =>
            {
                isLoading.current = true;
                setSubscriptions(response);
            });
        }
    }, [subscriptions]);
    function getPrice(price: any)
    {
        var result: string = String(price);
        result = result.replace(".", ",");

        return result;
    }
    function getFrequency(freq: any)
    {
        var result: string = String(freq);
        if(result === "month")
        {
            return "mes";
        }
        else
        {
            return "año";
        }
    }
    function getLimit(limit: any)
    {
        if(limit === null)
        {
            return "Sin Límite";
        }
        else
        {
            return String(limit)+"€";
        }
    }
    function getName(name: any)
    {
        if(name === undefined || name === null)
        {
            return "";
        }
        else
        {
            return String(name);
        }
    }
    function getDescription(desc: any)
    {
        if(desc === undefined || desc === null)
        {
            return "";
        }
        else
        {
            return String(desc);
        }
    }
    if(isLoading.current === false)
    {
        return (<Spinner size={SpinnerSize.large} />);
    }
    else
    {
        return (
            <React.Fragment>
                <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 ms-xl4 ms-xxl4">
                    <div className="row" style={{marginBottom: "2em"}}>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3"></div>
                        <LateralDiv theme={theme} className="ms-Grid-col ms-sm9 ms-md9 ms-lg9 ms-xl9 ms-xxl9">
                            <MainTitle theme={theme}>{subscriptions !== undefined && getName(subscriptions[0].name)}</MainTitle>
                            <MainTitle theme={theme}>{subscriptions !== undefined && getPrice(subscriptions[0].price)}€ / {subscriptions !== undefined && getFrequency(subscriptions[0].period)}</MainTitle>
                            <Description>{subscriptions !== undefined && getDescription(subscriptions[0].description)}</Description>
                            <MainTitle theme={theme}>Límite: {subscriptions !== undefined && getLimit(subscriptions[0].limit)}</MainTitle>
                            <Description>{limitText}</Description>
                            {subscriptions !== undefined && (
                                <Link to={
                                {
                                    pathname: `/subscription/${subscriptions[0].id}`,
                                    state:
                                    {
                                        initialContribution: 0,
                                        managedPortfolioId: null
                                    },
                                }}>
                                    <Button text="Seleccionar" styles={buttonStyle} />
                                </Link>
                            )}
                        </LateralDiv>
                    </div>
                </div>
                <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 ms-xl4">
                    <div className="row">
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2 ms-xxl2"></div>
                        <LateralDiv theme={theme} className="ms-Grid-col ms-sm9 ms-md9 ms-lg9 ms-xl9 ms-xxl9">
                            <MainTitle theme={theme}>{subscriptions !== undefined && getName(subscriptions[1].name)}</MainTitle>
                            <MainTitle theme={theme}>{subscriptions !== undefined && getPrice(subscriptions[1].price)}€ / {subscriptions !== undefined && getFrequency(subscriptions[1].period)}</MainTitle>
                            <Description>{subscriptions !== undefined && getDescription(subscriptions[1].description)}</Description>
                            <MainTitle theme={theme}>Límite: {subscriptions !== undefined && getLimit(subscriptions[1].limit)}</MainTitle>
                            <Description>{limitText}</Description>
                            {subscriptions !== undefined && (
                                <Link to={
                                {
                                    pathname: `/subscription/${subscriptions[1].id}`,
                                    state:
                                    {
                                        initialContribution: 0,
                                        managedPortfolioId: null
                                    },
                                }}>
                                    <Button text="Seleccionar" styles={buttonStyle} />
                                </Link>
                            )}
                        </LateralDiv>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 ms-xl1 ms-xxl1"></div>
                    </div>
                </div>
                <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 ms-xl4">
                    <div className="row">
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 ms-xl1 ms-xxl1"></div>
                        <LateralDiv theme={theme} className="ms-Grid-col ms-sm9 ms-md9 ms-lg9 ms-xl9 ms-xxl9">
                            <MainTitle theme={theme}>{subscriptions !== undefined && getName(subscriptions[2].name)}</MainTitle>
                            <MainTitle theme={theme}>{subscriptions !== undefined && getPrice(subscriptions[2].price)}€ / {subscriptions !== undefined && getFrequency(subscriptions[2].period)}</MainTitle>
                            <Description>{subscriptions !== undefined && getDescription(subscriptions[2].description)}</Description>
                            <MainTitle theme={theme}>Límite: {subscriptions !== undefined && getLimit(subscriptions[2].limit)}</MainTitle>
                            <Description>{limitText}</Description>
                            {subscriptions !== undefined && (
                                <Link to={
                                {
                                    pathname: `/subscription/${subscriptions[2].id}`,
                                    state:
                                    {
                                        initialContribution: 0,
                                        managedPortfolioId: null
                                    },
                                }}>
                                    <Button text="Seleccionar" styles={buttonStyle} />
                                </Link>
                            )}
                        </LateralDiv>
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2 ms-xxl2"></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ExperienceSavingsPlanDifferentSubscriptions;
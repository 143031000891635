import Highcharts, { Point, Series, SeriesPieOptions, TooltipFormatterContextObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import GraphTheme from '../../graphTheme';
import { percentFormatter } from '../../utils/numberFormatter';

const PieHighStock = (chartSerie: any, showSubcategories: boolean) =>
{
    const colors = GraphTheme.colors;
    let count = 0;
    const browserData: {name: string; y: number; color: string}[] = [];
    const versionsData: {name: string; y: number; color: string}[] = [];
    const categories: string[] = [];
    const data: any[] = [];

    if(chartSerie.chartSerie.length !== undefined && chartSerie.chartSerie.length > 0)
    {
        for(const entry of chartSerie.chartSerie)
        {
            const drilldownCategories: string[] = [];
            const drilldownData: number[] = [];
            entry.level2.forEach((level2Item: any) =>
            {
                drilldownCategories.push(`${level2Item.name} ${percentFormatter.format(level2Item.value/100)}`);
                drilldownData.push(level2Item.value);
            });
    
            data.push(
            {
                color: colors[count],
                y: entry.value,
                drilldown:
                {
                    name: `${entry.name} ${percentFormatter.format(entry.value/100)}`,
                    categories: drilldownCategories,
                    data: drilldownData,
                }
            });
    
            categories.push(`${entry.name} ${percentFormatter.format(entry.value/100)}`);
            count += 1;
        }
    }
    
    const dataLen = data.length;

    // Build the data arrays
    for(let i=0; i<dataLen; i+= 1)
    {
        // add browser data
        browserData.push(
        {
            name: categories[i],
            y: data[i].y,
            color: data[i].color,
        });

        // add version data
        const drillDataLen = data[i].drilldown.data.length;
        for(let j=0; j<drillDataLen; j+=1)
        {
            const brightness = 0.2-j / drillDataLen / 5;
            const color = Highcharts.color(data[i].color).brighten(brightness).get();
            if(typeof color === 'string')
            {
                versionsData.push(
                {
                    name: data[i].drilldown.categories[j],
                    y: data[i].drilldown.data[j],
                    color
                });
            }
        }
    }

    const defaultOptions: Highcharts.Options =
    {
        chart:
        {
            type: 'pie',
            backgroundColor: 'none'
        },
        title:
        {
            text: ''
        },
        plotOptions:
        {
            pie:
            {
                size: '110%',
                dataLabels:
                {
                    enabled: false,
                    style:
                    {
                        textOverflow: 'clip',
                        textOutline: 'none',
                    },
                }
            }
        },
        credits:
        {
            enabled: false,
        },
        tooltip:
        {
            valueSuffix: '%',
            enabled: true,
            headerFormat: '',
            clusterFormat: undefined,
            footerFormat: '',
            backgroundColor: '#FFFFFF',
            borderRadius: 4,
            borderColor: '#FFFFFF',
            formatter: function (this: TooltipFormatterContextObject)
            {
                return this.key.toString();
            },
            followPointer: false,
            style:
            {
                fontSize: '13px',
                fontWeight: '600',
                fontFamily: 'Barlow',
            },
        },
        legend:
        {
            layout: 'vertical',
            floating: false,
            x: -12,
            symbolHeight: 16,
            symbolRadius: 2,
            itemMarginTop: 12,
            itemStyle:
            {
                fontSize: '14px',
                fontWeight: '400',
                fontFamily: 'Barlow',
                lineHeight: '20px',
            },
            labelFormatter: function (this: Point | Series)
            {
                if('percentage' in this)
                {
                    if(this.percentage)
                    {
                        let percent = percentFormatter.format(this.percentage);
                        return this.name.replace(`${percent.toString()}%`, '');
                    }
                }
                return this.name;
            },
        },
        series:
        [
            {
                type: 'pie',
                name: 'Categoría',
                data: browserData,
                innerSize: '50%',
                showInLegend: true,
                dataLabels:
                {
                    crop: false,
                    distance: -30,
                },
            } as SeriesPieOptions,
        ],
    };

    if(chartSerie.showSubcategories || showSubcategories)
    {
        defaultOptions.series?.push(
        {
            type: 'pie',
            name: 'Subcategorías',
            data: versionsData,
            innerSize: '66%',
            size: '80%',
            dataLabels:
            {
                crop: true,
                overflow: 'allow',
                padding: 3,
                startAngle: 10,
                distance: 5,
                style:
                {
                    textOverflow: 'clip',
                }
            },
            id: 'versions'
        } as SeriesPieOptions);
    }
    return(
        <div>
            <HighchartsReact highcharts={Highcharts} options={{ ...GraphTheme, ...defaultOptions}} />
        </div>
    );
}

export default PieHighStock;
import { DefaultButton, DirectionalHint, Dropdown, IDropdownOption, IDropdownStyles, ITooltipProps, Separator, Spinner, SpinnerSize, Stack, TextField, TooltipDelay, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import React, { useEffect, useRef, useState } from "react";
import DocumentTitle from "react-document-title";
import { RouteComponentProps, useLocation } from "react-router";
import styled from "styled-components";
import Auth from "../../Auth/Auth";
import Moment from 'moment';
import BackButton from "../../components/buttons/BackButton";
import CustomSimpleDropdown, { IItem } from "../../components/dropdown/CustomSimpleDropdown";
import InespayService, { BankPeriodicContribution, InespayType } from "../../services/InespayService";
import PaymentService, { PaymentType } from "../../services/PaymentService";
import { translateOptionPeriod } from "../../utils/translateOptionPeriod";
import periodicContributionsService, { PeriodicContribution } from "../../services/PeriodicContributionsService";
import SignaturesService, { ISignature } from "../../services/SignaturesService";
import { serverHost } from "../../config";
import moment from "moment";
import scrollToTop from "../../utils/scrollToTop";
import ModalElementSigned from "../Elements/ModalElementSigned";
import { SignatureComponentMini } from "../../components/Signature/SignatureComponentMini";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 80px;
    justify-content: center;
    align-items: flex-start;
`;
export const Error = styled.span`
    position: absolute;
    top: 0%;
    left: 0%;
    margin: 0 !important;
    color: hsl(0, 100%, 63%);
    font-size: 20px;
    font-weight: 700;
`;
interface RouteParams{};
interface Props extends RouteComponentProps<RouteParams>{};

const ManagePeriodicContribution: React.FunctionComponent<Props> = () =>
{
    const { state } = useLocation<any>();
    const user = Auth.getUserProfile();
    const subscribed = Auth.isSubscribed();
    const portfolioModelName = useRef<any>(state?.portfolioModelName);
    const contract = useRef<any>(state?.contract);
    const portfolioModelId = useRef<any>(state?.portfolioModelId);
    const [isLoadedBanks, setIsLoadedBanks] = useState(true);
    const [canSubmit, setCanSubmit] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [hasErrorSubmitting, setHasErrorSubmitting] = useState(false);
    const [isLoadedSignature, setIsLoadedSignature] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [signatures, setSignatures] = React.useState<ISignature[]>([]);
    const [signatureId, setSignatureId] = React.useState<number>(-1);
    const [modalShow, setModalShow] = React.useState(false);
    const stackTokens = {childrenGap: 20};
    const ibanRegex = RegExp(/^([A-Z]{2}[ '+'\\'+'-]?[0-9]{2})(?=(?:[ '+'\\'+'-]?[A-Z0-9]){9,30}$)((?:[ '+'\\'+'-]?[A-Z0-9]{3,5}){2,7})([ '+'\\'+'-]?[A-Z0-9]{1,3})?$/);
    const [account, setAccount] = useState('');
    const [error, setError] = useState({status: false, message: ''});
    const [hasError, setHasError] = useState<any>({country: '', bank: '', account: '', amount: '', frequency: ''});
    const [bank, setBank] = useState('');
    const [banks, setBanks] = useState<IItem[]>([]);
    const [amount, setAmount] = useState<number>(1000);
    const [frequency, setFrequency] = useState<string>('');
    const [options, setOptions] = useState<IDropdownOption[]>();
    const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const reference = useRef<string>(`${user.id}-${portfolioModelId.current}-${today.valueOf().toString()}`);
    const country = useRef<string>('ES');
    const tooltipId = useId('tooltip');
    const tooltipProps: ITooltipProps =
    {
        onRenderContent: () => (
            <div>
                El pago periódico realizará una transferencia automática en el periodo especificado.
            </div>
        ),
    };
    const dropdownStyles: Partial<IDropdownStyles> =
    {
        label:
        {
            color: "black !important",
            paddingBottom: "0"  
        },
        caretDown:
        {
            color: "black",
            fontSize: "14px !important",
            fontWeight: 700
        },
        dropdownItems:
        {
            borderColor: "transparent !important",
            color: "black",
            fontWeight: 500,
            fontSize: "20px",
            marginTop: "5px"
        },
        title:
        {
            borderColor: "transparent !important",
            marginTop: "5px"
        },
        dropdownItemSelected:
        {
            color: "black"
        },
        dropdownOptionText:
        {
            color: "black"
        }
    };

    useEffect(() =>
    {
        setIsLoadedBanks(false);
        InespayService.getBanks().then((data: any) =>
        {
            setIsLoadedBanks(true);
            setBanks(data.filter(function (bank: any)
            {
                return bank.country === country.current && bank.frequencyPeriodicPayment !== null && bank.frequencyPeriodicPayment.length > 0;
            })
            .map((bank: BankPeriodicContribution) =>
            {
                return{
                    key: bank.bankId,
                    text: bank.name,
                    value: bank.bankId,
                    periods: bank.frequencyPeriodicPayment
                }
            }));
        },
        (error: any) =>
        {
            setIsLoadedBanks(true);
            setError(error);
        });

        reference.current = `-${user.id}-${portfolioModelId.current}-${new Date().setDate(new Date().getDate() + 1).valueOf().toString()}`;

    }, [user.id, contract]);
    useEffect(() =>
    {
        if(signatureId !== -1 && isSubmitted === false && hasErrorSubmitting === false)
        {
            setIsSubmitted(true);

            PaymentService.createPayment(
            {
                amount: amount,
                frequency: frequency,
                reference: reference.current,
                bank: bank,
                account: account,
                subject: `*${upToSixDigits(user.id)}*${upToSixDigits(portfolioModelId.current)}*`,
                startDate: Moment(today).format('YYYY-MM-DD'),
                status: 1,
                platform: "INESPAY"
            }).then((response : Partial<PaymentType>) =>
            {
                if(response !== undefined)
                {
                    var body: PeriodicContribution =
                    {
                        period: frequency,
                        amount: amount,
                        iban: account,
                        inespayReference: reference.current,
                        subject: `*${upToSixDigits(user.id)}*${upToSixDigits(parseInt(portfolioModelId.current))}*`,
                        portfolioModelId: portfolioModelId.current,
                        signatureId: signatureId,
                        paymentId: response.id
                    };
                    periodicContributionsService.createPeriodicContribution(body).then((periodicContribution: PeriodicContribution) =>
                    {
                        if(periodicContribution.id !== undefined && periodicContribution.id !== null)
                        {
                            var inespayData: InespayType =
                            {
                                subject: `*${upToSixDigits(user.id)}*${upToSixDigits(periodicContribution.id)}*`,
                                amount: (amount * 100).toString(),
                                frequency: frequency,
                                reference: reference.current,
                                account: account,
                                startDate: Moment(today).format('YYYY-MM-DD'),
                                subscription: false,
                                initialContribution: parseFloat(amount.toString())
                            };
                            sendToInespay(inespayData);
                        }
                    },
                    (error: any) =>
                    {
                        setError(error);
                    });
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureId, account, amount, bank, frequency, user.id, isSubmitted]);
    const sendToInespay = (inespayData: InespayType) =>
    {
        InespayService.postInespay(inespayData).then((dataInespay: any) =>
        {
            const inespay_result = JSON.parse(dataInespay.Result);
            if(inespay_result.status === '200')
            {
                window.location.href = inespay_result.url;
            }
            else
            {
                setError(
                {
                    status: false,
                    message: inespay_result.description
                });

                setIsSubmitted(false);
                setHasErrorSubmitting(true);
            }
        },
        (error: any) =>
        {
            setError(error);
        });
    };
    const changeBank = (option: any) =>
    {
        setBank(option.key);

        var optionsDropdown: IDropdownOption[] = option.periods.map((item: any, idx: number) =>
        {
            let itemTranslated = translateOptionPeriod(item);
            return {key: idx, value: item, text: itemTranslated}
        });

        setOptions(optionsDropdown);
        setIsLoadingOptions(true);

        const bankError = (option.key === null || option.key === "") ? 'Debe seleccionar un banco' : '';
        setHasError((prevIsError: any) => (
        {
            ...prevIsError,
            bank: bankError
        }));
        validateForm('bank', option.key, bankError);
    };
    const changePeriod = (option: any) =>
    {
        setFrequency(option.value);

        const frequencyError = (option.key === null || option.key === "") ? 'Debe seleccionar un periodo' : '';
        setHasError((prevIsError: any) => (
        {
            ...prevIsError,
            frequency: frequencyError
        }));     
        validateForm('frequency', option.key, frequencyError);
    }
    const changeAccount = (event: any) =>
    {
        const value = event.target.value;
        setAccount(value);
        const accountError = (value === null || value === "") ? 'El IBAN es obligatorio' : (value.length === 24 && ibanRegex.test(value) ? ''
            : 'El formato del IBAN no es válido. Ej. ES3456789012345678901234');

        setHasError((prevIsError: any) => (
        {
            ...prevIsError,
            account: accountError
        }));
        validateForm('account', value, accountError);
    };
    const changeAmount = (event: any) =>
    {
        const value = event.target.value;
        setAmount(value);
        const amountError = (value === null || value === "") ? 'El importe es obligatorio' : (parseInt(value) > 0 ? '' : 'El importe debe ser mayor que 0');

        setHasError((prevError: any) => (
        {
            ...prevError,
            amount: amountError
        }));
        validateForm('amount', value, amountError);
    }
    const validateForm = (key: any, value: any, error: any) =>
    {
        var invalidatedFields = Object.keys(hasError).filter(function (field: any)
        {
            /*eslint-disable no-eval */
            var fieldValue = eval(field);
            var fieldError = hasError[field];
            if(key === field)
            {
                fieldValue = value;
                fieldError = error;
            }
            return fieldValue === '' || fieldError !== '';
        });
        setCanSubmit(invalidatedFields.length === 0);
    };
    const createSignature = () =>
    {
        if(signatures.length === 0)
        {
            SignaturesService.createSignatureNoPlan(user.id).then((response: any) =>
            {
                if(response.data.id !== null)
                {
                    setSignatures([response.data]);
                    setIsLoadedSignature(true);
                }
            });
        }
    };
    const processExpirationDate = (signs: ISignature[]) =>
    {
        if(signs !== undefined && signs.length > 0)
        {
            // eslint-disable-next-line array-callback-return
            signs.map((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = moment(signature.expiresAt).toDate();
                }
            });
        }
    }
    const handleSubmit = (response: any, signatures: any) =>
    {
        if(response === true && signatures.length > 0)
        {
            scrollToTop();
            setSignatureId(signatures[0].id);
        }
    };
    const upToSixDigits = (id: number) =>
    {
        let result: string = id.toString();
        let count = id.toString().length;
        for(let i=0; i<=6; i++)
        {
            if(count < i)
            {
                result = "0"+result;
            }
        }

        return result;
    }

    if(user === undefined || user === null)
    {
        localStorage.clear();
        window.location.href = serverHost + '/login';
        return (<></>);
    }
    if(subscribed === false)
    {
        localStorage.clear();
        window.location.href = serverHost + '/login';
        return (<></>);
    }
    return (
        <DocumentTitle title="IronIA - Aportaciones Periódicas Carteras Modelo">
            <React.Fragment>
                <div className="ms-Grid-row">
                    <BackButton />
                </div>
                <Wrapper>
                    <TitleContainer>
                        <h1>Aportaciones Periódicas</h1>
                        <p className="description" style={{margin: 0}}>
                            Has seleccionado la cartera modelo "{portfolioModelName.current}" que realizará aportaciones periódicas al contrato "{contract.current}" tomando los porcentajes especificados en dicha cartera como elementos de reparto de la cantidad total aportada en el periodo especificado.
                            La firma de esta orden implica la firma periódica de todas las órdenes hasta la cancelación de la misma.
                            En caso de que alguna orden particular de compra de un producto financiero no se pudiera realizar por cualquier motivo, esta incidencia sería comunicada para su subsanación. Como cliente asumes estas incidencias sin posible reclamación por ellas.
                        </p>
                    </TitleContainer>
                    <Separator className="separatorRepayment" style={{ marginTop: "0 !important" }} />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6">
                            <div className="payment" style={{marginTop: "3em"}}>
                                <div className="ms-Tabs-Container">
                                    <button className={"ms-Tab active"}>
                                        <i className="ironia-icon refresh" />
                                        Pagos periódicos
                                    </button>
                                </div>
                                <form>
                                    <Stack tokens={stackTokens}>
                                        <React.Fragment>
                                            <Stack.Item style={{marginTop: "1em"}} grow>
                                                <CustomSimpleDropdown label="Entidad bancaria"
                                                    title="Selecciona un entidad bancaria"
                                                    options={banks}
                                                    id="bank"
                                                    name="bank"
                                                    loading={!isLoadedBanks}
                                                    onChange={changeBank}
                                                    disabled={isSubmitted}
                                                    showSummary={false}
                                                    hasMarginTop={true}
                                                />
                                                {isLoadedBanks && (
                                                    <TooltipHost tooltipProps={tooltipProps} delay={TooltipDelay.zero}
                                                        id={tooltipId} directionalHint={DirectionalHint.topCenter}
                                                        className="ironia-tooltip"
                                                    >
                                                        <DefaultButton aria-describedby={tooltipId} style={{paddingLeft: "1em"}}>
                                                            <i className="ironia-icon information" />
                                                        </DefaultButton>
                                                    </TooltipHost>
                                                )}
                                            </Stack.Item>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6">
                                                    <Stack.Item style={{marginTop: "2em"}} grow>
                                                        <TextField label="Aportación Periódica"
                                                            placeholder="Ingresa la cantidad deseada"
                                                            name="Aportación Periódica"
                                                            value={amount.toString()}
                                                            onChange={changeAmount}
                                                            errorMessage={hasError.amount.length > 0 ? hasError.amount : undefined}
                                                            disabled={isSubmitted}
                                                        />
                                                    </Stack.Item>
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6">
                                                    <Stack.Item style={{marginTop: "2em"}} grow>
                                                        <Dropdown placeholder="Selecciona el periodo"
                                                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12"
                                                            label="Periodo"
                                                            options={options as IDropdownOption[]}
                                                            onChange={(event, item) => changePeriod(item)}
                                                            styles={dropdownStyles}
                                                            disabled={!isLoadingOptions}
                                                        />
                                                    </Stack.Item>
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                                    <Stack.Item style={{marginTop: "2em"}} grow>
                                                        <TextField
                                                            label="IBAN"
                                                            placeholder="Ingresa el IBAN de tu cuenta bancaria"
                                                            name="Iban"
                                                            value={account}
                                                            onChange={changeAccount}
                                                            errorMessage={hasError.account.length > 0 ? hasError.account : undefined}
                                                            disabled={isSubmitted}
                                                            pattern="[A-Z]{2}[0-9]{18}"
                                                        />
                                                    </Stack.Item>
                                                </div>
                                            </div>
                                            {error.message !== "" && (
                                                <Error>{error.message}</Error>
                                            )}
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm7 ms-md7 ms-lg7 ms-xl7 ms-xxl7 ms-xxxl7 mt20"></div>
                                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2 ms-xxl2 ms-xxxl2 mt20">
                                                    {(isSubmitted) && (<Spinner size={SpinnerSize.large} style={{paddingTop: "1em", alignItems: "flex-end"}} />)}
                                                </div>
                                                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3 text-right mt20">
                                                    <DefaultButton className="button tiny-primary-button" onClick={() => {setIsSubmitting(true); createSignature();}}//async () => await sendRecursivePayment()}
                                                        style={{margin: "0 !important"}} disabled={(!canSubmit || isSubmitted || isSubmitting)}
                                                    >
                                                        Firma
                                                    </DefaultButton>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    </Stack>
                                </form>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6" style={{marginTop: "6.35em"}}>
                            {canSubmit && isSubmitting && (
                                <React.Fragment>
                                    {!isLoadedSignature && (
                                        <Spinner style={{paddingTop: "12em"}} size={SpinnerSize.large} />
                                    )}
                                    {isLoadedSignature && (
                                        <SignatureComponentMini
                                            signatures={signatures}
                                            alreadySignedText=" "
                                            alreadySignedSubtitle=" "
                                            submitButtonText="Validar Aportación"
                                            headerTextArea={"Introduce el código que te hemos enviado al teléfono XXXXXX" + signatures[0].phone?.slice(signatures[0].phone.length - 3) + " por SMS para firmar el contrato."}
                                            backButtonText="Volver"
                                            requestNewCodeButtonText="Recibir nuevo código"
                                            onNewCodeSended={(signatures: any) =>
                                            {
                                                processExpirationDate(signatures);
                                                setSignatures(signatures);
                                            }}
                                            onSignatureChanged={(signatures: any) =>
                                            {
                                                processExpirationDate(signatures);
                                                setSignatures(signatures);
                                            }}
                                            onSigned={(response: any, signatures: any) => {handleSubmit(response, signatures)}}
                                            isPeriodicContribution={true}
                                        />
                                    )}
                                    {modalShow && <ModalElementSigned setModalShow={setModalShow} />}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </Wrapper>
            </React.Fragment>
        </DocumentTitle>
    );
}

export default ManagePeriodicContribution;
import * as L from './AlchemyTransferStyle';
import { useContext, useEffect, useRef } from 'react';
import { IChoiceGroupOption, TextField, ChoiceGroup, Stack, SpinnerSize, Spinner } from '@fluentui/react';
import { CaretHollowMirroredIcon } from '@fluentui/react-icons-mdl2';
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { ThemeContext } from '@fluentui/react-theme-provider';
import Auth from "../../Auth/Auth";
import OrderService from "../../services/OrderService";
import { useForm, Controller } from "react-hook-form";
import { UserType } from '../../services/UserService';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { InvestmentFundFinder } from '../PurchaseTransferScreen/components/InvestmentFundFinder';
import { DistributorFinder } from '../PurchaseTransferScreen/components/DistributorFinder';
import { ManagedPortfolioList } from '../../services/ManagedPortfolioService';
import savingsPlans from '../../services/SavingsPlanService';

interface RouteParams {}
interface ITransferFormScreenProps extends RouteComponentProps<RouteParams> {}

const amountTypeOptions: IChoiceGroupOption[] =
[
    { key: "0", text: 'Por importe', value: 'amount' },
    { key: "1", text: 'Por participaciones', value: 'titles' }
];

export const AlchemyTransferScreen: React.FunctionComponent<ITransferFormScreenProps> = (props, context) =>
{
    const theme = useContext(ThemeContext);
    let activePlan = Auth.getActivePlan();
    const { register, handleSubmit, watch, setError, formState: { errors }, control, setValue } = useForm({mode: "onChange"});
    const [sending, setSending] = React.useState<boolean>(false);
    const history = useHistory();
    const mounted = useRef(false);
    let userData: UserType = Auth.getUserProfile();
    const watchAmountType = watch("amountType", "");
    const outdated = Auth.isLastPlanOutdated();
    const cancelled = Auth.isActivePlanCancelled();
    const alchemyPlan = Auth.isAlchemyPlan();
    const [listPortfolio, setListPortfolio] = React.useState<ManagedPortfolioList[]>();
    const firstLoadSemaphore = useRef<boolean>(true);

    React.useEffect(() =>
    {
        if(firstLoadSemaphore.current === true)
        {
            firstLoadSemaphore.current = false;
            savingsPlans.getDistributionFromSavingPlanId(activePlan.id).then((response: ManagedPortfolioList[]) =>
            {
                if(response.length !== 0)
                {
                    setListPortfolio(response);
                }
                else
                {
                    firstLoadSemaphore.current = true;
                }
            });
        }
    },[firstLoadSemaphore, activePlan]);

    const onSubmit = async (data: any) =>
    {
        if(data.sourceFund?.name === null || data.sourceFund?.name === "" || data.sourceFund?.isin === null || data.sourceFund?.isin === "")
        {
            setError("sourceFund", {type: "required"}, {shouldFocus: true});
            return;
        }

        let toSend: any =
        {
            UserIdentityId: userData.mainUserIdentity.id,
            FnmPortfolioId: activePlan?.portfolio?.id,
            OrderType: 3,
            SavingPlanType: 1,
            Values:
            {
                InceptionFund:
                {
                    Company: data.distributor.name.trim(),
                    Name: data.sourceFund?.name.trim(),
                    Isin: data.sourceFund?.isin.trim(),
                    Account: data.account.trim(),
                    EntityNif: data.distributor.nif.trim()
                },
                newInstrument: data.targetFund?.finametrixId,
                Fund: data.targetFund?.finametrixId,
                AmountType: data.amountType,
                Amount: data.amount !== undefined ? parseFloat(data.amount) : 0,
                Type: data.amountType
            },
            TargetFunds: listPortfolio
        };

        setSending(true);

        OrderService.createMultipleOrders(toSend).then((response: any) =>
        {
            if(response !== "Se ha producido un error")
            {
                history.push("/AlchemyTransfer/OrdersPreview",
                {
                    props: response
                });
            }
        })
        .catch((error) => {})
        .finally(() =>
        {
            if(mounted.current)
            {
                setSending(false);
            }
        });
    };
    useEffect(() =>
    {
        mounted.current = true;
        return () =>
        {
            mounted.current = false;
        };
    }, []);
    if(outdated === true || cancelled === true)
    {
        return (<Redirect to="/savingsPlans" />);
    }
    if(alchemyPlan === false)
    {
        return (<Redirect to="/Portfolio" />);
    }

    return(
        <React.Fragment>
            <DocumentTitle title={"IronIA - Traspaso Externo"}>
                <form className="external-transfer-form" id="external-transfer-form" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <L.Main theme={theme}>
                        <L.BackButtonContainer>
                            <span>
                                <Link to='/AlchemyTransfer'>
                                    <CaretHollowMirroredIcon />
                                    Volver atrás
                                </Link>
                            </span>
                        </L.BackButtonContainer>
                        {activePlan !== undefined &&
                        (
                            <div className="ms-Grid-row" style={{minHeight: "20px"}}>
                                <div className="ms-Grid-col">
                                    <L.TitleContainer>
                                        <h2>Traspaso Externo de un Fondo Origen</h2>
                                    </L.TitleContainer>
                                </div>
                                <div className="ms-Grid-col" style={{paddingTop: "12px", color: "#CC214F"}}>
                                    <L.SubTitleContainer>{activePlan.name}</L.SubTitleContainer>
                                </div>
                            </div>
                        )}
                        {activePlan === undefined &&
                        (
                            <L.TitleContainer>
                                <h2>Traspaso Externo de un Fondo Origen</h2>
                            </L.TitleContainer>
                        )}
                        <L.SeparatorTop />
                        <div className="ms-Grid restarted-ms-Grid">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-xl6">
                                    <L.CardContainer theme={theme}>
                                        <L.CardHeader>
                                            <L.CardHeadingContainer>
                                                <h3>Datos origen</h3>
                                            </L.CardHeadingContainer>
                                            <L.TextSmall className="small-text" theme={theme}>
                                                Si no encuentras el fondo que buscas en los desplegables, puedes introducirlo de
                                                manera manual en las cajas de texto correspondientes al ISIN y el nombre del fondo.
                                            </L.TextSmall>
                                        </L.CardHeader>
                                        <L.CardBodyTransferScreen>
                                            <Stack>
                                                <Stack.Item>
                                                    <Controller control={control}
                                                        name="sourceFund"
                                                        rules={{required: true}}
                                                        render={({field, field: {onChange}}) =>
                                                            <InvestmentFundFinder {...field} required
                                                                labelISIN="ISIN de origen"
                                                                labelName="Nombre del fondo de origen"
                                                                placeholderISIN="Escribe aquí el ISIN de origen..."
                                                                placeholderName="Escribe aquí el nombre del fondo de origen..."
                                                                onChange={(ev: any, name: any, value: any) =>
                                                                {
                                                                    setValue(name, value);
                                                                    onChange({target: {value, name}});
                                                                }}
                                                                errorMessage={errors.sourceFund &&
                                                                (
                                                                    (typeof (errors.sourceFund.type) === "string"
                                                                        && errors.sourceFund.type === "required"
                                                                        && "Debe seleccionar tanto el isin como el nombre del fondo de origen.")
                                                                    || "El fondo de origen no es válido."
                                                                )}
                                                            />
                                                        }
                                                    />
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Controller control={control}
                                                        name="distributor"
                                                        rules={{required: true}}
                                                        render={({field, field: {onChange}}) =>
                                                            <DistributorFinder {...field} required
                                                                label="Comercializador"
                                                                placeholder="Escribe aquí el comercializador..."
                                                                onChange={(ev: any, name: any, value: any) =>
                                                                {
                                                                    setValue(name, value);
                                                                    onChange({target: {value, name}});
                                                                }}
                                                                errorMessage={errors.distributor &&
                                                                (
                                                                    (typeof (errors.distributor.type) === "string"
                                                                        && errors.distributor.type === "required"
                                                                        && "Debe seleccionar un comercializador.")
                                                                    || "El comercializador no es válido."
                                                                )}
                                                            />
                                                        }
                                                    />
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <TextField required={true}
                                                        {...register("account",
                                                            {
                                                                required: true,
                                                                minLength: 10                                                                
                                                            }
                                                        )}
                                                        label="Cuenta de origen"
                                                        placeholder="Escribe aquí la cuenta de origen..."
                                                        errorMessage={errors.account &&
                                                        (
                                                            (typeof (errors.account.type) === "string"
                                                                && errors.account.type === "required"
                                                                && "Debe indicar una cuenta de origen.")
                                                            || "Introduzca una cuenta válida."
                                                        )}
                                                    />
                                                </Stack.Item>
                                            </Stack>
                                        </L.CardBodyTransferScreen>
                                    </L.CardContainer>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-xl6">
                                    <L.CardContainer theme={theme}>
                                        <L.MultiRadioButtonTitle>
                                            Tipo de importe
                                        </L.MultiRadioButtonTitle>
                                        <Stack>
                                            <Stack.Item styles={{root: {marginTop: "1em !important"}}}>
                                                <Controller name="amountType"
                                                    control={control}
                                                    rules={{required: true}}
                                                    render={({field}) => <ChoiceGroup {...field} required options={amountTypeOptions} />}
                                                />
                                            </Stack.Item>
                                            <Stack.Item styles={{root: {marginTop: "1em !important"}}}>
                                                <Controller name="amount" control={control}
                                                    rules={{required: true, min: 0 + Number.MIN_VALUE}}
                                                    render=
                                                    {
                                                        ({field}) =>
                                                        <TextField {...field} label={(watchAmountType === "titles" && "Participaciones") || "Importe"}
                                                            placeholder={(watchAmountType === "titles" && "Escribe aquí el número de participaciones...") || "Escribe aquí el importe..."}
                                                            type="number" required
                                                            errorMessage={errors.amount &&
                                                            (
                                                                (watchAmountType === "titles" &&
                                                                (
                                                                    (typeof (errors.amount.type) === "string"
                                                                        && errors.amount.type === "required"
                                                                        && "Indique las participaciones a traspasar.")
                                                                    || (typeof (errors.amount.type) === "string"
                                                                        && errors.amount.type === "min"
                                                                        && "Debe indicar un número mayor de participaciones.")
                                                                    || "Introduzca un número de participaciones válido."
                                                                ))
                                                                || (typeof (errors.amount.type) === "string"
                                                                    && errors.amount.type === "required"
                                                                    && "Debe indicar el importe a traspasar.")
                                                                || (typeof (errors.amount.type) === "string"
                                                                    && errors.amount.type === "min"
                                                                    && "El importe debe ser mayor.")
                                                                || "Introduzca un importe válido."
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Stack.Item>
                                        </Stack>
                                    </L.CardContainer> 
                                </div>
                            </div>
                        </div>
                        <L.OuterFooterArea>
                            <L.CardButton size="lg" disabled={sending}>
                                {sending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                Continuar
                            </L.CardButton>
                        </L.OuterFooterArea>
                    </L.Main>
                </form>
            </DocumentTitle>
        </React.Fragment>
    );
};
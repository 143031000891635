import DocumentTitle from 'react-document-title';
import { useEffect, useState } from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import ContractDocsService, { ContractDocsVersionType } from '../../../services/ContractDocsService';
import styles from './index.module.sass';

const contractName = 'Contrato marco';

export const FrameworkAgreement = () => {
	const [contractHTML, setContractHTML] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		ContractDocsService.getLastVersion(contractName).then((contractDocsVersion: ContractDocsVersionType) => {
			setContractHTML(contractDocsVersion.text);
		}).finally(() => {
			setIsLoading(false);
		})
	}, []);

	return (
		<DocumentTitle title="IronIA - Condiciones Generales">
			<>
				<div className={`row ${styles.title}`}>
					<div className="ms-Grid-col">
						<h2>Condiciones Generales</h2>
					</div>
				</div>
				<div className={`row ${styles.content}`}>
					<div className="ms-Grid-col ms-sm8 ms-smOffset2">
						{isLoading && (
							<Spinner size={SpinnerSize.large} />
						)}
						<div dangerouslySetInnerHTML={{ __html: contractHTML }}></div>
					</div>
				</div>
			</>
		</DocumentTitle>
	)
}
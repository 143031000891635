import { useEffect, useRef, useState } from "react";
import subscriptionService, { SubscriptionType } from "../../services/SubscriptionService";
import { useLocation } from "react-router-dom";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { MoreResuscriptionPlansItem } from "./MoreResuscriptionPlansItem";
import { ContractsUnderSameSubscription } from "./RePaymentSubscription";
import React from "react";
import { CardsContainer, MoreResuscriptionPlansTitle } from "./RePaymentSubscriptionStyle";
import DocumentTitle from "react-document-title";
import savingsPlans from "../../services/SavingsPlanService";
import Auth from "../../Auth/Auth";

export const MoreResuscriptionPlans = () =>
{
    const { state } = useLocation<any>();
    const contracts = useRef<ContractsUnderSameSubscription>(state?.contracts);
    const prevSubscription = contracts.current.subscriptionId;
    const limitAmount = contracts.current.subscriptionLimit;
    const isLoading = useRef<boolean>(false);
    const errorMini = useRef<string>("");
    const errorAnnual = useRef<string>("");
    const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>();
    const [portfolioValue, setPortfolioValue] = useState<number>(0);
    const user = Auth.getUserProfile();

    useEffect(() =>
    {
        const fetchData = async () =>
        {
            if(subscriptions === undefined)
            {
                try
                {
                    var portfolioAmount = localStorage.getItem('portfolioValue');

                    if(portfolioAmount !== null && portfolioAmount !== undefined)
                    {
                        setPortfolioValue(Number(portfolioAmount));
                    }
                    else
                    {
                        try
                        {
                            const portfolioResponse = await savingsPlans.getLatestPortfolioValue(contracts.current.subscriptionInfo[0].savingPlanId as number, user.id);
                            portfolioAmount = portfolioResponse?.portfolioValue.toString() || "0";
                            setPortfolioValue(Number(portfolioAmount));
                        }
                        catch(error)
                        {
                            console.error('Error al obtener el valor del portfolio:', error);
                            portfolioAmount = '0';
                            setPortfolioValue(0);
                        }
                    }

                    const response = await subscriptionService.getSubscriptionsByType("experience");

                    let filteredResponse = response.filter(sub => sub.name !== "IronIA Mes a Mes");
                    const prevSubscriptionId = [4, 8].includes(prevSubscription) ? prevSubscription : 4;
                    const selectedIndex = filteredResponse.findIndex(sub => sub.id === prevSubscriptionId);

                    if(Number(portfolioAmount) > 3000)
                    {
                        errorMini.current = "Tu cartera actualmente tiene un valor superior a 3.000€, por lo que no puedes "
                            + "renovar tu suscripción en IronIA Mini. Selecciona uno de los otros planes para resuscribirte.";
                        
                        filteredResponse = filteredResponse.filter(sub => sub.name !== "IronIA Mini");
                    }
                    if(Number(portfolioAmount) > 1000000)
                    {
                        errorAnnual.current = "Tu cartera actualmente tiene un valor superior a 1.000.000€, por lo que no puedes "
                            + "renovar tu suscripción a IronIA Anual. Sólo puedes escoger IronIA Premium";
                        
                        filteredResponse = filteredResponse.filter(sub => sub.name !== "IronIA Anual");
                    }

                    const selectedSub = filteredResponse[selectedIndex];

                    filteredResponse = filteredResponse.filter(sub => sub.id !== selectedSub.id);
                    filteredResponse =
                    [
                        ...filteredResponse.slice(0, 1),
                        selectedSub,
                        ...filteredResponse.slice(1)
                    ];

                    isLoading.current = true;
                    setSubscriptions(filteredResponse);
                }
                catch(error)
                {
                    console.error("Error al cargar los datos:", error);
                }
            }
        }

        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevSubscription, limitAmount]);

    if(isLoading.current === false)
    {
        return (<Spinner size={SpinnerSize.large} />);
    }
    else
    {
        return(
            <DocumentTitle title = 'IronIA - Renovación de Suscripción'>
                <React.Fragment>
                    <MoreResuscriptionPlansTitle>
                        {subscriptions?.length === 1 &&
                        (
                            <React.Fragment>
                                Esta será tu nueva suscripción
                            </React.Fragment>
                        )}
                        {subscriptions?.length as number > 1 &&
                        (
                            <React.Fragment>
                                Selecciona la suscripción
                            </React.Fragment>
                        )}
                    </MoreResuscriptionPlansTitle>
                    <CardsContainer>
                        {subscriptions?.map((subscription: SubscriptionType, index: number) =>
                        (
                            <MoreResuscriptionPlansItem
                                key={index}
                                contracts={contracts.current}
                                newSubscription={subscription}
                                className={'spaced'}
                            />
                        ))}
                    </CardsContainer>
                </React.Fragment>
            </DocumentTitle>
        );
    }
}
import { CommandBar, ICommandBarItemProps } from "office-ui-fabric-react/lib/CommandBar";
import { IButtonProps } from "@fluentui/react";
import { serverHost } from "../../config";
import { useHistory } from "react-router";

const _overflowItems: ICommandBarItemProps[] = [];
const overflowProps: IButtonProps = { ariaLabel: "Más acciones" };

// const doLogout = async (event: any) =>
// {
//     event.preventDefault();
//     await axios.get(`${serverHost}/oauth/revoke`, { withCredentials: true });
//     Auth.logout();
//     window.location.href = `${serverHost}/login`;
// }

const ProfileMenu = () =>
{
    const history = useHistory();
    const _items = [
    {
        text: "Condiciones Generales",
        key: "Condiciones Generales",
        onClick: () => history.push("/profile/contract/frameworkAgreement"),
        className: window.location.pathname === '/profile/contract/frameworkAgreement' ? 'is-selected' : ''
    },
    {
        text: "Documentación",
        key: "Documentación",
        onClick: () => history.push("/profile/documentation"),
        className: window.location.pathname === '/profile/documentation' ? 'is-selected' : ''
    },
    {
        text: "Titularidad del contrato",
        key: "Titularidad del contrato",
        onClick: () => history.push("/profile/ownership"),
        className: window.location.pathname === '/profile/ownership' ? 'is-selected' : ''
    },
    {
        text: "Suscripciones y Pagos",
        key: "profilesuscripcion",
        onClick: () => history.push("/profile/subscription"),
        className: window.location.pathname === '/profile/subscription' ? 'is-selected' : ''
    },
    {
        text: "Cuentas Corrientes",
        key: "Cuentas Corrientes",
        onClick: () => history.push("/profile/bank-accounts"),
        className: window.location.pathname === '/profile/bank-accounts' ? 'is-selected' : ''
    },
    {
        text: "Cambiar Contraseña y MFA",
        key: "Cambiar Contraseña",
        href: "/profile/changePassword",
        onClick: (event: any) => { event.preventDefault(); window.location.href = serverHost + '/password/change' }
    }
    ];

    return(
        <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" id="main-menu">
                <CommandBar
                    // @ts-ignore
                    items={_items}
                    overflowItems={_overflowItems}
                    overflowButtonProps={overflowProps}
                    ariaLabel="Usa las flechas izquierda y derecha para navegar entre las entradas del menú"
                />
            </div>
        </div>
    );
}

export default ProfileMenu;
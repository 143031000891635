import * as L from './layout.styles';
import { useEffect, useState } from 'react';
import Auth from "../../../Auth/Auth";
import { UserIdentityType } from '../../../services/UserIdentitiesService';
import SharedPortfolioService, { SharedPortfolioType } from '../../../services/SharedPortfoliosService';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { SignatureComponent } from '../../../components/Signature/SignatureComponent';
import moment from "moment";
import { ISignature } from "../../../services/SignaturesService";
import { Spinner, SpinnerSize } from '@fluentui/react';
import scrollToTop from '../../../utils/scrollToTop';
import ProcessingModalComponent from '../../../components/Modals/ProcessingModalComponent';
import { useBoolean } from '@fluentui/react-hooks';
import React from 'react';

const SharedPortfolioConfirm = () =>
{
    const { sharedPortfolioId } = useParams<any>();
    const history = useHistory();
    const [userIdentities, setUserIdentities] = useState<UserIdentityType[]>([]);
    const [sharedPortfolio, setSharedPortfolio] = useState<SharedPortfolioType>();
    const [userConfirmation, setUserConfirmation] = useState(false);
    const [phoneNumbers, setPhoneNumbers] = useState("");
    const [isLoaded, setIsLoaded] = useState(true);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const user = Auth.getUserProfile();
    const [error, setError] = useState();
    const { state } = useLocation<any>();

    const processExpirationDate = (signatures: ISignature[]) =>
    {
        if(signatures !== undefined && signatures.length > 0)
        {
            signatures.forEach((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = moment(signature.expiresAt).toDate();
                }
            });
        }
    }

    let defaultSignatures: any = state?.signatures;
    processExpirationDate(defaultSignatures);

    const [signatures, setSignatures] = useState(defaultSignatures);

    useEffect(() =>
    {
        scrollToTop();
    }, []);

    useEffect(() =>
    {
        SharedPortfolioService.getSharedPortfolio(sharedPortfolioId).then((sharedPortfolio: SharedPortfolioType) =>
        {
            setSharedPortfolio(sharedPortfolio);
        },
        (error) =>
        {
            setError(error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(sharedPortfolio !== undefined)
        {
            setUserIdentities([user.mainUserIdentity]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedPortfolio]);

    useEffect(() =>
    {
        setPhoneNumbers(userIdentities.map((userIdentity: any) =>
            "X".repeat(userIdentity.phone.length - 3) + userIdentity.phone.slice(userIdentity.phone.length - 3, userIdentity.phone.length)).join(", "));
    }, [userIdentities]);

    const handleSubmit = () =>
    {
        if(sharedPortfolio !== undefined)
        {
            SharedPortfolioService.patchSharedPortfolio(sharedPortfolioId, {status: 2}).then((sharedPortfolio: SharedPortfolioType) =>
            {
                var activePlan = Auth.getActivePlan();
                Auth.setActivePlan(
                {
                    ...activePlan,
                    portfolio: {
                        ...activePlan.portfolio,
                        sharedPortfolio: sharedPortfolio
                    }
                });
                hideModal();
            },
            (error: any) =>
            {
                setError(error);
            });
        }
        if(!state?.signatures || !isModalOpen)
        {
            scrollToTop();
            showModal();
        }
    };

    return(
        <React.Fragment>
            <L.HelperStyle />
            <L.Cont>
                <L.MainCont>
                    {!isLoaded &&
                    (
                        <Spinner size={SpinnerSize.large} />
                    )}
                    {isLoaded &&
                    (
                        <SignatureComponent signatures={signatures}
                            userIdentities={userIdentities}
                            title="Firma del contrato para compartir cartera"
                            submitButtonText="Firmar contrato"
                            cancelButtonText="Volver"
                            alreadySignedText="Enhorabuena, ¡has compartido tu cartera!"
                            headerTextArea={"A continuación te incluimos los detalles del contrato para Compartir cartera. Léelo y cuando estés listo, dale al botón "
                                + "de 'Solicitar SMS'. Te enviaremos un código SMS"
                                + (userIdentities.length > 1 ? ` a los números ${phoneNumbers} ` : userIdentities.length === 1 ? ` al número ${phoneNumbers} ` : " ")
                                + "para realizar la firma del contrato."}
                            backButtonText="Volver"
                            onBackButtonClicked={() => history.push(`/sharedPortfolio/{sharedPortfolioId}/contract`)}
                            onCancelButtonClicked={() => history.push(`/sharedPortfolio/{sharedPortfolioId}/contract`)}
                            requestNewCodeButtonText="Recibir nuevo código"
                            confirmElement={
                            {
                                text: "Acepto los términos y condiciones",
                                value: userConfirmation,
                                setValue: (value: boolean) => setUserConfirmation(!!value)
                            }}
                            onNewCodeSended={(signatures: any) =>
                            {
                                processExpirationDate(signatures);
                                setSignatures(signatures);
                            }}
                            onSignatureChanged={(signatures: any) =>
                            {
                                processExpirationDate(signatures);
                                setSignatures(signatures);
                            }}
                            onSigned={handleSubmit}
                        />
                    )}
                    {error && (<div>Alguno de los códigos enviados no es válido</div>)}
                </L.MainCont>
            </L.Cont>
            <ProcessingModalComponent title='Generando contrato para compartir cartera'
                isModalOpen={isModalOpen}
                hideModal={hideModal}
            />
        </React.Fragment>
    );
};

export default SharedPortfolioConfirm;
import React from "react";
import PromotionService from "../../../services/PromotionsService";
import DocumentTitle from "react-document-title";

const AsignPromotions = () =>
{
    const [loading, setLoading] = React.useState<boolean>(true);
    const [success, setSuccess] = React.useState<boolean>(false);
    const firstTime = React.useRef<boolean>(true);

    React.useEffect(() =>
    {
        window.scrollTo(0, 0);
    }, []);

    React.useEffect(() =>
    {
        if(firstTime.current === true)
        {
            const params = new URLSearchParams(window.location.search);

            var objToSend =
            {
                email: params.get("email"),
                promo: params.get("promo")
            };
            
            firstTime.current = false;
            PromotionService.asignPromotions(objToSend).then((response: boolean) =>
            {
                if(response === true)
                {
                    setSuccess(true);
                }
                else
                {
                    setSuccess(false);
                }
                
                setLoading(false);
            });
        }
    }, []);

    return(
        <React.Fragment>
            <DocumentTitle title={"IronIA"}>
                <React.Fragment>
                    {loading === true &&
                    (
                        <React.Fragment>
                            <p style={{cursor: "default"}}>
                                Comprobando los datos para aplicar promoción...
                            </p>
                        </React.Fragment>
                    )}
                    {loading === false &&
                    (
                        <React.Fragment>
                            {success === true &&
                            (
                                <React.Fragment>
                                    <p style={{cursor: "default"}}>
                                        Enhorabuena, tu promoción se ha activado con éxito.
                                    </p>
                                </React.Fragment>
                            )}
                            {success === false &&
                            (
                                <React.Fragment>
                                    <p style={{cursor: "default"}}>
                                        Se ha producido un error en la aplicación de la Promoción.
                                    </p>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            </DocumentTitle>
        </React.Fragment>
    )
}

export default AsignPromotions;
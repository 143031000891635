import styled from "styled-components";

export const PageTitle = styled.h2`
    padding-left: 10px;
    margin-top: 0;
`;
export const CardItem = styled.div`
    margin-bottom: 10px;
    padding: 10px;
`;
export const Card = styled.div`
    cursor: pointer;
    background: rgb(255, 255, 255);
    box-shadow: 0 0 5px 3px rgb(0 0 0 / 8%);
    border-radius: 16px;
    font-family: Barlow;
    position: relative;
    width: 100%;
    height: 250px;
    display: inline-block;
    overflow: hidden;
`;
export const ImageCard = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
`;
export const FooterCard = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 5px 0 10px 0;
    color: ${(props) => props.theme.palette.cardFooterColor};
    background: ${(props) => props.theme.palette.cardFooter};
`;
export const Title = styled.div`
    padding-left: 10px;
`;
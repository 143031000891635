import React from "react";
import * as L from '../../components/Instruments/layout.styles';
import { SpinnerSize, Spinner, SearchBox, DefaultButton } from '@fluentui/react';
import OrderService, { OrderTrendByFund, OrderTrendByFundResponse } from "../../services/OrderService";
import TableTends from "./Table";
import Auth from "../../Auth/Auth";
import { ThemeContext } from '@fluentui/react-theme-provider';

type Props = {};

const TendsSearch = (props: Props) => {
    const [fundSearchTerm, setFundSearchTerm] = React.useState("");
    const [loadFundsTends, setLoadFundsTends] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [funds, setFunds] = React.useState<OrderTrendByFund[]>([]);
	const [filteredFunds, setFilteredFunds] = React.useState<OrderTrendByFund[]>([]);
    const [fundsPerPage, setFundsPerPage] = React.useState(Number(Auth.getQtyPage()));
    const [page, setPage] = React.useState(0);
    const [recordsTotal, setRecordsTotal] = React.useState(0);
	const theme = React.useContext(ThemeContext);

    React.useEffect(() => {
		if(loadFundsTends) {
			setLoadFundsTends(false);
			setLoading(true);
			OrderService.getOrderTrendByFund()
				.then((response:  OrderTrendByFundResponse) => {
					setFunds(response.fundsTends);
					setFilteredFunds(response.fundsTends);
                    setRecordsTotal(response.totalRecords);
					setPage(0);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [loadFundsTends]);
    
    const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) =>
    {
        if(e.key === 'Enter')
        {
            filterFundsHandler(fundSearchTerm)
        }
    };

	const filterFundsHandler = (filterTerm: string) => {
		let itemsFitered = funds;
		if(filterTerm && filterTerm.trim() !== "") {
			itemsFitered = funds.filter(x => x.isin?.includes(filterTerm.toUpperCase()) || x.fondo?.includes(filterTerm.toUpperCase()));
		}
		setFilteredFunds(itemsFitered);
		setRecordsTotal(itemsFitered.length);
		setPage(0);
	};

	return (
		<L.TableWrapper>
			<div className="ms-Grid-row">
				<div className="ms-Grid-col ms-sm12 ms-md6">
					<L.Title>Tendencias</L.Title>
				</div>
			</div>
			<L.TableWidthManager>
				<L.TableFilterContainer>
					<L.TRCountContainer>
						<L.TRTitle>
							Resultados de la búsqueda
							<L.TRCount>{`(${recordsTotal})`}</L.TRCount>
						</L.TRTitle>
					</L.TRCountContainer>
					<L.InputSearchContainer>
						<SearchBox
							onKeyPress={e => handleKey(e)}
							placeholder="Buscar por nombre o ISIN del fondo..."
							onChange={event => {
								if(event?.target.value) {
									let text = event.target.value.toUpperCase();
									setFundSearchTerm(text);
								} else {
									setFundSearchTerm("");
								}
							}}
						/>
					</L.InputSearchContainer>
					<L.EditColumnContainer>
						<DefaultButton text="Buscar" onClick={() => filterFundsHandler(fundSearchTerm)} />
					</L.EditColumnContainer>
				</L.TableFilterContainer>
				{loading ? (
					<Spinner size={SpinnerSize.large} />
				) : (
					<>
						<L.TableContainer theme={theme}>
							<TableTends funds={filteredFunds} page={page} fundsPerPage={fundsPerPage} />
						</L.TableContainer>
						<L.PaginationContainer>
							<L.ProductsPerPage>
								<span>Resultados por página</span>
								<select
									onChange={event => {
										Auth.setQtyPage(Number(event.target.value));
										setFundsPerPage(Number(event.target.value));
										setPage(0);
									}}
									defaultValue={fundsPerPage}
								>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
									<option value={40}>40</option>
									<option value={50}>50</option>
								</select>
							</L.ProductsPerPage>
							<L.PageCount>
								<span>{`${page + 1} de ${Math.ceil(recordsTotal / fundsPerPage)}`}</span>
							</L.PageCount>
							<L.PageButtons>
								<button
									onClick={() => {
										if(page > 0) {
											setPage(page - 1);
										}
									}}
									className={page === 0 ? "disabled" : ""}
								>
									<i className={"ironia-icon keyboard-arrow-left"} />
								</button>
								<button
									onClick={() => {
										if(page < Math.ceil(recordsTotal / fundsPerPage!) - 1) {
											setPage(page + 1);
										}
									}}
									className={Math.ceil(recordsTotal / fundsPerPage!) === page + 1 ? "disabled" : ""}
								>
									<i className={"ironia-icon keyboard-arrow-right"} />
								</button>
							</L.PageButtons>
						</L.PaginationContainer>
					</>
				)}
			</L.TableWidthManager>
		</L.TableWrapper>
	);
};

export default TendsSearch;

import React from "react";
import styled from "styled-components";

const H3 = styled.h3`
  margin: 10px 0;
  padding-bottom: 35px;
`;

type MainLabelTitleType = {
  title: string;
  notBoderBottom?: boolean;
};

export const MainLabelTitle: React.FC<MainLabelTitleType> = ({ title, notBoderBottom }) => {
  return (
    <div>
      <H3
        className="ms-fontSize-32"
        style={{
          borderBottom: notBoderBottom ? "" : "1px solid #e2e7e9",
        }}
      >
        <strong>{title}</strong>
      </H3>
    </div>
  );
};

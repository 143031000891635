import { Toggle } from "@fluentui/react";
import styled from "styled-components";

///Ordenados por aparición en la página.
export const LoadingContainer = styled.div`
    margin-top: 3em;
`;
export const LoadingTitle = styled.p`
    font-weight: 600;
    text-align: center;
    font-size: 28px;
    font-family: system-ui;
    margin-bottom: 3em;
`;
export const PageTitle = styled.h2`
    margin-bottom: 0;
    padding-left: 0;
    display: inline-block;
    margin-top: -10px !important;
    @media (width: 320px)
    {
        text-align: center;
    }
    @media (min-width: 321px) and (max-width: 480px)
    {
        text-align: center;
        max-width: 445px;
    }
    @media (min-width: 481px) and (max-width: 640px)
    {
        text-align: center;
    }
    @media (min-width: 641px) and (max-width: 750px)
    {
        text-align: center;
        font-size: 24px;
        line-height: 32px;
    }
    @media (min-width: 751px) and (max-width: 1024px)
    {
        text-align: left;
        font-size: 28px;
        line-height: 32px;
        padding-left: 0.5em;
        max-width: 96%;
    }
    @media (min-width: 1024px) and (max-width: 1366px)
    {
        text-align: left;
        padding-left: 0.5em;
        max-width: 96%;
    }
    @media (width: 1366px)
    {
        text-align: left;
        padding-left: 0.5em;
        max-width: 96%;
        font-size: 34px;
        line-height: 40px;
    }
    @media (min-width: 1367px) and (max-width: 1600px)
    {
        text-align: left;
        padding-left: 0.5em;
        max-width: 96%;
        line-height: 40px;
        font-size: 35px;
    }
    @media (min-width: 1601px) and (max-width: 2560px)
    {
        text-align: left;
        padding-left: 0.5em;
        max-width: 96%;
        line-height: 40px;
        font-size: 36px;
    }
`;
///Container Suscripción
export const DivColRectangleSubscription = styled.div``;
export const FirstRectangle = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    text-align: center;
    cursor: pointer;
`;
export const FirstDisabledRectangle = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #908B8B;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    text-align: center;
    cursor: pointer;
`;
export const FirstRectangleSmall = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-radius: 12px;
    text-align: center;
    cursor: pointer;
`;
export const FirstDisabledRectangleSmall = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #908B8B;
    border-radius: 12px;
    text-align: center;
    cursor: pointer;
`;
export const FirstTextRectangle = styled.div`
    color: white;
    padding-top: 0.7em;
    font-size: 25px;
    font-weight: 600;
`;
export const OutdatedRectangle = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: black;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    text-align: center;
    cursor: pointer;
`;
export const OutdatedRectangleSmall = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: black;
    border-radius: 12px;
    text-align: center;
    cursor: pointer;
`;
export const DivContainerSubscription = styled.div``;
export const RowPanel = styled.div`
    background-color: ${(props) => props.theme.palette.white};
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
`;
export const SmallPanelContainer = styled.div`
    width: auto;
    height: auto;
    padding: 1em 1em 1em 0.5em;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;
export const DivRowSmallPanel = styled.div`
    height: auto;
`;
export const DivColFirstPanels = styled.div``;
export const Panel = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;
export const DivRow11Title = styled.div``;
export const DivColFirstPanelTitle = styled.div``;
export const SmallTitle11 = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;
export const DivColFirstPanelBadge = styled.div``;
export const MiniBadge11 = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const DivRow11Text = styled.div``;
export const DivColFirstPanelText = styled.div``;
export const TextInside11 = styled.p`
    margin: 0;
    padding: 1em;
    font-size: 16px;
    padding-right: 1em;
    overflow-wrap: inherit;
    padding-top: 0;
`;
export const BoldName = styled.b`
    color: #CC214F;
`;
export const FirstContainerButton = styled.div`
    padding-left: 1em;
    padding-bottom: 1em;
    @media (width: 320px)
    {
        margin-top: 0;
        padding-left: 1em;
    }
    @media (max-width: 480px)
    {
        padding-top: 1em !important;
        padding-right: 0 !important;
    }
    @media (min-width: 481px) and (max-width: 640px)
    {
        padding-right: 0 !important;
    }
    @media (min-width: 681px) and (max-width: 2560px)
    {
        padding-right: 1em;
    }
`;
export const ButtonRedirect11 = styled.button`
    background-color: #CC214F;
    color: white;
    border-radius: 12px;
    border: 1px solid white;
    padding: 1em 1em 0.9em 1em;
    cursor: pointer;
`;
export const ButtonRedirectDisabled11 = styled.button`
    background-color: #908B8B;
    color: white;
    border-radius: 12px;
    border: 1px solid white;
    padding: 1em;
`;
export const DivColSecondPanels = styled.div`
    padding-right: 0;
`;
export const SecondPanel = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;
export const DivRow12Title = styled.div``;
export const DivColSecondPanelTitle = styled.div``;
export const SmallTitle12 = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;
export const DivColSecondPanelBadge = styled.div``;
export const MiniBadge12 = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const DivRow12Text = styled.div``;
export const DivColSecondPanelText = styled.div``;
export const TextInside12 = styled.p`
    margin: 0;
    padding: 1em;
    font-size: 16px;
    padding-right: 1em;
    overflow-wrap: anywhere;
`;
export const SecondContainerButton = styled.div`
    padding-bottom: 1em;
    padding-left: 1em;
    padding-right: 0;
    @media (width: 320px)
    {
        margin-top: 25vw;
        padding-left: 1em;
    }
    @media (max-width: 480px)
    {
        padding-top: 1em !important;
    }
    @media (min-width: 640px)
    {
        padding-right: 0.5em;
    }
    @media (width: 1664px)
    {
        margin-top: 2.6vw;
    }
`;
export const ButtonRedirect12 = styled.button`
    background-color: #CC214F;
    color: white;
    border-radius: 12px;
    border: 1px solid white;
    padding: 1em 1em 0.9em 1em;
    cursor: pointer;
`;
export const ButtonRedirectDisabled12 = styled.button`
    background-color: #908B8B;
    color: white;
    border-radius: 12px;
    border: 1px solid white;
    padding: 1em;
`;
export const BankPanel = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-top: 1em;
    @media (width: 1664px)
    {
        min-height: 220px;
    }
`;
export const DivRow12BTitle = styled.div``;
export const DivColSecondBPanelTitle = styled.div``;
export const SmallTitle12B = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;
export const DivColSecondBPanelBadge = styled.div``;
export const MiniBadge12B = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const DivRow12BText = styled.div``;
export const DivColSecondBPanelText = styled.div`
    padding-top: 1em;
`;
export const TextInside12B = styled.p`
    margin: 0;
    padding: 0 1em 1em 1em;
    font-size: 16px;
    padding-right: 0;
    overflow-wrap: inherit;
`;
export const SecondBContainerButton = styled.div`
    padding-bottom: 1em;
    padding-left: 1em;
    padding-right: 0;
    @media (width: 320px)
    {
        padding-left: 1em;
    }
    @media (max-width: 480px)
    {
        padding-top: 1em !important;
    }
    @media (min-width: 640px)
    {
        padding-right: 0.5em;
    }
    @media (width: 1664px)
    {
        margin-top: 2.6vw;
    }
`;
export const ButtonRedirect12B = styled.button`
    background-color: #CC214F;
    color: white;
    border-radius: 12px;
    border: 1px solid white;
    padding: 1em 1em 0.9em 1em;
    cursor: pointer;
`;
export const ButtonRedirectDisabled12B = styled.button`
    background-color: #908B8B;
    color: white;
    border-radius: 12px;
    border: 1px solid white;
    padding: 1em;
`;
export const TogglePanelContainer = styled.div`
    width: auto;
    height: auto;
    padding: 0em 1em 1em 0.5em;
    border-radius: 12px;
`;
export const DivRowTogglePanel = styled.div``;
export const ContainerCol12 = styled.div`
    padding-right: 0;
    width: 100% !important;
`;
export const ConfigurationPanel = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

    @media (width: 320px)
    {
        height: auto;
    }
`;
export const DivRowToggleTitle = styled.div``;
export const DivColToggleTitle = styled.div`
    @media (width: 320px)
    {
        padding-right: 0;
    }
`;
export const SmallTitleToggle = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px 0 16px 16px;
    font-weight: 900;
    font-size: 18px;
    @media (width: 320px)
    {
        font-size: 14px;
        padding: 1em 0 0.5em 1em;
    }
`;
export const DivColToggleToggle = styled.div`
    padding-right: 0;
    @media (width: 320px)
    {
        padding-top: 0.5em;
    }
    @media (min-width: 641px) and (max-width: 768px)
    {
        padding-top: 1.35em;
    }
    @media (min-width: 769px) and (max-width: 1366px)
    {
        padding-top: 1.5em;
    }
    @media (min-width: 1367px) and (max-width: 3000px)
    {
        padding-top: 1.7em;
    }
`;
export const ToggleStyled = styled(Toggle)`
    display: inline-block;
    margin-bottom: 0 !important;
`;
export const DivRowToggleText = styled.div`
    height: auto;
`;
export const DivColToggleText = styled.div`
    @media (width: 320px)
    {
        padding-right: 0;
    }
`;
export const TextInsideToggle = styled.p`
    margin: 0;
    padding: 1em 0 1em 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
`;
export const DivColToggleContainerButton = styled.div`
    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 1em;
    @media (width: 320px)
    {
        padding-top: 0;
        width: 100% !important;
    }
`;
export const ButtonRedirectDisabledToggle = styled.button`
    background-color: #908B8B;
    color: white;
    border-radius: 12px;
    border: 1px solid white;
    padding: 1em;
`;
export const ButtonRedirectToggle = styled.button`
    background-color: #CC214F;
    color: white;
    border-radius: 12px;
    border: 1px solid white;
    padding: 1em 1em 0.9em 1em;
    cursor: pointer;
`;
///Fin Subscription Container
///-------------------------------
///Container Titulares
export const DivColRectangleTitulares = styled.div`
    padding-right: 0.5em;
    @media (min-width: 320px) and (max-width: 1023px)
    {
        margin-top: 1em;
    }
`;
export const SecondRectangle = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    text-align: center;
    cursor: pointer;
`;
export const SecondTextRectangle = styled.div`
    color: white;
    padding-top: 0.7em;
    font-size: 25px;
    font-weight: 600;
`;

export const DivContainerTitulares = styled.div`
    padding-right: 0;
    height: auto;
    margin-bottom: 10px;
`;
export const SecondRowPanel = styled.div`
    background-color: ${(props) => props.theme.palette.white};
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    padding-bottom: 1em;
    height: auto;
`;
export const PanelContainer2 = styled.div`
    width: auto;
    height: auto;
    padding: 1em 0.5em 1em 0.5em;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;
export const DivColContainer2 = styled.div`
    padding-right: 0.5em;
    width: 100% !important;
`;
export const BigPanelCard21 = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;

export const SmallTitle21 = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;

export const MiniBadge21 = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;

export const TextInside21 = styled.p`
    margin: 0;
    padding: 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
`;
export const DivRowImages = styled.div`
    padding-bottom: 1em;
`;

export const Image1 = styled.img`
    @media (min-width: 50px) and (max-width: 319.999px)
    {
        width: 40px;
        height: auto;
    }
    @media (width: 320px)
    {
        width: 46px;
        height: auto;
    }
    @media (min-width: 321px) and (max-width: 479.999999px)
    {
        width: 48px;
        height: auto;
    }
    @media (width: 480px)
    {
        width: 50px;
        height: auto;
    }
    @media (min-width: 481px) and (max-width: 767.999999px)
    {
        width: 55px !important;
        height: auto !important;
    }
    @media (width: 768px)
    {
        width: 60px;
        height: auto;
    }
    @media (min-width: 769px) and (max-width: 1023.999999999px)
    {
        width: 75px;
        height: auto;
    }
    @media (min-width: 1024px) and (max-width: 1365.999999px)
    {
        width: 83px;
        height: auto;
    }
    @media (min-width: 1366px) and (max-width: 3000px)
    {
        width: 91px;
        height: auto;
    }
`;

export const Image2 = styled.img`
    @media (min-width: 50px) and (max-width: 319.999px)
    {
        width: 40px;
        height: auto;
    }
    @media (width: 320px)
    {
        width: 46px;
        height: auto;
    }
    @media (min-width: 321px) and (max-width: 479.999999px)
    {
        width: 48px;
        height: auto;
    }
    @media (width: 480px)
    {
        width: 50px;
        height: auto;
    }
    @media (min-width: 481px) and (max-width: 767.999999px)
    {
        width: 55px !important;
        height: auto !important;
    }
    @media (width: 768px)
    {
        width: 60px;
        height: auto;
    }
    @media (min-width: 769px) and (max-width: 1023.999999999px)
    {
        width: 75px;
        height: auto;
    }
    @media (min-width: 1024px) and (max-width: 1365.999999px)
    {
        width: 83px;
        height: auto;
    }
    @media (min-width: 1366px) and (max-width: 3000px)
    {
        width: 91px;
        height: auto;
    }
`;
export const DivColIcon = styled.div`
    @media (min-width: 480px) and (max-width: 767.99px)
    {
        padding-top: 0.25em;
    }
    @media (min-width: 768px) and (max-width: 1023.99px)
    {
        padding-top: 0.5em;
    }
    @media (min-width: 1024px) and (max-width: 1365.99px)
    {
        padding-top: 0.5em;
    }
    @media (min-width: 1366px) and (max-width: 1919.99px)
    {
        padding-top: 0.55em;
    }
    @media (min-width: 1920px) and (max-width: 3000px)
    {
        padding-top: 0.75em;
    }
`;
export const DivContainerButton21 = styled.div`
    padding-top: 1em;
    @media (min-width: 320px) and (max-width: 479.99px)
    {
        padding-right: 0;
    }
    @media (min-width: 480px) and (max-width: 640px)
    {
        padding-right: 0.5em !important;
        min-width: auto !important;
    }
`;
export const MiniButton21 = styled.button`
    background-color: #CC214F;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
    cursor: pointer;
`;
export const MiniButton21Disabled = styled.button`
    background-color: #908B8B;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
`;
export const PanelCard22 = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-top: 1em;
`;

export const SmallTitle22 = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;

export const MiniBadge22 = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;

export const TextInside22 = styled.p`
    margin: 0;
    padding: 0em 1em 1em 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
`;
export const DivContainerButton22 = styled.div`
    padding-top: 1em;
    padding-bottom: 1em;
`;
export const MiniButton22 = styled.button`
    background-color: #CC214F;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
    cursor: pointer;
`;
export const MiniButton22Disabled = styled.button`
    background-color: #908B8B;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
`;
export const PanelCard23 = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-top: 1em;
`;

export const SmallTitle23 = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;

export const MiniBadge23 = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;

export const TextInside23 = styled.p`
    margin: 0;
    padding: 0 1em 1em 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
`;
export const DivContainerButton23 = styled.div`
    padding-top: 1em;
    padding-bottom: 1em;
`;
export const MiniButton23 = styled.button`
    background-color: #CC214F;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
    cursor: pointer;
`;
export const MiniButton23Disabled = styled.button`
    background-color: #908B8B;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
`;
export const BigPanelCard24 = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-top: 1em;
`;

export const SmallTitle24 = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;

export const MiniBadge24 = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;

export const TextInside24 = styled.p`
    margin: 0;
    padding: 0em 1em 1em 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
`;
export const DivContainerButton24 = styled.div`
    padding-top: 1em;
    padding-bottom: 1em;
    @media (min-width: 320px) and (max-width: 344px)
    {
        padding-top: 3em !important;
    }
    @media (min-width: 345px) and (max-width: 460px)
    {
        padding-top: 2em !important;
    }
    @media (min-width: 461px) and (max-width: 480px)
    {
        padding-top: 1.5em;
    }
`;

export const MiniButton24 = styled.button`
    background-color: #CC214F;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
    cursor: pointer;
`;

export const MiniButton24Disabled = styled.button`
    background-color: #908B8B;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
`;
export const PanelCard25 = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-top: 1em;
`;

export const SmallTitle25 = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;

export const MiniBadge25 = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;

export const TextInside25 = styled.p`
    margin: 0;
    padding: 0em 1em 1em 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
`;
export const DivContainerButton25 = styled.div`
    padding-top: 1em;
    padding-bottom: 1em;
`;
export const MiniButton25 = styled.button`
    background-color: #CC214F;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
    cursor: pointer;
`;
export const MiniButton25Disabled = styled.button`
    background-color: #908B8B;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
`;
///Fin Titulares Container
///----------------------------------
///Container Verification
export const DivColRectangleVerification = styled.div`
    @media (min-width: 320px) and (max-width: 1365.99px)
    {
        margin-top: 1em;
    }
`;
export const ThirdRectangle = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    text-align: center;
    cursor: pointer;
`;
export const ThirdRectangleSmall = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-radius: 12px;
    text-align: center;
`;
export const ThirdTextRectangle = styled.div`
    color: white;
    padding-top: 0.7em;
    font-size: 25px;
    font-weight: 600;
`;
export const ThirdDisabledRectangle = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #908B8B;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    text-align: center;
    cursor: pointer;
`;
export const ThirdDisabledRectangleSmall = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #908B8B;
    border-radius: 12px;
    text-align: center;
`;
export const DivContainerVerification = styled.div`
    padding-right: 0 !important;
`;
export const ThirdRowPanel = styled.div`
    background-color: ${(props) => props.theme.palette.white};
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    padding-bottom: 1em;
`;
export const PanelContainer3 = styled.div`
    width: auto;
    height: auto;
    padding: 1em 0.5em 1em 0.5em;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;
export const DivColContainer3 = styled.div`
    width: 100% !important;
`;
export const SmallPanel = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;
export const DivRow31Title = styled.div``;
export const DivCol31Title = styled.div``;
export const SmallTitle31 = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;
export const DivCol31Badge = styled.div``;
export const MiniBadge31 = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const DivRow31Text = styled.div``;
export const DivCol31Text = styled.div``;
export const TextInside31 = styled.p`
    margin: 0;
    padding: 0 0 1em 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
`;
export const DivColRevisionPanel = styled.div`
    width: 100% !important;
    margin-top: 1em;
`;
export const StatusPanelTime = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    padding-bottom: 1em;
`;
export const DivRowRevisionTimeTitle = styled.div`
    padding-right: 0;
`;
export const DivColRevisionTimeTitle = styled.div``;
export const SmallTitleRevision = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;
export const DivRowRevisionTimeText = styled.div``;
export const DivColRevisionTimeTime = styled.div``;
export const TimeChecking = styled.div`
    border-radius: 12px;
    border: 2px solid transparent;
    margin-left: 2em;
    margin-right: 1.5em;
    text-align: center;
    background-color: lightblue;
`;
export const TimeCheckingText = styled.p`
    margin: 0;
    color: darkblue;
`;
export const DivColSavingPlanContainer = styled.div`
    width: 100% !important;
    margin-top: 1em;
`;
export const StatusPanelSavingPlan = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    padding-bottom: 1em;
`;
export const DivRowSavingPlanTitle = styled.div``;
export const DivColSavingPlanTitle = styled.div``;
export const SmallTitleSavingPlan = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;
export const DivRowSavingPlanText = styled.div``;
export const DivColSavingPlanText = styled.div``;
export const StatusPlan = styled.div`
    border-radius: 12px;
    border: 2px solid #908b8b;
    margin-left: 2em;
    margin-right: 1.5em;
    text-align: center;
`;
export const StatusPlanText = styled.p`
    margin: 0;
    color: white;
`;
///Fin Verification Container
///----------------------------------
///Container Portoflio
export const DivColRectanglePortfolio = styled.div`
    @media (min-width: 320px) and (max-width: 1365.99px)
    {
        margin-top: 1em;
    }
`;
export const FourthRectangle = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    text-align: center;
    cursor: pointer;
`;
export const FourthRectangleSmall = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-radius: 12px;
    text-align: center;
`;
export const FourthTextRectangle = styled.div`
    color: white;
    padding-top: 0.7em;
    font-size: 25px;
    font-weight: 600;
`;
export const FourthDisabledRectangle = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #908B8B;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    text-align: center;
    cursor: pointer;
`;
export const FourthDisabledRectangleSmall = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #908B8B;
    border-radius: 12px;
    text-align: center;
`;
export const DivContainerPortfolio = styled.div`
    padding-right: 0 !important;
`;
export const LastRowPanel = styled.div`
    background-color: ${(props) => props.theme.palette.white};
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    padding-bottom: 1em;
`;
export const PanelContainer4 = styled.div`
    width: auto;
    height: auto;
    padding: 1em 0.5em 1em 0.5em;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;
export const DivColContainer4 = styled.div`
    width: 100% !important;
`;
export const CartPanel = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;
export const DivRow41Title = styled.div``;
export const DivCol41Title = styled.div``;
export const SmallTitle41 = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;
export const DivCol41Badge = styled.div``;
export const MiniBadge41 = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const DivRow41Text = styled.div``;
export const DivCol41Text = styled.div``;
export const TextInside41 = styled.p`
    margin: 0;
    padding: 1em 0 1em 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
`;
export const DivContainerButton41 = styled.div`
    padding-bottom: 1em;
    width: 100% !important;
`;
export const MiniButton41 = styled.button`
    background-color: #CC214F;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
    cursor: pointer;
`;
export const MiniButton41Disabled = styled.button`
    background-color: #908B8B;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
`;
export const SmallCartPanel = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-top: 1em;
`;
export const DivRow42Title = styled.div``;
export const DivCol42Title = styled.div``;
export const SmallTitle42 = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;
export const DivCol42Badge = styled.div``;
export const MiniBadge42 = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const DivRow42Text = styled.div``;
export const DivCol42Text = styled.div``;
export const TextInside42 = styled.p`
    margin: 0;
    padding: 0 0 1em 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
`;
export const DivContainerButton42 = styled.div`
    padding-bottom: 1em;
    width: 100% !important;
`;
export const MiniButton42 = styled.button`
    background-color: #CC214F;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
    cursor: pointer;
`;
export const MiniButton42Disabled = styled.button`
    background-color: #908B8B;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
`;
export const ExtraSmallPanel = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-top: 1em;
`;
export const DivRow43Title = styled.div``;
export const DivCol43Title = styled.div``;
export const SmallTitle43 = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;
export const DivCol43Badge = styled.div``;
export const MiniBadge43 = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const DivRow43Text = styled.div``;
export const DivCol43Text = styled.div``;
export const TextInside43 = styled.p`
    margin: 0;
    padding: 1em 0 1em 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
`;
export const DivContainerButton43 = styled.div`
    padding-bottom: 1em;
    width: 100% !important;
`;
export const MiniButton43 = styled.button`
    background-color: #CC214F;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
    cursor: pointer;
`;
export const MiniButton43Disabled = styled.button`
    background-color: #908B8B;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
`;
export const DivRow44Title = styled.div``;
export const DivCol44Title = styled.div``;
export const SmallTitle44 = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;
export const DivCol44Badge = styled.div``;
export const MiniBadge44 = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const DivRow44Text = styled.div``;
export const DivCol44Text = styled.div``;
export const TextInside44 = styled.p`
    margin: 0;
    padding: 0 0 1em 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
`;
export const DivContainerButton44 = styled.div`
    padding-bottom: 1em;
    width: 100% !important;
`;
export const MiniButton44 = styled.button`
    background-color: #CC214F;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
    cursor: pointer;
`;
export const MiniButton44Disabled = styled.button`
    background-color: #908B8B;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
`;
///Fin Container Portfolio
import { DirectionalHint, IDropdownStyles, Spinner, SpinnerSize, TeachingBubble } from "@fluentui/react";
import "office-ui-fabric-react/dist/css/fabric.css";
import { useEffect, useState } from "react";
import "office-ui-fabric-react/dist/css/fabric.css";
import Auth from "../../../../Auth/Auth";
import
{
    AccountRequestType,
    AccountsAutorized,
    AccountsUsed,
} from "../../../../services/SubscriptionService";
import UserService from "../../../../services/UserService";
import { ModalWithInputs } from "../../../../components/Modals/ModalWithInputs";
import { ModalWithCancel } from "../../../../components/Modals/ModalWithCancel";
import ProcessingModalComponent from "../../../../components/Modals/ProcessingModalComponent";
import { ErrorModal } from "../../../../components/Modals/ErrorModal";
import AccountTable from "./AccountTable";
import * as L from "../styles/AccountTableStyles";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import React, { ReactElement, } from "react";
import { InfoIcon } from "@fluentui/react-icons-mdl2";
import SavingsPlanService, { ActiveSubscriptionAndSavingPlans } from "../../../../services/SavingsPlanService";

const AccountTableLogic = ({
    dataUsed,
    dataAutorized,
    loading,
    error,
    setRefresh
}: {
    dataUsed?: AccountsUsed[];
    dataAutorized?: AccountsAutorized[];
    loading: boolean;
    error: any;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}) =>
{
    const [accountsUsed, setAccountsUsed] = useState<AccountsUsed[]>();
    const [accountsAutorized, setAccountsAutorized] = useState<AccountsAutorized[]>();
    const user = Auth.getUserProfile();
    const [loadingCall, setLoadingCall] = useState<boolean>(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showValidateModal, setShowValidateModal] = useState(false);
    const [idBankAccount, setIdBankAccount] = useState<number>(0);
    const [haveError, setHaveError] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File>();
    const [errorCall, setErrorCall] = useState({ status: false, message: "" });

    const [correctResponseValidate, setCorrectResponseValidate] = useState(false);
    const [options, setOptions] = useState<IDropdownOption[]>();
    const [selectedActivePlansIds, setSelectedActivePlansIds] = useState<number[]>([]);
    const [selectedActivePlansData, setSelectedActivePlansData] = useState<IDropdownOption>();
    const [teachingBubbleVisiblePortfolio, setTeachingBubbleVisiblePortfolio] = useState(false);

    const firstLoad = React.useRef<boolean>(true);

    const activePlan = Auth.getActivePlan();

    useEffect(() =>
    {
        setAccountsAutorized(dataAutorized);
        setAccountsUsed(dataUsed);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataUsed, dataAutorized]);

    useEffect(() =>
    {
        if (firstLoad.current === true)
        {
            firstLoad.current = false;


            let selectedActivePlanIds: number[] = [];

            SavingsPlanService.getActiveSubscriptionAndSavingPlan(user.id).then((response: ActiveSubscriptionAndSavingPlans[]) =>
            {
                var aux: IDropdownOption[] = [];
                for (var i = 0; i < response.length; i++)
                {
                    aux.push(
                        {
                            key: response[i].id,
                            text: response[i].planName,
                            selected: response[i].selected,
                            id: response[i].id.toString()
                        });


                    if (response[i].selected)
                    {
                        let selectedlanId = response[i].id;
                        selectedActivePlanIds.push(selectedlanId);
                    }
                }
                Auth.setActiveSubscriptions(aux);
                setOptions(aux);

                setSelectedActivePlansIds(selectedActivePlanIds);
                setSelectedActivePlansData(aux.find(e => e.selected == true));
            });


        }
    }, [user]);


    const columns = [
        {
            id: Math.floor(Math.random() * 100000).toString(),
            title: "IBAN",
        },
        {
            id: Math.floor(Math.random() * 100000).toString(),
            title: "Acciones",
        },
    ];

    useEffect(() =>
    {
        window.scrollTo(0, 0);
    }, []);




    const validateAccount = (accountOrigin: string, isFromAddButton: boolean, idDebt?: string, createAt?: Date) =>
    {
        var bankAccountInSavingPlan = accountsAutorized?.find(e => e.accountOrigin == idDebt);

        if(bankAccountInSavingPlan != undefined) {
           if(accountsAutorized?.some((e) => e.accountOrigin == idDebt && e.savingPlanId == selectedActivePlansIds[0] )){
            setHaveError(true);
            return setErrorCall({ status: true, message: "No puedes validar una cuenta que ya haya sido autorizada en el plan de cartera que indica. Solo lo podrá hacer si selecciona otro plan de cartera." });
           }
        }


        setLoadingCall(true);

        var data: AccountRequestType = {
            userId: user.id,
            accountOrigin: accountOrigin,
            holderOrigin: user.fullName,
            idDebt: idDebt,
            createAt: createAt,
        };

        const formData = new FormData();
        formData.append("file", selectedFile!);
        formData.append("data", JSON.stringify(data));
        formData.append("fromAddButton", JSON.stringify({ "isFromAddButton": isFromAddButton }));
        formData.append("savingPlanId", JSON.stringify({ "savingPlanId": Number(selectedActivePlansIds[0]!) }))

        UserService.postBankAccount(formData, user.id)
            .then((data) =>
            {
                setShowValidateModal(false);
                setCorrectResponseValidate(true);
                setRefresh((prevState: boolean) => !prevState);
            })
            .catch((error) =>
            {
                setHaveError(true);
                setErrorCall({ status: true, message: error.response.data });
            })
            .finally(() =>
            {
                setLoadingCall(false);
            });
    };

    const deleteAccount = (idBankAccount: number) =>
    {
        if (idBankAccount == 0)
        {
            setHaveError(true);
            return setErrorCall({ status: true, message: "El id seleccionado ha dado un fallo. Vuelva a intentarlo" });
        }

        setLoadingCall(true);

        UserService.deleteBankAccount(idBankAccount, user.id)
            .then((data) =>
            {
            })
            .catch((error) =>
            {
                setHaveError(true);
                setErrorCall({ status: true, message: error.response.data });
            })
            .finally(() =>
            {
                setLoadingCall(false);
                setRefresh((prevState: boolean) => !prevState);
            });
    };

    const updateDeleteInfo = (idBankAccount: number) =>
    {
        setIdBankAccount(idBankAccount);
        setShowDeleteModal(true);
    };

    const updateValidateInfo = async (
        isFromButton: boolean, accountNumber?: string, idDebt?: string, createAt?: Date,
    ) =>
    {

        if (isFromButton)
        {
            await setShowValidateModal(true);
        } else
        {
            if (accountNumber == undefined)
            {
                setHaveError(true);
                return setErrorCall({ status: true, message: "Se ha producido un error al intentar añadir la cuenta. Vuelva a intentarlo" });
            }

            await validateAccount(accountNumber, isFromButton, idDebt, createAt);
        }
    };

    const downloadPdf = (idBankAccount: number) =>
    {
        UserService.dowloadBase64Pdf(idBankAccount, user.id).then((response: any) =>
        {
            var downloadLink = document.createElement("a");
            downloadLink.download = `download.pdf`;
            downloadLink.href = response;
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            hideModal();
        })
            .catch((error) =>
            {
                console.log(error);
            });
    }


    const hideModal = () =>
    {
        setLoadingCall(false);
    }

    const validateInputs = (bankAccount: string) =>
    {

        var bankAccountInSavingPlan = accountsAutorized?.find(e => e.accountOrigin == bankAccount);

        if(bankAccountInSavingPlan != undefined) {

           if(accountsAutorized?.some((e) => e.accountOrigin == bankAccount && e.savingPlanId == selectedActivePlansIds[0] )){
                return false;
           }
        }


        return true;
    }

    const selectedAccount = (idBankAccount: string) =>
    {
        setLoadingCall(true);
        const formData = new FormData();
        formData.append("selectedAccount", JSON.stringify({ "selectedAccount": idBankAccount }));

        UserService.selectBankAccount(formData, user.id)
            .then((data) =>
            {
                
            })
            .catch((error) =>
            {
                setHaveError(true);
                setErrorCall({ status: true, message: error.response.data });
            })
            .finally(() =>
            {
                setLoadingCall(false);
                setRefresh((prevState: boolean) => !prevState);
            });
    };

    const dropdownStyles: Partial<IDropdownStyles> =
    {
        caretDown:
        {
            color: "#cc214F",
            fontSize: "14px !important",
            fontWeight: 700,
            background: "transparent !important"
        },
        dropdownItems:
        {
            color: "#cc214F !important",
            borderColor: "transparent !important",
            fontWeight: 500,
            fontSize: "20px",
            marginTop: "5px"
        },
        title:
        {
            color: "#cc214F !important",
            borderColor: "transparent !important",
            fontWeight: 500,
            fontSize: "20px",
            marginTop: "5px"
        },
        dropdownItemSelected:
        {
            color: "rgb(204, 33, 79) !important",
            backgroundColor: "#cc214F !important",
        },
        dropdownOptionText: { color: "rgb(204, 33, 79) !important" }
    };

    const handleRenderTitle = (items: IDropdownOption[] | undefined): ReactElement<any, any> =>
    {
        if (items && selectedActivePlansData != undefined)
        {
            return <React.Fragment>{`${selectedActivePlansData.text}`}</React.Fragment>;

        }
        return <React.Fragment></React.Fragment>;
    };

    const updateSelectedActivePlanKeys = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void =>
    {
        setSelectedActivePlansData(item);
        setSelectedActivePlansIds([Number(item!.id)]);
    };

    return (
        <div>
            {loading === true && <Spinner size={SpinnerSize.large} />}
            {loading === false && (
                <div className="ms-Grid-row">
                    <L.TableWrapper className="ms-Grid-col ms-sm5 ms-md5 ms-lg5 ms-xl5 ms-xxl5 ms-xxxl5">
                        <L.TableWidthManager>
                            <L.TableFilterContainer className="ms-Grid-row">
                                <L.TRCountContainer>
                                    <style>
                                        {`
                                    .ms-Dropdown-container span.ms-Dropdown-title {
                                        background: transparent !important;
                                    }
                                `}
                                    </style>
                                    <Dropdown placeholder={activePlan.name}
                                        options={options as IDropdownOption[]}
                                        selectedKeys={selectedActivePlansIds}
                                        onRenderTitle={handleRenderTitle}
                                        onChange={(event, item) => updateSelectedActivePlanKeys(event, item)}
                                        styles={dropdownStyles}
                                        disabled={loading}
                                        multiSelect={false}
                                        className="ms-Grid-col ms-sm9 ms-md9 ms-lg9 ms-xl9 ms-xxl9 ms-xxxl9"
                                    />
                                    <InfoIcon
                                        style={{
                                            color: " #94a1a1",
                                            width: "16px",
                                            height: "16px",
                                            cursor: "pointer",
                                            marginLeft: "4px",
                                            marginTop: "4px"
                                        }}
                                        id="portfoliotoselected"
                                        onMouseEnter={() =>
                                            setTeachingBubbleVisiblePortfolio(true)
                                        }
                                        onMouseLeave={() =>
                                            setTeachingBubbleVisiblePortfolio(false)
                                        }
                                    />
                                </L.TRCountContainer>
                            </L.TableFilterContainer>
                            <AccountTable
                                tableTitle={"Cuentas Corrientes Autorizadas"}
                                tableCount={accountsAutorized?.length}
                                rowDataAutorized={accountsAutorized}
                                rowDataUsed={undefined}
                                columnTitles={columns}
                                updateValidateInfo={updateValidateInfo}
                                updateDeleteInfo={updateDeleteInfo}
                                downloadPdf={downloadPdf}
                                isAuthorized={true}
                                selectedAccount={selectedAccount}
                                selectedActivePlansIds={selectedActivePlansIds}
                            />
                        </L.TableWidthManager>
                    </L.TableWrapper>
                    <L.TableWrapper className="ms-Grid-col ms-sm5 ms-md5 ms-lg5 ms-xl5 ms-xxl5 ms-xxxl5">
                    <L.TableWidthManager>
                        <AccountTable
                            tableTitle={"Cuentas Corrientes Utilizadas"}
                            tableCount={accountsUsed?.length}
                            rowDataUsed={accountsUsed}
                            rowDataAutorized={undefined}
                            columnTitles={columns}
                            updateValidateInfo={updateValidateInfo}
                            updateDeleteInfo={updateDeleteInfo}
                            isAuthorized={false}
                            downloadPdf={downloadPdf}
                            selectedAccount={selectedAccount}
                            selectedActivePlansIds={selectedActivePlansIds}
                        />
                         </L.TableWidthManager>
                    </L.TableWrapper>
                </div>
            )}
            <ModalWithInputs
                title="Añadir nueva cuenta bancaria"
                showModal={showValidateModal}
                showValidateModal={setShowValidateModal}
                onSubmit={(item: string) => validateAccount(item!, true)}
                sendPetition={(item: string) => validateAccount(item, true)}
                validateInputs={validateInputs}
                body={
                    <p>Para añadir una nueva cuenta, las dos cuentas deben ser iguales. Además, debe adjuntar el justificante de titularidad de la cuenta introducida.</p>
                }
                correctResponse={correctResponseValidate}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
            />
            <ModalWithCancel
                title="¿Desea borrar el registro?"
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                onSubmit={(item: string) => deleteAccount(idBankAccount)}
                body={
                    <p>Al pulsar en aceptar se eliminará el número de cuenta de nuestra base de datos. ¿Desea continuar con el proceso?</p>
                }
            />
            <ErrorModal
                title="Información incoherente"
                body={
                    <>{errorCall.message.includes("<div>") ? <div dangerouslySetInnerHTML={{ __html: errorCall.message }} /> : <p>{errorCall.message}</p>}</>
                }
                btnSubmitText="Aceptar"
                showModal={haveError}
                onSubmit={() =>
                {
                    setHaveError(false);
                }}
                onClose={() =>
                {
                    setHaveError(false);
                }}
            />
            <ProcessingModalComponent
                isModalOpen={loadingCall}
                hideModal={hideModal}
            />
            {teachingBubbleVisiblePortfolio === true && (
                // @ts-ignore
                <TeachingBubble
                    target={"#portfoliotoselected"}
                    hasCloseButton={true}
                    hasSmallHeadline={false}

                    closeButtonAriaLabel="Cerrar"
                    calloutProps={{
                        directionalHint: DirectionalHint.rightBottomEdge,
                        calloutWidth: 290,
                        calloutMaxHeight: 500,
                    }}
                    styles={{
                        content: {
                            maxWidth: "290px",
                            backgroundColor: "rgba(42, 128, 185, 0.68)",
                        },
                        bodyContent: { padding: "2px 0 2px 14px", cursor: "default" },
                        body: { marginBottom: "0 !important", cursor: "default" },
                    }}
                >
                    <h3>Selector de carteras</h3>
                    <p>
                        Se refiere a la cartera que tienes seleccionada. La información que aparece en la tabla de cuentas autorizadas se refiere a aquellas cuentas corrientes de las que eres titular siempre y cuando seas titular de la cartera seleccionada.
                    </p>
                </TeachingBubble>
            )}
        </div>
    );
};

export default AccountTableLogic;

import { DefaultButton } from "@fluentui/react";
import { useHistory } from "react-router-dom";
import React from "react";

interface PromoFriendsButtonProps {
	sending: boolean;
	setSending: any;
	text: string;
}

const PromoFriendsButton = (props: PromoFriendsButtonProps) => {
	const history = useHistory();

	return (
		<React.Fragment>
			<DefaultButton className="button tiny-primary-button" style={{ float: "right" }} onClick={() => history.push("/elements/friends-promotion/contract")}>
				{props.text}
			</DefaultButton>
		</React.Fragment>
	);
};

export default PromoFriendsButton;

import React from "react";
import { OrderTrendByFund } from "../../../services/OrderService";
import { ThemeContext } from "@fluentui/react-theme-provider";
import styled from "styled-components";
import TableBody from "../TableBody";
import { StockDownIcon, StockUpIcon } from "@fluentui/react-icons-mdl2";

type Props = {
	funds: OrderTrendByFund[];
	page: number;
	fundsPerPage: number;
};

export const tendencyOperation = (
	compra: number | undefined,
	transpasoOrigen: number | undefined,
	transpasoDestino: number | undefined,
	reembolso: number | undefined
) => {
	return (compra ?? 0) + (transpasoDestino ?? 0) - (transpasoOrigen ?? 0) - (reembolso ?? 0);
};

type SortConfig = {
	key: "semana" | "mes" | "trimestre" | "año" | "total";
	direction: "descending" | "ascending";
};

const TableTends = (props: Props) => {
	const { funds, page, fundsPerPage } = props;
	const theme = React.useContext(ThemeContext);
	const [sortConfig, setSortConfig] = React.useState<SortConfig>({ key: "semana", direction: "descending" });
    const [sortedItems, setSortedItems] = React.useState<OrderTrendByFund[]>([]);

	const requestSort = (key: typeof sortConfig.key) => {
		let direction: typeof sortConfig.direction = "descending";
		if(sortConfig.key === key && sortConfig.direction === "descending") {
			direction = "ascending";
		}
		setSortConfig({ key, direction });
	};

	const totalByPeriod = (fund: OrderTrendByFund, period: string) => {
		if(period === "semana") {
			return tendencyOperation(fund.semanaCompras, fund.semanaTraspasosOrigen, fund.semanaTraspasosDestino, fund.semanaReembolsos);
		}
		if(period === "mes") {
			return tendencyOperation(fund.mesCompras, fund.mesTraspasosOrigen, fund.mesTraspasosDestino, fund.mesReembolsos);
		}
		if(period === "trimestre") {
			return tendencyOperation(fund.trimestreCompras, fund.trimestreTraspasosOrigen, fund.trimestreTraspasosDestino, fund.trimestreReembolsos);
		}
		if(period === "año") {
			return tendencyOperation(fund.añoCompras, fund.añoTraspasosOrigen, fund.añoTraspasosDestino, fund.añoReembolsos);
		}
		if(period === "total") {
			return tendencyOperation(fund.compras, fund.traspasosOrigen, fund.traspasosDestino, fund.reembolsos);
		}
		return 0;
	};

	React.useEffect(() => {
		const indexOfLastRow = (page + 1) * fundsPerPage;
		const indexOfFirstRow = indexOfLastRow - fundsPerPage;

		let sortedFunds = [...funds];

		sortedFunds.sort((a, b) => {
			if(totalByPeriod(a, sortConfig.key) > totalByPeriod(b, sortConfig.key)) {
				return sortConfig.direction === "descending" ? -1 : 1;
			} else if(totalByPeriod(a, sortConfig.key) < totalByPeriod(b, sortConfig.key)) {
				return sortConfig.direction === "descending" ? 1 : -1;
			} else {
				return 0;
			}
		});

		setSortedItems(sortedFunds.slice(indexOfFirstRow, indexOfLastRow));
	}, [funds, fundsPerPage, page, sortConfig.key, sortConfig.direction]);

	return (
		<TableStyled theme={theme}>
			<thead>
				<tr>
					<th style={{ paddingLeft: 20 }}>
						<div>Nombre</div>
					</th>
					<th style={{ textAlign: "right" }} onClick={() => requestSort("semana")}>
						<div>
							Semana {sortConfig.key === "semana" ? (sortConfig.direction === "ascending" && <StockUpIcon color="black"/>) || <StockDownIcon /> : <></>}
						</div>
					</th>
					<th style={{ textAlign: "right" }} onClick={() => requestSort("mes")}>
						<div>
							Mes {sortConfig.key === "mes" ? (sortConfig.direction === "ascending" && <StockUpIcon />) || <StockDownIcon /> : <></>}
						</div>
					</th>
					<th style={{ textAlign: "right" }} onClick={() => requestSort("trimestre")}>
						<div>
							Trimestre {sortConfig.key === "trimestre" ? (sortConfig.direction === "ascending" && <StockUpIcon />) || <StockDownIcon /> : <></>}
						</div>
					</th>
					<th style={{ textAlign: "right" }} onClick={() => requestSort("año")}>
						<div>
							Año {sortConfig.key === "año" ? (sortConfig.direction === "ascending" && <StockUpIcon />) || <StockDownIcon /> : <></>}
						</div>
					</th>
					<th style={{ textAlign: "right" }} onClick={() => requestSort("total")}>
						<div>
							Total {sortConfig.key === "total" ? (sortConfig.direction === "ascending" && <StockUpIcon />) || <StockDownIcon /> : <></>}
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				{sortedItems &&
					sortedItems.length > 0 &&
					sortedItems.map((row, index) => (
						<React.Fragment key={index}>
							<TableBody fund={row} index={index}></TableBody>
						</React.Fragment>
					))}
			</tbody>
		</TableStyled>
	);
};

export default TableTends;

const TableStyled = styled.table`
	width: 100%;
	overflow-x: auto;
	border-spacing: 0;
	overflow: hidden;
	border-radius: 16px;
	thead {
		background: ${props => props.theme.palette.tertiary};
		text-align: left;
		th {
			border-spacing: 0;
			padding: 17px;
		}
	}
	tbody {
		button.expanded {
			border: none;
			cursor: pointer;
			transition: 150ms;
			border-radius: 0;
			&:hover {
				transition: 150ms;
			}
		}
		strong {
			color: ${props => props?.theme?.palette?.black};
		}
		tr {
			td {
				padding: 5px 17px 5px 10px;
				p {
					color: ${props => props?.theme?.palette?.black};
				}
			}
			svg {
				margin-right: 3px;
			}
			.max-content {
				min-width: max-content;
			}
		}
	}
`;

import moment from "moment";
import React, { useEffect } from "react";
import DocumentTitle from "react-document-title";
import { useHistory } from "react-router";
import Auth from "../../Auth/Auth";
import { ISignature } from "../../services/SignaturesService";
import scrollToTop from "../../utils/scrollToTop";
import * as E from '../Elements/ElementStyles';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { Spinner, SpinnerSize } from "@fluentui/react";
import { SignatureComponent } from "../../components/Signature/SignatureComponent";
import ModalElementSigned from "./ModalElementSigned";
import { ElementAmountType, ElementSignedType } from "../../services/ElementsService";
import ElementsService from "../../services/ElementsService";
import { setSavingsPlanElementsStatus } from "../../store/auth/actions";
import { useDispatch } from "react-redux";

const ElementSignatureConfirm = (props: any) =>
{
    const history = useHistory();
    const activePlan = Auth.getActivePlan();
    const [modalShow, setModalShow] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [signatures, setSignatures] = React.useState<ISignature[]>([]);
    const [phoneNumbers, setPhoneNumbers] = React.useState<string>("");
    const dispatch = useDispatch();

    React.useEffect(() =>
    {
        scrollToTop();
    }, []);

    React.useEffect(() =>
    {
        if(signatures.length === 0)
        {
            ElementsService.createElementAmount(activePlan.id).then((signatures: ISignature[]) =>
            {
                setSignatures(signatures);
                setIsLoaded(true);
            });
        }
    }, [signatures, activePlan.id, modalShow]);

    const processExpirationDate = (signs: ISignature[]) =>
    {
        if(signs !== undefined && signs.length > 0)
        {
            // eslint-disable-next-line array-callback-return
            signs.map((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = moment(signature.expiresAt).toDate();
                }
            });
        }
    }

    useEffect(() =>
    {
        if (signatures !== undefined) {
            setPhoneNumbers(signatures.map((signature: ISignature) =>
                signature.phone === undefined ? "" : "X".repeat(signature.phone.length - 3) + signature.phone.slice(signature.phone.length - 3, signature.phone.length)
            ).join(", "));
        }
    }, [signatures]);

    const handleSubmit = async () =>
    {
        if(signatures.length > 0 || !modalShow)
        {
            await dispatch(setSavingsPlanElementsStatus(activePlan.id));
            scrollToTop();
            setModalShow(true)
        }
    };

    return(
        <DocumentTitle title='IronIA - Elements - Firma de Contrato'>
            <React.Fragment>
                <E.HelperStyle />
                <E.ContainerSignature>
                    <E.MainContainerSignature>
                        <h2 style={{margin: 0}}>Elements - Firma de Contrato</h2>
                        <Separator />
                        {!isLoaded &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        {isLoaded &&
                        (
                            <SignatureComponent
                                signatures={signatures}
                                alreadySignedText=" "
                                submitButtonText="Firmar contrato"
                                cancelButtonText="Volver"
                                headerTextArea={"Introduce el código que te hemos enviado"
                                    + (signatures.length > 1 ? ` a los números ${phoneNumbers} ` : signatures.length === 1 ? ` al número ${phoneNumbers} ` : " ")
                                    + "por SMS para firmar el contrato."}
                                backButtonText="Volver"
                                onBackButtonClicked={() => history.push('/Elements/Signature')}
                                onCancelButtonClicked={() => history.push('/Elements/Signature')}
                                requestNewCodeButtonText="Recibir nuevo código"
                                onNewCodeSended={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                onSignatureChanged={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                onSigned={handleSubmit}
                            />
                        )}
                    </E.MainContainerSignature>
                </E.ContainerSignature>
                {modalShow && <ModalElementSigned setModalShow={setModalShow} />}
            </React.Fragment>
        </DocumentTitle>
    );
};

export default ElementSignatureConfirm;
import Air from "../../images/air.svg";
import Earth from "../../images/earth.svg";
import Fire from "../../images/fire.svg";
import Water from "../../images/water.svg";
import IA from "../../images/ia.svg";

interface ElementsIconProps
{
    type: string
};

const ElementsIcon = (props: ElementsIconProps) =>
{
    switch(props.type)
    {
        case "Air": return <img alt="Air" src={Air} className="elements-icon" />;
        case "Earth": return <img alt="Earth" src={Earth} className="elements-icon" />;
        case "Fire": return <img alt="Fire" src={Fire} className="elements-icon" />;
        case "Water": return <img alt="Water" src={Water} className="elements-icon" />;
        case "IA": return <img alt="IA" src={IA} className="elements-icon" />;
        default: return <></>;
    }
};

export default ElementsIcon;
import * as L from './layout.styles';
import UserIdentitiesService, { UserIdentityType } from '../../../services/UserIdentitiesService';
import ElementService from '../../../services/ElementsService';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import scrollToTop from '../../../utils/scrollToTop';
import Auth from '../../../Auth/Auth';
import React from 'react';
import SignaturesService from '../../../services/SignaturesService';
import Moment from "moment";

const FriendsPromotionContract = () =>
{
    const history = useHistory();
    const [contract, setContract] = useState<string>();
    const [userIdentities, setUserIdentities] = useState<UserIdentityType[]>([]);
    const [signatures, setSignatures] = useState<any[]>([]);
    const [phoneNumbers, setPhoneNumbers] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [bottom, setBottom] = useState(false);
    const activePlan = Auth.getActivePlan();

    useEffect(() =>
    {
        scrollToTop();
    }, []);

    useEffect(() =>
    {
        UserIdentitiesService.getIdentitiesBySavingsPlanId(activePlan.id).then((response: UserIdentityType[]) =>
        {
            var currentDate = Moment();

            var userIdentities = response.filter(userIdentity => {
                var birthdayDate = Moment(userIdentity.birthday);
                return currentDate.diff(birthdayDate, "years") >= 18;
            });
            setUserIdentities(userIdentities);
        });
    }, []);

    useEffect(() =>
    {
        if(userIdentities.length > 0)
        {
            setIsLoaded(false);
            ElementService.getElementContractByName('Contrato Elements Promoción Amigos').then((response: string) =>
            {
                if(response !== undefined)
                {
                    var fullname = [userIdentities[0].firstName, userIdentities[0].lastName, userIdentities[0].secondLastName].join(" ");
                    var dni = userIdentities[0].idDocument || 'NO DEFINIDO';
                    setContract(response.replace("[[FULLNAME]]", fullname).replace("[[DNI]]", dni));
                    setIsLoaded(true);
                }
            });
        }
    }, [userIdentities]);

    useEffect(() =>
    {
        setPhoneNumbers(userIdentities.map((userIdentity: any) =>
            "X".repeat(userIdentity.phone.length - 3)
            + userIdentity.phone.slice(userIdentity.phone.length - 3, userIdentity.phone.length)).join(", "));
    }, [userIdentities]);

    const handleSubmit = (event: any) =>
    {
        event.preventDefault();
        setIsSending(true);
         if(userIdentities.length > 0)
         {
            SignaturesService.createSignaturesBySavingsPlan(activePlan.id, "friends_promo", "Contrato Elements Promoción Amigos").then((response: any) =>
            {
                if(response.data !== null)
                {
                    setSignatures(response.data);
                    setIsSending(false);
                }
            });
         }
    };

    useEffect(() =>
    {
        if(userIdentities.length > 0 && userIdentities.length === signatures.length)
        {
            history.push(`/elements/friends-promotion/confirm`, { signatures: signatures, userIdentities: userIdentities });
        }
    }, [userIdentities, signatures]);

    const handleScroll = (event: any) =>
    {
        const bottom = event.target.scrollHeight - event.target.scrollTop <= (event.target.clientHeight + 40);
        setBottom(bottom)
    };

    return (
        <React.Fragment>
            <L.HelperStyle />
            <L.Cont>
                <L.MainCont>
                    <L.Title>
                        <h1>Firma del Contrato para la promoción: Quien tiene un amigo tiene Elements</h1>
                        <h2>
                            A continuación te incluimos los detalles del contrato. Léelo y
                            cuando estés listo, dale al botón de "solicitar firma". Te enviaremos un
                            código SMS al teléfono del titular principal
                            {userIdentities.length > 1
                                ? ` a los números ${phoneNumbers} `
                                : userIdentities.length === 1 ? ` al número ${phoneNumbers} ` : " "
                            }
                            para realizar la firma del contrato.
                        </h2>
                    </L.Title>
                    <L.TabletCont onScroll={handleScroll}>
                        {contract !== undefined && <div dangerouslySetInnerHTML={{ __html: contract }} />}
                        {!isLoaded && (<Spinner size={SpinnerSize.large} />)}
                    </L.TabletCont>
                    <L.BtnCont>
                        <L.ContinueBtn className="button primary-button"
                            onClick={handleSubmit}
                            disabled={!isLoaded || !bottom}
                        >
                            {isLoaded && isSending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                            Solicitar Firma
                        </L.ContinueBtn>
                    </L.BtnCont>
                </L.MainCont>
            </L.Cont>
        </React.Fragment>
    );
};

export default FriendsPromotionContract;
import React from "react";
import { Link } from "react-router-dom";
import { FundsWithRatiosResponse } from "../../../services/subcategories";
import { formatedCategoryValue } from "./tableBody";

type Props =
{
    funds: FundsWithRatiosResponse[];
    index: string | number;
};

export const ChildFunds = (props: Props) =>
{
    const {funds, index} = props
    return(
        <React.Fragment key={index}>
            {funds.map((row, index) =>
            {
                return(
                    <React.Fragment key={index}>
                        <tr>
                            <td>
                                <Link style={{marginLeft: "36px", textAlign: "left", flexWrap: "nowrap", whiteSpace: "nowrap"}} to={{pathname: "/" + row.isin}}>
                                    {row.name}
                                </Link>
                            </td>
                            {formatedCategoryValue(row.ratio1m)}
                            {formatedCategoryValue(row.bench1m)}
                            {formatedCategoryValue(row.ratio3m)}
                            {formatedCategoryValue(row.bench3m)}
                            {formatedCategoryValue(row.ratio6m)}
                            {formatedCategoryValue(row.bench6m)}
                            {formatedCategoryValue(row.ratio1y)}
                            {formatedCategoryValue(row.bench1y)}
                            {formatedCategoryValue(row.ratio2y)}
                            {formatedCategoryValue(row.bench2y)}
                            {formatedCategoryValue(row.ratio3y)}
                            {formatedCategoryValue(row.bench3y)}
                            {formatedCategoryValue(row.ratio4y)}
                            {formatedCategoryValue(row.bench4y)}
                            {formatedCategoryValue(row.ratio5y)}
                            {formatedCategoryValue(row.bench5y)}
                        </tr>
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};


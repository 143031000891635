import * as L from '../styles/SubscriptionTableStyles';
import { RouteComponentProps } from 'react-router';
import SubscriptionTableLogic from './SubscriptionTableLogic';
import DocumentTitle from 'react-document-title';
import React from 'react';
import ServiceSubscriptionTableLogic from './ServiceSubscriptionTableLogic';
import { Separator } from '@fluentui/react';

interface RouteParams { };
interface Props extends RouteComponentProps<RouteParams> { };

export const SubscriptionProfile: React.FunctionComponent<Props> = (props, context) => {
    return (
        <React.Fragment>
            <DocumentTitle title="IronIA - Suscripciones y Pagos">
                <L.Wrapper>
                    <L.TitleContainer>
                        <L.Title>Suscripciones y Pagos</L.Title>
                    </L.TitleContainer>
                    <Separator />
                    <SubscriptionTableLogic key={Math.floor(Math.random() * 100000)} />
                </L.Wrapper>
            </DocumentTitle>
            <ServiceSubscriptionTableLogic key={Math.floor(Math.random() * 100000)} />
        </React.Fragment>
    );
};
import { useParams } from "react-router";
import SuitabilityTestConfirm from "../../components/SuitabilityTests/SuitabilityTestConfirm";

const SuitabilityTestConfirmForComplexProduct = () => {
    const { savingsPlanId, isin, userIdentityId } = useParams<any>();

    return (
        <SuitabilityTestConfirm
            userIdentityId={userIdentityId}
            returnRoute={`/savingsPlans/${savingsPlanId}/complexProducts/${isin}/suitabilityTestList`}
            cancelRoute={`/savingsPlans/${savingsPlanId}/complexProducts/${isin}/suitabilityTest/${userIdentityId}`}
        />
    );
}

export default SuitabilityTestConfirmForComplexProduct;
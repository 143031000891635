import { ChevronLeftIcon, ChevronRightIcon } from "@fluentui/react-icons-mdl2";
import React from "react";
import styled from "styled-components";

const PaginationTableStyled = styled.div`
    .pagination
    {
        display: inline-flex;
        align-items: center;
        li
        {
            margin-right: 0.5rem;
        }
        button
        {
            background-color: #cc214f;
            color: white;
            font-size: 16px;
            font-weight: 700;
            border: 0;
            width: 24px;
            height: 24px;
            cursor: pointer;
            transition: 250ms;
            &:hover
            {
                transition: 250ms;
                opacity: 0.8;
            }
        }
        button.active
        {
            color: #CC214F;
            background-color: white;
            border: 1px solid;
        

        button:disabled
        {
            background-color: #c5c5c5;
        }
    }
`;

interface PaginationTableProps
{
    direction?: "start" | "center" | "end";
    onChangePage: (newPage: number) => void;
    totalPages: number;
    currentPage: number;
}

export const PaginationTable: React.FC<PaginationTableProps> = ({totalPages, onChangePage, direction, currentPage}) =>
{
    const [hasPrev, setHasPrev] = React.useState(false);
    const [hasNext, setHasNext] = React.useState(true);
    const [numberButtons, setNumberButtons] = React.useState<number>();

    React.useEffect(() =>
    {
        if(totalPages !== undefined)
        {
            setNumberButtons(Math.ceil(totalPages));
        }
    }, [totalPages]);

    React.useEffect(() =>
    {
        setHasPrev(currentPage > 0);
        setHasNext(currentPage < (totalPages - 1));
    }, [currentPage, totalPages]);

    return(
        <PaginationTableStyled>
            <ul className="pagination" style={{justifyContent: `flex-${direction}`}}>
                <li>
                    <button className="prev"
                        onClick={() =>
                        {
                            if(currentPage > 0)
                            {
                                setHasPrev(true);
                                onChangePage(currentPage-1);
                            }
                            else
                            {
                                setHasPrev(false);
                            }
                        }}
                        disabled={!hasPrev}
                    >
                        <ChevronLeftIcon />
                    </button>
                </li>
                {new Array(numberButtons).fill("").map((_, i) =>
                (
                    <li key={i}>
                        <button className={`page-item ${i === currentPage && "active"}`} onClick={() => onChangePage(i)}>
                            {i + 1}
                        </button>
                    </li>
                ))}
                <li>
                    <button className="next"
                        onClick={() =>
                        {
                            if((currentPage + 1) < totalPages)
                            {
                                onChangePage(currentPage + 1);
                                setHasNext(true);
                            }
                            else
                            {
                                setHasNext(false);
                            }
                        }}
                        disabled={!hasNext}
                    >
                        <ChevronRightIcon />
                    </button>
                </li>
            </ul>
        </PaginationTableStyled>
    );
};
import { Dropdown } from "@fluentui/react";
import styled from "styled-components";

///Ordenados por aparición en la página.
export const LoadingContainer = styled.div`
    margin-top: 3em;
`;
export const LoadingTitle = styled.p`
    font-weight: 600;
    text-align: center;
    font-size: 28px;
    font-family: system-ui;
    margin-bottom: 3em;
`;
export const PageTitle = styled.h2`
    margin-bottom: 0;
    padding-left: 0;
    display: inline-block;
    margin-top: -10px !important;
    @media (width: 320px)
    {
        text-align: center;
    }
    @media (min-width: 321px) and (max-width: 479.99px)
    {
        text-align: center;
        max-width: 445px;
    }
    @media (min-width: 481px) and (max-width: 640px)
    {
        text-align: center;
    }
    @media (min-width: 641px) and (max-width: 750px)
    {
        text-align: center;
        font-size: 24px;
        line-height: 32px;
    }
    @media (min-width: 751px) and (max-width: 1023.99px)
    {
        text-align: left;
        font-size: 28px;
        line-height: 32px;
        padding-left: 0.5em;
        max-width: 96%;
    }
    @media (min-width: 1024px) and (max-width: 1365.99px)
    {
        text-align: left;
        max-width: 96%;
    }
    @media (width: 1366px)
    {
        text-align: left;
        padding-left: 0.5em;
        max-width: 96%;
        font-size: 34px;
        line-height: 40px;
    }
    @media (min-width: 1367px) and (max-width: 1600px)
    {
        text-align: left;
        max-width: 96%;
        line-height: 40px;
        font-size: 35px;
    }
    @media (min-width: 1601px) and (max-width: 2560px)
    {
        text-align: left;
        max-width: 96%;
        line-height: 40px;
        font-size: 36px;
    }
`;
///Container PeriodicSaving
export const DivColRectanglePeriodicSaving = styled.div``;
export const FirstRectangle = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    text-align: center;
    @media (min-width: 320px) and (max-width: 1023.9999px)
    {
        cursor: pointer;
    }
    @media (min-width: 1024px) and (max-width: 3000px)
    {
        cursor: default;
    }
`;
export const FirstRectangleSmall = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-radius: 12px;
    text-align: center;
    @media (min-width: 320px) and (max-width: 1023.9999px)
    {
        cursor: pointer;
    }
    @media (min-width: 1024px) and (max-width: 3000px)
    {
        cursor: default;
    }
`;
export const FirstTextRectangle = styled.div`
    color: white;
    padding-top: 0.7em;
    font-size: 25px;
    font-weight: 600;
`;
export const DivContainerPeriodicSaving = styled.div`
    @media (min-width: 1024px) and (max-width: 1365px)
    {
        min-height: 780px;
    }
`;
export const RowPanel = styled.div`
    background-color: ${(props) => props.theme.palette.white};
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
`;
export const SmallPanelContainer = styled.div`
    width: auto;
    height: auto;
    padding: 1em 0.5em 1em 0.5em;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;
export const LastSmallPanelContainer = styled.div`
    width: auto;
    height: auto;
    padding: 1em 0.5em 0 0.5em;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;
export const DivRowSmallPanel = styled.div`
    height: auto;
`;
export const DivColFirstPanels = styled.div`
    @media (min-width: 320px) and (max-width: 639.99px)
    {
        width: 100% !important;
    }
`;
export const Panel = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;
export const DivRow11Title = styled.div``;
export const DivColFirstPanelTitle = styled.div``;
export const SmallTitle11 = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;
export const DivColFirstPanelBadge = styled.div``;
export const MiniBadge11 = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const DivRow11Text = styled.div``;
export const DivColFirstPanelText = styled.div``;
export const TextInside11 = styled.p`
    margin: 0;
    padding-top: 0;
    padding-right: 0.25em;
    padding-bottom: 1em;
    padding-left: 1em;
    font-size: 16px;
    overflow-wrap: inherit;
`;
export const DivRowTogglePanel = styled.div``;
export const DivContainerCol11 = styled.div`
    @media (min-width: 320px) and (max-width: 639.99px)
    {
        width: 100% !important;
    }
`;
export const ConfigurationPanel = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

    @media (width: 320px)
    {
        height: auto;
    }
`;
export const DivRowToggleTitle = styled.div``;
export const DivCol11Badge = styled.div``;
export const DivCol11Text = styled.div``;
export const DivRowPortfolioSelector = styled.div`
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
`;
export const PortfolioSelector = styled(Dropdown)`
    width: 100%;
`;
export const DivRowVideo1Title = styled.div``;
export const DivColFirstVideoTitle = styled.div``;
export const SmallTitleVideo1 = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
`;
export const DivRowVideo1Text = styled.div``;
export const DivColFirstVideoText = styled.div``;
export const TextInsideVideo1 = styled.p`
    margin: 0;
    padding-top: 0;
    padding-right: 0.25em;
    padding-bottom: 1em;
    padding-left: 1em;
    font-size: 16px;
    overflow-wrap: inherit;
`;
export const LinkToYoutube = styled.a`
    color: ${(props) => props.theme.palette.black};
`;
export const DivRowImageVideo1 = styled.div`
    text-align: center;
    padding-bottom: 1em;
    @media (min-width: 320px) and (max-width: 1023.99px)
    {
        padding-top: 1em;
    }
`;
export const ImageVideo1 = styled.img`
    cursor: pointer;
    border-radius: 12px;
    @media (min-width: 50px) and (max-width: 319.999px)
    {
        width: 40px;
        height: auto;
    }
    @media (width: 320px)
    {
        width: 150px;
        height: auto;
    }
    @media (min-width: 321px) and (max-width: 479.999999px)
    {
        width: 150px;
        height: auto;
    }
    @media (width: 480px)
    {
        width: 170px;
        height: auto;
    }
    @media (min-width: 481px) and (max-width: 639.999999px)
    {
        width: 180px !important;
        height: auto !important;
    }
    @media (min-width: 640px) and (max-width: 767.9999px)
    {
        width: 200px;
        height: auto;
    }
    @media (width: 768px)
    {
        width: 220px;
        height: auto;
    }
    @media (min-width: 769px) and (max-width: 1023.999999999px)
    {
        width: 300px;
        height: auto;
    }
    @media (min-width: 1024px) and (max-width: 1365.999999px)
    {
        width: 350px;
        height: auto;
    }
    @media (min-width: 1366px) and (max-width: 3000px)
    {
        width: 250px;
        height: auto;
    }
`;
///Fin Container PeriodicSaving
///----------------------------
///Container ModelPortfolio
export const DivColRectangleModelPortfolio = styled.div`
    padding-right: 0.5em;
    @media (min-width: 320px) and (max-width: 1023.9999px)
    {
        cursor: pointer;
    }
    @media (min-width: 1024px) and (max-width: 3000px)
    {
        cursor: default;
    }
    @media (min-width: 320px) and (max-width: 1023.55px)
    {
        margin-top: 1em;
    }
`;
export const SecondRectangle = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    text-align: center;
`;
export const SecondRectangleSmall = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-radius: 12px;
    text-align: center;
`;
export const SecondTextRectangle = styled.div`
    color: white;
    padding-top: 0.7em;
    font-size: 25px;
    font-weight: 600;
`;
export const DivContainerModelPortfolio = styled.div`
    padding-right: 0;
    height: auto;
    @media (min-width: 1024px) and (max-width: 1365px)
    {
        min-height: 780px;
    }
`;
export const SecondRowPanel = styled.div`
    background-color: ${(props) => props.theme.palette.white};
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    padding-bottom: 1em;
    height: auto;
`;
export const SmallPortfolioPanel = styled.div`
    width: auto;
    height: auto;
    padding: 1em 0.5em 1em 0.5em;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;
export const LastSmallPortfolioPanel = styled.div`
    width: auto;
    height: auto;
    padding: 1em 0.5em 0 0.5em;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;
export const DivRowSmallPortfolioPanel = styled.div`
    height: auto;
`;
export const DivColPortfolioPanel = styled.div`
    @media (min-width: 320px) and (max-width: 639.99px)
    {
        width: 100% !important;
    }
`;
export const PortfolioPanel = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;
export const DivRowPortfolioTitle = styled.div``;
export const DivColPortfolioTitle = styled.div``;
export const SmallTitlePortfolio = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
    cursor: default;
`;
export const DivRowPortfolioText = styled.div``;
export const DivColPortfolioText = styled.div``;
export const TextInsidePortfolio = styled.p`
    margin: 0;
    padding: 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
    cursor: default;
    padding-top: 0;
`;
export const SmallPortfolioVideoPanel = styled.div`
    width: auto;
    height: auto;
    padding: 0 0.5em 1em 0.5em;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;
export const PortfolioPanelVideo = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;
export const DivRowImageVideoPortfolio = styled.div`
    text-align: center;
    padding-bottom: 1em;
    padding-top: 1em;
`;
export const ImageVideoPortfolio = styled.img`
    cursor: pointer;
    border-radius: 12px;
    @media (min-width: 50px) and (max-width: 319.999px)
    {
        width: 40px;
        height: auto;
    }
    @media (width: 320px)
    {
        width: 150px;
        height: auto;
    }
    @media (min-width: 321px) and (max-width: 479.999999px)
    {
        width: 150px;
        height: auto;
    }
    @media (width: 480px)
    {
        width: 170px;
        height: auto;
    }
    @media (min-width: 481px) and (max-width: 639.999999px)
    {
        width: 180px !important;
        height: auto !important;
    }
    @media (min-width: 640px) and (max-width: 767.9999px)
    {
        width: 200px;
        height: auto;
    }
    @media (width: 768px)
    {
        width: 220px;
        height: auto;
    }
    @media (min-width: 769px) and (max-width: 1023.999999999px)
    {
        width: 300px;
        height: auto;
    }
    @media (min-width: 1024px) and (max-width: 1365.999999px)
    {
        width: 350px;
        height: auto;
    }
    @media (min-width: 1366px) and (max-width: 3000px)
    {
        width: 250px;
        height: auto;
    }
`;
export const DivRowCartTitle = styled.div``;
export const DivColCartTitle = styled.div``;
export const SmallCartTitle = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
    cursor: default;
`;
export const DivRowCartText = styled.div``;
export const DivColCartText = styled.div``;
export const TextInsideCart = styled.p`
    margin: 0;
    padding: 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
    cursor: default;
    padding-top: 0;
    padding-bottom: 0;
`;
export const DivColCartBadge = styled.div``;
export const MiniBadgeCart = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const DivContainerCartButton = styled.div`
    padding-bottom: 1em;
`;
export const MiniCartButton = styled.button`
    background-color: #CC214F;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
    cursor: pointer;
`;
export const DivRowFromPortfolioTitle = styled.div``;
export const DivColFromPortfolioTitle = styled.div``;
export const SmallFromPortfolioTitle = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
    cursor: default;
`;
export const DivRowFromPortfolioText = styled.div``;
export const DivColFromPortfolioText = styled.div``;
export const TextInsideFromPortfolio = styled.p`
    margin: 0;
    padding: 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
    cursor: default;
`;
export const DivColFromPortfolioBadge = styled.div``;
export const MiniBadgeFromPortfolio = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const DivContainerFromPortfolioButton = styled.div`  
    padding-bottom: 1em;
`;
export const MiniFromPortfolioButton = styled.button`
    background-color: #CC214F;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
    cursor: pointer;
`;
///Fin ModelPortfolio Container
///----------------------------------
///Container PeriodicContributions
export const DivColRectanglePeriodicContributions = styled.div`
    @media (min-width: 320px) and (max-width: 1023.9999px)
    {
        cursor: pointer;
    }
    @media (min-width: 1024px) and (max-width: 3000px)
    {
        cursor: default;
    }
    @media (min-width: 320px) and (max-width: 1365px)
    {
        margin-top: 1em;
    }
`;
export const DivRowPeriodicContributions = styled.div``;
export const ThirdRectangle = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    text-align: center;
`;
export const ThirdRectangleSmall = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-radius: 12px;
    text-align: center;
`;
export const ThirdTextRectangle = styled.div`
    color: white;
    padding-top: 0.7em;
    font-size: 25px;
    font-weight: 600;
`;
export const ThirdRowPanel = styled.div`
    background-color: ${(props) => props.theme.palette.white};
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    padding-bottom: 1em;
    height: auto;
`;
export const SmallPeriodicContributionsPanel = styled.div`
    width: auto;
    height: auto;
    padding: 1em 0.5em 1em 0.5em;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;
export const LastSmallPeriodicContributionsPanel = styled.div`
    width: auto;
    height: auto;
    padding: 1em 0.5em 0 0.5em;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;
export const DivRowSmallPeriodicContributionsPanel = styled.div`
    height: auto;
`;
export const DivColPeriodicContributionsPanel = styled.div`
    @media (min-width: 320px) and (max-width: 639.99px)
    {
        width: 100% !important;
    }
`;
export const PeriodicContributionsPanel = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;
export const DivRowPeriodicContributionsTitle = styled.div``;
export const DivColPeriodicContributionsTitle = styled.div``;
export const SmallTitlePeriodicContributions = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
    cursor: default;
`;
export const DivRowPeriodicContributionsText = styled.div``;
export const DivColPeriodicContributionsText = styled.div``;
export const TextInsidePeriodicContributions = styled.p`
    margin: 0;
    padding: 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
    cursor: default;
    padding-top: 0;
`;
export const DivRowLastPeriodicContributionsTitle = styled.div``;
export const DivColLastPeriodicContributionsTitle = styled.div``;
export const SmallLastPeriodicContributionsTitle = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
    cursor: default;
`;
export const DivRowLastPeriodicContributionsText = styled.div``;
export const DivColLastPeriodicContributionsText = styled.div``;
export const TextInsideLastPeriodicContributions = styled.p`
    margin: 0;
    padding: 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
    cursor: default;
    padding-top: 0;
    padding-bottom: 0;
`;
export const DivColLastPeriodicContributionsBadge = styled.div``;
export const MiniBadgeLastPeriodicContributions = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const DivContainerPeriodicContributionsButton = styled.div`
    padding-top: 1em;
    padding-bottom: 1em;
`;
export const MiniPeriodicContributionsButton = styled.button`
    background-color: #CC214F;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
    cursor: pointer;
`;
export const MiniPeriodicContributionsButtonDisabled = styled.button`
    background-color: lightgrey;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
    cursor: not-allowed;
`;
///Fin PeriodicContributions Container
///----------------------------------
///Container SavingPlans
export const DivColRectangleSavingPlans = styled.div`
    @media (min-width: 320px) and (max-width: 1023.9999px)
    {
        cursor: pointer;
    }
    @media (min-width: 1024px) and (max-width: 3000px)
    {
        cursor: default;
    }
    @media (min-width: 320px) and (max-width: 1365px)
    {
        margin-top: 1em;
    }
`;
export const FourthRectangle = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    text-align: center;
`;
export const FourthRectangleSmall = styled.div`
    width: auto;
    height: 5em;
    border: 1px solid white;
    background-color: #CC214F;
    border-radius: 12px;
    text-align: center;
`;
export const FourthTextRectangle = styled.div`
    color: white;
    padding-top: 0.7em;
    font-size: 25px;
    font-weight: 600;
`;
export const FourthRowPanel = styled.div`
    background-color: ${(props) => props.theme.palette.white};
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    padding-bottom: 1em;
    height: auto;
`;
export const SmallSavingPlanPanel = styled.div`
    width: auto;
    height: auto;
    padding: 1em 0.5em 1em 0.5em;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;
export const LastSmallSavingPlanPanel = styled.div`
    width: auto;
    height: auto;
    padding: 1em 0.5em 0 0.5em;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;
export const DivRowSmallSavingPlanPanel = styled.div`
    height: auto;
`;
export const DivColSavingPlanPanel = styled.div`
    @media (min-width: 320px) and (max-width: 639.99px)
    {
        width: 100% !important;
    }
`;
export const SavingPlanPanel = styled.div`
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.palette.contractElementPanel};
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;
export const DivRowSavingPlanTitle = styled.div``;
export const DivColSavingPlanTitle = styled.div``;
export const SmallTitleSavingPlan = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
    cursor: default;
`;
export const DivRowSavingPlanText = styled.div``;
export const DivColSavingPlanText = styled.div``;
export const TextInsideSavingPlan = styled.p`
    margin: 0;
    padding: 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
    cursor: default;
    padding-top: 0;
`;
export const DivRowLastSavingPlanTitle = styled.div``;
export const DivColLastSavingPlanTitle = styled.div``;
export const SmallLastSavingPlanTitle = styled.p`
    text-align: left;
    margin: 0;
    padding: 16px;
    font-weight: 900;
    font-size: 18px;
    cursor: default;
`;
export const DivRowLastSavingPlanText = styled.div``;
export const DivColLastSavingPlanText = styled.div``;
export const TextInsideLastSavingPlan = styled.p`
    margin: 0;
    padding: 1em;
    font-size: 16px;
    overflow-wrap: anywhere;
    cursor: default;
    padding-top: 0;
    padding-bottom: 0;
`;
export const DivColLastSavingPlanBadge = styled.div``;
export const MiniBadgeLastSavingPlan = styled.div`
    background-color: ${(props) => props.theme.palette.bubbleMapConfiguration};
    border-radius: 12px;
    text-align: center;
    margin-top: 1em;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const DivContainerSavingPlanButton = styled.div`
    padding-top: 1em;
    padding-bottom: 1em;
`;
export const MiniSavingPlanButton = styled.button`
    background-color: #CC214F;
    color: white;
    border: 1px solid white;
    text-align: center;
    border-radius: 12px;
    padding: 8px 1.3em;
    cursor: pointer;
`;
///Fin SavingsPlan Container
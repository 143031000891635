import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 80px;
    justify-content: center;
    align-items: flex-start;
`;
export const Title = styled.h1``;
export const FiltersWrapper = styled.div`
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: flex-start;
`;
export const FilterContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5px 0 0;
    > div span
    {
        border-radius: 100px;
    }
    i
    {
        color: white;
    }
`;
export const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 24px;
`;
export const TableWidthManager = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 30px;
    box-shadow: 0px 0px 16px rgba(8, 84, 117, 0.08);
    border-radius: 16px;
`;
export const TableFilterContainer = styled.div`
    display: flex;
    flex: 0 1 50px;
    margin-bottom: 10px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
`;
export const TRCountContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
`;
export const TRTitle = styled.span`
    font-size: 19px;
    font-weight: 500;
`;
export const TRCount = styled.span`
    color: #94a1a1;
    margin-left: 5px;
`;
export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: auto;
    border-bottom: 1px solid lightGrey;
    background: ${(props) => props.theme.palette.white};
    table
    {
        border-radius: 16px;
    }
    thead
    {
        background: ${(props) => props.theme.palette.tertiary};
    }
    > div
    {
        margin: 0;
    }
    .is-selected
    {
        a,
        div
        {
            color: white !important;
        }
    }
`;
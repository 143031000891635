import React from 'react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import './Element.sass';
import { ThemeContext } from '@fluentui/react-theme-provider';
import * as Component from '../Elements/ElementStyles';
import { DefaultButton, Spinner, SpinnerSize } from '@fluentui/react';
import ElementsAirIcon from "../../images/air.svg";
import ElementsEarthIcon from "../../images/earth.svg";
import ElementsFireIcon from "../../images/fire.svg";
import ElementsWaterIcon from "../../images/water.svg";
import ElementsIAIcon from "../../images/ia.svg";
import { useHistory } from "react-router";
import { currencyFormatter, decimalFormatter } from '../../utils/numberFormatter';
import { Donation } from '../../services/DonationService';

type Props =
{
    fire: number,
    earth: number,
    air: number,
    water: number,
    ia: number,
    isLoading: boolean,
    donation?: Donation
}

const ElementCardItem: React.FC<Props> = (props: Props) =>
{
    const theme = React.useContext(ThemeContext);
    const history = useHistory();
    const [windowSize, setWindowSize] = React.useState<any>(window.innerWidth);

    const handleWindowResize = React.useCallback(event =>
    {
        setWindowSize(window.innerWidth);

    }, []);
    React.useEffect(() =>
    {
        window.addEventListener('resize', handleWindowResize);

        return () =>
        {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);
    const buttonIAClickEvent = () =>
    {
        history.push("/ElementsIA");
    }

    return(
        <React.Fragment>
            <div className="ms-Grid-row rowHeight">
                <Component.DivColFireItem key="fire" className="ms-Grid-col ms-sm4 ms-md4 ms-lg2 ms-xl2 ms-xxl1 ms-xxxl1 colMargin">
                    <Component.ContainerCard theme={theme}>
                        <Component.TitleCard>
                            <Component.TitleText theme={theme}>Fuego</Component.TitleText>
                        </Component.TitleCard>
                        <Component.DataCardContract>
                            <div className="ms-Grid-col ms-sm6" style={{paddingLeft: "1em"}}>
                                <img alt="Fire" src={ElementsFireIcon} />
                            </div>
                            <div className="ms-Grid-col ms-sm6">
                                <Component.DataTextContract>
                                    {props.isLoading &&
                                    (
                                        <Spinner size={SpinnerSize.xSmall} />
                                    )}
                                    {!props.isLoading &&
                                    (
                                        <React.Fragment>
                                            {decimalFormatter.format(props.fire)}
                                        </React.Fragment>
                                    )}
                                </Component.DataTextContract>
                            </div>
                        </Component.DataCardContract>
                    </Component.ContainerCard>
                </Component.DivColFireItem>
                <Component.DivColEarthItem key="earth" className="ms-Grid-col ms-sm4 ms-md4 ms-lg2 ms-xl2 ms-xxl1 ms-xxxl1 colMargin">
                    <Component.ContainerCard theme={theme}>
                        <Component.TitleCard>
                            <Component.TitleText theme={theme}>Tierra</Component.TitleText>
                        </Component.TitleCard>
                        <Component.DataCardContract>
                            <div className="ms-Grid-col ms-sm6" style={{paddingLeft: "1em"}}>
                                <img alt="Earth" src={ElementsEarthIcon} />
                            </div>
                            <div className="ms-Grid-col ms-sm6">
                                <Component.DataTextContract>
                                    {props.isLoading &&
                                    (
                                        <Spinner size={SpinnerSize.xSmall} />
                                    )}
                                    {!props.isLoading &&
                                    (
                                        <React.Fragment>
                                            {decimalFormatter.format(props.earth)}
                                        </React.Fragment>
                                    )}
                                </Component.DataTextContract>
                            </div>
                        </Component.DataCardContract>
                    </Component.ContainerCard>
                </Component.DivColEarthItem>
                <Component.DivColAirItem key="air" className="ms-Grid-col ms-sm4 ms-md4 ms-lg2 ms-xl2 ms-xxl1 ms-xxxl1 colMargin">
                    <Component.ContainerCard theme={theme}>
                        <Component.TitleCard>
                            <Component.TitleText theme={theme}>Aire</Component.TitleText>
                        </Component.TitleCard>
                        <Component.DataCardContract>
                            <div className="ms-Grid-col ms-sm6" style={{paddingLeft: "1em"}}>
                                <img alt="Air" src={ElementsAirIcon} />
                            </div>
                            <div className="ms-Grid-col ms-sm6">
                                <Component.DataTextContract>
                                    {props.isLoading &&
                                    (
                                        <Spinner size={SpinnerSize.xSmall} />
                                    )}
                                    {!props.isLoading &&
                                    (
                                        <React.Fragment>
                                            {decimalFormatter.format(props.air)}
                                        </React.Fragment>
                                    )}
                                </Component.DataTextContract>
                            </div>
                        </Component.DataCardContract>
                    </Component.ContainerCard>
                </Component.DivColAirItem>
                <Component.DivColWaterItem key="water" className="ms-Grid-col ms-sm4 ms-md4 ms-lg2 ms-xl2 ms-xxl1 ms-xxxl1 colMargin">
                    <Component.ContainerCard theme={theme}>
                        <Component.TitleCard>
                            <Component.TitleText theme={theme}>Agua</Component.TitleText>
                        </Component.TitleCard>
                        <Component.DataCardContract>
                            <div className="ms-Grid-col ms-sm6" style={{paddingLeft: "1em"}}>
                                <img alt="Water" src={ElementsWaterIcon} />
                            </div>
                            <div className="ms-Grid-col ms-sm6">
                                <Component.DataTextContract>
                                    {props.isLoading &&
                                    (
                                        <Spinner size={SpinnerSize.xSmall} />
                                    )}
                                    {!props.isLoading &&
                                    (
                                        <React.Fragment>
                                            {decimalFormatter.format(props.water)}
                                        </React.Fragment>
                                    )}
                                </Component.DataTextContract>
                            </div>
                        </Component.DataCardContract>
                    </Component.ContainerCard>
                </Component.DivColWaterItem>
                <Component.DivColEmpty key="empty" className="ms-Grid-col ms-hiddenXlDown ms-xxl2 ms-xxxl2 colMargin" />
                {windowSize === 1366 &&
                (
                    <Component.DivColEmpty key="empty" className="ms-Grid-col ms-xl2 colMargin" />
                )}
                <Component.DivColBuyItem key="buy" className="ms-Grid-col ms-sm8 ms-md8 ms-lg4 ms-xl4 ms-xxl2 ms-xxxl2 colMargin">
                    <Component.IAContainerCard theme={theme}>
                        <Component.DivRowButtonIA className="ms-Grid-row">
                            <Component.DivColTitle className="ms-Grid-col ms-sm10 ms-md10 ms-xxxl6">
                                <Component.TitleCard>
                                    <Component.TitleIAText theme={theme}>
                                        Elements IA
                                    </Component.TitleIAText>
                                </Component.TitleCard>
                            </Component.DivColTitle>
                            <Component.DivColLogoIA className="ms-Grid-col ms-sm2 ms-md2 ms-xxxl6">
                                <Component.LogoIA alt="IA" src={ElementsIAIcon} width={"25px"} />
                            </Component.DivColLogoIA>
                            <Component.DivColButtonIA className="ms-Grid-col ms-sm6 ms-xxxl6">
                                <Component.ButtonBuyElements onClick={buttonIAClickEvent}>
                                    Compra
                                </Component.ButtonBuyElements>
                            </Component.DivColButtonIA>
                            <Component.DivColIAItem className="ms-Grid-col ms-sm6 ms-xxxl6">
                                <Component.DataCardIA>
                                    <Component.DataIAText>
                                        {props.isLoading &&
                                        (
                                            <Spinner size={SpinnerSize.xSmall} />
                                        )}
                                        {!props.isLoading &&
                                        (
                                            <React.Fragment>
                                                {decimalFormatter.format(props.ia)}
                                            </React.Fragment>
                                        )}
                                    </Component.DataIAText>
                                </Component.DataCardIA>
                            </Component.DivColIAItem>
                        </Component.DivRowButtonIA>
                    </Component.IAContainerCard>
                </Component.DivColBuyItem>
                {props.donation !== undefined && (
                    <div className="ms-Grid-col ms-sm12 ms-xl4" style={{padding: '0 0 0 10px'}}>
                        <div style={{boxShadow: '0px 0px 16px rgba(8,84,117,0.08)', padding: '12px 10px 8px', marginTop: '8px', borderRadius: '10px'}}>
                            Transforma tus Elements en {props.donation.name}
                            <div style={{display: 'grid', gridTemplateColumns: '2fr 1fr', marginTop: '12px'}}>
                                <span style={{lineHeight: '26px', fontWeight: '600', fontSize: '19px'}}>Recaudado: {currencyFormatter.format(props.donation.amount)}</span>
                                <DefaultButton
                                    className="mini-primary-button"
                                    style={{verticalAlign: 'middle'}}
                                    onClick={() => history.push("/elements/donations")}
                                >Donar</DefaultButton>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export default ElementCardItem;
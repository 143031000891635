import { Separator } from "office-ui-fabric-react/lib/Separator";
import { useEffect, useRef, useState, } from "react";
import DocumentTitle from "react-document-title";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router";
import Auth from "../../Auth/Auth";
import { UserType } from "../../services/UserService";
import { completeUserDataRedux } from "../../store/auth/actions";
import { useBoolean } from "@fluentui/react-hooks";
import "./Payments.sass";
import React from "react";
import { DefaultButton } from "@fluentui/react";

const PaymentCancelOk = () => {
  const history = useHistory();

  return (
    <DocumentTitle title="IronIA - Pago correcto">
      <React.Fragment>
        <h3>Orden cancelada correctamente</h3>
        <p className="description">La orden ha sido cancelada exitosamente.</p>
        <button>Volver a mis órdenes en curso</button>
        <DefaultButton
          className="button tiny-primary-button"
          onClick={() => history.push("/portfolio/orders")}
        ></DefaultButton>
      </React.Fragment>
    </DocumentTitle>
  );
};

export default PaymentCancelOk;

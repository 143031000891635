import { SET_THEME, SET_CONNECTION_REFERENCE, SET_HEADER_VISIBILITY, SET_MAIN_MENU_VISIBILITY, SET_FOOTER_VISIBILITY } from './Constants';


export const setTheme = (value: any) => async (dispatch: any) =>
{
    dispatch(
    {
        type: SET_THEME,
        payload: value
    });
};

export const setConnectionReference = (value: any) => async (dispatch: any) => {
    dispatch(
        {
            type: SET_CONNECTION_REFERENCE,
            payload: value
        });
};

export const setHeaderVisibility = (value: any) => async (dispatch: any) =>
{
    dispatch(
    {
        type: SET_HEADER_VISIBILITY,
        payload: value
    });
};

export const setMainMenuVisibility = (value: any) => async (dispatch: any) =>
{
    dispatch(
    {
        type: SET_MAIN_MENU_VISIBILITY,
        payload: value
    });
};

export const setFooterVisibility = (value: any) => async (dispatch: any) =>
{
    dispatch(
    {
        type: SET_FOOTER_VISIBILITY,
        payload: value
    });
};
import { useContext, useEffect } from 'react';
import * as L from './SignatureStyles';
import { Checkbox, DefaultButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { ThemeContext } from '@fluentui/react-theme-provider';
import { useState } from 'react';
import Countdown, { CountdownRendererFn } from 'react-countdown';
import moment from "moment";
import { Controller, useForm } from 'react-hook-form';
import SignaturesService, { ISignature } from '../../services/SignaturesService';
import { UserIdentityType } from '../../services/UserIdentitiesService';
import React from 'react';

interface ConfirmElement
{
    text: string,
    value: boolean,
    setValue: (...args: any) => void;
}
interface SignatureComponentProps
{
    signatures: ISignature[];
    title?: string;
    headerTextArea?: any;
    alreadySignedText?: string;
    alreadySignedSubtitle?: string;
    submitButtonText?: string;
    cancelButtonText?: string;
    requestNewCodeButtonText?: string;
    backButtonText?: string;
    allowRequestNewCode?: boolean;
    userIdentities?: UserIdentityType[];
    procedence?: string;
    confirmElement?: ConfirmElement;
    onSubmit?: (...args: any) => any;
    onExpired?: (...args: any) => any;
    onAllExpired?: (...args: any) => any;
    onError?: (...args: any) => any;
    onSigned?: (...args: any) => any;
    onNewCodeRequested?: (...args: any) => any;
    onNewCodeSended?: (...args: any) => any;
    onBackButtonClicked?: (...args: any) => any;
    onCancelButtonClicked?: (...args: any) => any;
    onSignatureChanged?: (...args: any) => any;
    isPeriodicContribution?: boolean
}

const countdown = React.createRef<Countdown>();

export const SignatureComponentMini = (props: SignatureComponentProps) =>
{
    let expiredDefault = false;
    let signedDefault = false;

    if(props?.signatures)
    {
        props.signatures.forEach((signature: ISignature) =>
        {
            if(signature.expiresAt)
            {
                expiredDefault = expiredDefault || (new Date()) > signature.expiresAt;
            }

            if(signature.signAt)
            {
                signedDefault = signedDefault && true;
            }
        });
    }

    if(props !== undefined && props?.alreadySignedSubtitle === null)
    {
        props.alreadySignedSubtitle = "El proceso de firma se ha completado con éxito.";
    }

    const theme = useContext(ThemeContext);
    const { handleSubmit, formState: { errors, isValid, isDirty }, control } = useForm({ mode: "onChange" });
    const [signed, setSigned] = useState(signedDefault);
    const [errorMessage, setErrorMessage] = useState("");
    const [expired, setExpired] = useState(expiredDefault);
    const [sending, setSending] = useState(false);
    const [sendingNewCode, setSendingNewCode] = useState(false);

    useEffect(() =>
    {
        if(expired !== undefined)
        {
            if(expired)
            {
                props.onExpired?.();
            }
            else if(countdown?.current)
            {
                countdown.current?.start();
            }
        }
    }, [expired, props]);

    const onCountComplete = () =>
    {
        setExpired(true);
    };
    const countdownRenderer: CountdownRendererFn = ({ minutes, seconds, completed }) =>
    {
        return (<>
            {minutes}:{seconds.toString().padStart(2, "0")}
        </>);
    };
    const requestNewCode = () =>
    {
        setSendingNewCode(true);
        props.onNewCodeRequested?.();
        SignaturesService.getRefreshSignature({signatureIds: props.signatures.map((signature: ISignature) => signature.id)}).then((response: any) =>
        {
            setSendingNewCode(false);
            if(!response?.data.signatures)
            {
                props.onError?.(response);
                return;
            }
            setExpired(false);
            props?.onNewCodeSended?.(response.data.signatures);
            countdown.current?.start();
        })
        .catch(error =>
        {
            setSendingNewCode(false);
            let signatures = error?.response?.data.signatures;
            switch(error?.response?.data?.errorCode)
            {
                case "CODE_STILL_VALID":
                    signatures.forEach((signature: ISignature) =>
                    {
                        if(signature && typeof signature.expiresAt === "string")
                        {
                            signature.expiresAt = moment(signature.expiresAt).toDate();
                        }
                        if(signature?.expiresAt && signature?.expiresAt > (new Date()))
                        {
                            props?.onSignatureChanged?.(signatures);
                            setExpired(false);
                        }
                        countdown.current?.start();
                        var message: string = "El código aún es válido.";
                        if(signatures.length === 1)
                        {
                            message = "Los códigos aún son válidos."
                        }
                        setErrorMessage(message);
                    });
                    break;

                case "ALREADY_SIGNED":
                    signatures.forEach((signature: ISignature) =>
                    {
                        if(signature.signAt)
                        {
                            props?.onSignatureChanged?.(signatures);
                            setSigned(true);
                        }
                    });
                    break;
            }
            props.onError?.(error);
        });
    };
    const onSubmit = (data: any) =>
    {
        if(expired)
        {
            return false;
        }

        props.onSubmit?.(data);
        setSending(true);
        setErrorMessage("");
        var signatureIds = props.signatures.map((signature: ISignature) => signature.id);
        var signatures = signatureIds.map((id: any, index: any) =>
        {
            return {'id': id, 'signatureCode': data.signatureCode[index]}
        });

        SignaturesService.putSignatures(signatures).then((response: any) =>
        {
            setSending(false);

            if(!response?.data?.success)
            {
                props.onError?.(response);
                return;
            }

            setSigned(true);
            if(props.isPeriodicContribution === true)
            {
                props.onSigned?.(response.data.success, signatures);
            }
            else
            {
                props.onSigned?.(response);
            }
        })
        .catch(error =>
        {
            setSending(false);
            if(error.response.status === 401)
            {
                setErrorMessage("No tiene permisos para completar el proceso de firma. Compruebe si la sesión ha caducado.");
            }
            else
            {
                let signatures = error?.response?.data.signatures;
                switch(error?.response?.data?.errorCode)
                {
                    case "WRONG_CODE":
                        signatures.forEach((signature: ISignature) =>
                        {
                            if(signature.status === 3)
                            {
                                setErrorMessage("El código no es válido.");
                            }
                        });
                        break;
                }
                countdown.current?.start();
            }
            props.onError?.(error);
        });
    };

    if(signed)
    {
        return (
            <React.Fragment>
                <L.Main theme={theme}>
                    <L.TitleContainer>
                        <h2>{(signed && (props.alreadySignedText || "¡Ya has firmado esta orden!"))}</h2>
                    </L.TitleContainer>
                    <L.TextTop theme={theme}>
                        {props.alreadySignedSubtitle}
                    </L.TextTop>
                </L.Main>
            </React.Fragment>
        );
    }
    else
    {
        return (
            <L.MainMini theme={theme}>
                {props.title !== "" && (
                    <L.TitleContainer>
                        <h2>{(props.title)}</h2>
                    </L.TitleContainer>
                )}
                <L.TextTop theme={theme}>
                    {props.headerTextArea || "Recibirás un SMS en el teléfono con la clave para confirmar esta orden."}
                </L.TextTop>
                <L.SeparatorTop />
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <L.TextNoMargin theme={theme}>
                        El código que te hemos enviado tiene una duración limitada. Después de eso, dejará de ser válido.
                    </L.TextNoMargin>
                    <Stack className="section-wrapper" horizontal>
                        <Stack.Item className="base-column column-count">
                            <L.TextCount theme={theme}>
                                <Countdown
                                    ref={countdown}
                                    date={props.signatures[0].expiresAt}
                                    precision={2}
                                    renderer={countdownRenderer}
                                    onComplete={onCountComplete}
                                />
                            </L.TextCount>
                        </Stack.Item>
                        <Stack.Item className="base-column">
                            {props.signatures.map((signature: ISignature, index: number) =>
                            {
                                var userIdentity: any;
                                if(props.userIdentities && props.userIdentities.length >= index - 1)
                                {
                                    userIdentity = props.userIdentities[index];
                                }
                                else if((props.userIdentities !== undefined && props.userIdentities.length === undefined))
                                {
                                    userIdentity = props.userIdentities;
                                }
                                return (
                                    <div key={'signature' + index}>
                                        <Controller
                                            name={"signatureCode[" + index + "]"}
                                            control={control}
                                            rules={
                                            {
                                                required: true,
                                                minLength: 4,
                                                maxLength: 4
                                            }}
                                            render={({field: {onChange, onBlur, value}}) => <L.VerificationTextField
                                                autoComplete="off"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                selected={value}
                                                // eslint-disable-next-line no-useless-concat
                                                label={"Código de verificación para el Interviniente " + ("00" + (index + 1))
                                                    + (userIdentity ? (" - " + [userIdentity?.firstName, userIdentity?.lastName, userIdentity?.secondLastName].join(" ")) : "")}
                                                placeholder="Escribe aquí el código que te hemos enviado por SMS..."
                                                required
                                                errorMessage={errorMessage || (isDirty && errors.signatureCode ? "El código debe tener 4 caracteres." : "")}
                                            />}
                                        />
                                    </div>
                                )
                            })}
                        </Stack.Item>
                    </Stack>
                    {expired && (
                        <React.Fragment>
                            <L.Text theme={theme} className="error" style={{marginTop: "2em"}}>
                                El código que te hemos enviado ha expirado. Tendrás que intentarlo de nuevo.
                            </L.Text>
                        </React.Fragment>
                    )}
                    {props !== undefined && props.confirmElement !== undefined && (
                        <Stack className="section-wrapper" horizontal>
                            <Stack.Item className="base-column">
                            </Stack.Item>
                            <Stack.Item className="base-column">
                                <Checkbox label={props.confirmElement.text}
                                    checked={props.confirmElement.value}
                                    onChange={(ev?: any, checked?: boolean) => props.confirmElement?.setValue(!!checked)}
                                />
                            </Stack.Item>
                        </Stack>
                    )}
                    {expired && (
                        <React.Fragment>
                            <L.OuterFooterAreaButtonSendCode>
                                {props?.onNewCodeSended && (
                                    <DefaultButton disabled={sendingNewCode}
                                        onClick={requestNewCode}
                                        className="button tiny-primary-button"
                                    >
                                        {sendingNewCode && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                        {props.requestNewCodeButtonText || "Recibir nuevo código"}
                                    </DefaultButton>
                                )}
                            </L.OuterFooterAreaButtonSendCode>
                        </React.Fragment>
                    )}
                    {!expired && (
                        <L.OuterFooterAreaButtonDefault>
                            <DefaultButton type="submit"
                                disabled={sending || (props.confirmElement !== undefined && !props.confirmElement.value) || !isDirty || !isValid}
                                className="button tiny-primary-button"
                            >
                                {sending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                {props.submitButtonText || "Firmar"}
                            </DefaultButton>
                        </L.OuterFooterAreaButtonDefault>
                    )}
                </form>
            </L.MainMini>
        );
    }
};
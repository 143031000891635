let cartKey = "cart";

export const loadCartState = () => {
    try {
        const cartJSON = localStorage.getItem(cartKey);
        if(cartJSON === null) {
            return undefined;
        }
        return JSON.parse(cartJSON);
    }
    catch (error) {
        return undefined;
    }
}

export const saveCartState = (cartState: any) => {
    try {
        let cartJSON = JSON.stringify(cartState);
        localStorage.setItem(cartKey, cartJSON);
    }
    catch (e) {}
}
import { Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import { RefundEstimationDates } from "../../../services/FundService";
import { SectionContainer, WarningContainer } from "../styles/RefundsStyles";
import '../styles/RefundTimeLine.css';

type RefundProps =
{
    refundEstimationDates : RefundEstimationDates,
}
export const RefundTimeLine = (props: RefundProps) =>
{
    const theme = window.localStorage.getItem("theme") || "light";
    const loadingPropsCompleted: boolean = (props.refundEstimationDates.ventaLiquidacion !== "" &&
                                            props.refundEstimationDates.ventaValor !== "" &&
                                            props.refundEstimationDates.finalProceso !== "");

    return(
        <React.Fragment>
            {theme === "dark" &&
            (
                <React.Fragment>
                    <div className="ms-Grid-row principalRowContainerBlack">
                        {loadingPropsCompleted === false &&
                        (
                            <React.Fragment>
                                <Spinner size={SpinnerSize.large} className="button-spinner" styles={{root:{margin: "3em 1.5em 1.5em 23em !important"}}} />
                                <SectionContainer className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                    <WarningContainer>
                                        <div>
                                            <img src='/icons/information.svg' alt="Información" />
                                        </div>
                                        <p>
                                            Las fechas de valor liquidativo y liquidación, así como los días fuera de mercado expuestos
                                            son una estimación informativa que NO tiene en cuenta los días no laborables del país de origen
                                            del fondo y no constituyen una garantía para ningún tipo de reclamación por dicha información
                                            expuesta. Asimismo, se recuerda al inversor que para poder calcular la estimación más exacta,
                                            deberá analizar la memoria de comercialización, el folleto y el resto de documentaciones
                                            pre-contractuales de los vehículos donde figuren las horas de corte y el periodo del cálculo
                                            liquidativo establecidas por las entidades comercializadoras y las gestoras de los mismos.
                                        </p>
                                    </WarningContainer>
                                </SectionContainer>
                            </React.Fragment>
                        )}
                        {loadingPropsCompleted === true &&
                        (
                            <React.Fragment>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                    <div className="bodyContainer">
                                        <div className="container">
                                            <div className="progress-container">
                                                <div className="progress"></div>
                                                <div className="circleVenta realized"></div>
                                                <div className="circleVenta realized">
                                                    <p className="circleTextWhite">Fecha de Valor Liquidativo Salida</p>
                                                </div>
                                                <div className="circleVenta realized">
                                                    <p className="circleTextWhite">Fecha Liquidación Salida</p>
                                                </div>
                                                <div className="circleVenta realized"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                    <div className="dateBody-container">
                                        <div className="container">
                                            <div className="date-container">
                                                <div className="circleEmptyLeft">{new Date().toLocaleDateString()}</div>
                                                <p className="circleDateWhite">{props.refundEstimationDates.ventaValor}</p>
                                                <p className="circleDateWhite">{props.refundEstimationDates.ventaLiquidacion}</p>
                                                <div className="circleEmptyRight">{props.refundEstimationDates.finalProceso}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <SectionContainer className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                    <WarningContainer>
                                        <div>
                                            <img src='/icons/information.svg' alt="Información" />
                                        </div>
                                        <p>
                                            Las fechas de valor liquidativo y liquidación, así como los días fuera de mercado expuestos 
                                            son una estimación informativa que NO tiene en cuenta los días no laborables del país de origen 
                                            del fondo y no constituyen una garantía para ningún tipo de reclamación por dicha información 
                                            expuesta. Asimismo, se recuerda al inversor que para poder calcular la estimación más exacta, 
                                            deberá analizar la memoria de comercialización, el folleto y el resto de documentaciones 
                                            pre-contractuales de los vehículos donde figuren las horas de corte y el periodo del cálculo 
                                            liquidativo establecidas por las entidades comercializadoras y las gestoras de los mismos. 
                                        </p>
                                    </WarningContainer>
                                </SectionContainer>
                            </React.Fragment>
                        )}
                    </div>
                </React.Fragment>
            )}
            {theme === "light" && 
            (
                <React.Fragment>
                    <div className="ms-Grid-row principalRowContainer">
                        {loadingPropsCompleted === false &&
                        (
                            <React.Fragment>
                                <Spinner size={SpinnerSize.large} className="button-spinner" styles={{root: {margin: "3em 1.5em 1.5em 23em !important"}}} />
                                <SectionContainer className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                    <WarningContainer>
                                        <div>
                                            <img src='/icons/information.svg' alt="Información" />
                                        </div>
                                        <p>
                                            Las fechas de valor liquidativo y liquidación, así como los días fuera de mercado expuestos
                                            son una estimación informativa que NO tiene en cuenta los días no laborables del país de origen
                                            del fondo y no constituyen una garantía para ningún tipo de reclamación por dicha información
                                            expuesta. Asimismo, se recuerda al inversor que para poder calcular la estimación más exacta,
                                            deberá analizar la memoria de comercialización, el folleto y el resto de documentaciones
                                            pre-contractuales de los vehículos donde figuren las horas de corte y el periodo del cálculo
                                            liquidativo establecidas por las entidades comercializadoras y las gestoras de los mismos.
                                        </p>
                                    </WarningContainer>
                                </SectionContainer>
                            </React.Fragment>
                        )}
                        {loadingPropsCompleted === true &&
                        (
                            <React.Fragment>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                    <div className="bodyContainer">
                                        <div className="container">
                                            <div className="progress-container">
                                                <div className="progress" id="progress"></div>
                                                <div className="circleVenta realized"></div>
                                                <div className="circleVenta realized">
                                                    <p className="circleTextBlack">Fecha de Valor Liquidativo Salida</p>
                                                </div>
                                                <div className="circleVenta realized">
                                                    <p className="circleTextBlack">Fecha Liquidación Salida</p>
                                                </div>
                                                <div className="circleVenta realized"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                    <div className="dateBody-container">
                                        <div className="container">
                                            <div className="date-container">
                                                <div className="circleEmptyLeft">{new Date().toLocaleDateString()}</div>
                                                <p className="circleDateBlack">{props.refundEstimationDates.ventaValor}</p>
                                                <p className="circleDateBlack">{props.refundEstimationDates.ventaLiquidacion}</p>
                                                <div className="circleEmptyRight">{props.refundEstimationDates.finalProceso}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <SectionContainer className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                                    <WarningContainer>
                                        <div>
                                            <img src='/icons/information.svg' alt="Información" />
                                        </div>
                                        <p>
                                            Las fechas de valor liquidativo y liquidación, así como los días fuera de mercado expuestos
                                            son una estimación informativa que NO tiene en cuenta los días no laborables del país de origen
                                            del fondo y no constituyen una garantía para ningún tipo de reclamación por dicha información
                                            expuesta. Asimismo, se recuerda al inversor que para poder calcular la estimación más exacta,
                                            deberá analizar la memoria de comercialización, el folleto y el resto de documentaciones
                                            pre-contractuales de los vehículos donde figuren las horas de corte y el periodo del cálculo
                                            liquidativo establecidas por las entidades comercializadoras y las gestoras de los mismos.
                                        </p>
                                    </WarningContainer>
                                </SectionContainer>
                            </React.Fragment>
                        )}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
import * as L from '../layout.styles';
import { DeleteIcon } from '@fluentui/react-icons-mdl2';
import { PrimaryButton, Stack, TooltipHost } from 'office-ui-fabric-react';
import { currencyFormatter, percentFormatter } from '../../../utils/numberFormatter';
import { CartModes } from '../CartScreen';

interface CartColumnProps
{
    item: any;
}

interface CartColumnActionsProps extends CartColumnProps
{
    toggleAttachmentsVisible: (isin: string) => any;
    removeCartLine?: (item: any) => any;
    cartModeStatus?: CartModes;
};

interface CartColumnPercentageProps
{
    percentage: number;
    amount: number;
}

export const CartColumnActions = (props: CartColumnActionsProps) =>
{
    return (<L.ActionButtonsContainer horizontal={true} horizontalAlign="center" verticalAlign="center">
        {((props.item?.readOnly === null || props.item?.readOnly === false) && props.cartModeStatus !== CartModes.VIEW_MODEL_PORTFOLIO) &&
        (
            <Stack.Item>
                <TooltipHost content="Borrar"
                    id={`btnRemove_${props.item.isin}`}
                    calloutProps={{ gapSpace: 0 }}
                >
                    <PrimaryButton className="light-circled-icon"
                        id={`btnRemove_${props.item.isin}`}
                        onClick={() => props.removeCartLine?.(props.item)}
                    >
                        <DeleteIcon className="ironia-table-icon" />
                    </PrimaryButton>
                </TooltipHost>
            </Stack.Item>
        )}
    </L.ActionButtonsContainer>);
};

export const CartColumnPercentage = (props: CartColumnPercentageProps) =>
{
    return(
        <table className="percentageColumn">
            <tbody>
                <tr>
                    <td className="percentage">
                        {percentFormatter.format(props.percentage / 100)}
                    </td>
                    <td className="currency">
                        {currencyFormatter.format(props.amount * props.percentage / 100)}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
import axios from "axios";
import { proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);

export interface DocPreLogs {
    id?: number,
    userId: number,
    isin: string,
    documentType: string,
    dateClick: string,
    createdAt?: string,
    updatedAt?: string
};

export const requiredPreLog = [
    '000008', // KIID
    '000009', // INFORME SEMESTRAL
    '000011', // MEMORIA
    '000018'  // PRIP KIID
];

class DocPreLogService {
    public docPreLogsByUserId = async (userId: number, isins: string[]): Promise<DocPreLogs[]> => {
        let url: string = `${proxyUrl}/docPreLogs/getByUserId/${userId}`
        const req = await axios.get(url, { params: { isins: isins } });
        return req.data;
    }

    public createDocPreLogs = async (docPreLogs: DocPreLogs): Promise<DocPreLogs> =>
    {
        let url: string = `${proxyUrl}/docPreLogs`
        const req = await axios.post(url, docPreLogs);
        return req.data;
    }
}

const DocPreLogs = new DocPreLogService();
export default DocPreLogs;
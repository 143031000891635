import { CommandBar, ICommandBarItemProps } from "office-ui-fabric-react/lib/CommandBar";
import { IButtonProps } from "@fluentui/react";
import { useHistory } from "react-router";

const _overflowItems: ICommandBarItemProps[] = [];
const overflowProps: IButtonProps = {ariaLabel: "Más acciones"};

const IframeSearchMenu = () =>
{
    const history = useHistory();
    const _items = [
    {
        text: "Buscador de fondos",
        key: "buscadorFondos",
        onClick: () => history.push("/iframe/search"),
        className: window.location.pathname === '/iframe/search' ? 'is-selected' : ''
    },
    {
        text: "Buscador de categorías",
        key: "buscadorCategorias",
        onClick: () => history.push("/iframe/search/category"),
        className: window.location.pathname === '/iframe/search/category' ? 'is-selected' : ''
    },
    {
        text: "Ranking de carteras",
        key: "rankingCarteras",
        onClick: () => history.push("/iframe/search/ranking"),
        className: window.location.pathname === '/iframe/search/ranking' ? 'is-selected' : ''
    },
    {
        text: "Tendencias",
        key: "TendsSearch",
        onClick: () => history.push("/iframe/search/tends"),
        className: window.location.pathname === '/iframe/search/tends' ? 'is-selected' : ''
    }];

    return(
        <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" id="main-menu">
                <CommandBar
                    // @ts-ignore
                    items={_items}
                    overflowItems={_overflowItems}
                    overflowButtonProps={overflowProps}
                    ariaLabel="Usa las flechas izquierda y derecha para navegar entre las entradas del menú"
                />
            </div>
        </div>
    );
}

export default IframeSearchMenu;
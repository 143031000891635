export const ResourcesOptimizer = {
	META_DESCRIPCION:
		"Información sobre el servicio de optimización de carteras de IronIA, IronIA Portfolio Optimizer, que permite comparar nuestra cartera actual con la cartera optima obtenida mediate la aplicación del modelo de Markowitz o Black-Litterman",
	QUE_ES_HEADER: "IronIA Portfolio Optimizer. ¿Qué es?",
	QUE_ES_P1:
		"El servicio de optimización de carteras de IronIA, IronIA Portfolio Optimizer, permite comparar nuestra cartera actual con la cartera optima obtenida mediate la aplicación del modelo de [MODELO], para la optimización de carteras. La comparación estable el % que debería tener de cada fondo para que la relación entre rentabilidad (ganancia) y volatilidad (riesgo) sea la mejor posible, es decir la mayor rentabilidad con el menor riesgo.",
	MARK_QUE_ES_P2:
		"<a href='https://es.wikipedia.org/wiki/Harry_Markowitz'>Harry Markowitz</a>, publicó en la revista Journal of Finance de 1952 un artículo basado en su tesis doctoral y titulado «Portfolio Selection». En dicho artículo planteaba un modelo de conducta racional del decisor para la selección de carteras de títulos-valores con liquidez inmediata. Posteriormente, en 1959, publicó su libro Portfolio Selection, Efficient Diversification of Investments, en el que expone y desarrolla con mayor detalle su teoría.",
	MARK_QUE_ES_P3:
		"Desde su aparición, el modelo de Markowitz ha conseguido un gran éxito a nivel teórico, dando lugar a múltiples desarrollos y derivaciones, e incluso sentando las bases de diversas teorías de equilibrio en el mercado de activos financieros. Sin embargo, su utilización en la práctica entre gestores de carteras y analistas de inversiones no ha sido tan extensa como podría suponerse de su éxito teórico.",
	MARK_QUE_ES_P4:
		"IronIA Fintech quiere proporcionar a sus clientes una implementación del método de Markowitz para aplicar su éxito teórico a la práctica concreta de su cartera de inversión.",
	LITTER_QUE_ES_P2: "En finanzas, el modelo Black-Litterman es un modelo matemático para la asignación de carteras desarrollado en 1990 en Goldman Sachs por Fischer Black y Robert Litterman, y publicado en 1992. Busca superar los problemas que los inversores institucionales han encontrado al aplicar la teoría moderna de carteras en la práctica. El modelo comienza con una asignación de activos basada en el supuesto de equilibrio (los activos se desempeñarán en el futuro como lo han hecho en el pasado) y luego modifica esa asignación teniendo en cuenta la opinión del inversionista con respecto al desempeño futuro de los activos.",
	INTRODUCCION: "Introducción",
	MARK_INTRODUCCION_P1:
		"El modelo consiste en la selección de la cartera de inversión más eficiente al analizar varias carteras posibles de valores dados. En nuestro caso los valores dados son los que componen su cartera actualmente. Al elegir valores que no se 'mueven' exactamente juntos, el modelo muestra a los inversores cómo reducir su riesgo. El modelo también se denomina modelo de media varianza debido a que se basa en los rendimientos esperados (media) y la desviación estándar (varianza) de las diversas carteras. Harry Markowitz hizo las siguientes suposiciones al desarrollar el modelo: ",
	MARK_INTRODUCCION_BOX_1: "El riesgo de una cartera se basa en la variabilidad de los rendimientos de dicha cartera.",
	MARK_INTRODUCCION_BOX_2: "Un inversor es reacio al riesgo. ",
	MARK_INTRODUCCION_BOX_3: "Un inversor prefiere aumentar el consumo. ",
	MARK_INTRODUCCION_BOX_4: "La función de utilidad del inversor es convexa y creciente, debido a su aversión al riesgo y preferencia de consumo.",
	MARK_INTRODUCCION_BOX_5: "El análisis se basa en un modelo de inversión de un solo período.",
	MARK_INTRODUCCION_BOX_6: "Un inversor es de naturaleza racional.",
	MARK_INTRODUCCION_BOX_7: "Un inversor maximiza el rendimiento de su cartera para un nivel de riesgo dado o maximiza su rendimiento por el riesgo mínimo.",
	MARK_INTRODUCCION_P2:
		"Para elegir el mejor portafolio entre una cantidad de portafolios posibles, cada uno con diferente rentabilidad y riesgo, se deben tomar dos decisiones por separado:",
	MARK_INTRODUCCION_BOX_B_1: "Determinación de un conjunto de carteras eficientes.",
	MARK_INTRODUCCION_BOX_B_2: "Selección de la mejor cartera del conjunto eficiente.",
	MARK_INTRODUCCION_P3:
		"El uso de este modelo implica que nuestra implementación realiza las mismas suposiciones por lo que si no está de acuerdo con ellas el resultado de la aplicación no será de utilidad.",
	LITTER_INTRODUCCION_P1: "La asignación de activos es la decisión a la que se enfrenta un inversor que debe elegir cómo distribuir su cartera entre una serie de clases de activos. Por ejemplo, un fondo de pensiones invertido globalmente debe elegir cuánto asignar a cada país o región principal.",
	LITTER_INTRODUCCION_P2: "En principio, la teoría moderna de carteras (el enfoque de media-varianza de Markowitz) ofrece una solución a este problema una vez que se conocen los rendimientos esperados y las covarianzas de los activos. Si bien la teoría moderna de la cartera es un avance teórico importante, su aplicación se ha encontrado universalmente con un problema: aunque las covarianzas de unos pocos activos pueden estimarse adecuadamente, es difícil llegar a estimaciones razonables de los rendimientos esperados.",
	LITTER_INTRODUCCION_P3: "Black-Litterman superó este problema al no requerir que el usuario ingrese estimaciones del rendimiento esperado; en cambio, asume que los rendimientos esperados iniciales son los que se requieren para que la asignación de activos de equilibrio sea igual a lo que observamos en los mercados. El usuario solo está obligado a indicar cómo difieren sus supuestos sobre los rendimientos esperados de los mercados y su grado de confianza en los supuestos alternativos. A partir de esto, el método Black-Litterman calcula la asignación de activos deseada (media-varianza eficiente).",
	LITTER_INTRODUCCION_P4: "En general, cuando hay restricciones de cartera, por ejemplo, cuando no se permiten las ventas en corto, la forma más fácil de encontrar la cartera óptima es usar el modelo de Black-Litterman para generar los rendimientos esperados de los activos y luego usar una media. Optimizador de varianza para resolver el problema de optimización con restricciones.",
	DETERMINACION_CONJUNTO: "Determinación del conjunto eficiente",
	DETERMINACION_CONJUNTO_P1:
		"Una cartera que ofrece el máximo rendimiento para un riesgo dado, o el riesgo mínimo para un rendimiento dado es una cartera eficiente. Por lo tanto, las carteras se seleccionan de la siguiente manera:",
	DETERMINACION_CONJUNTO_P2: "(a) De las carteras que tienen la misma rentabilidad, el inversor preferirá la cartera con menor riesgo, y",
	DETERMINACION_CONJUNTO_P3:
		"(b) A partir de las carteras que tienen el mismo nivel de riesgo, un inversor preferirá la cartera con mayor tasa de rendimiento.",
	DETERMINACION_CONJUNTO_P4:
		"Como el inversor es racional, les gustaría obtener un mayor rendimiento. Y como es reacio al riesgo, quiere tener un riesgo menor. En la Figura 1, el área sombreada PVWP incluye todos los valores posibles en los que un inversor puede invertir. Los portafolios eficientes son los que se encuentran en el límite de PQVW. Por ejemplo, en el nivel de riesgo x2, hay tres carteras S, T, U. Pero la cartera S se denomina cartera eficiente, ya que tiene el rendimiento más alto, y2, en comparación con T y U. Todas las carteras que se encuentran en el límite de PQVW son portafolios eficientes para un nivel de riesgo dado.",
	DETERMINACION_CONJUNTO_P5:
		"El límite PQVW se llama frontera eficiente. Todas las carteras que se encuentran debajo de la Frontera Eficiente no son lo suficientemente buenas porque la rentabilidad sería menor para el riesgo dado. Las carteras que se encuentran a la derecha de Efficient Frontier no serían lo suficientemente buenas, ya que existe un mayor riesgo para una tasa de rendimiento dada. Todas las carteras que se encuentran en el límite de PQVW se denominan Carteras Eficientes. La frontera eficiente es la misma para todos los inversores, ya que todos los inversores quieren el máximo rendimiento con el menor riesgo posible y son reacios al riesgo.",
	MEJOR_CARTERA: "Elegir la mejor cartera",
	MEJOR_CARTERA_P1:
		"Para la selección de la cartera óptima o la mejor cartera, se analizan las preferencias de riesgo-rendimiento. Un inversor que es muy reacio al riesgo mantendrá una cartera en la esquina inferior izquierda de la frontera, y un inversor que no sea demasiado reacio al riesgo elegirá una cartera en la parte superior de la frontera.",
	MEJOR_CARTERA_P2:
		"La Figura a la derecha muestra la curva de indiferencia de riesgo-rendimiento para los inversores. Se muestran las curvas de indiferencia C1, C2 y C3. Cada uno de los diferentes puntos en una curva de indiferencia particular muestra una combinación diferente de riesgo y rendimiento, que proporcionan la misma satisfacción a los inversores. Cada curva a la izquierda representa una mayor utilidad o satisfacción. El objetivo del inversor sería maximizar su satisfacción moviéndose a una curva que sea más alta.",
	MEJOR_CARTERA_P3:
		"Un inversionista puede tener satisfacción representada por C2, pero si su satisfacción / utilidad aumenta, entonces él / ella pasa a la curva C3. Por lo tanto, en cualquier momento, un inversor será indiferente entre las combinaciones S1 y S2, o S5 y S6.",
	MEJOR_CARTERA_P4:
		"La cartera óptima del inversor se encuentra en el punto de tangencia de la frontera eficiente con la curva de indiferencia. Este punto marca el nivel más alto de satisfacción que el inversor puede obtener. Esto se muestra en la Figura a la derecha. R es el punto donde la frontera eficiente es tangente a la curva de indiferencia C3, y también es una cartera eficiente.",
	MEJOR_CARTERA_P5:
		"Con esta cartera, el inversor obtendrá la mayor satisfacción, así como la mejor combinación de riesgo-rendimiento (una cartera que ofrece el mayor rendimiento posible para una cantidad determinada de riesgo).",
	MEJOR_CARTERA_P6:
		"Cualquier otra cartera, por ejemplo, X, no es la cartera óptima, aunque se encuentra en la misma curva de indiferencia que está fuera de la cartera factible disponible en el mercado. La cartera Y tampoco es óptima, ya que no se encuentra en la mejor curva de indiferencia factible, a pesar de que es una cartera de mercado factible.",
	MEJOR_CARTERA_P7: "Otro inversor que tenga otros conjuntos de curvas de indiferencia podría tener una cartera diferente como su cartera mejor / óptima.",
	QUE_OBTENGO: "IronIA Portfolio Optimizer. ¿Qué obtengo?",
	QUE_OBTENGO_P1:
		"IronIA Portfolio Optimizer, proporciona la cartera eficiente tomando como base su cartera actual, es decir le especifica el % de cada fondo que debe tener para que su cartera sea la cartera eficiente y le proporcione las órdenes que debe realizar para cambiar su cartera actual por la eficiente. Además, la cartera eficiente se guarda como cartera modelo para su uso posterior y futuros rebalanceos.",
	QUE_OBTENGO_P2: "IronIA Portfolio Optimizer tiene un coste de [Price] Elements IA por optimización de cartera.",
	FIRMA: "Por la presente yo [Nombre] mayor de edad y con DNI [DNI] he sido debidamente informado de que la optimización del cartera no constituye una propuesta de asesoramiento sino la implementación matemática de un modelo conocido como modelo de [Modelo], anteriormente descrito y que en base a este modelo se obtiene unos valores que son el entregable del servicio contratado y que no supone ninguna obligación por mi parte la ejecución de las ordenes derivadas de estos valores que en cualquiera de los casos serán realizadas de manera totalmente voluntaria por mi parte."
};

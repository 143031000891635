import React from "react";
import DocumentTitle from "react-document-title";
import PromotionService, { QLTBonification } from "../../services/PromotionsService";

const Bonification = () =>
{
    const [loading, setLoading] = React.useState<boolean>(true);
    const [contract, setContract] = React.useState<string>("");
    const [result, setResult] = React.useState<QLTBonification>();
    const [error, setError] = React.useState<boolean>(false);
    const firstTime = React.useRef<boolean>(true);

    React.useEffect(() =>
    {
        window.scrollTo(0, 0);
    }, []);

    React.useEffect(() =>
    {
        if(firstTime.current === true)
        {
            const pathname = window.location.pathname;
            const contract = pathname.split('/').pop() || '';

            setContract(contract);
            
            firstTime.current = false;
            PromotionService.getBonification(contract).then((response: QLTBonification) =>
            {
                setResult({result: response.result, savingPlans: response.savingPlans});   
                setLoading(false);
            })
            .catch((error) =>
            {
                setError(true);
                setLoading(false);
            });
        }
    }, []);

    return(
        <React.Fragment>
            <DocumentTitle title={"IronIA"}>
                <React.Fragment>
                    {loading === true &&
                    (
                        <React.Fragment>
                            <p style={{cursor: "default"}}>
                                Comprobando los datos para aplicar IronIA Premium...
                            </p>
                        </React.Fragment>
                    )}
                    {loading === false && error === true &&
                    (
                        <React.Fragment>
                            <p style={{cursor: "default"}}>
                                Ha ocurrido un error al procesar tu solicitud.
                                Por favor, verifica que el contrato sea correcto o contacta con
                                <a href="mailto:info@ironia.tech"> info@ironia.tech</a>.
                            </p>
                        </React.Fragment>
                    )}
                    {loading === false && error === false &&
                    (
                        <React.Fragment>
                            {result?.result === true &&
                            (
                                <React.Fragment>
                                    <p style={{cursor: "default"}}>
                                        Tu contrato {contract} ha sido cambiado a la suscripción IronIA Premium Bonificada.
                                        Esto afecta a tus Planes de Ahorro:
                                    </p>
                                    <ul style={{cursor: "default"}}>
                                        {result.savingPlans?.map((plan, index) =>
                                        (
                                            <li key={index}>{plan}</li>
                                        ))}
                                    </ul>
                                </React.Fragment>
                            )}
                            {result?.result === false &&
                            (
                                <React.Fragment>
                                    <p style={{cursor: "default"}}>
                                        Lo sentimos, no puedes acogerte a esta bonificación de suscripción, 
                                        si tienes alguna duda ponte en contacto en el correo
                                        <a href="mailto:info@ironia.tech"> info@ironia.tech</a>.
                                    </p>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            </DocumentTitle>
        </React.Fragment>
    )
}

export default Bonification;
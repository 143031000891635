import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { BrowserRouter, useHistory } from "react-router-dom";
import "office-ui-fabric-react/dist/css/fabric.css";
import { themes } from "./components/ThemeContext";
import { ThemeProvider } from "@fluentui/react-theme-provider";
import { initializeIcons } from "@uifabric/icons";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MainMenu from "./components/MainMenu";
import { insightsInstrumentationKey, nodeEnv, proxyUrl, serverHost } from "./config";
import GlobalStyle from "./components/GlobalStyle";
import Auth from "./Auth/Auth";
import "./App.sass";
import { connect, useDispatch } from "react-redux";
import { isAuthenticateRedux, loginRedux, completeUserDataRedux, checkSuscriptionsRedux, userLastPlanActive, setSavingsPlanElementsStatus } from "./store/auth/actions";
import { getAppInsights } from './services/TelemetryService';
import TelemetryProvider from './providers/telemetry-provider';
import ManagedPortfolioService from './services/ManagedPortfolioService';
import styled from 'styled-components';
import { setTheme, setConnectionReference } from "./store/ui/actions";
import AutoLogoutLogic from "./AutoLogoutLogic";
import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import { setIconOptions } from '@fluentui/react/lib/Styling';
import CartChip from "./components/CartChip/CartChip";

// Suppress icon warnings.
setIconOptions({disableWarnings: true});
initializeIcons(undefined, {disableWarnings: true});

const MainGrid = styled.div.attrs((props: any) =>
({
    footerVisible: props.footerVisible
}))`
${(props: any) =>
{
    return props.footerVisible ? "" : "padding-bottom: 0 !important";
}}`;

function mapStateToProps(state: any)
{
    return{
        footerVisible: state.ui.footerVisible,
        // Cambiar nombre a iFrame theme o algo similar. Separar del que se almacena en local storage?
        storeTheme: state.ui.theme,
    };
}

const App: (props: any) => any = ({children, footerVisible, storeTheme}) =>
{
    const dispatch = useDispatch();
    const localTheme = window.localStorage.getItem("theme") || "light";
    window.localStorage.setItem("theme", localTheme);
    const [theme, setStateTheme] = useState(
    {
        name: localTheme,
        palette: localTheme === "dark" ? themes.dark : themes.light,
    });
    const menuRef = useRef();
    const [isLoaded, setIsLoaded] = useState(false);
    const [connection, setConnection] = useState<HubConnection>();
    const [connectionReferenceAux, setConnectionReferenceAux] = useState<string>();
    let history = useHistory();
    const iframeVersion = useState(history.location.pathname.match(/iframe/));
    let appInsights: any = null;

    const checkUser = useCallback(async () =>
    {
        await dispatch(setTheme(localTheme));
        let user: any = Auth.getUserProfile();
        if(user === null || user.oid === undefined)
        {
            var response = await axios.get(`${serverHost}/user`, {withCredentials: true});

            if(typeof response.data.oid === "undefined")
            {
                await dispatch(isAuthenticateRedux(false));
                // Esta redirección ahora se hace desde ProtectedRoute.
                //window.location.href = `${config.server_host}/login`;
                if(iframeVersion)
                {
                    createConnection(null);
                }
            }
            else
            {
                Auth.setUserProfile(response.data);
                await dispatch(loginRedux(response.data));
                await dispatch(isAuthenticateRedux(true));
                await dispatch(completeUserDataRedux(response.data.oid));
                user = Auth.getUserProfile();
                await dispatch(checkSuscriptionsRedux(user.id));
                await dispatch(userLastPlanActive(user.id));
                let activePlan: any = Auth.getActivePlan();
                await dispatch(setSavingsPlanElementsStatus(activePlan.id));
                createConnection(user);
            }
        }
        setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, localTheme]);

    useEffect(() =>
    {
        if(!isLoaded)
        {
            checkUser();
            setStateTheme(
            {
                name: localTheme,
                palette: localTheme === "dark" ? themes.dark : themes.light,
            });
            initializeIcons(undefined, { disableWarnings: true });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
		if(isLoaded && nodeEnv !== 'development')
        {
			const script = document.createElement("script");
			script.type = "text/javascript";
			script.async = true;
			script.innerHTML = `window.clarity("set", "Id Usuario", "${Auth.getUserProfile().id}"); window.clarity("set", "Nombre Usuario", "${
				Auth.getUserProfile().fullName
			}");`;
			document.body.appendChild(script);
            //console.log(Auth.getUserProfile().id, Auth.getUserProfile().fullName);
			return () =>
            {
				// clean up the script when the component in unmounted
				document.body.removeChild(script);
			};
		}
	}, [isLoaded]);

    useEffect(() =>
    {
        if(connection)
        {
            connection.start().then(() =>
            {
                connection.on('newMessage', (message: any) =>
                {
                    localStorage.setItem('modelPortfolios', message);
                    const event = new Event('storageChange')
                    document.dispatchEvent(event)
                });

                if(localStorage.getItem('modelPortfolios') === null)
                {
                    if(connectionReferenceAux)
                    {
                        ManagedPortfolioService.getManagedPortfoliosForTheCover(connectionReferenceAux);
                    }
                }
            }).catch((e: any) => console.log('Connection failed: ', e));
        }
        else
        {
            var user = Auth.getUserProfile();
            if(user.id !== undefined)
            {
                createConnection(user);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection]);

    useEffect(() =>
    {
        if(connectionReferenceAux)
        {
            const host = window.location.hostname === 'localhost'
                ? 'http://localhost:7043'
                : proxyUrl;

            const newConnection = new HubConnectionBuilder().withUrl(`${host}/managedPortfoliosHub`,
            {
                headers: { "x-application-user-id": connectionReferenceAux }
            })
            .withAutomaticReconnect()
            .build();

            setConnection(newConnection);
        }
    }, [connectionReferenceAux]);

    const createConnection = async (user: any) =>
    {
        if(connectionReferenceAux === undefined)
        {
            var connectionReference;
            if(user?.id !== undefined)
            {
                connectionReference = user.id;
            }
            else
            {
                var hash = [...Array(32)].map(i => (~~(Math.random() * 36)).toString(36)).join('');
                connectionReference = hash;
            }
            setConnectionReferenceAux(connectionReference);
            dispatch(setConnectionReference(connectionReference));
        }
    }

    if(storeTheme !== theme.name)
    {
        setStateTheme(
        {
            name: storeTheme,
            palette: storeTheme === "dark" ? themes.dark : themes.light,
        });
    }
    const toggleTheme = async () =>
    {
        window.localStorage.setItem("theme", theme.palette.name === "dark" ? "light" : "dark");
        setStateTheme(
        {
            name: window.localStorage.theme,
            palette: window.localStorage.theme === "dark" ? themes.dark : themes.light,
        });
        dispatch(setTheme(window.localStorage.theme));
    };

    return(
        <BrowserRouter>
            {!isLoaded &&
            (
                <figure className='full-loading-image'>
                    <div>
                        <svg width="50%" viewBox="0 0 182 72" fill="none">
                            <path d="M 36.375,27.1719 28.0521,40.9062 H 47.9863 L 45.7968,37.5225" stroke="#cc214f" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" id="path10" />
                            <path d="m 43.1375,33.4593 -0.633,-0.9782 -0.8494,-1.278" stroke="#cc214f" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" id="path12" />
                            <path d="m 40.7812,24.875 c 0,1.7086 -1.3851,3.0938 -3.0937,3.0938 -1.7086,0 -3.0937,-1.3852 -3.0937,-3.0938 0,-1.7086 1.3851,-3.0938 3.0937,-3.0938 1.7086,0 3.0937,1.3852 3.0937,3.0938 z m -4.0031,0 c 0,0.5023 0.4071,0.9094 0.9094,0.9094 0.5023,0 0.9094,-0.4071 0.9094,-0.9094 0,-0.5023 -0.4071,-0.9094 -0.9094,-0.9094 -0.5023,0 -0.9094,0.4071 -0.9094,0.9094 z" fill="#2a4143" id="path14" />
                            <path d="M 61.2153,41.7401 V 22.2338 h 4.7805 v 19.5063 z" fill="#2a4143" id="path2" />
                            <path d="m 82.3294,33.4259 3.3435,8.3031 H 80.4636 L 77.5661,34.3174 H 74.0205 V 41.729 H 69.2837 V 22.2513 h 9.8985 c 3.3155,0 6.0462,2.7028 6.0462,6.0184 -0.0027,1.0354 -0.2706,2.053 -0.7781,2.9556 -0.5074,0.9026 -1.2376,1.6602 -2.1209,2.2006 z M 80.4622,28.2711 C 80.4583,27.9324 80.322,27.6087 80.0826,27.3693 79.8431,27.1298 79.5194,26.9935 79.1807,26.9896 h -5.1602 v 2.5914 h 5.1617 c 0.6966,0 1.2814,-0.5853 1.2814,-1.3099 z" fill="#2a4143" id="path16" />
                            <path d="m 106.626,31.9492 c 0.002,1.3103 -0.255,2.608 -0.756,3.8188 -0.501,1.2109 -1.235,2.311 -2.162,3.2376 -0.926,0.9265 -2.027,1.6612 -3.237,2.1621 -1.2112,0.5008 -2.5089,0.7579 -3.8192,0.7566 -5.4889,0 -9.975,-4.4582 -9.975,-9.9751 0,-5.4889 4.4837,-9.9751 9.975,-9.9751 5.5172,0 9.9742,4.4862 9.9742,9.9751 z m -4.736,0 c 0.001,-0.6881 -0.134,-1.3696 -0.397,-2.0055 -0.263,-0.6359 -0.649,-1.2136 -1.135,-1.7002 -0.487,-0.4866 -1.0648,-0.8724 -1.7007,-1.1354 -0.6359,-0.2629 -1.3174,-0.3979 -2.0055,-0.3971 -2.87,0 -5.2382,2.3407 -5.2382,5.2382 0,2.8975 2.3682,5.2382 5.2382,5.2382 0.6881,8e-4 1.3696,-0.1342 2.0055,-0.3972 0.6359,-0.2629 1.2137,-0.6488 1.7007,-1.1353 0.486,-0.4866 0.872,-1.0643 1.135,-1.7002 0.263,-0.6359 0.398,-1.3174 0.397,-2.0055 z" fill="#2a4143" id="path18" />
                            <path d="M 108.96,41.7401 V 22.2338 h 4.093 l 8.407,11.9236 V 22.2338 h 4.588 v 19.5063 h -4.093 l -8.407,-11.9236 v 11.9236 z" fill="#2a4143" id="path4" />
                            <path d="M 128.6,41.7402 V 22.2339 h 4.781 v 19.5063 z" fill="#cc214f" id="path6" />
                            <path d="m 135.385,41.7402 7.72,-19.5063 h 5.083 l 7.72,19.5063 h -4.946 l -1.373,-3.7914 h -8.05 l -1.374,3.7914 z m 7.61,-7.8026 h 5.138 L 145.55,26.822 Z" fill="#cc214f" id="path8" />
                            <text>
                                <tspan id="tspan9286" x="60.555046" y="53.544037">TU PERSONAL STORE FINANCIERO</tspan>
                            </text>
                        </svg>
                    </div>
                </figure>
            )}
            {isLoaded &&
            (
                <TelemetryProvider instrumentationKey={insightsInstrumentationKey} after={() => {appInsights = getAppInsights()}}>
                    <ThemeProvider theme={theme}>
                        <GlobalStyle theme={theme} />
                        <MainGrid className="ms-Grid" dir="ltr" footerVisible={footerVisible}>
                            {<Header toggleTheme={toggleTheme} theme={theme} menuRef={menuRef} />}
                            <div className="ms-Grid-row">
                                <div id="main-element" className="ms-Grid-col ms-sm12 ms-xl12">
                                    {children}
                                </div>
                            </div>
                            <MainMenu theme={{theme, toggleTheme}} ref={menuRef} />
                            <AutoLogoutLogic timeout={899} />
                            <Footer />
                            <CartChip />
                        </MainGrid>
                    </ThemeProvider>
                </TelemetryProvider>
            )}
        </BrowserRouter>
    );
};

export default connect(mapStateToProps)(App);
import React from "react";
import DocumentTitle from 'react-document-title';
import styled from "styled-components";
import scrollToTop from "../../utils/scrollToTop";
import { useHistory, useLocation } from "react-router";
import { useBoolean } from "@fluentui/react-hooks";
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { Spinner, SpinnerSize } from "@fluentui/react";
import { SignatureComponent } from "../Signature/SignatureComponent";
import { ISignature } from "../../services/SignaturesService";
import ModalExperienceSameIdentities from "./ModalExperienceSameIdentities";
import ModalKOExperienceSameIdentities from "./ModalKOExperienceSameIdentities";
import Auth from "../../Auth/Auth";
import moment from "moment";
import savingsPlans from "../../services/SavingsPlanService";
import contract, { SyncResult } from "../../services/FnmContractService";
import ProcessingModalComponent from "../Modals/ProcessingModalComponent";

export const TextBold = styled.p`
    margin-bottom: 0;
    font-weight: bold;
`;
export const Text = styled.p`
    margin-bottom: 0;
`;
export const ContainerSignature = styled.div`
    grid-area: main;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
`;
export const MainContainerSignature = styled.div`
    padding: 40px 55px;
`;

const ExperienceSavingsPlanSameIdentities = (props: any) =>
{
    const { state } = useLocation<any>();
    const history = useHistory();
    var subscription = state?.subscription;
    var savingsPlanToCopy = state?.savingsPlanToCopy;
    const user = Auth.getUserProfile();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [modalOkShow, setModalOkShow] = React.useState(false);
    const [modalKoShow, setModalKoShow] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [signatures, setSignatures] = React.useState<ISignature[]>([]);

    React.useEffect(() =>
    {
        scrollToTop();
    }, []);
    React.useEffect(() =>
    {
        if(signatures.length === 0)
        {
            savingsPlans.addSavingPlanSameIdentities(savingsPlanToCopy.id).then((response: any) =>
            {
                if(response !== null && response !== undefined && response.length > 0)
                {
                    localStorage.setItem("MultiPlanSavingPlanId", String(response[0].savingsPlansUserIdentity.savingsPlanId));
                    setSignatures(response);
                    setIsLoaded(true);
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatures, user.id, subscription.id]);
    const processExpirationDate = (signs: ISignature[]) =>
    {
        if(signs !== undefined && signs.length > 0)
        {
            // eslint-disable-next-line array-callback-return
            signs.map((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = moment(signature.expiresAt).toDate();
                }
            });
        }
    }
    const handleSubmit = () =>
    {
        if(signatures.length > 0 || !modalOkShow)
        {
            scrollToTop();
            showModal();
            
            var savingPlanIdString = localStorage.getItem("MultiPlanSavingPlanId");

            if(savingPlanIdString !== undefined && savingPlanIdString !== null)
            {
                var savingPlanId: number = parseInt(savingPlanIdString);

                contract.syncSavingPlan(savingPlanId).then((response: SyncResult) =>
                {
                    if(response.result === true)
                    {
                        hideModal();
                        setModalOkShow(true);
                        localStorage.removeItem("MultiPlanSavingPlanId");
                    }
                    else
                    {
                        hideModal();
                        setModalKoShow(true);
                        localStorage.removeItem("MultiPlanSavingPlanId");
                    }
                });
            }
            else
            {
                hideModal();
                setModalKoShow(true);
            }
        }
    };

    return(
        <React.Fragment>
            <DocumentTitle title="IronIA - Suscripción Mismos Intervinientes">
                <React.Fragment>
                    <h2 style={{ display: "inline-block", margin: 0 }}>Nueva Suscripción - Mismos Intervinientes</h2>
                    <Separator />
                    <div className="ms-Grid-row">
                        <h3>Estamos utilizando tu suscripción a {subscription.name} para este plan. No tienes que realizar ningún pago adicional.</h3>
                    </div>
                    <ContainerSignature>
                        <MainContainerSignature>
                            {!isLoaded && (
                                <Spinner size={SpinnerSize.large} />)
                            }
                            {isLoaded && (
                                <React.Fragment>

                                    <SignatureComponent
                                        signatures={signatures}
                                        alreadySignedText=" "
                                        submitButtonText="Firmar contrato"
                                        cancelButtonText="Volver"
                                        headerTextArea={"Introduce el código que te hemos enviado al teléfono XXXXXX" + signatures[0].phone?.slice(signatures[0].phone.length - 3) + " por SMS para firmar el contrato."}
                                        backButtonText="Volver"
                                        onBackButtonClicked={() => history.push('/savingsPlans/iHaveExperience')}
                                        onCancelButtonClicked={() => history.push('/savingsPlans/iHaveExperience')}
                                        requestNewCodeButtonText="Recibir nuevo código"
                                        onNewCodeSended={(signatures: any) =>
                                        {
                                            processExpirationDate(signatures);
                                            setSignatures(signatures);
                                        }}
                                        onSignatureChanged={(signatures: any) =>
                                        {
                                            processExpirationDate(signatures);
                                            setSignatures(signatures);
                                        }}
                                        onSigned={handleSubmit}
                                    />
                                    <ProcessingModalComponent
                                        isModalOpen={isModalOpen}
                                        hideModal={hideModal}
                                    />
                                </React.Fragment>
                            )}
                        </MainContainerSignature>
                    </ContainerSignature>
                    {modalOkShow && <ModalExperienceSameIdentities setModalShow={setModalOkShow} />}
                    {modalKoShow && <ModalKOExperienceSameIdentities setModalShow={setModalKoShow} />}
                </React.Fragment>
            </DocumentTitle>
        </React.Fragment>
    );
}

export default ExperienceSavingsPlanSameIdentities;
import { LOADING, PROFILE, AUTHENTICATED, ROLES, LOGINFAILE, COMPLETE_USER_DATA, CHECK_SUBSCRIPTIONS, USER_ACTIVE_PLAN, SAVINGS_PLAN_ELEMENTS_STATUS } from './Constants';
import Auth from "../../Auth/Auth";
import UserService from '../../services/UserService';
import ElementService, { SavingsPlanElementsStatus } from '../../services/ElementsService';

export const loginRedux = (respuesta: any) => async (dispatch: any) =>
{
    Auth.setSession(respuesta)
    dispatch(
    {
        type: PROFILE,
        payload: respuesta
    });
}

export const completeUserDataRedux = (userDruId: string) => async (dispatch: any) =>
{
    await UserService.getUserByDruid(userDruId).then(data =>
    {
        Auth.setUserProfile(data);
        dispatch(
        {
            type: COMPLETE_USER_DATA,
            payload: data
        })
    }).catch((e) => { });
}

export const setActiveSavingsPlan = (savingsPlan: any) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: USER_ACTIVE_PLAN,
        payload: savingsPlan
    })
};

export const userLastPlanActive = (userId: any) => async (dispatch: any) =>
{
    await UserService.getUserLastActivePlan(userId).then(async data =>
    {
        Auth.setActivePlan(data);
        await setActiveSavingsPlan({ ...data, sync: true });
    }).catch((e) => { });
}

export const setSavingsPlanElementsStatus = (savingsPlanId: number) => (dispatch: any) =>
{
    ElementService.isSavingsPlanSubscribedToElements(savingsPlanId).then(async (savingsPlanElementsStatus: SavingsPlanElementsStatus) =>
    {
        await dispatch(
        {
            type: SAVINGS_PLAN_ELEMENTS_STATUS,
            payload: savingsPlanElementsStatus
        })
        Auth.setSavingsPlanElementsStatus({ ...savingsPlanElementsStatus, sync: true });
    }).catch((e) => { });
};

export const checkSuscriptionsRedux = (userId: number) => async (dispatch: any) =>
{
    await UserService.getSuscriptions(userId).then(data =>
    {
        const user = Auth.getUserProfile();
        Auth.setUserProfile({ ...user, usersSubscriptions: data });
        dispatch(
        {
            type: CHECK_SUBSCRIPTIONS,
            payload: data
        })
    }).catch((e) => { });
}

export const restorePasswordRedux = (email: string) => async (dispatch: any) =>
{
    dispatch(
    {
        type: LOADING,
        payload: true
    });
}

export const resetProfileRedux = () => async (dispatch: any) =>
{
    localStorage.removeItem("access_token");
    localStorage.removeItem("expiresAt");
    localStorage.removeItem("profile");
    localStorage.removeItem("roles");

    dispatch(
    {
        type: PROFILE,
        payload: []
    });
    dispatch(
    {
        type: AUTHENTICATED,
        payload: false
    });
    dispatch(
    {
        type: ROLES,
        payload: []
    });
}

export const loginReduxError = () => async (dispatch: any) =>
{
    dispatch(
    {
        type: LOGINFAILE,
        payload: true
    });
}

export const isAuthenticateRedux = (value: any) => async (dispatch: any) =>
{
    dispatch(
    {
        type: AUTHENTICATED,
        payload: value
    });
}
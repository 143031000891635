import React from "react";
import { models, Report, Embed, service } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import Styles from "./index.module.css";
import PowerBiServices  from "../../../services/PowerBi";

type Props = {
	reportId: string;
};

const PowerBiEmbedCustom = (props: Props) => {
	const { reportId } = props;

	const [report, setReport] = React.useState<Report>();
	const [reportConfig, setReportConfig] = React.useState<models.IReportEmbedConfiguration>({
		//id: reportId,
		type: "report",
		embedUrl: undefined,
		tokenType: models.TokenType.Embed, //models.TokenType.Aad,
		accessToken: undefined,
		viewMode: models.ViewMode.View,
		permissions: models.Permissions.Read,
		settings: {
			localeSettings: {
				language: "es",
				formatLocale: "es"
			},
			bars: {
				actionBar: {
					visible: false
				}
			},
			panes: {
				filters: {
					expanded: false,
					visible: false
				}
			},
			//background: models.BackgroundType.Transparent
		}
	});

	React.useEffect(() => {
		const fetchReport = async () => {
			PowerBiServices.getReportTokens(reportId).then(response => {
				setReportConfig({
					...reportConfig,
					embedUrl: response.embedUrl,
					accessToken: response.embedToken,
					id: response.reportId
				});
			});
		};

		fetchReport();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportId]);

	const eventHandlersMap = new Map([
		[
			"loaded",
			function () {
				console.log("Report has loaded");
			}
		],
		[
			"rendered",
			function () {
				console.log("Report has rendered");
			}
		],
		[
			"saved",
			function () {
				console.log("Report saved");
			}
		],
		[
			"error",
			function (event?: service.ICustomEvent<any>) {
				if(event) {
					console.error(event.detail);
				}
			}
		]
	]);

	return (
		<PowerBIEmbed
			embedConfig={reportConfig}
			eventHandlers={eventHandlersMap}
			cssClassName={Styles.EmbebedReportBI}
			getEmbeddedComponent={(embedObject: Embed) => {
				console.log(`Embedded object of type "${embedObject.embedtype}" received`);
				setReport(embedObject as Report);
			}}
		/>
	);
};

export default PowerBiEmbedCustom;

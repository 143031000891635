import Auth from '../Auth/Auth';
import FnmContractComplexProductService from '../services/FnmContractComplexProductService';
import { UserIdentityType } from "../services/UserIdentitiesService";
import { addLine } from '../store/cart/actions';

export type CartLineDataType = {
    fund: any,
    amount: number,
    readOnly?: boolean,
    userSuscriptionId?: number
}

export const AppendLine = async (dispatch: any, fund: any, amount: number, readOnly?: boolean, userSuscriptionId?: number) => {
    fund.readonly = readOnly || false;

    await dispatch(addLine(
        {
            fund: fund,
            amount: amount,
            userSuscriptionId: userSuscriptionId
        }));
};

const AppendCartLine = async (
    dispatch: any,
    history: any,
    cartLineData: CartLineDataType,
    returnPath?: string
) => {
    if (cartLineData.fund.complexity) {
        var activePlan = Auth.getActivePlan();

        if (!activePlan.allUserIdentitiesHaveSuitabilityTest) {
            localStorage.setItem("complexProduct", JSON.stringify({
                returnPath: history.location.pathname,
                amount: cartLineData.amount,
                fund: cartLineData.fund
            }));
            history.push(`/savingsPlans/${activePlan.id}/complexProducts/${cartLineData.fund.isin}/suitabilityTestList`);
            return;
        }

        await FnmContractComplexProductService
            .getUserIdentitiesThatShouldSignComplexProductAgreement(activePlan.fnmContractId, cartLineData.fund.isin)
            .then(async (userIdentities: UserIdentityType[]) => {
                if (userIdentities.length > 0) {
                    localStorage.setItem("complexProduct", JSON.stringify({
                        returnPath: returnPath ? returnPath : history.location.pathname,
                        amount: cartLineData.amount,
                        fund: cartLineData.fund
                    }));
                    history.push(`/savingsPlans/${activePlan.id}/complexProducts/${cartLineData.fund.isin}/agreement`);
                    return;
                }
                else {
                    await AppendLine(dispatch, cartLineData.fund, cartLineData.amount);
                    if (returnPath !== undefined) {
                        localStorage.removeItem("complexProduct");
                        history.push(returnPath);
                    }
                }
            });
    }
    else {
        await AppendLine(dispatch, cartLineData.fund, cartLineData.amount, cartLineData.readOnly, cartLineData.userSuscriptionId);
    }
}

export default AppendCartLine;
import React, { useEffect, useRef, useState } from "react";
import Auth from "../../../../Auth/Auth";
import ElementService, { ElementContract } from "../../../../services/ElementsService";
import FinametrixService, { IClientDocs } from "../../../../services/FinametrixService";
import AttachmentService from "../../../../services/AttachmentService";
import DocumentationTable from "./DocumentationTable";
import { DetailsList, DetailsListLayoutMode, Modal, Pivot, PivotItem, SelectionMode, Spinner, SpinnerSize, ThemeProvider } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import styles from "../index.module.sass";

const TabComponent = () =>
{
    const firstTimeClientDocuments = useRef<boolean>(false);
    const firstTimeReports = useRef<boolean>(false);
    const firstTimeLoadingElements = useRef<boolean>(false);
    const isLoading = useRef<boolean>(true);
    const [clientDocuments, setClientDocuments] = useState<IClientDocs[]>();
    const [elementContracts, setElementContracts] = useState<ElementContract[]>();
    const [reports, setReports] = useState<any[]>();
    const user = Auth.getUserProfile();
    const plan = Auth.getActivePlan();
    const savingsPlanElementsStatus = Auth.getSavingsPlanElementsStatus();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    var columns = ["Tipo", "Fecha", "Archivo"];
    var elementsColumns = [
        {
            key: "name",
            fieldName: "name",
            name: "Contrato",
            minWidth: 100
        },
        {
            key: "document",
            fieldName: "userIdentityDocument",
            name: "Documento",
            minWidth: 100
        },
        {
            key: "fullname",
            fieldName: "userIdentityFullname",
            name: "Interviniente",
            minWidth: 100
        },
        {
            key: "version",
            fieldName: "version",
            name: "Versión",
            minWidth: 100
        },
        {
            key: "date",
            fieldName: "date",
            name: "Fecha de firma",
            minWidth: 100
        },
        {
            key: "file",
            fieldName: "file",
            name: "Archivo",
            minWidth: 100
        }
    ];

    const renderOrderItemColumn = (item: any, index: any, column: any) => {
        switch (column.key) {
            case "file":
                return (
                    <React.Fragment>
                        <a href={'#' + item.file} onClick={(event) => {
                            event.preventDefault();
                            showModal();
                            AttachmentService.downloadIroniaStoreBase64Pdf(event, item)
                            .finally(() => {
                                hideModal();
                            })
                        }}>
                            <i className={'ironia-icon downloads'} /> Descargar
                        </a>
                    </React.Fragment>
                );

            default:
                return <span>{item[column.key]}</span>
        }
    }

    useEffect(() =>
    {
        if(firstTimeLoadingElements.current === false)
        {
            firstTimeLoadingElements.current = true;

            if (savingsPlanElementsStatus.isSubscribed)
            {
                ElementService.getAllContracts(plan.id).then((response: ElementContract[]) =>
                {
                    setElementContracts(response);
                });
            }
        }
    }, [savingsPlanElementsStatus.isSubscribed, user]);

    useEffect(() =>
    {
        if(firstTimeClientDocuments.current === false)
        {
            firstTimeClientDocuments.current = true;

            FinametrixService.getClientDocuments(user.id).then((response: IClientDocs[]) =>
            {
                setClientDocuments(response);
            });
        }
        if(firstTimeReports.current === false)
        {
            firstTimeReports.current = true;
            FinametrixService.getMonthlyReports(user.id).then((response: IClientDocs[]) =>
            {
                setReports(response);
            });
        }

        isLoading.current = false;

    }, [firstTimeClientDocuments, firstTimeReports, user]);

    if(isLoading.current === true)
    {
        return(<Spinner size={SpinnerSize.large} />)
    }
    else
    {
        return(
            <React.Fragment>
                <ThemeProvider>
                    <div style={{padding: "10px"}}>
                        {!savingsPlanElementsStatus.isSubscribed && (
                            <Pivot>
                                <PivotItem headerText="Documentos de Clientes">
                                    <DocumentationTable rowData={clientDocuments} columnTitles={columns} dataType="clientDocument" />
                                </PivotItem>
                                <PivotItem headerText="Reportes Mensuales">
                                    <DocumentationTable rowData={reports} columnTitles={columns} dataType="monthlyReport" />
                                </PivotItem>
                            </Pivot>
                        )}
                        {savingsPlanElementsStatus.isSubscribed && (
                            <Pivot>
                                <PivotItem headerText="Documentos de Clientes">
                                    <DocumentationTable rowData={clientDocuments} columnTitles={columns} dataType="clientDocument" />
                                </PivotItem>
                                <PivotItem headerText="Reportes Mensuales">
                                    <DocumentationTable rowData={reports} columnTitles={columns} dataType="monthlyReport" />
                                </PivotItem>
                                <PivotItem headerText="Contratos de Elements">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-md2 ms-hiddenLgDown"></div>
                                        <div className="ms-Grid-col ms-sm12 ms-xl8">
                                            <DetailsList
                                                className={styles.elementsContracts}
                                                items={elementContracts || []}
                                                columns={elementsColumns}
                                                selectionMode={SelectionMode.none}
                                                onRenderItemColumn={renderOrderItemColumn}
                                                layoutMode={DetailsListLayoutMode.justified}
                                            />
                                        </div>
                                    </div>
                                </PivotItem>
                            </Pivot>
                        )}
                    </div>
                    {isModalOpen && (
                        <Modal
                            isOpen={isModalOpen}
                            onDismiss={hideModal}
                            isBlocking={false}
                            containerClassName={styles.modal}
                        >
                            <h3>Descargando archivo</h3>
                            <Spinner size={SpinnerSize.large} />
                        </Modal>
                    )}
                </ThemeProvider>
            </React.Fragment>
        );
    }
}

export default TabComponent;
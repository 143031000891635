import * as S from '../ConvenienceTest.style';
import { useEffect, useState } from 'react';
import Select from 'react-dropdown-select';
import { Attachment } from '../../Attachment';

const economicActivityOptions = [
    {
        key: 'SELF_EMPLOYED',
        text: 'Autónomo',
        name: 'Autónomo',
    },
    {
        key: 'OTHER_EMPLOYEES',
        text: 'Otros asalariados',
        name: 'Otros asalariados',
    }
];

const ConvenienceTestFinanceInputs = ({ // @ts-ignore
    handleChange, data, formVal, setFormVal
}) => {
    const {
        economicActivity,
        proofOfIncome,
        gafiQuestion,
        publicPosition
    } = data;

    const [errors, setErrors] = useState({
        economicActivity: "",
        proofOfIncome: "",
        gafiQuestion: "",
        publicPosition: ""
    });

    const getErrorMessage = (field: string, value: any) => {
        switch (field) {
            case 'economicActivity':
                return (!value.trim()) ? <S.Error>El campo Actividad es obligatorio</S.Error> : "";

            case 'proofOfIncome':
                return (value.size === 0) ? <S.Error>El campo Justificante de ingesos es obligatorio</S.Error> : "";

            case 'gafiQuestion':
                return (value === 'true') ? <S.Error>Ninguna pregunta puede quedar sin contestar</S.Error> : "";

            case 'publicPosition':
                return (value === 'true') ? <S.Error>Ninguna pregunta puede quedar sin contestar</S.Error> : "";

            default:
                throw new Error('Error en el switch');
        }
    };

    useEffect(() => {
        Object.keys(data).forEach((key) => {
            if (key in formVal) {
                if (data[key] === null || data[key] === undefined || data[key] === "") {
                    setFormVal(key, false);
                }
                else {
                    var error = getErrorMessage(key, data[key]);
                    setErrors((prevState: any) => {
                        return {
                            ...prevState,
                            [key]: error
                        }
                    });
                    setFormVal(key, error === "");
                }
            }
        });
    }, []);

    const handleInputChange = (event: any) => {
        if (event.target !== undefined) {
            var { name, value } = event.target;
            var error = getErrorMessage(name, value);
            setErrors((prevState: any) => {
                return {
                    ...prevState,
                    [name]: error
                }
            });
            setFormVal(name, error === "");
            handleChange(event);
        }
    };

    const handleInputFileChange = (event: any) => {
        if (event.target !== undefined) {
            var { name, value } = event.target;
            var error = getErrorMessage(name, value);
            setErrors((prevState: any) => {
                return {
                    ...prevState,
                    [name]: error
                }
            });
            setFormVal(name, error === "");
            handleChange(event);
        }
    };

    return (
        <>
            <S.FieldSet>
                <S.FieldRSCont className="full-width">
                    <S.DropDownLabel>Actividad *</S.DropDownLabel>
                    <Select
                        name='economicActivity'
                        labelField='name'
                        options={economicActivityOptions}
                        onChange={(event: any) => handleInputChange({ target: { name: "economicActivity", value: event[0].key } })}
                        // @ts-ignore
                        value={economicActivity}
                        placeholder=''
                        dropdownHeight='2000'
                        searchable={true}
                        noDataLabel={'No encontrado'}
                        backspaceDelete={false}
                        valueField={'key'}
                        values={economicActivityOptions.filter((economicActivityOption) => economicActivityOption.key === economicActivity)}
                    />
                    {errors.economicActivity !== "" && errors.economicActivity}
                </S.FieldRSCont>
                <S.FieldRBCont>
                    <S.Label>Justificante de ingresos *</S.Label>
                    <S.FieldFileCont>
                        <S.FileInput
                            id='proofOfIncome'
                            name='proofOfIncome'
                            type='file'
                            onChange={handleInputFileChange}
                            required
                        />
                    </S.FieldFileCont>
                    {proofOfIncome !== undefined && typeof proofOfIncome === "string" && (
                        <div>
                            <S.DocumentAttachmentWrapper >
                                <Attachment
                                    fileName={proofOfIncome}
                                    className="document-preview"
                                />
                            </S.DocumentAttachmentWrapper >
                        </div>
                    )}
                    {errors.proofOfIncome !== "" && errors.proofOfIncome}
                </S.FieldRBCont>
            </S.FieldSet>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-md6">
                    <S.RadioCont>
                        <S.Question>
                            ¿Alguno de los intervinientes o sus familiares realiza alguna actividad
                            o es residente/nacional de alguna de las jurisdicciones consideradas
                            de riesgo de acuerdo con la normativa de prevención de blanqueo de
                            capitales o no cooperantes según GAFI (Afganistán, Corea del Norte,
                            Irán, Laos, Papúa Nueva Guinea, Uganda, Yemen, Bosnia y Herzegovina,
                            Guyana, Irak, mi an-Mar, Siria y Vanuatu)?
                        </S.Question>
                        <S.RadioSelect>
                            <S.RadioLabel>
                                Si
                                <S.RadioInput
                                    id='gafiQuestion'
                                    type='radio'
                                    name='gafiQuestion'
                                    // @ts-ignore
                                    value={true}
                                    onChange={(event) => handleInputFileChange(event)}
                                    checked={gafiQuestion !== null && gafiQuestion} />
                                <S.RadioCheck />
                            </S.RadioLabel>
                        </S.RadioSelect>
                        <S.RadioSelect>
                            <S.RadioLabel>
                                No
                                <S.RadioInput
                                    id='gafiQuestion'
                                    type='radio'
                                    name='gafiQuestion'
                                    // @ts-ignore
                                    value={false}
                                    onChange={(event) => handleInputFileChange(event)}
                                    checked={gafiQuestion !== null && !gafiQuestion} />
                                <S.RadioCheck />
                            </S.RadioLabel>
                        </S.RadioSelect>
                        {errors.gafiQuestion !== "" && errors.gafiQuestion}
                    </S.RadioCont>
                </div>
                <div className="ms-Grid-col ms-md6">
                    <S.RadioCont>
                        <S.Question>
                            ¿Alguno de los intervinientes o sus familiares son personas políticamente
                            expuestas (personas que desempeñan o han desempeñado funciones
                            públicas relevantes en los últimos años)?
                        </S.Question>
                        <S.RadioSelect>
                            <S.RadioLabel>
                                Si
                                <S.RadioInput
                                    id='publicPosition'
                                    type='radio'
                                    name='publicPosition'
                                    // @ts-ignore
                                    value={true}
                                    onChange={(event) => handleInputFileChange(event)}
                                    checked={publicPosition !== null && publicPosition} />
                                <S.RadioCheck />
                            </S.RadioLabel>
                        </S.RadioSelect>
                        <S.RadioSelect>
                            <S.RadioLabel>
                                No
                                <S.RadioInput
                                    id='publicPosition'
                                    type='radio'
                                    name='publicPosition'
                                    // @ts-ignore
                                    value={false}
                                    onChange={(event) => handleInputFileChange(event)}
                                    checked={publicPosition !== null && !publicPosition} />
                                <S.RadioCheck />
                            </S.RadioLabel>
                        </S.RadioSelect>
                        {errors.publicPosition !== "" && errors.publicPosition}
                    </S.RadioCont>
                </div>
            </div>
        </>
    );
};

export default ConvenienceTestFinanceInputs;
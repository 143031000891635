import { Separator } from 'office-ui-fabric-react/lib/Separator';
import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { DefaultButton, MessageBar, MessageBarType, Spinner, SpinnerSize } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from "react-router";


const PaymentAccountNoTitular = () =>
{
    const { data } = useParams<any>();

    return (
        <DocumentTitle title='IronIA - Titularidad de Cuenta bancaria'>
            <React.Fragment>
                <div className="ms-Grid-row p10">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12">
                        <div className="section-row">
                            <p>La cuenta utilizada no esta en el listado de cuentas autorizadas por favor añadala.</p>
                            <br />
                            <div className="ms-Grid-col ms-sm12 text-right">
                                <Link to={{
                                    pathname: "/profile/bank-accounts",
                                    state: {
                                        data
                                    }
                                }}>
                                    <DefaultButton className="button mini-primary-button">
                                        Añadir
                                    </DefaultButton>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default PaymentAccountNoTitular;
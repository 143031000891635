import { setupInterceptorsTo } from "./Interceptors";
import axios from "axios";
import { fundsService } from "../config";
setupInterceptorsTo(axios);

export interface IInfoFundPortfolio 
{
    allFundsId: number,
    available?: boolean,
    averageScore: number,
    benchmarkFinametrixId: number,
    isin: string,
    documents?: IDocuments[],
    managementFee?: number,
    minimunInitialInvestment?: number,
    minimunAdditionalInvestment?: number,
    noMin?: boolean,
    ongoingCharges?: number,
    rebate?: number,
    complexity?: boolean,
    finametrixId?: string
}
export interface IDocuments
{
    name: string,
    type: string,
    url: string
}
export interface TransferEstimationDates
{
    ventaValor: string,
    ventaLiquidacion: string,
    compraValor: string,
    compraLiquidacion: string,
    finalProceso: string,
}
export interface RefundEstimationDates
{
    ventaValor: string,
    ventaLiquidacion: string,
    finalProceso: string
}
export interface IFundCloseHours
{
    time: any
}
export interface IFund
{
    name: string,
    family: string,
    morningStarCategoryId: string,
    isin: string,
    allfundsId: string,
    asset: string,
    available: boolean,
    classCode: string,
    cleanShare: boolean,
    currency: string,
    finametrixId: string,
    allFundsId: number,
    manager: string,
    managementFee: number,
    performanceFee: number,
    minimumAdditionalInvestment: number,
    minimumInitialInvestment: number,
    noMin: boolean,
    averageScore: number,
    complexity: boolean,
    documents?: any
}
export type FundType = IFund;

export interface FundDocumentType {
    name: string,
    type: string,
    url: string
};

export interface IFundWithExtraData
{
    allFundsId: number,
    benchmark: string,
    benchmarkFinametrixId: string,
    finametrixId: string,
    name: string,
    available: boolean,
    switchable: boolean,
    isin: string,
    currency: string,
    currencyHedge: boolean,
    manager: string,
    ucits?: null,
    documents: FundDocumentType[],
    managementFee?: null,
    minimumInitialInvestment: number,
    minimumAdditionalInvestment: number,
    morningStarCategoryId: number,
    income: number,
    rebate: number,
    noMin: number,
    cleanSharecleanShare: number,
    liquidationDaysOnSellsliquidationDaysOnSells: number,
    liquidationDaysOnBuysliquidationDaysOnBuys: number,
    ongoingCharges: number,
    cleanShare: string,
    liquidationDaysOnSells: string,
    liquidationDaysOnBuys: string,
    category: string,
    subcategory: string,
    averageScore: number,
    performanceFee: string,
    complexity: boolean
}
export type FundWithExtraDataType = IFundWithExtraData;
interface IFunds
{
    results: IFund[],
    "total": 61,
    "page": 1,
    "count": 15
}
export type ListFunds = IFunds[]
export type SearchFundsMinType =
{
	name: string;
    isin: string;
    finametrixId: string;
    switchable: boolean;
};
export interface AvailableFundType
{
    name: string,
    isin: string,
    finametrixId: string,
    allfundsId: string,
    complexity?: boolean,
    noMin: boolean,
    minimumInitialInvestment: number,
    minimumAdditionalInvestment: number
}
export type TransferSimulationManagerInfo =
{
    manager: string;
    ratioKOOrigen?: number;
    ratioOKOrigen?: number;
    ratioKODestino?: number;
    ratioOKDestino?: number;
    totalOrigen?: number;
    totalDestino?: number;
    switchable?: boolean;
}
export type TransferSimulationManagerInfoRequestDto =
{
    isins: string[],
    externalManagerNames: string[]
}
export interface IRatioFunds
{
    valueProduct: number,
    valueBenchmark: number,
    period: string
}
export interface IRatioBothFunds
{
    firstFundValue: number,
    secondFundValue?: number,
    valueBenchmark: number,
    period: string
}
export interface IRatioFundChangeBenchmark
{
    fundValue: number,
    benchmark: number,
    period: string
}
export interface IRatioTwoFundsChangeBenchmark
{
    firstFundValue: number,
    secondFundValue?: number,
    benchmark: number,
    period: string
}

class FundService
{
    public getFund = async (isin: any) =>
    {
        if(isin !== undefined && isin !== null)
        {
            let url: string = `${fundsService}/fund/${isin}`;
            const req = await axios.get(url);
            return req.data;
        }
        else
        {
            return {};
        }
    }
    public getFundByFinametrixId = async(finametrixId: any) =>
    {
        if(finametrixId !== undefined && finametrixId !== null)
        {
            let url: string = `${fundsService}/FundByFinametrixId/${finametrixId}`;
            const req = await axios.get(url);
            return req.data;
        }
        else
        {
            return {};
        }
    }
    public getInfoFundsPortfolio = async(isins: string[]) =>
    {
        if(isins !== undefined && isins.length > 0)
        {
            let url: string = `${fundsService}/infoFundsPortfolio`;
            const req = await axios.get(url, {params: {isins: isins}});
            return req.data;
        }
    }
    public getMultipleFunds = async (isins: string[]) =>
    {
        if(isins !== undefined && isins.length > 0)
        {
            let url: string = `${fundsService}/MultipleFunds`;
            const req = await axios.get(url, {params: {isins: isins}});
            return req.data;
        }
        else
        {
            return [];
        }
    }
    public getMultipleFundsByIds = async (finametrix_ids: string[]) =>
    {
        if(finametrix_ids !== undefined && finametrix_ids.length > 0)
        {
            let url: string = `${fundsService}/multiple-funds-by-ids`;
            const req = await axios.get(url, {params: {ids: finametrix_ids}});
            return req.data;
        }
        else
        {
            return [];
        }
    }
    public getMultipleFundsByAllfundsIds = async (allfunds_ids: string[]) =>
    {
        if(allfunds_ids !== undefined && allfunds_ids.length > 0)
        {
            let url: string = `${fundsService}/multiple-funds-by-allfunds-ids`;
            const req = await axios.get(url, {params: {allfunds_ids: allfunds_ids}});
            return req.data;
        }
        else
        {
            return [];
        }
    }
    public getFundsWithScore = async(isins: string[]) =>
    {
        if(isins !== undefined && isins.length > 0)
        {
            let url: string = `${fundsService}/MultiFundsWithScore`;
            const req = await axios.get(url, {params: {isins: isins}});
            return req.data;
        }
        else
        {
            return [];
        }
    }
    public getFunds = async (page: number, count: number, term?: string, morningStarCategoryId?: string | undefined, available?: boolean | undefined, income?: boolean | undefined, switchable?: boolean | undefined, indexed?: boolean | undefined, manager?: string | undefined, currency?: string | undefined, classCategory?: boolean | undefined): Promise<any> =>
    {
        let url: string = `${fundsService}/funds?count=${count}&page=${page}`
        if(term !== undefined && term !== "")
        {
            url = url + `&term=${term}`;
        }
        if(available !== undefined)
        {
            url = url + `&available=${available}`;
        }
        if(switchable !== undefined)
        {
            url = url + `&switchable=${switchable}`;
        }
        if(indexed !== undefined)
        {
            url = url + `&indexed=${indexed}`;
        }
        if(classCategory !== undefined)
        {
            url = url + `&class_category=${classCategory}`;
        }
        if(income !== undefined)
        {
            url = url + `&income=${income}`;
        }
        if(manager !== undefined && manager !== "")
        {
            let parameter = "";
            if(Array.isArray(manager))
            {
                manager.forEach(item =>
                {
                    parameter += `&manager[]=${encodeURIComponent(item)}`;
                });
            }
            else
            {
                parameter = `&manager[]=${encodeURIComponent(manager)}`;
            }

            url = url + parameter;
        }
        if(currency !== undefined && currency !== "")
        {
            let parameter = "";
            if(Array.isArray(currency))
            {
                currency.forEach(item =>
                {
                    parameter += `&currency[]=${encodeURIComponent(item)}`;
                });
            }
            else
            {
                parameter = `&currency[]=${encodeURIComponent(currency)}`;
            }

            url = url + parameter;
        }
        if(morningStarCategoryId !== undefined && morningStarCategoryId !== "")
        {
            let parameter = "";
            if(Array.isArray(morningStarCategoryId))
            {
                morningStarCategoryId.forEach(item =>
                {
                    parameter += `&morningstar_category_id[]=${encodeURIComponent(item)}`;
                });
            }
            else
            {
                parameter = `&morningstar_category_id[]=${encodeURIComponent(morningStarCategoryId)}`;
            }

            url = url + parameter;
        }
        const req = await axios.get<ListFunds>(url);
        return req.data;
    }
    public getSearchFunds = async (term: string, limit?: number): Promise<any> =>
    {
        let url: string = `${fundsService}/buscadorIronIAFunds?term=${encodeURIComponent(term)}`;
        if(limit !== undefined)
        {
            url += `&limit=${limit}`;
        }
        const req = await axios.get(url);
        return req.data
    }
    public getAvailableFunds = async (term?: string): Promise<AvailableFundType[]> =>
    {
        let url: string = `${fundsService}/availableFunds?term=${encodeURIComponent(term || '')}`;
        const req = await axios.get(url);
        return req.data
    }
    public getManagers = async (): Promise<string[]> =>
    {
        let url: string = `${fundsService}/managers`;
        const req = await axios.get(url);
        return req.data;
    }
    public getCurrencies = async (): Promise<string[]> =>
    {
        let url: string = `${fundsService}/currencies`;
        const req = await axios.get(url);
        return req.data;
    }
    public getBenchmarks = async (): Promise<string[]> =>
    {
        let url: string = `${fundsService}/benchmarks`;
        const req = await axios.get(url);
        return req.data;
    }
    public getClosingHours = async(isins: any[]): Promise<string> =>
    {
        let url: string = `${fundsService}/GetManagersClosingTime`;
        const req = await axios.get(url, {params: {isins: isins}});
        return req.data;
    }
    public getFundEstimationDate = async(isin: string): Promise<string> =>
    {
        let url: string = `${fundsService}/GetFundEstimationDate/${isin}`;
        const req = await axios.get(url);
        let result = req.data.substr(0,10).split("-");
        return result[2]+"/"+result[1]+"/"+result[0];
    }
    public getTransferEstimationDates = async (isinOrigin: string, isinTarget: string, transferType: boolean): Promise<TransferEstimationDates> =>
    {
        let url: string = `${fundsService}/GetTransferEstimationDates/${isinOrigin}/${isinTarget}/${transferType}`;
        const req = await axios.get(url);
        let result: TransferEstimationDates = parseTransferDates(req.data);  
        return result;
    }
    public getRefundEstimationDates = async (isin: string): Promise<RefundEstimationDates> =>
    {
        let url: string = `${fundsService}/GetRefundEstimationDates/${isin}`;
        const req = await axios.get(url);
        let result: RefundEstimationDates = parseRefundDates(req.data);
        return result;
    }
    public getFundsNameByIsin = async (isins: string[]): Promise<SearchFundsMinType[]> =>
    {
        let url: string = `${fundsService}/GetFundsNameByIsin?isins=${isins}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getComplexityByIsin = async (isin: string): Promise<boolean> =>
    {
        let url: string = `${fundsService}/complexityByIsin/${isin}`;
        const req = await axios.get(url);
        return req.data;
    }
    public GetTransferSimulationManagerInfo = async (isins: string[], externalManagerNames?: string[]): Promise<TransferSimulationManagerInfo[]> =>
    {
        let url: string = `${fundsService}/GetTransferSimulationManagerInfo`;
        const body: TransferSimulationManagerInfoRequestDto =
        {
            isins: isins,
            externalManagerNames: externalManagerNames ?? []
        }
        const req = await axios.post(url, body);
        return req.data;
    }
    public getRatioFundSeries = async (allFundsId: number): Promise<IRatioFunds[]> =>
    {
        let url: string = `${fundsService}/RatioFundsProfitabilityFunction/${allFundsId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getRatioForBothFunds = async (firstAllFundsId: number, secondAllFundsId: number): Promise<IRatioBothFunds[]> =>
    {
        let url: string = `${fundsService}/RatioSecondFund/${firstAllFundsId}/${secondAllFundsId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getRatioChangeBenchmark = async (allFundsId: number, benchmark: string): Promise<IRatioFundChangeBenchmark[]> =>
    {
        let url: string = `${fundsService}/RatioFundChangeBenchmark/${allFundsId}/${benchmark}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getRatioBothFundsChangeBenchmark = async (firstAllFundsId: number, secondAllFundsId: number, benchmarkId: string): Promise<IRatioTwoFundsChangeBenchmark[]> =>
    {
        let url: string = `${fundsService}/RatioFundsChangeBenchmark/${firstAllFundsId}/${secondAllFundsId}/${benchmarkId}`;
        const req = await axios.get(url);
        return req.data;
    }
}

const funds = new FundService();

function parseTransferDates(transferDates: TransferEstimationDates)
{
    let result: TransferEstimationDates = {compraLiquidacion: "", compraValor: "", ventaLiquidacion: "", ventaValor: "", finalProceso: ""};

    let compraLiq = transferDates.compraLiquidacion.substring(0, 10).split("-");
    let compraVal = transferDates.compraValor.substring(0, 10).split("-");
    let ventaLiq = transferDates.ventaLiquidacion.substring(0, 10).split("-");
    let ventaVal = transferDates.ventaValor.substring(0, 10).split("-");
    let finalDate = transferDates.finalProceso.substring(0, 10).split("-");
    
    result.compraLiquidacion = compraLiq[2]+"/"+compraLiq[1]+"/"+compraLiq[0];
    result.compraValor = compraVal[2]+"/"+compraVal[1]+"/"+compraVal[0];
    result.ventaLiquidacion = ventaLiq[2]+"/"+ventaLiq[1]+"/"+ventaLiq[0];
    result.ventaValor = ventaVal[2]+"/"+ventaVal[1]+"/"+ventaVal[0];
    result.finalProceso = finalDate[2]+"/"+finalDate[1]+"/"+finalDate[0];

    return result;
}
function parseRefundDates(refundDates: RefundEstimationDates)
{
    let result: RefundEstimationDates = {ventaLiquidacion: "",ventaValor: "",finalProceso: ""};

    let ventaLiq = refundDates.ventaLiquidacion.substring(0, 10).split("-");
    let ventaVal = refundDates.ventaValor.substring(0, 10).split("-");
    let finalDate = refundDates.finalProceso.substring(0, 10).split("-");
    
    result.ventaLiquidacion = ventaLiq[2] + "/" + ventaLiq[1] + "/" + ventaLiq[0];
    result.ventaValor = ventaVal[2] + "/" + ventaVal[1] + "/" + ventaVal[0];
    result.finalProceso = finalDate[2] + "/" + finalDate[1] + "/" + finalDate[0];

    return result;
}

export default funds;
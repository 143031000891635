import React, { useState } from 'react';
import { Slider } from 'office-ui-fabric-react';
import { Text, DefaultButton, Separator } from '@fluentui/react';
import NumberFormat from "react-number-format";
import styled from 'styled-components';
import './PromotionedPage.sass';
import { registerIcons } from '@uifabric/styling';
import { Link, useHistory } from 'react-router-dom';
import { UsersSubscriptionType } from "../../services/UsersSubscriptionService";
import AppendCartLine from '../../utils/AppendCartLine';
import { useDispatch } from 'react-redux';
const debounce = require('lodash/debounce');

registerIcons(
{
    icons: { 'ironiaCancel': <i className="ironia-icon cross" />}
});

const StyledPromotionedPage = styled.div`
    p.description
    {
        width: 50%;
    }
    > .separator
    {
        margin-top: 32px;
        margin-bottom: 28px;
    }
    button
    {
        padding: 14px 38px;
        height: fit-content;
        margin-bottom: 16px;
        border-color: transparent;

        &.full-width
        {
            width: 100%;
        }

        &.is-disabled
        {
            background: #DFE3E3;
            box-shadow: none;
            color: #93AEB9;

            &.pending
            {
                background: #CC214F;
                color: white;
                opacity: 0.3;
            }
        }

        &.iconned
        {
            padding-right: 65px;
            .ironia-icon
            {
                font-weight: 100;
                right: 18px;

                &:after
                {
                    font-size: 14px;
                    line-height: 34px;
                }
            }
        }

        span
        {
            font-weight: 600;
        }
    } 
    h3
    {
        font-size: 24px;
        line-height: 32px;
        font-weight: 800;
    }
    .ms-Grid-row
    {
        display: flex;
        flex-flow: row wrap;

        .ms-Grid-col
        {
            display: flex;
            flex: 1 0 100px;
            flex-flow: column;

            .step
            {
                position: relative;
                padding: 32px 32px 100px 32px;
                border-radius: 24px;
                height: 100%;

                .step-content
                {
                    height: 100%;
                }

                .step-footer
                {
                    position: absolute;
                    bottom: 0;
                    width: calc(100% - 64px);
                    text-align: center;
                }
            }
        }
    }
    h4
    {
        font-size: 18px;
        line-height: 24px;
        font-weight: 800;
        margin-top: 0;
        margin-bottom: 8px;
    }
    .grey
    {
        padding: 24px;
        border-radius: 16px;
    }
    .ironia-icon
    {
        position: absolute;
        right: 8px;
        width: 32px;
        height: 32px;
        border-radius: 100px;

        &.check
        {
            background: #BEFBD6;
            color: #1BB3AD;
        }

        &:after
        {
            font-size: 14px;
            line-height: 32px;
        }
    }
    p
    {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        margin-top: 8px;
        margin-bottom: 24px;
    }
    .separator
    {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .ms-Checkbox,
    .ms-ChoiceField-field
    {
        display: inline-block;
        margin-right: 16px;
        margin-top: 3px;
    }
    .ms-Checkbox
    {
        .ms-Checkbox-checkbox
        {
            border-radius: 4px;
            width: 20px;
            height: 20px;
            box-sizing: border-box;
            border: 1.25px solid #93AEB9;
            background: #F3F7F9;
        }

        &.is-checked
        {
            .ms-Checkbox-checkbox
            {
                background: #CC214F;
                border: 1.25px solid #B31540;

                .ms-Checkbox-checkmark
                {
                    font-weight: 700;
                }
            }
        }
    }
    .ms-Slider
    {
        margin-bottom: 38px;

        .ms-Slider-container
        {
            position: relative;

            .ms-Slider-slideBox
            {
                position: absolute;
                right: 0;
                width: calc(100% - 70px);

                .ms-Slider-thumb
                {
                    width: 16px;
                    height: 8px;
                    background: #CC214F;
                    border: 0;
                    top: 2px;
                    margin-left: 10px;
                }

                .ms-Slider-inactive
                {
                    height: 12px;
                    background: #FAE9ED;
                    border-radius: 100;
                }
            }

            .ms-Label
            {
                position: absolute;
                color: #BFC6C7;
                font-weight: 800;
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
    .ms-ChoiceField-field
    {
        &:before
        {
            border: 2px solid #93AEB9;
            background: #FFFFFF;
            opacity: 0.5;
        }

        &.is-checked
        {
            &:after
            {
                background: #CC214F;
                width: 14px;
                height: 14px;
                border: 0;
                left: 3px;
                top: 3px;
            }
        }
    }
    .ms-Label
    {
        color: #556769;
    }
    .checkbox-data
    {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 42px);

        button.full-width
        {
            width: calc(100% - 45px);
        }
    }
    .ms-TextField-fieldGroup
    {
        border-radius: 0;
    }
    .step
    {
        &.disabled
        {
            opacity: 0.5;
        }
    }
`;

const Error = styled.div`
    margin-top: 1em;
    color: red;
`;

interface IStepsPromotioned
{
    isSavingPlanReady: boolean,
    hasPortfolio: boolean,
    savingPlan:
    {
        id: number,
        name: string,
        minAmount: number,
        fundName?: string,
        isin?: string,
        fundDescription?: string,
        portfolioName?: string,
        portfolioDescription?: string
    },
    fund: any,
    usersSubscription: UsersSubscriptionType
}

const StepsPromotioned: React.FC<IStepsPromotioned> = (props: IStepsPromotioned) =>
{
    const valueFormat = (value: number) => `${value}%`;
    const history = useHistory();
    const dispatch = useDispatch();
    const [amountError, setAmountError] = useState("");
    const [amountValue, setAmountValue] = useState(props.savingPlan.minAmount);

    const onChangeQuantity = (values: any) =>
    {
        const { floatValue } = values;

        if(props.savingPlan.minAmount > floatValue)
        {
            setAmountError("La cantidad ha de ser superior o igual a " + props.savingPlan.minAmount + "€.");
        }
        else
        {
            setAmountError("");
            setAmountValue(floatValue);
        }
    }

    const handleClick = async () =>
    {
        await AppendCartLine(
            dispatch,
            history,
            {
                fund: props.fund,
                amount: amountValue,
                readOnly: true,
                userSuscriptionId: props.usersSubscription?.id
            }
        );
        
        return history.push("/cart");
    };

    const getProgress = () =>
    {
        var progress = 0;

        if(props.isSavingPlanReady)
        {
            progress += 50;
        }

        return progress;
    };

    return(
        <React.Fragment>
            <StyledPromotionedPage className="shadow">
                {props.savingPlan.fundDescription !== undefined &&
                (
                    <div className="ms-Grid-col ms-sm12 ms-xl12 ms-lg12" style={{marginBottom: "2em"}}>
                        <h4>{props.savingPlan.fundDescription}</h4>
                    </div>
                )}
                <div className="ms-Grid-col ms-sm12">
                    <h3>Finaliza la promoción</h3>
                    <Slider max={100} value={getProgress()} valueFormat={valueFormat} disabled={true} />
                </div>
                <div className="ms-Grid-col ms-sm12">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md3">
                            <div className={"step "}>
                                <div className="step-content">
                                    <h4>Datos fiscales obligatorios</h4>
                                    <p>Antes de poder operar debes rellenar un breve test para confirmar ante la CNMV que sabes qué estás haciendo. Recuerda que debes tener tu DNI y al menos una nómina a mano.</p>
                                </div>
                                <div className="step-footer">
                                    {!props.isSavingPlanReady &&
                                    (
                                        <Link to="/configurationMap" onClick={(event) => event.preventDefault()}>
                                            <DefaultButton className="button primary-button" onClick={() => history.push(
                                                {
                                                    pathname: `/configurationMap`,
                                                    state:
                                                    {
                                                        savingPlanId: props.savingPlan.id,
                                                        savingPlanName: props.savingPlan.name
                                                    }
                                                })}>
                                                Cumplimentar datos
                                            </DefaultButton>
                                        </Link>
                                    )}
                                    {props.isSavingPlanReady &&
                                    (
                                        <DefaultButton className="button primary-button" disabled>
                                            <Text>Test realizado correctamente</Text>
                                            <i className="ironia-icon check"></i>
                                        </DefaultButton>
                                    )}
                                </div>
                            </div>
                            <Separator className="separator hiddenSmUp" />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md3">
                            <div className={props.isSavingPlanReady ? 'step' : 'step disabled'}>
                                <div className="step-content">
                                    <h4>Finaliza tu compra</h4>
                                    {props.savingPlan.fundName !== undefined && props.savingPlan.isin !== undefined &&
                                    (
                                        <React.Fragment>
                                            <div className="ms-Grid-col ms-sm12 ms-lg12">
                                                <p>{props.savingPlan.fundName} - {props.savingPlan.isin}</p>
                                            </div>
                                        </React.Fragment>
                                    )}
                                    <p>Indica qué importe vas a aportar inicialmente.</p>
                                    <div className="number-control">
                                        <label className="ms-Label">Importe (euros)</label>
                                        <NumberFormat thousandSeparator="." decimalSeparator=","
                                            // @ts-ignore
                                            label="¿Cuál es tu dinero base?"
                                            placeholder="Escribe una cantidad..."
                                            value={amountValue}
                                            min={props.savingPlan.minAmount}
                                            onValueChange={debounce((values: any) => onChangeQuantity(values), 500)}
                                            style={{marginLeft: "1em"}}
                                            suffix="€"
                                            className="ms-TextField-field"
                                        />
                                        {amountError !== undefined && <Error>{amountError}</Error>}
                                    </div>
                                </div>
                                <div className="step-footer text-right">
                                    {props.isSavingPlanReady === true && props.hasPortfolio === true &&
                                    (
                                        <DefaultButton className="button primary-button" onClick={handleClick}>
                                            Activar
                                        </DefaultButton>
                                    )}
                                    {props.isSavingPlanReady === true && props.hasPortfolio === false &&
                                    (
                                        <DefaultButton className="button primary-button" disabled={true} onClick={handleClick}>
                                            Pendiente de Confirmación
                                        </DefaultButton>
                                    )}
                                    {props.isSavingPlanReady === false && props.hasPortfolio === false &&
                                    (
                                        <DefaultButton className="button primary-button" disabled={true} onClick={handleClick}>
                                            Esperando resultados de Test
                                        </DefaultButton>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledPromotionedPage>
        </React.Fragment>
    );
}

export default StepsPromotioned;
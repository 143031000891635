const subcategories = [
    {
        category: 'Activo Monetario',
        name: 'Mercado Monetario Corto Plazo EUR',
        benchmarkFinametrixId: '5ef5b2aa5abfac59ebf195a7',
        benchmark: 'EUROPEAN CENTRAL BANK EONIA INDEX 7 DAY',
        morningStarCategoryId: 'EUCA000830',
    },
    {
        category: 'Activo Monetario',
        name: 'Mercado Monetario Corto Plazo GBP',
        benchmarkFinametrixId: '5f60e1e8bb5f1ece0cdf5bff',
        benchmark: 'FTSE 3-Month UK Sterling Eurodeposit LCL',
        morningStarCategoryId: 'EUCA000831',
    },
    {
        category: 'Activo Monetario',
        name: 'Mercado Monetario Corto Plazo USD',
        benchmarkFinametrixId: '5f60e1050e4901860189f4f9',
        benchmark: 'BB U.S. Tr Bills: 1-3 Months TR Index Value Unhedged',
        morningStarCategoryId: 'EUCA000832',
    },
    {
        category: 'Activo Monetario',
        name: 'Mercado Monetario EUR',
        benchmarkFinametrixId: '5ef5b2aa5abfac59ebf195a7',
        benchmark: 'EUROPEAN CENTRAL BANK EONIA INDEX 7 DAY',
        morningStarCategoryId: 'EUCA000591',
    },
    {
        category: 'Activo Monetario',
        name: 'Mercado Monetario GBP',
        benchmarkFinametrixId: '5f60e1e8bb5f1ece0cdf5bff',
        benchmark: 'FTSE 3-Month UK Sterling Eurodeposit LCL',
        morningStarCategoryId: 'EUCA000608',
    },
    {
        category: 'Activo Monetario',
        name: 'Mercado Monetario USD',
        benchmarkFinametrixId: '5f60e1050e4901860189f4f9',
        benchmark: 'BB U.S. Tr Bills: 1-3 Months TR Index Value Unhedged',
        morningStarCategoryId: 'EUCA000584',
    },
    {
        category: 'Activo Monetario',
        name: 'RF Ultra Corto Plazo EUR',
        benchmarkFinametrixId: '5ef5b2aa5abfac59ebf195a7',
        benchmark: 'EUROPEAN CENTRAL BANK EONIA INDEX 7 DAY',
        morningStarCategoryId: 'EUCA000592',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Arbitraje Valor-Relativo',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000798',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Crédito Long/Short',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000805',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Divisas',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000797',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Event Driven',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000800',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Futuros Gestionados',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000813',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Global Macro',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000804',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Long/Short RV Europa',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000807',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Long/Short RV Global',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000808',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Long/Short RV Otros',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000880',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Long/Short RV RU',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000809',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Long/Short RV USA',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000810',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Market Neutral - RV',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000811',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Multiestrategia',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000812',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Otros',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000881',
    },
    {
        category: 'Alternativos',
        name: 'Alt - Volatilidad',
        benchmarkFinametrixId: '5887a12ec11e3870353bca5e',
        benchmark: 'Hedge Fund Research HFRX Global Hedge Fund Index',
        morningStarCategoryId: 'EUCA000814',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Agresivos CHF',
        benchmarkFinametrixId: '5c4f2b3e5a1a3f2383f3f54c',
        benchmark: 'AGRESIVO',
        morningStarCategoryId: 'EUCA000618',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Agresivos EUR',
        benchmarkFinametrixId: '5c4f2b3e5a1a3f2383f3f54c',
        benchmark: 'AGRESIVO',
        morningStarCategoryId: 'EUCA000862',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Agresivos EUR - Global',
        benchmarkFinametrixId: '5c4f2b3e5a1a3f2383f3f54c',
        benchmark: 'AGRESIVO',
        morningStarCategoryId: 'EUCA000563',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Agresivos GBP',
        benchmarkFinametrixId: '5c4f2b3e5a1a3f2383f3f54c',
        benchmark: 'AGRESIVO',
        morningStarCategoryId: 'EUCA000620',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Agresivos SEK',
        benchmarkFinametrixId: '5c4f2b3e5a1a3f2383f3f54c',
        benchmark: 'AGRESIVO',
        morningStarCategoryId: 'EUCA000616',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Agresivos USD',
        benchmarkFinametrixId: '5c4f2b3e5a1a3f2383f3f54c',
        benchmark: 'AGRESIVO',
        morningStarCategoryId: 'EUCA000693',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Asia',
        benchmarkFinametrixId: '5c4f2b3e5a1a3f2383f3f54c',
        benchmark: 'AGRESIVO',
        morningStarCategoryId: 'EUCA000559',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Defensivos CHF',
        benchmarkFinametrixId: '5c4f2aa85a1a3f2383f3f53c',
        benchmark: 'MODERADO',
        morningStarCategoryId: 'EUCA000619',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Defensivos EUR',
        benchmarkFinametrixId: '5c4f2aa85a1a3f2383f3f53c',
        benchmark: 'MODERADO',
        morningStarCategoryId: 'EUCA000863',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Defensivos EUR - Global',
        benchmarkFinametrixId: '5c4f2aa85a1a3f2383f3f53c',
        benchmark: 'MODERADO',
        morningStarCategoryId: 'EUCA000564',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Defensivos GBP',
        benchmarkFinametrixId: '5c4f2aa85a1a3f2383f3f53c',
        benchmark: 'MODERADO',
        morningStarCategoryId: 'EUCA000621',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Defensivos SEK',
        benchmarkFinametrixId: '5c4f2aa85a1a3f2383f3f53c',
        benchmark: 'MODERADO',
        morningStarCategoryId: 'EUCA000617',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Defensivos USD',
        benchmarkFinametrixId: '5c4f2aa85a1a3f2383f3f53c',
        benchmark: 'MODERADO',
        morningStarCategoryId: 'EUCA000695',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Flexibles EUR',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000864',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Flexibles EUR - Global',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000739',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Flexibles GBP',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000740',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Flexibles SEK',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000745',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Flexibles USD',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000746',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Global Emergentes',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000741',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Gran China',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000919',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Moderadamente Agresivos GBP',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000916',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Moderadamente Defensivos GBP',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000917',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Moderados CHF',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000738',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Moderados EUR',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000865',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Moderados EUR - Global',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000565',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Moderados GBP',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000796',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Moderados NOK',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000566',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Moderados SEK',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000898',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Moderados USD',
        benchmarkFinametrixId: '5c4f24e25a1a3f2383f3f51c',
        benchmark: 'EQUILIBRADO',
        morningStarCategoryId: 'EUCA000694',
    },
    {
        category: 'Mixtos',
        name: 'Mixtos Otros',
        benchmarkFinametrixId: '5e80996bbb592c4d830f3cd3',
        benchmark: 'MSCI ACWI Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000743',
    },
    {
        category: 'Renta Fija',
        name: 'EUR Deuda Subordinada',
        benchmarkFinametrixId: '5f60e56a36ade5cdfeac1752',
        benchmark: 'Markit iBoxx EUR Liquid High Yield Subordinated TRI',
        morningStarCategoryId: 'EUCA000922',
    },
    {
        category: 'Renta Fija',
        name: 'RF Asia',
        benchmarkFinametrixId: '5e810428ee0c714d9dbf8626',
        benchmark: 'Bloomberg Barclays EM Asia USD Credit Total Return Index Value Unhedged USD',
        morningStarCategoryId: 'EUCA000570',
    },
    {
        category: 'Renta Fija',
        name: 'RF Asia - Moneda Local',
        benchmarkFinametrixId: '5e810428ee0c714d9dbf8626',
        benchmark: 'Bloomberg Barclays EM Asia USD Credit Total Return Index Value Unhedged USD',
        morningStarCategoryId: 'EUCA000897',
    },
    {
        category: 'Renta Fija',
        name: 'RF Bonos Alto Rendimiento EUR',
        benchmarkFinametrixId: '5e810403ee0c714d9dbf8624',
        benchmark: 'Bloomberg Barclays Pan-European High Yield Total Return Index Value U',
        morningStarCategoryId: 'EUCA000590',
    },
    {
        category: 'Renta Fija',
        name: 'RF Bonos Alto Rendimiento Global',
        benchmarkFinametrixId: '593817a715c9967ff557705a',
        benchmark: 'Bloomberg Barclays Global High Yield Total Return Index Value Unhedge',
        morningStarCategoryId: 'EUCA000766',
    },
    {
        category: 'Renta Fija',
        name: 'RF Bonos Alto Rendimiento Global - EUR Cubierto',
        benchmarkFinametrixId: '593817a715c9967ff557705a',
        benchmark: 'Bloomberg Barclays Global High Yield Total Return Index Value Unhedge',
        morningStarCategoryId: 'EUCA000854',
    },
    {
        category: 'Renta Fija',
        name: 'RF Bonos Alto Rendimiento Global - GBP Cubierto',
        benchmarkFinametrixId: '593817a715c9967ff557705a',
        benchmark: 'Bloomberg Barclays Global High Yield Total Return Index Value Unhedge',
        morningStarCategoryId: 'EUCA000855',
    },
    {
        category: 'Renta Fija',
        name: 'RF Bonos Alto Rendimiento USD',
        benchmarkFinametrixId: '5e80ffd66de77f531ae1a6b9',
        benchmark: 'Bloomberg Barclays US Corporate High Yield Total Return Index Value Unhedged USD',
        morningStarCategoryId: 'EUCA000583',
    },
    {
        category: 'Renta Fija',
        name: 'RF Bonos Ligados Inflación EUR',
        benchmarkFinametrixId: '6001ca937826282dd172a7df',
        benchmark: 'BBarclays Euro Govt Infl Linked Bond TR Index',
        morningStarCategoryId: 'EUCA000625',
    },
    {
        category: 'Renta Fija',
        name: 'RF Convertibles - Otros',
        benchmarkFinametrixId: '5e80ff8dbb592c4d830f3cdf',
        benchmark: 'Bloomberg Barclays Global Convertibles Composite Total Return Unhedged USD',
        morningStarCategoryId: 'EUCA000751',
    },
    {
        category: 'Renta Fija',
        name: 'RF Convertibles Europa',
        benchmarkFinametrixId: '5e80ffb46de77f531ae1a6b7',
        benchmark: 'Bloomberg Barclays EMEA Convertibles Europe Total Return Hedged EUR',
        morningStarCategoryId: 'EUCA000748',
    },
    {
        category: 'Renta Fija',
        name: 'RF Convertibles Global',
        benchmarkFinametrixId: '5e80ff8dbb592c4d830f3cdf',
        benchmark: 'Bloomberg Barclays Global Convertibles Composite Total Return Unhedged USD',
        morningStarCategoryId: 'EUCA000749',
    },
    {
        category: 'Renta Fija',
        name: 'RF Convertibles Global - EUR Cubierto',
        benchmarkFinametrixId: '5e80ff667755e65314e0de00',
        benchmark: 'Bloomberg Barclays Global Convertibles EUR Total Return Hedged EUR',
        morningStarCategoryId: 'EUCA000750',
    },
    {
        category: 'Renta Fija',
        name: 'RF Convertibles Global - USD Cubierto',
        benchmarkFinametrixId: '5e80eac76de77f531ae1a6b5',
        benchmark: 'Bloomberg Barclays Global Convertibles: Composite Total Return Index Hedged USD',
        morningStarCategoryId: 'EUCA000823',
    },
    {
        category: 'Renta Fija',
        name: 'RF Deuda Corporativa Corto Plazo EUR',
        benchmarkFinametrixId: '6037d7d035aa4fc254859178',
        benchmark: 'BBarclays Euro Aggregate Corporate 1-3 Year',
        morningStarCategoryId: 'EUCA000819',
    },
    {
        category: 'Renta Fija',
        name: 'RF Deuda Corporativa EUR',
        benchmarkFinametrixId: '5c41ef3d62d151772de0801b',
        benchmark: 'Bloomberg Barclays Euro Aggregate Corporate Total Return Index Value Unhedged EU',
        morningStarCategoryId: 'EUCA000690',
    },
    {
        category: 'Renta Fija',
        name: 'RF Deuda Corporativa Global',
        benchmarkFinametrixId: '5c41f0fd62d151772de08029',
        benchmark: 'Bloomberg Barclays Global Agg Corporate Total Return Index Value Unhedged USD',
        morningStarCategoryId: 'EUCA000889',
    },
    {
        category: 'Renta Fija',
        name: 'RF Deuda Corporativa Global - EUR Cubierto',
        benchmarkFinametrixId: '5e80eaab6de77f531ae1a6b3',
        benchmark: 'Bloomberg Barclays Global Aggregate Corporate Total Return Index   Hedged EUR',
        morningStarCategoryId: 'EUCA000891',
    },
    {
        category: 'Renta Fija',
        name: 'RF Deuda Corporativa Global - USD Cubierto',
        benchmarkFinametrixId: '5e80ea8b6de77f531ae1a6b1',
        benchmark: 'Bloomberg Barclays Global Aggregate Corporate Total Return Index   Hedged USD',
        morningStarCategoryId: 'EUCA000893',
    },
    {
        category: 'Renta Fija',
        name: 'RF Deuda Corporativa Global Emergente',
        benchmarkFinametrixId: '5e80e6639ef6b65332cf887c',
        benchmark: 'J.P. Morgan EMBI Global Diversified Composite',
        morningStarCategoryId: 'EUCA000878',
    },
    {
        category: 'Renta Fija',
        name: 'RF Deuda Corporativa USD',
        benchmarkFinametrixId: '5f60ae1f36ade5cdfeac174b',
        benchmark: 'BB US Corporate TR Value Unhedged USD',
        morningStarCategoryId: 'EUCA000691',
    },
    {
        category: 'Renta Fija',
        name: 'RF Deuda Pública EUR',
        benchmarkFinametrixId: '5f60ad7736ade5cdfeac1749',
        benchmark: 'BB EuroAgg Treasury TR Index Value Unhedged EUR',
        morningStarCategoryId: 'EUCA000589',
    },
    {
        category: 'Renta Fija',
        name: 'RF Deuda Pública USD',
        benchmarkFinametrixId: '5f60ac9ebb5f1ece0cdf5bfb',
        benchmark: 'BB US Agency Intermediate TR Unhedged USD',
        morningStarCategoryId: 'EUCA000582',
    },
    {
        category: 'Renta Fija',
        name: 'RF Diversificada Corto Plazo EUR',
        benchmarkFinametrixId: '5f60abeea2871d8612946896',
        benchmark: 'BB EuroAgg 1-3 Year TR Index Value Unhedged EUR',
        morningStarCategoryId: 'EUCA000593',
    },
    {
        category: 'Renta Fija',
        name: 'RF Diversificada Corto Plazo USD',
        benchmarkFinametrixId: '5f60ab1036ade5cdfeac1745',
        benchmark: 'BB US Agg 1-3 Year TR Value Unhedged USD',
        morningStarCategoryId: 'EUCA000585',
    },
    {
        category: 'Renta Fija',
        name: 'RF Diversificada EUR',
        benchmarkFinametrixId: '5addb770ff442d74888305e1',
        benchmark: 'Bloomberg Barclays Global-Aggregate Total Return Index Value Hedged EUR',
        morningStarCategoryId: 'EUCA000587',
    },
    {
        category: 'Renta Fija',
        name: 'RF Diversificada USD',
        benchmarkFinametrixId: '5e80ea686de77f531ae1a6af',
        benchmark: 'Bloomberg Barclays Global-Aggregate Total Return Index Value Unhedged USD',
        morningStarCategoryId: 'EUCA000580',
    },
    {
        category: 'Renta Fija',
        name: 'RF Flexible EUR',
        benchmarkFinametrixId: '5f5f84040e4901860189f4e7',
        benchmark: 'BB EuroAgg Total Return Index Value Unhedged EUR',
        morningStarCategoryId: 'EUCA000754',
    },
    {
        category: 'Renta Fija',
        name: 'RF Flexible Global',
        benchmarkFinametrixId: '5e80ea686de77f531ae1a6af',
        benchmark: 'Bloomberg Barclays Global-Aggregate Total Return Index Value Unhedged USD',
        morningStarCategoryId: 'EUCA000899',
    },
    {
        category: 'Renta Fija',
        name: 'RF Flexible Global-EUR Cubierto',
        benchmarkFinametrixId: '5addb770ff442d74888305e1',
        benchmark: 'Bloomberg Barclays Global-Aggregate Total Return Index Value Hedged EUR',
        morningStarCategoryId: 'EUCA000901',
    },
    {
        category: 'Renta Fija',
        name: 'RF Flexible Global-USD Cubierto',
        benchmarkFinametrixId: '5e80e68eee0c714d9dbf8622',
        benchmark: 'Bloomberg Barclays Global-Aggregate Total Return Index Value Hedged USD',
        morningStarCategoryId: 'EUCA000902',
    },
    {
        category: 'Renta Fija',
        name: 'RF Flexible USD',
        benchmarkFinametrixId: '5e80ea686de77f531ae1a6af',
        benchmark: 'Bloomberg Barclays Global-Aggregate Total Return Index Value Unhedged USD',
        morningStarCategoryId: 'EUCA000775',
    },
    {
        category: 'Renta Fija',
        name: 'RF Global',
        benchmarkFinametrixId: '5e80ea686de77f531ae1a6af',
        benchmark: 'Bloomberg Barclays Global-Aggregate Total Return Index Value Unhedged USD',
        morningStarCategoryId: 'EUCA000759',
    },
    {
        category: 'Renta Fija',
        name: 'RF Global - EUR Cubierto',
        benchmarkFinametrixId: '5addb770ff442d74888305e1',
        benchmark: 'Bloomberg Barclays Global-Aggregate Total Return Index Value Hedged EUR',
        morningStarCategoryId: 'EUCA000624',
    },
    {
        category: 'Renta Fija',
        name: 'RF Global - USD Cubierto',
        benchmarkFinametrixId: '5e80e68eee0c714d9dbf8622',
        benchmark: 'Bloomberg Barclays Global-Aggregate Total Return Index Value Hedged USD',
        morningStarCategoryId: 'EUCA000763',
    },
    {
        category: 'Renta Fija',
        name: 'RF Global Bonos Ligados Inflación',
        benchmarkFinametrixId: '6026669c82aeb9d058d4b7a6',
        benchmark: 'BBarclays Global Inflation Linked TR USD',
        morningStarCategoryId: 'EUCA000858',
    },
    {
        category: 'Renta Fija',
        name: 'RF Global Bonos Ligados Inflación - EUR Cubierto',
        benchmarkFinametrixId: '6001c90266c43c2dbd85c5b7',
        benchmark: 'BBarclays Global Inflation Linked TR Hedged EUR',
        morningStarCategoryId: 'EUCA000859',
    },
    {
        category: 'Renta Fija',
        name: 'RF Global Emergente',
        benchmarkFinametrixId: '5e80e6639ef6b65332cf887c',
        benchmark: 'J.P. Morgan EMBI Global Diversified Composite',
        morningStarCategoryId: 'EUCA000586',
    },
    {
        category: 'Renta Fija',
        name: 'RF Global Emergente - Moneda Local',
        benchmarkFinametrixId: '5e80e63a9ef6b65332cf887a',
        benchmark: 'Bloomberg Barclays EM Local Currency Government TR Index Unhedged USD',
        morningStarCategoryId: 'EUCA000765',
    },
    {
        category: 'Renta Variable',
        name: 'Inmobiliario - Indirecto Global',
        benchmarkFinametrixId: '5f60e4cabb5f1ece0cdf5c05',
        benchmark: 'FTSE EPRA/NAREIT Global Index TR USD',
        morningStarCategoryId: 'EUCA000541',
    },
    {
        category: 'Renta Variable',
        name: 'Materias Primas - Diversificado',
        benchmarkFinametrixId: '5f60e40a36ade5cdfeac1750',
        benchmark: 'Bloomberg Commodity Index Total Return',
        morningStarCategoryId: 'EUCA000785',
    },
    {
        category: 'Renta Variable',
        name: 'RV Alemania Cap. Grande',
        benchmarkFinametrixId: '5e80e5fbc6805b4d89f16875',
        benchmark: 'DAX',
        morningStarCategoryId: 'EUCA000638',
    },
    {
        category: 'Renta Variable',
        name: 'RV Alemania Cap. Peq/Mediana',
        benchmarkFinametrixId: '5e80e5fbc6805b4d89f16875',
        benchmark: 'DAX',
        morningStarCategoryId: 'EUCA000639',
    },
    {
        category: 'Renta Variable',
        name: 'RV Asia',
        benchmarkFinametrixId: '5f5f8309a2871d8612946887',
        benchmark: 'MSCI AC ASEAN USD Net',
        morningStarCategoryId: 'EUCA000669',
    },
    {
        category: 'Renta Variable',
        name: 'RV Asia (ex-Japón)',
        benchmarkFinametrixId: '5e80b662bb592c4d830f3cdd',
        benchmark: 'MSCI AC Asia ex Japan Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000779',
    },
    {
        category: 'Renta Variable',
        name: 'RV Asia (ex-Japón) Cap. Peq/Mediana',
        benchmarkFinametrixId: '5e80b662bb592c4d830f3cdd',
        benchmark: 'MSCI AC Asia ex Japan Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000907',
    },
    {
        category: 'Renta Variable',
        name: 'RV Asia Pacífico (ex-Japón)',
        benchmarkFinametrixId: '5e80b662bb592c4d830f3cdd',
        benchmark: 'MSCI AC Asia ex Japan Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000501',
    },
    {
        category: 'Renta Variable',
        name: 'RV Asia Pacífico (ex-Japón) Alto Dividendo',
        benchmarkFinametrixId: '5e80b662bb592c4d830f3cdd',
        benchmark: 'MSCI AC Asia ex Japan Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000918',
    },
    {
        category: 'Renta Variable',
        name: 'RV Asia Pacífico (incl. Japón)',
        benchmarkFinametrixId: '5f5f82730e4901860189f4e3',
        benchmark: 'MSCI AC Asia Pacific Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000502',
    },
    {
        category: 'Renta Variable',
        name: 'RV BRIC',
        benchmarkFinametrixId: '5f5f80f4a2871d8612946882',
        benchmark: 'MSCI BRIC NETR USD Index',
        morningStarCategoryId: 'EUCA000700',
    },
    {
        category: 'Renta Variable',
        name: 'RV Brasil',
        benchmarkFinametrixId: '5f5f81bba2871d8612946884',
        benchmark: 'MSCI Brazil Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000699',
    },
    {
        category: 'Renta Variable',
        name: 'RV China',
        benchmarkFinametrixId: '5e80aea99ef6b65332cf8878',
        benchmark: 'MSCI China Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000504',
    },
    {
        category: 'Renta Variable',
        name: 'RV China - A Shares',
        benchmarkFinametrixId: '5e80aea99ef6b65332cf8878',
        benchmark: 'MSCI China Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000896',
    },
    {
        category: 'Renta Variable',
        name: 'RV Corea',
        benchmarkFinametrixId: '5f5f805aa2871d861294687f',
        benchmark: 'MSCI Korea Net USD Index',
        morningStarCategoryId: 'EUCA000523',
    },
    {
        category: 'Renta Variable',
        name: 'RV España',
        benchmarkFinametrixId: '58878f27c11e3870353bca03',
        benchmark: 'Ibex 35',
        morningStarCategoryId: 'EUCA000643',
    },
    {
        category: 'Renta Variable',
        name: 'RV Europa Alto Dividendo',
        benchmarkFinametrixId: '5e80b608bb592c4d830f3cd9',
        benchmark: 'MSCI Europe Net Total Return',
        morningStarCategoryId: 'EUCA000875',
    },
    {
        category: 'Renta Variable',
        name: 'RV Europa Cap. Flexible',
        benchmarkFinametrixId: '5e80b608bb592c4d830f3cd9',
        benchmark: 'MSCI Europe Net Total Return',
        morningStarCategoryId: 'EUCA000514',
    },
    {
        category: 'Renta Variable',
        name: 'RV Europa Cap. Grande Blend',
        benchmarkFinametrixId: '5e80b608bb592c4d830f3cd9',
        benchmark: 'MSCI Europe Net Total Return',
        morningStarCategoryId: 'EUCA000511',
    },
    {
        category: 'Renta Variable',
        name: 'RV Europa Cap. Grande Growth',
        benchmarkFinametrixId: '5e80b6476de77f531ae1a6ad',
        benchmark: 'MSCI Europe Growth Net Total Return EUR Index',
        morningStarCategoryId: 'EUCA000512',
    },
    {
        category: 'Renta Variable',
        name: 'RV Europa Cap. Grande Value',
        benchmarkFinametrixId: '5e80b628bb592c4d830f3cdb',
        benchmark: 'MSCI Europe Value Net Total Return EUR Index',
        morningStarCategoryId: 'EUCA000513',
    },
    {
        category: 'Renta Variable',
        name: 'RV Europa Cap. Mediana',
        benchmarkFinametrixId: '5e80b608bb592c4d830f3cd9',
        benchmark: 'MSCI Europe Net Total Return',
        morningStarCategoryId: 'EUCA000850',
    },
    {
        category: 'Renta Variable',
        name: 'RV Europa Cap. Pequeña',
        benchmarkFinametrixId: '5e80b27bbb592c4d830f3cd7',
        benchmark: 'MSCI Europe Small Cap Net TR EUR',
        morningStarCategoryId: 'EUCA000515',
    },
    {
        category: 'Renta Variable',
        name: 'RV Europa Emergente',
        benchmarkFinametrixId: '5f5f7f9ba2871d861294687d',
        benchmark: 'MSCI Emerging Markets Europe Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000508',
    },
    {
        category: 'Renta Variable',
        name: 'RV Europa ex-RU Cap. Grande',
        benchmarkFinametrixId: '5e80b262ee0c714d9dbf8620',
        benchmark: 'MSCI Europe ex UK Net Return EUR Index',
        morningStarCategoryId: 'EUCA000509',
    },
    {
        category: 'Renta Variable',
        name: 'RV Europa ex-RU Cap. Med/Peq',
        benchmarkFinametrixId: '5e80b262ee0c714d9dbf8620',
        benchmark: 'MSCI Europe ex UK Net Return EUR Index',
        morningStarCategoryId: 'EUCA000510',
    },
    {
        category: 'Renta Variable',
        name: 'RV Francia Cap. Grande',
        benchmarkFinametrixId: '5e80b247c6805b4d89f16873',
        benchmark: 'CAC 40 Net Total Return Index',
        morningStarCategoryId: 'EUCA000636',
    },
    {
        category: 'Renta Variable',
        name: 'RV Francia Cap. Peq/Mediana',
        benchmarkFinametrixId: '5e80b247c6805b4d89f16873',
        benchmark: 'CAC 40 Net Total Return Index',
        morningStarCategoryId: 'EUCA000637',
    },
    {
        category: 'Renta Variable',
        name: 'RV Global Alto Dividendo',
        benchmarkFinametrixId: '5e80996bbb592c4d830f3cd3',
        benchmark: 'MSCI ACWI Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000876',
    },
    {
        category: 'Renta Variable',
        name: 'RV Global Cap. Flexible',
        benchmarkFinametrixId: '5e80996bbb592c4d830f3cd3',
        benchmark: 'MSCI ACWI Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000558',
    },
    {
        category: 'Renta Variable',
        name: 'RV Global Cap. Grande Blend',
        benchmarkFinametrixId: '5e80996bbb592c4d830f3cd3',
        benchmark: 'MSCI ACWI Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000555',
    },
    {
        category: 'Renta Variable',
        name: 'RV Global Cap. Grande Growth',
        benchmarkFinametrixId: '5e80b226c6805b4d89f16871',
        benchmark: 'MSCI ACWI Growth Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000556',
    },
    {
        category: 'Renta Variable',
        name: 'RV Global Cap. Grande Value',
        benchmarkFinametrixId: '5e80af077755e65314e0ddfe',
        benchmark: 'MSCI ACWI Value Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000557',
    },
    {
        category: 'Renta Variable',
        name: 'RV Global Cap. Pequeña',
        benchmarkFinametrixId: '5e80aeebc6805b4d89f1686f',
        benchmark: 'MSCI ACWI Small Cap Net Return USD Index',
        morningStarCategoryId: 'EUCA000836',
    },
    {
        category: 'Renta Variable',
        name: 'RV Global Emergente',
        benchmarkFinametrixId: '5e80aecac6805b4d89f1686d',
        benchmark: 'MSCI Emerging Markets Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000507',
    },
    {
        category: 'Renta Variable',
        name: 'RV Global Emergente Cap. Peq/Med',
        benchmarkFinametrixId: '5f5f7e2cbb5f1ece0cdf5bdf',
        benchmark: 'MSCI Emerging Markets SMID Cap Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000910',
    },
    {
        category: 'Renta Variable',
        name: 'RV Global Mercados Frontera',
        benchmarkFinametrixId: '5f5f7d79bb5f1ece0cdf5bdd',
        benchmark: 'MSCI Frontier Markets Net Return USD Index',
        morningStarCategoryId: 'EUCA000845',
    },
    {
        category: 'Renta Variable',
        name: 'RV Gran China',
        benchmarkFinametrixId: '5e80aea99ef6b65332cf8878',
        benchmark: 'MSCI China Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000500',
    },
    {
        category: 'Renta Variable',
        name: 'RV India',
        benchmarkFinametrixId: '5e80aa439ef6b65332cf8876',
        benchmark: 'MSCI India Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000520',
    },
    {
        category: 'Renta Variable',
        name: 'RV Italia',
        benchmarkFinametrixId: '5e80aa1aee0c714d9dbf861e',
        benchmark: 'FTSE MIB Net Total Return Index',
        morningStarCategoryId: 'EUCA000640',
    },
    {
        category: 'Renta Variable',
        name: 'RV Japón Cap. Flexible',
        benchmarkFinametrixId: '5e80a9fcee0c714d9dbf861c',
        benchmark: 'TOPIX Net Total Return',
        morningStarCategoryId: 'EUCA000915',
    },
    {
        category: 'Renta Variable',
        name: 'RV Japón Cap. Grande',
        benchmarkFinametrixId: '5e80a9fcee0c714d9dbf861c',
        benchmark: 'TOPIX Net Total Return',
        morningStarCategoryId: 'EUCA000521',
    },
    {
        category: 'Renta Variable',
        name: 'RV Japón Cap. Med/Peq',
        benchmarkFinametrixId: '5e80a9d69ef6b65332cf8874',
        benchmark: 'TOPIX Small Index',
        morningStarCategoryId: 'EUCA000522',
    },
    {
        category: 'Renta Variable',
        name: 'RV Latinoamérica',
        benchmarkFinametrixId: '5e809c91c6805b4d89f1686b',
        benchmark: 'MSCI EM Latin America Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000524',
    },
    {
        category: 'Renta Variable',
        name: 'RV Nórdica',
        benchmarkFinametrixId: '5fae7a5b1b05ed35c34f41aa',
        benchmark: 'MSCI NORDIC COUNTRIES NR EUR',
        morningStarCategoryId: 'EUCA000525',
    },
    {
        category: 'Renta Variable',
        name: 'RV Nórdica Cap. Med/Peq',
        benchmarkFinametrixId: '5fae7a5b1b05ed35c34f41aa',
        benchmark: 'MSCI NORDIC COUNTRIES NR EUR',
        morningStarCategoryId: 'EUCA000920',
    },
    {
        category: 'Renta Variable',
        name: 'RV Otros',
        benchmarkFinametrixId: '5e80996bbb592c4d830f3cd3',
        benchmark: 'MSCI ACWI Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000539',
    },
    {
        category: 'Renta Variable',
        name: 'RV RU Alto Dividendo',
        benchmarkFinametrixId: '5e809c4dbb592c4d830f3cd5',
        benchmark: 'FTSE 100 Net Tax Index',
        morningStarCategoryId: 'EUCA000846',
    },
    {
        category: 'Renta Variable',
        name: 'RV RU Cap. Flexible',
        benchmarkFinametrixId: '5e809c4dbb592c4d830f3cd5',
        benchmark: 'FTSE 100 Net Tax Index',
        morningStarCategoryId: 'EUCA000553',
    },
    {
        category: 'Renta Variable',
        name: 'RV RU Cap. Grande',
        benchmarkFinametrixId: '5e809c4dbb592c4d830f3cd5',
        benchmark: 'FTSE 100 Net Tax Index',
        morningStarCategoryId: 'EUCA000550',
    },
    {
        category: 'Renta Variable',
        name: 'RV RU Cap. Mediana',
        benchmarkFinametrixId: '5e809c4dbb592c4d830f3cd5',
        benchmark: 'FTSE 100 Net Tax Index',
        morningStarCategoryId: 'EUCA000852',
    },
    {
        category: 'Renta Variable',
        name: 'RV RU Cap. Pequeña',
        benchmarkFinametrixId: '5e809c4dbb592c4d830f3cd5',
        benchmark: 'FTSE 100 Net Tax Index',
        morningStarCategoryId: 'EUCA000554',
    },
    {
        category: 'Renta Variable',
        name: 'RV Rusia',
        benchmarkFinametrixId: '5f5f7c410e4901860189f4df',
        benchmark: 'MSCI Russia Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000642',
    },
    {
        category: 'Renta Variable',
        name: 'RV Sector Agricultura',
        benchmarkFinametrixId: '5e809bf5ee0c714d9dbf861a',
        benchmark: 'S&P Global Agribusiness Equity Index',
        morningStarCategoryId: 'EUCA000827',
    },
    {
        category: 'Renta Variable',
        name: 'RV Sector Agua',
        benchmarkFinametrixId: '5e809bd7ee0c714d9dbf8618',
        benchmark: 'S&P Global Water Index',
        morningStarCategoryId: 'EUCA000828',
    },
    {
        category: 'Renta Variable',
        name: 'RV Sector Biotecnología',
        benchmarkFinametrixId: '5e809a5e6de77f531ae1a6ab',
        benchmark: 'NASDAQ Biotechnology Total Return Index',
        morningStarCategoryId: 'EUCA000532',
    },
    {
        category: 'Renta Variable',
        name: 'RV Sector Consumo',
        benchmarkFinametrixId: '5e8099e49ef6b65332cf8872',
        benchmark: 'MSCI World Consumer Staples Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000534',
    },
    {
        category: 'Renta Variable',
        name: 'RV Sector Ecología',
        benchmarkFinametrixId: '5e80996bbb592c4d830f3cd3',
        benchmark: 'MSCI ACWI Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000706',
    },
    {
        category: 'Renta Variable',
        name: 'RV Sector Energía',
        benchmarkFinametrixId: '5e8098edee0c714d9dbf8616',
        benchmark: 'MSCI World Energy Sector Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000535',
    },
    {
        category: 'Renta Variable',
        name: 'RV Sector Energía Alternativa',
        benchmarkFinametrixId: '5e8088539ef6b65332cf8870',
        benchmark: 'S&P Global Clean Energy Net TR',
        morningStarCategoryId: 'EUCA000705',
    },
    {
        category: 'Renta Variable',
        name: 'RV Sector Finanzas',
        benchmarkFinametrixId: '5e808768bb592c4d830f3cd1',
        benchmark: 'MSCI World Financials Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000536',
    },
    {
        category: 'Renta Variable',
        name: 'RV Sector Infraestructura',
        benchmarkFinametrixId: '5e8086b99ef6b65332cf886e',
        benchmark: 'S&P Global Infrastructure Total Return Index',
        morningStarCategoryId: 'EUCA000707',
    },
    {
        category: 'Renta Variable',
        name: 'RV Sector Materiales Insustriales',
        benchmarkFinametrixId: '6001c78414ff97fddc56d0c6',
        benchmark: 'MSCI World Materials Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000538',
    },
    {
        category: 'Renta Variable',
        name: 'RV Sector Oro y Metales preciosos',
        benchmarkFinametrixId: '5e8085e1c6805b4d89f16869',
        benchmark: 'EMIX Global Mining Global Gold',
        morningStarCategoryId: 'EUCA000646',
    },
    {
        category: 'Renta Variable',
        name: 'RV Sector Recursos Naturales',
        benchmarkFinametrixId: '5e808531c6805b4d89f16867',
        benchmark: 'S&P Global Natural Resources Net Total Return Index',
        morningStarCategoryId: 'EUCA000704',
    },
    {
        category: 'Renta Variable',
        name: 'RV Sector Salud',
        benchmarkFinametrixId: '5e8081babb592c4d830f3ccf',
        benchmark: 'MSCI World Health Care Net Total Return USD Index',
        morningStarCategoryId: 'EUCA000537',
    },
    {
        category: 'Renta Variable',
        name: 'RV Sector Tecnología',
        benchmarkFinametrixId: '5e32b9f59fd58c15144cdcfa',
        benchmark: 'NASDAQ Composite Index',
        morningStarCategoryId: 'EUCA000542',
    },
    {
        category: 'Renta Variable',
        name: 'RV Suiza Cap. Grande',
        benchmarkFinametrixId: '5f917318cad2fecda45d93ed',
        benchmark: 'Swiss Exchange Swiss Performance Index',
        morningStarCategoryId: 'EUCA000548',
    },
    {
        category: 'Renta Variable',
        name: 'RV Suiza Cap. Peq/Mediana',
        benchmarkFinametrixId: '5f917318cad2fecda45d93ed',
        benchmark: 'Swiss Exchange Swiss Performance Index',
        morningStarCategoryId: 'EUCA000644',
    },
    {
        category: 'Renta Variable',
        name: 'RV USA Cap. Flexible',
        benchmarkFinametrixId: '5e7ff3d5bb592c4d830f3ccd',
        benchmark: 'S&P 500 Net Total Return Index',
        morningStarCategoryId: 'EUCA000529',
    },
    {
        category: 'Renta Variable',
        name: 'RV USA Cap. Grande Blend',
        benchmarkFinametrixId: '5e7ff3d5bb592c4d830f3ccd',
        benchmark: 'S&P 500 Net Total Return Index',
        morningStarCategoryId: 'EUCA000526',
    },
    {
        category: 'Renta Variable',
        name: 'RV USA Cap. Grande Growth',
        benchmarkFinametrixId: '5e7ff052bb592c4d830f3ccb',
        benchmark: 'S&P 500 Growth Index',
        morningStarCategoryId: 'EUCA000527',
    },
    {
        category: 'Renta Variable',
        name: 'RV USA Cap. Grande Value',
        benchmarkFinametrixId: '5e7fee5bc6805b4d89f16865',
        benchmark: 'S&P 500 Value Index',
        morningStarCategoryId: 'EUCA000528',
    },
    {
        category: 'Renta Variable',
        name: 'RV USA Cap. Mediana',
        benchmarkFinametrixId: '5e7feb456de77f531ae1a6a9',
        benchmark: 'Russell 1000 Index Total Return',
        morningStarCategoryId: 'EUCA000853',
    },
    {
        category: 'Renta Variable',
        name: 'RV USA Cap. Pequeña',
        benchmarkFinametrixId: '5e7fe8c67755e65314e0ddfc',
        benchmark: 'Russell 2000 Total Return Index',
        morningStarCategoryId: 'EUCA000530',
    },
    {
        category: 'Renta Variable',
        name: 'RV Zona Euro Cap. Flexible',
        benchmarkFinametrixId: '5e7fe62f9ef6b65332cf886c',
        benchmark: 'EURO STOXX 50 Net Return EUR',
        morningStarCategoryId: 'EUCA000517',
    },
    {
        category: 'Renta Variable',
        name: 'RV Zona Euro Cap. Grande',
        benchmarkFinametrixId: '5e7fe62f9ef6b65332cf886c',
        benchmark: 'EURO STOXX 50 Net Return EUR',
        morningStarCategoryId: 'EUCA000516',
    },
    {
        category: 'Renta Variable',
        name: 'RV Zona Euro Cap. Mediana',
        benchmarkFinametrixId: '5e7fde55bb592c4d830f3cc9',
        benchmark: 'EURO STOXX Mid Price EUR',
        morningStarCategoryId: 'EUCA000851',
    },
    {
        category: 'Renta Variable',
        name: 'RV Zona Euro Cap. Pequeña',
        benchmarkFinametrixId: '5e7fdca57755e65314e0ddfa',
        benchmark: 'EURO STOXX Small Net Total Return Index EUR',
        morningStarCategoryId: 'EUCA000518',
    },
    {
        category: 'Renta Variable',
        name: 'RV África',
        benchmarkFinametrixId: '602664316202c78cc396f74b',
        benchmark: 'S&P Pan Africa Total Return Index',
        morningStarCategoryId: 'EUCA000697',
    },
];

export default subcategories;
import { CartModes } from '../../screen/Cart/CartScreen';
import {ADD_FUND, UPDATE_FUND, REMOVE_FUND, CLEAR_CART, MODEL_PERCENTAGE_TOTAL, UPDATE_FUND_MODEL_PERCENTAGE, CART_STATE, MODEL_PORTFOLIO_NAME, PUNCTUAL_CONTRIBUTION_PORTFOLIO_ID} from './Constants';

interface IActionLine
{
    fund: any
    amount: number,
    modelPercentage?: number
    readOnly?: boolean,
    userSuscriptionId?: number,
    savingsPlanId?: number,
    source?: string
}
interface IActionFund
{
    isin: string
}
export const addLine = (value: IActionLine) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: ADD_FUND,
        payload: value
    });
};
export const updateLine = (value: IActionLine) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: UPDATE_FUND,
        payload: value
    });
};
export const removeLine = (value: IActionFund) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: REMOVE_FUND,
        payload: value
    });
};
export const clearCart = () => async (dispatch: any) =>
{
    await dispatch(
    {
        type: CLEAR_CART,
    });
};
export const updateModelPercentageLine = (value: IActionLine) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: UPDATE_FUND_MODEL_PERCENTAGE,
        payload: value
    });
};
export const updateModelPercentageTotal = (value: Number) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: MODEL_PERCENTAGE_TOTAL,
        payload: value
    });
};
export const cartState = (value: CartModes) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: CART_STATE,
        payload: value
    });
};

export const updateModelPortfolioName = (value: string) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: MODEL_PORTFOLIO_NAME,
        payload: value
    });
};

export const setPunctualContributionPortfolioData = (value: any) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: PUNCTUAL_CONTRIBUTION_PORTFOLIO_ID,
        payload: {name: value.name, id: value.id}
    });
}
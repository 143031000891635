import * as S from '../../ConvenienceTest/ConvenienceTest.style';
import { Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { InputStyles } from '../../GlobalStyle';
import Select from 'react-dropdown-select';
import { UserIdentityType } from '../../../services/UserIdentitiesService';
import AddressTypes from '../../../utils/AddressTypes';
import Regions from '../../../utils/Regions';
import FinametrixService from '../../../services/FinametrixService';
import { useEffect, useState } from 'react';

interface SelectOption {
    key: string;
    text: string;
    value: string;
}

interface PostalAddressProps {
    userIdentityData: UserIdentityType;
    handleAddressSelectChange: (name: string, value: string) => void;
    handleAddressChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    errors: {
        [key: string]: string;
    };
    disabled: boolean;
    countries: SelectOption[];
}

export const PostalAddress = (props: PostalAddressProps) => {
    const { userIdentityData, handleAddressSelectChange, handleAddressChange, countries, disabled, errors } = props;

    const [cities, setCities] = useState<SelectOption[]>([]);
    const [postalCodes, setPostalCodes] = useState<SelectOption[]>([]); // Nuevos códigos postales
    const [loadingCities, setLoadingCities] = useState(false);
    const [loadingPostalCodes, setLoadingPostalCodes] = useState(false);
    const [isCitySelectDisabled, setCitySelectDisabled] = useState(true);
    const [isPostalCodeSelectDisabled, setPostalCodeSelectDisabled] = useState(true);

    // Método para obtener las ciudades según la región seleccionada
    const fetchCities = async (regionText: string) => {
        const selectedRegion = Regions.find(region => region.text === regionText);
        if (!selectedRegion) {
            console.error("No se encontró la región correspondiente.");
            return;
        }
        const regionValue = selectedRegion.value;
        setLoadingCities(true);
        try {
            const fetchedCities: SelectOption[] = await FinametrixService.getCitiesByRegion(regionValue); // Llamada con el value
            setCities(fetchedCities);
            setCitySelectDisabled(false);
        } catch (error) {
            console.error("Error al obtener las ciudades:", error);
            setCities([]);
            setCitySelectDisabled(true);
        } finally {
            setLoadingCities(false);
        }
    };

    // Método para obtener los códigos postales según la ciudad seleccionada
    const fetchPostalCodes = async (cityValue: string) => {
        setLoadingPostalCodes(true);
        try {
            const fetchedPostalCodes: SelectOption[] = await FinametrixService.getPostalCodesByCity(cityValue);
            setPostalCodes(fetchedPostalCodes);
            setPostalCodeSelectDisabled(false);
        } catch (error) {
            console.error("Error al obtener los códigos postales:", error);
            setPostalCodes([]);
            setPostalCodeSelectDisabled(true);
        } finally {
            setLoadingPostalCodes(false);
        }
    };

    // Efecto que se ejecuta cuando cambia la región seleccionada
    useEffect(() => {
        if (userIdentityData?.postalAddress?.state) {
            fetchCities(userIdentityData.postalAddress.state);
        } else {
            setCitySelectDisabled(true);
        }
    }, [userIdentityData.postalAddress?.state]);

    // Efecto que se ejecuta cuando cambia la ciudad seleccionada
    useEffect(() => {
        if (userIdentityData?.postalAddress?.city! && userIdentityData.postalAddress.state!) {
            const regionText = userIdentityData.postalAddress?.state!;
            const selectedRegion = Regions.find(region => region.text === regionText);
            const selectedRegionValue = selectedRegion?.value;
            if (selectedRegionValue) {
                FinametrixService.getCitiesByRegion(selectedRegionValue)
                    .then((cities) => {
                        const city = cities.find((city: any) => city.text === userIdentityData.postalAddress?.city);
                        fetchPostalCodes(city.value);
                    })
                    .catch((error) => {
                        console.error("Error en las llamadas:", error);
                    });
            }
        } else {
            setPostalCodeSelectDisabled(true);
        }
    }, [userIdentityData?.postalAddress?.city, userIdentityData?.postalAddress?.state]);


    return (
        <>
            {/* Dirección Postal: Tipo vía / Nombre vía / Número /Piso */}
            <S.FielSet>
                <S.FieldAddressTypeCont>
                    <S.Label>Tipo de vía</S.Label>
                    <Select
                        name={'roadType'}
                        labelField='text'
                        options={AddressTypes}
                        onChange={(selectedItems) => handleAddressSelectChange("roadType", selectedItems.length === 0 ? "" : selectedItems[0].value)}
                        dropdownHeight='2000'
                        searchable={true}
                        searchBy="text"
                        disabled={disabled}
                        noDataLabel={'No encontrado'}
                        values={AddressTypes.filter(aT => aT.value === (userIdentityData.postalAddress?.roadType || AddressTypes[6].text))}
                        placeholder={'Selecciona un tipo de vía'}
                        itemRenderer={({ item, methods }) => (
                            <span
                                role="option"
                                aria-selected="false"
                                aria-label={item.text}
                                // @ts-ignore
                                tabIndex="-1"
                                className="react-dropdown-select-item"
                                onClick={() => methods.addItem(item)}
                                key={'road-type-' + item.value}
                            >
                                {item.text}
                            </span>
                        )}
                        required
                    />
                </S.FieldAddressTypeCont>
                <S.FieldCont>
                    <TextField
                        label='Nombre de vía'
                        // @ts-ignore
                        styles={InputStyles}
                        id={'postal_streetName'}
                        name={'postal_streetName'}
                        type='text'
                        disabled={disabled}
                        value={userIdentityData.postalAddress?.streetName || ''}
                        onChange={handleAddressChange}
                        required
                    />
                    {errors.postal_streetName !== "" && errors.postal_streetName}
                </S.FieldCont>
                <S.FieldCont>
                    <TextField
                        label='Número'
                        // @ts-ignore
                        styles={InputStyles}
                        id={'postal_streetNumber'}
                        name={'postal_streetNumber'}
                        type='text'
                        disabled={disabled}
                        value={userIdentityData?.postalAddress?.streetNumber || ''}
                        onChange={handleAddressChange}
                        required
                    />
                    {errors.postal_streetNumber !== "" && errors.postal_streetNumber}
                </S.FieldCont>
                <S.FieldCont>
                    <TextField
                        label='Piso'
                        // @ts-ignore
                        styles={InputStyles}
                        id={'streetFloor'}
                        name={'streetFloor'}
                        type='text'
                        disabled={disabled}
                        value={userIdentityData?.postalAddress?.streetFloor || ''}
                        onChange={handleAddressChange}
                    />
                </S.FieldCont>
            </S.FielSet>

            {/* Dirección Postal: Escalera / Puerta / Otros / País */}
            <S.FielSet>
                <S.FieldCont>
                    <TextField
                        label='Escalera'
                        // @ts-ignore
                        styles={InputStyles}
                        id={'streetStair'}
                        name={'streetStair'}
                        type='text'
                        disabled={disabled}
                        value={userIdentityData?.postalAddress?.streetStair || ''}
                        onChange={handleAddressChange}
                    />
                </S.FieldCont>
                <S.FieldCont>
                    <TextField
                        label='Puerta'
                        // @ts-ignore
                        styles={InputStyles}
                        id={'streetDoor'}
                        name={'streetDoor'}
                        type='text'
                        disabled={disabled}
                        value={userIdentityData?.postalAddress?.streetDoor || ''}
                        onChange={handleAddressChange}
                    />
                </S.FieldCont>
                <S.FieldCont>
                    <TextField
                        label='Otros datos'
                        // @ts-ignore
                        styles={InputStyles}
                        id={'streetOther'}
                        name={'streetOther'}
                        type='text'
                        disabled={disabled}
                        value={userIdentityData?.postalAddress?.streetOther || ''}
                        onChange={handleAddressChange}
                    />
                </S.FieldCont>

                <S.FieldAddressTypeCont>
                    <S.Label>País</S.Label>
                    <Select
                        name={'postal_country'}
                        labelField='text'
                        valueField='value'
                        options={countries}
                        onChange={(selectedItems) => handleAddressSelectChange("postal_country", selectedItems.length === 0 ? "ES" : selectedItems[0].value)}
                        dropdownHeight='2000'
                        searchable={true}
                        disabled={disabled}
                        searchBy="text"
                        noDataLabel={'No encontrado'}
                        values={countries.filter(country => country.value === (userIdentityData.postalAddress?.country || 'ES'))}
                        placeholder={'Selecciona un país'}
                        itemRenderer={({ item, methods }) => (
                            <span
                                role="option"
                                aria-selected="false"
                                aria-label={item.text}
                                // @ts-ignore
                                tabIndex="-1"
                                className="react-dropdown-select-item"
                                onClick={() => methods.addItem(item)}
                                key={'country-' + item.value}
                            >
                                {item.text}
                            </span>
                        )}
                        required
                    />
                </S.FieldAddressTypeCont>
            </S.FielSet>

            {/* Dirección Postal: Provincia, Ciudad y Código Postal */}
            <S.FielSet>
                <S.FieldAddressTypeCont>
                    <S.Label>Provincia</S.Label>
                    <Select
                        name={'postal_state'}
                        labelField='text'
                        options={Regions}
                        onChange={(selectedItems) => {
                            const selectedStateText = selectedItems.length === 0 ? "" : selectedItems[0].text;
                            handleAddressSelectChange("postal_state", selectedStateText);
                            if (selectedStateText) {
                                fetchCities(selectedStateText);
                            }
                        }}
                        dropdownHeight='2000'
                        searchable={true}
                        searchBy="text"
                        disabled={disabled}
                        noDataLabel={'No encontrado'}
                        values={Regions.filter(region => region.text === (userIdentityData.postalAddress?.state || ''))}
                        placeholder={'Selecciona una provincia'}
                        itemRenderer={({ item, methods }) => (
                            <span
                                role="option"
                                aria-selected="false"
                                aria-label={item.text}
                                // @ts-ignore
                                tabIndex="-1"
                                className="react-dropdown-select-item"
                                onClick={() => methods.addItem(item)}
                                key={'state-' + item.value}
                            >
                                {item.text}
                            </span>
                        )}
                        required
                    />
                </S.FieldAddressTypeCont>

                {/* Ciudad */}
                <S.FieldAddressTypeCont>
                    <S.Label>Ciudad</S.Label>
                    {loadingCities ? (
                        <Spinner size={SpinnerSize.small} className="button-spinner" />
                    ) : (
                        <Select
                            name={'postal_city'}
                            labelField='text'
                            options={cities}
                            onChange={(selectedItems) => {
                                const selectedCity = selectedItems.length === 0 ? null : selectedItems[0];
                                handleAddressSelectChange("postal_city", selectedCity ? selectedCity.key : "");
                                if (selectedCity) {
                                    fetchPostalCodes(selectedCity.value);
                                }
                            }}
                            dropdownHeight='2000'
                            searchable={true}
                            searchBy="text"
                            noDataLabel={'No encontrado'}
                            values={cities.filter(city => city.key === userIdentityData.postalAddress?.city)}
                            placeholder={'Selecciona una ciudad'}
                            itemRenderer={({ item, methods }) => (
                                <span
                                    role="option"
                                    aria-selected="false"
                                    aria-label={item.text}
                                    // @ts-ignore
                                    tabIndex="-1"
                                    className="react-dropdown-select-item"
                                    onClick={() => methods.addItem(item)}
                                    key={'city-' + item.value}
                                >
                                    {item.text}
                                </span>
                            )}
                            disabled={isCitySelectDisabled || disabled}
                            required
                        />
                    )}
                </S.FieldAddressTypeCont>

                {/* Código Postal */}
                <S.FieldAddressTypeCont>
                    <S.Label>Código Postal</S.Label>
                    {loadingPostalCodes ? (
                        <Spinner size={SpinnerSize.small} className="button-spinner" />
                    ) : (
                        <>
                            <Select
                                name={'postal_zipCode'}
                                labelField='text'
                                options={postalCodes}
                                onChange={(selectedItems) => handleAddressSelectChange("postal_zipCode", selectedItems.length === 0 ? "" : selectedItems[0].key)}
                                dropdownHeight='2000'
                                searchable={true}
                                searchBy="text"
                                noDataLabel={'No encontrado'}
                                values={postalCodes.filter(postalCode => postalCode.key === (userIdentityData.postalAddress?.zipCode || ''))}
                                placeholder={'Selecciona un código postal'}
                                itemRenderer={({ item, methods }) => (
                                    <span
                                        role="option"
                                        aria-selected="false"
                                        aria-label={item.text}
                                        // @ts-ignore
                                        tabIndex="-1"
                                        className="react-dropdown-select-item"
                                        onClick={() => methods.addItem(item)}
                                        key={'zip-code-' + item.value}
                                    >
                                        {item.text}
                                    </span>
                                )}
                                disabled={isPostalCodeSelectDisabled || disabled}
                                required
                            />
                            <div style={{ marginTop: '24px' }}>
                                {errors.postal_zipCode !== "" && errors.postal_zipCode}
                            </div>
                        </>

                    )}
                </S.FieldAddressTypeCont>

                <S.EmptyHalf />

            </S.FielSet>
        </>
    );
};

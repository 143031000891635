import { createStore, combineReducers, applyMiddleware } from "redux";
import authReducer from "./auth/reducer";
import porfolioReducer from './porfolio/reducer'
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'; // this is for debugging with React-Native-Debugger, you may leave it out
import uiReducer from "./ui/reducer";
import cartReducer from "./cart/reducer";
import { loadCartState } from "./cart/keepCart";

const reducers = combineReducers({
    cart: cartReducer,
    auth: authReducer,
    porfolio: porfolioReducer,
    ui: uiReducer,
});

export const store = createStore(
    reducers,
    {
        cart: loadCartState()
    },
    composeWithDevTools(applyMiddleware(thunkMiddleware))
);

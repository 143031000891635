// FIXME: unificar estilos
import * as S from '../../components/ManagementAuthorization/ManagementAuthorization.style';
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import Moment from 'moment';
import { ISignature } from "../../services/SignaturesService";
import { UserIdentityType } from "../../services/UserIdentitiesService";
import ModalComplete from "../../components/ComplexProducts/ModalComplete";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { SignatureComponent } from "../../components/Signature/SignatureComponent";
import scrollToTop from '../../utils/scrollToTop';
import FnmContractComplexProductService, { FnmContractComplexProductType } from '../../services/FnmContractComplexProductService';
import FundService from '../../services/FundService';
import { AppendLine } from '../../utils/AppendCartLine';
import { useDispatch } from 'react-redux';

const ComplexProductConfirm = () => {
    const { savingsPlanId, isin } = useParams<any>();
    const [fund, setFund] = useState<any>();
    const [fnmContractComplexProduct, setFnmContractComplexProduct] = useState<FnmContractComplexProductType>();
    const [userIdentities, setUserIdentities] = useState<UserIdentityType[]>([]);
    const [phoneNumbers, setPhoneNumbers] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();
    const { state } = useLocation<any>();
    let returnPath = "";
    let amount = 0;
    var complexProductTxt = localStorage.getItem("complexProduct");
    if (complexProductTxt !== null) {
        var complexProduct = JSON.parse(complexProductTxt);
        returnPath = complexProduct.returnPath;
        amount = complexProduct.amount;
    }

    const processExpirationDate = (signatures: ISignature[]) => {
        if (signatures !== undefined && signatures.length > 0) {
            signatures.forEach((signature: ISignature) => {
                if (signature && typeof signature.expiresAt === "string") {
                    signature.expiresAt = Moment(signature.expiresAt).toDate();
                }
            });
        }
    }

    let defaultSignatures: any = state?.signatures;
    processExpirationDate(defaultSignatures);

    const [signatures, setSignatures] = useState(defaultSignatures);

    useEffect(() => {
        FundService.getFund(isin).then((fund: any) => {
            setFund(fund);
            setIsLoaded(true);
        },
            (error) => {
                setIsLoaded(true);
                setError(error);
            });
    }, [isin]);

    useEffect(() => {
        FnmContractComplexProductService.getOrCreateFnmContractComplexProductBySavingsPlanIdAndIsin(savingsPlanId, isin).then(
            (fnmContractComplexProduct: FnmContractComplexProductType) => {
                setFnmContractComplexProduct(fnmContractComplexProduct);
                setIsLoaded(true);
            },
            (error) => {
                setError(error);
                setIsLoaded(true);
            }
        );
    }, []);

    useEffect(() => {
        if (fnmContractComplexProduct?.fnmContractComplexProductsUserIdentities !== undefined) {
            var userIdentities = fnmContractComplexProduct.fnmContractComplexProductsUserIdentities
                .filter(fCCPUI => fCCPUI.userIdentity !== undefined)
                .map(fCCPUI => fCCPUI.userIdentity);
            // @ts-ignore
            setUserIdentities(userIdentities);
        }
    }, [fnmContractComplexProduct]);

    useEffect(() => {
        setPhoneNumbers(userIdentities.map((userIdentity: any) =>
            "X".repeat(userIdentity.phone.length - 3) + userIdentity.phone.slice(userIdentity.phone.length - 3, userIdentity.phone.length)).join(", "));
    }, [userIdentities]);

    const handleSubmit = async () => {
        if (fnmContractComplexProduct !== undefined) {
            await AppendLine(dispatch, fund, amount);
            localStorage.removeItem("complexProduct");
            history.push(complexProduct.returnPath);
        }

        if (!state?.signatures || !modalShow) {
            scrollToTop();
            setModalShow(true)
        }
    };

    return (
        <>
            <S.HelperStyle />
            <S.Cont>
                <S.MainCont>
                    {!isLoaded &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                    {isLoaded &&
                        (
                            <SignatureComponent
                                signatures={signatures}
                                userIdentities={userIdentities}
                                title="Firma del contrato"
                                submitButtonText="Firmar contrato"
                                cancelButtonText="Volver"
                                alreadySignedText={"Enhorabuena, ¡ya puedes invertir en el fondo " + (fund !== undefined ? fund.name : '') + "!"}
                                headerTextArea={"Te informamos de que, dadas las características de la operación compra del fondo " + (fund !== undefined ? fund.name : '')
					                + ", Diaphanum SV, S.A.está obligada a evaluar la conveniencia de la misma para tí. En nuestra opinión esta operación no "
                                    + "es conveniente para tí. Una operación no resulta conveniente cuando el cliente carece de los conocimientos y "
                                    + "experiencia necesarios para comprender la naturaleza y riesgos del instrumento financiero sobre el que va a operar. "
                                    + "Te enviaremos un código SMS por cada interviniente "
                                    + (userIdentities.length > 1 ? ` a los números ${phoneNumbers} ` : userIdentities.length === 1 ? ` al número ${phoneNumbers} ` : " ")
                                    + "para realizar la firma del contrato."}
                                backButtonText="Volver"
                                onBackButtonClicked={() => history.push(`/savingsPlans/${savingsPlanId}/complexProducts/${isin}/agreement`)}
                                onCancelButtonClicked={() => history.push(`/savingsPlans/${savingsPlanId}/complexProducts/${isin}/agreement`)}
                                requestNewCodeButtonText="Recibir nuevo código"
                                onNewCodeSended={(signatures: any) => {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                onSignatureChanged={(signatures: any) => {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                onSigned={handleSubmit}
                            />
                        )}
                    {error &&
                        (
                            <div>Alguno de los códigos enviados no es válido</div>
                        )}
                </S.MainCont>
            </S.Cont>
            {modalShow && (
                <ModalComplete
                    setModalShow={setModalShow}
                    isin={isin}
                    returnPath={returnPath || ''}
                />
            )}
        </>
    );
}

export default ComplexProductConfirm;

import { DefaultButton, SpinnerSize } from '@fluentui/react';
import { useState, useContext } from 'react';
import { ChevronDownMedIcon, ChevronUpMedIcon } from '@fluentui/react-icons-mdl2';
import { TextField } from '@fluentui/react';
import { Spinner } from "@fluentui/react";
import OutsideClickHandler from 'react-outside-click-handler';
import styled from "styled-components";
import { ThemeContext } from '@fluentui/react-theme-provider';
import React from 'react';

export interface IItem
{
    key: string,
    text: string,
    value: string,
    disabled?: boolean
}

const CustomSimpleDropDownContainer = styled.div`
    position: relative;
    display: block;
    min-height: 60px;

    label
    {
        display: block;
        padding 5px 0;
    }

    > button
    {
        border-radius: 30px;
        border: 1px solid #bcc8c9;
        padding: 0.75rem 1rem;
        padding-right: 1.75rem;
        width: max-content;
        cursor: pointer;
        color: ${(props) => props.theme.palette.lightBlack} !important;
        background: ${(props) => props.theme.palette.white} !important;
        height: min-content !important;
        svg
        {
            margin-left: 0.75rem;
            color: ${(props) => props.theme.palette.themePrimary};
            padding-left: 5px;
        }
    }

    .menu
    {
        display: flex;
        flex-direction: column;
        width: max-content;
        position: absolute;
        z-index: 100;
        background: ${(props) => props.theme.palette.popupBackground} !important;
        top: 48px;
        left: 0;
        border-radius: 0 0 16px 16px;
        min-width: 100%;
        max-height: 315px;
        box-shadow: 0px 2px 64px rgba(8, 84, 117, 0.12), 0px 1px 20px rgba(8, 84, 117, 0.12);

        .menu-header
        {
            display: flex;
            flex-direction: column;
            min-height: 30px;
            div,
            input
            {
                :after
                {
                    border: none;
                    outline: none;
                }
                border: none;
                outline: none;
            }

            .ms-TextField-fieldGroup
            {
                border-radius: 0;
                background: ${(props) => props.theme.palette.shadowBackground};
                input
                {
                    padding: 0 20px;
                    color: ${(props) => props.theme.palette.greyText};
                }
            }
        }

        .menu-body
        {
            margin-top: 10px;
            padding: 0 16px 30px;
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: auto;
            border-radius: 0 0 16px 16px;
            button
            {
                border-radius: unset;
                border: 0;
                border-bottom: 1px solid rgba(147,174,185,0.2);
                text-align: left;
                padding: 20px 16px;
                color: ${(props) => props.theme.palette.neutralPrimary};
                background: transparent;
                &:active
                {
                    color: white;
                }
                &.selected
                {
                    > span
                    {
                        color: #CC214F;
                    }
                }
                &.is-disabled
                {
                    cursor: default;
                    color: ${(props) => props.theme.palette.inputColor};
                }
            }
        }
        p
        {
            text-align: center;
            font-weight: 600;
        }
    }

    .selected-summary
    {
        padding: 0 10px;
        position: absolute;
        top: 50px;
        max-width: 100%;
        color: grey;
        ul
        {
            margin-top: 0;
            li
            {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
`;
interface props
{
    options: IItem[],
    id: string,
    onChange: any,
    loading: boolean,
    title: string,
    defaultValue?: string,
    label?: string,
    name?: string,
    disabled?: boolean,
    showSummary?: boolean,
    hasMarginTop?: boolean
};
const CustomSimpleDropdown: React.FC<props> = ({options, onChange, loading, title, defaultValue, label, name, disabled, showSummary, hasMarginTop}) =>
{
    const theme = useContext(ThemeContext);
    const [value, setValue] = useState(defaultValue !== '' ? defaultValue : options[0].value);
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState<IItem[]>([]);
    const optionsToRender = filteredOptions.length > 0 ? filteredOptions : options;

    const dropdownTitle = (title: string) =>
    {
        if(!loading)
        {
            // @ts-ignore
            if(!showSummary && options.length > 0 && options.find((item) => item.value === value) !== undefined)
            {
                return(
                    // @ts-ignore
                    <span>{options.find((item) => item.value === value).text}</span>
                )
            }
            return(
                <span>{title}</span>
            );
        }

        return (
            <div style={{ display: "flex" }}>
                <Spinner size={0} />
                <span style={{ marginLeft: "5px" }}>{title}</span>
            </div>
        );
    };
    const handleFilter = (event: any) =>
    {
        let filtered: IItem[] = options.filter((item: any) =>
            item.text
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(event.target.value.toLowerCase())
        );
        setFilteredOptions([...filtered]);
    };
    const handleChange = (item: IItem) =>
    {
        setValue(item.value)
        setIsOpen(false)
        onChange(item)
    };

    if(options.length > 0)
    {
        return (
            <React.Fragment>
                <CustomSimpleDropDownContainer className={'drop-down-simple' + (isOpen ? ' opened' : '')} theme={theme}>
                    {label !== '' && (<label>{label}</label>)}
                    <button onClick={(event) => { event.preventDefault(); setIsOpen(!isOpen) }} disabled={disabled || false}>
                        <div style={{ display: 'flex' }}>
                            {dropdownTitle(title)}
                            {isOpen ? <ChevronUpMedIcon /> : <ChevronDownMedIcon />}
                        </div>
                    </button>
                    {isOpen && hasMarginTop !== undefined &&
                    (
                        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                            <div className='menu ms-depth-16' style={{marginTop: "1.3rem"}}>
                                <div className='menu-header'>
                                    <TextField onChange={handleFilter} placeholder='Buscar...' />
                                </div>
                                <div className='menu-body'>
                                    {optionsToRender.map((item: any) =>
                                    (
                                        <DefaultButton
                                            key={item.key}
                                            text={item.text}
                                            className={item.value === value ? 'selected' : ''}
                                            onClick={() => handleChange(item)}
                                            name={name || ''}
                                            disabled={item.disabled || false}
                                        />
                                    ))}
                                </div>
                            </div>
                        </OutsideClickHandler>
                    )}
                    {isOpen && hasMarginTop === undefined &&
                    (
                        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                            <div className='menu ms-depth-16' style={{ marginTop: "1.3rem" }}>
                                <div className='menu-header'>
                                    <TextField onChange={handleFilter} placeholder='Buscar...' />
                                </div>
                                <div className='menu-body'>
                                    {optionsToRender.map((item: any) =>
                                    (
                                        <DefaultButton
                                            key={item.key}
                                            text={item.text}
                                            className={item.value === value ? 'selected' : ''}
                                            onClick={() => handleChange(item)}
                                            name={name || ''}
                                            disabled={item.disabled || false}
                                        />
                                    ))}
                                </div>
                            </div>
                        </OutsideClickHandler>
                    )}
                    {showSummary && true &&
                    (
                        <div className="selected-summary">
                            <ul>
                                {optionsToRender.map((option) =>
                                {
                                    if(option.value === value)
                                    {
                                        return <li key={option.value}>{option.text}</li>
                                    }
                                    return "";
                                })}
                            </ul>
                        </div>
                    )}
                </CustomSimpleDropDownContainer>
            </React.Fragment>
        )
    }
    else
    {
        return(
            <React.Fragment>
                {label !== '' && (<label>{label}</label>)}
                <Spinner size={SpinnerSize.medium} />
            </React.Fragment>
        )
    }
}

export default CustomSimpleDropdown;
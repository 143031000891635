import styled from 'styled-components';
import {mergeStyleSets, Separator, Stack} from '@fluentui/react';

export const classNames = mergeStyleSets(
{
    text:
    {
        textAlign: "left"
    },
    number:
    {
        textAlign: "right",
        flexDirection: "row-reverse"
    },
    cellInput:
    {
        textAlign: "right",
        flexDirection: "row-reverse"
    },
    actions:
    {
        textAlign: "right",
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
    }
});
export const Main = styled.main`
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    width: 100%;
    margin: 0;
    @media (min-width: 1280px)
    {   
        margin: 0 auto;
    }
    .section-row
    {
        margin-top: 32px;
    }
    .inner-section
    {
        padding-left: 12px;
        padding-right: 12px;
    }
    .ms-MessageBar
    {
        border-radius: 16px;
        &.ms-MessageBar--warning
        {
            color: #F69A2D;
            background: #FFF1BE;
        }
        .ms-MessageBar-content
        {
            padding: 12px !important;
            .ms-MessageBar-text
            {
                font-weight: 600;
                font-size: 1.01em;
            }
        }
        .ms-MessageBar-icon
        {
            i
            {
                color: #F79A2D
            }
        }
    }
    .percentageColumn
    {
        td
        {
            &.percentage
            {
                cursor: default;
                width: 50px;
            }
        }
    }
    .ms-NumberField
    {
        width: 100%;
        padding: 5px;
        border: 1px solid;
        &.field-fund-investment-invalid
        {
            border-color: #CC214F;
        }
    }
    .cart-top-alerts
    {
        margin-bottom: 32px;
    }
    .ms-Viewport
    {
        margin: 0;
    }
    .ms-DetailsHeader-cellTitle
    {
        flex-direction: column;
    }
    .ms-DetailsRow-cell
    {
        display: flex;
        align-self: center;
        align-items: center;
    }
    .ms-List-cell
    {
        .cell-input
        {
            padding-top: 6px !important;
            padding-bottom: 6px !important;
            input
            {
                text-align: right;
            }
        }
        .ms-DetailsRow-cell
        {
            min-height: 52px;
        }
    }
    .button-spinner
    {
        float: left;
        margin-right: 10px;
    }
    .ms-Stack .ms-StackItem:not(:first-child)
    {
        margin-left: 5px;
    }
    .percentage-detail
    {
        padding: 0;
        table
        {
            table-layout: fixed;
            margin: 0;
            width: 100%;
            height: 52px;
            border-horizontal-spacing: 0;
            border-vertical-spacing: 0;
            -webkit-border-horizontal-spacing: 0;
            -webkit-border-vertical-spacing: 0;
            td
            {
                width: 50%;
                height: 52px;
                margin: 0;
                padding: 0 12px;
                vertical-align: middle;
                text-align: right;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                cursor: default;
                &:first-child
                {
                    text-align: left;
                    border-right: 1px solid ${props => props?.theme?.palette?.neutralLighter || "#e9eff1"};
                }
            }
        }
    }
`;
export const AmountCell = styled(Separator)`
    position: absolute;
    right: 10px;
    top: 12px;
    > div
    {
        width: 100%;
        background: none;
        .warningAmount
        {
            border: 0;
            margin-right: 3px;
            img
            {
                margin-left: 10px;
                top: 5px;
                position: relative;
            }
        }
        input
        {
            width: 100px !important;
        }
    }
    &:before
    {
        content: none;
    }
`;
export const SeparatorTop = styled(Separator)`
    margin-bottom: 20px;
    padding-top: 0 !important;
    font-size: 6px !important;
`;
export const ActionButtonsContainer = styled(Stack)`
    .ms-StackItem
    {
        mergin-left: 5px;
        &:first-child
        {
            margin-left: 0;
        }
    }
`;
export const DetailsRowFooter = styled.div`
    .ms-DetailsRow
    {
        border-bottom: 0;
        .ms-DetailsRow-cell
        {
            flex-direction: row-reverse;
            border-right-width: 0 !important;
            &[data-automation-key="cleanShare"]
            {
                border-right-width: 1px !important;
            }
        }
    }
`;
export const HeaderContainer = styled.div`
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    width: 100%;
`;
export const TitleContainer = styled.div`
    flex: 1 1 20%;
    flex-direction: row;
    justify-content: flex-start;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    max-width: 20%;
    margin-right: 0.25em;
    cursor: default;
`;
export const PlanTitleContainer = styled.div`
    max-width: 10%;
    padding-top: 10px;
    flex: 1 1 10%;
    color: #CC214F;
    cursor: default;
    margin-right: 0.25em;
`;
export const SubTitleContainer = styled.h3`
    margin: 0;
    cursor: default;
`;
export const DropdownContainer = styled.div`
    max-width: 55%;
    flex: 1 1 55%;
    display: flex;
    flex-wrap: nowrap;
    float: inline-end;
    align-items: center;
    margin-right: 8px;
    justify-content: end;
`;
export const LabelDropdown = styled.div`
    margin-right: 0.25em;
    font-size: 24px;
    padding-top: 0.25em;
    align-self: center; 
    font-weight: 600 !important;
    cursor: default;
`;
export const PunctualContributionContainer = styled.div`
    min-height: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
    display: inline-flex;
    justify-content: right;
`;
export const PunctualContributionLabel = styled.div`
    margin-right: 5px;
    font-size: 24px;
    align-self: center;
    font-weight: 500;
    cursor: default;
`;
export const OuterFooterArea = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    button
    {
        margin-left: 8px;
        &:first-child
        {
            margin-left: 0;
        }
    }
`;
export const CenterArea = styled.div`
    text-align: center;
    button
    {
        margin-top: 0px;
    }
`;
export const ModelWalletLabel = styled.div`
    margin-right: 5px;
    font-size: x-large;
    white-space: nowrap;
`;
export const ModelWalletToggle = styled.div`
    margin: 0 !important;
`;
export const ModelWalletInputsContainer = styled.div`
	flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
`;
export const WarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: ${(props) => props.theme.palette.warnSecondary};
    color: ${(props) => props.theme.palette.warn};
    padding: 30px;
    gap: 10px;
    font-weight: 500;
    h3
    {
        margin: 0;
    }
    p
    {
        line-height: 20px;
        margin: 0;
    }
`;
import * as L from '../Styles/ProductCardStyles';
import { Separator } from '@fluentui/react';
import { getScoreIcons } from '../../../utils/scoreToIcons';
import React from 'react';
import { ActiveIcon, InactiveIcon } from '../../../components/icons/IconsTable';
import { translate } from "../../../utils/i18n";

interface IProductCard
{
    product: any,
    publicVersion: any
}

const ProductCard = (props: IProductCard) =>
{
    const scoreIcons = getScoreIcons(props.product.averageScore);
    let iFrame: any = props.publicVersion.path !== undefined ? props.publicVersion.path.includes('iframe') : false;
    return(
        <React.Fragment>
            <L.PCWrapper>
                <L.PCHeader>
                    <L.PCTitleContainer>
                        <h3>{props.product.family}</h3>
                    </L.PCTitleContainer>
                    <L.PCIconsContainer>
                        {scoreIcons.length > 0 && scoreIcons.map((active, activeIndex) =>
                        {
                            return active ? <ActiveIcon key={'activeIndex' + activeIndex} /> : <InactiveIcon key={'activeIndex' + activeIndex} />;
                        })}
                    </L.PCIconsContainer>
                    <L.PCScoreContainer>
                        <span>
                            {parseFloat(props.product.averageScore).toFixed(2).toLocaleString()}
                            <span>/100</span>
                        </span>
                    </L.PCScoreContainer>
                </L.PCHeader>
                <Separator />
                <L.PCBody>
                    <L.PCDescription>
                        <L.PCName>{props.product.name}</L.PCName>
                        <L.PCSubcategory>{props.product.subcategory}</L.PCSubcategory>
                        <L.PCManager>{translate(props.product.manager)}</L.PCManager>
                        <L.PCViewButtonContainer>
                            <L.PCViewButton to={{pathname: (iFrame === true ? '/iframe' : '') + `/${props.product.isin}`, state: {fund: props.product}}}>
                                Ver
                            </L.PCViewButton>
                        </L.PCViewButtonContainer>
                    </L.PCDescription>
                </L.PCBody>
            </L.PCWrapper>
        </React.Fragment>
    );
};

export default ProductCard;
import { useEffect, useState } from 'react';
import PDFViewer from './PDFViewer/PDFViewer';
import MarketAttachments from '../services/MarketAttachmentService';
import { Spinner, SpinnerSize } from '@fluentui/react';

interface AttachmentProps {
    fileName: string,
    className: string
};

export const MarketAttachment = (props: AttachmentProps) => {
    const [base64Image, setBase64Image] = useState<string>("");
    const [base64UrlImage, setBase64UrlImage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        MarketAttachments.getBase64MarketAttachment(props.fileName)
            .then((response: any) => {
                setBase64Image(response);
            }).finally(() => setIsLoading(false));
    }, [props.fileName]);

    useEffect(() => {
        MarketAttachments.getMarketBlobAttachment(props.fileName)
            .then((response: any) => {
                const file = new Blob(
                    [response.data],
                    { type: response.headers['content-type'] });
                setBase64UrlImage(URL.createObjectURL(file))
            })
            .finally(() => setIsLoading(false));
    }, [props.fileName]);

    return (
        <>

            {
                isLoading ? (
                    <div className="spinner-container">
                        <Spinner size={SpinnerSize.large} className="button-spinner" />
                    </div>
                ) : (
                    props.fileName.endsWith(".pdf") ? (
                        <div style={{ position: "relative", flex: 1, width: "100%", minHeight: "1300px" }}>
                            <PDFViewer pdfFilePath={base64UrlImage} />
                        </div>
                    ) : (
                        <img src={base64Image} className={props.className} alt={' Cargando...'} />
                    )
                )
            }
        </>

    );
};

import styled from "styled-components"
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Link } from 'react-router-dom';

export const SelectedTR = styled.tr`
    background-color: ${(props) => props.theme.palette.selected};
    color: ${(props) => props.theme.palette.white} !important;
    cursor: default;

    td
    {
        cursor: default;
    }
`;
export const OutdatedTR = styled.tr`
    background-color: ${(props) => props.theme.palette.outdated};
    color: ${(props) => props.theme.palette.allWhite} !important;
    cursor: default;
`;

export const NoActiveTR = styled.tr`
    color: ${(props) => props.theme.palette.black} !important;
    cursor: default;
`;

export const SelectedBtn = styled.p`
    cursor: not-allowed;
    margin: 0;
    color: ${(props) => props.theme.palette.white} !important;
`;
export const SelectedOutdatedBtn = styled.p`
    cursor: pointer;
    margin: 0;
    color: ${(props) => props.theme.palette.white} !important;
`;
export const OutdatedBtn = styled.p`
    cursor: pointer;
    margin: 0;
    color: ${(props) => props.theme.palette.allWhite} !important;
`;
export const SelectActivePlanBtn = styled.p`
    cursor: pointer;
    margin: 0;
    text-decoration: underline;
    color: ${(props) => props.theme.palette.paragraphSelectUnderline} !important;
    :hover
    {
        color: ${(props) => props.theme.palette.paragraphSelectUnderlineHover} !important;
    }
`;
export const SelectOutdatedPlanBtn = styled.p`
    cursor: pointer;
    margin: 0;
    text-decoration: underline;
    text-decoration-color: ${(props) => props.theme.palette.allWhite} !important;
    color: ${(props) => props.theme.palette.allWhite} !important;
    :hover
    {
        color: ${(props) => props.theme.palette.paragraphSelectUnderline} !important;
    }
`;
export const UnSelectedBtn = styled.p`
    cursor: pointer;
    text-decoration: none;
    color: ${(props) => props.theme.palette.paragraphSelectUnderline};
`;
export const ReSubscribeBtn = styled(DefaultButton)`
    background-color: ${(props) => props.theme.palette.greySelected};
    color: ${(props) => props.theme.palette.darkBlack} !important;
`;
export const RenameBtn = styled.button`
    border: 0px;
    width: 100%;
    height: 50%;
    padding: 0px;
`;
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 80px;
    justify-content: center;
    align-items: flex-start;

    h1
    {
        cursor: default;
        margin-bottom: 1em;
    }
    h3
    {
        cursor: default;
        margin-bottom: 0 !important;
    }
`;

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    background: ${(props) => props.theme.palette.white};
    table
    {
        border-radius: 16px;
    }
    thead
    {
        background: ${(props) => props.theme.palette.tertiary};
    }
    > div
    {
        margin: 0;
    }
`;

export const Table = styled.table`
    border-collapse: collapse;
    text-align: left;
    table-layout: fixed;

    & hr
    {
        display: none;
    }

    & tr
    {
        border-bottom: 2px solid #e9eff2;
    }

    & th:first-of-type
    {
        border-left: none;
        border-top-left-radius: 20px;
    }

    & th:last-of-type
    {
        border-right: none;
        border-top-right-radius: 20px;
    }

    & th
    {
        padding: 10px 10px 10px 10px;
        font-size: 17px;
        color: ${(props) => props.theme.palette.black} !important;
        border-left: 2px solid #e9eff2;
        border-right: 2px solid #e9eff2;
        background-color: ${(props) => props.theme.palette.grey} !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & td:first-of-type
    {
        border-left: none;
    }

    & td:last-of-type:not(.portfolioTd)
    {
        border-right: none;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        text-align: center;
        transform: translateY(2px);
    }

    & td
    {
        padding: 0px 15px 0px 15px;
        word-break: break-word;
        font-size: 15px;
        border-left: 2px solid #e9eff2;
        border-right: 2px solid #e9eff2;
    }

    & th: nth-child(1)
    {
        width: 30%;
    }
    & th: nth-child(2)
    {
        width: 12%;
    }
    & th: nth-child(3)
    {
        width: 12%;
    }
    & th: nth-child(4)
    {
        width: 12%;
    }
    & th: nth-child(5)
    {
        width: 12%;
    }
    & th: nth-child(6)
    {
        width: 12%;
    }
`;
export const TableDiv = styled.div`
    display: flex;
    margin: 10px 0;

    & tr:last-of-type
    {
        width: 100%;
    }
`;
export const TdModelPortfolio = styled.td`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: default;
    border-left: 0 !important;
    padding-top: 10px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 10px !important;
    height: 30px;
`;
export const TdBtnCont = styled.div`
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
`;
export const DivSmallBtnSelected = styled.div`
    display: block;
    width: 100%;
    margin-left: 10px;
    cursor: not-allowed !important;
`;
export const DivSmallBtn = styled.div`
    display: block;
    width: 100%;
    margin-left: 10px;
    color: ${(props) => props.theme.palette.white} !important;
`;
export const LinkSmallBtn = styled(Link)`
    display: block;
    width: 100%;
    margin-left: 10px;
`;
export const LinkBtn = styled(Link)`
    width: 100%;
`;
export const BeginBtn = styled(DefaultButton)``;
export const GraphicCont = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 25px;
`;
export const GraphicBlue = styled.div`
    width: 74%;
    height: 100%;
    border-radius: 5px;
    margin: 0 1px;
    background-color: #9ec0f0;
`;
export const GraphicPurple = styled.div`
    width: 16%;
    height: 100%;
    border-radius: 5px;
    margin: 0 1px;
    background-color: #c9a3c1;
`;
export const GraphicYellow = styled.div`
    width: 10%;
    height: 100%;
    border-radius: 5px;
    margin: 0 1px;
    background-color: #f8dab5;
`;
export const GraphicGrey = styled.div`
    width: 5%;
    height: 100%;
    border-radius: 5px;
    margin: 0 1px;
    background-color: #e2eaec;
`;
export const Cont = styled.div`
    grid-area: main;
    background-color: #fafcfd;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
`;
export const MainCont = styled.div`
    padding: 20px 10px 0px;

    & hr
    {
        margin: 40px 0;
        border: solid 1px #eff4f6;
    }
`;
export const Title = styled.div`
    & h1
    {
        font-size: 30px;
        color: #2a4143;
        font-weight: 800;
    }

    & h2
    {
        font-size: 24px;
        font-weight: 400;
        color: #5f7172;
    }
`;
export const TableCont = styled.div`
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0px 1px 7px -1px #00000025;
`;
export const SubTitle = styled.h4`
    color: ${(props) => props.theme.palette.themePrimary};
    font-size: 18px;
    font-weight: 700;
    margin-top: 0 !important;
    margin-bottom: 1rem;
    cursor: default;
`;
export const LinkButton = styled(Link)`
    display: block;
    width: fit-content;
    margin: 40px 0 20px auto;
`;
export const Note = styled.p`
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    color: #93aeb9;
    text-align: left;
`;
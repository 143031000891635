import { DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import { useEffect, useState } from "react";
import ReportCard from "../../components/Dataverse/ReportCard";
import * as L from '../../components/Instruments/layout.styles';
import DataverseService, { DataverseResponse, DataverseType } from "../../services/DataverseService";

const Dataverse = () => {
	const [error, setError] = useState({ message: false });
	const [isLoaded, setIsLoaded] = useState(true);
	const [dataverseItems, setDataverseItems] = useState<DataverseType[]>([]);
	const [page, setPage] = useState(0);
	const [recordsTotal, setRecordsTotal] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(12);

	useEffect(() => {
		setIsLoaded(false);
		DataverseService.getDataverseItems(page, itemsPerPage).then(
			(response: DataverseResponse) => {
				setDataverseItems(response.items);
				setRecordsTotal(response.totalRecords);
				setPage(page);
				setIsLoaded(true);
			},
			error => {
				setIsLoaded(true);
				setError(error);
			}
		);
	}, [page, itemsPerPage]);

	return (
		<L.TableWrapper>
			<div className="ms-Grid-row">
				<div className="ms-Grid-col ms-sm12">
					<L.Title>Dataverse</L.Title>
				</div>
			</div>
			<L.TableWidthManager>
				<div className="ms-Grid-row">
					{error.message && <div>Error: {error.message}</div>}
					{!error.message && (
						<>
							{!isLoaded && <Spinner size={SpinnerSize.large} />}
							{isLoaded && (
								<>
									{dataverseItems.map((dataverseItem: DataverseType, dataverseIndex: number) => (
										<ReportCard
											key={'dataverseIndex' + dataverseIndex}
											reportId={dataverseItem.reportId}
											imgHeaderSource={dataverseItem.image}
											cardTitle={dataverseItem.title}
											cardBody={dataverseItem.description}
											createdBy={dataverseItem.creator}
											createdByLogo={dataverseItem.creatorLogo}
										/>
									))}
								</>
							)}
						</>
					)}
				</div>
				<L.PaginationContainer>
					<L.ProductsPerPage>
						<span>Resultados por página</span>
						<select
							onChange={event => {
								setItemsPerPage(Number(event.target.value));
								setPage(0);
							}}
							defaultValue={itemsPerPage}
						>
							<option value={12}>12</option>
							<option value={18}>18</option>
							<option value={24}>24</option>
							<option value={30}>30</option>
						</select>
					</L.ProductsPerPage>
					<L.PageCount>
						<span>{`${page + 1} de ${Math.ceil(recordsTotal / itemsPerPage)}`}</span>
					</L.PageCount>
					<L.PageButtons>
						<button
							onClick={() => {
								if (page > 0) {
									setPage(page - 1);
								}
							}}
							className={page === 0 ? "disabled" : ""}
						>
							<i className={"ironia-icon keyboard-arrow-left"} />
						</button>
						<button
							onClick={() => {
								if (page < Math.ceil(recordsTotal / itemsPerPage!) - 1) {
									setPage(page + 1);
								}
							}}
							className={Math.ceil(recordsTotal / itemsPerPage!) === page + 1 ? "disabled" : ""}
						>
							<i className={"ironia-icon keyboard-arrow-right"} />
						</button>
					</L.PageButtons>
				</L.PaginationContainer>
			</L.TableWidthManager>
		</L.TableWrapper>
	)
}

export default Dataverse;

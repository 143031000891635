import React from "react";
import DocumentTitle from 'react-document-title';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { useLocation } from "react-router";
import ExperienceSavingsPlanDifferentSubscriptions from "./ExperienceSavingsPlanDifferentSubscriptions";

const ExperienceSavingsPlanDifferentIdentities = () =>
{
    const { state } = useLocation<any>();
    var subscription = state?.subscription;

    return (
        <React.Fragment>
            <DocumentTitle title="IronIA - Suscripción Distintos Intervinientes">
                <React.Fragment>
                    <h2 style={{ display: "inline-block", margin: 0 }}>Nueva Suscripción - Distintos Intervinientes</h2>
                    <Separator />
                    <ExperienceSavingsPlanDifferentSubscriptions />
                </React.Fragment>
            </DocumentTitle>
        </React.Fragment>
    );
}

export default ExperienceSavingsPlanDifferentIdentities;
import { TooltipHost } from '@fluentui/react';
import { ActiveIcon } from '../../../components/icons/IconsTable';

export interface IProfitability
{
    fundName: string,
    linear_return: any,
    b_linear_return: any,
    alpha: any,
    b_alpha: any,
    benchmark: any
}

export const Profitability = (props: IProfitability) =>
{
    return (
        <table style={{marginTop: "1em"}}>
            <thead>
                <tr>
                    <th>Ratio / Producto</th>
                    <th>{props.fundName}</th>
                    <th>{props.benchmark}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='La ganancia o pérdida en términos porcentuales y anualizada obtenida por una inversión en un periodo determinado'>
                                Retorno anualizado
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.linear_return}%</td>
                    <td>{props.b_linear_return}%</td>
                    <td>{props.linear_return > props.b_linear_return ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Mide la diferencia de rentabilidad entre el gestor y el índice. Muestra si el fondo aporta valor o no, respecto al índice'>
                                Alpha
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.alpha}%</td>
                    <td>{props.b_alpha}%</td>
                    <td>{props.alpha > props.b_alpha ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
            </tbody>
        </table>
    );
};
import Styles from "./index.module.css";

type Props =
{
    title: string;
    onClickViewMore?: any;
};

const SearchHeadTitle = (props: Props) =>
{
    const {title, onClickViewMore} = props;

    const HandleClick = (e: any) =>
    {
        if(onClickViewMore)
        {
            onClickViewMore(e);
        }
    };

    return(
        <div className={Styles.HeadTitle}>
            <div className={Styles.HeadTitleText}><p>{title}</p></div>
            <div className={Styles.HeadTitleLine} />
            {onClickViewMore &&
            (
                <div className={Styles.HeadTitleAnchorViewMore} onClick={HandleClick}>
                    Ver más
                </div>
            )}
        </div>
    );
};

export default SearchHeadTitle;
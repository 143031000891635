/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import './CardElementStyle.css';
import * as L from "../IroniaExplica/IroniaExplicaStyle";
import { getScoreIcons } from "../../utils/scoreToIcons";
import { Modal, Separator } from "@fluentui/react";
import { decimalFormatter } from "../../utils/numberFormatter";
import { ActiveIcon, InactiveIcon } from "../../components/icons/IconsTable";
import { useBoolean } from "@fluentui/react-hooks";
import ReactPlayer from "react-player";
import { ai } from "../../services/TelemetryService";
import Auth from "../../Auth/Auth";

interface ICardElement
{
    date: string,
    url: string,
    isin: string,
    cleanShare: boolean,
    score: number,
    managerName: string,
    managerId: string,
    category: string,
    subcategory: string,
    familyName: string,
    name: string
}

const CardElementVideo = (props: ICardElement) =>
{
    const appInsights = ai.reactPlugin;
    const scoreIcons = getScoreIcons(props.score);
    const theme = window.localStorage.getItem("theme") || "light";
    const [managerName, setManagerName] = React.useState<string>("");
    const [isVideoOpen, {setTrue: showVideo, setFalse: hideVideo}] = useBoolean(false);
    const handleEndedVideo = () =>
    {
        hideVideo();
    }
    const customEventVídeoIATrigger = (isin: any) =>
    {
        showVideo();
        appInsights.trackEvent({name: "IronIA Explica", properties: {isin: isin, userId: Auth.getUserProfile().id}});
    };
    const customEventManagerTrigger = (manager: any) =>
    {
        appInsights.trackEvent({name: "IronIA Tech - Link Gestora", properties: {manager: manager}});
    }
    const customEventFundTrigger = (isin: any) =>
    {
        appInsights.trackEvent({name: "Buscador Ficha Fondo", properties: {isin: isin}});
    }

    React.useEffect(() =>
    {
        if(managerName === "")
        {
            var aux = props.managerName.toLowerCase().replaceAll(" ", "-");
            var aux2 = aux.replaceAll("(", "");
            var aux3 = aux2.replaceAll(")", "");

            setManagerName(aux3.trim());
        }
    }, [props.managerName, managerName]);

    return(
        <React.Fragment>
            <L.PCWrapper theme={theme}>
                <L.PCHeader>
                    <L.PCTitleContainer>
                        <L.FundName onClick={(customEventFundTrigger)}>
                            <a href={"/"+props.isin} target="_blank">
                                {props.name}
                            </a>
                        </L.FundName>
                    </L.PCTitleContainer>
                    <L.PCIconsContainer>
                        {scoreIcons.length > 0 && scoreIcons.map((active, activeIndex) =>
                        {
                            return active ? <ActiveIcon key={'activeIndex' + activeIndex} /> : <InactiveIcon key={'activeIndex' + activeIndex} />;
                        })}
                    </L.PCIconsContainer>
                    <L.PCScoreContainer>
                        <span>
                            {decimalFormatter.format(parseFloat(parseFloat(props.score.toString()).toFixed(2).toLocaleString()))}
                            <span>/100</span>
                        </span>
                        <L.PCCleanShareContainer>
                            {props.cleanShare === true &&
                            (
                                <ActiveIcon color={"#1BB2AD"} />
                            )}
                        </L.PCCleanShareContainer>
                    </L.PCScoreContainer>
                </L.PCHeader>
                <Separator />
                <L.PCBody>
                    <L.PCDescription>
                        <L.PCName>{props.familyName}</L.PCName>
                        <L.PCIsin>{props.isin}</L.PCIsin>
                        <L.PCSubcategory>{props.subcategory}</L.PCSubcategory>
                        <L.PCManager onClick={() => customEventManagerTrigger(props.managerName)}>
                            <a href={"https://ironia.tech/managers/"+managerName+"/"+props.managerId} target="_blank">
                                {props.managerName.toLowerCase()}
                            </a>
                        </L.PCManager>
                        <L.PCViewButtonContainer>
                            <L.PCViewButton onClick={() => customEventVídeoIATrigger(props.isin)}>
                                {props.date} - Ver
                            </L.PCViewButton>
                        </L.PCViewButtonContainer>
                    </L.PCDescription>
                </L.PCBody>
            </L.PCWrapper>
            <Modal isOpen={isVideoOpen} onDismiss={hideVideo} isBlocking={false} containerClassName="video-container">
                <ReactPlayer url={props.url} playing={true} controls={true} width={"100%"} height={"auto"}
                    config={{file:{forceVideo: true, attributes: {controlsList: 'nodownload'}}}}
                    // @ts-ignore
                    onEnded={handleEndedVideo}
                />
            </Modal>
        </React.Fragment>
    );
}

export default CardElementVideo;
import "./index.sass";
import React from "react";
import { DefaultButton } from "office-ui-fabric-react";
import imgA from "../../../images/Miax-Javi.png";
import imgB from "../../../images/Miax-Jose.jpeg";
import logo from "../../../images/BME_logo_marine_rgb.svg";
import { ai } from "../../../services/TelemetryService";

type Props = {};

const CardMiax = (props: Props) => {
	const appInsights = ai.reactPlugin;

	const handlePFDClick = (url: any) => {
		appInsights.trackEvent({ name: "Curso Miax Folleto" });
		window.open(url, "_blank", "noreferrer");
	};

	const handleLinkClick = () => {
		appInsights.trackEvent({ name: "Curso Miax Link" });
	};

	return (
		<div className="training-course-miax-item ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8 ms-xxl6 ms-xxxl4">
			<div className="training-course-miax-card" title={"Máster Miax"}>
				<div className="training-course-miax-images">
					<div className="training-course-miax-image-div ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3">
						<img className="training-course-miax-image-a" src={imgA} alt={"Máster Miax"} />
					</div>
					<div className="training-course-miax-middle-text ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6">
						Máster en IA y Computación Cuántica aplicada a los Mercados Financieros (MIAX)
						<div className="training-course-miax-image-logo-div">
							<img className="training-course-miax-image-logo" src={logo} alt={"Máster Miax Logo"} />
						</div>
					</div>
					<div className="training-course-miax-image-div ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3">
						<img
							className="training-course-miax-image-b"
							src={imgB}
							alt={"Máster Miax"}
						/>
					</div>
				</div>
				<div className="description">
					El principal objetivo es dotar al mercado de profesionales del más alto nivel en inteligencia artificial, capaces de desarrollar nuevos
					modelos de gestión de inversiones, y con profundos conocimientos de los distintos tipos de mercados y productos.
					<br />
					<br />
					Javier Riaño y José A. Esteban socios de IronIA Fintech forman parte de este programa formativo. Aprovecha las ventajas para la comunidad
					IronIA.
				</div>
				<div className="ms-Grid-row footer">
					<div className="ms-Grid-col ms-sm6 pdf">
						<DefaultButton
							className="button mini-primary-button"
							onClick={() => handlePFDClick("https://ironiastorage.azureedge.net/folletos/FolletoMiax11ed.pdf")}
						>
							Descargar Folleto
						</DefaultButton>
					</div>
					<div className="ms-Grid-col ms-sm6 text-right link">
						Matriculate con un 20% de descuento
						<br />
						<a
							href="https://gestion.institutobme.es/ofertaformativa/inscripcionBme-%20181-mia-x"
							target="_blank"
							rel="noopener noreferrer"
							onClick={() => handleLinkClick()}
						>
							Código: IRONIAALUMNO23
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardMiax;

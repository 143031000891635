import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { IButtonProps } from '@fluentui/react';
import { useHistory, useLocation } from 'react-router';
import { SavingsPlanElementsStatus } from '../../services/ElementsService';
import { useEffect, useState } from 'react';
import DonationsService, { Donation } from "../../services/DonationService";

const _overflowItems: ICommandBarItemProps[] = [];
const overflowProps: IButtonProps = {ariaLabel: 'Más acciones'};

const ElementsMenu = ({savingsPlanElementsStatus} : {savingsPlanElementsStatus: SavingsPlanElementsStatus}) =>
{
    const history = useHistory();
    const [items, setItems] = useState<ICommandBarItemProps[]>([]);
    const location = useLocation();
    const [activeDonation, setActiveDonation] = useState<boolean>(false);

    DonationsService.getCurrentDonation().then((donation: Donation) =>
    {
        setActiveDonation(donation !== null);
    })
    .catch((error) =>
    {
        setActiveDonation(false);
    })

    useEffect(() => {
        let _items = [
            {
                text: 'Elements',
                key: 'elements',
                onClick: () => history.push('/elements'),
                className: location.pathname === '/elements' ? 'is-selected' : ''
            },
            {
                text: 'Ranking de carteras',
                key: 'sharedPortfolios',
                onClick: () => history.push('/elements/sharedPortfolios'),
                className: location.pathname === '/elements/sharedPortfolios' ? 'is-selected' : ''
            },
            {
                text: 'Programa Lealtad',
                key: 'FrameworkAgreement',
                onClick: () => history.push('/elements/frameworkAgreement'),
                className: location.pathname === '/elements/frameworkAgreement' ? 'is-selected' : ''
            },
            {
                text: 'Compra Elements IA',
                key: 'ElementsIA',
                onClick: () => history.push('/ElementsIA'),
                className: location.pathname === '/ElementsIA' ? 'is-selected' : ''
            },
            {
                text: 'Donaciones',
                key: 'Donations',
                onClick: () => history.push('/elements/donations'),
                className: location.pathname === '/elements/donations' ? 'is-selected' : ''
            }
        ];

        if (!savingsPlanElementsStatus.isSubscribed) {
            _items.splice(3, 2);
            _items.splice(0, 1);
        }
        else {
            if (!activeDonation) {
                _items.splice(4, 1);
            }
        }

        setItems(_items);
    }, [savingsPlanElementsStatus, location.pathname, activeDonation]);

    return (
        <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" id="main-menu">
                <CommandBar
                    // @ts-ignore
                    items={items}
                    overflowItems={_overflowItems}
                    overflowButtonProps={overflowProps}
                    ariaLabel="Usa las flechas izquierda y derecha para navegar entre las entradas del menú"
                />
            </div>
        </div>
    );
};

export default ElementsMenu;
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ProductCard = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(25% - 20px);
    height: 300px;
    box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px;
    border-radius: 16px;
    margin: 0 10px 30px 10px;
`;
export const PCWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 15px 20px;
`;
// PC = PRODUCT CARD
export const PCHeader = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
export const PCBody = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;
export const PCTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    flex: 1;
    h3
    {
        margin: 0;
        font-size: 18px !important;
        font-weight: 800;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #2a4143;
    }
`;
export const PCIconsContainer = styled.div`
    display: flex;
    flex: 1;
    margin-top: 5px;
    padding: 10px 0;
`;
export const PCScoreContainer = styled.div`
    display: flex;
    flex: 1;
    font-size: 20px;
    font-weight: 600;
    > span span
    {
        color: #bec7c7;
    }
`;
export const PCDescription = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 10px;
`;
export const PCName = styled.div`
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 800;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    margin-bottom: 10px;
`;
export const PCSubcategory = styled.div`
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: pre-wrap;
`;
export const PCManager = styled.div`
    display: flex;
    flex: 1;
    margin-top: 10px;
    color: #95a0a1;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    text-transform: capitalize;
`;
export const PCViewButtonContainer = styled.div`
    display: flex;
    flex: 0;
`;
export const PCViewButton = styled(Link)`
    text-align: center;
    border-radius: 9999px;
    background: #cc214f;
    width: 100%;
    border: none;
    margin: 15px 0 5px 0;
    padding: 20px 0;
    cursor: pointer;
    color: white;
`;
import { CaretHollowMirroredIcon } from "@fluentui/react-icons-mdl2";
import React from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PowerBiEmbedCustom from "../../../components/Dataverse/PowerBiEmbedCustom";

const BackButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #93aeb9;
    margin-bottom: 20px;
    span
    {
        margin-right: 10px;
        cursor: pointer;
        button
        {
            border: 0;
            color: #93aeb9;
            cursor: pointer;
        }
    }
`;

const Report = () => {
	const history = useHistory();
	const { reportId } = useParams<any>();

	return (
		<>
			<BackButtonContainer >
				<span onClick={() => history.goBack()}>
					<CaretHollowMirroredIcon />
					<button >Volver atrás</button>
				</span>
			</BackButtonContainer>
			<PowerBiEmbedCustom reportId={reportId} />
		</>
	);
};

export default Report;

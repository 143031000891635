import { Separator } from 'office-ui-fabric-react/lib/Separator';
import React from 'react';
import DocumentTitle from 'react-document-title';

const PaymentCancelError = () =>
{
    return(
        <DocumentTitle title='IronIA - Cancelación de pago ha fallado'>
            <React.Fragment>
                <h3>La cancelación del pago de la orden ha fallado</h3>
                <p className="description">Se ha producido un error durante el proceso de devolución pago.
                    Póngase en contacto con nosotros a través del
                    email <a href="mailto:info@ironia.tech">info@ironia.tech</a>.
                </p>
                <Separator className="separator" />
            </React.Fragment>
        </DocumentTitle>
    );
};

export default PaymentCancelError;
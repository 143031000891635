import React, { useState, useContext } from 'react';
import {
    FocusZoneDirection,
    ITextFieldProps,
    SuggestionsController,
    Callout, FocusZone, KeyCodes, Suggestions, DirectionalHint, TextField,
    mergeStyleSets
} from "@fluentui/react";
import {useConst, useForceUpdate, useId} from '@fluentui/react-hooks';
import {SearchAutofillWrapper} from "./styles";
import {ThemeContext} from '@fluentui/react-theme-provider';

export interface ISearchAutofillProps extends ITextFieldProps {
    label?: string;
    errorMessage?: any;
    suggestions?: any;
    selectedItem?: any;
    loadData: (filter: string) => Promise<any[]>;
    getKey?: (item?: any) => string|number;
    getDisplayValue?: (item?: any) => string|number;
    getSuggestionDisplayValue?: (item?: any) => string|number;
    onSuggestionsLoad?: (filter: string, selectedItems?: any[]) => any[] | Promise<any[]>;
    onFilterChange?: (event: any, filter: string) => any;
    onChange?: (event: any, value: any) => any;
}

const stores: {[k: string]: SuggestionsController<any>} = {};

let lastLoadPromise: any;
export const SearchAutofill: React.FunctionComponent<ISearchAutofillProps> = (
    props: ISearchAutofillProps/*, context*/
) => {
    const hWrapper = React.createRef<any>();
    const compId = useId();
    const hSuggestions = React.createRef<Suggestions<any>>();
    const inputId = useId();
    const forceUpdate = useForceUpdate();
    const theme = useContext(ThemeContext);
    const [filter, setFilter] = useState<string|undefined>("");
    const [suggestionsVisible, setSuggestionsVisible] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const moreSuggestionsAvailable = useConst(true);
  
    if(!stores[compId]) {
        stores[compId] = new SuggestionsController<any>();
    }

    // TODO: Revisar si esto afecta al rendimiento en caso de tener el mismo valor.
    const updateOverlayVisibility = (filter: string|undefined) => {
        setSuggestionsVisible(!!filter || !!stores[compId].suggestions?.length || isLoading);
    };

    const loadSuggestions = (currentFilter: any) => {
        // TODO: Cacheo.
        setLoading(true);

        if(typeof currentFilter === "undefined") {
            currentFilter = filter;
        }

        let promise = lastLoadPromise = props.loadData?.(currentFilter);
        if(!promise) {
            return [];
        }

        return promise.then(items => {
            if(promise !== lastLoadPromise) {return;}

            setLoading(false);
            let suggestions: any[] = [];

            if(Array.isArray(items) && items.length > 0) {
                suggestions = items.slice(0, 20).map((item: any, index: any) => {
                    return { // AÃ±adir a cada elemento las propiedades displayValue y key.
                        item: {
                            index,
                            data: item,
                            key: props.getKey?.(item) || index,
                        }
                    };
                });
            }

            stores[compId].updateSuggestions(suggestions);

            function ifSuggestionsReady() {
                if(suggestions.length !== stores[compId].getSuggestions().length) {
                    setTimeout(ifSuggestionsReady);
                    return;
                }
                setTimeout(() => {
                    updateOverlayVisibility(filter);
                    forceUpdate();
                }, 0);
            };
            setTimeout(ifSuggestionsReady);
      
            return suggestions;
        })
        .catch(error => {
            if(promise !== lastLoadPromise) {return;}
            setLoading(false);
            return [];
        });
    };

    const selectAllText = (event: any) => {
        if(event?.target) {
            event.target.selectionStart = 0;
            event.target.selectionEnd = event.target.value?.length;
        }
    };
  
    const changeSelectedItem = (item: any) => {
        props.onChange?.call(this, null, item);
    };

    const getSelectedItem = () => props.selectedItem;
    const getItemDisplayValue = (item: any) => {
        return item ? props.getSuggestionDisplayValue?.(item?.data) || item?.key : undefined; 
    };
    const getSelectedItemDisplayValue = () => {
        let item = getSelectedItem();
        if(!item) {
            return undefined;
        }

        return props?.getDisplayValue?.(item?.data)
            || getItemDisplayValue(item);
    };

    const completeSuggestion = () => {
        if(stores[compId].hasSelectedSuggestion()) {
            // TODO: ¿Debería pasar el item o la suggestion entera?
            changeSelectedItem(stores[compId].currentSuggestion!.item);
            updateOverlayVisibility(filter);
        }
    }

    // Events:
    const onChange: any = (event: any, value?: any) => {
        changeSelectedItem(undefined);
        setFilter(value);
        updateOverlayVisibility(value);
        loadSuggestions(value);
        props.onFilterChange?.call(this, event, value);
    };

    const onSuggestionClick = (event: any, item: any, index?: any) => {
        if(typeof index !== "undefined") {
            stores[compId].setSelectedSuggestion(index);
            completeSuggestion();
            stores[compId].deselectAllSuggestions();
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const onKeyDown = (ev: React.KeyboardEvent<HTMLElement>) => {
        switch(ev.which) {
            case KeyCodes.escape:
                if(suggestionsVisible) {
                    setSuggestionsVisible(false);
                    ev.preventDefault();
                    ev.stopPropagation();
                }
                break;

            case KeyCodes.enter:
                if(!ev.shiftKey && suggestionsVisible) {
                    // TODO: Update input value...
                    completeSuggestion();
                    selectAllText(ev);
                    ev.preventDefault();
                    ev.stopPropagation();
                } else {
                    // TODO: Validation...
                }
                break;

            case KeyCodes.up:
                if(
                    (ev.target as any)?.getAttribute?.("id") !== "undefined"
                    && (ev.target as any)?.getAttribute?.("id") === inputId
                    && suggestionsVisible
                ) {
                    if(stores[compId].previousSuggestion()) {
                        hSuggestions.current?.forceUpdate();
                        ev.preventDefault();
                        ev.stopPropagation();
                    }
                }
                else {
                    updateOverlayVisibility(filter);
                }
                break;

            case KeyCodes.down:
                if(
                    (ev.target as any)?.getAttribute?.("id") !== "undefined"
                    && (ev.target as any)?.getAttribute?.("id") === inputId
                    && suggestionsVisible
                ) {
                    if(stores[compId].nextSuggestion()) {
                        hSuggestions.current?.forceUpdate();
                        ev.preventDefault();
                        ev.stopPropagation();
                    }
                }
                else {
                    updateOverlayVisibility(filter);
                }
            break;

            default:
                break;
        }
    };

    const styles = mergeStyleSets({
        callout: {
            width: "100%",
            zIndex: 10
        }
    });
  
    const renderSuggestionItem = (item: any): any => {
        return (
            <div className="suggestion-rendered-item"
                key={item.key}
                onMouseEnter={(ev) => {
                    stores[compId].deselectAllSuggestions();
                    ev.preventDefault();
                    ev.stopPropagation();
                }}
            >
                {getItemDisplayValue(item)}
            </div>
        );
    };

    const renderSuggestions = () => {
        return (
            <Callout
                className={styles.callout}
                target={hWrapper}
                hidden={!suggestionsVisible || getSelectedItem()}
                setInitialFocus={false}
                isBeakVisible={false}
                coverTarget={false}
                calloutMaxHeight={300}
                alignTargetEdge={true}
                directionalHint={DirectionalHint.bottomCenter}
                directionalHintFixed={true}
                doNotLayer={true}
                preventDismissOnEvent={(event) => {
                    switch(event.type) {
                    case "scroll":
                        return true;
                    }
                    return false;
                }}
                gapSpace={2}
                onDismiss={() => {setSuggestionsVisible(false)}}
            >
                <Suggestions
                    onRenderSuggestion={renderSuggestionItem}
                    onSuggestionClick={onSuggestionClick}
                    suggestions={stores[compId].getSuggestions()}
                    ref={hSuggestions}
                    isLoading={isLoading}
                    moreSuggestionsAvailable={moreSuggestionsAvailable}
                />
            </Callout>
        );
    };

    return (
        <SearchAutofillWrapper
            ref={hWrapper}
            onClick={() => updateOverlayVisibility(filter)}
            onKeyDown={onKeyDown}
            theme={theme}
        >
            <FocusZone
                direction={FocusZoneDirection.vertical}
                isCircularNavigation={true}
                isInnerZoneKeystroke={(event) => {
                    if(suggestionsVisible) {
                        switch(event.which) {
                        case KeyCodes.up:
                        case KeyCodes.down:
                            return true;
                        }
                    }
                    if(event.which === KeyCodes.enter) {
                        return true;
                    }
                    return false;
                }}
            >
                <TextField
                    {...props}
                    id={inputId}
                    autoComplete="off"
                    errorMessage={props.errorMessage}
                    value={getSelectedItemDisplayValue() || filter}
                    onChange={onChange}
                    onFocus={(event) => {
                        setTimeout(() => {
                            selectAllText(event);
                            updateOverlayVisibility(event.target.value);
                        }, 0);
                    }}
                />
            </FocusZone>
            {renderSuggestions()}
        </SearchAutofillWrapper>
    );
};
const Regions = [
    {
        "value": "592c3af4a591700d7c70cbf8",
        "text": "ALAVA"
    },
    {
        "value": "592c3af5a591700d7c70d042",
        "text": "ALBACETE"
    },
    {
        "value": "592c3af5a591700d7c70d25b",
        "text": "ALICANTE"
    },
    {
        "value": "592c3af7a591700d7c70dd01",
        "text": "ALMERÍA"
    },
    {
        "value": "592c3af8a591700d7c70e299",
        "text": "ASTURIAS"
    },
    {
        "value": "592c3b0aa591700d7c7117ee",
        "text": "AVILA"
    },
    {
        "value": "592c3b0ca591700d7c711b57",
        "text": "BADAJOZ"
    },
    {
        "value": "592c3b0da591700d7c711d32",
        "text": "BALEARES"
    },
    {
        "value": "592c3b0fa591700d7c712261",
        "text": "BARCELONA"
    },
    {
        "value": "592c3b12a591700d7c7126f1",
        "text": "BURGOS"
    },
    {
        "value": "592c3b18a591700d7c7131de",
        "text": "CANTABRIA"
    },
    {
        "value": "592c3b1da591700d7c713a8d",
        "text": "CASTELLÓN"
    },
    {
        "value": "592c3b1ea591700d7c713c7e",
        "text": "CEUTA"
    },
    {
        "value": "592c3b1ea591700d7c713c88",
        "text": "CIUDAD REAL"
    },
    {
        "value": "592c3b1fa591700d7c713df1",
        "text": "CORUÑA, LA"
    },
    {
        "value": "592c3b89a591700d7c71b2c0",
        "text": "CUENCA"
    },
    {
        "value": "592c3b8ca591700d7c71b55d",
        "text": "CÁCERES"
    },
    {
        "value": "592c3b8fa591700d7c71b7d3",
        "text": "CÁDIZ"
    },
    {
        "value": "592c3b92a591700d7c71ba31",
        "text": "CÓRDOBA"
    },
    {
        "value": "592c3b96a591700d7c71bdac",
        "text": "GERONA"
    },
    {
        "value": "592c3b9fa591700d7c71c4f6",
        "text": "GRANADA"
    },
    {
        "value": "592c3ba3a591700d7c71c7d0",
        "text": "GUADALAJARA"
    },
    {
        "value": "592c3ba8a591700d7c71cbeb",
        "text": "GUIPUZCOA"
    },
    {
        "value": "592c3baba591700d7c71ce74",
        "text": "HUELVA"
    },
    {
        "value": "592c3bada591700d7c71cfaa",
        "text": "HUESCA"
    },
    {
        "value": "592c3bb5a591700d7c71d625",
        "text": "JAEN"
    },
    {
        "value": "592c3bb8a591700d7c71d844",
        "text": "LEÓN"
    },
    {
        "value": "592c3bc8a591700d7c71e398",
        "text": "LUGO"
    },
    {
        "value": "592c3bd7a591700d7c71ee90",
        "text": "LÉRIDA"
    },
    {
        "value": "592c3be3a591700d7c71f666",
        "text": "MADRID"
    },
    {
        "value": "592c3beea591700d7c71fe3f",
        "text": "MELILLA"
    },
    {
        "value": "592c3beea591700d7c71fe4b",
        "text": "MURCIA"
    },
    {
        "value": "592c3bfba591700d7c720661",
        "text": "MÁLAGA"
    },
    {
        "value": "592c3c0aa591700d7c720f7f",
        "text": "NAVARRA"
    },
    {
        "value": "592c3c16a591700d7c72175f",
        "text": "ORENSE"
    },
    {
        "value": "592c3c49a591700d7c723437",
        "text": "PALENCIA"
    },
    {
        "value": "592c3c50a591700d7c723844",
        "text": "PALMAS (LAS)"
    },
    {
        "value": "592c3c64a591700d7c724325",
        "text": "PONTEVEDRA"
    },
    {
        "value": "592c3c79a591700d7c724f63",
        "text": "RIOJA (LA)"
    },
    {
        "value": "592c3c7da591700d7c725181",
        "text": "S.C.TENERIFE"
    },
    {
        "value": "592c3c8ca591700d7c725945",
        "text": "SALAMANCA"
    },
    {
        "value": "592c3c98a591700d7c725f24",
        "text": "SEGOVIA"
    },
    {
        "value": "592c3c9fa591700d7c726299",
        "text": "SEVILLA"
    },
    {
        "value": "592c3ca2a591700d7c726463",
        "text": "SORIA"
    },
    {
        "value": "592c3caba591700d7c726888",
        "text": "TARRAGONA"
    },
    {
        "value": "592c3cb1a591700d7c726bc0",
        "text": "TERUEL"
    },
    {
        "value": "592c3cb6a591700d7c726e2e",
        "text": "TOLEDO"
    },
    {
        "value": "592c3cbaa591700d7c72705a",
        "text": "VALENCIA"
    },
    {
        "value": "592c3cc4a591700d7c727526",
        "text": "VALLADOLID"
    },
    {
        "value": "592c3cc9a591700d7c727767",
        "text": "VIZCAYA"
    },
    {
        "value": "592c3cd5a591700d7c727d31",
        "text": "ZAMORA"
    },
    {
        "value": "592c3cdda591700d7c728164",
        "text": "ZARAGOZA"
    }
];

export default Regions;
import axios from "axios";
import { setupInterceptorsTo } from "./Interceptors";
import { powerbiServiceUrl, powerbiApiReportTokensCode } from "../config";
setupInterceptorsTo(axios);

export type EmbedContent =
{
    embedToken: string;
    embedUrl: string;
    reportId: string;
};
export type PowerBiReport =
{
    id: string;
    name: string;
    webUrl: string;
    embedUrl: string;
    datasetId: string;
};
export type datasetParameters =
{
    name: string;
    newValue: string;
};

const powerbi_environment: string = "ironia";

class PowerBiService
{
    public getReportTokens = async (reportId: string): Promise<EmbedContent> =>
    {
        let url: string = `${powerbiServiceUrl}/api/powerbi/${encodeURIComponent(reportId)}/tokens?environment=${powerbi_environment}&code=${powerbiApiReportTokensCode}`;
        const req = await axios.get<any>(url)
        return req.data
    }
}

const powerBiServices = new PowerBiService();
export default powerBiServices;
import { useState, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import Auth from "../../Auth/Auth";
import SavingsPlanService, { SavingsPlanType } from '../../services/SavingsPlanService';
import BackButton from "../buttons/BackButton";
import { useParams } from 'react-router-dom';
import FirstSteps from './FirstSteps';
import subscriptionService from '../../services/SubscriptionService';
import { getCdnUrl } from '../../utils/Cdn';

interface INewExperienceSavingsPlanProps
{
    publicVersion: boolean;
}

const NewExperienceSavingsPlan = (props: INewExperienceSavingsPlanProps) =>
{
    const [error, setError] = useState({ message: false });
    const user = Auth.getUserProfile();
    const [subscription, setSubscription] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>();
    const { savingsPlanId } = useParams<any>();

    useEffect(() =>
    {
        if(savingsPlanId)
        {
            SavingsPlanService.getSavingsPlan(savingsPlanId).then((storedSavingsPlan: any) =>
            {
                setSavingsPlan(storedSavingsPlan);

                if(subscription === undefined)
                {
                    subscriptionService.getSubscriptionBySavingPlanId(user.id, savingsPlanId).then((response: any) =>
                    {
                        if(response !== undefined)
                        {
                            setSubscription(response);
                            setIsLoaded(true);
                        }
                    });
                }
            },
            (error) =>
            {
                setIsLoaded(true);
                setError(error);
            });
        }
        else
        {
            setIsLoaded(true);
        }
    }, [savingsPlanId, subscription, user.id]);

    const onChangePeriodicQuantity = (event: any) =>
    {
        // @ts-ignore
        setSavingsPlan(prevState => ( // @ts-ignore
            { ...prevState, periodicContribution: parseFloat(event.target.value.replace(/[.€]/g, "")) }
        ))
    };
    const onChangeContributionPeriod = (event: any) =>
    {
        // @ts-ignore
        setSavingsPlan(prevState => ( // @ts-ignore
            { ...prevState, periodicContributionPeriod: parseFloat(event.target.value) }
        ))
    };
    if(error.message)
    {
        return (<div>Error: {error.message}</div>)
    }
    return (
        <DocumentTitle title='IronIA - Nuevo plan de cartera'>
            <div>
                {!props.publicVersion && <BackButton />}
                <div className="ms-Grid-row savings-plans section-header">
                    <div className="ms-Grid-col ms-md3">
                        <div className="image-experience-container">
                            <img
                                src={getCdnUrl('ihaveexperience.webp')}
                                alt='Cliente con experiencia'
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-md9">
                        <h2 className="highlighted">Plan Libertad</h2>
                        <p className="description left">Utiliza el buscador de fondos para seleccionar tus productos, añádelos al carrito y págalos desde tu banco habitual.</p>
                    </div>
                </div>
                <div className="ms-Grid-row savings-plans options">
                    <FirstSteps
                        savingsPlan={savingsPlan}
                        subscription={subscription}
                        isSavingsPlanReady={isLoaded}
                        onChangePeriodicQuantity={onChangePeriodicQuantity}
                        onChangeContributionPeriod={onChangeContributionPeriod}
                        publicVersion={props.publicVersion}
                        user={user}
                        savingsPlanIsCompleted={true}
                    />
                </div>
            </div>
        </DocumentTitle>
    );
}

export default NewExperienceSavingsPlan;
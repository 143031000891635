import { Link } from 'react-router-dom';
import { getScoreIcons } from '../../../utils/scoreToIcons';
import { ActiveIcon, InactiveIcon } from '../../../components/icons/IconsTable';

export interface IFundEvaluation
{
    subcategory: any,
    averageScore: any,
    morningStarCategoryId: any
}

export const FundEvaluation = (props: IFundEvaluation) =>
{
    const scoreIcons = getScoreIcons(props.averageScore);
    
    return(
        <table style={{marginTop: "1em"}}>
            <thead>
                <tr>
                    <th>Categoría</th>
                    <th>Nota</th>
                    <th>Puntos IronIA</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{width: "50%"}}>
                        <Link to={{ pathname: "/search", state:
                            {
                                subcategory: [
                                {
                                    morningStarCategoryId: props.morningStarCategoryId,
                                    name: props.subcategory,
                                }]
                            }}}
                        >
                            <strong style={{color: "#4284e6"}}>{props.subcategory}</strong>
                        </Link>
                    </td>
                    <td style={{ width: "20%" }}>
                        <strong>{`${parseFloat(props.averageScore).toFixed(2)} / 100`}</strong>
                    </td>
                    <td style={{ width: "30%" }}>
                        {scoreIcons.map((active, index) => (active ? <ActiveIcon key={'active-icon-' + index} /> : <InactiveIcon key={'inactive-icon-' + index} />))}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
import { useEffect, useState } from "react";
import * as S from '../../components/SuitabilityTests/SuitabilityTest.style';
import { DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import DocumentTitle from "react-document-title";
import Moment from 'moment';
import { useHistory, useParams } from "react-router";
import { UserIdentityType } from '../../services/UserIdentitiesService';
import FinametrixService, { RiskType } from "../../services/FinametrixService";
import React from "react";
import FnmContractComplexProductService, { FnmContractComplexProductType } from "../../services/FnmContractComplexProductService";
import Auth from "../../Auth/Auth";
import FundService from "../../services/FundService";
import AppendCartLine from '../../utils/AppendCartLine';
import { useDispatch } from "react-redux";

const SuitabilityTestListForComplexProduct = () => {
    const { savingsPlanId, isin } = useParams<any>();
    const [userIdentities, setUserIdentities] = useState<UserIdentityType[]>([]);
    const [fnmContractComplexProduct, setFnmContractComplexProduct] = useState<FnmContractComplexProductType>();
    const [risks, setRisks] = useState<RiskType[]>();
    const [fund, setFund] = useState<any>();
    const [error, setError] = useState({ message: false });
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const history = useHistory();
    const activePlan = Auth.getActivePlan();
    const dispatch = useDispatch();

    useEffect(() => {
        FnmContractComplexProductService.getOrCreateFnmContractComplexProductBySavingsPlanIdAndIsin(savingsPlanId, isin).then(
            (fnmContractComplexProduct: FnmContractComplexProductType) => {
                setFnmContractComplexProduct(fnmContractComplexProduct);
                setIsLoaded(true);
            },
            (error) => {
                setError(error);
                setIsLoaded(true);
            }
        );
    }, []);

    useEffect(() => {
        FundService.getFund(isin).then((fund: any) => {
            setFund(fund);
            setIsLoaded(true);
        },
            (error) => {
                setIsLoaded(true);
                setError(error);
            });
    }, [isin]);

    useEffect(() => {
        if (fnmContractComplexProduct?.fnmContractComplexProductsUserIdentities !== undefined) {
            var userIdentities = fnmContractComplexProduct.fnmContractComplexProductsUserIdentities
                .filter(fCCPUI => fCCPUI.userIdentity !== undefined)
                .map(fCCPUI => fCCPUI.userIdentity);

            if (!userIdentities.some(uI => uI?.suitabilityTests?.filter(sT => sT.status === "SIGNED").length === 0)) {
                Auth.setActivePlan({
                    ...activePlan,
                    allUserIdentitiesHaveSuitabilityTest: true
                });

                var complexProductTxt = localStorage.getItem("complexProduct");
                if (complexProductTxt !== null) {
                    var complexProduct = JSON.parse(complexProductTxt);
                    if (complexProduct?.amount !== undefined) {
                        AppendCartLine(
                            dispatch,
                            history,
                            {
                                fund: complexProduct.fund,
                                amount: complexProduct.amount
                            },
                            complexProduct.returnPath
                        );
                    }
                }
            }
            else {
                // @ts-ignore
                setUserIdentities(userIdentities);
            }
        }
    }, [fnmContractComplexProduct]);

    useEffect(() => {
        FinametrixService.getRisks()
            .then((risksAux: RiskType[]) => {
                setRisks(risksAux);
            },
                (error) => {
                    setError(error);
                });
    }, []);

    function editUserIdentity(userIdentityId: number) {
        history.push(`/savingsPlans/${savingsPlanId}/complexProducts/${isin}/suitabilityTest/${userIdentityId}`);
    }

    return (
        <DocumentTitle title='IronIA - 2.2 Test de idoneidad'>
            <div className="ms-Grid-row savings-plans savings-plan-select-identities">
                <div className="mt20">
                    <h1>Test de idoneidad de la inversión</h1>
                </div>
                <p className="descripction">
                    Para poder suscribir un fondo complejo todos los intervinientes del contrato deben realizar el test de idoneidad.
                </p>
                {userIdentities.length === 0 && (
                    <Spinner size={SpinnerSize.large} />
                )}
                {userIdentities.length > 0 && (
                    <S.TableCont>
                        <table className="full-width">
                            <thead>
                                <tr>
                                    <th>Nombre y apellidos</th>
                                    <th>Correo electrónico</th>
                                    <th>Menor de edad</th>
                                    <th>Test de idoneidad</th>
                                    <th className="actions"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {userIdentities.map((userIdentity, userIdentityIndex) => {
                                    var currentDate = Moment();
                                    var birthdayDate = Moment(userIdentity.birthday);
                                    var isMinor = currentDate.diff(birthdayDate, 'years') < 18;
                                    return (
                                        <tr key={'userIdentityIndex' + userIdentityIndex}>
                                            <td>{userIdentity.firstName} {userIdentity.lastName} {userIdentity.secondLastName}</td>
                                            <td>{userIdentity.email}</td>
                                            <td className="text-center">{isMinor ? 'Sí' : 'No'}</td>
                                            <td className="text-center">
                                                {isMinor && (<>No es necesario</>)}
                                                {!isMinor && userIdentity.maxRiskAllowed === null &&
                                                    (
                                                        <React.Fragment>
                                                            <i className='ironia-icon warning-alert' />
                                                            No
                                                        </React.Fragment>
                                                    )}
                                                {!isMinor && userIdentity.maxRiskAllowed !== null &&
                                                    (
                                                        <React.Fragment>
                                                            {risks !== undefined &&
                                                                (
                                                                    <React.Fragment>
                                                                        {(risks.find(r => userIdentity.suitabilityTests !== undefined
                                                                            && r._id === userIdentity.suitabilityTests[userIdentity.suitabilityTests.length - 1].results?.results.profile)?.order || 0)}/4
                                                                        {' '}
                                                                        {risks.find(r => userIdentity.suitabilityTests !== undefined
                                                                            && r._id === userIdentity.suitabilityTests[userIdentity.suitabilityTests.length - 1].results?.results.profile)?.name}
                                                                    </React.Fragment>
                                                                )}
                                                        </React.Fragment>
                                                    )}
                                            </td>
                                            <td className="text-center">
                                                {userIdentity?.id !== undefined
                                                    && userIdentity.status !== undefined
                                                    && userIdentity.maxRiskAllowed === null
                                                    && !isMinor
                                                    && (
                                                        <DefaultButton className="button mini-primary-button"
                                                            disabled={isSending || userIdentity.status === 'CANCELLED'}
                                                            onClick={() => {
                                                                // @ts-ignore
                                                                editUserIdentity(userIdentity.id);
                                                            }}
                                                        >
                                                            {isSending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                                            {userIdentity.maxRiskAllowed === null && <>Realizar test</>}
                                                        </DefaultButton>
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </S.TableCont>
                )}
            </div>
        </DocumentTitle>
    );
}

export default SuitabilityTestListForComplexProduct;

import React, { useEffect, useState } from "react";
import scrollToTop from "../utils/scrollToTop";
import Moment from "moment";
import { UserIdentityType } from "../services/UserIdentitiesService";
import * as S from "../Api/Styles/ApiContractStyles";
import DocumentTitle from "react-document-title";
import { DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import { SignatureComponent } from "../components/Signature/SignatureComponent";
import { ISignature } from "../services/SignaturesService";
import Api from "../services/ApiService";
import SavingsPlanService from "../services/SavingsPlanService";

const ApiTripartiteContract = (props: any) =>
{
    const guid: any = window.location.pathname.split('/').pop();
    const [isSending, setIsSending] = useState<boolean>(false);
    const [userIdentities, setUserIdentities] = useState<UserIdentityType[]>([]);
    const [signatures, setSignatures] = useState<any[]>([]);
    const [savingsPlanId, setSavingsPlanId] = useState<number>();
    const [phoneNumbers, setPhoneNumbers] = useState("");
    const [bottom, setBottom] = useState<boolean>(false);
    const [urlOK, setUrlOK] = useState<string>("");
    const [urlKO, setUrlKO] = useState<string>("");
    const [enableSignature, setEnableSignature] = useState<boolean>(false);
    const [contractHTML, setContractHTML] = useState<string>("");
    const [isContractLoaded, setIsContractLoaded] = useState(false);

    React.useEffect(() =>
    {
        scrollToTop();
    }, []);

    useEffect(() => {
        SavingsPlanService.getHTMLTripartiteContract(guid).then((contractHTML: any) => {
            setContractHTML(contractHTML);
            setIsContractLoaded(true);
        });
    }, []);

    useEffect(() =>
    {
        Api.getUserIdentities(guid).then((response: any) =>
        {
            setUserIdentities(response);
        });
    }, []);

    useEffect(() =>
    {
        setPhoneNumbers(userIdentities.map((userIdentity: any) => "X".repeat(userIdentity.phone.length - 3)
            + userIdentity.phone.slice(userIdentity.phone.length - 3, userIdentity.phone.length)).join(", "));
    }, [userIdentities]);

    const handleClick = () =>
    {
        setIsSending(true);
        if(userIdentities.length > 0)
        {
            Api.getContractSignatures(guid).then((response: any) =>
            {
                setSavingsPlanId(response.savingPlanId);
                setUrlOK(response.urlOk);
                setUrlKO(response.urlKo);
                setIsSending(false);
                setSignatures(response.signatures);
                setEnableSignature(true);
            });
        }
    };

    const handleScroll = (event: any) =>
    {
        const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 1;
        setBottom(bottom);
    };

    const processExpirationDate = (signatures: ISignature[]) =>
    {
        if(signatures !== undefined && signatures.length > 0)
        {
            signatures.forEach((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = Moment(signature.expiresAt).toDate();
                }
            });
        }
    }

    const handleSubmit = () =>
    {
        if(savingsPlanId !== undefined)
        {
            Api.patchSavingPlan(savingsPlanId).then((response: any) =>
            {
                if(response === true)
                {
                    window.location.href = urlOK;
                }
                else
                {
                    window.location.href = urlKO;
                }
            });
        }
    };

    return(
        <DocumentTitle title={'IronIA - Firma de Contrato'}>
            <React.Fragment>
                <S.HelperStyle />
                <S.Cont>
                    <S.MainCont>
                        <S.Title>
                            <S.Header>
                                Contrato Tripartito
                            </S.Header>
                            <S.ContractSubTitle>
                                A continuación te incluimos los detalles del contrato con las distintas partes. Léelo y
                                cuando estés listo, dale al botón de "Solicitar Firma". Te enviaremos un
                                código SMS por cada titular
                                {userIdentities.length > 1 ? ` a los números ${phoneNumbers} ` : userIdentities.length === 1 ? ` al número ${phoneNumbers} ` : " "}
                                para realizar la firma del contrato.
                            </S.ContractSubTitle>
                        </S.Title>
                        <S.BarCont>
                            <S.BgBar />
                            <S.ProsBar />
                        </S.BarCont>
                        {isContractLoaded && (
                            <>
                                <S.ScrollableTabletCont
                                    onScroll={handleScroll}
                                    dangerouslySetInnerHTML={{ __html: contractHTML }}
                                />
                            </>
                        )}

                        {!isContractLoaded && (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        <S.BtnCont>
                            <DefaultButton onClick={() => window.location.href = `/api/Contract/${guid}`} className="button tiny-secondary-button">
                                Volver
                            </DefaultButton>
                            <DefaultButton className="button tiny-primary-button" onClick={handleClick} disabled={!bottom || enableSignature}>
                                {isSending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                Solicitar Firma
                            </DefaultButton>
                        </S.BtnCont>
                    </S.MainCont>
                </S.Cont>
                {enableSignature &&
                (
                    <React.Fragment>
                        <S.SignatureContainer>
                            <SignatureComponent
                                signatures={signatures}
                                userIdentities={userIdentities}
                                isApiSignature={true}
                                backButtonText={"Volver atrás"}
                                title={"Firma de los contratos"}
                                submitButtonText={"Firmar contratos"}
                                cancelButtonText={"Volver atrás"}
                                alreadySignedText={"Contratos firmados. Redirigiendo..."}
                                headerTextArea={"Completa la firma rellenando el código que te hemos enviado por SMS"}
                                onBackButtonClicked={() => window.location.href = urlKO}
                                onCancelButtonClicked={() => window.location.href = urlKO}
                                requestNewCodeButtonText={"Recibir nuevo código"}
                                onNewCodeSended={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                onSignatureChanged={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                onSigned={handleSubmit}
                            />
                        </S.SignatureContainer>
                    </React.Fragment>
                )}
            </React.Fragment>
        </DocumentTitle>
    );
}

export default ApiTripartiteContract;
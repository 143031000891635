import { createIntl, createIntlCache } from '@formatjs/intl';
import Spanish from '../translations/es.json';

const cache = createIntlCache();
const intl = createIntl(
    {
        locale: 'es',
        messages: Spanish,
    },
    cache
);

export const translate = (message?: string): string => {
    if (message === undefined) {
        return '';
    }

    if (intl.messages[`${message}`] === undefined) {
        return `${message}`;
    }

    return intl.messages[`${message}`].toString() ?? message;
};
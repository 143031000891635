import axios from "axios";
import { fundsService, proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);

interface IManager
{
    id?: number,
    name?: string,
    logo?: string,
    color?: string,
    status?: number,
    createdAt?: string,
    updated?: string
}

export type ManagerType = IManager

class ManagerService
{
    public getBQManagers = async (): Promise<string[]> =>
    {
        let url: string = `${fundsService}/managers`;
        const req = await axios.get(url)
        return req.data
    }
    public getManagers = async (): Promise<ManagerType[]> =>
    {
        let url: string = `${proxyUrl}/managers`
        const req = await axios.get(url)
        return req.data
    }
    public getManager = async (managerId: number): Promise<ManagerType> =>
    {
        let url: string = `${proxyUrl}/managers/${managerId}`
        const req = await axios.get<any>(url)
        return req.data
    }
    public createManager = async (manager: ManagerType): Promise<ManagerType> =>
    {
        let url: string = `${proxyUrl}/managers`
        const req = await axios.post(url, manager)
        return req.data
    }
    public updateManager = async (manager: ManagerType): Promise<ManagerType> =>
    {
        let url: string = `${proxyUrl}/managers/${manager.id}`
        const req = await axios.put(url, manager)
        return req.data
    }
}

const managers = new ManagerService();
export default managers;
import axios from "axios";
import currenciesMock from "../mocks/currencies";
import { fundsService } from "../config";

export const getCurrencies = async () =>
{
    try
    {
        const response = await axios.get(fundsService + "/currencies");
        return response.data;
    }
    catch (error)
    {
        return currenciesMock;
    }
};
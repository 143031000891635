import { IDropdownStyles } from "@fluentui/react";

export const dropdownStyles: Partial<IDropdownStyles> = {
	caretDown: {
		fontSize: "14px !important",
		fontWeight: 700
	},
	dropdownItems: {
		borderColor: "transparent",
		fontWeight: 500,
		fontSize: "20px",
		marginTop: "5px"
	},
	title: {
		color: "#cc214F !important",
		borderColor: "transparent !important",
		fontWeight: 500,
		fontSize: "20px",
		marginTop: "5px"
	},
	dropdownItemSelected: {
		color: "white",
		backgroundColor: "rgb(204, 33, 79)",
		"& span span": {
			color: "white !important"
		},
	},
	dropdownOptionText: { color: "rgb(204, 33, 79)" }
};
import { Spinner, SpinnerSize } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { useEffect, useState } from 'react';
import Auth from '../../../../Auth/Auth';
import SubscriptionService from '../../../../services/SubscriptionService';
import { UserSubscriptionsType } from '../../../../services/SubscriptionService';
import ServiceSubscriptionTable from './ServiceSubscriptionTable';

const ServiceSubscriptionTableLogic = () => {
    const user = Auth.getUserProfile();
    const [subscriptions, setSubscription] = useState<UserSubscriptionsType[]>();
    const [loading, setLoading] = useState(true);

    var columns =
        [
            {
                id: Math.floor(Math.random() * 100000).toString(),
                title: "Servicio"
            },
            {
                id: Math.floor(Math.random() * 100000).toString(),
                title: "Fecha de Inicio"
            },
            {
                id: Math.floor(Math.random() * 100000).toString(),
                title: "Fecha de Fin"
            },
            {
                id: Math.floor(Math.random() * 100000).toString(),
                title: "Estado"
            }
        ];

    const callAPIValues = async () => {
        let data = await SubscriptionService.getSubscriptionByUser(user.id);
        if (data !== undefined) {
            const filteredData = data.filter(item => item.subscriptionType === 'service');
            setSubscription(filteredData);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        callAPIValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (<Spinner size={SpinnerSize.large} />);
    }
    else {
        return (<ServiceSubscriptionTable tableTitle={"Servicios del Cliente"} tableCount={subscriptions?.length} rowData={subscriptions as UserSubscriptionsType[]} columnTitles={columns} />);
    }
};

export default ServiceSubscriptionTableLogic;
import styled from 'styled-components';
import { TextField } from '@fluentui/react';

export const Main = styled.main`
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    width: 100%;
    margin: 0;
    & .ms-Stack .ms-StackItem
    {
        width: 100%;
    }
    .ms-Stack .ms-StackItem:first-child
    {
        width: auto;
    }
    .button-spinner
    {
        float: left;
        margin-right: 10px;
    }
    .column-count
    {
        margin-right: 40px;
        padding-right: 40px;
        border-right: 1px solid ${props => props?.theme?.palette?.middleBorder || "#93AEB9"};
        opacity: 0.3;
    }
    & .ms-Stack .ms-StackItem:not(:first-child)
    {
        margin-left: 0;
    }
    .section-wrapper
    {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .subsection-wrapper
    {
        margin-top: 1em;
        margin-bottom: 1em;
    }
    .section-form
    {
        margin-top: 15px;
        .ms-StackItem:last-child
        {
            padding-right: 0;
        }
    }
`;
export const MainMini = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    border-radius: 12px;
    padding: 1em;
    & .ms-Stack .ms-StackItem
    {
        width: 100%;
    }
    .ms-Stack .ms-StackItem:first-child
    {
        width: auto;
    }
    .button-spinner
    {
        float: left;
        margin-right: 10px;
    }
    .column-count
    {
        margin-right: 40px;
        padding-right: 40px;
        border-right: 1px solid ${props => props?.theme?.palette?.middleBorder || "#93AEB9"};
        opacity: 0.3;
    }
    & .ms-Stack .ms-StackItem:not(:first-child)
    {
        margin-left: 0;
    }
    .section-wrapper
    {
        margin-top: 30px;
    }
    .section-form
    {
        margin-top: 15px;
        .ms-StackItem:last-child
        {
            padding-right: 0;
        }
    }
`;
export const SeparatorTop = styled.hr`
    border: solid 4px ${props => props?.theme?.palette?.borderPrimary || "#CC214F"};
    border-radius: 10px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
`;
export const SeparatorTopWithIsins = styled.hr`
    border: solid 4px ${props => props?.theme?.palette?.borderPrimary || "#CC214F"};
    border-radius: 10px;
    width: 100%;
    margin-top: 2em;
    margin-bottom: 0.5em;
    box-sizing: border-box;
`;
export const OuterFooterArea = styled.div`
    display: flex;
    justify-content: flex-end;
`;
export const OuterFooterAreaButtonDefault = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 4.25em;
`;
export const OuterFooterAreaButtonSendCode = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 1.6em;
`;
export const FundDetail = styled.p`
    margin-bottom: 0;
    padding-left: 2em;
    color: ${props => props?.theme?.palette?.lightBlack || "#556769"};
    cursor: pointer;
    font-weight: bold;
    > i
    {
        color: #CC214F;
        &:hover
        {
            color: ${props => props?.theme?.palette?.lightBlack || "white"}
        }
    }

    &:first-of-type {
        margin-top: 10px;
    }
`;
export const FirstFundDetailCart = styled.p`
    margin-top: 2em;
    margin-bottom: 0;
    padding-left: 1em;
    color: ${props => props?.theme?.palette?.lightBlack || "#556769"};
    cursor: default;
    font-weight: bold;
    > i
    {
        color: #CC214F;
        &:hover
        {
            color: ${props => props?.theme?.palette?.lightBlack || "white"}
        }
    }
`;
export const FundDetailCart = styled.p`
    margin-top: 2em;
    width: 100%;
    margin-bottom: 0;
    padding-left: 1em;
    color: ${props => props?.theme?.palette?.lightBlack || "#556769"};
    text-align: left;
    cursor: default;
    > i
    {
        color: #CC214F;
        &:hover
        {
            color: ${props => props?.theme?.palette?.lightBlack || "white"}
        }
    }
`;
export const TextDisclaimer = styled.p`
    margin-bottom: 1.5em;
    margin-top: 0.5em;
    padding-left: 2em;
    cursor: default;
`;
export const TitleModalDocuments = styled.h4`
    width: 85%;
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 0;
`;
export const Text = styled.p`
    color: ${props => props?.theme?.palette?.lightBlack || "#556769"};
    margin-bottom: 0;
    cursor: default;
    &.error
    {
        color: #a4262c;
        font-weight: 500;
    }
`;
export const TextNoMargin = styled.p`
    color: ${props => props?.theme?.palette?.lightBlack || "#556769"};
    margin: 0;
    &.error
    {
        color: #a4262c;
        font-weight: 500;
    }
`;
export const TextCount = styled.p`
    line-height: 76px;
    font-size: 68px;
    margin: 0;
    color: ${props => props?.theme?.palette?.black || "#2A4143"};
    opacity: 0.9;
`;
export const TextTop = styled(Text)`
    margin-top: 12px;
    font-size: 20px;
    line-height: 24px;
    max-width: 888px;
    cursor: default;
`;
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    font-family: Barlow;
    h2
    {
        margin: 0;
    }
`;
export const VerificationTextField = styled(TextField)`
    max-width: 415px;
`;
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    padding-bottom: 10px;

    @media (max-width: 1279px) {
        flex-wrap: wrap;
        max-height: 190px;
    }
`;

export const CardButton = styled.button`
    background: none;
    border: none;
    cursor: ${(props) => (props.readOnly ? 'default' : 'pointer')};
    border-radius: 16px;
    flex: 0 0 calc(100% / 10);
    padding: 2.5px;
    img
    {
        max-width: 100%;
        opacity: ${(props) => (props.anyselected ? '0.5' : '1')};
        border-radius: 16px;
        padding: 2px;
        ${(props) =>
            props.selected &&
            {
                border: `2px solid ${props.theme.palette.themePrimary}`,
                opacity: '1',
            }}
    }

    @media (max-width: 1279px) {
        flex: 0 0 calc(100% / 8);
    }
}
`;

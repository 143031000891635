import "./index.sass";
import Moment from "moment";
import { ai } from "../../services/TelemetryService";
import { Link } from "react-router-dom";
import { getCdnUrl } from "../../utils/Cdn";

type Props =
{
    title: string,
    url: string,
    image: string,
    category?: string,
    summary?: string,
    publishDate: string,
    sourceName: string,
    sourceImage: string
}

const Card = (props: Props) =>
{
    const {title, url, image, category, summary, publishDate, sourceName, sourceImage} = props;
    const appInsights = ai.reactPlugin;

    const handleClick = (event: any) =>
    {
        event.preventDefault();
        appInsights.trackEvent({name: "News", properties: {gestora: sourceName, titulo: title}});
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return(
        <div className="news-item ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
            <Link className="card"
                to={{pathname: url}}
                target="_blank"
                rel="noopener,noreferrer"
                title={title}
                onClick={(event: any) => handleClick(event)}
            >
                <img src={image !== '' ? image : getCdnUrl(sourceImage)} alt={title} />
                <div className="category">{category}</div>
                <div className="title">{title}</div>
                <div className="summary">{summary}</div>
                <div className="ms-Grid-row footer">
                    <div className="ms-Grid-col ms-sm6 date">{Moment(publishDate).format('DD/MM/YYYY')}</div>
                    <div className="ms-Grid-col ms-sm6 text-right source">
                        {sourceName}
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default Card;
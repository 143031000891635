import { Modal } from "@fluentui/react";
import loadingImage from '../../src/images/loading.svg';
import * as M from './Styles/ApiCartStyles';
import React from "react";

interface ModalProps
{
    isModalOpen: boolean,
    hideModal: () => void,
    blocking?: boolean,
    title?: string,
    description?: string
}

const ApiProcessingModal = (props: ModalProps) =>
{
    const title = React.useRef<string>(props.title !== undefined ? props.title : "Procesando la operación");
    const description = React.useRef<string>(props.description !== undefined ? props.description : 'No cierres el navegador hasta que la operación haya terminado.');

    return(
        <React.Fragment>
            <Modal isOpen={props.isModalOpen} onDismiss={props.hideModal} isBlocking={true}>
                <M.ModalRow className="ms-Grid-row">
                    <M.ModalFirstCol className="ms-Grid-col ms-sm6 ms-md4 ms-lg4 ms-xl2 ms-xxl2 ms-xxxl2">
                        <M.ModalImage src={loadingImage} alt="Loading" style={{height: "100px"}} />
                    </M.ModalFirstCol>
                    <M.ModalSecondCol className="ms-Grid-col ms-sm6 ms-md8 ms-lg8 ms-xl10 ms-xxl10 ms-xxxl10">
                        <M.ModalTitle>
                            {title.current}
                        </M.ModalTitle>
                    </M.ModalSecondCol>
                    <M.ModalThirdCol className="ms-sm12">
                        <M.ModalBody>
                            {description.current}
                        </M.ModalBody>
                    </M.ModalThirdCol>
                </M.ModalRow>
            </Modal>
        </React.Fragment>
    );
};

export default ApiProcessingModal;
import * as S from '../ConvenienceTest.style';
import ConvenienceTestIDInputs from './ConvenienceTestIDInputs';
import { useHistory, useParams } from 'react-router-dom';
import Moment from 'moment';
import './dropDown.css';
import UserIdentitiesService, { UserIdentityType } from '../../../services/UserIdentitiesService';
import FinametrixService from '../../../services/FinametrixService';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DefaultButton, Spinner, SpinnerSize } from '@fluentui/react';
import scrollToTop from '../../../utils/scrollToTop';
import React from 'react';
import SavingsPlanService, { SavingsPlanType } from '../../../services/SavingsPlanService';
import DocumentTitle from 'react-document-title';
import { ThemeContext } from '@fluentui/react-theme-provider';

const ConvenienceTestID = () =>
{
    const history = useHistory();
    const { savingsPlanId } = useParams<any>();
    const { userIdentityId } = useParams<any>();
    const theme = useContext(ThemeContext);
    const [error, setError] = useState({ message: '' });
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>();
    const [userIdentity, setUserIdentity] = useState<UserIdentityType>();
    const [countries, setCountries] = useState([]);
    const [isLoadedCountries, setIsLoadedCountries] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [userIdentityForm, setUserIdentityForm] = useState<any>();
    const [sending, setSending] = useState(false);
    const [validating, setValidating] = useState(false);

    useEffect(() =>
    {
        scrollToTop();
    }, []);

    useEffect(() =>
    {
        if(savingsPlanId !== undefined)
        {
            SavingsPlanService.getSavingsPlan(savingsPlanId).then((savingPlan: any) =>
            {
                setSavingsPlan(savingPlan);
            },
            (error: any) =>
            {
                setError(error);
            });
        }
    }, [savingsPlanId]);

    useEffect(() =>
    {
        if(userIdentityId !== null && userIdentityId !== undefined)
        {
            UserIdentitiesService.getUserIdentity(userIdentityId).then((userIdentity: UserIdentityType) =>
            {
                setUserIdentityForm(
                {
                    nationalCountry: false,
                    birthdayCountry: false,
                    idType: false,
                    idDocument: false,
                    idExpiration: false,
                    idFront: false,
                    idBack: false
                });
                if(userIdentity.nationalCountry === null)
                {
                    userIdentity.nationalCountry = "";
                }
                if(userIdentity.birthdayCountry === null)
                {
                    userIdentity.birthdayCountry = "";
                }
                setUserIdentity(userIdentity);
            },
            (error) =>
            {
                setError(error);
            });
        }
    }, [userIdentityId]);

    useEffect(() =>
    {
        FinametrixService.getCountries().then((result: any) =>
        {
            setCountries(result);
            setIsLoadedCountries(true);
        },
        (error: any) =>
        {
            setError(error);
            setIsLoadedCountries(true);
        });
    }, []);

    const handleIDChange = (event: any) =>
    {
        var {name, value}: {name: string, value: string} = event.target;
        var realValue = value;
        if(event.target.files !== null && event.target.files !== undefined && event.target.files.length > 0)
        {
            realValue = event.target.files[0];
        }
        setUserIdentity((prevSate: any) =>
        ({
            ...prevSate,
            [name]: realValue
        }));
    };

    const btnSubmit = useCallback(() =>
    {
        if(userIdentityForm !== undefined)
        {
            setCanSubmit(Object.values(userIdentityForm).every((x) => x === true));
        }
    }, [userIdentityForm]);

    useEffect(() =>
    {
        btnSubmit();
    }, [btnSubmit, userIdentity]);

    const handleSubmit = async (event: any) =>
    {
        event.preventDefault();
        if(userIdentity !== undefined)
        {
            setSending(true);

            var currentDate = Moment();
            var birthdayDate = Moment(userIdentity.birthday);
            var isMinor = currentDate.diff(birthdayDate, 'years') < 18;

            await UserIdentitiesService.updateUserIdentity(userIdentity).then((result: any) =>
            {
                isMinor ? history.push('/configurationMap',
                {
                    savingPlanId: savingsPlan?.id,
                    savingPlanName: savingsPlan?.name
                })
                : history.push('/finance/' + savingsPlanId + '/' + userIdentityId)
            },
            (error: any) =>
            {
                setError(error);
            });
        }
    };

    return(
        <DocumentTitle title={'IronIA - Documentos de Identidad'}>
            <React.Fragment>
                <S.HelperStyle />
                <S.Cont theme={theme}>
                    <S.Warning>
                        <p>
                            Recuerda que toda la información del proceso va siendo guardada conforme
                            completas cada paso de forma que puedas terminar cuando mejor te venga.
                        </p>
                        <p>CONTINUAR MÁS TARDE</p>
                    </S.Warning>
                    <S.MainCont>
                        <S.Steps>
                            <h3>Paso 3 <span>de 4</span></h3>
                        </S.Steps>
                        <S.Title>
                            <h1>Documento de identidad</h1>
                            <h2>
                                Necesitamos que nos facilites tu Documento Nacional de Identidad o tu
                                Tarjeta de Identificación Extranjera, debe encontrarse en período de validez
                                y ser completamente legible.
                            </h2>
                        </S.Title>
                        <S.BarCont>
                            <S.BgBar />
                            <S.ProsBar />
                        </S.BarCont>
                        {(userIdentity === undefined || !isLoadedCountries) &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        {userIdentity !== undefined && isLoadedCountries &&
                        (
                            <S.TabletCont theme={theme}>
                                <S.SubTitle>
                                    Detalles de tu Documento de Identidad del
                                    <span> Interviniente  - {[userIdentity.firstName, userIdentity.lastName, userIdentity.secondLastName].join(" ")}</span>
                                </S.SubTitle>
                                <ConvenienceTestIDInputs
                                    id={userIdentity.id}
                                    finametrixId={userIdentity.finametrixId}
                                    data={userIdentity}
                                    handleChange={(event: any) => handleIDChange(event)}
                                    formVal={userIdentityForm}
                                    setFormVal={(name: string, value: any) => setUserIdentityForm((prevState: any) => ({ ...prevState, [name]: value}))}
                                    countries={countries}
                                    validating={validating}
                                    setValidating={setValidating}
                                />
                            </S.TabletCont>
                        )}
                        <S.BtnRCont>
                            {error.message !== "" &&
                            (
                                <S.Error style={{left: "10em !important"}}>Se ha producido un error que impide continuar con el proceso</S.Error>
                            )}
                            <DefaultButton disabled={userIdentity === undefined} className="button tiny-secondary-button"
                                onClick={() => history.push('/address/' + savingsPlanId + '/' + userIdentityId)}
                            >
                                Volver
                            </DefaultButton>
                            <DefaultButton onClick={handleSubmit} className="button tiny-primary-button"
                                disabled={userIdentity === undefined || !canSubmit || sending || error.message !== "" || validating}
                            >
                                {sending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                Continuar
                            </DefaultButton>
                        </S.BtnRCont>
                    </S.MainCont>
                </S.Cont>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default ConvenienceTestID;
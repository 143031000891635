import React from 'react';
import * as S from './SuitabilityTestConfirm.style';
import { SignatureComponent } from '../Signature/SignatureComponent';
import { ISignature } from "../../services/SignaturesService";
import { useHistory, RouteComponentProps, useLocation, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import moment from "moment";
import UserIdentitiesService, { UserIdentityType } from '../../services/UserIdentitiesService';
import SuitabilityTestService, { ISuitabilityTest } from '../../services/SuitabilityTestsService';

const SuitabilityTestConfirm = ({
    userIdentityId,
    returnRoute,
    cancelRoute
}: {
    userIdentityId: number,
    returnRoute: string,
    cancelRoute: string
}) => {
    const history = useHistory();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();
    const { state } = useLocation<any>();
    const [userIdentity, setUserIdentity] = useState<UserIdentityType>();
    const [suitabilityTest, setSuitabilityTest] = useState<ISuitabilityTest>();
    const [phoneNumbers, setPhoneNumbers] = useState("");

    const processExpirationDate = (signatures: ISignature[]) =>
    {
        if(signatures !== undefined && signatures.length > 0)
        {
            // eslint-disable-next-line array-callback-return
            signatures.map((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = moment(signature.expiresAt).toDate();
                }
            });
        }
    }

    let defaultSignatures: any = state?.signatures;
    processExpirationDate(defaultSignatures);

    const [signatures, setSignatures] = useState(defaultSignatures);

    useEffect(() =>
    {
        if(userIdentityId !== undefined) {
            UserIdentitiesService.getUserIdentityWithSuitabilityTests(userIdentityId).then(
                (userIdentity: UserIdentityType) => {
                    setUserIdentity(userIdentity);
                    if(userIdentity.suitabilityTests !== undefined && userIdentity.suitabilityTests.length > 0) {
                        setSuitabilityTest(userIdentity.suitabilityTests[userIdentity.suitabilityTests.length - 1]);
                    }
                    setIsLoaded(true);
                },
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                }
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIdentityId]);

    useEffect(() =>
    {
        if(userIdentity?.phone !== undefined) {
            setPhoneNumbers("X".repeat(userIdentity.phone.length - 3) + userIdentity.phone.slice(userIdentity.phone.length - 3, userIdentity.phone.length));
        }
    }, [userIdentity]);

    const updateStatus = () =>
    {
        if(suitabilityTest !== undefined
            && suitabilityTest.id !== undefined
            && suitabilityTest.answers !== undefined
            && userIdentity?.finametrixId !== undefined)
        {
            SuitabilityTestService.patchSuitabilityTest(suitabilityTest.id, { status: "SIGNED" }).then(() =>
            {
                history.push(returnRoute);
            },
            (error: any) =>
            {
                setError(error);
            });
        }
    };

    return (
        <React.Fragment>
            <S.Cont>
                <S.MainCont>
                    {!isLoaded && (<Spinner size={SpinnerSize.large} />)}
                    {isLoaded && userIdentity !== undefined && (
                        <React.Fragment>
                            <SignatureComponent
                                signatures={signatures}
                                userIdentities={[userIdentity]}
                                title="Test de idioneidad de la inversión"
                                submitButtonText="Firmar y enviar"
                                cancelButtonText="Volver"
                                headerTextArea={`Te hemos enviado un código SMS al número ${phoneNumbers}`
                                    + " para verificar la identidad del interviniente."}
                                backButtonText="Volver"
                                onBackButtonClicked={() => history.push(cancelRoute)}
                                onCancelButtonClicked={() => history.push(cancelRoute)}
                                requestNewCodeButtonText="Recibir nuevo código"
                                onNewCodeSended={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                onSignatureChanged={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatures(signatures);
                                }}
                                onSigned={updateStatus}
                            />

                            {error && (
                                <div>Alguno de los códigos enviados no es válido</div>
                            )}
                        </React.Fragment>
                    )}
                </S.MainCont>
            </S.Cont>
        </React.Fragment>
    );
};

export default SuitabilityTestConfirm;
import axios from "axios";
import { proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);

interface IDataverse
{
    id?: number,
    title: string,
    description: string,
    reportId: string,
    image: string,
    creator?: string,
    creatorLogo?: string,
}
export type DataverseResponse =
{
    totalRecords: number,
    items: IDataverse[]
}
export type DataverseType = IDataverse;

class DataverseService
{
    public getDataverseItems = async (page: number, itemsPerPage: number): Promise<DataverseResponse> =>
    {
        let url: string = `${proxyUrl}/dataverse?page=` + page + '&itemsPerPage=' + itemsPerPage;
        const req = await axios.get(url)
        return req.data
    }
    public getDataverseItemsByIds = async (page: number, itemsPerPage: number, ids: number[]): Promise<DataverseResponse> =>
    {
        let url: string = `${proxyUrl}/dataverse?page=` + page + '&itemsPerPage=' + itemsPerPage;
        if(ids.length > 0)
        {
            url += '&ids=' + ids.join(",");
        }
        const req = await axios.get(url)
        return req.data
    }
}

const news = new DataverseService();
export default news;
import React, { useEffect, useState } from 'react';
import { Slider } from 'office-ui-fabric-react';
import { Checkbox, Text, DefaultButton, IDropdownOption, IDropdownStyles, Dropdown, Separator, Spinner, SpinnerSize } from '@fluentui/react';
import NumberFormat from "react-number-format";
import { SavingsPlanType, SavingsPlanTypes } from '../../services/SavingsPlanService';
import { UserType } from '../../services/UserService';
import { SubscriptionType } from '../../services/SubscriptionService';
import { SavingsPlanUserIdentityType } from '../../services/SavingsPlanUserIdentityService';
import SuitabilityTestsService, { ISuitabilityTestVersion } from '../../services/SuitabilityTestsService';
import UserService from '../../services/UserService';
import { UsersSubscriptionType } from '../../services/UsersSubscriptionService';
import './SavingsPlans.sass';
import './FirstSteps.sass';
import { registerIcons } from '@uifabric/styling';
import { createIntl, createIntlCache } from '@formatjs/intl';
import Spanish from '../../translations/es.json';
import { Link, useHistory } from 'react-router-dom';
import Moment from "moment";
import { decimalFormatter } from '../../utils/numberFormatter';

const cache = createIntlCache()
const intl = createIntl(
    {
        locale: 'es',
        messages: Spanish,
    },
    cache
);
const debounce = require('lodash/debounce');

registerIcons(
{
    icons:
    {
        'ironiaCancel': <i className="ironia-icon cross" />,
    }
});
const options: IDropdownOption[] = [
    { key: 1, text: 'Mensual' },
    { key: 2, text: 'Anual' }
];
const dropdownStyles: Partial<IDropdownStyles> =
{
    dropdown: { width: 300 },
};

const FirstSteps: React.FC<
{
    savingsPlan?: SavingsPlanType,
    subscription?: SubscriptionType,
    isSavingsPlanReady: boolean,
    onChangeQuantity?: any,
    onChangePeriodicQuantity: any,
    onChangeContributionPeriod: any,
    publicVersion?: boolean,
    user: UserType,
    savingsPlanIsCompleted: boolean,
    isLoadedModelPortfolioComposition?: boolean,
    addFundsToCart?: any,
    isDisabled?: boolean
}> = (
{
    savingsPlan,
    subscription,
    isSavingsPlanReady,
    onChangeQuantity,
    onChangePeriodicQuantity,
    onChangeContributionPeriod,
    publicVersion,
    user,
    savingsPlanIsCompleted,
    isLoadedModelPortfolioComposition,
    addFundsToCart,
    isDisabled
}) =>
{
    const valueFormat = (value: number) => `${value}%`;
    const history = useHistory();
    const [error, setError] = useState({message: ""});
    const [suitabilityTestVersion, setSuitabilityTestVersion] = useState<ISuitabilityTestVersion>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
    const [usersSubscriptions, setUsersSubscriptions] = useState<UsersSubscriptionType[]>();
    const [hasSuitabilityTest, setHasSuitabilityTest] = useState(false);
    const [isAddingFunds, setIsAddingFunds] = useState(false);
    const [isSubscribing, setIsSubscribing] = useState(false);

    const getProgress = () =>
    {
        var progress = 0;
        if(publicVersion)
        {
            return progress;
        }

        if(isSavingsPlanReady)
        {
            progress += 33
        }

        if(savingsPlan?.status !== undefined)
        {
            if(savingsPlan.status > 1)
            {
                progress += 33
            }

            if(savingsPlan.status > 2 && hasSuitabilityTest)
            {
                progress += 33
            }
        }

        if(progress > 33)
        {
            progress += 1
        }

        return progress;
    };

    useEffect(() =>
    {
        if(!loadingSubscriptions && user?.id !== undefined && usersSubscriptions === undefined && savingsPlan?.type !== undefined &&
            (savingsPlan.type === SavingsPlanTypes.Experience || savingsPlan.type === SavingsPlanTypes.Saver || savingsPlan.type === SavingsPlanTypes.Alchemy))
        {
            setLoadingSubscriptions(true);
            UserService.getSuscriptions(user.id).then((subscriptionsStored: UsersSubscriptionType[]) =>
            {
                setIsLoaded(true);
                setUsersSubscriptions(subscriptionsStored);
                setLoadingSubscriptions(false);
            },
            (error) =>
            {
                setIsLoaded(true);
                setError(error);
                setLoadingSubscriptions(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() =>
    {
        if(!publicVersion && savingsPlan?.type !== undefined && savingsPlan.type !== SavingsPlanTypes.Experience)
        {
            SuitabilityTestsService.getLastSuitabilityTestVersion().then((suitabilityTestVersion: ISuitabilityTestVersion) =>
            {
                setSuitabilityTestVersion(suitabilityTestVersion);
            },
            (error) =>
            {
                setError(error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(suitabilityTestVersion !== undefined && savingsPlan?.savingsPlansUserIdentities !== undefined && savingsPlan.savingsPlansUserIdentities.length > 0)
        {
            var pendingSuitabilityTest = savingsPlan.savingsPlansUserIdentities.find((savingsPlanUserIdentity: SavingsPlanUserIdentityType) =>
            {
                if(savingsPlanUserIdentity.userIdentity === undefined)
                {
                    return true;
                }
                var currentDate = Moment();
                var birthdayDate = Moment(savingsPlanUserIdentity.userIdentity.birthday);
                var isMinor = currentDate.diff(birthdayDate, "years") < 18;

                if(isMinor)
                {
                    return false;
                }

                return (savingsPlanUserIdentity.userIdentity.suitabilityTests !== undefined
                    && savingsPlanUserIdentity.userIdentity.suitabilityTests.length > 0
                    && !savingsPlanUserIdentity.userIdentity.suitabilityTests.some(sT => sT.suitabilityTestVersionId === suitabilityTestVersion.id && sT.status === 'SIGNED'))
            });
            setHasSuitabilityTest(pendingSuitabilityTest === undefined);
        }
    }, [savingsPlan, suitabilityTestVersion]);

    const isDisabledSubscription = () =>
    {
        return !savingsPlanIsCompleted || savingsPlan?.type === undefined ||
            (savingsPlan.type !== SavingsPlanTypes.Experience && savingsPlan.type !== SavingsPlanTypes.Saver && savingsPlan.type !== SavingsPlanTypes.Alchemy);
    };

    const isDisabledConvenience = () =>
    {
        return publicVersion || !savingsPlanIsCompleted || savingsPlan?.type === undefined ||
                (savingsPlan.type !== SavingsPlanTypes.Experience && savingsPlan.type !== SavingsPlanTypes.Saver && savingsPlan.type !== SavingsPlanTypes.Alchemy);
    };

    const isDisabledSuitability = () =>
    {
        return publicVersion || !savingsPlanIsCompleted || savingsPlan?.status === undefined || savingsPlan.status < 2;
    };

    const isDisabledInitialContribution = () =>
    {
        return publicVersion || !savingsPlanIsCompleted || savingsPlan?.status === undefined || savingsPlan.status < 2 || !hasSuitabilityTest;
    };

    const isDisabledPeriodicContribution = () =>
    {
        return publicVersion || !savingsPlanIsCompleted || savingsPlan?.status === undefined || savingsPlan.status < 2 || !hasSuitabilityTest;
    };

    const isAlchemyAndDistributionLoaded = () =>
    {
        return savingsPlan?.type !== SavingsPlanTypes.Alchemy
            || (savingsPlan?.bmePortfolio?.distribution !== undefined && savingsPlan.bmePortfolio.distribution.length > 0)
            || (savingsPlan?.managedPortfolioId !== undefined);
    };

    const subscribe = () =>
    {
        setIsSubscribing(true);
        history.push(
        {
            pathname: `/subscription/${subscription?.id}`,
            state:
            {
                initialContribution: savingsPlan?.initialContribution,
                managedPortfolioId: savingsPlan?.managedPortfolioId
            },
        });
    };

    return(
        <div className="first-steps shadow">
            <div className="ms-Grid-col ms-sm12">
                <h3 style={{cursor: "default"}}>Da tus primeros pasos</h3>
                <Slider max={100} value={getProgress()} valueFormat={valueFormat} disabled={true} />
            </div>
            <div className="ms-Grid-col ms-sm12">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md3">
                        <div className={'step grey' + (isDisabledSubscription() ? ' disabled' : '')}>
                            <div className="step-content">
                                {!isSavingsPlanReady && !isDisabled &&
                                (
                                    <Spinner size={SpinnerSize.large} />
                                )}
                                {(isSavingsPlanReady || isDisabled) && (subscription !== undefined) &&
                                (
                                    <React.Fragment>
                                        <h4>{subscription.name}</h4>
                                        <h4>{decimalFormatter.format(subscription.price as number)} €/ {intl.messages[subscription?.period || '']}</h4>
                                        <p>{subscription?.description}</p>
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="step-footer text-center">
                                {savingsPlan?.type !== undefined && savingsPlan.type !== SavingsPlanTypes.Experience && savingsPlan.type !== SavingsPlanTypes.Saver && savingsPlan.type !== SavingsPlanTypes.Alchemy &&
                                (
                                    <React.Fragment>
                                        <Link to="/" className="disabledCursor" onClick={(event) => event.preventDefault()}>
                                            <DefaultButton className="button primary-button pending" disabled={!isSavingsPlanReady}>
                                                Próximamente
                                            </DefaultButton>
                                        </Link>
                                    </React.Fragment>
                                )}
                                {(savingsPlan?.type === undefined || savingsPlan.type === SavingsPlanTypes.Experience || savingsPlan.type === SavingsPlanTypes.Saver || savingsPlan.type === SavingsPlanTypes.Alchemy) &&
                                (
                                    <React.Fragment>
                                        {(!isSavingsPlanReady || (isDisabled !== undefined && isDisabled) ?
                                            <Link to="/" className="disabledCursor" onClick={(event) => event.preventDefault()}>
                                                <DefaultButton className="button primary-button pending" disabled={!isSavingsPlanReady && !publicVersion}>
                                                    {(publicVersion && <>Regístrate</>) || <>Suscribir</>}
                                                </DefaultButton>
                                            </Link>
                                            :
                                            (
                                                (!publicVersion &&
                                                    <React.Fragment>
                                                        {(savingsPlan?.id === undefined || !isLoaded) &&
                                                        (
                                                            <DefaultButton className="button primary-button pending" disabled=
                                                                {
                                                                    !isSavingsPlanReady
                                                                    || !isLoaded
                                                                    || !isAlchemyAndDistributionLoaded()
                                                                    || isSubscribing
                                                                }
                                                                onClick={() => subscribe()}
                                                            >
                                                                {isSubscribing &&
                                                                (
                                                                    <Spinner size={SpinnerSize.small} className="button-spinner" />
                                                                )}
                                                                Suscribir
                                                            </DefaultButton>
                                                        )}
                                                        {savingsPlan?.id !== undefined && isLoaded &&
                                                        (
                                                            <DefaultButton className="button primary-button" disabled={true} >
                                                                <Text>Ya estás suscrito</Text>
                                                                <i className="ironia-icon check" ></i>
                                                            </DefaultButton >
                                                        )}
                                                    </React.Fragment>
                                                ) ||
                                                (
                                                    <a href="https://store.ironia.tech" className="notDisabled" target="_blank" rel="noopener noreferrer">
                                                        <DefaultButton type="button" className="button primary-button pending" disabled={!isSavingsPlanReady} onClick={(event) => false}>
                                                            Regístrate
                                                        </DefaultButton>
                                                    </a>
                                                )
                                            )
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <Separator className="separator hiddenSmUp" />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md3">
                        <div className={"step "+(isDisabledConvenience() ? " disabled" : "")}>
                            <div className="step-content">
                                <h4>Datos fiscales obligatorios</h4>
                                <p>
                                    Antes de poder operar debes rellenar un breve test para confirmar ante la CNMV que
                                    sabes qué estás haciendo. Recuerda que debes tener tu DNI y al menos una nómina a
                                    mano.
                                </p>
                            </div>
                            {
                                ((!isSavingsPlanReady && !isDisabled) || !savingsPlanIsCompleted || savingsPlan?.status === undefined)
                                && (<Spinner size={SpinnerSize.large} />)
                            }
                            {(isSavingsPlanReady || isDisabled) && savingsPlanIsCompleted && savingsPlan?.status !== undefined &&
                            (
                                <div className="step-footer">
                                    {savingsPlan?.type !== undefined && savingsPlan.type !== SavingsPlanTypes.Experience && savingsPlan.type !== SavingsPlanTypes.Saver && savingsPlan.type !== SavingsPlanTypes.Alchemy &&
                                    (
                                        <React.Fragment>
                                            <Link to="/" className="disabledCursor" onClick={(event) => event.preventDefault()}>
                                                <DefaultButton className="button primary-button pending" disabled={!isSavingsPlanReady}>
                                                    Próximamente
                                                </DefaultButton>
                                            </Link>
                                        </React.Fragment>
                                    )}
                                    {(savingsPlan?.type === undefined || savingsPlan.type === SavingsPlanTypes.Experience || savingsPlan.type === SavingsPlanTypes.Saver || savingsPlan.type === SavingsPlanTypes.Alchemy) &&
                                    (
                                        <React.Fragment>
                                            {savingsPlan.id === undefined &&
                                            (
                                                <React.Fragment>
                                                    <Link to={`/convenience/${savingsPlan.id}`} className="disabledCursor" onClick={(event) => event.preventDefault()}>
                                                        <DefaultButton className="button primary-button pending" disabled={true}>
                                                            Conveniencia
                                                        </DefaultButton>
                                                    </Link>
                                                </React.Fragment>
                                            )}
                                            {savingsPlan.id !== undefined &&
                                            (
                                                <React.Fragment>
                                                    {savingsPlan.status === 0 &&
                                                    (
                                                        <Link to={`/convenience/${savingsPlan.id}`} className="disabledCursor" onClick={(event) => event.preventDefault()}>
                                                            <DefaultButton className="button primary-button pending"
                                                                disabled={!isSavingsPlanReady || publicVersion || !user.mainUserIdentity}
                                                                onClick={() => history.push(`/convenience/${savingsPlan.id}`)}
                                                            >
                                                                Conveniencia
                                                            </DefaultButton>
                                                        </Link>
                                                    )}
                                                    {savingsPlan.status === 1 &&
                                                    (
                                                        <DefaultButton className="button primary-button" disabled >
                                                            <Text>Pendiente de validaciÃ³n</Text>
                                                            <i className="ironia-icon check"></i>
                                                        </DefaultButton >
                                                    )}
                                                    {savingsPlan.status > 1 &&
                                                    (
                                                        <DefaultButton className="button primary-button" disabled >
                                                            <Text>Test realizado correctamente</Text>
                                                            <i className="ironia-icon check"></i>
                                                        </DefaultButton >
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            )}
                            <Separator className="separator hiddenSmUp" />
                        </div>
                    </div>
                    {savingsPlan?.type !== undefined && savingsPlan.type !== SavingsPlanTypes.Experience &&
                    (
                        <div className="ms-Grid-col ms-sm12 ms-md3">
                            <div className={"step" + (isDisabledSuitability() ? " disabled" : "")}>
                                <div className="step-content">
                                    <h4>Datos idoneidad de la inversión</h4>
                                    <p>
                                        Estos datos sólo los deberás rellenar si decides invertir a través de un gestor o un
                                        asesor. En ellos presentarás qué tipo de inversión deseas realizar.
                                    </p>
                                </div>
                                {!publicVersion && (((!isSavingsPlanReady && !isDisabled) || savingsPlan?.status === undefined || suitabilityTestVersion === undefined)) &&
                                (
                                    <Spinner size={SpinnerSize.large} />
                                )}
                                {(publicVersion || ((isSavingsPlanReady || isDisabled) && savingsPlan?.status !== undefined && suitabilityTestVersion !== undefined )) &&
                                (
                                    <div className="step-footer">
                                        <React.Fragment>
                                            {(publicVersion || (savingsPlan.status !== undefined && savingsPlan.status < 2) || !hasSuitabilityTest) &&
                                            (
                                                <Link to={`/suitabilityTest/${savingsPlan.id}`} className="disabledCursor" onClick={(event) => event.preventDefault()}>
                                                    <DefaultButton className="button primary-button pending" disabled={publicVersion || (savingsPlan.status !== undefined && savingsPlan.status < 2)}
                                                        onClick={(event) => history.push(`/suitabilityTest/${savingsPlan.id}`)}
                                                    >
                                                        Idoneidad
                                                    </DefaultButton>
                                                </Link>
                                            )}
                                            {hasSuitabilityTest &&
                                            (
                                                <DefaultButton className="button primary-button" disabled>
                                                    <Text>Test realizado correctamente</Text>
                                                    <i className="ironia-icon check" ></i >
                                                </DefaultButton>
                                            )}
                                        </React.Fragment>
                                    </div>
                                )}
                            </div>
                            <Separator className="separator hiddenSmUp" />
                        </div>
                    )}
                    {savingsPlan?.type !== undefined && savingsPlan.type !== SavingsPlanTypes.Experience &&
                    (
                        <div className="ms-Grid-col ms-sm12 ms-md3">
                            <div className={"step" + (isDisabledInitialContribution() ? " disabled" : "")}>
                                <div className="step-content">
                                    <h4>Aportación inicial</h4 >
                                    <p>Si seleccionas esta opción deberás indicar qué importe quieres invertir inicialmente.</p>
                                    <div className="number-control">
                                        <label className="ms-Label">Importe (euros)</label>
                                        <NumberFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            // @ts-ignore
                                            label="Â¿CuÃ¡l es tu dinero base?"
                                            placeholder="Escribe una cantidad..."
                                            value={savingsPlan?.initialContribution}
                                            onValueChange={debounce(
                                                (values: any) => onChangeQuantity(values),
                                                500
                                            )}
                                            suffix="€"
                                            className="ms-TextField-field"
                                        />
                                    </div>
                                </div>
                                <div className="step-footer text-right">
                                    <DefaultButton className="button primary-button" disabled={isDisabledInitialContribution()
                                            || (savingsPlan?.initialContribution !== undefined && savingsPlan?.initialContribution < 1)
                                            || (savingsPlan?.type === 0 && !isLoadedModelPortfolioComposition)
                                            || isAddingFunds
                                        }
                                        onClick={() =>
                                        {
                                            setIsAddingFunds(true);
                                            addFundsToCart();
                                        }}
                                    >
                                        {isAddingFunds && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                        Activar
                                    </DefaultButton>
                                </div>
                            </div>
                            <Separator className="separator hiddenSmUp" />
                        </div>
                    )}
                    <div className="ms-Grid-col ms-sm12 ms-md3">
                        <div className={"step" + (isDisabledPeriodicContribution() ? " disabled" : " disabled")}>
                            <div className="step-content">
                                <Checkbox />
                                <div className="checkbox-data">
                                    <h4>Inversión periódica</h4>
                                    <p>Si seleccionas esta opción deberás indicar qué importe quieres invertir y su periodicidad.</p>
                                    <div className="ms-Grid-col ms-sm6">
                                        <div className="number-control">
                                            <label className="ms-Label">Importe (euros)</label>
                                            <NumberFormat
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                value={savingsPlan?.periodicContribution}
                                                onChange={onChangePeriodicQuantity}
                                                suffix="€"
                                                className="ms-TextField-field"
                                            />
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6">
                                        <Dropdown
                                            defaultSelectedKey={savingsPlan?.periodicContributionPeriod}
                                            label="Periodicidad"
                                            options={options}
                                            styles={dropdownStyles}
                                            onChange={onChangeContributionPeriod}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )}

export default FirstSteps;
import { CartModes } from '../../screen/Cart/CartScreen';
import { ADD_FUND, UPDATE_FUND, REMOVE_FUND, CLEAR_CART, MODEL_PERCENTAGE_TOTAL, UPDATE_FUND_MODEL_PERCENTAGE, CART_STATE, MODEL_PORTFOLIO_NAME, PUNCTUAL_CONTRIBUTION_PORTFOLIO_ID } from './Constants';
import { saveCartState } from './keepCart';

interface ICartLine
{
    fund: any,
    amount: number,
    readOnly?: boolean,
    userSuscriptionId?: number,
    savingsPlanId?: number,
    source?: string,
    modelPercentage?: number
}
interface ICart
{
    cartLines: ICartLine[],
    modelPercentageTotal: Number | undefined,
    current_state?: CartModes,
    modelPortfolioName: string
}
export const INITIAL_STATE: ICart =
{
    cartLines: [],
    modelPercentageTotal: undefined,
    current_state: CartModes.DEFAULT,
    modelPortfolioName: ""
};

export default function cartReducer(state = INITIAL_STATE, action: any)
{
    let cartLines;
    let newState;
    switch(action.type)
    {
        case ADD_FUND:
            cartLines = [...state.cartLines];
            let alreadyIncludedFund = false;
            cartLines.forEach(line =>
            {
                if(line.fund.isin === action.payload.fund.isin)
                {
                    alreadyIncludedFund = true;
                    if(line.readOnly === undefined || line.readOnly === null || line.readOnly === false)
                    {
                        line.amount += action.payload.amount || 0;
                        line.modelPercentage = action.payload.modelPercentage || 0;
                    }
                }
            });

            if(!alreadyIncludedFund)
            {
                var newLine: ICartLine =
                {
                    fund: action.payload.fund,
                    amount: action.payload.amount || 0,
                    modelPercentage: action.payload.modelPercentage || 0,
                    readOnly: action.payload.readOnly || false
                };

                if(action.payload.userSuscriptionId !== null)
                {
                    newLine.userSuscriptionId = action.payload.userSuscriptionId;
                }

                if(action.payload.savingsPlanId !== null)
                {
                    newLine.savingsPlanId = action.payload.savingsPlanId;
                }

                if(action.payload.source !== null)
                {
                    newLine.source = action.payload.source;
                }

                cartLines.push(newLine);
            }

            newState = { ...state, cartLines };
            saveCartState(newState);
            return newState;

        case REMOVE_FUND:
            cartLines = state.cartLines.filter(line => line.fund.isin !== action.payload.isin);
            newState = { ...state, cartLines };
            saveCartState(newState);
            return newState;

        case UPDATE_FUND:
            cartLines = [...state.cartLines];
            cartLines = cartLines.map(line =>
            {
                if(line.fund.isin === action.payload.fund.isin)
                {
                    line.amount = action.payload.amount;
                }
                return line;
            });
            newState = { ...state, cartLines };
            saveCartState(newState);
            return newState;

        case CLEAR_CART:
            newState = { ...state, cartLines: [] };
            saveCartState(newState);
            return newState;

        case MODEL_PERCENTAGE_TOTAL:
            return { ...state, modelPercentageTotal: action.payload };

        case UPDATE_FUND_MODEL_PERCENTAGE:
            cartLines = [...state.cartLines];
            cartLines = cartLines.map(line =>
            {
                if(line.fund.isin === action.payload.fund.isin)
                {
                    line.modelPercentage = action.payload.modelPercentage;
                }
                return line;
            });
            newState = { ...state, cartLines };
            saveCartState(newState);
            return newState;

        case CART_STATE:
            return { ...state, current_state: action.payload };

        case MODEL_PORTFOLIO_NAME:
            return { ...state, modelPortfolioName: action.payload };
        case PUNCTUAL_CONTRIBUTION_PORTFOLIO_ID:
            return { ...state, activePunctualContribution: action.payload };
        default:
            return state;
    }
};
import { useContext, useEffect, useState } from "react";
import * as L from "./layout.styles";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { ThemeContext } from "@fluentui/react-theme-provider";
import SavingsPlanService, { SavingsPlanType } from "../../services/SavingsPlanService";
import { Cart } from "./components/Cart";
import Auth from "../../Auth/Auth";
import { connect, useDispatch } from "react-redux";
import { Spinner, SpinnerSize, Toggle, TextField, DefaultButton, Dropdown, IDropdownOption } from "@fluentui/react";
import PortfolioModelWalletService, { ModelWallet } from "../../services/PortfolioModelWalletService";
import { addLine, cartState, clearCart, setPunctualContributionPortfolioData, updateModelPercentageTotal, updateModelPortfolioName } from "../../store/cart/actions";
import DocumentTitle from "react-document-title";
import React from "react";
import UserIdentitiesService from "../../services/UserIdentitiesService";
import { ai } from '../../services/TelemetryService';
import { dropdownStyles } from "../../components/dropdown/dropdownStyles";

interface CartScreenProps extends RouteComponentProps
{
    cartLines: any[];
    modelPercentageTotal?: number;
    savingPlanType?: number;
    portfolioModelId?: number;
    current_state: CartModes;
    modelPortfolioName: string;
    activePunctualContribution: any;
}

export enum CartModes
{
    DEFAULT = "DEFAULT",
    VIEW_MODEL_PORTFOLIO = "VIEW_MODEL_PORTFOLIO",
    CREATE_MODEL_PORTFOLIO = "CREATE_MODEL_PORTFOLIO",
    PUNCTUAL_CONTRIBUTION = "PUNCTUAL_CONTRIBUTION"
}

function mapStateToProps(state: any)
{
    return { cartLines: state.cart.cartLines, modelPercentageTotal: state.cart.modelPercentageTotal, current_state: state.cart.current_state, modelPortfolioName: state.cart.modelPortfolioName, activePunctualContribution: state.cart.activePunctualContribution };
}
const TmpCartScreen = (props: CartScreenProps) =>
{
    const {modelPercentageTotal, current_state, modelPortfolioName, activePunctualContribution } = props;
    const location = useLocation();
    let state: any = location.state;
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useContext(ThemeContext);
    const plan = Auth.getActivePlan();
    let user = Auth.getUserProfile();
    const appInsights = ai.reactPlugin;
    const PromotionalPlanTypeId = 3;
    const ExperiencePlanTypeId = 4;
    const defaultModelWalletDropDownItem = {key: 0, id: "0", text: "Selecciona una Cartera Modelo"};

    //Common States
    const [planName, setPlanName] = useState(plan.name);
    const [planType, setPlanType] = useState(plan.type);
    const [loadingSavingsPlan, setLoadingSavingsPlan] = useState(false);
    const [currentInstrumentIds, setCurrentInstrumentsIds] = useState<string[]>();
    const [isCurrentInstrumentIdsLoaded, setIsCurrentInstrumentIdsLoaded] = useState<boolean>(false);
    const [error, setError] = useState({message: false});
    const [cartModeStatus, setCartModeStatus] = useState<CartModes>(current_state);

    //CREATE_MODEL_PORTFOLIO States
    const [toggleChecked, setToggleChecked] = useState<boolean>(current_state === CartModes.CREATE_MODEL_PORTFOLIO || current_state === CartModes.VIEW_MODEL_PORTFOLIO);
    const [loadingModelWallet, setLoadingModelWallet] = useState<boolean>(false);
    const [modelWalletName, setModelWalletName] = useState<string>(modelPortfolioName ?? "");

    //PUNCTUAL_CONTRIBUTION States
    const [savingPlanModelPortfolios, setSavingPlanModelPortfolios] = useState<IDropdownOption[]>([]);
    const [selectedModelPortfolio, setSelectedModelPortfolio] = useState<IDropdownOption | null>();

    useEffect(() =>
    {
        if(props.current_state !== cartModeStatus)
        {
            setCartModeStatus(current_state);
        }
        if(activePunctualContribution !== undefined && props.current_state === CartModes.PUNCTUAL_CONTRIBUTION && selectedModelPortfolio === undefined)
        {
            setSelectedModelPortfolio({key: activePunctualContribution.id.toString(), text: activePunctualContribution.name, id: activePunctualContribution.id});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() =>
    {
        if(props.cartLines.length > 0 && props.cartLines[0].savingsPlanId !== null && props.cartLines[0].savingsPlanId !== undefined)
        {
            setLoadingSavingsPlan(true);
            SavingsPlanService.getSavingsPlan(props.cartLines[0].savingsPlanId).then((savingsPlan: SavingsPlanType) =>
            {
                if(savingsPlan !== undefined)
                {
                    setPlanName(savingsPlan.name);
                    setPlanType(savingsPlan.type);
                    setLoadingSavingsPlan(false);
                }
            })
            .catch(error =>
            {
                setError(error);
            });
        }
    }, [props.cartLines]);

    useEffect(() =>
    {
        if(!isCurrentInstrumentIdsLoaded)
        {
            UserIdentitiesService.getInstrumentsIds(user.mainUserIdentity.id).then((currentInstrumentIds: string[]) =>
            {
                setCurrentInstrumentsIds(currentInstrumentIds);
                setIsCurrentInstrumentIdsLoaded(true);
            })
            .catch((error: any) =>
            {
                setError(error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(plan.id && (planType === ExperiencePlanTypeId || planType === PromotionalPlanTypeId))
        {
            PortfolioModelWalletService.getModelWallets(plan.id).then((response: ModelWallet[]) =>
            {
                let spModelPortfolios: IDropdownOption[] = response.map((portfolio: ModelWallet) =>
                ({
                    key: portfolio.id.toString(),
                    text: portfolio.name,
                    id: portfolio.id.toString()
                }));
                spModelPortfolios.unshift(defaultModelWalletDropDownItem);
                setSavingPlanModelPortfolios(spModelPortfolios);
                setIsCurrentInstrumentIdsLoaded(true);
            })
            .catch((error: any) =>
            {
                setError(error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plan.id, planType]);

    useEffect(() =>
    {
        if(selectedModelPortfolio && selectedModelPortfolio.id !== "0" && plan.id && planType)
        {
            setIsCurrentInstrumentIdsLoaded(false);
            dispatch(cartState(CartModes.PUNCTUAL_CONTRIBUTION));
            PortfolioModelWalletService.getModelWallet(plan.id, Number(selectedModelPortfolio.id)).then((response: ModelWallet) =>
            {
                setModelWalletName(response.name);
                dispatch(clearCart());
                if(response)
                {
                    let modelPercentageTotal = 0;
                    response.jsonFundsData.map(item =>
                    {
                        modelPercentageTotal += item.modelPercentage;
                        return dispatch(
                            addLine(
                            {
                                fund: item.fund,
                                amount: 0,
                                modelPercentage: item.modelPercentage,
                                readOnly: item.readOnly,
                                savingsPlanId: item.savingPlanId
                            })
                        );
                    });

                    dispatch(updateModelPercentageTotal(modelPercentageTotal));
                }
                setIsCurrentInstrumentIdsLoaded(true);
            });
        }
    }, [dispatch, plan.id, planType, selectedModelPortfolio]);

    const onChangeModelWalletDropDown = (item?: IDropdownOption) =>
    {
        if(item?.id !== undefined)
        {
            setSelectedModelPortfolio(savingPlanModelPortfolios.find(x => x.id === item?.id));
        }
    };

    const onChangeModelWalletToggle = (checked: boolean) =>
    {
        setToggleChecked(checked);
        dispatch(cartState((checked ? CartModes.CREATE_MODEL_PORTFOLIO : CartModes.DEFAULT)));
        setCartModeStatus(checked ? CartModes.CREATE_MODEL_PORTFOLIO : CartModes.DEFAULT);
        setSelectedModelPortfolio(undefined);
        dispatch(setPunctualContributionPortfolioData({name: "", id: undefined}));
    };

    const saveModelWalletOnClickHandler = () =>
    {
        setLoadingModelWallet(true);

        if(state?.portfolioModelId !== undefined)
        {
            PortfolioModelWalletService.updateModelWallet(plan.id!, modelWalletName, state?.portfolioModelId, props.cartLines).finally(() =>
            {
                dispatch(clearCart());
                dispatch(cartState(CartModes.DEFAULT));
                dispatch(updateModelPortfolioName(""));
                setLoadingModelWallet(false);
                history.push("/savingsPlans");
            });
        }
        else
        {
            PortfolioModelWalletService.saveModelWallet(plan.id!, modelWalletName, 0, props.cartLines).finally(() =>
            {
                dispatch(clearCart());
                dispatch(cartState(CartModes.DEFAULT));
                dispatch(updateModelPortfolioName(""));
                setLoadingModelWallet(false);
                history.push("/savingsPlans");
            });
        }
    };

    const modelWalletNameChangeHandler = (newValue: string | undefined) =>
    {
        if(newValue !== undefined)
        {
            setModelWalletName(newValue);
        }
    };

    return(
        <DocumentTitle title="IronIA - Carrito">
            <L.Main theme={theme}>
                <L.HeaderContainer>
                    <L.TitleContainer>
                        Carrito de Inversiones
                    </L.TitleContainer>
                    {plan !== undefined &&
                    (
                        <React.Fragment>
                            <L.PlanTitleContainer>
                                {loadingSavingsPlan && <Spinner size={SpinnerSize.small} className="button-spinner" style={{paddingTop: "4px"}} />}
                                {!loadingSavingsPlan && <L.SubTitleContainer>{planName}</L.SubTitleContainer>}
                            </L.PlanTitleContainer>
                        </React.Fragment>
                    )}
                    {(cartModeStatus === CartModes.DEFAULT || cartModeStatus === CartModes.PUNCTUAL_CONTRIBUTION) && (planType === ExperiencePlanTypeId || planType === PromotionalPlanTypeId) &&
                    (
                        <React.Fragment>
                            <L.DropdownContainer>
                                <L.LabelDropdown>
                                    Aportación Cartera Modelo
                                </L.LabelDropdown>
                                <Dropdown placeholder={"Selecciona una Cartera Modelo"}
                                    style={{minWidth: "300px"}}
                                    disabled={!isCurrentInstrumentIdsLoaded}
                                    selectedKey={selectedModelPortfolio?.key ?? undefined}
                                    options={savingPlanModelPortfolios as IDropdownOption[]}
                                    onChange={(event, item) =>
                                    {
                                        dispatch(cartState((CartModes.PUNCTUAL_CONTRIBUTION)));
                                        appInsights.trackEvent({name: "Cartera Modelo, Aportación Puntual", properties: {userId: user?.id, modelPortfolioId: selectedModelPortfolio?.id ?? 0}});
                                        onChangeModelWalletDropDown(item);
                                    }}
                                    styles={dropdownStyles}
                                />
                            </L.DropdownContainer>
                        </React.Fragment>
                    )}
                    {/* {props.current_state !== 'PUNCTUAL_CONTRIBUTION' &&
                    ( */}
                        <L.ModelWalletInputsContainer>
                            <L.ModelWalletToggle>
                                <Toggle label={<L.ModelWalletLabel>Cartera Modelo</L.ModelWalletLabel>}
                                    inlineLabel
                                    onText=" "
                                    offText=" "
                                    checked={toggleChecked}
                                    disabled={cartModeStatus === CartModes.VIEW_MODEL_PORTFOLIO || cartModeStatus === CartModes.PUNCTUAL_CONTRIBUTION}
                                    onChange={(ev, checked) =>
                                    {
                                        onChangeModelWalletToggle(checked ?? false);
                                    }}
                                    styles={
                                    {
                                        root: {margin: 0},
                                        label: {margin: 0, paddingBottom: 0, paddingTop: "8px"},
                                        container: {paddingTop: "13px"}
                                    }}
                                />
                            </L.ModelWalletToggle>
                            {(cartModeStatus === CartModes.CREATE_MODEL_PORTFOLIO || cartModeStatus === CartModes.VIEW_MODEL_PORTFOLIO) &&
                            (
                                <React.Fragment>
                                    <TextField className="model-wallet-textfield"
                                        theme={theme}
                                        value={modelWalletName}
                                        onChange={(e, newValue) => modelWalletNameChangeHandler(newValue)}
                                        disabled={cartModeStatus === CartModes.VIEW_MODEL_PORTFOLIO}
                                        styles={
                                        {
                                            field:
                                            {
                                                borderRadius: "100px !important"
                                            },
                                            fieldGroup:
                                            {
                                                "&:hover":
                                                {
                                                    borderRadius: "100px !important"
                                                },
                                                "&::after":
                                                {
                                                    borderRadius: "100px !important"
                                                },
                                                borderRadius: "100px !important",
                                                height: "35px !important"
                                            },
                                            root:
                                            {
                                                margin: "0 1em 0 0 !important",
                                                paddingTop: "7px"
                                            }
                                        }}
                                    />
                                    {loadingModelWallet && <Spinner size={SpinnerSize.medium} className="button-spinner" />}
                                    {!loadingModelWallet && cartModeStatus === CartModes.CREATE_MODEL_PORTFOLIO &&
                                    (
                                        <DefaultButton className="button mini-primary-button"
                                            disabled=
                                            {
                                                modelPercentageTotal !== 100 ||
                                                loadingModelWallet ||
                                                !isCurrentInstrumentIdsLoaded ||
                                                !modelWalletName
                                            }
                                            onClick={saveModelWalletOnClickHandler}
                                            styles={{root: {height: "30px", marginTop: "7px !important"}}}
                                        >
                                            Guardar
                                        </DefaultButton>
                                    )}
                                </React.Fragment>
                            )}
                        </L.ModelWalletInputsContainer>
                    {/* )} */}
                </L.HeaderContainer>
                <L.SeparatorTop />
                {!isCurrentInstrumentIdsLoaded && <Spinner size={SpinnerSize.large} className="button-spinner" />}
                {isCurrentInstrumentIdsLoaded &&
                (
                    <Cart cartModeStatus={props.current_state} savingPlanType={state?.savingPlanType} currentInstrumentIds={currentInstrumentIds}
                        onChangeCartModeStatus={(newStatus: CartModes) =>
                        {
                            dispatch(cartState((newStatus)));
                            if(newStatus === CartModes.DEFAULT)
                            {
                                setSelectedModelPortfolio(defaultModelWalletDropDownItem);
                            }
                        }}
                    />
                )}
            </L.Main>
        </DocumentTitle>
    );
};

export const CartScreen = connect(mapStateToProps)(TmpCartScreen);
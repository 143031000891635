import * as L from '../styles/OwnershipTableStyles';
import { RouteComponentProps } from 'react-router';
import OwnershipTableLogic from './OwnershipTableLogic';

interface RouteParams {};
interface Props extends RouteComponentProps<RouteParams>{};

export const OwnershipProfile: React.FunctionComponent<Props> = () =>
{
    return (
        <L.Wrapper>
            <L.TitleContainer>
                <L.Title>Titularidad de los Contratos</L.Title>
            </L.TitleContainer>
            <OwnershipTableLogic />
        </L.Wrapper>
    );
};
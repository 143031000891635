import "./index.sass";
import { BraindexCategory, BraindexImages } from "../../../services/TrainingCoursesService";
import React from "react";
import img from "../../../images/BraindexTemplate-min.jpg"
import { DefaultButton } from "office-ui-fabric-react";
import { ai } from '../../../services/TelemetryService';

type Props = {
	name: string,
	slug: string,
	permalink: string,
	featured: boolean,
	short_description: string,
	price: string,
	regular_price: string,
	sale_price: string,
	price_html: string,
	on_sale: boolean,
	average_rating: string,
	categories: BraindexCategory[],
	images: BraindexImages[]
}

const CardBaindex = (props: Props) => {
	const {
		name,
		permalink,
		short_description,
		price,
		categories,
		regular_price,
		sale_price,
		on_sale,
		images
	} = props;
	const appInsights = ai.reactPlugin;
	
	const handleClick = (url: any, name: string, price: string) => {
		appInsights.trackEvent({name: "Curso Baindex Link", properties: {nombreCurso: name, precio: price}});
		window.open(url, "_blank", "noreferrer");
	}

	return (
		<div className="training-course-baindex-item ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl4 ms-xxl3 ms-xxxl2">
			<div className="training-course-baindex-card" title={name}>
				<img src={images[0].src} alt={images[0].name} />
				<div className="categories">
					{categories.map((category, index) => (
						<div key={index}>{category.name}</div>
					))}
				</div>
				<div className="name">{name}</div>
				<div className="description">
					<div className="innerHtml" dangerouslySetInnerHTML={{ __html: short_description }} />
				</div>
				<div className="ms-Grid-row footer">
					<div className="ms-Grid-col ms-sm6 time"></div>
					<div className="ms-Grid-col ms-sm6 text-right price">
						<DefaultButton className="button mini-primary-button" onClick={() => handleClick(permalink, name, price)}>
							{price === "0" && "Gratuito"}
							{price !== "0" && on_sale === false && <>{regular_price}{" €"}</>}
							{price !== "0" && on_sale === true && (<><div className="regular-price">{regular_price}{" €"}</div><div className="sale-price">{sale_price}{" €"}</div></>)}
						</DefaultButton>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardBaindex;

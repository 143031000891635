import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px !important;
    gap: 20px;

    h3 {
        width: fit-content;
    }

    .flex10 {
         display: flex;
         gap: 10px;
    }
`;

export const CounterContainer = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 800;
    font-size: 18px;
    flex-grow: 1;
    padding: 5px 0;
    color: ${(props) => props.theme.palette.neutralText};

    h3
    {
        margin: 0;
        font-size: 18px;
        font-weight: 800;
        color: ${(props) => props.theme.palette.black};
    }
`;
export const SearchContainer = styled.div`
    display: flex;
    position: relative;
    flex-grow: 1;
    font-size: 8px;
    background: ${(props) => props.theme.palette.tertiary};
    border-radius: 16px;
    padding: 0 5px 0 0;

    input
    {
        width: 100%;
        border-radius: 16px;
        border: none;
        color: ${(props) => props.theme.palette.neutralText};
        font-size: 14px;
        padding-left: 10px;
    }
    img
    {
        padding-left: 10px;
        padding-right: 5px;
        width: 14px;
    }
`;
export const ActionsContainer = styled.div`
    display: flex;
    button
    {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
        background: ${(props) => props.theme.palette.white};
        padding: 0 10px;
        border-radius: 16px;
        color: ${(props) => props.theme.palette.lightBlack};
        border: 1px solid ${(props) => props.theme.palette.neutralLighter};
        cursor: pointer;
    }
`;
export const TableWrapper = styled.div`
    background: ${(props) => props.theme.palette.white};
    border-radius: 16px;
    box-shadow: 0px 0px 16px rgba(8, 84, 117, 0.08);
    padding: 25px 20px;
    margin-bottom: 20px !important;

    @media screen and (min-width: 1200px)
    {
        width: 90%;
        margin: 0 auto 0px auto;
    }
`;
export const TableContainer = styled.div`
    overflow-x: auto;
    display: flex;
    flex: 1;
`;
export const Table = styled.table`
    width: 100%;
    height: 100%;
    border-spacing: 0;
    text-align: left;
    thead
    {
        th
        {
            background: ${(props) => props.theme.palette.tertiary};
            padding: 17px;
        }
        th:first-of-type
        {
            border-top-left-radius: 10px;
        }
        th:last-of-type
        {
            border-top-right-radius: 10px;
        }
    }
    td
    {
        padding: 16px;
        font-size: 13px;
        color: ${(props) => props.theme.palette.selected};
        > a
        {
            color: ${(props) => props.theme.palette.darkBorder};
            font-weight: 600;
            line-height: 20px;
            font-size: 14px;
            cursor: pointer;
        }
    }
`;
export const ResumeHeader = styled.div`
    th:first-of-type
    {
        border-top-left-radius: 30px;
    }
    th:last-of-type
    {
        border-top-right-radius: 30px;
    }
    th
    {
        background: ${(props) => props.theme.palette.tertiary};
        padding: 17px;
    }
`;
export const ParticipationsContainer = styled.div`
    display: flex;
    justify-content: center;

    & .price-no-wrap
    {
        white-space: nowrap;
    }

    img
    {
        cursor: pointer;
        :hover
        {
        opacity: 0.6;
        }
    }

    a > * {
        vertical-align: middle;
        color: ${(props) => props.theme.palette.themePrimary};
    }

    a span {
        margin-left: 5px;
    }
`;
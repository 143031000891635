export const processHoursFunction = (processHours: any, hourFund: string) =>
{
    let hour: number = new Date().getHours();
    let minutes: number = new Date().getMinutes();
    let hourFundNumber: number = parseInt(hourFund.substring(0));
    let minuteFundNumber: number = parseInt(hourFund.substring(3));
    let i: number = 0;
    let intervalArray: number[] = [];
    let intervalRiskArray: number[] = [];
    let intervalActualHour: number = hour * 4;
    let intervalActualMinute: number = 0;
    let intervalFundHour: number = hourFundNumber * 4;
    let intervalFundMinute: number = 0;
    
    if(hour === 0 && minutes === 0)
    {
        return true;
    }
    else if(hour >= hourFundNumber)
    {
        return true;
    }
    else
    {
        while(processHours[i] !== undefined)
        {
            let intervalHour: number = parseInt(processHours[i].hour.substr(0));
            let intervalMinute: number = parseInt(processHours[i].hour.substr(3));
            let intervalRisk: number = processHours[i].risk;

            if(intervalHour === 0)
            {
                intervalArray.push(setNumberInterval(intervalMinute));
            }
            else
            {
                intervalArray.push((intervalHour * 4)+setNumberInterval(intervalMinute));
            }

            intervalRiskArray.push(setNumberInterval(intervalRisk));

            i++;
        }

        intervalFundMinute = setNumberInterval(minuteFundNumber);
        intervalActualMinute = setNumberInterval(minutes);

        for(let n=0; n<intervalArray.length; n++)
        {
            if(intervalArray[n] === 0 && intervalArray[n+1] !== undefined && intervalArray[n+1] !== 0 && intervalArray[n+1] <= intervalFundHour && (intervalActualHour + intervalFundMinute) < intervalFundMinute)
            {
                return false;
            }
            else
            {
                if(intervalActualHour+intervalActualMinute < intervalFundHour+intervalFundMinute)
                {
                    if(intervalArray[n] > intervalActualHour)
                    {
                        if(intervalArray[n+1] !== undefined)
                        {
                            if(intervalArray[n+1]+intervalRiskArray[n+1] < intervalFundHour+intervalFundMinute)
                            {
                                return false;
                            }
                            else
                            {
                                return true;
                            }
                        }
                        else
                        {
                            if(intervalArray[n]+intervalRiskArray[n] < intervalActualHour+intervalActualMinute)
                            {
                                return false;
                            }
                            else
                            {
                                return true;
                            }
                        }
                    }
                    else
                    {
                        if(intervalArray[n] === intervalActualHour && intervalArray[n+1] === undefined)
                        {
                            return true;
                        }
                        if(intervalArray[n] < intervalActualHour && intervalArray[n+1] === undefined)
                        {
                            return true;
                        }
                        if(intervalArray[n]+intervalRiskArray[n] > intervalActualHour+intervalActualMinute)
                        {
                            if(intervalArray[n+1] !== undefined && intervalArray[n+1]+intervalRiskArray[n+1] < intervalActualHour+intervalActualMinute)
                            {
                                return true;
                            }
                        }
                        else if(intervalArray[n]+intervalRiskArray[n] === intervalActualHour+intervalActualMinute)
                        {
                            if(intervalArray[n+1] !== undefined && intervalArray[n+1]+intervalRiskArray[n+1] < intervalActualHour+intervalActualMinute)
                            {
                                //Debe seguir iterando aquí.
                            }
                        }
                        else
                        {
                            //Debe seguir iterando aquí.
                        }
                    }
                }
                else
                {
                    return true;
                }
            }
        }

        return false;
    }
}

const setNumberInterval = (n: number) =>
{
    let result = 0;

    if(n < 15)
    {
        result = 0;
    }
    else if(n >= 15 && n <30)
    {
        result = 1;
    }
    else if(n >= 30 && n <45)
    {
        result = 2;
    }
    else
    {
        result = 3;
    }

    return result;
}
import { ReactElement } from "react";
import { ReactComponent as FacebookIcon } from '../images/facebook.svg';
import { ReactComponent as YoutubeIcon } from '../images/youtube.svg';
import { ReactComponent as XIcon } from '../images/X-Logo.svg';
import { ReactComponent as LinkedinIcon } from '../images/linkedin.svg';
import { DefaultButton, Stack } from "@fluentui/react";

export default function SocialNetworksLinks (): ReactElement {
    return (
        <Stack className="social-networks">
            <DefaultButton>
                <a href="https://www.facebook.com/Ironia-Fintech-102982654720710" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
                    <FacebookIcon />
                </a>
            </DefaultButton>
            <DefaultButton>
                <a href="https://www.youtube.com/channel/UChJiXVzR0iwllShhaJTnTaw" target="_blank" rel="noopener noreferrer" style={{ color: "white", maxWidth: "20 px !important" }}>
                    <YoutubeIcon />
                </a>
            </DefaultButton>
            <DefaultButton>
                <a href="https://twitter.com/IroniaTech" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
                    <XIcon />
                </a>
            </DefaultButton>
            <DefaultButton>
                <a href="https://www.linkedin.com/company/ironia-fintech/" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
                    <LinkedinIcon />
                </a>
            </DefaultButton>
        </Stack>
    )
}
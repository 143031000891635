import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import graphTheme from '../../graphTheme';
import { Spinner, SpinnerSize } from "@fluentui/react";

export enum GraphicType
{
    moneyGraphic = 1,
    linearGraphic = 2
}

type Props =
{
    series: any,
    customOptions: object | null,
    onChange?: (event: any, value?: any) => any;
    graphicType: GraphicType;
}

const HighStock: React.FC<Props> = ({series, customOptions, onChange, graphicType}) =>
{
    // @ts-ignore
    Highcharts.setOptions(graphTheme);
    var buttonPressed : number = 5;

    const [buttonStatePressed, setStateButton] = React.useState<number>(buttonPressed);

    const handleButton = (event: any) =>
    {
        if(event.currentTarget.textContent !== undefined && onChange !== undefined)
        {
            var pressed = event.currentTarget.textContent;

            if(pressed === "1m")
            {
                buttonPressed = 0;
            }
            if(pressed === "3m")
            {
                buttonPressed = 1;
            }
            if(pressed === "6m")
            {
                buttonPressed = 2;
            }
            if(pressed === "1y")
            {
                buttonPressed = 3;
            }
            if(pressed === "YTD")
            {
                buttonPressed = 4;
            }
            if(pressed === "Todo")
            {
                buttonPressed = 5;
            }

            setStateButton(buttonPressed);
            onChange(buttonPressed);
        }
    };
    const options = 
    {
        title:
        {
            text: ''
        }, // @ts-ignore
        series: series,
        plotOptions:
        {
            series:
            {
                compare: 'percent',
                showInNavigator: true
            }
        },
        rangeSelector:
        {
            allButtonsEnabled: true,
            selected: 5,
            buttons: [
            {
                type: 'month',
                count: 1,
                text: '1m',
                events:
                {
                    click: handleButton
                }
            },
            {
                type: 'month',
                count: 3,
                text: '3m',
                events:
                {
                    click: handleButton
                }
            },
            {
                type: 'month',
                count: 6,
                text: '6m',
                events:
                {
                    click: handleButton
                }
            },
            {
                type: 'year',
                count: 1,
                text: '1y',
                events:
                {
                    click: handleButton
                }
            },
            {
                type: 'ytd',
                text: 'YTD',
                events:
                {
                    click: handleButton
                }
            },
            {
                type: 'all',
                text: 'Todo',
                events:
                {
                    click: handleButton
                }
            }]
        },
        credits:
        {
            enabled: false,
        },
        yAxis:
        {
            labels:
            {
                formatter: function (item: any)
                {
                    return (item.value > 0 ? ' + ' : '') + item.value + '%';
                }
            },
            resize:
            {
                enabled: true
            },
            title:
            {
                text: ''
            }
        },
        tooltip:
        {
            // @ts-ignore
            pointFormatter: function ()
            {
                // @ts-ignore
                return this.series.name + ': ' + (this.y ? Highcharts.numberFormat(this.y, 4, ',', '.') : "") + ' (' + Highcharts.numberFormat(this.change, 2, ',', '.') + '%)';
            },
            valueSuffix: '',
            split: true
        },
        chart:
        {
            type: 'line',
            backgroundColor: 'none',
            height: 550,
        }
    };

    const [chartOptions, setChartOptions] = useState({...graphTheme, ...options, ...customOptions});

    useEffect(() =>
    {
        setChartOptions(prevState =>
        {
            return {...prevState, series: series}
        });
    }, [series]);

    useEffect(() =>
    {
        setChartOptions(prevState =>
        {
            if(buttonStatePressed !== -1)
            {
                prevState.rangeSelector.selected = buttonStatePressed;
            }

            return {...prevState, ...customOptions};
        });
    }, [customOptions, buttonStatePressed]);

    return(
        <React.Fragment>
            {(series.length === undefined || series.length === 0) &&
            (
                <Spinner size={SpinnerSize.large} />
            )}
            {series.length > 0 &&
            (
                <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'}
                    // @ts-ignore
                    options={chartOptions}
                />
            )}
        </React.Fragment>
    );
};

export default HighStock;
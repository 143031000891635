import { TooltipHost } from '@fluentui/react';
import { ActiveIcon } from '../../../components/icons/IconsTable';

export interface IRisk
{
    fundName: any,
    volatility: any,
    b_volatility: any,
    max_drawdown: any,
    b_max_drawdown: any,
    time_to_recovery: any,
    b_time_to_recovery: any,
    correlation: any,
    b_correlation: any,
    downside_deviation: any,
    b_downside_deviation: any,
    beta: any,
    b_beta: any,
    bull_beta: any,
    b_bull_beta: any,
    bear_beta: any,
    b_bear_beta: any,
    benchmark: any,
}

export const Risk = (props: IRisk) =>
{
    return (
        <table style={{marginTop: "1em"}}>
            <thead>
                <tr>
                    <th>Ratio / Producto</th>
                    <th>{props.fundName}</th>
                    <th>{props.benchmark}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Mide el tamaño de las desviaciones de la rentabilidad respecto a su media. Para contextualizar, la renta variable se mueve de media un 17% y la renta fija un 5%'>
                                Volatilidad
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.volatility}%</td>
                    <td>{props.b_volatility}%</td>
                    <td>{props.volatility < props.b_volatility ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Mide la mayor caída que haya tenido el fondo en el plazo que se considere expresado en porcentaje'>
                                Mayor caída
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.max_drawdown}%</td>
                    <td>{props.b_max_drawdown}%</td>
                    <td>{props.max_drawdown > props.b_max_drawdown ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Mide el tiempo que ha tardado el inversor que compró el fondo en el peor momento (en un máximo) en recuperar su inversión'>
                                Tiempo de recuperación
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.time_to_recovery} semanas</td>
                    <td>{props.b_time_to_recovery} semanas</td>
                    <td>{props.time_to_recovery < props.b_time_to_recovery ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Mide si las rentabilidades del fondo y el índice se mueven en la misma dirección. El mínimo es -1 que quiere decir que hacen lo opuesto y el máximo 1 que quiere decir que se mueven igual'>
                                Correlación
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.correlation}</td>
                    <td>{props.b_correlation}</td>
                    <td>{props.correlation < props.b_correlation ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Mide las desviaciones del fondo respecto a la media pero solo cuando éstas son negativas'>
                                Downside risk
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.downside_deviation}%</td>
                    <td>{props.b_downside_deviation}%</td>
                    <td>{props.downside_deviation < props.b_downside_deviation ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Mide como es la intensidad de movimientos del fondo respecto al índice. Por encima de 1 los amplifica y por debajo de 1 los mitiga'>
                                Beta
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.beta}</td>
                    <td>{props.b_beta}</td>
                    <td>{props.beta > props.b_beta ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Mide como es la intensidad de movimientos del fondo respecto al índice pero solo cuando estos son positivos. Si es mayor que 1 quiere decir que el fondo sube más que el índice'>
                                Bull Beta
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.bull_beta}</td>
                    <td>{props.b_bull_beta}</td>
                    <td>{props.bull_beta > props.b_bull_beta ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Mide como es la intensidad de movimientos del fondo respecto al índice pero solo cuando estos son negativos. Si es menor que 1 quiere decir que el fondo cae menos que el índice'>
                                Bear Beta
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.bear_beta}</td>
                    <td>{props.b_bear_beta}</td>
                    <td>{props.bear_beta < props.b_bear_beta ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
            </tbody>
        </table>
    );
};
import { useContext } from 'react';
import { Separator } from '@fluentui/react';
import { RouteComponentProps, Link, Redirect } from "react-router-dom";
import { ThemeContext } from '@fluentui/react-theme-provider';
import Auth from '../../Auth/Auth';
import { serverHost } from '../../config';
import * as L from './AlchemyTransferStyle';

interface BuyTransferScreenProps extends RouteComponentProps {}

export const AlchemyTransfer = (props: BuyTransferScreenProps) =>
{
    const theme = useContext(ThemeContext);
    const subscribed = Auth.isSubscribed();
    const plan = Auth.getActivePlan();
    const outdated = Auth.isLastPlanOutdated();
    const activeManagementPlan = Auth.isActiveManagementPlan();

    if(subscribed === false)
    {
        localStorage.clear();
        window.location.href = serverHost + '/login';
        return (<></>);
    }
    if(outdated === true)
    {
        return (<Redirect to="/savingsPlans" />);
    }
    if(activeManagementPlan === false)
    {
        return (<Redirect to="/Portfolio" />);
    }

    return(
        <L.Main theme={theme}>
            {plan !== undefined &&
                <div className="ms-Grid-row" style={{ minHeight: "20px" }}>
                    <div className="ms-Grid-col">
                        <L.TitleContainer>
                            <h2>Traspasos Externos desde un Fondo Origen</h2>
                        </L.TitleContainer>
                    </div>
                    <div className="ms-Grid-col" style={{paddingTop: "12px", color: "#CC214F"}}>
                        <L.SubTitleContainer>{plan.name}</L.SubTitleContainer>
                    </div>
                </div>
            }
            {plan === undefined &&
                <L.TitleContainer>
                    <h2>Traspasos Externos de un Fondo Origen</h2>
                </L.TitleContainer>
            }
            <L.SeparatorTop />
            <div className="ms-Grid restarted-ms-Grid">
                <div className="ms-Grid-row">
                    {/* <div className="ms-Grid-col ms-sm12 ms-xl6">
                        <L.CardContainer theme={theme}>
                            <L.CardHeader>
                                <L.CardHeadingContainer>
                                    <h3>Cambio de comercializador</h3>
                                </L.CardHeadingContainer>
                                <Separator />
                            </L.CardHeader>
                            <L.CardBody>
                                <L.Text theme={theme}>
                                    Puedes incrementar tu cartera realizando un cambio en la empresa que comercializa los fondos pasándolos a IronIA Fintech. Este cambio permite que
                                    <b> los fondos cambiados cotizen durante todo el proceso, pero no existe ningún tiempo definido</b> para hacer dicho proceso
                                    y las entidades comercializadoras origen pueden realizar acciones comerciales que ralenticen el proceso.
                                    La siguiente pantalla te guiará en la documentación necesaria para realizar un cambio de comercializador.
                                </L.Text>
                                <Link to="/distributor-switch">
                                    <L.CardButton size="lg">Cambio de comercializador</L.CardButton>
                                </Link>
                            </L.CardBody>
                        </L.CardContainer>
                    </div> */}
                    <div className="ms-Grid-col ms-sm12 ms-xl6">
                        <L.CardContainer theme={theme}>
                            <L.CardHeader>
                                <L.CardHeadingContainer>
                                    <h3>Traspaso externo de Fondo Origen</h3>
                                </L.CardHeadingContainer>
                                <Separator />
                            </L.CardHeader>
                            <L.CardBody>
                                <L.Text theme={theme}>
                                    Puedes incrementar tu cartera en IronIA traspasando fondos de inversión que ya tengas
                                    en otras entidades. Esta operación ofrece ventajas fiscales, ya que no hay peaje
                                    fiscal al no ser necesario disponer de liquidez en efectivo.
                                </L.Text>
                                <br/>
                                <L.Text theme={theme}>
                                    Y, sobre todo, lo más importante es que tendrás un ahorro económico considerable al
                                    poder acceder a las clases más baratas disponibles. Para traspasar un fondo necesitas
                                    tener a mano los datos de la operación: ISIN del fondo de origen,
                                    entidad comercializadora y número de cuenta en el que se encuentra.
                                </L.Text>
                                <L.ContainerButton>
                                    <Link to="/AlchemyTransfer/TransferScreen">
                                        <L.CardButton size="lg">Traspaso externo</L.CardButton>
                                    </Link>
                                </L.ContainerButton>
                                <L.TextSmall theme={theme}>
                                    El tiempo de un traspaso de fondos varía en función de la divisa y el mercado en el
                                    que se invierte. Lo habitual es que el proceso dure alrededor de una semana.
                                </L.TextSmall>
                            </L.CardBody>
                        </L.CardContainer>
                    </div>
                </div>
            </div>
        </L.Main>
    );
};
import { DefaultButton, Stack, mergeStyleSets } from "@fluentui/react";
import { Image } from 'office-ui-fabric-react/lib/Image';
import styled from "styled-components";

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    h2
    {
        margin: 0;
    }
`;
export const DetailsRowFooter = styled.div`
    .ms-DetailsRow
    {
        border-bottom: 0;
        .ms-DetailsRow-cell
        {
            flex-direction: row-reverse;
            border-right-width: 0 !important;
            &[data-automation-key="cleanShare"]
            {
                border-right-width: 1px !important;
            }
        }
    }
`;
export const PortfolioName = styled.h3`
    margin: 0 !important;
`;
export const TableDivRow = styled.div`
    text-align: end;
`;
export const SignatureDivRow = styled.div`
    margin-top: 1em !important;
`;
export const ColTable = styled.div`
    width: 100%;
    padding: 0;
    margin-top: 1em;
`;
export const ActionButtonsContainer = styled(Stack)`
    .ms-StackItem
    {
        padding-top: 4px;
        mergin-left: 5px;
        &:first-child
        {
            margin-left: 0;
        }
    }
`;
export const TablePercentage = styled.table`
    width: 100%;
`;
export const TBodyTable = styled.tbody``;
export const TrTable = styled.tr``;
export const TdPercentage = styled.td`
    width: 50%;
    height: 25px;
    margin: 0;
    padding: 0 12px;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
    text-align: center;
    border-right: 1px solid #e9eff1;
`;
export const TdCurrency = styled.td`
    width: 50%;
    height: 25px;
    margin: 0;
    padding: 0 12px;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
    text-align: center;
`;
export const LinkIsin = styled.div`
    color: #4284e6;
    text-decoration: underline;
    cursor: pointer;
    padding-top: 4px;
`;
export const IsinColumn = styled.div`
    padding-top: 4px;
    cursor: default;
`;
export const MinInvestment = styled.div`
    cursor: default;
    padding-top: 4px;
    text-align: end;
`;
export const MinApplied = styled.div`
    cursor: default;
    padding-top: 4px;
    text-align: end;
`;
export const CleanClass = styled.div`
    cursor: default;
    padding-top: 4px;
    text-align: end;
`;
export const Amount = styled.div`
    cursor: default;
    padding-top: 4px;
    text-align: end;
`;
export const SignButton = styled.button`
    margin-top: 2em;
    margin-right: 1em;
    padding: 10px 20px;
    background: #CC214F;
    color: white;
    cursor: pointer;
    border: 1px solid #CC214F;
    border-radius: 20px;
`;
export const ButtonSigned = styled(DefaultButton)`
    margin-top: 2em;
    margin-right: 1em;
    padding: 10px 20px;
    background: #CC214F;
    color: white;
    cursor: progress;
    opacity: 0.4;
    border: 1px solid #CC214F;
    border-radius: 20px;
`;
export const classNames = mergeStyleSets(
{
    text:
    {
        textAlign: "left"
    },
    number:
    {
        textAlign: "right",
        flexDirection: "row-reverse"
    },
    cellInput:
    {
        textAlign: "right",
        flexDirection: "row-reverse"
    },
    actions:
    {
        textAlign: "right",
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
    }
});
export const WarningContainerCart = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: #FFF1BE;
    color: #F69A2D;
    padding: 30px;
    gap: 10px;
    font-weight: 500;
    h3
    {
        margin: 0;
    }
    p
    {
        line - height: 20px;
        margin: 0;
    }

`;
export const SectionRow = styled.div`
    margin-top: 2em;
`;
export const ContainerIcon = styled.div`
    width: 100%;
`;
export const TitleWarning = styled.h3`
    cursor: default;
    display: flex;
`;
export const ParagraphWarning = styled.p`
    cursor: default;
`;
export const ModalRow = styled.div`
    margin: 0;
`;
export const ModalFirstCol = styled.div`
    text-align: center;
`;
export const ModalSecondCol = styled.div`
`;
export const ModalThirdCol = styled.div`
    padding-top: 1em;
`;
export const ModalImage = styled(Image)`
    height: 100px;
`;
export const ModalTitle = styled.h3`
    cursor: default;
`;
export const ModalBody = styled.p`
    cursor: default;
    margin: 0 !important;
    text-align: center;
`;
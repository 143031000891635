import { TooltipHost } from "@fluentui/react";
import { InfoIcon } from "@fluentui/react-icons-mdl2";

export interface IInterestData
{
    liquidationDaysOnSells: any,
    liquidationDaysOnBuys: any,
    ogc: any,
    managementFee: any,
    rebate: any,
    cleanShare: any,
    noMin: any,
    minimumInitialInvestment: any,
    minimumAdditionalInvestment: any,
    income: any,
    performanceFee: any,
    complexity: any
}

export const InterestData = (props: IInterestData) =>
{
    return(
        <table style={{marginTop: "1em"}}>
            <tbody>
                <tr>
                    <td>
                        <strong>Comisión de gestión</strong>
                    </td>
                    <td>{props.managementFee}%</td>
                </tr>
                <tr>
                    <td>
                        <strong>Comisión de éxito</strong>
                    </td>
                    <td>{props.performanceFee !== "-" ? props.performanceFee + "%": props.performanceFee}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Producto Complejo</strong>
                    </td>
                    <td>{props.complexity ? 'Si' : 'No'}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Gastos corrientes (OGC)</strong>
                    </td>
                    <td>{props.ogc}%</td>
                </tr>
                <tr>
                    <td>
                        <strong>Retrocesión</strong>
                    </td>
                    <td>{props.rebate > 0 ? `${props.rebate}%` : '-'}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Inversión inicial mínima</strong>
                    </td>
                    <td>
                        {Intl.NumberFormat("de-DE",
                            {
                                style: 'currency',
                                currency: 'EUR',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                                
                            }).format(props.minimumInitialInvestment)
                        }
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Aportación adicional mínima</strong>
                    </td>
                    <td>
                        {Intl.NumberFormat("de-DE",
                            {
                                style: 'currency',
                                currency: 'EUR',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,


                            }).format(props.minimumAdditionalInvestment)
                        }
                    </td>
                </tr>
                <tr>
                    <td>
                        <TooltipHost className="ironia-tooltip" content='Esta columna refleja la aplicación del mínimo de manera genérica para la clase del fondo. Puede que en una orden concreta si se aplique.'>
                            <strong>Aplica mínimo <InfoIcon /></strong>
                        </TooltipHost>
                    </td>
                    <td>{props.noMin ? 'Si' : 'No'}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Clase limpia</strong>
                    </td>
                    <td>{props.cleanShare ? 'Si' : 'No'}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Dividendos</strong>
                    </td>
                    <td>{props.income ? 'Si' : 'No'}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Días de liquidación en compras</strong>
                    </td>
                    <td>D + {props.liquidationDaysOnBuys}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Días de liquidación en ventas</strong>
                    </td>
                    <td>D + {props.liquidationDaysOnSells}</td>
                </tr>
            </tbody>
        </table>
    );
};
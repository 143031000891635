import { DefaultButton, DirectionalHint, MessageBar, MessageBarType, Separator, Spinner, SpinnerSize, TooltipHost } from "@fluentui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import DocumentTitle from "react-document-title";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import styled from "styled-components";
import BackButton from "../../components/buttons/BackButton";
import { ThemeContext } from "@fluentui/react-theme-provider";
import periodicContributionsService, { ITablePeriodicContributions } from "../../services/PeriodicContributionsService";
import CustomIcon from "../../components/icons/CustomIcon";
import { translateOptionPeriod } from "../../utils/translateOptionPeriod";
import inespay from "../../services/InespayService";
import { useId } from "@fluentui/react-hooks";
import { RootStateOrAny, useSelector } from "react-redux";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 80px;
    justify-content: center;
    align-items: flex-start;
`;
export const Table = styled.table`
    border-collapse: collapse;
    text-align: left;
    table-layout: fixed;
    & hr
    {
        display: none;
    }
    & tr
    {
        border-bottom: 2px solid #e9eff2;
    }
    & th:first-of-type
    {
        border-left: none;
        border-top-left-radius: 20px;
    }
    & th:last-of-type
    {
        border-right: none;
        border-top-right-radius: 20px;
    }
    & th
    {
        text-align: center;
        padding: 10px 10px 10px 10px;
        font-size: 14px;
        color: ${(props) => props.theme.palette.black} !important;
        border-left: 2px solid #e9eff2;
        border-right: 2px solid #e9eff2;
        background-color: ${(props) => props.theme.palette.grey} !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    & td:first-of-type
    {
        border-left: none;
    }
    & td:last-of-type
    {
        border-right: none;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        text-align: center;
        transform: translateY(2px);
    }
    & td
    {
        padding: 0px 15px 0px 15px;
        word-break: break-word;
        text-align: center;
        font-size: 15px;
        border-left: 2px solid #e9eff2;
        border-right: 2px solid #e9eff2;
    }
    & th: nth-child(1)
    {
        width: 30%;
    }
    & th: nth-child(2)
    {
        width: 30%;
    }
    & th: nth-child(3)
    {
        width: 30%;
    }
    & th: nth-child(4)
    {
        width: 10%;
    }
`;

interface RouteParams {};
interface Props extends RouteComponentProps<RouteParams>{};

const PeriodicContribution : React.FunctionComponent<Props>  = () =>
{
    const history = useHistory();
    const {state} = useLocation<any>();
    const theme = useContext(ThemeContext);
    const portfolioModelName = useRef<any>(state?.portfolioModelName);
    const portfolioModelId = useRef<any>(state?.portfolioModelId);
    const contract = useRef<any>(state?.contract);
    const firstTime = useRef<boolean>(true);
    const [data, setData] = useState<ITablePeriodicContributions[] | undefined>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState({status: false, message: ''});
    const [sending, setSending] = useState<boolean>(false);
    const tooltipId = useId('tooltip');
    const activeSavingsPlanHasOutdatedcontract = useSelector((state: RootStateOrAny) => state.auth.outdatedContract);

    useEffect(() =>
    {
        if(firstTime.current === true)
        {
            periodicContributionsService.getPeriodicContributions(portfolioModelId.current).then((response: ITablePeriodicContributions[]) =>
            {
                if(response !== undefined && response !== null)
                {
                    setData(response);
                    setLoading(false);
                }
            });
        }
    },[]);

    const deletePeriodicContribution = (id: number) =>
    {
        if(id > 0)
        {
            setSending(true);
            inespay.postCancelPeriodicContribution(id).then((data: any) =>
            {
                const inespay_result = JSON.parse(data.Result);
                if(inespay_result.status === '200')
                {
                    setSending(false);
                    window.location.href = inespay_result.url;
                }
                else
                {
                    setSending(false);
                    setError(
                    {
                        status: false,
                        message: "Se ha producido un error al comunicar con la pasarela bancaria. Inténtalo más tarde."
                    });
                }
            },
            (error: any) =>
            {
                setError(error);
                setSending(false);
            });
        }
    }
    
    return(
        <DocumentTitle title="IronIA - Aportaciones Periódicas Carteras Modelo">
            <React.Fragment>
                <div className="ms-Grid-row">
                    <BackButton />
                </div>
                <Wrapper>
                    <TitleContainer>
                        <h1>Aportaciones Periódicas</h1>
                        <p className="description" style={{margin: 0}}>Estas son todas las aportaciones periódicas basadas en la Cartera Modelo "{portfolioModelName.current}" que se realizan para el contrato "{contract.current}".</p>
                    </TitleContainer>
                    <Separator className="separatorRepayment" style={{marginTop: "0 !important"}} />
                    {error.message !== "" && (
                        <MessageBar messageBarType={MessageBarType.error} isMultiline={false} styles={{root: {marginBottom: "1.5em !important", borderRadius: "12px"}, content: {borderRadius: "12px"}}}>
                            {error.message}
                        </MessageBar>
                    )}
                    {sending === true && (
                        <MessageBar messageBarType={MessageBarType.info} isMultiline={false} styles={{root: {marginBottom: "1.5em !important", borderRadius: "12px"}, content: {background: "lightcyan", borderRadius: "12px"}}}>
                            Procesando la cancelación periódica...
                        </MessageBar>
                    )}
                    {loading === false && (
                        <React.Fragment>
                            <Table theme={theme}>
                                <thead>
                                    <tr>
                                        <th style={{paddingLeft: 16}}>
                                            Periodo
                                        </th>
                                        <th style={{paddingLeft: 16}}>
                                            Aportación
                                        </th>
                                        <th style={{paddingLeft: 16}}>
                                            IBAN
                                        </th>
                                        <th style={{paddingLeft: 16}}>
                                            Acciones
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data !== undefined && data.length > 0 && data !== null && data.map((item: ITablePeriodicContributions, index) => 
                                    {
                                        return(
                                            <React.Fragment key={item.id+"-"+index}>
                                                <tr>
                                                    <td>{translateOptionPeriod(item.period)}</td>
                                                    <td>{item.amount} €</td>
                                                    <td>{item.iban}</td>
                                                    <td>
                                                        <TooltipHost content="Eliminar Aportación Periódica"
                                                            id={tooltipId}
                                                            styles={{root: {display: "block"}}}
                                                            calloutProps={
                                                            {
                                                                gapSpace: 0,
                                                                beakWidth: 12,
                                                                directionalHint: DirectionalHint.topCenter,
                                                                styles:
                                                                {
                                                                    root:{right: "119px !important"}
                                                                }
                                                            }}
                                                        >
                                                            <CustomIcon iconName="Delete" color="#CC214F" size="17px" onClick={() => deletePeriodicContribution(item.id)} />
                                                        </TooltipHost>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 text-right" style={{marginTop: "1em"}}>
                                    <DefaultButton
                                        className="button tiny-stretch-button"
                                        disabled={activeSavingsPlanHasOutdatedcontract}
                                        onClick={() => history.push(
                                        {
                                            pathname: `/ModelPortfolio/ManagePeriodicContributions/${portfolioModelId.current}`,
                                            state:
                                            {
                                                portfolioModelId: portfolioModelId.current,
                                                portfolioModelName: portfolioModelName.current,
                                                contract: contract.current
                                            }
                                        })}
                                    >
                                        Añadir Aportación Periódica
                                    </DefaultButton>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    {loading === true && (<Spinner size={SpinnerSize.large} />)}
                </Wrapper>
            </React.Fragment>
        </DocumentTitle>
    );
}

export default PeriodicContribution;
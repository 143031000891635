import { useParams } from "react-router";
import SuitabilityTest from "../../components/SuitabilityTests/SuitabilityTest";

const SuitabilityTestForComplexProduct = () => {
    const { savingsPlanId, isin, userIdentityId } = useParams<any>();

    return (
        <SuitabilityTest
            savingsPlanId={savingsPlanId}
            userIdentityId={userIdentityId}
            confirmUrl={`/savingsPlans/${savingsPlanId}/complexProducts/${isin}/suitabilityTestConfirm/${userIdentityId}`} />
    );
}

export default SuitabilityTestForComplexProduct;
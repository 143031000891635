export const currencyFormatter = new Intl.NumberFormat('de-DE',
{
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const currencyNeutreFormatter = new Intl.NumberFormat('de-DE',
{
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const longCurrencyNeutreFormatter = new Intl.NumberFormat('de-DE',
{
    minimumFractionDigits: 2,
    maximumFractionDigits: 6
});

export const percentFormatter = new Intl.NumberFormat('es-ES',
{
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const longPercentFormatter = new Intl.NumberFormat('es-ES',
{
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 10
});

export const decimalFormatter = new Intl.NumberFormat('es-ES',
{
    style: 'decimal',
    maximumFractionDigits: 4,
    useGrouping: true
});

export const longDecimalFormatter = new Intl.NumberFormat('es-ES',
{
    style: 'decimal',
    maximumFractionDigits: 6
});

export const decimalMaximumLimitFormatter = new Intl.NumberFormat('es-ES',
{
    style: 'decimal',
    maximumFractionDigits: 20
});

export function roundToDecimalPlaces(number: number, decimalPlaces?: number)
{
	const multiplier = 10 ** (decimalPlaces ?? 6);
	return Math.round(number * multiplier) / multiplier;
}

export const setCurrency = (currency: string, value: number) =>
{
    return setLocaleCurrency(currency, 123.45).replace(/123[.,]45/, currencyNeutreFormatter.format(value));
}

export const setLongCurrency = (currency: string, value: number) =>
{
    return setLocaleCurrency(currency, 123.45).replace(/123[.,]45/, longCurrencyNeutreFormatter.format(value));
}

export const setLocaleCurrency = (currency: string, value: number) =>
{
    switch(currency)
    {
        case "AED":
            var currencyFormatterAED: Intl.NumberFormat = new Intl.NumberFormat('ar-AE',
            {
                style: 'currency',
                currency: 'AED',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterAED.format(value);

        case "AUD":
            var currencyFormatterAUD: Intl.NumberFormat = new Intl.NumberFormat('en-AU',
            {
                style: 'currency',
                currency: 'AUD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterAUD.format(value);

        case "CAD":
            var currencyFormatterCAD: Intl.NumberFormat = new Intl.NumberFormat('en-CA',
            {
                style: 'currency',
                currency: 'CAD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterCAD.format(value);

        case "CHF":
            var currencyFormatterCHF: Intl.NumberFormat = new Intl.NumberFormat('fr-CH',
            {
                style: 'currency',
                currency: 'CHF',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterCHF.format(value);

        case "CNY":
            var currencyFormatterCNY: Intl.NumberFormat = new Intl.NumberFormat('zh-CN',
            {
                style: 'currency',
                currency: 'CNY',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterCNY.format(value);

        case "DKK":
            var currencyFormatterDKK: Intl.NumberFormat = new Intl.NumberFormat('da-DK',
            {
                style: 'currency',
                currency: 'DKK',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterDKK.format(value);

        case "GBP":
            var currencyFormatterGBP: Intl.NumberFormat = new Intl.NumberFormat('en-GB',
            {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterGBP.format(value);

        case "HKD":
            var currencyFormatterHKD: Intl.NumberFormat = new Intl.NumberFormat('zh-HK',
            {
                style: 'currency',
                currency: 'HKD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterHKD.format(value);

        case "INR":
            var currencyFormatterINR: Intl.NumberFormat = new Intl.NumberFormat('hi-IN',
            {
                style: 'currency',
                currency: 'INR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterINR.format(value);

        case "JPY":
            var currencyFormatterJPY: Intl.NumberFormat = new Intl.NumberFormat('ja-JP',
            {
                style: 'currency',
                currency: 'JPY',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterJPY.format(value);

        case "MXN":
            var currencyFormatterMXN: Intl.NumberFormat = new Intl.NumberFormat('es-MX',
            {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterMXN.format(value);

        case "NOK":
            var currencyFormatterNOK: Intl.NumberFormat = new Intl.NumberFormat('nb-NO',
            {
                style: 'currency',
                currency: 'NOK',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterNOK.format(value);

        case "PLN":
            var currencyFormatterPLN: Intl.NumberFormat = new Intl.NumberFormat('pl-PL',
            {
                style: 'currency',
                currency: 'PLN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterPLN.format(value);

        case "SEK":
            var currencyFormatterSEK: Intl.NumberFormat = new Intl.NumberFormat('sv-SE',
            {
                style: 'currency',
                currency: 'SEK',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterSEK.format(value);

        case "SGD":
            var currencyFormatterSGD: Intl.NumberFormat = new Intl.NumberFormat('zh-SG',
            {
                style: 'currency',
                currency: 'SGD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterSGD.format(value);

        case "US":
        case "USD":
            var currencyFormatterUSD: Intl.NumberFormat = new Intl.NumberFormat('de-DE',
            {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterUSD.format(value);

        default:
            var currencyFormatterEUR: Intl.NumberFormat = new Intl.NumberFormat('de-DE',
            {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return currencyFormatterEUR.format(value);
    }
}
import React from "react";
import CustomIcon from "../icons/CustomIcon";
import * as Style from "./RelationPlansStyle";
import { ChevronDownIcon, ChevronUpIcon } from "@fluentui/react-icons-mdl2";
import { GenericMessageModal } from "../Modals/GenericMessageModal";
import { CancelIcon } from "@fluentui/react-icons-mdl2";

export interface ITableSPlansRelational
{
    id: number;
    planName: string;
    startDate: string;
    validDate: string;
    outdated?: boolean;
    outdatedContract?: boolean;
    cancelled?: boolean;
    status: any;
    type: number;
    selected: boolean;
    subscriptionId?: number;
    userSubscriptionId?: number;
    portfolio: any;
    portfolioAmount: number;
    finametrixContract: string;
    isExpanded: boolean;
    canBeDeleted?: boolean;
    managedPortfolioFinametrixId?: string;
    portfolioModels?: PortfolioModelType[];
    configurationMapStep: string;
    relation: any;
    endDate: any;
    periodicRenovation: boolean;
}
type PortfolioModelType =
{
    id: number;
    name: string;
    savingPlanId: number;
    userSubscriptionId: number;
    contract: string;
    deletable: boolean;
};
export interface ITableProps
{
    columnTitles: string[];
    rowData: ITableSPlansRelational[] | undefined;
    theme: any;
    onClick?: (event: any, item: any, hTR: any) => any;
    onSubmit?: (item: string, id: number) => any;
}

export const TableRelationPlans = (props: ITableProps) =>
{
    const [data, setData] = React.useState<ITableSPlansRelational[]>();
    const [cancelSubscription, setCancelSubscription] = React.useState<boolean>(false);

    React.useEffect(() =>
    {
        if(props.rowData !== undefined)
        {
            setData(props.rowData);
        }
    }, [props.rowData]);

    function displayArrow(expanded: boolean, list: any[] | undefined): JSX.Element
    {
        if(list)
        {
            if(list?.length <= 0)
            {
                return <ChevronDownIcon className="savingPlans-TableArrowHidden" />;
            }
            if(expanded && list?.length > 0)
            {
                return <ChevronUpIcon className="savingPlans-TableArrow" />;
            }
            if(!expanded && list?.length > 0)
            {
                return <ChevronDownIcon className="savingPlans-TableArrow" />;
            }
        }
        return <ChevronDownIcon className="savingPlans-TableArrowHidden" />;
    }

    return(
        <React.Fragment>
            <Style.TableRelation theme={props.theme}>
                <thead>
                    <tr>
                        {props.columnTitles.length > 0 && props.columnTitles.map((column, columnIndex) =>
                        {
                            return(
                                <th style={{textAlign: "center"}} key={"SPlansActiveColumnIndex" + columnIndex}>
                                    {column}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data !== undefined && data.length > 0 && data.map((row, index) =>
                    {
                        return(
                            <React.Fragment key={"SPlansActiveRowIndex"+index}>
                                <React.Fragment>
                                    {row.selected === true &&
                                    (
                                        <Style.SelectedTR theme={props.theme}>
                                            <td onClick={() =>
                                                {
                                                    row.isExpanded = !row.isExpanded;
                                                    setData([...data]);
                                                }}
                                            >
                                                <CustomIcon iconName="BullseyeTargetEdit"
                                                    size="15"
                                                    color="#08FF08"
                                                    paddingBottom="1px"
                                                    verticalAlign="middle"
                                                    cursor="default"
                                                />
                                                {displayArrow(row.isExpanded, row.portfolioModels)}
                                                {row.planName}
                                            </td>
                                            <td>{row.relation}</td>
                                            <td>{row.startDate}</td>
                                            <td>{row.endDate}</td>
                                            <td>
                                                {row.status === "4" ? "Activo"
                                                    : row.status === "5" ? "Caducado"
                                                    : row.status === "6" ? "Cancelado"
                                                    : row.status
                                                }
                                            </td>
                                        </Style.SelectedTR>
                                    )}
                                    {row.selected === false &&
                                    (
                                        <tr>
                                            <td onClick={() =>
                                                {
                                                    row.isExpanded = !row.isExpanded;
                                                    setData([...data]);
                                                }}
                                            >
                                                {displayArrow(row.isExpanded, row.portfolioModels)}
                                                {row.planName}
                                            </td>
                                            <td>{row.relation}</td>
                                            <td>{row.startDate}</td>
                                            <td>{row.endDate}</td>
                                            <td>
                                                {row.status === "4" ? "Activo"
                                                    : row.status === "5" ? "Caducado"
                                                    : row.status === "6" ? "Cancelado"
                                                    : row.status
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                                {row && row.portfolioModels && row.portfolioModels.map((modelWallet: PortfolioModelType, subIndex) =>
                                {
                                    return(
                                        <React.Fragment key={row.id + "_modelWalletRow_" + subIndex + "_" + modelWallet.id}>
                                            {row.isExpanded &&
                                            (
                                                <tr>
                                                    <td colSpan={9} style={{paddingLeft: "50px", height: "50px"}}>
                                                        {modelWallet.name}
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                                {cancelSubscription ?
                                (
                                    <GenericMessageModal headerLabel="Cancelar renovación periódica" icon={<CancelIcon />}
                                        bodyLabel={`Si pulsas en el botón aceptar se cancelará la renovación periódica de la cartera `}
                                    />
                                ) :
                                (
                                    <></>
                                )}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Style.TableRelation>
        </React.Fragment>
    );
};

export default TableRelationPlans;
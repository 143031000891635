import React, { useContext, useEffect, useState } from "react";
import OrderService, { OrderType } from "../../../services/OrderService";
import Auth from "../../../Auth/Auth";
import { ISignature } from "../../../services/SignaturesService";
import { ai } from "../../../services/TelemetryService";
import { ThemeContext } from "@fluentui/react-theme-provider";
import { useHistory } from "react-router";
import { currencyFormatter, decimalMaximumLimitFormatter } from "../../../utils/numberFormatter";
import { WarningContainer, WarningText } from "../../Elements/ElementStyles";
import BalancerSignature from "../../../components/Portfolio/Balancer/BalancerSignature";
import { DefaultButton, DetailsList, Selection, SelectionMode, Spinner, SpinnerSize } from "@fluentui/react";
import { Link, useLocation } from "react-router-dom";

type Props = {
	orders: any[];
};

const BalancerOrdersConfirmation = (props: Props) => {
	const user = Auth.getUserProfile();
	const activePlan = Auth.getActivePlan();
	const appInsights = ai.reactPlugin;
	const location = useLocation();
	const history = useHistory();
	const themeContext = useContext(ThemeContext);
	const [generatedOrders, setGeneratedOrders] = useState<any[]>([]);
	const [signatures, setSignatures] = useState<ISignature[]>([]);
	const [internalTransfersError, setInternalTransfersError] = useState<string>("");
	const [loadingInternalTransfers, setLoadingInternalTransfers] = useState<boolean>(false);
	const [signedSuccess, setSignedSuccess] = useState<boolean>(false);
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const selection = new Selection({
		onSelectionChanged: () => {
			updateSelection()
		}
	});

	const updateSelection = () => {
		setSelectedItems(selection.getSelectedIndices());
	}

	useEffect(() => {
		setGeneratedOrders(props?.orders ?? (location.state as any)?.orders ?? []);
	}, []);

	useEffect(() => {
		selection.setAllSelected(true);
	}, [generatedOrders]);

	const createOrdersMovements = () => {
		setLoadingInternalTransfers(true);
		setInternalTransfersError("");
		let orders: OrderType[] = [];
		generatedOrders
			?.filter((order, index) => selectedItems.includes(index) && order.skip !== true)
			?.forEach(order => {
				let orderType: any = {
					userIdentityId: user?.mainUserIdentity?.id,
					fnmPortfolioId: activePlan?.portfolio?.id,
					orderType: 2, //Internal
					status: 0,
					values: {
						FundName: order.sourceName,
						FundIsin: order.sourceIsin,
						Fund: order.sourceId,
						Amount: order.titles,
						AmountType: "titles",
						type: "titles",
						newInstrument: order.targetId,
						newInstrumentName: order.targetName
					},
					finametrixId: order.sourceId
				};
				orders.push(orderType);
			});

		OrderService.createInternalTransfersOrders(orders)
			.then(response => {
				setSignatures([response]);
			})
			.catch(() => {
				setInternalTransfersError(
					"Lo sentimos ha ocurrido un error inesperado al procesar las ordenes, intentaremos resolverlo lo antes posible, disculpe las molestias."
				);
				appInsights.trackEvent({
					name: "Rebalanceo createInternalTransfersOrders Failed",
					properties: { userId: user.id, orders: orders }
				});
			})
			.finally(() => {
				setLoadingInternalTransfers(false);
			});
	};

	const columns: any = [
		{ key: 'source', name: 'Fondo Origen', fieldName: 'order.sourceName', minWidth: 300, width: 300, isResizable: true },
		{ key: 'target', name: 'Fondo Destino', fieldName: 'order.targetName', minWidth: 300, width: 300, isResizable: true },
		{ key: 'amount', name: 'Cantidad', fieldName: 'amount', minWidth: 150, width: 150, isResizable: true },
		{ key: 'titles', name: 'Títulos', fieldName: 'titles', minWidth: 150, width: 150, isResizable: true },
	];

	const renderItemColumn = (item: any, index: any, column: any) => {
		switch (column.key) {
			case "source":
				return (
					<React.Fragment>
						<Link to={`/${item.sourceIsin}`}>
							<span className={"column-title " + item.type}>
								{item.sourceName}
							</span>
						</Link>
						<br />
						<span className={"column-title " + item.type}>
							{item.sourceIsin}
						</span>
					</React.Fragment>
				);
			case "target":
				return (
					<React.Fragment>
						<Link to={`/${item.targetIsin}`}>
							<span className={"column-title " + item.type}>
								{item.targetName}
							</span>
						</Link>
						<br />
						<span className={"column-title " + item.type}>
							{item.targetIsin}
						</span>
					</React.Fragment>
				);

			case "amount":
				return (
					<span className="text-right block full-width">
						{currencyFormatter.format(item.amount)}
					</span>
				);

			case "titles":
				return (
					<span className="text-right block full-width">
						{decimalMaximumLimitFormatter.format(item.titles)}
					</span>
				);
		}
	}

	return (
		<>
			<div style={{ margin: "5px" }}>
				{generatedOrders.length > 0 && (
					<>
						<h2 style={{ paddingBottom: "20px" }}>Conjunto de operaciones de traspasos</h2>
						<DetailsList
							items={generatedOrders}
							columns={columns}
							selectionMode={SelectionMode.multiple}
							onRenderItemColumn={renderItemColumn}
							selection={selection}
							selectionPreservedOnEmptyClick={true}
						/>
						{internalTransfersError !== "" && (
							<div style={{ padding: "10px 0px 10px 0px" }}>
								<WarningContainer theme={themeContext}>
									<WarningText>{internalTransfersError}</WarningText>
								</WarningContainer>
							</div>
						)}
						<div style={{ float: "inline-end", paddingTop: "10px", paddingBottom: "20px" }}>
							<DefaultButton
								className="button tiny-primary-button"
								disabled={loadingInternalTransfers || signatures.length > 0 || selectedItems.length === 0}
								onClick={() => {
									setLoadingInternalTransfers(true);
									createOrdersMovements();
								}}
							>
								<div style={{ display: "flex" }}>
									{loadingInternalTransfers && <Spinner size={SpinnerSize.xSmall} style={{ paddingRight: "5px" }} />}
									{!loadingInternalTransfers && selectedItems.length === 1 && <>Generar 1 traspaso interno</>}
									{!loadingInternalTransfers && selectedItems.length !== 1 && <>Generar {selectedItems.length} traspasos internos</>}
								</div>
							</DefaultButton>
						</div>
					</>
				)}
			</div>
			{signatures.length > 0 && (
				<div style={{ margin: "5px" }}>
					<div style={{ margin: "5px 0px" }}>
						<BalancerSignature
							defaultSignatures={signatures}
							isins={
								generatedOrders &&
								generatedOrders
									.filter((order, index) => selectedItems.includes(index) && order.skip !== true)
									.map(order => {
										return { origin: order.sourceIsin, destiny: order.targetIsin };
									})
							}
							userIdentities={[user.mainUserIdentity]}
							onCancelSignature={(cancel: boolean) => {
								if (cancel === true) {
									setSignatures([]);
								}
							}}
							onSignedSuccess={() => {
								setSignedSuccess(true);
								history.replace('/portfolio/orders');
							}}
						></BalancerSignature>
					</div>
				</div>
			)}
			{signedSuccess && (
				<div style={{ display: "inline-block", paddingTop: "10px" }}>
					<DefaultButton
						className="button tiny-primary-button"
						onClick={() => {
							setSignedSuccess(false);
							history.push("/");
						}}
					>
						<div style={{ display: "flex" }}>Volver a la cartera</div>
					</DefaultButton>
				</div>
			)}
		</>
	);
};

export default BalancerOrdersConfirmation;

import { DefaultButton, IconButton, IIconProps, Modal, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { useState } from 'react';
import SharedPortfoliosService, { SharedPortfolioType } from '../../../services/SharedPortfoliosService';
import * as L from './layout.styles';

const cancelIcon: IIconProps = { iconName: 'Cancel' };

export const ModalSharedPortfolioName = ({
    isModalOpen,
    hideModal,
    _onSubmit,
    theme,
    sharedPortfolio,
    sending,
    setSending
}: {
    isModalOpen: boolean,
    hideModal: () => void,
    _onSubmit: any,
    theme: any,
    sharedPortfolio?: SharedPortfolioType,
    sending: boolean,
    setSending: any
}) => {
    const [value, setValue] = useState("");
    const [error, setError] = useState("");

    const handleChange = (e: any) => {
        setValue(e.target.value);

        if(e.target.value !== "" && e.target.value !== undefined) {
            setError("");
        }
    }

    const onSubmit = async () => {
        if(value === "" || value === undefined) {
            setError("Debes introducir un valor válido");
        }
        else {
            setSending(true);
            SharedPortfoliosService.validateName(value)
                .then(
                    (nameIsValid: boolean) => {
                        if(nameIsValid) {
                            setError("");
                            _onSubmit(value);
                        } else {
                            setError("El nombre de la cartera compartida ya existe");
                            setSending(false);
                        }
                    },
                    (error) => {
                        setError(error);
                        setSending(false);
                    });
        }
    }

    return(
        <Modal
            isOpen={isModalOpen}
            onDismiss={hideModal}
            className="share-portfolio"
        >
            <L.StyledModal theme={theme}>
                <IconButton
                    iconProps={cancelIcon}
                    ariaLabel="Cerrar"
                    onClick={hideModal}
                    className="close-button"
                />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <h3>Compartir cartera</h3>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <TextField
                            required
                            label="Seudónimo de la cartera"
                            placeholder="Escriba un seudónimo para compartir cartera"
                            onChange={handleChange}
                            defaultValue={sharedPortfolio?.name || ''}
                        />
                        {(error !== undefined || error !== "") && <L.Error>{error}</L.Error>}
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 text-right">
                        <DefaultButton
                            className="button primary-button"
                            onClick={onSubmit}
                            disabled={sending}
                        >
                            {sending && (<Spinner size={SpinnerSize.small} className="button-spinner" />)}
                            Compartir
                        </DefaultButton>
                    </div>
                </div>
            </L.StyledModal>
        </Modal>
    );
}
import { useContext } from "react";
import { ThemeContext } from "@fluentui/react-theme-provider";
import styled from "styled-components";
import * as L from '../styles/SubscriptionTableStyles';
import { SubscriptionSubTable } from "./SubscriptionSubTable";
import { UserSubscriptionsType } from "../../../../services/SubscriptionService";
import React from "react";

export const Table = styled.table`
    width: 100%;
    height: 100%;
    border-spacing: 0;
    text-align: left;
    background: ${(props) => props.theme.palette.white};
    thead
    {
        th
        {
            background: ${(props) => props.theme.palette.tertiary};
            font-weight: 500;
            padding: 17px;
            text-align: left;
        }
        th:first-of-type
        {
            border-top-left-radius: 10px;
        }
        th:last-of-type
        {
            border-top-right-radius: 10px;
        }
    }
  
    td
    {
        padding: 16px;
        text-align: left;
        font-size: 12px;
        > a
        {
            color: ${(props) => props.theme.palette.darkBorder};
            font-weight: 600;
            line-height: 20px;
            font-size: 14px;
            cursor: pointer;
        }
    }
`;
export interface ITableProps
{
    tableTitle: string,
    rowData: UserSubscriptionsType[] | undefined,
    columnTitles: {id: string, title: string}[],
    tableCount: number | undefined
};
const SubscriptionTable = (props : ITableProps) =>
{
    const theme = useContext(ThemeContext);

    return(
        <L.TableWrapper>
            <L.TableWidthManager>
                <L.TableFilterContainer>
                    <L.TRCountContainer>
                        <L.TRTitle>{props.tableTitle}<L.TRCount>({props.tableCount})</L.TRCount></L.TRTitle>
                    </L.TRCountContainer>
                </L.TableFilterContainer>
                <L.TableContainer theme={theme}>
                    <Table theme={theme}>
                        <thead>
                            <tr>
                                {props.columnTitles.length > 0 && props.columnTitles.map((column, columnIndex) =>
                                    {
                                        return <th key={columnIndex} style={{ paddingLeft: 16 }}>{column.title}</th>;
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {props.rowData !== undefined && props.rowData.length > 0 && props.rowData.map((row, index) =>
                                {
                                    return (
                                        <React.Fragment key={index}>
                                            <SubscriptionSubTable
                                                subscriptionEndDate={row.subscriptionEndDate}
                                                subscriptionStartDate={row.subscriptionStartDate}
                                                subscriptionName={row.subscriptionName}
                                                subscriptionStatus={row.subscriptionStatus}
                                                subscriptionPayments={row.subscriptionPayments}
                                            />
                                        </React.Fragment>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </L.TableContainer>
            </L.TableWidthManager>
        </L.TableWrapper>
    );
};

export default SubscriptionTable;
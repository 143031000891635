import { DetailsList, DetailsListLayoutMode, DetailsRow, DetailsRowCheck, IColumn, IDetailsFooterProps, IDetailsRowBaseProps, SelectionMode, Separator, Spinner, SpinnerSize } from "@fluentui/react";
import React, { useRef } from "react";
import DocumentTitle from "react-document-title";
import { DetailsRowFooter, TitleContainer, PortfolioName, classNames, ColTable, SignButton, TableDivRow, LinkIsin, IsinColumn, MinInvestment, MinApplied, CleanClass, Amount, WarningContainerCart, SectionRow, ContainerIcon, TitleWarning, ParagraphWarning, SignatureDivRow, ButtonSigned } from "./Styles/ApiCartStyles";
import { IDataCart, SignatureComponent } from "../components/Signature/SignatureComponent";
import { ISignature } from "../services/SignaturesService";
import scrollToTop from "../utils/scrollToTop";
import moment from "moment";
import { currencyFormatter } from "../utils/numberFormatter";
import Api, { CartModel, CartOrderInfo, CartOrders, CartSignatureModel, InespayData } from "../services/ApiService";
import { FundDocumentsCallout } from "../screen/Cart/components/FundDocumentsCallout";
import { ApiCartColumn, ApiCartSubTable } from "./ApiCartSubTable";
import Auth from "../Auth/Auth";
import funds from "../services/FundService";
import { useBoolean } from "@fluentui/react-hooks";
import { UserIdentityType } from "../services/UserIdentitiesService";
import ApiProcessingModal from "./ApiProcessingModal";

const ApiCart = (props: any) =>
{
    const firstTime = useRef<boolean>(true);
    const [allItems, setAllItems] = React.useState<CartModel>(
    {
        userId: -1, 
        savingPlanName: "",
        allAmount: 0,
        isReadonly: true,
        funds: []
    });
    const processHours: any = Auth.getProcessHours();
    const [userConfirmation, setUserConfirmation] = React.useState({user: false, disclaimer: false});
    const [isSignatureEnabled, setIsSignatureEnabled] = React.useState<boolean>(false);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [signatures, setSignatures] = React.useState<ISignature[]>();
    const [userIdentity, setUserIdentity] = React.useState<UserIdentityType>();
    const [isAttachmentsVisible, setIsAttachmentsVisible] = React.useState<boolean>(false);
    const [isRequestingSignature, setIsRequestingSignature] = React.useState<boolean>(false);
    const [selectedRegister, setSelectedRegister] = React.useState<any>(undefined);
    const [dataFunds, setDataFunds] = React.useState<IDataCart[]>();
    const firstLoading = useRef<boolean>(true);
    const guid: any = window.location.pathname.split('/').pop();
    const toggleAttachmentsVisible = (isin: string) =>
    {
        const register = allItems?.funds.find(item => item.isin === isin);
        setSelectedRegister(register);
        setIsAttachmentsVisible(true);
    };

    React.useEffect(() =>
    {
        scrollToTop();
    }, []);
    React.useEffect(() =>
    {
        if(firstLoading.current === true)
        {
            Api.getCartFunds(guid).then((response: CartModel) =>
            {
                setAllItems(response);
            });
        }
    }, [guid]);
    React.useEffect(() =>
    {
        let arrayResult: IDataCart[] = [];

        if(allItems !== undefined && allItems.funds !== undefined && allItems.funds.length > 0 && firstTime.current === true)
        {
            firstTime.current = false;

            for(let i=0; i<allItems.funds.length; i++)
            {
                funds.getFundEstimationDate(allItems.funds[i].isin).then((dataResponse: any) =>
                {
                    arrayResult.push(
                    {
                        isin: allItems.funds[i].isin,
                        name: allItems.funds[i].name,
                        complexity: allItems.funds[i].complexity,
                        date: dataResponse !== undefined && dataResponse !== null ? dataResponse : new Date().toLocaleDateString()
                    });
                });
            }
        }
        setDataFunds(arrayResult);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allItems]);
    const baseColumns: IColumn[] =
    [
        { key: "name", name: "Nombre", fieldName: "name", minWidth: 140, isResizable: true },
        { key: "isin", name: "ISIN", fieldName: "isin", minWidth: 85, isResizable: true },
        { key: "ongoingCharges", name: "Gastos corrientes", fieldName: "ongoingCharges", minWidth: 140, className: "percentage-detail", isResizable: true },
        { key: "managementFee", name: "Comisión gestión", fieldName: "managementFee", minWidth: 140, className: "percentage-detail", isResizable: true },
        { key: "rebate", name: "Retrocesión", fieldName: "rebate", minWidth: 140, className: "percentage-detail", isResizable: true },
        { key: "minimumInitialInvestment", name: "Inversión mínima", fieldName: "minimumInitialInvestment", className: classNames.number, headerClassName: classNames.number, minWidth: 120, isResizable: true },
        { key: "noMin", name: "Aplica mínimo", fieldName: "noMin", minWidth: 90, isResizable: true },
        { key: "cleanShare", name: "Clase limpia", fieldName: "cleanShare", minWidth: 75, isResizable: true },
        { key: "amount", name: "Importe (€)", fieldName: "amount", minWidth: 100, isResizable: true, className: "cell-input text-right", headerClassName: classNames.number },
        { key: "actions", name: "Documentos", minWidth: 85, maxWidth: 85, isResizable: false, isRowHeader: false, className: classNames.actions}
    ];
    const processExpirationDate = (signs: ISignature[]) =>
    {
        if(signs !== undefined && signs.length > 0)
        {
            // eslint-disable-next-line array-callback-return
            signs.map((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = moment(signature.expiresAt).toDate();
                }
            });
        }
    }
    const onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element =>
    {
        return <DetailsRowCheck {...props} selected={true} />;
    };
    const renderColumn = (item: any, index?: number, column?: IColumn) =>
    {
        if(!column)
        {
            return;
        }
        switch(column?.key)
        {
            case "name":
                return(
                    <LinkIsin onClick={() => window.open(`/iFrame/${item.isin}`)}>
                        {item.name}
                    </LinkIsin>
                );
            case "ongoingCharges":
                return <ApiCartColumn amount={item.amount} percentage={item.onGoingCharges} />;
            case "managementFee":
                return <ApiCartColumn percentage={item.managementFee} amount={item.amount} />;
            case "rebate":
                return <ApiCartColumn percentage={item.rebate} amount={item.amount} />;
            case "minimumInitialInvestment":
                return(
                <MinInvestment>
                    {currencyFormatter.format(item.minimumAdditionalInvestment)}
                </MinInvestment>);
            case "noMin":
                return(
                    <MinApplied>
                        {item.noMin ? "Sí" : "No"}
                    </MinApplied>
                );
            case "cleanShare":
                return(
                    <CleanClass>
                        {item.cleanShare ? "Sí" : "No"}
                    </CleanClass>
                );
            case "amount":
                return(
                    <React.Fragment key={"amount-readOnly-"+index}>
                        <Amount>
                            {currencyFormatter.format(item.amount)}    
                        </Amount>
                    </React.Fragment>
                );
            case "actions":
                return <ApiCartSubTable item={item} toggleAttachmentsVisible={toggleAttachmentsVisible} />;
            default:
                return(
                    <IsinColumn>
                        {item[column.key]}
                    </IsinColumn>
                );
        }
    };
    const onRenderDetailsFooter = (props?: IDetailsFooterProps): JSX.Element =>
    {
        return(
            <DetailsRowFooter>
                <DetailsRow {...props}
                    columns={props?.columns}
                    item={{}}
                    itemIndex={-1}
                    groupNestingDepth={props?.groupNestingDepth}
                    selectionMode={SelectionMode.none}
                    selection={props?.selection}
                    onRenderItemColumn={renderDetailsFooterItemColumn}
                    onRenderCheck={onRenderCheckForFooterRow}
                />
            </DetailsRowFooter>
        );
    };
    const renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = (item, index, column) =>
    {
        switch(column?.key)
        {
            case "cleanShare":
                return <b style={{textAlign: "right", flexDirection: "row-reverse"}}>Total</b>;
            case "amount":
                return <b>{currencyFormatter.format(allItems.allAmount)}</b>;
            default:
                break;
        }
        return undefined;
    };
    const createIsins = () =>
    {
        let result: string[] = [];

        if(allItems !== undefined && allItems.funds !== undefined && allItems.funds.length > 0)
        {
            for(let i=0; i<allItems.funds.length; i++)
            {
                result.push(allItems.funds[i].isin);
            }
        }

        return result;
    }
    const handleSubmit = () =>
    {
        setIsRequestingSignature(true);
        Api.getCartSignatures(guid).then((response: CartSignatureModel) =>
        {
            if(response !== undefined && response !== null)
            {
                setSignatures(response.signatures);
                setUserIdentity(response.userIdentity);
            }
        });
        setIsSignatureEnabled(true);
    }
    const handleSign = () =>
    {
        showModal();

        if(signatures !== undefined)
        {
            if(signatures.length > 0 && allItems.funds.length > 0)
            {
                var allData: CartOrderInfo[] = allItems.funds.map(a =>
                {
                    return{ 
                        signatureId: signatures[0].id as number,
                        instrumentId: a.finametrixId,
                        hasDisclaimerTime: userConfirmation.disclaimer,
                        values:
                        {
                            amountType: "amount",
                            type: "amount", 
                            amount: a.amount,
                            fund: a.finametrixId
                        }};
                });

                var data: CartOrders = 
                {
                    userId: allItems.userId,
                    userIdentityId: userIdentity?.id,
                    cartId: guid,
                    data: allData
                }

                Api.createOrdersAndPayments(data).then((response: InespayData) =>
                {
                    if(response !== undefined)
                    {
                        Api.postInespay(response).then((dataInespay: any) =>
                        {
                            const inespay_result = JSON.parse(dataInespay.Result);
                            if(inespay_result.status === '200')
                            {
                                hideModal();
                                window.location.href = inespay_result.url;
                            }
                            else
                            {
                                if(inespay_result.status === "E301")
                                {
                                    console.log("El IBAN introducido no es válido.");
                                }
                                else
                                {
                                    console.log(inespay_result.description);
                                }
                            }
                        });
                    }
                });
            }
        }
    }

    return(
        <React.Fragment>
            <DocumentTitle title={"Carrito"}>
                <React.Fragment>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col">
                            <TitleContainer>
                                <div style={{fontWeight: 800, fontSize: "32px", lineHeight: "40px", display: "inline-block", width: "100%", fontFamily: "Barlow"}}>
                                    Carrito de Inversiones
                                </div>
                            </TitleContainer>
                        </div>
                        <div className="ms-Grid-col" style={{paddingTop: "13px", color: "#CC214F", marginBottom: '0px !important'}}>
                            <PortfolioName>{allItems !== undefined ? allItems.savingPlanName : ""}</PortfolioName>
                        </div>
                    </div>
                    <Separator style={{marginBottom: '20px'}} />
                    <TableDivRow className="ms-Grid-row">
                        {allItems !== undefined && allItems.funds.length > 0 &&
                        (
                            <React.Fragment>
                                <ColTable className="ms-Grid-col">
                                    {selectedRegister &&
                                    (
                                        <FundDocumentsCallout fund={selectedRegister}
                                            onClose={() => setIsAttachmentsVisible(false)}
                                            isVisible={isAttachmentsVisible}
                                        />
                                    )}
                                    <DetailsList items={allItems.funds}
                                        columns={baseColumns}
                                        onRenderItemColumn={renderColumn}
                                        onRenderDetailsFooter={onRenderDetailsFooter}
                                        selectionMode={SelectionMode.none}
                                        layoutMode={DetailsListLayoutMode.justified}
                                    />
                                </ColTable>
                                {isRequestingSignature === false &&
                                (
                                    <SignButton onClick={handleSubmit}>Tramitar Órdenes</SignButton>
                                )}
                                {isRequestingSignature === true &&
                                (
                                    <ButtonSigned disabled={true}>
                                        <Spinner size={SpinnerSize.small} className="button-spinner" />
                                        Firmando...
                                    </ButtonSigned>
                                )}
                            </React.Fragment>
                        )}
                        {(allItems === undefined || allItems.funds.length === 0) &&
                        (
                            <React.Fragment>
                                <div className="ms-Grid-col" style={{display: 'contents'}}>
                                    <Spinner size={SpinnerSize.large} styles={{circle: {width: '100px', height: '100px'}}} style={{paddingTop: '5em', paddingBottom: '5em'}} />
                                </div>
                            </React.Fragment>
                        )}
                    </TableDivRow>
                    <SignatureDivRow className="ms-Grid-row">
                        <div className="ms-Grid-col">
                            {isSignatureEnabled === true && signatures !== undefined &&
                            (
                                <React.Fragment>
                                    <SignatureComponent 
                                        signatures={signatures}
                                        title={allItems.savingPlanName !== undefined || allItems.savingPlanName !== "" ? 'Firma las Órdenes de tu cartera "'+allItems.savingPlanName+'"' : ''}
                                        submitButtonText={"Compra"}
                                        requestNewCodeButtonText="Recibir nuevo código"
                                        headerTextArea={"Introduce el código que te hemos enviado al teléfono XXXXXX" + signatures[0].phone?.slice(signatures[0].phone.length - 3) + " por SMS para firmar la orden."}
                                        onBackButtonClicked={() => window.location.href = "https://www.micappital.com"}
                                        onCancelButtonClicked={() => window.location.href = "https://www.micappital.com"}
                                        dataCart={dataFunds}
                                        isApiSignature={true}
                                        userIdentities={[userIdentity as UserIdentityType]}
                                        onNewCodeSended={(signatures: any) =>
                                        {
                                            processExpirationDate(signatures);
                                            setSignatures(signatures);
                                        }}
                                        onSignatureChanged={(signatures: any) =>
                                        {
                                            processExpirationDate(signatures);
                                            setSignatures(signatures);
                                        }}
                                        confirmElement={
                                        {
                                            text: "Confirmo haber recibido, leído y comprendido la documentación obligatoria de IIC's y realizar esta operación por iniciativa propia.",
                                            value: userConfirmation.user,
                                            setValue: (value: boolean) => setUserConfirmation({user: !!value, disclaimer: userConfirmation.disclaimer})
                                        }}
                                        disclaimerEnabled={
                                        {
                                            text: "He sido informado de que alguna de las operaciones realizadas en este momento puede no tomar el valor liquidativo del día en curso.",
                                            processHours: processHours,
                                            isins: createIsins(),
                                            value: userConfirmation.disclaimer,
                                            isTransfer: false,
                                            isMultiIsin: false,
                                            setValue: (value: boolean) => setUserConfirmation({user: userConfirmation.user, disclaimer: !!value})
                                        }}  
                                        onSigned={handleSign}
                                    />
                                    <ApiProcessingModal isModalOpen={isModalOpen} hideModal={hideModal} 
                                        title={'Procesando datos...'}
                                        description={'Estamos preparando todos los datos para autorizar la compra. No cierres la pestaña ni el navegador, por favor.'}
                                    />
                                    <SectionRow className="section-row">
                                        {allItems.funds.length !== allItems.funds.filter(item => item.complexity).length &&
                                        (
                                            <WarningContainerCart>
                                                <ContainerIcon className="ms-Grid-col">
                                                    <TitleWarning>
                                                        <img src='/icons/information.svg' alt="Información" style={{paddingRight: "1em"}} />
                                                        Operación a iniciativa del cliente que no precisa evaluación por la Entidad
                                                    </TitleWarning>
                                                </ContainerIcon>
                                                {allItems.funds.some(item => item.complexity) &&
                                                (
                                                    <React.Fragment>
                                                        Respecto a la suscripción de los fondos: {allItems.funds.filter(item => !item.complexity).map(item => item.name + ' ' + item.isin).join(", ")}
                                                    </React.Fragment>
                                                )}
                                                <ParagraphWarning>
                                                    La entidad no tiene obligación de recabar información sobre los conocimientos
                                                    y experiencia del cliente para un análisis de la conveniencia de la presente
                                                    operación, dado que el instrumento sobre el que va operar tiene la categoría
                                                    normativa de no complejo y que la operación se realiza por iniciativa suya y
                                                    no de la entidad.  Al no realizar dicha valuación la entidad no puede formarse
                                                    una opinión respecto a si esta operación es o no conveniente para usted y por
                                                    tanto, en caso de que, la operación no resultase conveniente para usted, no
                                                    podría advertírselo.
                                                </ParagraphWarning>
                                            </WarningContainerCart>
                                        )}
                                        <br />
                                        <WarningContainerCart>
                                            <ContainerIcon className="ms-Grid-col">
                                                <TitleWarning>
                                                    <img src='/icons/information.svg' alt="Información" style={{paddingRight: "1em"}} />
                                                    Entrega documentación obligatoria IIC's
                                                </TitleWarning>
                                            </ContainerIcon>
                                            <ParagraphWarning>
                                                De conformidad con lo establecido en la normativa vigente aplicable a Instituciones de Inversión Colectiva
                                                y en las Circulares 4/2008 y 2/2011 de la Comisión Nacional del Mercado de Valores, Diaphanum Valores, S.V., S.A.U.
                                                entrega la documentación legal requerida al partícipe que se identifica con anterioridad a la suscripción de
                                                participaciones/acciones del fondo de inversión. El firmante declara haber recibido, leído, comprendido y
                                                tener a su disposición la documentación indicada relativa al/los fondo/s que desea suscribir y acusa recibo de la documentación entregada.
                                            </ParagraphWarning>
                                        </WarningContainerCart>
                                    </SectionRow>
                                </React.Fragment>
                            )}
                        </div>
                    </SignatureDivRow>
                </React.Fragment>
            </DocumentTitle>
        </React.Fragment>
    );
}

export default ApiCart;
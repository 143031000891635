import DocPreLogService, { requiredPreLog } from '../../../services/DocPreLogService';
import Moment from "moment";
import { FundDocumentType } from '../../../services/FundService';

export interface Props {
    documents: FundDocumentType[],
    userId: number,
    isin: string
};

export const Documents = (props: Props) =>
{
    const onClick = (document: FundDocumentType) => {
        if (requiredPreLog.includes(document.type)) {
            DocPreLogService.createDocPreLogs({
                userId: props.userId,
                isin: props.isin,
                documentType: document.type,
                dateClick: Moment().toISOString()
            });
        }
    };

    return (
        <table style={{marginTop: "1em"}}>
            <tbody>
                {props.documents.map((document: any) => (
                    <tr key={document.name}>
                        <td><strong>{document.name}</strong></td>
                        <td>
                            <a
                                href={document.url}
                                onClick={() => onClick(document)}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className={'ironia-icon downloads'} /> Descargar
                            </a>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
const AddressTypes = [
    {
        "value": "AL",
        "text": "Alameda"
    },
    {
        "value": "AD",
        "text": "Aldea"
    },
    {
        "value": "AP",
        "text": "Apartamento"
    },
    {
        "value": "AV",
        "text": "Avenida"
    },
    {
        "value": "BO",
        "text": "Barrio"
    },
    {
        "value": "BL",
        "text": "Bloque"
    },
    {
        "value": "CL",
        "text": "Calle"
    },
    {
        "value": "CM",
        "text": "Camino"
    },
    {
        "value": "CR",
        "text": "Carretera"
    },
    {
        "value": "CS",
        "text": "Caserío"
    },
    {
        "value": "CH",
        "text": "Chalet"
    },
    {
        "value": "CO",
        "text": "Colonia"
    },
    {
        "value": "CT",
        "text": "Cuesta"
    },
    {
        "value": "ED",
        "text": "Edificio"
    },
    {
        "value": "GL",
        "text": "Glorieta"
    },
    {
        "value": "GR",
        "text": "Grupo"
    },
    {
        "value": "LG",
        "text": "Lugar"
    },
    {
        "value": "MZ",
        "text": "Manzana"
    },
    {
        "value": "MC",
        "text": "Mercado"
    },
    {
        "value": "MN",
        "text": "Municipio"
    },
    {
        "value": "PQ",
        "text": "Parque"
    },
    {
        "value": "PJ",
        "text": "Pasaje"
    },
    {
        "value": "PS",
        "text": "Paseo"
    },
    {
        "value": "PZ",
        "text": "Plaza"
    },
    {
        "value": "PB",
        "text": "Poblado"
    },
    {
        "value": "PG",
        "text": "Polígono"
    },
    {
        "value": "PR",
        "text": "Prolongación"
    },
    {
        "value": "RB",
        "text": "Rambla"
    },
    {
        "value": "RD",
        "text": "Ronda"
    },
    {
        "value": "TR",
        "text": "Travesía"
    },
    {
        "value": "UR",
        "text": "Urbanización"
    }
];

export default AddressTypes;
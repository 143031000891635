import { Service } from "./Service";

export interface IDistributor
{
    id?: number,
    createdAt?: string | Date,
    updatedAt?: string | Date,
    NIF: string,
    name?: string,
}

const baseURLOverride = undefined;//"http://localhost:7072/api";

class DistributorsService extends Service<IDistributor>
{
    public getDistributors(params?: any)
    {
        return Service.request<IDistributor[]>(
        {
            method: "get",
            url: "/distributors",
            params
        }, baseURLOverride);
    }
    public getDistributor(id: number, params?: any)
    {
        return Service.request<IDistributor>(
        {
            method: "get",
            url: `/distributors/${id}`,
            params
        }, baseURLOverride);
    }
}

const service = new DistributorsService();
export default service;
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import React from 'react';
import DocumentTitle from 'react-document-title';

const PaymentError = () =>
{
    return(
        <DocumentTitle title='IronIA - Pago erróneo'>
            <React.Fragment>
                <h3>No se ha podido completar el pago</h3>
                <p className="description">Se ha producido un error durante el proceso de pago.
                    Compruebe que no se haya realizado el cargo en su cuenta bancaria.
                    En caso de que el pago se haya hecho efectivo, póngase en contacto con nosotros a través del
                    email <a href="mailto:info@ironia.tech">info@ironia.tech</a>.
                </p>
                <Separator className="separator" />
            </React.Fragment>
        </DocumentTitle>
    );
};

export default PaymentError;
import styled from "styled-components";
import { DefaultButton } from "office-ui-fabric-react";

export const RowStatusBar = styled.div``;
export const StatusBarText = styled.p`
    margin: 0;
    cursor: default;
`;
export const HeaderMain = styled.div``;
export const HeaderDiv = styled.div``;
export const RowHeader = styled.div``;
export const ColNineDots = styled.div`
    @media (width: 320px)
    {
        width: 15%;
    }
    @media (min-width: 321px) and (max-width: 479.99px)
    {
        width: 12%;
    }
    @media (width: 480px)
    {
        width: 11%;
        padding-right: 0;
    }
    @media (min-width: 481px) and (max-width: 639.99px)
    {
        width: 8%;
        padding-right: 0;
    }
    @media (width: 640px)
    {
        width: 8%;
        padding-right: 0;
    }
    @media (min-width: 641px) and (max-width: 800px)
    {
        width: 8%;
        padding-right: 0;
    }
    @media (min-width: 801px) and (max-width: 1023.99px)
    {
        width: 5%;
        padding-right: 0;
    }
    @media (min-width: 1025px) and (max-width: 4000px)
    {
        width: 4%;
        padding-right: 0;
    }
`;
export const ButtonDots = styled(DefaultButton)`
    margin-left: 0 !important;
    @media (width: 320px)
    {
        padding-left: 5%;
        padding-right: 0;
    }
    @media (min-width: 321px) and (max-width: 479px)
    {
        padding-left: 8%;
        padding-right: 0;
    }
    @media (width: 480px)
    {
        padding-left: 0;
        padding-right: 0;
    }
    @media (min-width: 481px) and (max-width: 639.99px)
    {
        padding-left: 0;
        padding-right: 0;
    }
    @media (width: 640px)
    {
        padding-left: 0;
        padding-right: 0;
    }
    @media (min-width: 1366px) and (max-width: 1919.99px)
    {
        padding-left: 40%;
    }
`;
export const ColSeparator = styled.div`
    @media (width: 320px)
    {
        width: 3%;
        min-height: 55px;
    }
    @media (min-width: 321px) and (max-width: 479.79px)
    {
        width: 3%;
        min-height: 55px;
    }
    @media (width: 480px)
    {
        width: 2%;
        min-height: 55px;
    }
    @media (min-width: 481px) and (max-width: 639.99px)
    {
        width: 2%;
        min-height: 55px;
        padding-right: 0;
    }
    @media (width: 640px)
    {
        width: 2%;
        min-height: 55px;
    }
    @media (min-width: 641px) and (max-width: 800px)
    {
        width: 2%;
        min-height: 56px;
    }
    @media (min-width: 801px) and (max-width: 1023.99px)
    {
        width: 2%;
        min-height: 57px;
    }
    @media (min-width: 1025px) and (max-width: 4000px)
    {
        width: 2%;
        min-height: 60px;
    }
`;
export const ColImageLogo = styled.div`
    @media (width: 320px)
    {
        width: 60%;
    }
    @media (min-width: 321.99px) and (max-width: 479.99px)
    {
        width: 50%;
    }
    @media (width: 480px)
    {
        width: 43%;
    }
    @media (min-width: 481px) and (max-width: 639.99px)
    {
        width: 57%;
    }
    @media (width: 640px)
    {
        width: 30%;
        padding-left: 0;
    }
    @media (min-width: 641px) and (max-width: 800px)
    {
        width: 26%;
        padding-left: 0;
    }
    @media (min-width: 801px) and (max-width: 1023.99px)
    {
        width: 25%;
        padding-left: 0;
    }
    @media (min-width: 1025px) and (max-width: 4000px)
    {
        width: 15%;
        min-height: 50px;
    }
`;
export const ButtonLogo = styled(DefaultButton)`
    @media (min-width: 480px) and (max-width: 640px)
    {
        padding: 0 !important;
        width: 100%;
    }
`;
export const ColSearch = styled.div`
    @media (min-width: 319.99px) and (max-width: 1023.99px)
    {
        display: none;
    }
    @media (min-width: 1025px) and (max-width: 4000px)
    {
        width: 36%;
    }
`;
export const ColToggle = styled.div`
    @media (width: 480px)
    {
        text-align: center;
        width: 15%;
        padding-top: 1px;
    }
    @media (min-width: 481px) and (max-width: 639.99px)
    {
        width: 10%;
    }
    @media (min-width: 640px)
    {
        width: 9%;
    }
    @media (min-width: 641px) and (max-width: 800px)
    {
        width: 9%;
    }
    @media (min-width: 801px) and (max-width: 1023.99px)
    {
        width: 6%;
    }
    @media (min-width: 1025px) and (max-width: 4000px)
    {
        width: 6%;
    }
`;
export const ColSeparator2 = styled.div`
    @media (min-width: 319.99px) and (max-width: 479.99px)
    {
        display: none;
    }
    @media (width: 480px)
    {
        width: 2%;
    }
    @media (min-width: 481px) and (max-width: 800px)
    {
        width: 2%;
    }
    @media (min-width: 801px) and (max-width: 1023.99px)
    {
        width: 2%;
    }
    @media (min-width: 1025px) and (max-width: 4000px)
    {
        width: 2%;
    }
`;
export const ColPersonStatus = styled.div`
    @media (width: 640px)
    {
        width: 40%;
    }
    @media (min-width: 641px) and (max-width: 800px)
    {
        width: 50%;
    }
    @media (min-width: 801px) and (max-width: 1023.99px)
    {
        width: 47%;
    }
    @media (min-width: 1025px) and (max-width: 4000px)
    {
        width: 25%;
    }
`;
export const ColNotifications = styled.div`
    @media (width: 320px)
    {
        display: none;
    }
    @media (min-width: 321px) and (max-width: 479.99px)
    {
        width: 20%;
        padding-right: 0;
        padding-top: 0.7em;
        text-align: right;
    }
    @media (width: 480px)
    {
        width: 12%;
        padding-right: 0;
        padding-top: 0.85em;
    }
    @media (min-width: 481px) and (max-width: 639.99px)
    {
        width: 11%;
        padding-top: 0.25em;
        padding-right: 0;
        text-align: end;
    }
    @media (width: 640px)
    {
        width: 8%;
    }
    @media (min-width: 641px) and (max-width: 800px)
    {
        width: 5%;
        padding-top: 0.25em;
    }
    @media (min-width: 801px) and (max-width: 1023.99px)
    {
        width: 5%;
        padding-top: 0.25em;
    }
    @media (min-width: 1025px) and (max-width: 4000px)
    {
        width: 5%;
        margin-top: 0.25em;
        margin-left: -1.25em;
    }
`;
export const ButtonNotifications = styled(DefaultButton)`
    @media (width: 320px)
    {
        display: none;
    }
    @media (min-width: 321px) and (max-width: 479.99px)
    {
        min-width: 40px !important;
        min-height: 40px !important;
        padding-left: 0;
        padding-right: 0;
    }
    @media (width: 480px)
    {
        min-width: 40% !important;
    }
    @media (width: 640px)
    {
        min-width: 38px !important;
        padding: 0;
    }
    @media (min-width: 801px) and (max-width: 1023.99px)
    {
        padding-left: 0;
    }
`;
export const ColCartButton = styled.div`
    @media (width: 320px)
    {
        width: 22%;
    }
    @media (min-width: 321px) and (max-width: 479.99px)
    {
        width: 15%;
    }
    @media (min-width: 481px) and (max-width: 639.99px)
    {
        width: 9%;
        text-align: end;
    }
    @media (width: 640px)
    {
        width: 12%;
    }
    @media (min-width: 641px) and (max-width: 800px)
    {
        width: 8%;
    }
    @media (min-width: 801px) and (max-width: 1023.99px)
    {
        width: 5%;
    }
    @media (min-width: 1025px) and (max-width: 4000px)
    {
        width: 5%;
    }
`;
export const DivCartButton = styled.div`
    position: relative; 
    margin-top: 0.25em;
    @media (min-width: 321px) and (max-width: 479.99px)
    {
        margin-top: 1px;
    }
    @media (width: 480px)
    {
        margin-top: 4px !important;
    }
`;
export const DivBadgeButton = styled.div`
    top: 8px;
    bottom: auto;
    left: auto;
    @media (width: 320px)
    {
        top: 5px;
        right: 5px;
    }
    @media (min-width: 321px) and (max-width: 365.99px)
    {
        top: 2px;
        right: -8px;
    }
    @media (min-width: 366px) and (max-width: 400px)
    {
        top: 2px;
        right: -5px;
    }
    @media (min-width: 401px) and (max-width: 479.99px)
    {
        top: 2px;
        right: 2px;
    }
    @media (width: 480px)
    {
        top: 4px;
        right: 8px;
    }
    @media (min-width: 481px) and (max-width: 639.99px)
    {
        top: 4px;
        right: -10px;
    }
    @media (width: 640px)
    {
        top: 4px;
        right: 8px;
    }
    @media (min-width: 641px) and (max-width: 800px)
    {
        top: 4px;
        right: -5px;
    }
    @media (min-width: 801px) and (max-width: 1023.99px)
    {
        top: 4px;
        right: -20px;
    }
    @media (min-width: 1025px) and (max-width: 1366.99px)
    {
        top: 4px;
        right: 2px;
    }
    @media (min-width: 1367px) and (max-width: 1450px)
    {
        top: 4px;
        right: 12px;
    }
    @media (min-width: 1451px) and (max-width: 1650px)
    {
        top: 4px;
        right: 18px;
    }
    @media (min-width: 1651px) and (max-width: 1710px)
    {
        top: 4px;
        right: 20px;
    }
    @media (min-width: 1711px) and (max-width: 1800px)
    {
        top: 4px;
        right: 25px;
    }
    @media (min-width: 1801px) and (max-width: 2050px)
    {
        top: 4px;
        right: 2em;
    }
    @media (min-width: 2051px) and (max-width: 4000px)
    {
        top: 4px;
        right: 2.5vw;
    }
`;
export const DivButtonToggle = styled.div`
    margin-top: 0.25em;
    @media (width: 480px)
    {
        margin-top: 0;
    }
`;
export const ButtonToggle = styled.button`
    background: ${(props) => // @ts-ignore
        `${props.theme.palette.borderTertiary}`};
    border: ${(props) => // @ts-ignore
        `1px solid ${props.theme.palette.borderPrimary}`};
    border-radius: 100px;
    width: 48px;
    height: 24px;
    margin-top: 16px;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    > div
    {
        display: inline-block;
        height: 12px;
        padding: 0;
        margin: 2px;
        width: 1px;
        background:  ${(props) => // @ts-ignore
        `${props.theme.palette.borderQuaternary}`};
        &:after
        {
            display: none;
        }
    }
    @media (width: 480px)
    {
        width: 60px;
        height: 30px;
    }
`;
export const Icon = styled.i`
    background: ${(props) => // @ts-ignore
        `${props.theme.palette.themePrimary}`};
    border: 0;
    border-radius: 12px;
    display: inline-block;
    box-sizing: border-box;
    width: 19px;
    height: 19px;
    line-height: 18px;
    vertical-align: middle;
    &:after
    {
        font-size: 13px !important;
        color: ${(props) => // @ts-ignore
        `${props.theme.palette.borderTertiary}`};
    }
    &.light.moonlight,
    &.dark.daylight
    {
        background: ${(props) => // @ts-ignore
        `${props.theme.palette.borderTertiary}`};
        &:after
        {
            color:  ${(props) => // @ts-ignore
        `${props.theme.palette.borderQuaternary}`} !important;
        }
    }
    &.moonlight
    {
        background: #FFFF;
        &:after
        {
            font-size: 15px !important;
        }
    }
    &.moonlight.dark
    {
        border: ${(props) => // @ts-ignore
        `1px solid ${props.theme.palette.borderPrimary}`};
        box-shadow: ${(props) => // @ts-ignore
        `0px 0px 16px ${props.theme.palette.shadow}`};
    }
`;
import React from "react";
import { EditColumnContainer, InputSearchContainer, PageButtons, PageCount, PaginationContainer, ProductsPerPage, TRCount, TRCountContainer, TRTitle, TableContainer, TableFilterContainer, TableWidthManager, TableWrapper } from "./SearchManagersStyle";
import { DefaultButton, SearchBox, Spinner, SpinnerSize } from "@fluentui/react";
import SearchEngineTable from "./SearchEngineTable";
import FundService from "../../../services/FundService";
import groupFundsByFamily from "../../../utils/groupFundsByFamily";

interface SearchProps
{
    managerName: string,
    managerSearch: any,
    filters: any
}
const SearchEngine = (props: SearchProps) =>
{
    let lastPromise: any;
    const search = props.managerName;
    const [loading, setLoading] = React.useState<boolean>(false);
    const [productsPerPage, setProductsPerPage] = React.useState<number>(10);
    const [page, setPage] = React.useState<number>(0);
    const [recordsTotal, setRecordsTotal] = React.useState<number>(0);
    const [searchTerm, setSearchTerm] = React.useState<string>(search);
    const [familyRecordsTotal, setFamilyRecordsTotal] = React.useState<number>(0);
    const [fundGroups, setFundGroups] = React.useState<any>([]);
    const handleKey = (e: any) =>
    {
        if(e.key === 'Enter')
        {
            load();
        }
    };

    const modifySearchTerm = (newSearchTerm: any) =>
    {
        setSearchTerm(newSearchTerm);
    }

    const searchFunds = () =>
    {
        let { subcategory, currency, available, income, switchable, indexed, classCategory } = props.filters;
        let subcategories = subcategory.map((sc: any) =>
        {
            return sc.morningStarCategoryId;
        });

        let promise = lastPromise = FundService.getFunds(page, productsPerPage, searchTerm, subcategories, available, income, switchable, indexed, props.managerSearch, currency, classCategory);

        promise.then((response: any) =>
        {
            if(promise !== lastPromise)
            {
                return;
            }
            setFamilyRecordsTotal(response?.total_Family ?? 0);
            setRecordsTotal(response.total);
            setFundGroups(groupFundsByFamily(response.results));
            setLoading(false);
        })
        .catch(() =>
        {
            if(promise !== lastPromise)
            {
                return;
            }
        })
        .finally(() =>
        {
            if(promise !== lastPromise)
            {
                return;
            }
        });
    };

    const load = () =>
    {
        setFamilyRecordsTotal(0);
        setRecordsTotal(0);
        setLoading(true);
        setFundGroups([]);
        setTimeout(() => searchFunds());
    };

    React.useEffect(() =>
    {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, productsPerPage, props.filters]);

    return(
        <React.Fragment>
            <TableWrapper>
                <TableWidthManager>
                    <TableFilterContainer>
                        <TRCountContainer>
                            <TRTitle>
                                Resultado de la Búsqueda
                                <TRCount>
                                    ({familyRecordsTotal})
                                </TRCount>
                            </TRTitle>
                        </TRCountContainer>
                        <InputSearchContainer>
                            <SearchBox onKeyPress={e => handleKey(e)}
                                placeholder={`Busca entre los fondos de ${props.managerName}...`}
                                value={searchTerm}
                                onChange={event =>
                                {
                                    let text = event?.target.value;
                                    modifySearchTerm(text);
                                }}
                                autoComplete="off"
                            />
                        </InputSearchContainer>
                        <EditColumnContainer>
                            <DefaultButton text="Buscar" onClick={load} />
                        </EditColumnContainer>
                    </TableFilterContainer>
                    {loading &&
                    (
                        <React.Fragment>
                            <Spinner size={SpinnerSize.large} />
                        </React.Fragment>
                    )}
                    {!loading &&
                    (
                        <React.Fragment>
                            <TableContainer>
                                <SearchEngineTable columns={["Nombre", "Gestora", "Categoría", "Puntos IRONIA"]} groups={fundGroups} />
                            </TableContainer>
                            <PaginationContainer>
                                <ProductsPerPage>
                                    <span>Productos por página</span>
                                    <select defaultValue={productsPerPage} onChange={(event: any) =>{setProductsPerPage(event.target.value)}}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={30}>30</option>
                                        <option value={40}>40</option>
                                        <option value={50}>50</option>
                                    </select>
                                </ProductsPerPage>
                                <PageCount>
                                    <span>{`Mostrando ${recordsTotal} productos de ${page + 1}/${Math.ceil(recordsTotal / productsPerPage)} páginas`}</span>
                                </PageCount>
                                <PageButtons>
                                    <button onClick={() => {if(page > 0) setPage(page-1)}} className={page === 0 ? "disabled" : ""}>
                                        <i className={"ironia-icon keyboard-arrow-left"} />
                                    </button>
                                    <button onClick={() => {if(page < Math.ceil(recordsTotal / productsPerPage)-1) setPage(page + 1)}} className={Math.ceil(recordsTotal / productsPerPage) === page+1 ? "disabled" : ""}>
                                        <i className={"ironia-icon keyboard-arrow-right"} />
                                    </button>
                                </PageButtons>
                            </PaginationContainer>
                        </React.Fragment>
                    )}
                </TableWidthManager>
            </TableWrapper>
        </React.Fragment>
    );
}

export default SearchEngine;
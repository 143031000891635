import { useEffect, useState } from "react";
import UserIdentitiesService from "../../../services/UserIdentitiesService";
import { DefaultButton, IconButton, Modal, Spinner, TextField, SpinnerSize } from "@fluentui/react";
import React from "react";
import { InputStyles } from "../../GlobalStyle";
import Countdown, { CountdownRendererFn } from "react-countdown";
import SignaturesService, { ISignature } from "../../../services/SignaturesService";

type Props = {
	showModal: boolean;
	handleSubmit?: (dni: string, signatureSuccess: boolean) => any;
	existingUserDni?: string;
};

const countdown = React.createRef<Countdown>();

const ModalAddExistingAccountHolder = (props: Props) => {
	const { showModal, handleSubmit, existingUserDni } = props;
	const [userFound, setUserFound] = useState<boolean | undefined>();
	const [verificationCodeSended, setVerificationCodeSended] = useState<boolean>(false);
	const [userDni, setUserDni] = useState<string>(existingUserDni ?? "");
	const [loadingVerifyUser, setLoadingVerifyUser] = useState<boolean>(false);
	const [expired, setExpired] = useState<boolean>();
	const [expiredAt, setExpiredAt] = useState<string>();
	const [sendingNewCode, setSendingNewCode] = useState(false);
	const [signatureError, setSignatureError] = useState<string>("");
	const [signatureId, setSignatureId] = useState<number>();
	const [inputSignatureCode, setInputSignatureCode] = useState<string>();
	const [validatingSignature, setValidatingSignature] = useState<boolean>(false);

	useEffect(() => {
		if (existingUserDni) {
			setUserDni(existingUserDni);
			setUserFound(true);
		}
	}, [existingUserDni]);

	const handleCloseModalExistingAccount = (success: boolean) => {
        if (handleSubmit !== undefined) {
			handleSubmit(success ? userDni : "", success);
		};
        setUserDni("");
		setSignatureError("");
        setSignatureId(undefined);
		setUserFound(undefined);
        setInputSignatureCode("");
		setVerificationCodeSended(false);
	};

	const verifyUserExists = (dni: string) => {
		setLoadingVerifyUser(true);
		UserIdentitiesService.userIdentityExists(dni.trim())
			.then(
				(response: boolean) => {
					setUserFound(response);
				},
				(error: any) => {
					setUserFound(false);
				}
			)
			.finally(() => {
				setLoadingVerifyUser(false);
			});
	};

	const onCountComplete = () => {
		setExpired(true);
	};

	const countdownRenderer: CountdownRendererFn = ({ minutes, seconds, completed }) => {
		return (
			<>
				{minutes}:{seconds.toString().padStart(2, "0")}
			</>
		);
	};

	const requestNewCode = () => {
		setSendingNewCode(true);
		setVerificationCodeSended(false);

		SignaturesService.requestSignatureNoPlanByDni(userDni)
			.then((response: any) => {
				setSendingNewCode(false);
				if (!response?.data) {
					setSignatureError("Ha ocurrido un error");
					return;
				}
				setExpired(false);
				setSignatureId(response.data.id);
				setExpiredAt(response.data.expiresAt);
				setVerificationCodeSended(true);
			})
			.catch(error => {
				setSendingNewCode(false);
				setSignatureError("Ha ocurrido un error");
			});
	};

	const onSubmit = () => {
		if (expired) {
			return false;
		}

		var signature = {
			id: signatureId,
			signatureCode: inputSignatureCode
		};
		var signatures = [signature];

		setValidatingSignature(true);
		SignaturesService.putSignatures(signatures)
			.then((response: any) => {
				setValidatingSignature(false);

				if (!response?.data?.success) {
					setSignatureError("Ha ocurrido un error inesperado");
					return;
				}

				handleCloseModalExistingAccount(true);
			})
			.catch(error => {
				setValidatingSignature(false);
				if (error.response.status === 401) {
					setSignatureError("No tiene permisos para completar el proceso de firma. Compruebe si la sesión ha caducado.");
				} else {
					let signatures = error?.response?.data.signatures;
					switch (error?.response?.data?.errorCode) {
						case "WRONG_CODE":
							signatures.forEach((signature: ISignature) => {
								if (signature.status === 3) {
									setSignatureError("El código no es válido.");
								}
							});
							break;
					}
				}
			});
	};

	return (
		<>
			{/* Existing Account Holder Modal*/}
			<Modal
				isOpen={showModal}
				onDismiss={() => handleCloseModalExistingAccount(false)}
				isBlocking={false}
				containerClassName="modal-container-ExistingAccountHolder"
			>
				<div style={{ fontFamily: "Barlow", fontSize: "14px" }}>
					<div style={{ width: "100%", marginBottom: "20px", textAlign: "right" }}>
						<b style={{ float: "left", fontSize: "24px" }}>Titulares Existentes</b>
						<IconButton iconProps={{ iconName: "ChromeClose" }} ariaLabel="Cerrar modal" onClick={() => handleCloseModalExistingAccount(false)} />
					</div>
					<div style={{ width: "100%", fontWeight: "400", color: "#5f7172", textAlign: "justify" }}>
						<div style={{ marginTop: "10px" }}>
							Esta opción le permite asociar un titular existente en otra cuenta de IronIA Store a esta cuenta.
						</div>
						<div style={{ marginTop: "10px" }}>
							Para ello el titular de la cuenta recibirá un SMS con un código que deberá facilitarte autorizando que utilices sus datos
							personales.
						</div>
					</div>
					<div>
						<div style={{ width: "99%", overflow: "hidden", marginTop: "10px" }}>
							<TextField
								//@ts-ignore
								styles={InputStyles}
								label="DNI del interviniente a añadir:"
								id={"dni"}
								name={"dni"}
								type="text"
								value={userDni}
								disabled={loadingVerifyUser || userFound === true || existingUserDni !== undefined}
								onChange={(e: any) => setUserDni(e.target.value)}
							/>
						</div>
						<div style={{ width: "100%", textAlign: "end" }}>
							{!userFound && (
								<>
									{userFound === false && (
										<span style={{ color: "rgb(164, 38, 44)", paddingRight: "20px", fontWeight: 400, fontSize: "15px" }}>
											Ese DNI no existe en IronIA
										</span>
									)}
									<DefaultButton
										className="button mini-primary-button"
										disabled={loadingVerifyUser}
										onClick={() => {
											verifyUserExists(userDni);
										}}
									>
										Buscar
										{loadingVerifyUser && (
											<div style={{ paddingLeft: "10px" }}>
												<Spinner size={SpinnerSize.small} className="button-spinner" />
											</div>
										)}
									</DefaultButton>
								</>
							)}
							{userFound && (
								<>
									<span style={{ color: "green", paddingRight: "20px", fontWeight: 400, fontSize: "15px" }}>Usuario encontrado</span>
									<DefaultButton
										className="button mini-primary-button"
										disabled={sendingNewCode || expired === false}
										onClick={() => {
											requestNewCode();
										}}
									>
										Enviar código
										{sendingNewCode && (
											<div style={{ paddingLeft: "10px" }}>
												<Spinner size={SpinnerSize.small} className="button-spinner" />
											</div>
										)}
									</DefaultButton>
								</>
							)}
						</div>
						{!sendingNewCode && verificationCodeSended && (
							<>
								<div style={{ width: "100%", overflow: "hidden", display: "inline-block", marginTop: "10px" }}>
									<div style={{ width: "20%", display: "inline-block", textAlign: "center", fontSize: "24px" }}>
										<Countdown ref={countdown} date={expiredAt} precision={2} renderer={countdownRenderer} onComplete={onCountComplete} />
									</div>
									<div style={{ width: "79%", display: "inline-block" }}>
										<TextField
											//@ts-ignore
											styles={InputStyles}
											label="Introducir código de autorización:"
											id={"authcode"}
											name={"authcode"}
											type="number"
											autoComplete="false"
											value={inputSignatureCode ?? ""}
											onChange={(e: any) => {
												setInputSignatureCode(e.target.value);
											}}
										/>
									</div>
								</div>
								<div style={{ width: "100%", textAlign: "end" }}>
									<DefaultButton
										className="button mini-primary-button"
										disabled={validatingSignature}
										onClick={() => {
											onSubmit();
										}}
									>
										Verificar
										{validatingSignature && (
											<div style={{ paddingLeft: "10px" }}>
												<Spinner size={SpinnerSize.small} className="button-spinner" />
											</div>
										)}
									</DefaultButton>
								</div>
							</>
						)}
						{signatureError !== "" && (
							<div
								style={{
									width: "100%",
									display: "inline-block",
									marginTop: "10px",
									color: "rgb(164, 38, 44)",
									fontWeight: 400,
									fontSize: "15px"
								}}
							>
								{signatureError}
							</div>
						)}
					</div>
				</div>
			</Modal>
		</>
	);
};

export default ModalAddExistingAccountHolder;

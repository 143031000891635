import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import graphTheme from '../../graphTheme';
import { Spinner, SpinnerSize } from "@fluentui/react";

type Props =
{
    series: any,
    customOptions: object | null,
    onChange?: (event: any, value?: any) => any;
}

const MoneyFlagHighStock: React.FC<Props> = (props: Props) =>
{
    Highcharts.setOptions(graphTheme as any);
    var buttonPressed : number = 5;

    const handleButton = (event: any) =>
    {
        if(event.currentTarget.textContent !== undefined && props.onChange !== undefined)
        {
            var pressed = event.currentTarget.textContent;

            if(pressed === "1m")
            {
                buttonPressed = 0;
            }
            if(pressed === "3m")
            {
                buttonPressed = 1;
            }
            if(pressed === "6m")
            {
                buttonPressed = 2;
            }
            if(pressed === "1y")
            {
                buttonPressed = 3;
            }
            if(pressed === "YTD")
            {
                buttonPressed = 4;
            }
            if(pressed === "Todo")
            {
                buttonPressed = 5;
            }

            props.onChange(buttonPressed);
        }
    };

    const optionsMoney =
    {
        title:
        {
            text: ''
        },
        series: props.series,
        rangeSelector:
        {
            allButtonsEnabled: true,
            selected: buttonPressed,
            buttons:
            [
                {
                    type: 'month',
                    count: 1,
                    text: '1m',
                    events:
                    {
                        click: handleButton
                    }
                },
                {
                    type: 'month',
                    count: 3,
                    text: '3m',
                    events:
                    {
                        click: handleButton
                    }
                },
                {
                    type: 'month',
                    count: 6,
                    text: '6m',
                    events:
                    {
                        click: handleButton
                    }
                },
                {
                    type: 'year',
                    count: 1,
                    text: '1y',
                    events:
                    {
                        click: handleButton
                    }
                },
                {
                    type: 'ytd',
                    text: 'YTD',
                    events:
                    {
                        click: handleButton
                    }
                },
                {
                    type: 'all',
                    text: 'Todo',
                    events:
                    {
                        click: handleButton
                    }
                }
            ]
        },
        credits:
        {
            enabled: false,
        },
        yAxis:
        {
            labels:
            {
                formatter: function (item: any)
                {
                    return item.value;
                }
            },
            resize:
            {
                enabled: true
            },
            title:
            {
                text: ''
            }
        },
        tooltip:
        {
            pointFormatter(this: Highcharts.TooltipFormatterContextObject): string
            {
                var y = Highcharts.numberFormat(this.y, 2, ',', '.');
                return this.series.name + ': ' + (y ? Highcharts.numberFormat(this.y, 2, ',', '.') : "") + ' €';
            },
            valueSuffix: '',
            split: true
        },
        chart:
        {
            type: 'line',
            backgroundColor: 'none',
            height: 755,
        }
    }

    const [chartMoneyOptions, setChartMoneyOptions] = React.useState({...graphTheme, ...optionsMoney, ...props.customOptions});

    return(
        <React.Fragment>
            {(props.series.length === undefined || props.series.length <= 1) &&
            (
                <Spinner size={SpinnerSize.large} />
            )}
            {props.series.length > 1 &&
            (      
                <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={chartMoneyOptions} />
            )}
        </React.Fragment>
    );
};

export default MoneyFlagHighStock;
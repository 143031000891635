import { DefaultButton } from "@fluentui/react";
import styled from "styled-components";

export const MainPage = styled.div``;
export const MainTitle = styled.h3`
    cursor: default;
    font-size: 30px !important;
    font-weight: bold;
    margin-bottom: 0.25em;
`;
export const MainSubTitle = styled.h4`
    font-size: 20px;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    cursor: default;
    color: #CC214F;
`;
export const ContractPanel = styled.div`
    background-color: ${props => props?.theme?.palette?.contractElementPanel};
    color: ${props => props?.theme?.palette?.black};
    width: 99%;
    padding: 1em 1em 1em 1em;
    border-radius: 10px;
    margin-top: 1em;
    max-height: 400px;
    overflow: auto;
    & hr
    {
        margin: 40px 0;
        border: solid 1px #e9eff2;
    }
`;
export const SubscribeButton = styled(DefaultButton)`
    background-color: #CC214F;
    color: white;
    root:
    {
        display: block,
        border: none,
        borderRadius: 10px !important,
        width: 6em,
        height: 2.5em,
        backgroundColor: #CC214F
    },
    rootPressed:
    {
        backgroundColor: #CC214F,
        color: #FFF
    },
    rootHovered:
    {
        backgroundColor: #CC214F,
        color: #FFF
    },
    textContainer:
    {
        color: #FFF,
        fontFamily: inherit,
        fontSize: 14px
    }
`;
export const DisabledButton = styled(DefaultButton)`
    background-color: grey;
    color: white;
    root:
    {
        display: block,
        border: none,
        borderRadius: 10px !important,
        width: 6em,
        height: 2.5em,
        backgroundColor: #CC214F
    },
    rootPressed:
    {
        backgroundColor: #CC214F,
        color: #FFF
    },
    rootHovered:
    {
        backgroundColor: #CC214F,
        color: #FFF
    },
    textContainer:
    {
        color: #FFF,
        fontFamily: inherit,
        fontSize: 14px,
    }
`;
export const FirstParagraph = styled.p`
    margin: 0;
    cursor: default;
`;
export const SecondParagraph = styled.p`
    margin: 1em 0 0 0;
    cursor: default;
`;
export const SpecificConditions = styled.p`
    cursor: default;
    font-weight: bold;
    color: #CC214F;
    font-size: 20px;
    margin: 1em 0 0.5em 0;
`;
export const SmallTitle = styled.p`
    cursor: default;
    font-weight: bold;
    font-size: 15px !important;
`;
export const FullParagraph = styled.div`
    cursor: default;
    margin: 0.5em 0 0 0;
`;
export const DivRowButton = styled.div`
    margin-top: 1em !important;
    text-align: end;
`;
export const DivColButton = styled.div`
`;
export const SignatureContainer = styled.div`
    padding-top: 1em;
    padding-bottom: 1em;
`;
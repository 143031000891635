import React from 'react';
import styled from 'styled-components';
import { ChevronDownMedIcon, ChevronUpMedIcon } from '@fluentui/react-icons-mdl2';
import { TextField, Checkbox, Spinner } from '@fluentui/react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ThemeContext } from '@fluentui/react-theme-provider';
import { DivDropdownContainer, DivDropdownTitle, SpanDropdownTitle } from '../Buscadores/iFrameGestoras/SearchManagersStyle';

interface CustomDropdownContainerProps
{
    hasSelection: boolean
}
const CustomDropdownContainer = styled.div<CustomDropdownContainerProps>`
    position: relative;
    background: ${(props) => props.theme.palette.white} !important;

    > button
    {
        border-radius: 5rem;
        border: 1px solid ${(props) => (props.hasSelection ? props.theme.palette.themePrimary : '#bcc8c9') };
        padding: 0.75rem 1rem;
        padding-right: 1.75rem;
        width: max-content;
        cursor: pointer;
        color: ${(props) => props.theme.palette.lightBlack} !important;
        background: ${(props) => props.theme.palette.white} !important;
        font-weight: 500;
        font-size: 14px;
        color: #556769;
        svg
        {
            margin-left: 0.75rem;
        }
        span
        {
            color: ${(props) => props.theme.palette.themePrimary};
            padding-left: 5px;
        }
    }
    .menu
    {
        display: flex;
        flex-direction: column;
        width: max-content;
        position: absolute;
        z-index: 100;
        background-color: ${(props) => props.theme.palette.white} !important;
        top: 2.7rem;
        border-radius: 1.1rem;
        max-height: 315px;
        .ms-Checkbox
        {
            padding: 0.75rem 0;
            border-bottom: 1px solid #DAE5E6;
            label
            {
                font-weight: 600;
            }
        }
        .menu-header
        {
            display: flex;
            flex-direction: column;
            min-height: 30px;
            margin-bottom: 5px;

            div,
            input
            {
                :after
                {
                    border: none;
                    outline: none;
                }
                border: none;
                outline: none;
            }
        }
        .menu-body
        {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: auto;
            max-height: 230px;
            border-bottom: 1px solid lightGrey;
        }
        .menu-footer
        {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            button
            {
                border: none;
                padding: 10px 15px;
                margin: 10px;
            }
            button:first-of-type
            {
                background: none;
                cursor: pointer;
            }
            button:last-of-type
            {
                background: #CC214F;
                border-radius: 50px;
                color: white;
                cursor: pointer;
            }
        }
        p
        {
            text-align: center;
            font-weight: 600;
        }
    }
`;
interface ICustomDropdownCategory
{
    selectedValues: any,
    options: any,
    title: any,
    onSave: any,
    isLoading: boolean
}
const CustomDropdownCategory = (props: ICustomDropdownCategory) =>
{
    const theme = React.useContext(ThemeContext);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [selectedKeys, setSelectedKeys] = React.useState<any[]>([]);
    const [filteredOptions, setFilteredOptions] = React.useState<any[]>([]);
    const [optionsToRender, setOptionsToRender] = React.useState<any[]>([]);
    const [searchTerm, setSearchTerm] = React.useState<any>([]);

    const handleFilter = (event: any) =>
    {
        setSearchTerm(event.target.value);

        let filtered = props.options.filter((item: any) => item.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(event.target.value.toLowerCase()));
        setFilteredOptions([...filtered]);
    };
    React.useEffect(() =>
    {
        setOptionsToRender(filteredOptions.length > 0 ? filteredOptions : props.options);
    }, [filteredOptions, props.options]);
    React.useEffect(() =>
    {
        if(props.selectedValues !== undefined && props.selectedValues.length > 0)
        {
            const selected = props.selectedValues.options.map((v: any) =>
            {
                return{
                    label: v.name || v,
                    value: v,
                };
            });

            setSelectedKeys([...selected]);
        }
    }, [props.selectedValues]);

    return(
        <React.Fragment>
            {props !== undefined &&
            (
                <CustomDropdownContainer hasSelection={props.selectedValues.length > 0} theme={theme}>
                    <button onClick={() => setIsOpen(!isOpen)}>
                        <DivDropdownContainer>
                            <DivDropdownTitle>
                                {props.isLoading && <Spinner size={0} />}
                                <SpanDropdownTitle>
                                    {props.title}
                                </SpanDropdownTitle>
                            </DivDropdownTitle>
                            <span>{selectedKeys.length > 0 && `(${selectedKeys.length})`}</span>
                            {isOpen ? <ChevronUpMedIcon /> : <ChevronDownMedIcon />}
                        </DivDropdownContainer>
                    </button>
                    {isOpen &&
                    (
                        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                            <div className='menu ms-depth-16'>
                                <div className='menu-header'>
                                    <TextField value={searchTerm} onChange={handleFilter} placeholder='Buscar...' />
                                </div>
                                <div className='menu-body'>
                                    {optionsToRender.length > 0 ?
                                    (
                                        optionsToRender.map(({label, value}, i) =>
                                        (
                                            <Checkbox key={i} label={label} value={value} checked={selectedKeys.some((key) => key.label === label)}
                                                onChange={() =>
                                                {
                                                    if(selectedKeys.some((item) => item.label === label))
                                                    {
                                                        let selected = selectedKeys.filter((key) => key.label !== label);
                                                        setSelectedKeys([...selected]);
                                                    }
                                                    else
                                                    {
                                                        setSelectedKeys([...selectedKeys, { label, value }]);
                                                    }
                                                }}
                                            />
                                        ))
                                    ) :
                                    (
                                        <p>No hay resultados</p>
                                    )}
                                </div>
                                <div className='menu-footer'>
                                    <button onClick={() => {setSelectedKeys([]); props.onSave([]);}}>
                                        Borrar
                                    </button>
                                    <button onClick={() => {props.onSave(selectedKeys); setIsOpen(false);}}>
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </OutsideClickHandler>
                    )}
                </CustomDropdownContainer>
            )}
        </React.Fragment>
    );
};

export default CustomDropdownCategory;
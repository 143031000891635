import styled from "styled-components";

export const RowHeader = styled.div`
    padding-bottom: 1em;
`;
export const ColHeaderTitle = styled.div``;
export const ColHeaderTheme = styled.div``;
export const ColHeaderButton = styled.div``;
export const MainTitle = styled.h1`
    cursor: default;
    margin: 0;
`;
export const SubTitle = styled.p`
    cursor: default;
    margin: 1em 0 0 0;
`;
export const ButtonSubscribe = styled.button`
    border: 1px solid #CC214F;
    border-radius: 20px;
    background-color: #CC214F;
    color: white;
    cursor: pointer;
    padding: 1em;
    margin-top: 1em;
    width: 100%;
`;
export const RowManager = styled.div`
    padding-bottom: 1em;
`;
export const ContainerManager = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 130px;
    margin-bottom: 1em;
`;
export const ColContainer = styled.div`
    text-align: left;
`;
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 1 10px;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
`;
export const TitleManager = styled.h4`
    font-size: 12px;
    font-weight: 500;
    margin: 0.25em 0 0 0;
    color: #95A0A1;
`;
export const CardsContainer = styled.div`
    padding-top: 1em;
    overflow-x: auto;
`;
export const CardImage = styled.button`
    background: none;
    border: none;
    cursor: default;
    border-radius: 1em;
    img
    {
        opacity: 0.5;
        border-radius: 1em;
        opacity: 1;
    }
`;
export const RowFilters = styled.div`
    padding-bottom: 1em;
    padding-top: 1em;
    padding-left: 1em;
`;
export const RowSearchEngine = styled.div``;

//////
//Filters
export const DivDropdownTitle = styled.div`
    display: flex;
`;
export const SpanDropdownTitle = styled.span`
    margin-left: 0.25em;
`;
export const FilterContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 3.5em 0 0;

    > div span
    {
        border-radius: 100px;
    }
    i
    {
        color: white;
    }
`;
export const FilterLabel = styled.label`
    color: grey;
    font-size: 12px;
    margin-top: 0.25em;
    width: 100%;
    padding-left: 10px;
    display: block;
`;
export const ToggleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 10px 12px;
    border-radius: 100px;
    border: 1px solid rgb(188, 200, 201);
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    color: #556769 !important;
`;
export const ToggleTitle = styled.span`
    margin-right: 1em;
    margin-left: 0.75em;
    height: 100%;
`;
export const DivContainerToggle = styled.div``;
export const FirstH4Tooltip = styled.h4`
    margin-top: 0;
`;
export const DivClassYes = styled.div``;
export const SecondH4Tooltip = styled.h4``;
export const DivClassNo = styled.div``;
///////
//Search Engine
export const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
export const TableWidthManager = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 30px;
    box-shadow: 0px 0px 16px rgba(8, 84, 117, 0.08);
    border-radius: 16px;
    min-height: 500px;
`;
export const TableFilterContainer = styled.div`
    display: flex;
    flex: 0 1 50px;
    margin-bottom: 2em;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    > *
    {
        vertical-align: top;
        flex-direction: column;
        display: block;
    }
`;
export const TRCountContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 0.75;
    margin-top: 1em;
    padding-right: 0.5em;
`;
export const TRTitle = styled.span`
    font-size: 19px;
    font-weight: 500;
    cursor: default;
`;
export const TRCount = styled.span`
    color: #94A1A1;
    margin-left: 5px;
`;
export const InputSearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 3;
    justify-content: center;
    &.jcl
    {
        justify-content: left;
    }
    > div,
    input
    {
        border-radius: 100px;
        border: 1px solid lightGrey;
        padding-left: 10px;
        :hover
        {
            border: 1px solid lightGrey;
        }
        ::after
        {
            border: none;
        }
    }
`;
export const EditColumnContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
`;
export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: auto;
    border-bottom: 1px solid lightGrey;
    background: white;
    table
    {
        border-radius: 16px;
    }
    thead
    {
        background: #F3F7F9;
    }
    > div
    {
        margin: 0;
    }
    .is-selected
    {
        a,
        div
        {
            color: white !important;
        }
    }
`;
export const PaginationContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
    gap: 20px;
    span
    {
        color: #95A0A1;
        border-radius: 16px;
        line-height: 28px;
    }
    > *
    {
        vertical-align: middle;
    }
`;
export const ProductsPerPage = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    height: 100%;
    select
    {
        min-width: 70px;
        border: none;
        border-bottom: 1px solid black;
        padding: 5px 0;
    }
`;
export const PageCount = styled.div`
    display: flex;
`;
export const PageButtons = styled.div`
    button
    {
        cursor: pointer;
        border: 0;
        color: #CC214F;
        &.disabled
        {
            cursor: default;
            color: #F1BAC9;
        }
    }
`;
export const FilterLabelContainer = styled.div`
    text-align: left;
`;
export const FilterLabelSearchEngine = styled.label`
    color: grey;
    font-size: 12px;
    margin-top: 5px;
    width: 100%;
    padding-left: 10px;
    display: block;
`;

///////
//CUSTOM DROPDOWN CATEGORY
export const DivDropdownContainer = styled.div`
    display: flex;
`;
///////
//SEARCH ENGINE TABLE
export const TableSearch = styled.table`
    width: 100%;
    border-spacing: 0;
    overflow: hidden;
    thead
    {
        text-align: left;
        border-top-right-radius: 30px;
        width: 100%;
        th
        {
            border-spacing: 0;
            padding: 0;
            cursor: default;
            div
            {
                padding-top: 17px;
                padding-right: 17px;
                padding-bottom: 17px;
            }
        }
        th:first-of-type
        {
            div
            {
                padding-left: 10px;
                border-top-left-radius: 30px;
            }
        }
        th:last-of-type
        {
            div
            {
                padding-right: 10px;
                border-top-right-radius: 30px;
            }
        }
    }
    tbody
    {
        button.expanded
        {
            border: none;
            cursor: pointer;
            transition: 150ms;
            border-radius: 0;
            &:hover
            {
                border-bottom: 1px solid #4284E6;
                transition: 150ms;
            }
        }
        strong
        {
            color: black;
        }
        tr
        {
            td
            {
                border-bottom: 2px solid #ECF1F3;
                div
                {
                    padding: 0;
                }
                p
                {
                    color: black;
                }
            }
            svg
            {
                margin-right: 3px;
            }
            .max-content
            {
                min-width: max-content;
            }
        }
        tr:last-of-type
        {
            td
            {
                border-bottom: none;
            }
        }
        tr.container-table td:first-of-type
        {
            padding: 0;
        }
    }
`;
export const FirstTh = styled.th`
    padding-left: 20px;
`;
///////
//SEARCH ENGINE SECOND TABLE
export const SubTableSearch = styled.div`
    div
    {
        width: 100%;
        min-width: max-content;
        padding: 10px 0 !important;
    }
    margin: 15px;
    margin-left: 10px !important;
    table
    {
        color: black;
        box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px;
        border-radius: 16px;
        th div,
        td div
        {
            text-align: left;
            margin: 0 !important;
        }
        th,
        td
        {
            padding-left: 20px !important;
        }
    }
`;
export const FundName = styled.div`
    .brothers-btn-icon
    {
        margin-right: 5px;
        vertical-align: baseline;
    }
`;
export const ButtonArrow = styled.button`
    margin-left: 20px;
    color: black
`;
export const PSecondTable = styled.p`
    min-width: max-content;
    cursor: default;
`;
///////
//SEARCH ENGINE THIRD TABLE
export const ThirdSearchEngineTable = styled.table`
    width: 100%;
    border-spacing: 0;
    overflow: hidden;
    thead
    {
        text-align: left;
        border-top-right-radius: 30px;
        th
        {
            border-spacing: 0;
            padding: 0;
            div
            {
                padding-top: 17px;
                padding-right: 17px;
                padding-bottom: 17px;
            }
        }
        th:first-of-type
        {
            div
            {
                padding-left: 10px;
                border-top-left-radius: 30px;
            }
        }
        th:last-of-type
        {
            div
            {
                padding-right: 10px;
                border-top-right-radius: 30px;
            }
        }
    }
    tbody
    {
        button.expanded
        {
            border: none;
            cursor: pointer;
            transition: 150ms;
            border-radius: 0;
            &:hover
            {
                border-bottom: 1px solid #4284E6;
                transition: 150ms;
            }
        }
        strong
        {
            color: #4284E6;
        }
        tr
        {
            td
            {
                cursor: default;
                border-bottom: 2px solid #ECF1F3;
                div
                {
                    padding: 0;
                }
                p
                {
                    color: #9CA6A6;
                }
            }
            svg
            {
                margin-right: 3px;
            }
            .max-content
            {
                min-width: max-content;
            }
        }
        tr:last-of-type
        {
            td
            {
                border-bottom: none;
            }
        }
        tr.container-table td:first-of-type
        {
            padding: 0;
        }
    }
`;
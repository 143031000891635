const orderedArray: string[] = ['1m','3m','6m','1y','2y','3y','4y','5y'];

export function comparer(a: string, b: string): number
{
    const orderIndexA = orderedArray.indexOf(a);
    const orderIndexB = orderedArray.indexOf(b);

    if(orderIndexA !== -1 && orderIndexB !== -1)
    {
        return orderIndexA-orderIndexB;
    }

    if(orderIndexA !== -1)
    {
        return -1;
    }

    if(orderIndexB !== -1)
    {
        return 1;
    }

    return a.localeCompare(b);
}
import { useBoolean } from "@fluentui/react-hooks";
import SharePortolioButton from "./SharePortolioButton";
import PromoFriendsButton from "./FriendsPromotion/PromoFriendsButton";

interface ElementsIconProps
{
    element: string
    sending: boolean,
    setSending: any,
    text: string
};

const DynamicElement = (props: ElementsIconProps) =>
{
    const [isNameModalOpen, {setTrue: showNameModal, setFalse: hideNameModal}] = useBoolean(false);

    switch(props.element)
    {
        case "SharePortolioButton":
            return <SharePortolioButton
                sending={props.sending}
                setSending={props.setSending}
                text={props.text}
                isModalOpen={isNameModalOpen}
                showModal={showNameModal}
                hideModal={hideNameModal}
            />;
        case "PromoFriendsButton":
            return <PromoFriendsButton
                sending={props.sending}
                setSending={props.setSending}
                text={props.text}
            />;
        default: return <></>;
    }
};

export default DynamicElement;
import moment from 'moment';
import './MarketCardStyle.css';
import { MarketAttachment } from '../../../../components/MarketAttachment';

const PREFIX = '/marketreports/';
interface MarketCardComponentProps {
    reportDate: string;
    reportImage: string;
    disabled?: boolean;
    className: string;
    active?: string;
    onMarketCardClicked?: () => void;
}

const MarketCardComponent = (props: MarketCardComponentProps) => {
    const cleanReportImages = (item: MarketCardComponentProps): MarketCardComponentProps => {
        return {
            ...item,
            reportImage: item.reportImage.replace(PREFIX, ""),
            reportDate: moment(item.reportDate).format('DD-MM-YYYY')
        };
    }

    const cleanedMarketCards = cleanReportImages(props);
    return (
        <div
            className={`containerMarketImgCard ${props.active ? 'active' : ''}`}
            onClick={props.disabled ? undefined : props.onMarketCardClicked}
            style={{ opacity: props.disabled ? 0.5 : 1, cursor: props.disabled ? 'not-allowed' : 'pointer' }}
        >

            <MarketAttachment fileName={props.reportImage} className={props.className} />
            <div className="centeredTextCard">{cleanedMarketCards.reportDate}</div>
        </div>
    );

};

export default MarketCardComponent;

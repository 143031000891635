import styled from "styled-components";

export const ButtonIAIFrame = styled.button`
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    background-color: #E0E0E0;
    color: black;
    cursor: not-allowed;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 1em;
    padding: 1em;
    width: 50%;
    text-align: center;
`;
export const TextDisabledIFrame = styled.p`
    margin: 0;
    color: ${(props) => props.theme.palette.black};
`;
export const ButtonIA = styled.button`
    border: 1px solid #CC214F;
    border-radius: 20px;
    background-color: #CC214F;
    color: ${(props) => props.theme.palette.white};
    cursor: pointer;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 1em;
    padding: 1em;
    width: 48%;
`;
export const ButtonIAInsideRow = styled.button`
    border: 1px solid #CC214F;
    border-radius: 20px;
    background-color: #CC214F;
    color: ${(props) => props.theme.palette.white};
    cursor: pointer;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 1em;
    padding: 1em;
    width: 97%;
`;
export const ButtonIADisabled = styled.button`
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    background-color: #E0E0E0;
    color: black;
    cursor: not-allowed;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 1em;
    padding: 1em;
    width: 48%;
`;
export const ButtonIADisabledInsideRow = styled.button`
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    background-color: #E0E0E0;
    color: black;
    cursor: not-allowed;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 1em;
    padding: 1em;
    width: 97%;
`;
export const DivRowButtonDisabled = styled.div`
`;
export const DivColButtonSpinnerDisabled = styled.div`
`;
export const DivColButtonTextDisabled = styled.div`
`;
export const TextDisabled = styled.p`
    margin: 0;
    text-align: left;
    color: ${(props) => props.theme.palette.black};
`;
export const WarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    cursor: default;
    border-radius: 16px;
    background: ${(props) => props.theme.palette.warnSecondary};
    color: ${(props) => props.theme.palette.warn};
    padding: 1em;
    text-align: justify;
    gap: 10px;
    font-weight: 500;
    h3
    {
        margin: 0;
    }
    p
    {
        line-height: 20px;
        margin: 0;
    }
`;
export const WarningText = styled.p`
    margin: 0 !important;
    font-size: 10px !important;
    line-height: 15px !important;
`;
export const DivSubtitleManager = styled.div`
    padding-top: 0.25em;
    padding-bottom: 0.25em;
`;
export const SubtitleManager = styled.p`
    margin: 0 !important;
    cursor: default;
    color: ${(props) => props.theme.palette.black};
    padding-left: 0.5em;
`;
export const ContainerDiv = styled.div``;
export const LoadingText = styled.p`
    text-align: left;
    margin: 0 !important;
    cursor: default;
    color: ${(props) => props.theme.palette.black};
`;
export const DivColGenerateVideo = styled.div`
    text-align: center;
`;
export const ButtonGenerateVideo = styled.button`
    border: 1px solid #CC214F;
    border-radius: 20px;
    background-color: #CC214F;
    color: ${(props) => props.theme.palette.white};
    cursor: pointer;
    margin-left: 0.25em;
    padding: 0.5em;
    width: 100%;
`;
export const DivTitleContainer = styled.div`
    text-align: left;
    cursor: default;
`;
export const TitleText = styled.h3`
    margin-bottom: 0 !important;
    padding-top: 0.5em;
    padding-left: 0.5em;
    font-size: 20px;
    color: ${(props) => props.theme.palette.black};
    display: inline;
`;
export const DivBodyContainer = styled.div`
    padding-top: 1.5em;
`;
export const DivBodyContainerNoElements = styled.div`
    padding-top: 0;
`;
export const DivBodyRow = styled.div`
    margin-bottom: 2em;
    padding-top: 0.25em;
`;
export const DivNoElementsBodyRow = styled.div`
    padding-top: 0.25em;
`;
export const DivBodyListenAudio = styled.div`
    text-align: center;
    cursor: not-allowed;
`;
export const DivBodyVideoAudio = styled.div`
    text-align: center;
    cursor: not-allowed;
`;
export const DivBodyTotalElements = styled.div`
    text-align: center;
    cursor: not-allowed;
`;
export const TitleReading = styled.p`
    margin: 0 !important;
    color: ${(props) => props.theme.palette.black};
`;
export const TitleVideo = styled.p`
    margin: 0 !important;
    color: ${(props) => props.theme.palette.black};
`;
export const TitleElements = styled.p`
    margin: 0 !important;
    color: ${(props) => props.theme.palette.black};
`;
export const TextExplanation = styled.p`
    margin: 0 !important;
    cursor: default;
    text-align: justify;
    padding-left: 1.25em;
    color: ${(props) => props.theme.palette.black};
`;
export const DivNoElementsCol = styled.div``;
export const DivNoElementsInsideRow = styled.div``;
export const DivNoElementsColText = styled.div`
    padding-left: 2em;
`;
export const TextSubscribe = styled.div`
    margin: 0 !important;
    cursor: default;
    font-size: 14px;
    color: ${(props) => props.theme.palette.black};
`;
export const DivNoElementsColButtonSubscribe = styled.div`
    padding-top: 1em;
    text-align: center;
`;
export const ButtonSubscribeElements = styled.button`
    border: 1px solid #CC214F;
    border-radius: 20px;
    background-color: #CC214F;
    color: ${(props) => props.theme.palette.allWhite};
    cursor: pointer;
    margin-left: 0.5em;
    margin-bottom: 1em;
    padding: 0.5em;
    width: 90%;
`;
export const DivBodyExplanationRow = styled.div`  
    margin-bottom: 1em;
`;
export const DivBodyExplanationCol = styled.div``;
export const DivBodyErrorRow = styled.div`
`;
export const ErrorExplanation = styled.p`
    margin: 0 !important;
    cursor: default;
    text-align: justify;
    padding-left: 3em;
    padding-right: 1em;
    padding-bottom: 1em;
    font-size: 20px;
    color: ${(props) => props.theme.palette.black};
`;
export const BodyLoading = styled.div`
    padding-left: 1em;
    margin-bottom: 1em;
`;
export const DivRowLoadingMultimedia = styled.div`
    padding-left: 2em;
`;
export const DivColLoadingAudio = styled.div``;
export const DivColLoadingVideo = styled.div``;
export const DivColAudio = styled.div``;
export const DivColVideo = styled.div``;
export const DivRowLoadingAudio = styled.div``;
export const DivRowLoadingVideo = styled.div`
    margin: 0;
`;
export const DivColBuyElements = styled.div``;
export const ButtonGenerateVideoDisabled = styled.button`
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    background-color: #E0E0E0;
    color: ${(props) => props.theme.palette.white};
    cursor: not-allowed;
    margin-left: 0.25em;
    padding: 0.5em;
    width: 100%;
`;
export const ButtonBuyElements = styled.button`
    border: 1px solid #CC214F;
    border-radius: 20px;
    background-color: #CC214F;
    color: ${(props) => props.theme.palette.white};
    cursor: pointer;
    margin-left: 0.25em;
    padding: 0.5em;
    width: 100%;
`;
export const DivColSpinnerAudio = styled.div`
    padding-top: 0.2em;
`;
export const DivColSpinnerVideo = styled.div`
    padding-top: 0.2em;
`;
export const DivColIconAudio = styled.div`
    padding-top: 0.2em;
    text-align: end;
    color: red;
    cursor: default;
`;
export const DivColIconVideo = styled.div`
    padding-top: 0.2em;
    text-align: end;
    color: red;
    cursor: default;
`;

export const DivRowSubtitle = styled.div`
    margin: 0;
    padding-left: 1em;
    padding-bottom: 1.5em;
`;
export const DivRowErrorMessage = styled.div`
    margin: 0;
    padding-left: 1em;
    padding-bottom: 0.5em;
`;
export const DivColErrorMessage = styled.div``;
export const ErrotText = styled.p`
    color: #CC214F;
    font-weight: 500;
    text-align: justify;
    cursor: default;
`;
export const DivColFirstCard = styled.div``;
export const DivColSecondCard = styled.div``;
export const DivColThirdCard = styled.div``;
export const DivColFourthCard = styled.div``;
export const SubtitleCard = styled.div`
    background: #F3F4F9;
    border-radius: 0.5em;
    box-shadow: 1px 1px 5px black;
    min-height: 95px;

    @media (max-width: 1092px)
    {
        min-height: 95px;
    }
`;
export const SubtitleContainerCard = styled.div`
    display: flex;
    padding-top: 5px;
    padding-left 0;
    padding-right: 1em;
`;
export const FirstDivInsideCard = styled.div`
    flex: 2;
`;
export const VideoText = styled.p`
    margin: 0 !important;
    cursor: default;
    font-size: 17px !important;
    padding-left: 0.5em;
    font-weight: 600;
`;
export const VideoTextDisabled = styled.p`
    margin: 0 !important;
    cursor: default;
    font-size: 14px !important;
    padding-left: 0.5em;
    font-weight: 600;
    padding-top: 2px;
`;
export const ElementsIAText = styled.p`
    margin: 0 !important;
    cursor: pointer;
    font-size: 17px !important;
    padding-left: 0.5em;
    font-weight: 600;
`;
export const SecondDivInsideCard = styled.div`
    flex: 0 1 auto;
`;
export const SubtitleSecondContainerCard = styled.div`
    display: flex;
    padding-top: 5px;
    padding-left: 0;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
    
    @media (max-width: 1092px)
    {
        align-items: end;
        justify-content: center;
    }
`;
export const SubtitleSecondContainerCard2 = styled.div`
    display: flex;
    padding-top: 2em;
    padding-left: 0;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
    
    @media (max-width: 1092px)
    {
        align-items: end;
        justify-content: center;
        padding-top: 2em;
    }
`;
export const ThirdDivInsideCard = styled.div`
    flex: 0 1 auto;
`;
export const PriceLastVideo = styled.p`
    margin: 0 !important;
    cursor: default;
    font-size: 20px !important;
    padding-left: 0.5em;
    font-weight: bold;
    font-family: Barlow;
`;
export const FourthDivInsideCard = styled.div`
    flex: 2;
    text-align: end;
`;
export const ButtonWatchLastVideo = styled.button`
    background: #CC214F;
    cursor: pointer;
    border: 1px solid #CC214F;
    border-radius: 12px;
    padding: 5px 20px;
    color: white;
`;
export const ButtonWatchLastVideoDisabled = styled.button`
    background: rgb(204,33,79,0.45);
    cursor: not-allowed;
    border: 1px solid rgb(204,33,79,0.05);
    border-radius: 12px;
    padding: 5px 20px;
    color: white;
    opacity: 0.9;
`;

//Second Modal
export const MainRow = styled.div`
    margin: 0 !important;
    padding-top: 0.75em;
`;
export const DivText = styled.div``;
export const ConfirmationText = styled.p`
    cursor: default;
    text-align: center;
    font-size: 20px !important;
`;
export const DivButtonYes = styled.div`
    text-align: end;
`;
export const DivButtonNo = styled.div``;
export const ButtonYes = styled.button`
    padding: 0.75em 2em;
    border-radius: 8px;
    border: 1px solid green;
    background: green;
    color: white;
    cursor: pointer;
`;
export const ButtonNo = styled.button`
    padding: 0.75em 2em;
    border-radius: 8px;
    border: 1px solid #CC214F;
    background: #CC214F;
    color: white;
    cursor: pointer;
`;
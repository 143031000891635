import styled from 'styled-components';

export const Wrapper = styled.div`
	.item
    {
		background: ${(props) => props.theme.palette.white};
		box-shadow: 0px 0px 16px ${(props) => props.theme.palette.shadow};
		border-radius: 16px;
		font-family: 'Barlow';
		margin: 5px;
		position: relative;
		min-height: 400px;
        &.loading
        {
            min-width: 100%;
        }
		.header
        {
			position: relative;
			text-align: center;
			font-weight: 800;
			font-size: 18px;
			line-height: 24px;
			color: #FFFFFF;
			.ms-Image
      {
				position: relative;
				max-height: 163px;
				img
        {
					width: 100%;
					border-radius: 15px 15px 0 0;
					min-height: 175px;
				}
			}
			.data
			{
				position: absolute;
				width: 100%;
				text-align: center;
				margin: 35px 0;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-shadow: 0 0 2px rgb(0, 0, 0);
				letter-spacing: 0.75px;
				.name
				{
					display block;
					color: #FFF;
					margin: 0 auto;
					width: 100%;
				}
				a.name:hover
				{
					color: ${(props) => props.theme.palette.outdated};
				}
				.manager
				{
					display block;
					font-size: 16px;
					font-weight: 700;
					opacity: 0.8;
					text-shadow: 0 0 2px rgb(0, 0, 0)
				}
			}
		}
		.relationship
		{
			position: absolute;
			bottom: 0;
			right: 5px;
			color: white;
			font-size: 12px;
			font-weight: 300;
		}
		.content
        {
			padding: 20px;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			color: ${(props) => props.theme.palette.black};
			span
            {
				display: block;
				margin: 8px 0;
			}
			.description
            {
				min-height: 80px;
			}
			.price
            {
				border: 1px solid #DFE3E3;
				border-radius: 64px;
				padding: 7px 40px;
				text-align: center;
				margin: 20.5px 0;
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
			}
			.ms-Checkbox-checkbox
            {
			    margin: 8px 8px 8px 0;
			}
		}
	}
`;
export const StyledModal = styled.div`
    color: ${(props) => props.theme.palette.black};
	.name
    {
		color: ${(props) => props.theme.palette.black};
	}
`;
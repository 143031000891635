import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { ChevronDownMedIcon, ChevronUpMedIcon } from '@fluentui/react-icons-mdl2';
import { TextField, Checkbox } from '@fluentui/react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ThemeContext } from '@fluentui/react-theme-provider';

const CustomDropdownContainer = styled.div`
  position: relative;
  background: ${(props) => props.theme.palette.white} !important;

  > button {
    //background: none;
    border-radius: 5rem;
    border: 1px solid ${(props) => (props.hasselection ? props.theme.palette.themePrimary : '#bcc8c9')};
    padding: 0.75rem 1rem;
    padding-right: 1.75rem;
    width: max-content;
    cursor: pointer;
    color: ${(props) => props.theme.palette.lightBlack} !important;
    background: ${(props) => props.theme.palette.white} !important;
    font-weight: 500;
    font-size: 14px;
    color: #556769;
    svg {
      margin-left: 0.75rem;
    }
    span {
      color: ${(props) => props.theme.palette.themePrimary};
      padding-left: 5px;
    }
  }
  .menu {
    display: flex;
    flex-direction: column;
    width: max-content;
    position: absolute;
    z-index: 100;
    background-color: ${(props) => props.theme.palette.white} !important;
    top: 2rem;
    left: 1rem;
    border-radius: 1.1rem;
    max-height: 315px;
    .ms-Checkbox {
      padding: 0.75rem 0;
      border-bottom: 1px solid #dae5e6;
      label {
        font-weight: 600;
      }
    }

    .menu-header {
      display: flex;
      flex-direction: column;
      min-height: 30px;
      margin-bottom: 5px;

      div,
      input {
        :after {
          border: none;
          outline: none;
        }
        border: none;
        outline: none;
      }
    }

    .menu-body {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: auto;
      max-height: 230px;
      border-bottom: 1px solid lightGrey;
    }

    .menu-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      button {
        border: none;
        padding: 10px 15px;
        margin: 10px;
      }
      button:first-of-type {
        background: none;
        cursor: pointer;
      }
      button:last-of-type {
        background: #cc214f;
        border-radius: 50px;
        color: white;
        cursor: pointer;
      }
    }
    p {
      text-align: center;
      font-weight: 600;
    }
  }
`;

const CustomDopdownCategory = ({ selectedValues, options, title, onSave }) => {
  const theme = useContext(ThemeContext);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [optionsToRender, setOptionsToRender] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);

  const handleFilter = (event) => {
    setSearchTerm(event.target.value);

    let filtered = options.filter((item) =>
      item.label
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(event.target.value.toLowerCase())
    );
    setFilteredOptions([...filtered]);
  };

  useEffect(() => {
    setOptionsToRender(filteredOptions.length > 0 ? filteredOptions : options);
  }, [filteredOptions, options]);

  useEffect(() => {
    if(selectedValues) {
      const selected = selectedValues.map((v) => {
        return {
          label: v.name || v,
          value: v,
        };
      });

      setSelectedKeys([...selected]);
    }
  }, [selectedValues]);
  return (
    <CustomDropdownContainer hasselection={selectedValues.length > 0} theme={theme}>
      <button onClick={() => setIsOpen(!isOpen)}>
        <div style={{ display: 'flex' }}>
          {title}
          <span>{selectedKeys.length > 0 && `(${selectedKeys.length})`}</span>
          {isOpen ? <ChevronUpMedIcon /> : <ChevronDownMedIcon />}
        </div>
      </button>
      {isOpen && (
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <div className='menu ms-depth-16'>
            <div className='menu-header'>
              <TextField value={searchTerm} onChange={handleFilter} placeholder='Buscar...' />
            </div>
            <div className='menu-body'>
              {optionsToRender.length > 0 ? (
                optionsToRender.map(({ label, value }, i) => (
                  <Checkbox
                    key={i}
                    label={label}
                    value={value}
                    onChange={() => {
                      if(selectedKeys.some((item) => item.label === label)) {
                        let selected = selectedKeys.filter((key) => key.label !== label);
                        setSelectedKeys([...selected]);
                      } else {
                        setSelectedKeys([...selectedKeys, { label, value }]);
                      }
                    }}
                    checked={selectedKeys.some((key) => key.label === label)}
                  />
                ))
              ) : (
                <p>No options</p>
              )}
            </div>
            <div className='menu-footer'>
              <button
                onClick={() => {
                  setSelectedKeys([]);
                  onSave([]);
                  //setIsOpen(false);
                }}
              >
                Borrar
              </button>
              <button
                onClick={() => {
                  onSave(selectedKeys);
                  setIsOpen(false);
                }}
              >
                Guardar
              </button>
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </CustomDropdownContainer>
  );
};

export default CustomDopdownCategory;

import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { SignatureComponent } from '../../components/Signature/SignatureComponent';
import moment from "moment";
import React, { useEffect, useState } from "react";
import service, { ISignature } from "../../services/SignaturesService";
import OrderService, { OrderType } from '../../services/OrderService';
import { MessageBar, MessageBarType } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import ProcessingModalComponent from "../../components/Modals/ProcessingModalComponent";
import Auth from "../../Auth/Auth";

interface SignatureProps extends RouteComponentProps {}

export const SignatureScreen = (props: SignatureProps) =>
{
    const history = useHistory();
    const { state } = useLocation<any>();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userConfirmation, setUserConfirmation] = useState({user: false, disclaimer: false});
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [showAlert, { toggle: toggleShowAlert }] = useBoolean(false);
    const processHours : any = Auth.getProcessHours();
    const processExpirationDate = (signature: ISignature) =>
    {
        if(signature && typeof signature.expiresAt === "string")
        {
            signature.expiresAt = moment(signature.expiresAt).toDate();
        }
    }

    if(processHours === undefined)
    {
        service.getProcessHours().then((response: any) =>
        {
            if(response.status === 200 && response.data.length > 0)
            {
                Auth.setProcessHours(response.data);
            }
        });
    }

    let transfer: any = state?.transfer;
    let isinOrigin: any = state?.isinOrigin;
    let isinDestination: any = state?.isinDestination;
    let defaultSignature: any = state?.signature;
    processExpirationDate(defaultSignature);
    const [signature, setSignature] = useState(defaultSignature);

    useEffect(() =>
    {
        var operationPhone = transfer?.userIdentity?.operationPhone;
        setPhoneNumber("X".repeat(operationPhone.length - 3) + operationPhone.slice(operationPhone.length - 3, operationPhone.length));
    }, [transfer]);

    if(!state?.signature || !state?.transfer)
    {
        history.push("/transfer");
        return;
    }

    const submit = (signature: any) =>
    {
        showModal();
        var data = { id: transfer.id, signatureId: state.signature.id, hasDisclaimerTime: signature.hasDisclaimerTime };
        OrderService.patchOrder(data).then((response: OrderType) =>
        {
            if(response.signatureId)
            {
                history.push("/portfolio/orders");
            }
        })
        .catch((error) =>
        {
            hideModal();
            toggleShowAlert();
        });
    };

    return (
        <React.Fragment>
            {showAlert &&
            (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">
                    Se ha producido un error al procesar la operación.
                </MessageBar>
            )}
            <SignatureComponent userIdentities={[transfer?.userIdentity]}
                signatures={[signature]}
                alreadySignedText="¡Tu orden de traspaso está en proceso!"
                title="Firma del traspaso"
                submitButtonText="Firmar traspaso"
                cancelButtonText="Cancelar"
                headerTextArea={`Recibirás un SMS en el teléfono ${phoneNumber} con la clave para confirmar esta orden.`}
                backButtonText="Volver"
                onBackButtonClicked={() => history.push("/transfer")}
                onCancelButtonClicked={() => history.push("/")}
                requestNewCodeButtonText="Recibir nuevo código"
                disclaimerEnabled={
                {
                    text: "He sido informado de que la operación realizada en este momento puede no tomar el valor liquidativo del día en curso.",
                    processHours: processHours,
                    isins: [{origin: isinOrigin, destiny: isinDestination}],
                    value: userConfirmation.disclaimer,
                    isTransfer: true,
                    isMultiIsin: true,
                    setValue: (value: boolean) => setUserConfirmation({user: userConfirmation.user, disclaimer: !!value})
                }}
                confirmElement={
                {
                    text: "Confirmo haber recibido, leído y comprendido la documentación obligatoria de IIC's y realizar esta operación por iniciativa propia",
                    value: userConfirmation.user,
                    setValue: (value: boolean) => setUserConfirmation({user: !!value, disclaimer: userConfirmation.disclaimer})
                }}
                onNewCodeSended={(signatures: any) =>
                {
                    signatures.forEach((signature: any) =>
                    {
                        processExpirationDate(signature);
                    })
                    setSignature(signatures[0]);
                }}
                onSignatureChanged={(signature: any) =>
                {
                    processExpirationDate(signature);
                    setSignature(signature);
                }}
                onSigned={(signature: any) =>
                {
                    processExpirationDate(signature);
                    setSignature(signature);
                    submit(signature);
                }}
            />
            <ProcessingModalComponent
                isModalOpen={isModalOpen}
                hideModal={hideModal}
            />
        </React.Fragment>
    );
};
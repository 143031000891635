import React from "react";
import { Link } from "react-router-dom";
import { FundsWithRatiosResponse, getFundsWithRatiosBySubcategory, SubCategory } from "../../../services/subcategories";
import { formatedCategoryValue } from "./tableBody";
import { ChevronDownIcon, ChevronRightIcon } from "@fluentui/react-icons-mdl2";
import { ThemeContext } from '@fluentui/react-theme-provider';
import { ChildFunds } from "./childFunds";
import { Spinner } from "@fluentui/react";
import { SortConfig } from ".";

type Props =
{
    subCategory: SubCategory;
    index: string | number;
    selectedRatioKey: string;
    sortConfig: SortConfig;
};

export const ChildSubCategories = (props: Props) =>
{
    const { subCategory, index, selectedRatioKey, sortConfig } = props;
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [funds, setFunds] = React.useState<FundsWithRatiosResponse[]>([]);
    const theme = React.useContext(ThemeContext);

    React.useEffect(() =>
    {
        setIsExpanded(false);
    }, [sortConfig.key, sortConfig.direction]);

    const fetchFundsBySubcategories = (selectedSubCategory: string) =>
    {
        setLoading(true);
        getFundsWithRatiosBySubcategory(selectedRatioKey, selectedSubCategory).then(response =>
        {
            let sortedFunds = [...response];

            sortedFunds.sort((a, b) =>
            {
                let valueA = a[sortConfig.key] ?? 0;
                let valueB = b[sortConfig.key] ?? 0;
                if(valueA > valueB)
                {
                    return sortConfig.direction === "descending" ? -1 : 1;
                }
                else if (valueA < valueB)
                {
                    return sortConfig.direction === "descending" ? 1 : -1;
                }
                else
                {
                    return 0;
                }
            });

            setFunds(sortedFunds);
        })
        .finally(() =>
        {
            setLoading(false);
        });
    };

    return(
        <React.Fragment key={index}>
            <tr>
                <td>
                    <button style={{marginLeft: "10px", color: theme!.palette.black}} className="expanded"
                        onClick={() =>
                        {
                            if(isExpanded === false)
                            {
                                fetchFundsBySubcategories(subCategory.subCategory);
                            }
                            setIsExpanded(!isExpanded);
                        }}
                    >
                        {isExpanded ? <ChevronDownIcon className="brothers-btn-icon" /> : <ChevronRightIcon className="brothers-btn-icon" />}
                    </button>
                    <Link style={{textAlign: "left", flexWrap: "nowrap", whiteSpace: "nowrap"}} to={
                        {
                            pathname: "/search",
                            state:
                            {
                                subcategory:
                                [
                                    {
                                        morningStarCategoryId: subCategory.morningstarCategoryId,
                                        name: subCategory.subCategory
                                    }
                                ]
                            }
                        }}
                    >
                        {subCategory.subCategory}
                    </Link>
                </td>
                {formatedCategoryValue(subCategory.ratio1m)}
                {formatedCategoryValue(subCategory.bench1m)}
                {formatedCategoryValue(subCategory.ratio3m)}
                {formatedCategoryValue(subCategory.bench3m)}
                {formatedCategoryValue(subCategory.ratio6m)}
                {formatedCategoryValue(subCategory.bench6m)}
                {formatedCategoryValue(subCategory.ratio1y)}
                {formatedCategoryValue(subCategory.bench1y)}
                {formatedCategoryValue(subCategory.ratio2y)}
                {formatedCategoryValue(subCategory.bench2y)}
                {formatedCategoryValue(subCategory.ratio3y)}
                {formatedCategoryValue(subCategory.bench3y)}
                {formatedCategoryValue(subCategory.ratio4y)}
                {formatedCategoryValue(subCategory.bench4y)}
                {formatedCategoryValue(subCategory.ratio5y)}
                {formatedCategoryValue(subCategory.bench5y)}
            </tr>
            {isExpanded && isLoading &&
            (
                <tr>
                    <td colSpan={17}>
                        <Spinner size={3} />
                    </td>
                </tr>
            )}
            {isExpanded && !isLoading && 
            (
                <ChildFunds funds={funds} index={"fund-" + index} />
            )}
        </React.Fragment>
    );
};
import axios from "axios";
import { proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
import { DataAndCartLines } from "./PortfolioModelWalletService";

setupInterceptorsTo(axios);

export enum CompetitionTypes {
    "Un ganador",
    "Tres ganadores"
}

export type CompetitionInitialValues = ICompetitionInitialValues;

interface ICompetitionInitialValues {
  userId: number;
  savingsPlanId: number;
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  type: number;
  typeOfPrice: number;
  price: number;
  modelPortfolio: string;
  allowOutdatedIncriptions: boolean;
}


interface ICompetition {
    id: number,
    name: string,
    description: string,
    type: CompetitionTypes,
    price: number,
    startDate: string,
    endDate: string,
    competitionsUsers: CompetitionsUsersType[],
    createdAt: string,    
    typeOfPrice: number,
}
export type CompetitionType = ICompetition;

interface ICompetitionsUsers {
    id: number,
    competitionId: number,
    savingsPlanId: number,
    portfolioModelId: number,
    portfolioModel: PortfolioModelType,
    performance?: number,
    createdAt: string,
    updatedAt: string,

}
export type CompetitionsUsersType = ICompetitionsUsers;

interface ICompetitionsUsersElement {
    competitionId: number,
    savingsPlanId: number,
    portfolioModelId: number,
}
export type CompetitionsUsersTypeElement = ICompetitionsUsersElement;

interface IValueVirtualPortfolios {
    id: number,
    portfolioid: number,
    dateValue: string,
    dateCreated: string,
    ammount: number,
    performance1m: number,
    performance3m: number,
    performance6m: number,
    performance1y: number,
    performanceYTD: number,
    performance: number
}

export type ValueVirtualPortfoliosType = IValueVirtualPortfolios;

interface IPortfolioModel {
    id: number,
    name: string,
    deleted: boolean,
    savingsPlanId: number,
    jsonFundsData: DataAndCartLines[],
    valueVirtualPortfolios: ValueVirtualPortfoliosType[],
    createdAt: string,
    updatedAt: string
}

export type PortfolioModelType = IPortfolioModel;

class CompetitionsService {
    public getCompetitions = async (): Promise<ICompetition[]> => {
        let url: string = `${proxyUrl}/competitions`;
        const req = await axios.get(url);
        return req.data;
    }

    public getCompetition = async (competitionId: number): Promise<ICompetition> => {
        let url: string = `${proxyUrl}/competitions/${competitionId}`;
        const req = await axios.get(url);
        return req.data;
    }

    public createCompetition = async (competitionData: CompetitionInitialValues): Promise<ICompetition> => {
        let url: string = `${proxyUrl}/competitions`;
        const req = await axios.post(url, competitionData);
        return req.data;
    }

    public inscribeInCompetition = async (competitionData: CompetitionsUsersTypeElement): Promise<ICompetition> => {
        let url: string = `${proxyUrl}/competition/inscription`;
        const req = await axios.post(url, competitionData);
        return req.data;
    }
}

const competitions = new CompetitionsService();
export default competitions;
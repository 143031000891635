import { useRef } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import DocumentTitle from 'react-document-title';
import './Payments.sass';
import * as Style from './RePaymentSubscriptionStyle';
import React from 'react';
import CustomIcon from '../icons/CustomIcon';
import ElementService from '../../services/ElementsService';
import { decimalFormatter, longDecimalFormatter } from '../../utils/numberFormatter';
import Auth from '../../Auth/Auth';
import SubscriptionService, { SubscriptionType } from '../../services/SubscriptionService';
import PaymentService from '../../services/PaymentService';
import InespayService, { InespayNoPrice, InespayType } from '../../services/InespayService';

interface RouteParams {};
interface Props extends RouteComponentProps<RouteParams> {};

export interface ContractsUnderSameSubscription
{
    subscriptionInfo: Contracts[];
    dateOfOutdate: string;
    outdateRecharge: number;
    subscriptionId: number;
    subscriptionType: string;
    subscriptionLimit: number;
    subscriptionName: string;
    subscriptionPeriod: string;
    subscriptionPrice: number;
    userSubscriptionId: number;
}
export interface Contracts
{
    elementsWater?: number;
    elementsIA?: number;
    savingPlanId?: number;
    savingPlanName?: string;
    elementAmountId?: number;
    contract?: string;
    isSubscriptionRenewalBeforeExpiration?: boolean;
    hasElements?: boolean;
}

const RePaymentSubscription: React.FunctionComponent<Props> = () =>
{
    const { state } = useLocation<any>();
    const contracts = useRef<ContractsUnderSameSubscription>(state?.contracts);
    const arePricesLoaded = useRef<boolean>(false);
    const subscriptionHasNoElements = contracts.current.subscriptionInfo.every(p => p.hasElements === false);
    const [loadingPage, setLoadingPage] = React.useState<boolean>(true);
    const [elementWaterPrice, setElementWaterPrice] = React.useState<number>(0);
    const [elementsWaterUsed, setElementsWaterUsed] = React.useState<number>(0);
    const [elementsIAUsed, setElementsIAUsed] = React.useState<number>(0);
    const [infoMessage, setInfoMessage] = React.useState<string | undefined>(undefined);
    const [initialPrice, setInitialPrice] = React.useState<number>(contracts.current.subscriptionPrice);
    const [finalPrice, setFinalPrice] = React.useState<number>(contracts.current.subscriptionPrice);
    const [newDateSubscription, setNewDateSubscription] = React.useState<string>(contracts.current.dateOfOutdate);
    const [maxElementsWater, setMaxElementsWater] = React.useState<number>(0);
    const [maxElementsIA, setMaxElementsIA] = React.useState<number>(0);
    const [inputWaterValue, setInputWaterValue] = React.useState<number[]>([]);
    const [inputIaValue, setInputIaValue] = React.useState<number[]>([]);
    const [newSubscription, setSubscription] = React.useState<SubscriptionType>();
    const [recharge, setRecharge] = React.useState<number>(contracts.current.outdateRecharge);
    const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
    var user = Auth.getUserProfile();
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const [reference, setReference] = React.useState<string>(`${user.id}-S${contracts.current.subscriptionId}-${today.valueOf().toString()}`);

    React.useEffect(() =>
    {
        if(arePricesLoaded.current === false)
        {
            const arrayElements = Array(contracts.current.subscriptionInfo.length).fill(0);
            setInputWaterValue(arrayElements);
            setInputIaValue(arrayElements);

            var total = arrayElements.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setElementsIAUsed(total);
            setElementsWaterUsed(total);

            var repaymentSubscription = Auth.getRepaymentSubscription();

            if((repaymentSubscription !== "" && repaymentSubscription !== null))
            {
                //Viene del MorePlans
                //Hay que recalcular el recargo.
                var subscriptionId: number = parseInt(Auth.getRepaymentSubscription() as string);
                SubscriptionService.getSubscription(subscriptionId).then((data: SubscriptionType) =>
                {
                    setSubscription(data);
                    setInitialPrice(data.price as number);

                    var dateConverted = orderDate(contracts.current.dateOfOutdate);
                    
                    if(data.name.includes("Mes"))
                    {
                        var newDate = new Date(new Date(dateConverted).setMonth(new Date(dateConverted).getMonth() + 1));
                        setNewDateSubscription(`${String(newDate.getDate()).padStart(2, '0')}-${String(newDate.getMonth() + 1).padStart(2, '0')}-${newDate.getFullYear()}`);
                    }
                    else
                    {
                        var dateFromAnnual = new Date(new Date(dateConverted).setFullYear(new Date(dateConverted).getFullYear() + 1));
                        setNewDateSubscription(`${String(dateFromAnnual.getDate()).padStart(2, '0')}-${String(dateFromAnnual.getMonth() + 1).padStart(2, '0')}-${dateFromAnnual.getFullYear()}`);
                    }

                    var newRecharge = recalculateRecharge(new Date(dateConverted), data.price as number);
                    setRecharge(newRecharge);

                    ElementService.getLastElementsPrice().then((waterPrice: any) =>
                    {
                        setElementWaterPrice(waterPrice);
                        setTotalElementsNeeded(waterPrice, data.price as number, newRecharge);
                        setReference(`${user.id}-S${data.id}-${today.valueOf().toString()}`);
                        arePricesLoaded.current = true;
                        localStorage.removeItem("repaymentSubscription");
                        setLoadingPage(false);
                    });
                });
            }
            else
            {
                //No hace falta recalcular el recargo.
                var subscription: SubscriptionType =
                {
                    description: "",
                    subscriptionType: "",
                    period: "",
                    priceProvider: 0,
                    limit: 0,
                    riskLevel: 0,
                    name: contracts.current.subscriptionName,
                    priceIronia: contracts.current.subscriptionPrice,
                    id: contracts.current.subscriptionId,
                    price: contracts.current.subscriptionPrice,
                    type: contracts.current.subscriptionType,
                };

                setSubscription(subscription);
                setInitialPrice(subscription.price as number);

                let validDate =  new Date(orderDate(contracts.current.dateOfOutdate));

                if (recharge > 0){
                    validDate = new Date(Date.now());
                }

                if(contracts.current.subscriptionPeriod === "month")
                {
                    validDate.setMonth(validDate.getMonth() + 1);
                }
                else
                {
                    validDate.setFullYear(validDate.getFullYear() + 1);
                }
                setNewDateSubscription(`${String(validDate.getDate()).padStart(2, '0')}-${String(validDate.getMonth() + 1).padStart(2, '0')}-${validDate.getFullYear()}`);

                ElementService.getLastElementsPrice().then((waterPrice: any) =>
                {
                    setElementWaterPrice(waterPrice);
                    setTotalElementsNeeded(waterPrice, subscription.price as number, contracts.current.outdateRecharge);
                    arePricesLoaded.current = true;
                    localStorage.removeItem("repaymentSubscription");
                    setLoadingPage(false);
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const orderDate = (dateToOrder: string) =>
    {
        const [day, month, year] = dateToOrder.split("-");
        return `${month}-${day}-${year}`;
    }
    const recalculateRecharge = (dateOutdated: Date, subscriptionPrice: number) =>
    {
        let newRecharge: number = 0;
        const subtract = new Date().getTime() - dateOutdated.getTime();
        const totalDays = subtract / (1000 * 60 * 60 * 24);

        if(totalDays < 30)
        {
            return newRecharge;
        }

        if(subscriptionPrice > 0)
        {
            newRecharge = parseFloat(((subscriptionPrice / 12) * Math.round(totalDays / 30) * 100 / 100).toFixed(2));
        }

        return newRecharge;
    }
    const setTotalElementsNeeded = (priceElementWater: number, initialPrice: number, recharge: number) =>
    {
        var totalElementsWater = (initialPrice+recharge) / priceElementWater;
        setMaxElementsWater(Math.ceil(totalElementsWater));

        var totalElements = (initialPrice+ recharge) * 100;
        setMaxElementsIA(totalElements);
    }
    const fullWaterElements = (elementAmountId?: number) =>
    {
        setInfoMessage(undefined);
        var contractInfo = contracts.current.subscriptionInfo.find(item => item.elementAmountId === elementAmountId);
        var totalElementsUsed = elementsWaterUsed + elementsIAUsed;

        if(finalPrice === 0)
        {
            setInfoMessage("¡El importe de la renovación ya está totalmente cubierto con Elements!");
        }
        else
        {
            if(contractInfo?.elementsWater === 0)
            {
                setInfoMessage("¡No dispones de Elements Agua para utilizar en este contrato!");
            }
            else
            {
                if(totalElementsUsed === 0)
                {
                    ///No se ha utilizado previamente ningún Element, por lo que lo único que tenemos que hacer es cubrir el importe total.
    
                    if(contractInfo?.elementsWater as number >= maxElementsWater)
                    {
                        upgradeElements(maxElementsWater, "water", elementAmountId);
                    }
                    else
                    {
                        upgradeElements(contractInfo?.elementsWater as number, "water", elementAmountId);
                    }
                }
                else
                {
                    ///Hay Elements previamente utilizados. Sólo debemos rellenar lo faltante.
                    var index: number = contracts.current.subscriptionInfo.findIndex(item => item.elementAmountId === elementAmountId);
                    var price: number = contracts.current.subscriptionPrice + contracts.current.outdateRecharge;
    
                    ///Comprobamos si este input tiene ya Elements usándose, porque afecta a la manera de  calcular el precio si es así.
    
                    if(inputWaterValue[index] === 0)
                    {
                        ///No se están usando Elements Agua de este contrato, por lo que el input está vacío y el precio que falta es el finalPrice.

                        var elementsLeft = Math.ceil(finalPrice/elementWaterPrice);
    
                        if(elementsLeft <= (contractInfo?.elementsWater as number))
                        {
                            upgradeElements(elementsLeft, "water", elementAmountId);
                        }
                        else
                        {
                            ///El valor no da para rellenar todos los Elements
                            upgradeElements(contractInfo?.elementsWater as number, "water", elementAmountId);
                        }
                    }
                    else
                    {
                        ///Se están usando Elements en este input. Hay que calcular el precio sin tener en cuenta esos Elements.
                        var priceRemaining = 0;
    
                        if(elementsWaterUsed-inputWaterValue[index] === 0)
                        {
                            priceRemaining = Math.abs(((elementsWaterUsed-inputWaterValue[index]) * elementWaterPrice) + (elementsIAUsed * 0.01) - price);
                        }
                        else
                        {
                            priceRemaining = Math.abs(((elementsWaterUsed-inputWaterValue[index]) * elementWaterPrice) + (elementsIAUsed * 0.01) - finalPrice);
                        }
    
                        var elementsRemaining = Math.ceil(priceRemaining / elementWaterPrice);
    
                        if(elementsRemaining <= (contractInfo?.elementsWater as number))
                        {
                            upgradeElements(elementsRemaining, "water", elementAmountId);
                        }
                        else
                        {
                            ///El valor no da para rellenar todos los Elements, ponemos el tope que haya en este contrato.
                            upgradeElements(contractInfo?.elementsWater as number, "water", elementAmountId);
                        }
                    }
                }
            }
        }
    }
    const fullIAElements = (elementAmountId?: number) =>
    {
        setInfoMessage(undefined);
        var contractInfo = contracts.current.subscriptionInfo.find(item => item.elementAmountId === elementAmountId);
        var totalElementsUsed = elementsWaterUsed + elementsIAUsed;

        if(finalPrice === 0)
        {
            setInfoMessage("¡El importe de la renovación ya está totalmente cubierto con Elements!");
        }
        else
        {
            if(contractInfo?.elementsIA === 0)
            {
                setInfoMessage("¡No dispones de Elements IA para utilizar en este contrato!");
            }
            else
            {
                if(totalElementsUsed === 0)
                {
                    ///No se ha utilizado previamente ningún Element, por lo que lo único que tenemos que hacer es cubrir el importe total.
    
                    if(contractInfo?.elementsIA as number >= maxElementsIA)
                    {
                        upgradeElements(maxElementsIA, "ia", elementAmountId);
                    }
                    else
                    {
                        upgradeElements(contractInfo?.elementsIA as number, "ia", elementAmountId);
                    }
                }
                else
                {
                    ///Hay Elements previamente utilizados. Sólo debemos rellenar lo faltante.
                    var index: number = contracts.current.subscriptionInfo.findIndex(item => item.elementAmountId === elementAmountId);
                    var price: number = contracts.current.subscriptionPrice + contracts.current.outdateRecharge;
    
                    ///Comprobamos si este input tiene ya Elements usándose, porque afecta a la manera de  calcular el precio si es así.
    
                    if(inputIaValue[index] === 0)
                    {
                        ///No se están usando Elements IA de este contrato, por lo que el input está vacío y el precio que falta es el finalPrice.

                        var elementsLeft = Math.ceil(finalPrice / 0.01);
    
                        if(elementsLeft <= (contractInfo?.elementsIA as number))
                        {
                            upgradeElements(elementsLeft, "ia", elementAmountId);
                        }
                        else
                        {
                            ///El valor no da para rellenar todos los Elements
                            upgradeElements(contractInfo?.elementsIA as number, "ia", elementAmountId);
                        }
                    }
                    else
                    {
                        ///Se están usando Elements en este input. Hay que calcular el precio sin tener en cuenta esos Elements.
                        var priceRemaining = 0;
    
                        if(elementsIAUsed-inputIaValue[index] === 0)
                        {
                            priceRemaining = Math.abs((elementsWaterUsed * elementWaterPrice) + ((elementsIAUsed - inputIaValue[index]) * 0.01) - price);
                        }
                        else
                        {
                            priceRemaining = Math.abs((elementsWaterUsed * elementWaterPrice) + ((elementsIAUsed - inputIaValue[index]) * 0.01) - finalPrice);
                        }
    
                        var elementsRemaining = Math.ceil(priceRemaining / 0.01);
    
                        if(elementsRemaining <= (contractInfo?.elementsIA as number))
                        {
                            upgradeElements(elementsRemaining, "ia", elementAmountId);
                        }
                        else
                        {
                            ///El valor no da para rellenar todos los Elements, ponemos el tope que haya en este contrato.
                            upgradeElements(contractInfo?.elementsIA as number, "ia", elementAmountId);
                        }
                    }
                }
            }
        }
    }
    const cleanElementsWater = (elementAmountId?: number) =>
    {
        setInfoMessage(undefined);
        upgradeElements(0, "water", elementAmountId);
    }
    const cleanElementsIA = (elementAmountId?: number) =>
    {
        setInfoMessage(undefined);
        upgradeElements(0, "ia", elementAmountId);
    }
    const handleInputElementsWater = (value: string, elementAmountId?: number) =>
    {
        setInfoMessage(undefined);
        var index: number = contracts.current.subscriptionInfo.findIndex(item => item.elementAmountId === elementAmountId);
        var price: number = initialPrice+recharge;

        if(parseInt(value) < 0)
        {
            setInfoMessage("No se pueden introducir Elements negativos");
        }
        else
        {
            if(contracts.current.subscriptionInfo[index].elementsWater as number < parseInt(value))
            {
                setInfoMessage(`La cantidad de Elements Agua que has intentado introducir (${parseInt(value)} no puede utilizarse, ya que es mayor a la cantidad de elements de los que dispones (${contracts.current.subscriptionInfo[index].elementsWater as number}) en este contrato`);
            }
    
            if(inputWaterValue[index] === 0)
            {
                //Este input estaba vacío antes, por lo que hay que tener en cuenta el ElementsWaterUsed.
                var prevPrice = ((parseInt(value)+elementsWaterUsed) * elementWaterPrice) + (elementsIAUsed * 0.01);
                
                if(prevPrice > price)
                {
                    setInfoMessage("La cantidad de elements que has introducido para utilizar ya excede a la cantidad del precio de la suscripción, por lo que no puedes añadir más Elements Agua");
                }
                else
                {
                    upgradeElements(value === "" ? 0 : parseInt(value), "water", elementAmountId);
                }
            }
            else
            {
                //Este input estaba ya lleno antes, por lo que hay que tener en cuenta la suma de ElementsWater de cada uno de los valores del array menos del que está relleno, que
                //se sustituye por este nuevo valor.
                var previousPrice = ((parseInt(value)+inputWaterValue.filter((_, excludedIndex) => excludedIndex !== index).reduce((acc, valAct) => acc+valAct, 0)) * elementWaterPrice) + (elementsIAUsed * 0.01);
                
                if(previousPrice > price)
                {
                    setInfoMessage("La cantidad de elements que has introducido para utilizar ya excede a la cantidad del precio de la suscripción, por lo que no puedes añadir más Elements Agua");
                }
                else
                {
                    upgradeElements(value === "" ? 0 : parseInt(value), "water", elementAmountId);
                }
            }
        }

    }
    const handleInputElementsIA = (value: string, elementAmountId?: number) =>
    {
        setInfoMessage(undefined);
        var index: number = contracts.current.subscriptionInfo.findIndex(item => item.elementAmountId === elementAmountId);
        var price: number = initialPrice+recharge;

        if(parseInt(value) < 0)
        {
            setInfoMessage("No se pueden introducir Elements negativos");
        }
        else
        {
            if(contracts.current.subscriptionInfo[index].elementsIA as number < parseInt(value))
            {
                setInfoMessage(`La cantidad de Elements IA que has intentado introducir (${parseInt(value)} no puede utilizarse, ya que es mayor a la cantidad de elements de los que dispones (${contracts.current.subscriptionInfo[index].elementsIA as number}) en este contrato`);
            }

            if(inputIaValue[index] === 0)
            {
                //Este input estaba vacío antes, por lo que hay que tener en cuenta el ElementsIAUsed.
                var prevPrice = ((parseInt(value) + elementsIAUsed) * 0.01) + (elementsWaterUsed*elementWaterPrice);
                
                if(prevPrice > price)
                {
                    setInfoMessage("La cantidad de elements que has introducido para utilizar ya excede a la cantidad del precio de la suscripción, por lo que no puedes añadir más Elements IA");
                }
                else
                {
                    upgradeElements(value === "" ? 0 : parseInt(value), "ia", elementAmountId);
                }
            }
            else
            {
                //Este input estaba ya lleno antes, por lo que hay que tener en cuenta la suma de ElementsIA de cada
                //uno de los valores del array menos del que está relleno, que se sustituye por este nuevo valor.
                var previousPrice = ((parseInt(value)+inputIaValue.filter((_, excludedIndex) => excludedIndex !== index).reduce((acc, valAct) => acc+valAct, 0)) * 0.01) + (elementsWaterUsed*elementWaterPrice);
                
                if(previousPrice > price)
                {
                    setInfoMessage("La cantidad de elements que has introducido para utilizar ya excede a la cantidad del precio de la suscripción, por lo que no puedes añadir más Elements IA");
                }
                else
                {
                    upgradeElements(value === "" ? 0 : parseInt(value), "ia", elementAmountId);
                }
            }
        }
    };
    const upgradeElements = (elementsNeccesary: number, type: string, elementAmountId?: number) =>
    {
        const indexInArray = contracts.current.subscriptionInfo.findIndex(item => item.elementAmountId === elementAmountId);

        if(elementAmountId !== undefined)
        {
            const newValues: number[] = type === "water" ? [...inputWaterValue] : [...inputIaValue];
            newValues[indexInArray] = Math.ceil(elementsNeccesary);
            var total = newValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

            if(type === "water")
            {
                setInputWaterValue(newValues);
                setElementsWaterUsed(total);
            }
            else if(type === "ia")
            {
                setInputIaValue(newValues);
                setElementsIAUsed(total);
            }
        }
    }
    React.useEffect(() =>
    {
        var finalPrice: number = initialPrice+recharge-(elementsIAUsed * 0.01)-(elementsWaterUsed * elementWaterPrice);
        
        if(finalPrice < 0)
        {
            finalPrice = 0.00;
        }
        
        var finalPriceStr: string = finalPrice.toFixed(2);
        setFinalPrice(parseFloat(finalPriceStr));
    }, [contracts, elementsIAUsed, elementsWaterUsed, elementWaterPrice, initialPrice, recharge]);
    const sendPayment = async (event: any) =>
    {
        event.preventDefault();
        setIsSubmitted(true);

        var priceToPay: number = parseFloat(finalPrice.toFixed(2));
        
        PaymentService.createPayment(
        {
            amount: priceToPay,
            reference: reference,
            subject: `${user.id}-${newSubscription?.id}`,
            status: 1,
            usersSubscriptionId: contracts.current.userSubscriptionId,
            platform: "INESPAY"
        })
        .then(() =>
        {
            var inespayData: InespayType =
            {
                subject: `${user.id}-${newSubscription?.id}`,
                reference: reference,
                amount: (priceToPay * 100).toString(),
                subscription: true,
                renovation: true,
                oldSubscriptionId: contracts.current.subscriptionId,
                subscriptionId: newSubscription?.id,
                usersSubscriptionId: contracts.current.userSubscriptionId,
                userId: user.id
            };

            if(subscriptionHasNoElements === false)
            {      
                var contractsElements: any = [];
                
                contracts.current.subscriptionInfo.map((item, index) =>
                {
                    return(
                        contractsElements.push(
                        {
                            elementAmountId: item.elementAmountId,
                            water: -1 * inputWaterValue[index],
                            ia: -1 * inputIaValue[index]
                        })
                    );
                });
                    
                inespayData.contractsElements = contractsElements;
            }
            if(contracts.current.subscriptionInfo[0].isSubscriptionRenewalBeforeExpiration !== undefined)
            {
                //Como todas van con el mismo, cogemos la primera.
                inespayData.isPlanRenewalBeforeExpiration = contracts.current.subscriptionInfo[0].isSubscriptionRenewalBeforeExpiration;
            }

            sendToInespay(inespayData);
        },
        (error: any) =>
        {
            setInfoMessage(error);
        });
    };
    const sendToInespay = (inespayData: InespayType) =>
    {
        if(subscriptionHasNoElements === false && finalPrice === 0)
        {
            ///Tenemos Elements que cubren el importe y no vamos a Inespay
            InespayService.postNoPriceInespay(inespayData).then((dataInespay: InespayNoPrice) =>
            {
                if(dataInespay.isError === true)
                {
                    setInfoMessage(dataInespay.statusMessage);
                    setIsSubmitted(false);
                }
                else
                {
                    if(dataInespay.statusMessage === "Inespay")
                    {
                        InespayService.postInespay(inespayData).then((dataInespay: any) =>
                        {
                            const inespay_result = JSON.parse(dataInespay.Result);
                            if(inespay_result.status === '200')
                            {
                                window.location.href = inespay_result.url;
                            }
                            else
                            {
                                setInfoMessage(inespay_result.description);
                                setIsSubmitted(false);
                            }
                        },
                        (error: any) =>
                        {
                            setInfoMessage("Se ha producido un error al procesar su transacción. Por favor, inténtelo de nuevo más tarde.");
                        });
                    }
                    else
                    {
                        window.location.href = dataInespay.statusMessage;
                    }
                }
            });
        }
        else
        {
            //No cubrimos el importe íntegro y vamos a Inespay
            if(parseFloat(inespayData.amount) > 0 && parseFloat(inespayData.amount) < 100)
            {
                setInfoMessage("El valor de compra con Elements debe ser completamente 0€ o mayor de 1€ para poder procesar la transacción. Los valores entre 0.01€ y 1€ no son aceptados por la plataforma PSD2.");
                setIsSubmitted(false);
            }
            else
            {
                setIsSubmitted(true);
                InespayService.postInespay(inespayData).then((dataInespay: any) =>
                {
                    const inespay_result = JSON.parse(dataInespay.Result);
                    if(inespay_result.status === '200')
                    {
                        window.location.href = inespay_result.url;
                    }
                    else
                    {
                        setInfoMessage(inespay_result.description);
                        setIsSubmitted(false);
                    }
                },
                (error: any) =>
                {
                    setInfoMessage("Se ha producido un error al procesar su transacción. Por favor, inténtelo de nuevo más tarde.");
                });
            }
        }
    };

    return(
        <DocumentTitle title='IronIA - Renovación de Suscripción'>
            <React.Fragment>
                <Style.DivRowPrincipal className="ms-Grid-row">
                    <Style.PrincipalHeader>
                        Renovación de la Suscripción
                    </Style.PrincipalHeader>
                    <Style.PrincipalDescription>
                        Este método de pago conecta con tu banca online utilizando las claves de acceso de tu banca online.
                    </Style.PrincipalDescription>
                    <Separator className="separatorRepayment" style={{marginTop: "0 !important"}} />
                </Style.DivRowPrincipal>
                {loadingPage === true &&
                (
                    <React.Fragment>
                        <Spinner size={SpinnerSize.large} style={{paddingTop: "2em"}} />
                        <Style.TextLoading>
                            Estamos cargando todos los datos necesarios asociados para la Renovación de tu Suscripción...
                        </Style.TextLoading>
                    </React.Fragment>
                )}
                {loadingPage === false &&
                (
                    <React.Fragment>
                        {subscriptionHasNoElements === false &&
                        (
                            <Style.Card>
                                <Style.CardTitle>
                                    Utiliza tus Elements
                                </Style.CardTitle>
                                <Style.TableContainer>
                                    <Style.TableHeader>Plan de Cartera</Style.TableHeader>
                                    <Style.TableHeader>Contrato</Style.TableHeader>
                                    <Style.TableHeader>
                                        Elements &nbsp; Agua
                                        <br />
                                        <Style.SpanIntraCell>
                                            Precio
                                        </Style.SpanIntraCell>
                                        <span>
                                            Valor
                                        </span>
                                    </Style.TableHeader>
                                    <Style.TableHeader>Elements IA</Style.TableHeader>
                                    <Style.TableHeader>Elements Agua</Style.TableHeader>
                                    <Style.TableHeader>Elements IA</Style.TableHeader>
                                    {contracts?.current.subscriptionInfo.map((contractInfo: Contracts, index: number) =>
                                    (
                                        <React.Fragment key={index}>
                                            <Style.TableCell>{contractInfo.savingPlanName}</Style.TableCell>
                                            <Style.TableCell>{contractInfo.contract}</Style.TableCell>
                                            <Style.TableCell>
                                                <Style.SpanIntraCell>
                                                    {longDecimalFormatter.format(elementWaterPrice)} €
                                                </Style.SpanIntraCell>
                                                <span>
                                                    {contractInfo.elementsWater !== undefined ? decimalFormatter.format(contractInfo.elementsWater) : 0}
                                                </span>
                                                {contractInfo.hasElements === true &&
                                                (
                                                    <Style.SpanButton onClick={() => fullWaterElements(contractInfo.elementAmountId)}>
                                                        <CustomIcon disabled={contractInfo.elementsWater === 0 || finalPrice === 0} size={"15"} color={"#CC214F"} iconName={"NavigateForward"} marginLeft={"0.5em"} />
                                                    </Style.SpanButton>
                                                )}
                                                {contractInfo.hasElements === false &&
                                                (
                                                    <Style.SpanButton style={{cursor: "not-allowed"}}>
                                                        <CustomIcon size={"15"} disabled={true} iconName={"NavigateForward"} marginLeft={"0.5em"} />
                                                    </Style.SpanButton>
                                                )}
                                            </Style.TableCell>
                                            <Style.TableCell>
                                                <Style.SpanIntraCell>
                                                    {longDecimalFormatter.format(contractInfo.elementsIA as number)}
                                                </Style.SpanIntraCell>
                                                {contractInfo.hasElements === true &&
                                                (
                                                    <Style.SpanButton onClick={() => fullIAElements(contractInfo.elementAmountId)}>
                                                        <CustomIcon disabled={contractInfo.elementsIA === 0 || finalPrice === 0} size={"15"} color={"#CC214F"} iconName={"NavigateForward"} marginLeft={"0.5em"} />
                                                    </Style.SpanButton>
                                                )}
                                                {contractInfo.hasElements === false &&
                                                (
                                                    <Style.SpanButton style={{cursor: "not-allowed"}}>
                                                        <CustomIcon size={"15"} disabled={true} iconName={"NavigateForward"} marginLeft={"0.5em"} />
                                                    </Style.SpanButton>
                                                )}
                                            </Style.TableCell>
                                            <Style.ElementsGroup>
                                                <Style.ElementsInput disabled={contractInfo.elementsWater === 0} type="text" value={inputWaterValue[index]} onChange={(e) => handleInputElementsWater(e.target.value, contractInfo.elementAmountId)} />
                                                {contractInfo.hasElements === true &&
                                                (
                                                    <Style.ElementsButton onClick={() => cleanElementsWater(contractInfo.elementAmountId)}>
                                                        <CustomIcon disabled={contractInfo.elementsWater === 0} iconName='EraseTool' size={"15"} color={"#CC214F"} />
                                                    </Style.ElementsButton>
                                                )}
                                                {contractInfo.hasElements === false &&
                                                (
                                                    <Style.ElementsButton>
                                                        <CustomIcon iconName='EraseTool' disabled={true} size={"15"} color={"#CC214F"} />
                                                    </Style.ElementsButton>
                                                )}
                                            </Style.ElementsGroup>
                                            <Style.ElementsGroup>
                                                <Style.ElementsInput disabled={contractInfo.elementsIA === 0} type="text" value={inputIaValue[index]} onChange={(e) => handleInputElementsIA(e.target.value, contractInfo.elementAmountId)} />
                                                {contractInfo.hasElements === true &&
                                                (
                                                    <Style.ElementsButton onClick={() => cleanElementsIA(contractInfo.elementAmountId)}>
                                                        <CustomIcon disabled={contractInfo.elementsIA === 0} iconName='EraseTool' size={"15"} color={"#CC214F"} />
                                                    </Style.ElementsButton>
                                                )}
                                                {contractInfo.hasElements === false &&
                                                (
                                                    <Style.ElementsButton>
                                                        <CustomIcon disabled={true} iconName='EraseTool' size={"15"} color={"#CC214F"} />
                                                    </Style.ElementsButton>
                                                )}
                                            </Style.ElementsGroup>
                                        </React.Fragment>
                                    ))}
                                </Style.TableContainer>
                                {infoMessage !== undefined &&
                                (
                                    <Style.FlexContainer>
                                        <Style.TextRightAligned>
                                            <Style.InfoIconStyled id="repayment" />
                                            {infoMessage}
                                        </Style.TextRightAligned>
                                    </Style.FlexContainer>
                                )}
                            </Style.Card>
                        )}
                        <Style.CardContainer>
                            <Style.PaymentCard>
                                <Style.RowSubscriptionCard className="ms-Grid-row">
                                    <Style.ColSubscriptionTitle className="ms-Grid-col ms-sm12 ms-lg7 ms-xl6">
                                        <Style.CardTitle>
                                            Pago de la suscripción
                                        </Style.CardTitle>
                                    </Style.ColSubscriptionTitle>
                                    {(contracts.current.subscriptionType === "promotion" || contracts.current.subscriptionType === "experience") &&
                                    (
                                        <Style.ColSubscriptionButton className="ms-Grid-col ms-sm12 ms-lg5 ms-xl6">
                                            <Style.MoreOptionsWrapper>
                                                <Style.MoreOptions to={
                                                    {
                                                        pathname: "/Repayment/morePlans",
                                                        state: {contracts: contracts.current}
                                                    }}
                                                >
                                                    Más opciones de Renovación
                                                </Style.MoreOptions>
                                            </Style.MoreOptionsWrapper>
                                        </Style.ColSubscriptionButton>
                                    )}
                                </Style.RowSubscriptionCard>
                                <Style.PaymentBreakdown>
                                    <Style.PaymentItem>
                                        <Style.PaymentLabel>Suscripción {newSubscription?.name}</Style.PaymentLabel>
                                        <Style.PaymentValue>+ {decimalFormatter.format(newSubscription?.price as number)} €</Style.PaymentValue>
                                    </Style.PaymentItem>
                                    {subscriptionHasNoElements === false &&
                                    (
                                        <React.Fragment>
                                            <Style.PaymentItem>
                                                <Style.PaymentLabel>Elements Agua</Style.PaymentLabel>
                                                <Style.PaymentValue>- {decimalFormatter.format(parseFloat((elementsWaterUsed * elementWaterPrice).toFixed(2)))} €</Style.PaymentValue>
                                            </Style.PaymentItem>
                                            <Style.PaymentItem>
                                                <Style.PaymentLabel>Elements IA</Style.PaymentLabel>
                                                <Style.PaymentValue>- {decimalFormatter.format(elementsIAUsed * 0.01)} €</Style.PaymentValue>
                                            </Style.PaymentItem>
                                        </React.Fragment>
                                    )}
                                    <Style.PaymentItem>
                                        <Style.PaymentLabel>Retrasos</Style.PaymentLabel>
                                        <Style.PaymentValue>+ {decimalFormatter.format(recharge)} €</Style.PaymentValue>
                                    </Style.PaymentItem>
                                    <Style.PaymentTotal>
                                        <Style.PaymentLabel>Total</Style.PaymentLabel>
                                        <Style.PaymentValue>{decimalFormatter.format(finalPrice)} €</Style.PaymentValue>
                                    </Style.PaymentTotal>
                                </Style.PaymentBreakdown>
                                <Style.PaymentInfo>
                                    <p>Renovación hasta el <Style.RenewalDate>{newDateSubscription}</Style.RenewalDate></p>
                                    <p>Beneficiario</p>
                                    <p>DIAPHANUM VALORES, SV, S.A.U</p>
                                    <p>A87637450 Maria de Molina 40, Planta 7 - Madrid 28006</p>
                                </Style.PaymentInfo>
                                {isSubmitted &&
                                (
                                    <React.Fragment>
                                        <Style.PayButtonDisabled disabled={true}>
                                            <Style.SpinnerContainer>
                                                <Spinner size={SpinnerSize.small} className="button-spinner" />
                                                <Style.SpinnerText>
                                                    Pagando...
                                                </Style.SpinnerText>
                                            </Style.SpinnerContainer>
                                        </Style.PayButtonDisabled>
                                    </React.Fragment>
                                )}
                                {!isSubmitted &&
                                (
                                    <React.Fragment>
                                        <Style.PayButton onClick={sendPayment}>
                                            Pagar
                                        </Style.PayButton>
                                    </React.Fragment>
                                )}
                            </Style.PaymentCard>
                            <Style.PaymentInfoBox>
                                <p>El retraso en el pago de la suscripción implica el pago del tiempo desde que caducó hasta que la suscripción se renueva por el período especificado.</p>
                                <p>Si se cancela una suscripción se devolverá el dinero correspondiente al tiempo del servicio no disfrutado.</p>
                            </Style.PaymentInfoBox>
                        </Style.CardContainer>
                    </React.Fragment>
                )}
            </React.Fragment>
        </DocumentTitle>
    );
};

export default RePaymentSubscription;
import styled from "styled-components";

export const TitleDiv = styled.div`
    margin: 0;
`;
export const Title = styled.h1`
    margin: 0;
    cursor: default;
`;
export const SubTitle = styled.h4`
    margin: 0;
    padding-top: 1em;
    cursor: default;
    font-style: normal;
    font-weight: normal;
`;
export const FundName = styled.div`
    cursor: default;
    line-height: 24px;
    margin: 0;
    font-size: clamp(1rem, 2.5vw, 0.45rem) !important;
    font-weight: 800 !important;
    overflow: hidden;
    text-overflow: ellipsis;

    > a
    {
        color: #CC214F !important;
    }
`;
export const LoadingText = styled.h3`
    text-align: center;
    margin: 1em;
    cursor: default;
`;
export const RowSearchCards = styled.div``;
export const ColSearchEngine = styled.div`
    margin-top: 1rem;
`;
export const RowCards = styled.div``;
export const ColCards = styled.div``;
export const ColCard = styled.div`
    padding-top: 1em;
`;
export const RowPagination = styled.div``;
export const ColPagination = styled.div``;
export const Table = styled.table``;
export const ThColumn = styled.th`
    text-align: center;
`;
export const PCTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
`;
export const PCWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 15px 20px;
    border-radius: 12px;
    box-shadow: ${props => props?.theme === 'light' ?  "rgba(0, 0, 0, 0.35) 0px 3px 6px" : "#FFF 6px 7px 12px"};

    &:hover
    {
        box-shadow: ${props => props?.theme === 'light' ? "rgba(0, 0, 0, 0.6) 0px 6px 12px" : "#CC214F 0px 0px 20px 12px"};
    }
`;
export const PCHeader = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    cursor: default;
    min-height: 115px;
`;
export const PCBody = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;
export const PCIconsContainer = styled.div`
    display: flex;
    flex: 1;
    padding: 10px 0;
`;
export const PCScoreContainer = styled.div`
    display: flex;
    flex: 1;
    font-size: 20px;
    cursor: default;
    font-weight: 600;
    > span span
    {
        color: #BEC7C7;
    }
`;
export const PCCleanShareContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
`;
export const PCDescription = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    cursor: default;
    min-height: 215px;
`;
export const PCIsin = styled.div`
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    margin-bottom: 10px;
`;
export const PCName = styled.div`
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 800;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    margin-bottom: 10px;
`;
export const PCSubcategory = styled.div`
    display: flex;
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: pre-wrap;
`;
export const PCManager = styled.div`
    display: flex;
    flex: 1;
    margin-top: 10px;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    text-transform: capitalize;
    cursor: pointer;

    a
    {
        color: #95A0A1 !important;
    }
`;
export const PCViewButtonContainer = styled.div`
    display: flex;
    flex: 0;
`;
export const PCViewButton = styled.button`
    text-align: center;
    border-radius: 32px;
    background: #cc214f;
    width: 100%;
    border: none;
    margin: 15px 0 5px 0;
    padding: 20px 0;
    cursor: pointer;
    color: white;
`;
export const FilterContainer = styled.div`
    display: flex;
    flex: 0 1 50px;
    margin-bottom: 1rem;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    > *
    {
        vertical-align: top;
        flex-direction: column;
        display: block;
    }
`;
export const CountContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 0.75;
    margin-top: 0.15rem;
    padding-right: 0.5em;
`;
export const CountTitle = styled.span`
    font-size: 19px;
    font-weight: 500;
    cursor: default;
`;
export const Count = styled.span`
    color: #94A1A1;
    margin-left: 5px;
`;
export const InputSearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 3;
    justify-content: center;
    &.jcl
    {
        justify-content: left;
    }
    > div,
    input
    {
        border-radius: 100px;
        border: 1px solid lightGrey;
        padding-left: 10px;
        :hover
        {
            border: 1px solid lightGrey;
        }
        ::after
        {
            border: none;
        }
    }
`;
export const ButtonSearchContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    
    > button
    {
        cursor: pointer;
    }
`;
export const PaginationContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
    gap: 20px;
    span
    {
        color: #95A0A1;
        border-radius: 16px;
        line-height: 28px;
    }
    > *
    {
        vertical-align: middle;
    }
`;
export const PageCount = styled.div`
    display: flex;
`;
export const PageButtons = styled.div`
    button
    {
        cursor: pointer;
        border: 0;
        color: #CC214F;
        &.disabled
        {
            cursor: default;
            color: #F1BAC9;
        }
    }
`;
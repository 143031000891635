import * as S from '../styles/RefundsSignatureStyles';
import { useEffect, useState } from 'react';
import { useHistory, RouteComponentProps, useLocation } from 'react-router-dom';
import moment from "moment";
import { ISignature } from '../../../services/SignaturesService';
import { SignatureComponent } from '../../../components/Signature/SignatureComponent';
import OrderService, { OrderType } from '../../../services/OrderService';
import ProcessingModalComponent from "../../../components/Modals/ProcessingModalComponent";
import { useBoolean } from "@fluentui/react-hooks";
import { MessageBar, MessageBarType } from "@fluentui/react";
import DocumentTitle from 'react-document-title';
import React from 'react';

interface SignatureProps extends RouteComponentProps {}

const RefundsSignature = (props: SignatureProps) =>
{
    const { state } = useLocation<any>();
    let refund: any = state?.refund;
    let defaultSignature: any = state?.signature;
    const history = useHistory();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [signature, setSignature] = useState(defaultSignature);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [showAlert, { toggle: toggleShowAlert }] = useBoolean(false);

    const processExpirationDate = (signature: ISignature) =>
    {
        if(signature && typeof signature.expiresAt === "string")
        {
            signature.expiresAt = moment(signature.expiresAt).toDate();
        }
    }
    processExpirationDate(defaultSignature);
    useEffect(() =>
    {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() =>
    {
        var operationPhone = state?.refund.userIdentity?.operationPhone;
        setPhoneNumber("X".repeat(operationPhone.length - 3) + operationPhone.slice(operationPhone.length - 3, operationPhone.length));
    }, [state]);
    if(!state?.signature || !state?.refund)
    {
        history.push("/portfolio");
        return;
    }

    const submit = (signature: ISignature) =>
    {
        showModal();
        var data = { id: refund.id, signatureId: state.signature.id };
        OrderService.patchOrder(data).then((response: OrderType) =>
        {
            if(response.signatureId)
            {
                history.push("/portfolio/orders");
            }
            else
            {
                hideModal();
                toggleShowAlert();
            }
        })
        .catch((error) =>
        {
            hideModal();
            toggleShowAlert();
        });
    };

    return(
        <React.Fragment>
            <DocumentTitle title='IronIA - Firma de Reembolso'>
                <React.Fragment>
                    <S.HelperStyle />
                    <S.Cont>
                        <S.MainCont>
                            {showAlert &&
                            (
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">
                                    Se ha producido un error al procesar la operación.
                                </MessageBar>
                            )}
                            <SignatureComponent
                                userIdentities={[refund?.userIdentity]}
                                signatures={[signature]}
                                alreadySignedText="¡Tu orden de reembolso está en proceso!"
                                title="Firma del Reembolso"
                                submitButtonText="Firmar Reembolso"
                                cancelButtonText="Volver"
                                headerTextArea={`Introduzca la clave SMS que le hemos enviado a ${phoneNumber} para validar su reembolso.`}
                                backButtonText="Volver"
                                onBackButtonClicked={() => history.push("portfolio/refunds")}
                                onCancelButtonClicked={() => history.goBack()}
                                requestNewCodeButtonText="Recibir nuevo código"
                                onNewCodeSended={(signature: any) =>
                                {
                                    processExpirationDate(signature);
                                    setSignature(signature);
                                }}
                                onSignatureChanged={(signature: any) =>
                                {
                                    processExpirationDate(signature);
                                    setSignature(signature);
                                }}
                                onSigned={(signature: any) =>
                                {
                                    processExpirationDate(signature);
                                    setSignature(signature);
                                    submit(signature);
                                }}
                            />
                            <ProcessingModalComponent
                                isModalOpen={isModalOpen}
                                hideModal={hideModal}
                            />
                        </S.MainCont>
                    </S.Cont>
                </React.Fragment>
            </DocumentTitle>
        </React.Fragment>
    );
};

export default RefundsSignature;
import { useState, useContext } from 'react';
import { FilterContainer, ToggleContainer, ToggleTitle, FilterLabel } from './layout.styles';
import { ThemeContext } from '@fluentui/react-theme-provider';
import ManagerService from '../../services/ManagerService';
import { getCurrencies } from '../../services/currencies';
import { getSubcategories } from '../../services/subcategories';
import { useEffect } from 'react';
import { DirectionalHint, Spinner } from '@fluentui/react';
import ThreeWayToggle from '../ThreeWayToggle/ThreeWayToggle';
import CustomDopdownCategory from '../dropdown/CustomDopdownCategory';
import CustomDropdownManager from '../dropdown/CustomDropdownManager';
import { TeachingBubble } from "@fluentui/react";
import { translate } from "../../utils/i18n";

const Filters = ({ selectedFilters, onChange, hiddenFilters }) =>
{
    const theme = useContext(ThemeContext);

    const [dropdownData, setDropdownData] = useState({
        managers: [],
        currencies: [],
        subcategories: [
            {
                morningStarCategoryId: "",
                name: ""
            }
        ]
    });

    const [loading, setLoading] = useState(false);
    const [tooltipClassToggle, setTooltipClassToggle] = useState(false);

    const DropdownTitle = ({ title }) =>
    {
        if (!loading) return title;

        return (
            <div style={{ display: "flex" }}>
                <Spinner size={0} />
                <span style={{ marginLeft: "5px" }}>{title}</span>
            </div>
        );
    };

    useEffect(() =>
    {
        async function populateFilters()
        {
            setLoading(true);
            let managers = await ManagerService.getBQManagers();
            let currencies = await getCurrencies();
            let subcategories = await getSubcategories();

            const convertToItem = (value, index, array) =>
            {
                array[index] = {
                    key: value.name || value,
                    label: value.name || value,
                    value: value
                };
            };
            managers.forEach(convertToItem);
            currencies.forEach(convertToItem);
            subcategories.forEach(convertToItem);

            setDropdownData(currentDropdownData => ({ ...currentDropdownData, managers, currencies, subcategories }));
            setLoading(false);
        }

        populateFilters();
    }, []);

    return (
        <>
            <FilterContainer>
                <CustomDopdownCategory
                    options={dropdownData.subcategories}
                    title={<DropdownTitle title="Categorías del fondo" />}
                    selectedValues={selectedFilters.subcategory}
                    onSave={value =>
                    {
                        let values = value.map(({ value }) =>
                        {
                            return value;
                        });
                        onChange(values, "subcategory");
                    }}
                />
                {selectedFilters &&
                    selectedFilters.subcategory &&
                    selectedFilters.subcategory.length > 0 &&
                    selectedFilters.subcategory.map((filter, filterIndex) =>
                    {
                        return <FilterLabel key={"filterIndex" + filterIndex}>{filter.name}</FilterLabel>;
                    })
                }
            </FilterContainer>
            {hiddenFilters.manager === undefined &&
            (
                <FilterContainer>
                    <CustomDropdownManager
                        options={dropdownData.managers}
                        title={<DropdownTitle title="Gestora" />}
                        selectedValues={selectedFilters.manager}
                        onSave={value =>
                        {
                            let values = value.reduce(function (all, current) {
                                {
                                    current = current.value;
                                    if (typeof current !== 'string') {
                                        return [...all, ...current];
                                    }
                                    else {
                                        return [...all, current];
                                    }
                                }
                            }, []);
                            onChange(values, "manager");
                        }}
                    />
                    {selectedFilters &&
                        selectedFilters.manager &&
                        selectedFilters.manager.length > 0 &&
                        Array.from(new Set(selectedFilters.manager.map(m => translate(m)))).map((filter, filterIndex) =>
                        {
                            return <FilterLabel key={"filterIndex" + filterIndex}>{filter}</FilterLabel>;
                        })}
                </FilterContainer>
            )}
            <FilterContainer>
                <CustomDopdownCategory
                    options={dropdownData.currencies}
                    title={<DropdownTitle title="Divisa" />}
                    selectedValues={selectedFilters.currency}
                    onSave={value =>
                    {
                        let values = value.map(val =>
                        {
                            val = val.value;
                            return val;
                        });
                        onChange(values, "currency");
                    }}
                />
                {selectedFilters &&
                    selectedFilters.currency &&
                    selectedFilters.currency.length > 0 &&
                    selectedFilters.currency.map((filter, filterIndex) =>
                    {
                        return <FilterLabel key={"filterIndex" + filterIndex}>{filter}</FilterLabel>;
                    })}
            </FilterContainer>
            <FilterContainer>
                <ToggleContainer theme={theme}>
                    <ToggleTitle theme={theme}>Disponibilidad</ToggleTitle>
                    <ThreeWayToggle onChange={value => onChange(value, "available")} />
                </ToggleContainer>
            </FilterContainer>
            <FilterContainer>
                <ToggleContainer theme={theme}>
                    <ToggleTitle theme={theme}>Dividendos</ToggleTitle>
                    <ThreeWayToggle onChange={value => onChange(value, "income")} />
                </ToggleContainer>
            </FilterContainer>
            <FilterContainer>
                <ToggleContainer theme={theme}>
                    <ToggleTitle theme={theme}>Traspasable</ToggleTitle>
                    <ThreeWayToggle onChange={value => onChange(value, "switchable")} />
                </ToggleContainer>
            </FilterContainer>
            <FilterContainer>
                <ToggleContainer theme={theme}>
                    <ToggleTitle theme={theme}>Indexado</ToggleTitle>
                    <ThreeWayToggle onChange={value => onChange(value, "indexed")} />
                </ToggleContainer>
            </FilterContainer>
            <FilterContainer>
                {tooltipClassToggle === true &&
                (
                    <TeachingBubble target="#tooltipClassToggle" hasCloseButton={false} headline="Filtro de Clases" onDismiss={() => setTooltipClassToggle(false)} calloutProps={{directionalHint: DirectionalHint.topCenter}}>
                        <h4>
                            {"Clases: SÍ"}
                        </h4>
                        <div>
                            {"Devuelve todas las clases de un fondo si una clase cumple con los criterios de búsqueda."}
                        </div>
                        <h4>
                            {"Clases: NO"}
                        </h4>
                        <div>
                            {"Devuelve solo las clases que cumplen los criterios de búsqueda, pueden existir clases más baratas que las buscadas."}
                        </div>
                    </TeachingBubble>
                )}
                <div className="ms-Grid-col ms-sm12">
                    <div id="tooltipClassToggle" onMouseEnter={() => setTooltipClassToggle(true)} onMouseLeave={() => setTooltipClassToggle(false)}>
                        <ToggleContainer theme={theme}>
                            <ToggleTitle theme={theme}>Clases</ToggleTitle>
                            <ThreeWayToggle toggleInitialState={1} disableMiddlePosition={true} onChange={value => onChange(value, "classCategory")} />
                        </ToggleContainer>
                    </div>
                </div>
            </FilterContainer>
        </>
    );
};

export default Filters;

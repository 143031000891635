import axios from 'axios';
import subcategoriesMock from '../mocks/subcategories';
import { fundsService } from "../config";

export type CategoriesWithRatiosResponse =
{
    categories: Category[];
    subCategories: SubCategory[];
}
interface IRatios
{    
    ratio1m?: number;
    ratio3m?: number;
    ratio6m?: number;
    ratio1y?: number;
    ratio2y?: number;
    ratio3y?: number;
    ratio4y?: number;
    ratio5y?: number;
    bench1m?: number;
    bench3m?: number;
    bench6m?: number;
    bench1y?: number;
    bench2y?: number;
    bench3y?: number;
    bench4y?: number;
    bench5y?: number;
}
interface ICategory
{
    category: string;
}
interface ISubCategory
{
    category: string;
    subCategory: string;
    morningstarCategoryId: string;
}
interface IFund
{
    name: string;
    isin: string;
    allfunds_id: number;
    benchmarkFinametrixId: number
}

export type Category =  ICategory & IRatios
export type SubCategory =  ISubCategory & IRatios
export type FundsWithRatiosResponse =  IFund & IRatios

export const getSubcategories = async () =>
{
    try
    {
        const response = await axios.get(fundsService + '/subcategories');
        return response.data;
    }
    catch(error)
    {
        return subcategoriesMock;
    }
};
export const getSubcategoriesWithRatios = async (ratio: string): Promise<CategoriesWithRatiosResponse> =>
{
	const response = await axios.get(fundsService + `/SubCategoriesWithRatios?ratio=${encodeURIComponent(ratio)}`);
	return response.data;
};
export const getFundsWithRatiosBySubcategory = async (ratio: string, subCategory: string): Promise<FundsWithRatiosResponse[]> =>
{
	const response = await axios.get(fundsService + `/FundsWithRatiosBySubCategory?ratio=${encodeURIComponent(ratio)}&subCategory=${encodeURIComponent(subCategory)}`);
	return response.data;
};
import { useContext, useState } from 'react';
import * as L from '../layout.styles';
import { DetailsList, DetailsListLayoutMode, DetailsRow, DetailsRowCheck, IColumn, IDetailsFooterProps, IDetailsRowBaseProps, SelectionMode, TeachingBubble } from '@fluentui/react';
import { Link } from "react-router-dom";
import { currencyFormatter, decimalFormatter } from '../../../utils/numberFormatter';
import { IFund } from '../../../services/FundService';
import { FundDocumentsCallout } from './FundDocumentsCallout';
import { CartColumnActions, CartColumnPercentage } from './CartTableColumns';
import NumberFormat from "react-number-format";
import React from 'react';
import { CartModes } from '../CartScreen';
import { ThemeContext } from "@fluentui/react-theme-provider";
//const { uuid } = require('uuidv4');
interface CartTableProps
{
    items: any[];
    total: number;
    modelPercentageTotal?: number;
    removeCartLine?: (item: any) => any;
    updateCartLine?: (item: any, newAmount: any) => any;
    updateCartModelPercentageLine?: (item: any, newAmount: any) => any;
    readOnly?: any;
    cartModeStatus?: CartModes;
    currentInstrumentIds?: string[]
}

export const GetCartInfo = (cartLines: any[], currentInstrumentIds?: string[]) =>
{
    let total = 0;
    let modelPercentageTotal = 0;
    let hasEmptyAmounts = false;
    let hasInvalidAmounts = false;
    let hasNonAvailableFunds = false;
    let nonAvailableFunds: string[] = [];

    const items = cartLines.map(item =>
    {
        total += item.amount;
        modelPercentageTotal += item.modelPercentage;

        if(item.amount <= 0)
        {
            hasEmptyAmounts = hasInvalidAmounts = true;
        }
        else
        {
            if(item.fund.noMin)
            {
                var minimum = currentInstrumentIds !== undefined && currentInstrumentIds.includes(item.fund.finametrixId)
                    ? item.fund.minimumAddtionalInvestment
                    : item.fund.minimumInitialInvestment;

                if(item.amount < minimum)
                {
                    hasInvalidAmounts = true;
                }
            }
        }

        if(!item?.fund?.available)
        {
            hasNonAvailableFunds = true;
            nonAvailableFunds.push(item?.fund?.name);
        }

        return(
        {
            ...(item.fund),
            amount: item.amount,
            modelPercentage: item.modelPercentage,
            key: item.fund.isin,
            readOnly: item.readOnly,
            userSuscriptionId: item.userSuscriptionId,
            savingsPlanId: item.savingsPlanId
        });
    });

    return {items, total, modelPercentageTotal, hasEmptyAmounts, hasInvalidAmounts, hasNonAvailableFunds, nonAvailableFunds};
};

const baseColumns: IColumn[] =
[
    { key: "name", name: "Nombre", fieldName: "name", minWidth: 100, isResizable: true },
    { key: "isin", name: "ISIN", fieldName: "isin", minWidth: 85, isResizable: true },
    { key: "ongoingCharges", name: "Gastos corrientes", fieldName: "ongoingCharges", minWidth: 160, className: "percentage-detail", isResizable: true },
    { key: "managementFee", name: "Comisión gestión", fieldName: "managementFee", minWidth: 160, className: "percentage-detail", isResizable: true },
    { key: "rebate", name: "Retrocesión", fieldName: "rebate", minWidth: 160, className: "percentage-detail", isResizable: true },
    { key: "minimumInitialInvestment", name: "Inversión mínima", fieldName: "minimumInitialInvestment", className: L.classNames.number, headerClassName: L.classNames.number, minWidth: 100, isResizable: true },
    { key: "noMin", name: "Aplica mínimo", fieldName: "noMin", minWidth: 90, isResizable: true },
    { key: "cleanShare", name: "Clase limpia", fieldName: "cleanShare", minWidth: 75, isResizable: true }
];

const onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element =>
{
    return <DetailsRowCheck {...props} selected={true} />;
};

export const CartTable = (props: CartTableProps) =>
{
    let columns = baseColumns;
    const { cartModeStatus = CartModes.DEFAULT } = props;
    const theme = useContext(ThemeContext);
    const [tooltipsClassToggle, setTooltipsClassToggle] = useState<boolean[]>(new Array(props.items.length).fill(true));
    const [count, setCount] = React.useState(0);

    const onDismiss = (ev: any, index: number, visible: boolean) =>
    {
        setCount(s => s+1);
        setTooltipsClassToggle(prevState =>
        {
            prevState[index ?? 0] = visible;
            return prevState;
        });
    }

    if(cartModeStatus === CartModes.VIEW_MODEL_PORTFOLIO || cartModeStatus === CartModes.CREATE_MODEL_PORTFOLIO || cartModeStatus === CartModes.PUNCTUAL_CONTRIBUTION)
    {
        columns =
        [
            ...baseColumns,
            {
                key: "modelpercentage",
                name: "%",
                fieldName: "modelpercentage",
                minWidth: 100,
                isResizable: true,
                className: "cell-input text-right",
                headerClassName: L.classNames.number
            }
        ];
    }
    else
    {
        columns =
        [
            ...baseColumns,
            {
                key: "amount",
                name: "Importe (€)",
                fieldName: "amount",
                minWidth: 130,
                isResizable: true,
                className: "cell-input text-right",
                headerClassName: L.classNames.number,
                styles: {root:{ 'alignItems': 'end', 'flexDirection': 'column' }}
            }
        ];
    }

    if(!props.readOnly)
    {
        columns =
        [
            ...columns,
            {key: "actions", name: "", minWidth: 45, maxWidth: 45, isResizable: false, isRowHeader: false, className: L.classNames.actions}
        ];
    }

    const [isAttachmentsVisible, setIsAttachmentsVisible] = useState(false);
    const [selectedRegister, setSelectedRegister] = useState<IFund | undefined>(undefined);
    const toggleAttachmentsVisible = (isin: string) =>
    {
        const register = props.items.find(item => item.isin === isin);
        setSelectedRegister(register);
        setIsAttachmentsVisible(true);
    };
    const renderColumn = (item: any, index?: number, column?: IColumn) =>
    {
        if(!column)
        {
            return;
        }
        switch(column?.key)
        {
            case "name":
                return(
                    <Link to={{pathname: `/${item.isin}`, state: {fund: item}}}>
                        {item.name}
                    </Link>
                );
            case "ongoingCharges":
                return <CartColumnPercentage percentage={item.ongoingCharges} amount={item.amount} />;
            case "managementFee":
                return <CartColumnPercentage percentage={item.managementFee} amount={item.amount} />;
            case "rebate":
                return <CartColumnPercentage percentage={item.rebate} amount={item.amount} />;
            case "minimumInitialInvestment":
                if(props.currentInstrumentIds !== undefined && props.currentInstrumentIds.includes(item.finametrixId))
                {
                    return <>{currencyFormatter.format(item.minimumAdditionalInvestment)}</>;
                }
                else
                {
                    return <>{currencyFormatter.format(item.minimumInitialInvestment)}</>;
                }
            case "noMin":
                return item.noMin ? "Sí" : "No";
            case "cleanShare":
                return item.cleanShare ? "Sí" : "No";
            case "amount":
                return(
                    <L.AmountCell key={"amount-" + index}>
                        {item.error &&
                        (
                            <React.Fragment>
                                <button id={"fs"}  //id={tooltipsRef[index ?? 0].replace(/[-0-9]/g, '')}
                                    onMouseEnter={(ev) => onDismiss(ev, index ?? 0, true)}
                                    className='warningAmount'
                                >
                                    <img
                                        // @ts-ignore
                                        src={`/icons/${theme?.palette.name === 'light' ? 'informationBlack' : 'informationWhite'}.svg`}
                                        alt="Información"
                                    />
                                </button>
                                {tooltipsClassToggle[index ?? 0] &&
                                (
                                    // target={`#${tooltipsRef[index ?? 0].replace(/[-0-9]/g, '')}`}
                                    <TeachingBubble target={"sds"}>
                                        <header style={{textAlign: 'right', width: 'calc(100% + 10px)', marginTop: '-22px'}}>
                                            <button className='ms-Button ms-Button--icon ms-TeachingBubble-closebutton'
                                                onClick={(ev) => onDismiss(ev, index ?? 0, false)}
                                                style={{border: '0', color: 'white', fontSize: '17px', padding: '2px 5px', background: 'none', marginRight: '-10px' }}
                                            >
                                                <span className='ms-Button-flexContainer'>
                                                    <i className='ms-Icon ms-Button-icon' data-icon-name="Cancel"></i>
                                                </span>
                                            </button>
                                        </header>
                                        {item.error}
                                    </TeachingBubble>
                                )}
                            </React.Fragment>
                        )}
                        {(item.readOnly || props.readOnly) &&
                        (
                            <React.Fragment key={"amount-readOnly-" + index}>{currencyFormatter.format(item.amount)}</React.Fragment>
                        )}
                        {!item.readOnly && !props.readOnly &&
                        (
                            <NumberFormat thousandSeparator="."
                                decimalSeparator=","
                                value={item.amount}
                                min={item.noMin ? item.minimumInitialInvestment : 1}
                                onValueChange={(values: any) =>
                                {
                                    const {floatValue} = values;
                                    props.updateCartLine?.(item, floatValue);
                                }}
                                suffix=" €"
                                className={(item.noMin && item.amount < item.minimumInitialInvestment) || item.amount <= 0
                                    ? "ms-NumberField field-fund-investment-invalid"
                                    : "ms-NumberField"
                                }
                            />
                        )}
                    </L.AmountCell>
                );
            case "modelpercentage":
                if(cartModeStatus !== CartModes.VIEW_MODEL_PORTFOLIO && cartModeStatus !== CartModes.CREATE_MODEL_PORTFOLIO && cartModeStatus !== CartModes.PUNCTUAL_CONTRIBUTION)
                {
                    return;
                }
                return(
                    <React.Fragment key={"percentage-" + index}>
                        {(item.readOnly || props.readOnly) &&
                        (
                            <React.Fragment key={"percentage-readOnly-" + index}>{item.modelPercentage}%</React.Fragment>
                        )}
                        {!item.readOnly && !props.readOnly &&
                        (
                            <NumberFormat thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                value={item.modelPercentage}
                                onValueChange={(values: any) =>
                                {
                                    const { floatValue } = values;
                                    props.updateCartModelPercentageLine?.(item, floatValue);
                                }}
                                suffix=" %"
                                disabled={cartModeStatus === CartModes.VIEW_MODEL_PORTFOLIO}
                                className={item.modelPercentage <= 0 || item.modelPercentage > 100 ? "ms-NumberField field-fund-investment-invalid": "ms-NumberField"}
                            />
                        )}
                    </React.Fragment>
                );
            case "actions":
                return <CartColumnActions
                    item={item}
                    toggleAttachmentsVisible={toggleAttachmentsVisible}
                    removeCartLine={props.removeCartLine}
                    cartModeStatus={cartModeStatus}
                />;
            default:
                return item[column.key];
        }
    };

    const renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = (item, index, column) =>
    {
        switch(column?.key)
        {
            case "cleanShare":
                return <b className={L.classNames.number}>Total</b>;
            case "amount":
                return <b>{currencyFormatter.format(props.total)}</b>;
            case "modelpercentage":
                return <b>{decimalFormatter.format(props.modelPercentageTotal!) + " %"}</b>;
            default:
                break;
        }
        return undefined;
    };

    const onRenderDetailsFooter = (props?: IDetailsFooterProps): JSX.Element =>
    {
        return(
            <L.DetailsRowFooter>
                <DetailsRow {...props}
                    columns={props?.columns}
                    item={{}}
                    itemIndex={-1}
                    groupNestingDepth={props?.groupNestingDepth}
                    selectionMode={SelectionMode.none}
                    selection={props?.selection}
                    onRenderItemColumn={renderDetailsFooterItemColumn}
                    onRenderCheck={onRenderCheckForFooterRow}
                />
            </L.DetailsRowFooter>
        );
    };

    return(
        <React.Fragment>
            {selectedRegister &&
            (
                <FundDocumentsCallout
                    fund={selectedRegister}
                    onClose={() => setIsAttachmentsVisible(false)}
                    isVisible={isAttachmentsVisible}
                />
            )}
            <DetailsList items={props.items}
                columns={columns}
                onRenderItemColumn={renderColumn}
                onRenderDetailsFooter={onRenderDetailsFooter}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
            />
        </React.Fragment>
    );
};
import { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from "react-router-dom";
import UserForm from './UserForm';
import { Text, Spinner, SpinnerSize } from "@fluentui/react";
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import DocumentTitle from 'react-document-title';
import Auth from "../../Auth/Auth";
import SubscriptionService, { SubscriptionType } from '../../services/SubscriptionService';
import UsersSubscriptionService, { UsersSubscriptionType } from '../../services/UsersSubscriptionService';
import UserService, { UserType } from '../../services/UserService';
import BackButton from "../buttons/BackButton";
import './Subscriptions.sass';
import { UserIdentityType } from '../../services/UserIdentitiesService';

const Subscription = () =>
{
    const [error, setError] = useState({ message: "" });
    const [isLoaded, setIsLoaded] = useState(false);
    const [subscription, setSubscription] = useState<Partial<SubscriptionType>>({});
    const user: UserType = Auth.getUserProfile();
    const [userIdentity, setUserIdentity] = useState<UserIdentityType>(user.mainUserIdentity);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const { subscriptionId } = useParams<any>();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const history = useHistory();
    const { state } = useLocation<any>();
    let initialContribution: any = state.initialContribution;
    let managedPortfolioId: any = state.managedPortfolioId;
    let bmePortfolioId: any = state.bmePortfolioId;

    useEffect(() =>
    {
        setIsLoaded(false);
        SubscriptionService.getSubscription(subscriptionId)
            .then((data: SubscriptionType) =>
            {
                setIsLoaded(true);
                setSubscription(data);
            },
            (error) =>
            {
                setIsLoaded(true);
                setError(error);
            })
    }, [subscriptionId]);

    const createOrder = (event: any) =>
    {
        event.preventDefault();
        setIsSubmitted(true);

        if (userIdentity.phone !== undefined &&
            userIdentity.countryPrefix !== undefined &&
            userIdentity.phone.startsWith(userIdentity.countryPrefix ?? '')
        ) {
            userIdentity.phone = userIdentity.phone.replace(userIdentity.countryPrefix, '');
        }

        var userData =
        {
            id: user.id,
            mainUserIdentity:
            {
                ...userIdentity,
                id: user.mainUserIdentity?.id,
                signCountryPrefix: userIdentity.countryPrefix,
                signPhone: userIdentity.phone
            }
        };

        if(user.mainUserIdentity?.status === undefined)
        {
            userData.mainUserIdentity.status = "NEW";
        }

        if(user.mainUserIdentity?.operationCountryPrefix === undefined)
        {
            userData.mainUserIdentity.operationCountryPrefix = userIdentity.countryPrefix;
            userData.mainUserIdentity.operationPhone = userIdentity.phone;
        }

        if(user.mainUserIdentity?.signCountryPrefix === undefined) {
            userData.mainUserIdentity.signCountryPrefix = userIdentity.countryPrefix;
            userData.mainUserIdentity.signPhone = userIdentity.phone;
        }

        UserService.updateUser(userData).then(async () =>
        {
            setIsLoaded(true);

            UsersSubscriptionService.createUsersSubscription(
            {
                userId: user.id,
                subscriptionId: parseInt(subscriptionId)
            }).then((usersSubscription: UsersSubscriptionType) =>
            {
                setIsLoaded(true);

                var url = `/payment?subscriptionId=${subscriptionId}&usersSubscriptionId=${usersSubscription.id}`;
                
                if(initialContribution !== undefined)
                {
                    url += `&initialContribution=${initialContribution}`;
                }
                if(managedPortfolioId !== undefined)
                {
                    url += `&managedPortfolioId=${managedPortfolioId}`;
                }
                if(bmePortfolioId !== undefined)
                {
                    url += `&bmePortfolioId=${bmePortfolioId}`;
                }

                history.push(url);
            },
            (error) =>
            {
                setIsLoaded(true);
                setError(error);
            });
        },
        (error) =>
        {
            setIsLoaded(true);
            setError(error);
        });
    };

    function getFrequency(freq: any)
    {
        var result: string = String(freq);
        if(result === "month")
        {
            return "mes";
        }
        else
        {
            return "año";
        }
    }

    if(error.message)
    {
        return <div>Error: {error.message}</div>;
    }
    else
    {
        if(!isLoaded || subscription === undefined)
        {
            return <Spinner size={SpinnerSize.large} />
        }
        else
        {
            return (
                <DocumentTitle title={`IronIA - Suscripción ${subscription.name}`}>
                    <>
                        <div className="ms-Grid-row">
                            <BackButton />
                        </div>
                        <div className="ms-Grid-row strait">
                            <h2>Suscríbete ya a IronIA Fintech</h2>
                            <p className="description">Inserta tus datos personales, realiza el pago y empieza a invertir en IronIA.</p>
                            <Separator />
                        </div>
                        <div className="ms-Grid-row strait">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-lg7">
                                    <div className="shadow medium">
                                        <h3>
                                            <span className="red circled small">1</span> Datos del titular del Plan de Cartera
                                        </h3>
                                        <Text block className="legend">
                                            Los titulares de los planes de cartera pueden ser diferentes a los intervinientes de los contratos,
                                            el titular del plan de cartera es el titular de la suscripción:
                                        </Text>
                                        <UserForm user={user} setUserIdentity={setUserIdentity} setIsButtonDisabled={setIsButtonDisabled} />
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-lg5">
                                    <div className="shadow medium summary">
                                        <h3>Resumen del pedido</h3>
                                        <Text>Plan<span className="right">{subscription.name}</span></Text>
                                        <Separator />
                                        {!!subscription.freePeriods && (
                                            <>
                                                <Text>Prueba gratuita<span className="right">{subscription.freePeriods} {getFrequency(subscription.period)}</span></Text>
                                                <Separator />
                                            </>
                                        )}
                                        {subscription.period === 'month' && (
                                            <Text block className="total">Facturación Mensual<span className="right">{subscription.price}€</span></Text>
                                        )}
                                        {(subscription.period === 'year') &&
                                            <Text block className="total">Facturación Anual<span className="right">{subscription.price}€</span></Text>
                                        }
                                        {!isSubmitted && (
                                            <button className={"button secondary-button " + (isButtonDisabled ? 'disabled' : '')} disabled={isButtonDisabled} onClick={(event) => { createOrder(event) }}>Realizar pago</button>
                                        )}
                                        {isSubmitted && (
                                            <Spinner size={SpinnerSize.large} />
                                        )}
                                    </div>
                                    <span className="legend sublegend">
                                        Los planes de cartera asocian suscripciones y contratos con diferentes intervinientes que según la legislación española pueden ser menores,
                                        empresas, etc. En este paso del proceso de alta hacemos referencia al titular del plan de cartera no a los intervinientes de los contratos
                                        que se especificarán en los siguientes pasos.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                </DocumentTitle>
            );
        }
    }
};

export default Subscription;
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DefaultButton } from "@fluentui/react/lib/Button";
import subscriptionService, { SubscriptionType } from "../../services/SubscriptionService";
import Auth from "../../Auth/Auth";
import { Link } from "react-router-dom";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { ThemeContext } from "@fluentui/react-theme-provider";


export const MainTitle = styled.h2`
	text-align: center;
	display: block !important;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 1em;
	font-size: 25px !important;
	color: ${props => props.theme.palette.black};
`;

export const Description = styled.p`
	margin: 0;
	overflow-y: hidden;
	max-height: 10em;
	padding-top: 1em;
	padding-left: 1em;
	padding-right: 1em;
	font-size: 23px;
	text-align: justify;
`;

export const SelectedDiv = styled.div`
	background-color: ${props => props.theme.palette.greyMorePlansSelected};
	padding-right: 0;
	border-radius: 12px;
	text-overflow: ellipsis;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1em;
    margin: 5px;
`;

export const Button = styled(DefaultButton)``;
const buttonStyle = {
	root: {
		textAlign: "center",
		display: "block",
		border: "none",
		borderRadius: "10px !important",
		width: "auto",
		height: "3.25em",
		marginTop: "25%",
		marginBottom: "2em",
		marginLeft: "auto",
		marginRight: "auto",
		padding: "1em",
		backgroundColor: "#cc214f"
	},
	rootPressed: {
		backgroundColor: "#cc214f",
		color: "#ffffff"
	},
	rootHovered: {
		backgroundColor: "#0f2426"
	},
	textContainer: {
		color: "#ffffff",
		fontFamily: "inherit",
		fontSize: "18px"
	}
};
export const MoreExperiencePlans = () => {
	const limitText = "El límite marca el volumen total de la cartera. Si se supera el límite no se pueden realizar más compras de fondos o traspasos.";
	const theme = useContext(ThemeContext);
	const user = Auth.getUserProfile();
	const prevSubscription = Auth.getExperienceSubscription();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>([]);

	useEffect(() => {
		if (user.id) {
			const p0 = subscriptionService.getSubscriptionsByType("experience");
			const p1 = subscriptionService.getPromotedSubscriptionGetByUserId(user.id);

			Promise.all([p0, p1]).then(values => {
				var index: number = values[0].findIndex((element: SubscriptionType) => element.id?.toString() === prevSubscription);
				if (index !== -1) {
					values[0][index].isSelected = true;
				}

				if (values[1].id && index === -1) {
					values[1].isSelected = true;
				}
				if (!values[1].id) {
					setSubscriptions(values[0]);
				} else {
					setSubscriptions([...values[0], values[1]]);
				}

				setIsLoading(false);
			});
		}
	}, [prevSubscription, user.id]);

	function getPrice(price: any) {
		var result: string = String(price);
		result = result.replace(".", ",");

		return result;
	}
	function getFrequency(freq: any) {
		var result: string = String(freq);
		if (result === "month") {
			return "mes";
		} else {
			return "año";
		}
	}
	function getLimit(limit: any) {
		if (limit === null) {
			return "Sin Límite";
		} else {
			return String(limit) + "€";
		}
	}
	function getName(name: any) {
		if (name === undefined || name === null) {
			return "";
		} else {
			return String(name);
		}
	}
	function getDescription(desc: any) {
		if (desc === undefined || desc === null) {
			return "";
		} else {
			return String(desc);
		}
	}
	function setSubValue(value: any) {
		Auth.setExperienceSubscription(value);
	}

	if (isLoading === true) {
		return <Spinner size={SpinnerSize.large} />;
	} else {
		return (
			<React.Fragment>
				<div style={{ display: "flex", flexWrap: "wrap" }}>
					{subscriptions &&
						subscriptions.map((subscription, index) => (
							<div
								className={` ${subscription.isSelected ? "selected" : ""}`}
								key={subscription.id}
								style={{ display: "flex", flexWrap: "wrap", minWidth: "350px", flex: 1 }}
							>
								<div style={{ paddingTop: "5em", display: "flex", flexWrap: "wrap" }}>
									<SelectedDiv theme={theme} style={{ border: subscription.isSelected ? "3px solid #cc214f" : "none" }}>
										<div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
											<MainTitle theme={theme}>{getName(subscription.name)}</MainTitle>
											<MainTitle theme={theme}>
												{getPrice(subscription.price)}€ / {getFrequency(subscription.period)}
											</MainTitle>
											<Description>{getDescription(subscription.description)}</Description>
											<MainTitle theme={theme}>Límite: {getLimit(subscription.limit)}</MainTitle>
											<Description>{limitText}</Description>
											<Link to="/savingsPlans/iHaveExperience">
												<Button text={subscription.isSelected ? "Mantener Selección" : "Seleccionar"} styles={buttonStyle} onClick={() => setSubValue(subscription.id)} />
											</Link>
										</div>
									</SelectedDiv>
								</div>
							</div>
						))}
				</div>
			</React.Fragment>
		);
	}
};

import axios from "axios";
import { proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);

interface IPeriodicContribution
{
    id?: number,
    period: any,
    amount: number,
    iban: string,
    inespayReference: any,
    subject: string,
    portfolioModelId: string,
    signatureId: number,
    paymentId: any
}
export type PeriodicContribution = IPeriodicContribution;

export interface ITablePeriodicContributions
{
    id: number,
    period: any,
    amount: number,
    iban: string
}
class PeriodicContributionsService
{
    public createPeriodicContribution = async (body: PeriodicContribution): Promise<PeriodicContribution> =>
    {
        let url: string = `${proxyUrl}/PeriodicContributions/${body.portfolioModelId}`;
        const req = await axios.post<any>(url, JSON.stringify(body));
        return req.data;
    }
    public getPeriodicContributions = async (portfolioModelId: number): Promise<ITablePeriodicContributions[]> =>
    {
        let url: string = `${proxyUrl}/PeriodicContributions/${portfolioModelId}`;
        const req = await axios.get(url);
        return req.data;
    }
}

const periodicContributionsService = new PeriodicContributionsService();
export default periodicContributionsService;
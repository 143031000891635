import { DefaultButton, IconButton, IIconProps, Modal, Spinner, SpinnerSize } from '@fluentui/react';
import { useState } from 'react';
import * as L from './layout.styles';

const cancelIcon: IIconProps = { iconName: 'Cancel' };

export const ModalEndSharePortfolio = ({
    isModalOpen,
    hideModal,
    _onSubmit,
    theme
}: {
    isModalOpen: boolean,
    hideModal: () => void,
    _onSubmit: any,
    theme: any
}) => {
    const [sending, setSending] = useState(false);

    const onSubmit = async () => {
        setSending(true);
        _onSubmit();
    }

    return(
        <Modal
            isOpen={isModalOpen}
            onDismiss={hideModal}
            className="share-portfolio"
        >
            <L.StyledModal theme={theme}>
                <IconButton
                    iconProps={cancelIcon}
                    ariaLabel="Cerrar"
                    onClick={hideModal}
                    className="close-button"
                />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <h3>ATENCIÓN VA a DEJAR de COMPARTIR SU CARTERA</h3>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        Dejar de compartir su cartera le hace perder de forma irrevocable todos los derechos sobre las
                        promociones asociadas a compartir cartera.
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 text-right">
                        <DefaultButton
                            className="button tiny-secondary-button"
                            onClick={hideModal}
                        >
                            Cancelar
                        </DefaultButton>
                        <DefaultButton
                            className="button tiny-primary-button"
                            onClick={onSubmit}
                            disabled={sending}
                        >
                            {sending && (<Spinner size={SpinnerSize.small} className="button-spinner" />)}
                            Aceptar
                        </DefaultButton>
                    </div>
                </div>
            </L.StyledModal>
        </Modal>
    );
}
import * as React from "react";
import { Separator } from '@fluentui/react/lib/Separator';
import { useContext } from "react";
import { ThemeContext } from '@fluentui/react-theme-provider';
import { ButtonToggle, DivButtonToggle, Icon } from "./HeaderStyles";

type Props =
{
    className: string,
    identificator:string
    toggleTheme: () => void
}

const Toggle: React.FC<Props> = (props: Props) =>
{
    const theme = useContext(ThemeContext);
    return (
        <DivButtonToggle className={"toggle " + props.className} id={props.identificator}>
            <ButtonToggle title="Cambiar de tema" onClick={() => props.toggleTheme()} theme={theme}
                // @ts-ignore
                href="/">
                <Icon theme={theme}
                    // @ts-ignore
                    className={"ironia-icon daylight " + theme.palette.name} />
                <Separator vertical />
                <Icon theme={theme}
                    // @ts-ignore
                    className={"ironia-icon moonlight " + theme.palette.name} />
            </ButtonToggle>
        </DivButtonToggle>
    );
};

export default Toggle;
import
{
    DefaultButton,
    FontWeights,
    getTheme,
    IButtonStyles,
    ILabelStyles,
    Label,
    mergeStyleSets,
    Modal,
    Stack,
    TextField,
    IconButton,
    IIconProps,
} from "@fluentui/react";
import { PrimaryButton } from "@fluentui/react";
import * as React from "react";

import styled from "styled-components";

export const FileInputAccounts = styled.input`
  font-family: inherit;
  width: 465px;
  height: 100%;
  padding: 10px 5px;
  border: 1px solid #95a0a1;
  border-radius: 5px;
  &::file-selector-button {
    -webkit-appearance: none;
    position: absolute;
    float: right;
    height: 90%;
    width: 133px;
    top: 50%;
    right: 20%;
    transform: translate(65%, -50%);
    border: none;
    background-color: #cc214f;
    border-radius: 5px;
    color: #ffffff;
  }
`;

export const FieldFileContAccounts = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 449px;
  padding-top: 8px;
  label {
    margin: 5px 0;
  }
  input {
    position: relative;
    max-height: 38.8px;
    &::file-selector-button {
      right: 84px;
    }
  }
  span.error {
    top: 100%;
  }
`;

export const LabelInput = styled.label`
  margin-top: 5px;
  display: block;
  font-weight: 500;
  font-size: 14px;
`;

const theme = getTheme();
const RenameBtn = styled.button`
  border: 0px;
  width: 100%;
  height: 50%;
  padding: 0px;
`;
const contentStyles = mergeStyleSets({
    container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        borderRadius: "2em",
        minWidth: "500px !important",
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: "1 1 auto",
            color: "#CC214F",
            display: "flex",
            alignItems: "center",
            fontWeight: FontWeights.semibold,
            padding: "12px 12px 14px 24px",
        },
    ],
    body: {
        textAlign: "center",
        flex: "4 4 auto",
        padding: "0 24px 12px 24px",
        overflowY: "hidden",
        selectors: {
            p: {
                margin: "0",
            },
            "p:first-child": {
                marginTop: 0,
            },
            "p:last-child": {
                marginBottom: 0,
            },
        },
    },
});
const styleLabel: Partial<ILabelStyles> = {
    root: {
        fontSize: "19px !important",
        padding: "0 45px",
        margin: "0px",
        textAlign: "center",
        width: "100%",
    },
};
const styleDefButton1: Partial<IButtonStyles> = {
    root: {
        marginLeft: "0",
        marginTop: "1em",
        cursor: "pointer",
        background: "#CC214F",
        fontFamily: "Barlow",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "22px",
        textAlign: "center",
        color: "#FFFFFF",
        borderRadius: "100px",
        padding: "0.5em 1.5em 0.5em 1.5em",
        borderStyle: "none",
    },
};
const styleDefButton2: Partial<IButtonStyles> = {
    root: {
        marginLeft: "2em",
        marginTop: "1em",
        cursor: "pointer",
        background: "#CC214F",
        fontFamily: "Barlow",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "22px",
        textAlign: "center",
        color: "#FFFFFF",
        borderRadius: "100px",
        padding: "0.5em 1.5em 0.5em 1.5em",
        borderStyle: "none",
    },
};

type Props = {
    title?: string;
    body?: string | JSX.Element | JSX.Element[];
    btnSubmitText?: string;
    btnSubmitDisabled?: boolean;
    sendPetition: any;
    onSubmit?: any;
    onClose?: any;
    size?: "sm" | "lg" | "xl";
    showModal: boolean;
    showValidateModal: (b: boolean) => any;
    validateInputs: (iban: string) => boolean;
    setSelectedFile: (b: any) => any;
    selectedFile: any;
    correctResponse: boolean;
};

export const ModalWithInputs: React.FunctionComponent<Props> = (
    props: Props
) =>
{
    const {
        body,
        title,
        showModal,
        showValidateModal,
        validateInputs,
        setSelectedFile,
        selectedFile,
        correctResponse,
    } = props;
    const [show, setShow] = React.useState(false);
    const [bankAccountFirst, setBankAccountFirst] = React.useState("");
    const [bankAccountSecond, setBankAccountSecond] = React.useState("");
    const [error, setError] = React.useState("");
    const patternIBAN =
        "(([E+S]|[e+s]){2})\t*(\\d{2})\\s*\t*(\\d{4})\\s*\t*(\\d{4})\\s*\t*(\\d{2})\\s*\t*(\\d{10})";
    const cancelIcon: IIconProps = { iconName: "Cancel" };
    const handleClose = () =>
    {
        showValidateModal(false);
        if (props.onClose !== undefined)
        {
            props.onClose();
        }

        resetForm();
    };

    const resetForm = () =>
    {
        setBankAccountFirst("");
        setBankAccountSecond("");
        setSelectedFile(undefined);
        setError("");
    };

    React.useEffect(() =>
    {
        setShow(showModal);
    }, [showModal]);

    React.useEffect(() =>
    {
        if (correctResponse == true)
        {
            handleClose();
        }
    }, [correctResponse]);

    const onSubmit = async () =>
    {
        setError("");

        var isValid = validateInputs(bankAccountFirst);
        let newReg = new RegExp(patternIBAN);

        if (selectedFile == undefined)
        {
            return setError("Debes adjuntar el archivo de titularidad");
        } else if (
            bankAccountFirst === "" ||
            bankAccountFirst === undefined ||
            bankAccountSecond === "" ||
            bankAccountSecond === undefined
        )
        {
            return setError("Los campos deben estar rellenados para poder enviarse");
        } else if (bankAccountFirst != bankAccountSecond)
        {
            return setError(
                "Debes introducir el mismo número de cuenta en los dos campos"
            );
        } else if (
            !bankAccountFirst.match(newReg) ||
            !bankAccountFirst.match(newReg)
        )
        {
            return setError("El IBAN no tiene un formato válido. Un ejemplo es: ES1234567891234567891234. El IBAN no puede contener ni espacios, ni guiones, ni carácteres especiales");
        } else if (!isValid)
        {
            return setError(
                "No puedes introducir una cuenta que ya se encuentre en alguna de las tablas"
            );
        } else
        {
            if (props.sendPetition !== undefined)
            {
                setError("");
                props.sendPetition(bankAccountFirst);
            }
        }
    };

    const handleChangeFirstAccount = (e: any) =>
    {
        setBankAccountFirst(e.target.value);

        if (e.target.value !== "" && e.target.value !== undefined)
        {
            setError("");
        }
    };

    const handleChangeSecondAccount = (e: any) =>
    {
        setBankAccountSecond(e.target.value);

        if (e.target.value !== "" && e.target.value !== undefined)
        {
            setError("");
        }
    };

    const fileUploadOnChangeHandler = (event: any) =>
    {
        setError("");

        if (checkValidFile(event.target.files[0]))
        {
            setSelectedFile(event.target.files[0]);
        }
    };

    const checkValidFile = (file: any) =>
    {
        if (file && file!["type"] !== "application/pdf")
        {
            setError("El documento adjuntado debe tener la extensión .pdf");
            return false;
        }
        if (file)
        {
            return true;
        }
    };

    return (
        <React.Fragment>
            <Modal isOpen={show} onDismiss={handleClose}>
                <div className={contentStyles.header}>
                    <Label styles={styleLabel}>{title}</Label>
                    <IconButton
                        iconProps={cancelIcon}
                        ariaLabel="Cerrar modal"
                        onClick={handleClose}
                    />
                </div>
                <div className={contentStyles.body}>
                    {body}
                    <TextField
                        value={bankAccountFirst}
                        required
                        label={"Introduzca el IBAN de su cuenta bancaria"}
                        placeholder="Cuenta Bancaria"
                        onChange={handleChangeFirstAccount}
                    />
                    <TextField
                        value={bankAccountSecond}
                        required
                        label={"Repita el IBAN de su cuenta bancaria"}
                        placeholder="Cuenta Bancaria"
                        onChange={handleChangeSecondAccount}
                    />
                    <LabelInput>Justificante titularidad de la cuenta *</LabelInput>
                    <FieldFileContAccounts>
                        <FileInputAccounts
                            id="proofOfIncome"
                            name="proofOfIncome"
                            type="file"
                            required
                            onChange={fileUploadOnChangeHandler}
                        />
                    </FieldFileContAccounts>
                    {(error !== undefined || error !== "") && (
                        <Label style={{ color: "red" }}>{error}</Label>
                    )}
                </div>
                <div></div>
                <Stack>
                    <Stack.Item style={{ display: "flex", justifyContent: "center" }}>
                        <DefaultButton
                            className="button mini-primary-button"
                            style={{ marginTop: "0px", width: "100px" }}
                            onClick={handleClose}
                        >
                            Cancelar
                        </DefaultButton>
                        <DefaultButton
                            className="button mini-primary-button"
                            style={{ marginTop: "0px", width: "100px", marginLeft: "10px" }}
                            onClick={onSubmit}
                        >
                            Confirmar
                        </DefaultButton>
                    </Stack.Item>
                </Stack>
            </Modal>
        </React.Fragment>
    );
};

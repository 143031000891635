import * as React from "react";
import {useState} from "react";
import fundService from "../../../services/FundService";
import {ISearchAutofillProps, SearchAutofill} from "./SearchAutofill";

interface InvestmentFundFinderProps {
    labelISIN?: string;
    labelName?: string;
    name?: string;
    required?: boolean;
    errorMessage?: string;
    errorMessageIsin?: string;
    errorMessageName?: string;
    placeholderISIN?: string;
    placeholderName?: string;
    switchableRequired?: boolean;
    onChange?: (event: any, name?: string, value?: any, required?: boolean) => any;
    onValidate?: (inputStatus: any) => any;
    onFilterChange?: (inputName: string, value: any) => any;
}

interface InvestmentFundFinderDefaultProps extends InvestmentFundFinderProps {
    default?: object;
    loadData?: (filter: any) => Promise<any[]>;
}

export const InvestmentFundFinder = React.forwardRef((
    props: InvestmentFundFinderDefaultProps, ref: any
) => {
    const defaultItem = props.default;

    const loadData = props.loadData || ((filter: any) => {
        let classCategory = undefined;
        if (filter.length === 12 && /^[A-Za-z0-9]*$/.test(filter) && filter.match(/[A-Za-z]+/) !== null && filter.match(/[0-9]+/) !== null) {
            classCategory = false;
        }
        return fundService.getFunds(0, 5, filter, undefined, undefined, undefined, props.switchableRequired, undefined, undefined, undefined, classCategory)
        .then(data => data.results?.slice(0, 20))
        .catch(err => []);
    });

    const [selectedItem, setSelectedItem] = useState<any>({data: defaultItem});

    const onFundChange: ISearchAutofillProps["onChange"] = (event: any, item: any) => {
        setSelectedItem(item);
        if (props.onChange) {
            props.onChange(event, props.name, item?.data, props.required);
        }
    };

    const onFilterChange = (event: any, name: string, filter: string) => {
        if (props.onFilterChange) {
            props.onFilterChange(name, filter);
        }
    };

    return (
        <div className="investment-fund-finder">
            <SearchAutofill
                {
                ...({ ...props, errorMessage: props.errorMessage ?? props.errorMessageIsin } as InvestmentFundFinderProps)
                }
                label={props.labelISIN}
                placeholder={props.placeholderISIN}
                selectedItem={selectedItem}
                getKey={(item: any) => item?.isin}
                getDisplayValue={(item: any) => item?.isin && `${item?.isin}`}
                getSuggestionDisplayValue={(item: any) => item?.isin && item?.name && `${item?.isin} | ${item?.name}`}
                loadData={loadData}
                onChange={onFundChange}
                onFilterChange={(event: any, filter: string) => onFilterChange(event, 'isin', filter)}
            />

            <SearchAutofill
                {
                ...({ ...props, errorMessage: props.errorMessage ?? props.errorMessageName } as InvestmentFundFinderProps)
                }
                itemRef={ref}
                label={props.labelName}
                placeholder={props.placeholderName}
                selectedItem={selectedItem}
                getKey={(item: any) => item?.name}
                getDisplayValue={(item: any) => item?.name}
                loadData={loadData}
                onChange={onFundChange}
                onFilterChange={(event: any, filter: string) => onFilterChange(event, 'name', filter)}
            />
        </div>
    );
});

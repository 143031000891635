export const toFixedDecimals = (value, numberOfDecimals) =>
{
    if(value === undefined || value === null || isNaN(value) || countDecimals(value) <= numberOfDecimals)
    {
        return value;
    }

    return value.toFixed(numberOfDecimals);
};

export const countDecimals = function (value)
{
    if(value === null || !isNaN(value))
    {
        return 0;
    }

    let text = value.toString();
    // verify if number 0.000005 is represented as "5e-6"
    if(text.indexOf("e-") > -1)
    {
        let trail = text.split("e-")[1];
        let deg = parseInt(trail, 10);
        return deg;
    }
    // count decimals for number in representation like "0.123456"
    if(value % 1 !== 0)
    {
        return value.toString().split(".")[1].length || 0;
    }
    return 0;
};

export const parseNumber = (value, numberDecimals = 2) =>
{
    if(isNaN(value) && (value.indexOf(",") !== -1))
    {
        value = value.replace(",", ".");
        return value = parseFloat(value).toFixed(numberDecimals);
    }
    else if(isNaN(value) && !isNaN(parseFloat(value)))
    {
        return value = parseFloat(value).toFixed(numberDecimals);
    }
    else
    {
        return value;
    }
};
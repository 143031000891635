import * as S from '../ConvenienceTest.style';
import Moment from 'moment';
import { DayPickerStrings } from '../Utils/DatesTranlateFluents';
import Select from 'react-dropdown-select';
import { useEffect, useState } from 'react';
import { useConst } from '@fluentui/react-hooks';
import { Attachment } from '../../Attachment';
import { Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { InputStyles, DatePickerStyle } from '../../GlobalStyle';
import UserIdentitiesService from '../../../services/UserIdentitiesService';
import React from 'react';
import ModalAddExistingAccountHolder from '../ModalAddExistingAccountHolder';

const options = [
    {
        key: 'DNI',
        text: 'DNI',
        name: "DNI"
    },
    {
        key: 'NIE',
        text: 'NIE',
        name: "NIE"
    },
    {
        key: 'PASSPORT',
        text: 'Pasaporte',
        name: "Pasaporte"
    },
    {
        key: 'OTHER',
        text: 'Otro',
        name: "Otro"
    },
];

const ConvenienceTestIDInputs = ({ // @ts-ignore
    handleChange, data, formVal, setFormVal, countries, finametrixId, id, validating, setValidating
}) => {
    const
        {
            nationalCountry,
            birthdayCountry,
            idType,
            idDocument,
            idExpiration,
            idFront,
            idBack
        } = data;

    const [errors, setErrors] = useState(
        {
            nationalCountry: "",
            birthdayCountry: "",
            idType: "",
            idDocument: "",
            idExpiration: "",
            idFront: "",
            idBack: ""
        });

    const [showExistingAccountModal, setShowExistingAccountModal] = useState<boolean>(false);
    const [existingAccountDni, setExistingAccountDni] = useState<string>("");
    const today = useConst(new Date(Date.now()));

    const validateIdDocument = (event: any, propagation: boolean) => {
        var { name, value } = event.target;

        var error: any;
        if (value && value.trim().length < 9) {
            error = <S.Error>El campo Número de documento debe tener al menos 9 caracteres</S.Error>;
            setErrors((prevState: any) => {
                return {
                    ...prevState,
                    [name]: error
                }
            });
            setFormVal(name, error === "");
            if (propagation) handleChange(event);
        }
        else {
            if (propagation) handleChange(event);
            setValidating(true);
            UserIdentitiesService.validateUniqueDocument(finametrixId, value.trim(), id).then((unique: boolean) => {
                setValidating(false);
                error = !unique ? (
                    <>
                        <S.Error>
                            El documento ya está asignado a otro titular.{" "}
                            {/* <span
								style={{ color: "#4284e6", cursor: "pointer" }}
								onClick={() => {
									setExistingAccountDni(value.trim());
									setShowExistingAccountModal(true);
								}}
							>
								Pulse aquí para solicitar añadirlo
							</span> */}
                        </S.Error>
                    </>
                ) : (
                    ""
                );
                setErrors((prevState: any) => {
                    return {
                        ...prevState,
                        [name]: error
                    };
                });
                setFormVal(name, error === "");
            },
                (error: any) => {
                    setValidating(false);
                    error = <S.Error>No hemos podido validar el documento</S.Error>;
                    setErrors((prevState: any) => {
                        return {
                            ...prevState,
                            [name]: error
                        }
                    });
                    setFormVal(name, error === "");
                });
        }
    }

    const handleInputChange = (event: any) => {
        if (event.target !== undefined) {
            var { name, value } = event.target;
            [name,] = name.split("-");
            var error: any;
            if (name === 'idDocument') {
                if (event.target.value !== undefined) {
                    event.target.value = event.target.value.toUpperCase();
                }
                validateIdDocument(event, true);
            }
            else {
                error = getErrorMessage(name, value);
                setErrors((prevState: any) => {
                    return {
                        ...prevState,
                        [name]: error
                    }
                });
                setFormVal(name, error === "");
                handleChange(event);
            }
        }
    };

    const getErrorMessage = (field: string, value: any) => {
        switch (field) {
            case 'nationalCountry':
                return (value && !value.trim()) ? <S.Error>El campo Nacionalidad es obligatorio</S.Error> : "";

            case 'birthdayCountry':
                return (value && !value.trim()) ? <S.Error>El campo País de nacimiento es obligatorio</S.Error> : "";

            case 'idType':
                return (value && !value.trim()) ? <S.Error>El campo Tipo documento es obligatorio</S.Error> : "";

            case 'idExpiration':
                if (value === null || value === undefined) {
                    return <S.Error>El campo Validez es obligatorio</S.Error>;
                }
                var currentDate = Moment();
                var idExpirationDate = Moment(value);
                if (idExpirationDate.diff(currentDate, 'days') < 0) {
                    return <S.Error>Debe tener validez a día de hoy</S.Error>;
                }
                return '';

            case 'idFront':
                return (value.size === 0) ? <S.Error>El campo DNI / NIE parte delantera es obligatorio</S.Error> : "";

            case 'idBack':
                return (value.size === 0) ? <S.Error>El campo DNI / NIE parte trasera es obligatorio</S.Error> : "";

            default:
                throw new Error('Error en el switch');
        }
    };

    useEffect(() => {
        Object.keys(data).forEach((key) => {
            if (key in formVal) {
                if (data[key] === null || data[key] === undefined || data[key] === "") {
                    setFormVal(key, false);
                }
                else {
                    var error: any;
                    if (key === 'idDocument') {
                        validateIdDocument({ target: { name: key, value: data[key] } }, false);
                    }
                    else {
                        error = getErrorMessage(key, data[key]);
                        setErrors((prevState: any) => {
                            return {
                                ...prevState,
                                [key]: error
                            }
                        });
                        setFormVal(key, error === "");
                    }
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (idExpiration === null) {
            setErrors((prevState: any) => {
                return {
                    ...prevState,
                    idExpiration: ""
                }
            });
            setFormVal('idExpiration', true);
            var tomorrow = Moment().add(1, 'days').toDate();
            handleChange({ target: { name: 'idExpiration', value: Moment(tomorrow).subtract(tomorrow?.getTimezoneOffset(), "minutes").toDate(), files: null } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idExpiration]);

    const handleDateInputChange = async (name: string, value: any) => {
        if (value !== undefined) {
            var error = getErrorMessage(name, value);
            setErrors((prevState: any) => {
                return {
                    ...prevState,
                    [name]: error
                }
            });
            setFormVal(name, error === "");
            handleChange({ target: { name: name, value: value, files: null } });
        }
    };

    return (
        <React.Fragment>
            <S.FieldSet>
                <S.DropDownRBCont>
                    <S.DropDownLabel>Nacionalidad *</S.DropDownLabel>
                    <Select
                        name="nationalCountry"
                        labelField="text"
                        options={countries}
                        onChange={(event: any) => {
                            handleInputChange({
                                target: {
                                    name: "nationalCountry",
                                    value: event.length > 0 ? event[0].value : ""
                                }
                            });
                        }}
                        // @ts-ignore
                        value={nationalCountry}
                        dropdownHeight="2000"
                        searchable={true}
                        searchBy="key"
                        noDataLabel={"No encontrado"}
                        backspaceDelete={false}
                        valueField={"value"}
                        placeholder=""
                        values={
                            nationalCountry !== "" && countries.find((countryAux: any) => countryAux.value === nationalCountry)
                                ? [countries.find((countryAux: any) => countryAux.value === nationalCountry)]
                                : []
                        }
                    />
                    {errors.nationalCountry !== "" && errors.nationalCountry}
                </S.DropDownRBCont>
                <S.DropDownRBCont>
                    <S.DropDownLabel>País de nacimiento *</S.DropDownLabel>
                    <Select
                        name="birthdayCountry"
                        labelField="text"
                        options={countries}
                        onChange={(event: any) => {
                            handleInputChange({
                                target: {
                                    name: "birthdayCountry",
                                    value: event.length > 0 ? event[0].value : ""
                                }
                            });
                        }}
                        // @ts-ignore
                        value={birthdayCountry}
                        placeholder=""
                        dropdownHeight="2000"
                        searchable={true}
                        searchBy="key"
                        noDataLabel={"No encontrado"}
                        backspaceDelete={false}
                        valueField={"value"}
                        values={
                            birthdayCountry !== "" && countries.find((countryAux: any) => countryAux.value === birthdayCountry)
                                ? [countries.find((countryAux: any) => countryAux.value === birthdayCountry)]
                                : []
                        }
                    />
                    {errors.birthdayCountry !== "" && errors.birthdayCountry}
                </S.DropDownRBCont>
                <S.DropDownRBCont>
                    <S.DropDownLabel>Tipo documento *</S.DropDownLabel>
                    <Select
                        name="idType"
                        labelField="name"
                        options={options}
                        onChange={(event: any) => handleInputChange({ target: { name: "idType", value: event[0].key } })}
                        // @ts-ignore
                        value={idType}
                        placeholder=""
                        dropdownHeight="2000"
                        searchable={true}
                        noDataLabel={"No encontrado"}
                        backspaceDelete={false}
                        valueField={"key"}
                        values={options.filter(option => option.key === idType)}
                    />
                    {errors.idType !== "" && errors.idType}
                </S.DropDownRBCont>
            </S.FieldSet>
            <S.FieldSet>
                <S.FieldRBCont>
                    <TextField
                        label="Numero de Documento *"
                        // @ts-ignore
                        styles={InputStyles}
                        id="idDocument"
                        name="idDocument"
                        type="text"
                        onChange={handleInputChange}
                        value={idDocument || ""}
                    />
                    <div className={validating ? "validating" : "hidden"}>
                        <Spinner size={SpinnerSize.small} />
                    </div>
                    <div style={{ position: "relative", width: "100%", top: "-15px" }}>{errors.idDocument !== "" && errors.idDocument}</div>
                </S.FieldRBCont>
                <S.FieldRBCont>
                    <S.DateInput
                        styles={DatePickerStyle}
                        id="idExpiration"
                        label="Validez"
                        // @ts-ignore
                        name="idExpiration"
                        minDate={today}
                        formatDate={(date: any) => {
                            return Moment(date).format("DD/MM/YYYY");
                        }}
                        onSelectDate={date => handleDateInputChange("idExpiration", Moment(date).subtract(date?.getTimezoneOffset(), "minutes").toDate())}
                        // @ts-ignore
                        value={new Date(idExpiration)}
                        isRequired
                        strings={DayPickerStrings}
                        allowTextInput={true}
                        parseDateFromString={dateStr => {
                            var parts: string[] = dateStr.split("/");
                            var myDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
                            return new Date(myDate);
                        }}
                    />
                    <div style={{ position: "relative", width: "100%", top: "-15px" }}>{errors.idExpiration !== "" && errors.idExpiration}</div>
                </S.FieldRBCont>
            </S.FieldSet>
            <S.FieldSet>
                <S.FieldRBCont>
                    <S.Label>DNI / NIE parte delantera *</S.Label>
                    <S.FieldFileCont>
                        <S.FileInput id="idFront" name="idFront" type="file" onChange={handleInputChange} />
                    </S.FieldFileCont>
                    {idFront !== undefined && typeof idFront === "string" && (
                        <div>
                            <Attachment fileName={idFront} className="image-preview" />
                        </div>
                    )}
                    {errors.idFront !== "" && errors.idFront}
                </S.FieldRBCont>
                <S.FieldRBCont>
                    <S.Label>DNI / NIE parte trasera *</S.Label>
                    <S.FieldFileCont>
                        <S.FileInput id="idBack" name="idBack" type="file" onChange={handleInputChange} />
                    </S.FieldFileCont>
                    {idBack !== undefined && typeof idBack === "string" && (
                        <div>
                            <Attachment fileName={idBack} className="image-preview" />
                        </div>
                    )}
                    {errors.idBack !== "" && errors.idBack}
                </S.FieldRBCont>
            </S.FieldSet>

            {/* Existing Account Holder Modal*/}
            {showExistingAccountModal && (<ModalAddExistingAccountHolder
                showModal={showExistingAccountModal}
                existingUserDni={existingAccountDni}
                handleSubmit={(dni, signatureSuccess) => {
                    setShowExistingAccountModal(false);
                    if (signatureSuccess) {
                        //Remove Duplicate Data
                        let userIdentityId = id;
                    }
                }}
            ></ModalAddExistingAccountHolder>)}
        </React.Fragment>
    );
};

export default ConvenienceTestIDInputs;
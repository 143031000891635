import { CommandBar, ICommandBarItemProps } from "office-ui-fabric-react/lib/CommandBar";
import { IButtonProps } from "@fluentui/react";
import { useHistory } from "react-router";

const _overflowItems: ICommandBarItemProps[] = [];
const overflowProps: IButtonProps = {ariaLabel: "Más acciones"};

const SearchMenu = () =>
{
    const history = useHistory();
    const _items = [
        {
            text: "Buscador de fondos",
            key: "buscadorFondos",
            onClick: () => history.push("/search"),
            className: window.location.pathname === '/search' ? 'is-selected' : ''
        },
        {
            text: "Buscador de categorías",
            key: "buscadorCategorias",
            onClick: () => history.push("/search/category"),
            className: window.location.pathname === '/search/category' ? 'is-selected' : ''
        },
        {
            text: "Ranking de carteras",
            key: "rankingCarteras",
            onClick: () => history.push("/search/ranking"),
            className: window.location.pathname === '/search/ranking' ? 'is-selected' : ''
        },
        {
            text: "Tendencias",
            key: "TendsSearch",
            onClick: () => history.push("/search/tends"),
            className: window.location.pathname === '/search/tends' ? 'is-selected' : ''
        },
        {
            text: "Artículos",
            key: "NewsSearch",
            onClick: () => history.push("/search/news"),
            className: window.location.pathname === '/search/news' ? 'is-selected' : ''
        },
        {
            text: "Formación",
            key: "trainingCourses",
            onClick: () => history.push("/search/trainingCourses"),
            className: window.location.pathname === '/search/trainingCourses' ? 'is-selected' : ''
        },
        {
            text: "IronIA Explica",
            key: "ironiaExplica",
            onClick: () => history.push("/search/IronIAExplica"),
            className: window.location.pathname === '/search/IronIAExplica' ? 'is-selected' : ''
        }
    ];

    return(
        <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" id="main-menu">
                <CommandBar
                    // @ts-ignore
                    items={_items}
                    overflowItems={_overflowItems}
                    overflowButtonProps={overflowProps}
                    ariaLabel="Usa las flechas izquierda y derecha para navegar entre las entradas del menú"
                />
            </div>
        </div>
    );
}

export default SearchMenu;
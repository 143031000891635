import { DefaultButton, Separator, Spinner, SpinnerSize } from '@fluentui/react';
import { useContext, useEffect, useState } from "react";
import Auth from "../../../Auth/Auth";
import FinametrixService from '../../../services/FinametrixService';
import { ThemeContext } from "@fluentui/react-theme-provider";
import PortfolioOrdersTable from './PortfolioOrdersTable';
import DocumentTitle from 'react-document-title';
import { ActionsContainer, CounterContainer, Header, SearchContainer, TableWrapper } from './PortfolioOrdersStyle';
import { PaginationTable } from '../../../components/Pagination';
import { OrderStatuses, OrderTypes } from '../../../services/OrderService';
import moment from "moment";
import { ExportIndex } from '../../../components/ExportIndex';
import { parseNumber } from '../../../utils/toFixedDecimals';

const initialItems = 10;
const itemsPerPage = 10;

const exportHeaders =
[
    'Fecha',
    'Estado',
    'Detalle del Estado',
    'Tipo de orden',
    'Importe',
    'Fondo origen',
    'Fondo destino'
];

const PortfolioOrders = () =>
{
    const [error, setError] = useState({ message: "", status: 0 });
    const [ordersRaw, setOrdersRaw] = useState([]);
    const [orders, setOrders] = useState<any[]>([]);
    const [filteredOrders, setFilteredOrders] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const theme = useContext(ThemeContext);
    let activePlan = Auth.getActivePlan();

    useEffect(() =>
    {
        setIsLoading(true);
        FinametrixService.getOrders(activePlan.finametrixContract, initialItems)
            .then((data: any) =>
            {
                setOrdersRaw(data);
                setIsLoading(false);
            },
            (error) =>
            {
                setError(error);
                setIsLoading(false);
            });
    }, [activePlan.finametrixContract]);

    const handleClick = () =>
    {
        setShowAll(true);
        setCurrentPage(0);
        setIsLoading(true);
        FinametrixService.getOrders(activePlan.finametrixContract).then((data: any) =>
        {
            setOrdersRaw(data);
            setIsLoading(false);
        },
        (error) =>
        {
            setError(error);
            setIsLoading(false);
        });
    }

    useEffect(() =>
    {
        if(ordersRaw.length > 0)
        {
            const ordersAux = ordersRaw.map((d: any) =>
            (
                {
                    finametrixId: d.finametrixId,
                    date: moment(d.date).format("DD/MM/YYYY"),
                    status:
                    {
                        name: OrderStatuses.get(d.status),
                        message: d.statusMessage
                    },
                    type: d.type,
                    typeText: OrderTypes.get(d.type),
                    amount: d.amount.replace(',', '.'),
                    fund:
                    {
                        "nameOrig": d.funds[0].name,
                        "isinOrig": d.funds[0].isin,
                        "instrumentIdOrig": d.funds[0].instrumentId,
                        "typeOrig": d.funds[0].type,
                        "nameDesty": typeof d.funds[1] !== "undefined" ? d.funds[1].name : "",
                        "isinDesty": typeof d.funds[1] !== "undefined" ? d.funds[1].isin : "",
                        "instrumentIdDesty": typeof d.funds[1] !== "undefined" ? d.funds[1].instrumentId : "",
                        "typeDesty": typeof d.funds[1] !== "undefined" ? d.funds[1].type : ""
                    },
                    iban: d.iban,
                    incidence: d.incidence
                }
            ));
            setOrders(ordersAux);
        }
    }, [ordersRaw]);

    useEffect(() =>
    {
        setFilteredOrders(orders);
    }, [orders]);

    const filter = (value: string) =>
    {
        if(value !== undefined)
        {
            setCurrentPage(0);
            var result: any[] = [];
            for(var item in orders)
            {
                const text = [
                    orders[item].date,
                    orders[item].status.name,
                    orders[item].status.message,
                    orders[item].typeText,
                    orders[item].fund.nameOrig,
                    orders[item].fund.isinOrig,
                    orders[item].fund.nameDesty,
                    orders[item].fund.isinDesty
                ].join(" ");

                if(text.toUpperCase().indexOf(value.toUpperCase()) !== -1)
                {
                    result.push(orders[item]);
                }
            }
            setFilteredOrders(result);
        }
    }

    const getDataToExport = () =>
    {
        return filteredOrders.map(order => (
        [
            order.date,
            order.status.name,
            order.status.message,
            order.typeText,
            order.amount.includes("participaciones")
                ? order.amount
                : (isNaN(parseNumber(order.amount, 2)) ? order.amount : parseNumber(order.amount, 2)),
            order.fund.isinOrig !== '' ? `${order.fund.isinOrig} - ${order.fund.nameOrig}` : '',
            order.fund.isinDesty !== '' ? `${order.fund.isinDesty} - ${order.fund.nameDesty}` : ''
        ]));
    };

    if(error.status)
    {
        return(
            <DocumentTitle title="IronIA - Resumen de cartera">
                <div>Error: {error.message}</div>
            </DocumentTitle>
        );
    }
    else
    {
        if(isLoading || orders === undefined)
        {
            return <Spinner size={SpinnerSize.large} />
        }
        else
        {
            return(
                <DocumentTitle title='IronIA - Órdenes en curso'>
                    <>
                        <div>
                            <h1>Histórico de operaciones</h1>
                            <Separator />
                        </div>
                        {orders.length > 0 &&
                        (
                            <TableWrapper theme={theme}>
                                <Header className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <CounterContainer theme={theme}>
                                            <h3>Órdenes en curso</h3>
                                            <span>({orders.length})</span>
                                        </CounterContainer>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 flex10">
                                        {!showAll &&
                                        (
                                            <DefaultButton
                                                className="button mini-primary-button"
                                                onClick={handleClick}
                                            >Ver todo</DefaultButton>
                                        )}
                                        <SearchContainer theme={theme}>
                                            <img src="/icons/search.svg" alt="Search Icon" />
                                            <input placeholder="Buscar..." onChange={val => filter(val.target.value)} />
                                        </SearchContainer>
                                        <ActionsContainer theme={theme} className="ms-hiddenLgDown">
                                            <ExportIndex
                                                headers={exportHeaders}
                                                getDataToExport={getDataToExport}
                                                name={activePlan.name}
                                            />
                                        </ActionsContainer>
                                    </div>
                                </Header>
                                <PortfolioOrdersTable data={filteredOrders.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)}
                                    columns=
                                    {[
                                        {
                                            id: "Fecha",
                                            title: "Fecha",
                                            source: "date",
                                        },
                                        {
                                            id: "Estado",
                                            title: "Estado",
                                            source: "estado",
                                        },
                                        {
                                            id: "EstadoDetalle",
                                            title: "Detalle del Estado",
                                            source: "estado",
                                        },
                                        {
                                            id: "Tipo de orden",
                                            title: "Tipo de orden",
                                            source: "typeText",
                                        },
                                        {
                                            id: "Importe",
                                            title: "Importe",
                                            source: "amount",
                                        },
                                        {
                                            id: "Fondo",
                                            title: "Fondo",
                                            source: "fondo",
                                        },
                                        {
                                            id: "Acciones",
                                            title: "Acciones",
                                            source: "incidence",
                                        }
                                    ]}
                                />
                            </TableWrapper>
                        )}
                        <div className="full-width text-center">
                            {showAll &&
                            (
                                <PaginationTable onChangePage={(newPage: number) => {setCurrentPage(newPage)}}
                                    totalPages={filteredOrders.length / itemsPerPage}
                                    currentPage={currentPage}
                                />
                            )}
                        </div>
                    </>
                </DocumentTitle>
            );
        }
    }
}

export default PortfolioOrders;
import * as S from '../ManagementAuthorization/ManagementAuthorization.style';
import { useEffect, useState } from "react";
import { Icon } from '@fluentui/react/lib/Icon';
import { useHistory } from "react-router-dom";
import { DefaultButton } from '@fluentui/react';
import { closeBtnStyle } from '../GlobalStyle';
import React from 'react';

const ModalComplete = ({
    setModalShow,
    isPromotioned,
    isin,
    returnPath
}: {
    setModalShow: any,
    isPromotioned?: boolean | undefined,
    isin: string,
    returnPath: string
}) => {
    const history = useHistory();
    const [height, setHeight] = useState('');

    useEffect(() =>
    {
        const toheight = document?.querySelector('#root')?.scrollHeight + 'px';
        setHeight(toheight);
    }, []);

    return(
        <React.Fragment>
            <S.HelperStyle />
            <S.ModalMainCont>
                <S.ToExitCont>
                    <S.ToExit>
                        <Icon iconName='ChromeClose'
                            // @ts-ignore
                            styles={closeBtnStyle}
                            onClick={() => setModalShow(false)}
                        />
                    </S.ToExit>
                </S.ToExitCont>
                <S.CompletedIcon src="/icons/greenCheck.png" />
                <S.Continue>¡Confirmación de información recibida completada!</S.Continue>
                <hr />
                <S.NeedsCont>
                    <p>
                        Enhorabuena, ya puede invertir en el fondo {isin}.
                    </p>
                </S.NeedsCont>
                <S.BtnCont>
                    <DefaultButton onClick={() => history.push(returnPath)} className="button tiny-primary-button">
                        Continuar con la inversión
                    </DefaultButton>
                </S.BtnCont>
            </S.ModalMainCont>
            <S.Focus className='modal'
                // @ts-ignore
                height={height} />
        </React.Fragment>
    );
};

export default ModalComplete;
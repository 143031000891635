import { Checkbox, TextField } from '@fluentui/react';
import styled from 'styled-components';

export const DropdownContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2000;
`;
export const DropdownHeader = styled.div`
    position: relative;

    span, i
    {
        position: absolute;
    }
    
    i
    {
        position: absolute;
        color: white;
        background: #CC214F;
        border-radius: 30px;
        width: 32px;
        height: 32px;
        text-align: center;
        top: 5px;
        right: 25px;
        
        &:after
        {
            line-height: 32px;
            font-size: 20px;
        }
    }

    span
    {
        color: lightgrey;
        height: 18px;
        top: 13px;
        right: 35px;
    }
`;
export const DropdownInputText = styled(TextField)`
    border: 0;
    cursor: pointer;
    color: rgb(85,103,105);
    background: white;
    width: 95%;

    * div
    {
        border: 0;
        height: 42px !important;
    }

    * input
    {
        background: transparent !important;
        border: 1px solid #BCC8C9;
        border-radius: 5rem;
        padding: 20px 40px 20px 20px;
        caret-color: #CC214F;
        color: #666;

        &::placeholder
        {
            color: #888
        }

        &:focus
        {
            box-shadow: inset 0px 0 0px 1px #CC214F;
            border: 1px solid #CC214F;
        }
    }
`;
export const Menu = styled.div`
    box-shadow: rgba(8, 84, 117, 0.12) 0px 2px 64px, rgba(8, 84, 117, 0.12) 0px 1px 20px;
    position: relative;
    border-radius: 16px;
    width: 95%;
`;
export const MenuBody = styled.div`
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    max-height: 210px;
    border-bottom: 1px solid #EEE;
`;
export const CheckBox = styled(Checkbox)`
    padding: 0.75rem 0;
    border-bottom: 1px solid #DAE5E6;

    > label
    {
        font-weight: 600;
    }
`;
export const MenuFooter = styled.div`
    display: flex;
    justify-content: space-between;
    background: white;
    border-radius: 0 0 16px 16px;

    button
    {
        margin: 10px;
        min-width: auto !important;
        width: 100%;
        padding: 2px 0px !important;
    }
`;
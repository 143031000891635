import React, { ReactElement, useContext, useRef } from "react";
import { ThemeContext } from "@fluentui/react-theme-provider";
import styled from "styled-components";
import * as L from "../styles/AccountTableStyles";
import { AccountsAutorized, AccountsUsed } from "../../../../services/SubscriptionService";
import { DefaultButton, Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";
import { DeleteIcon, InfoIcon, PDFIcon } from "@fluentui/react-icons-mdl2";
import { useEffect, useState } from "react";
import { DirectionalHint, TeachingBubble, Spinner, SpinnerSize } from "@fluentui/react";
import { RadioBtnOffIcon, RadioBtnOnIcon } from "@fluentui/react-icons-mdl2";
import Auth from "../../../../Auth/Auth";
import SavingsPlanService, { ActiveSubscriptionAndSavingPlans } from "../../../../services/SavingsPlanService";
import { ErrorModal } from "../../../../components/Modals/ErrorModal";
import { parseNumber } from "../../../../utils/toFixedDecimals";

export const Table = styled.table`
  width: 100%;
  height: 100%;
  border-spacing: 0;
  text-align: left;
  background: ${(props) => props.theme.palette.white};
  thead {
    th {
      background: ${(props) => props.theme.palette.tertiary};
      font-weight: 500;
      padding: 17px;
      text-align: left;
    }
    th:first-of-type {
      border-top-left-radius: 10px;
    }
    th:last-of-type {
      border-top-right-radius: 10px;
    }
  }

  td {
    padding: 16px;
    text-align: left;
    font-size: 12px;
    > a {
      color: ${(props) => props.theme.palette.darkBorder};
      font-weight: 600;
      line-height: 20px;
      font-size: 14px;
      cursor: pointer;
    }
  }
`;
export interface ITableProps
{
    tableTitle: string;
    rowDataUsed: AccountsUsed[] | undefined;
    rowDataAutorized: AccountsAutorized[] | undefined;
    columnTitles: { id: string; title: string }[];
    tableCount: number | undefined;
    updateValidateInfo: (isFromButton: boolean, accountNumber?: string, idDebt?: string, createAt?: Date,) => any;
    updateDeleteInfo: (idBankAccount: number) => any;
    downloadPdf: (idBankAccount: number) => any;
    isAuthorized: boolean;
    selectedAccount: (idBankAccount: string) => any;
    selectedActivePlansIds: number[];
}


const AccountTable = (props: ITableProps) =>
{
    const theme = useContext(ThemeContext);
    const { columnTitles, rowDataUsed, rowDataAutorized, updateValidateInfo, updateDeleteInfo, downloadPdf, isAuthorized, selectedAccount, selectedActivePlansIds } = props;
    const [teachingBubbleVisibleAuthorized, setTeachingBubbleVisibleAuthorized] = useState(false);
    const [teachingBubbleVisibleUsed, setTeachingBubbleVisibleUsed] = useState(false);
    const [teachingBubbleVisiblePortfolio, setTeachingBubbleVisiblePortfolio] = useState(false);
    const [isLoadingChange, setLoadingChange] = useState<boolean>(false);
    const [errorSavingPlan, setErrorSavingPlan] = useState<boolean>(false);

    const firstLoad = useRef<boolean>(true);

    const activePlan = Auth.getActivePlan();
    const user = Auth.getUserProfile();





    const handleBubbleChange = () =>
    {
        setTeachingBubbleVisibleAuthorized(false);
    };

   


    return (
        <div>
            {selectedActivePlansIds.length > 0 ?  
                <div>
                    {isAuthorized ?
                        <div>
                            <L.TableFilterContainer>
                                <L.TRCountContainer className="ms-Grid-row">
                                    <L.TRTitle className="ms-Grid-col ms-sm9 ms-md9 ms-lg9 ms-xl9 ms-xxl9 ms-xxxl9">
                                        {props.tableTitle}
                                        <L.TRCount
                                            id="authorizedbankaccount"
                                            onMouseEnter={() => setTeachingBubbleVisibleAuthorized(true)
                                            }
                                            onMouseLeave={() =>
                                                setTeachingBubbleVisibleAuthorized(false)
                                            }
                                        >
                                            <InfoIcon
                                                style={{
                                                    color: "#a6a6a",
                                                    width: "16px",
                                                    height: "16px",
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </L.TRCount>
                                    </L.TRTitle>
                                    <DefaultButton
                                        className="button mini-primary-button"
                                        onClick={() => updateValidateInfo(true)}
                                        style={{ width: "110px" }}
                                    >
                                        Añadir
                                    </DefaultButton>
                                </L.TRCountContainer>
                            </L.TableFilterContainer>
                            <L.TableContainer theme={theme}>
                                <Table theme={theme}>
                                    <thead style={{ backgroundColor: "grey !important" }}>
                                        <tr>
                                            <L.ThBlue style={{ textAlign: "center" }}>IBAN</L.ThBlue>
                                            <L.ThCenter style={{ textAlign: "center" }}>
                                                Acciones
                                            </L.ThCenter>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rowDataAutorized && rowDataAutorized.filter(e => e.savingPlanId == selectedActivePlansIds[0]).length > 0
                                            ? rowDataAutorized.filter(e => e.savingPlanId == selectedActivePlansIds[0]).map((row, index) => (
                                                <tr key={index}>
                                                    <L.TdTableAccount>
                                                        {" "}
                                                        <div style={{ display: "flex" }}>
                                                            {
                                                                row.isSelected ? (
                                                                    <div className="ms-Tabs-Container-BankAccount">
                                                                        <div style={{ paddingBottom: "12px" }}>
                                                                            <button
                                                                                className={"ms-Tab-BankAccount active"}
                                                                                onClick={() =>
                                                                                    selectedAccount(row.accountOrigin!)
                                                                                }
                                                                            >
                                                                                <RadioBtnOnIcon className="ms-Tab-BankAccount active-button" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="ms-Tabs-Container-BankAccount">
                                                                        <div style={{ paddingBottom: "12px" }}>
                                                                            <button
                                                                                className="ms-Tab-BankAccount"
                                                                                onClick={() =>
                                                                                    selectedAccount(row.accountOrigin!)
                                                                                }
                                                                            >
                                                                                <RadioBtnOffIcon
                                                                                    className="ms-Tab-BankAccount"
                                                                                />{" "}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            <div style={{ paddingLeft: "6px", paddingTop: "1px" }}>{row.accountOrigin}</div>
                                                        </div>
                                                    </L.TdTableAccount>
                                                    <L.TdTableAccount
                                                        style={{ display: "flex", justifyContent: "center" }}
                                                    >
                                                        {isAuthorized && row.titularityPdf != null ? (
                                                            <div style={{ display: "flex", marginTop: "10px" }}>
                                                                <DeleteIcon
                                                                    style={{
                                                                        color: "#a6a6a",
                                                                        width: "16px",
                                                                        height: "16px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => updateDeleteInfo(row.id!)}
                                                                />
                                                                <PDFIcon
                                                                    style={{
                                                                        marginLeft: "5px",
                                                                        color: "#a6a6a",
                                                                        width: "16px",
                                                                        height: "16px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => downloadPdf(row.id!)}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <DeleteIcon
                                                                style={{
                                                                    color: "#a6a6a",
                                                                    width: "16px",
                                                                    height: "16px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => updateDeleteInfo(row.id!)}
                                                            />
                                                        )}
                                                    </L.TdTableAccount>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td colSpan={columnTitles.length}>
                                                        No hay datos disponibles
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </L.TableContainer>
                        </div> :
                        <div>
                            <L.TableFilterContainer>
                                <L.TRCountContainer className="ms-Grid-row">
                                    <L.TRTitle className="ms-Grid-col ms-sm9 ms-md9 ms-lg9 ms-xl9 ms-xxl9 ms-xxxl9">
                                        {props.tableTitle}
                                        <L.TRCount
                                            id="usedbankaccount"
                                            onMouseEnter={() => setTeachingBubbleVisibleUsed(true)
                                            }
                                            onMouseLeave={() =>
                                                setTeachingBubbleVisibleUsed(false)
                                            }
                                        >
                                            <InfoIcon
                                                style={{
                                                    color: "#a6a6a",
                                                    width: "16px",
                                                    height: "16px",
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </L.TRCount>
                                    </L.TRTitle>
                                </L.TRCountContainer>
                            </L.TableFilterContainer>
                            <L.TableContainer theme={theme}>
                                <Table theme={theme}>
                                    <thead style={{ backgroundColor: "grey !important" }}>
                                        <tr>
                                            <L.ThBlue style={{ textAlign: "center" }}>IBAN</L.ThBlue>
                                            <L.ThCenter style={{ textAlign: "center" }}>
                                                Acciones
                                            </L.ThCenter>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rowDataUsed && rowDataUsed.length > 0 ? rowDataUsed.map((row, index) => (
                                            <tr key={index}>
                                                <L.TdTableAccount>
                                                    <div style={{ paddingLeft: "6px", paddingTop: "1px" }}>{row.accountOrigin}</div>

                                                </L.TdTableAccount>
                                                <L.TdTableAccount
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <div className="ms-Tabs-Container-BankAccount">
                                                        <DefaultButton
                                                            className="button mini-primary-button"
                                                            onClick={() =>
                                                            {
                                                                updateValidateInfo(
                                                                    false,
                                                                    row.accountOrigin,
                                                                    (row as AccountsUsed).idDebt,
                                                                    (row as AccountsUsed).createAt
                                                                )
                                                            }}
                                                            style={{ width: "70px" }}
                                                        >
                                                            Autorizar
                                                        </DefaultButton>
                                                    </div>
                                                </L.TdTableAccount>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan={columnTitles.length}>
                                                    No hay datos disponibles
                                                </td>
                                            </tr>
                                        )
                                        }
                                    </tbody>
                                </Table>
                            </L.TableContainer>
                        </div>
                    }
                </div>
             : isAuthorized && <Spinner size={SpinnerSize.large} />}
            <div>
                {teachingBubbleVisibleAuthorized === true && (
                    // @ts-ignore
                    <TeachingBubble
                        target={"#authorizedbankaccount"}
                        hasCloseButton={true}
                        hasSmallHeadline={false}
                        onDismiss={handleBubbleChange}
                        closeButtonAriaLabel="Cerrar"
                        calloutProps={{
                            directionalHint: DirectionalHint.bottomLeftEdge,
                            calloutWidth: 290,
                            calloutMaxHeight: 500,
                        }}
                        styles={{
                            content: {
                                maxWidth: "290px",
                                backgroundColor: "rgba(42, 128, 185, 0.68)",
                            },
                            bodyContent: { padding: "2px 0 2px 14px", cursor: "default" },
                            body: { marginBottom: "0 !important", cursor: "default" },
                        }}
                    >
                        <h3 style={{ marginBottom: "4px !important" }}>
                            Cuentas Corrientes Autorizadas
                        </h3>
                        <p>
                            Son las cuentas corrientes que has añadido y/o autorizado y hemos validado que pertenecen a la cartera seleccionada y de la que eres titular. Si pulsa en el botón {<DeleteIcon />} la cuenta será eliminada de nuestra base
                            de datos. También puede pulsar el botón Añadir para incluir nuevas cuentas. Además, el botón <RadioBtnOnIcon className="ms-Tab-BankAccount active-button" />
                            te permitirá seleccionar una cuenta por defecto de entre todas las de la lista.
                        </p>
                    </TeachingBubble>
                )}
                {teachingBubbleVisibleUsed === true && (
                    // @ts-ignore
                    <TeachingBubble
                        target={"#usedbankaccount"}
                        hasCloseButton={true}
                        hasSmallHeadline={false}
                        onDismiss={handleBubbleChange}
                        closeButtonAriaLabel="Cerrar"
                        calloutProps={{
                            directionalHint: DirectionalHint.bottomLeftEdge,
                            calloutWidth: 290,
                            calloutMaxHeight: 500,
                        }}
                        styles={{
                            content: {
                                maxWidth: "290px",
                                backgroundColor: "rgba(42, 128, 185, 0.68)",
                            },
                            bodyContent: { padding: "2px 0 2px 14px", cursor: "default" },
                            body: { marginBottom: "0 !important", cursor: "default" },
                        }}
                    >
                        <h3>Cuentas Corrientes Utilizadas</h3>
                        <p>
                            Son las cuentas a tu nombre con las que ya hemos operado. Si pulsa en el botón Autorizar la cuenta corriente pasará a
                            formar parte de tus Cuentas Corrientes Autorizadas.
                        </p>
                    </TeachingBubble>
                )}
                {teachingBubbleVisiblePortfolio === true && (
                    // @ts-ignore
                    <TeachingBubble
                        target={"#portfoliotoselected"}
                        hasCloseButton={true}
                        hasSmallHeadline={false}
                        onDismiss={handleBubbleChange}
                        closeButtonAriaLabel="Cerrar"
                        calloutProps={{
                            directionalHint: DirectionalHint.rightBottomEdge,
                            calloutWidth: 290,
                            calloutMaxHeight: 500,
                        }}
                        styles={{
                            content: {
                                maxWidth: "290px",
                                backgroundColor: "rgba(42, 128, 185, 0.68)",
                            },
                            bodyContent: { padding: "2px 0 2px 14px", cursor: "default" },
                            body: { marginBottom: "0 !important", cursor: "default" },
                        }}
                    >
                        <h3>Selector de carteras</h3>
                        <p>
                            Se refiere a la cartera que tienes seleccionada. La información que aparece en la tabla de cuentas autorizadas se refiere a aquellas cuentas corrientes de las que eres titular siempre y cuando seas titular de la cartera seleccionada.
                        </p>
                    </TeachingBubble>
                )}
                <ErrorModal
                    title="Error"
                    body={
                        <><p>
                            Se ha producido un error. Vuelve a intentarlo de nuevo en unos momentos
                        </p></>
                    }
                    btnSubmitText="Aceptar"
                    showModal={errorSavingPlan}
                    onSubmit={() =>
                    {
                        setErrorSavingPlan(false);
                    }}
                    onClose={() =>
                    {
                        setErrorSavingPlan(false);
                    }}
                />
            </div>
        </div>
    );
};

export default AccountTable;

import { DefaultButton } from '@fluentui/react';
import { ReactElement } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './index.module.sass';

interface CartButtonProps {
    cartLines: any[];
}

export default function CartChip(): ReactElement {

    function mapStateToProps(state: any) {
        return { cartLines: state.cart.cartLines };
    }

    const CartChipChild = connect(mapStateToProps)((props: CartButtonProps) => {
        if (props.cartLines?.length === 0) {
            return <></>
        }

        return (
            <div className={styles.CartButtonContainer}>
                <Link to='/cart'>
                    <div>
                        <div className={`${styles.Badge} badge`}>{props.cartLines.length}</div>
                        <DefaultButton title="Carrito">
                            <i className="ironia-icon shopping-cart" />
                        </DefaultButton>
                    </div>
                </Link>
            </div>
        )
    });

    return (
        <CartChipChild />
    )
}
import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { IButtonProps } from '@fluentui/react';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import MenuService from '../../services/MenuService';

const _overflowItems: ICommandBarItemProps[] = [];
const overflowProps: IButtonProps = { ariaLabel: 'Más acciones' };

const PortfolioMenu = () => {
    const history = useHistory();
    const [menuItems, setMenuItems] = useState<ICommandBarItemProps[]>([
        {
            text: 'Resumen de cartera',
            key: 'portfolio',
            onClick: () => history.push('/portfolio'),
            className: window.location.pathname.match('/(portfolio)$') ? 'is-selected' : ''
        },
        {
            text: 'Operaciones realizadas',
            key: 'operations',
            onClick: () => history.push('/portfolio/operations'),
            className: window.location.pathname === '/portfolio/operations' ? 'is-selected' : ''
        },
        {
            text: 'Órdenes en curso',
            key: 'orders',
            onClick: () => history.push('/portfolio/orders'),
            className: window.location.pathname === '/portfolio/orders' ? 'is-selected' : ''
        },
        {
            text: 'Información fiscal',
            key: 'fiscal_report',
            onClick: () => history.push('/portfolio/fiscalReport'),
            className: window.location.pathname === '/portfolio/fiscalReport' ? 'is-selected' : ''
        }
    ]);

    useEffect(() => {
        MenuService.getMarketItemEnable().then((isMarketEnabled) => {
            if (isMarketEnabled) {
                setMenuItems((prevItems) => [
                    ...prevItems,
                    {
                        text: 'Mercado',
                        key: 'market',
                        onClick: () => history.push('/portfolio/market'),
                        className: window.location.pathname === '/portfolio/market' ? 'is-selected' : ''
                    }
                ]);
            }
        });
    }, [history]);

    return (
        <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" id="main-menu">
                <CommandBar
                    // @ts-ignore
                    items={menuItems}
                    overflowItems={_overflowItems}
                    overflowButtonProps={overflowProps}
                    ariaLabel="Usa las flechas izquierda y derecha para navegar entre las entradas del menú"
                />
            </div>
        </div>
    );
};

export default PortfolioMenu;

import styled from "styled-components";

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    table-layout: fixed;

    hr
    {
        display: none;
    }

    tr
    {
        border-bottom: 2px solid #E9EFF2;
        height: 46px;
        cursor: default;

        th
        {
            padding: 10px 10px 10px 10px;
            font-size: 17px;
            color: #2A4143;
            border-left: 2px solid #E9EFF2;
            border-right: 2px solid #E9EFF2;
            background-color: #F3F7F9;
            text-align: center;
            white-space: nowrap;

            &:first-of-type
            {
                border-left: none;
                border-top-left-radius: 20px;
            }

            &:last-of-type
            {
                border-right: none;
                border-top-right-radius: 20px;
            }

            &:nth-child(1)
            {
                width: 40%;
            }

            &:nth-child(2)
            {
                width: 30%;
            }

            &:nth-child(3)
            {
                width: 15%;
                padding-left: 8px !important;
            }

            &:nth-child(4)
            {
                width: 15%;
            }
        }

        td
        {
            padding: 0px 15px 0px 15px;
            word-break: break-word;
            font-size: 15px;
            border-left: 2px solid #E9EFF2;
            border-right: 2px solid #E9EFF2;

            &:first-of-type
            {
                border-left: none;
            }

            &:last-of-type
            {
                border-right: none;
                padding-top: 5px !important;
                padding-bottom: 5px !important;
                text-align: center;
            }
        }
    }

    @media screen and (min-width: 280px)
    {
        a
        {
            padding: 5px 5px !important;
        }
    }
    @media screen and (min-width: 320px)
    {
        a
        {
            padding: 5px 7px !important;
        }
    }
    @media screen and (min-width: 360px)
    {
        a
        {
            padding: 5px 5px !important;
        }
    }
    @media screen and (min-width: 412px)
    {
        a
        {
            padding: 5px 8px !important;
        }
    }
    @media screen and (min-width: 480px)
    {
        a
        {
            padding: 5px 12px !important;
        }
    }
    @media screen and (min-width: 540px)
    {
        a
        {
            padding: 5px 16px !important;
        }
    }
    @media screen and (min-width: 600px)
    {
        a
        {
            padding: 2px 4px !important;
        }
    }
    @media screen and (min-width: 768px)
    {
        a
        {
            padding: 5px 15px !important;
        }
    }
    @media screen and (min-width: 1024px)
    {
        a
        {
            padding: 5px 30px !important;
        }
    }
    @media screen and (min-width: 1366px)
    {
        a {
            padding: 5px 45px !important;
        }
    }
    @media screen and (min-width: 1440px)
    {
        a
        {
            padding: 5px 50px !important;
        }
    }
    @media screen and (min-width: 1650px)
    {
        a
        {
            padding: 5px 65px !important;
        }
    }
    @media screen and (min-width: 2000px)
    {
        a
        {
            padding: 5px 85px !important;
        }
    }
`;

export const TableDiv = styled.div`
    display: flex;
    margin: 10px 0;
    tr:last-of-type
    {
        width: 100%;
    }
`;
export const TdBtnCont = styled.div`
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 399px)
    {
        justify-content: center;
    }
    @media screen and (min-width: 899px)
    {
        justify-content: center;
    }
    @media screen and (min-width: 1300px)
    {
        justify-content: space-between;
    }
`;
export const GraphicCont = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 25px;
`;
export const GraphicBlue = styled.div`
    width: 74%;
    height: 100%;
    border-radius: 5px;
    margin: 0 1px;
    background-color: #9EC0F0;
`;
export const GraphicPurple = styled.div`
    width: 16%;
    height: 100%;
    border-radius: 5px;
    margin: 0 1px;
    background-color: #C9A3C1;
`;
export const GraphicYellow = styled.div`
    width: 10%;
    height: 100%;
    border-radius: 5px;
    margin: 0 1px;
    background-color: #F8DAB5;
`;
export const GraphicGrey = styled.div`
    width: 5%;
    height: 100%;
    border-radius: 5px;
    margin: 0 1px;
    background-color: #E2EAEC;
`;
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
export const Title = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 80px;
    justify-content: center;
    align-items: flex-start;
    h1
    {
        cursor: default;
    }
`;
export const TableCont = styled.div`
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0px 1px 7px -1px #00000025;
    @media screen and (max-width: 899px)
    {
        padding: 15px;
    }
`;
export const SubTitle = styled.h4`
    font-size: 18px;
    font-weight: 700;
    margin-top: 0 !important;
    margin-bottom: 1rem;
    cursor: default;
    color: #CC214F;
`;
export const Note = styled.p`
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    color: #93AEB9;
    text-align: left;
`;
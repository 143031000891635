import axios from "axios";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);

export type AzureNewsResponse =
{
    "@odata.context": string;
    "@odata.count": number;
    value: AzureNewsType[];
    "@odata.nextLink": string;
}
export type AzureNewsType =
{
    "@search.score": number;
    Id: number,
    Title: string,
    Url: string,
    Image: string,
    PublishDate: string,
    Category?: string,
    Summary?: string,
    NewsImportId?: number
}
export type AzureDocFondosResponse =
{
    "@odata.context": string;
    "@odata.count": number;
    value: AzureDocFondosType[];
    "@odata.nextLink": string;
}
export type AzureDocFondosType =
{
    "@search.score": number;
    metadata_storage_name: string;
    metadata_storage_path: string;
    metadata_storage_file_extension: string;
    metadata_content_type: string;
    metadata_language: string;
    metadata_author: string;
    metadata_title: string;
    metadata_creation_date: string;
    isin: string;
    name?: string;
}

class AzureSearchServices
{
    public getNewsFromAzureSearchService = async (page: number, itemsPerPage: number, searchTerm: string): Promise<AzureNewsResponse> =>
    {
        const apiVer = "2021-04-30-Preview"
        const key = "zsdvuJSWxQt03HOm2FV7GroPesQk4NyzyV1IttQxZtAzSeDMXCOX";
        let url: string = `https://newsironia.search.windows.net/indexes/ironianews/docs?api-version=${apiVer}&api-key=${key}`;
        if(searchTerm && searchTerm.trim() !== '')
        {
            url += `&search=${encodeURIComponent(searchTerm)}`;
        }
        else
        {
            url += `&search=*`;
        }
        url += `&$count=true`
        url += `&$top=${itemsPerPage}`
        url += `&$skip=${page * itemsPerPage}`

        const req = await axios.get(url)
        return req.data
    }
    public getDocFondos = async (page: number, itemsPerPage: number, searchTerm: string): Promise<AzureDocFondosResponse> =>
    {
        const apiVer = "2021-04-30-Preview"
        const key = "3EAFE891E461BB0074A5C208FF611F8F";
        let url: string = `https://docfondos.search.windows.net/indexes/azureblob-index/docs?api-version=${apiVer}&api-key=${key}`;
        if(searchTerm && searchTerm.trim() !== '')
        {
            url += `&search=${encodeURIComponent(searchTerm)}`;
        }
        else
        {
            url += `&search=*`;
        }
        url += `&$count=true`
        url += `&$top=${itemsPerPage}`
        url += `&$skip=${page * itemsPerPage}`

        const req = await axios.get(url)
        return req.data
    }
}

const azureSearchServices = new AzureSearchServices();
export default azureSearchServices;
import React from "react";
import { FirstTh, TableSearch } from "./SearchManagersStyle";
import SearchEngineSecondTable from "./SearchEngineSecondTable";

interface ISearchEngineTable
{
    columns: any,
    groups: any
}
const SearchEngineTable = (props: ISearchEngineTable) =>
{
    return(
        <React.Fragment>
            <TableSearch>
                <thead>
                    <tr>
                        {props.columns && props.columns.length > 0 && props.columns.map((column: any, columnIndex: number) =>
                        {
                            if(column === 'Nombre')
                            {
                                return(
                                    <React.Fragment key={'columnIndex' + columnIndex}>
                                        <FirstTh key={'columnIndex'+columnIndex}>
                                            <div>{column}</div>
                                        </FirstTh>
                                    </React.Fragment>
                                );
                            }
                            else
                            {
                                return(
                                    <React.Fragment key={'columnIndex'+columnIndex}>
                                        <th key={'columnIndex'+columnIndex}>
                                            <div>{column}</div>
                                        </th>
                                    </React.Fragment>
                                );

                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    {props.groups && props.groups.length > 0 && props.groups.map((row: any, index: number) =>
                    {
                        return(
                            <React.Fragment key={index}>
                                <SearchEngineSecondTable fund={row} key={'index'+index} group={props.groups.find((group: any) => group.family === row.family)} />
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </TableSearch>
        </React.Fragment>
    );
}

export default SearchEngineTable;
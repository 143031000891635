import { useContext } from "react";
import{ Wrapper, TableContainer, Table } from "./PortfolioOperationsStyles";
import { ThemeContext } from "@fluentui/react-theme-provider";
import { toFixedDecimals } from "../../../utils/toFixedDecimals";
import React from "react";
import { IOperations } from "../../../services/FinametrixService";

interface IOperationsProps
{
    data: IOperations[],
    columns: {id: string, title: string, source: string, customCell?: (value: any) => JSX.Element}[],
    resumeData?: any
}

const PortfolioOperationsTable = (props: IOperationsProps) =>
{
    const theme = useContext(ThemeContext);

    return (
        <Wrapper>
            <TableContainer>
                <Table theme={theme}>
                    <thead>
                        <tr>
                            {props.columns.map((column, index) =>
                            {
                                if(index === 4)
                                {
                                    return <th style={{textAlign: "center"}} key={index}>{column.title}</th>;
                                }
                                else
                                {
                                    return <th key={index}>{column.title}</th>;
                                }
                            })}
                        </tr>
                        <tr>
                            {props.resumeData !== undefined && props.resumeData.map((cell: any, index: number) =>
                            {
                                if(index === 4)
                                {
                                    return <th style={{textAlign: "center"}} key={index}>{cell}</th>;
                                }
                                else
                                {
                                    return <th key={index}>{cell}</th>;
                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map((row: any, index: number) =>
                        {
                            return(
                                <React.Fragment key={index}>
                                    <tr>
                                        {props.columns.map((col, index) =>
                                        {
                                            const cellValue = toFixedDecimals(row[col.source], 3);
                                            if(col.customCell)
                                            {
                                                return (<td key={index}>{col.customCell({ value: cellValue, row, col })}</td>);
                                            }
                                            else
                                            {
                                                return <td key={index}>{cellValue}</td>;
                                            }
                                        })}
                                    </tr>
                                    {row.subData && row.subData.expanded && row.subData.data.map((childRow: any, index: number) =>
                                    {
                                        return (
                                            <tr key={index} style={{ background: theme?.palette.themeTertiary }}>
                                                {row.subData.columns.map((childCol: any, subIndex: number) =>
                                                {
                                                    const cellValue = toFixedDecimals(childRow[childCol.source], 3);
                                                    if(childCol.customCell)
                                                    {
                                                        return(
                                                            <td key={subIndex}>
                                                                {childCol.customCell(
                                                                    {
                                                                        value: cellValue,
                                                                        childRow,
                                                                        childCol,
                                                                    }
                                                                )}
                                                            </td>
                                                        );
                                                    }
                                                    else
                                                    {
                                                        return <td>{cellValue}</td>;
                                                    }
                                                })}
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </Table>
            </TableContainer>
        </Wrapper>
    );
};

export default PortfolioOperationsTable;
import { Route } from "react-router-dom";

const FundRoute = ({component: Component, error: ErrorComponent, ...rest}) =>
{
    let regex = new RegExp(/^[A-Z]{2}[0-9A-Z]{10}/);
    let isinToRegex = rest.location.pathname.substr(1);

    if(regex.test(isinToRegex) === true)
    {
        return(
            <Route {...rest} render={props =>
            {
                return <Component {...props} />;
            }}
            />
        );
    }
    else
    {
        return(
            <Route render={props =>
            {
                return <ErrorComponent {...props} />;
            }}
            />
        );
    }
};

export default FundRoute;
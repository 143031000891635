import axios from "axios";
import { proxyUrl } from "../config";
import { ElementsPromotionType } from "./ElementsPromotionsService";
import { setupInterceptorsTo } from "./Interceptors";
import { ContractDocsVersionType } from "./ContractDocsService";
import { UserIdentityType } from "./UserIdentitiesService";
import { ISignature } from "./SignaturesService";
setupInterceptorsTo(axios);

interface IElementPrice
{
    date: Date;
    dateString: string;
    events: number;
    price: number;
}
export type ElementPrice = IElementPrice;

interface IElementAmount
{
    elementAir: number;
    elementEarth: number;
    elementFire: number;
    elementWater: number;
    elementIA: number;
    elementTransactions: IElementTransaction[];
    elementsSigned: ElementSignedType[];
}
export type ElementAmountType = IElementAmount;

interface IElementTransaction
{
    elementAmountId: number;
    elementAmount: ElementAmountType;
    amountType: string;
    amountElement: number;
    concept: string;
    description: string;
    transactionDate: Date;
    elementPrmotionId?: number;
    elementPromotion?: ElementsPromotionType;
}
export type ElementTransactionType = IElementTransaction;

interface IElementIA
{
    elementsWaterUsed: number;
    elementsIA: number;
    reference: string;
    subject: string;
    price: number;
    areElementsApplied: boolean;
    savingsPlanId: number;
}
export type ElementIAObject = IElementIA;

interface IElementIAResponse
{
    areElementsApplied: boolean;
    isPriceEqualZero: boolean;
    hasError: boolean;
    error: string;
}
export type ElementIAResponse = IElementIAResponse;

export type UpdateElementsAmountType =
{
    savingsPlanId: number;
    air?: number;
    earth?: number;
    fire?: number;
    water?: number;
    iA?: number;
    concept: string;
    description: string;
};

export type ServiceType =
{
    id?: number;
    name?: string;
    serviceType: string;
    elementIA?: number;
    elementWater?: number;
    elementEarth?: number;
    elementFire?: number;
    elementAir?: number;
}

export interface SavingsPlanElementsStatus
{
    isSubscribed: boolean,
    isOutdatedContract: boolean,
    lastContractVersion?: string,
    sync: boolean
}

export interface ElementContract
{
    name: string,
    file: string,
    version: string,
    date: string,
    userIdentityFullname: string,
    userIdentityDocument: string
}

export interface ElementSignedType
{
    id: number,
    elementAmountId: number,
    userIdentityId: number,
    signatureId: number,
    signedContractURL: number,
    signedPromotionId?: number,
    contractDocsVersionsId: number,
    contractDocsVersions: ContractDocsVersionType[],
    elementAmount: ElementAmountType,
    userIdentity: UserIdentityType,
    signature: ISignature,
    signedPromotion: ElementsPromotionType
}

class ElementsService
{
    public isSavingsPlanSubscribedToElements = async (savingsPlanId: number): Promise<SavingsPlanElementsStatus> =>
    {
        let url: string = `${proxyUrl}/IsSavingsPlanSubscribedToElements/${savingsPlanId}`;
        const req = await axios.get(url);
        return req.data;
    };
    public getElementContract = async (): Promise<any> =>
    {
        let url: string = `${proxyUrl}/GetElementContract`;
        const req = await axios.get(url);
        return req.data;
    };
    public getElementContractByName = async (elementContractName: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/getElementContractByName/${elementContractName}`;
        const req = await axios.get(url);
        return req.data;
    };
    public getAllPricesData = async (): Promise<ElementPrice[]> =>
    {
        let url: string = `${proxyUrl}/GetAllPricesData`;
        const req = await axios.get(url);
        return req.data;
    };
    public generateElementPromotionPdfs = async (savingsPlanId: number, elementPromotionSlug: string): Promise<boolean> =>
    {
        let url: string = `${proxyUrl}/generateElementPromotionPdfs/${savingsPlanId}/${elementPromotionSlug}`;
        const req = await axios.post(url);
        return req.data;
    };

    public getElementsAmountBySavingsPlanId = async (savingsPlanId: number): Promise<any> =>
    {
        let url: string = `${proxyUrl}/elementsAmount/getBySavingsPlanId/${savingsPlanId}`;
        const req = await axios.get(url);
        return req.data;
    };

    public getElementsAmountBySavingsPlanIdCompetition = async (savingsPlanId: number): Promise<any> =>
    {
        let url: string = `${proxyUrl}/elementsAmount/getBySavingsPlanId/${savingsPlanId}?fromCompetition=true`;
        const req = await axios.get(url);
        return req.data;
    };

    public getOnlyElementsAmountByUsersSubscriptionId = async (usersSubscriptionId: number): Promise<any> =>
    {
        let url: string = `${proxyUrl}/OnlyElementsAmountByUsersSubscriptionId/${usersSubscriptionId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getAllContracts = async (savingsPlanId: number): Promise<ElementContract[]> =>
    {
        let url: string = `${proxyUrl}/GetAllContracts/${savingsPlanId}`;
        const req = await axios.get(url);
        return req.data;
    };
    public getElementContractPDF = async (id: string): Promise<any> =>
    {
        let url: string = `${proxyUrl}/GetElementContractPDF/${id}`;
        const req = await axios.get(url);
        return req.data;
    };
    public getLastElementsPrice = async (): Promise<any> =>
    {
        let url: string = `${proxyUrl}/elements/getLastElementsPrice`;
        const req = await axios.get(url);
        return req.data;
    };
    public elementsIA = async (toSend: ElementIAObject): Promise<ElementIAResponse> =>
    {
        let url: string = `${proxyUrl}/ElementsIA`;
        const req = await axios.post(url, toSend, { headers: { "content-type": "application/json" } });
        return req.data;
    };
    public updateElementsAmount = async (elements: UpdateElementsAmountType): Promise<boolean> =>
    {
        let url: string = `${proxyUrl}/elementsAmount/UpdateElementsAmount`;
        const req = await axios.patch(url, elements);
        return req.data;
    };
    public servicePrice = async (servicePriceTypeId: number): Promise<ServiceType> =>
    {
        let url: string = `${proxyUrl}/elements/services-prices/${servicePriceTypeId}`;
        const req = await axios.get(url);
        return req.data;
    };
    public transferElementsIA = async (data: any): Promise<any> =>
    {
        let url: string = `${proxyUrl}/ElementsIATransferRefund`;
        const req = await axios.post(url, data, { headers: { "content-type": "application/json" } });
        return req.data;
    }
    public createElementAmount = async (savingsPlanId: any): Promise<ISignature[]> =>
    {
        let url: string = `${proxyUrl}/elementAmounts/${savingsPlanId}`;
        const req = await axios.post(url);
        return req.data;
    }
}

const ElementService = new ElementsService();
export default ElementService;
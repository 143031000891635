import styled from "styled-components";

export const OptimizerBody = styled.div`
	font-size: 18px;
	line-height: 1.5em;

	section {
		box-shadow: none !important;

		h2 {
			margin-top: 40px;
			margin-bottom: 20px;
		}

		p {
			margin: 10px 0;
		}

		.ms-DetailsList {
			margin: 20px 0;
			line-height: 1.25em;
		}

		&:first-of-type {
			h2 {
				margin-top: 0;
			}
		}

		img {
			 width: 100%;
			 height: fit-content;
		}

		.cards {
			font-size: 14px;
			line-height: 1.25em;
			margin: -10px 0 20px !important;

			.ms-Grid-col {
				height: 140px;
				margin: 5px 0;

				p {
					height: 100%;
					box-sizing: border-box;
					background: ${props => (props.theme.name === "dark" ? props.theme.palette.background : "#F3F7F9")};
					box-shadow: rgb(0 0 0 / 25%) 0.5px 1px 2px;
					border-radius: 8px;
					padding: 20px;
					position: relative;

					img {
						position: absolute;
						width: 30px;
						right: 20px;
						top: 15px;
					}

					b.bottomLeft {
						position: absolute;
						bottom: 20px;
						left: 20px;
					}

					button {
						padding: 0 30px !important;
						position: absolute;
						bottom: 10px;
						right: 10px;
					}

					&.tiny {
						height: 110px;
					}
				}
			}
		}
	}

	@media(min-width: 1280px) {
		max-width: 1450px;
		margin: 0 auto;
	}
`;

export const OptimizerActionPanel = styled.div`
	display: inline-flex;
	box-shadow: rgb(0 0 0 / 25%) 0.5px 1px 2px;
	border-radius: 10px;
	margin-bottom: 10px;
	margin-top: 10px;
	margin-right: 10px;
	height: 100px;
	width: 200px;
	position: relative;
	background: ${props => (props.theme.name === "dark" ? props.theme.palette.background : "#F3F7F9")};
	color: ${props => props.theme.palette.color};
`;

export const OptimizerActionPanelElementTR = styled.div`
	position:absolute;
	top:0;
	right:0; 
	padding: 10px;
`;

export const OptimizerActionPanelElementBR = styled.div`
	position:absolute;
	bottom:0;
	right:0;
	padding: 10px;
`;

export const OptimizerActionPanelElementTL = styled.div`
	position:absolute;
	top:0;
	left:0; 
	padding: 15px;
`;

export const OptimizerActionPanelElementBL = styled.div`
	position:absolute;
	bottom:0;
	left:0;
	padding: 15px;
`;

export const OptimizerButton = styled.button`
    border: 0px;
    border-radius: 24px;
    background-color: #CC214F;
    color: white;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 1px;
    cursor: pointer;

	&:disabled {
		background-color: #cdc8ca;
	  }
`;
import { useCallback, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { themes } from "../components/ThemeContext";
import { ThemeProvider } from "@fluentui/react-theme-provider";
import { useDispatch } from "react-redux";
import { setHeaderVisibility, setFooterVisibility, setTheme, setMainMenuVisibility } from "../store/ui/actions";

const FrameRoute: (props: any) => any = ({component: Component, ...rest}) =>
{
    const dispatch = useDispatch();
    const [theme/*, setStateTheme*/] = useState(
    {
        palette: themes.light
    });

    const hideHeaderAndFooter = useCallback(async () =>
    {
        dispatch(setHeaderVisibility(false));
        dispatch(setMainMenuVisibility(false));
        dispatch(setFooterVisibility(false));
        dispatch(setTheme("light"));
    }, [dispatch]);

    useEffect(() =>
    {
        hideHeaderAndFooter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Route {...rest} render={props =>
            {
                return <Component {...rest} publicVersion={true} />;
            }}
            />
        </ThemeProvider>
    );
};

export default FrameRoute;
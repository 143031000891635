import React from "react";
import DocumentTitle from "react-document-title";
import * as L from '../Legal/LegalInformationStyles';

const Legal = () =>
{
    return (
        <DocumentTitle title='IronIA - Legal'>
            <React.Fragment>
                <L.Wrapper>
                    <L.TitleContainer>
                        <L.Title>Información Legal</L.Title>
                    </L.TitleContainer>
                </L.Wrapper>
                <p>
                    1. IDENTIFICACIÓN
                </p>
                <p>
                        IRONIA es la marca comercial de SIMPLICITAS CAPITAL, S.L. (con domicilio en la calle María de Molina, 40, planta 7ª, Madrid, y NIF B87806337), sociedad agente financiero registrado de DIAPHANUM VALORES, SV, S.A.U. (<a href="https://www.diaphanum.es" target="_blank" rel="noopener noreferrer">www.diaphanum.es</a>), empresa de servicios de inversión registrada en la Comisión Nacional del Mercado de Valores con el número 265, cuyo domicilio se encuentra en la calle María de Molina, 40, planta 7ª, Madrid y cuyo NIF es A87637450.

                    Puede contactar con IRONIA por teléfono (+34 917939400) o correo electrónico (info@ironia.tech).
                </p>
                <p>
                    2. OBJETO Y ÁMBITO DE APLICACIÓN
                </p>
                <p>
                    A continuación, se exponen las condiciones generales que regulan el acceso, navegación y uso de las páginas web bajo el dominio de IRONIA, así como la responsabilidad derivada del uso de sus contenidos.
                </p>
                <p>
                    – Se presume que el acceso o la mera utilización de www.ironia.tech por parte del Usuario implica la adhesión de éste a las condiciones generales que IRONIA tenga publicadas en cada momento en el que acceda a su página web. En consecuencia, el Usuario debe estar al tanto de estas condiciones generales. En este sentido, se entenderá por Usuario a la persona que acceda, navegue, utilice o participe en los servicios y actividades, gratuitas u onerosas, desarrolladas en IRONIA.

                    Las presentes condiciones generales tienen por objeto regular el acceso, navegación y uso de www.ironia.tech. Independientemente de las mismas, IRONIA podrá establecer condiciones particulares que regulen la utilización y/o contratación de servicios específicos ofrecidos a los Usuarios a través de www.ironia.tech. Asimismo, a través de www.ironia.tech, IRONIA podrá habilitar a terceras entidades para que publiciten o presten sus servicios. En estos casos, IRONIA no será responsable de establecer las condiciones generales y particulares a tener en cuenta en la utilización, prestación o contratación de estos servicios por terceros y, por tanto, no podrá ser considerado responsable de los mismos. En cualquier caso, IRONIA no facilitará datos de carácter personal de Usuarios a dichos terceros.

                    Antes de utilizar y/o contratar servicios específicos prestados por IRONIA, el Usuario deberá leer atentamente las correspondientes condiciones particulares que se estipulen a tales efectos. La utilización y/o la contratación de dichos servicios específicos implican la aceptación de las condiciones particulares que los regulen en la versión publicada por IRONIA en el momento en que se produzca dicha utilización y/o contratación.
                </p>
                <p>
                    3. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL
                </p>
                <p>
                    IRONIA, como autor de obra colectiva, es titular de los derechos de explotación de propiedad intelectual e industrial de www.ironia.tech, y es también titular o tiene la correspondiente licencia sobre los derechos de propiedad intelectual, industrial y de imagen sobre de los contenidos disponibles a través de dicha web.

                    En ningún caso se entenderá que el acceso y navegación del Usuario implica una renuncia, transmisión, licencia o cesión total ni parcial de dichos derechos por parte de IRONIA.

                    En consecuencia, no está permitido suprimir, eludir o manipular el aviso de derechos de autor (“copyright”) y cualesquiera otros datos de identificación de los derechos de IRONIA o de sus titulares incorporados a los contenidos, así como los dispositivos técnicos de protección, las huellas digitales o cualesquiera mecanismos de información y/o de identificación que pudieren incluirse o estar incluidos en los contenidos.

                    Asimismo, está prohibido modificar, copiar, reutilizar, explotar, reproducir, comunicar públicamente, hacer segundas o posteriores publicaciones, cargar archivos, enviar por correo, transmitir, usar, tratar o distribuir de cualquier forma la totalidad o parte de los contenidos incluidos en www.ironia.tech para propósitos públicos o comerciales, si no se cuenta con la autorización expresa y por escrito de IRONIA o, en su caso, del titular de los derechos correspondiente. En particular, IRONIA se opone de manera expresa a que la reproducción de sus páginas pueda ser considerada una cita en los términos previstos en el artículo 32, 1º párrafo segundo, de la Ley de Propiedad Intelectual.
                </p>
                <p>
                    4. ACCESO
                </p>
                <p>
                    El acceso al dominio www.ironia.tech por parte de los Usuarios tiene carácter libre y gratuito. No obstante, algunos de los servicios y contenidos ofrecidos por IRONIA o terceros a través de www.ironia.tech pueden encontrarse sujetos a la contratación previa del servicio o producto y al pago de una cantidad de dinero en la forma que se determine

                    en las correspondientes condiciones particulares, en cuyo caso se pondrían a disposición del Usuario de forma expresa y clara.

                    Cuando sea necesario que el Usuario se registre o aporte datos personales para poder acceder a alguno de los servicios específicos, la recogida, el tratamiento y, en su caso, la cesión o el acceso de los datos personales de los Usuarios será de aplicación lo dispuesto en la Política de Privacidad recogida en www.ironia.tech.

                    Queda prohibida la contratación de productos y servicios a través del dominio www.ironia.tech por parte de menores de edad, quienes deberán obtener con anterioridad el debido consentimiento de sus padres, tutores o representantes legales, los cuales serán considerados como responsables de los actos que lleven a cabo los menores a su cargo, conforme a lo estipulado en la normativa vigente.
                </p>
                <p>
                    5. UTILIZACIÓN DE LA PÁGINA
                </p>
                <p>
                    Los contenidos incluidos en www.ironia.tech se facilitan únicamente a consumidores o usuarios finales. Cualquier uso comercial no autorizado de los mismos, o su reventa, quedan prohibidos, salvo que se cuente con la previa autorización por escrito de IRONIA. – Si para la utilización y/o contratación de un servicio en www.ironia.tech, el Usuario debiera proceder a su registro, éste será responsable de aportar información veraz y lícita. Si como consecuencia del registro, se dotara al Usuario de una contraseña, éste se compromete a hacer un uso diligente y a mantener en secreto la contraseña para acceder a estos servicios. En consecuencia, los Usuarios son responsables de la adecuada custodia y confidencialidad de cualesquiera identificadores y/o contraseñas que le sean suministradas por IRONIA, y se comprometen a no ceder su uso a terceros, ya sea temporal o permanente, ni a permitir su acceso a personas ajenas. Será responsabilidad del Usuario la utilización ilícita de los servicios por cualquier tercero ilegítimo que emplee a tal efecto una contraseña a causa de una utilización no diligente o de la pérdida de la misma por el Usuario. En cualquier caso, debe tenerse en cuenta que la identificación y autenticación mediante nombre de usuario y contraseña es un método menos fiable en comparación con la firma electrónica avanzada.

                    En virtud de lo anterior, es obligación del Usuario informar de manera inmediata a los gestores de www.ironia.tech (enviando un correo electrónico a la dirección info@ironia.tech o llamando al número de teléfono 917939400) de cualquier hecho que permita el uso indebido de los identificadores y/o contraseñas, tales como el robo, extravío, o el acceso no autorizado a los mismos, con el fin de proceder a su inmediata cancelación. Mientras no se comuniquen tales hechos, IRONIA quedará eximido de cualquier responsabilidad que pudiera derivarse del uso indebido de los identificadores o contraseñas por terceros no autorizados.

                    El acceso, navegación y uso de www.ironia.tech es responsabilidad del Usuario, por lo que el Usuario se compromete a observar diligente y fielmente cualquier instrucción adicional impartida por IRONIA relativa al uso de www.ironia.tech y de sus contenidos.

                    Por tanto, el Usuario se obliga a usar los contenidos de forma diligente, correcta y lícita. En particular se compromete a abstenerse de:

                    Utilizar los contenidos con fines o efectos contrarios a la ley, a la moral, a las buenas costumbres generalmente aceptadas o al orden público;

                    Reproducir o copiar, distribuir, permitir el acceso del público a través de cualquier modalidad de comunicación pública, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido;

                    Emplear los contenidos y, en particular, la información de cualquier clase obtenida a través de la página o de los servicios para remitir publicidad, comunicaciones con fines de venta directa o con cualquier otra clase de finalidad comercial, mensajes no solicitados dirigidos a una pluralidad de personas con independencia de su finalidad, así como a abstenerse de comercializar o divulgar de cualquier modo dicha información.
                </p>
                <p>
                    6. LICENCIA SOBRE LAS COMUNICACIONES
                </p>
                <p>
                    En el caso de que el Usuario envíe información de cualquier tipo a www.ironia.tech, él mismo declara, garantiza y acepta:
                </p>
                <p>
                    i) que tiene derecho a hacerlo libremente,
                </p>
                <p>
                    ii) que dicha información no infringe ningún derecho de propiedad intelectual, de marca, de patente, secreto comercial, o cualquier otro derecho de tercero,
                </p>
                <p>
                    iii) que dicha información no tiene carácter confidencial y
                </p>
                <p>
                    iv) que dicha información no es perjudicial para terceros.
                </p>
                <p>
                    El Usuario reconoce asumir la responsabilidad y dejará indemne a IRONIA por cualquier comunicación que suministre personalmente o a su nombre, alcanzando dicha responsabilidad sin restricción alguna a la exactitud, legalidad, originalidad y titularidad de la misma.

                    La aceptación de las presentes condiciones generales implica la cesión en exclusiva por parte del Usuario de cualquier derecho de explotación de propiedad intelectual o industrial que el Usuario pudiera ostentar sobre las comunicaciones que realice a través de www.ironia.tech.
                </p>
                <p>
                    7. RESPONSABILIDADES Y GARANTÍAS
                </p>
                <p>
                    IRONIA no garantiza la licitud, fiabilidad, utilidad, veracidad o exactitud de los servicios o de la información que se presten a través de www.ironia.tech.

                    En consecuencia, IRONIA no garantiza ni se hace responsable de: i) la continuidad de los contenidos de www.ironia.tech; ii) la ausencia de errores en dichos contenidos ni la corrección de cualquier defecto que pudiera ocurrir; iii) la ausencia de virus y/o demás componentes dañinos en www.ironia.tech o en el servidor que lo suministra; iv) la invulnerabilidad de www.ironia.tech y/o la inexpugnabilidad de las medidas de seguridad que se adopten en el mismo; v) la falta de utilidad o rendimiento de los contenidos de www.ironia.tech; vi) los daños o perjuicios que cause, a sí mismo o a un tercero, cualquier persona que infringiera las condiciones, normas e instrucciones que IRONIA establece en www.ironia.tech o a través de la vulneración de los sistemas de seguridad de www.ironia.tech. No obstante, IRONIA declara que ha adoptado todas las medidas necesarias, dentro de sus posibilidades y del estado de la tecnología, para garantizar el funcionamiento de www.ironia.tech y evitar la existencia y transmisión de virus y demás componentes dañinos a los Usuarios.
                </p>
                <p>
                    8. ENLACES
                </p>
                <p>
                    En www.ironia.tech el Usuario podrá encontrar enlaces a otras páginas web mediante diferentes botones, links, banners, etc., los cuales son gestionados por terceros. IRONIA no tiene facultad ni medios humanos ni técnicos para conocer, controlar ni aprobar toda la información, contenidos, productos o servicios facilitados por otros sitios web a los que se establecen enlaces desde www.ironia.tech. En consecuencia, IRONIA no puede asumir ningún tipo de responsabilidad por cualquier aspecto relativo a la página web a la que se establece un enlace desde www.ironia.tech, en concreto, a título enunciativo y no taxativo, sobre su funcionamiento, acceso, datos, información, archivos, calidad y fiabilidad de sus productos y servicios, sus propios enlaces y/o cualquiera de sus contenidos en general.

                    El establecimiento de cualquier tipo de enlace por parte de www.ironia.tech a otro sitio web ajeno no implica que exista algún tipo de relación, colaboración o dependencia entre IRONIA y el responsable del sitio web ajeno. Enlaces en otros sitios web con destino a www.ironia.tech.

                    Si cualquier Usuario, entidad o sitio web deseara establecer algún tipo de enlace con destino a www.ironia.tech deberá atenerse a las siguientes estipulaciones:

                    El enlace se podrá dirigir a la Página Principal o a cualquier otra de las integradas en ironía.tech.

                    El enlace debe ser absoluto y completo, es decir, debe llevar al Usuario, mediante un clic, a la propia dirección URL de IRONIA ironia.tech o a las URLs correspondientes a las páginas que desee enlazar, y debe abarcar completamente toda la extensión de la pantalla de www.ironia.tech. En ningún caso, salvo que IRONIA lo autorice de manera expresa y por escrito, el sitio web que realiza el enlace podrá reproducir, de cualquier manera, www.ironia.tech, incluirlo como parte de su web o dentro de uno de sus “frames” o crear un “browser” sobre cualquiera de las páginas de www.ironia.tech.

                    En la página que establece el enlace no se podrá declarar de ninguna manera que IRONIA ha autorizado tal enlace, salvo que lo haya hecho expresamente y por escrito. Si la entidad que realiza el enlace desde su página a ironía.tech correctamente deseara incluir en su página web la marca, denominación, nombre comercial, rótulo, logotipo, slogan o cualquier otro tipo de elemento identificativo de IRONIA y/o de www.ironia.tech, deberá contar previamente con la correspondiente autorización expresa y por escrito.

                    IRONIA no autoriza el establecimiento de un enlace a ironía.tech desde aquellas páginas web que contengan materiales, información o contenidos ilícitos, ilegales, degradantes, obscenos, y en general, que contravengan la moral, el orden público o las normas sociales generalmente aceptadas.

                    IRONIA no tiene facultad ni medios humanos y técnicos para conocer, controlar ni aprobar toda la información, contenidos, productos o servicios facilitados por otros sitios web que tengan establecidos enlaces con destino a www.ironia.tech. IRONIA no asume ningún tipo de responsabilidad por cualquier aspecto relativo a www.ironia.tech que establece ese enlace con destino a www.ironia.tech, en concreto, a título enunciativo y no taxativo, sobre su funcionamiento, acceso, datos, información, archivos, calidad y fiabilidad de sus productos y servicios, sus propios enlaces y/o cualquiera de sus contenidos, en general. Servicios prestados por terceros a través de www.ironia.tech.

                    IRONIA no garantiza la licitud, fiabilidad y utilidad de los servicios prestados por terceros a través de www.ironia.tech o sobre los que IRONIA únicamente actúe como medio cauce publicitario.

                    IRONIA no será responsable de los daños y perjuicios de toda naturaleza causados por los servicios prestados por terceros a través de www.ironia.tech, y en particular, a título meramente enunciativo, los causados por:

                    El incumplimiento de la ley, la moral, las buenas costumbres o el orden público;

                    La incorporación de virus o cualquier otro código informático, archivo o programa que pueda dañar, interrumpir o impedir el normal funcionamiento de cualquier software, hardware o equipo de telecomunicaciones;

                    La infracción de los derechos de propiedad intelectual e industrial, de los secretos empresariales, o de compromisos contractuales de cualquier clase.

                    La realización de actos que constituyan publicidad ilícita, engañosa o desleal y, en general, que constituyan competencia desleal;

                    La falta de veracidad, exactitud, calidad, pertinencia y/o actualidad de los contenidos transmitidos, difundidos, almacenados, recibidos, obtenidos, puestos a disposición o accesibles;

                    La infracción de los derechos al honor, a la intimidad personal y familiar y a la imagen de las personas o, en general, cualquier tipo de derechos de terceros;

                    La inadecuación para cualquier tipo de propósito y la defraudación de las expectativas generadas, o los vicios y defectos que pudieran generarse en la relación con terceros;

                    El incumplimiento, retraso en el cumplimiento, cumplimiento defectuoso o terminación por cualquier causa de las obligaciones contraídas por terceros y contratos realizados con terceros.
                </p>
                <p>
                    9. DURACIÓN Y MODIFICACIÓN
                </p>
                <p>
                    IRONIA podrá modificar los términos y condiciones aquí estipulados, total o parcialmente, publicando cualquier cambio en la misma forma en que aparecen estas condiciones generales o a través de cualquier tipo de comunicación dirigida a los Usuarios.

                    La vigencia temporal de estas condiciones generales coincide, por lo tanto, con el tiempo de su exposición, hasta que sean modificadas total o parcialmente, momento en el cual pasarán a tener vigencia las condiciones generales modificadas.

                    Con independencia de lo dispuesto en las condiciones particulares, IRONIA podrá dar por terminado, suspender o interrumpir, en cualquier momento, y sin necesidad de preaviso, el acceso a los contenidos de la página, sin posibilidad por parte del Usuario de exigir indemnización alguna. Tras dicha extinción, seguirán vigentes las prohibiciones de uso de los contenidos expuestas anteriormente en las condiciones generales.
                </p>
                <p>
                    10. GENERALIDADES
                </p>
                <p>
                    Los encabezamientos de las distintas cláusulas son sólo informativos, y no afectarán, calificarán o ampliarán la interpretación de las condiciones generales.

                    En caso de existir discrepancia entre lo establecido en estas condiciones generales y las condiciones particulares de cada servicio específico, prevalecerá lo dispuesto en éstas últimas.

                    En el caso de que cualquier disposición o disposiciones de estas condiciones generales fuera(n) considerada(s) nula(s) o inaplicable(s), en su totalidad o en parte, por cualquier Juzgado, Tribunal u órgano administrativo competente, dicha nulidad o inaplicación no afectará a las otras disposiciones de las condiciones generales ni a las condiciones particulares de los diferentes servicios de IRONIA.

                    El no ejercicio o ejecución por parte de IRONIA de cualquier derecho o disposición contenida en estas condiciones generales no constituirá una renuncia al mismo, salvo reconocimiento y acuerdo por escrito por su parte.
                </p>
                <p>
                    11. JURISDICCIÓN
                </p>
                <p>
                    Las relaciones establecidas entre IRONIA y el Usuario se regirán por lo dispuesto en la normativa vigente acerca de la legislación aplicable y la jurisdicción competente. No obstante, para los casos en los que la normativa prevea la posibilidad a las partes de someterse a un fuero, IRONIA y el Usuario, con renuncia expresa a cualquier otro fuero que pudiera corresponderles, se someten a los Juzgados y Tribunales de la ciudad de Madrid, España.
                </p>
                <L.Wrapper>
                    <L.TitleContainer>
                        <L.Title>Política de Privacidad</L.Title>
                    </L.TitleContainer>
                </L.Wrapper>
                <p>
                    En virtud de lo dispuesto en la vigente normativa en materia de Protección de Datos de Carácter Personal, IRONIA pone en su conocimiento que:
                </p>
                <p>
                    PRIMERO. – La visita del sitio web de IRONIA en su dominio www.ironia.tech no implica que el usuario deba suministrar información alguna respecto a su identidad, no pudiendo ser asociada a un usuario concreto por la empresa. En el supuesto que se proporcionen datos de carácter de personal, estos datos serán recogidos y tratados respetando los derechos de los afectados y conforme a las limitaciones recogidas en la vigente normativa en materia de protección de datos. Los usuarios que faciliten datos de carácter personal aceptan de forma clara, exacta e inequívoca las presentes condiciones.
                </p>
                <p>
                    SEGUNDO. – Los datos personales proporcionados por el usuario a través del presente sitio web, se incorporarán a los ficheros de titularidad privada de IRONIA (marca comercial de SIMPLICITAS CAPITAL, S.L., sociedad agente financiero de DIAPHANUM VALORES, SV, S.A.U., empresa de servicios de inversión registrada en la CNMV con el número 265) quien además tiene la condición de responsable del tratamiento, con CIF B87806337 y domicilio social sito en la calle María de Molina 40, planta 7, 28006 Madrid, cuya finalidad será exclusivamente la gestión de la relación comercial de los clientes con la compañía y cumplimiento de la prestación de los servicios ofrecidos, y el envío de comunicaciones a diferentes personas de contacto. Los referidos datos personales proporcionados por el usuario serán comunicados, en caso de ser necesario, a administraciones públicas y a todas aquellas entidades con las que sea necesaria la comunicación con la finalidad de cumplir con la prestación de los servicios contratados, incluyendo transferencias de datos a entidades internacionales si fuera el caso. El hecho de no facilitar los datos a las entidades mencionadas podría implicar que no se pueda cumplir con la prestación de los servicios objeto del presente contrato.
                </p>
                <p>
                    TERCERO. – IRONIA ha desarrollado todos los sistemas e implementado las medidas técnicas y organizativas a su alcance, previstas en la normativa de protección de datos de carácter personal, para evitar la pérdida, mal uso, alteración, acceso no autorizado y sustracción de los datos de carácter personal facilitados por el usuario.
                </p>
                <p>
                    CUARTO. – El usuario garantiza que los datos que, en su caso, haya aportado a través del presente sitio web son verdaderos, exactos, completos y actualizados, siendo responsable de cualquier daño o perjuicio, directo o indirecto, que pudiera ocasionarse
                    como consecuencia del incumplimiento de tal obligación. Por su parte, IRONIA procederá a tratar los datos de manera lícita, leal, transparente, adecuada, pertinente, limitada, exacta y actualizada. Es por ello que IRONIA se compromete a adoptar todas las medidas razonables para que estos se supriman o rectifiquen sin dilación cuando sean inexactos.
                </p>
                <p>
                    QUINTO. – IRONIA se compromete al cumplimiento de su obligación de secreto con respecto a los datos de carácter personal recibidos a través del presente sitio web y al deber de tratarlos con confidencialidad. Los datos personales recabados no serán cedidos a terceros (salvo que medie obligación legal) sin el consentimiento expreso del titular de los mismos.
                </p>
                <p>
                    SEXTO. – De acuerdo con los derechos que le confiere la normativa vigente en protección de datos podrá ejercer los derechos de acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición al tratamiento de sus datos de carácter personal, así como del consentimiento prestado para el tratamiento de los mismos, dirigiendo su petición a la dirección postal indicada más arriba o al correo electrónico info@diaphanum.es. La retirada del consentimiento al tratamiento de datos conllevaría la resolución de los servicios contratados, ya que para poder prestar nuestros servicios es imprescindible poder tratarlos. Puede contactar con el Delegado de Protección de Datos dirigiéndose por escrito a la dirección de correo dpo.cliente@conversia.es o al teléfono 902877192. Asimismo podrá dirigirse a la Autoridad de Control competente (Agencia Española de Protección de Datos <a href="https://www.aepd.es" target="_blank" rel="noopener noreferrer">www.aepd.es</a>) para presentar la reclamación que considere oportuna.
                </p>
                <p>
                    SÉPTIMO. – IRONIA, una vez finalizada la relación con el usuario, conservará los datos durante el tiempo imprescindible para cumplir las obligaciones legales vigentes. A su vez, procederá a la cancelación de los datos recogidos cuando dejen de ser necesarios a la finalidad para la que fueron recabados.
                </p>
                <L.Wrapper>
                    <L.TitleContainer>
                        <L.Title>Política de Cookies</L.Title>
                    </L.TitleContainer>
                </L.Wrapper>
                <p>
                    Utilizamos cookies para facilitar el uso de nuestra página web. Las cookies son pequeños ficheros de información cuya función principal es almacenar y recuperar información sobre los hábitos de navegación de un usuario o su equipo. También pueden llegar a permitirnos reconocerle como usuario y así mejorar consecuentemente el proceso de navegación. Las cookies que utilizamos no almacenan dato personal alguno, ni ningún tipo de información que pueda identificarle. Para continuar sin cambios en la configuración de las cookies, simplemente continúe en la página web. Puede controlar el uso de cookies aceptando o rechazando su instalación mediante la configuración del navegador. En caso de no hacerlo, entendemos que está aceptando el uso de las cookies. Puede obtener más información sobre las cookies y su uso en aboutcookies.org. Los tipos de cookies que utilizamos son los siguientes:
                </p>
                <p>
                    Propias: aquellas que se envían al terminal del usuario desde un equipo gestionado por el dominio al que el usuario accede y del que solicita el servicio.
                </p>
                <p>
                    De terceros: aquellas que se envían al terminal de usuario desde un equipo gestionado por un tercero, distinto al que trata los datos obtenidos a través de la cookie.
                </p>
                <p>
                    De sesión: aquellas cuya temporalidad está vinculada al tiempo que el usuario accede a la web.
                </p>
                <p>
                    Persistentes: aquellas que se almacenan en el terminal durante un determinado tiempo, definido por el responsable de la cookie.
                </p>
                <p>
                    Técnicas: aquellas que permiten la navegación a través de la web y la utilización de las diferentes opciones o servicios.
                </p>
                <p>
                    Personalización: aquellas que permiten al usuario predefinir ciertas características generales de su terminal.
                </p>
                <p>
                    Análisis: aquellas que permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios en las webs a las que están vinculadas.
                </p>
                <p>
                    La presente política de cookies se aplica a todas las páginas web de IRONIA. Con su utilización se pretende adquirir un mayor conocimiento sobre la actividad de los usuarios, con ánimo de mejorar y facilitar nuestro servicio online.
                </p>
            </React.Fragment>
        </DocumentTitle>
    );
}

export default Legal;
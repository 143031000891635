import * as L from '../styles/DocumentTableStyles';
import { RouteComponentProps } from 'react-router';
import TabComponent from './TabComponent';

interface RouteParams {};
interface Props extends RouteComponentProps<RouteParams>{};

export const DocumentationProfile : React.FunctionComponent<Props> = (props, context) =>
{
    return(
        <L.Wrapper>
            <L.TitleContainer>
                <L.Title>Documentación contractual firmada</L.Title>
            </L.TitleContainer>
            <TabComponent/>
        </L.Wrapper>
    );
};
import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import graphTheme from '../../graphTheme';
import { Spinner, SpinnerSize } from "@fluentui/react";
import * as L from './ScatterHighstockStyles';

type Props =
{
    series: any,
    defaultPeriod: string,
    allPeriods: string[],
    doubleFund: boolean
}

const ScatterHighStock: React.FC<Props> = ({series, defaultPeriod, allPeriods, doubleFund}) =>
{
    const [rollingPercent, setRollingPercent] = React.useState<number>(-1);
    const [secondFundRollingPercent, setSecondFundRollingPercent] = React.useState<number>(-1);
    const [fundComparerRollingPercent, setFundComparerRollingPercent] = React.useState<number>(-1);
    const [pointsAbove, setPointsAbove] = React.useState<number>(0);
    const [secondPointsAbove, setSecondPointsAbove] = React.useState<number>(0);
    const [fundComparer, setFundComparer] = React.useState<number>(0);
    const [period, setPeriod] = React.useState<string>(defaultPeriod);
    // @ts-ignore
    Highcharts.setOptions(graphTheme);

    const allData = React.useMemo(() =>
    {
        var data: any[] = [];
        return data;
    }, []);

    React.useEffect(() =>
    {
        if(doubleFund === false)
        {
            allData.length = 0;
            var sum = 0;
            series[2].data = series[0].data.filter((obj: any) => obj.period === period).slice(-12);
            
            for(let i=0; i<series.length; i++)
            {
                var dataFiltered = [];
                let type = series[i].type;
                let color = series[i].color;
        
                for(let j=0; j<series[i].data.length; j++)
                {
                    if(series[i].data[j].period === period)
                    {
                        dataFiltered.push(
                        {
                            x: series[i].data[j].x,
                            y: series[i].data[j].y
                        });
                        
                        if(i===0 && series[i].data[j].y >= series[i].data[j].x)
                        {
                            sum++;
                        }
                    }
                }
    
                if(i === 0)
                {
                    setPointsAbove(sum);
                }
        
                allData.push(
                {
                    name: series[i].fundName,
                    data: dataFiltered,
                    type: type,
                    color: color,
                    marker:
                    {
                        symbol: 'circle',
                        radius: i === 2 ? 3 : 2
                    }
                });
            }
        }
        else
        {
            if(series.length > 3)
            {
                allData.length = 0;
                var sumDouble = 0;
                var sumSecondFund = 0;
                var sumVSFund = 0;
                series[3].data = series[0].data.filter((obj: any) => obj.period === period).slice(-12);
                series[4].data = series[2].data.filter((obj: any) => obj.period === period).slice(-12);
                
                for(let i=0; i<series.length; i++)
                {
                    var dataDoubleFiltered = [];
                    let type = series[i].type;
                    let color = series[i].color;
            
                    for(let j=0; j<series[i].data.length; j++)
                    {
                        if(series[i].data[j].period === period)
                        {
                            dataDoubleFiltered.push(
                            {
                                x: series[i].data[j].x,
                                y: series[i].data[j].y
                            });
                            
                            if(i===0 && series[i].data[j].y >= series[i].data[j].x)
                            {
                                sumDouble++;
                            }
                            if(i===2 && series[i].data[j].y >= series[i].data[j].x)
                            {
                                sumSecondFund++;
                            }
                            if(i===2 && series[i].data[j].y >= series[0].data[j].y)
                            {
                                //Comprobación de Segundo Fondo vs Primer Fondo
                                sumVSFund++;
                            }
                            
                        }
                    }
        
                    if(i===0)
                    {
                        setPointsAbove(sumDouble);
                    }
                    if(i===2)
                    {
                        setSecondPointsAbove(sumSecondFund);
                        setFundComparer(sumVSFund);
                    }
            
                    allData.push(
                    {
                        name: series[i].fundName,
                        data: dataDoubleFiltered,
                        type: type,
                        color: color,
                        marker:
                        {
                            symbol: 'circle',
                            radius: (i === 3 || i === 4) ? 3 : 2
                        }
                    });
                }
            }
        }

    }, [allData, series, period, doubleFund]);
    
    React.useEffect(() =>
    {
        if(doubleFund === true)
        {
            if(allData.length > 0)
            {
                var perc = (pointsAbove/allData[0].data.length) * 100;
                var secondPerc = (secondPointsAbove/allData[0].data.length) * 100;
                var fundVsFund = (fundComparer/allData[0].data.length) * 100;
                setRollingPercent(perc);
                setSecondFundRollingPercent(secondPerc);
                setFundComparerRollingPercent(fundVsFund);
            }
        }
        else
        {
            if(allData.length > 0)
            {
                var percentage = (pointsAbove/allData[0].data.length) * 100;
                setRollingPercent(percentage);
            }
        }
    }, [doubleFund, pointsAbove, secondPointsAbove, fundComparer, allData]);

    const optionsScatter =
    {
        chart: {type: 'scatter'},
        title:
        {
            text: ''
        },
        xAxis:
        {
            title:
            {
                text: 'Índice'
            },
            resize:
            {
                enabled: true
            },
            plotLines:
            [
                {
                    color: 'gray',
                    width: 1.5,
                    value: 0
                }
            ],
            labels:
            {
                formatter(this: Highcharts.AxisLabelsFormatterContextObject): string
                {
                    return this.value+'%';
                }
            }
        },
        yAxis:
        {
            title:
            {
                text: 'Fondo'
            },
            resize:
            {
                enabled: true
            },
            plotLines:
            [
                {
                    color: 'gray',
                    width: 2,
                    value: 0
                }
            ],
            labels:
            {
                formatter(this: Highcharts.AxisLabelsFormatterContextObject): string
                {
                    return this.value + '%';
                }
            }
        },
        credits:
        {
            enabled: false,
        },
        legend:
        {
            enabled: true,
            itemStyle:
            {
                font: '14px !important Barlow, sans-serif',
                color: '#2A4143',
                fontWeight: 'normal'
            }
        },
        plotOptions:
        {
            scatter:
            {
                tooltip:
                {
                    headerFormat: '<b>{series.name}</b><br>',
                    pointFormatter(this: Highcharts.TooltipFormatterContextObject): string
                    {
                        var x = Highcharts.numberFormat(this.x, 2, ',', '.');
                        var y = Highcharts.numberFormat(this.y, 2, ',', '.');
                        return `Fondo: ${y}%, Índice: ${x}%`;
                    }
                }
            },
            line:
            {
                tooltip:
                {
                    headerFormat: '<b>Índice</b><br>',
                    pointFormatter(this: Highcharts.TooltipFormatterContextObject): string
                    {
                        var x = Highcharts.numberFormat(this.x, 2, ',', '.');
                        var name = this.series.name;
                        return `${name}: ${x}%`;
                    }
                }
            }
        },
        series: allData
    }

    if(series.length <= 0 || series[0].data.length <= 0)
    {
        return(
            <React.Fragment>
                <Spinner size={SpinnerSize.large} />
            </React.Fragment>
        );
    }
    else
    {
        return(
            <React.Fragment>
                <L.RowPeriodButtons className="ms-Grid-row">
                    <L.PeriodButtonContainer>
                        {allPeriods.length > 0 && allPeriods.map((element, index) =>
                        (
                            <React.Fragment key={index}>
                                <button className={period === element ? 'active' : ''} onClick={(event) =>
                                {
                                    event.stopPropagation();
                                    setTimeout(() => {setPeriod(element)}, 50);
                                }}>
                                    {element}
                                </button>
                            </React.Fragment>
                        ))}
                    </L.PeriodButtonContainer>
                </L.RowPeriodButtons>
                <HighchartsReact highcharts={Highcharts} options={optionsScatter} />
                {doubleFund === true &&
                (
                    <React.Fragment>
                        {rollingPercent !== -1 && secondFundRollingPercent !== -1 && fundComparerRollingPercent !== -1 &&
                        (
                            <L.RatioRollingComparerMessage>
                                {rollingPercent >= 50 &&
                                (
                                    <React.Fragment>
                                        El primer fondo supera o iguala al índice en el&nbsp;
                                        <L.SpanRatioRolling style={{color: "#4FA72C"}}>
                                            {rollingPercent === 100 ? rollingPercent : rollingPercent.toFixed(2).replace('.', ',')}%
                                        </L.SpanRatioRolling>
                                        &nbsp;de los periodos.
                                    </React.Fragment>
                                )}
                                {rollingPercent < 50 &&
                                    <React.Fragment>
                                        El primer fondo supera o iguala al índice en el&nbsp;
                                        <L.SpanRatioRolling style={{color: "#CC214F"}}>
                                            {rollingPercent === 0 ? rollingPercent : rollingPercent.toFixed(2).replace('.', ',')}%
                                        </L.SpanRatioRolling>
                                        &nbsp;de los periodos.
                                    </React.Fragment>
                                }
                                <br />
                                {secondFundRollingPercent >= 50 &&
                                (
                                    <React.Fragment>
                                        El segundo fondo supera o iguala al índice en el&nbsp;
                                        <L.SpanRatioRolling style={{color: "#4FA72C"}}>
                                            {secondFundRollingPercent === 100 ? secondFundRollingPercent : secondFundRollingPercent.toFixed(2).replace('.', ',')}%
                                        </L.SpanRatioRolling>
                                        &nbsp;de los periodos.
                                    </React.Fragment>
                                )}
                                {secondFundRollingPercent < 50 &&
                                    <React.Fragment>
                                        El segundo fondo supera o iguala al índice en el&nbsp;
                                        <L.SpanRatioRolling style={{color: "#CC214F"}}>
                                            {secondFundRollingPercent === 0 ? secondFundRollingPercent : secondFundRollingPercent.toFixed(2).replace('.', ',')}%
                                        </L.SpanRatioRolling>
                                        &nbsp;de los periodos.
                                    </React.Fragment>
                                }
                                <br />
                                {fundComparerRollingPercent >= 50 &&
                                (
                                    <React.Fragment>
                                        El segundo fondo supera o iguala al primer fondo en el&nbsp;
                                        <L.SpanRatioRolling style={{color: "#4FA72C"}}>
                                            {fundComparerRollingPercent === 100 ? fundComparerRollingPercent : fundComparerRollingPercent.toFixed(2).replace('.', ',')}%
                                        </L.SpanRatioRolling>
                                        &nbsp;de los periodos.
                                    </React.Fragment>
                                )}
                                {fundComparerRollingPercent < 50 &&
                                    <React.Fragment>
                                        El segundo fondo supera o iguala al primer fondo en el&nbsp;
                                        <L.SpanRatioRolling style={{color: "#CC214F"}}>
                                            {fundComparerRollingPercent === 0 ? fundComparerRollingPercent : fundComparerRollingPercent.toFixed(2).replace('.', ',')}%
                                        </L.SpanRatioRolling>
                                        &nbsp;de los periodos.
                                    </React.Fragment>
                                }
                            </L.RatioRollingComparerMessage>
                        )}
                    </React.Fragment>
                )}
                {doubleFund === false &&
                (
                    <React.Fragment>
                        {rollingPercent !== -1 &&
                        (
                            <L.RatioRollingComparerMessage>
                                {rollingPercent >= 50 &&
                                (
                                    <React.Fragment>
                                        El fondo supera o iguala al índice en el&nbsp;
                                        <L.SpanRatioRolling style={{color: "#4FA72C"}}>
                                            {rollingPercent === 100 ? rollingPercent : rollingPercent.toFixed(2).replace('.', ',')}%
                                        </L.SpanRatioRolling>
                                        &nbsp;de los periodos.
                                    </React.Fragment>
                                )}
                                {rollingPercent < 50 &&
                                    <React.Fragment>
                                        El fondo supera o iguala al índice en el&nbsp;
                                        <L.SpanRatioRolling style={{color: "#CC214F"}}>
                                            {rollingPercent === 0 ? rollingPercent : rollingPercent.toFixed(2).replace('.', ',')}%
                                        </L.SpanRatioRolling>
                                        &nbsp;de los periodos.
                                    </React.Fragment>
                                }
                            </L.RatioRollingComparerMessage>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default ScatterHighStock;
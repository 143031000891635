export default function groupFundsByFamily(funds)
{
    let list = [];
    for(let i = 0; i < funds.length; i++)
    {
        let found = false;
        for(let b = 0; b < list.length; b++)
        {
            if(list[b].family === funds[i].family)
            {
                found = true;
                list[b].children.push(funds[i]);
                break;
            }
        }
        if(!found)
        {
            list.push({family: funds[i].family, children: [funds[i]]});
        }
    }
    return list;
}
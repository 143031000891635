import { useContext, useEffect, useState } from 'react';
import * as L from './layout.styles';
import { DefaultButton, Spinner, SpinnerSize } from '@fluentui/react';
import { RouteComponentProps, useHistory } from "react-router-dom";
import { ThemeContext } from '@fluentui/react-theme-provider';
import sharedPortfoliosService, { IGetSharedPortfoliosStatsParams } from "../../services/SharedPortfoliosService";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { SearchBox } from '@fluentui/react';
import useSWR from 'swr';
import Auth from '../../Auth/Auth';
import { percentFormatter } from '../../utils/numberFormatter';

interface SharedPortfoliosRankingProps extends RouteComponentProps {}

const colors: {[key: string]: string} =
{
    "Renta Variable": "#4284E6",
    "Renta Variable Otros": "#0244A6",
    "Alternativos": "#0F5257",
    "No categorizado": "#D4DFE3",
    "Renta Fija": "#FFBD70",
    "Mixtos": "#D64D73",
    "Activo Monetario": "#A4508B",
    "Otros": "#50D6A4"
};

const getTypeColor = (type: string): string =>
{
    let color = colors[type]
    if(!color)
    {
        color = colors["Otros"];
    }
    return color;
};

export const SharedPortfoliosRankingScreen = (props: SharedPortfoliosRankingProps) =>
{
    const theme = useContext(ThemeContext);
    let history = useHistory();
    const [filter, setFilter] = useState<string>("");
    const [results, setResults] = useState<any[]>([]);
    const [series, setSeries] = useState<any[]>([]);
    const [legend, setLegend] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [resultsPerPage, setResultsPerPage] = useState(20); // 30
    const isActiveManagementPlan: boolean = Auth.isActiveManagementPlan();

    let params: IGetSharedPortfoliosStatsParams =
    {
        offset: page * resultsPerPage,
        resultsPerPage,
    };

    if(filter.length > 2)
    {
        params.filter = filter;
    }

    const {data} = useSWR(JSON.stringify(params), sharedPortfoliosService.getSharedPortfoliosStats);

    useEffect(() =>
    {
        setLoading(() => true);
    }, [filter, resultsPerPage, page]);

    useEffect(() =>
    {
        let legend: string[] = [];
        var seriesAux: any[] = [];
        let resultsAux = data?.data?.results;

        if(Array.isArray(resultsAux))
        {
            resultsAux.forEach(item =>
            {
                var serie: any[] = [];
                if(item.assetsAllocation)
                {
                    var types = Object.keys(item.assetsAllocation);
                    types.forEach(type =>
                    {
                        if(!legend.includes(type))
                        {
                            legend.push(type);
                        }
                        if(!serie.find(s => s.name === type))
                        {
                            serie.push(
                            {
                                name: type,
                                data: [item.assetsAllocation[type]]
                            });
                        }
                        else
                        {
                            serie.find(s => s.name === type).data[0] += item.assetsAllocation[type];
                        }
                    })
                }

                seriesAux.push(serie.sort((serieItemA, serieItemB) => serieItemA.data[0] - serieItemB.data[0]));
            });
        }

        setLoading(() => false);
        setTotal(data?.data?.summary?.total || 0);
        setLegend(legend);
        setSeries(seriesAux);
        setResults(() => resultsAux || []);
    }, [data]);

    const options: Highcharts.Options =
    {
        title:
        {
            text: '',
        },
        //width: width,
        xAxis: {visible: false},
        yAxis: {visible: false},
        legend: {enabled: false},
        exporting: {enabled: false},
        credits: {enabled: false},
        chart:
        {
            type: 'bar',
            margin: 0,
            height: 24,
            backgroundColor: "transparent",
            borderColor: "transparent"
        },
        tooltip:
        {
            headerFormat: "",
            valueSuffix: '%',
            outside: true,
        },
        plotOptions:
        {
            bar:
            {
                stacking: 'percent',
                groupPadding: 0,
                pointPadding: 0,
                borderRadius: 4,
                borderColor: "transparent",
            },
        },
    };

    return(
        <L.Main theme={theme}>
            <L.TitleContainer>
                <h2>Ránking de carteras</h2>
            </L.TitleContainer>
            <L.InputSearchContainer>
                <SearchBox placeholder="Buscar..."
                    onChange={(event, newValue) =>
                    {
                        let val: string = newValue || "";
                        setPage(() =>
                        {
                            setFilter(val);
                            return 0;
                        })
                    }}
                />
            </L.InputSearchContainer>
            <L.SeparatorTop />
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-xl12 ms-xxl9">
                    <L.CardContainer theme={theme}>
                        <L.CardBody>
                            <L.TableContainer theme={theme}>
                                <L.Table cellPadding={0} cellSpacing={0} width={100}>
                                    <thead>
                                        <tr>
                                            <th className="column-with-number">#</th>
                                            <th className="column-with-number">Rentabilidad<br />(90 días)</th>
                                            <th>Cartera</th>
                                            <th className="column-grow">Composición</th>
                                            <th className="column-with-number">Ranking de popularidad</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Array.isArray(results) && results.map((row, index) =>
                                            {
                                                return(
                                                    <tr key={index}>
                                                        <td className="column-with-number">{page*resultsPerPage+index+1}</td>
                                                        <td className="column-with-number">
                                                            {(typeof row?.profitability == 'number' ? percentFormatter.format(row.profitability/100) : '-')}
                                                        </td>
                                                        <td>{row.sharedPortfolio?.name}</td>
                                                        <td>
                                                            <HighchartsReact highcharts={Highcharts}
                                                                options={
                                                                {
                                                                    ...options,
                                                                    series: series[index],
                                                                    colors: Object.entries(colors)
                                                                        .filter((entry) => Object.keys(row.assetsAllocation).includes(entry[0]))
                                                                        .sort((a, b) => Object.keys(row.assetsAllocation).indexOf(b[0]) - (Object.keys(row.assetsAllocation).indexOf(a[0])))
                                                                        .map(entry => entry[1])
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="column-with-number">{row.popularityPosition}</td>
                                                        <td>
                                                            <DefaultButton onClick={() => history.push(`/sharedPortfolios/${row.sharedPortfolio.id}`)} className="button mini-primary-button">
                                                                {isActiveManagementPlan ? 'Ver' : 'Copiar'}
                                                            </DefaultButton>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </L.Table>
                            </L.TableContainer>
                            {(loading || !data) && <L.LoadingContainer>
                                <Spinner size={SpinnerSize.large} className="button-spinner" />
                            </L.LoadingContainer>}
                            <L.PaginationContainer>
                                <L.ResultsPerPage>
                                    <span>Resultados por página</span>
                                    <select defaultValue={resultsPerPage} onChange={(event) =>
                                        {
                                            setPage(() =>
                                            { // Si cambian los resultados por página, vuelve a la página 0.
                                                setResultsPerPage(parseInt(event.target.value));
                                                return 0;
                                            })
                                        }}
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={30}>30</option>
                                        <option value={40}>40</option>
                                        <option value={50}>50</option>
                                    </select>
                                </L.ResultsPerPage>
                                <L.PageCount>
                                    <span>{`${page + 1}-${resultsPerPage} de ${Math.ceil(total/resultsPerPage)}`}</span>
                                </L.PageCount>
                                <L.PageButtons>
                                    <button onClick={() =>
                                        {
                                            if(page > 0)
                                            {
                                                setPage(page - 1);
                                            }
                                        }}
                                        className={(page === 0 ? 'disabled' : '')}
                                    >
                                        <i className={'ironia-icon keyboard-arrow-left'} />
                                    </button>
                                    <button onClick={() =>
                                        {
                                            if(page < Math.ceil(total/resultsPerPage)-1)
                                            {
                                                setPage(page + 1);
                                            }
                                        }}
                                        className={(Math.ceil(total/resultsPerPage) === (page+1) ? 'disabled' : '')}
                                    >
                                        <i className={'ironia-icon keyboard-arrow-right'} />
                                    </button>
                                </L.PageButtons>
                            </L.PaginationContainer>
                        </L.CardBody>
                    </L.CardContainer>
                </div>
                <div className="column-legend ms-Grid-col ms-sm12 ms-xl12 ms-xxl3">
                    <ul>
                        {legend && legend.length > 0 && legend.sort().map((type, index) =>
                        {
                            return <L.LegendLi key={index}>
                                <L.ColorBox color={getTypeColor(type)}></L.ColorBox> {type}
                            </L.LegendLi>
                        })}
                    </ul>
                    {(loading || !data) && <L.LoadingContainer>
                        <Spinner size={SpinnerSize.large} className="button-spinner" />
                    </L.LoadingContainer>}
                </div>
            </div>
        </L.Main>
    );
};
import styled from 'styled-components';
import { Toggle as Tgl } from '@fluentui/react';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 80px;
    justify-content: center;
    align-items: flex-start;
`;

export const Title = styled.h1`
    margin-bottom: 1em;
    cursor: default;
`;

//Featured Managers Container
export const FMContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 130px;
  margin-bottom: 10px;
`;

export const FMTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 1 10px;
  margin-bottom: 10px;
`;

export const FMTitle = styled.h4`
  font-size: 12px;
  font-weight: 500;
  margin: 5px 0 0 0px;
  color: #95a0a1;
`;

export const FMCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  overflow-x: auto;
  @media (min-width: 1440px) {
    justify-content: space-between;
  }
`;

export const FilterBadgeContainer = styled.div`
  display: flex;
  margin: 5px 10px 10px 10px;
  flex-wrap: wrap;
  gap: 10px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: flex-start;
`;

export const FilterContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5px 0 0;

  &.short-select button {
    padding: 7px 20px 5px;

    span:first-of-type {
      position: relative;
      top: -2px;
    }
  }

  > div span {
    border-radius: 100px;
  }

  i {
    color: white;
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.palette.white};
  padding: 10px 12px;
  border-radius: 100px;
  border: 1px solid rgb(188, 200, 201);
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.palette.lightBlack} !important;
`;

export const ToggleTitle = styled.span`
  margin-right: 20px;
  margin-left: 10px;
  height: 100%;
`;

export const Toggle = styled(Tgl)`
  margin: 0;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TableWidthManager = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px;
  box-shadow: 0px 0px 16px rgba(8, 84, 117, 0.08);
  border-radius: 16px;
  min-height: 500px;
`;

export const TableFilterContainer = styled.div`
    display: flex;
    flex: 0 1 50px;
    margin-bottom: 2em;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;

    > *
    {
        vertical-align: top;
        flex-direction: column;
        display: block;
    }
`;

// TR = Table Results
export const TRCountContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
`;
export const TRTitle = styled.span`
    font-size: 19px;
    font-weight: 500;
    cursor: default;
`;
export const TRCount = styled.span`
  color: #94a1a1;
  margin-left: 5px;
`;

export const InputSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  justify-content: center;

  &.jcl {
    justify-content: left;
  }

  > div,
  input {
    border-radius: 100px;
    border: 1px solid lightGrey;
    padding-left: 10px;

    :hover {
      border: 1px solid lightGrey;
    }
    ::after {
      border: none;
    }
  }
`;

export const EditColumnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  > button {
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  border-bottom: 1px solid lightGrey;
  background: ${(props) => props.theme.palette.white};
  table {
    border-radius: 16px;
  }
  thead {
    background: ${(props) => props.theme.palette.tertiary};
  }
  > div {
    margin: 0;
  }

  .is-selected {
    a,
    div {
      color: white !important;
    }
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  gap: 20px;

  span {
    color: #95a0a1;
    border-radius: 16px;
    line-height: 28px;
  }

  > * {
    vertical-align: middle;
  }
`;

export const ProductsPerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  height: 100%;

  select {
    min-width: 70px;
    border: none;
    border-bottom: 1px solid black;
    padding: 5px 0;
  }
`;

export const PageCount = styled.div`
  display: flex;
`;

export const PageButtons = styled.div`
  button {
    cursor: pointer;
    border: 0;
    color: #CC214F;

    &.disabled {
      cursor: default;
      color: #F1BAC9;
    }
  }
`;

export const FilterLabelContainer = styled.div`
  text-align: left;
`;
export const FilterLabel = styled.label`
  color: grey;
  font-size: 12px;
  margin-top: 5px;
  width: 100%;
  padding-left: 10px;
  display: block;
`;

import React, { useState } from "react";
import styled from "styled-components";

const TabBarStyled = styled.div`
    > ul
    {
        display: flex;
        border-bottom: 1px solid #e2e7e9;
    }
    > li
    {
        margin: 0;
        margin-right: 0.5rem;
    }
    button.button-tab
    {
        background-color: transparent;
        border: none;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 0.2rem solid;
        border-color: transparent;
        border-radius: 0;
        padding: 0.5rem 1.5rem;
        cursor: pointer;
    }
`;

type TabBarProps =
{
    tabs:
    {
        label: string;
        Component: React.FC;
    }[];
};

export const TabBar: React.FC<TabBarProps> = ({ tabs }) =>
{
    const [isActive, setIsActive] = useState(0);
    return (
        <TabBarStyled>
            <ul>
                {tabs.map((item, i) => (
                    <li key={i}>
                        <button
                            className="button-tab"
                            onClick={() => setIsActive(i)}
                            style={
                            {
                                color: isActive === i ? "#cc214f" : undefined,
                                borderColor: isActive === i ? "#cc214f" : undefined,
                            }}
                        >
                            {item.label}
                        </button>
                    </li>
                ))}
            </ul>
            <div>
                {tabs.map((Item, i) => (
                    <div key={i}>{i === isActive && <Item.Component key={i} />}</div>
                ))}
            </div>
        </TabBarStyled>
    );
};
export const translateOptionPeriod = (item: string) =>
{
    switch(item)
    {
        case "Daily":
            return "Diario";
        case "Weekly":
            return "Semanal";
        case "EveryTwoWeeks":
            return "Quincenal";
        case "Monthly": default:
            return "Mensual";
        case "EveryTwoMonths":
            return "Bimestral";
        case "Quarterly":
            return "Trimestral";
        case "SemiAnnual":
            return "Semestral";
        case "Annual":
            return "Anual";
    }
}
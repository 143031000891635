import styled from 'styled-components';
import { Button } from "../../components/buttons/MainButton";
import { Separator } from '@fluentui/react';

export const Main = styled.main`
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;

    width: 100%;
    margin: 0;

    @media (min-width: 1280px)
    {
        max-width: 1450px;
        margin: 0 auto;
    }

    .button-spinner
    {
        float: left;
        margin-right: 10px;
    }

    & .restarted-ms-Grid > .ms-Grid-row
    {
        margin: 0 -24px !important;

        & > .ms-Grid-col
        {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    & .ms-Stack .ms-StackItem
    {
        width: 100%;
    }

    & .ms-Stack .ms-StackItem,
    & .investment-fund-finder .ms-ComboBox-container
    {
        margin-top: 24px;
    }

    & .ms-Stack .ms-StackItem:first-child,
    .investment-fund-finder .ms-ComboBox-container:first-child
    {
        margin-top: 0;
    }

    & .ms-Stack .ms-StackItem:not(:first-child)
    {
        margin-left: 0;
    }

    & .ms-ChoiceField
    {
        display: inline-flex;
        margin-right: 8px;
        background: ${props => props?.theme?.palette?.white || "#FFF"};
        border: 1px solid ${props => props?.theme?.palette?.neutralLighter || "#E9EFF1"};
        padding: 10px 16px;
        border-radius: 26px;
    }
`;
export const SeparatorTop = styled(Separator)`
    margin-bottom: 1em;
`;
export const SeparatorForm = styled(Separator)`
    margin-top: 17px;
    margin-bottom: 17px;
`;
export const OuterFooterArea = styled.div`
    display: flex;
    justify-content: flex-end;
`;
export const Text = styled.p`
    font-size: 18px;
    line-height: 24px;
    color: ${props => props?.theme?.palette?.lightBlack || "#556769"};
    margin-top: 0;
    margin-bottom: 0;
`;
export const TextSmall = styled.p`
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin: 0;
    margin-top: 32px;
    color: ${props => props?.theme?.palette?.neutralText || "#95A0A1"};
`;
export const BackButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    span
    {
        margin-right: 10px;
        cursor: pointer;
    }
`;
export const BackButton = styled.button`
    color: rgb(66,132,230);
    padding: 0;
    text-decoration: none;
    cursor: pointer;
    font-family: "Barlow";
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    border: 0px;
`;
export const DivRowActivePlan = styled.div`
    min-height: 20px;
`;
export const DivColActivePlan = styled.div`
`;
export const TextActivePlan = styled.h2``;
export const DivColActivePlanText = styled.div`
    padding-top: 12px;
    color: #CC214F;
    cursor: default;
`;
export const DivGridMain = styled.div``;
export const DivGridRow = styled.div``;
export const DivGridOriginCol = styled.div``;
export const DivGridTargetCol = styled.div``;
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: default;

    h2
    {
        margin: 0;
    }
`;
export const SubTitleContainer = styled.h3`
    margin: 0;
`;
export const CardContainer = styled.div`
    background-color: ${props => props?.theme?.palette?.tertiary || "#F3F7F9"};
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 20px 40px 40px 40px;
    height: auto;
`;
export const CardHeader = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 0;
    justify-content: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .small-text
    {
        margin-top: 6px;
    }
`;
export const CardHeaderH3 = styled.h3`
    margin: 0 !important;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    cursor: default;
`;
export const CardButton = styled(Button.Primary)`
    margin-top: 32px;
    margin-bottom: 0px;
`;
export const CardHeadingContainer = styled.div`
    margin-top: 10px;
`;
export const CardBody = styled.div`
    margin-top: 19px;
`;
export const OriginISIN = styled.input``;
export const OriginFundName = styled.input``;
export const Distributor = styled.input``;
export const TargetISIN = styled.input``;
export const TargetFundName = styled.input``;
export const AmountType = styled.input``;
export const Amount = styled.input``;
export const FieldFileCont = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 449px;
    height: 38.8px;
    margin-top: 6.4px;
`;
export const FileInput = styled.input`
    font-family: inherit;
    width: 100%;
    height: 100%;
    padding: 0 0 0 5px;
    border: 1px solid #95a0a1;
    border-radius: 5px;
    cursor: pointer;

    &::file-selector-button
    {
        cursor: pointer;
        -webkit-appearance: none;
        position: absolute;
        float: right;
        height: 90%;
        width: 133px;
        top: 2px;
        right: -9px;
        border: none;
        background-color: #cc214f;
        border-radius: 0 5px 5px 0;
        color: #ffffff;
    }
`;
export const Label = styled.label`
    margin-top: 12px;
    display: block;
    font-weight: 500;
    color: #556769;
    font-size: 14px;
`;
export const Error = styled.span`
    display: block;
    width: fit-content;
    margin: 5px 0 0;
    color: hsl(0, 100%, 63%);
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
`;
export const BodyTimeLine = styled.div`
    background-color: transparent;
    font-family: 'Muli', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    overflow: hidden;
    margin: 0;
`;
export const ContainerTimeLine = styled.div`
    text-align: center;
`;
export const ProgressContainerTimeLine = styled.div`
    display: flex;
    justify-content: space - between;
    position: relative;
    margin-bottom: 30px;
    max-width: 100 %;
    width: 350px;
    &::before
    {
        content: '';
        background - color: var(--line - border - empty);
        position: absolute;
        top: 50 %;
        left: 0;
        transform: translateY(-50 %);
        height: 4px;
        width: 100 %;
        z-index: -1;
    }
`;
export const ProgressTimeLine = styled.div`
    background-color: var(--line - border - fill);
    position: absolute;
    top: 50 %;
    left: 0;
    transform: translateY(-50 %);
    height: 4px;
    width: 0 %;
    z-index: -1;
    transition: 0.4s ease;
`;
export const CircleTimeLine = styled.div`
    background-color: #1f1f1f;
    color: #e2e2e2;
    border-radius: 50 %;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--line - border - empty);
    transition: 0.4s ease;
    &.active
    {
        border-color: var(--line - border - fill);
    }
`;
export const Table = styled.table`
    cursor: default;
    border-collapse: collapse;
    text-align: left;
    table-layout: fixed;
    border-radius: 16px;
    margin-top: 2ch;
`;
export const TableHead = styled.thead`
    cursor: default;
`;
export const TableTR = styled.tr`
    cursor: default;
    border-bottom: 2px solid #E9EFF2;
`;
export const TableTH = styled.th`
    cursor: default;
    padding: 10px 10px 10px 10px;
    font-size: 17px;
    border: 2px solid #E9EFF2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    
    :first-of-type
    {
        border-top-left-radius: 12px;
    }
    :last-of-type
    {
        border-top-right-radius: 12px;
    }
`;
export const TableBody = styled.tbody`
    cursor: default;
`;
export const TableTD = styled.td`
    cursor: default;
    padding: 0px 15px 0px 15px;
    word-break: break-word;
    font-size: 13px;
    border: 2px solid #E9EFF2;
    text-align: center;

    :first-of-type
    {
        border-top-left-radius: 12px;
    }
    :last-of-type
    {
        border-top-right-radius: 12px;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        text-align: center;
        transform: translateY(2px);
    }
`;
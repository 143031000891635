import { useEffect } from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import './SavingsPlans.sass';
import * as Style from './SavingsPlansConfiguringStyle';
import { ITableSPlansActive, SavingsPlansConfiguringTable } from './SavingsPlansConfiguringTable';

const SavingsPlansConfiguring = ({data, loading, error, reload}: {data?: ITableSPlansActive[], loading: boolean, error: any, reload: (reloadData: boolean) => void;}) =>
{
    var columns = ["Planes de Cartera", "Estado", "Fecha de Inicio", "Acciones"];

    useEffect(() =>
    {
        window.scrollTo(0, 0);
    }, []);

    if(error.message)
    {
        return(<div>Error: {// @ts-ignore
            error.message}</div>)
    }

    const handleReload = (reloadData: boolean) =>
    {
        if(reloadData && reload !== undefined)
        {
            reload(reloadData);
        }
	};

    return(
        <Style.Wrapper>
            <Style.Title>
            <h3 style={{paddingTop: "1.4em", paddingBottom: "0.5em", cursor: "default", marginBottom: 0}}>
                Mis planes de cartera en configuración
            </h3>
            </Style.Title>
            <Style.TableCont>
                <Style.SubTitle>Todos los planes en configuración actualmente</Style.SubTitle>
                {loading === true && (<Spinner size={SpinnerSize.large} />)}
                {loading === false &&
                (
                    <SavingsPlansConfiguringTable columnTitles={columns} reload={(reloadData) => {handleReload(reloadData)}} rowData={data as ITableSPlansActive[]} />
                )}
            </Style.TableCont>
        </Style.Wrapper>
    );
}

export default SavingsPlansConfiguring;
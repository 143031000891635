import { Separator } from 'office-ui-fabric-react/lib/Separator';
import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { DefaultButton, MessageBar, MessageBarType, Spinner, SpinnerSize } from '@fluentui/react';
import { ErrorResponse, ISignature } from '../../services/SignaturesService';
import { SignatureComponent } from "../Signature/SignatureComponent";
import { useHistory, useParams } from 'react-router';
import moment from "moment";
import OrderService, { OrderType } from '../../services/OrderService';
import { SignatureComponentWithoutCancel } from '../Signature/SignatureComponentWithoutCancel';

const PaymentAccountError = () =>
{
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(true);
    const [order, setOrder] = React.useState<OrderType>();
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [signatureCancelSign, setSignatureCancelSign] = React.useState<ISignature>();
    const [signatureAcceptSign, setSignatureAcceptSign] = React.useState<ISignature>();
    const [serviceError, setServiceError] = React.useState<ErrorResponse>();
    const [userConfirmation, setUserConfirmation] = React.useState({
        user: false,
        disclaimer: false,
    });
    const [waitingForResponse, setWaitingForResponse] = React.useState(false);
    

    const { idorder } = useParams<any>();

    const processExpirationDate = (signature: ISignature) =>
    {
        if (signature && typeof signature.expiresAt === "string")
        {
            signature.expiresAt = moment(signature.expiresAt).toDate();
        }
    };

    useEffect(() =>
    {
        if (order !== undefined)
        {
            var operationPhone = order.userIdentity?.operationPhone;
            if (operationPhone !== undefined)
            {
                setPhoneNumber(
                    "X".repeat(operationPhone.length - 3) +
                    operationPhone.slice(
                        operationPhone.length - 3,
                        operationPhone.length
                    )
                );
            }
        }
    }, [order]);


    const acceptSignature = async () =>
    {
        try
        {
            setIsLoading(true);
            const order = await OrderService.getOrder(idorder);
            const updatedOrder = await OrderService.patchAcceptOrder(order.id);
            setOrder(updatedOrder);
            setSignatureAcceptSign(updatedOrder.signatureAceptation);
        } catch (error: any)
        {
            setServiceError(
                error.message || "Hubo un error al procesar la solicitud."
            );
        } finally
        {
            setIsLoading(false);
        }
    };


    const cancelSignature = async () =>
    {
        try
        {
            setIsLoading(true);
            const order = await OrderService.getOrder(idorder);
            const updatedOrder = await OrderService.patchCancelOrder(order.id);
            setOrder(updatedOrder);
            setSignatureCancelSign(updatedOrder.signatureCancel);
        } catch (error: any)
        {
            setServiceError(
                error.message || "Hubo un error al procesar la solicitud."
            );
        } finally
        {
            setIsLoading(false);
        }
    };

    const cancelSign = (response: any) =>
    {
        if (order !== undefined && signatureCancelSign !== undefined)
        {
            OrderService.inespayCancelOrden(order)
                .then((response: any) =>
                {
                    if (response.errorMsg)
                    {
                        setServiceError({ status: true, message: response.errorMsg });
                    } else
                    {
                        history.push("/portfolio/orders");
                    }
                })
                .catch((error) => setServiceError({ status: true, message: error.response.data }))
        }
    };



    return (
        <DocumentTitle title='IronIA - Titularidad de Cuenta bancaria'>
            <React.Fragment>
                <div className="ms-Grid-row p10">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12">
                        <div className="section-row">
                            <br />
                            <div className="warning-container">
                                <div>
                                    <img
                                        src="/icons/information.svg"
                                        alt="Información"
                                    />
                                </div>
                                <p><i> Atención.</i></p>
                                <p>
                                    <i>
                                    Hemos detectado que está realizando una transferencia desde una cuenta corriente cuyos titulares no coinciden con los titulares del contrato en Diaphanum. Esto puede suponer una práctica de blanqueo de capitales o una donación entre particulares, que debe ser reflejada en su declaración fiscal.
                                    Si ha realizado la orden por error, por favor pulse el botón cancelar para anularla, y el dinero será devuelto en 48 horas hábiles a la cuenta corriente que utilizó, no realizándose la compra del fondo.
                                    </i>
                                </p>

                            </div>
                            <div className="ms-Grid-col ms-sm12 text-right" style={{ marginTop: "20px" }}>
                                <DefaultButton onClick={acceptSignature} disabled={signatureCancelSign != undefined} className="button mini-primary-button">
                                    Aceptar
                                </DefaultButton>
                                <DefaultButton onClick={cancelSignature} disabled={signatureAcceptSign != undefined} className="button mini-primary-button">
                                    Cancelar
                                </DefaultButton>
                            </div>
                            {serviceError?.status == true && <div>Error: {serviceError.message}</div>}
                            {(signatureAcceptSign !== undefined || signatureAcceptSign != null) && order?.userIdentity !== undefined 
                            && <SignatureComponentWithoutCancel
                                signatures={[signatureAcceptSign]}
                                userIdentities={[order.userIdentity]}
                                title="Aceptación de riesgo"
                                submitButtonText="Firmar"
                                alreadySignedText="La firma ha sido guardada."
                                headerTextArea={`Confirma la aceptación con la clave enviada a tu teléfono ${phoneNumber}.`}
                                confirmElement={{
                                    text: "He leído y comprendo los riesgos que implica realizar una transferencia desde una cuenta corriente cuyos titulares no coinciden con los del contrato. Acepto continuar con la operación bajo mi responsabilidad.",
                                    value: userConfirmation.user,
                                    setValue: (value: boolean) =>
                                        setUserConfirmation({
                                            user: !!value,
                                            disclaimer: userConfirmation.disclaimer,
                                        }),
                                }}
                                onBackButtonClicked={() =>
                                    history.push("/portfolio/orders")
                                }
                                onCancelButtonClicked={() =>
                                    history.push("/portfolio/orders")
                                }
                                requestNewCodeButtonText="Recibir nuevo código"
                                onNewCodeSended={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatureAcceptSign(signatures);
                                }}
                                onSignatureChanged={(signatures: any) =>
                                {
                                    processExpirationDate(signatures);
                                    setSignatureAcceptSign(signatures);
                                }}
                                onSigned={() => history.push("/portfolio/orders")}
                            />
                            }
                            {(signatureCancelSign !== undefined || signatureCancelSign != null) && order?.userIdentity !== undefined && <SignatureComponentWithoutCancel
                                userIdentities={[order.userIdentity]}
                                signatures={[signatureCancelSign]}
                                alreadySignedText="¡La cancelación de la orden está en proceso!"
                                title=""
                                submitButtonText="Firmar"
                                cancelButtonText="Cancelar"
                                headerTextArea={`Confirma la order con la clave enviada a tu teléfono ${phoneNumber}.`}
                                backButtonText="Volver"
                                onBackButtonClicked={() =>
                                    history.push("/portfolio/orders")
                                }
                                onCancelButtonClicked={() =>
                                    history.push("/portfolio/orders")
                                }
                                requestNewCodeButtonText="Recibir nuevo código"
                                confirmElement={{
                                    text: "Confirmo haber recibido, leído y comprendido la documentación obligatoria de IIC's y realizar esta operación por iniciativa propia.",
                                    value: userConfirmation.user,
                                    setValue: (value: boolean) =>
                                        setUserConfirmation({
                                            user: !!value,
                                            disclaimer: userConfirmation.disclaimer,
                                        }),
                                }}
                                onNewCodeSended={(signatures: any) =>
                                {
                                    signatures.forEach((signature: any) =>
                                    {
                                        processExpirationDate(signature);
                                    });
                                    setSignatureCancelSign(signatures[0]);
                                }}
                                onSignatureChanged={(signature: any) =>
                                {
                                    processExpirationDate(signature);
                                    setSignatureCancelSign(signature);
                                }}
                                onSigned={(signature: any) =>
                                {
                                    processExpirationDate(signature);
                                    setSignatureCancelSign(signature);
                                    cancelSign(signature);
                                }}
                            />}
                            {waitingForResponse && (
                                <div style={{ textAlign: "center", marginTop: "10px", display: "flex", alignItems: "center" }}>
                                    <Spinner
                                        size={SpinnerSize.large}
                                        className="button-spinner"
                                    />
                                    <p style={{ fontSize: "20px", marginLeft: "10px" }}>Esperando la respuesta del servidor. Será redireccionado próximamente...</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default PaymentAccountError;
import { TooltipHost } from '@fluentui/react';
import { ActiveIcon } from '../../../components/icons/IconsTable';

export interface ICompliantProfitability
{
    fundName: any,
    omega: any,
    b_omega: any,
    sharpe: any,
    b_sharpe: any,
    calmar: any,
    b_calmar: any,
    sortino: any,
    b_sortino: any,
    tracking_error: any,
    b_tracking_error: any,
    treynor: any,
    b_treynor: any,
    information_ratio: any,
    b_information_ratio: any,
    benchmark: any,
}

export const CompliantProfitability = (props: ICompliantProfitability) =>
{
    return (
        <table style={{marginTop: "1em"}}>
            <thead>
                <tr>
                    <th>Ratio / Producto</th>
                    <th>{props.fundName}</th>
                    <th>{props.benchmark}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Mide si la probabilidad de obtener ganancias es mayor que la de obtener pérdidas. Lecturas por encima de 1 son muy positivas. Muy importante en fondos de retorno absoluto'>
                                Omega
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.omega}</td>
                    <td>{props.b_omega}</td>
                    <td>{props.omega > props.b_omega ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Mide la rentabilidad por cada unidad de riesgo (volatilidad) que se asume. Cifras a cercanas a 1 o superiores son muy buenas. Este ratio es MUY IMPORTANTE'>
                                Sharpe
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.sharpe}</td>
                    <td>{props.b_sharpe}</td>
                    <td>{props.sharpe > props.b_sharpe ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Variación sobre el ratio de sharpe que utiliza la mayor caída en lugar de la volatilidad como medida de riesgo. Mide la rentabilidad conseguida por cada unidad de riesgo máximo asumido. Cuanto mayor mejor'>
                                Calmar
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.calmar}</td>
                    <td>{props.b_calmar}</td>
                    <td>{props.calmar > props.b_calmar ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Variación del ratio de sharpe, utilizando como medida de riesgo solo las desviaciones negativas en lugar de la volatidad. Mide la rentabilidad conseguida por cada unidad de riesgo asumido en las caídas'>
                                Sortino
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.sortino}</td>
                    <td>{props.b_sortino}</td>
                    <td>{props.sortino > props.b_sortino ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Mide lo que se desvía el fondo respecto del índice de referencia para ver si se asume mucho riesgo relativo. Muy importante para ver si el fondo es de gestión activa o pasiva. Cuanto más cercano a 0, más pasivo'>
                                Tracking error
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.tracking_error}%</td>
                    <td>{props.b_tracking_error}%</td>
                    <td>{props.tracking_error > props.b_tracking_error ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Mide la aportación de rentabilidad del gestor sobre el índice pero corregida por la beta. Es decir, a la hora de medir lo que se aporta, esto se corrige por el riesgo sistemático'>
                                Treynor
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.treynor}</td>
                    <td>{props.b_treynor}</td>
                    <td>{props.treynor > props.b_treynor ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            <TooltipHost content='Mide si las desviaciones respecto al índice que conforman el tracking error aportan o destruyen valor. Cuanto mayor mejor'>
                                Information Ratio
                            </TooltipHost>
                        </strong>
                    </td>
                    <td>{props.information_ratio}</td>
                    <td>{props.b_information_ratio}</td>
                    <td>{props.information_ratio > props.b_information_ratio ? <ActiveIcon color='#1BB2AD' /> : ''}</td>
                </tr>
            </tbody>
        </table>
    );
};
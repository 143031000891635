import styled from 'styled-components';
import {Button} from "../../components/buttons/MainButton";
import {Separator} from '@fluentui/react';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;

  width: 100%;
  margin: 0;

  @media (min-width: 1280px) {
    max-width: 1450px;
    margin: 0 auto;
  }

  .button-spinner {
    float: left;
    margin-right: 10px;
  }

  & .restarted-ms-Grid > .ms-Grid-row {
    margin: 0 -24px !important;

    & > .ms-Grid-col {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  & .ms-Stack .ms-StackItem {
    width: 100%;
  }

  & .ms-Stack .ms-StackItem,
  & .investment-fund-finder .ms-ComboBox-container {
    margin-top: 24px;
  }

  & .ms-Stack .ms-StackItem:first-child,
  .investment-fund-finder .ms-ComboBox-container:first-child {
    margin-top: 0;
  }

  & .ms-Stack .ms-StackItem:not(:first-child) {
    margin-left: 0;
  }

  & .ms-ChoiceField {
    display: inline-flex;
    margin-right: 8px;
    background: ${props => props?.theme?.palette?.white || "#FFF"};
    border: 1px solid ${props => props?.theme?.palette?.neutralLighter || "#E9EFF1"};
    padding: 10px 16px;
    border-radius: 26px;
  }

  .column-legend {
    padding-left: 27px;
  }
`;

export const SeparatorTop = styled(Separator)`
  margin-bottom: 39px;
`;

export const SeparatorForm = styled(Separator)`
  margin-top: 17px;
  margin-bottom: 17px;
`;

export const OuterFooterArea = styled.div`
  display: flex;
  justify-content: center;
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${props => props?.theme?.palette?.lightBlack || "#556769"};
  margin-top: 0;
  margin-bottom: 0;
`;

export const TextSmall = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  margin-top: 32px;
  color: ${props => props?.theme?.palette?.neutralText || "#95A0A1"};
`;

export const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  span {
    margin-right: 10px;
    cursor: pointer;
  }
`;

export const InputSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 14px;
  flex: 3;
  justify-content: center;
  height: 48px;

  > div, input {
    background-color: transparent !important;
    border-radius: 100px;
    padding-left: 10px;
  }
  
  input {
    border: none !important;
  }

  > div {
    height: 100%;
    border: 1px solid lightGrey;

    &:hover {
      border: 1px solid lightGrey;
    }
    &::after {
      border: none;
    }
  }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    h2
    {
        margin: 0;
        cursor: default;
    }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const CardContainer = styled.div`
  background-color: ${props => props?.theme?.palette?.tertiary || "#F3F7F9"};
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin-bottom: 20px;
  padding: 20px 40px;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 0;
  justify-content: center;

  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  h3 {
    margin: 0;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
  }

  .small-text {
    margin-top: 6px;
  }
`;

export const CardButton = styled(Button.Primary)`
  margin-top: 32px;
  margin-bottom: 0px;
`;

export const CardHeadingContainer = styled.div`
  margin-top: 10px;
`;

export const CardBody = styled.div`
  margin-top: 19px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  border-bottom: 1px solid lightGrey;
  background: ${(props) => props.theme.palette.white};
  table {
    border-radius: 16px;
  }
  thead {
    background: ${(props) => props.theme.palette.tertiary};
  }
  > div {
    margin: 0;
  }
`;

export const Table = styled.table`
  width: 100%;
  max-width: 100%;

  thead {
    background: #E3E7E9;
    white-space: nowrap;
  }

  th, td {
    text-align: left;
    padding: 12px 16px;
    height: 14px;
  }

  th.column-grow {
    width: 100%
  }

  th.column-with-number, td.column-with-number {
    text-align: right;
  }

  td {
    border-top: 1px solid rgba(147, 174, 185, 0.2);
  }

  tr:first-child td {
    border-top: none;
  }

  th:first-child {
    border-radius: 4px 0 0 4px;
  }

  th:last-child {
    border-radius: 0 4px 4px 0;
  }
`;

export const ColorBox = styled.div`
  width: 16px;
  height: 16px;
  display: inline-block;
  background-color: ${props => props?.color || "gray"};
  vertical-align: sub;
  margin-right: 10px;
`;

export const LegendLi = styled.li`
  height: 32px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  gap: 20px;

  span {
    color: #95a0a1;
    border-radius: 16px;
    line-height: 28px;
  }

  > * {
    vertical-align: middle;
  }
`;

export const ResultsPerPage = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    height: 100%;

    select
    {
        min-width: 70px;
        border: none;
        border-bottom: 1px solid black;
        padding: 5px 0;
    }
`;

export const PageCount = styled.div`
  display: flex;
`;

export const PageButtons = styled.div`
  button {
    cursor: pointer;
    border: 0;
    color: #CC214F;

    &.disabled {
      cursor: default;
      color: #F1BAC9;
    }
  }
`;

import axios from "axios";
import { proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
import { UserIdentityType } from "./UserIdentitiesService";
import { SavingsPlanType } from "./SavingsPlanService";
import { ISignature } from "./SignaturesService";
setupInterceptorsTo(axios);

interface ISavingsPlanUserIdentity
{
    id?: number,
    userIdentityId: number,
    userIdentity?: UserIdentityType,
    savingsPlanId: number,
    savingsPlan?: SavingsPlanType,
    signatureId?: number,
    signature?: ISignature,
    managementAuthorizationSignatureId?: number,
    managementAuthorizationSignature?: ISignature,
    relation: number
}
export type SavingsPlanUserIdentityType = ISavingsPlanUserIdentity

class SavingsPlanUserIdentityService
{
    public getAllUserIdentitiesFromContract = async (finametrixContract: string): Promise<SavingsPlanUserIdentityType> =>
    {
        let url: string = `${proxyUrl}/getAllUserIdentitiesFromContract/${finametrixContract}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public getSavingsPlanUserIdentity = async (savingsPlanUserIdentityId: number): Promise<SavingsPlanUserIdentityType> =>
    {
        let url: string = `${proxyUrl}/savingsPlansUserIdentity/${savingsPlanUserIdentityId}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public getSavingsPlanUserIdentitiesBySavingsPlanId = async (savingsPlanId: number): Promise<SavingsPlanUserIdentityType[]> =>
    {
        let url: string = `${proxyUrl}/savingsPlansUserIdentities/getBySavingsPlanId/${savingsPlanId}`;
        const req = await axios.get(url);
        return req.data;
    }
    public createSavingsPlanUserIdentity = async (savingsPlanUserIdentity: SavingsPlanUserIdentityType): Promise<SavingsPlanUserIdentityType> =>
    {
        let url: string = `${proxyUrl}/savingsPlansUserIdentities`;
        const req = await axios.post(url, savingsPlanUserIdentity);
        return req.data;
    }
    public updateSavingsPlanUserIdentity = async (savingsPlanUserIdentity: SavingsPlanUserIdentityType): Promise<SavingsPlanUserIdentityType> =>
    {
        let url: string = `${proxyUrl}/savingsPlansUserIdentity/${savingsPlanUserIdentity.id}`;
        const req = await axios.put(url, savingsPlanUserIdentity);
        return req.data;
    }
    public patchSavingsPlanUserIdentity = async (savingsPlanUserIdentityId: number, savingsPlanUserIdentity: any): Promise<SavingsPlanUserIdentityType> =>
    {
        let url: string = `${proxyUrl}/savingsPlansUserIdentity/${savingsPlanUserIdentityId}`;
        const req = await axios.patch(url, savingsPlanUserIdentity);
        return req.data;
    }
    public deleteSavingsPlanUserIdentity = async (savingsPlanUserIdentityId: number): Promise<SavingsPlanUserIdentityType> =>
    {
        let url: string = `${proxyUrl}/savingsPlansUserIdentity/${savingsPlanUserIdentityId}`;
        const req = await axios.delete<any>(url);
        return req.data;
    }
}

const savingsPlansUserIdentities = new SavingsPlanUserIdentityService();
export default savingsPlansUserIdentities;
import React from "react";
import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import Auth from "../../Auth/Auth";
import SavingsPlanService from "../../services/SavingsPlanService";
import ActiveSavingPlans from "../SavePlans/ActiveSavingPlans";
import { ITableSPlansActive } from "../SavePlans/TableActiveSavingPlans";
import SavingsPlansConfiguring from "./SavingsPlansConfiguring";
import RelationPlans from "../SavePlans/RelationPlans";
import ExpiredSavingPlans from "../SavePlans/ExpiredSavingPlans";
import CancelledSavingPlans from "../SavePlans/CancelledSavingPlans";
import { ITableSPlansRelational } from "../SavePlans/TableRelationPlans";
import { Spinner, SpinnerSize } from "@fluentui/react";

const SavingsPlans = () =>
{
    const [savingsPlans, setSavingsPlans] = useState<ITableSPlansActive[]>();
    const [relationalPlans, setRelationalPlans] = useState<ITableSPlansRelational[]>();
    const [activeSavingsPlans, setActiveSavingsPlans] = useState<ITableSPlansActive[]>();
    const [configuringSavingsPlans, setConfiguringActiveSavingsPlans] = useState<ITableSPlansActive[]>();
    const [error, setError] = useState({message: false});
    const [loading, setLoading] = useState<boolean>(true);
    const [refreshTable, setRefreshTable] = useState<boolean>(false);

    useEffect(() =>
    {
        const user = Auth.getUserProfile();

        Promise.all([SavingsPlanService.getSavingsPlansAndStatus(user.id), SavingsPlanService.getRelationalPlans(user.id)]).then(([plans, relationalPlans]) =>
        {
            setSavingsPlans(plans);
            setRelationalPlans(relationalPlans);
        })
        .catch((error) =>
        {
            setError(error);
        });
    }, []);

    useEffect(() =>
    {
        if(refreshTable)
        {
            setLoading(true);
            setRefreshTable(false);
            const user = Auth.getUserProfile();
            Promise.all([SavingsPlanService.getSavingsPlansAndStatus(user.id), SavingsPlanService.getRelationalPlans(user.id)]).then(([plans, relationalPlans]) =>
            {
                setSavingsPlans(plans);
                setRelationalPlans(relationalPlans);
            })
            .catch((error) =>
            {
                setError(error);
            });
        }
    }, [refreshTable]);

    useEffect(() =>
    {
        if(savingsPlans !== undefined)
        {
            setConfiguringActiveSavingsPlans(savingsPlans.filter((sP) => sP.status < 4));
            setActiveSavingsPlans(savingsPlans);
            setLoading(false);
        }
    }, [savingsPlans]);

    return(
        <DocumentTitle title="IronIA - Mis Planes de Cartera">
            <React.Fragment>
                <div className="ms-Grid-row savings-plans">
                    <h2 style={{cursor: "default"}}>Mis Planes de Cartera</h2>
                    <p className="description">
                        Ten a mano todos los detalles sobre tus planes de cartera y configura o edita las cosas que necesites...
                    </p>
                </div>
                {loading && <Spinner size={SpinnerSize.large} />}
                {!loading &&
                (
                    <React.Fragment>
                        {activeSavingsPlans !== undefined && activeSavingsPlans.some((e) => e.status === 5) &&
                        (
                            <ExpiredSavingPlans data={activeSavingsPlans} loading={loading} error={error} />
                        )}
                        {activeSavingsPlans !== undefined && activeSavingsPlans.some((e) => e.status === 4) &&
                        (
                            <ActiveSavingPlans data={activeSavingsPlans} loading={loading} error={error} />
                        )}
                        {configuringSavingsPlans !== undefined && configuringSavingsPlans.length > 0 &&
                        (
                            <SavingsPlansConfiguring data={configuringSavingsPlans} loading={loading} error={error}
                                reload={(reloadData) =>
                                {
                                    if(reloadData)
                                    {
                                        setRefreshTable(true);
                                    }
                                }}
                            />
                        )}
                        {relationalPlans !== undefined && relationalPlans.length > 0 &&
                        (
                            <RelationPlans data={relationalPlans} loading={loading} error={error} />
                        )}
                        {activeSavingsPlans !== undefined && activeSavingsPlans.some((e) => e.status === 6) &&
                        (
                            <CancelledSavingPlans data={activeSavingsPlans} loading={loading} error={error} />
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        </DocumentTitle>
    );
};

export default SavingsPlans;
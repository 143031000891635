import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 80px;
    justify-content: center;
    align-items: flex-start;
`;

export const Title = styled.h1``;
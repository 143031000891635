import { IDropdownOption, Dropdown, IDropdownStyles, Spinner, SpinnerSize } from '@fluentui/react';
import { ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "@fluentui/react-theme-provider";
import DocumentTitle from 'react-document-title';
import React from 'react';
import { ColMarketDescription, ColMarket, MarketDescription, MarketTitle, RowMarket, MarketColLastAnalyisis, MarketColSearch, ColMarketAnalysis, MarketColLastAnalyisisContainer, MarketColSearchContainer, ButtonAnalysis, MarketSubTitle, MarketSearchCardsContainer, MarketManagementContainer, MarketDatePickerCont, MarkerDatePickerInner, ErrorMessage, MarketColSearchContainerInner, MarketColLastAnalyisisContainerInner, AnalisysPortfolioTableContainer, AnalisysPortfolioContainer, ColHeaderMarket, RowHeaderMarket, MarketSpacer, MarketSeparator, SearchButtonAnalysis, MarketLineSeparator } from './MarketStyle';
import SavingsPlanService, { ActiveSubscriptionAndSavingPlans } from '../../../services/SavingsPlanService';
import Auth from '../../../Auth/Auth';
import { IInfoFundPortfolio } from '../../../services/FundService';
import PortfolioTable from '../Summary/PortfolioTable';
import FinametrixService from '../../../services/FinametrixService';
import { useHistory } from 'react-router';
import { decimalFormatter } from '../../../utils/numberFormatter';
import moment from 'moment';
import { DatePicker } from "@fluentui/react";
import { useConst } from '@fluentui/react-hooks';
import { DatePickerStyle } from '../../../components/GlobalStyle';
import { DayPickerStrings } from '../../../components/ConvenienceTest/Utils/DatesTranlateFluents';
import MarketCardsCarousel from './MarketCarousel/MarketCarousel';
import MarketService from '../../../services/MarketService';
import subscriptionService, { UserSubscriptionsType } from '../../../services/SubscriptionService';
import { MarketAttachment } from '../../../components/MarketAttachment';
import { set } from 'lodash';

type ActivePlansToAgregate = {
    id: string,
    finametrixId: string,
    createdAt: Date,
    isSelected: boolean,
    finametrixContract: string,
    fnmContractId: number
};

type MarketItem = {
    id: string;
    fileUrl: string;
    imageUrl: string;
};

interface Subscription {
    id?: number,
    name: string,
    description: string,
    period: string,
    price?: number,
    limit?: number,
    freePeriods?: number,
    advised?: boolean,
    recommended?: boolean,
    actionText?: string,
    subscriptionType: string,
    priceIronia: number,
    priceProvider: number,
    riskLevel: number,
    providerId?: number,
    provider?: any,
    type?: string,
    limitPeriod?: number,
    isSelected?: boolean
}

type TrialAttemps = {
    success: boolean,
    usedAttempts: number,
}

const dropdownStyles: Partial<IDropdownStyles> =
{
    caretDown:
    {
        color: "#cc214F",
        fontSize: "14px !important",
        fontWeight: 700
    },
    dropdownItems:
    {
        color: "#cc214F !important",
        borderColor: "transparent !important",
        fontWeight: 500,
        fontSize: "20px",
        marginTop: "5px"
    },
    title:
    {
        color: "#cc214F !important",
        borderColor: "transparent !important",
        fontWeight: 500,
        fontSize: "20px",
        marginTop: "5px"
    },
    dropdownItemSelected:
    {

    },
    dropdownOptionText: { color: "rgb(204, 33, 79) !important" }
};

const initialReporting =
{
    hasError: false,
    positions:
    {
        positionsTo:
        {
            positions: [],
            aggregation: []
        }
    },
    performance:
    {
        serie: [{ dateAsString: "", accReturn: 0 }]
    }
}

const Market = () => {
    const theme = useContext(ThemeContext);
    const user = Auth.getUserProfile();
    var activePlan = Auth.getActivePlan();
    const [isLoadingChange, setLoadingChange] = useState<boolean>(false);
    const [options, setOptions] = useState<IDropdownOption[]>();
    const [allActivePlanToAgregate, setAllActivePlanToAgregate] = React.useState<ActivePlansToAgregate[]>([]);
    const [selectedActivePlansIds, setSelectedActivePlansIds] = React.useState<number[]>([]);
    const firstLoad = useRef<boolean>(true);
    const isLoadedReporting = useRef<boolean>(true);
    const [modelFunds, setModelFunds] = useState<any[]>([]);
    const [funds, setFunds] = useState<IInfoFundPortfolio[]>([]);
    const [isLoadingReporting, setIsLoadingReporting] = useState<boolean>(false);
    const [reporting, setReporting] = useState(initialReporting);
    const [selectedFinametrixContracts, setSelectedFinametrixContracts] = React.useState<string[]>([]);
    const [isLoadedFunds, setIsLoadedFunds] = useState(false);

    const [showMarketManagementContainer, setShowMarketManagementContainer] = useState<boolean>(false);
    const [showMarketReport, setShowMarketReport] = useState<boolean>(false);
    const [showMarketItems, setShowMarketItems] = useState<boolean>(false);
    const [marketItems, setMarketItems] = useState<MarketItem[]>([]);
    const [showLastMarketItems, setShowLastMarketItems] = useState<boolean>(false);
    const [lastMarketItems, setLastMarketItems] = useState<MarketItem[]>([]);
    const [showAnalysis, setShowAnalysis] = useState<boolean>(true);
    const [marketSubscription, setMarketSubscription] = useState<Subscription>();
    const [showPdfViewer, setShowPdfViewer] = useState<boolean>(false);
    const [isSuscribed, setIsSuscribed] = useState<boolean>(false);
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const [analysisName, setAnalysisName] = useState<string | null>(null);
    const [blockedCarousel, setBlockedCarousel] = useState<boolean>(false);
    const [analyisisDateRange, setAnalysisDateRange] = useState({ startDate: new Date(), endDate: new Date() });
    const [attemps, setAttemps] = useState<number | null>(0);
    const [errors, setErrors] = useState(
        {
            analysisStartDate: "",
            analysisEndDate: "",
        });

    const history = useHistory();
    const isFinametrixPortfolioLoaded = useRef<boolean>(false);
    const date_to = function (this: any) {
        this.setDate(this.getDate());
        return this;
    }.call(new Date());
    const date_to_text = useConst(date_to.getFullYear().toString() + ("0" + (date_to.getMonth() + 1)).slice(-2) + ("0" + date_to.getDate()).slice(-2));

    const [finametrixPortfolio, setFinametrixPortfolio] = useState(
        {
            creationDate: "",
            finametrixId: "",
            amount: 0,
            cost: 0
        });
    moment.locale("es");

    const portfolioDataWithErrorsStatuses = () => {
        // setError(error);
        isLoadedReporting.current = true;
        setIsLoadedFunds(true);
        setLoadingChange(false);
    }

    const updateModelFunds = (items: any[]) => {
        if (items) {
            setModelFunds(items);
        }
        return items;
    }

    const updateSelectedActivePlanKeys = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        if (item) {
            setAllActivePlanToAgregate(prevState => {
                const newState = prevState.map(obj => {
                    if (obj.id === item.key.toString()!) {
                        return { ...obj, isSelected: item.selected ? item.selected : false };
                    }
                    return obj;
                });

                return newState;
            });
            if (item.selected) {
                setSelectedActivePlansIds(state => [...state, Number(item.id)!]);
            }
            else {
                let newState = selectedActivePlansIds.filter(x => x !== Number(item.id)!);
                setSelectedActivePlansIds(newState);
            }
        }
    };

    const handleRenderTitle = (items: IDropdownOption[] | undefined): ReactElement<any, any> => {
        if (items) {
            if (items.length === 1) {
                return <React.Fragment>{`${items[0].text}`}</React.Fragment>;
            }
            return <React.Fragment>{"Cartera resumen"}</React.Fragment>;
        }
        return <React.Fragment></React.Fragment>;
    };

    useEffect(() => {
        const fetchTrialAttempts = async () => {
            try {
                const response: TrialAttemps = await MarketService.getFreeTrialAttemps(user.id);
                if (response.usedAttempts === null || response.usedAttempts === undefined) {
                    // Si no hay registros, asumimos que el usuario no ha usado intentos
                    setAttemps(0);
                    setBlockedCarousel(false);
                } else {
                    const remainingAttempts = 3 - response.usedAttempts;
                    // Si hay registros, calculamos los intentos restantes
                    if (response.usedAttempts > 3) {
                        setAttemps(3);
                        setBlockedCarousel(true);
                    } else {

                        setAttemps(response.usedAttempts);
                        setBlockedCarousel(remainingAttempts <= 0);
                    }
                }
            } catch (error) {
                console.error("Error fetching trial attempts:", error);
                setAttemps(0); // En caso de error, asumimos que no hay intentos restantes
                setBlockedCarousel(true);
            }
        };

        fetchTrialAttempts();
    }, []);



    // hector: Comprobar si el usuario está suscrito al servicio "Mercado"
    useEffect(() => {
        subscriptionService.getSubscriptionByUser(user.id).then((response: UserSubscriptionsType[]) => {
            const mercadoSubscription = response.filter(subscription =>
                subscription.subscriptionType === "service" && subscription.subscriptionName === "Mercado"
            );
            if (mercadoSubscription.length > 0) {
                setIsSuscribed(true);
            } else {
                setIsSuscribed(false);
            }
        });
    }, []);

    useEffect(() => {
        if (firstLoad.current === true) {
            firstLoad.current = false;

            let allPlanToAgregate: ActivePlansToAgregate[] = [];
            let selectedActivePlanIds: number[] = [];

            SavingsPlanService.getActiveSubscriptionAndSavingPlan(user.id).then((response: ActiveSubscriptionAndSavingPlans[]) => {
                console.log('response:getActiveSubscriptionAndSavingPlan >>>', response)
                var aux: IDropdownOption[] = [];
                for (var i = 0; i < response.length; i++) {
                    aux.push(
                        {
                            key: response[i].id,
                            text: response[i].planName,
                            selected: response[i].selected,
                            id: response[i].id.toString()
                        });

                    let planToAgregate: ActivePlansToAgregate =
                    {
                        id: response[i].id.toString(),
                        createdAt: response[i].createdAt!,
                        isSelected: response[i].selected,
                        finametrixId: response[i].finametrixId,
                        finametrixContract: response[i].finametrixContract,
                        fnmContractId: response[i].fnmContractId
                    };

                    allPlanToAgregate.push(planToAgregate);

                    if (response[i].selected) {
                        let selectedlanId = response[i].id;
                        selectedActivePlanIds.push(selectedlanId);
                    }
                }
                // Auth.setActiveSubscriptions(aux);
                setOptions(aux);
            });
            setAllActivePlanToAgregate(allPlanToAgregate);
            setSelectedActivePlansIds(selectedActivePlanIds);
        }
    }, [user]);

    const fetchFinametrixReporting = useCallback(async (portfolioId: string, date_from: string, date_to: string) => {
        if (isLoadedReporting.current) {
            isLoadedReporting.current = false;
            await FinametrixService.getReporting(portfolioId, date_from, date_to).then((data: any) => {
                var realPlan = Auth.getActivePlan();
                FinametrixService.getOperations(realPlan.portfolio.finametrixId).then((ops: any) => {
                    let opArray: any[] = [];

                    ops.forEach((op: any) => {
                        let description: string = op.cashIn ? op.description : op.title;
                        opArray.push(
                            {
                                id: op.id,
                                operationAt: moment(op.operationAt).format('YYYY-MM-DD'),
                                settlementDate: moment(op.settlementDate).format('YYYY-MM-DD'),
                                operationInstrument:
                                {
                                    isin: op.operationInstrument.isin,
                                    name: op.operationInstrument.name
                                },
                                description: description,
                                amount: op.amount,
                                nav: op.nav,
                                exchangeRate: op.exchangeRate === 1 ? op.exchangeRate : decimalFormatter.format(op.exchangeRate),
                                titles: op.titles,
                                currency: op.currency
                            });
                    });

                    setReporting(data);
                });
            },
                error => {
                    portfolioDataWithErrorsStatuses();
                })
                .catch((() => {
                    portfolioDataWithErrorsStatuses();
                }))
                .finally(() => {
                    setIsLoadedFunds(true);
                    isLoadedReporting.current = true;
                    setIsLoadingReporting(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchFinametrixPortfolio = async (portfolioId: string) => {
        if (isFinametrixPortfolioLoaded.current === false) {
            isFinametrixPortfolioLoaded.current = true;
            await FinametrixService.getPortfolio(portfolioId).then((finametrixData: any) => {
                if (finametrixData === "Not Found") {
                    setReporting(initialReporting);
                    portfolioDataWithErrorsStatuses();
                }
                else {
                    setFinametrixPortfolio(finametrixData);
                }
            },
                error => {
                    setReporting(initialReporting);
                    portfolioDataWithErrorsStatuses();
                })
                .catch(() => {
                    setReporting(initialReporting);
                    portfolioDataWithErrorsStatuses();
                });
        }
    };

    // hector: market reports
    useEffect(() => {

        const today = new Date();
        const fourteenDaysAgo = new Date(today);
        fourteenDaysAgo.setDate(today.getDate() - 30);

        const startDate = fourteenDaysAgo.toISOString().split('T')[0];
        const endDate = today.toISOString().split('T')[0];

        MarketService.getLastMarketItems().then((response: any) => {
            if (response.length > 0) {
                setLastMarketItems(response);
                setShowLastMarketItems(true);
            }
        });

        MarketService.getMarketItems(startDate, endDate).then((response: any) => {
            if (response.length > 0) {
                setMarketItems(response);
                setShowMarketItems(true);
            }
        });
    }, []);

    useEffect(() => {
        if (activePlan?.portfolio?.finametrixId !== undefined) {
            fetchFinametrixPortfolio(activePlan.portfolio.finametrixId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePlan]);

    useEffect(() => {
        if (isLoadedReporting.current) {
            if (finametrixPortfolio && finametrixPortfolio !== undefined && finametrixPortfolio.creationDate !== "" && finametrixPortfolio.creationDate !== undefined && activePlan?.portfolio?.finametrixId !== undefined) {
                setSelectedFinametrixContracts([activePlan.finametrixContract]);
                fetchFinametrixReporting(activePlan?.portfolio?.finametrixId, finametrixPortfolio.creationDate.substring(0, 10).replace(/-/g, ""), date_to_text);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finametrixPortfolio, activePlan?.portfolio?.finametrixId, isLoadedReporting, fetchFinametrixReporting, activePlan.finametrixContract]);


    // inicialización selector de fechas
    useEffect(() => {
        const today = new Date();
        const fourteenDaysAgo = new Date();
        fourteenDaysAgo.setDate(today.getDate() - 14);

        setAnalysisDateRange({
            startDate: fourteenDaysAgo,
            endDate: today
        });
    }, []);

    // TODO: texto suscription :: Mercado
    useEffect(() => {
        subscriptionService.getSubscriptionsByType("service").then((response: Subscription[]) => {
            if (response.length > 0) {
                setMarketSubscription(response[0]);
            }
        });
    }, []);


    // TODO: botón de actualizar
    const handleAnalysisClick = () => {
        if (selectedActivePlansIds.length === 1) {
            setIsLoadingReporting(true);
            setShowPdfViewer(false);
            setShowMarketReport(true);
            setShowAnalysis(false);
            setBlockedCarousel(true);
            fetchFinametrixReporting(activePlan?.portfolio?.finametrixId, moment(analyisisDateRange.startDate).format('YYYYMMDD'), moment(analyisisDateRange.endDate).format('YYYYMMDD'));
        }
    };

    // X - close
    const handleCloseAnalysisClick = () => {
        setShowAnalysis(true);
        setShowMarketReport(false);
        setIsLoadingReporting(false);
        setBlockedCarousel(false);
    };

    // selector FECHAS
    const handleAnalysisDateInputChange = (name: string, value: Date) => {
        if (name === 'analysisStartDate') {
            setAnalysisDateRange((prevRange) => ({
                ...prevRange,
                startDate: value,
            }));
        } else if (name === 'analysisEndDate') {
            setAnalysisDateRange((prevRange) => ({
                ...prevRange,
                endDate: value,
            }));
        }
    };

    // buscar por FECHAS
    const handleAnalysisDatesClick = () => {
        const startDate = moment(analyisisDateRange.startDate).format('YYYY-MM-DD');
        const endDate = moment(analyisisDateRange.endDate).format('YYYY-MM-DD');

        MarketService.getMarketItems(startDate, endDate).then((response: any) => {
            if (response.length > 0) {
                setMarketItems(response);
                setShowMarketItems(true);
            }
        });
    };


    const handleMarketCardClick = async (marketItem: MarketItem) => {
        setBlockedCarousel(true); // Bloquea temporalmente mientras procesas
        setShowMarketManagementContainer(false); // Oculta el contenedor hasta verificar
        setShowPdfViewer(false);

        try {
            // Realizar el POST para registrar el intento
            await MarketService.postFreeTrialAttempt(user.id, 47, marketItem.fileUrl);
            // Realizar el GET para calcular los intentos restantes
            const response = await MarketService.getFreeTrialAttemps(user.id);

            if (response.success) {
                if (response.usedAttempts === null) {
                    // Intentos ilimitados
                    setAttemps(null);
                    const fileName = marketItem.fileUrl.split("/marketreports/")[1];
                    setPdfUrl(fileName);
                    setAnalysisName(marketItem.id);
                    setShowPdfViewer(true);
                    setShowMarketManagementContainer(true);
                    setBlockedCarousel(false);
                } else if (response.usedAttempts < 4) {
                    // Mostrar PDF con intentos restantes
                    setAttemps(response.usedAttempts);
                    setShowPdfViewer(true);
                    console.log("marketItem.fileUrl", marketItem.fileUrl);
                    const fileName = marketItem.fileUrl.split("/marketreports/")[1];
                    setPdfUrl(fileName);
                    setAnalysisName(marketItem.id);
                    setShowMarketManagementContainer(true);
                    setBlockedCarousel(false);
                } else {
                    // Bloquear si no hay intentos restantes
                    setAttemps(3);
                    setShowPdfViewer(false);
                    setShowMarketManagementContainer(false);
                    setBlockedCarousel(true);
                }
            } else {
                alert("No se pudo procesar tu solicitud.");
                setBlockedCarousel(true);
            }
        } catch (error) {
            console.error("Error al manejar el clic en la tarjeta:", error);
            setBlockedCarousel(true);
        } finally {

        }
    };

    return (
        <DocumentTitle title='IronIA - Mercado'>
            <>
                <React.Fragment>
                    <div>
                        <RowHeaderMarket className="ms-Grid-row">
                            <ColHeaderMarket>
                                <h1>Análisis Mercado</h1>
                            </ColHeaderMarket>
                            {
                                !isSuscribed && <ButtonAnalysis
                                    text="Suscibirse"
                                    onClick={() => history.push('/portfolio/market-suscription')}
                                    disabled={isLoadingChange}
                                />
                            }
                        </RowHeaderMarket>
                        <MarketLineSeparator className='separator' />
                    </div>
                    <MarketSeparator />

                    <RowMarket className="ms-Grid-row">
                        <ColMarket className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                            <MarketTitle>
                                Análisis Mercado
                                {!isSuscribed &&
                                    <span> {attemps} de 3 gratis</span>
                                }
                            </MarketTitle>
                        </ColMarket>
                        <ColMarketDescription className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                            <MarketDescription>
                                {marketSubscription?.description}.{!isSuscribed && <span>Dispone de 3 análisis gratuitos.</span>}  El coste del servicio es de {marketSubscription?.price}€/año.
                            </MarketDescription>
                        </ColMarketDescription>
                    </RowMarket>

                    {/* Últimos Análisis y buscar */}
                    <RowMarket className="ms-Grid-row">
                        <ColMarketAnalysis>
                            <MarketColLastAnalyisis>
                                <MarketColLastAnalyisisContainer>
                                    <MarketColLastAnalyisisContainerInner>
                                        <div className="shadow">
                                            <MarketSubTitle>
                                                Últimos análisis
                                            </MarketSubTitle>
                                            <MarketSpacer></MarketSpacer>
                                            <MarketSearchCardsContainer>
                                                {showLastMarketItems &&
                                                    <MarketCardsCarousel
                                                        disabled={blockedCarousel}
                                                        marketItems={lastMarketItems}
                                                        onMarketCardClick={handleMarketCardClick}
                                                    />
                                                }
                                            </MarketSearchCardsContainer>
                                        </div>
                                    </MarketColLastAnalyisisContainerInner>
                                </MarketColLastAnalyisisContainer>
                            </MarketColLastAnalyisis>

                            <MarketColSearch>
                                <MarketColSearchContainer>
                                    <MarketColSearchContainerInner>
                                        <div className="shadow">
                                            <MarketSubTitle>
                                                Buscar
                                            </MarketSubTitle>
                                            <MarketDatePickerCont>
                                                <MarkerDatePickerInner>
                                                    <DatePicker
                                                        styles={DatePickerStyle}
                                                        label='Fecha de inicio'
                                                        id={'analysisStartDate'}
                                                        // @ts-ignore
                                                        name={'analysisStartDate'}
                                                        formatDate={(date) => {
                                                            return moment(date).format('DD/MM/YYYY');
                                                        }}
                                                        onSelectDate={(date) => {
                                                            if (date) {
                                                                const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                                                                handleAnalysisDateInputChange('analysisStartDate', utcDate);
                                                            }
                                                        }}
                                                        value={analyisisDateRange.startDate! ? analyisisDateRange.startDate! : undefined}
                                                        maxDate={new Date()}
                                                        disabled={false}
                                                        strings={DayPickerStrings}
                                                        allowTextInput={true}
                                                        parseDateFromString={(dateStr) => {
                                                            var parts: string[] = dateStr.split('/');
                                                            var myDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
                                                            return new Date(myDate)
                                                        }}
                                                    />
                                                    <ErrorMessage className="error">{errors.analysisStartDate}</ErrorMessage>
                                                </MarkerDatePickerInner>
                                                <MarkerDatePickerInner>
                                                    <DatePicker
                                                        styles={DatePickerStyle}
                                                        label='Fecha de fin'
                                                        id={'analysisEndDate'}
                                                        // @ts-ignore
                                                        name={'analysisEndDate'}
                                                        formatDate={(date) => {
                                                            return moment(date).format('DD/MM/YYYY');
                                                        }}
                                                        onSelectDate={(date) => {
                                                            if (date) {
                                                                const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                                                                handleAnalysisDateInputChange('analysisEndDate', utcDate);
                                                            }
                                                        }}
                                                        value={analyisisDateRange.endDate! ? analyisisDateRange.endDate! : undefined}
                                                        maxDate={new Date()}
                                                        disabled={false}
                                                        strings={DayPickerStrings}
                                                        allowTextInput={true}
                                                        parseDateFromString={(dateStr) => {
                                                            var parts: string[] = dateStr.split('/');
                                                            var myDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
                                                            return new Date(myDate)
                                                        }}
                                                    />
                                                    <ErrorMessage className="error">{errors.analysisEndDate}</ErrorMessage>
                                                </MarkerDatePickerInner>
                                                <SearchButtonAnalysis
                                                    text="Buscar"
                                                    onClick={handleAnalysisDatesClick}
                                                    disabled={isLoadingChange}
                                                />
                                            </MarketDatePickerCont>
                                            <MarketSearchCardsContainer>
                                                {showMarketItems &&
                                                    <MarketCardsCarousel
                                                        disabled={blockedCarousel}
                                                        marketItems={marketItems}
                                                        onMarketCardClick={handleMarketCardClick}
                                                    />
                                                }
                                            </MarketSearchCardsContainer>
                                        </div>
                                    </MarketColSearchContainerInner>
                                </MarketColSearchContainer>
                            </MarketColSearch>
                        </ColMarketAnalysis>
                    </RowMarket>

                    {/* Análisis */}
                    {showMarketManagementContainer &&
                        <RowMarket className="ms-Grid-row">
                            <ColMarketAnalysis>
                                <MarketColLastAnalyisis>
                                    <MarketColLastAnalyisisContainer>

                                        <MarketSubTitle>
                                            Análisis{analysisName !== null && ": " + moment(analysisName).format('DD-MM-YYYY')}
                                        </MarketSubTitle>
                                    </MarketColLastAnalyisisContainer>
                                </MarketColLastAnalyisis>

                                <MarketColSearch>
                                    <MarketColSearchContainer>
                                        <MarketManagementContainer className="analysis-management">
                                            {showAnalysis ? (
                                                options !== undefined && (
                                                    <React.Fragment>
                                                        {options.length > 0 && (
                                                            <Dropdown
                                                                placeholder={activePlan.name}
                                                                className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl4 ms-xxl5 ms-xxxl6"
                                                                options={options as IDropdownOption[]}
                                                                selectedKeys={selectedActivePlansIds}
                                                                onRenderTitle={handleRenderTitle}
                                                                onChange={(event, item) => updateSelectedActivePlanKeys(event, item)}
                                                                styles={dropdownStyles}
                                                                disabled={isLoadingChange}
                                                            />
                                                        )}
                                                        <ButtonAnalysis
                                                            text="Análisis Cartera"
                                                            onClick={handleAnalysisClick}
                                                            disabled={isLoadingChange}
                                                        />
                                                    </React.Fragment>
                                                )
                                            ) : (
                                                <React.Fragment>
                                                    <i
                                                        className="ironia-icon cross"
                                                        onClick={handleCloseAnalysisClick}
                                                        style={{ cursor: 'pointer', fontSize: '24px' }}
                                                    ></i>
                                                </React.Fragment>
                                            )}
                                        </MarketManagementContainer>
                                    </MarketColSearchContainer>
                                </MarketColSearch>
                            </ColMarketAnalysis>
                        </RowMarket>
                    }
                    {
                        showPdfViewer &&
                        <RowMarket className="ms-Grid-row">
                            {pdfUrl !== null &&
                                <MarketAttachment fileName={pdfUrl} className='market-viewer' />
                            }

                        </RowMarket>
                    }
                    {
                        showMarketReport &&
                        <>
                            <RowMarket className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12">
                                    <div className="shadow">
                                        <div className="ms-Grid-row">
                                            <div>
                                                <h3>Detalles de tu cartera</h3>
                                            </div>
                                        </div>
                                        <AnalisysPortfolioContainer className='testanalisis'>
                                            <AnalisysPortfolioTableContainer>
                                                {isLoadingReporting && <Spinner size={SpinnerSize.large} />}
                                                {!isLoadingReporting &&
                                                    (
                                                        <PortfolioTable
                                                            showActions={false}
                                                            showDocuments={false}
                                                            showIroniaPoints={false}
                                                            reporting={reporting}
                                                            funds={funds}
                                                            fundsforModelData={items => updateModelFunds(items)}
                                                            isResumeWallet={selectedFinametrixContracts.length > 1}
                                                        />
                                                    )}
                                            </AnalisysPortfolioTableContainer>
                                        </AnalisysPortfolioContainer>
                                    </div>
                                </div>
                            </RowMarket>
                        </>
                    }
                </React.Fragment>
            </>
        </DocumentTitle >
    );
}

export default Market;
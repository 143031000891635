import axios from "axios";
import { proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);

export type OfficialDocsIntegrationResponseDto<T> = {
    status: number;
    hasErrors: boolean;
    errorsMessages: string[];
    data?: T;
  }

export type LoginResponseDto = {
    status: string;
    token_type: string;
    access_token: string;
    expires_at: Date;
    data: UserDataDto;
}

export type UserDataDto = {
	user_id: string;
	funder_id: string;
	funder_name: string;
	name: string;
	email: string;
	role: string;
};

export type ClavePinCheckServiceResponseDto = {
    status: string;
    message: string;
}

export type ClavePinCheckServiceRequestDto = {
	user_id: string;
    accessToken: string;
}

export type ClavePinRequestDto = {
	user_id: string;
    accessToken: string;
    request_id?: string;
}

export type ClavePinResponseDto = {
	status: string;
    data: ClavePinDataDto;
}

export type ClavePinDataDto = {
    request_id: string;
    user_id: string;
    user_name: string;
    person_id: string;
    person_name: string;
    person_phone: number;
    person_document_number: string;
    person_birth_date: string;
    flow_id: string;
    flow_name: string;
    status_code: string;
    status_message: string;
    widget_url: string;
    has_documents: boolean;
    created_at: Date;
    updated_at: Date;
}

export type ClavePinTermsRequestDto = {
	user_id: string;
    terms_and_conditions: boolean;
    accessToken: string;
    request_id: string;
}

export type ClavePinTermsResponseDto = {
	status: string;
    data: ClavePinDataDto;
}

export type OfficialDocumentationSignedDto = {
    id?: number;
    userIdentityId: number;
    status: string;
    protectedDocs?: boolean;
    request_id?: string;
    user_id?: string;
}

export type OfficialDocumentationSignedRequestDto = {
	newStatusId: number;
	user_id: string;
	request_id: string;
};

export type DocumentsRequestDto = {
    request_id: string;
}

export type AzureDocumentDto = {
    fileName: string;
    createdAt: Date;
    requestId: string;
    url: string;
    userIdentityId: string;
    id: string;
};

class OfficialDocumentationService
{
    public login = async (): Promise<OfficialDocsIntegrationResponseDto<LoginResponseDto>> =>
    {
        let url: string = `${proxyUrl}/official-docs/login`
        const req = await axios.get<any>(url);
        return req.data;
    }
    public clavePinCheckService = async (request: ClavePinCheckServiceRequestDto): Promise<OfficialDocsIntegrationResponseDto<ClavePinCheckServiceResponseDto>> =>
    {
        let url: string = `${proxyUrl}/official-docs/clave-pin/check-service`
        const req = await axios.post(url, request);
        return req.data;
    }
    public clavePin = async (request: ClavePinRequestDto): Promise<OfficialDocsIntegrationResponseDto<ClavePinResponseDto>> =>
    {
        let url: string = `${proxyUrl}/official-docs/clave-pin`
        const req = await axios.post(url, request);
        return req.data;
    }
    public clavePinTerms = async (request: ClavePinTermsRequestDto): Promise<OfficialDocsIntegrationResponseDto<ClavePinTermsResponseDto>> =>
    {
        let url: string = `${proxyUrl}/official-docs/clave-pin/terms`
        const req = await axios.post(url, request);
        return req.data;
    }
    public getOfficialDocumentationUserStatus = async (): Promise<OfficialDocsIntegrationResponseDto<OfficialDocumentationSignedDto>> =>
    {
        let url: string = `${proxyUrl}/official-docs/user-status`
        const req = await axios.get(url);
        return req.data;
    }
    public postOfficialDocumentationUserStatus = async (data: OfficialDocumentationSignedRequestDto): Promise<OfficialDocsIntegrationResponseDto<OfficialDocumentationSignedDto>> =>
    {
        let url: string = `${proxyUrl}/official-docs/user-status`
        const req = await axios.post(url, data);
        return req.data;
    }
    public GetDocuments = async (): Promise<OfficialDocsIntegrationResponseDto<AzureDocumentDto[]>> =>
    {
        let url: string = `${proxyUrl}/official-docs/documents`
        const req = await axios.get(url);
        return req.data;
    }
}

const OfficialDocumentationServices = new OfficialDocumentationService();

export default OfficialDocumentationServices;
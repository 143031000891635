import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import DocumentTitle from "react-document-title";
import { DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import moment from "moment";
import OrderService, { OrderType } from "../../../../services/OrderService";
import { SignatureComponent } from "../../../../components/Signature/SignatureComponent";
import service, { ErrorResponse, ISignature } from "../../../../services/SignaturesService";
import Auth from "../../../../Auth/Auth";
import { parseNumber } from "../../../../utils/toFixedDecimals";
import { GenericMessageModalWithoutButton } from "../../../../components/Modals/GenericModal";
import "./OrderCancelAutomatic.sass";

const OrderCancelAutomatic = (props: any) => {
  const {
    amount,
    date,
    isinOrig,
    nameOrig,
    isinDesty,
    nameDesty,
    status,
    statusMessage,
    typeText,
    type,
    iban,
  } = props.location.state;

  const { finametrixId } = useParams<any>();
  const history = useHistory();
  const processHours: any = Auth.getProcessHours();
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState<OrderType>();
  const [sending, setSending] = useState<boolean>(false);
  const [signature, setSignature] = useState<ISignature>();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userConfirmation, setUserConfirmation] = useState({
    user: false,
    disclaimer: false,
  });
  const [serviceError, setServiceError] = useState<ErrorResponse>();
  const [waitingForResponse, setWaitingForResponse] = React.useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (order !== undefined) {
      var operationPhone = order.userIdentity?.operationPhone;
      if (operationPhone !== undefined) {
        setPhoneNumber(
          "X".repeat(operationPhone.length - 3) +
            operationPhone.slice(
              operationPhone.length - 3,
              operationPhone.length
            )
        );
      }
    }
  }, [order]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const order = await OrderService.getOrderByFinametrixId(finametrixId);
      setSending(true);
      const updatedOrder = await OrderService.patchCancelOrder(order.id);
      setOrder(updatedOrder);
      setSignature(updatedOrder.signatureCancel);
    } catch (error: any) {
      setServiceError(
        error.message || "Hubo un error al procesar la solicitud."
      );
    } finally {
      setSending(false);
      setIsLoading(false);
    }
  };

  const submitSignature = (response: any) => {
    if (order !== undefined && signature !== undefined) {
      OrderService.inespayCancelOrden(order)
        .then((response: any) => {
          if (response.errorMsg) {
            setServiceError({status: true, message: response.errorMsg});
          } else {
            setWaitingForResponse(true);
          }
        })
        .catch((error) => setServiceError({status: true, message: error.response.data}))
    }
  };

  const processExpirationDate = (signature: ISignature) => {
    if (signature && typeof signature.expiresAt === "string") {
      signature.expiresAt = moment(signature.expiresAt).toDate();
    }
  };

  if (processHours === undefined) {
    service.getProcessHours().then((response: any) => {
      if (response.status === 200 && response.data.length > 0) {
        Auth.setProcessHours(response.data);
      }
    });
  }

  if (serviceError?.status) {
    return <div>Error: {serviceError.message}</div>;
  }

  if (order != undefined && (signature == null || signature == undefined)) {
    return <div>Error: El proceso de firma ha fallado.</div>;
  }
  

  return (
    <DocumentTitle title="IronIA - Cancelación de order">
      <div className="cancel-order">
        <div className="ms-Grid-row">
          <h2>Cancelación de orden</h2>
        </div>
        {/* { <button onClick={submitSignature}>Enviar</button> } */}
        {isLoading && <Spinner size={SpinnerSize.large} />}
        {serviceError?.status == true && <div>Error: {serviceError.message}</div>  }
        {!isLoading &&
          order != undefined &&
          (signature !== undefined || signature != null) && (
            <>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12">
                  <div
                    className="form rounded shadow"
                    style={{ marginTop: "10px" }}
                  >
                    <h3>
                      <b>Resumen de la orden</b>
                    </h3>

                    <table style={{ marginBottom: "20px" }}>
                      <tr className="cancel-order-tr">
                        <th className="cancel-order-th">Fecha</th>
                        <td className="cancel-order-td">{date}</td>
                      </tr>
                      <tr className="cancel-order-tr">
                        <th className="cancel-order-th">Estado</th>
                        <td>{status}</td>
                      </tr>
                      <tr className="cancel-order-tr">
                        <th className="cancel-order-th">Detalle del estado</th>
                        <td>{statusMessage}</td>
                      </tr>
                      <tr className="cancel-order-tr">
                        <th className="cancel-order-th">Tipo de orden</th>
                        <td>{typeText}</td>
                      </tr>
                      <tr className="cancel-order-tr">
                        <th className="cancel-order-th">Importe</th>
                        <td>
                          {isNaN(parseNumber(amount, 2))
                            ? amount
                            : parseNumber(amount, 2) + " €"}
                        </td>
                      </tr>
                      <tr className="cancel-order-tr">
                        <th className="cancel-order-th">Fondo</th>
                        <td>
                          {type === "subscription" && (
                            <p>
                              <span
                                className="fund-name"
                                style={{
                                  color: "rgb(66,132,230)",
                                  cursor: "pointer",
                                }}
                                onClick={() => history.push(`/${isinOrig}`)}
                              >
                                {isinOrig}
                                {" - "}
                                {nameOrig} - Destino:{" "}
                                <span
                                  className="fund-name"
                                  style={{
                                    color: "rgb(66,132,230)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => history.push(`/${isinDesty}`)}
                                >
                                  {isinDesty}
                                  {" - "}
                                  {nameDesty}
                                </span>
                              </span>
                            </p>
                          )}
                          {type === "refund" && (
                            <>
                              <p>
                                Origen:{" "}
                                <span
                                  className="fund-name"
                                  style={{
                                    color: "rgb(66,132,230)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => history.push(`/${isinOrig}`)}
                                >
                                  {isinOrig}
                                  {" - "}
                                  {nameOrig}
                                </span>{" "}
                                - Destino:{" "}
                                <span
                                  className="fund-name"
                                  style={{
                                    color: "rgb(66,132,230)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => history.push(`/${isinDesty}`)}
                                >
                                  {isinDesty}
                                  {" - "}
                                  {nameDesty}
                                </span>
                              </p>
                              <p>
                                IBAN Destino:{" "}
                                <span className="iban">{iban}</span>
                              </p>
                            </>
                          )}
                          {!["subscription", "refund"].includes(type) && (
                            <div>
                              <p>
                                Origen:{" "}
                                <span
                                  className="fund-name"
                                  style={{
                                    color: "rgb(66,132,230)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => history.push(`/${isinOrig}`)}
                                >
                                  {isinOrig}
                                  {" - "}
                                  {nameOrig}
                                </span>{" "}
                                - Destino:{" "}
                                <span
                                  className="fund-name"
                                  style={{
                                    color: "rgb(66,132,230)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => history.push(`/${isinDesty}`)}
                                >
                                  {isinDesty}
                                  {" - "}
                                  {nameDesty}
                                </span>
                              </p>
                            </div>
                          )}
                        </td>
                      </tr>
                    </table>
                    {(signature !== undefined || signature != null) &&
                      order?.userIdentity !== undefined && (
                        <SignatureComponent
                          userIdentities={[order.userIdentity]}
                          signatures={[signature]}
                          alreadySignedText="¡La cancelación de la incidencia está en proceso!"
                          title=""
                          submitButtonText="Firmar"
                          cancelButtonText="Cancelar"
                          headerTextArea={`Confirma la order con la clave enviada a tu teléfono ${phoneNumber}.`}
                          backButtonText="Volver"
                          onBackButtonClicked={() =>
                            history.push("/portfolio/orders")
                          }
                          onCancelButtonClicked={() =>
                            history.push("/portfolio/orders")
                          }
                          requestNewCodeButtonText="Recibir nuevo código"
                          confirmElement={{
                            text: "Confirmo haber recibido, leído y comprendido la documentación obligatoria de IIC's y realizar esta operación por iniciativa propia.",
                            value: userConfirmation.user,
                            setValue: (value: boolean) =>
                              setUserConfirmation({
                                user: !!value,
                                disclaimer: userConfirmation.disclaimer,
                              }),
                          }}
                          onNewCodeSended={(signatures: any) => {
                            signatures.forEach((signature: any) => {
                              processExpirationDate(signature);
                            });
                            setSignature(signatures[0]);
                          }}
                          onSignatureChanged={(signature: any) => {
                            processExpirationDate(signature);
                            setSignature(signature);
                          }}
                          onSigned={(signature: any) => {
                            processExpirationDate(signature);
                            setSignature(signature);
                            submitSignature(signature);
                          }}
                        />
                      )}
                       {waitingForResponse && (
                      <div style={{textAlign: "center", marginTop: "10px", display: "flex", alignItems: "center"}}>
                        <Spinner
                          size={SpinnerSize.large}
                          className="button-spinner"
                        />
                        <p style={{fontSize: "20px", marginLeft: "10px"}}>Esperando la respuesta del servidor. Será redireccionado próximamente...</p>
                      </div>
                    )}
                    <div className="ms-Grid-row p10">
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12">
                        <div className="section-row">
                          <br />
                          <div className="warning-container">
                            <div>
                              <img
                                src="/icons/information.svg"
                                alt="Información"
                              />
                            </div>
                            <h3>Descarga de responsabilidad</h3>
                            <p>
                              El proceso de cancelación de orderes es un proceso
                              especial que depende de muchos factores ajenos
                              Simplicitas Capital y Diaphanum SLV, dicho proceso
                              se va a realizar bajo el compromiso de mejor
                              esfuerzo, este compromiso establece que las
                              empresas antes citadas realizarán todo lo que este
                              en su mano para la ejecución del proceso, no
                              constituyendo una garantia para ningun tipo de
                              reclamación la no ejecución de este proceso.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
    </DocumentTitle>
  );
};

export default OrderCancelAutomatic;

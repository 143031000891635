import { useContext, useState } from "react";
import { ThemeContext } from "@fluentui/react-theme-provider";
import styled from "styled-components";
import { FnmContract, FnmContractsUserIdentity } from "../../../../services/FnmContractService";
import Attachments from '../../../../services/AttachmentService';
import { Modal, SpinnerSize, Spinner, mergeStyleSets } from '@fluentui/react';
import { useBoolean } from "@fluentui/react-hooks";
import SavingsPlanService from "../../../../services/SavingsPlanService";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
export const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    gap: 20px;
`;
export const CounterContainer = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 800;
    font-size: 18px;
    flex-grow: 1;
    padding: 5px 0;
    color: ${(props) => props.theme.palette.neutralText};
    h3
    {
        margin: 0;
        font-size: 18px;
        font-weight: 800;
        color: ${(props) => props.theme.palette.black};
    }
`;
export const ActionsContainer = styled.div`
    display: flex;
    button
    {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
        background: ${(props) => props.theme.palette.white};
        padding: 0 10px;
        border-radius: 16px;
        color: ${(props) => props.theme.palette.lightBlack};
        border: 1px solid ${(props) => props.theme.palette.neutralLighter};
        cursor: pointer;
    }
`;
export const TableContainer = styled.div`
    overflow-x: auto;
    display: flex;
    flex: 1;
    border-radius: 16px;
    box-shadow: 0px 0px 16px rgba(8, 84, 117, 0.08);
    padding: 25px 20px;
    margin-bottom: 20px;
  
    @media screen and (min-width: 1200px)
    {
        margin: 0 auto 20px auto;
    }
`;
export const Table = styled.table`
    width: 100%;
    height: 100%;
    border-spacing: 0;
    text-align: left;
    background: ${(props) => props.theme.palette.white};

    thead
    {
        th
        {
            background: ${(props) => props.theme.palette.tertiary};
            font-weight: 500;
            padding: 17px;
            text-align: center;
        }
        th:first-of-type
        {
            border-top-left-radius: 10px;
        }
        th:last-of-type
        {
            border-top-right-radius: 10px;
        }
    }
  
    tr {
        td
        {
            padding: 16px;
            text-align: right;
            font-size: 12px;
            color: ${(props) => props.theme.palette.neutralText};
            > a
            {
                color: ${(props) => props.theme.palette.darkBorder};
                font-weight: 600;
                line-height: 20px;
                font-size: 14px;
                cursor: pointer;
            }

            &:nth-child(1),
            &:nth-child(3) {
                text-align: left !important;
            }
        }
    }
`;

const contentStyles = mergeStyleSets(
{
    container:
    {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        padding: '45px',
        borderRadius: '16px',
        selectors:
        {
            h3:
            {
                textAlign: 'center',
                marginBottom: '20px'
            }
        },
    }
});

export interface ITableProps
{
    tableTitle: string,
    rowData: FnmContract[] | undefined,
    columnTitles: {id: string, title: string}[],
    tableCount: number | undefined
};

const OwnershipTable = (props : ITableProps) =>
{
    const theme = useContext(ThemeContext);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const downloadBase64Pdf = (event: any, fnmContract: FnmContract, signedContract: string) =>
    {
        event.preventDefault();
        showModal();
        Attachments.getBlobAttachment(signedContract).then((response) =>
        {
            const file = new Blob([response.data],{type: response.headers['content-type']});
            var downloadLink = document.createElement("a");
            downloadLink.download = fnmContract.contractName;
            downloadLink.href = URL.createObjectURL(file);
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            hideModal();
        })
        .catch((error) =>
        {
            console.log(error);
        });
    };

    return(
        <Wrapper>
            <Header>
                <CounterContainer theme={theme}>
                    <h3>{props.tableTitle}</h3>
                    <span>({props.tableCount})</span>
                </CounterContainer>
                <ActionsContainer theme={theme}></ActionsContainer>
            </Header>
            <TableContainer>
                <Table theme={theme}>
                    <thead>
                        <tr>
                            {props.columnTitles.map((column, columnIndex) =>
                                {
                                return <th key={'header-' + columnIndex}>{column.title}</th>;
                                }
                            )}
                        </tr>
                    </thead>
                    {props.rowData?.map((row: FnmContract, rowIndex) => (
                        <tbody key={'fnmContractsUserIdentities' + rowIndex}>
                            {row.fnmContractsUserIdentities.map((fnmContractsUserIdentity: FnmContractsUserIdentity, fnmContractsUserIdentitiesIndex: number) => (
                                <>
                                    <tr key={'fnmContractsUserIdentities-' + rowIndex + '-' + fnmContractsUserIdentitiesIndex}>
                                        <td>{row.contractName}</td>
                                        <td>{row.date}</td>
                                        <td>{row.savingsPlanName}</td>
                                        <td>{row.contractCode}</td>
                                        <td>{fnmContractsUserIdentity.version}</td>
                                        <td>{fnmContractsUserIdentity.signDate ?? ''}</td>
                                        <td>{fnmContractsUserIdentity.idDocument}</td>
                                        <td>
                                            <a
                                                href={'#' + row.contractName}
                                                onClick={(event) => downloadBase64Pdf(event, row, fnmContractsUserIdentity.signedContract)}
                                            >
                                                <i className={'ironia-icon downloads'} /> Descargar
                                            </a>
                                        </td>
                                    </tr>
                                    {fnmContractsUserIdentity.signedManagementAuthorizationContract !== undefined
                                        && fnmContractsUserIdentity.signedManagementAuthorizationContract !== null && (
                                        <tr key={'fnmContractsUserIdentities-' + rowIndex + '-' + fnmContractsUserIdentitiesIndex}>
                                            <td>Contrato de autorización</td>
                                            <td>{row.date}</td>
                                            <td>{row.savingsPlanName}</td>
                                            <td>{row.contractCode}</td>
                                            <td>{fnmContractsUserIdentity.version}</td>
                                            <td>{fnmContractsUserIdentity.signDate ?? ''}</td>
                                            <td>{fnmContractsUserIdentity.idDocument}</td>
                                            <td>
                                                <a
                                                    href={'#' + row.contractName}
                                                    onClick={(event) => downloadBase64Pdf(event, row, fnmContractsUserIdentity.signedManagementAuthorizationContract)}
                                                >
                                                    <i className={'ironia-icon downloads'} /> Descargar
                                                </a>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            ))}
                        </tbody>
                    ))}
                </Table>
            </TableContainer>
            {isModalOpen &&
            (
                <Modal isOpen={isModalOpen}
                    onDismiss={hideModal}
                    containerClassName={contentStyles.container}
                    isBlocking={false}
                >
                    <h3>Descargando archivo</h3>
                    <Spinner size={SpinnerSize.large} />
                </Modal>
            )}
        </Wrapper>
    );
};

export default OwnershipTable;
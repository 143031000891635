import styled from 'styled-components';
import { DefaultButton } from '@fluentui/react/lib/Button';

export const Cont = styled.div`
  grid-area: main;
  background-color: #fafcfd;
`;

export const Steps = styled.div`
  width: 400px;

  & h3 {
    font-size: 22px;
    color: #cc214f;
    word-spacing: -5px;
  }

  & h3 span {
    color: #beced5;
  }
`;

export const MainCont = styled.div`
  padding: 40px 55px 150px 55px;
`;

export const Title = styled.div`
  margin-top: 30px;

  & h1 {
    font-size: 30px;
    color: #2a4143;
    font-weight: 800;
  }

  & h2 {
    font-size: 24px;
    font-weight: 400;
    color: #5f7172;
  }

  & h2 span {
    color: #4284e6;
  }
`;

export const BarCont = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  margin-top: 30px;
`;
export const BgBar = styled.hr`
  position: absolute;
  width: 100%;
  border: solid 6px #fae9ed;
  border-radius: 10px;
  top: 50%;
  transform: translate(0%, -50%);
`;

export const ProsBar = styled.hr`
  position: absolute;
  width: ${(props) =>
    // @ts-ignore
    props.barCount + '%'};
  border: solid 4px #cc214f;
  border-radius: 10px;
  top: 50%;
  transform: translate(0%, -50%);
`;

export const FieldSet = styled.fieldset`
  border: 0;

  &.grouped {
    position: relative;
    margin: 20px 0;
    border-bottom: 1px solid lightgrey;

    legend {
      padding: 10px 20px;
      background: lightgrey;
      width: 100%;
      box-sizing: border-box;
      font-size: 18px;
      line-height: 30px;
    }

    div {
      form {
        padding: 20px;
        border: 1px solid lightgrey;
        border-top: 0;
        border-bottom: 0;
        margin-top: 0;
      }
    }
  }
`;

export const Block = styled.div`
  margin-bottom: 30px;
`;

export const TableCont = styled.div`
  background-color: #f3f7f9;
  width: 100%;
  padding: 10px 25px 30px 25px;
  border-radius: 20px;
  margin-top: 30px;

  table {
    margin-top: 20px;

    th {
      padding: 0 10px 0 5px;
    }

    tr {
      border-bottom: 2px solid #e9eff2;
      height: 46px;

      td {
        label {
          margin: 5px 0;
        }

        &:first-child {
          padding-right: 20px;
        }
      }
    }
  }
`;

export const RadioCont = styled.form`
  margin-top: 30px;
  padding: 0 20px;
`;

export const CheckBoxCont = styled.div`
  padding: 0 20px;
`;

export const Question = styled.p`
  font-weight: 500;
  color: #2a4143;
  font-size: 17px;
`;

export const SubTitle = styled.h4`
    color: #2a4143;
    font-size: 18px;
    font-weight: 500;

    span {
        color: #4284e6;
    }
`;

export const Description = styled.p`
  font-weight: 400;
  color: #2a4143;
  font-size: 17px;
`;

export const RadioSelect = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  text-align: left;
  color: #cc214f;
`;

export const CheckSelect = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  text-align: left;
  color: #cc214f;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  color: #2a4143;
  font-size: 17px;
  padding-bottom: 10px;
  padding-top: 10px;
`;

export const Radio = styled.input`
  display: block;
  margin-right: 15px;
  min-width: 20px;
  height: 20px;

  &:checked:after {
    width: 16px;
    height: 16px;
    border-radius: 15px;
    top: 2px;
    left: 2px;
    position: relative;
    background-color: #cc214f;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid white;
  }
`;

export const Checke = styled.input`
  display: block;
  margin-right: 15px;
  min-width: 20px;
  height: 20px;

  &:checked:after {
    width: 16px;
    height: 16px;
    //border-radius: 15px;
    top: 2px;
    left: 2px;
    position: absolute;
    background-color: #cc214f;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #cc214f;
    background-color: #cc214f;
  }
  &:checked:before {
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    top: 2px;
    left: 2px;
    position: absolute;
    border-radius: 2px;
    background-color: #cc214f;
    content: '';
    display: inline-block;
    border: 1px solid #cc214f;
    background-color: #cc214f;
  }
`;

//* styled radio
export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 38px;
  cursor: pointer;
  font-weight: 500;
  color: #2a4143;
  font-size: 17px;
  user-select: none;
  height: 20px;
  margin: 10px 0;
  width: fit-content;

  &:hover input ~ span {
    background-color: #ccc;
  }

  & input:checked ~ span {
    background-color: #ffffff;
  }

  & input:checked ~ span:after {
    display: block;
  }

  & span:after {
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #cc214f;
  }
`;

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const RadioCheck = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #c3d2d9;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 3px !important;
    left: 3px !important;
  }
`;

//* Styled checkbox
export const CheckLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 38px;
  cursor: pointer;
  font-weight: 500;
  color: #2a4143;
  font-size: 17px;
  user-select: none;
  height: 25px;
  margin: 15px 0;
  width: fit-content;

  &:hover input ~ span {
    background-color: #ccc;
  }

  & input:checked ~ span {
    background-color: #cc214f;
    border: 1px solid #b21540;
  }

  & input:checked ~ span:after {
    display: block;
  }

  & span:after {
    left: 7px;
    top: 4px;
    width: 7px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

/* ocultar check simbolo */
export const CheckInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

/* el checkmark */
export const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #f3f7f9;
  border: 1px solid #c3d2d9;
  border-radius: 5px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 3px !important;
    left: 6px !important;
  }
`;

export const Multi = styled.div`
    display: inline-block;
    padding-left: 20px;
    margin-top: 30px;

    .checkbox {
        float: left;
        clear: left;
    }

    .extra {
        float: left;
        padding: 15px 0 15px 5px;

        input[type='number'] {
            width: 55px;
            margin-left: 5px;
            line-height: 24px;
            border: 1px solid #c3d2d9;
        }
    }
`;

export const AproveCont = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 50px;
`;

export const CheckMarkFinal = styled.span`
  position: absolute;
  top: -15px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #f3f7f9;
  border: 1px solid #c3d2d9;
  border-radius: 5px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 3px !important;
    left: 6px !important;
  }
`;

export const AproveDescription = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;

  & p {
    position: relative;
    top: -5px;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    color: #2a4143;

    &:first-child {
      margin-top: 6px;
    }
  }
`;

export const BtnCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const ContinueBtn = styled(DefaultButton)`
  background-color: ${(props) => (props.disabled ? '#f0bcca' : '#cc214f')};
`;

export const Error = styled.span`
  width: fit-content;
  top: 83%;
  left: 0;
  margin: 0;
  color: hsl(0, 100%, 63%);
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;

  &.floating {
    float: left;
    clear: both;
  }
`;
import { Spinner, SpinnerSize } from "@fluentui/react";
import { HistoryIcon } from "@fluentui/react-icons-mdl2";
import React from "react";
import { SearchFundsMinType } from "../../../services/FundService";
import Styles from "./index.module.css";
import SearchHeadTitle from "./SearchHeadTitle";
import { useHistory } from "react-router-dom";
import { AzureNewsType } from "../../../services/AzureSearchServices";
import { ai } from "../../../services/TelemetryService";
import styled from "styled-components";

type Props =
{
	theme: any;
	searchTerm: string;
	isLoading: boolean;
	fundsResult: SearchFundsMinType[];
	news: AzureNewsType[];
	fundsByCompany: SearchFundsMinType[];
	top5searchTermHistory: string[];
	onClose: (close: boolean) => any;
	changedSearchTerm: (newTerm: string) => any;
};

export const MainBoxLayout = styled.div`
    padding: 8px 16px 16px 16px;
    border-radius: 15px;
    position: absolute;
    z-index: 100000;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    margin: 0px 10px;
    box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
    font-weight: 400;
    font-size: 14px;
    font-family: "Barlow";
    background: ${(props) => props.theme.palette.backgroundSearch};
`;

const SearchResults = (props: Props) =>
{
	const {searchTerm, isLoading, fundsResult, news, fundsByCompany, top5searchTermHistory, theme, onClose, changedSearchTerm} = props;
	let history = useHistory();
	const appInsights = ai.reactPlugin;
	const HandleNavigateClose = (navigateUrl: string, withState?: boolean) =>
    {
		if(withState)
        {
			history.push(navigateUrl, {searchTerm: searchTerm});
		}
        else
        {
			history.push(navigateUrl);
		}
		onClose(true);
	}
	const handleChangeSearchTerm = (newTerm: string) =>
    {
		if(changedSearchTerm)
        {
			changedSearchTerm(newTerm);
		}
	}

	return(
		<MainBoxLayout theme={theme}>
			{(!searchTerm || searchTerm.length < 3) &&
            (
				<React.Fragment>
					<SearchHeadTitle title="Historial de búsqueda" />
					{top5searchTermHistory && top5searchTermHistory.length > 0 && top5searchTermHistory.map((historicTerm, index) =>
                    (
						<div key={index} className={Styles.NavigationBlock} onClick={() => {handleChangeSearchTerm(historicTerm)}}>
                            <HistoryIcon className={Styles.HistoryIcon} style={{cursor: "pointer !important"}} /> 
                            <div className={Styles.NavigationText}>
                                {historicTerm}
                            </div>
                        </div>
                    ))}
					{top5searchTermHistory.length === 0 &&
                    (
						<div className={Styles.NavigationBlock}>
							<div className={Styles.NavigationText}>Sin Historial de búsqueda</div>
						</div>
					)}
				</React.Fragment>
			)}
			{isLoading && <Spinner size={SpinnerSize.large} />}
			{searchTerm && searchTerm.length >= 3 &&
            (
				<React.Fragment>
					{!isLoading && fundsByCompany.length > 0 && <SearchHeadTitle title="Empresas" />}
					{!isLoading && fundsByCompany && fundsByCompany.map((fund, index) =>
                    (
                        <div key={index} className={Styles.NavigationBlock} onClick={() => HandleNavigateClose(`/${fund.isin}`)}>
                            <div className={Styles.NavigationText}>
                                {fund.isin}
                                {" - "}
                                {fund.name}
                            </div>
                        </div>
                    ))}
					{!isLoading && fundsResult.length > 0 && <SearchHeadTitle title="Productos" onClickViewMore={() => HandleNavigateClose(`/search`, true)} />}
					{!isLoading && fundsResult && fundsResult.map((fund, index) =>
                    (
                        <div key={index} className={Styles.NavigationBlock} onClick={() => HandleNavigateClose(`/${fund.isin}`)}>
                            <div className={Styles.NavigationText}>
                                {fund.isin}
                                {" - "}
                                {fund.name}
                            </div>
                        </div>
                    ))}
					{!isLoading && news.length > 0 && <SearchHeadTitle title="Noticias" onClickViewMore={() => HandleNavigateClose(`/search/news`, true)} />}
					{!isLoading && news && news.map((item, index) =>
                    (
                        <div key={index} className={Styles.NavigationBlock} onClick={() =>
                            {
                                appInsights.trackEvent({name: "Buscador IronIa News", properties: {id: item.Id, titulo: item.Title}});
                                window.open(item.Url, "_blank", "noreferrer");
                                onClose(true);
                            }}
                        >
                            <div className={Styles.NavigationText}>{item.Title}</div>
                        </div>
                    ))}
					{!isLoading && news.length === 0 && fundsResult.length === 0 && fundsByCompany.length === 0 &&
                    (
						<div className={Styles.NavigationBlock}>
							<div className={Styles.NavigationText}>No se han encontrado resultados</div>
						</div>
					)}
				</React.Fragment>
			)}
		</MainBoxLayout>
	);
};

export default SearchResults;
import { DefaultButton, FontWeights, getTheme, IButtonStyles, IconButton, IIconProps, ILabelStyles, Label, mergeStyleSets, Modal, Stack, TextField } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import * as React from 'react';
import { useState } from 'react';
import CustomIcon from '../icons/CustomIcon';
import * as Style from "./ActiveSavingPlansStyle";

const theme = getTheme();
const contentStyles = mergeStyleSets(
{
    container:
    {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        borderRadius: '2em',
        minWidth: "500px !important"
    },
    header:
    [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            color: '#CC214F',
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body:
    {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors:
        {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const styleLabel: Partial<ILabelStyles> =
{
    root:
    {
        fontSize: '19px !important',
        padding: '0 45px',
        margin: '0px',
        textAlign: "center",
        width: "100%"
    }
};
const iconButtonStyles: Partial<IButtonStyles> =
{
    root:
    {
        color: '#CC214F',
        marginLeft: 'auto',
        marginRight: '2px',
    },
    rootHovered:
    {
        color: theme.palette.neutralDark,
    }
};
const styleDefButton1: Partial<IButtonStyles> =
{
    root:
    {
        marginLeft: '0',
        marginTop: '1em',
        cursor: 'pointer',
        background: '#CC214F',
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'center',
        color: '#FFFFFF',
        borderRadius: '100px',
        padding: '0.5em 1.5em 0.5em 1.5em',
        borderStyle: 'none',
    }
};
const styleDefButton2: Partial<IButtonStyles> =
{
    root:
    {
        marginLeft: '2em',
        marginTop: '1em',
        cursor: 'pointer',
        background: '#CC214F',
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'center',
        color: '#FFFFFF',
        borderRadius: '100px',
        padding: '0.5em 1.5em 0.5em 2em',
        borderStyle: 'none',
    }
};
export interface Props
{
    name: string,
    onSubmit?: (item: string) => any,
    isModelPortfolio?: boolean,
    marginRightIcon?: string,
    paddingTopIcon?: string
}
export const ModalChangePlanName : React.FunctionComponent<Props> = (props: Props) =>
{
    const titleId = useId('title');
    const { isModelPortfolio } = props;
    const [isModalValidationOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const [value, setValue] = useState(props.name);
    const [error, setError] = useState("");

    function cleanModal()
    {
        setValue('');
    };
    const handleChange = (e: any) =>
    {
        setValue(e.target.value);

        if(e.target.value !== "" && e.target.value !== undefined)
        {
            setError("");
        }
    }
    const onSubmit = async () =>
    {
        if(value === "" || value === undefined)
        {
            setError("Debes introducir un valor válido");
        }
        else
        {
            if(props.onSubmit !== undefined)
            {
                setError("");
                props.onSubmit(value);
                hideModal();
            }
        }
    }
    
    let textLabel = isModelPortfolio ? "Nombre de la Cartera Modelo" : "Nombre del Plan";
    let textLabelHeader = isModelPortfolio ? "Renombre la Cartera Modelo" : "Renombre el Plan de Cartera";
    
    return(
        <React.Fragment>
            <Style.RenameBtn onClick={showModal}>
                {props.marginRightIcon !== undefined && 
                    <CustomIcon iconName="Rename" size="21" color="#CC214F" marginRight={props.marginRightIcon} paddingTop={props.paddingTopIcon} onClick={showModal} />
                }
                {props.marginRightIcon === undefined &&
                    <CustomIcon iconName="Rename" size="21" color="#CC214F" paddingTop={props.paddingTopIcon} onClick={showModal} />
                }
            </Style.RenameBtn>
            <Modal titleAriaId={titleId} isOpen={isModalValidationOpen} onDismiss={hideModal} isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <Label styles={styleLabel} id={titleId}>{textLabelHeader}</Label>                   
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Cerrar modal" onClick={hideModal} />
                </div>
                <div className={contentStyles.body}>
                    <TextField value={value} required label={textLabel} placeholder="Introduzca un nombre" onChange={handleChange} />
                    {(error !== undefined || error !== "") && <Label>{error}</Label>}
                </div>
                <Stack>
                    <Stack.Item style={{ display: "flex", justifyContent: "center" }}>
                        <DefaultButton styles={styleDefButton1} aria-label="Limpiar" onClick={cleanModal} text="Limpiar" />
                        <DefaultButton styles={styleDefButton2} onClick={onSubmit}>Renombrar</DefaultButton>
                    </Stack.Item>
                </Stack>
            </Modal>
        </React.Fragment>
    );
}
import { DefaultButton, FontWeights, getTheme, IButtonStyles, ILabelStyles, Label, mergeStyleSets, Modal, Stack } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import * as React from 'react';

import styled from "styled-components";

const theme = getTheme();
const RenameBtn = styled.button`
    border: 0px;
    width: 100%;
    height: 50%;
    padding: 0px;
`;
const contentStyles = mergeStyleSets(
{
    container:
    {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        borderRadius: '2em',
        minWidth: "500px !important"
    },
    header:
    [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            color: '#CC214F',
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body:
    {
        textAlign: 'center',
        flex: '4 4 auto',
        padding: '0 24px 12px 24px',
        overflowY: 'hidden',
        selectors:
        {
            p:
            {
                margin: '0'
            },
            'p:first-child':
            {
                marginTop: 0
            },
            'p:last-child':
            {
                marginBottom: 0
            }
        }
    }
});
const styleLabel: Partial<ILabelStyles> =
{
    root:
    {
        fontSize: '19px !important',
        padding: '0 45px',
        margin: '0px',
        textAlign: "center",
        width: "100%"
    }
};
const styleDefButton1: Partial<IButtonStyles> =
{
    root:
    {
        marginLeft: '0',
        marginTop: '1em',
        cursor: 'pointer',
        background: '#CC214F',
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'center',
        color: '#FFFFFF',
        borderRadius: '100px',
        padding: '0.5em 1.5em 0.5em 1.5em',
        borderStyle: 'none',
    }
};
const styleDefButton2: Partial<IButtonStyles> =
{
    root:
    {
        marginLeft: '2em',
        marginTop: '1em',
        cursor: 'pointer',
        background: '#CC214F',
        fontFamily: 'Barlow',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'center',
        color: '#FFFFFF',
        borderRadius: '100px',
        padding: '0.5em 1.5em 0.5em 2em',
        borderStyle: 'none',
    }
};
export interface Props
{
    headerLabel: string,
    bodyLabel: string,
    icon: JSX.Element | JSX.Element[],
    onSubmit?: (accepted: boolean) => any
}
export const GenericMessageModal : React.FunctionComponent<Props> = (props: Props) =>
{
    const titleId = useId("title");
	const { headerLabel, bodyLabel, icon } = props;
	const [isModalValidationOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const onSubmit = async () =>
    {
		if(props.onSubmit !== undefined)
        {
			props.onSubmit(true);
		}
		hideModal();
	};
    
    return(
        <React.Fragment>
            <RenameBtn onClick={showModal}>{icon}</RenameBtn>
            <Modal titleAriaId={titleId} isOpen={isModalValidationOpen} onDismiss={hideModal} isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <Label styles={styleLabel} id={titleId}>{headerLabel}</Label>
                </div>
                <div className={contentStyles.body}>
                    <p style={{margin: 0}}>{bodyLabel}</p>
                </div>
                <Stack>
                    <Stack.Item style={{display: "flex", justifyContent: "center"}}>
                        <DefaultButton styles={styleDefButton1} aria-label="Cerrar" onClick={hideModal} text="Cerrar" />
                        <DefaultButton styles={styleDefButton2} onClick={onSubmit}>Confirmar</DefaultButton>
                    </Stack.Item>
                </Stack>
            </Modal>
        </React.Fragment>
    );
}
import axios from "axios";
import { setupInterceptorsTo } from "./Interceptors";
import { optimizerMarkowitzCode, optimizerUrl, proxyUrl } from "../config";
setupInterceptorsTo(axios);

export type Portfolio = {
    Portfolio: PortfolioType;
};

export type Views = {
    Views: PortfolioType;
};

export type PortfolioType = {
    [isin: string]: number;
};

export type OptimizerResponse = {
    Years: Year[];
};

export type Year = {
    Year: number;
    AnnualReturn: number;
    AnnualVolatility: number;
    [isin: string]: number;
};

export type OrderData = {
    isin: string;
    amount: number;
    weight: number;
};

export type OrdersResponse = {
    sourceId: string;
    sourceName: string;
    sourceIsin: string;
    targetId: string;
    targetName: string;
    targetIsin: string;
    amount: number;
    titles: number;
    skip: boolean;
};

class OptimizerService
{
    public optmizeMarkowitz = async (portfolio: Portfolio): Promise<OptimizerResponse> =>
    {
        let url: string = `${optimizerUrl}/api/Markowitz`;
        const headers = {"x-functions-key": optimizerMarkowitzCode};
        const req = await axios.post(url, portfolio, { headers });
        return req.data;
    };
    public optmizeLitterman = async (portfolio: Portfolio, views: Views): Promise<OptimizerResponse> =>
    {
        let url: string = `${optimizerUrl}/api/BlackLitterman`;
        const headers = {"x-functions-key": optimizerMarkowitzCode};
        const req = await axios.post(url, { ...portfolio, ...views }, { headers });
        return req.data;
    };
    public generateOrders = async (savingsPlanId: number, orders: OrderData[]): Promise<OrdersResponse[]> =>
    {
        let url: string = `${proxyUrl}/savingsPlans/${savingsPlanId}/optimize`
        const req = await axios.post(url, orders);
        return req.data;
    };
}

const optimizer = new OptimizerService();
export default optimizer;
import { DefaultButton, Modal, Spinner, SpinnerSize, TeachingBubble } from "@fluentui/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SavingsPlanService from "../../services/SavingsPlanService";
import CustomIcon from "../icons/CustomIcon";
import * as Style from "./SavingsPlansConfiguringStyle";

export interface ITableSPlansActive
{
	id: number;
	planName: string;
	startDate: string;
	status: number;
	canBeDeleted?: boolean;
	configurationMapStep: string;
}
export interface ITableProps
{
	columnTitles: string[];
	rowData: ITableSPlansActive[] | undefined;
	reload: (reloadData: boolean) => void;
}
const getStatusTxt = (status: number, step: string) =>
{
	var statusTxt = "";
	switch(status)
    {
		case 0:
			switch (step)
            {
				case "2.1":
					statusTxt = "Conocer a nuestro cliente";
					break;
				case "2.2":
					statusTxt = "Test de idoneidad";
					break;
				case "2.3":
					statusTxt = "Seleccionar intervinientes";
					break;
				case "2.4":
					statusTxt = "Autorización de gestión en la plataforma";
					break;
				case "2.5":
					statusTxt = "Firma Contrato";
					break;
			}
			break;
		case 1:
			statusTxt = "Pendiente de validación";
			break;
		case 2:
			statusTxt = "Activar";
			break;
		case 3:
			statusTxt = "Firmar";
			break;
		case 7:
			statusTxt = "Pendiente de confirmación TPV";
			break;
		default:
			statusTxt = "";
	}

	return statusTxt;
};

export const SavingsPlansConfiguringTable = (props: ITableProps) =>
{
	const history = useHistory();
	const [showModalDeleteSavingPlanOnConfigure, setShowModalDeleteSavingPlanOnConfigure] = useState<boolean>(false);
	const [savingPlanToDeleteId, setSavingPlanToDeleteId] = useState<number>();
	const [savingPlanToDeleteName, setSavingPlanToDeleteName] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tooltipVisibilities, setTooltipVisibilities] = React.useState<boolean[]>([]);
    const [tooltipDelete, setTooltipDelete] = React.useState<boolean[]>([]);
	const handleShowDeletePlanModal = (id: number, name: string) =>
    {
		setShowModalDeleteSavingPlanOnConfigure(true);
		setSavingPlanToDeleteId(id);
		setSavingPlanToDeleteName(name);
	};
	const handleCloseDeletePlanModal = () =>
    {
		setShowModalDeleteSavingPlanOnConfigure(false);
		setSavingPlanToDeleteId(undefined);
		setSavingPlanToDeleteName(undefined);
	};
    const handleDeletePlan = () =>
    {
        setIsLoading(true);
		SavingsPlanService.deleteSavingPlanOnConfigure(savingPlanToDeleteId!).finally(() =>
        {
            if(props.reload !== undefined)
            {
				props.reload(true);
			}
            setIsLoading(false);
			handleCloseDeletePlanModal();
		});
	};
    const handleMouseEnter = (index: number) =>
    {
        const newTooltipVisibilities = [...tooltipVisibilities];
        newTooltipVisibilities[index] = true;
        setTooltipVisibilities(newTooltipVisibilities);
    };
    const handleMouseLeave = (index: number) =>
    {
        const newTooltipVisibilities = [...tooltipVisibilities];
        newTooltipVisibilities[index] = false;
        setTooltipVisibilities(newTooltipVisibilities);
    };
    const handleDeleteMouseEnter = (index: number) =>
    {
        const newTooltipDelete = [...tooltipDelete];
        newTooltipDelete[index] = true;
        setTooltipDelete(newTooltipDelete);
    };
    const handleDeleteMouseLeave = (index: number) =>
    {
        const newTooltipDelete = [...tooltipDelete];
        newTooltipDelete[index] = false;
        setTooltipDelete(newTooltipDelete);
    };

	return(
		<React.Fragment>
			<Style.Table>
				<thead>
					<tr>
						{props.columnTitles.length > 0 && props.columnTitles.map((column, columnIndex) =>
                        {
                            return(
                                <th style={{paddingLeft: 16}} key={columnIndex}>
                                    {column}
                                </th>
                            );
                        })}
					</tr>
				</thead>
				<tbody>
					{props.rowData !== undefined && props.rowData.length > 0 && props.rowData.map((row, index) =>
                    {
                        return(
							<React.Fragment key={index}>
								<tr>
									<td>{row.planName}</td>
									<td>{getStatusTxt(row.status, row.configurationMapStep)}</td>
									<td>{row.startDate}</td>
									<td>
										{row.status !== 7 &&
                                        (
											<React.Fragment>
                                                <div id={"edit"+index} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave(index)}>
                                                    {tooltipVisibilities[index] &&
                                                    (
                                                        <React.Fragment>
                                                            <TeachingBubble target={"#edit"+index} hasCloseButton={true} onDismiss={() => handleMouseLeave(index)} headline="Editar" closeButtonAriaLabel="Cerrar">
                                                                Pulsa para editar este Plan de Cartera en Configuración : {row.planName}
                                                            </TeachingBubble>
                                                        </React.Fragment>
                                                    )}
                                                    <CustomIcon iconName="Edit" size="16" color="#CC214F" paddingTop="13%" onClick={() => history.push(
                                                        {
                                                            pathname: `/configurationMap`,
                                                            state:
                                                            {
                                                                savingPlanId: row.id,
                                                                savingPlanName: row.planName
                                                            }
                                                        })}
                                                    />
                                                </div>
											</React.Fragment>
										)}
										{row.canBeDeleted === true &&
                                        (
											<React.Fragment>
                                                <div id={"delete"+index} className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3" onMouseEnter={() => handleDeleteMouseEnter(index)} onMouseLeave={() => handleDeleteMouseLeave(index)}>
                                                    {tooltipDelete[index] &&
                                                    (
                                                        <React.Fragment>
                                                            <TeachingBubble target={"#delete"+index} hasCloseButton={true} onDismiss={() => handleDeleteMouseLeave(index)} headline="Borrar" closeButtonAriaLabel="Cerrar">
                                                                Pulsa para borrar este Plan de Cartera en Configuración : {row.planName}
                                                            </TeachingBubble>
                                                        </React.Fragment>
                                                    )}
                                                    <CustomIcon iconName="Delete" size="16" color="#CC214F" paddingTop="13%" onClick={() => handleShowDeletePlanModal(row.id, row.planName)} />
                                                </div>
											</React.Fragment>
										)}
									</td>
								</tr>
							</React.Fragment>
						);
                    })}
				</tbody>
			</Style.Table>
			<Modal isOpen={showModalDeleteSavingPlanOnConfigure} onDismiss={() => handleCloseDeletePlanModal()} isBlocking={false} containerClassName="modal-container-ExistingAccountHolder">
				<div style={{display: "flex", justifyContent: "center"}}>
					<p style={{fontSize: "19px"}}>
                        {savingPlanToDeleteName}
                    </p>
				</div>
				<div style={{display: "flex", justifyContent: "center"}}>
					<p>
                        ¿Deseas borrar este plan de cartera en configuración?
                    </p>
				</div>
				<div style={{display: "flex", justifyContent: "center", padding: "10px"}}>
					{isLoading && <Spinner size={SpinnerSize.large} />}
					{!isLoading &&
                    (
						<React.Fragment>
							<DefaultButton className="button mini-primary-button" onClick={() =>
                                {
									handleCloseDeletePlanModal();
								}}
							>
								Cancelar
							</DefaultButton>
							<DefaultButton className="button mini-primary-button" onClick={() =>
                                {
									handleDeletePlan();
								}}
							>
								Borrar
							</DefaultButton>
						</React.Fragment>
					)}
				</div>
			</Modal>
		</React.Fragment>
	);
};

export default SavingsPlansConfiguringTable;
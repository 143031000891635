import React, { useState } from "react";
import styled from "styled-components";
import { Table } from "../../../components";
import { ActiveIcon, InactiveIcon } from "../../../components/icons/IconsTable";

export interface SubTableInternalProps
{
    name: string;
    gestora: string;
    category: string;
}

const SubTableInternalStyled = styled.div`
  div {
    width: 100%;
    min-width: max-content;
    margin-right: 1rem;
  }
`;

export const SubTableInternal: React.FC<any> = () =>
{
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            <tr>
                <td>
                    <div className="max-content">
                        <button className="expanded" onClick={() => setIsExpanded(!isExpanded)}>
                            {/* <strong>{name}</strong> */}
                        </button>
                    </div>
                </td>
                <td>{/* <p className="max-content">{gestora}</p> */}</td>
                <td>{/* <p className="max-content">{category}</p> */}</td>
                <td>
                    <div className="max-content">
                        <ActiveIcon />
                        <ActiveIcon />
                        <ActiveIcon />
                        <InactiveIcon />
                        <InactiveIcon />
                    </div>
                </td>
            </tr>
            {isExpanded && (
                <tr className="container-table">
                    <td colSpan={4}>
                        <SubTableInternalStyled>
                            <Table.TableStyled>
                                <thead>
                                    <tr>
                                        <th>
                                            <div>Nombre/ISIN</div>
                                        </th>
                                        <th>
                                            <div>Divisa</div>
                                        </th>
                                        <th>
                                            <div>Gastos Corrientes (OGC)</div>
                                        </th>
                                        <th>
                                            <div>Retrocesión</div>
                                        </th>
                                        <th>
                                            <div>Inversión Inicial Mínima</div>
                                        </th>
                                        <th>
                                            <div>Dividendos</div>
                                        </th>
                                        <th>
                                            <div>Aplica mínimo</div>
                                        </th>
                                        <th>
                                            <div>Clase limpia</div>
                                        </th>
                                        <th>
                                            <div>Disponible</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[1, 2, 3, 4, 5].map((item) => (
                                        <tr key={item}>
                                            <td>
                                                <div className="max-content">
                                                    <button className="expanded">
                                                        <strong>Eurizon Fund - Italian Equity Opportunites</strong>
                                                    </button>
                                                </div>
                                            </td>
                                            <td>EUR</td>
                                            <td>0.86%</td>
                                            <td>%</td>
                                            <td>500,000.00 &euro;</td>
                                            <td>No</td>
                                            <td>No</td>
                                            <td>Si</td>
                                            <td>No</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table.TableStyled>
                        </SubTableInternalStyled>
                    </td>
                </tr>
            )}
        </>
    );
};
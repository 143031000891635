import styled from 'styled-components';

interface ToggleProps
{
    toggleState: number
}
interface ButtonToggleProps
{
    toggleState: number,
    checked: boolean
}

export const Wrapper = styled.div<ToggleProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 20px;
    border-radius: 9999px;
    border: 1px solid ${(props) => (props.toggleState === 0 || props.toggleState === 1 ? props.theme.palette.shadowedPrimary : 'lightGrey')};
    position: relative;

    ${({toggleState}) =>
    {
        if(toggleState === 0)
        {
            return {background: "#F3F7F9"};
        }
        else if(toggleState === 1)
        {
            return {background: "#CC214F"};
        }
    }}
`;
export const Button = styled.button<ButtonToggleProps>`
    width: 100%;
    opacity: 0;
    height: 70%;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    ${(props) => (props.toggleState === 0 ? 'margin-left: 3px' : props.toggleState === 1 ? 'margin-right: 3px' : 'margin: 0 1px')};
    background: ${(props) => (props.toggleState === 0 ? '#F1BAC9' : 'white')};
    box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px;

    ${(props) => props.checked && {opacity: '1'}}
`;
export const StateText = styled.span<ToggleProps>`
    position: absolute;
    height: 100%;
    margin-top: 3px;
    ${({toggleState}) =>
    {
        if(toggleState === 0)
        {
            return {right: '7px', color: '#F1BAC9'};
        }
        else if(toggleState === 1)
        {
            return {left: '5px', color: 'white'};
        }
        else if(toggleState === -1)
        {
            return {display: 'none',};
        }
    }}
`;
import { useId } from "@fluentui/react-hooks";
import React from "react";
import { Accounts } from "../Refunds";
import { Dropdown, IDropdownOption, PrimaryButton, TeachingBubble } from "@fluentui/react";
import { Link } from "react-router-dom";

export interface AccountProps
{
    accounts: Accounts[],
    errorMessage: string,
    alignment?: string,
    dropdownChange: (item: any) => any;
}

const dropdownStyles =
{
    root:
    {
        width: '100%',
    },
    dropdownItem:
    {
        display: 'flex',
        alignItems: 'left'
    },
    title:
    {
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
        color: '#CC214F !important'
    },
};

export const DropdownAccounts = (props: AccountProps) =>
{
    const dropdownId = useId();
    const [selectedKey, setSelectedKey] = React.useState<string | number | undefined>(undefined);
    const [options, setOptions] = React.useState<IDropdownOption[]>();
    const [tooltipVisibility, setTooltipVisibility] = React.useState<boolean>(false);
    const alignment = props.alignment !== undefined ? props.alignment : 'center';

    React.useEffect(() =>
    {
        if(props.accounts.length > 0)
        {
            let dropdownOptions: IDropdownOption[] = [];

            for(let i=0; i<props.accounts.length; i++)
            {
                dropdownOptions.push(
                {
                    key: props.accounts[i].id,
                    text: props.accounts[i].value
                });
            }

            const defaultOption = props.accounts.find(option => option.default);
            if(defaultOption !== undefined)
            {
                setSelectedKey(defaultOption?.id);
            }
            setOptions(dropdownOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.accounts]);

    const onChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void =>
    {
        setSelectedKey(option ? option.key : undefined);
        props.dropdownChange(option?.text);
    };
    const handleMouseEnter = () =>
    {
        setTooltipVisibility(true);
    }
    const handleMouseLeave = () =>
    {
        setTooltipVisibility(false);
    }

    return(
        <React.Fragment>
            {options === undefined &&
            (
                <React.Fragment>
                    <div style={{flex: '0 0 70%'}}>
                        <Dropdown id={dropdownId} selectedKey="Debes escoger una Cuenta Corriente para poder realizar el reembolso"
                            label="IBAN Cuenta Bancaria" options={[]} styles={dropdownStyles} disabled
                        />
                    </div>
                    <div style={{flex: '0 0 30%', display: 'flex', justifyContent: alignment, paddingTop: '1.85rem'}}>
                        <Link to={{pathname: "/profile/bank-accounts"}} id="DropdownBubble" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <PrimaryButton>
                                Cuentas Corrientes
                            </PrimaryButton>
                        </Link>
                    </div>
                </React.Fragment>
            )}
            {options !== undefined && options.length === 0 &&
            (
                <React.Fragment>
                    <div style={{flex: '0 0 70%'}}>
                        <Dropdown id={dropdownId} selectedKey="Debes escoger una Cuenta Corriente para poder realizar el reembolso"
                            label="IBAN Cuenta Bancaria" options={options} disabled styles={dropdownStyles}
                        />
                    </div>
                    <div style={{flex: '0 0 30%', display: 'flex', justifyContent: alignment, paddingTop: '1.85rem'}}>
                        <Link to={{pathname: "/profile/bank-accounts"}} id="DropdownBubble" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <PrimaryButton>
                                Cuentas Corrientes
                            </PrimaryButton>
                        </Link>
                    </div>
                </React.Fragment>
            )}
            {options !== undefined && options.length > 0 &&
            (
                <React.Fragment>
                    <div style={{display: 'flex'}}>
                        <div style={{flex: '0 0 70%'}}>
                            <Dropdown id={dropdownId} selectedKey={selectedKey} options={options} styles={dropdownStyles} onChange={onChange} 
                                required label="IBAN Cuenta Bancaria" errorMessage={props.errorMessage}
                            />
                        </div>
                        <div style={{flex: '0 0 30%', display: 'flex', justifyContent: alignment, paddingTop: '1.85rem'}}>
                            <Link to={{pathname: "/profile/bank-accounts"}} id="DropdownBubble" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                <PrimaryButton>
                                    Cuentas Corrientes
                                </PrimaryButton>
                            </Link>
                        </div>
                    </div>
                </React.Fragment>
            )}
            {tooltipVisibility &&
            (
                <TeachingBubble target={"#DropdownBubble"} onDismiss={() => handleMouseLeave} headline="Cuentas Corrientes">
                    Para cumplir con la normativa antibloqueo de capitales y financiación del terrorismo sólo puedes realizar 
                    reembolsos a cuentas corrientes en las que hayas demostrado tu titularidad.
                    <br />
                    <br />
                    Este botón te permite navegar a la sección de las Cuentas Corrientes.
                </TeachingBubble>
            )}
        </React.Fragment>
    );
};

export default DropdownAccounts;
import * as S from '../ConvenienceTest.style';
import Moment from 'moment';
import { useCallback, useState, useEffect, useContext } from 'react';
import ConvenienceTestAddressInputs from './ConvenienceTestAddressInputs';
import { useHistory, useParams } from 'react-router-dom';
import UserIdentitiesService, { UserIdentityType } from '../../../services/UserIdentitiesService';
import FinametrixService from '../../../services/FinametrixService';
import { DefaultButton, Spinner, SpinnerSize } from '@fluentui/react';
import scrollToTop from '../../../utils/scrollToTop';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { ThemeContext } from '@fluentui/react-theme-provider';

const ConvenienceTestAddress = () =>
{
    const { savingsPlanId } = useParams<any>();
    const { userIdentityId } = useParams<any>();
    const theme = useContext(ThemeContext);
    const [userIdentity, setUserIdentity] = useState<UserIdentityType>();
    const [userIdentityForm, setUserIdentityForm] = useState<any>();
    const [isLoadedCountries, setIsLoadedCountries] = useState(false);
    const [isLoadedRegions, setIsLoadedRegions] = useState(false);
    const [isLoadedAddressTypes, setIsLoadedAddressTypes] = useState(false);
    const [error, setError] = useState({ message: '' });
    const history = useHistory();
    const [canSubmit, setCanSubmit] = useState(false);
    const [sending, setSending] = useState(false);
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [addressTypes, setAddressTypes] = useState([]);

    useEffect(() =>
    {
        scrollToTop();
    }, []);

    useEffect(() =>
    {
        if(userIdentityId !== null && userIdentityId !== undefined)
        {
            UserIdentitiesService.getUserIdentity(userIdentityId).then((userIdentity: UserIdentityType) =>
            {
                setUserIdentityForm(
                {
                    fiscalAddressForm:
                    {
                        roadType: false,
                        streetName: false,
                        streetNumber: false,
                        city: false,
                        state: false,
                        zipCode: false,
                        country: false
                    },
                    postalAddressForm:
                    {
                        roadType: false,
                        streetName: false,
                        streetNumber: false,
                        city: false,
                        state: false,
                        zipCode: false,
                        country: false
                    }
                });

                if(userIdentity.postalAddress === null)
                {
                    userIdentity.postalAddress =
                    {
                        addressType: 'Postal',
                        roadType: 'Calle',
                        streetName: '',
                        streetNumber: '',
                        streetFloor: '',
                        streetStair: '',
                        streetDoor: '',
                        streetOther: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        country: '',
                        createdAt: new Date()
                    };
                }
                if(userIdentity.fiscalAddress === null)
                {
                    userIdentity.fiscalAddress =
                    {
                        addressType: 'Fiscal',
                        roadType: 'Calle',
                        streetName: '',
                        streetNumber: '',
                        streetFloor: '',
                        streetStair: '',
                        streetDoor: '',
                        streetOther: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        country: '',
                        createdAt: new Date()
                    };
                }
                setUserIdentity(userIdentity);
            },
            (error) =>
            {
                setError(error);
            });
        }
    }, [userIdentityId]);

    useEffect(() =>
    {
        FinametrixService.getCountries().then((result: any) =>
        {
            setCountries(result);
            setIsLoadedCountries(true);
        },
        (error: any) =>
        {
            setError(error);
            setIsLoadedCountries(true);
        });
    }, []);

    useEffect(() =>
    {
        FinametrixService.getRegions().then((result: any) =>
        {
            setRegions(result);
            setIsLoadedRegions(true);
        },
        (error: any) =>
        {
            setError(error);
            setIsLoadedRegions(true);
        });
    }, []);

    useEffect(() =>
    {
        FinametrixService.getAddressTypes().then((result: any) =>
        {
            setAddressTypes(result);
            setIsLoadedAddressTypes(true);
        },
        (error: any) =>
        {
            setError(error);
            setIsLoadedAddressTypes(true);
        });

    }, []);

    const handleAddressChange = (event: any, field: string) =>
    {
        var { name, value }: {name: string, value: string} = event.target;
        setUserIdentity((prevSate: any) =>
        ({
            ...prevSate,
            [field]: {
                // @ts-ignore
                ...prevSate[field],
                [name]: value
            }
        }));
    };

    const goBack = () =>
    {
        if(userIdentity !== undefined)
        {
            history.push('/accountHolderData/' + savingsPlanId + '/' + userIdentity.id);
        }
    }

    const btnSubmit = useCallback((userIdentities) =>
    {
        if(userIdentity !== undefined)
        {
            setCanSubmit((userIdentity.sameAddress === null || userIdentity.sameAddress)
                ? Object.values(userIdentityForm.postalAddressForm).every((x) => x === true)
                : Object.values(userIdentityForm.postalAddressForm).every((x) => x === true) && Object.values(userIdentityForm.fiscalAddressForm).every((x) => x === true)
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIdentityForm]);

    useEffect(() =>
    {
        btnSubmit(userIdentity);
    }, [btnSubmit, userIdentity]);

    const handleSubmit = async (event: any) =>
    {
        event.preventDefault();
        if(userIdentity !== undefined)
        {
            setSending(true);
            await UserIdentitiesService.updateUserIdentity(userIdentity).then(() =>
            {
                history.push('/id/' + savingsPlanId + '/' + userIdentityId);
            },
            (error: any) =>
            {
                setError(error);
                setSending(false);
            });
        }
    };

    return(
        <DocumentTitle title={`IronIA - Dirección Postal/Fiscal`}>
            <React.Fragment>
                <S.HelperStyle />
                <S.Cont theme={theme}>
                    <S.MainCont>
                        <S.Steps>
                            <h3>Paso 2 <span>de 4</span></h3>
                        </S.Steps>
                        <S.Title>
                            <h1>Dirección fiscal y postal</h1>
                            <h2>
                                Necesitamos dirección fiscal para abrir tu cuenta de valores, si tienes una
                                dirección postal distinta también puedes proporcionárnosla.
                            </h2>
                        </S.Title>
                        <S.BarCont>
                            <S.BgBar />
                            <S.ProsBar />
                        </S.BarCont>
                        {(userIdentity === undefined || !isLoadedCountries || !isLoadedRegions || !isLoadedAddressTypes) &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        {userIdentity !== undefined && isLoadedCountries && isLoadedRegions && isLoadedAddressTypes &&
                        (
                            <S.TabletCont theme={theme}>
                                <S.SubTitle>
                                    Dirección del
                                    <span> Interviniente - {[userIdentity.firstName, userIdentity.lastName, userIdentity.secondLastName].join(" ")}</span>
                                </S.SubTitle>
                                <S.TabletCont theme={theme}>
                                    <S.SubTitle>Dirección postal</S.SubTitle>
                                    <ConvenienceTestAddressInputs data={userIdentity.postalAddress}
                                        handleChange={(event: any) => handleAddressChange(event, 'postalAddress')}
                                        formVal={userIdentityForm.postalAddressForm}
                                        // eslint-disable-next-line no-useless-computed-key
                                        setFormVal={(name: string, value: any) => setUserIdentityForm((prevState: any) => ({ ...prevState, ['postalAddressForm']: { ...prevState['postalAddressForm'], [name]: value } }))}
                                        countries={countries}
                                        regions={regions}
                                        addressTypes={addressTypes}
                                    />
                                    <hr />
                                    {userIdentity.sameAddress !== null && !userIdentity.sameAddress &&
                                    (
                                        <React.Fragment>
                                            <S.SubTitle>Dirección fiscal</S.SubTitle>
                                            <ConvenienceTestAddressInputs
                                                data={userIdentity.fiscalAddress}
                                                handleChange={(event: any) => handleAddressChange(event, 'fiscalAddress')}
                                                formVal={userIdentityForm.postalAddressForm}
                                                // eslint-disable-next-line no-useless-computed-key
                                                setFormVal={(name: string, value: any) => setUserIdentityForm((prevState: any) => ({ ...prevState, ['fiscalAddressForm']: { ...prevState['fiscalAddressForm'], [name]: value } }))}
                                                countries={countries}
                                                regions={regions}
                                                addressTypes={addressTypes}
                                            />
                                        </React.Fragment>
                                    )}
                                </S.TabletCont>
                                <S.AproveCont>
                                    <S.CheckSelect>
                                        <S.CheckLabel>
                                            Mi dirección postal es distinta a mi dirección fiscal.
                                            <S.CheckInput
                                                type='checkbox'
                                                name='sameAddress'
                                                checked={userIdentity.sameAddress !== null && !userIdentity.sameAddress}
                                                onChange={(event: any) =>
                                                {
                                                    var { name }: { name: string } = event.target;
                                                    setUserIdentity((prevSate: any) =>
                                                    ({
                                                        ...prevSate,
                                                        [name]: userIdentity.sameAddress === null ? false : !userIdentity.sameAddress
                                                    }));
                                                }}
                                            />
                                            <S.CheckMark />
                                        </S.CheckLabel>
                                    </S.CheckSelect>
                                </S.AproveCont>
                            </S.TabletCont>
                        )}
                        {error.message !== "" &&
                        (
                            <S.Error>Se ha producido un error que impide continuar con el proceso</S.Error>
                        )}
                        <S.BtnCont>
                            <DefaultButton disabled={userIdentity === undefined}
                                onClick={() => goBack()}
                                className="button tiny-secondary-button"
                            >
                                Volver
                            </DefaultButton>
                            <DefaultButton onClick={handleSubmit}
                                disabled={userIdentity === undefined || !canSubmit || sending || error.message !== ""}
                                className="button tiny-primary-button"
                            >
                                {sending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                Continuar
                            </DefaultButton>
                        </S.BtnCont>
                    </S.MainCont>
                </S.Cont>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default ConvenienceTestAddress;
import React from "react";
import { ThemeContext } from '@fluentui/react-theme-provider';
import { ChevronDownIcon, ChevronRightIcon } from "@fluentui/react-icons-mdl2";
import { Category, SubCategory } from "../../../services/subcategories";
import { ChildSubCategories } from "./childSubCategories";
import { StyledTd, TdValueNegative, StyledTdPositive, SortConfig } from ".";
import { percentFormatter } from "../../../utils/numberFormatter";

type Props =
{
    category: Category,
    subCategories: SubCategory[],
    index: number,
    selectedRatioKey: string;
    sortConfig: SortConfig;
};

export const formatedCategoryValue = (value: number | undefined) =>
{
    if(value)
    {
        let formatedVal = percentFormatter.format(value);
        if(value > 0)
        {
            return <StyledTdPositive>{formatedVal}</StyledTdPositive>
        }
        else if(value < 0)
        {
            return <TdValueNegative>{formatedVal}</TdValueNegative>
        }
        else
        {
            return <StyledTd>{formatedVal}</StyledTd>
        }
    }
    else
    {
        return <StyledTd></StyledTd>;
    }
}

const TableBody = (props: Props) =>
{
    const { category, subCategories, index, selectedRatioKey, sortConfig } = props;
    const theme = React.useContext(ThemeContext);
    const [isExpanded, setIsExpanded] = React.useState(false);

    React.useEffect(() =>
    {
        setIsExpanded(false);
    }, [sortConfig.key, sortConfig.direction]);

    return(
        <React.Fragment key={index}>
            <tr>
                <td>
                    <div style={{width: "40%", textAlign: "left"}}>
                        <div className="max-content">
                            <button style={{color: theme!.palette.black}} className="expanded"
                                onClick={() =>
                                {
                                    setIsExpanded(!isExpanded);
                                }}
                            >
                                {category &&
                                    (isExpanded ? <ChevronDownIcon className="brothers-btn-icon" /> : <ChevronRightIcon className="brothers-btn-icon" />)}
                            </button>
                            {category.category}
                        </div>
                    </div>
                </td>
                {formatedCategoryValue(category.ratio1m)}
                {formatedCategoryValue(category.bench1m)}
                {formatedCategoryValue(category.ratio3m)}
                {formatedCategoryValue(category.bench3m)}
                {formatedCategoryValue(category.ratio6m)}
                {formatedCategoryValue(category.bench6m)}
                {formatedCategoryValue(category.ratio1y)}
                {formatedCategoryValue(category.bench1y)}
                {formatedCategoryValue(category.ratio2y)}
                {formatedCategoryValue(category.bench2y)}
                {formatedCategoryValue(category.ratio3y)}
                {formatedCategoryValue(category.bench3y)}
                {formatedCategoryValue(category.ratio4y)}
                {formatedCategoryValue(category.bench4y)}
                {formatedCategoryValue(category.ratio5y)}
                {formatedCategoryValue(category.bench5y)}
            </tr>
            {isExpanded && subCategories && subCategories.length > 0 && subCategories.map((row, index) =>
            (
                <React.Fragment key={index}>
                    <ChildSubCategories subCategory={row} index={"subCategory-row-" + index} selectedRatioKey={selectedRatioKey} sortConfig={sortConfig} />
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};

export default TableBody;
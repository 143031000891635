//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router";
import { useDispatch } from 'react-redux';
import { DetailsList, SelectionMode, Callout, TooltipHost, Spinner, SpinnerSize } from '@fluentui/react';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { DependencyAddIcon, SignOutIcon, ShoppingCartIcon } from '@fluentui/react-icons-mdl2';
import IroniaPoints from './IroniaPoints';
import Moment from 'moment';
import { currencyFormatter, percentFormatter, decimalFormatter, longDecimalFormatter, setCurrency } from '../../../utils/numberFormatter';
import 'office-ui-fabric-react/dist/css/fabric.css';
import Auth from "../../../Auth/Auth";
import { parseNumber } from '../../../utils/toFixedDecimals';
import { ReactComponent as IncrementIcon } from '../../../images/increment.svg';
import { ReactComponent as DecrementIcon } from '../../../images/decrement.svg';
import AppendCartLine from '../../../utils/AppendCartLine';

export interface IInitialRegisters {
    actions?: string,
    allFundsId?: any,
    available?: boolean,
    benchmarkFinametrixId?: any,
    bought?: any,
    pmpc: any,
    category?: string,
    documents?: [],
    exchangeRate?: any,
    expanded?: boolean,
    from?: string,
    hidden?: boolean,
    instrumentId?: number,
    finametrixId?: string,
    ironiaPoints?: string,
    isin?: string,
    liquidDate?: string,
    managementFee?: number,
    minimumInitialInvestment?: number,
    name?: string,
    noMin?: boolean,
    ongoingCharges?: number,
    titles?: string,
    rebate?: number,
    revaluation?: any,
    revaluationPercentage?: any,
    score?: any,
    subcategory?: any,
    type?: string,
    valuation?: any,
    vLiquid?: any,
    weight?: any,
    complexity?: boolean
}

type PortfolioTableProps =
    {
        reporting?: any,
        funds?: any[],
        fundsforModelData: (items: any[]) => any[],
        isResumeWallet?: boolean,
        showActions?: boolean,
        showDocuments?: boolean,
        showIroniaPoints?: boolean
    }

const documentsColumns: any = [
    { key: 'name', name: 'Fichero', fieldName: 'name', minWidth: 200, isResizable: true },
    { key: 'link', name: 'Acción', fieldName: 'url', width: 50, isResizable: false },
];

const dateFormat = (stringDate: any) => {
    return Moment(stringDate).format('DD/MM/YYYY');
}

const PortfolioTable = (props: PortfolioTableProps) => {
    const { isResumeWallet } = props;
    const [registers, setRegisters] = useState<IInitialRegisters[]>([]);
    const [isAttachmentsVisible, setIsAttachmentsVisible] = useState(false);
    const [selectedRegister, setSelectedRegister] = useState<any>({});
    const isActiveManagementPlan: boolean = Auth.isActiveManagementPlan();
    const history = useHistory();
    const dispatch = useDispatch();
    const [sending, setSending] = useState("");
    var activePlan = Auth.getActivePlan();

    const handleClick = async (item: any) => {
        setSending(item.isin);
        await AppendCartLine(
            dispatch,
            history,
            {
                fund: item,
                amount: 0
            }
        );
        setSending("");
    };

    const columns = [
        { key: 'name', name: '', isCollapsible: false, fieldName: 'name', minWidth: 250, maxWidth: 500, isResizable: true },
        ...(props.showDocuments ? [{ key: 'documents', name: '', minWidth: 40, maxWidth: 40, isResizable: false }] : []),
        { key: 'titles', name: 'Títulos', fieldName: 'titles', minWidth: 70, maxWidth: 70, isResizable: false },
        { key: 'bought', name: 'Comprado', fieldName: 'bought', minWidth: 70, maxWidth: 70, isResizable: false },
        { key: 'valuation', name: 'Valoración', fieldName: 'valuation', minWidth: 70, maxWidth: 70, isResizable: false },
        { key: 'revaluation', name: 'Revalorización', fieldName: 'revaluation', minWidth: 80, maxWidth: 80, isResizable: false },
        { key: 'revaluationPercentage', name: '', fieldName: 'revaluationPercentage', minWidth: 50, maxWidth: 50, isResizable: false },
        { key: 'weight', name: 'Peso', fieldName: 'weight', minWidth: 50, maxWidth: 50, isResizable: false },
        { key: 'pmpc', name: 'PMPC', fieldName: 'pmpc', minWidth: 50, maxWidth: 70, isResizable: true },
        { key: 'vLiquid', name: 'V. liq', fieldName: 'vLiquid', minWidth: 50, maxWidth: 150, isResizable: true },
        { key: 'exchangeRate', name: 'TC', fieldName: 'exchangeRate', minWidth: 50, maxWidth: 50, isResizable: false },
        { key: 'liquidDate', name: 'Fecha VL', fieldName: 'liquidDate', minWidth: 80, maxWidth: 80, isResizable: false },
        ...(props.showActions ? [{
            key: 'actions', name: 'Acciones', fieldName: 'actions', minWidth: 110, maxWidth: 110, isResizable: false,
            onRender: (item: any) => {
                if (!item?.isin || isResumeWallet === true || activePlan.outdatedContract) {
                    return (<></>);
                }

                return (
                    <React.Fragment>
                        {isActiveManagementPlan === true &&
                            (
                                <React.Fragment>
                                    <TooltipHost content="Reembolso" id={`refundBtn_${item.isin}`} styles={{ root: { display: 'inline-block', paddingLeft: '2px' } }} calloutProps={{ gapSpace: 0 }}>
                                        <Link to={{ pathname: '/portfolio/refunds', state: { origin: item } }}>
                                            <PrimaryButton
                                                className="light-circled-icon"
                                                id={`refundBtn_${item.isin}`}
                                            >
                                                <SignOutIcon className="ironia-table-icon" />
                                            </PrimaryButton>
                                        </Link>
                                    </TooltipHost>
                                </React.Fragment>)
                        }
                        {isActiveManagementPlan === false &&
                            (
                                <React.Fragment>
                                    {item.available === true && (<TooltipHost content="Carrito" id={`cartBtn_${item.isin}`} styles={{ root: { display: 'inline-block', paddingLeft: '2px' } }} calloutProps={{ gapSpace: 0 }}>
                                        <PrimaryButton
                                            className="light-circled-icon"
                                            id={`cartBtn_${item.isin}`}
                                            onClick={() => handleClick(item)}
                                            disabled={sending === item.isin || item.available === false}
                                        >
                                            {sending === item.isin && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                            {sending !== item.isin && <ShoppingCartIcon className="ironia-table-icon" shopping-cart />}
                                        </PrimaryButton>
                                    </TooltipHost>)}
                                    {props.funds && props.funds?.find(x => x.isin === item.isin)?.switchable === true && (<TooltipHost content="Traspasar" id={`transferBtn_${item.isin}`} styles={{ root: { display: 'inline-block', paddingLeft: '2px' } }} calloutProps={{ gapSpace: 0 }}>
                                        <Link to={{ pathname: '/internal-switch', state: { origin: item } }}>
                                            <PrimaryButton
                                                className="light-circled-icon"
                                                id={`transferBtn_${item.isin}`}
                                            >
                                                <DependencyAddIcon className="ironia-table-icon" />
                                            </PrimaryButton>
                                        </Link>
                                    </TooltipHost>)}
                                    <TooltipHost content="Reembolso" id={`refundBtn_${item.isin}`} styles={{ root: { display: 'inline-block', paddingLeft: '2px' } }} calloutProps={{ gapSpace: 0 }}>
                                        <Link to={{ pathname: '/portfolio/refunds', state: { origin: item } }}>
                                            <PrimaryButton
                                                className="light-circled-icon"
                                                id={`refundBtn_${item.isin}`}
                                            >
                                                <SignOutIcon className="ironia-table-icon" />
                                            </PrimaryButton>
                                        </Link>
                                    </TooltipHost>
                                </React.Fragment>)
                        }
                    </React.Fragment>
                );
            }
        }] : []),
        ...(props.showIroniaPoints ? [{ key: 'ironiaPoints', name: 'Puntos IronIA', fieldName: 'score', minWidth: 140, maxWidth: 200, isResizable: false }] : [])
    ];

    useEffect(() => {
        const valuation = props.reporting.positions.positionsTo.aggregation.reduce((total: any, aggregation: any) => {
            return total += aggregation.total.value;
        }, 0);

        const plusMinus = props.reporting.positions.positionsTo.aggregation.reduce((total: any, aggregation: any) => {
            return total += aggregation.total.plusMinus;
        }, 0);

        const buyAmount = valuation - plusMinus;

        const growth = buyAmount === 0 ? 0 : (valuation - buyAmount) / buyAmount;

        const weight = props.reporting.positions.positionsTo.aggregation.reduce((total: any, aggregation: any) => {
            return total += aggregation.total.weight;
        }, 0);

        var categories: any = [];
        props.reporting.positions.positionsTo.aggregation.forEach((aggregation: any) => {
            var subcategories: any = [];
            aggregation.level2.forEach((level2: any) => {
                var positions: any = [];
                level2.positions.forEach((position: any) => {
                    if (!position.hasOwnProperty('exchangeRate')) {
                        position['exchangeRate'] = { value: 1 };
                    }
                    else {
                        if (!position.exchangeRate.hasOwnProperty('value')) {
                            position.exchangeRate['value'] = 1;
                        }
                    }

                    positions.push(
                        {
                            name: position.productName,
                            shortName: position.productShortName,
                            instrumentId: position.instrumentId,
                            isin: position.isin,
                            weight: position.weight,
                            titles: position.titles,
                            price: position.lastPriceInProductCurrency,
                            plusMinus: position.plusMinus,
                            plusMinusPercentage: position.plusMinusPercentage,
                            value: position.value,
                            instrumentType: position.instrumentType,
                            valuation: position.value,
                            buyAmount: position.value === 0 || position.value == null ? 0 : position.value - position.plusMinus,
                            exchangeRate: position.exchangeRate.value,
                            from: position.exchangeRate.from !== undefined ? position.exchangeRate.from : "",
                            lastPriceDate: position.dateLastPriceAsString
                        });
                });

                const level2PlusMinus = level2.total.plusMinus;
                const level2PlusMinusPercentage = level2.total.plusMinusPercentage;
                const level2BuyAmount = level2.total.value === 0 || level2.total.value === null ? 0 : level2.total.value - level2.total.plusMinus;
                const level2Valuation = level2.total.value;
                const level2Growth = level2BuyAmount === 0 ? 0 : (level2Valuation - level2BuyAmount) / level2BuyAmount;
                const level2Weight = level2.total.weight;

                subcategories.push(
                    {
                        name: level2.data.name,
                        positions: positions,
                        buyAmount: level2BuyAmount,
                        valuation: level2Valuation,
                        plusMinus: level2PlusMinus,
                        plusMinusPercentage: level2PlusMinusPercentage,
                        growth: level2Growth,
                        weight: level2Weight
                    });
            });

            const aggregationBuyAmount = aggregation.total.value === 0 || aggregation.total.value === null ? 0 : aggregation.total.value - aggregation.total.plusMinus;
            const aggregationValuation = aggregation.total.value;
            const aggregationWeight = aggregation.total.weight;
            const aggregationPlusMinus = aggregation.total.plusMinus;
            const aggregationPlusMinusPercentage = aggregation.total.plusMinusPercentage;
            const aggregationGrowth = aggregationBuyAmount === 0 ? 0 : (aggregationValuation - aggregationBuyAmount) / aggregationBuyAmount;

            var positions: any = [];
            if (aggregation.level2.length === 0) {
                aggregation.positions.forEach((position: any) => {
                    if (position.instrumentType !== 21) {
                        var date = new Date(position.date);
                        var dateAsString = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();

                        positions.push(
                            {
                                name: "Operación pendiente (EUR)",
                                shortName: position.productShortName,
                                instrumentId: position.instrumentId,
                                isin: position.isin !== null ? position.isin : '',
                                weight: position.weight,
                                titles: position.titles,
                                price: null,
                                plusMinus: currencyFormatter.format(position.plusMinus),
                                plusMinusPercentage: position.plusMinusPercentage,
                                value: currencyFormatter.format(position.value),
                                instrumentType: position.instrumentType,
                                valuation: currencyFormatter.format(position.value === 0 || position.value === null ? 0 : position.value - position.plusMinus),
                                exchangeRate: null,
                                from: null,
                                lastPriceDate: dateAsString
                            });
                    }
                    else {
                        positions.push(
                            {
                                name: position.productName,
                                shortName: position.productShortName,
                                instrumentId: position.instrumentId,
                                isin: position.isin,
                                weight: position.weight,
                                titles: position.titles,
                                price: position.lastPriceInProductCurrency,
                                plusMinus: position.plusMinus,
                                plusMinusPercentage: position.plusMinusPercentage,
                                value: position.value,
                                instrumentType: position.instrumentType,
                                valuation: position.value,
                                buyAmount: position.value === 0 || position.value == null
                                    ? 0
                                    : position.value - position.plusMinus,
                                exchangeRate: position.exchangeRate.value !== undefined
                                    ? position.exchangeRate.value
                                    : position.exchangeRate,
                                from: position.exchangeRate.from !== undefined
                                    ? ""
                                    : position.exchangeRate.from,
                                lastPriceDate: position.dateLastPriceAsString
                            });
                    }
                });
            }

            categories.push(
                {
                    name: aggregation.data.name,
                    subcategories: subcategories,
                    buyAmount: aggregationBuyAmount,
                    valuation: aggregationValuation,
                    weight: aggregationWeight,
                    plusMinus: aggregationPlusMinus,
                    plusMinusPercentage: aggregationPlusMinusPercentage,
                    growth: aggregationGrowth,
                    positions: positions
                });
        });

        var initialRegisters: IInitialRegisters[] = [
            {
                name: 'Total Cartera',
                documents: [],
                titles: '-',
                bought: !isNaN(buyAmount) ? currencyFormatter.format(buyAmount) : '-',
                valuation: !isNaN(valuation) ? currencyFormatter.format(valuation) : '-',
                revaluation: !isNaN(plusMinus) ? currencyFormatter.format(plusMinus) : '-',
                revaluationPercentage: !isNaN(growth) ? percentFormatter.format(growth) : '-',
                weight: weight ? percentFormatter.format(weight) : '-',
                pmpc: '-',
                vLiquid: '-',
                exchangeRate: '-',
                liquidDate: '-',
                ironiaPoints: '-',
                actions: '-',
                type: 'total',
                expanded: false,
                hidden: false,
                category: '-'
            }];

        categories.forEach((category: any) => {
            initialRegisters.push(
                {
                    name: category.name ? category.name : '-',
                    documents: [],
                    titles: '-',
                    bought: !isNaN(category.buyAmount) ? currencyFormatter.format(category.buyAmount) : '-',
                    valuation: !isNaN(category.valuation) ? currencyFormatter.format(category.valuation) : '-',
                    revaluation: !isNaN(category.plusMinus) ? currencyFormatter.format(category.plusMinus) : '-',
                    revaluationPercentage: !isNaN(category.plusMinusPercentage) ? percentFormatter.format(category.plusMinusPercentage) : '-',
                    weight: category.weight ? percentFormatter.format(category.weight) : '-',
                    pmpc: '-',
                    vLiquid: '-',
                    exchangeRate: '-',
                    liquidDate: '-',
                    ironiaPoints: '-',
                    actions: '-',
                    type: 'category',
                    hidden: false,
                    expanded: true,
                    category: 'Total'
                });

            category.positions.forEach((position: any) => {
                if (position.instrumentType !== 21) {
                    initialRegisters.push(
                        {
                            name: position.name,
                            isin: position.isin,
                            documents: [],
                            titles: '-',
                            bought: position.value,
                            valuation: position.value,
                            revaluation: '-',
                            revaluationPercentage: '-',
                            weight: position.weight ? percentFormatter.format(position.weight) : '-',
                            pmpc: '-',
                            vLiquid: position.value,
                            exchangeRate: '-',
                            liquidDate: position.lastPriceDate,
                            ironiaPoints: '-',
                            actions: '-',
                            type: 'position',
                            hidden: false,
                            category: category.name ? category.name : '',
                            score: '-',
                            instrumentId: position.instrumentId,
                            allFundsId: '',
                            benchmarkFinametrixId: ''
                        });
                }
                else {
                    let fund = props.funds.find((fund: any) => fund.isin === position.isin);
                    initialRegisters.push(
                        {
                            name: position.name,
                            isin: position.isin,
                            available: fund ? fund.available : true,
                            documents: fund ? fund.documents : [],
                            titles: position.titles ? longDecimalFormatter.format(position.titles) : '-',
                            bought: !isNaN(position.buyAmount) ? currencyFormatter.format(position.buyAmount) : '-',
                            valuation: !isNaN(position.valuation) ? currencyFormatter.format(position.valuation) : '-',
                            revaluation: !isNaN(position.plusMinus) ? currencyFormatter.format(position.plusMinus) : '-',
                            revaluationPercentage: !isNaN(position.plusMinusPercentage) ? percentFormatter.format(position.plusMinusPercentage) : '-',
                            weight: position.weight ? percentFormatter.format(position.weight) : '-',
                            pmpc: !isNaN(position.buyAmount) && position.titles ? currencyFormatter.format(position.buyAmount / position.titles) : '-',
                            vLiquid: position.price ? setCurrency(position.from, position.price) : '-',
                            exchangeRate: position.exchangeRate?.value === undefined ? '-' : decimalFormatter.format(position.exchangeRate.value),
                            liquidDate: position.lastPriceDate ? dateFormat(position.lastPriceDate) : '-',
                            ironiaPoints: '-',
                            actions: '-',
                            type: 'position',
                            hidden: false,
                            category: category.name ? category.name : '',
                            score: fund ? fund.averageScore : 0,
                            finametrixId: position.instrumentId,
                            instrumentId: position.instrumentId,
                            allFundsId: fund ? fund.allFundsId : '',
                            benchmarkFinametrixId: fund ? fund.benchmarkFinametrixId : '',
                            managementFee: fund ? fund.managementFee : '',
                            noMin: fund ? fund.noMin : '',
                            minimumInitialInvestment: fund ? fund.minimumInitialInvestment : '',
                            ongoingCharges: fund ? fund.ongoingCharges : '',
                            rebate: fund ? fund.rebate : '',
                            complexity: fund ? fund.complexity : false,
                            minimumAdditionalInvestment: fund ? fund.minimumAdditionalInvestment : '',
                            cleanShare: fund ? fund.cleanShare : false
                        });
                }
            });

            category.subcategories.forEach((subcategory: any) => {
                initialRegisters.push(
                    {
                        name: subcategory.name,
                        documents: [],
                        titles: '-',
                        bought: !isNaN(subcategory.buyAmount) ? currencyFormatter.format(subcategory.buyAmount) : '-',
                        valuation: !isNaN(subcategory.valuation) ? currencyFormatter.format(subcategory.valuation) : '-',
                        revaluation: !isNaN(subcategory.plusMinus) ? currencyFormatter.format(subcategory.plusMinus) : '-',
                        revaluationPercentage: !isNaN(subcategory.plusMinusPercentage) ? percentFormatter.format(subcategory.plusMinusPercentage) : '-',
                        weight: subcategory.weight ? percentFormatter.format(subcategory.weight) : '-',
                        pmpc: '-',
                        vLiquid: '-',
                        liquidDate: '-',
                        exchangeRate: '-',
                        ironiaPoints: '-',
                        actions: '-',
                        type: 'subcategory',
                        hidden: false,
                        expanded: true,
                        category: category.name ? category.name : '',
                    });

                subcategory.positions.forEach((position: any) => {
                    let fund = props.funds.find((fund: any) => fund.isin === position.isin);
                    let exchangeRate = position.exchangeRate.value !== undefined ? position.exchangeRate.value : position.exchangeRate;
                    initialRegisters.push(
                        {
                            name: position.name,
                            isin: position.isin,
                            available: fund ? fund.available : true,
                            documents: fund ? fund.documents : [],
                            titles: position.titles ? longDecimalFormatter.format(position.titles) : '-',
                            bought: !isNaN(position.buyAmount) ? currencyFormatter.format(position.buyAmount) : '-',
                            valuation: !isNaN(position.valuation) ? currencyFormatter.format(position.valuation) : '-',
                            revaluation: !isNaN(position.plusMinus) ? currencyFormatter.format(position.plusMinus) : '-',
                            revaluationPercentage: !isNaN(position.plusMinusPercentage) ? percentFormatter.format(position.plusMinusPercentage) : '-',
                            weight: position.weight ? percentFormatter.format(position.weight) : '-',
                            pmpc: !isNaN(position.buyAmount) && position.titles ? currencyFormatter.format(position.buyAmount / position.titles) : '-',
                            vLiquid: position.price ? setCurrency(position.from, position.price) : '-',
                            exchangeRate: exchangeRate === 1 ? (1 / exchangeRate) : decimalFormatter.format(1 / exchangeRate),
                            liquidDate: dateFormat(position.lastPriceDate),
                            ironiaPoints: '-',
                            actions: '-',
                            type: 'position',
                            hidden: false,
                            category: category.name ? category.name : '',
                            subcategory: subcategory.name,
                            score: fund ? fund.averageScore : 0,
                            finametrixId: position.instrumentId,
                            instrumentId: position.instrumentId,
                            allFundsId: fund ? fund.allFundsId : '',
                            benchmarkFinametrixId: fund ? fund.benchmarkFinametrixId : '',
                            managementFee: fund ? fund.managementFee : '',
                            noMin: fund ? fund.noMin : '',
                            minimumInitialInvestment: fund ? fund.minimumInitialInvestment : '',
                            ongoingCharges: fund ? fund.ongoingCharges : '',
                            rebate: fund ? fund.rebate : '',
                            complexity: fund ? fund.complexity : false,
                            minimumAdditionalInvestment: fund ? fund.minimumAdditionalInvestment : '',
                            cleanShare: fund ? fund.cleanShare : false
                        });
                });
            });
        });

        props.fundsforModelData(initialRegisters.filter(x => x.type === "position" && x.documents?.length > 0));

        setRegisters(initialRegisters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.funds, props.reporting.positions.positionsTo.aggregation]);

    const toggleCategory = (categoryName: any) => {
        const hidden = registers.find((item: any) => item.type === 'category' && item.name === categoryName).expanded;
        setRegisters(registers.map(function callback(item, index, array) {
            switch (item.type) {
                case "category":
                    if (item.name === categoryName) {
                        item.expanded = !item.expanded;
                    }
                    break;
                case "subcategory":
                    if (item.category === categoryName) {
                        item.hidden = hidden;
                    }
                    break;
                case "position":
                    if (item.category === categoryName) {
                        var subcategory = registers.find(register => register.type === 'subcategory' && register.name === item.subcategory);
                        if (subcategory) {
                            item.hidden = hidden || !subcategory.expanded;
                        }
                        else {
                            item.hidden = hidden;
                        }
                    }
                    break;
                default:
                    break;
            }

            return item;
        }));
    };
    const toggleSubcategory = (categoryName: any) => {
        const hidden: any = registers.find((item: IInitialRegisters) => item.type === 'subcategory' && item.name === categoryName).expanded;
        setRegisters(registers.map(function callback(currentValue: any, index: any, array: any) {
            if (currentValue.type === "subcategory") {
                if (currentValue.name === categoryName) {
                    currentValue.expanded = !currentValue.expanded;
                }
            }
            else {
                if (currentValue.subcategory === categoryName) {
                    currentValue.hidden = hidden;
                }
            }

            return currentValue;
        }));
    };
    const toggleAttachmentsVisible = (isin: string) => {
        const register: any = registers.find((item: any) => item.isin === isin);
        setSelectedRegister(register);
        setIsAttachmentsVisible(true);
    };
    const renderItemColumn = (item: any, index: any, column: any) => {
        const fieldContent = item[column.fieldName];
        switch (column.key) {
            case "name":
                switch (item.type) {
                    case "position":
                        return (
                            <React.Fragment>
                                <Link to={`/${item.isin}`}>
                                    <span className={"column-title " + item.type}>
                                        {item.name}
                                    </span>
                                </Link>
                                <br />
                                <span className={"column-title " + item.type}>
                                    {item.isin}
                                </span>
                            </React.Fragment>
                        );
                    case "category":
                        return (
                            <span className={"column-title " + item.type}>
                                <PrimaryButton onClick={() => toggleCategory(item.name)} className="toggleGroupVisibility">
                                    <i className={"ironia-icon " + (item.expanded ? "keyboard-arrow-up" : "keyboard-arrow-down")} />
                                </PrimaryButton>
                                {item.name}
                            </span>
                        );
                    case "subcategory":
                        return (
                            <span className={"column-title " + item.type}>
                                <PrimaryButton onClick={() => toggleSubcategory(item.name)} className="toggleGroupVisibility">
                                    <i className={"ironia-icon " + (item.expanded ? "keyboard-arrow-up" : "keyboard-arrow-down")} />
                                </PrimaryButton>
                                {item.name}
                            </span>
                        );
                    default:
                        return (
                            <span className={"column-title " + item.type}>
                                {item.name}
                            </span>
                        );
                }
            case "documents":
                if (item.documents === undefined || item.documents.length === 0) {
                    return "";
                }
                else {
                    return (
                        <TooltipHost content="Documentos" id={`buttonId${item.isin}`} styles={{ root: { display: "inline-block" } }}
                            calloutProps={{ gapSpace: 0 }}
                        >
                            <PrimaryButton className="light-circled-icon" id={"buttonId" + item.isin} onClick={() => toggleAttachmentsVisible(item.isin)}>
                                <i className={"ironia-icon pdf"} />
                            </PrimaryButton>
                        </TooltipHost>
                    );
                }
            case "ironiaPoints":
                return <IroniaPoints score={item.score} />;
            case "titles":
            case "bought":
            case "valuation":
            case "revaluation":
            case "weight":
            case "pmpc":
            case "vLiquid":
            case "exchangeRate":
            case "liquidDate":
                return (
                    <span className={item.type + " table-numeric-column"}>
                        {fieldContent}
                    </span>
                );
            case "revaluationPercentage":
                let value = parseNumber(fieldContent, 2);
                return (
                    <span className={item.type + " table-numeric-column"}>
                        {fieldContent} {value > 0 && (<IncrementIcon />)} {value < 0 && (<DecrementIcon />)}
                    </span>
                );
            default:
                return <span className={item.type}>{fieldContent}</span>;
        }
    }
    const renderAttachmentColumn = (item: any, index: any, column: any) => {
        switch (column.key) {
            case 'link':
                return (<a href={item.url} target="_blank" rel="noreferrer"><i className={'ironia-icon downloads'} /> Descargar</a>)
            default: return (item[column.key])
        }
    }

    var fundPositions = props.reporting.positions.positionsTo.positions.filter((pos: any) => pos.isin !== null);
    if (fundPositions.length === 0) {
        return (
            <p className="description">
                No hay fondos en la cartera seleccionada
            </p>
        );
    }
    else {
        if (registers.length < 2) {
            return (
                <>
                    <Spinner size={SpinnerSize.large} />
                </>
            );
        }

        return (
            <React.Fragment>
                <div className={`ms-Grid-col ms-sm12`}>
                    <DetailsList items={registers.filter((register: any) => !register.hidden)}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onRenderRow={(props: any, defaultRender: any) => {
                            return defaultRender({ ...props, className: props.item.type })
                        }}
                        onRenderItemColumn={renderItemColumn}
                    />
                </div>
                {isAttachmentsVisible &&
                    (
                        <Callout gapSpace={0} target={`#buttonId${selectedRegister.isin}`} onDismiss={() => setIsAttachmentsVisible(false)} setInitialFocus isBeakVisible={false}>
                            <DefaultButton style={{ marginTop: '8px' }} onClick={() => setIsAttachmentsVisible(false)} className="closeButton">
                                <i className='ironia-icon cross' />
                            </DefaultButton>
                            <h4 style={{ padding: "0px 20px 0px 20px" }}>Documentos {selectedRegister.name}</h4>
                            <DetailsList
                                items={selectedRegister.documents}
                                columns={documentsColumns}
                                onRenderItemColumn={renderAttachmentColumn}
                                selectionMode={SelectionMode.none}
                            />
                        </Callout>
                    )}
            </React.Fragment>
        );
    }
};

export default PortfolioTable;
import { getTheme, IButtonStyles, IconButton, IIconProps, mergeStyleSets, Modal, Spinner, SpinnerSize } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import React, { useRef } from "react";
import * as S from './Styles/ModalFundStyle';
import { ThemeContext } from "@fluentui/react-theme-provider";
import IronIAIntegrations, { ChatGPTResponse, VideoResponse, VideoURLData, VideoView } from "../../services/IroniaIntegrationsService";
import CustomIcon from "../../components/icons/CustomIcon";
import ElementsIAIcon from "../../images/ia.svg";
import ReactPlayer from "react-player";
import { ai } from "../../services/TelemetryService";
import Auth from "../../Auth/Auth";
import { useHistory } from "react-router-dom";
import ElementService from "../../services/ElementsService";
import './Styles/ModalFundStyle.css';
import { translate } from "../../utils/i18n";

export interface Props
{
    chatGPTResponse: ChatGPTResponse,
    managerEnabled?: boolean,
    isIframe?: boolean
    isUserSubscribedToElements: boolean,
    userId: number,
    savingsPlanId: number
};
const theme = getTheme();
const iconButtonStyles: Partial<IButtonStyles> =
{
    root:
    {
        color: '#CC214F',
        marginLeft: 'auto',
        marginRight: '2px',
        float: "right"
    },
    rootHovered:
    {
        color: theme.palette.neutralDark,
    }
};
const containerStyles = mergeStyleSets(
{
    container:
    {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        borderRadius: '2em',
        minWidth: "550px !important",
        width: "55% !important"
    }
});
const containerConfirmationStyles = mergeStyleSets(
{
    container:
    {
        width: '33%',
        minHeight: '100px !important',
        top: '15%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        borderRadius: '2em',
        minWidth: '200px !important',
        height: '125px !important'
    }
});
export const ModalFundExplanation: React.FunctionComponent<Props> = (props: Props) =>
{
    const appInsights = ai.reactPlugin;
    const history = useHistory();
    const [isModalOpen, {setTrue: showModal, setFalse: hideModal}] = useBoolean(false);
    const modalOpen = useRef<boolean>(false);
    const [isConfirmationModalOpen, {setTrue: showConfirmationModal, setFalse: hideConfirmationModal}] = useBoolean(false);
    const [isFirstConfirmationModalOpen, {setTrue: showFirstConfirmationModal, setFalse: hideFirstConfirmationModal}] = useBoolean(false);
    const titleId = useId('title');
    const secondTitleId = useId('secondTitle');
    const thirdTitleId = useId('thirdTitle');
    const themeContext = React.useContext(ThemeContext);
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const [isShowingVideo, setIsShowingVideo] = React.useState<boolean>(false);
    const [isShowingLastFundVideo, setIsShowingLastFundVideo] = React.useState<boolean>(false);
    const [elementsIAAmount, setElementsIAAmount] = React.useState<number>(0);
    const [price, setPrice] = React.useState<number>(0);
    const [lastPrice, setLastPrice] = React.useState<number>(0);
    const [videoURL, setVideoURL] = React.useState<VideoURLData>(
    {
        url: props.chatGPTResponse.fund !== null ? props.chatGPTResponse.fund.urlVideo: "",
        manager: props.chatGPTResponse.managerName !== null ? translate(props.chatGPTResponse.managerName) : "",
        videoDate: props.chatGPTResponse.fund !== null ? props.chatGPTResponse.fund.videoDate : ""
    });
    const [lastFundURL, setLastFundURL] = React.useState<VideoURLData>(
    {
        url: props.chatGPTResponse.lastFund !== null ? props.chatGPTResponse.lastFund.urlVideo : "",
        manager: props.chatGPTResponse.managerName !== null ? translate(props.chatGPTResponse.managerName) : "",
        videoDate: props.chatGPTResponse.lastFund !== null ? props.chatGPTResponse.lastFund.videoDate : ""
    });
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [errorAfterGettingURL, setErrorAfterGettingURL] = React.useState<boolean>(false);
    const [isGeneratingAfterVideoView, setIsGeneratingAfterVideoView] = React.useState<boolean>(false);
    const [isLastFundVideo, setIsLastFundVideo] = React.useState<boolean>(false);
    const isFirstLoading = useRef<boolean>(true);
    const isLoadingVideo = useRef<boolean>(true);
    const isFirstLoadingElements = useRef<boolean>(true);
    const isFirstLoadingPrice = useRef<boolean>(true);
    const barValue = useRef<number>(0);

    React.useEffect(() =>
    {
        if(isFirstLoadingElements.current && props.chatGPTResponse.isin !== "")
        {
            isFirstLoadingElements.current = false;

            if(props.isUserSubscribedToElements === true)
            {
                ElementService.getElementsAmountBySavingsPlanId(props.savingsPlanId).then((iaResponse: any) =>
                {
                    setElementsIAAmount(iaResponse.elementIA);
                });
            }
        }
    }, [props]);
    React.useEffect(() =>
    {
        if(isFirstLoadingPrice.current)
        {
            var changed: boolean = true;
            if(props.chatGPTResponse.fund !== null && props.chatGPTResponse.fund.explanation !== "")
            {
                setPrice(props.chatGPTResponse.fund.price);
                changed = false;
            }
            if(props.chatGPTResponse.lastFund !== null && props.chatGPTResponse.lastFund.explanation !== "")
            {
                setLastPrice(props.chatGPTResponse.lastFund.price);
                changed = false;
            }
            isFirstLoadingPrice.current = changed;
        }
    }, [props]);
    React.useEffect(() =>
    {
        if(isFirstLoading.current && modalOpen.current)
        {
            if(props.chatGPTResponse.isGeneratingVideo === true || isGeneratingAfterVideoView === true)
            {
                let downloadTimer = setInterval(() =>
                {
                    isFirstLoading.current = false;
                    
                    if(modalOpen.current === true)
                    {
                        (document.getElementById("progressBar") as HTMLProgressElement).value++;
                        barValue.current = (document.getElementById("progressBar") as HTMLProgressElement).value;
                        if((document.getElementById("progressBar") as HTMLProgressElement).value >= (document.getElementById("progressBar") as HTMLProgressElement).max)
                        {
                            clearInterval(downloadTimer);
                            if((props.chatGPTResponse.isGeneratingVideo !== null && props.chatGPTResponse.isGeneratingVideo === true) || isGeneratingAfterVideoView === true)
                            {
                                getVideoURL(true);
                            }
                            else if(props.chatGPTResponse.lastFund !== null && isLastFundVideo === true)
                            {
                                getVideoURL(false);
                            }
                        }
                    }
                        
                }, 1800);
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGeneratingAfterVideoView, isLastFundVideo, modalOpen.current, props.chatGPTResponse.isGeneratingVideo, props.chatGPTResponse.lastFund]);
    React.useEffect(() =>
    {
        if(modalOpen.current && (isShowingVideo === true || isShowingLastFundVideo === true) && isLoadingVideo.current === true)
        {
            if(isShowingVideo === true && props.chatGPTResponse.fund.urlVideo === "" && videoURL.url === "")
            {
                isLoadingVideo.current = false;
                let downloadTimer = setInterval(() =>
                {
                    isFirstLoading.current = false;

                    if(modalOpen.current === true)
                    {
                        (document.getElementById("progressBar") as HTMLProgressElement).value++;
    
                        if((document.getElementById("progressBar") as HTMLProgressElement).value >= (document.getElementById("progressBar") as HTMLProgressElement).max)
                        {
                            clearInterval(downloadTimer);
                            getVideoURL(true);
                        }
                    }
                }, 1800);
            }
            if(isShowingLastFundVideo === true && props.chatGPTResponse.lastFund.urlVideo === "" && lastFundURL.url === "")
            {
                isLoadingVideo.current = false;
                let downloadTimer = setInterval(() =>
                {
                    isFirstLoading.current = false;

                    if(modalOpen.current === true)
                    {
                        (document.getElementById("progressBar") as HTMLProgressElement).value++;
    
                        if((document.getElementById("progressBar") as HTMLProgressElement).value >= (document.getElementById("progressBar") as HTMLProgressElement).max)
                        {
                            clearInterval(downloadTimer);
                            getVideoURL(false);
                        }
                    }
                }, 1800);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen.current]);
    const getVideoURL = (isFund: boolean) =>
    {
        barValue.current = 0;
        if(isFund)
        {
            var fundDate: any = convertToDateString(props.chatGPTResponse.fund.textDate);
            IronIAIntegrations.getVideoURL(props.chatGPTResponse.isin, fundDate).then((response: VideoURLData) =>
            {
                if(response !== undefined && response.url !== "Error")
                {
                    setVideoURL(response);
                    playVideo();
                }
                else if(response !== undefined && response.url === "Error")
                {
                    setErrorAfterGettingURL(true);
                    setErrorMessage("Se ha producido un error en la generación u obtención del vídeo y no estará disponible. Disculpa las molestias.");
                }
            });
        }
        else
        {
            var lastFundDate: any = convertToDateString(props.chatGPTResponse.lastFund.textDate);
            IronIAIntegrations.getVideoURL(props.chatGPTResponse.isin, lastFundDate).then((response: VideoURLData) =>
            {
                if(response !== undefined && response.url !== "Error")
                {
                    setLastFundURL(response);
                    setIsGeneratingAfterVideoView(false);
                    setIsLastFundVideo(false);
                    playLastFundVideo();
                }
                else if(response !== undefined && response.url === "Error")
                {
                    setErrorMessage("Se ha producido un error en la generación u obtención del vídeo y no estará disponible. Disculpa las molestias.");
                }
            });
        }
    }
    const getVideoView = () =>
    {
        hideFirstConfirmationModal();
        setErrorMessage("");
        var userId = Auth.getUserProfile().id;
        appInsights.trackEvent({name: "IronIA Explica - Video", properties: {isin: props.chatGPTResponse.isin, userId: Auth.getUserProfile().id, dateMultimedia: props.chatGPTResponse.lastFund.videoDate}});

        var objectToSend: VideoView =
        {
            userId: userId,
            savingsPlanId: props.savingsPlanId,
            isin: props.chatGPTResponse.isin,
            isLastVideo: true,
            videoDate: props.chatGPTResponse.lastFund.videoDate
        };

        IronIAIntegrations.addVideoView(objectToSend).then((response: VideoResponse) =>
        {
            if(response !== undefined && response.errorMessage === "")
            {
                setElementsIAAmount(response.elementsIARemaining);
                setLastPrice(0);
                isLoadingVideo.current = true;
                if(response.urlVideo !== null && response.urlVideo !== undefined)
                {
                    setLastFundURL({...videoURL, url: response.urlVideo});
                    setIsShowingLastFundVideo(true);
                }
                else
                {
                    setIsGeneratingAfterVideoView(true);
                    setIsLastFundVideo(true);
                }
            }
            else
            {
                setErrorMessage(response.errorMessage);
            }
        });
    }
    const postVideoView = () =>
    {
        hideConfirmationModal();
        setErrorMessage("");
        var userId = Auth.getUserProfile().id;
        appInsights.trackEvent({name: "IronIA Explica - Video", properties: {isin: props.chatGPTResponse.isin, userId: Auth.getUserProfile().id, dateMultimedia: props.chatGPTResponse.fund.videoDate}});

        var objectToSend: VideoView =
        {
            userId: userId,
            savingsPlanId: props.savingsPlanId,
            isin: props.chatGPTResponse.isin
        };

        IronIAIntegrations.addVideoView(objectToSend).then((response: VideoResponse) =>
        {
            if(response !== undefined && response.errorMessage === "")
            {
                setElementsIAAmount(response.elementsIARemaining);
                setPrice(0);
                isLoadingVideo.current = true;
                if(response.urlVideo !== null && response.urlVideo !== undefined)
                {
                    setVideoURL({...videoURL, url: response.urlVideo});
                    setIsShowingVideo(true);
                }
                else
                {
                    setIsGeneratingAfterVideoView(true);
                }
            }
            else
            {
                setErrorMessage(response.errorMessage);
            }
        });
    }
    const customEventVideoIATrigger = () =>
    {
        appInsights.trackEvent({name: "IronIA Explica", properties: {isin: props.chatGPTResponse.isin, userId: Auth.getUserProfile().id}});
    };
    const customEventIronIAExplicaTrigger = () =>
    {
        appInsights.trackEvent({name: "IronIA Explica", properties: {isin: props.chatGPTResponse.isin, userId: Auth.getUserProfile().id}});
        modalOpen.current = true;
        showModal();
    };
    const playVideo = () =>
    {
        setIsShowingVideo(true);
        if(isGeneratingAfterVideoView === true)
        {
            setIsGeneratingAfterVideoView(false);
        }
    }
    const playLastFundVideo = () =>
    {
        setIsShowingLastFundVideo(true);
    }
    const closeVideo = () =>
    {
        setIsShowingVideo(false);
        setIsShowingLastFundVideo(false);
        modalOpen.current = false;
        hideModal();
    }
    const convertToDateString = (date: string) =>
    {
        var dateSplitted: string = date.split(",")[1].trimStart().trimEnd();
        var arraySplitted: string[] = dateSplitted.split("de");
        arraySplitted = arraySplitted.map(x => x.trim());

        switch(arraySplitted[1])
        {
            case "enero":
                return arraySplitted[0]+"-01-"+arraySplitted[2];
            case "febrero":
                return arraySplitted[0]+"-02-"+arraySplitted[2];
            case "marzo":
                return arraySplitted[0]+"-03-"+arraySplitted[2];
            case "abril":
                return arraySplitted[0]+"-04-"+arraySplitted[2];
            case "mayo":
                return arraySplitted[0]+"-05-"+arraySplitted[2];
            case "junio":
                return arraySplitted[0]+"-06-"+arraySplitted[2];
            case "julio":
                return arraySplitted[0]+"-07-"+arraySplitted[2];
            case "agosto":
                return arraySplitted[0]+"-08-"+arraySplitted[2];
            case "septiembre":
                return arraySplitted[0]+"-09-"+arraySplitted[2];
            case "octubre":
                return arraySplitted[0]+"-10-"+arraySplitted[2];
            case "noviembre":
                return arraySplitted[0]+"-11-"+arraySplitted[2];
            case "diciembre":
                return arraySplitted[0]+"-12-"+arraySplitted[2];
        }
    }

    return(
        <React.Fragment>
            {props.isIframe !== undefined && props.isIframe === true &&
            (
                <S.ButtonIAIFrame>
                    <S.DivColButtonTextDisabled className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                        <S.TextDisabledIFrame theme={themeContext}>
                            Regístrate para IronIA Explica
                        </S.TextDisabledIFrame>
                    </S.DivColButtonTextDisabled>
                </S.ButtonIAIFrame>
            )}
            {(props.isIframe === undefined || props.isIframe === false) &&
            (
                <React.Fragment>
                    {props.chatGPTResponse.isin === "" &&
                    (
                        <React.Fragment>
                            {props.managerEnabled !== undefined && props.managerEnabled === true &&
                            (
                                <S.ButtonIADisabledInsideRow>
                                    <S.DivRowButtonDisabled className="ms-Grid-row">
                                        <S.DivColButtonSpinnerDisabled className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 ms-xl4 ms-xxl4 ms-xxxl4">
                                            <Spinner size={SpinnerSize.small} />
                                        </S.DivColButtonSpinnerDisabled>
                                        <S.DivColButtonTextDisabled className="ms-Grid-col ms-sm8 ms-md8 ms-lg8 ms-xl8 ms-xxl8 ms-xxxl8">
                                            <S.TextDisabled theme={themeContext}>
                                                IronIA Explica
                                            </S.TextDisabled>
                                        </S.DivColButtonTextDisabled>
                                    </S.DivRowButtonDisabled>
                                </S.ButtonIADisabledInsideRow>
                            )}
                            {(props.managerEnabled === undefined || props.managerEnabled === false) &&
                            (
                                <S.ButtonIADisabled>
                                    <S.DivRowButtonDisabled className="ms-Grid-row">
                                        <S.DivColButtonSpinnerDisabled className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 ms-xl4 ms-xxl4 ms-xxxl4">
                                            <Spinner size={SpinnerSize.small} />
                                        </S.DivColButtonSpinnerDisabled>
                                        <S.DivColButtonTextDisabled className="ms-Grid-col ms-sm8 ms-md8 ms-lg8 ms-xl8 ms-xxl8 ms-xxxl8">
                                            <S.TextDisabled theme={themeContext}>
                                                IronIA Explica
                                            </S.TextDisabled>
                                        </S.DivColButtonTextDisabled>
                                    </S.DivRowButtonDisabled>
                                </S.ButtonIADisabled>
                            )}
                        </React.Fragment>
                    )}
                    {props.chatGPTResponse.isin !== "" &&
                    (
                        <React.Fragment>
                            {props.managerEnabled !== undefined && props.managerEnabled === true &&
                            (
                                <S.ButtonIAInsideRow type="button" onClick={customEventIronIAExplicaTrigger} theme={themeContext}>
                                    IronIA Explica
                                </S.ButtonIAInsideRow>
                            )}
                            {(props.managerEnabled === undefined || props.managerEnabled === false) &&
                            (
                                <S.ButtonIA type="button" onClick={customEventIronIAExplicaTrigger} theme={themeContext}>
                                    IronIA Explica
                                </S.ButtonIA>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            <Modal
                titleAriaId={titleId}
                isOpen={isModalOpen}
                onDismiss={hideModal}
                isBlocking={false}
                containerClassName={containerStyles.container}
            >
                {props.isUserSubscribedToElements === true &&
                (
                    <React.Fragment>
                        <S.DivTitleContainer>
                            {props.chatGPTResponse.fund !== null &&
                            (
                                <S.TitleText theme={themeContext} id={titleId}>Explicación IA de fondo - {props.chatGPTResponse.fund.textDate}</S.TitleText>
                            )}
                            {props.chatGPTResponse.fund === null &&
                            (
                                <React.Fragment>
                                    {props.chatGPTResponse.lastFund !== null &&
                                    (
                                        <S.TitleText theme={themeContext} id={titleId}>Explicación IA de fondo - {props.chatGPTResponse.lastFund.textDate}</S.TitleText>
                                    )}
                                    {props.chatGPTResponse.lastFund === null &&
                                    (
                                        <S.TitleText theme={themeContext} id={titleId}>Explicación IA de fondo</S.TitleText>
                                    )}
                                </React.Fragment>
                            )}
                            <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Cerrar modal" onClick={closeVideo} />
                        </S.DivTitleContainer>
                        <S.DivBodyContainer>
                            {isShowingVideo === false && isShowingLastFundVideo === false &&
                            (
                                <React.Fragment>
                                    <S.DivRowSubtitle className="ms-Grid-row">
                                        <S.DivColFirstCard className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 ms-xl4 ms-xxxl4">
                                            {props.chatGPTResponse.lastFund !== null &&
                                            (
                                                <React.Fragment>
                                                    {props.chatGPTResponse.lastFund.isVideoAvailable &&
                                                    (
                                                        <S.SubtitleCard>
                                                            <S.SubtitleContainerCard>
                                                                <S.FirstDivInsideCard>
                                                                    <S.VideoText>
                                                                        Vídeo {props.chatGPTResponse.lastFund.videoDate !== "01/01/1753" ? props.chatGPTResponse.lastFund.videoDate : ""}
                                                                    </S.VideoText>
                                                                </S.FirstDivInsideCard>
                                                                <S.SecondDivInsideCard>
                                                                    <CustomIcon iconName="Video" size="25" cursor="default" verticalAlign="bottom" fontWeight="600" />
                                                                </S.SecondDivInsideCard>
                                                            </S.SubtitleContainerCard>
                                                            <S.SubtitleSecondContainerCard>
                                                                <S.ThirdDivInsideCard>
                                                                    <S.PriceLastVideo>
                                                                        {lastPrice}
                                                                    </S.PriceLastVideo>
                                                                </S.ThirdDivInsideCard>
                                                                <S.FourthDivInsideCard>
                                                                    <S.ButtonWatchLastVideo onClick={showFirstConfirmationModal}>
                                                                        Ver
                                                                    </S.ButtonWatchLastVideo>
                                                                </S.FourthDivInsideCard>
                                                            </S.SubtitleSecondContainerCard>
                                                        </S.SubtitleCard>
                                                    )}
                                                    {!props.chatGPTResponse.lastFund.isVideoAvailable &&
                                                    (
                                                        <S.SubtitleCard>
                                                            <S.SubtitleContainerCard>
                                                                <S.FirstDivInsideCard>
                                                                    <S.VideoText>
                                                                        No existe vídeo previo
                                                                    </S.VideoText>
                                                                </S.FirstDivInsideCard>
                                                                <S.SecondDivInsideCard>
                                                                    <CustomIcon iconName="Video" size="25" cursor="default" verticalAlign="bottom" fontWeight="600" />
                                                                </S.SecondDivInsideCard>
                                                            </S.SubtitleContainerCard>
                                                            <S.SubtitleSecondContainerCard>
                                                                <S.ThirdDivInsideCard>
                                                                    <S.PriceLastVideo>
                                                                        -
                                                                    </S.PriceLastVideo>
                                                                </S.ThirdDivInsideCard>
                                                                <S.FourthDivInsideCard>
                                                                    <S.ButtonWatchLastVideoDisabled>
                                                                        Ver
                                                                    </S.ButtonWatchLastVideoDisabled>
                                                                </S.FourthDivInsideCard>
                                                            </S.SubtitleSecondContainerCard>
                                                        </S.SubtitleCard>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {props.chatGPTResponse.lastFund === null &&
                                            (
                                                <React.Fragment>
                                                    <S.SubtitleCard>
                                                        <S.SubtitleContainerCard>
                                                            <S.FirstDivInsideCard>
                                                                <S.VideoText>
                                                                    No existe vídeo previo
                                                                </S.VideoText>
                                                            </S.FirstDivInsideCard>
                                                            <S.SecondDivInsideCard>
                                                                <CustomIcon iconName="Video" size="25" cursor="default" verticalAlign="bottom" fontWeight="600" />
                                                            </S.SecondDivInsideCard>
                                                        </S.SubtitleContainerCard>
                                                        <S.SubtitleSecondContainerCard>
                                                            <S.ThirdDivInsideCard>
                                                                <S.PriceLastVideo>
                                                                    -
                                                                </S.PriceLastVideo>
                                                            </S.ThirdDivInsideCard>
                                                            <S.FourthDivInsideCard>
                                                                <S.ButtonWatchLastVideoDisabled>
                                                                    Ver
                                                                </S.ButtonWatchLastVideoDisabled>
                                                            </S.FourthDivInsideCard>
                                                        </S.SubtitleSecondContainerCard>
                                                    </S.SubtitleCard>
                                                </React.Fragment>
                                            )}
                                        </S.DivColFirstCard>
                                        <S.DivColSecondCard className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 ms-xl4 ms-xxxl4">
                                            {props.chatGPTResponse.fund !== null &&
                                            (
                                                <React.Fragment>
                                                    {props.chatGPTResponse.isGeneratingVideo &&
                                                    (
                                                        <React.Fragment>
                                                            {errorAfterGettingURL === false &&
                                                            (
                                                                <React.Fragment>
                                                                    <S.SubtitleCard>
                                                                        <S.SubtitleContainerCard>
                                                                            <S.FirstDivInsideCard>
                                                                                <S.VideoText>
                                                                                    Generando vídeo
                                                                                </S.VideoText>
                                                                            </S.FirstDivInsideCard>
                                                                            <S.SecondDivInsideCard>
                                                                                <CustomIcon iconName="Video" size="25" cursor="default" verticalAlign="bottom" fontWeight="600" />
                                                                            </S.SecondDivInsideCard>
                                                                        </S.SubtitleContainerCard>
                                                                        <S.SubtitleSecondContainerCard2>
                                                                            <Spinner size={SpinnerSize.large} style={{paddingLeft: "1em"}} />
                                                                        </S.SubtitleSecondContainerCard2>
                                                                    </S.SubtitleCard>
                                                                </React.Fragment>
                                                            )}
                                                            {errorAfterGettingURL === true &&
                                                            (
                                                                <React.Fragment>
                                                                    <S.SubtitleCard>
                                                                        <S.SubtitleContainerCard>
                                                                            <S.FirstDivInsideCard>
                                                                                <S.VideoText>
                                                                                    Error
                                                                                </S.VideoText>
                                                                            </S.FirstDivInsideCard>
                                                                            <S.SecondDivInsideCard>
                                                                                <CustomIcon iconName="Video" size="25" cursor="default" verticalAlign="bottom" fontWeight="600" />
                                                                            </S.SecondDivInsideCard>
                                                                        </S.SubtitleContainerCard>
                                                                        <S.SubtitleSecondContainerCard2>
                                                                        </S.SubtitleSecondContainerCard2>
                                                                    </S.SubtitleCard>
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                    {!props.chatGPTResponse.isGeneratingVideo &&
                                                    (
                                                        <React.Fragment>
                                                            {props.chatGPTResponse.fund !== null && props.chatGPTResponse.fund.isFundPromoted &&
                                                            (
                                                                <React.Fragment>
                                                                    {!props.chatGPTResponse.fund.isVideoAvailable &&
                                                                    (
                                                                        <React.Fragment>
                                                                            <S.SubtitleCard>
                                                                                <S.SubtitleContainerCard>
                                                                                    <S.FirstDivInsideCard>
                                                                                        <S.VideoText>
                                                                                            Reproducir Vídeo
                                                                                        </S.VideoText>
                                                                                    </S.FirstDivInsideCard>
                                                                                    <S.SecondDivInsideCard>
                                                                                        <CustomIcon iconName="Video" size="25" cursor="default" verticalAlign="bottom" fontWeight="600" />
                                                                                    </S.SecondDivInsideCard>
                                                                                </S.SubtitleContainerCard>
                                                                                <S.SubtitleSecondContainerCard2>
                                                                                    <S.ThirdDivInsideCard>
                                                                                        <S.PriceLastVideo>
                                                                                            -
                                                                                        </S.PriceLastVideo>
                                                                                    </S.ThirdDivInsideCard>
                                                                                    <S.FourthDivInsideCard>
                                                                                        <S.ButtonWatchLastVideoDisabled>
                                                                                            Reproducir
                                                                                        </S.ButtonWatchLastVideoDisabled>
                                                                                    </S.FourthDivInsideCard>
                                                                                </S.SubtitleSecondContainerCard2>
                                                                            </S.SubtitleCard>
                                                                        </React.Fragment>
                                                                    )}
                                                                    {props.chatGPTResponse.fund.isVideoAvailable &&
                                                                    (
                                                                        <React.Fragment>
                                                                            <S.SubtitleCard>
                                                                                <S.SubtitleContainerCard>
                                                                                    <S.FirstDivInsideCard>
                                                                                        <S.VideoText>
                                                                                            Reproducir Vídeo
                                                                                        </S.VideoText>
                                                                                    </S.FirstDivInsideCard>
                                                                                    <S.SecondDivInsideCard>
                                                                                        <CustomIcon iconName="Video" size="25" cursor="default" verticalAlign="bottom" fontWeight="600" />
                                                                                    </S.SecondDivInsideCard>
                                                                                </S.SubtitleContainerCard>
                                                                                <S.SubtitleSecondContainerCard2>
                                                                                    <S.ThirdDivInsideCard>
                                                                                        <S.PriceLastVideo>
                                                                                            {price}
                                                                                        </S.PriceLastVideo>
                                                                                    </S.ThirdDivInsideCard>
                                                                                    <S.FourthDivInsideCard>
                                                                                        <S.ButtonWatchLastVideo onClick={playVideo}>
                                                                                            Reproducir
                                                                                        </S.ButtonWatchLastVideo>
                                                                                    </S.FourthDivInsideCard>
                                                                                </S.SubtitleSecondContainerCard2>
                                                                            </S.SubtitleCard>
                                                                        </React.Fragment>
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                            {!props.chatGPTResponse.fund.isFundPromoted &&
                                                            (
                                                                <React.Fragment>
                                                                    {!props.chatGPTResponse.fund.isVideoAvailable &&
                                                                    (
                                                                        <React.Fragment>
                                                                            {isGeneratingAfterVideoView === true &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    <S.SubtitleCard>
                                                                                        <S.SubtitleContainerCard>
                                                                                            <S.FirstDivInsideCard>
                                                                                                <S.VideoText>
                                                                                                    Generando Vídeo
                                                                                                </S.VideoText>
                                                                                            </S.FirstDivInsideCard>
                                                                                            <S.SecondDivInsideCard>
                                                                                                <CustomIcon iconName="Video" size="25" cursor="default" verticalAlign="bottom" fontWeight="600" />
                                                                                            </S.SecondDivInsideCard>
                                                                                        </S.SubtitleContainerCard>
                                                                                        <S.SubtitleSecondContainerCard2>
                                                                                            <Spinner size={SpinnerSize.large} style={{ paddingLeft: "1em" }} />
                                                                                        </S.SubtitleSecondContainerCard2>
                                                                                    </S.SubtitleCard>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {isGeneratingAfterVideoView === false &&
                                                                            (
                                                                                <React.Fragment>
                                                                                    <S.SubtitleCard>
                                                                                        <S.SubtitleContainerCard>
                                                                                            <S.FirstDivInsideCard>
                                                                                                <S.VideoText>
                                                                                                    Generar Vídeo
                                                                                                </S.VideoText>
                                                                                            </S.FirstDivInsideCard>
                                                                                            <S.SecondDivInsideCard>
                                                                                                <CustomIcon iconName="Video" size="25" cursor="default" verticalAlign="bottom" fontWeight="600" />
                                                                                            </S.SecondDivInsideCard>
                                                                                        </S.SubtitleContainerCard>
                                                                                        <S.SubtitleSecondContainerCard2>
                                                                                            <S.ThirdDivInsideCard>
                                                                                                <S.PriceLastVideo>
                                                                                                    {price}
                                                                                                </S.PriceLastVideo>
                                                                                            </S.ThirdDivInsideCard>
                                                                                            <S.FourthDivInsideCard>
                                                                                                <S.ButtonWatchLastVideo onClick={showConfirmationModal}>
                                                                                                    Generar
                                                                                                </S.ButtonWatchLastVideo>
                                                                                            </S.FourthDivInsideCard>
                                                                                        </S.SubtitleSecondContainerCard2>
                                                                                    </S.SubtitleCard>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </React.Fragment>
                                                                    )}
                                                                    {props.chatGPTResponse.fund.isVideoAvailable &&
                                                                    (
                                                                        <React.Fragment>
                                                                            <S.SubtitleCard>
                                                                                <S.SubtitleContainerCard>
                                                                                    <S.FirstDivInsideCard>
                                                                                        <S.VideoText>
                                                                                            Reproducir Vídeo
                                                                                        </S.VideoText>
                                                                                    </S.FirstDivInsideCard>
                                                                                    <S.SecondDivInsideCard>
                                                                                        <CustomIcon iconName="Video" size="25" cursor="default" verticalAlign="bottom" fontWeight="600" />
                                                                                    </S.SecondDivInsideCard>
                                                                                </S.SubtitleContainerCard>
                                                                                <S.SubtitleSecondContainerCard2>
                                                                                    <S.ThirdDivInsideCard>
                                                                                        <S.PriceLastVideo>
                                                                                            {price}
                                                                                        </S.PriceLastVideo>
                                                                                    </S.ThirdDivInsideCard>
                                                                                    <S.FourthDivInsideCard>
                                                                                        {price === 0 &&
                                                                                        (
                                                                                            <S.ButtonWatchLastVideo onClick={playVideo}>
                                                                                                Reproducir
                                                                                            </S.ButtonWatchLastVideo>
                                                                                        )}
                                                                                        {price !== 0 &&
                                                                                        (
                                                                                            <S.ButtonWatchLastVideo onClick={showConfirmationModal}>
                                                                                                Reproducir
                                                                                            </S.ButtonWatchLastVideo>
                                                                                        )}
                                                                                    </S.FourthDivInsideCard>
                                                                                </S.SubtitleSecondContainerCard2>
                                                                            </S.SubtitleCard>
                                                                        </React.Fragment>
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {props.chatGPTResponse.fund === null &&
                                            (
                                                <React.Fragment>
                                                    <S.SubtitleCard>
                                                        <S.SubtitleContainerCard>
                                                            <S.FirstDivInsideCard>
                                                                <S.VideoTextDisabled>
                                                                    Generar Vídeo
                                                                </S.VideoTextDisabled>
                                                            </S.FirstDivInsideCard>
                                                            <S.SecondDivInsideCard>
                                                                <CustomIcon iconName="Video" size="25" cursor="default" verticalAlign="bottom" fontWeight="600" />
                                                            </S.SecondDivInsideCard>
                                                        </S.SubtitleContainerCard>
                                                        <S.SubtitleSecondContainerCard2>
                                                            <S.ThirdDivInsideCard>
                                                                <S.PriceLastVideo>
                                                                    -
                                                                </S.PriceLastVideo>
                                                            </S.ThirdDivInsideCard>
                                                            <S.FourthDivInsideCard>
                                                                <S.ButtonWatchLastVideoDisabled>
                                                                    Generar
                                                                </S.ButtonWatchLastVideoDisabled>
                                                            </S.FourthDivInsideCard>
                                                        </S.SubtitleSecondContainerCard2>
                                                    </S.SubtitleCard>
                                                </React.Fragment>
                                            )}
                                        </S.DivColSecondCard>
                                        <S.DivColThirdCard className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 ms-xl4 ms-xxxl4">
                                            <S.SubtitleCard>
                                                <S.SubtitleContainerCard>
                                                    <S.FirstDivInsideCard>
                                                        <S.ElementsIAText onClick={() => window.open("https://blog.ironia.tech/post/elements-ia")}>
                                                            Elements IA
                                                        </S.ElementsIAText>
                                                    </S.FirstDivInsideCard>
                                                    <S.SecondDivInsideCard>
                                                        <img alt="IA" src={ElementsIAIcon} width="25px" style={{cursor: "default", marginTop: "2px"}} />
                                                    </S.SecondDivInsideCard>
                                                </S.SubtitleContainerCard>
                                                <S.SubtitleSecondContainerCard2>
                                                    <S.ThirdDivInsideCard>
                                                        <S.PriceLastVideo>
                                                            {Intl.NumberFormat().format(elementsIAAmount)}
                                                        </S.PriceLastVideo>
                                                    </S.ThirdDivInsideCard>
                                                    <S.FourthDivInsideCard>
                                                        <S.ButtonWatchLastVideo onClick={() => history.push("/ElementsIA")}>
                                                            Comprar
                                                        </S.ButtonWatchLastVideo>
                                                    </S.FourthDivInsideCard>
                                                </S.SubtitleSecondContainerCard2>
                                            </S.SubtitleCard>
                                        </S.DivColThirdCard>
                                    </S.DivRowSubtitle>
                                    {errorMessage === "" &&
                                    (
                                        <React.Fragment>
                                            {props.chatGPTResponse.fund !== null &&
                                            (
                                                <React.Fragment>
                                                    {(props.chatGPTResponse.isGeneratingVideo === true || isGeneratingAfterVideoView === true) &&
                                                    (
                                                        <S.DivRowErrorMessage className="ms-Grid-row">
                                                            <S.DivColErrorMessage className="ms-Grid-col ms-sm12 ms-xxxl12">
                                                                <progress id="progressBar" max="100" value={barValue.current} style={{width: "100%"}}></progress>
                                                            </S.DivColErrorMessage>
                                                        </S.DivRowErrorMessage>
                                                    )}
                                                    {props.chatGPTResponse.isGeneratingVideo === false && isGeneratingAfterVideoView === false &&
                                                    (
                                                        <S.DivRowErrorMessage className="ms-Grid-row">
                                                            <S.DivColErrorMessage className="ms-Grid-col ms-sm12 ms-xxxl12">
                                                                <S.ErrotText>
                                                                    No se ha podido generar un nuevo vídeo promocionado del fondo.
                                                                </S.ErrotText>
                                                            </S.DivColErrorMessage>
                                                        </S.DivRowErrorMessage>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {props.chatGPTResponse.fund === null &&
                                            (
                                                <React.Fragment>
                                                    {props.chatGPTResponse.lastFund === null &&
                                                    (
                                                        <S.DivRowErrorMessage className="ms-Grid-row">
                                                            <S.DivColErrorMessage className="ms-Grid-col ms-sm12 ms-xxxl12">
                                                                <S.ErrotText>
                                                                    No se ha podido establecer una conexión con el servidor
                                                                    de ChatGPT para la explicación del Fondo y la generación
                                                                    del vídeo.
                                                                </S.ErrotText>
                                                            </S.DivColErrorMessage>
                                                        </S.DivRowErrorMessage>
                                                    )}
                                                    {props.chatGPTResponse.lastFund !== null &&
                                                    (
                                                        <S.DivRowErrorMessage className="ms-Grid-row">
                                                            <S.DivColErrorMessage className="ms-Grid-col ms-sm12 ms-xxxl12">
                                                                <S.ErrotText>
                                                                    No se puede generar un vídeo nuevo de este fondo. Los datos no han variado lo suficiente.
                                                                </S.ErrotText>
                                                            </S.DivColErrorMessage>
                                                        </S.DivRowErrorMessage>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                    {errorMessage !== "" &&
                                    (
                                        <S.DivRowErrorMessage className="ms-Grid-row">
                                            <S.DivColErrorMessage className="ms-Grid-col ms-sm12 ms-xxxl12">
                                                <S.ErrotText>
                                                    {errorMessage}
                                                </S.ErrotText>
                                            </S.DivColErrorMessage>
                                        </S.DivRowErrorMessage>
                                    )}
                                </React.Fragment>
                            )}
                            {isShowingVideo === true && isShowingLastFundVideo === false &&
                            (
                                <React.Fragment>
                                    <S.ContainerDiv onClick={customEventVideoIATrigger}>
                                        {props.chatGPTResponse.fund.isFundPromoted &&
                                        (
                                            <S.DivSubtitleManager>
                                                <S.SubtitleManager theme={theme}>Vídeo patrocinado por {translate(props.chatGPTResponse.managerName !== undefined ? props.chatGPTResponse.managerName : videoURL.manager)}</S.SubtitleManager>
                                            </S.DivSubtitleManager>
                                        )}
                                        {props.chatGPTResponse.fund.urlVideo !== "" &&
                                        (
                                            <ReactPlayer style={{textAlign: "center", width: "auto", marginBottom: "1em"}} config={{file:{forceVideo: true, attributes: {controlsList: 'nodownload'}}}}
                                                width={"100%"} height={"650px"} playing={true} url={props.chatGPTResponse.fund.urlVideo} muted={false} controls={true}
                                            />
                                        )}
                                        {props.chatGPTResponse.fund.urlVideo === "" && videoURL.url !== "" &&
                                        (
                                            <ReactPlayer style={{textAlign: "center", width: "auto", marginBottom: "1em"}} config={{file:{forceVideo: true, attributes: {controlsList: 'nodownload'}}}}
                                                width={"100%"} height={"650px"} playing={true} url={videoURL.url} muted={false} controls={true}
                                            />
                                        )}
                                        {props.chatGPTResponse.fund.urlVideo === "" && videoURL.url === "" &&
                                        (
                                            <S.DivRowErrorMessage className="ms-Grid-row">
                                                <Spinner size={SpinnerSize.large} styles={{root: {paddingLeft: "1em !important"}}} />
                                                <S.DivColErrorMessage className="ms-Grid-col ms-sm12 ms-xxxl12">
                                                    <progress id="progressBar" max="100" value="0" style={{width: "100%"}}></progress>
                                                </S.DivColErrorMessage>
                                            </S.DivRowErrorMessage>
                                        )}
                                    </S.ContainerDiv>
                                </React.Fragment>
                            )}
                            {isShowingVideo === false && isShowingLastFundVideo === true &&
                            (
                                <React.Fragment>
                                    <S.ContainerDiv onClick={customEventVideoIATrigger}>
                                        {props.chatGPTResponse.lastFund.isFundPromoted &&
                                        (
                                            <S.DivSubtitleManager>
                                                <S.SubtitleManager theme={theme}>Vídeo patrocinado por {translate(props.chatGPTResponse.managerName !== undefined ? props.chatGPTResponse.managerName : videoURL.manager)}</S.SubtitleManager>
                                            </S.DivSubtitleManager>
                                        )}
                                        {props.chatGPTResponse.lastFund.urlVideo !== "" &&
                                        (
                                            <ReactPlayer style={{textAlign: "center", width: "auto", marginBottom: "1em"}} config={{file:{forceVideo: true, attributes: {controlsList: 'nodownload'}}}}
                                                width={"100%"} height={"650px"} playing={true} url={props.chatGPTResponse.lastFund.urlVideo} muted={false} controls={true}
                                            />
                                        )}
                                        {props.chatGPTResponse.lastFund.urlVideo === "" && lastFundURL.url !== "" &&
                                        (
                                            <ReactPlayer style={{textAlign: "center", width: "auto", marginBottom: "1em"}} config={{file:{forceVideo: true, attributes: {controlsList: 'nodownload'}}}}
                                                width={"100%"} height={"650px"} playing={true} url={lastFundURL.url} muted={false} controls={true}
                                            />
                                        )}
                                        {props.chatGPTResponse.lastFund.urlVideo === "" && lastFundURL.url === "" &&
                                        (
                                            <S.DivRowErrorMessage className="ms-Grid-row">
                                                <Spinner size={SpinnerSize.large} />
                                                <S.DivColErrorMessage className="ms-Grid-col ms-sm12 ms-xxxl12">
                                                    <progress id="progressBar" max="100" value="0" style={{width: "100%"}}></progress>
                                                </S.DivColErrorMessage>
                                            </S.DivRowErrorMessage>
                                        )}
                                    </S.ContainerDiv>
                                </React.Fragment>
                            )}
                            <S.DivBodyExplanationRow className="ms-Grid-row">
                                <S.TextExplanation theme={themeContext}>
                                    {props.chatGPTResponse.fund !== null &&
                                    (
                                        <React.Fragment>
                                            {props.chatGPTResponse.fund.explanation === "" &&
                                            (
                                                <React.Fragment>
                                                    Cargando datos del fondo...
                                                </React.Fragment>
                                            )}
                                            {props.chatGPTResponse.fund.explanation !== "" &&
                                            (
                                                <React.Fragment>
                                                    {props.chatGPTResponse.fund.explanation}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                    {props.chatGPTResponse.fund === null &&
                                    (
                                        <React.Fragment>
                                            {props.chatGPTResponse.lastFund !== null &&
                                            (
                                                <React.Fragment>
                                                    {props.chatGPTResponse.lastFund.explanation === "" &&
                                                    (
                                                        <React.Fragment>
                                                            Cargando datos del fondo...
                                                        </React.Fragment>
                                                    )}
                                                    {props.chatGPTResponse.lastFund.explanation !== "" &&
                                                    (
                                                        <React.Fragment>
                                                            {props.chatGPTResponse.lastFund.explanation}
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {props.chatGPTResponse.lastFund === null &&
                                            (
                                                <React.Fragment>
                                                    Generando explicación del fondo...
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </S.TextExplanation>
                            </S.DivBodyExplanationRow>
                            <S.WarningContainer theme={themeContext}>
                                <S.WarningText>
                                    El presente contenido ha sido generado a través de herramientas de inteligencia artificial (IA) y, por lo tanto, le advertimos que la información que se traslade podría contener errores o no ser del todo precisa.
                                    La información aquí contenida se expone a título meramente informativo y no constituye una recomendación de inversión ni invitación, oferta, solicitud u obligación por parte de IronIA para llevar a cabo operación o transacción alguna.
                                    Cualquier decisión de inversión deberá adoptarse teniendo en cuenta la información pública existente del instrumento financiero y atendiendo al contenido del folleto informativo y de la restante documentación disponible en la página web del emisor de dicho instrumento y/o registrada en la Comisión Nacional del Mercado de Valores.
                                    Este contenido no constituye información pública aprobada por la Comisión Nacional del Mercado de Valores.
                                    Este contenido puede ser considerado publicidad atendiendo a la definición de la RAE “Conjunto de medios que se emplean para divulgar o extender la noticia de las cosas o de los hechos”.
                                    Informamos: Rentabilidades pasadas no garantizan rentabilidades futuras; deberá analizar los riesgos de pérdida de capital invertido asociados a cada potencial inversión.
                                </S.WarningText>
                            </S.WarningContainer>
                        </S.DivBodyContainer>
                    </React.Fragment>
                )}
                {props.isUserSubscribedToElements === false &&
                (
                    <React.Fragment>
                        <S.DivTitleContainer>
                            {props.chatGPTResponse.fund !== undefined && props.chatGPTResponse.fund !== null &&
                            (
                                <React.Fragment>
                                    <S.TitleText theme={themeContext} id={titleId}>Explicación IA de fondo - {props.chatGPTResponse.fund.textDate}</S.TitleText>
                                </React.Fragment>
                            )}
                            {(props.chatGPTResponse.fund === undefined || props.chatGPTResponse.fund === null) &&
                            (
                                <React.Fragment>
                                    {props.chatGPTResponse.lastFund !== undefined && props.chatGPTResponse.lastFund !== null &&
                                    (
                                        <React.Fragment>
                                            <S.TitleText theme={themeContext} id={titleId}>Explicación IA de fondo - {props.chatGPTResponse.lastFund.textDate}</S.TitleText>
                                        </React.Fragment>
                                    )}
                                    {(props.chatGPTResponse.lastFund === undefined || props.chatGPTResponse.lastFund === null) &&
                                    (
                                        <React.Fragment>
                                            <S.TitleText theme={themeContext} id={titleId}>Explicación IA de fondo</S.TitleText>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                            <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Cerrar modal" onClick={hideModal} />
                        </S.DivTitleContainer>
                        <S.DivBodyContainerNoElements>
                            <S.DivNoElementsBodyRow className="ms-Grid-row">
                                <S.DivNoElementsColText className="ms-Grid-col ms-sm8 ms-md8 ms-lg8 ms-xl8 ms-xxl8 ms-xxxl8">
                                    <S.TextSubscribe theme={themeContext}>
                                        Para acceder al video de IronIA Explica tienes que suscribirte al programa de Elements.
                                    </S.TextSubscribe>
                                </S.DivNoElementsColText>
                                <S.DivNoElementsColButtonSubscribe className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 ms-xl4 ms-xxl4 ms-xxxl4">
                                    <S.ButtonSubscribeElements theme={themeContext} onClick={() => history.push("/Elements")}>
                                        Suscríbete a Elements
                                    </S.ButtonSubscribeElements>
                                </S.DivNoElementsColButtonSubscribe>
                                <S.DivBodyExplanationRow className="ms-Grid-row">
                                    <S.TextExplanation theme={themeContext}>
                                        {props.chatGPTResponse.fund !== undefined && props.chatGPTResponse.fund !== null &&
                                        (
                                            <React.Fragment>
                                                {props.chatGPTResponse.fund.explanation === "" &&
                                                (
                                                    <React.Fragment>
                                                        Cargando datos del fondo...
                                                    </React.Fragment>
                                                )}
                                                {props.chatGPTResponse.fund.explanation !== "" &&
                                                (
                                                    <React.Fragment>
                                                        {props.chatGPTResponse.fund.explanation}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                        {(props.chatGPTResponse.fund === undefined || props.chatGPTResponse.fund === null) &&
                                        (
                                            <React.Fragment>
                                                {props.chatGPTResponse.lastFund !== undefined && props.chatGPTResponse.lastFund !== null &&
                                                (
                                                    <React.Fragment>
                                                        {props.chatGPTResponse.lastFund.explanation === "" &&
                                                        (
                                                            <React.Fragment>
                                                                Cargando datos del fondo...
                                                            </React.Fragment>
                                                        )}
                                                        {props.chatGPTResponse.lastFund.explanation !== "" &&
                                                        (
                                                            <React.Fragment>
                                                                {props.chatGPTResponse.lastFund.explanation}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                                {(props.chatGPTResponse.lastFund === undefined || props.chatGPTResponse.lastFund === null) &&
                                                (
                                                    <React.Fragment>
                                                        Cargando datos del fondo...
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </S.TextExplanation>
                                </S.DivBodyExplanationRow>
                                <S.WarningContainer theme={themeContext}>
                                    <S.WarningText>
                                        El presente contenido ha sido generado a través de herramientas de inteligencia artificial (IA) y, por lo tanto, le advertimos que la información que se traslade podría contener errores o no ser del todo precisa.
                                        La información aquí contenida se expone a título meramente informativo y no constituye una recomendación de inversión ni invitación, oferta, solicitud u obligación por parte de IronIA para llevar a cabo operación o transacción alguna.
                                        Cualquier decisión de inversión deberá adoptarse teniendo en cuenta la información pública existente del instrumento financiero y atendiendo al contenido del folleto informativo y de la restante documentación disponible en la página web del emisor de dicho instrumento y/o registrada en la Comisión Nacional del Mercado de Valores.
                                        Este contenido no constituye información pública aprobada por la Comisión Nacional del Mercado de Valores.
                                        Este contenido puede ser considerado publicidad atendiendo a la definición de la RAE “Conjunto de medios que se emplean para divulgar o extender la noticia de las cosas o de los hechos”.
                                        Informamos: Rentabilidades pasadas no garantizan rentabilidades futuras; deberá analizar los riesgos de pérdida de capital invertido asociados a cada potencial inversión.
                                    </S.WarningText>
                                </S.WarningContainer>
                            </S.DivNoElementsBodyRow>
                        </S.DivBodyContainerNoElements>
                    </React.Fragment>
                )}
            </Modal>
            <Modal titleAriaId={secondTitleId} isOpen={isConfirmationModalOpen} onDismiss={hideConfirmationModal} isBlocking={false} containerClassName={containerConfirmationStyles.container}>
                <S.MainRow className="ms-Grid-row">
                    <S.DivText className="ms-Grid-col ms-sm12 ms-xxxl12">
                        <S.ConfirmationText>
                            ¿Estás seguro de querer generar el vídeo?
                        </S.ConfirmationText>
                    </S.DivText>
                    <S.DivButtonYes className="ms-Grid-col ms-sm6 ms-xxxl6">
                        <S.ButtonYes onClick={postVideoView}>Sí</S.ButtonYes>
                    </S.DivButtonYes>
                    <S.DivButtonNo className="ms-Grid-col ms-sm6 ms-xxxl6">
                        <S.ButtonNo onClick={hideConfirmationModal}>No</S.ButtonNo>
                    </S.DivButtonNo>
                </S.MainRow>
            </Modal>
            <Modal titleAriaId={thirdTitleId} isOpen={isFirstConfirmationModalOpen} onDismiss={hideFirstConfirmationModal} isBlocking={false} containerClassName={containerConfirmationStyles.container}>
                <S.MainRow className="ms-Grid-row">
                    <S.DivText className="ms-Grid-col ms-sm12 ms-xxxl12">
                        <S.ConfirmationText>
                            ¿Estás seguro de querer reproducir el vídeo?
                        </S.ConfirmationText>
                    </S.DivText>
                    <S.DivButtonYes className="ms-Grid-col ms-sm6 ms-xxxl6">
                        <S.ButtonYes onClick={getVideoView}>Sí</S.ButtonYes>
                    </S.DivButtonYes>
                    <S.DivButtonNo className="ms-Grid-col ms-sm6 ms-xxxl6">
                        <S.ButtonNo onClick={hideFirstConfirmationModal}>No</S.ButtonNo>
                    </S.DivButtonNo>
                </S.MainRow>
            </Modal>
        </React.Fragment>
    );
}
import React from "react";
import { ThemeContext } from "@fluentui/react-theme-provider";
import styled from "styled-components";
import { Category, SubCategory } from "../../../services/subcategories";
import TableBody from "./tableBody";
import { StockDownIcon, StockUpIcon } from "@fluentui/react-icons-mdl2";

type Props =
    {
        categories: Category[];
        subCategories: SubCategory[];
        selectedRatioKey: string;
    };

export type SortConfig =
    {
        key:
        | "ratio1m"
        | "ratio3m"
        | "ratio6m"
        | "ratio1y"
        | "ratio2y"
        | "ratio3y"
        | "ratio4y"
        | "ratio5y"
        | "bench1m"
        | "bench3m"
        | "bench6m"
        | "bench1y"
        | "bench2y"
        | "bench3y"
        | "bench4y"
        | "bench5y";
        direction: "descending" | "ascending";
    };

const TableCategory = (props: Props) =>
{
    const {categories, subCategories, selectedRatioKey} = props;
    const [sortConfig, setSortConfig] = React.useState<SortConfig>({key: "ratio1m", direction: "descending"});
    const [sortedCategories, setSortedCategories] = React.useState<Category[]>([]);
    const [sortedSubCategories, setSortedSubCategories] = React.useState<SubCategory[]>([]);
    const theme = React.useContext(ThemeContext);

    const requestSort = (key: typeof sortConfig.key) =>
    {
        let direction: typeof sortConfig.direction = "descending";
        if(sortConfig.key === key && sortConfig.direction === "descending")
        {
            direction = "ascending";
        }
        setSortConfig({key, direction});
    };

    React.useEffect(() =>
    {
        let sortedCategories = [...categories];

        sortedCategories.sort((a, b) =>
        {
            let valueA = a[sortConfig.key] ?? 0;
            let valueB = b[sortConfig.key] ?? 0;
            if(valueA > valueB)
            {
                return sortConfig.direction === "descending" ? -1 : 1;
            }
            else if (valueA < valueB)
            {
                return sortConfig.direction === "descending" ? 1 : -1;
            }
            else
            {
                return 0;
            }
        });

        let sortedSubCategories = [...subCategories];

        sortedSubCategories.sort((a, b) =>
        {
            let valueA = a[sortConfig.key] ?? 0;
            let valueB = b[sortConfig.key] ?? 0;
            if(valueA > valueB)
            {
                return sortConfig.direction === "descending" ? -1 : 1;
            }
            else if (valueA < valueB)
            {
                return sortConfig.direction === "descending" ? 1 : -1;
            }
            else
            {
                return 0;
            }
        });

        setSortedCategories(sortedCategories);
        setSortedSubCategories(sortedSubCategories);
    }, [categories, subCategories, sortConfig.key, sortConfig.direction]);

    return(
        <TableStyled theme={theme}>
            <thead>
                <tr>
                    <th>
                        <div></div>
                    </th>
                    <StyledTh colSpan={2}>
                        <div>1 Mes</div>
                    </StyledTh>
                    <StyledTh colSpan={2}>
                        <div>3 Meses</div>
                    </StyledTh>
                    <StyledTh colSpan={2}>
                        <div>6 Meses</div>
                    </StyledTh>
                    <StyledTh colSpan={2}>
                        <div>1 Año</div>
                    </StyledTh>
                    <StyledTh colSpan={2}>
                        <div>2 Años</div>
                    </StyledTh>
                    <StyledTh colSpan={2}>
                        <div>3 Años</div>
                    </StyledTh>
                    <StyledTh colSpan={2}>
                        <div>4 Años</div>
                    </StyledTh>
                    <StyledTh colSpan={2}>
                        <div>5 Años</div>
                    </StyledTh>
                </tr>
                <tr>
                    <th style={{textAlign: "center"}}>
                        <div>Categoría</div>
                    </th>
                    <th onClick={() => requestSort("ratio1m")}>
                        <div>
                            Ratio{" "}
                            {sortConfig.key === "ratio1m" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("bench1m")}>
                        <div>
                            Índice{" "}
                            {sortConfig.key === "bench1m" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("ratio3m")}>
                        <div>
                            Ratio{" "}
                            {sortConfig.key === "ratio3m" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("bench3m")}>
                        <div>
                            Índice{" "}
                            {sortConfig.key === "bench3m" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("ratio6m")}>
                        <div>
                            Ratio{" "}
                            {sortConfig.key === "ratio6m" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("bench6m")}>
                        <div>
                            Índice{" "}
                            {sortConfig.key === "bench6m" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("ratio1y")}>
                        <div>
                            Ratio{" "}
                            {sortConfig.key === "ratio1y" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("bench1y")}>
                        <div>
                            Índice{" "}
                            {sortConfig.key === "bench1y" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("ratio2y")}>
                        <div>
                            Ratio{" "}
                            {sortConfig.key === "ratio2y" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("bench2y")}>
                        <div>
                            Índice{" "}
                            {sortConfig.key === "bench2y" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("ratio3y")}>
                        <div>
                            Ratio{" "}
                            {sortConfig.key === "ratio3y" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("bench3y")}>
                        <div>
                            Índice{" "}
                            {sortConfig.key === "bench3y" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("ratio4y")}>
                        <div>
                            Ratio{" "}
                            {sortConfig.key === "ratio4y" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("bench4y")}>
                        <div>
                            Índice{" "}
                            {sortConfig.key === "bench4y" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("ratio5y")}>
                        <div>
                            Ratio{" "}
                            {sortConfig.key === "ratio5y" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                    <th onClick={() => requestSort("bench5y")}>
                        <div>
                            Índice{" "}
                            {sortConfig.key === "bench5y" ?
                            (
                                (sortConfig.direction === "ascending" && <StockUpIcon color="black" />) || <StockDownIcon />
                            ) :
                            (
                                <></>
                            )}
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody style={{flexWrap: "nowrap", whiteSpace: "nowrap"}}>
                {sortedCategories && sortedCategories.length > 0 && sortedCategories.map((row, index) =>
                (
                    <React.Fragment key={index}>
                        <TableBody category={row} subCategories={sortedSubCategories.filter(x => x.category === row.category)}
                            index={index} selectedRatioKey={selectedRatioKey} sortConfig={sortConfig}
                        />
                    </React.Fragment>
                ))}
            </tbody>
        </TableStyled>
    );
};

export default TableCategory;

export const StyledTd = styled.td`
	text-align: center;
`;
export const TdValueNegative = styled.td`
	text-align: center;
	color: rgb(204, 33, 79);
`;
export const StyledTdPositive = styled.td`
	text-align: center;
	color: rgb(27, 179, 173);
`;
const StyledTh = styled.th`
	text-align: center !important;
	padding: 10px !important;
`;
const TableStyled = styled.table`
	width: 100%;
	overflow-x: auto;
	border-spacing: 0;
	overflow: hidden;
	border-radius: 16px;
	thead
    {
		background: ${props => props.theme.palette.tertiary};
		text-align: left;
		th
        {
			border-spacing: 0;
			padding: 10px;
			text-align: center;
		}
	}
	tbody
    {
		button.expanded
        {
			border: none;
			cursor: pointer;
			transition: 150ms;
			border-radius: 0;
			&:hover
            {
				transition: 150ms;
			}
		}
		strong
        {
			color: ${props => props?.theme?.palette?.black};
		}
		tr
        {
			td
            {
				padding: 5px 10px 5px 10px;
				p
                {
					color: ${props => props?.theme?.palette?.black};
				}
			}
			svg
            {
				margin-right: 3px;
			}
			.max-content
            {
				min-width: max-content;
			}
		}
	}
`;
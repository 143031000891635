import styled from "styled-components";

type BoxProps = {
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  m?: number;
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
  p?: number;
  align?: "start" | "center" | "end";
};

export const Box = styled.div<BoxProps>`
  margin: ${(props) => (props.m ? `${props.m}rem` : 0)};
  margin-top: ${(props) => (props.mt ? `${props.mt}rem` : 0)};
  margin-right: ${(props) => (props.mr ? `${props.mr}rem` : 0)};
  margin-bottom: ${(props) => (props.mb ? `${props.mb}rem` : 0)};
  margin-left: ${(props) => (props.ml ? `${props.ml}rem` : 0)};
  padding: ${(props) => (props.p ? `${props.p}rem` : 0)};
  padding-top: ${(props) => (props.pt ? `${props.pt}rem` : 0)};
  padding-right: ${(props) => (props.pr ? `${props.pr}rem` : 0)};
  padding-bottom: ${(props) => (props.pb ? `${props.pb}rem` : 0)};
  padding-left: ${(props) => (props.pl ? `${props.pl}rem` : 0)};
  ${(props) => props.align && `text-align: ${props.align}`}
`;

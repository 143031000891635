import React, { useContext, useState, useEffect, useRef } from 'react';
import { ThemeContext } from '@fluentui/react-theme-provider';
import { MessageBar, MessageBarType, Spinner, SpinnerSize } from "@fluentui/react";
import * as L from './layout.styles';
import { CartTable, GetCartInfo } from './components/CartTable';
import service, { ISignature } from "../../services/SignaturesService";
import OrderService, { OrderType } from '../../services/OrderService';
import PaymentService, { PaymentType } from '../../services/PaymentService';
import InespayService from '../../services/InespayService';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import { useBoolean } from "@fluentui/react-hooks";
import { IDataCart, SignatureComponent } from '../../components/Signature/SignatureComponent';
import ProcessingModalComponent from "../../components/Modals/ProcessingModalComponent";
import BackButton from '../../components/buttons/BackButton';
import Auth from "../../Auth/Auth";
import moment from 'moment';
import funds from '../../services/FundService';
import DocumentTitle from 'react-document-title';
import UserIdentitiesService from '../../services/UserIdentitiesService';
import FnmContractComplexProductService, { FnmContractComplexProductUserIdentityAgreementType, FnmContractComplexProductUserIdentityType } from '../../services/FnmContractComplexProductService';
import { IExtraData, ISuitabilityTest } from '../../services/SuitabilityTestsService';

interface SignatureProps extends RouteComponentProps{}

export const CartSignatureScreen = (props: SignatureProps) =>
{
    const theme = useContext(ThemeContext);
    const history = useHistory();
    const { state } = useLocation<any>();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userConfirmation, setUserConfirmation] = useState({ user: false, disclaimer: false });
    const [currentInstrumentIds, setCurrentInstrumentsIds] = useState<string[]>();
    const [isCurrentInstrumentIdsLoaded, setIsCurrentInstrumentIdsLoaded] = useState<boolean>(false);
    const [error, setError] = useState({message: false});
    const { items, total } = GetCartInfo(state.cartLines, currentInstrumentIds);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [showAlert, { toggle: toggleShowAlert }] = useBoolean(false);
    const processHours: any = Auth.getProcessHours();
    const [signature, setSignature] = useState<ISignature>();
    const [reference, setReference] = useState<string>("");
    const [dataFunds, setDataFunds] = useState<IDataCart[]>();
    const firstTime = useRef<boolean>(true);
    const [subscriptions, setSubscriptions] = useState<OrderType[]>(state?.subscriptions);
    const defaultSignature: any = state?.signature;
    const [complexProducts, setComplexProducts] = useState<IDataCart[]>([]);
    const [complexProductAgreements, setComplexProductAgreements] = useState<FnmContractComplexProductUserIdentityAgreementType[]>([]);
    const [complexProductsLoaded, setComplexProductsLoaded] = useState<boolean>(false);
    const user = Auth.getUserProfile();

    if(!state?.signature)
    {
        history.push("/");
    }

    const processExpirationDate = (signature: ISignature) =>
    {
        if(signature && typeof signature.expiresAt === "string")
        {
            signature.expiresAt = moment(signature.expiresAt).toDate();
        }
    };

    if(processHours === undefined)
    {
        service.getProcessHours().then((response: any) =>
        {
            if(response.status === 200 && response.data.length > 0)
            {
                Auth.setProcessHours(response.data);
            }
        });
    }

    useEffect(() =>
    {
        if(!isCurrentInstrumentIdsLoaded)
        {
            UserIdentitiesService.getInstrumentsIds(user.mainUserIdentity.id).then((currentInstrumentIds: string[]) =>
            {
                setCurrentInstrumentsIds(currentInstrumentIds);
                setIsCurrentInstrumentIdsLoaded(true);
            }).catch((error: any) =>
            {
                setError(error);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(defaultSignature !== undefined)
        {
            setSignature(defaultSignature);
            processExpirationDate(defaultSignature);
        }
    }, [defaultSignature]);

    useEffect(() =>
    {
        if(state.signature !== undefined)
        {
            var reference = `${user.id}-O${state.signature.id}`;
            setReference(reference);

            OrderService.getOrders({signatureId: state.signature.id}).then((subscriptions: any[]) =>
            {
                setSubscriptions(subscriptions);
            },
            (error: any) => {});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(items !== undefined && !complexProductsLoaded)
        {
            var complexProducts = items.filter((item) => item.complexity);
            setComplexProducts(complexProducts);
            setComplexProductsLoaded(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(() =>
    {
        if(complexProducts.length > 0)
        {
            setComplexProductAgreements([]);

            var lastTest = user.mainUserIdentity.suitabilityTests.findLast((sT: ISuitabilityTest) => sT.status === "SIGNED");

            if(lastTest && lastTest.results.results.extraResults.find((eR: IExtraData) => eR.dataType === "investorProfile").result > 23)
            {
                complexProducts.forEach(cP =>
                {
                    setComplexProductAgreements((prevState: FnmContractComplexProductUserIdentityAgreementType[]) =>
                    [
                        ...prevState,
                        {
                            isin: cP.isin,
                            name: cP.name,
                            profile: lastTest
                                .results
                                .results
                                .extraResults
                                .find((eR: IExtraData) => eR.dataType === "investorProfile")
                                .name,
                            value: false
                        }
                    ]);
                });
            }
            else
            {
                FnmContractComplexProductService.getFnmContractComplexProductsUserIdentitiesByUserIdentityIdAndIsins(user.mainUserIdentity.id, complexProducts.map(cP => cP.isin))
                .then((fnmContractComplexProductsUserIdentity: FnmContractComplexProductUserIdentityType[]) =>
                {
                    complexProducts.forEach(cP =>
                    {
                        if(fnmContractComplexProductsUserIdentity !== undefined)
                        {
                            complexProducts.forEach(cP =>
                            {
                                var fnmContractComplexProductUserIdentity = fnmContractComplexProductsUserIdentity.find((fCCPUI: FnmContractComplexProductUserIdentityType) => fCCPUI.fnmContractComplexProduct?.isin === cP.isin);
                                if(fnmContractComplexProductUserIdentity !== undefined)
                                {
                                    var phone = fnmContractComplexProductUserIdentity.signature?.phone || '';
                                    setComplexProductAgreements((prevState: FnmContractComplexProductUserIdentityAgreementType[]) =>
                                    [
                                        ...prevState,
                                        {
                                            isin: cP.isin,
                                            name: cP.name,
                                            phone: "X".repeat(phone.length - 3) + phone.slice(phone.length - 3, phone.length),
                                            // @ts-ignore
                                            date: moment(fnmContractComplexProductUserIdentity.signature?.signAt).format('DD/MM/YYYY hh:mm'),
                                            value: false
                                        }
                                    ]);
                                }
                            });
                        }
                    });
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [complexProducts]);

    useEffect(() =>
    {
        var operationPhone = user?.mainUserIdentity?.operationPhone;
        if(operationPhone)
        {
            setPhoneNumber("X".repeat(operationPhone.length-3)+operationPhone.slice(operationPhone.length-3, operationPhone.length));
        }
    }, [subscriptions, user]);

    useEffect(() =>
    {
        if(isModalOpen && subscriptions !== undefined)
        {
            paymentOrders();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptions]);

    const paymentOrders = () =>
    {
        var total = 0.0;
        var usersSubscriptionItem;
        var savingsPlanItem;
        if(subscriptions !== undefined && subscriptions.length > 0)
        {
            subscriptions.forEach((subscription: OrderType) => total += (subscription?.values?.amount || 0));
            usersSubscriptionItem = items.find((item: any) => item.userSuscriptionId !== null);
            savingsPlanItem = items.find((item: any) => item.savingsPlanId !== null);
        }

        let subject = `Orden IronIA - ${subscriptions.length > 0 ? subscriptions[0].id : usersSubscriptionItem?.userSuscriptionId}`;

        PaymentService.createPayment(
        {
            reference: reference,
            subject: subject,
            amount: total,
            status: 1,
            platform: "INESPAY",
            orders: subscriptions,
            usersSubscriptionId: usersSubscriptionItem?.userSuscriptionId,
            savingsPlanId: savingsPlanItem?.savingsPlanId
        }).then((data: Partial<PaymentType>) =>
        {
            InespayService.postInespay(
            {
                reference: reference,
                subject: subject,
                amount: ((total) * 100).toString(),
                subscription: false
            }).then((dataInespay: any) =>
            {
                const inespay_result = JSON.parse(dataInespay.Result);
                if(inespay_result.status === '200')
                {
                    window.location.href = inespay_result.url;
                }
                else
                {
                    if(inespay_result.status === "E301")
                    {
                        console.log("El IBAN introducido no es válido.");
                    }
                    else
                    {
                        console.log(inespay_result.description);
                    }
                }
            },
            (error: any) =>{});
        },
        (error: any) =>{});
    };

    const createIsins = () =>
    {
        let result = [];

        if(items !== undefined && items.length > 0)
        {
            for(let i=0; i<items.length;i++)
            {
                result.push(items[i].isin);
            }
        }

        return result;
    }

    useEffect(() =>
    {
        let arrayResult: IDataCart[] = [];

        if(items !== undefined && items.length > 0 && firstTime.current === true)
        {
            firstTime.current = false;

            for(let i=0; i <items.length; i++)
            {
                funds.getFundEstimationDate(items[i].isin).then((dataResponse: any) =>
                {
                    arrayResult.push(
                    {
                        isin: items[i].isin,
                        name: items[i].name,
                        complexity: items[i].complexity,
                        date: dataResponse !== undefined && dataResponse !== null ? dataResponse : new Date().toLocaleDateString()
                    });
                });
            }
        }
        setDataFunds(arrayResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = (response: any) =>
    {
        showModal();
        var data = subscriptions.map(subscription =>
        {
            return{id: subscription.id, signatureId: state.signature.id, hasDisclaimerTime: response.hasDisclaimerTime}
        });
        OrderService.patchSubscriptionOrders(data).then((response: OrderType[]) =>
        {
            if(response.length > 0 && response[0].signatureId)
            {
                setSubscriptions(response);
            }
        })
        .catch((error) =>
        {
            hideModal();
            toggleShowAlert();
        });
    };

    return(
        <DocumentTitle title={'IronIA - Firma Carrito de Compra'}>
            <React.Fragment>
                <L.Main theme={theme}>
                    <BackButton />
                    <L.TitleContainer>
                        <h2>Comprueba tu cartera y firma tus órdenes</h2>
                    </L.TitleContainer>
                    <L.SeparatorTop />
                    {!isCurrentInstrumentIdsLoaded && <Spinner size={SpinnerSize.large} className="button-spinner" />}
                    {isCurrentInstrumentIdsLoaded &&
                    (
                        <CartTable items={items}
                            total={total}
                            readOnly={true}
                            currentInstrumentIds={currentInstrumentIds}
                        />
                    )}
                    {showAlert &&
                    (
                        <MessageBar messageBarType={MessageBarType.error}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                        >
                            Se ha producido un error al procesar la operación.
                        </MessageBar>
                    )}
                    {(signature === undefined || subscriptions === undefined || subscriptions[0].userIdentity === undefined) &&
                    (
                        <Spinner size={SpinnerSize.large} className="button-spinner" />
                    )}
                    {signature !== undefined && subscriptions !== undefined && subscriptions[0].userIdentity !== undefined &&
                    (
                        <SignatureComponent userIdentities={[subscriptions[0].userIdentity]}
                            signatures={[signature]}
                            alreadySignedText="¡Tu orden de compra está en proceso!"
                            title=""
                            submitButtonText="Firmar"
                            cancelButtonText="Cancelar"
                            headerTextArea={`Confirma la orden con la clave enviada a tu teléfono ${phoneNumber}.`}
                            backButtonText="Volver"
                            onBackButtonClicked={() => history.push("/cart")}
                            onCancelButtonClicked={() => history.push("/")}
                            requestNewCodeButtonText="Recibir nuevo código"
                            confirmElement={
                            {
                                text: "Confirmo haber recibido, leído y comprendido la documentación obligatoria de IIC's y realizar esta operación por iniciativa propia.",
                                value: userConfirmation.user,
                                setValue: (value: boolean) => setUserConfirmation({user: !!value, disclaimer: userConfirmation.disclaimer})
                            }}
                            disclaimerEnabled={
                            {
                                text: "He sido informado de que alguna de las operaciones realizadas en este momento pueden no tomar el valor liquidativo del día en curso.",
                                processHours: processHours,
                                isins: createIsins(),
                                value: userConfirmation.disclaimer,
                                isTransfer: false,
                                isMultiIsin: false,
                                setValue: (value: boolean) => setUserConfirmation({user: userConfirmation.user, disclaimer: !!value})
                            }}
                            dataCart={dataFunds}
                            complexProducts={complexProducts}
                            complexProductAgreements={complexProductAgreements}
                            setComplexProductAgreements={setComplexProductAgreements}
                            onNewCodeSended={(signatures: any) =>
                            {
                                signatures.forEach((signature: any) =>
                                {
                                    processExpirationDate(signature);
                                })
                                setSignature(signatures[0]);
                            }}
                            onSignatureChanged={(signature: any) =>
                            {
                                processExpirationDate(signature);
                                setSignature(signature);
                            }}
                            onSigned={(signature: any) =>
                            {
                                processExpirationDate(signature);
                                setSignature(signature);
                                localStorage.removeItem("cart");
                                submit(signature);
                            }}
                        />
                    )}
                    <ProcessingModalComponent isModalOpen={isModalOpen} hideModal={hideModal} />
                    <div className="section-row">
                        {items.length !== items.filter(item => item.complexity).length &&
                        (
                            <L.WarningContainer theme={theme}>
                                <div>
                                    <img src='/icons/information.svg' alt="Información" />
                                </div>
                                {items.some(item => item.complexity) &&
                                (
                                    <React.Fragment>
                                        Respecto a la suscripción de los fondos: {items.filter(item => !item.complexity).map(item => item.name + ' ' + item.isin).join(", ")}
                                    </React.Fragment>
                                )}
                                <h3>Operación a iniciativa del cliente que no precisa evaluación por la Entidad</h3>
                                <p>
                                    La entidad no tiene obligación de recabar información sobre los conocimientos
                                    y experiencia del cliente para un análisis de la conveniencia de la presente
                                    operación, dado que el instrumento sobre el que va operar tiene la categoría
                                    normativa de no complejo y que la operación se realiza por iniciativa suya y
                                    no de la entidad.  Al no realizar dicha valuación la entidad no puede formarse
                                    una opinión respecto a si esta operación es o no conveniente para usted y por
                                    tanto, en caso de que, la operación no resultase conveniente para usted, no
                                    podría advertírselo.
                                </p>
                            </L.WarningContainer>
                        )}
                        <br/>
                        <L.WarningContainer theme={theme}>
                            <div>
                                <img src='/icons/information.svg' alt="Información" />
                            </div>
                            <h3>Entrega documentación obligatoria IIC’s</h3>
                            <p>
                                De conformidad con lo establecido en la normativa vigente aplicable a Instituciones de Inversión Colectiva
                                y en las Circulares 4/2008 y 2/2011 de la Comisión Nacional del Mercado de Valores, Diaphanum Valores, S.V., S.A.U. 
                                entrega la documentación legal requerida al partícipe que se identifica con anterioridad a la suscripción de 
                                participaciones/acciones del fondo de inversión. El firmante declara haber recibido, leído, comprendido y
                                tener a su disposición la documentación indicada relativa al/los fondo/s que desea suscribir y acusa recibo de la documentación entregada.
                            </p>
                        </L.WarningContainer>
                    </div>
                </L.Main>
            </React.Fragment>
        </DocumentTitle>
    );
};
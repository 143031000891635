import { DatePicker } from "@fluentui/react";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

export const Cont = styled.div`
    grid-area: main;
    background-color: ${(props) => props.theme.palette.white};
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
`;
export const Steps = styled.div`
    h3
    {
        font-size: 22px;
        color: #cc214f;
    }
    h3 span
    {
        color: #beced5;
        margin-left: 2px;
    }
`;
export const MainCont = styled.div`
    padding: 40px 55px;
    hr
    {
        margin: 40px 0;
        border: solid 1px #eff4f6;
    }

    .section-wrapper
    {
        margin-top: 30px;
    }

    .section-wrapper .ms-TextField
    {
        margin-top: 10px;
    }

    @media screen and (max-width: 599px)
    {
        padding: 40px 25px;
    }
`;
export const ModalMainCont = styled.div`
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 430px;
    border-radius: 30px;
    background-color: #ffffff;
    z-index: 10;
    padding: 40px;
    hr
    {
        margin: 15px 0 30px 0;
        border: solid 1px #e9eff2;
    }
`;
export const Title = styled.div`
    margin-top: 30px;
    h1
    {
        font-size: 30px;
        color: #2a4143;
        font-weight: 800;
    }
    h2
    {
        font-size: 24px;
        font-weight: 400;
        color: #5f7172;
    }
`;
export const BarCont = styled.div`
    position: relative;
    width: 100%;
    height: 20px;
`;
export const BgBar = styled.hr`
    position: absolute;
    width: 100%;
    border: solid 6px #fae9ed;
    border-radius: 10px;
    top: 50%;
    transform: translate(0%, -50%);
`;
export const ProsBar = styled.hr`
    position: absolute;
    width: calc(100% - 8.33%);
    border: solid 4px #cc214f;
    border-radius: 10px;
    top: 50%;
    transform: translate(0%, -50%);
`;
export const AddDelCont = styled.div`
    display: flex;
    margin-top: 2em;
`;
export const TabletCont = styled.div`
    background: ${(props) => props.theme.palette.tertiary};
    width: 100%;
    padding: 35px 25px 30px 25px;
    border-radius: 20px;
    margin-top: 60px;
    position: relative;
    hr
    {
        margin: 40px 0;
        border: solid 1px #e9eff2;
    }

    input,
    .react-dropdown-select {
        background: ${(props) => props.theme.palette.white} !important;
    }
    .actions{
        display: flex;
        justify-content: space-around;
        & > button{
            min-width: 64px;
        }
    }
    .react-dropdown-select .react-dropdown-select-input {
        color: ${(props) => props.theme.palette.black} !important;
        opacity: 0 !important;
    }

    > span {
        top: 40%;
        left: 20px;
    }
`;
export const ScrollableTabletCont = styled.div`
    background-color: #f3f7f9;
    width: 100%;
    padding: 35px 25px 30px 25px;
    border-radius: 20px;
    margin-top: 60px;
    max-height: 400px;
    overflow: auto;
    hr
    {
        margin: 40px 0;
        border: solid 1px #e9eff2;
    }
`;
export const TabletTitle = styled.h3`
    color: #2a4143;
    font-size: 20px;
    font-weight: 800;
`;
export const SubTitle = styled.h4`
    color: #2a4143;
    font-size: 18px;
    font-weight: 500;
    span
    {
        color: #4284e6;
    }
`;
export const Phrase = styled.p`
    color: #95a0a1;
    font-size: 14px;
    font-weight: 400;
`;
export const FielSet = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    @media screen and (max-width: 1199px)
    {
        flex-flow: row wrap;
    }
`;
export const FieldRSCont = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 363px;
    margin: 0 12px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button
    {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number]
    {
        -moz-appearance: textfield;
    }
    &.full-width
    {
        > div
        {
            width: 100%;
            margin-top: 7.5px;
        }
    }
`;
export const FieldRBCont = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 490px;
    .validating
    {
        position: absolute;
        right: 15px;
        top: 45px;
    }
    .hidden
    {
        display: none;
    }
}
`;
export const FieldFileCont = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 449px;
    padding-top: 8px;
    label
    {
        margin: 5px 0;
    }
    input
    {
        position: relative;
        max-height: 38.8px;
        &::file-selector-button
        {
            right: 84px;
        }
    }
    span.error
    {
        top: 100%;
    }
`;

export const ExtendedFieldFileIsMinorCont = styled(FieldFileCont)`
    margin: 16px 0; 
`;
export const FileInput = styled.input`
    font-family: inherit;
    width: 100%;
    height: 100%;
    padding: 10px 5px;
    border: 1px solid #95a0a1;
    border-radius: 5px;
    &::file-selector-button
    {
        -webkit-appearance: none;
        position: absolute;
        float: right;
        height: 90%;
        width: 133px;
        top: 50%;
        right: 20%;
        transform: translate(65%, -50%);
        border: none;
        background-color: #cc214f;
        border-radius: 5px;
        color: #ffffff;
    },
    &::disabled {
        border: 1px solid #cccccc;
        &::file-selector-button {
            background-color: #cccccc;
            color: #ffffff;
        },
        &::hover {
            cursor: not-allowed;
        }
    }`;

export const Label = styled.label`
    margin-top: 5px;
    display: block;
    font-weight: 500;
    color: #556769;
    font-size: 14px;
`;
export const BtnCont = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 80px;
    @media screen and (max-width: 599px)
    {
        justify-content: center;
        flex-flow: row wrap;
        margin-top: 40px;
        a
        {
            margin: 0 10px;
        }
        button
        {
            margin-bottom: 20px;
        }
    }
`;
export const BtnRCont = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 80px;
    @media screen and (max-width: 599px)
    {
        justify-content: center;
        flex-flow: row wrap;
        margin-top: 40px;
        a
        {
            margin: 0 10px;
        }
        button
        {
            margin-bottom: 20px;
        }
    }
`;
export const RadioInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
`;
export const RadioCheck = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #c3d2d9;
    &:after
    {
        content: '';
        position: absolute;
        display: none;
    }
`;
export const RadiosCont = styled.form`
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
    padding: 0 20px;
    @media screen and (max-width: 599px)
    {
        flex-flow: row wrap;
        justify-content: center;
    }
`;
export const RadioContM = styled.div`
    width: 100%;
    max-width: 700px;
    margin-right: 65px;
    @media screen and (max-width: 599px)
    {
        margin-right: 0;
    }
`;
export const RadioCont = styled.div`
    position: relative;
    width: 100%;
    max-width: 700px;
    margin-top: 40px;
`;
export const Question = styled.p`
    font-weight: 500;
    color: #556769;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
`;
export const RadioSelect = styled.div`
    display: inline-block;
    margin-bottom: 40px;
    label:first-of-type
    {
        margin-right: 40px;
    }
`;
export const RadioLabel = styled.label`
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 38px;
    cursor: pointer;
    font-weight: 500;
    color: #2a4143;
    font-size: 17px;
    user-select: none;
    height: 20px;
    margin: 10px 0;
    width: fit-content;
    &:hover input ~ span
    {
        background-color: #ccc;
    }
    input:checked ~ span
    {
        background-color: #ffffff;
    }
    input:checked ~ span:after
    {
        display: block;
    }
    span:after
    {
        top: 2px;
        left: 2px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #cc214f;
    }
`;
export const Radio = styled.input`
    display: inline-block;
    margin-right: 20px;
    width: 20px;
    height: 20px;
    &:checked:after
    {
        width: 16px;
        height: 16px;
        border-radius: 15px;
        top: 2px;
        left: 2px;
        position: relative;
        background-color: #cc214f;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid white;
    }
`;
export const Error = styled.span`
    position: absolute;
    display: block;
    width: fit-content;
    top: 83%;
    left: 0;
    margin: 0;
    color: hsl(0, 100%, 63%);
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
`;
export const InlineError = styled.span`
    display: block;
    width: fit-content;
    top: 83%;
    left: 0;
    margin: 0;
    color: hsl(0, 100%, 63%);
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
`;
export const HelperStyle = createGlobalStyle`
*,
::after,
::before
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} 
ul
{
    list-style-type: none;
}
a
{
    text-decoration: none;
} 
`;
export const EmptyHalf = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 750px;
    margin: 0 4px;
`;
export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    table-layout: fixed;
    .actions{},
    tr:first-of-type
    {
        border-bottom: 2px solid #e9eff2;
    }
    th
    {
        padding: 20px 10px 10px 10px;
        font-size: 14px;
        color: #2a4143;
        border-left: 2px solid #e9eff2;
        border-right: 2px solid #e9eff2;
        &:first-of-type
        {
            border-left: none;
        }
        &:last-of-type
        {
            border-left: none;
        }
    }
    td
    {
        padding: 10px 15px 20px 15px;
        word-break: break-all;
        color: #95a0a1;
        border-left: 2px solid #e9eff2;
        border-right: 2px solid #e9eff2;
        &:first-of-type
        {
            border-left: none;
        }
        &:last-of-type
        {
            border-left: none;
        }
    }
`;
export const FieldSet = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1199px)
    {
        flex-flow: row wrap;
    }
`;
export const FieldViaCont = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 200px;
    margin: 0 4px;
    label
    {
        margin-top: 13px;
        margin-bottom: 5px;
    }
    div
    {
        width: 100%;
    }
`;
export const FieldCont = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 370px;
    margin: 0 4px;
`;

export const FieldDatePickerCont = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 350px;
    margin: 0 4px;
`;
export const FieldAddressTypeCont = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 370px;
    margin: 0 4px;
    margin-top: 4px;
    & > label{
        padding: 5px 0px;
    },
    & > div,
    & > label
    {
        width: 100%;
        font-size: 14px !important;
    },
`;
export const FieldDateCont = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 210px;
    margin: 0 4px;
`;
export const DateInput = styled(DatePicker)`
    .ms-TextField-fieldGroup input
    {
        border: 1px solid #95a0a1;
    }
`;
export const FieldAccountTypeCont = styled.div`
    display: inline-block;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 343px;
    margin: 0 4px;
    padding-top: 8px;
    label
    {
        margin: 5px 0;
    }
`;
export const FieldEmailCont = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 370px;
    margin: 0 4px;
    input[disabled]
    {
        opacity: 0.3;
    }
`;

export const CustomFieldEmailCont = styled(FieldEmailCont)`
    max-width: 350px;  
     input[disabled]
    {
        opacity: 1 !important;
    },
`;

export const FieldPrefixCont = styled.div`
    padding-top: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 250px;
    margin: 0 4px;
    label
    {
        margin-bottom: 5px;
    }
    div
    {
        width: 100%;
    }
`;
export const CustomFieldPrefixCont = styled(FieldPrefixCont)`
    max-width: 360px;  
`;
export const FieldPhoneCont = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 350px;
    margin: 0 4px;
`;
export const AproveCont = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    p
    {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: #2a4143;
    }
    @media screen and (max-width: 599px)
    {
        align-items: flex-start;
        p
        {
            position: relative;
            top: -5px;
        }
        input
        {
            margin-left: 15px;
        }
    }
`;
export const CheckLabel = styled.label`
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 38px;
    cursor: pointer;
    font-weight: 500;
    color: #2a4143;
    font-size: 17px;
    user-select: none;
    height: 25px;
    margin: 15px 0;
    width: fit-content;
    &:hover input ~ span
    {
        background-color: #ccc;
    }
    input:checked ~ span
    {
        background-color: #cc214f;
        border: 1px solid #b21540;
    }
    input:checked ~ span:after
    {
        display: block;
    }
    span:after
    {
        left: 7px;
        top: 4px;
        width: 7px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
`;
export const CheckInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;
export const CheckMark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #f3f7f9;
    border: 1px solid #c3d2d9;
    border-radius: 5px;
    &:after
    {
        content: '';
        position: absolute;
        display: none;
    }
`;
export const CheckSelect = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #cc214f;
    margin-left: 40px;
`;
export const FieldViaNameCont = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 845px;
    margin: 0 4px;
`;
export const DropDownLabel = styled.label`
    display: block;
    margin-top: 5px;
    color: rgb(85, 103, 105);
`;
export const DropDownRBCont = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 450px;
    margin: 0 4px;
    label
    {
        margin-top: 13px;
        margin-bottom: 5px;
    }
    div
    {
        width: 100%;
    }
`;
export const FieldNumberCont = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 200px;
    margin: 0 4px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button
    {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number]
    {
        -moz-appearance: textfield;
    }
`;
export const FieldRMCont = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 300px;
    smargin: 0 4px;
`;
export const Warning = styled.div`
    width: 100%;
    background-color: #fff1be;
    padding: 16px 82px;
    p
    {
        font-size: 13px;
        font-weight: 500;
        color: #f7a53f;
    }
    @media screen and (max-width: 599px)
    {
        padding: 16px 25px;
    }
`;
export const ToDosCont = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 1199px)
    {
        justify-content: center;
    }
`;
export const ToDo = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 0 20px 16px 20px;
    padding: 20px;
    width: calc(25% - 40px);
    height: 365px;
    border-radius: 30px;
    background-color: #ffffff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    h3
    {
        margin-top: 60px;
        font-size: 16px !important;
        font-weight: 800;
        color: #2a4143;
        letter-spacing: -1px;
    }
    p
    {
        color: #95a0a1;
	    font-size: 15px;
        line-height: 24px;
    }
`;
export const TodoLogoCont = styled.div`
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: solid 1px #eaf0f2;
	margin-bottom: 20px;
`;
export const TodoLogo = styled.img`
	display: block
	height: auto;
	width: 100%;
`;
export const Agrement = styled.p`
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: #93aeb9;
    span
    {
        color: #4d8be7;
    }
`;
export const Focus = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${(props) => (
        // @ts-ignore
        props.height
    )};
    background-color: #818d8f80;
`;
export const ToExitCont = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100;
`;
export const ToExit = styled.div`
    position: relative;
    top: -25px;
    right: -20px;
    i
    {
        &:hover
        {
            cursor: pointer;
        }
    }
`;
export const Continue = styled.h3`
    font-size: 19px;
    font-weight: 700;
    color: #2a4143;
`;
export const NeedsCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 10px 0 10px;
`;
export const NeedCont = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    p
    {
		font-size: 18px;
		font-weight: 500;
        color: #2a4143;
		margin: 0;
    }
`;
export const NeedIcon = styled.img`
    display: block;
	width: 30px;
	height: 30px;
	margin-right: 15px;
`;
export const CompletedIcon = styled.img`
	display: block;
	width: 50px;
	height: 50px;
    margin-bottom: 40px;
    margin-top: -20px;
`;

export const DocumentItemWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 463px;
    .validating
    {
        position: absolute;
        right: 15px;
        top: 45px;
    }
    .hidden
    {
        display: none;
    },
    .advice, .error{
        font-size: 11px;
    },
    .error{
        color: red;
        font-size: 11px;
        font-weight: semi-bold;
    }
}
`;

export const DocumentItemLabel = styled.label`
    margin-top: 5px;
    display: block;
    font-weight: 500;
    color: #556769;
    font-size: 14px;
`;

export const DocumentItemFileCont = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 449px;
    padding-top: 8px;
    label
    {
        margin: 5px 0;
    }
    input
    {
        position: relative;
        max-height: 42px;
        min-height: 42px;
        background-color: #fff;
        &::file-selector-button
        {
            right: 84px;
        }
    }
    span.error
    {
        top: 100%;
    },
    & .activity-dropdown, &.select{
        background-color: #fff;
        border: 1px solid #95a0a1;
        border-radius: 5px;
        max-height: 42px;
        min-height: 42px;
        padding: 10px;
        font-family: inherit;
    },
    & .dropdown-option-container{
        position: relative;
        background-color: #fff !important;
    },
    & .dropdown-option{
        padding: 10px;
        background-color: #fff;
    },
        & .select{
        border-bottom: 1px solid #95a0a1;
        height: 42px;

        & .fui-Select__icon{
            position: relative;
            right: 10px;
        }
        }
    `;
export const DocumentItemFileInput = styled.input`
    font-family: inherit;
    width: 100%;
    height: 100%;
    padding: 10px;
    border: 1px solid #95a0a1;
    border-radius: 5px;
    &::file-selector-button
    {
        -webkit-appearance: none;
        position: absolute;
        font-size: 14px;
        float: right;
        height: 90%;
        width: 132px;
        top: 50%;
        right: 34%;
        transform: translate(65%, -50%);
        border: none;
        background-color: #00a9d0;
        border-radius: 5px;
        color: #ffffff;
    },
    &.disabled{
        background-color: #e8e8e8;
        &:hover{
            cursor: not-allowed;
        }
    `;

export const DocumentIntelligenceWrapper = styled.div`
    display: flex;
    position: relative;
    justify-content: flex.start;
    min-width: 100%;
    min-height: 64px;
    `;

export const DocumentAttachmentWrapper = styled.div`
    background-color: rgb(245, 245, 245);
    min-width: 450px;
    max-width: 450px;
    height: 297px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #c4c4c4;
    margin: 12px 0;
    box-shadow: 0px 2px 64px rgba(8, 84, 117, 0.12), 0px 1px 20px rgba(8, 84, 117, 0.12);
    & a{
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
  `;

import { Spinner, SpinnerSize } from "@fluentui/react";
import * as S from '../../ConvenienceTest/ConvenienceTest.style';

type DNIPlaceholderProps = {
    addSpinner?: boolean;
};

export const DNIPlaceholder = (props: DNIPlaceholderProps) => {
    const iconStyle = {
        width: '64px',
        height: '64px',
        backgroundColor: '#d0d0d0',
        paddinBottom: '16px',
        marginBottom: props.addSpinner ? '24px' : '0px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        transition: 'all 0.2s ease-in-out',
    };

    return (
        <S.DocumentAttachmentWrapper className='document-attachment' >
            <img src="/icons/ic_fluent_camera_off_24_filled.png" alt="CameraOff" style={iconStyle} />
            {props.addSpinner && <Spinner size={SpinnerSize.large} />}
        </S.DocumentAttachmentWrapper>
    );
};
import { useParams } from "react-router";
import SuitabilityTest from "../../components/SuitabilityTests/SuitabilityTest";

const SuitabilityTestScreen = () =>
{
    const { savingsPlanId, userIdentityId } = useParams<any>();

    return (
        <SuitabilityTest
            savingsPlanId={savingsPlanId}
            userIdentityId={userIdentityId}
            confirmUrl={`/suitabilityTest/${savingsPlanId}/confirm/${userIdentityId}`} />
    );
};

export default SuitabilityTestScreen;
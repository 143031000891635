import * as S from '../ConvenienceTest.style';
import { useEffect, useState } from "react";
import { Check } from '../assets/index';
import { Icon } from '@fluentui/react/lib/Icon';
import { useHistory } from "react-router-dom";
import { DefaultButton } from '@fluentui/react';
import { closeBtnStyle } from '../../GlobalStyle';

const ModalComplete = ({ setModalShow, isPromotioned }: { setModalShow: any, isPromotioned?: boolean | undefined }) =>
{
    const history = useHistory();
    const [height, setHeight] = useState('');

    useEffect(() =>
    {
        const toheight = document?.querySelector('#root')?.scrollHeight + 'px';
        setHeight(toheight);
    }, []);

    return (
        <>
            <S.HelperStyle />
            <S.ModalMainCont>
                <S.ToExitCont>
                    <S.ToExit>
                        <Icon iconName='ChromeClose'
                            // @ts-ignore
                            styles={closeBtnStyle}
                            onClick={() => setModalShow(false)}
                        />
                    </S.ToExit>
                </S.ToExitCont>
                <S.CompletedIcon src={Check} />
                <S.Continue>¡Proceso de alta completado!</S.Continue>
                <hr />
                <S.NeedsCont>
                    <p>
                        Enhorabuena, has completado el proceso de verificación correctamente en
                        IronIA.
                    </p>
                    <p>
                        Un miembro del equipo de Diaphanum Valores S.V. revisará la documentación
                        presentada y te habilitará la cuenta lo antes posible.
                    </p>
                    <p>Te mantendremos informado por correo electrónico en todo momento.</p>
                </S.NeedsCont>
                <S.BtnCont>
                    {isPromotioned === true && (
                        <DefaultButton
                            onClick={() => history.push('/promotioned')}
                            className="button tiny-primary-button"
                        >
                            Continuar con el test
                        </DefaultButton>
                    )}
                    {isPromotioned === undefined && (
                        <DefaultButton
                            onClick={() => history.push('/savingsPlans')}
                            className="button tiny-primary-button"
                        >
                            Continuar con el test
                        </DefaultButton>
                    )}
                </S.BtnCont>
            </S.ModalMainCont>
            <S.Focus
                className='modal'
                // @ts-ignore
                height={height} />
        </>
    );
};

export default ModalComplete;
import { DefaultButton, SearchBox, Spinner, SpinnerSize } from '@fluentui/react';
import { useEffect, useState } from 'react';
import * as L from '../../components/Instruments/layout.styles';
import Card from '../../components/News/Card';
import NewsService, { NewsResponse, NewsSourceType, NewsType } from '../../services/NewsService';
import AzureSearchServices, { AzureNewsResponse } from '../../services/AzureSearchServices';
import CustomDopdownCategory from '../../components/dropdown/CustomDopdownCategory';
import { useLocation } from "react-router-dom";
import { translate } from '../../utils/i18n';

interface CustomizedState {
	searchTerm: string
  }

const News = () => {
	const location = useLocation();
	const state = location?.state as CustomizedState;
	const [error, setError] = useState({ message: false });
	const [isLoaded, setIsLoaded] = useState(true);
	const [newsItems, setNewsItems] = useState<NewsType[]>([]);
	const [newsSources, setNewsSources] = useState<any[]>([]);
	const [selectedSources, setSelectedSources] = useState<string[]>([]);
	const [searchText, setSearchText] = useState(state?.searchTerm ?? "");
	const [page, setPage] = useState(0);
	const [recordsTotal, setRecordsTotal] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(12);

	const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			handleSearch();
		}
	};

	useEffect(() => {
		if (isLoaded) {
			setSearchText(state?.searchTerm ?? "");
			handleSearch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state?.searchTerm, page, itemsPerPage]);

	const loadNewsSources = () => {
		NewsService.getNewsSources().then(
			(newsSources: NewsSourceType[]) => {
				var sources = newsSources.map(source => ({
					key: source.name,
					label: source.name,
					value: source.name
				}));
				setNewsSources(sources);
			},
			error => {
				setError(error);
			}
		);
	};

	const onChangeSources = (values: any) => {
		setSelectedSources(values);
	};

	const handleSearch = () => {
		if(selectedSources.length === 0 && searchText && searchText.trim().length > 0){
			handleSearchByTerm();
		}
		else if(selectedSources.length > 0){
			requestNews();
		}
		else{
			requestNews();
		}
	}

	const requestNews = () => {
		setIsLoaded(false);
		NewsService.getNews(page, itemsPerPage, searchText !== undefined ? searchText.trim().toUpperCase() : "", selectedSources.map(s => translate(s))).then(
			(response: NewsResponse) => {
				setNewsItems(response.news);
				setRecordsTotal(response.totalRecords);
				setPage(page);
				setIsLoaded(true);
				if(newsSources.length === 0){
					loadNewsSources();
				}
			},
			error => {
				setIsLoaded(true);
				setError(error);
			}
		);
	};

	const handleSearchByTerm = () => {
		setIsLoaded(false);
		AzureSearchServices.getNewsFromAzureSearchService(page, itemsPerPage, searchText.trim().toUpperCase()).then(
			(response: AzureNewsResponse) => {
				let azureNewsResponse = response.value.filter(x => x.Id !== null && x.Id !== undefined).sort((a, b) => b["@search.score"] - a["@search.score"]);
				let ids = azureNewsResponse.map(function (obj) {
					return obj.Id!;
				});
				setRecordsTotal(response["@odata.count"]);
				if (ids.length === 0) {
					setNewsItems([]);
					setRecordsTotal(0);
					setPage(page);
					setIsLoaded(true);
				} else {
					NewsService.getNewsByIds(0, itemsPerPage, ids).then(
						(response: NewsResponse) => {
							let shortResponseArray = response.news.sort((a, b) => {
								return ids.indexOf(a.id!) - ids.indexOf(b.id!);
							});
							setNewsItems(shortResponseArray);
							setPage(page);
							setIsLoaded(true);
						},
						error => {
							setIsLoaded(true);
							setError(error);
						}
					);
				}
			},
			error => {
				requestNews();
				setError(error);
			}
		);
	};

	return (
		<L.TableWrapper>
			<div className="ms-Grid-row">
				<div className="ms-Grid-col ms-sm12">
					<L.Title>Artículos</L.Title>
				</div>
			</div>
			<L.TableWidthManager>
				<L.TableFilterContainer>
					<L.TRCountContainer>
						<L.TRTitle>
							Resultados de la búsqueda
							<L.TRCount>{`(${recordsTotal})`}</L.TRCount>
						</L.TRTitle>
					</L.TRCountContainer>
					<L.FilterContainer className="short-select">
						<CustomDopdownCategory
							options={newsSources}
							title="Gestora"
							selectedValues={selectedSources}
							onSave={(value: any) => {
								let values = value.map((val: any) => {
									val = val.value;
									return val;
								});
								onChangeSources(values);
							}}
						/>
						{selectedSources &&
							selectedSources.length > 0 &&
							selectedSources.map((filter, filterIndex) => {
								var source = newsSources.find((nS: any) => nS.label === filter);
								return <L.FilterLabel key={"filterIndex" + filterIndex}>{source.label}</L.FilterLabel>;
							})}
					</L.FilterContainer>
					<L.InputSearchContainer className="jcl">
						<SearchBox
							onKeyPress={(e: any) => handleKey(e)}
							placeholder="Buscar por título o resumen..."
							value={searchText}
							onChange={(event: any) => {
								if (event?.target.value) {
									let text = event.target.value;
									setSearchText(text);
								} else {
									setSearchText("");
								}
							}}
						/>
					</L.InputSearchContainer>
					<L.EditColumnContainer style={{ justifyContent: "normal" }}>
						<DefaultButton text="Buscar" onClick={() => page === 0 ? handleSearch() : setPage(0)} />
					</L.EditColumnContainer>
				</L.TableFilterContainer>
				<div className="ms-Grid-row">
					{error.message && <div>Error: {error.message}</div>}
					{!error.message && (
						<>
							{!isLoaded && <Spinner size={SpinnerSize.large} />}
							{isLoaded && (
								<>
									{newsItems.map((newsItem: NewsType, newsItemIndex: number) => (
										<Card
											key={"newsItemIndex" + newsItemIndex}
											title={newsItem.title}
											url={newsItem.url}
											image={newsItem.image}
											category={newsItem.category}
											summary={newsItem.summary}
											publishDate={newsItem.publishDate}
											sourceName={newsItem.newsImport?.newsSource?.name !== undefined ? newsItem.newsImport.newsSource.name : ""}
											sourceImage={newsItem.newsImport?.newsSource?.logo !== undefined ? newsItem.newsImport.newsSource.logo : ""}
										/>
									))}
								</>
							)}
						</>
					)}
				</div>
				<L.PaginationContainer>
					<L.ProductsPerPage>
						<span>Resultados por página</span>
						<select
							onChange={event => {
								setItemsPerPage(Number(event.target.value));
								setPage(0);
							}}
							defaultValue={itemsPerPage}
						>
							<option value={12}>12</option>
							<option value={18}>18</option>
							<option value={24}>24</option>
							<option value={30}>30</option>
						</select>
					</L.ProductsPerPage>
					<L.PageCount>
						<span>{`${page + 1} de ${Math.ceil(recordsTotal / itemsPerPage)}`}</span>
					</L.PageCount>
					<L.PageButtons>
						<button
							onClick={() => {
								if (page > 0) {
									setPage(page - 1);
								}
							}}
							className={page === 0 ? "disabled" : ""}
						>
							<i className={"ironia-icon keyboard-arrow-left"} />
						</button>
						<button
							onClick={() => {
								if (page < Math.ceil(recordsTotal / itemsPerPage!) - 1) {
									setPage(page + 1);
								}
							}}
							className={Math.ceil(recordsTotal / itemsPerPage!) === page + 1 ? "disabled" : ""}
						>
							<i className={"ironia-icon keyboard-arrow-right"} />
						</button>
					</L.PageButtons>
				</L.PaginationContainer>
			</L.TableWidthManager>
		</L.TableWrapper>
	);
};

export default News;
import React from 'react';
import { Wrapper, Button, StateText } from './ThreeWayToggleStyles';
import { ThemeContext } from '@fluentui/react-theme-provider';

interface ThreeWayToggleProps
{
    onChange: (v: any) => any,
    toggleInitialState: number,
    disableMiddlePosition: any,
    nameThreeWay?: string
}

const ThreeWayToggle = (props: ThreeWayToggleProps) =>
{
    let stateValue = -1;
    let disabled = false;

    if(props.toggleInitialState)
    {
        stateValue = props.toggleInitialState;
    }
    if(props.disableMiddlePosition)
    {
        disabled = props.disableMiddlePosition;
    }

    const [toggleState, setToggleState] = React.useState(stateValue);
    const theme = React.useContext(ThemeContext);

    React.useEffect(() =>
    {
        let state = undefined;
        if(toggleState === 0)
        {
            state = false;
        }
        else if(toggleState === 1)
        {
            state = true;
        }
        props.onChange(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleState]);

    return(
        <Wrapper theme={theme} toggleState={toggleState}>
            <StateText theme={theme} toggleState={toggleState}>
                {toggleState === 1 ? 'SÍ' : toggleState === 0 ? 'NO' : ''}
            </StateText>
            <Button toggleState={toggleState} theme={theme} onClick={() => setToggleState(0)} checked={toggleState === 0} />
            <Button toggleState={toggleState} theme={theme} onClick={() => setToggleState(!disabled ? -1 : (toggleState === 1 ? 0 : 1 ))} checked={toggleState === -1} />
            <Button toggleState={toggleState} theme={theme} onClick={() => setToggleState(1)} checked={toggleState === 1} />
        </Wrapper>
    );
};

export default ThreeWayToggle;
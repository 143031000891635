import { useEffect, useState } from 'react';
import * as L from '../layout.styles';
import { RootStateOrAny, connect, useDispatch, useSelector } from "react-redux";
import { clearCart, removeLine, updateLine, updateModelPercentageLine, updateModelPercentageTotal } from '../../../store/cart/actions';
import { CartTable, GetCartInfo } from './CartTable';
import { DefaultButton, MessageBar, MessageBarType, Spinner, SpinnerSize } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import OrderService from '../../../services/OrderService';
import SavingsPlanService from '../../../services/SavingsPlanService';
import { ISignature } from '../../../services/SignaturesService';
import Auth from '../../../Auth/Auth';
import React from 'react';
import NumberFormat from 'react-number-format';
import { CartModes } from '../CartScreen';
import { ai } from '../../../services/TelemetryService';
import { roundToDecimalPlaces } from '../../../utils/numberFormatter';
import Documents from '../../../components/Carts/Documents';
interface CartItem
{
    UserIdentityId: number,
    FnmPortfolioId: number,
    InstrumentId: string,
    OrderType: number,
    Values: CartItemValues,
    Source?: string
}
interface CartItemValues
{
    Type: string,
    AmountType: string,
    Amount: number
}
interface CartTableProps
{
    cartLines: any[],
    cartModeStatus: CartModes,
    savingPlanType?: number,
    currentInstrumentIds: string[],
    onChangeCartModeStatus?: (newStatus: CartModes) => any
}

function mapStateToProps(state: any, props: any)
{
    return {cartLines: state.cart.cartLines};
}

const TmpCart = (props: CartTableProps) =>
{
    const dispatch = useDispatch();
    const history = useHistory();
    const {cartModeStatus, onChangeCartModeStatus} = props;
    const {items, total, modelPercentageTotal, hasEmptyAmounts, hasInvalidAmounts, hasNonAvailableFunds, nonAvailableFunds} = GetCartInfo(props.cartLines, props.currentInstrumentIds);
    const [sending, setSending] = useState(false);
    const [modelWalletContributionAmount, setModelWalletContributionAmount] = useState<number | undefined>();
    let user = Auth.getUserProfile();
    let activePlan = Auth.getActivePlan();
    const appInsights = ai.reactPlugin;
    const activeSavingsPlanHasOutdatedcontract = useSelector((state: RootStateOrAny) => state.auth.outdatedContract);
    const [allDocumentsReaded, setAllDocumentsReaded] = useState<boolean>(false);

    useEffect(() =>
    {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() =>
    {
        dispatch(updateModelPercentageTotal(modelPercentageTotal));
    }, [dispatch, modelPercentageTotal]);

    const removeCartLine = async (fund: any) =>
    {
        dispatch(removeLine(fund));
    };

    const updateCartLine = async (fundItem: any, amount?: string) =>
    {
        let fund = props.cartLines.filter(item => item.fund.isin === fundItem.isin)?.[0]?.fund;
        if(!fund)
        {
            return;
        }

        let n = 0;
        if(amount)
        {
            try
            {
                n = Number.parseFloat(amount);
            }
            catch(e){}
        }
        if(typeof n === "undefined" || isNaN(n))
        {
            n = 0;
        }
        dispatch(updateLine({ fund, amount: n }));
    };

    const updateCartModelPercentageLine = async (fundItem: any, percentage?: string) =>
    {
        let fund = props.cartLines.filter(item => item.fund.isin === fundItem.isin)?.[0]?.fund;
        if(!fund)
        {
            return;
        }
        let n = 0;
        if(percentage)
        {
            try
            {
                n = Number.parseFloat(percentage);
            }
            catch(e){}
        }
        if(typeof n === "undefined" || isNaN(n))
        {
            n = 0;
        }
        dispatch(updateModelPercentageLine({ fund, modelPercentage: n, amount: fund.amout }));
    };

    const removeAllLines = async () =>
    {
        dispatch(clearCart());
    };

    const puntualContributionOnClickHandler = async () =>
    {
        appInsights.trackEvent({name: "Cartera Modelo, Aportación Puntual", properties: {userId: user?.id, contributionAmount: modelWalletContributionAmount}});
        props.cartLines.forEach(cartline =>
        {
            let fund = cartline.fund;
            let amount = Number(((modelWalletContributionAmount ?? 0) * (Number(cartline.modelPercentage ?? 0) / 100)).toFixed(2));
            dispatch(updateLine({fund, amount: amount}));
        });

        if(onChangeCartModeStatus)
        {
            onChangeCartModeStatus(CartModes.DEFAULT);
        }
    };

    const cancelPuntualContributionButton = async () =>
    {
        if(onChangeCartModeStatus)
        {
            onChangeCartModeStatus(CartModes.DEFAULT);
            //No hace falta borrar el carrito, pero si hiciera falta: dispatch(clearCart());
        }
    }

    const onSubmit = async () =>
    {
        var fnmPortfolioId = activePlan?.portfolio?.id;
        if(props.cartLines[0].savingsPlanId !== null && props.cartLines[0].savingsPlanId !== undefined)
        {
            var savingsPlan = await SavingsPlanService.getSavingsPlan(props.cartLines[0].savingsPlanId);
            if(savingsPlan?.fnmContract?.fnmPortfolio !== null && savingsPlan?.fnmContract?.fnmPortfolio !== undefined)
            {
                fnmPortfolioId = savingsPlan.fnmContract.fnmPortfolio.id;
            }
        }
        let toSend: CartItem[] = [];
        props.cartLines.forEach((cartLine: any) =>
        {
            var cartItem: CartItem =
            {
                UserIdentityId: user.mainUserIdentity.id,
                FnmPortfolioId: fnmPortfolioId,
                InstrumentId: cartLine.fund.finametrixId,
                OrderType: 0,
                Values:
                {
                    Type: "amount",
                    AmountType: "amount",
                    Amount: parseFloat(cartLine.amount)
                }
            };
            if(cartLine.source !== undefined)
            {
                cartItem.Source = cartLine.source;
            }

            toSend.push(cartItem);
        });
    
        setSending(true);
        OrderService.createSubscriptionOrders(toSend).then((signature: ISignature) =>
        {
            if(signature !== undefined)
            {
                history.push("/cart/signature",
                {
                    signature: signature,
                    cartLines: props.cartLines
                });
            }
        })
        .catch((error: any) =>
        {
            setSending(false);
        });
    };
   
    return(
		<React.Fragment>
			{hasInvalidAmounts && cartModeStatus === CartModes.DEFAULT &&
            (
				<div className="cart-top-alerts">
					<MessageBar messageBarType={MessageBarType.warning} isMultiline={false} dismissButtonAriaLabel="Close">
						{(hasEmptyAmounts && <>Los importes destinados a cada fondo deben ser superiores a cero.</>) ||
							(hasInvalidAmounts && <>El importe destinado a un fondo no puede ser inferior al mínimo permitido.</>)}
					</MessageBar>
				</div>
			)}
			{hasNonAvailableFunds &&
            (
				<div className="cart-top-alerts">
					<MessageBar messageBarType={MessageBarType.error} isMultiline={true} dismissButtonAriaLabel="Close">
						Los siguientes fondos no están disponibles:
						<p>
							{nonAvailableFunds.map((fundName: string) =>
                            (
								<li>{fundName}</li>
							))}
						</p>
					</MessageBar>
				</div>
			)}
			{(cartModeStatus === CartModes.CREATE_MODEL_PORTFOLIO || cartModeStatus === CartModes.PUNCTUAL_CONTRIBUTION) && roundToDecimalPlaces(modelPercentageTotal, 2) !== 100 &&
            (
				<div className="cart-top-alerts">
					<MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">
						<React.Fragment>
							Total {roundToDecimalPlaces(modelPercentageTotal, 2)}% debe ser 100% para guardar o realizar aportaciones con la cartera modelo 
						</React.Fragment>
					</MessageBar>
				</div>
            )}
			{(items.length > 0 &&
            (
				<React.Fragment>
                    <CartTable items={items} total={total} modelPercentageTotal={modelPercentageTotal}
                        removeCartLine={item => removeCartLine(item)}
                        updateCartLine={(item, newAmount) => updateCartLine(item, newAmount)}
                        updateCartModelPercentageLine={(item, newAmount) => updateCartModelPercentageLine(item, newAmount)}
                        cartModeStatus={cartModeStatus}
                        currentInstrumentIds={props.currentInstrumentIds}
                    />
					{cartModeStatus === CartModes.CREATE_MODEL_PORTFOLIO &&
                    (
						<L.OuterFooterArea>
							<DefaultButton onClick={() => history.push("/search")} className="button tiny-primary-button">
								Añadir más fondos
							</DefaultButton>
						</L.OuterFooterArea>
					)}
                    {cartModeStatus === CartModes.DEFAULT &&
                    (
						<L.OuterFooterArea>
							<DefaultButton onClick={() => removeAllLines()} className="button tiny-secondary-button">
								Vaciar cesta
                            </DefaultButton>
                            {!activeSavingsPlanHasOutdatedcontract &&
                            (
                                <DefaultButton
                                    onClick={() => onSubmit()}
                                    disabled={hasInvalidAmounts || sending || hasNonAvailableFunds || !allDocumentsReaded}
                                    className="button tiny-primary-button"
                                >
                                    {sending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                    Tramitar órdenes
                                </DefaultButton>
                            )}
						</L.OuterFooterArea>
					)}
                    {cartModeStatus === CartModes.PUNCTUAL_CONTRIBUTION &&
                    (
                        <L.PunctualContributionContainer>
                            <L.PunctualContributionLabel>
                                Importe
                            </L.PunctualContributionLabel>
                            <NumberFormat thousandSeparator="." decimalSeparator=","
                                style={{width: "200px"}}
                                value={modelWalletContributionAmount}
                                onValueChange={(values: any) =>
                                {
                                    const {floatValue} = values;
                                    if(typeof floatValue === "number")
                                    {
                                        setModelWalletContributionAmount(floatValue);
                                    }
                                }}
                                suffix=" €"
                                className={(modelWalletContributionAmount ?? 0) <= 0 ? "ms-NumberField field-fund-investment-invalid" : "ms-NumberField"}
                            />
                            <DefaultButton style={{whiteSpace: "nowrap"}} disabled={(modelWalletContributionAmount ?? 0) <= 0 || roundToDecimalPlaces(modelPercentageTotal, 2) !== 100}
                                onClick={() =>
                                {
                                    puntualContributionOnClickHandler();
                                }}
                                className="button mini-primary-button"
                            >
                                Aportación
                            </DefaultButton>
                            <DefaultButton style={{whiteSpace: "nowrap"}} onClick={cancelPuntualContributionButton} className="button mini-primary-button">
                                Cancelar
                            </DefaultButton>
                        </L.PunctualContributionContainer>
                    )}
                    {cartModeStatus === CartModes.DEFAULT && items.length > 0 &&
                    (
                        <Documents cartLines={items} user={user} allDocumentsReaded={allDocumentsReaded} setAllDocumentsReaded={setAllDocumentsReaded} />
                    )}
				</React.Fragment>
			)) ||
            (
				<React.Fragment>
					<h3 className="text-center" style={{cursor: "default"}}>
                        No has incluido nada en tu carrito. ¡Pásate por nuestro buscador de fondos!
                    </h3>
					<L.CenterArea>
						<DefaultButton onClick={() => history.push("/search")} disabled={hasInvalidAmounts} className="button tiny-secondary-button">
							Explorar fondos
						</DefaultButton>
					</L.CenterArea>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export const Cart = connect(mapStateToProps)(TmpCart);
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import OrderService, {
  ExternalSwitchValuesType,
  InternalSwitchValuesType,
  OrderOrderType,
  OrderType,
  OrderValuesType,
} from "../../../../services/OrderService";
import fundService, {
  AvailableFundType,
  FundType,
  SearchFundsMinType,
} from "../../../../services/FundService";
import FinametrixService from "../../../../services/FinametrixService";
import DocumentTitle from "react-document-title";
import { InvestmentFundFinder } from "../../../PurchaseTransferScreen/components/InvestmentFundFinder";
import { useBoolean } from "@fluentui/react-hooks";
import { useForm, Controller } from "react-hook-form";
import {
  DefaultButton,
  Spinner,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import { RadioBtnOffIcon, RadioBtnOnIcon } from "@fluentui/react-icons-mdl2";
import {
  IDataCart,
  SignatureComponent,
} from "../../../../components/Signature/SignatureComponent";
import service, { ISignature } from "../../../../services/SignaturesService";
import Auth from "../../../../Auth/Auth";
import moment from "moment";
import FundService from "../../../../services/FundService";
import FnmContractComplexProductService, {
  FnmContractComplexProductUserIdentityAgreementType,
} from "../../../../services/FnmContractComplexProductService";
import UserIdentitiesService, {
  UserIdentityType,
} from "../../../../services/UserIdentitiesService";
import { ModalRefund } from "../../../Refunds/components/ModalRefund";
import "./OrderCancelManual.sass";
import { currencyFormatter } from "../../../../utils/numberFormatter";
import { parseNumber } from "../../../../utils/toFixedDecimals";
import { GenericMessageModalWithoutButton } from "../../../../components/Modals/GenericModal";

const OrderCancelManual = (props: any) => {
  const { finametrixId } = useParams<any>();
  const history = useHistory();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm();
  const processHours: any = Auth.getProcessHours();
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState<OrderType>();
  const [sourceFundName, setSourceFundName] = useState<string>();
  const [sourceFundIsin, setSourceFundIsin] = useState<string>();
  const [fundSelector, { setTrue: setFundSelector, setFalse: setRefund }] =
    useBoolean(true);
  const [sending, setSending] = useState<boolean>(false);
  const [signature, setSignature] = useState<ISignature>();
  const [newOrder, setNewOrder] = useState<OrderType>();
  const [refundCodeIsDefined, setRefundCodeIsDefined] = useState<boolean>();
  const [dataFund, setDataFund] = useState<IDataCart>();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userConfirmation, setUserConfirmation] = useState({
    user: false,
    disclaimer: false,
  });
  const [refundCode, setRefundCode] = useState<any>("");
  const [complexProductAgreements, setComplexProductAgreements] = useState<
    FnmContractComplexProductUserIdentityAgreementType[]
  >([]);
  const [serviceError, setServiceError] = useState({
    status: false,
    message: "",
  });
  const [isValidatingTargetFund, setIsValidatingTargetFund] = useState(false);
  
  const fundOrigen = watch("sourceFund");
  const distributor = watch("distributor");
  const watchTargetFund = watch("targetFund");

  const [initialTargetFund, setInitialTargetFund] = useState<any>();
  const [userIdentityInstrumentsIds, setUserIdentityInstrumentsIds] = useState<
    string[]
  >([]);
  const [
    isUserIdentityInstrumentIdsLoaded,
    setIsUserIdentityInstrumentIdsLoaded,
  ] = useState(false);
  const {
    amount,
    date,
    isinOrig,
    nameOrig,
    isinDesty,
    nameDesty,
    status,
    statusMessage,
    typeText,
    type,
    iban,
  } = props.location.state;
  let user = Auth.getUserProfile();

  const [showModalFundSelector, setShowModalFundSelector] = useState(false);
  const [isModalFundSelector, setIsModalFundSelector] = useState(false);
  const [showModalMail, setShowModalMail] = useState(false);
  const [isModalMail, setIsModalMail] = useState(false);

  useEffect(() => {
    let initialIsin = "";
    if (typeof window !== "undefined") {
      const query = new URLSearchParams(window.location.search);
      initialIsin = query.get("isin") ?? "";
      if (initialIsin !== "") {
        completeInitialTargetFund(initialIsin);
      }
    }
  }, []);

  const completeInitialTargetFund = useCallback(async (initialIsin) => {
    var defaultTargetFund = await fundService
      .getAvailableFunds(initialIsin)
      .then((data: AvailableFundType[]) => data)
      .catch((err) => []);
    setInitialTargetFund(defaultTargetFund[0]);
    setValue("targetFund", defaultTargetFund[0]);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    OrderService.getOrderByFinametrixId(finametrixId).then(
      (data: OrderType) => {
        setOrder(data);
      },
      (error) => {
        setServiceError(error);
      }
    );
  }, []);

  useEffect(() => {
    if (order !== undefined) {
      switch (order.orderType) {
        case OrderOrderType.Subscription:
          if (order.instrumentId !== undefined) {
            FinametrixService.getInstrument(order.instrumentId)
              .then(
                (data: FundType) => {
                  setSourceFundName(data.name);
                  setSourceFundIsin(data.isin);
                },
                (error) => setServiceError(error)
              )
              .finally(() => setIsLoading(false));
          }
          break;

        case OrderOrderType.InternalSwitch:
          if (order.instrumentId !== undefined) {
            FinametrixService.getInstrument(order.instrumentId)
              .then(
                (data: FundType) => {
                  setSourceFundName(data.name);
                  setSourceFundIsin(data.isin);
                },
                (error) => setServiceError(error)
              )
              .finally(() => setIsLoading(false));
          }
          break;

        case OrderOrderType.ExternalSwitch:
          if (order.values !== undefined) {
            const values: ExternalSwitchValuesType =
              order.values as ExternalSwitchValuesType;
            if (values.inceptionFund.isin !== undefined) {
              FundService.getFundsNameByIsin([values.inceptionFund.isin])
                .then(
                  (data: SearchFundsMinType[]) => {
                    setSourceFundName(values.inceptionFund.name);
                    setSourceFundIsin(values.inceptionFund.isin);
                  },
                  (error) => setServiceError(error)
                )
                .finally(() => setIsLoading(false));
            }
          }
          break;
      }
    }
  }, [order]);


  const onSubmit = async (data: any) => {
    if (order !== undefined) {
      if (fundSelector) {
        if (data.targetFund?.finametrixId === undefined) {
          setError("targetFund", {
            type: "invalidTargetFund",
            message: "El fondo de destino no es válido",
          });
          return false;
        }

        let newOrder: any = {
          userIdentityId: order?.userIdentityId,
          fnmPortfolioId: order?.fnmPortfolioId,
          status: 0,
          orderType: order?.orderType,
          paymentId : order?.paymentId
        };
        
        if(order?.orderType === OrderOrderType.Subscription){
          newOrder.values =  {
            amountType: order?.values?.amountType,
            type: order?.values?.type,
            amount: order?.values?.amount,
            fund:  data.targetFund.finametrixId 
          }
        }

        if(order?.orderType === OrderOrderType.InternalSwitch){
          const internalSwitchValues = order.values as InternalSwitchValuesType;
          newOrder.values =  {
            amountType: internalSwitchValues.amountType,
            type: internalSwitchValues.type,
            amount: internalSwitchValues.amount,
            fund: order.instrumentId, 
            newInstrument: data.targetFund.finametrixId,
            newInstrumentName: data.targetFund.name.trim()
          }
        }

        if(order?.orderType === OrderOrderType.ExternalSwitch){
          const externalSwitchValues = order.values as ExternalSwitchValuesType;

          newOrder.values =  {
            amountType: externalSwitchValues.amountType,
            type: externalSwitchValues.type,
            amount: externalSwitchValues.amount,
            fund: data.targetFund.finametrixId, 
            newInstrument: data.targetFund.finametrixId,
            newInstrumentName: data.targetFund.name,
            inceptionFund: {
              company: externalSwitchValues.inceptionFund.company,
              name: externalSwitchValues.inceptionFund.name,
              isin: externalSwitchValues.inceptionFund.isin,
              account: externalSwitchValues.inceptionFund.account,
              entityNif: externalSwitchValues.inceptionFund.entityNif,
            }
          }
        }

        if (fundSelector) {
          newOrder.instrumentId = data.targetFund.finametrixId;
        }

        setSending(true);

        OrderService.createOrderFromCancel(newOrder, order.id)
          .then((response: OrderType) => {
            if (response.id) {
              setNewOrder(response);
              setSignature(response.signature);
            }
          })
          .catch((error) => setServiceError(error))
          .finally(() => setSending(false));

      } else {
        setSending(true);
         OrderService.patchCancelOrder(order.id)
          .then((data) => {
            setOrder(data);
            setSignature(data.signatureCancel);
          })
          .catch((error) => setServiceError(error))
          .finally(() => setSending(false));
      }
    }
  };

  const processExpirationDate = (signature: ISignature) => {
    if (signature && typeof signature.expiresAt === "string") {
      signature.expiresAt = moment(signature.expiresAt).toDate();
    }
  };

  if (processHours === undefined) {
    service.getProcessHours().then((response: any) => {
      if (response.status === 200 && response.data.length > 0) {
        Auth.setProcessHours(response.data);
      }
    });
  }

  useEffect(() => {
    if (order !== undefined) {
      var operationPhone = order.userIdentity?.operationPhone;
      if (operationPhone !== undefined) {
        setPhoneNumber(
          "X".repeat(operationPhone.length - 3) +
            operationPhone.slice(
              operationPhone.length - 3,
              operationPhone.length
            )
        );
      }
    }
  }, [order]);

  useEffect(() => {
    if (
      order?.userIdentityId !== undefined &&
      fundSelector !== undefined &&
      refundCodeIsDefined === undefined
    ) {
      UserIdentitiesService.isRefundCodeDefined(order.userIdentityId)
        .then((response: boolean) => {
          setRefundCodeIsDefined(response);
        })
        .catch((error) => setServiceError(error));
    }
  }, [fundSelector]);

  useEffect(() => {
    if (refundCode !== undefined && refundCode !== "") {
      setRefundCodeIsDefined(true);
    }
  }, [refundCode]);

  const submitSignature = (response: any) => {
    if (newOrder !== undefined && signature !== undefined) {
      var data = {
        id: newOrder.id,
        signatureId: signature.id,
        hasDisclaimerTime: response.hasDisclaimerTime,
      };

      OrderService.signedCancelOrder(order, 0).then((response: any) => {
        if (response.errorMsg) {
          setServiceError(response.errorMsg);
        } else {
          setIsModalFundSelector(true);
          setShowModalFundSelector(true);
        }
      });
    } else {
      OrderService.signedCancelOrder(order, 1).then((response: any) => {
        if (response.errorMsg) {
          setServiceError(response.errorMsg);
        } else {
          setIsModalMail(true);
          setShowModalMail(true);
        }
      });
    }
  };

  useEffect(() => {
    if (watchTargetFund !== undefined) {
      fundService
        .getFundEstimationDate(watchTargetFund.isin)
        .then((dataResponse: any) => {
          setDataFund({
            isin: watchTargetFund.isin,
            name: watchTargetFund.name,
            complexity: watchTargetFund.complexity,
            date:
              dataResponse !== undefined && dataResponse !== null
                ? dataResponse
                : new Date().toLocaleDateString(),
          });
        });
    }
  }, [watchTargetFund]);

  useEffect(() => {
    if (watchTargetFund !== undefined) {
      validateTarget();
    }
  }, [watchTargetFund]);

  const createIsins = () => {
    if (fundSelector && watchTargetFund?.isin !== undefined) {
      return [watchTargetFund.isin];
    }
    return [];
  };

  const checkMin = useCallback(
    async (targetFund) => {
      if (targetFund.noMin) {
        setIsValidatingTargetFund(true);
        let userIdentityInstrumentsIdsAux: string[] = [];
        if (isUserIdentityInstrumentIdsLoaded) {
          userIdentityInstrumentsIdsAux = userIdentityInstrumentsIds;
        } else {
          userIdentityInstrumentsIdsAux =
            await UserIdentitiesService.getInstrumentsIds(
              user.mainUserIdentity.id
            );
          setUserIdentityInstrumentsIds(userIdentityInstrumentsIdsAux);
          setIsUserIdentityInstrumentIdsLoaded(true);
        }

        const min = userIdentityInstrumentsIdsAux.includes(targetFund.isin)
          ? targetFund.minimumAdditionalInvestment
          : targetFund.minimumInitialInvestment;

        const values = order?.values as OrderValuesType;
        if (values.amountType === "amount" && values.amount < min) {
          setIsValidatingTargetFund(false);
          return { validAmount: false, userIdentityInstrumentsIds, min };
        }
      }

      setIsValidatingTargetFund(false);
      return { validAmount: true, userIdentityInstrumentsIds: [], min: 0 };
    },
    [
      isUserIdentityInstrumentIdsLoaded,
      userIdentityInstrumentsIds,
      user.mainUserIdentity.id,
      setIsUserIdentityInstrumentIdsLoaded,
      order?.values,
    ]
  );

  const validateTarget = useCallback(async () => {
    setIsValidatingTargetFund(true);

    if (
      watchTargetFund?.complexity !== undefined &&
      watchTargetFund.complexity
    ) {
      setIsValidatingTargetFund(true);

      var activePlan = Auth.getActivePlan();

      if (!activePlan.allUserIdentitiesHaveSuitabilityTest) {
        localStorage.setItem(
          "complexProduct",
          JSON.stringify({
            returnPath: history.location.pathname,
          })
        );
        history.push(
          `/savingsPlans/${activePlan.id}/complexProducts/${watchTargetFund.isin}/suitabilityTestList`
        );
        return;
      }

      FnmContractComplexProductService.getUserIdentitiesThatShouldSignComplexProductAgreement(
        activePlan.fnmContractId,
        watchTargetFund.isin
      ).then((userIdentities: UserIdentityType[]) => {
        if (userIdentities.length > 0) {
          localStorage.setItem(
            "complexProduct",
            JSON.stringify({ returnPath: history.location.pathname })
          );
          history.push(
            `/savingsPlans/${activePlan.id}/complexProducts/${watchTargetFund.isin}/agreement`
          );
          return;
        } else {
          localStorage.removeItem("complexProduct");
          setIsValidatingTargetFund(false);
        }
      });
    } else {
      setIsValidatingTargetFund(false);
    }
  }, [
    setIsValidatingTargetFund,
    Auth,
    history,
    localStorage,
    FnmContractComplexProductService,
    watchTargetFund,
  ]);

  if (serviceError.status) {
    return <div>Error: {serviceError.message}</div>;
  }

  return (
    <DocumentTitle title="IronIA - Cancelación de order">
      <div className="incidence-order">
        <div className="ms-Grid-row" style={{ marginBottom: "10px" }}>
         {order?.orderType === OrderOrderType.Subscription ? <h2>Cancelación de orden</h2> : <h2>Cancelación de traspaso</h2>} 
        </div>
        {isLoading && <Spinner size={SpinnerSize.large} />}
        {!isLoading && order !== undefined && (
          <>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="form rounded shadow" style={{ flex: "1" }}>
                <h3>
                  <b>Resumen de la orden</b>
                </h3>
                <table style={{ marginBottom: "20px" }}>
                  <tr className="cancel-order-tr">
                    <th className="cancel-order-th">Fecha</th>
                    <td className="cancel-order-td">{date}</td>
                  </tr>
                  <tr className="cancel-order-tr">
                    <th className="cancel-order-th">Estado</th>
                    <td>{status}</td>
                  </tr>
                  <tr className="cancel-order-tr">
                    <th className="cancel-order-th">Detalle del estado</th>
                    <td>{statusMessage}</td>
                  </tr>
                  <tr className="cancel-order-tr">
                    <th className="cancel-order-th">Tipo de orden</th>
                    <td>{typeText}</td>
                  </tr>
                  <tr className="cancel-order-tr">
                    <th className="cancel-order-th">Importe</th>
                    <td>
                      {isNaN(parseNumber(amount, 2))
                        ? amount
                        : parseNumber(amount, 2) + " €"}
                    </td>
                  </tr>
                  <tr className="cancel-order-tr">
                    <th className="cancel-order-th">Fondo</th>
                    <td>
                      {type === "subscription" && (
                        <p>
                          <span
                            className="fund-name"
                            style={{
                              color: "rgb(66,132,230)",
                              cursor: "pointer",
                            }}
                            onClick={() => history.push(`/${isinOrig}`)}
                          >
                            {isinOrig}
                            {" - "}
                            {nameOrig} - Destino:{" "}
                            <span
                              className="fund-name"
                              style={{
                                color: "rgb(66,132,230)",
                                cursor: "pointer",
                              }}
                              onClick={() => history.push(`/${isinDesty}`)}
                            >
                              {isinDesty}
                              {" - "}
                              {nameDesty}
                            </span>
                          </span>
                        </p>
                      )}
                      {type === "refund" && (
                        <>
                          <p>
                            Origen:{" "}
                            <span
                              className="fund-name"
                              style={{
                                color: "rgb(66,132,230)",
                                cursor: "pointer",
                              }}
                              onClick={() => history.push(`/${isinOrig}`)}
                            >
                              {isinOrig}
                              {" - "}
                              {nameOrig}
                            </span>{" "}
                            - Destino:{" "}
                            <span
                              className="fund-name"
                              style={{
                                color: "rgb(66,132,230)",
                                cursor: "pointer",
                              }}
                              onClick={() => history.push(`/${isinDesty}`)}
                            >
                              {isinDesty}
                              {" - "}
                              {nameDesty}
                            </span>
                          </p>
                          <p>
                            IBAN Destino: <span className="iban">{iban}</span>
                          </p>
                        </>
                      )}
                      {!["subscription", "refund"].includes(type) && (
                        <div>
                          <p>
                            Origen:{" "}
                            <span
                              className="fund-name"
                              style={{
                                color: "rgb(66,132,230)",
                                cursor: "pointer",
                              }}
                              
                              onClick={() => history.push(`/${isinOrig}`)}
                            >
                              {isinOrig}
                              {" - "}
                              {nameOrig}
                            </span>{" "}
                            - Destino:{" "}
                            <span
                              className="fund-name"
                              style={{
                                color: "rgb(66,132,230)",
                                cursor: "pointer",
                              }}
                              onClick={() => history.push(`/${isinDesty}`)}
                            >
                              {isinDesty}
                              {" - "}
                              {nameDesty}
                            </span>
                          </p>
                        </div>
                      )}
                    </td>
                  </tr>
                </table>
              </div>
              <div className="form rounded shadow" style={{ flex: "1" }}>
                <h3>
                  <b>
                    Seleccione una de las siguientes opciones para gestionar la
                    cancelación:
                  </b>
                </h3>
                {order?.orderType != null && (
                  <div className="ms-Grid-row">
                    <div className="ms-Tabs-Container-Cancel">
                      <div style={{paddingBottom: "12px"}}>
                      <button
                        className={"ms-Tab-Cancel" + (fundSelector ? " active" : "")}
                        onClick={() => setFundSelector()}
                        disabled={signature !== undefined && true}
                      >
                        {fundSelector ? (
                          <RadioBtnOnIcon className={"ms-Tab-Cancel" + (fundSelector ? " active-button" : "")}/>
                        ) : (
                          <RadioBtnOffIcon className={"ms-Tab-Cancel" + (fundSelector ? " active-button" : "")}/>
                        )}{" "}
                        Seleccione otro fondo de inversión al que ira el importe
                        de la orden
                      </button>
                      </div>
                      <button
                        className={"ms-Tab-Cancel" + (fundSelector ? "" : " active")}
                        onClick={() => setRefund()}
                        disabled={ signature !== undefined && true}
                      >
                        {fundSelector ? (
                          <RadioBtnOffIcon  className={"ms-Tab-Cancel" + (fundSelector ? "" : " active-button")} />
                        ) : (
                          <RadioBtnOnIcon  className={"ms-Tab-Cancel" + (fundSelector ? "" : " active-button")}/>
                        )}{" "}
                       {order?.orderType === OrderOrderType.Subscription ?  "Devolución del dinero a su cuenta bancaria" : "Cancelación de traspaso"}
                      </button>
                    </div>
                  </div>
                )}
                <div className="ms-Grid-row">
                  <div
                    className="ms-Grid-col ms-sm12 ms-xl12"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <form
                      className={(fundSelector || (!fundSelector && signature === undefined) ) ? "form rounded shadow" : ""}
                      id="form"
                      onSubmit={handleSubmit(onSubmit)}
                      noValidate
                    >
                      {fundSelector && (
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12">
                            <Controller
                              name="targetFund"
                              control={control}
                              rules={{ required: fundSelector }}
                              render={({ field, field: { onChange } }) => (
                                  <InvestmentFundFinder
                                    {...field}
                                    required={fundSelector}
                                    default={initialTargetFund ?? null}
                                    labelISIN="ISIN de destino"
                                    labelName="Nombre del fondo de destino"
                                    placeholderISIN="Escribe aquí el ISIN de destino..."
                                    placeholderName="Escribe aquí el nombre del fondo de destino..."
                                    loadData={(filter: any) => {
                                      return fundService
                                        .getAvailableFunds(filter)
                                        .then(
                                          (data: AvailableFundType[]) => data
                                        )
                                        .catch((err) => []);
                                    }}
                                    onChange={async (
                                      ev: any,
                                      name: any,
                                      value: any
                                    ) => {
                                      if (value !== undefined) {
                                        const result = await checkMin(value);
                                        if (!result.validAmount) {
                                          setError("targetFund", {
                                            type: "invalidTargetFundMin",
                                            message: `El fondo seleccionado requiere una inversión ${
                                              result.userIdentityInstrumentsIds.includes(
                                                value.isin
                                              )
                                                ? "adicional"
                                                : "inicial"
                                            } de ${currencyFormatter.format(
                                              result.min
                                            )}`,
                                          });
                                          return false;
                                        } else {
                                          clearErrors("targetFund");
                                          setValue(name, value);
                                          onChange({ target: { value, name } });
                                        }
                                      }
                                    }}
                                    errorMessageName={
                                      errors.targetFund?.message ?? ""
                                    }
                                  />
                              )}
                            />
                          </div>
                        </div>
                      )}
                      {!fundSelector && signature === undefined  && (
                        <>
                          <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12">
                              Pulse el botón continuar si desea que gestionemos
                              la cancelación 
                            </div>
                          </div>
                        </>
                      )}
                      {signature === undefined && (
                        <div className="ms-Grid-row pt20">
                          <div className="ms-Grid-col ms-sm12 text-right">
                            <DefaultButton
                              className="button tiny-primary-button"
                              type="submit"
                              disabled={
                                sending ||
                                isValidatingTargetFund ||
                                (fundSelector && errors.targetFund)
                              }
                            >
                              {(sending || isValidatingTargetFund) && (
                                <Spinner
                                  size={SpinnerSize.small}
                                  className="button-spinner"
                                />
                              )}
                              Continuar
                            </DefaultButton>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {(signature !== undefined || signature != null) &&
              order?.userIdentity !== undefined && (
                <SignatureComponent
                  userIdentities={[order.userIdentity]}
                  signatures={[signature]}
                  alreadySignedText= {order?.orderType === OrderOrderType.Subscription ?  "¡La cancelación de la orden está en proceso!": "¡La cancelación del traspaso está en proceso!"}
                  title=""
                  submitButtonText="Firmar"
                  cancelButtonText="Cancelar"
                  headerTextArea={`Confirma la order con la clave enviada a tu teléfono ${phoneNumber}.`}
                  backButtonText="Volver"
                  onBackButtonClicked={() => history.push("/portfolio/orders")}
                  onCancelButtonClicked={() =>
                    history.push("/portfolio/orders")
                  }
                  requestNewCodeButtonText="Recibir nuevo código"
                  confirmElement={
                    {
                        text: "Confirmo haber recibido, leído y comprendido la documentación obligatoria de IIC's y realizar esta operación por iniciativa propia.",
                        value: userConfirmation.user,
                        setValue: (value: boolean) => setUserConfirmation({user: !!value, disclaimer: userConfirmation.disclaimer})
                    }}
                    disclaimerEnabled={
                    !fundSelector ? undefined : {
                      text: "He sido informado de que alguna de las operaciones realizadas en este momento pueden no tomar el valor liquidativo del día en curso.",
                      processHours: processHours,
                      isins: createIsins(),
                      value: userConfirmation.disclaimer,
                      isTransfer: false,
                      isMultiIsin: false,
                      setValue: (value: boolean) => setUserConfirmation({user: userConfirmation.user, disclaimer: !!value})
                  }
                    }
                  onNewCodeSended={(signatures: any) => {
                    signatures.forEach((signature: any) => {
                      processExpirationDate(signature);
                    });
                    setSignature(signatures[0]);
                  }}
                  onSignatureChanged={(signature: any) => {
                    processExpirationDate(signature);
                    setSignature(signature);
                  }}
                  onSigned={(signature: any) => {
                    processExpirationDate(signature);
                    setSignature(signature);
                    submitSignature(signature);
                  }}
                />
              )}
            {isModalFundSelector && (
              <GenericMessageModalWithoutButton
                title={order?.orderType === OrderOrderType.Subscription ?  "Orden cancelada y creación de nueva orden": "Traspaso cancelado y creación de nueva orden"} 
                body={ order?.orderType === OrderOrderType.Subscription ?
                  <p>
                    La orden ha sido cancelada y se ha creado una nueva orden para <b>{watchTargetFund.name + " " + "ISIN " +  watchTargetFund.isin}</b>  por el mismo importe de la orden cancelada. 
                  </p> : <p>
                    El traspaso ha sido cancelado y se ha creado una nueva orden para <b>{watchTargetFund.name + " " + "ISIN " +  watchTargetFund.isin}</b>  por el mismo importe del traspaso cancelado. 
                  </p>
                }
                btnSubmitText="Aceptar"
                showModal={showModalFundSelector}
                onSubmit={() => {
                  setShowModalFundSelector(false);
                  history.push("/portfolio/orders");
                }}
                onClose={() => {
                  setShowModalFundSelector(false);
                  history.push("/portfolio/orders");
                }}
              ></GenericMessageModalWithoutButton>
            )}
            {isModalMail && order?.orderType === OrderOrderType.Subscription  && (
              <GenericMessageModalWithoutButton
                title={"Cancelación de la orden registrada" }
                body={
                  <p>
                   La orden ha sido cancelada. En un plazo mínimo de 48 horas (días laborables) recibirá una transferencia SEPA al IBAN origen del pago. 
                  </p> 
                }
                btnSubmitText="Aceptar"
                showModal={showModalMail}
                onSubmit={() => {
                  setShowModalMail(false);
                  history.push("/portfolio/orders");
                }}
                onClose={() => {
                  setShowModalMail(false);
                  history.push("/portfolio/orders");
                }}
              ></GenericMessageModalWithoutButton>
            )}
            {isModalMail && typeText != "Suscripción"  && (
              <GenericMessageModalWithoutButton
                title={"Cancelación del traspaso registrado" }
                body={<p>
                   El traspaso ha sido cancelado. Pulse el boton aceptar para volver a las ordenes en curso. 
                  </p>
                }
                btnSubmitText="Aceptar"
                showModal={showModalMail}
                onSubmit={() => {
                  setShowModalMail(false);
                  history.push("/portfolio/orders");
                }}
                onClose={() => {
                  setShowModalMail(false);
                  history.push("/portfolio/orders");
                }}
              ></GenericMessageModalWithoutButton>
            )}
            <div className="ms-Grid-row p10">
              <div className="ms-Grid-col ms-sm12">
                <div className="section-row">
                  <br />
                  <div className="ms-Grid-row p10">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12">
                      <div className="section-row">
                        <br />
                        <div className="warning-container">
                          <div>
                            <img
                              src="/icons/information.svg"
                              alt="Información"
                            />
                          </div>
                          <h3>Descarga de responsabilidad</h3>
                          <p>
                            El proceso de cancelación de orderes y traspasos es un proceso especial que depende de muchos factores ajenos Simplicitas Capital y Diaphanum SLV, dicho proceso
                            se va a realizar bajo el compromiso de mejor esfuerzo, este compromiso establece que las empresas antes citadas realizarán todo lo que este en su mano
                            para la ejecución del proceso, no constituyendo una garantia para ningun tipo de reclamación la no ejecución de este proceso.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </DocumentTitle>
  );
};

export default OrderCancelManual;

import DocPreLogService, { DocPreLogs, requiredPreLog } from '../../services/DocPreLogService';
import moment from "moment";
import styles from './index.module.sass';
import { useEffect, useState } from 'react';
import { DetailsList, SelectionMode, Spinner, SpinnerSize } from '@fluentui/react';

const columns: any = [
    { key: 'name', name: 'Fichero', fieldName: 'name', minWidth: 75, maxWidth: 75 },
    { key: 'link', name: 'Acción', fieldName: 'url', minWidth: 90, maxWidth: 90 },
    { key: 'dateClick', name: 'Fecha de lectura', fieldName: 'dateClick', minWidth: 150 }
];

interface Props {
    cartLines: any[],
    user: any
    allDocumentsReaded: boolean,
    setAllDocumentsReaded: (allDocumentsReaded: boolean) => void
}

const Documents = (props: Props) => {
    const { cartLines, user, allDocumentsReaded, setAllDocumentsReaded } = props;
    const [docPreLogs, setDocPreLogs] = useState<DocPreLogs[]>();
    const [loadingDocPreLogs, setLoadingDocPreLogs] = useState<boolean>(true);
    const [cartIsins, setCartIsins] = useState<string[]>([]);

    useEffect(() => {
        const newIsins = cartLines.map(cL => cL.isin);

        const longArray = newIsins.length > cartIsins.length ? newIsins : cartIsins;
        const shortArray = newIsins.length > cartIsins.length ? cartIsins : newIsins;

        const differenceIsins = longArray.filter(isin => !shortArray.includes(isin));
        if (differenceIsins.length > 0) {
            setCartIsins(newIsins);
        }
    }, [cartLines]);

    useEffect(() => {
        if (cartLines.length > 0) {
            setLoadingDocPreLogs(true);
            DocPreLogService.docPreLogsByUserId(user.id, cartIsins).then((response: DocPreLogs[]) => {
                setDocPreLogs(response);
                setTimeout(() => setLoadingDocPreLogs(false), 100);
            });
        }
    }, [cartIsins]);

    useEffect(() => {
        if (cartLines.length > 0 && docPreLogs !== undefined) {
            const notAllDocumentsReaded = cartLines.some((cL: any) => {
                var docPreLogsTypesByIsin = docPreLogs.filter((dPL: DocPreLogs) => dPL.isin === cL.isin).map((dPL: any) => dPL.documentType);
                var docsToRead = cL.documents.filter((d: any) => requiredPreLog.includes(d.type));
                return docsToRead.some((d: any) => !docPreLogsTypesByIsin.includes(d.type));
            });
            setAllDocumentsReaded(!notAllDocumentsReaded);
        }
    }, [docPreLogs]);

    const onDocumentClick = (isin: string, document: any) => {
        DocPreLogService.createDocPreLogs({
            userId: user.id,
            isin: isin,
            documentType: document.type,
            dateClick: moment().toISOString()
        }).then((response: DocPreLogs) => {
            setDocPreLogs(prevAux => [
                ...(prevAux ?? []),
                response
            ]);
        });
    };

    const renderAttachmentColumn = (isin: string, item: any, index: any, column: any) => {
        switch (column.key) {
            case 'name':
                if (docPreLogs === undefined) {
                    return <></>
                }
                var docPrelog = docPreLogs.find(dPL => dPL.isin === isin && dPL.documentType === item.type);
                if (docPrelog) {
                    return <span>{item.name}</span>
                }
                return <span>{item.name}</span>

            case 'link':
                return <a
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => onDocumentClick(isin, item)}
                >
                    <i className={'ironia-icon downloads'} /> Descarga
                </a>;

            case 'dateClick':
                if (docPreLogs === undefined) {
                    return <></>
                }
                var docPrelog = docPreLogs.find(dPL => dPL.isin === isin && dPL.documentType === item.type);
                if (docPrelog) {
                    return <div className={styles.withDate}>
                        <i className="ironia-icon circledCheck" />
                        <span>{moment(docPrelog.dateClick).format("DD/MM/YYYY hh:mm")}</span>
                    </div>
                }

                return <div className={styles.withoutDate}>
                    <i className={`ironia-icon cross primaryColor ${styles.cross}`} />
                    <span>Pulse sobre el enlace de descarga para leer el documento</span>
                </div>

            default:
                return item[column.fieldName];
        }
    }

    if (loadingDocPreLogs || allDocumentsReaded) {
        return null
    }

    return (
        <div className={styles.documents}>
            {loadingDocPreLogs && <Spinner size={SpinnerSize.small} />}
            {!loadingDocPreLogs && cartLines.length > 0 && (
                <div className="ms-Grid-row">
                    {cartLines.map((cartLine) => (
                        <>
                            <div className={`ms-Grid-col ms-lg12 ms-xl6 ms-xxl4 ms-xxxl3 p10 ${styles.listContainer}`}>
                                <h4>Documentos del fondo {cartLine.isin}</h4>
                                <DetailsList
                                    setKey="items"
                                    items={cartLine.documents.filter((d: any) => requiredPreLog.includes(d.type)) ?? []}
                                    columns={columns}
                                    onRenderItemColumn={(row, index, column) => renderAttachmentColumn(cartLine.isin, row, index, column)}
                                    selectionMode={SelectionMode.none}
                                />
                            </div>
                        </>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Documents;

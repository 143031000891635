export const getScoreIcons = (averageScore) =>
{
    let score = Math.ceil(averageScore / 20);

    let counter = score;
    let iconArray = [0, 0, 0, 0, 0];

    iconArray = iconArray.map((active) =>
    {
        active = counter > 0 ? 1 : 0;
        counter--;
        return active;
    });

    return iconArray;
};
import { ITextFieldProps, ITextFieldStyles, TextField } from "@fluentui/react";
import { useId } from '@fluentui/react-hooks';
import {ThemeContext} from '@fluentui/react-theme-provider';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import {ModalRefund} from './ModalRefund';

export interface Props extends ITextFieldProps
{
    placeholder: string,
    buttonTitle: string,
    valueRefund: string,
    onChange?: (event: any, name?: string, value?: any, required?: boolean) => any;
    handleRefund: (refund: string) => void
};

export const DivInputElement = styled.div`
    > div
    {
        width: 100%;
        min-Height: 80px !important;

        > div
        {
            width: 70% !important;
        }

        #divButton
        {
            width: 20% !important;
        }
    }
`;
const stylesPassword: Partial<ITextFieldStyles> =
{
    revealButton:
    {
        backgroundColor: "transparent !important",
        paddingTop: "10px"
    }
};

const InputRefundsButton :  React.FC<Props> = (props: Props) =>
{
    const inputId = useId();
    const theme = useContext(ThemeContext);

    const [selectedItem, setRefundCode] = useState<any>({data: props.valueRefund});

    const handleRefund: Props["onChange"] = (event: any, item: any) =>
    {
        setRefundCode(item);
        if(props.onChange)
        {
            props.onChange(event, props.name, item, props.required);
        }
    };

    if(props.valueRefund === undefined || props.valueRefund === "")
    {
        return(
            <DivInputElement theme={theme}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col" style={{width: "70% !important"}}>
                        <TextField styles={stylesPassword} {...props} id={inputId} autoComplete="off" errorMessage={props.errorMessage} label={props.label} placeholder={props.placeholder} />
                    </div>
                    <div className="ms-Grid-col" id="divButton" style={{minHeight: "90px !important", width: "20% !important"}}>
                        <ModalRefund onChange={handleRefund} />
                    </div>
                </div>
            </DivInputElement>
        );
    }
    else
    {
        return(
            <TextField styles={stylesPassword} {...props} id={inputId} autoComplete="off" errorMessage={props.errorMessage} label={props.label} placeholder={props.placeholder} />
        );
    }
}

export default InputRefundsButton;
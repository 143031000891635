import styled from "styled-components";

export const ButtonIA = styled.button`
    border: 1px solid #CC214F;
    border-radius: 20px;
    background-color: #CC214F;
    color: ${(props) => props.theme.palette.white};
    cursor: pointer;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 1em;
    padding: 1em;
    width: 100%;
`;
export const ButtonIADisabled = styled.button`
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    background-color: #E0E0E0;
    color: black;
    cursor: not-allowed;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 1em;
    padding: 1em;
    width: 48%;
`;
export const DivRowButtonDisabled = styled.div`
`;
export const DivColButtonSpinnerDisabled = styled.div`
`;
export const DivColButtonTextDisabled = styled.div`
`;
export const TextDisabled = styled.p`
    margin: 0;
    text-align: left;
    color: ${(props) => props.theme.palette.black};
`;
export const WarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    cursor: default;
    border-radius: 16px;
    background: ${(props) => props.theme.palette.warnSecondary};
    color: ${(props) => props.theme.palette.warn};
    padding: 1em;
    text-align: justify;
    gap: 10px;
    font-weight: 500;
    h3
    {
        margin: 0;
    }
    p
    {
        line-height: 20px;
        margin: 0;
    }
`;
export const WarningText = styled.p`
    margin: 0 !important;
    font-size: 10px !important;
    line-height: 15px !important;
`;
export const DivSubtitleManager = styled.div`
    padding-top: 0.25em;
`;
export const SubtitleManager = styled.p`
    margin: 0 !important;
    cursor: default;
    color: ${(props) => props.theme.palette.black};
`;
export const ContainerDiv = styled.div``;
export const LoadingText = styled.p`
    text-align: left;
    margin: 0 !important;
    cursor: default;
    color: ${(props) => props.theme.palette.black};
`;
export const DivTitleContainer = styled.div`
    text-align: left;
    cursor: default;
`;
export const TitleText = styled.h3`
    width: 95%;
    margin-bottom: 0 !important;
    padding-top: 0.5em;
    font-size: 20px;
    color: ${(props) => props.theme.palette.black};
`;
export const DivBodyContainer = styled.div`
    padding-top: 1em;
`;
export const DivBodyRow = styled.div`
    margin-bottom: 2em;
    padding-top: 0.25em;
`;
export const DivBodyListenAudio = styled.div`
    text-align: center;
    cursor: not-allowed;
`;
export const DivBodyVideoAudio = styled.div`
    text-align: center;
    cursor: not-allowed;
`;
export const DivBodyTotalElements = styled.div`
    text-align: center;
    cursor: not-allowed;
`;
export const TitleReading = styled.p`
    margin: 0 !important;
    color: ${(props) => props.theme.palette.black};
`;
export const TitleVideo = styled.p`
    margin: 0 !important;
    color: ${(props) => props.theme.palette.black};
`;
export const TitleElements = styled.p`
    margin: 0 !important;
    color: ${(props) => props.theme.palette.black};
`;
export const TextExplanation = styled.p`
    margin: 0 !important;
    cursor: default;
    text-align: justify;
    padding-left: 1.25em;
    padding-right: 1em;
    color: ${(props) => props.theme.palette.black};
`;
export const DivBodyExplanationRow = styled.div`
    margin-top: 1em;    
    margin-bottom: 1em;
`;
export const DivBodyExplanationCol = styled.div``;
export const DivBodyErrorRow = styled.div`
`;
export const ErrorExplanation = styled.p`
    margin: 0 !important;
    cursor: default;
    text-align: justify;
    padding-left: 3em;
    padding-right: 1em;
    padding-bottom: 1em;
    font-size: 20px;
    color: ${(props) => props.theme.palette.black};
`;
export const BodyLoading = styled.div`
    padding-left: 1em;
    margin-bottom: 1em;
`;
export const DivRowLoadingMultimedia = styled.div`
`;
export const DivColLoadingAudio = styled.div`
`;
export const DivColLoadingVideo = styled.div`
`;
export const DivColSpinnerAudio = styled.div`
    padding-top: 0.2em;
`;
export const DivColSpinnerVideo = styled.div`
    padding-top: 0.2em;
`;
export const DivColIconAudio = styled.div`
    padding-top: 0.2em;
    text-align: end;
    color: red;
    cursor: default;
`;
export const DivColIconVideo = styled.div`
    padding-top: 0.2em;
    text-align: end;
    color: red;
    cursor: default;
`;
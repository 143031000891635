import { useContext, useEffect, useRef, useState } from 'react';
import * as L from './layout.styles';
import { IChoiceGroupOption, TextField, ChoiceGroup, Stack, SpinnerSize, Spinner } from '@fluentui/react';
import { CaretHollowMirroredIcon } from '@fluentui/react-icons-mdl2';
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { InvestmentFundFinder } from "./components/InvestmentFundFinder";
import { DistributorFinder } from "./components/DistributorFinder";
import { ThemeContext } from '@fluentui/react-theme-provider';
import Auth from "../../Auth/Auth";
import OrderService, { OrderType } from "../../services/OrderService";
import fundService, { TransferEstimationDates, TransferSimulationManagerInfo } from "../../services/FundService";
import { useForm, Controller } from "react-hook-form";
import { UserType } from '../../services/UserService';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { TransferTimeLine } from './components/TransferTimeLine';

interface RouteParams{}
interface ITransferFormScreenProps extends RouteComponentProps<RouteParams>{}

const amountTypeOptions: IChoiceGroupOption[] = [
    { key: "0", text: 'Por importe', value: 'amount' },
    { key: "1", text: 'Por participaciones', value: 'titles' },
    { key: "2", text: 'Total', value: 'total' },
];

interface filters {
    sourceIsin: '',
    sourceName: ''
}

export const TransferFormScreen: React.FunctionComponent<ITransferFormScreenProps> = (props, context) =>
{
    const theme = useContext(ThemeContext);
    let activePlan = Auth.getActivePlan();
    const { register, handleSubmit, watch, setError, formState: { errors }, control, setValue} = useForm();
    const [sending, setSending] = useState<boolean>(false);
    const [simulation, setSimulation] = useState<boolean>(false);
    const [hasChangedIsin, setHasChangedIsin] = useState<boolean>(false);
    const [hasChangedExternalManager, setHasChangedExternalManager] = useState<boolean>(false);
    const [simulationAvailable, setSimulationAvailable] = useState<boolean>(false);
    const [transferDates, setTransferDates] = useState<TransferEstimationDates>({compraLiquidacion: "", compraValor: "", finalProceso: "", ventaLiquidacion: "", ventaValor: ""});
    const history = useHistory();
    const mounted = useRef(false);
    let userData: UserType = Auth.getUserProfile();
    const watchAmountType = watch("amountType", "");
    const outdated = Auth.isLastPlanOutdated();
    const cancelled = Auth.isActivePlanCancelled();
    const activeManagementPlan = Auth.isActiveManagementPlan();
    const [filters, setFilters] = useState<filters>({sourceIsin: '', sourceName: ''});
    const [simulationLoading, setSimulationLoading] = useState<boolean>(false);
    const [originTransferSimulationManagerInfo, setOriginTransferSimulationManagerInfo] = useState<TransferSimulationManagerInfo[]>([]);
    const [targetTransferSimulationManagerInfo, setTargetTransferSimulationManagerInfo] =  useState<TransferSimulationManagerInfo[]>([]);

    const onSubmit = (data: any) =>
    {
        if((data.sourceFund?.name ?? filters.sourceName) === '')
        {
            setError("sourceFund.name", {type: "required"}, {shouldFocus: true});
            return;
        }
        if((data.sourceFund?.isin ?? filters.sourceIsin) === '')
        {
            setError("sourceFund.isin", {type: "required"}, {shouldFocus: true});
            return;
        }
        if(data.amountType === "total" && typeof data.amount !== "undefined")
        {
            delete data.amount;
        }

        let toSend: any =
        {
            UserIdentityId: userData.mainUserIdentity.id,
            FnmPortfolioId: activePlan?.portfolio?.id,
            OrderType: 3,
            Values:
            {
                InceptionFund:
                {
                    Company: data.distributor.name.trim(),
                    Name: (data.sourceFund?.name ?? filters.sourceName).trim(),
                    Isin: (data.sourceFund?.isin ?? filters.sourceIsin).trim(),
                    Account: data.account.trim(),
                    EntityNif: data.distributor.nif.trim()
                },
                newInstrument: data.targetFund?.finametrixId,
                Fund: data.targetFund?.finametrixId,
                AmountType: data.amountType,
                Amount: data.amount !== undefined ? parseFloat(data.amount) : 0,
                Type: data.amountType
            }
        };

        setSending(true);
        OrderService.createOrder(toSend).then((response: OrderType) =>
        {
            if(response.id)
            {
                history.push("/transfer/signature",
                {
                    isinOrigin: (data.sourceFund?.isin ?? filters.sourceIsin).trim(),
                    isinDestination: data.targetFund.isin.trim(),
                    signature: response.signature,
                    transfer: response,
                });
            }
        })
        .catch((error) => {})
        .finally(() =>
        {
            if(mounted.current)
            {
                setSending(false);
            }
        });
    };
    useEffect(() =>
    {
        if(hasChangedIsin && watch("sourceFund") !== undefined && watch("targetFund") !== undefined && watch("distributor") !== undefined)
        {
            setSimulationAvailable(true);
        }
        else
        {
            setSimulationAvailable(false);
        }
    },[simulationAvailable, watch, hasChangedIsin, hasChangedExternalManager]);

    const handleSimulation = () =>
    {
        setSimulationLoading(true);
        setSimulation(true);
        const fundOrigen = watch("sourceFund");
        const fundDestino = watch("targetFund");
        const distributor = watch("distributor");

        if(fundOrigen !== undefined && fundDestino !== undefined)
        {
            fundService
            .GetTransferSimulationManagerInfo([fundOrigen.isin], [(distributor?.name ?? "").trim()])
            .then((response: TransferSimulationManagerInfo[]) => setOriginTransferSimulationManagerInfo(response));

            fundService
            .GetTransferSimulationManagerInfo([fundDestino.isin], [])
            .then((response: TransferSimulationManagerInfo[]) => setTargetTransferSimulationManagerInfo(response));

            fundService.getTransferEstimationDates(fundOrigen.isin, fundDestino.isin, false).then((response: any) =>
            {
                if(response !== null && response !== undefined)
                {
                    setTransferDates(
                    {
                        ventaValor: response.ventaValor,
                        ventaLiquidacion: response.ventaLiquidacion,
                        compraValor: response.compraValor,
                        compraLiquidacion: response.compraLiquidacion,
                        finalProceso: response.finalProceso
                    });
                }
                else{
                    setSimulation(false);
                }
            }).finally(() => {setSimulationLoading(false);});
        }
        else
        {
            setSimulation(false);
        }
    }

    useEffect(() =>
    {
        mounted.current = true;
        return () =>
        {
            mounted.current = false;
        };
    }, []);

    if(outdated === true || cancelled === true)
    {
        return (<Redirect to="/savingsPlans" />);
    }
    if(activeManagementPlan === true)
    {
        return (<Redirect to="/Portfolio" />);
    }

    return(
        <React.Fragment>
            <DocumentTitle title={"IronIA - Traspaso Externo"}>
                <form
                    className="external-transfer-form"
                    id="external-transfer-form"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                >
                    <L.Main theme={theme}>
                        <L.BackButtonContainer>
                            <span>
                                <CaretHollowMirroredIcon />
                                <Link to='/purchase-transfer'>
                                    Volver atrás
                                </Link>
                            </span>
                        </L.BackButtonContainer>
                        {activePlan !== undefined &&
                            <div className="ms-Grid-row" style={{ minHeight:"20px"}}>
                                <div className="ms-Grid-col">
                                    <L.TitleContainer>
                                        <h2>Aumenta tu inversión / ahorro</h2>
                                    </L.TitleContainer>
                                </div>
                                <div className="ms-Grid-col" style={{paddingTop: "12px", color: "#CC214F"}}>
                                <L.SubTitleContainer> {activePlan.name}</L.SubTitleContainer>
                                </div>
                            </div>
                        }
                        {activePlan === undefined &&
                            <L.TitleContainer>
                                <h2>Aumenta tu inversión / ahorro</h2>
                            </L.TitleContainer>
                        }
                        <L.SeparatorTop />
                        <div className="ms-Grid restarted-ms-Grid">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-xl6">
                                    <L.CardContainer theme={theme}>
                                        <L.CardHeader>
                                            <L.CardHeadingContainer>
                                                <h3>Datos origen</h3>
                                            </L.CardHeadingContainer>
                                            <L.TextSmall className="small-text" theme={theme}>
                                                Si no encuentra el fondo que busca en los desplegables, puede introducirlo de
                                                manera manual en las cajas de texto correspondientes al ISIN y el nombre del fondo.
                                            </L.TextSmall>
                                        </L.CardHeader>
                                        <L.CardBody>
                                            <Stack>
                                                <Stack.Item>
                                                    <Controller control={control}
                                                        name="sourceFund"
                                                        render={({field, field: {onChange}}) =>
                                                            <InvestmentFundFinder {...field}
                                                                labelISIN="ISIN de origen"
                                                                labelName="Nombre del fondo de origen"
                                                                placeholderISIN="Escribe aquí el ISIN de origen..."
                                                                placeholderName="Escribe aquí el nombre del fondo de origen..."
                                                                required={false}
                                                                switchableRequired={true}
                                                                onChange={(ev: any, name: any, value: any) =>
                                                                {
                                                                    if(value !== undefined)
                                                                    {
                                                                        setHasChangedIsin(true);
                                                                        setSimulation(false);
                                                                    }
                                                                    else
                                                                    {
                                                                        setHasChangedIsin(false);
                                                                    }
                                                                    setValue(name, value);
                                                                    onChange({target: {value, name}});
                                                                }}
                                                                onFilterChange={(inputName: string, value: any) =>
                                                                {
                                                                    setFilters((prevState: filters) =>
                                                                    ({
                                                                        ...prevState,
                                                                        [inputName === 'isin' ? 'sourceIsin' : 'sourceName']: value
                                                                    }))
                                                                }}
                                                                errorMessageIsin={errors.sourceFund?.isin &&
                                                                (
                                                                    (typeof (errors.sourceFund.isin.type) === "string"
                                                                        && errors.sourceFund.isin.type === "required"
                                                                        && "Debe seleccionar el isin del fondo de origen.")
                                                                    || "El fondo de origen no es válido."
                                                                )}
                                                                errorMessageName={errors.sourceFund?.name &&
                                                                (
                                                                    (typeof (errors.sourceFund.name.type) === "string"
                                                                        && errors.sourceFund.name.type === "required"
                                                                        && "Debe seleccionar el nombre del fondo de origen.")
                                                                    || "El fondo de origen no es válido."
                                                                )}
                                                            />
                                                        }
                                                    />
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Controller control={control}
                                                        name="distributor"
                                                        rules={{required: true}}
                                                        render={({field, field: {onChange}}) =>
                                                            <DistributorFinder {...field} required
                                                                label="Comercializador"
                                                                placeholder="Escribe aquí el comercializador..."
                                                                onChange={(ev: any, name: any, value: any) =>
                                                                {
                                                                    if(value !== undefined)
                                                                    {
                                                                        setHasChangedExternalManager(true);
                                                                        setSimulation(false);
                                                                    }
                                                                    else
                                                                    {
                                                                        setHasChangedExternalManager(false);
                                                                    }
                                                                    setValue(name, value);
                                                                    onChange({target: {value, name}});
                                                                }}
                                                                errorMessage={errors.distributor && 
                                                                (
                                                                    (typeof(errors.distributor.type) === "string"
                                                                        && errors.distributor.type === "required"
                                                                        && "Debe seleccionar un comercializador.")
                                                                    || "El comercializador no es válido."
                                                                )}
                                                            />
                                                        }
                                                    />
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <TextField
                                                        {...register("account",
                                                            {
                                                                required: true,
                                                                minLength: 3
                                                            }
                                                        )}
                                                        required
                                                        label="Cuenta de origen"
                                                        placeholder="Escribe aquí la cuenta de origen..."
                                                        errorMessage={errors.account &&
                                                        (
                                                            (typeof(errors.account.type) === "string"
                                                                && errors.account.type === "required"
                                                                && "Debe indicar una cuenta de origen.")
                                                            || "Introduzca una cuenta válida."
                                                        )}
                                                    />
                                                </Stack.Item>
                                            </Stack>
                                        </L.CardBody>
                                    </L.CardContainer>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-xl6">
                                    <L.CardContainer theme={theme}>
                                        <L.CardHeader>
                                            <L.CardHeadingContainer>
                                                <h3>Datos destino</h3>
                                            </L.CardHeadingContainer>
                                        </L.CardHeader>
                                        <L.CardBody>
                                            <Stack>
                                                <Stack.Item>
                                                    <Controller name="targetFund"
                                                        control={control}
                                                        rules={{required: true}}
                                                        render={({field, field: {onChange}}) => 
                                                            <InvestmentFundFinder {...field} required
                                                                labelISIN="ISIN de destino"
                                                                labelName="Nombre del fondo de destino"
                                                                placeholderISIN="Escribe aquí el ISIN de destino..."
                                                                placeholderName="Escribe aquí el nombre del fondo de destino..."
                                                                loadData={(filter: any) =>
                                                                {
                                                                    return fundService.getAvailableFunds(filter).then(data => data).catch(err => []);
                                                                }}
                                                                onChange={(ev: any, name: any, value: any) =>
                                                                {
                                                                    if(value !== undefined)
                                                                    {
                                                                        setHasChangedIsin(true);
                                                                        setSimulation(false);
                                                                    }
                                                                    else
                                                                    {
                                                                        setHasChangedIsin(false);
                                                                    }
                                                                    setValue(name, value);
                                                                    onChange({target: {value, name}});
                                                                }}
                                                                errorMessage={errors.targetFund &&
                                                                (
                                                                    (typeof(errors.targetFund.type) === "string"
                                                                        && errors.targetFund.type === "required"
                                                                        && "Debe seleccionar un fondo de destino.")
                                                                    || "El fondo de destino no es válido."
                                                                )}
                                                            />
                                                        }
                                                    />
                                                </Stack.Item>
                                            </Stack>
                                            <L.SeparatorForm />
                                            <h3>Tipo de importe</h3>
                                            <Stack>
                                                <Stack.Item>
                                                    <Controller name="amountType"
                                                        control={control}
                                                        rules={{required: true}}
                                                        render={({field}) => <ChoiceGroup {...field} required options={amountTypeOptions}/>}
                                                    />
                                                </Stack.Item>
                                                {watchAmountType !== "total" && <Stack.Item>
                                                    <Controller name="amount"
                                                        control={control}
                                                        rules={{required: true, min: 0 + Number.MIN_VALUE}}
                                                        render={({field}) => 
                                                            <TextField {...field}
                                                                label={(watchAmountType === "titles" && "Participaciones") || "Importe"}
                                                                placeholder={(watchAmountType === "titles" && "Escribe aquí el número de participaciones...") || "Escribe aquí el importe..."}
                                                                type="number" required
                                                                errorMessage={errors.amount &&
                                                                (
                                                                    (watchAmountType === "titles" &&
                                                                        (
                                                                            (typeof (errors.amount.type) === "string"
                                                                                && errors.amount.type === "required"
                                                                                && "Indique las participaciones a traspasar.")
                                                                            || (typeof (errors.amount.type) === "string"
                                                                                && errors.amount.type === "min"
                                                                                && "Debe indicar un número mayor de participaciones.")
                                                                            || "Introduzca un número de participaciones válido."
                                                                        )
                                                                    )
                                                                    || (typeof (errors.amount.type) === "string"
                                                                        && errors.amount.type === "required"
                                                                        && "Debe indicar el importe a traspasar.")
                                                                    || (typeof (errors.amount.type) === "string"
                                                                        && errors.amount.type === "min"
                                                                        && "El importe debe ser mayor.")
                                                                    || "Introduzca un importe válido."
                                                                )}
                                                            />
                                                        }
                                                    />
                                                </Stack.Item>}
                                            </Stack>
                                        </L.CardBody>
                                    </L.CardContainer>
                                </div>
                            </div>
                        </div>
                        <L.OuterFooterArea>
                            <L.CardButton size="lg" disabled={!simulationAvailable} type="button" style={{marginRight: "1em"}} onClick={() => {setSimulationLoading(true);handleSimulation();}}>
                                Simular
                            </L.CardButton>
                            <L.CardButton size="lg" disabled={sending}>
                                {sending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                Continuar
                            </L.CardButton>
                        </L.OuterFooterArea>
                        {simulation === true && simulationAvailable === true && transferDates !== undefined && <TransferTimeLine isLoadingProps={simulationLoading} transferEstimationDates={transferDates} simulationManagerInfoTarget={targetTransferSimulationManagerInfo} simulationManagerInfoOrigin={originTransferSimulationManagerInfo} />}
                    </L.Main>
                </form>
            </DocumentTitle>
        </React.Fragment>
    );
};
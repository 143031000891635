import { DefaultButton, SearchBox, Separator, Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import DocumentTitle from "react-document-title";
import IronIAIntegrations, { PromotionedResult, PromotionedVideos } from "../../services/IroniaIntegrationsService";
import * as S from './IroniaExplicaStyle';
import IroniaExplicaGrid from "./IroniaExplicaGrid";
import { ai } from "../../services/TelemetryService";

const IronIAExplica = () =>
{
    const elements = 12;
    const appInsights = ai.reactPlugin;
    const [data, setData] = React.useState<PromotionedVideos[]>(Array(1).fill(0));
    const [page, setPage] = React.useState<number>(0);
    const [count, setCount] = React.useState<number>(0);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [uploadingCards, setUploadingCards] = React.useState<boolean>(false);
    const [searchTerm, setSearchTerm] = React.useState<string | undefined>("");
    const isFirstLoading = React.useRef<boolean>(true);

    React.useEffect(() =>
    {
        if(isFirstLoading.current === true)
        {
            isFirstLoading.current = false;

            IronIAIntegrations.getAllPromotionedVideos(page, "undefined").then((response: PromotionedResult) =>
            {
                if(response !== undefined)
                {
                    setData(response.list);
                    setCount(response.list.length);
                    setTotalCount(response.totalCount);
                }
            });
        }
    }, [page, elements]);

    const modifySearchTerm = (newSearchTerm: any) =>
    {
        setSearchTerm(newSearchTerm);
    }
    const handleKey = (e: any) =>
    {
        if(e.key === 'Enter')
        {
            load();
        }
    };
    const customEventSearchTrigger = () =>
    {
        appInsights.trackEvent({name: "Buscador en IronIA Explica"});
    }
    const load = () =>
    {
        setUploadingCards(true);
        customEventSearchTrigger();

        if(searchTerm === undefined || searchTerm === "")
        {
            IronIAIntegrations.getAllPromotionedVideos(page, "undefined").then((response: PromotionedResult) =>
            {
                if(response !== undefined)
                {
                    setData(response.list);
                    setCount(response.list.length);
                    setTotalCount(response.totalCount);
                    setPage(0);
                    setUploadingCards(false);
                }
            });
        }
        else
        {
            IronIAIntegrations.getAllPromotionedVideos(page, searchTerm as string).then((response: PromotionedResult) =>
            {
                if(response !== undefined)
                {
                    setData(response.list);
                    setCount(response.list.length);
                    setTotalCount(response.totalCount);
                    setPage(0);
                    setUploadingCards(false);
                }
            });
        }
    };
    const handlePage = (e: any) =>
    {
        if(e.target.id === 'up')
        {
            setPage(page+1);
            setUploadingCards(true);

            if(searchTerm === undefined || searchTerm === "")
            {
                IronIAIntegrations.getAllPromotionedVideos(page+1, "undefined").then((response: PromotionedResult) =>
                {
                    if(response !== undefined && response.list.length > 0)
                    {
                        setData(response.list);
                        setCount(response.list.length);
                        setTotalCount(response.totalCount);
                        setUploadingCards(false);
                    }
                });
            }
            else
            {
                IronIAIntegrations.getAllPromotionedVideos(page+1, searchTerm as string).then((response: PromotionedResult) =>
                {
                    if(response !== undefined && response.list.length > 0)
                    {
                        setData(response.list);
                        setCount(response.list.length);
                        setTotalCount(response.totalCount);
                        setUploadingCards(false);
                    }
                });
            }
        }
        else
        {
            if(page > 0)
            {
                setPage(page-1);
                setUploadingCards(true);

                if(searchTerm === undefined || searchTerm === "")
                {
                    IronIAIntegrations.getAllPromotionedVideos(page-1, "undefined").then((response: PromotionedResult) =>
                    {
                        if(response !== undefined && response.list.length > 0)
                        {
                            setData(response.list);
                            setCount(response.list.length);
                            setTotalCount(response.totalCount);
                            setUploadingCards(false);
                        }
                    });
                }
                else
                {
                    IronIAIntegrations.getAllPromotionedVideos(page-1, searchTerm as string).then((response: PromotionedResult) =>
                    {
                        if(response !== undefined && response.list.length > 0)
                        {
                            setData(response.list);
                            setCount(response.list.length);
                            setTotalCount(response.totalCount);
                            setUploadingCards(false);
                        }
                    });
                }
            }
        }
    }

    return(
        <React.Fragment>
            <DocumentTitle title={'IronIA Explica'} />
            <S.TitleDiv>
                <S.Title>
                    IronIA Explica
                </S.Title>
                <S.SubTitle>
                    Aquí en IronIA Explica puedes encontrar todas los vídeos que explican los fondos de inversión.
                    &nbsp;
                    Utiliza el buscador para encontrar por ISIN, nombre del Fondo o de la Gestora el vídeo que desees.
                </S.SubTitle>
                <Separator />
            </S.TitleDiv>
            {isFirstLoading.current === true &&
            (
                <React.Fragment>
                    <Spinner size={SpinnerSize.large} styles={{root: {marginTop: "2em"}}} />
                    <S.LoadingText>Cargando los datos de los vídeos...</S.LoadingText>
                </React.Fragment>
            )}
            {isFirstLoading.current === false &&
            (
                <React.Fragment>
                    <S.RowSearchCards className="ms-Grid-row">
                        <S.ColSearchEngine className="ms-Grid-col ms-sm12">
                            <S.FilterContainer>
                                <S.CountContainer>
                                    <S.CountTitle>
                                        Resultado de la Búsqueda
                                        <S.Count>
                                            ({totalCount})
                                        </S.Count>
                                    </S.CountTitle>
                                </S.CountContainer>
                                <S.InputSearchContainer>
                                    <SearchBox onKeyPress={e => handleKey(e)}
                                        placeholder={`Busca entre todos los vídeos de IronIA Explica...`}
                                        value={searchTerm}
                                        onChange={event =>
                                        {
                                            let text = event?.target.value;
                                            modifySearchTerm(text);
                                        }}
                                        autoComplete="off"
                                    />
                                </S.InputSearchContainer>
                                <S.ButtonSearchContainer>
                                    <DefaultButton text="Buscar" onClick={() => load()} />
                                </S.ButtonSearchContainer>
                            </S.FilterContainer>
                        </S.ColSearchEngine>
                            <S.ColCards className="ms-Grid-col ms-sm12">
                                <S.RowCards className="ms-Grid-row">
                                    {uploadingCards === false &&
                                    (
                                        <IroniaExplicaGrid page={page} elements={elements} data={data} />
                                    )}
                                    {uploadingCards === true &&
                                    (
                                        <React.Fragment>
                                            <Spinner size={SpinnerSize.large} styles={{root: {marginTop: "2em"}}} />
                                            <S.LoadingText>Actualizando los datos de los vídeos con la búsqueda...</S.LoadingText>
                                        </React.Fragment>
                                    )}
                                </S.RowCards>
                            </S.ColCards>
                    </S.RowSearchCards>
                    <S.RowPagination className="ms-Grid-row">
                        <S.ColPagination className="ms-Grid-col ms-sm12">
                            <S.PaginationContainer>
                                <S.PageCount>
                                    {count === 1 &&
                                    (
                                        <React.Fragment>
                                            {(totalCount/elements) <= 1 &&
                                            (
                                                <React.Fragment>
                                                    <span>{`Mostrando ${count} producto de ${totalCount} totales en ${page+1}/${Math.ceil(totalCount/elements)} página`}</span>
                                                </React.Fragment>
                                            )}
                                            {(totalCount/elements) > 1 &&
                                            (
                                                <span>{`Mostrando ${count} producto de ${totalCount} totales en ${page+1}/${Math.ceil(totalCount/elements)} páginas`}</span>
                                            )}
                                        </React.Fragment>
                                        
                                    )}
                                    {count !== 1 &&
                                    (
                                        <React.Fragment>
                                            {(totalCount/elements) <= 1 &&
                                            (
                                                <React.Fragment>
                                                    <span>{`Mostrando ${count} productos de ${totalCount} totales en ${page+1}/${Math.ceil(totalCount/elements)} página`}</span>
                                                </React.Fragment>
                                            )}
                                            {(totalCount/elements) > 1 &&
                                            (
                                                <span>{`Mostrando ${count} productos de ${totalCount} totales en ${page+1}/${Math.ceil(totalCount/elements)} páginas`}</span>
                                            )}
                                        </React.Fragment>
                                    )}
                                </S.PageCount>
                                <S.PageButtons>
                                    <button onClick={handlePage} className={page === 0 ? "disabled" : ""}>
                                        <i className={"ironia-icon keyboard-arrow-left"} id="down" />
                                    </button>
                                    <button onClick={handlePage} className={Math.ceil(totalCount/elements) === page+1 ? "disabled" : ""}>
                                        <i className={"ironia-icon keyboard-arrow-right"} id="up" />
                                    </button>
                                </S.PageButtons>
                            </S.PaginationContainer>
                        </S.ColPagination>
                    </S.RowPagination>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default IronIAExplica;
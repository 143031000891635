import { SET_THEME, SET_CONNECTION_REFERENCE, SET_MAIN_MENU_VISIBILITY, SET_HEADER_VISIBILITY, SET_FOOTER_VISIBILITY } from './Constants';

export const INITIAL_STATE =
{
    theme: "light",
    connectionReference: null,
    headerVisible: true,
    mainMenuVisible: true,
    footerVisible: true,
};

export default function uiReducer(state = INITIAL_STATE, action: any)
{
    switch(action.type)
    {
        case SET_THEME:
            return { ...state, theme: action.payload };
        case SET_CONNECTION_REFERENCE:
            return { ...state, connectionReference: action.payload };
        case SET_HEADER_VISIBILITY:
            return {...state, headerVisible: action.payload};
        case SET_MAIN_MENU_VISIBILITY:
            return {...state, mainMenuVisible: action.payload};
        case SET_FOOTER_VISIBILITY:
            return {...state, footerVisible: action.payload};
        default:
            return state;
    }
};
import Styles from "./index.module.css";
import logo from "../../../images/logo.svg";

import { useHistory } from 'react-router';

type Props =
{
	reportId: string;
	imgHeaderSource: string;
	cardTitle: string;
	cardBody: string;
	createdBy?: string;
	createdByLogo?: string;
}

const ReportCard = (props: Props) =>
{
	const { reportId, imgHeaderSource, cardTitle, cardBody, createdBy, createdByLogo } = props;
	const history = useHistory();

	return(
		<div className={Styles.CardContainer +" ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxl2"}>
			<div className={Styles.Card}>
				<div className={Styles.CardHeader}>
					<img
						className={Styles.CardHeaderImg}
						src={'./images/dataverse/' + imgHeaderSource}
						alt="IronIA logotipo"
						width="245px"
						height="138.991px"
					/>
				</div>
				<div className={Styles.CardBodyTitle}>{cardTitle}</div>
				<div className={Styles.CardBody}>{cardBody}</div>
				<div className={Styles.CardButtoms} onClick={() => history.push(`/dataverse/report/${reportId}`)}>
					Visualizar
				</div>
				<div className={"ms-Grid-row " + Styles.CreatedBy}>
					<div className={"ms-Grid-col ms-sm6 " + Styles.CreatedByTxt}>Creado Por:</div>
					<div className={"ms-Grid-col ms-sm6 text-right " + Styles.CreatedByValue}>
						{createdBy !== null && <>{createdBy}</>}
						{!createdBy && createdByLogo !== null && (<img className={Styles.CreatedByImg} src={'./images/dataverse/' + createdByLogo} alt="logotipo" />)}
						{!createdBy && !createdByLogo && (<img className={Styles.CreatedByImg} src={logo} alt="IronIA logotipo" />)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReportCard;
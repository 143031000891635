import React from "react";
import Styles from "./index.module.css";
import styled from "styled-components";
import { InfoIcon } from "@fluentui/react-icons-mdl2";
import { DirectionalHint, TeachingBubble } from "@fluentui/react";
import { ActiveIcon, InactiveIcon } from "../../../../components/icons/IconsTable";
import { translate } from "../../../../utils/i18n";

type Props = {
	name: string;
	okRatio?: number;
	koRatio?: number;
	totalTransfers?: number;
	theme: string;
	index: number;
	isOrigin: boolean;
};

const StyledScore = styled.div`
	svg {
		margin-right: 4px;
	}
`;

export const getScoreIcons = (okRatio: number, totalTransfers: number) =>
{
	let score = Math.ceil((okRatio - 50) / 10);

	let counter = score;
	let iconArray = [0, 0, 0, 0, 0];

	if(totalTransfers >= 100)
    {
		iconArray = iconArray.map(active =>
        {
			active = counter > 0 ? 1 : 0;
			counter--;
			return active;
		});
	}
	return iconArray;
};

const TransferManagerRiskCard = (props: Props) =>
{
	const { name, koRatio, okRatio, totalTransfers, theme, index, isOrigin } = props;
	const [tooltip, setTooltip] = React.useState(false);
	const [tooltipIroniaPoints, setTooltipIroniaPoints] = React.useState(false);
	const uniqueComponentId = (isOrigin ? "O" : "T") + "-" + index;

	const scoreIcons = getScoreIcons(okRatio ?? 0, totalTransfers ?? 0);
	return(
		<div className={Styles.CardContainer + " ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12"}>
			<div className={Styles.Card} style={{backgroundColor: theme === "dark" ? "#24282F" : "white"}}>
				<div className={Styles.ManagerName}>
					{translate(name)}{" "}
					<InfoIcon
						id={"tooltipIroniaPoints" + uniqueComponentId}
						onMouseEnter={() => setTooltipIroniaPoints(true)}
						onMouseLeave={() => setTooltipIroniaPoints(false)}
						style={{ color: "#4284e6", padding: "0px 0px 2px 2px" }}
					/>
				</div>
				<div className={Styles.ManagerScore}>
					<StyledScore>
						{scoreIcons.map((active, index) =>
							active ? <ActiveIcon key={"active-icon-" + index} /> : <InactiveIcon key={"inactive-icon-" + index} />
						)}
					</StyledScore>
				</div>
				<div className={Styles.ManagerRisk}>
					<InfoIcon id={"tooltip" + uniqueComponentId}
						onMouseEnter={() => setTooltip(true)}
						onMouseLeave={() => setTooltip(false)}
						style={{color: "#4284e6", padding: "0px 2px 2px 0px"}}
					/>{" "}
					{"Riesgo Traspasos: " + koRatio?.toFixed(2) + "%"}
				</div>
			</div>
			{tooltip === true &&
            (
				<TeachingBubble target={"#tooltip" + uniqueComponentId}
					hasCloseButton={false}
					headline=""
					onDismiss={() => setTooltip(false)}
					calloutProps={{directionalHint: DirectionalHint.topCenter}}
				>
					<div>El riesgo de traspaso muestra el % de traspasos que han tenido error del historico de traspasos realizado en nuestra plataforma.</div>
				</TeachingBubble>
			)}
			{tooltipIroniaPoints === true &&
            (
				<TeachingBubble target={"#tooltipIroniaPoints" + uniqueComponentId}
					hasCloseButton={false}
					headline=""
					onDismiss={() => setTooltipIroniaPoints(false)}
					calloutProps={{directionalHint: DirectionalHint.topCenter}}
				>
					<div>Los puntos ironia indican, en este caso, la calidad de la gestora en esta operación de traspaso.</div>
				</TeachingBubble>
			)}
		</div>
	);
};

export default TransferManagerRiskCard;
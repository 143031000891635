import styled from "styled-components";

type HighlightedPanelButtonProps =
{
    theme?: any;
};

export const HighlightedPanelButton = styled.button<HighlightedPanelButtonProps>`
    background: ${(props) => props?.theme?.palette?.highlightedAreaMain || 'rgba(66, 132, 230, 0.2)'};
    padding: 4px 14px;
    border-radius: 100px;
    box-sizing: border-box;
    height: 32px;
    color: ${(props) => props?.theme?.palette?.highlightedAreaMainText || '#4369A1'};
    border: none;
    outline: none;
    cursor: pointer;
`;

import axios from "axios";
import { proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
import { ISignature } from "./SignaturesService";
import { UserIdentityType } from "./UserIdentitiesService";

setupInterceptorsTo(axios);

export interface Donation {
  id: number,
  name: string,
  description: string,
  urlImage: string,
  amount: number,
  startDate: string,
  endDate: string
};

export interface ElementsMapItem {
  savingsPlanId: number,
  waterElements: number,
  IAElements: number
};

export interface ElementTransactionSignature {
  userIdentityId: number,
  userIdentity?: UserIdentityType,
  signatureId: number,
  signature: ISignature,
  elementTransactionId: number
}

class DonationsService {
  public getCurrentDonation = async (): Promise<Donation> => {
      let url: string = `${proxyUrl}/donations`;
      const req = await axios.get(url);
      return req.data;
  }

  public CreateSignatures = async (incrementData: ElementsMapItem[]): Promise<ElementTransactionSignature[]> =>
  {
    let url: string = `${proxyUrl}/donations/signatures`
    const req = await axios.post(url, incrementData);
    return req.data;
  }

  public IncrementDonationAmount = async (incrementData: ElementsMapItem[], elementTransactionSignatures: ElementTransactionSignature[]): Promise<ElementsMapItem[]> =>
  {
    let url: string = `${proxyUrl}/donations/add`
    const req = await axios.post(url, {incrementData, elementTransactionSignatures});
    return req.data;
  }
}

const donations = new DonationsService();
export default donations;
import { DefaultButton } from '@fluentui/react';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

export const Error = styled.span`
    width: fit-content;
    margin: 0;
    color: hsl(0, 100%, 63%);
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
`;

export const StyledModal = styled.div`
	color: ${(props) => props.theme.palette.black};

	.name {
		color: ${(props) => props.theme.palette.black};
	}
`;

export const HelperStyle = createGlobalStyle`
    *,
    ::after,
    ::before
    {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    ul
    {
        list-style-type: none;
    }
    a
    {
        text-decoration: none;
    }
`;

export const Cont = styled.div`
  grid-area: main;
  background-color: #fafcfd;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
`;

export const MainCont = styled.div`
  padding: 40px 55px;

  @media screen and (max-width: 599px) {
    padding: 40px 25px;
  }
`;

export const Title = styled.div`
  margin-top: 30px;

  & h1 {
    font-size: 30px;
    color: #2a4143;
    font-weight: 800;
  }

  & h2 {
    font-size: 24px;
    font-weight: 400;
    width: 100%;
    max-width: 900px;
    color: #5f7172;
    text-align: justify;
  }
`;

export const TabletCont = styled.div`
  background-color: #f3f7f9;
  width: 100%;
  padding: 35px 25px 30px 25px;
  border-radius: 20px;
  margin-top: 60px;
  max-height: 400px;
  overflow: auto;

  & hr {
    margin: 40px 0;
    border: solid 1px #e9eff2;
  }
`;

export const TabletTitle = styled.h3`
  color: #2a4143;
  font-size: 20px;
  font-weight: 800;
`;

export const SubTitle = styled.h4`
  color: #2a4143;
  font-size: 17px;
  font-weight: 600;

  @media screen and (max-width: 599px) {
    margin: 20px 0;
  }
`;

export const Phrase = styled.p`
  color: #556769;
  font-size: 15px;
  font-weight: 500;
  margin: 20px 0;
  line-height: 27px;

  @media screen and (max-width: 599px) {
    text-align: justify;
  }
`;

export const BtnCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 80px;

  @media screen and (max-width: 599px) {
    justify-content: center;
    flex-flow: row wrap;
    margin-top: 40px;

    & a {
      margin: 0 10px;
    }

    & button {
      margin-bottom: 20px;
    }
  }
`;
export const LinkBtn = styled(Link)`
  display: block;
  width: fit-content;
  margin-left: 20px;
`;

export const ContinueBtn = styled(DefaultButton)`
	root: {
        display: 'block',
        border: 'none',
        borderRadius: '30px !important',
        width: '225px',
        height: '55px',
        marginLeft: '20px',
        backgroundColor: '#cc214f',
    },

    rootPressed: {
        backgroundColor: '#cc214f',
        color: '#ffffff',
    },

    rootHovered: {
        backgroundColor: '#cc214f',
        color: '#ffffff',
    },

    textContainer: {
        color: '#ffffff',
        fontFamily: 'inherit',
        fontSize: '14px',
    },

    '@media(max-width: 599px)': {
        marginLeft: 0,
    },
`;
import React from "react";
import { getCurrencies } from '../../../services/currencies';
import { getSubcategories } from '../../../services/subcategories';
import { DivClassNo, DivClassYes, DivContainerToggle, FilterContainer, FilterLabel, FirstH4Tooltip, SecondH4Tooltip, ToggleContainer, ToggleTitle } from "./SearchManagersStyle";
import { DirectionalHint, TeachingBubble } from '@fluentui/react';
import CustomDropdownCategory from "../../dropdown/CustomDropdownCategory";
import ThreeWayToggle from "../../ThreeWayToggle/ThreeWayToggle";

interface IFilterManagers
{
    selectedFilters: any,
    onChange: (v: any, filterName: string) => any,
}

const FilterManagers = (props: IFilterManagers) =>
{
    const [loading, setLoading] = React.useState<boolean>(false);
    const [tooltipClassToggle, setTooltipClassToggle] = React.useState<boolean>(false);
    const [dropDownData, setDropdownData] = React.useState(
    {
        currencies: [],
        subcategories:
        [{
            morningStarCategoryId: "",
            name: ""
        }]
    });

    React.useEffect(() =>
    {
        async function populateFilters()
        {
            setLoading(true);
            let currencies = await getCurrencies();
            let subcategories = await getSubcategories();

            const convertToItem = (value: any, index: number, array: any) =>
            {
                array[index] =
                {
                    key: value.name || value,
                    label: value.name || value,
                    value: value
                };
            };

            currencies.forEach(convertToItem);
            subcategories.forEach(convertToItem);

            setDropdownData(currentDropdownData => ({ ...currentDropdownData, currencies, subcategories }));
            setLoading(false);
        }

        populateFilters();
    }, []);

    return(
        <React.Fragment>
            <FilterContainer>
                <CustomDropdownCategory options={dropDownData.subcategories} title={"Categorías del fondo"}
                    selectedValues={props.selectedFilters.subcategory}
                    isLoading={loading}
                    onSave={(value: any) =>
                    {
                        let values = value.map((value: any) =>
                        {
                            return value;
                        });
                        
                        props.onChange(values, "subcategories");
                    }}
                />
                {props.selectedFilters && props.selectedFilters.subcategory && props.selectedFilters.subcategory.length > 0 && props.selectedFilters.subcategory.map((filter: any, filterIndex: number) =>
                {
                    return <FilterLabel key={"filterIndex"+filterIndex}>{filter.name}</FilterLabel>;
                })}
            </FilterContainer>
            <FilterContainer>
                <CustomDropdownCategory options={dropDownData.currencies} title={"Divisa"}
                    isLoading={loading}
                    selectedValues={props.selectedFilters.currency}
                    onSave={(value: any) =>
                    {
                        let values = value.map((value: any) =>
                        {
                            value = value.value;
                            return value;
                        });

                        props.onChange(values, "currency");
                    }}
                />
                {props.selectedFilters && props.selectedFilters.currency && props.selectedFilters.currency.length > 0 && props.selectedFilters.currency.map((filter: any, filterIndex: number) =>
                {
                    return <FilterLabel key={"filterIndex" + filterIndex}>{filter}</FilterLabel>;
                })}
            </FilterContainer>
            <FilterContainer>
                <ToggleContainer>
                    <ToggleTitle>Disponibilidad</ToggleTitle>
                    <ThreeWayToggle onChange={(value: any) => props.onChange(value, "available")} disableMiddlePosition={false} toggleInitialState={0} />
                 </ToggleContainer>
            </FilterContainer>
            <FilterContainer>
                 <ToggleContainer>
                    <ToggleTitle>Dividendos</ToggleTitle>
                    <ThreeWayToggle onChange={(value: any) => props.onChange(value, "income")} disableMiddlePosition={false} toggleInitialState={0} />
                 </ToggleContainer>
            </FilterContainer>
            <FilterContainer>
                 <ToggleContainer>
                    <ToggleTitle>Traspasable</ToggleTitle>
                    <ThreeWayToggle onChange={(value: any) => props.onChange(value, "switchable")} disableMiddlePosition={false} toggleInitialState={0} />
                 </ToggleContainer>
            </FilterContainer>
            <FilterContainer>
                 <ToggleContainer>
                    <ToggleTitle>Indexado</ToggleTitle>
                    <ThreeWayToggle onChange={(value: any) => props.onChange(value, "indexed")} disableMiddlePosition={false} toggleInitialState={0} />
                 </ToggleContainer>
            </FilterContainer>
            {tooltipClassToggle === true &&
            (
                <TeachingBubble target="#tooltipClassToggle" hasCloseButton={false} headline="Filtro de Clases" onDismiss={() => setTooltipClassToggle(false)} calloutProps={{directionalHint: DirectionalHint.topCenter}}>
                    <FirstH4Tooltip>
                        Clases: SÍ
                    </FirstH4Tooltip>
                    <DivClassYes>
                        Devuelve todas las clases de un fondo si una clase cumple los criterios de búsqueda.
                    </DivClassYes>
                    <SecondH4Tooltip>
                        Clases: NO
                    </SecondH4Tooltip>
                    <DivClassNo>
                        Devuelve solo las clases que cumplen los criterios de búsqueda, pueden existir clases más baratas que las buscadas.
                    </DivClassNo>
                </TeachingBubble>
            )}
            <FilterContainer onMouseEnter={() => setTooltipClassToggle(true)} onMouseLeave={() => setTooltipClassToggle(false)} id="tooltipClassToggle">
                <DivContainerToggle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                    <ToggleContainer>
                        <ToggleTitle>Clases</ToggleTitle>
                        <ThreeWayToggle toggleInitialState={1} disableMiddlePosition={true} onChange={(value: any) => props.onChange(value, "classCategory")} />
                    </ToggleContainer>
                </DivContainerToggle>
            </FilterContainer>
        </React.Fragment>
    );
}

export default FilterManagers;
import {
  DefaultButton,
  FontWeights,
  getTheme,
  IButtonStyles,
  ILabelStyles,
  Label,
  mergeStyleSets,
  Modal,
  Stack,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import * as React from "react";

import styled from "styled-components";

const theme = getTheme();
const RenameBtn = styled.button`
  border: 0px;
  width: 100%;
  height: 50%;
  padding: 0px;
`;
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    borderRadius: "2em",
    minWidth: "500px !important",
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      color: "#CC214F",
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    textAlign: "center",
    flex: "4 4 auto",
    padding: "0 24px 12px 24px",
    overflowY: "hidden",
    selectors: {
      p: {
        margin: "0",
      },
      "p:first-child": {
        marginTop: 0,
      },
      "p:last-child": {
        marginBottom: 0,
      },
    },
  },
});
const styleLabel: Partial<ILabelStyles> = {
  root: {
    fontSize: "19px !important",
    padding: "0 45px",
    margin: "0px",
    textAlign: "center",
    width: "100%",
  },
};
const styleDefButton1: Partial<IButtonStyles> = {
  root: {
    marginLeft: "0",
    marginTop: "1em",
    cursor: "pointer",
    background: "#CC214F",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#FFFFFF",
    borderRadius: "100px",
    padding: "0.5em 1.5em 0.5em 1.5em",
    borderStyle: "none",
  },
};
const styleDefButton2: Partial<IButtonStyles> = {
  root: {
    marginLeft: "0",
    marginTop: "1em",
    cursor: "pointer",
    background: "#CC214F",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#FFFFFF",
    borderRadius: "100px",
    padding: "0.5em 1.5em 0.5em 1.6em",
    borderStyle: "none",
  },
};

type Props = {
  title?: string;
  body?: string | JSX.Element | JSX.Element[];
  btnSubmitText?: string;
  btnSubmitDisabled?: boolean;
  onSubmit?: any;
  onClose?: any;
  size?: "sm" | "lg" | "xl";
  showModal: boolean;
};

export const ErrorModal: React.FunctionComponent<
  Props
> = (props: Props) => {
  const { body, title, btnSubmitText, showModal, btnSubmitDisabled, size } =
    props;
  const [show, setShow] = React.useState(false);

  const handleClose = () => {
    setShow(false);
    if (props.onClose !== undefined) {
      props.onClose();
    }
  };

  React.useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const onSubmit = async () => {
    handleClose();
    if (props.onSubmit !== undefined) {
      props.onSubmit();
    }
  };

  return (
    <React.Fragment>
      <Modal isOpen={showModal} onDismiss={handleClose} >
        <div style={{padding: "10px"}}>
          <h3 className="text-center"><b>{title}</b></h3>
        </div>
        <p>{body}</p>
        <Stack.Item style={{ display: "flex", justifyContent: "center"}}>
          <DefaultButton styles={styleDefButton2} onClick={onSubmit}>
            Aceptar
          </DefaultButton>
        </Stack.Item>
      </Modal>
    </React.Fragment>
  );
};

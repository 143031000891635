import { useState, useEffect, useContext } from "react";
import { CategoriesWithRatiosResponse, getSubcategoriesWithRatios } from "../../services/subcategories";
import { Dropdown, IDropdownOption, Spinner } from "@fluentui/react";
import { ThemeContext } from "@fluentui/react-theme-provider";
import TableCategory from "./TableCategory";
import styled from "styled-components";
import * as L from '../../components/Instruments/layout.styles';
import { dropdownStyles } from "../../components/dropdown/dropdownStyles";

const CategorySearch = (setPivotItem: any) =>
{
    const theme = useContext(ThemeContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [categoriesResponse, setCategoriesResponse] = useState<CategoriesWithRatiosResponse>();
    const [selectedRatioKey, setSelectedRatioKey] = useState<string>("linear_return");
    var options: IDropdownOption[] =
    [
        { key: "linear_return", id: "linear_return", text: "Rentabilidad" },
        { key: "correlation", id: "correlation", text: "Correlación" },
        { key: "alpha", id: "alpha", text: "Alpha" },
        { key: "beta", id: "beta", text: "Beta" },
        { key: "bear_beta", id: "bear_beta", text: "Bear Beta" },
        { key: "bull_beta", id: "bull_beta", text: "Bull Beta" },
        { key: "volatility", id: "volatility", text: "Volatilidad" },
        { key: "sortino", id: "sortino", text: "Sortino" },
        { key: "tracking_error", id: "tracking_error", text: "Tracking Error" },
        { key: "information_ratio", id: "information_ratio", text: "Information Ratio" },
        { key: "calmar", id: "calmar", text: "Calmar" },
        { key: "treynor", id: "treynor", text: "Treynor" },
        { key: "time_to_recovery", id: "time_to_recovery", text: "Tiempo de Recuperación" },
        { key: "downside_deviation", id: "downside_deviation", text: "Downside Deviation" },
        { key: "max_drawdown", id: "max_drawdown", text: "Máxima Caída" },
        { key: "omega", id: "omega", text: "Omega" },
        { key: "sharpe", id: "sharpe", text: "Sharpe" },
        { key: "log_annualized_return", id: "log_annualized_return", text: "Rentabilidad Logarítmica Ann" }
    ];

    useEffect(() =>
    {
        const fetchSubcategories = () =>
        {
            setLoading(true);
            getSubcategoriesWithRatios(selectedRatioKey).then(response =>
            {
                setCategoriesResponse(response);
            })
            .finally(() =>
            {
                setLoading(false);
            });
        };

        fetchSubcategories();
    }, [selectedRatioKey]);

    const updateSelectedRatio = (item: IDropdownOption | undefined) =>
    {
        if(item)
        {
            setSelectedRatioKey(item.key.toString());
        }
    };

    return(
        <L.TableWrapper theme={theme}>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6">
                    <L.Title>Buscador de categorías</L.Title>
                </div>
            </div>
            <BoxWrapper>
                <div style={{display: "flex", justifyContent: "right"}}>
                    <Dropdown placeholder={"Selecciona un tipo de ratio"}
                        className="ms-Grid-col ms-sm3"
                        options={options as IDropdownOption[]}
                        selectedKey={selectedRatioKey}
                        onChange={(event, item) => updateSelectedRatio(item)}
                        styles={dropdownStyles}
                    />
                </div>
                <br />
                {loading ? (<Spinner size={3} />) :
                (
                    <TableContainer theme={theme}>
                        {categoriesResponse &&
                        (
                            <TableCategory categories={categoriesResponse?.categories}
                                subCategories={categoriesResponse?.subCategories}
                                selectedRatioKey={selectedRatioKey}
                            />
                        )}
                    </TableContainer>
                )}
            </BoxWrapper>
        </L.TableWrapper>
    );
};

export default CategorySearch;

const BoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 30px;
    box-shadow: 0px 0px 16px rgba(8, 84, 117, 0.08);
    border-radius: 16px;
    min-height: 500px;
`;
const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: auto;
    background: ${(props) => props.theme.palette.white};
    table
    {
        border-radius: 16px;
    }
    thead
    {
        background: ${(props) => props.theme.palette.tertiary};
    }
    > div
    {
        margin: 0;
    }
    .is-selected
    {
        a, div
        {
            color: white !important;
        }
    }
`;
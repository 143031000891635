import { DefaultButton, Separator } from "@fluentui/react";
import styled, { keyframes } from "styled-components";
import { Link } from 'react-router-dom';
import { createGlobalStyle } from "styled-components";
import { Button } from "../../components/buttons/MainButton";

export const TitleCard = styled.div`
    padding-left: 1em;
    padding-top: 1em;
`;
export const ContainerCard = styled.div`
    min-height: 6em;
    background-color: ${props => props?.theme?.palette?.tertiary || "#e9eff1"};
    border-radius: 10px;
    width: 100%;
    margin-left: 10px;
    line-height: 16px;
    box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
`;
export const IAContainerCard = styled.div`
    min-height: 6em;
    background-color: ${props => props?.theme?.palette?.tertiary || "#e9eff1"};
    border-radius: 10px;
    width: 100%;
    line-height: 16px;
    box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
`;
export const DataCard = styled.div`
    padding-top: 1.3em;
    font-size: 20px;
    float: right;
    padding-right: 8px;
    display: flex;
    width: 80%;
`;
export const DataCardContract = styled.div`
    padding-top: 0.65em;
    font-size: 20px;
    float: right;
    padding-right: 5px;
    display: flex;
    width: 100%;
`;
export const DataCardIA = styled.div`
    padding-top: 0.75em;
    font-size: 20px;
    float: right;
    padding-right: 5px;
    display: flex;
    width: 100%;
`;
export const TitleText = styled.h5`
    margin: 0;
    font-weight: 600;
    font-size: 1em;
    cursor: default;
    color: ${props => props?.theme?.palette?.primary};
`;
export const DataText = styled.h5`
    padding-left: 1em;
    margin: 0 !important;
    width: 100%;
    text-align: end;
    font-size: 0.75em;
`;
export const DataTextContract = styled.h5`
    width: 100%;
    padding-top: 0.5em;
    margin: 0 !important;
    text-align: end;
    font-size: 1em;
    cursor: default;
`;
export const FilterContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 1em;
    padding: 0 5px 0 0;

    > div span
    {
        border-radius: 15px;
    }
`;
export const ToggleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.palette.white};
    padding: 10px 12px;
    border-radius: 15px;
    border: 1px solid rgb(188, 200, 201);
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    color: ${(props) => props.theme.palette.lightBlack} !important;
`;
export const Shadow = styled.div`
    border-radius: 16px;
    padding: 48px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 20px;
    min-height: 700px;
    max-height: 700px;
    background-color: ${props => props?.theme?.palette?.white};
    box-shadow: 0px 0px 16px rgb(8 84 117 / 8%);
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
`;
export const ShadowMargin10 = styled.div`
    border-radius: 16px;
    padding: 48px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    min-height: 370px;
    background-color: ${props => props?.theme?.palette?.white};
    box-shadow: 0px 0px 16px rgb(8 84 117 / 8%);
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
`;
export const ShadowActivePromotions = styled.div`
    border-radius: 16px;
    padding: 48px 48px 0px 48px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    min-height: 370px;
    background-color: ${props => props?.theme?.palette?.white};
    box-shadow: 0px 0px 16px rgb(8 84 117 / 8%);
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
`;
export const SubscribeButton = styled(DefaultButton)`
    background-color: #CC214F;
    color: white;
`;
export const DisabledButton = styled(DefaultButton)`
    background-color: grey;
    color: white;
`;
export const InfoSellCard = styled.div`
    background: #c0ced6;
    border-radius: 12px;
    color: black;
    padding: 1em;
    margin-bottom: 1em;
    line-height: 24px;
    display: inline-block;
    width: calc(100% - 30px);

    .ms-Grid-col
    {
        padding-left: 30px;
        img
        {
            margin-right: 10px;
        }
        h4
        {
            margin: 0;
            display: inline-block;
        }
        &:first-child
        {
            padding-left: 0;
        }
        button
        {
            margin-top: 14px;
        }
    }
    &:last-child
    {
        margin-bottom: 0;
    }
    .childrens
    {
        margin-top: 10px;
        padding-left: 0;
        .children
        {
            padding-top: 20px;
            margin-bottom: 10px;
            border-top: 1px solid white;
            padding-left: 30px;

            &:last-child
            {
                border-bottom: 1px solid white;
                padding-bottom: 10px;
            }
        }
    }
`;
export const ContractPanel = styled.div`
    background-color: ${props => props?.theme?.palette?.contractElementPanel};
    color: ${props => props?.theme?.palette?.black};
    width: 99%;
    padding: 1em 1em 1em 1em;
    border-radius: 10px;
    margin-top: 1em;
    max-height: 400px;
    overflow: auto;
    & hr
    {
        margin: 40px 0;
        border: solid 1px #e9eff2;
    }
`;
export const SignatureButton = styled(DefaultButton)``;
export const ContainerSignature = styled.div`
    grid-area: main;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
`;
export const HelperStyle = createGlobalStyle`
    *,
    ::after,
    ::before
    {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    } 
    ul
    {
        list-style-type: none;
    }
    a
    {
        text-decoration: none;
    }
`;
export const MainContainerSignature = styled.div`
    padding: 40px 55px;
`;
export const ModalMainCont = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 430px;
    border-radius: 15px;
    background-color: #ffffff;
    z-index: 150;
    padding: 40px;
    & hr
    {
        margin: 25px 0 25px 0;
        border: solid 1px #E9EFF2;
    }
`;
export const ModalIAMainCont = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 430px;
    border-radius: 15px;
    background-color: #ffffff;
    z-index: 150;
    padding: 2.5rem;
    font-size: 18px;
`;
export const ModalFocus = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${(props) => // @ts-ignore
            props.height};
    background-color: #2a414380;
    z-index: 100;
`;
export const ModalToExitCont = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;
export const ModalToExit = styled.div`
    position: relative;
    & i
    {
        &:hover
        {
            cursor: pointer;
        }
    }
`;
export const ModalContinue = styled.h3`
    font-size: 20px !important;
    font-weight: 800;
    color: #2A4143;
    margin-top: -20px !important;
    font-weight: 700 !important;
    word-spacing: -1px;
`;
export const ModalNeedsCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    &p
    {
        font-size: 15px;
        font-weight: 500;
        color: #6a7a7c;
        margin-bottom: 25px;
    }
`;
export const ModalCompletedIcon = styled.img`
	display: block;
	width: 50px;
	height: 50px;
    margin-bottom: 40px;
    margin-top: -20px;
`;
export const ModalBtnCont = styled.div`
    display: flex;
    float: right;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;
`;
export const ModalAcceptBtn = styled.button`
    display: block;
    border: none;
    border-radius: 20px;
    width: 160px;
    height: 45px;
    background-color: #cc214f;
    font-family: inherit;
    color: #ffffff;
    font-weight: 500;
    &:hover
    {
        cursor: pointer;
    }
`;
export const ModalLinkBtn = styled(Link)`
    display: block;
    width: fit-content;
`;
export const DivColWaterItem = styled.div``;
export const DivColFireItem = styled.div``;
export const DivColEarthItem = styled.div``;
export const DivColAirItem = styled.div``;
export const DivColBuyItem = styled.div`
    @media (min-width: 320px) and (max-width: 1365.99px)
    {
        padding-left: 0.75em;
    }
`;
export const DivColEmpty = styled.div``;
export const DivRowButtonIA = styled.div``;
export const DivColIAItem = styled.div``;
export const DivColButtonIA = styled.div`
    padding-left: 1em;
    padding-top: 0.5em;
`;
export const TitleIAText = styled.h4`
    cursor: default;
    margin: 0 !important;
    padding-left: 0.5em;
`;
export const LogoIA = styled.img`
    padding-top: 0.25em;
`;
export const DivColLogoIA = styled.div`
    margin-top: 0.5em;
    text-align: end;
`;
export const DataIAText = styled.h5`
    width: 100%;
    margin: 0 !important;
    text-align: end;
    font-size: 1em;
    cursor: default; 
`;
export const DivColTitle = styled.div``;
export const ButtonBuyElements = styled.button`
    border: 0px;
    border-radius: 24px;
    background-color: #CC214F;
    color: white;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 1px;
    cursor: pointer;
`;

//---------------ELEMENTS IA------------------------//
export const MainTitle = styled.h2`
    cursor: default;
    margin-top: 0;
    margin-bottom: 0.5em;
`;
export const MainDescription = styled.p`
    margin: 0;
    color: #556769;
    cursor: default;
`;
export const MainElementsDescription = styled.p`
    margin: 0;
    cursor: default;
`;
export const RowBody = styled.div`
    margin-bottom: 3em;
`;
export const RowWaterElements = styled.div`
    margin-bottom: 1em;
`;
export const ColImageWaterIcon = styled.div`
    text-align: center;
    @media (min-width: 480px) and (max-width: 639.99px)
    {
        margin-top: 4px;
    }
    @media (min-width: 640px) and (max-width: 1023.99px)
    {
        margin-top: 10px;
    }
    @media (min-width: 1024px) and (max-width: 1365.99px)
    {
        margin-top: 8px;
    }
`;
export const DivSMAuxiliarRow = styled.div`
`;
export const DivSMAuxiliarRow2 = styled.div`
    padding-top: 1em;
`;
export const ColElementsDescription = styled.div`
    margin-top: 6px;

    @media (min-width: 320px) and (max-width: 479.99px)
    {
        margin-top: 0;
    }
`;
export const ElementsWater = styled.p`
    margin: 0;
    cursor: default;
`;
export const ColElementsInput = styled.div`
    text-align: center;
`;
export const InputWaterElements = styled.input`
    width: 75%;
    height: 25px;
    border: 1px solid black;
    text-align: end;
    border-color: #CC214F;
`;
export const ColElementsUsingButton = styled.div``;
export const ButtonUseElements = styled.button`
    border: 0px;
    border-radius: 24px;
    background-color: #CC214F;
    color: white;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 1px;
    cursor: pointer;

    @media (min-width: 320px) and (max-width: 479.99px)
    {
        width: 100%;
    }
`;
export const ColRestoreElements = styled.div`
`;
export const ButtonRestoreElements = styled.button`
    border: 0px;
    border-radius: 24px;
    background-color: #CC214F;
    color: white;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 1px;
    cursor: pointer;

    @media (min-width: 320px) and (max-width: 479.99px)
    {
        width: 80%;
    }
`;
export const ColDataOrder = styled.div``;
export const DivCardOrder = styled.div`
    border-radius: 1.5em !important;
    background: #F3F7F9 !important;
    padding: 1em 1em 2em 2em;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #556769;
`;
export const DlOrder = styled.dl`
    margin: 0 !important;
`;
export const DtOrder = styled.dt``;
export const DdOrder = styled.dd``;
export const UlOrder = styled.ul``;
export const LiOrder = styled.li``;
export const DtReference = styled.dt``;
export const DdReference = styled.dd``;
export const DtImporte = styled.dt``;
export const DdImporte = styled.dd``;
export const ColPuntual = styled.div``;
export const Form = styled.form`
    border: 1px solid rgba(147,174,185,0.2);
    min-height: 14.5em;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;
export const DivPayment = styled.div`
    margin: 0;
    padding: 0 0 0 1em;
    border-radius: 0.75em;
`;
export const TabContainer = styled.div`
    margin-left: -15px;
    top: -12px;
    position: relative;
    border-color: rgba(147, 174, 185, 0.2);
    border-width: 1px 1px 0 1px;
    border-radius: 12px 12px 0 0;
    padding: 10px 20px 10px 0;
    cursor: default;
    border-bottom: 1px solid rgba(147, 174, 185, 0.5)
    &:hover:not(.active)
    {
        background: rgba(147, 174, 185, 0.2)
    }
    i
    {
        margin-right: 10px
    }
`;
export const ButtonTab = styled.button``;
export const DivRowForm = styled.div``;
export const DivColDisclaimer = styled.div`
    padding-left: 1em;
    padding-top: 1em;
    padding-bottom: 2em;
`;
export const DivColImageIA = styled.div`
    text-align: center;

    @media (min-width: 320px) and (max-width: 1249.99px)
    {
        padding-top: 0.5em;
    }
`;
export const ImageIA = styled.img`
`;
export const DivColText = styled.div`
`;
export const InfoText = styled.p`
    margin: 0;
    padding-left: 0;
    padding-bottom: 1em;
    cursor: default;
    text-align: justify;
`;
export const DivColBuyElements = styled.div`
    padding-left: 1em;
    padding-top: 1em;
`;
export const InputSubmitElements = styled.input`
    width: 100%;
    height: 25px;
    border: 1px solid black;
    text-align: end;
    border-color: #CC214F;
`;
export const DivColButtonSubmit = styled.div`
    text-align: end;
    padding-top: 1em;
`;
export const WarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    cursor: default;
    background: ${(props) => props.theme.palette.warnSecondary};
    color: ${(props) => props.theme.palette.warn};
    padding: 1em;
    text-align: justify;
    gap: 10px;
    font-weight: 500;
    h3
    {
        margin: 0;
    }
    p
    {
        line-height: 20px;
        margin: 0;
    }
`;
export const WarningText = styled.p`
    margin: 0 !important;
    font-size: 13px !important;
    line-height: 15px !important;
`;
export const DivRowButtonSubmit = styled.div``;
export const DivSpinner = styled.div``;
export const DivTextButton = styled.div``;
export const DivTextButtonNoSpinner = styled.div`
    padding: 0;
`;
export const ButtonSubmit = styled.button`
    display: inline-block;
    background: #CC214F;
    box-shadow: 0px 0px 16px rgba(8,84,117,0.08);
    color: #FFFFFF;
    font-family: Barlow;
    font-weight: 600 !important;
    padding: 3px 20px !important;
    max-width: 100%;
    box-sizing: content-box;
    border: 2px solid #CC214F;
    border-radius: 100px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
`;
export const DivProcessPayment = styled.div`
    padding-left: 1em;
    padding-top: 2.5em;
    padding-bottom: 0.5em;
    text-align: end;
`;
export const ProcessPayment = styled.p`
    margin: 0;
    cursor: default;
    font-size: 11px;
`;
export const ErrorBarCol = styled.div``;
export const ErrorText = styled.p`
    margin: 0;
    font-size: 18px;
    color: red;
    padding-left: 1em;
    cursor: default;
`;
export const RowInfo = styled.div`
    padding-top: 1em;
`;
export const ColInfoTitle = styled.div``;
export const InfoTitle = styled.h1`
    cursor: default;
    margin-top: 0;
    margin-bottom: 0;
    color: ${props => props.theme.palette.black};
`;
export const ColInfoSubTitle = styled.div``;
export const InfoSubTitle = styled.h4`
    cursor: default;
    color: ${props => props.theme.palette.black};
    font-weight: 350;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;
export const ColCards = styled.div``;
export const RowInsideCards = styled.div``;
export const ColFirstCard = styled.div`
    padding-bottom: 1em;
`;
export const ColSecondCard = styled.div`
    padding-bottom: 1em;
`;
export const ColThirdCard = styled.div`
    padding-bottom: 1em;
`;
export const Card = styled.div`
    cursor: default;
    background: rgb(255, 255, 255);
    box-shadow: 0 0 5px 3px rgb(0 0 0 / 8%);
    border-radius: 16px;
    font-family: Barlow;
    position: relative;
    width: 100%;
    height: auto;
    display: inline-block;
    overflow: hidden;
    padding-bottom: 1em;
    @media (min-width: 1366px)
    {
        min-height: 315px;  
    }
`;
export const CardTitle = styled.div`
    width: 100%;
    padding: 1em 0 1em 0;
    color: ${(props) => props.theme.palette.black};
    background: ${(props) => props.theme.palette.cardFooter};
`;
export const TitleCardText = styled.p`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    padding-left: 1em;
    padding-right: 0.75em;
    text-overflow: ellipsis;
    overflow-x: hidden;
`;
export const CardBody = styled.div`
    padding-top: 0.5em;
`;
export const BodyCardText = styled.p`
    margin: 0;
    cursor: default;
    padding-left: 1em;
    padding-right: 1.5em;
    text-align: justify;
    color: ${props => props.theme.palette.allBlack};
`;
export const RowButtonGetMoney = styled.div`
    padding-left: 1em;
    @media (min-width: 640px)
    {
        padding-right: 1em;
    }
`;
export const ColButtonTextGetMoney = styled.div``;
export const ColButtonGetMoney = styled.div``;
export const TextGetMoney = styled.p`
    color: ${props => props.theme.palette.allBlack};
    margin-top: 1.5em;
`;
export const ButtonGetMoney = styled.button`
    border: 1px solid #CC214F;
    border-radius: 20px;
    background-color: #CC214F;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    padding: 0.75em 1em 0.75em 1em;
    width: 100%;
`;
export const RowContract = styled.div`
    padding-top: 1em;
    padding-left: 1em;
    @media (min-width: 640px)
    {
        padding-right: 1em;
    }
`;
export const ColContractText = styled.div``;
export const ContractText = styled.p`
    padding-right: 0.5em;
    margin-top: 0;
    margin-bottom: 0;
    color: ${props => props.theme.palette.allBlack};
`;
export const ColContractButton = styled.div`
    text-align: end;
    padding-right: 0.5em;
    padding-top: 0.5em;
`;
export const ButtonContract = styled.button`
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    background-color: #E0E0E0;
    color: black;
    cursor: not-allowed;
    padding: 0.75em 1em 0.75em 1em;
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
`;

///ElementIATransfer

export const IATransferMain = styled.main`
    display: flex;
    flex-direction: column;
    padding-bottom: 2em;
    width: 100%;
    margin: 0;

    .button-spinner
    {
        float: left;
        margin-right: 10px;
    }
    & .restarted-ms-Grid > .ms-Grid-row
    {
        margin: 0 -24px !important;

        & > .ms-Grid-col
        {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
    & .ms-Stack .ms-StackItem
    {
        width: 100%;
    }
    & .ms-Stack .ms-StackItem,
    & .investment-fund-finder .ms-ComboBox-container
    {
        margin-top: 1px;
    }
    & .ms-Stack .ms-StackItem:first-child, .investment-fund-finder .ms-ComboBox-container:first-child
    {
        margin-top: 0;
    }
    & .ms-Stack .ms-StackItem:not(:first-child)
    {
        margin-left: 0;
    }
    & .ms-ChoiceField
    {
        display: inline-flex;
        margin-right: 8px;
        background: ${props => props?.theme?.palette?.white || "#FFF"};
        border: 1px solid ${props => props?.theme?.palette?.neutralLighter || "#E9EFF1"};
        padding: 10px 16px;
        border-radius: 26px;
    }
`;
export const IASeparatorTop = styled(Separator)`
    margin-bottom: 1.5em;
`;
export const IASeparatorForm = styled(Separator)`
    margin-top: 17px;
    margin-bottom: 17px;
`;
export const IATitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: default;
    h2
    {
        margin: 0;
        cursor: default;
    }
`;
export const IACard = styled.div`
    cursor: default;
    background: rgb(255, 255, 255);
    box-shadow: 0 0 5px 3px rgb(0 0 0 / 8%);
    border-radius: 16px;
    font-family: Barlow;
    position: relative;
    width: 100%;
    height: auto;
    display: inline-block;
    overflow: hidden;
    padding-bottom: 1em;
    @media (max-width: 1669.50px)
    {
        min-height: 360px;
    }
`;
export const IACardContainer = styled.div`
    background-color: ${props => props?.theme?.palette?.tertiary || "#F3F7F9"};
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 25px 30px 25px 30px;
    height: auto;
`;
export const IACardHeader = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 0;
    justify-content: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    h3
    {
        margin: 0;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
    }

    .small-text
    {
        margin-top: 6px;
    }
`;
export const IATitleCardText = styled.p`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    padding-left: 1em;
    padding-right: 0.75em;
    text-overflow: ellipsis;
    overflow-x: hidden;
    text-align: center;
`;
export const IACardHeadingContainer = styled.div`
    cursor: default;
`;
export const IACardBody = styled.div`
    margin-top: 0;
    padding-top: 0.5em;
    padding-left: 1em;
    padding-right: 1.5em;
    text-align: justify;
`;
export const IAText = styled.p`
    font-size: 18px;
    line-height: 24px;
    cursor: default;
    color: ${props => props?.theme?.palette?.lightBlack};
    margin-top: 0;
    margin-bottom: 0;
`;
export const IATextSmall = styled.p`
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin: 0;
    cursor: default;
    margin-top: 2em;
    color: ${props => props?.theme?.palette?.neutralText || "#95A0A1"};
`;
export const IAContainerButton = styled.div`
    text-align: end;
`;
export const IACardButton = styled(Button.Primary)`
    margin-top: 1.5em;
    margin-bottom: 0px;
    padding: 0.75rem 2rem !important;
`;
export const IADisabledCardButton = styled(Button.Primary)`
    margin-top: 1.5em;
    margin-bottom: 0px;
    padding: 0.75rem 2rem !important;
    background-color: #556769 !important;
    cursor: not-allowed !important;
`;
export const IACardTitle = styled.div`
    width: 100%;
    padding: 1em 0 1em 0;
    color: ${(props) => props.theme.palette.black};
    background: ${(props) => props.theme.palette.cardFooter};
`;
export const IATransferForm = styled.div``;
export const IAInfoForm = styled.div`
    @media (max-width: 1023.50px)
    {
        padding-top: 1em;
    }
`;
export const IASignatureForm = styled.div`
    padding-top: 2em;
    padding-left: 2em;
    padding-right: 2em;
`;
const changeColor = keyframes`
    0%
    {
        color: black;
    }
    50%
    {
        color: #CC214F;
    }
    100%
    {
        color: black;
    }
`;
export const IAProcessingLoadingText = styled.p`
    cursor: default;
    padding-left: 1em;
    padding-top: 1em;
    font-size: 15px;
    font-weight: 700;
    animation: ${changeColor} 1.5s infinite;
`;
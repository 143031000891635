import { Table } from "../../../components";
import { SubTableInternal, SubTableInternalProps } from "./SubTableInternal";
import styled from "styled-components";
import DropbdownZone from "../DropbdownZone";

const data: SubTableInternalProps[] = [
    {
        name: "Eurizon Fund - Italian Equity Opportunites",
        gestora: "Eurizon Capital S.A.",
        category: "RV Italia",
    },
    {
        name: "Eurizon Fund - Italian Equity Opportunites",
        gestora: "Eurizon Capital S.A.",
        category: "RV Italia",
    },
    {
        name: "Eurizon Fund - Italian Equity Opportunites",
        gestora: "Eurizon Capital S.A.",
        category: "RV Italia",
    },
];

const SearchCategoryRankingScreenStyled = styled.div`
    > table
    {
        width: 70%;
        margin: 0 auto;
    }
`;

export const SearchCategoryRankingScreen = () =>
{
    return (
        <SearchCategoryRankingScreenStyled>
            <DropbdownZone />
            <Table.TableStyled>
                <thead>
                    <tr>
                        <th>
                            <div>Nombre</div>
                        </th>
                        <th>
                            <div>Gestora</div>
                        </th>
                        <th>
                            <div>Categoría</div>
                        </th>
                        <th>
                            <div>Puntos IRONIA</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, i) => (
                        <SubTableInternal
                            name={item.name}
                            gestora={item.gestora}
                            category={item.category}
                            key={i}
                        />
                    ))}
                </tbody>
            </Table.TableStyled>
        </SearchCategoryRankingScreenStyled>
    );
};
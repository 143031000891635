import { useEffect, useState } from "react";
import DonationsService, { Donation, ElementsMapItem, ElementTransactionSignature } from "../../../services/DonationService";
import { DefaultButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import SavingsPlanService, { SavingsPlanStatuses } from "../../../services/SavingsPlanService";
import Auth from "../../../Auth/Auth";
import { ITableSPlansActive } from "../../../components/SavePlans/TableActiveSavingPlans";
import { decimalFormatter, longDecimalFormatter } from "../../../utils/numberFormatter";
import CustomIcon from "../../../components/icons/CustomIcon";
//import ElementService from "../../../services/ElementsService";
import './textField.sass';
import Styles from './index.module.sass';
import NumericFormat from 'react-number-format';
import { dateFormatter } from "../../../utils/DateFormatter";
import { AxiosError } from "axios";
import { ISignature } from "../../../services/SignaturesService";
import { SignatureComponent } from "../../../components/Signature/SignatureComponent";
import moment from "moment";
import { useHistory } from "react-router-dom";

const Donations = () => {
  const user = Auth.getUserProfile();
  const history = useHistory();
  const [donation, setDonation] = useState<Donation>();
  const [savingsPlans, setSavingsPlans] = useState<ITableSPlansActive[]>();
  const [error, setError] = useState<AxiosError>();
  //const [elementWaterPrice, setElementWaterPrice] = useState<number>(0);
  const [elementsMap, setElementsMap] = useState<ElementsMapItem[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [signatures, setSignatures] = useState<ISignature[]>();
  const [elementTransactionSignatures, setElementTransactionSignatures] = useState<ElementTransactionSignature[]>();
  const [phoneNumbers, setPhoneNumbers] = useState("");

  useEffect(() => {
      setIsLoading(true);
      DonationsService.getCurrentDonation().then((donation: Donation) =>
      {
          if (donation !== null) {
              setDonation(donation);
          }
      })
      .catch((error) =>
      {
          setError(error);
      })
  }, []);

  /*useEffect(() => {
      ElementService.getLastElementsPrice().then((waterPrice: any) =>
      {
          setElementWaterPrice(waterPrice);
      });
  }, []);*/

  useEffect(() =>
  {
      SavingsPlanService.getSavingsPlansAndStatus(user.id).then((plans) =>
      {
          const activePlans = plans.filter(sP => sP.status === SavingsPlanStatuses.Active && sP.hasElements && sP.elementsIA !== undefined && sP.elementsIA > 0);
          setSavingsPlans(activePlans);
      })
      .catch((error) =>
      {
          setError(error);
      })
      .finally(() =>
      {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
      if (savingsPlans !== undefined) {
          const elemenstsMap = savingsPlans.map(sP => ({
              savingsPlanId: sP.id,
              waterElements: 0,
              IAElements: 0
          }));
          setElementsMap(elemenstsMap);
      }
  }, [savingsPlans]);

  /*const fullWaterElements = (savingsPlanId: number) => {
      const currentElementMap = elementsMap?.find(eM => eM.savingsPlanId === savingsPlanId);
      const allSavingsPlanWaterElements = savingsPlans?.find(sP => sP.id === savingsPlanId)?.elementsWater ?? 0;

      if (currentElementMap !== undefined) {
          const elementsMapWithoutCurrent = elementsMap?.filter(eM => eM.savingsPlanId !== savingsPlanId);
          setElementsMap([
            {
              ...currentElementMap,
              waterElements: allSavingsPlanWaterElements
            },
            ...(elementsMapWithoutCurrent ?? [])
          ]);
      }
  };*/

  const fullIAElements = (savingsPlanId: number) => {
      const currentElementMap = elementsMap?.find(eM => eM.savingsPlanId === savingsPlanId);
      const allSavingsPlanIAElements = savingsPlans?.find(sP => sP.id === savingsPlanId)?.elementsIA ?? 0;

      if (currentElementMap !== undefined) {
          const elementsMapWithoutCurrent = elementsMap?.filter(eM => eM.savingsPlanId !== savingsPlanId);
          setElementsMap([
            {
              ...currentElementMap,
              IAElements: allSavingsPlanIAElements
            },
            ...(elementsMapWithoutCurrent ?? [])
          ]);
      }
  };

  /*const handleInputElementsWater = (amount: string, savingsPlanId: number) => {
      const currentElementMap = elementsMap?.find(eM => eM.savingsPlanId === savingsPlanId);
      const value = parseInt(amount.replaceAll(",", "").replaceAll(".", ""));
      if (currentElementMap !== undefined) {
          const elementsMapWithoutCurrent = elementsMap?.filter(eM => eM.savingsPlanId !== savingsPlanId);
          setElementsMap([
              {
                ...currentElementMap,
                waterElements: value
              },
              ...(elementsMapWithoutCurrent ?? [])
          ]);
      }
  };

  const cleanElementsWater = (savingsPlanId: number) => {
      const currentElementMap = elementsMap?.find(eM => eM.savingsPlanId === savingsPlanId);

      if (currentElementMap !== undefined) {
          const elementsMapWithoutCurrent = elementsMap?.filter(eM => eM.savingsPlanId !== savingsPlanId);
          setElementsMap([
              {
                ...currentElementMap,
                waterElements: 0
              },
              ...(elementsMapWithoutCurrent ?? [])
          ]);
      }
  };*/

  const handleInputElementsIA = (amount: string, savingsPlanId: number) => {
      const value = parseInt(amount.replaceAll(",", "").replaceAll(".", ""));
      const currentElementMap = elementsMap?.find(eM => eM.savingsPlanId === savingsPlanId);

      if (currentElementMap !== undefined) {
          const elementsMapWithoutCurrent = elementsMap?.filter(eM => eM.savingsPlanId !== savingsPlanId);
          setElementsMap([
            {
              ...currentElementMap,
              IAElements: value
            },
            ...(elementsMapWithoutCurrent ?? [])
          ]);
      }
  };

  const cleanElementsIA = (savingsPlanId: number) => {
      const currentElementMap = elementsMap?.find(eM => eM.savingsPlanId === savingsPlanId);

      if (currentElementMap !== undefined) {
          const elementsMapWithoutCurrent = elementsMap?.filter(eM => eM.savingsPlanId !== savingsPlanId);
          setElementsMap([
            {
              ...currentElementMap,
              IAElements: 0
            },
            ...(elementsMapWithoutCurrent ?? [])
          ]);
      }
  };

  const createSignatures = () => {
    if (elementsMap !== undefined) {
      setIsSubmitting(true);
      const notEmptyElementsMap = elementsMap.filter(eM => eM.IAElements > 0 || eM.waterElements > 0);
      DonationsService.CreateSignatures(notEmptyElementsMap).then((elementTransactionSignatures: ElementTransactionSignature[]) =>
        {
            setElementTransactionSignatures(elementTransactionSignatures);
        })
        .catch((error) =>
        {
            setError(error);
        })
        .finally(() =>
        {
            setIsSubmitting(false);
        });
    }
  }

  useEffect(() => {
      if (elementTransactionSignatures !== undefined) {
        setSignatures(elementTransactionSignatures.map(eTS => eTS.signature));
      }
  }, [elementTransactionSignatures]);

  useEffect(() => {
      const userIdentities = elementTransactionSignatures?.map(eTS => eTS.userIdentity);
      if (userIdentities !== undefined) {
        setPhoneNumbers(userIdentities.map((userIdentity: any) =>
            "X".repeat(userIdentity.phone.length - 3) + userIdentity.phone.slice(userIdentity.phone.length - 3, userIdentity.phone.length)).join(", "));
      }
  }, [elementTransactionSignatures]);

  const processExpirationDate = (signs: ISignature[]) =>
  {
      if(signs !== undefined && signs.length > 0)
      {
          // eslint-disable-next-line array-callback-return
          signs.map((signature: ISignature) =>
          {
              if(signature && typeof signature.expiresAt === "string")
              {
                  signature.expiresAt = moment(signature.expiresAt).toDate();
              }
          });
      }
  }

  const handleSubmit = () => {
    if (elementsMap !== undefined && elementTransactionSignatures !== undefined) {
      setIsSubmitting(true);
      const notEmptyElementsMap = elementsMap.filter(eM => eM.IAElements > 0 || eM.waterElements > 0);
      DonationsService.IncrementDonationAmount(notEmptyElementsMap, elementTransactionSignatures).then((elementsMap: ElementsMapItem[]) =>
          {
              history.push({
                pathname: "/elements",
                state: { succesfullDonation: true }
              });
          })
          .catch((error) =>
          {
              setError(error);
          })
          .finally(() =>
          {
              setIsSubmitting(false);
          });
    }
  };

  return (
    <section id="donations" className={Styles.donations}>
      <h2>Donación Elements</h2>
      <p className="description">En ocasiones especiales la comunidad IronIA ayuda a una buena causa permitiendo donar tus Elements.</p>
      {isLoading && <Spinner size={SpinnerSize.large} />}
      {!isLoading && (
        <div className={Styles.content}>
          <h3>Utiliza tus Elements</h3>
          <table className={Styles.donationsTable}>
              <thead>
                <tr>
                  <th rowSpan={2}>Plan de Cartera</th>
                  <th rowSpan={2}>Contrato</th>
                  {/*<th colSpan={2}>Elements Agua</th>*/}
                  <th rowSpan={2}>Elements IA</th>
                  {/*<th rowSpan={2}>Elements Agua</th>*/}
                  <th rowSpan={2}>Elements IA</th>
                </tr>
                {/*<tr>
                  <th>Precio</th>
                  <th>Valor</th>
                </tr>*/}
              </thead>
              <tbody>
                {savingsPlans !== undefined && savingsPlans.map((savingsPlan: ITableSPlansActive, index: number) =>
                (
                  <tr key={index}>
                      <td>{savingsPlan.planName}</td>
                      <td>{savingsPlan.finametrixContract}</td>
                      {/*<td className="text-center">
                          {longDecimalFormatter.format(elementWaterPrice)} €
                      </td>
                      <td className={`text-center ${Styles.shared}`}>
                          <span>
                              {savingsPlan.elementsWater !== undefined ? decimalFormatter.format(savingsPlan.elementsWater) : 0}
                          </span>
                          {savingsPlan.hasElements === true &&
                          (
                              <DefaultButton
                                onClick={() => fullWaterElements(savingsPlan.id)}
                                disabled={savingsPlan.elementsWater === 0}
                              >
                                  <CustomIcon
                                    disabled={savingsPlan.elementsWater === 0}
                                    size={"15"}
                                    color={"#CC214F"}
                                    iconName={"NavigateForward"}
                                    marginLeft={"0.5em"} />
                              </DefaultButton>
                          )}
                          {savingsPlan.hasElements === false &&
                          (
                              <DefaultButton disabled={true} style={{cursor: "not-allowed"}}>
                                  <CustomIcon
                                    size={"15"}
                                    disabled={true}
                                    iconName={"NavigateForward"}
                                    marginLeft={"0.5em"}
                                  />
                              </DefaultButton>
                          )}
                      </td>*/}
                      <td className={`text-center ${Styles.shared}`}>
                          <span>
                              {longDecimalFormatter.format(savingsPlan.elementsIA as number)}
                          </span>
                          {savingsPlan.hasElements === true &&
                          (
                              <DefaultButton onClick={() => fullIAElements(savingsPlan.id)} disabled={savingsPlan.elementsIA === 0}>
                                  <CustomIcon
                                    disabled={savingsPlan.elementsIA === 0}
                                    size={"15"}
                                    color={"#CC214F"}
                                    iconName={"NavigateForward"}
                                    marginLeft={"0.5em"} />
                              </DefaultButton>
                          )}
                          {savingsPlan.hasElements === false &&
                          (
                              <DefaultButton disabled={true} style={{cursor: "not-allowed"}}>
                                  <CustomIcon
                                    size={"15"}
                                    iconName={"NavigateForward"}
                                    marginLeft={"0.5em"}
                                    disabled={true}
                                  />
                              </DefaultButton>
                          )}
                      </td>
                      {/*<td className={`text-center ${Styles.shared}`}>
                          <NumericFormat
                            customInput={TextField}
                            thousandSeparator="."
                            decimalSeparator=","
                            allowNegative={false}
                            isAllowed={(values) => {
                              const { floatValue } = values;
                              return (floatValue ?? 0) >= 0 && (floatValue ?? 0) <= (savingsPlans?.find(sP => sP.id === savingsPlan.id)?.elementsWater ?? 0)
                            }}
                            disabled={savingsPlan.elementsWater === 0}
                            min={0}
                            max={savingsPlans?.find(sP => sP.id === savingsPlan.id)?.elementsWater}
                            value={elementsMap?.find(eM => eM.savingsPlanId === savingsPlan.id)?.waterElements}
                            onChange={(e) => handleInputElementsWater(e.target.value, savingsPlan.id)}
                          />
                          {savingsPlan.hasElements === true &&
                          (
                              <DefaultButton
                                disabled={savingsPlan.elementsWater === 0 || elementsMap?.find(eM => eM.savingsPlanId === savingsPlan.id)?.waterElements === 0}
                                onClick={() => cleanElementsWater(savingsPlan.id)}
                              >
                                  <CustomIcon
                                    iconName='EraseTool'
                                    size={"15"}
                                    color={"#CC214F"}
                                    disabled={savingsPlan.elementsWater === 0 || elementsMap?.find(eM => eM.savingsPlanId === savingsPlan.id)?.waterElements === 0}
                                  />
                              </DefaultButton>
                          )}
                          {savingsPlan.hasElements === false &&
                          (
                              <DefaultButton disabled={true}>
                                  <CustomIcon
                                    iconName='EraseTool'
                                    size={"15"}
                                    color={"#CC214F"}
                                    disabled={true}
                                  />
                              </DefaultButton>
                          )}
                      </td>*/}
                      <td className={`text-center ${Styles.shared}`}>
                          <NumericFormat
                            customInput={TextField}
                            thousandSeparator="."
                            decimalSeparator=","
                            allowNegative={false}
                            isAllowed={(values) => {
                              const { floatValue } = values;
                              return (floatValue ?? 0) >= 0 && (floatValue ?? 0) <= (savingsPlans?.find(sP => sP.id === savingsPlan.id)?.elementsIA ?? 0)
                            }}
                            disabled={savingsPlan.elementsIA === 0}
                            min={0}
                            max={savingsPlans?.find(sP => sP.id === savingsPlan.id)?.elementsIA}
                            value={elementsMap?.find(eM => eM.savingsPlanId === savingsPlan.id)?.IAElements}
                            onChange={(e) => handleInputElementsIA(e.target.value, savingsPlan.id)}
                            isNumericString={true}
                          />
                          {savingsPlan.hasElements === true &&
                          (
                              <DefaultButton
                                onClick={() => cleanElementsIA(savingsPlan.id)}
                                disabled={savingsPlan.elementsIA === 0 ||elementsMap?.find(eM => eM.savingsPlanId === savingsPlan.id)?.IAElements === 0}
                              >
                                  <CustomIcon
                                    iconName='EraseTool'
                                    size={"15"}
                                    color={"#CC214F"}
                                    disabled={savingsPlan.elementsIA === 0 ||elementsMap?.find(eM => eM.savingsPlanId === savingsPlan.id)?.IAElements === 0}
                                  />
                              </DefaultButton>
                          )}
                          {savingsPlan.hasElements === false &&
                          (
                              <DefaultButton disabled={true}>
                                  <CustomIcon
                                    iconName='EraseTool'
                                    size={"15"}
                                    color={"#CC214F"} 
                                    disabled={true}
                                  />
                              </DefaultButton>
                          )}
                      </td>
                  </tr>
              ))}
              </tbody>
          </table>
        </div>
      )}
      {!isLoading && (
        <>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl4 ms-xxl4">
              <img src={donation?.urlImage} alt={`Promoción ${donation?.name}`} />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8 ms-xxl8">
              {(signatures === undefined || isSubmitting) && (
                <>
                <h2>{donation?.name}</h2>
                <p className="description">{donation?.description}</p>
                <p className="description mt20">Fecha inicio donación {dateFormatter(donation?.startDate ?? '')} Fecha final donación {dateFormatter(donation?.endDate ?? '')}</p>
                <div className={Styles.disclamer}>
                  <span>Esta donación no tiene deducción fiscal ni para los clientes de IronIA ni para ninguna empresa del grupo Diaphanum.
                    Diaphanum Sociedad de Valores realizará la donación a la entidad señalada anteriormente.</span>
                  <DefaultButton
                    className="tiny-primary-button"
                    onClick={createSignatures}
                    disabled={isSubmitting || error !== undefined || (elementsMap?.reduce((total: number, current: ElementsMapItem) => total += current.IAElements, 0) ?? 0) === 0}
                  >
                    {isSubmitting && <Spinner size={SpinnerSize.xSmall} />} Donar
                  </DefaultButton>
                </div>
                {error && (
                  <span className={Styles.error}>Se ha producido un error</span>
                )}
                </>
              )}
              {signatures !== undefined && (
                <>
                  <SignatureComponent
                      signatures={signatures}
                      alreadySignedText=" "
                      submitButtonText="Firmar donación"
                      cancelButtonText="Volver"
                      headerTextArea={"Te hemos enviado un código SMS"
                        + (signatures.length > 1 ? ` a los números ${phoneNumbers} ` : signatures.length === 1 ? ` al número ${phoneNumbers} ` : " ")
                        + "para realizar la firma de la donación de elements."}
                      backButtonText="Volver"
                      onBackButtonClicked={() => history.push(`/elements/donations`)}
                      onCancelButtonClicked={() => history.push(`/elements/donations`)}
                      requestNewCodeButtonText="Recibir nuevo código"
                      onNewCodeSended={(signatures: any) =>
                      {
                          processExpirationDate(signatures);
                          setSignatures(signatures);
                      }}
                      onSignatureChanged={(signatures: any) =>
                      {
                          processExpirationDate(signatures);
                          setSignatures(signatures);
                      }}
                      onSigned={handleSubmit}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default Donations;
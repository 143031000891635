import { useEffect, useState } from "react";
import { DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import DocumentTitle from "react-document-title";
import Moment from 'moment';
import { useHistory, useParams } from "react-router";
import UserIdentitiesService, { UserIdentityType } from '../../services/UserIdentitiesService';
import SavingsPlanService, { SavingsPlanType, SavingsPlanTypes } from '../../services/SavingsPlanService';
import Auth from "../../Auth/Auth";
import ManagedPortfolioService, { ManagedPortfolioType } from "../../services/ManagedPortfolioService";
import FinametrixService, { RiskType } from "../../services/FinametrixService";
import PortfolioSummary from "./PortfolioSummary";
import savingsPlansUserIdentitiesService, { SavingsPlanUserIdentityType } from "../../services/SavingsPlanUserIdentityService";
import React from "react";

interface errorType {
    status: boolean;
    message: string;
};

const SavingsPlanSelectIdentities = () =>
{
    const { savingsPlanId } = useParams<any>();
    const [userIdentities, setUserIdentities] = useState<UserIdentityType[]>([]);
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>();
    const [savingsPlanUserIdentities, setSavingsPlanUserIdentities] = useState<SavingsPlanUserIdentityType[]>([]);
    const [loadedUserIdentities, serLoadedUserIdentities] = useState(false);
    const [managedPortfolio, setManagedPortfolio] = useState<ManagedPortfolioType>();
    const [risks, setRisks] = useState<RiskType[]>();
    const [savingsPlanRiskProfile, setSavingsPlanRiskProfile] = useState<RiskType>();
    const [suitabilityTestsRiskProfile, setSuitabilityTestsRiskProfile] = useState<RiskType>();
    const [canFinish, setCanFinish] = useState<boolean>(false);
    const [error, setError] = useState<errorType>({ status: false, message: '' });
    const [isSending, setIsSending] = useState(false);
    const user = Auth.getUserProfile();
    const history = useHistory();

    useEffect(() =>
    {
        UserIdentitiesService.getIdentitiesByUserId(user.id).then((userIdentities: UserIdentityType[]) =>
        {
            setUserIdentities(userIdentities);
            serLoadedUserIdentities(true);
        },
        (error) =>
        {
            setError({ status: error.status, message: error.message });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        SavingsPlanService.getSavingsPlan(savingsPlanId).then((savingsPlan: SavingsPlanType) =>
        {
            setSavingsPlan(savingsPlan);
        },
        (error) =>
        {
            setError({ status: error.status, message: error.message });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if (savingsPlan?.savingsPlansUserIdentities !== undefined
            && savingsPlan?.savingsPlansUserIdentities !== null
            && savingsPlanUserIdentities.length === 0
        ) {
            setSavingsPlanUserIdentities(savingsPlan.savingsPlansUserIdentities);
        }
    }, [savingsPlan]);

    useEffect(() =>
    {
        if (savingsPlan?.managedPortfolioId !== undefined
            && savingsPlan.managedPortfolioId !== null
            && managedPortfolio === undefined
        ) {
            ManagedPortfolioService.getManagedPortfolioWithAlternatives(savingsPlan.managedPortfolioId)
                .then((managedPortfolio: ManagedPortfolioType) =>
                {
                    if (savingsPlan.type === SavingsPlanTypes.Alchemy) {
                        managedPortfolio = {
                            ...managedPortfolio,
                            name: managedPortfolio.name.replace("We Will Rock You - 1977", "Alchemy Portfolio")
                        }

                        if (managedPortfolio.riskLevel1ManagedPortfolio !== undefined) {
                            managedPortfolio.riskLevel1ManagedPortfolio = {
                                ...managedPortfolio.riskLevel1ManagedPortfolio,
                                name: managedPortfolio.riskLevel1ManagedPortfolio.name.replace("We Will Rock You - 1977", "Alchemy Portfolio")
                            }
                        }

                        if (managedPortfolio.riskLevel2ManagedPortfolio !== undefined) {
                            managedPortfolio.riskLevel2ManagedPortfolio = {
                                ...managedPortfolio.riskLevel2ManagedPortfolio,
                                name: managedPortfolio.riskLevel2ManagedPortfolio.name.replace("We Will Rock You - 1977", "Alchemy Portfolio")
                            }
                        }

                        if (managedPortfolio.riskLevel3ManagedPortfolio !== undefined) {
                            managedPortfolio.riskLevel3ManagedPortfolio = {
                                ...managedPortfolio.riskLevel3ManagedPortfolio,
                                name: managedPortfolio.riskLevel3ManagedPortfolio.name.replace("We Will Rock You - 1977", "Alchemy Portfolio")
                            }
                        }

                        if (managedPortfolio.riskLevel4ManagedPortfolio !== undefined) {
                            managedPortfolio.riskLevel4ManagedPortfolio = {
                                ...managedPortfolio.riskLevel4ManagedPortfolio,
                                name: managedPortfolio.riskLevel4ManagedPortfolio.name.replace("We Will Rock You - 1977", "Alchemy Portfolio")
                            }
                        }
                    }
                    setManagedPortfolio(managedPortfolio);
                },
                (error: any) =>
                {
                    setError({ status: error.status, message: error.message });
                });
        }
    }, [savingsPlan?.managedPortfolioId]);

    useEffect(() => {
        if (savingsPlan?.managedPortfolioId !== undefined
            && savingsPlan.managedPortfolioId !== null
            && managedPortfolio !== undefined
            && managedPortfolio.id !== savingsPlan.managedPortfolioId
            && risks !== undefined
            && managedPortfolio.riskLevel1ManagedPortfolio !== undefined
            && managedPortfolio.riskLevel2ManagedPortfolio !== undefined
            && managedPortfolio.riskLevel3ManagedPortfolio !== undefined
            && managedPortfolio.riskLevel4ManagedPortfolio !== undefined
        ) {
            var alternatives: ManagedPortfolioType[] = [
                managedPortfolio.riskLevel1ManagedPortfolio,
                managedPortfolio.riskLevel2ManagedPortfolio,
                managedPortfolio.riskLevel3ManagedPortfolio,
                managedPortfolio.riskLevel4ManagedPortfolio
            ];
            var portfolio = alternatives.find(p => p.id === savingsPlan?.managedPortfolioId)
            if (portfolio !== undefined) {
                setSavingsPlanRiskProfile(risks.find((risk: RiskType) => risk._id === portfolio?.riskProfile));
            }
        }
    }, [savingsPlan?.managedPortfolioId]);

    useEffect(() =>
    {
        if (savingsPlan !== undefined
            && risks !== undefined
            && savingsPlan?.managedPortfolio !== null
        ) {
            switch(savingsPlan.type)
            {
                case 1:
                case 0:
                    setSavingsPlanRiskProfile(risks.find((risk: RiskType) => risk._id === savingsPlan?.managedPortfolio?.riskProfile));
                    break;
            }
        }
    }, [savingsPlan, risks]);

    useEffect(() =>
    {
        FinametrixService.getRisks().then((risksAux: RiskType[]) =>
        {
            setRisks(risksAux);
        },
        (error) =>
        {
            setError({ status: error.status, message: error.message });
        });
    }, []);

    useEffect(() =>
    {
        if (risks !== undefined
            && savingsPlanUserIdentities.length > 0
            && savingsPlan !== undefined
            && [0, 1].includes(savingsPlan.type))
        {
            var minRiskOnSuitabilityTests = savingsPlanUserIdentities
                .filter(sPUI => {
                    const birthdayDate = Moment(sPUI.userIdentity?.birthday);
                    const isMinor = Moment().diff(birthdayDate, 'years') < 18;
                    return sPUI.userIdentity?.suitabilityTests !== undefined && !isMinor
                })
                .map(sPUI => {
                    var completedSuitabilityTests = sPUI.userIdentity?.suitabilityTests?.filter(sT => sT.results?.results?.profile !== undefined)
                    if(completedSuitabilityTests !== undefined)
                    {
                        var profile = completedSuitabilityTests[completedSuitabilityTests.length - 1].results?.results.profile;
                        if(profile !== undefined)
                        {
                            var risk = risks.find(r => r._id === profile);
                            if(risk !== undefined)
                            {
                                return risk;
                            }
                        }
                    }
                
                    return risks[0];
                })

            if (minRiskOnSuitabilityTests.length > 0) {
                setSuitabilityTestsRiskProfile(minRiskOnSuitabilityTests.reduce((rA: RiskType, rB: RiskType) => {
                    return rA.order < rB.order
                        ? rA
                        : rB
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savingsPlanUserIdentities]);

    useEffect(() =>
    {
        if(savingsPlanRiskProfile !== undefined)
        {
            setCanFinish(savingsPlanRiskProfile !== undefined
                && suitabilityTestsRiskProfile !== undefined
                && suitabilityTestsRiskProfile.order >= savingsPlanRiskProfile.order);
        }
        else
        {
            if(savingsPlan !== undefined && ![0, 1].includes(savingsPlan?.type))
            {
                setCanFinish(true);
            }
        }
    }, [savingsPlanRiskProfile, suitabilityTestsRiskProfile, savingsPlan]);

    useEffect(() =>
    {
        var selectedOwner = savingsPlanUserIdentities.find(sPUI => sPUI.relation === 1);
        var currentDate = Moment();
        var anyMinorIsSelected = savingsPlanUserIdentities.some(sPUI => {
            var birthdayDate = Moment(sPUI.userIdentity?.birthday);
            return currentDate.diff(birthdayDate, 'years') < 18;
        });

        var anyRepresentativeIsSelected = savingsPlanUserIdentities.some(sPUI => sPUI.relation === 4);

        if (anyMinorIsSelected && !anyRepresentativeIsSelected) {
            setError({ status: true, message: 'Si selecciona a algún menor es obligatorio seleccionar al menos a un representante legal' });
            return;
        }
        if (!anyMinorIsSelected && anyRepresentativeIsSelected) {
            setError({ status: true, message: 'Si selecciona a algún representante legal es obligatorio seleccionar al menos a un menor' });
            return;
        }

        if (selectedOwner === undefined)
        {
            setError({ status: true, message: 'Debe seleccionar a un Titular' });
            return;
        }

        const mainUserIdentity = savingsPlanUserIdentities.find(sPUI => sPUI.userIdentityId === user.mainUserIdentity.id);
        if (mainUserIdentity === undefined) {
            const mainUserIdentityFullname = [
                user.mainUserIdentity.firstName,
                user.mainUserIdentity.lastName,
                user.mainUserIdentity.secondLastNam
            ].join(" ").trim()
            setError({ status: true, message: `El usuario ${mainUserIdentityFullname} debe aparecer al menos como autorizado` });
            return;
        }

        const timer = setTimeout(() => setError({ status: false, message: '' }));
        return () => clearTimeout(timer);
    }, [savingsPlanUserIdentities]);

    const RelationChange = (event: any, userIdentityId: number | undefined) =>
    {
        var existingSavingsPlanUserIdentity = savingsPlanUserIdentities.find(sPUI => sPUI.userIdentityId === userIdentityId);
        var selectedOwner = savingsPlanUserIdentities.find(sPUI => sPUI.relation === 1);
        if(selectedOwner !== undefined)
        {
            if(event.target.value === '1')
            {
                return false;
            }
        }
        if(event.target.value !== undefined && event.target.value !== '')
        {
            if(existingSavingsPlanUserIdentity !== undefined)
            {
                existingSavingsPlanUserIdentity.relation = parseInt(event.target.value);
                setSavingsPlanUserIdentities((prevState: SavingsPlanUserIdentityType[]) =>
                {
                    if(existingSavingsPlanUserIdentity !== undefined)
                    {
                        return [
                            ...prevState.filter(sPUI => sPUI.userIdentityId !== userIdentityId),
                            existingSavingsPlanUserIdentity
                        ]
                    }
                    else
                    {
                        return prevState;
                    }
                });
            }
            else
            {
                if(userIdentityId !== undefined)
                {
                    var newSavingsPlanUserIdentityType: SavingsPlanUserIdentityType =
                    {
                        userIdentityId: userIdentityId,
                        userIdentity: userIdentities.find(uI => uI.id === userIdentityId),
                        savingsPlanId: savingsPlanId,
                        relation: parseInt(event.target.value)
                    };
                    setSavingsPlanUserIdentities((prevState: SavingsPlanUserIdentityType[]) =>
                    [
                        ...prevState.filter(sPUI => sPUI.userIdentityId !== userIdentityId),
                        newSavingsPlanUserIdentityType
                    ]);
                }
            }
        }
        else
        {
            setSavingsPlanUserIdentities((prevState: SavingsPlanUserIdentityType[]) =>
            [
                ...prevState.filter(sPUI => sPUI.userIdentityId !== userIdentityId)
            ]);
        }

        event.target.parentNode.parentNode.childNodes.forEach((cN: any) => cN.classList.remove("selected"));
        event.target.parentNode.classList.add("selected");

        if(selectedOwner !== undefined && event.target.value !== '1' && selectedOwner.userIdentityId === userIdentityId)
        {
            var radios = event.target.parentNode.parentNode.parentNode.getElementsByClassName('owner-radio');
            for(var index = 0; index < radios.length; index++)
            {
                if(radios[index].disabled === true && radios[index].name !== event.target.name)
                {
                    radios[index].disabled = false;
                }
            }
        }

        if(event.target.value === '1')
        {
            var radios2 = event.target.parentNode.parentNode.parentNode.getElementsByClassName('owner-radio');
            for(var idx = 0; idx < radios2.length; idx++)
            {
                if(radios2[idx].disabled === false && radios2[idx].name !== event.target.name)
                {
                    radios2[idx].disabled = true;
                }
            }
        }
    };

    const isChecked = (userIdentityId: number | undefined, value: number | undefined) =>
    {
        var savingsPlanUserIdentity = savingsPlanUserIdentities.find(sPUI => sPUI.userIdentityId === userIdentityId);
        if(savingsPlanUserIdentity !== undefined)
        {
            return savingsPlanUserIdentity?.relation === value;
        }
        else
        {
            return value === undefined;
        }
    };

    function backToPreviousStep()
    {
        if(savingsPlan !== undefined)
        {
            var nextStep = "2.1";
            if (savingsPlan !== undefined && [0, 1].includes(savingsPlan.type)) {
                nextStep = "2.2";
            }
            setIsSending(true);
            SavingsPlanService.patchSavingsPlan(savingsPlanId, { configurationMapStep: nextStep })
                .then(
                    () => history.push('/configurationMap', { savingPlanId: savingsPlan.id, savingPlanName: savingsPlan.name }),
                    (error) => setError({ status: error.status, message: error.message })
                );
        }
    }

    async function onSubmit()
    {
        if(savingsPlan?.id !== undefined && savingsPlanUserIdentities !== undefined && savingsPlanUserIdentities.length > 0)
        {
            setIsSending(true);

            for(const savingsPlanUserIdentity of savingsPlanUserIdentities)
            {
                if (savingsPlanUserIdentity.relation !== 0) {
                    if (savingsPlanUserIdentity.id !== undefined) {
                        await savingsPlansUserIdentitiesService.updateSavingsPlanUserIdentity(savingsPlanUserIdentity);
                    }
                    else {
                        await savingsPlansUserIdentitiesService.createSavingsPlanUserIdentity({
                            userIdentityId: savingsPlanUserIdentity.userIdentityId,
                            savingsPlanId: savingsPlanUserIdentity.savingsPlanId,
                            relation: savingsPlanUserIdentity.relation
                        });
                    }
                }
            };

            var storedSavingsPlansUserIdentities = savingsPlan.savingsPlansUserIdentities;
            if (storedSavingsPlansUserIdentities !== undefined)
            {
                for (const storedSavingsPlanUserIdentity of storedSavingsPlansUserIdentities)
                {
                    if (savingsPlanUserIdentities.find(sPUI => sPUI.id === storedSavingsPlanUserIdentity.id) === undefined)
                    {
                        await savingsPlansUserIdentitiesService.deleteSavingsPlanUserIdentity(storedSavingsPlanUserIdentity.id);
                    }
                }
            }

            var someAuthorizedSavingsPlanUserIdentity = savingsPlanUserIdentities.find(sPUI => sPUI.relation === 5);
            var nextStep = someAuthorizedSavingsPlanUserIdentity ? "2.4" : "2.5";

            SavingsPlanService.patchSavingsPlan(savingsPlanId, { configurationMapStep: nextStep, status: 3 })
                .then(
                    () => history.push('/configurationMap', { savingPlanId: savingsPlan.id, savingPlanName: savingsPlan.name }),
                (error) => setError({ status: error.status, message: error.message })
            );
        }
    }

    return(
        <DocumentTitle title='IronIA - 2.3 Seleccionar intervinientes'>
            <div className="ms-Grid-row savings-plans savings-plan-select-identities">
                <h2>Seleccionar intervinientes para el Contrato del Plan de Cartera</h2>
                <p className="description">Confirma o edita quiénes son los intervinientes de esta cuenta.</p>
                {userIdentities.length === 0 &&
                (
                    <Spinner size={SpinnerSize.large} />
                )}
                {userIdentities.length > 0 && savingsPlan?.type !== undefined &&
                (
                    <div className="rounded grey p40">
                        <table className="full-width">
                            <thead>
                                <tr>
                                    <th className="select">No incluido en el contrato</th>
                                    <th className="radio">Titular</th>
                                    <th className="select">Cotitular</th>
                                    <th className="select">Representante legal</th>
                                    <th className="select">Autorizado</th>
                                    <th>Nombre y apellidos</th>
                                    <th>Correo electrónico</th>
                                    <th className="tight">Menor de edad</th>
                                    <th className="tight">Conocer a nuestro cliente</th>
                                    {[0, 1].includes(savingsPlan?.type) && (
                                        <th className="tight">Test de idoneidad</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {userIdentities.map((userIdentity, userIdentityIndex) =>
                                {
                                    var currentDate = Moment();
                                    var birthdayDate = Moment(userIdentity.birthday);
                                    var isMinor = currentDate.diff(birthdayDate, 'years') < 18;
                                    var isActive = userIdentity.status === 'ACTIVE';

                                    return(
                                        <tr key={'userIdentityIndex' + userIdentityIndex}>
                                            <td className="text-center selected">
                                                <input
                                                    type="radio"
                                                    name={'userIdentityIndex' + userIdentityIndex}
                                                    value={undefined}
                                                    onChange={(event) => RelationChange(event, userIdentity.id)}
                                                    checked={isChecked(userIdentity.id, undefined)}
                                                    disabled={!isActive || ([0, 1].includes(savingsPlan?.type) && userIdentity.suitabilityTests?.length === 0 && !isMinor)}
                                                />
                                            </td>
                                            <td className="text-center">
                                                <input
                                                    type="radio"
                                                    name={'userIdentityIndex' + userIdentityIndex}
                                                    value={1}
                                                    onChange={(event) => RelationChange(event, userIdentity.id)}
                                                    checked={isChecked(userIdentity.id, 1)}
                                                    className='owner-radio'
                                                    disabled={!isActive || ([0, 1].includes(savingsPlan?.type) && userIdentity.suitabilityTests?.length === 0 && !isMinor)}
                                                />
                                            </td>
                                            <td className="text-center">
                                                <input
                                                    type="radio"
                                                    name={'userIdentityIndex' + userIdentityIndex}
                                                    value={11}
                                                    onChange={(event) => RelationChange(event, userIdentity.id)}
                                                    checked={isChecked(userIdentity.id, 11)}
                                                    disabled={!isActive || ([0, 1].includes(savingsPlan?.type) && userIdentity.suitabilityTests?.length === 0)}
                                                />
                                            </td>
                                            <td className="text-center">
                                                <input
                                                    type="radio"
                                                    name={'userIdentityIndex' + userIdentityIndex}
                                                    value={4}
                                                    disabled={!isActive || isMinor || ([0, 1].includes(savingsPlan?.type) && userIdentity.suitabilityTests?.length === 0)}
                                                    onChange={(event) => RelationChange(event, userIdentity.id)}
                                                    checked={isChecked(userIdentity.id, 4)}
                                                />
                                            </td>
                                            <td className="text-center">
                                                <input
                                                    type="radio"
                                                    name={'userIdentityIndex' + userIdentityIndex}
                                                    value={5}
                                                    disabled={!isActive || isMinor || ([0, 1].includes(savingsPlan?.type) && userIdentity.suitabilityTests?.length === 0)}
                                                    onChange={(event) => RelationChange(event, userIdentity.id)}
                                                    checked={isChecked(userIdentity.id, 5)}
                                                />
                                            </td>
                                            <td>{userIdentity.firstName} {userIdentity.lastName} {userIdentity.secondLastName}</td>
                                            <td>{userIdentity.email}</td>
                                            <td className="text-center" style={{background: isMinor ? "lightgrey" : ""}}>{isMinor ? 'Sí' : 'No'}</td>
                                            <td className="text-center">
                                                {userIdentity.status !== undefined && userIdentity.status === 'NEW' &&
                                                (
                                                    <span>No</span>
                                                )}
                                                {userIdentity.status !== undefined && ['PENDING', 'ACTIVE', 'OUTDATED', 'CANCELLED'].includes(userIdentity.status) &&
                                                (
                                                    <span>Sí</span>
                                                )}
                                            </td>
                                            {[0, 1].includes(savingsPlan?.type) &&
                                            (
                                                <td className="text-center">
                                                    {userIdentity.maxRiskAllowed === null &&
                                                    (
                                                        <React.Fragment>
                                                            <i className='ironia-icon warning-alert' />
                                                            No
                                                        </React.Fragment>
                                                    )}
                                                    {userIdentity.maxRiskAllowed !== null &&
                                                    (
                                                        <React.Fragment>
                                                            {risks !== undefined &&
                                                            (
                                                                <React.Fragment>
                                                                    {(risks.find(r => userIdentity.suitabilityTests !== undefined
                                                                        && r._id === userIdentity.suitabilityTests[userIdentity.suitabilityTests.length - 1].results?.results.profile)?.order || 0)}/4
                                                                    {' '}
                                                                    {risks.find(r => userIdentity.suitabilityTests !== undefined
                                                                        && r._id === userIdentity.suitabilityTests[userIdentity.suitabilityTests.length - 1].results?.results.profile)?.name}
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </td>
                                            )}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
                {(error?.status) &&
                (
                    <p className='error'>
                        {error.message}
                    </p>
                )}
                {savingsPlan !== undefined && [0, 1].includes(savingsPlan?.type) &&
                (
                    <React.Fragment>
                        <p className="description">
                            El plan de cartera seleccionado tiene un perfil de riesgo {(savingsPlanRiskProfile?.order || 0)}/4
                            {" "}
                            {savingsPlanRiskProfile?.name}
                        </p>
                        {!canFinish && savingsPlanUserIdentities.length > 0 && (
                            <React.Fragment>
                                <p>
                                    No puedes continuar el proceso siendo el perfil de riesgo de la cartera asociada al plan de cartera que has seleccionado
                                    superior al perfil de riesgo de alguno de los intervinientes seleccionados.
                                </p>
                                {managedPortfolio !== undefined &&
                                (
                                    <p>
                                        A continuación te mostramos las carteras {managedPortfolio?.name} adecuadas a cada nivel de riesgo. Selecciona una
                                        compatible con el nivel de riesgo del los intervinientes seleccionados.
                                    </p>
                                )}
                                {managedPortfolio !== null && risks !== undefined && savingsPlan !== undefined && managedPortfolio !== undefined
                                    && suitabilityTestsRiskProfile !== undefined &&
                                (
                                    <div>
                                        {managedPortfolio?.riskLevel1ManagedPortfolio != null &&
                                        (
                                            <PortfolioSummary managedPortfolio={managedPortfolio.riskLevel1ManagedPortfolio}
                                                suitabilityTestsRiskProfile={suitabilityTestsRiskProfile}
                                                risks={risks}
                                                savingsPlan={savingsPlan}
                                                setSavingsPlan={setSavingsPlan}
                                                savingsPlanUserIdentities={savingsPlanUserIdentities}
                                            />
                                        )}
                                        {managedPortfolio?.riskLevel2ManagedPortfolio != null &&
                                        (
                                            <PortfolioSummary managedPortfolio={managedPortfolio.riskLevel2ManagedPortfolio}
                                                suitabilityTestsRiskProfile={suitabilityTestsRiskProfile}
                                                risks={risks}
                                                savingsPlan={savingsPlan}
                                                setSavingsPlan={setSavingsPlan}
                                                savingsPlanUserIdentities={savingsPlanUserIdentities}
                                            />
                                        )}
                                        {managedPortfolio?.riskLevel3ManagedPortfolio != null &&
                                        (
                                            <PortfolioSummary managedPortfolio={managedPortfolio.riskLevel3ManagedPortfolio}
                                                suitabilityTestsRiskProfile={suitabilityTestsRiskProfile}
                                                risks={risks}
                                                savingsPlan={savingsPlan}
                                                setSavingsPlan={setSavingsPlan}
                                                savingsPlanUserIdentities={savingsPlanUserIdentities}
                                            />
                                        )}
                                        {managedPortfolio?.riskLevel4ManagedPortfolio != null &&
                                        (
                                            <PortfolioSummary managedPortfolio={managedPortfolio.riskLevel4ManagedPortfolio}
                                                suitabilityTestsRiskProfile={suitabilityTestsRiskProfile}
                                                risks={risks}
                                                savingsPlan={savingsPlan}
                                                setSavingsPlan={setSavingsPlan}
                                                savingsPlanUserIdentities={savingsPlanUserIdentities}
                                            />
                                        )}
                                        * Rentibilidades pasadas no aseguran rentabilidades futuras
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 text-right pt10">
                        <DefaultButton className="button tiny-secondary-button"
                            disabled={isSending}
                            onClick={backToPreviousStep}
                        >
                            {isSending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                            Volver al paso anterior
                        </DefaultButton>
                        {canFinish &&
                        (
                            <DefaultButton className="button tiny-primary-button"
                                disabled={isSending || !loadedUserIdentities || error?.status}
                                onClick={() =>
                                {
                                    setIsSending(true);
                                    onSubmit();
                                }}
                            >
                                {isSending && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                Continuar
                            </DefaultButton>
                        )}
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default SavingsPlanSelectIdentities;
import { GETFISCALREPORTDEAD, GETFISCALREPORTACT,} from './Constants';

export const INITIAL_STATE = {
  fiscalReportActivas:[],
  fiscalReportCerradas: []
};

export default function portfolioReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case GETFISCALREPORTACT:
      return {...state, fiscalReportActivas: action.payload};
    case GETFISCALREPORTDEAD:
      return {...state, fiscalReportCerradas: action.payload};

    default:
      return state;
  }
};

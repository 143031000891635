import React from "react";
import { Link } from "react-router-dom";
import { ActiveIcon } from "../../../components/icons/IconsTable";

interface IChildrenTableProps
{
    children: any[],
    onRowClicked: () => any;
}

export const FundTableMin = (props: IChildrenTableProps) =>
{
    return(
        <table cellPadding="0" cellSpacing="0">
            <thead>
                <tr>
                    <th>Familia del fondo</th>
                    <th>ISIN</th>
                    <th>Divisa</th>
                    <th>Gastos Corrientes (OGC)</th>
                    <th>Retrocesión</th>
                    <th>Inversión Inicial Mínima</th>
                    <th>Dividendos</th>
                    <th>Aplica mínimo</th>
                    <th>Clase limpia</th>
                    <th>Disponible</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.children.length > 0 && props.children.map((item, index) =>
                (
                    <React.Fragment key={index}>
                        <tr key={item.key || index} onClick={props.onRowClicked}>
                            <td>
                                {
                                    <Link to={`/${item.isin}`}>
                                        {item.name}
                                    </Link>
                                }
                            </td>
                            <td>{item.isin}</td>
                            <td>{item.currency}</td>
                            <td>{item.ongoingCharges}%</td>
                            <td>{parseFloat(item.rebate).toFixed(4)}%</td>
                            <td>
                                {Intl.NumberFormat("de-DE",
                                    {
                                        style: 'currency',
                                        currency: 'EUR',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2

                                    }).format(item.minimumInitialInvestment)
                                }
                            </td>
                            <td>{item.income ? "Si" : "No"}</td>
                            <td>{item.noMin ? "Si" : "No"}</td>
                            <td>{item.cleanShare ? "Si" : "No"}</td>
                            <td>{item.available ? "Si" : "No"}</td>
                            <td>{item.currency === "EUR" && item.cleanShare ? (<ActiveIcon color='#1BB2AD' />) : ("")}</td>
                        </tr>
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    );
};
import { PrimaryButton, Stack, TooltipHost } from 'office-ui-fabric-react';
import { ActionButtonsContainer, TBodyTable, TablePercentage, TdCurrency, TdPercentage, TrTable } from './Styles/ApiCartStyles';
import { currencyFormatter, percentFormatter } from '../utils/numberFormatter';

interface CartColumnProps
{
    item: any;
}
interface CartColumnActionsProps extends CartColumnProps
{
    toggleAttachmentsVisible: (isin: string) => any;
};
interface CartColumnPercentageProps
{
    percentage: number;
    amount: number;
}

export const ApiCartSubTable = (props: CartColumnActionsProps) =>
{
    return(
        <ActionButtonsContainer horizontal={true} horizontalAlign="center" verticalAlign="center">
            {props.item.documents?.length > 0 &&
            (
                <Stack.Item>
                    <TooltipHost content="Documentos" id={`btnDocuments_${props.item?.isin}`} calloutProps={{gapSpace: 0}}>
                        <PrimaryButton className="light-circled-icon" id={`btnDocuments_${props.item?.isin}`} onClick={(event) => props.toggleAttachmentsVisible(props.item?.isin)}>
                            <i className={"ironia-icon pdf"} />
                        </PrimaryButton>
                    </TooltipHost>
                </Stack.Item>
            )}
        </ActionButtonsContainer>
    );
};
export const ApiCartColumn = (props: CartColumnPercentageProps) =>
{
    return(
        <TablePercentage>
            <TBodyTable>
                <TrTable>
                    <TdPercentage>
                        {percentFormatter.format(props.percentage / 100)}
                    </TdPercentage>
                    <TdCurrency>
                        {currencyFormatter.format(props.amount * props.percentage / 100)}
                    </TdCurrency>
                </TrTable>
            </TBodyTable>
        </TablePercentage>
    );
};
import { Spinner, SpinnerSize } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import Auth from "../../Auth/Auth";
import FundService from "../../services/FundService";
import SavingsPlanService, { DefaultSavingPlan, ReadySavingPlan } from "../../services/SavingsPlanService";
import UsersSubscriptionService, { UsersSubscriptionType } from "../../services/UsersSubscriptionService";
import StepsPromotioned from "./StepsPromotioned";

const PromotionedPage = () =>
{
    const firstTimeInitPromotionedSavingPlan = useRef<boolean>(false);
    const [defaultSavingPlan, setDefaultSavingPlan] = useState<DefaultSavingPlan>({fundDescription: "", fundName: "", id: 0, name: "", isin: "", minAmount: 1000, userId: 0});
    const [usersSubscription, setUsersSubscription] = useState<UsersSubscriptionType>({});
    const [loading, setLoading] = useState(true);
    const [isPlanReady, setReadyPlan] = useState(false);
    const [hasPortfolio, setHasPortfolio] = useState(false);
    const [fund, setFund] = useState<any>();

    useEffect(() =>
    {
        window.scrollTo(0,0);
    }, []);

    useEffect(() =>
    {
        const user = Auth.getUserProfile();

        if(firstTimeInitPromotionedSavingPlan.current === false)
        {
            SavingsPlanService.getPromotionedSavingPlan(user.id).then((response: DefaultSavingPlan) =>
            {
                setDefaultSavingPlan(response);

                FundService.getFund(response.isin).then((fund: any) =>
                {
                    setFund(fund);
                });
                
                SavingsPlanService.getReadyPromotionedPlan(user.id, response.id).then((data: ReadySavingPlan) =>
                {
                    setReadyPlan(data.readySavingPlan);
                    setHasPortfolio(data.hasPortfolio);
                });

                UsersSubscriptionService.getPendingPromotioned(user.id, response.id).then((data: UsersSubscriptionType) =>
                {
                    setUsersSubscription(data);
                    setLoading(false);
                });
            });
        }

    }, [firstTimeInitPromotionedSavingPlan]);

    if(loading === true)
    {
        return <Spinner size={SpinnerSize.large} />;
    }
    else if(loading === false && defaultSavingPlan.id === 0)
    {
        return <h1>No hay promociones asociadas a este usuario.</h1>
    }
    else
    {
        return(
            <StepsPromotioned
                isSavingPlanReady={isPlanReady}
                hasPortfolio={hasPortfolio}
                savingPlan={defaultSavingPlan}
                fund={fund}
                usersSubscription={usersSubscription}
            />
        );
    }
}

export default PromotionedPage;
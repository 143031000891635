import styled from 'styled-components';

export const WarningAdvise = styled.div`
	color: ${(props) => props.theme.palette.black};

    i::after {
        font-size: 72px;
        color: gold;
        text-shadow: 0 0 2px rgb(0 0 0 / 95%);
    }
`;
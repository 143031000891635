import { useState, forwardRef, useImperativeHandle } from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { registerIcons } from '@uifabric/styling';
import { Nav, INavStyles, INavLinkGroup, INavLink } from '@fluentui/react/lib/Nav';
import { Link, useHistory } from "react-router-dom";
import { Toggle } from '@fluentui/react/lib/Toggle';
import { DefaultButton, Stack, Text, Image } from 'office-ui-fabric-react';
import { Separator } from '@fluentui/react/lib/Separator';
import PersonStatus from './PersonStatus';
import logo from '../images/logo.svg';
import logo_dark from '../images/logo_dark.svg';
import Auth from '../Auth/Auth';
import React from 'react';
import { MainSearch } from './Buscadores/main/MainSearch';
import SocialNetworksLinks from './SocialNetworksLinks';
import { RootStateOrAny, useSelector } from 'react-redux';

var isSubscribed = false;
var isActiveManagementPlan = false;
var isAlchemyPlan = false; 

const navStyles: Partial<INavStyles> =
{
    root:
    {
        boxSizing: 'border-box',
        border: '1px solid #eee',
        overflowY: 'auto',
    },
    groupContent:
    {
        marginBottom: 0,
    },
    link:
    {
        whiteSpace: 'normal',
        lineHeight: 'inherit',
    }
};

// @ts-ignore
registerIcons(
{
    icons:
    {
        'ironiaSearch': <i className="ironia-icon search" />,
    }
});

const MainMenu = (theme: any, ref: any) =>
{
    const user: any = Auth.getUserProfile();
    const history = useHistory();
    isSubscribed = Auth.isSubscribed();
    isActiveManagementPlan = Auth.isActiveManagementPlan();
    isAlchemyPlan = Auth.isAlchemyPlan();
    const [state, setState] = useState({ menuIsOpened: false });
    const activeSavingsPlanHasOutdatedcontract = useSelector((state: RootStateOrAny) => state.auth.outdatedContract);

    useImperativeHandle(ref, () =>
    ({
        showMenu()
        {
            setState({menuIsOpened: true});
        }
    }));
    const dismissMenu = (event: any) =>
    {
        event.preventDefault();
        setState(prevState =>
        ({
            ...prevState,
            menuIsOpened: false
        }));
    }
    var navLinkGroups: INavLinkGroup[] =
    [
        {
            links:
            [
                {
                    key: '/periodicSaving',
                    name: "Ahorro Periódico",
                    url: '/periodicSaving'
                },
                {
                    key: '/savingsPlans',
                    name: "Mis Planes",
                    url: '/savingsPlans'
                },
                {
                    key: '/search',
                    name: "Buscadores",
                    url: '/search'
                },
                {
                    key: '/portfolio',
                    name: "Mi Cartera",
                    url: '/portfolio'
                },
                {
                    key: '/purchase-transfer',
                    name: 'Traspasos Externos',
                    url: '/purchase-transfer'
                },
                {
                    key: '/elements',
                    name: 'Elements',
                    url: '/elements'
                },
                {
                    key: '/elements/sharedPortfolios',
                    name: 'Ranking de Carteras',
                    url: '/elements/sharedPortfolios'
                },
                {
                    key: '/dataverse',
                    name: 'Dataverse',
                    url: '/dataverse'
                },
                {
                    key: '/competitions',
                    name: 'Competiciones',
                    url: '/competitions'
                }
            ]
        }
    ];
    if(isSubscribed === false)
    {
        navLinkGroups[0].links.splice(0, 1);
        navLinkGroups[0].links.splice(2, 3);
    }
    if(isAlchemyPlan === true)
    {
        navLinkGroups[0].links.splice(4, 1, {key: '/AlchemyTransfer', name: 'Traspasos Externos', url: '/AlchemyTransfer'});
    }
    else if(isActiveManagementPlan === true)
    {
        navLinkGroups[0].links.splice(4, 1, {key: '/ManagementTransfer', name: 'Traspasos Externos', url: '/ManagementTransfer'});
    }

    if (activeSavingsPlanHasOutdatedcontract) {
        const purchaseTransferLink = navLinkGroups[0].links.findIndex(l => l.key === '/purchase-transfer');
        if (purchaseTransferLink !== -1) {
            navLinkGroups[0].links.splice(purchaseTransferLink, 1);
        }
    }

    return(
        <React.Fragment>
            <Panel isOpen={state.menuIsOpened}
                onDismiss={dismissMenu}
                type={PanelType.smallFluid}
                hasCloseButton={false}
                closeButtonAriaLabel="Cerrar"
            >
                <Stack horizontal>
                    <DefaultButton className="icon-button" onClick={dismissMenu}>
                        <i className="ironia-icon cross" />
                    </DefaultButton>
                    <Separator vertical className="separator vertical" />
                    <Image src={theme.theme.theme.palette.name === 'light' ? logo_dark : logo} alt="IronIA logotipo" />
                </Stack>
                <div className="ms-hiddenXlUp">
                    <MainSearch theme={theme.theme.theme} />
                </div>
                <Nav className="main-menu"
                    ariaLabel="Menu"
                    styles={navStyles}
                    groups={navLinkGroups}
                    onLinkClick={(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) =>
                    {
                        if(ev)
                        {
                            ev.nativeEvent.preventDefault();
                        }
                        if(item && item.url)
                        {
                            history.push(item.url);
                            dismissMenu(ev);
                        }
                    }}
                    selectedKey={navLinkGroups[0].links.find(l => history.location.pathname === l.url) !== undefined ?
                        // @ts-ignore
                        navLinkGroups[0].links.find(l => history.location.pathname === l.url).key
                        : (navLinkGroups[0].links.find(l => history.location.pathname.includes(l.url)) !== undefined ?
                            // @ts-ignore
                            navLinkGroups[0].links.find(l => history.location.pathname.includes(l.url)).key
                            : '')
                    }
                />
                <Stack className="ms-hiddenLgUp">
                    <Text>Personaliza tu vista de contenidos:</Text>
                    <Toggle checked={theme.theme.theme.palette.name === 'light' ? true : false } onText="LIGHT MODE" offText="DARK MODE" onChange={() => { theme.theme.toggleTheme()}} />
                </Stack>
                {!isSubscribed &&
                (
                    <Stack>
                        <h4 style={{ marginTop: 0 }}>¿Quieres operar en Ironia Store?</h4>
                        <Text className="fs16" style={{width: "63%", textAlign: "left"}}>
                            Suscríbete ya en Ironia para descubrir todas las ventajas de llevar tus finanzas a otro nivel
                        </Text>
                        <Link to="/savingsPlans/iHaveExperience" className="primary-button">¡Suscríbete!</Link>
                    </Stack>
                )}
                <Separator className="separator separatorMenu" />
                <div className="ms-hiddenLgUp">
                    <PersonStatus
                        // @ts-ignore
                        user={user} />
                    <Separator className="separator" />
                </div>
                <Text className="fs12">SÍGUENOS EN:</Text>
                <SocialNetworksLinks />
            </Panel>
        </React.Fragment>
    );
}

export default forwardRef(MainMenu);
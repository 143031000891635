import { Spinner, SpinnerSize } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { useEffect, useState } from 'react';
import Auth from '../../../../Auth/Auth';
import FnmContractService, { FnmContract } from '../../../../services/FnmContractService';
import OwnershipTable from './OwnershipTable';

const OwnershipTableLogic = () =>
{
    const user = Auth.getUserProfile();
    const [fnmContract, setFnmContract] = useState<FnmContract[]>();
    const [loading, setLoading] = useState(true);

    var columns =
    [
        {
            id: "type",
            title: "Tipo",
        },
        {
            id: "date",
            title: "Fecha",
        },
        {
            id: "savingsPlanName",
            title: "Plan de cartera",
        },
        {
            id: "contractCode",
            title: "Contrato",
        },
        {
            id: "version",
            title: "Versión",
        },
        {
            id: "signDate",
            title: "Fecha de firma",
        },
        {
            id: "idDocument",
            title: "Documento de identificación",
        },
        {
            id: "file",
            title: "Archivo",
        },
    ];

    const callAPIValues = async () =>
    {
        let data = await FnmContractService.getFnmContract(user.id);
        
        if(data !== undefined)
        {
            setFnmContract(data);
            setLoading(false);
        }
        else
        {
            setLoading(false);
        }
    };

    useEffect(() =>
    {
        window.scrollTo(0,0);
    }, []);

    useEffect(() =>
    {
        callAPIValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(loading)
    {
        return(<Spinner size={SpinnerSize.large} />);
    }
    else
    {
        return (
            <OwnershipTable
                key={"key"}
                tableTitle={"Documentación del Cliente"}
                tableCount={fnmContract?.length}
                rowData={fnmContract}
                columnTitles={columns}
            />
        );
    }
};

export default OwnershipTableLogic;
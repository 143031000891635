import React from "react";
import { Redirect } from "react-router-dom";
import Auth from "../Auth/Auth";

export const Home = () =>
{
    const isSubscribed = Auth.isSubscribed();
    const isPromotioned = Auth.isPromotioned();
    const isOutdated = Auth.isLastPlanOutdated();
    
    return (
        <React.Fragment>
            {isOutdated && <Redirect to="/savingsPlans"/>}
            {isSubscribed && <Redirect to="/portfolio"/>}
            {!isSubscribed && isPromotioned && <Redirect to="/promotioned"/>}
            {!isSubscribed && !isPromotioned && <Redirect to="/savingsPlans/new"/>}
        </React.Fragment>
    );
};
import Auth from "../../Auth/Auth";
import { SubscriptionType } from "../../services/SubscriptionService";
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Link } from "react-router-dom";
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { ai } from '../../services/TelemetryService';
import { useContext } from "react";
import { ThemeContext } from "@fluentui/react-theme-provider";
import { ContractsUnderSameSubscription } from "./RePaymentSubscription";
import { DescriptionContainer, ItemDiv, MoreResuscriptionPlansH2Item, MoreResuscriptionPlansPItem, ButtonContainer } from "./RePaymentSubscriptionStyle";
import { decimalFormatter } from "../../utils/numberFormatter";

const limitText = "El límite marca el volumen total de la cartera. Si se supera el límite no se pueden realizar más compras de fondos o traspasos.";

interface MoreResuscriptionPlansItemProps
{
    contracts: ContractsUnderSameSubscription,
    newSubscription: SubscriptionType,
    className: string
}

export const MoreResuscriptionPlansItem = (props: MoreResuscriptionPlansItemProps) =>
{
    const appInsights = ai.reactPlugin;
    const theme = useContext(ThemeContext);

    function getPrice()
    {
        var result: string = String(props.newSubscription.price);
        result = result.replace(".", ",");
        return result;
    }
    function getFrequency()
    {
        var result: string = String(props.newSubscription.period);
        if(result === "month")
        {
            return "mes";
        }
        else
        {
            return "año";
        }
    }
    function getLimit()
    {
        if(props.newSubscription.limit === null)
        {
            return "Sin Límite";
        }
        else
        {
            return String(decimalFormatter.format(props.newSubscription.limit as number))+"€";
        }
    }
    function getName()
    {
        if(props.newSubscription.name === undefined || props.newSubscription.name === null)
        {
            return "";
        }
        else
        {
            return String(props.newSubscription.name);
        }
    }
    function getDescription()
    {
        if(props.newSubscription.description === undefined || props.newSubscription.description === null)
        {
            return "";
        }
        else
        {
            return String(props.newSubscription.description);
        }
    }
    function setSubValue()
    {
        Auth.setRepaymentSubscription(props.newSubscription.id);
    }
    const trackClicks = useTrackEvent(appInsights, props.newSubscription.name, props.newSubscription.name);
    const performTracking = () =>
    {
        trackClicks(props.newSubscription.name);
    };

    return(
        <ItemDiv theme={theme} className={`ms-Grid-col ms-sm12 ${props.className}`}>
            <MoreResuscriptionPlansH2Item theme={theme} className="title">
                {getName()}
            </MoreResuscriptionPlansH2Item>
            <MoreResuscriptionPlansH2Item theme={theme} className="price">
                {getPrice()}€ / {getFrequency()}
            </MoreResuscriptionPlansH2Item>
            <DescriptionContainer>
                <MoreResuscriptionPlansPItem theme={theme} className="description">
                    {getDescription()}
                </MoreResuscriptionPlansPItem>
            </DescriptionContainer>
            <MoreResuscriptionPlansH2Item theme={theme} className="limit">
                Límite: {getLimit()}
            </MoreResuscriptionPlansH2Item>
            <MoreResuscriptionPlansPItem theme={theme} className="limit-text">
                {limitText}
            </MoreResuscriptionPlansPItem>
            <ButtonContainer>
                <Link to={{pathname: "/Repayment", state: {contracts: props.contracts}}}>
                    <DefaultButton text="Seleccionar" className="button tiny-primary-button" 
                        onClick={() =>
                        {
                            setSubValue();
                            performTracking();
                        }}
                    />
                </Link>
            </ButtonContainer>
        </ItemDiv>
    );
}
import { getTheme, IButtonStyles, IconButton, IIconProps, mergeStyleSets, Modal } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import React from "react";
import * as S from './Styles/ModalManagerStyle';
import { ThemeContext } from "@fluentui/react-theme-provider";
import ReactPlayer from "react-player";
import { ai } from "../../services/TelemetryService";

export interface Props
{
    isin: string
};
const theme = getTheme();
const iconButtonStyles: Partial<IButtonStyles> =
{
    root:
    {
        color: '#CC214F',
        marginLeft: 'auto',
        marginRight: '2px',
    },
    rootHovered:
    {
        color: theme.palette.neutralDark,
    }
};
const containerStyles = mergeStyleSets(
{
    container:
    {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        borderRadius: '2em',
        minWidth: "550px !important",
        width: "55%",
    }
});
export const ModalManagerExplanation: React.FunctionComponent<Props> = (props: Props) =>
{
    const appInsights = ai.reactPlugin;
    const [isModalManagerOpen, {setTrue: showManagerModal, setFalse: hideManagerModal}] = useBoolean(false);
    const titleId = useId('title');
    const themeContext = React.useContext(ThemeContext);
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const videoURL = "https://ironiaimg.blob.core.windows.net/video/JH-IE00BFRSYK98-2023-04-14.mp4";

    const customEventTrigger = () =>
    {
        appInsights.trackEvent({name: "Vídeo Gestora IA", properties: {isin: props.isin}});
    };

    return(
        <React.Fragment>
            <S.ButtonIA type="button" onClick={showManagerModal} theme={themeContext}>
                Janus Henderson Explica
            </S.ButtonIA>
            <Modal titleAriaId={titleId} isOpen={isModalManagerOpen} onDismiss={hideManagerModal} isBlocking={false} containerClassName={containerStyles.container}>
                <S.DivTitleContainer>
                    <S.TitleText theme={themeContext} id={titleId}>Vídeo de Janus Henderson</S.TitleText>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Cerrar modal" onClick={hideManagerModal} />
                </S.DivTitleContainer>
                <S.DivBodyContainer>
                    <S.ContainerDiv onClick={customEventTrigger}>
                        <ReactPlayer style={{textAlign: "center", width: "auto", marginBottom: "1em"}} width={"100%"} height={"650px"} playing={true} url={videoURL} muted={false} controls={true} />
                    </S.ContainerDiv>
                </S.DivBodyContainer>
            </Modal>
        </React.Fragment>
    );
}
import * as S from '../ConvenienceTest.style';
import { useEffect, useState } from 'react';
import './dropDown.css';
import Select from 'react-dropdown-select';
import FinametrixService from '../../../services/FinametrixService';
import { TextField } from '@fluentui/react';
import { InputStyles } from '../../GlobalStyle';

const ConvenienceTestAddressInputs = ({ // @ts-ignore
    handleChange, data, formVal, setFormVal, countries, regions, addressTypes
}) =>
{
    const {
        addressType,
        roadType,
        streetName,
        streetNumber,
        streetFloor,
        streetStair,
        streetDoor,
        streetOther,
        city,
        state,
        zipCode,
        country
    }: {
        addressType: string,
        roadType: string,
        streetName: string,
        streetNumber: string,
        streetFloor: string,
        streetStair: string,
        streetDoor: string,
        streetOther: string,
        city: string,
        state: string,
        zipCode: number,
        country: string
    } = data;

    const [errors, setErrors] = useState(
    {
        roadType: "",
        streetName: "",
        streetNumber: "",
        city: "",
        state: "",
        zipCode: "",
        country: ""
    });

    interface Option
    {
        text: string,
        key: string,
        value: any
    }

    const [cities, setCities] = useState<Option[]>([]);
    const [postalCodes, setPostalCodes] = useState<Option[]>([]);
    const [loadingCities, setLoadingCities] = useState(false);
    const [loadingPostalCodes, setLoadingPostalCodes] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const getErrorMessage = (field: string, value: any) =>
    {
        switch(field)
        {
            case 'roadType':
                return (!value.trim()) ? <S.Error>Este campo no puede estar vacio</S.Error> : "";

            case 'streetName':
                return (value.length < 3) ? <S.Error>El campo nombre no puede tener menos de 3 caracteres</S.Error> : "";

            case 'streetNumber':
                return (value.length === 0) ? <S.Error>El campo número no puede estar vacio</S.Error> : "";

            case 'city':
                return (value.length < 3) ? <S.Error>La ciudad no puede tener menos de 3 caracteres</S.Error> : "";

            case 'state':
                return (value.length < 3) ? <S.Error>El campo provincia no puede tener menor de 3 caracteres caracteres</S.Error> : "";

            case 'zipCode':
                return (value.length < 3)
                    ? <S.Error>El código postal no puede estar vacío</S.Error>
                    : !countryIsSpain() && (parseInt(value) <= 1000 || parseInt(value) >= 52999) ? <S.Error>El código postal es incorrecto</S.Error> : "";

            case 'country':
                return (!value.trim()) ? <S.Error>Este campo no puede estar vacío</S.Error> : "";

            default:
                throw new Error('Error en el switch');
        }
    };

    useEffect(() =>
    {
        Object.keys(data).forEach((key) =>
        {
            if(key in formVal)
            {
                if(data[key] === "")
                {
                    setFormVal(key, false);
                }
                else
                {
                    var error = getErrorMessage(key, data[key]);
                    setErrors((prevState: any) =>
                    {
                        return {
                            ...prevState,
                            [key]: error
                        }
                    });
                    setFormVal(key, error === "");
                }
            }
        });
    }, []);

    const countryIsSpain = () =>
    {
        return country.toUpperCase() === 'ES';
    }

    useEffect(() =>
    {
        if(isLoaded)
        {
            setFormVal("state", false);
            handleChange({ target: { name: "state", value: "" } });

            setFormVal("city", false);
            handleChange({ target: { name: "city", value: "" } });

            setFormVal("zipCode", false);
            handleChange({ target: { name: "zipCode", value: "" } });
        }
        else
        {
            setIsLoaded(true);
        }
    }, [country]);

    useEffect(() =>
    {
        if(state !== "" && countryIsSpain())
        {
            setLoadingCities(true);
            setCities([]);
            setPostalCodes([]);
            var stateAux = regions.find((stateItem: any) => stateItem.text === state.toUpperCase())
            if(stateAux)
            {
                FinametrixService.getCitiesByRegion(stateAux.value)
                    .then((result: any) =>
                        {
                            setCities(result);
                            setLoadingCities(false);
                        },
                        (error: any) =>
                        {
                            setErrors(error);
                            setLoadingCities(false);
                        }
                    );
            }
        }
    }, [state]);

    useEffect(() =>
    {
        if(city !== "" && cities.length > 0 && countryIsSpain())
        {
            setLoadingPostalCodes(true);
            var cityAux = cities.find((cityItem: any) => cityItem.text === city.toUpperCase())
            if(cityAux)
            {
                FinametrixService.getPostalCodesByCity(cityAux.value)
                    .then((result: any) =>
                        {
                            setPostalCodes(result);
                            setLoadingPostalCodes(false);
                        },
                        (error: any) =>
                        {
                            setErrors(error);
                            setLoadingPostalCodes(false);
                        }
                    );
            }
        }
    }, [city, cities]);

    const handleInputChange = (event: any) =>
    {
        if(event.target !== undefined)
        {
            var { name, value } = event.target;
            var error = getErrorMessage(name, value);
            setErrors((prevState: any) =>
            {
                return {
                    ...prevState,
                    [name]: error
                }
            });
            setFormVal(name, error === "");
            handleChange(event);
        }
    };

    return (
        <>
            <S.HelperStyle />
            <S.FieldSet>
                <S.FieldViaCont>
                    <S.DropDownLabel>Tipo de vía *</S.DropDownLabel>
                    <Select
                        name="roadType"
                        labelField='text'
                        options={addressTypes}
                        onChange={(event: any) =>
                        {
                            handleInputChange(
                            {
                                target: {
                                    name: "roadType",
                                    value: event.length > 0 ? event[0].value : ""
                                }
                            })
                        }}
                        // @ts-ignore
                        values={roadType !== "" && addressTypes.find((addressTypeAux) => addressTypeAux.value === roadType) ? [addressTypes.find((addressTypeAux) => addressTypeAux.value === roadType)] : []}
                        dropdownHeight='2000'
                        searchable={true}
                        searchBy="key"
                        noDataLabel={'No encontrado'}
                        valueField={'value'}
                        placeholder=''
                    />
                    {errors.roadType !== "" && errors.roadType}
                </S.FieldViaCont>
                <S.FieldViaNameCont>
                    <TextField
                        // @ts-ignore
                        styles={InputStyles}
                        label='Nombre de la Vía'
                        id='streetName'
                        name='streetName'
                        onChange={handleInputChange}
                        value={streetName}
                        type='text'
                        required
                    />
                    {errors.streetName !== "" && errors.streetName}
                </S.FieldViaNameCont>
                <S.FieldNumberCont>
                    <TextField
                        // @ts-ignore
                        styles={InputStyles}
                        label='Número'
                        id='streetNumber'
                        name='streetNumber'
                        onChange={handleInputChange}
                        value={streetNumber}
                        type='number'
                        required
                    />
                    {errors.streetNumber !== "" && errors.streetNumber}
                </S.FieldNumberCont>
            </S.FieldSet>
            <S.FieldSet>
                <S.FieldRMCont>
                    <TextField
                        // @ts-ignore
                        styles={InputStyles}
                        label='Escalera / Bloque'
                        id='streetStair'
                        name='streetStair'
                        onChange={handleChange}
                        value={streetStair}
                        type='text'
                    />
                </S.FieldRMCont>
                <S.FieldRSCont>
                    <TextField
                        // @ts-ignore
                        styles={InputStyles}
                        label='Piso'
                        id='streetFloor'
                        name='streetFloor'
                        onChange={handleChange}
                        value={streetFloor}
                        type='number'
                    />
                </S.FieldRSCont>
                <S.FieldRSCont>
                    <TextField
                        // @ts-ignore
                        styles={InputStyles}
                        label='Puerta'
                        id='streetDoor'
                        name='streetDoor'
                        onChange={handleChange}
                        value={streetDoor}
                        type='text'
                    />
                </S.FieldRSCont>
                <S.FieldRBCont>
                    <TextField
                        // @ts-ignore
                        styles={InputStyles}
                        label='Otro'
                        id='streetOther'
                        name='streetOther'
                        onChange={handleChange}
                        value={streetOther}
                        type='text'
                    />
                </S.FieldRBCont>
            </S.FieldSet>
            <S.FieldSet>
                <S.DropDownRBCont>
                    <S.DropDownLabel>País *</S.DropDownLabel>
                    <Select
                        name="country"
                        labelField='text'
                        placeholder=''
                        options={countries}
                        onChange={(event: any) =>
                        {
                            handleInputChange(
                            {
                                target: {
                                    name: "country",
                                    value: event.length > 0 ? event[0].value : ""
                                }
                            })
                        }}
                        // @ts-ignore
                        values={country !== "" && countries.find((countryAux) => countryAux.value === country) ? [countries.find((countryAux) => countryAux.value === country)] : []}
                        dropdownHeight='2000'
                        searchable={true}
                        searchBy="key"
                        noDataLabel={'No encontrado'}
                        valueField={'value'}
                    />
                    {errors.country !== "" && errors.country}
                </S.DropDownRBCont>
                {!countryIsSpain() && (
                    <>
                        <S.FieldRSCont>
                            <TextField
                                // @ts-ignore
                                styles={InputStyles}
                                label='Estado/Provincia'
                                id='state'
                                name='state'
                                onChange={handleInputChange}
                                value={state}
                                type='text'
                                required
                            />
                            {errors.state !== "" && errors.state}
                        </S.FieldRSCont>
                        <S.FieldRMCont>
                            <TextField
                                // @ts-ignore
                                styles={InputStyles}
                                label='Ciudad'
                                id='city'
                                name='city'
                                onChange={handleInputChange}
                                value={city}
                                type='text'
                                required
                            />
                            {errors.city !== "" && errors.city}
                        </S.FieldRMCont>
                        <S.FieldRSCont>
                            <TextField
                                // @ts-ignore
                                styles={InputStyles}
                                label='Código Postal'
                                id='zipCode'
                                name='zipCode'
                                onChange={handleInputChange}
                                value={zipCode.toString()}
                                type='number'
                                required
                            />
                            {errors.zipCode !== "" && errors.zipCode}
                        </S.FieldRSCont>
                    </>
                )}
                {countryIsSpain() && (
                    <>
                        <S.DropDownRBCont>
                            <S.DropDownLabel>Estado/Provincia *</S.DropDownLabel>
                            <Select
                                name="state"
                                labelField='text'
                                placeholder=''
                                options={regions}
                                onChange={(event: any) =>
                                {
                                    handleInputChange(
                                    {
                                        target: {
                                            name: "state",
                                            value: event.length > 0 ? event[0].text : ""
                                        }
                                    })
                                }}
                                // @ts-ignore
                                values={state !== "" && regions.find((regionAux) => regionAux.text === state) ? [regions.find((regionAux) => regionAux.text === state)] : []}
                                dropdownHeight='2000'
                                searchable={true}
                                searchBy="key"
                                noDataLabel={'No encontrado'}
                                valueField={'value'}
                                required
                            />
                            {errors.state !== "" && errors.state}
                        </S.DropDownRBCont>
                        <S.DropDownRBCont>
                            <S.DropDownLabel>Ciudad *</S.DropDownLabel>
                            <Select
                                name="city"
                                labelField='text'
                                placeholder=''
                                options={cities}
                                onChange={(event: any) =>
                                {
                                    handleInputChange(
                                    {
                                        target: {
                                            name: "city",
                                            value: event.length > 0 ? event[0].text : ""
                                        }
                                    })
                                }}
                                // @ts-ignore
                                values={city !== "" && cities.find((cityAux) => cityAux.text === city) ? [cities.find((cityAux) => cityAux.text === city)] : []}
                                dropdownHeight='2000'
                                searchable={true}
                                searchBy="key"
                                noDataLabel={'No encontrado'}
                                valueField={'value'}
                                required
                            />
                            {errors.city !== "" && errors.city}
                        </S.DropDownRBCont>
                        <S.DropDownRBCont>
                            <S.DropDownLabel>Código Postal *</S.DropDownLabel>
                            <Select
                                name="zipCode"
                                labelField='text'
                                placeholder=''
                                options={postalCodes}
                                onChange={(event: any) =>
                                {
                                    handleInputChange({
                                        target: {
                                            name: "zipCode",
                                            value: event.length > 0 ? event[0].text : ""
                                        }
                                    })
                                }}
                                // @ts-ignore
                                values={zipCode !== "" && postalCodes.find((postalCodeAux) => postalCodeAux.text === zipCode.toString()) ? [postalCodes.find((postalCodeAux) => postalCodeAux.text === zipCode.toString())] : []}
                                dropdownHeight='2000'
                                searchable={true}
                                searchBy="key"
                                noDataLabel={'No encontrado'}
                                valueField={'value'}
                                required
                            />
                            {errors.zipCode !== "" && errors.zipCode}
                        </S.DropDownRBCont>
                    </>
                )}
            </S.FieldSet>
        </>
    );
};

export default ConvenienceTestAddressInputs;
import axios, { CancelToken } from "axios";
import { proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
import { ManagerType } from './ManagerService';
import { RiskType } from './FinametrixService';
import { SubscriptionType } from "./SubscriptionService";
setupInterceptorsTo(axios);

interface IManagedPortfolio
{
    id: number,
    name: string,
    description: string,
    finametrixId: string,
    managerId: number,
    manager: ManagerType,
    subscriptionId: number,
    subscription: SubscriptionType,
    monthlyPrice: number,
    annualPrice: number,
    managedPortfolioDistributions: IManagedPortfolioDistribution[],
    showOnCover: boolean,
    default: boolean,
    status: number,
    image?: string,
    url?: string,
    riskLevel1ManagedPortfolioId?: number,
    riskLevel2ManagedPortfolioId?: number,
    riskLevel3ManagedPortfolioId?: number,
    riskLevel4ManagedPortfolioId?: number,
    riskLevel1ManagedPortfolio?: IManagedPortfolio,
    riskLevel2ManagedPortfolio?: IManagedPortfolio,
    riskLevel3ManagedPortfolio?: IManagedPortfolio,
    riskLevel4ManagedPortfolio?: IManagedPortfolio,
    riskProfile?: string,
    managedPortfolioRelationship?: IManagedPortfolioRelationship,
    createdAt: string,
    updatedAt?: string
}
interface IManagedPortfolioDistribution
{
    distribution: IDistributionItem[]
}
interface IDistributionItem
{
    instrumentId: string,
    weight: number
}
interface IInstrumentPrice
{
    name?: string,
    value?: string,
    data: [string, any]
};
interface IQuantData
{
    annualizedVolatility: number,
    performance: number,
    performance1M: number,
    performance1Y: number,
    performance2Y: number,
    performance3M: number,
    performance3Y: number,
    performance5Y: number,
    performance6M: number,
    performanceMTD: number,
    performanceYTD: number,
    volatility: number
}
interface ISerieItem
{
    accReturn: number,
    dailyReturn: number,
    date: string,
    dateAsString: string,
    value: number
};
interface IPortfolioDistributionItem
{
    name: string,
    value: number,
    level2?: any[]
};
interface IQuant
{
    quant: IQuantData,
    serie: ISerieItem[],
    lastCompositionItems: IPortfolioCompositionItem[]
};
interface IPortfolioCompositionItem
{
    _id: string,
    instrumentId: string,
    weight: number,
    name?: string,
    isin?: string,
    category?: string,
    subcategory?: string,
}
interface IFnmModelPortfolio
{
    _id: string,
    name: string,
    description: string,
    risk: RiskType,
    type: string,
    entity: string,
    compositions: IPortfolioCompositionItem[],
    creationDate: string
    __v: number,
    quant: IQuantData
}
interface ICompleteManagedPortfolio
{
    managedPortfolio: IManagedPortfolio,
    modelPortfolio?: IFnmModelPortfolio,
    serie?: IInstrumentPrice[],
    composition?: IPortfolioCompositionItem[],
    distribution?: IPortfolioDistributionItem[],
    performance?: number,
    performanceStartDate?: string
}
interface IManagedPortfolioRelationship
{
    id?:  number,
    name: string,
    createdAt: string,
    updatedAt?: string,
    managedPortfolios?: IManagedPortfolio[]
}
interface IManagedPortfolioFromSavingPlan
{
    isin: string,
    weight: number,
    name: string,
    instrumentId: string
}

export type ManagedPortfolioType = IManagedPortfolio;
export type DistributionItemType = IDistributionItem;
export type CompleteManagedPortfolioType = ICompleteManagedPortfolio;
export type PortfolioCompositionItemType = IPortfolioCompositionItem;
export type QuantType = IQuant;
export type InstrumentPriceType = IInstrumentPrice;
export type PortfolioDistributionItemType = IPortfolioDistributionItem;
export type ManagedPortfolioList = IManagedPortfolioFromSavingPlan;

class ManagedPortfolioService
{
    public getManagedPortfolios = async (): Promise<ManagedPortfolioType[]> =>
    {
        let url: string = `${proxyUrl}/managedPortfolios`;
        const req = await axios.get(url);
        return req.data;
    }
    public getCoverManagedPortfolios = async (): Promise<ManagedPortfolioType[]> =>
    {
        let url: string = `${proxyUrl}/managedPortfolios?showOnCover`;
        const req = await axios.get(url);
        return req.data;
    }
    public getManagedPortfolio = async (managedPortfolioId: number): Promise<ManagedPortfolioType> =>
    {
        let url: string = `${proxyUrl}/managedPortfolios/${managedPortfolioId}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public createManagedPortfolio = async (managedPortfolio: ManagedPortfolioType): Promise<ManagedPortfolioType> =>
    {
        let url: string = `${proxyUrl}/managedPortfolios`;
        const req = await axios.post(url, managedPortfolio);
        return req.data;
    }
    public updateManagedPortfolio = async (managedPortfolio: ManagedPortfolioType): Promise<ManagedPortfolioType> =>
    {
        let url: string = `${proxyUrl}/managedPortfolios/${managedPortfolio.id}`;
        const req = await axios.put(url, managedPortfolio);
        return req.data;
    }
    public getManagedPortfolioWithAlternatives = async (managedPortfolioId: number): Promise<ManagedPortfolioType> =>
    {
        let url: string = `${proxyUrl}/managedPortfolios/${managedPortfolioId}?withAlternatives`;
        const req = await axios.get<any>(url);
        return req.data;
    }
    public getManagedPortfoliosForTheCover = async (connectionReference: string): Promise<any> =>
    {
        let url: string = `http://localhost:7043/managedPortfoliosHub/getForTheCover/${connectionReference}`;
        const req = await axios.get<any>(url);
        return req;
    }
    public getCompletedManagedPortfoliosForTheCover = async (): Promise<any> =>
    {
        let url: string = `http://localhost:7043/managedPortfoliosHub/getForTheCover`;
        const req = await axios.get<any>(url);
        return req;
    }
    public getManagedPortfolioForTheCover = async (managedPortfolioId: number, cancelToken: CancelToken): Promise<CompleteManagedPortfolioType> =>
    {
        let url: string = `${proxyUrl}/managedPortfolios/${managedPortfolioId}/getForTheCover`;
        const req = await axios.get<any>(url, { cancelToken: cancelToken });
        return req.data;
    }
    public getManagedPortfolioFromSavingPlanId = async (savingPlanId: number) : Promise<ManagedPortfolioList[]> =>
    {
        let url: string = `${proxyUrl}/managedPortfoliosFromSavingPlanId/${savingPlanId}`;
        const req = await axios.get<any>(url);
        return req.data;
    }
}

const managedPortfolios = new ManagedPortfolioService();
export default managedPortfolios;
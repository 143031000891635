import styled from "styled-components";

type SpanProps = {
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  m?: number;
  color?: string;
};

const Span = styled.span<SpanProps>`
  color: ${(props) => (props.color ? props.color : "#95a0a1")};
  margin: ${(props) => `${props.m}rem`};
  margin-top: ${(props) => `${props.mt}rem`};
  margin-right: ${(props) => `${props.mr}rem`};
  margin-bottom: ${(props) => `${props.mb}rem`};
  margin-left: ${(props) => `${props.ml}rem`};
`;

export const Spans = {
  Span,
};

import { useContext } from 'react';
import { DefaultButton } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import Auth from '../../Auth/Auth';
import { ThemeContext } from '@fluentui/react-theme-provider';
import { ModalSharedPortfolioName } from '../../screen/Portfolios/SharedPortfolios/ModalSharedPortfolioName';
import SharedPortfolioService, { SharedPortfolioType } from "../../services/SharedPortfoliosService";
import React from 'react';

interface SharePortolioButtonProps
{
    sending: boolean,
    setSending: any,
    text: string,
    isModalOpen: any,
    showModal: any,
    hideModal: any
};

const SharePortolioButton = (props: SharePortolioButtonProps) =>
{
    const theme = useContext(ThemeContext);
    let activePlan = Auth.getActivePlan();
    const history = useHistory();

    const sharePortfolio = (name: string) =>
    {
        props.setSending(true);
        SharedPortfolioService.createSharedPortfolio(
        {
            name: name,
            agreeToTermsAndConditions: true,
            sharedTimes: 0,
            status: 0,
            fnmPortfolioId: activePlan.portfolio.id
        })
        .then((response: SharedPortfolioType) =>
        {
            if(response.id)
            {
                history.push(`/sharedPortfolios/${response.id}/contract`);
            }
        })
        .catch((error: any) => {})
        .finally(() =>
        {
            props.setSending(false);
        });
    }

    const updateSharedPortfolio = (sharedPortfolioId: number, name: string) =>
    {
        props.setSending(true);
        SharedPortfolioService.patchSharedPortfolio(sharedPortfolioId, {name: name}).then((response: SharedPortfolioType) =>
        {
            if(response.id)
            {
                Auth.setActivePlan(
                {
                    ...activePlan,
                    portfolio:
                    {
                        ...activePlan.portfolio,
                        sharedPortfolio:
                        {
                            ...activePlan.portfolio.sharedPortfolio,
                            name: name
                        }
                    }
                });
                activePlan = Auth.getActivePlan();
                props.setSending(false);
                props.hideModal();
            }
        })
        .catch((error: any) =>
        {
            props.setSending(false);
            props.hideModal();
        });
    }

    return(
        <React.Fragment>
            {(activePlan.portfolio?.sharedPortfolio?.status === undefined || activePlan.portfolio?.sharedPortfolio?.status !== 2) &&
            (
                <DefaultButton className="button tiny-primary-button"
                    style={{ float: "right" }}
                    onClick={props.showModal}
                >
                    {props.text}
                </DefaultButton>
            )}
            <ModalSharedPortfolioName isModalOpen={props.isModalOpen}
                hideModal={props.hideModal}
                _onSubmit={(name: string) => activePlan.portfolio?.sharedPortfolio?.status === undefined
                    ? sharePortfolio(name)
                    : updateSharedPortfolio(activePlan.portfolio?.sharedPortfolio?.id, name)
                }
                theme={theme}
                sharedPortfolio={activePlan.portfolio?.sharedPortfolio}
                sending={props.sending}
                setSending={props.setSending}
            />
        </React.Fragment>
    );
};

export default SharePortolioButton;
import axios from "axios";
import { proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);

class MarketAttachmentService {
    public downloadIroniaStoreBase64Pdf = async (event: any, data: any) => {
        return this.getMarketBlobAttachment(data.file)
            .then((response) => {
                const file = new Blob(
                    [response.data],
                    { type: response.headers['content-type'] });

                var downloadLink = document.createElement("a");
                downloadLink.download = data.name;
                downloadLink.href = URL.createObjectURL(file);
                downloadLink.style.display = "none";
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            })
    };

    public getMarketBlobAttachment = async (filename: string): Promise<any> => {
        const cleanedFileName = filename.replace("/marketreports/", "");
        let url: string = `${proxyUrl}/market-attachments/${cleanedFileName}`
        console.log('url llamada al PDF >>> ', url);
        const req = await axios.get<any>(url, { responseType: 'blob' });
        console.log('req llamada al PDF >>> ', req);
        return req;
    }

    // market attachments
    public getBase64MarketAttachment = async (filename: string): Promise<any> => {
        const cleanedFileName = filename.replace("/marketreports/", "");
        let url: string = `${proxyUrl}/market-attachments/${cleanedFileName}`
        const req = await axios.get<any>(url, { responseType: 'blob' });
        const reader = new FileReader();
        return new Promise((resolve) => {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(req.data);
        });
    }
}

const MarketAttachments = new MarketAttachmentService();
export default MarketAttachments;
import React, { useRef } from "react";
import DocumentTitle from "react-document-title";
import * as Style from './SearchManagersStyle';
import { ai } from "../../../services/TelemetryService";
import { useHistory } from "react-router-dom";
import { Separator } from "@fluentui/react";
import FilterManagers from "./FilterManagers";
import SearchEngine from "./SearchEngine";
import { getManagerNames } from "./ManagerNames";
import {publicUrl} from '../../../config';

interface Managers
{
    name: string,
    url: string
}

const SearchManagers = () =>
{
    const appInsights = ai.reactPlugin;
    const history = useHistory();
    const firstLoading = useRef<boolean>(true);
    const [imageURL, setImageURL] = React.useState<string>("");
    const [managerName, setManagerName] = React.useState<string>("");
    const [managerSearch, setManagerSearch] = React.useState<string[]>();
    const [filters, setFilters] = React.useState(
    {
        subcategory: [],
        manager: [],
        currency: [],
        available: undefined,
        income: undefined,
        switchable: undefined,
        indexed: undefined,
        class: undefined
    });

    const clickRegister = () =>
    {
        appInsights.trackEvent({name: "IFrame Buscador de Gestora "+managerName, properties: {date: new Date()}});
        history.push("/");
    }
    const _managers: Managers[] =
    [
        {name: 'Allianz', url: '/images/allianz.jpg'},
        {name: 'Carmignac', url: '/images/carmignac.jpg'},
        {name: 'Columbia-Threadneedle', url: '/images/columbia_threadneedle.jpg'},
        {name: 'Fidelity', url: '/images/fidelity.jpg'},
        {name: 'Gam', url: 'images/gam.jpg'},
        {name: 'Janus-Henderson', url: '/images/janusHenderson.jpg'},
        {name: 'Lonvia', url: '/images/lonvia.jpg'},
        {name: 'MG', url: '/images/m&g.jpg'},
        {name: 'Schroder', url: '/images/schroders.jpg'},
        {name: 'Vontobel', url: '/images/vontobel.jpg'}
    ];
    React.useEffect(() =>
    {
        if(firstLoading.current === true)
        {
            firstLoading.current = false;
            for(let i=0; i<_managers.length; i++)
            {
                if(_managers[i].name.toUpperCase() === window.location.pathname.substring(17).toUpperCase())
                {
                    let mName: string = _managers[i].name.toLowerCase();
                    setManagerSearch(getManagerNames(mName));
                    if(mName.includes("-"))
                    {
                        let index: number = mName.indexOf("-");
                        setManagerName(mName.charAt(0).toUpperCase()+mName.slice(1,index)+" "+mName.charAt(index+1).toUpperCase()+mName.slice(index+2));
                    }
                    else
                    {
                        if(mName === "mg")
                        {
                            setManagerName(mName.charAt(0).toUpperCase()+'&'+mName.charAt(1).toUpperCase());
                        }
                        else
                        {
                            setManagerName(mName.charAt(0).toUpperCase()+mName.slice(1));
                        }
                    }
                    setImageURL(_managers[i].url);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChange = React.useCallback((value, filterId) =>
    {
        setFilters(currentFilters => ({...currentFilters, [filterId]: value}));
    }, []);

    return(
        <React.Fragment>
            <DocumentTitle title={"IronIA - Buscador de Fondos de "+managerName}>
                <React.Fragment>
                    <Style.RowHeader className="ms-Grid-row">
                        <Style.ColHeaderTitle className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl10 ms-xxl10 ms-xxxl10">
                            <Style.MainTitle>
                                {managerName.toUpperCase()}
                            </Style.MainTitle>
                            <Style.SubTitle>
                                Consulta aquí todos los fondos de {managerName}
                            </Style.SubTitle>
                        </Style.ColHeaderTitle>
                        <Style.ColHeaderButton className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl2 ms-xxl2 ms-xxxl2">
                            <Style.ButtonSubscribe onClick={clickRegister}>
                                Regístrate en IronIA para operar
                            </Style.ButtonSubscribe>
                        </Style.ColHeaderButton>
                    </Style.RowHeader>
                    <Separator />
                    <Style.RowManager className="ms-Grid-row">
                        <Style.ColContainer className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                            <Style.TitleContainer>
                                <Style.TitleManager>
                                    GESTORA {managerName !== "" ? managerName.toUpperCase() : ""}
                                </Style.TitleManager>
                            </Style.TitleContainer>
                            <Style.CardsContainer>
                                <Style.CardImage>
                                    <img src={publicUrl+imageURL} alt={"Imagen de la gestora "+managerName} />
                                </Style.CardImage>
                            </Style.CardsContainer>
                        </Style.ColContainer>
                    </Style.RowManager>
                    <Style.RowFilters className="ms-Grid-row">
                        <FilterManagers onChange={handleChange} selectedFilters={filters} />
                    </Style.RowFilters>
                    <Style.RowSearchEngine className="ms-Grid-row">
                        {managerSearch !== undefined &&
                        (
                            <SearchEngine managerName={managerName} filters={filters} managerSearch={managerSearch} />
                        )}
                    </Style.RowSearchEngine>
                </React.Fragment>
            </DocumentTitle>
        </React.Fragment>
    );
}

export default SearchManagers;
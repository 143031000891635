export const getManagerNames = (managerName: string) =>
{
    switch(managerName.toLowerCase())
    {
        case "Allianz".toLowerCase():
        {
            return ["ALLIANZ GLOBAL INVESTORS"];
        }
        case "Carmignac".toLowerCase():
        {
            return ["CARMIGNAC GESTION FRANCE", "CARMIGNAC GESTION LUXEMBOURG"];
        }
        case "Columbia-Threadneedle".toLowerCase():
        {
            return ["COLUMBIA THREADNEEDLE INV SERVICES LTD", "COLUMBIA THREADNEEDLE MGMT LUXEMBOURG", "COLUMBIA THREADNEEDLE INV SERVICES LTD*"];
        }
        case "Fidelity".toLowerCase():
        {
            return ["FIDELITY INTERNATIONAL", "FIDELITY UCITS II ICAV"];
        }
        case "Gam".toLowerCase():
        {
            return ["GAM (LUXEMBOURG) S.A.", "GAM STERLING MANAGEMENT LIMITED"];
        }
        case "Janus-Henderson".toLowerCase():
        {
            return ["JANUS HENDERSON CAPITAL", "JANUS HENDERSON FUND", "JANUS HENDERSON HORIZON", "JANUS HENDERSON INVESTORS"];
        }
        case "Lonvia".toLowerCase():
        {
            return ["LONVIA CAPITAL"];
        }
        case "MG".toLowerCase():
        {
            return ["M&G LUXEMBOURG S.A", "M&G LUXEMBOURG S.A.", "M&G SECURITIES LIMITED*"];
        }
        case "Schroder".toLowerCase():
        {
            return ["SCHRODER INVESTMENT MANAGEMENT"];
        }
        case "Vontobel".toLowerCase():
        {
            return ["VONTOBEL AM", "VONTOBEL FUND II"];
        }
    }
}
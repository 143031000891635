import styled from 'styled-components';
import {Separator} from '@fluentui/react';
import { Button } from '../../../components/buttons/MainButton';

export const Main = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    @media(min-width: 1280px)
    {
        margin: 0 auto;
    }
    .button-spinner
    {
        float: left;
        margin-right: 10px;
    }
    & .restarted-ms-Grid > .ms-Grid-row
    {
        min-height: 80px !important;
        margin: 0 0 !important;
        & > .ms-Grid-col
        {
            padding-left: 24px;
        }
    }
    & .ms-Stack .ms-StackItem
    {
        width: 100%;
    }
    & .ms-Stack .ms-StackItem,
    & .investment-fund-finder .ms-ComboBox-container
    {
        margin-top: 24px;
    }
    & .ms-Stack .ms-StackItem:first-child,
    .investment-fund-finder .ms-ComboBox-container:first-child
    {
        margin-top: 0;
    }
    & .ms-Stack .ms-StackItem:not(:first-child)
    {
        margin-left: 0;
    }
    & .ms-ChoiceField
    {
        display: inline-flex;
        margin-right: 8px;
        background: ${props => props?.theme?.palette?.white || "#FFF"};
        border: 1px solid ${props => props?.theme?.palette?.neutralLighter || "#E9EFF1"};
        padding: 10px 16px;
        border-radius: 26px;
    }
`;
export const SeparatorTop = styled(Separator)`
    margin-bottom: 1em;
`;
export const SeparatorForm = styled(Separator)`
    margin-top: 0;
    margin-bottom: 17px;
`;
export const OuterFooterArea = styled.div`
    display: flex;
    justify-content: flex-end;
`;
export const Text = styled.p`
    font-size: 18px;
    line-height: 24px;
    color: ${props => props?.theme?.palette?.lightBlack || "#556769"};
    margin-top: 0;
    margin-bottom: 0;
`;
export const TextSmall = styled.p`
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin: 0;
    margin-top: 32px;
    color: ${props => props?.theme?.palette?.neutralText || "#95A0A1"};
`;
export const BackButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    span
    {
        margin-right: 10px;
        cursor: pointer;
    }
`;
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    h2
    {
        margin: 0;
    }
`;
export const SubTitleContainer = styled.h3`
    margin: 0;
`;
export const CardContainer = styled.div`
    background-color: ${props => props?.theme?.palette?.tertiary || "#F3F7F9"};
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    margin-bottom: 20px;
    padding: 20px 40px 20px 40px;
`;
export const CardHeader = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 0;
    justify-content: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    h3
    {
        margin: 0;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
    }
    .small-text
    {
        margin-top: 6px;
    }
`;
export const CardButton = styled(Button.Primary)`
    margin-top: 32px;
    margin-bottom: 0px;
`;
export const CardHeadingContainer = styled.div`
    margin-top: 10px;
`;
export const CardBody = styled.div`
    margin-top: 19px;
`;
export const SectionContainer = styled.div`
    padding: 0;
    margin-top: 2em;
`;
export const WarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: #FFF1BE;
    color: #F69A2D;
    padding: 30px;
    gap: 10px;
    font-weight: 500;
    p
    {
        line-height: 20px;
        margin: 0;
    }
`;
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import DocumentTitle from 'react-document-title';
import SubscriptionService, { SubscriptionType } from '../../services/SubscriptionService';
import './Subscriptions.sass';
import { createIntl, createIntlCache } from '@formatjs/intl';
import Spanish from '../../translations/es.json';

const cache = createIntlCache()
const intl = createIntl(
    {
        locale: 'es',
        messages: Spanish,
    },
    cache
)

const Subscriptions = () =>
{
    const [error, setError] = useState({ status: false });
    const [isLoaded, setIsLoaded] = useState(false);
    const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>([]);

    useEffect(() =>
    {
        setIsLoaded(false);
        SubscriptionService.getSubscriptions()
            .then(
                (data: SubscriptionType[]) =>
                {
                    setIsLoaded(true);
                    setSubscriptions(data.filter(subscription => subscription.actionText !== null));
                },
                (error) =>
                {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, []);

    if(error.status)
    {
        return (<div>Error: {// @ts-ignore
            error.message}</div>)
    }

    if(!isLoaded || subscriptions === undefined || subscriptions.length === 0)
    {
        return <Spinner size={SpinnerSize.large} />
    }

    return (
        <DocumentTitle title='IronIA - Suscripciones'>
            <div className="ms-Grid-row tight">
                <h2>Elige tu plan</h2>
                <p className="description">Suscríbete en IRONIA y disfruta de todas las ventajas de llevar tu cartera de inversiones a otro nivel.</p>
                <Separator className="separator" />
                <div className="table-content">
                    <table className="subscriptions">
                        <thead>
                            <tr>
                                <th></th>
                                {subscriptions.map((subscription) => (

                                    <th  // @ts-ignore
                                        className={subscription.recommended ? 'recommended' : ''} key={subscription.id}>
                                        <div className="attribute">
                                            { // @ts-ignore
                                                subscription.freeDays > 0 &&
                                                <div className="free-days">30 días gratis</div>
                                            }
                                            { // @ts-ignore
                                                subscription.recommended &&
                                                <div className="recommended">Recomendado</div>
                                            }
                                        </div>
                                    </th>
                                ))}
                            </tr>
                            <tr>
                                <th></th>
                                {subscriptions.map((subscription) => (
                                    <th className={ // @ts-ignore
                                        subscription.recommended ? 'recommended' : ''} key={subscription.id}>
                                        <div className="name">
                                            { // @ts-ignore
                                                subscription.name}
                                        </div>
                                        <div className="description">
                                            { // @ts-ignore
                                                subscription.description}
                                        </div>
                                        <div className="price">
                                            { // @ts-ignore
                                                subscription.price}<span>€/{intl.messages[subscription.period]}</span>
                                        </div>
                                        <div className="action">
                                            <Link  // @ts-ignore
                                                to={`/subscription/${subscription.id}`} className={"button " + (subscription.default ? "seconday-button" : "primary-button")}>
                                                { // @ts-ignore
                                                    subscription.actionText}
                                            </Link>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Más de 18.000 fondos para invertir</td>
                                {subscriptions.map((subscription) => (
                                    <td className={ // @ts-ignore
                                        subscription.recommended ? 'recommended' : ''} key={subscription.id}>
                                        <i className="ironia-icon check" />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Intelgicencia Artificial para seleccionar fondos</td>
                                {subscriptions.map((subscription) => (
                                    <td className={  // @ts-ignore
                                        subscription.recommended ? 'recommended' : ''} key={subscription.id}>
                                        <i className="ironia-icon check" />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Sin comisiones por operación</td>
                                {subscriptions.map((subscription) => (
                                    <td className={ // @ts-ignore
                                        subscription.recommended ? 'recommended' : ''} key={subscription.id}>
                                        <i className="ironia-icon check" />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Libertad de gestión</td>
                                {subscriptions.map((subscription) => (
                                    <td className={ // @ts-ignore
                                        subscription.recommended ? 'recommended' : ''} key={subscription.id}>
                                        <i className="ironia-icon check" />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Devolución de incentivos de gestoras a los clientes</td>
                                {subscriptions.map((subscription) => (
                                    <td className={ // @ts-ignore
                                        subscription.recommended ? 'recommended' : ''} key={subscription.id}>
                                        <i className="ironia-icon check" />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Asesoramientio sobre tu cartera</td>
                                {subscriptions.map((subscription) => (
                                    <td className={ // @ts-ignore
                                        subscription.recommended ? 'recommended' : ''} key={subscription.id}>
                                        <i  // @ts-ignore
                                            className={"ironia-icon " + (subscription.Advised ? 'check' : 'cross')} />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Educación personalizada #moneygym</td>
                                {subscriptions.map((subscription) => (
                                    <td  // @ts-ignore
                                        className={subscription.recommended ? 'recommended' : ''} key={subscription.id}>
                                        <i  // @ts-ignore
                                            className={"ironia-icon " + (subscription.advised ? 'check' : 'cross')} />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td></td>
                                {subscriptions.map((subscription) => (
                                    // @ts-ignore
                                    <td className={subscription.recommended ? 'recommended' : ''} key={subscription.id}></td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default Subscriptions;
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import React from "react"
import DocumentTitle from "react-document-title";
import * as E from '../Elements/ElementStyles';
import { ThemeContext } from '@fluentui/react-theme-provider';
import ElementService from '../../services/ElementsService';
import { useHistory } from 'react-router-dom';
import styles from './Contract/index.module.sass';
import { DefaultButton } from '@fluentui/react';

const ElementSignature = () =>
{
    const history = useHistory();
    const loadContractFirstTime = React.useRef<boolean>(false);
    const [contract, setContract] = React.useState<string>("Cargando el contrato...");
    const theme = React.useContext(ThemeContext);
    const [bottom, setBottom] = React.useState(false);

    const handleScroll = (event: any) =>
    {
        const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 1;
        setBottom(bottom);
    };

    React.useEffect(() =>
    {
        if(loadContractFirstTime.current === false)
        {
            ElementService.getElementContract().then((response: string) =>
            {
                if(response !== undefined)
                {
                    loadContractFirstTime.current = true;
                    setContract(response);
                }
            });
        }
    }, []);

    return(
        <DocumentTitle title='IronIA - Elements - Contrato'>
            <React.Fragment>
                <E.ContractPanel
                    theme={theme}
                    onScroll={handleScroll}
                    className={styles.content}
                >
                    {contract !== undefined && <div dangerouslySetInnerHTML={{__html: contract}} />}
                </E.ContractPanel>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 text-right">
                        <DefaultButton
                            className="button tiny-primary-button"
                            id={styles.signButton}
                            disabled={!bottom}
                            onClick={() => history.push('/Elements/Signature/Confirmation')}
                        >
                            Firmar
                        </DefaultButton>
                    </div>
                </div>
            </React.Fragment>
        </DocumentTitle>
    )
}

export default ElementSignature;
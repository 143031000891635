import { Image } from 'office-ui-fabric-react/lib/Image';
import { DefaultButton, Stack, Text, Separator } from 'office-ui-fabric-react';
import { ThemeContext } from './ThemeContext';
import { Link, useHistory } from "react-router-dom";
import logo_dark from '../images/logo_dark.svg';
// import logo_cnmv from '../images/cnmv.png';
import logo_diaphanum from '../images/diaphanum.png';
import logo_fintech from '../images/fintech.png';
import styled from "styled-components";
import { connect } from 'react-redux';
import SocialNetworksLinks from './SocialNetworksLinks';
import { publicUrl } from "../config";

const StyledFooter = styled.footer`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 514px;
    box-shadow: 0px 2px 8px rgba(8, 84, 117, 0.08);
    color: #FFFFFF;
    button
    {
        background: transparent;
        border: 0;
    }
    .footprint
    {
        width: 374px;
        height: 469px;
        margin: 45px 115px 0;
        padding: 135px 44px 0 64px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        float: right;
        &:before
        {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 100;
            background-image: url(${props => // @ts-ignore
            `${publicUrl + '/images/footprint.svg'}`});
        }
        .home-link
        {
            height: fit-content;
            padding: 0;
            margin-left: -42px !important;
            img
            {
                height: 114px;
                margin-bottom: -27px;
            }
        }
    }
    .ms-Grid-row.footer-container
    {
        > .ms-Grid-col
        {
            &:nth-child(n+2)
            {
                padding-top: 76px;
            }

            &:last-child {
                text-wrap: balance;
                padding-right: 20px;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    .slogan
    {
        font-size: 24px;
        font-weight: 200;
        line-height: 32px;
        opacity: 0.7;
    }
    .separator
    {
        opacity: 0.2;
        margin-top: 12px;
        margin-bottom: 13px;
    }
    .copyright
    {
        font-size: 16px;
        font-weight: 100;
        line-height: 22px;
        opacity: 0.4;
    }
    .title
    {
        font-size: 14px;
        line-height: 20px;
        opacity: 0.5;
        margin-top: 30px;
        margin-bottom: 24px;
        display: block;
    }
    .titleSmall
    {
        font-size: 14px;
        line-height: 20px;
        opacity: 0.5;
        margin-top: 30px;
        margin-bottom: 8px;
        display: block;
    }
    .titleSede
    {
        font-size: 14px;
        line-height: 20px;
        margin-top: 0;
        margin-bottom: 24px;
        display: block;
        max-width: 600px;
        text-wrap: balance;
        a
        {
            color: #CC214F;
        }
    }
    .links
    {
        font-size: 14px;
        line-height: 20px;
        display: inline-block;
        > *
        {
            margin-left: 72px;
            &:first-of-type
            {
                margin-left: 0;
            }
        }
    }
    .social-networks
    {
        display: inline-block;
        button
        {
            min-width: 0;
            width: 56px;
            height: 32px;
            text-align: center;
            background: rgba(255,255,255,0.1);
            border-radius: 32px;
            margin-left: 16px;
            padding: 0;
            &:first-of-type
            {
                margin-left: 0;
            }
        }
    }
    .sponsors
    {
        display: inline-block;
        margin-top: -15px;
        .ms-Image
        {
            vertical-align: bottom;
            margin-left: 92px;
            display: inline-block;
            &:first-child
            {
                margin-left: 0;
            }
        }
    }
    .related-entities
    {
        display: flex;
    }

    @media (max-width: 640px)
    {
        position: relative;
        height: 850px;
        min-height: unset;
        .footprint
        {
            height: 300px;
            padding: 33px 30px;
            margin: 0;
            &:before
            {
                transform: rotate(125deg);
                top: -150px;
                left: -55px;
                height: 470px;
            }
        }
        .ms-Grid-row
        {
            .ms-Grid-col:last-child
            {
                padding: 0 32px;

                .title:first-child
                {
                    margin-top: 20px;
                }

                .titleSede {
                    margin-top: 40px;
                }

                .links
                {
                    > *
                    {
                        margin-left: 32px;
                        &:first-child
                        {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        .sponsors
        {
            .ms-Image
            {
                padding: 15px 0;
                display: block;
                margin: 0;
            }
        }
    }
`;

function mapStateToProps(state: any)
{
    return{ footerVisible: state.ui.footerVisible };
}

const Footer = (props: any) =>
{
    let history = useHistory();

    const handleHomeButtonClick = () =>
    {
        // FIXME: marcar la entrada del menú correpondiente
        history.push("/");
    }

    if(!props.footerVisible)
    {
        return (<></>);
    }

    return(
        <ThemeContext.Consumer>
            {theme =>
            (
                <StyledFooter>
                    <div className="ms-Grid-row footer-container">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl4">
                            <div className="footprint">
                                <DefaultButton className="home-link" title="Página principal" onClick={handleHomeButtonClick}>
                                    <Image src={logo_dark} alt="IronIA logotipo" />
                                </DefaultButton>
                                <Text className="slogan">Libertad para invertir</Text>
                                <Separator className="separator" />
                                <Text className="copyright">&copy;{new Date().getFullYear()} by IronIA Fintech</Text>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl4">
                            <Text className="titleSede">
                                Simplicitas Capital SL, marca IronIA Fintech,
                                agente exclusivo de <a
                                    href='https://www.diaphanum.es/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Diaphanum Valores SV SAU
                                </a> , entidad autorizada y supervisada por CNMV
                                con domicilio en C/María de Molina 40, 28006 Madrid
                                a través de nuestro correo electrónico <a
                                    href='mailto:info@ironia.tech'
                                    title='Correo electrónico de contacto'
                                >
                                    <span>info@ironia.tech</span>
                                </a>
                            </Text>
                            <Text className="title">Links de interés</Text>
                            <Stack verticalAlign="start" className="links">
                                <Link to="/Legal" target="_blank" rel="noopener noreferrer" style={{color: "white"}}>
                                    <Text>Aviso Legal</Text>
                                </Link>
                                <a href="https://www.linkedin.com/company/ironia-fintech/people/" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
                                    <Text>Nuestro equipo</Text>
                                </a>
                                <a href="https://www.linkedin.com/company/ironia-fintech/jobs/" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
                                    <Text>Careers</Text>
                                </a>
                                <a href="https://blog.ironia.tech" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
                                    <Text>Blog</Text>
                                </a>
                            </Stack>
                            <Text className="title">Síguenos en nuestras redes sociales:</Text>
                            <SocialNetworksLinks />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl4">
                            <p>
                                IronIA informa a sus clientes que, conforme a estándares de mercado generalmente aceptados, la
                                puesta a disposición del espacio publicitario en nuestra plataforma a gestoras de fondo de
                                inversión está sujeto a los costes operativos y publicitarios pertinentes; dichos costes son
                                íntegramente sufragados por las gestoras de fondos sin repercusión a nuestros clientes. En
                                ningún caso dichos costes tienen la consideración de “platform fees” o comisiones variables
                                pagadas periódicamente por la gestora de la IIC a IronIA como un porcentaje del volumen
                                intermediado, ni están directamente ligados a la prestación de un servicio de inversión al
                                cliente por IronIA o las entidades de su grupo o relacionadas o vinculadas al patrimonio del
                                fondo que la plataforma pueda haber intermediado para sus clientes finales.
                            </p>
                            <Text className="title">Información Adicional:</Text>
                            <Stack className="related-entities">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl8 ms-xxl8 ms-xxxl8">
                                        <a href="https://www.diaphanum.es/" target="_blank" rel="noopener noreferrer">
                                            <Image src={logo_diaphanum} alt="Diaphanum" />
                                        </a>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-hiddenXlUp" style={{paddingTop: "2em"}}>
                                        <a href="https://www.asociacionfintech.es/" target="_blank" rel="noopener noreferrer">
                                            <Image src={logo_fintech} alt="FINTECH e INSURTECH" />
                                        </a>
                                    </div>
                                    <div className="ms-Grid-col ms-xl4 ms-xxl4 ms-xxxl4 ms-hiddenLgDown" style={{paddingTop: 0}}>
                                        <a href="https://www.asociacionfintech.es/" target="_blank" rel="noopener noreferrer">
                                            <Image src={logo_fintech} alt="FINTECH e INSURTECH" />
                                        </a>
                                    </div>
                                </div>
                            </Stack>
                        </div>
                    </div>
                </StyledFooter>
            )}
        </ThemeContext.Consumer>
    )
};

export default connect(mapStateToProps)(Footer);
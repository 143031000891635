import { useContext } from "react";
import { ThemeContext } from "@fluentui/react-theme-provider";
import styled from "styled-components";
import FinametrixService, { IClientDocs } from "../../../../services/FinametrixService";
import { Modal, SpinnerSize, Spinner, mergeStyleSets } from '@fluentui/react';
import { useBoolean } from "@fluentui/react-hooks";
import Attachments from "../../../../services/AttachmentService";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    gap: 20px;
`;

export const CounterContainer = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 800;
    font-size: 18px;
    flex-grow: 1;
    padding: 5px 0;
    color: ${(props) => props.theme.palette.neutralText};
    h3
    {
        margin: 0;
        font-size: 18px;
        font-weight: 800;
        color: ${(props) => props.theme.palette.black};
    }
`;

export const ActionsContainer = styled.div`
    display: flex;
    button
    {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
        background: ${(props) => props.theme.palette.white};
        padding: 0 10px;
        border-radius: 16px;
        color: ${(props) => props.theme.palette.lightBlack};
        border: 1px solid ${(props) => props.theme.palette.neutralLighter};
        cursor: pointer;
    }
`;

export const TableContainer = styled.div`
    overflow-x: auto;
    display: flex;
    flex: 1;
    border-radius: 16px;
    box-shadow: 0px 0px 16px rgba(8, 84, 117, 0.08);
    padding: 25px 20px;
    margin-bottom: 20px;
    max-height: 350px;
    overflow-y: auto;
  
    @media screen and (min-width: 1200px)
    {
        width: 1047px;
        margin: 0 auto 20px auto;
    }
`;

export const Table = styled.table`
    width: 100%;
    overflow-y: hidden;
    border-spacing: 0;
    text-align: left;
    background: ${(props) => props.theme.palette.white};

    thead
    {
        th
        {
            background: ${(props) => props.theme.palette.tertiary};
            font-weight: 500;
            padding: 17px;
            text-align: center;
        }
        th:first-of-type
        {
            border-top-left-radius: 10px;
        }
        th:last-of-type
        {
            border-top-right-radius: 10px;
        }
    }
  
    td
    {
        padding: 16px;
        text-align: center;
        font-size: 12px;
        color: ${(props) => props.theme.palette.neutralText};
        > a
        {
            color: ${(props) => props.theme.palette.darkBorder};
            font-weight: 600;
            line-height: 20px;
            font-size: 14px;
            cursor: pointer;
        }
    }
`;

const contentStyles = mergeStyleSets(
{
    container:
    {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        padding: '45px',
        borderRadius: '16px',
        selectors:
        {
            h3:
            {
                textAlign: 'center',
                marginBottom: '20px'
            }
        },
    }
});

export interface ITableDocumentationProps
{
    tableTitle?: string | undefined,
    rowData: IClientDocs[] | undefined,
    columnTitles: string[],
    tableCount?: number | undefined,
    dataType: string
};

const DocumentationTable = (props: ITableDocumentationProps) =>
{
    const theme = useContext(ThemeContext);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const downloadByDataType = (docId: string) =>
    {
        if(props.dataType === 'clientDocument')
        {
            return FinametrixService.getClientDocumentBase64Pdf(docId);
        }
        else
        {
            return FinametrixService.getMonthlyReportBase64Pdf(docId);
        }
    };

    const downloadBase64Pdf = (event: any, clientDoc: IClientDocs) =>
    {
        event.preventDefault();
        showModal();
        downloadByDataType(clientDoc.id).then((response) =>
        {
            var downloadLink = document.createElement("a");
            downloadLink.download = clientDoc.name;
            downloadLink.href = response;
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            hideModal();
        }).catch((error) =>
        {
            console.log(error);
        });
    };

    const downloadIroniaStoreBase64Pdf = (event: any, data: any) => {
        event.preventDefault();
        showModal();
        Attachments.getBlobAttachment(data.link)
            .then((response) => {
                const file = new Blob(
                    [response.data],
                    { type: response.headers['content-type'] });

                var downloadLink = document.createElement("a");
                downloadLink.download = data.name;
                downloadLink.href = URL.createObjectURL(file);
                downloadLink.style.display = "none";
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                hideModal();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return(
        <Wrapper>
            <Header>
                <CounterContainer theme={theme}>
                    {props.tableTitle !== undefined && <h3>{props.tableTitle}</h3>}
                    {props.tableCount !== undefined && <span>({props.tableCount})</span>}
                </CounterContainer>
                <ActionsContainer theme={theme}></ActionsContainer>
            </Header>
            <TableContainer>
                <Table theme={theme}>
                    <thead>
                        <tr>
                            {props.columnTitles.map((column, columnIndex) =>
                            {
                                return <th key={'header-' + columnIndex}>{column}</th>;
                            }
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {props.rowData?.map((row, rowIndex) =>
                        {
                            return (
                                <tr key={'row-'+rowIndex}>
                                    <td>{row.name}</td>
                                    <td>{row.date}</td>
                                    <td>
                                        {props.dataType !== "elements" && (
                                            <a href={'#' + row.name} onClick={(event) => downloadBase64Pdf(event, row)}>
                                                <i className={'ironia-icon downloads'} /> Descargar
                                            </a>
                                        )}
                                        {props.dataType === "elements" && (
                                            <a href={'#' + row.link} onClick={(event) => downloadIroniaStoreBase64Pdf(event, row)}>
                                                <i className={'ironia-icon downloads'} /> Descargar
                                            </a>
                                        )}
                                    </td>
                                </tr>
                            );
                        }
                        )}
                    </tbody>
                </Table>
            </TableContainer>
            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    onDismiss={hideModal}
                    containerClassName={contentStyles.container}
                    isBlocking={false}
                >
                    <h3>Descargando archivo</h3>
                    <Spinner size={SpinnerSize.large} />
                </Modal>
            )}
        </Wrapper>
    );
};
export default DocumentationTable;
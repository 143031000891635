import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const H2 = styled.h2`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
`;

export const H3 = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const H4 = styled.h4`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const H5 = styled.h5`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const H6 = styled.h6`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const Title = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
};
